import React, { useContext } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { AdminSetting } from '../../../../models/AdminSetting';
import { GlobalContext } from '../../PortalPage';
import { useTranslation } from 'react-i18next';

export const PropertyMultipleSelectionDataTable = ({ rows, groups, selectedProperties, onSelectionChange, filterPropertyType = "" }
    : { rows: UserProperty[], groups: GroupLicenseTable[], selectedProperties: UserProperty[], onSelectionChange: any, filterPropertyType?: string }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const { adminSetting }: { adminSetting: AdminSetting } = useContext(GlobalContext);
    const [filters, setFilters] = React.useState<any>([]);
    const {t} = useTranslation();

    React.useEffect(()=>{
        setFilters([{ columnName: UserProperty.Columns.propertyType, value: filterPropertyType }]);
    }, [filterPropertyType])

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === "groups") {
            let names = groups.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).map(x => x.notificationName);
            return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} ${t("homeDrawerFilter.units")}`);
        }
        if (column.name === "devices") {
            let names = groups.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).map(x => x.uniqueDeviceId);
            return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} DeviceIds`);
        }
        return <Table.Cell {...props} />;
    };

    const columns = [
        {
            name: UserProperty.Columns.name,
            title: "Name",
            getCellValue: (row: UserProperty) => row.name,
        },
        {
            name: UserProperty.Columns.propertyType,
            title: t("myUnitsSettings.featureType"),
            getCellValue: (row: UserProperty) => UserProperty.GetLabelByType(row.propertyType),
        },
        {
            name: UserProperty.Columns.co2Value,
            title: t("myUnitsSettings.co2ConsumptionKgM2"),
            getCellValue: (row: UserProperty) => {
                let currentUsageCategory = adminSetting.adminUsageCategories.find(x => x.id == row.usageCategoryId);
                return currentUsageCategory ? currentUsageCategory.carbonDioxideConsumption : "Keine Nutzungsart zugewiesen"
            },
        },
        {
            name: UserProperty.Columns.usageType,
            title: t("myUnitsSettings.usageType"),
            getCellValue: (row: UserProperty) => {
                let currentUsageCategory = adminSetting.adminUsageCategories.find(x => x.id == row.usageCategoryId);
                return currentUsageCategory ? currentUsageCategory.name : "Keine Nutzungsart zugewiesen"
            },
        },
        {
            name: UserProperty.Columns.areaPerformancePerSquareMeter,
            title: t("myUnitsSettings.areaPerformance"),
            getCellValue: (row: UserProperty) => row.areaPerformancePerSquareMeter,
        },
        {
            name: "groups",
            title: t("myUnitsSettings.assignedUnits"),
            getCellValue: (row: UserProperty) => {
                return groups.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.notificationName) return prevVal
                    return curVal.notificationName + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: "devices",
            title: "DeviceIds",
            getCellValue: (row: UserProperty) => {
                return groups.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.uniqueDeviceId) return prevVal
                    return curVal.uniqueDeviceId + ", " + prevVal;
                }, "");;
            },
        },

    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 350 } }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: UserProperty) => row.id}
                columns={columns}
            >

                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters as any}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedProperties.map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
        </div>
    );
};
