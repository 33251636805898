export class ApiOfflineDataModel {
    public groupId: string = "";
    public userId: string = "";
    public offlineDataContent : ApiOfflineDataContentModel[] = [];
}

export class ApiOfflineDataContentModel {
    public createdTimestamp !: Date;
    public name: string = "";
    public tryExecuteCount: number = 0;
    constructor (name: string, createdTimestamp: Date, tryExecuteCount: number){
        this.name = name;
        this.createdTimestamp = createdTimestamp;
        this.tryExecuteCount = tryExecuteCount;
    }

    static Columns = {
        name: 'name',
        createdTimestamp: "createdTimestamp",
        createdTime: "createdTime"
    }
}