import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, FormControlLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, ApiOutlined, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ObjectSelectionDataTable } from '../entireEval/ObjectSelectionDataTable';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { useTranslation } from 'react-i18next';

export const ObjectSelectionAssignServiceWorkerToObjectDialog = ({
  open,
  setOpen,
  currentServiceWorker,
  groups,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  currentServiceWorker: UserServiceWorker,
  groups: GroupLicenseTable[],
  onRefresh: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation();
  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [token, setToken] = React.useState("");
  const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);

  const [isAssignGroupsFromObjectEnabled, setIsAssignGroupsFromObjectEnabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setSelectedObjects([]);
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const tempObjects = await api.getUserObjectUnits(tempToken);
      setObjects(tempObjects);

    })();

  }, [open]);

  const disabled = selectedObjects.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Wähle Objekte, die der Servicekraft <span className='header-dialog-default-title'>{currentServiceWorker.name}</span> zugewiesen werden sollen</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <ObjectSelectionDataTable rows={objects} groups={groups} currentGroup={currentServiceWorker}
          selectedObjects={selectedObjects}
          onSelectionChange={(selection: string[]) => {
            setSelectedObjects(objects.filter(x => selection.some(s => s === x.id)));
          }}
        />

      </DialogContent>
      <DialogActions>
        <FormControlLabel
          style={{ display: 'inline-block', textAlign: 'right', width: '100%' }}
          control={
            <Checkbox
              checked={isAssignGroupsFromObjectEnabled}
              onChange={(event: any) => { setIsAssignGroupsFromObjectEnabled(event.target.checked); }}
              name="isEnabled"
              color="primary"
            />
          }
          label={t("myObjectSettings.assignRemoveUnitsOfObjectToFromServiceStaff")}
        />
      </DialogActions>
      <DialogActions>

        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="large"
          endIcon={<Delete />}
          startIcon={loading && <CircularProgress size={32} />}
          disabled={disabled || loading}
          onClick={async () => {
            if (!currentServiceWorker) return;
            setLoading(true);
            await api.unAssignObjectsFromServiceWorker(selectedObjects.map(x => x.id), currentServiceWorker.id, token, enqueueSnackbar);
            if (isAssignGroupsFromObjectEnabled) {
              for (let prop of selectedObjects) {
                await unAssignGroupsFromObjectToServiceWorker(isAssignGroupsFromObjectEnabled, prop, groups, currentServiceWorker, token, enqueueSnackbar);
              }
            }
            setLoading(false);
            onRefresh();
            handleClose();
          }}
        >
          Entfernen
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Add />}
          startIcon={loading && <CircularProgress size={32} />}
          disabled={disabled || loading}
          onClick={async () => {
            if (!currentServiceWorker) return;
            setLoading(true);
            await api.assignObjectsToServiceWorker(selectedObjects.map(x => x.id), currentServiceWorker.id, token, enqueueSnackbar);
            if (isAssignGroupsFromObjectEnabled) {
              for (let prop of selectedObjects) {
                await assignGroupsFromObjectToServiceWorker(isAssignGroupsFromObjectEnabled, prop, groups, currentServiceWorker, token, enqueueSnackbar);
              }
            }
            setLoading(false);
            onRefresh();
            handleClose();
          }}
        >
          Zuweisen
        </Button>

      </DialogActions>

    </Dialog>
  );
}

export const unAssignGroupsFromObjectToServiceWorker = async (isAssignGroupsFromObjectEnabled: boolean, prop: ObjectUnit, groups: any[], currentServiceWorker: UserServiceWorker, token: any, enqueueSnackbar: any) => {
  if (isAssignGroupsFromObjectEnabled) {
    let groupsOfObject = groups.filter(x => x.objectUnitId == prop.id);
    for (let curGroup of groupsOfObject) {
      await api.UnAssignUserServiceWorkerFromGroup(curGroup.id, currentServiceWorker.id, token, enqueueSnackbar);
    }
  }
}

export const assignGroupsFromObjectToServiceWorker = async (isAssignGroupsFromObjectEnabled: boolean, prop: ObjectUnit, groups: any[], currentServiceWorker: UserServiceWorker, token: any, enqueueSnackbar: any) => {
  if (isAssignGroupsFromObjectEnabled) {
    let groupsOfObject = groups.filter(x => x.objectUnitId == prop.id);
    for (let curGroup of groupsOfObject) {
      await api.assignUserServiceWorkerToGroup(curGroup.id, currentServiceWorker.id, token, enqueueSnackbar);
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));