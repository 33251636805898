export class Base {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;

    public constructor(systemETag: string | null) {
        this.systemETag = systemETag;
    }

}

