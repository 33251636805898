import React from 'react';
import { Card } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { NetworkImage } from '../images/NetworkImage';
import { GroupSettings, GroupSettingsType } from '../../models/GroupSettings';
import { useGroupLogoDialogStyles } from '../../styles/useGroupLogoDialogStyles';
import { getUserId } from '../../helpers/storage-management';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import LicenseSvg from '../../assets/svgs/checkmenow_logo.svg'
import { getUserIdFromStorage } from '../../helpers/common';

export const GroupLogoDialog = ({ open, onClose, groupSettings, type }
  : { open: boolean, onClose: any, groupSettings: GroupSettings | UserGroupSettingsTemplate, type: GroupSettingsType }) => {

  const styles = useGroupLogoDialogStyles();
  const userId = getUserIdFromStorage();

  const [source, setSource] = React.useState('');

  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    (async () => {

      if (!groupSettings.logo?.fileName){
        setSource(LicenseSvg);
        return;
      }

      switch (type) {
        case GroupSettingsType.Template:
          setSource(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${groupSettings.id}/images/logos/${groupSettings.logo?.fileName}`);
          break;
        default:
          setSource(`${process.env.REACT_APP_BASIC_URI}/groups/${groupSettings.id}/images/logos/${groupSettings.logo?.fileName}`);
          break;
      }
    })();
  }, [open])

  // No rendering if dialog is closed
  if (!open) return null;

  return (
    <Dialog onClose={handleClose} aria-labelledby="group-logo-dialog-title" open={open}>
      <Card className={styles.root} style={{minHeight: 10}}>
        <NetworkImage src={source} />
      </Card>
    </Dialog>
  );
}
