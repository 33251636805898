import axios from "axios";
import { headers } from "../../api";
import { getUserIdFromStorage } from "../../helpers/common";

export const DeleteServiceModeById = async (groupId: string, serviceModeId: string, token: string, enqueueSnackbar: any): Promise<boolean> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.delete(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/service-modes/${serviceModeId}`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Service Mode Löschen erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Service Mode Löschen error", { variant: 'error' });
    }
    return null as any;
}