import { SensorLicense } from './GroupLicenseTable';
import { LicenseType } from './LicenseType';

export class GroupLicense {
    public id!: string;
    public systemETag!: string;
    public systemTimestamp!: string;
    public userId!: string;
    public purchaseTimestamp!: string;
    public purchaseTimestampUnixTimeSeconds!: number;
    public duration!: any;
    public durationDays !: number;
    public durationNumber!: any;
    public durationYears!: number;
    public isUsed!: boolean;
    public usedByGroup!: string;
    public validUntil!: any;
    public cost!: number;
    public extraCharge!: number;
    public type!: LicenseType;
    public netTotal!: number;
    public grossTotal!: number;
    public taxTotal!: number;
    public taxRate!: number;
    public sensorCount! : number;
    public loraSensorCount! : number;
    public sensorLicenses! : SensorLicense[];
    public loraSensorLicenses! : SensorLicense[];
    public checkpointCount !: number;
    public qualityCheckCount !: number;
    public publicFeedbackCount !: number;
    public payPerServiceCount !: number;
    public calendarProCount !: number;
    public taqtFeedbackCount !: number;
    public taqtTimeCount !: number;

    public batterySmartServiceDisplaySensorCount !: number;
    public buyerType!: BuyerType;
    public buyerUserId! : string; 
    public purchaseReasonType! :PurchaseReasonType;

}
export enum BuyerType
{
    Normal = 0,
    FromAdmin = 1,
    FromSeller = 2
}
export enum PurchaseReasonType
{
    Promotion = 0,
    Gift = 1,
    Borrowed = 2,
    PersonalNeed = 3,
}

export class GroupLicensePatch {
    public id!: string;
    public userId !: string;
    public buyerType!: BuyerType;
    public purchaseReasonType !: PurchaseReasonType;
    public buyerUserId !: string;

    constructor(id: string, userId: string, buyerType: BuyerType, purchaseReasonType: PurchaseReasonType, buyerUserId: string) {
        this.id = id;
        this.userId = userId;
        this.buyerType = buyerType;
        this.purchaseReasonType = purchaseReasonType;
        this.buyerUserId = buyerUserId;
    }
}