import React, { useContext, useImperativeHandle } from "react";
import { ObjectLeaderQualityCheckModel } from "../../../../../models/ObjectLeaderQualityCheckModel";
import { GroupLicenseTable } from "../../../../../models/GroupLicenseTable";
import { GlobalContext } from "../../../PortalPage";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../checkout/ShopPage";
import { differenceInMinutes, subDays } from "date-fns";
import { PrintContext } from "../HitListDashboard";
import { formatDateRange, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../../helpers/date-management";
import { ChartHeightTextBox, PrintButton } from "../../../../core/GlobalTimeChoice";
import { StandardBarChartSimpleColor } from "../../../../charts/StandardBarChartSimpleColor";
import { blue, red } from "@mui/material/colors";
import { Grid, Paper, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { getAvgFromSimpleChart } from "../../../../../helpers/common";
import { MaxChartItemCountInput } from "../../../../../helpers/compontents";
import { getValueFromMarkedService } from "../../../../../helpers/charts/objectLeader/dataGenerator";
import { useTranslation } from "react-i18next";

export const HitListChartsQualityChecksCountMarking = React.forwardRef(({ qualityChecksTableData, groupsLicenseTableEntries }: { qualityChecksTableData: ObjectLeaderQualityCheckModel[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateQualityCheckCountMarkingSimpleChartData(date: Date) {
            updateStartDateQualityCheckCountMarkingSimpleChartData(date);
        },
        updateEndDateQualityChecksCountMarkingSimpleChartData(date: Date) {
            updateEndDateQualityChecksCountMarkingSimpleChartData(date);
        }

    }))

    const { maxChartItemCount }: { showStatus: boolean, setShowStatus: any, maxChartItemCount: number } = useContext(GlobalContext);
    const history = useHistory();
    const queryString = useQuery(history);

    const [chartHeightQualityCheckCountRatingSimple, setChartHeightQualityCheckCountRatingSimple] = React.useState(500);
    const [QualityCheckCountRatingSimpleChartData, setQualityCheckCountRatingSimpleChartData] = React.useState<any[]>([]);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);


    const [QualityCheckCountRatingSimpleChartDataBad, setQualityCheckCountRatingSimpleChartDataBad] = React.useState<any[]>([]);


    const [startDateQualityCheckCountRatingSimpleChartData, setStartDateQualityCheckCountRatingSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateQualityCheckCountRatingSimpleChartData, setEndDateQualityCheckCountRatingSimpleChartData] = React.useState(new Date());

    const {t} = useTranslation();



    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);

    React.useEffect(() => {
        updateQualityCheckCountRatingSimpleChart(startDateQualityCheckCountRatingSimpleChartData, endDateQualityCheckCountRatingSimpleChartData, qualityChecksTableData)
    }, [maxChartItemCount])

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (qualityChecksTableData) {
            setStartDateQualityCheckCountRatingSimpleChartData(startDate);
            setEndDateQualityCheckCountRatingSimpleChartData(endDate);
            updateQualityCheckCountRatingSimpleChart(startDate, endDate, qualityChecksTableData);
        }
    }, [qualityChecksTableData, groupsLicenseTableEntries])

    const updateStartDateQualityCheckCountMarkingSimpleChartData = (newVal: Date) => {
        if (!qualityChecksTableData) return;
        setStartDateQualityCheckCountRatingSimpleChartData(newVal);
        updateQualityCheckCountRatingSimpleChart(newVal, endDateQualityCheckCountRatingSimpleChartData, qualityChecksTableData);
    }

    const updateEndDateQualityChecksCountMarkingSimpleChartData = (newVal: Date) => {
        if (!qualityChecksTableData) return;
        setEndDateQualityCheckCountRatingSimpleChartData(newVal);
        updateQualityCheckCountRatingSimpleChart(startDateQualityCheckCountRatingSimpleChartData, newVal, qualityChecksTableData);
    }

    const updateQualityCheckCountRatingSimpleChart = (startDate: Date, endDate: Date, qualityChecks: ObjectLeaderQualityCheckModel[]) => {


        let filteredqualityChecks = qualityChecks.filter(x => new Date(x.createdTimestamp) >= startDate && new Date(x.createdTimestamp) <= endDate);
        let countGroupUnits = 0;
        let res = filteredqualityChecks.reduce((sumValue, newValue: ObjectLeaderQualityCheckModel) => {
            const notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
            let entryExists = sumValue.find(x => x.argumentFieldService == notificationName);
            if (entryExists) {
                entryExists.valueFieldService += 1;
                entryExists.countService += 1;
            } else {
                sumValue = [...sumValue, { argumentFieldService: notificationName, valueFieldService: 1, countService: 1 }];
                countGroupUnits++;
            }
            return [...sumValue];
        }, [] as any[]);
        let resNormal = res.sort((a, b) => a.valueFieldService - b.valueFieldService);


        setQualityCheckCountRatingSimpleChartData(resNormal.slice(-maxChartItemCount));
        setSimpleChartDataAvg(getAvgFromSimpleChart(resNormal.slice(-maxChartItemCount)))

        setQualityCheckCountRatingSimpleChartDataBad(resNormal.slice(0, maxChartItemCount).reverse());
        setSimpleChartDataAvgBad(getAvgFromSimpleChart(resNormal.slice(0, maxChartItemCount).reverse()))

        //percent
    }

    const buildContent = () => {
        return (
            <Paper className="paper-small" style={{ paddingTop: 40 }}>
                <Grid container spacing={3} item xs={12} direction="column">
                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"} fontWeight="bold">Qualitätssicherung pro Einheit Gesamt Bericht</Typography>
                    <Grid item container direction="row" spacing={3} alignItems="center">

                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={t("serviceDocumentationTable.startDate")}
                                value={startDateQualityCheckCountRatingSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateStartDateQualityCheckCountMarkingSimpleChartData(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}

                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={t("serviceDocumentationTable.endDate")}
                                value={endDateQualityCheckCountRatingSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateEndDateQualityChecksCountMarkingSimpleChartData(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}

                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightQualityCheckCountRatingSimple} setChartHeight={setChartHeightQualityCheckCountRatingSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätssicherung pro Einheit Absteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {QualityCheckCountRatingSimpleChartData.length} Einheiten: <b>{simpleChartDataAvg}</b></Typography>
                            </Grid>
                            <Grid item>
                                <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={QualityCheckCountRatingSimpleChartData} name={"Qualitätssicherung pro Einheit Gesamt (Gut)"} height={chartHeightQualityCheckCountRatingSimple} />
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätssicherung pro Einheit Aufsteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {QualityCheckCountRatingSimpleChartData.length} Einheiten: <b>{simpleChartDataAvgBad}</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={QualityCheckCountRatingSimpleChartDataBad} name={"Qualitätssicherung pro Einheit Gesamt (Schlecht)"} height={chartHeightQualityCheckCountRatingSimple} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>
        );
    }

    const buildPrintContent = () => {

        const titleDesc = `<b>Qualitätssicherung pro Einheit Absteigend</b><br />${formatDateRange(startDateQualityCheckCountRatingSimpleChartData, endDateQualityCheckCountRatingSimpleChartData)}`;

        const titleAsc = `<b>Qualitätssicherung pro Einheit Aufsteigend</b><br />${formatDateRange(startDateQualityCheckCountRatingSimpleChartData, endDateQualityCheckCountRatingSimpleChartData)}`

        return (
            <>
                <PrintButton title="Qualitätssicherung pro Einheit Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightQualityCheckCountRatingSimple} setChartHeight={setChartHeightQualityCheckCountRatingSimple} display={false} />
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} color={blue[600]} dataSource={QualityCheckCountRatingSimpleChartData} name={"Qualitätssicherung pro Einheit Gesamt (Gut)"} height={chartHeightQualityCheckCountRatingSimple} title={titleDesc} />
                    </div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={QualityCheckCountRatingSimpleChartDataBad} name={"Qualitätssicherung pro Einheit Gesamt (Schlecht)"} height={chartHeightQualityCheckCountRatingSimple} title={titleAsc} />
                    </div>
                </div>
            </>

        )



    }

    if (!qualityChecksTableData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )

});









export const HitListChartsQualityChecksAvgMarking = React.forwardRef(({ qualityChecksTableData, groupsLicenseTableEntries }: { qualityChecksTableData: ObjectLeaderQualityCheckModel[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateQualityCheckAvgMarkingSimpleChartData(date: Date) {
            updateStartDateQualityCheckAvgMarkingSimpleChartData(date);
        },
        updateEndDateQualityChecksAvgMarkingSimpleChartData(date: Date) {
            updateEndDateQualityChecksAvgMarkingSimpleChartData(date);
        }

    }))

    const { maxChartItemAvg }: { showStatus: boolean, setShowStatus: any, maxChartItemAvg: number } = useContext(GlobalContext);
    const history = useHistory();
    const queryString = useQuery(history);

    const [chartHeightQualityCheckAvgRatingSimple, setChartHeightQualityCheckAvgRatingSimple] = React.useState(500);
    const [QualityCheckAvgRatingSimpleChartData, setQualityCheckAvgRatingSimpleChartData] = React.useState<any[]>([]);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);


    const [QualityCheckAvgRatingSimpleChartDataBad, setQualityCheckAvgRatingSimpleChartDataBad] = React.useState<any[]>([]);


    const [startDateQualityCheckAvgRatingSimpleChartData, setStartDateQualityCheckAvgRatingSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateQualityCheckAvgRatingSimpleChartData, setEndDateQualityCheckAvgRatingSimpleChartData] = React.useState(new Date());

    const [QualityChecksAvg, setQualityChecksAvg] = React.useState(0);
    const {t} = useTranslation();


    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);

    React.useEffect(() => {
        updateQualityCheckAvgRatingSimpleChart(startDateQualityCheckAvgRatingSimpleChartData, endDateQualityCheckAvgRatingSimpleChartData, qualityChecksTableData)
    }, [maxChartItemAvg])

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (qualityChecksTableData) {
            setStartDateQualityCheckAvgRatingSimpleChartData(startDate);
            setEndDateQualityCheckAvgRatingSimpleChartData(endDate);
            updateQualityCheckAvgRatingSimpleChart(startDate, endDate, qualityChecksTableData);
        }
    }, [qualityChecksTableData, groupsLicenseTableEntries])

    const updateStartDateQualityCheckAvgMarkingSimpleChartData = (newVal: Date) => {
        if (!qualityChecksTableData) return;
        setStartDateQualityCheckAvgRatingSimpleChartData(newVal);
        updateQualityCheckAvgRatingSimpleChart(newVal, endDateQualityCheckAvgRatingSimpleChartData, qualityChecksTableData);
    }

    const updateEndDateQualityChecksAvgMarkingSimpleChartData = (newVal: Date) => {
        if (!qualityChecksTableData) return;
        setEndDateQualityCheckAvgRatingSimpleChartData(newVal);
        updateQualityCheckAvgRatingSimpleChart(startDateQualityCheckAvgRatingSimpleChartData, newVal, qualityChecksTableData);
    }

    const updateQualityCheckAvgRatingSimpleChart = (startDate: Date, endDate: Date, qualityChecks: ObjectLeaderQualityCheckModel[]) => {


        let filteredqualityChecks = qualityChecks.filter(x => new Date(x.createdTimestamp) >= startDate && new Date(x.createdTimestamp) <= endDate);
        let AvgGroupUnits = 0;
        let res = filteredqualityChecks.reduce((sumValue, newValue: ObjectLeaderQualityCheckModel) => {
            const notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
            let entryExists = sumValue.find(x => x.argumentFieldService == notificationName);
            if (entryExists) {
                let sumOfServices = newValue.markedServices.reduce((prevVal, currentVal) => {
                    return prevVal + getValueFromMarkedService(currentVal)
                }, 0);
                entryExists.valueFieldService += sumOfServices;
                entryExists.countService += newValue.markedServices.length;
            } else {
                let sumOfServices = newValue.markedServices.reduce((prevVal, currentVal) => {
                    return prevVal + getValueFromMarkedService(currentVal)
                }, 0);
                sumValue = [...sumValue, { argumentFieldService: notificationName, valueFieldService: sumOfServices, countService: newValue.markedServices.length }];
                AvgGroupUnits++;
            }
            return [...sumValue];
        }, [] as any[]);
        let resNormal = res.map(x => {
            return { argumentFieldService: x.argumentFieldService, valueFieldService: x.valueFieldService / x.countService, countService: x.countService };
        }).sort((a, b) => a.valueFieldService - b.valueFieldService);
        setQualityChecksAvg(res.length);


        setQualityCheckAvgRatingSimpleChartData(resNormal.slice(-maxChartItemAvg));
        setSimpleChartDataAvg(getAvgFromSimpleChart(resNormal.slice(-maxChartItemAvg)))

        setQualityCheckAvgRatingSimpleChartDataBad(resNormal.slice(0, maxChartItemAvg).reverse());
        setSimpleChartDataAvgBad(getAvgFromSimpleChart(resNormal.slice(0, maxChartItemAvg).reverse()))

        //percent

    }

    const buildContent = () => {
        return (
            <Paper className="paper-small" style={{ paddingTop: 40 }}>
                <Grid container spacing={3} item xs={12} direction="column">
                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"} fontWeight="bold">Qualitätsschnitt pro Einheit Gesamt Bericht</Typography>
                    <Grid item container direction="row" spacing={3} alignItems="center">

                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={t("serviceDocumentationTable.startDate")}
                                value={startDateQualityCheckAvgRatingSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateStartDateQualityCheckAvgMarkingSimpleChartData(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}

                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={t("serviceDocumentationTable.endDate")}
                                value={endDateQualityCheckAvgRatingSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateEndDateQualityChecksAvgMarkingSimpleChartData(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}

                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightQualityCheckAvgRatingSimple} setChartHeight={setChartHeightQualityCheckAvgRatingSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätsschnitt pro Einheit Absteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {QualityCheckAvgRatingSimpleChartData.length} Einheiten: <b>{simpleChartDataAvg}%</b></Typography>
                            </Grid>
                            <Grid item>
                                <StandardBarChartSimpleColor extraLabel="%" avgVal={simpleChartDataAvg} color={blue[600]} dataSource={QualityCheckAvgRatingSimpleChartData} name={"Qualitätsschnitt pro Einheit Gesamt (Gut)"} height={chartHeightQualityCheckAvgRatingSimple} />
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätsschnitt pro Einheit Aufsteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {QualityCheckAvgRatingSimpleChartData.length} Einheiten: <b>{simpleChartDataAvgBad}%</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StandardBarChartSimpleColor extraLabel="%" avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={QualityCheckAvgRatingSimpleChartDataBad} name={"Qualitätsschnitt pro Einheit Gesamt (Schlecht)"} height={chartHeightQualityCheckAvgRatingSimple} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>
        );
    }

    const buildPrintContent = () => {

        const titleDesc = `<b>Qualitätsschnitt pro Einheit Absteigend</b><br />${formatDateRange(startDateQualityCheckAvgRatingSimpleChartData, endDateQualityCheckAvgRatingSimpleChartData)}`;
        const titleAsc = `<b>Qualitätsschnitt pro Einheit Aufsteigend</b><br />${formatDateRange(startDateQualityCheckAvgRatingSimpleChartData, endDateQualityCheckAvgRatingSimpleChartData)}`

        return (
            <>
                <PrintButton title="Qualitätsschnitt pro Einheit Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightQualityCheckAvgRatingSimple} setChartHeight={setChartHeightQualityCheckAvgRatingSimple} display={false} />
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor extraLabel="%" avgVal={simpleChartDataAvg} color={blue[600]} dataSource={QualityCheckAvgRatingSimpleChartData} name={"Qualitätsschnitt pro Einheit Gesamt (Gut)"} height={chartHeightQualityCheckAvgRatingSimple} title={titleDesc} />
                    </div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor extraLabel="%" avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={QualityCheckAvgRatingSimpleChartDataBad} name={"Qualitätsschnitt pro Einheit Gesamt (Schlecht)"} height={chartHeightQualityCheckAvgRatingSimple} title={titleAsc} />
                    </div>
                </div>
            </>

        )



    }

    if (!qualityChecksTableData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )

});

















export const HitListChartsQualityChecksBestServices = React.forwardRef(({ qualityChecksTableData }: { qualityChecksTableData: ObjectLeaderQualityCheckModel[] }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateQualityCheckBestServicesSimpleChartData(date: Date) {
            updateStartDateQualityCheckBestServicesSimpleChartData(date);
        },
        updateEndDateQualityChecksBestServicesSimpleChartData(date: Date) {
            updateEndDateQualityChecksBestServicesSimpleChartData(date);
        }

    }))

    const { maxChartItemAvg }: { showStatus: boolean, setShowStatus: any, maxChartItemAvg: number } = useContext(GlobalContext);
    const history = useHistory();
    const queryString = useQuery(history);

    const [chartHeightQualityCheckAvgRatingSimple, setChartHeightQualityCheckAvgRatingSimple] = React.useState(500);
    const [QualityCheckAvgRatingSimpleChartData, setQualityCheckAvgRatingSimpleChartData] = React.useState<any[]>([]);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);


    const [QualityCheckAvgRatingSimpleChartDataBad, setQualityCheckAvgRatingSimpleChartDataBad] = React.useState<any[]>([]);
    const {t} = useTranslation();


    const [startDateQualityCheckAvgRatingSimpleChartData, setStartDateQualityCheckAvgRatingSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateQualityCheckAvgRatingSimpleChartData, setEndDateQualityCheckAvgRatingSimpleChartData] = React.useState(new Date());

    const [QualityChecksAvg, setQualityChecksAvg] = React.useState(0);


    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);

    React.useEffect(() => {
        updateQualityCheckAvgRatingSimpleChart(startDateQualityCheckAvgRatingSimpleChartData, endDateQualityCheckAvgRatingSimpleChartData, qualityChecksTableData)
    }, [maxChartItemAvg])

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (qualityChecksTableData) {
            setStartDateQualityCheckAvgRatingSimpleChartData(startDate);
            setEndDateQualityCheckAvgRatingSimpleChartData(endDate);
            updateQualityCheckAvgRatingSimpleChart(startDate, endDate, qualityChecksTableData);
        }
    }, [qualityChecksTableData])

    const updateStartDateQualityCheckBestServicesSimpleChartData = (newVal: Date) => {
        if (!qualityChecksTableData) return;
        setStartDateQualityCheckAvgRatingSimpleChartData(newVal);
        updateQualityCheckAvgRatingSimpleChart(newVal, endDateQualityCheckAvgRatingSimpleChartData, qualityChecksTableData);
    }

    const updateEndDateQualityChecksBestServicesSimpleChartData = (newVal: Date) => {
        if (!qualityChecksTableData) return;
        setEndDateQualityCheckAvgRatingSimpleChartData(newVal);
        updateQualityCheckAvgRatingSimpleChart(startDateQualityCheckAvgRatingSimpleChartData, newVal, qualityChecksTableData);
    }

    const updateQualityCheckAvgRatingSimpleChart = (startDate: Date, endDate: Date, qualityChecks: ObjectLeaderQualityCheckModel[]) => {


        let filteredqualityChecks = qualityChecks.filter(x => new Date(x.createdTimestamp) >= startDate && new Date(x.createdTimestamp) <= endDate);
        let AvgGroupUnits = 0;
        let res = filteredqualityChecks.reduce((sumValue, newValue: ObjectLeaderQualityCheckModel) => {
            for (let markedService of newValue.markedServices) {
                const notificationName = markedService.cleaningOperationText;
                let entryExists = sumValue.find(x => x.argumentFieldService == notificationName);
                if (entryExists) {
                    entryExists.valueFieldService += getValueFromMarkedService(markedService);
                    entryExists.countService += 1;
                } else {
                    sumValue = [...sumValue, { argumentFieldService: notificationName, valueFieldService: getValueFromMarkedService(markedService), countService: 1 }];
                    AvgGroupUnits++;
                }
            }

            return [...sumValue];
        }, [] as any[]);
        let resNormal = res.map(x => {
            return { argumentFieldService: x.argumentFieldService, valueFieldService: x.valueFieldService / x.countService, countService: x.countService };
        }).sort((a, b) => a.valueFieldService - b.valueFieldService);
        setQualityChecksAvg(res.length);


        setQualityCheckAvgRatingSimpleChartData(resNormal.slice(-maxChartItemAvg));
        setSimpleChartDataAvg(getAvgFromSimpleChart(resNormal.slice(-maxChartItemAvg)))

        setQualityCheckAvgRatingSimpleChartDataBad(resNormal.slice(0, maxChartItemAvg).reverse());
        setSimpleChartDataAvgBad(getAvgFromSimpleChart(resNormal.slice(0, maxChartItemAvg).reverse()))

        //percent

    }

    const buildContent = () => {
        return (
            <Paper className="paper-small" style={{ paddingTop: 40 }}>
                <Grid container spacing={3} item xs={12} direction="column">
                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"} fontWeight="bold">Qualitätsschnitt pro Service Gesamt Bericht</Typography>
                    <Grid item container direction="row" spacing={3} alignItems="center">

                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={t("serviceDocumentationTable.startDate")}
                                value={startDateQualityCheckAvgRatingSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateStartDateQualityCheckBestServicesSimpleChartData(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}

                            />
                        </Grid>
                        <Grid item>
                            < DateTimePicker
                                disableFuture
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={t("serviceDocumentationTable.endDate")}
                                value={endDateQualityCheckAvgRatingSimpleChartData}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    updateEndDateQualityChecksBestServicesSimpleChartData(newVal);
                                }}
                                format={"dd.MM.yyyy HH:mm"}

                            />
                        </Grid>
                        <Grid item>
                            <ChartHeightTextBox chartHeight={chartHeightQualityCheckAvgRatingSimple} setChartHeight={setChartHeightQualityCheckAvgRatingSimple} />
                        </Grid>
                        <MaxChartItemCountInput />
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätsschnitt pro Service Absteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {QualityCheckAvgRatingSimpleChartData.length} Einheiten: <b>{simpleChartDataAvg}%</b></Typography>
                            </Grid>
                            <Grid item>
                                <StandardBarChartSimpleColor extraLabel="%" avgVal={simpleChartDataAvg} color={blue[600]} dataSource={QualityCheckAvgRatingSimpleChartData} name={"Qualitätsschnitt pro Service Gesamt (Gut)"} height={chartHeightQualityCheckAvgRatingSimple} />
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={3} item xs={6}>
                            <Grid item>
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Qualitätsschnitt pro Service Aufsteigend</Typography>
                                <Typography className="avg-font" variant="h5">Durchschnitt von {QualityCheckAvgRatingSimpleChartData.length} Einheiten: <b>{simpleChartDataAvgBad}%</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StandardBarChartSimpleColor extraLabel="%" avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={QualityCheckAvgRatingSimpleChartDataBad} name={"Qualitätsschnitt pro Service Gesamt (Schlecht)"} height={chartHeightQualityCheckAvgRatingSimple} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>
        );
    }

    const buildPrintContent = () => {

        const titleDesc = `<b>Qualitätsschnitt pro Service Absteigend</b><br />${formatDateRange(startDateQualityCheckAvgRatingSimpleChartData, endDateQualityCheckAvgRatingSimpleChartData)}`;
        const titleAsc = `<b>Qualitätsschnitt pro Service Aufsteigend</b><br />${formatDateRange(startDateQualityCheckAvgRatingSimpleChartData, endDateQualityCheckAvgRatingSimpleChartData)}`

        return (
            <>
                <PrintButton title="Qualitätsschnitt pro Service Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightQualityCheckAvgRatingSimple} setChartHeight={setChartHeightQualityCheckAvgRatingSimple} display={false} />
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor extraLabel="%" avgVal={simpleChartDataAvg} color={blue[600]} dataSource={QualityCheckAvgRatingSimpleChartData} name={"Qualitätsschnitt pro Service Gesamt (Gut)"} height={chartHeightQualityCheckAvgRatingSimple} title={titleDesc} />
                    </div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor extraLabel="%" avgVal={simpleChartDataAvgBad} color={red[600]} dataSource={QualityCheckAvgRatingSimpleChartDataBad} name={"Qualitätsschnitt pro Service Gesamt (Schlecht)"} height={chartHeightQualityCheckAvgRatingSimple} title={titleAsc} />
                    </div>
                </div>
            </>

        )



    }

    if (!qualityChecksTableData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )

});