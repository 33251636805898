import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, TableSummaryRow, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { Column, FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, IntegratedSummary, PagingState, SelectionState, SortingState, SummaryState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { MarkedItemThing, MarkedMaterial, MarkedService, ObjectLeaderQualityCheckModel } from '../../../../models/ObjectLeaderQualityCheckModel';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { FIVE_SMILE, GREEN_LIGHT_SMILE, GREEN_SMILE, RED_LIGHT_SMILE, RED_SMILE, YELLOW_SMILE } from '../../../../assets';
import { formatEuropeanDateTime } from '../../../../helpers/date-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Group, GroupType } from '../../../../models/Group';
import { mapToiletTypeKey } from '../../../tables/Base';
import { IncidentImagesDialog } from '../../../authorized/portal/Dashboard/serviceMode/IncidentImagesDialog';
import { GroupServiceModeIncidentPhoto } from '../../../../models/GroupServiceMode';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, IconButton, ListItem, ListItemButton, TextField, Typography } from '@mui/material';
import { Camera, CameraAlt, Chair, Mail, PhotoAlbum, PhotoAlbumRounded, Smartphone } from '@mui/icons-material';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { StringHelper } from '../../../../helpers/string-helper';
import { useStyles } from '../../../../styles/container';
import { Button, Grid as MuiGrid, List, ListItemIcon, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { QualityCheckImagesDialog } from '../../../authorized/portal/Dashboard/serviceMode/QualityCheckImagesDialog';
import { getDefaultIcon } from '../../DefaultItemsDialogOld';
import { ObjectUnit, QMRatingType, QMSettings } from '../../../../models/ObjectUnit';
import { ItemThing, ItemThingType } from '../../../../models/GroupSettings';
import { useTranslation } from 'react-i18next';



export const ObjectLeaderQualityCheckTableErrorPoints = ({ qualityChecks, userServiceWorkers, groups, objectUnits }
    : { qualityChecks: ObjectLeaderQualityCheckModel[], userServiceWorkers: UserServiceWorker[], groups: GroupLicenseTable[], objectUnits: ObjectUnit[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            if (userServiceWorkers && userServiceWorkers.length > 0) {
                saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Qualitätssicherung-${userServiceWorkers.map(x => x.name).join()}-`);
            } else {
                saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Qualitätssicherung-${t("homeDrawerFilter.units")}`);
            }
        });
    };
    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [totalSummaryItems, setTotalSummaryItems] = React.useState<any[]>([]);
    let [columns, setColumns] = React.useState<Column[]>([]);
    let [columnWidths, setColumnWidths] = React.useState<TableColumnWidthInfo[]>([]);
    const {t} = useTranslation();


    React.useEffect(() => {

        let columns = [
            {
                name: ObjectLeaderQualityCheckModel.Columns.groupId,
                title: t("serviceDocumentationTable.unitName"),
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.notificationName,
            },
            {
                name: GroupLicenseTable.Columns.groupType,
                title: t("myUnitsSettings.unitType"),
                getCellValue: (row: ObjectLeaderQualityCheckModel) => Group.GetLabelByType(groups.find(x => x.id == row.groupId)?.groupType || GroupType.DisplayWallApp),
            },
            {
                name: GroupLicenseTable.Columns.objectName,
                title: t("myUnitsSettings.objectName"),
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.objectName,
            },
            {
                name: GroupLicenseTable.Columns.levelName,
                title: t("myUnitsSettings.level"),
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.levelName,
            },
            {
                name: GroupLicenseTable.Columns.roomName,
                title: t("myUnitsSettings.customer"),
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.roomName,
            },
            {
                name: GroupLicenseTable.Columns.roomSize,
                title: 'Raumgröße',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => groups.find(x => x.id == row.groupId)?.roomSize,
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.userServiceWorkerId,
                title: t("myObjectSettings.serviceStaff"),
                getCellValue: (row: ObjectLeaderQualityCheckModel) => userServiceWorkers.find(x => x.id == row.userServiceWorkerId)?.name || row.userServiceWorkerId,
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.markedItemThings,
                title: 'Gegenstände',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                    return row?.markedItemThings && row?.markedItemThings?.map(x => `${x.text}`).join(' | ')
                },
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponentGrade,
                title: 'Gesamt Niveau',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => row.markedItemThings.reduce((prevVal, curVal) => {
                    let main = getMark(row, ItemThingType.MainUsageComponent);
                    let re = getMark(row, ItemThingType.RestInventory);
                    let wa = getMark(row, ItemThingType['Walls&Covers']);
                    let fl = getMark(row, ItemThingType.Floor);
                    let hst = getMark(row, ItemThingType.HardToSeeThings);
                    let avg = Math.min(main, re, wa, fl, hst);
                    return avg;
                }, 0),
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponent,
                title: 'Gesamt Fehlerpunkte',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => row.markedItemThings.reduce((prevVal, curVal) => {
                    return prevVal + curVal.errorCount;
                }, 0),
            },

            {
                name: ObjectLeaderQualityCheckModel.Columns.mainUsageComponent,
                title: "HK - Fehlerpunkte",
                getCellValue: (row: ObjectLeaderQualityCheckModel) => row.markedItemThings.reduce((prevVal, curVal) => {
                    return curVal.itemThingType == ItemThingType.MainUsageComponent ? prevVal + curVal.errorCount : prevVal;
                }, 0),
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.mainUsageComponentGrade,
                title: `HK Niveau`,
                getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                    return getMark(row, ItemThingType.MainUsageComponent);
                },
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.restInventory,
                title: "RI - Fehlerpunkte",
                getCellValue: (row: ObjectLeaderQualityCheckModel) => row.markedItemThings.reduce((prevVal, curVal) => {
                    return curVal.itemThingType == ItemThingType.RestInventory ? prevVal + curVal.errorCount : prevVal;
                }, 0),
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.restInventoryGrade,
                title: `RI Niveau`,
                getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                    return getMark(row, ItemThingType.RestInventory);
                },
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.wallsAndCovers,
                title: "W/D - Fehlerpunkte",
                getCellValue: (row: ObjectLeaderQualityCheckModel) => row.markedItemThings.reduce((prevVal, curVal) => {
                    return curVal.itemThingType == ItemThingType['Walls&Covers'] ? prevVal + curVal.errorCount : prevVal;
                }, 0),
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.wallsAndCoversGrade,
                title: `W/D Niveau`,
                getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                    return getMark(row, ItemThingType['Walls&Covers']);
                },
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.floor,
                title: "B - Fehlerpunkte",
                getCellValue: (row: ObjectLeaderQualityCheckModel) => row.markedItemThings.reduce((prevVal, curVal) => {
                    return curVal.itemThingType == ItemThingType.Floor ? prevVal + curVal.errorCount : prevVal;
                }, 0),
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.floorGrade,
                title: `B Niveau`,
                getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                    return getMark(row, ItemThingType.Floor);
                },
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.hardToSeeThings,
                title: "SEB - Fehlerpunkte",
                getCellValue: (row: ObjectLeaderQualityCheckModel) => row.markedItemThings.reduce((prevVal, curVal) => {
                    return curVal.itemThingType == ItemThingType.HardToSeeThings ? prevVal + curVal.errorCount : prevVal;
                }, 0),
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.hardToSeeThingsGrade,
                title: `SEB Niveau`,
                getCellValue: (row: ObjectLeaderQualityCheckModel) => {
                    return getMark(row, ItemThingType.HardToSeeThings);
                },
            },
            {
                name: ObjectLeaderQualityCheckModel.Columns.createdTimestamp,
                title: 'Erstellt am',
                getCellValue: (row: ObjectLeaderQualityCheckModel) => formatEuropeanDateTime(row.createdTimestamp),
            },


        ];

        setTotalSummaryItems([


            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponent, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponent, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponent, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponent, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponentGrade, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponentGrade, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponentGrade, type: 'avg' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponent, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponent, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponent, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponent, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponentGrade, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponentGrade, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponentGrade, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponentGrade, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventory, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventory, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventory, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventory, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventoryGrade, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventoryGrade, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventoryGrade, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventoryGrade, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCovers, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCovers, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCovers, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCovers, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCoversGrade, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCoversGrade, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCoversGrade, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCoversGrade, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.floor, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.floor, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.floor, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.floor, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.floorGrade, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.floorGrade, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.floorGrade, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.floorGrade, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThings, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThings, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThings, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThings, type: 'sum' },

            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThingsGrade, type: 'count' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThingsGrade, type: 'max' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThingsGrade, type: 'avg' },
            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThingsGrade, type: 'sum' },


        ]);
        setColumns(columns);
        setColumnWidths([
            { columnName: ObjectLeaderQualityCheckModel.Columns.groupId, width: 160 },
            { columnName: GroupLicenseTable.Columns.objectName, width: 130 },
            { columnName: GroupLicenseTable.Columns.groupType, width: 180 },
            { columnName: GroupLicenseTable.Columns.levelName, width: 130 },
            { columnName: GroupLicenseTable.Columns.roomName, width: 130 },
            { columnName: GroupLicenseTable.Columns.roomSize, width: 130 },
            { columnName: GroupLicenseTable.Columns.toiletType, width: 130 },
            { columnName: GroupLicenseTable.Columns.userPropertyName, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.incidents, width: 400 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.incidentPhotos, width: 300 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.markedMaterials, width: 400 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.markedServices, width: 450 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.markedItemThings, width: 450 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.createdTimestamp, width: 150 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.userServiceWorkerId, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.qmRatingType, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponentGrade, width: 200 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.overAllUsageComponent, width: 200 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponent, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.mainUsageComponentGrade, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventory, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.restInventoryGrade, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCovers, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.wallsAndCoversGrade, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.floor, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.floorGrade, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThings, width: 160 },
            { columnName: ObjectLeaderQualityCheckModel.Columns.hardToSeeThingsGrade, width: 160 },

        ])

    }, [qualityChecks])

    const AccordionForStringArrayMarkedItemThings = ({ props, itemThings, title, listItemIcon = null, row }: { props: any, itemThings: MarkedItemThing[], title: any, listItemIcon?: any, row: ObjectLeaderQualityCheckModel }) => {
        const classes = useStyles();
        const {t} = useTranslation();

        const getIcon = (index: number) => {
            if (listItemIcon && listItemIcon[index]) {
                return listItemIcon[index];
            }
            return (
                <Smartphone />
            )
        }

        return (
            <Table.Cell {...props}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <MuiGrid container direction="column">
                            <MuiGrid item>
                                <Typography className={classes.heading}>{title}</Typography>
                            </MuiGrid>

                        </MuiGrid>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 0, padding: 0 }}>
                        <List style={{ width: '100%' }} component="nav" aria-label="main mailbox folders">
                            {itemThings && itemThings.length > 0 && itemThings.map((el, index) => {
                                return (
                                    <ListItem style={{ width: '100%' }} key={index}>
                                        <Button

                                            onClick={async () => {
                                                setGroupId(row.groupId);
                                                setQualityCheckPhotos(el.qualityCheckPhoto);
                                                setIsQualityCheckImagesDialogVisible(true);
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disabled={el.qualityCheckPhoto && el.qualityCheckPhoto.length < 1}
                                            size="small"
                                            endIcon={<CameraAlt />}
                                        >
                                            {el.qualityCheckPhoto ? `${el.qualityCheckPhoto.length} Foto` : "Keine"}
                                        </Button>

                                        <ListItemText style={{ marginLeft: 10 }} secondary={`${ItemThing.getItemThingTypeText(el.itemThingType,t)}`} primary={`${el.text} | Fehler: ${el.errorCount}`} />
                                        <ListItemIcon>
                                            {getIcon(index)}
                                        </ListItemIcon>

                                    </ListItem>
                                )
                            })}

                        </List>
                    </AccordionDetails>
                </Accordion>

            </Table.Cell>
        );
    }

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: ObjectLeaderQualityCheckModel, value: any } = props;
        if (column.name == ObjectLeaderQualityCheckModel.Columns.overAllUsageComponent || column.name == ObjectLeaderQualityCheckModel.Columns.overAllUsageComponentGrade) {
            return (
                <Table.Cell {...props} >
                    <Typography style={{ fontWeight: 'bold' }}>{value}</Typography>
                </Table.Cell>
            )
        }
        /*       if (column.name == ObjectLeaderQualityCheckModel.Columns.mainUsageComponent) {
                   let items = row.markedItemThings.filter(x=>x.itemThingType == ItemThingType.MainUsageComponent).map(y=>y.text);
                   return buildCellStringArray(props, items, `Gesamt ${props.value} Fehlerpunkte`, null, null);
               } */
        if (column.name == ObjectLeaderQualityCheckModel.Columns.markedItemThings && row.markedItemThings) {
            let servicesIcon = row.markedItemThings.map((x: MarkedItemThing) => <Chair />);
            let countPhotos = row.markedItemThings.reduce((prevVal, curVal) => {
                return prevVal + curVal.qualityCheckPhoto.length;
            }, 0)
            return <AccordionForStringArrayMarkedItemThings props={props} itemThings={row.markedItemThings} title={`${t("myObjectSettings.contains")} ${row.markedItemThings.length} Gegenstände und ${countPhotos} Fotos`} listItemIcon={servicesIcon} row={row}></AccordionForStringArrayMarkedItemThings>

        }
        return <Table.Cell {...props} />;
    };


    const getMark = (row: ObjectLeaderQualityCheckModel, itemThingType: ItemThingType) => {
        let group = groups.find(x => row.groupId == x.id);
        let objectUnit = objectUnits.find(x => x.id == group?.objectUnitId);
        if (objectUnit && group) {
            let marksystem = objectUnit.qmSettings.qmGradeErrorPointsSettings.find(settings => group && settings.minAreaSize <= group.roomSize && settings.maxAreaSize >= group.roomSize && settings.qmGradeItemThing.some(qmGrade => qmGrade.type == itemThingType));
            let mark = marksystem?.qmGradeItemThing.find(x => x.type == itemThingType);
            if (mark) {
                let errorPoints = row.markedItemThings.reduce((prevVal, curVal) => {
                    return curVal.itemThingType == itemThingType ? prevVal + curVal.errorCount : prevVal;
                }, 0);
                if (mark.grade5 >= errorPoints) {
                    return 5;
                } else if (mark.grade4 >= errorPoints) {
                    return 4;
                } else if (mark.grade3 >= errorPoints) {
                    return 3;
                } else if (mark.grade2 >= errorPoints) {
                    return 2;
                } else {
                    return 1;
                }
            }
        }
        return 100;
    }


    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [isIncidentImagesDialogVisible, setIsIncidentImagesDialogVisible] = React.useState(false);
    const [incidentPhotos, setIncidentPhotos] = React.useState<GroupServiceModeIncidentPhoto[]>([]);
    const [groupId, setGroupId] = React.useState("");

    const [isQualityCheckImagesDialogVisible, setIsQualityCheckImagesDialogVisible] = React.useState(false);
    const [qualityCheckPhotos, setQualityCheckPhotos] = React.useState<GroupServiceModeIncidentPhoto[]>([]);

    const [selection, setSelection] = React.useState<string[]>([]);
    const [mail, setMail] = React.useState<string>("");
    const [loadingMail, setLoadingMail] = React.useState(false);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    return (
        <Paper>
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <TextField
                    id="email"
                    fullWidth
                    label="Email Addresse"
                    margin="dense"
                    name="email"
                    required={true}
                    disabled={loadingMail}
                    size={'medium'}
                    variant="outlined"
                    type="email"
                    value={mail || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMail(event.target.value);
                    }}
                />
                <Button onClick={async () => {
                    setLoadingMail(true);
                    const tokenTemp = getApiToken(history);
                    await api.sendSelectedQualityChecksToMail(tokenTemp, selection, mail, enqueueSnackbar);
                    setLoadingMail(false);
                }}
                    disabled={selection.length < 1 || loadingMail || !StringHelper.IsValidEmail(mail)}
                    style={{ marginLeft: 20 }}
                    startIcon={loadingMail && <CircularProgress size={24} />}
                    color="primary" endIcon={<Mail />} variant={"contained"} size={"small"}>
                    {selection.length} ausgewählte Qualitätsicherungen als Email versenden
                </Button>
            </div>
            <IncidentImagesDialog groupId={groupId} visible={isIncidentImagesDialogVisible} setVisible={setIsIncidentImagesDialogVisible} incidentPhotos={incidentPhotos} />
            <QualityCheckImagesDialog groupId={groupId} visible={isQualityCheckImagesDialogVisible} setVisible={setIsQualityCheckImagesDialogVisible} incidentPhotos={qualityCheckPhotos} />
            <Grid
                rows={qualityChecks}
                columns={columns}
                getRowId={(row: ObjectLeaderQualityCheckModel) => row.id}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection as any}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <IntegratedSummary />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                />
                <TableHeaderRow showSortingControls />
                <TableSummaryRow />
                <TableSelection showSelectAll />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={qualityChecks}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};