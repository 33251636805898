import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserAddress } from '../models/UserAddress';
import { UserProperty } from '../models/UserProperty';

export const createUserProperty = async (property: UserProperty | undefined, token: string, enqueueSnackbar: any): Promise<UserProperty> => {
    try {
        if (!property) throw (null);
        let userId = getUserIdFromStorage();
        property.userId = userId || "";
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/create-property`,
            { ...property },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Merkmal Speichern erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Merkmal Speichern error", { variant: 'error' });
    }
    return null as any;
}