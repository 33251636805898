import Dialog from '@mui/material/Dialog';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { getApiToken, setObjectStorageForDrawer, setObjectStorageForMultipleDrawer } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { GlobalContext } from '../../authorized/PortalPage';
import { ObjectSelectionDataTable } from '../../authorized/extendedapp/entireEval/ObjectSelectionDataTable';
import { useTranslation } from 'react-i18next';

export const ObjectSelectionEvaluationDialog = ({
  open,
  setOpen,
  isMultiple = false
}: {
  open: boolean,
  setOpen: any,
  isMultiple?: boolean
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
  const { selectedObjectId, setSelectedObjectId, setSelectedObjectName, setSelectedCombinationObject, selectedCombinationObject }: any = useContext(GlobalContext);
  const [selectedObject, setSelectedObject] = React.useState<ObjectUnit | undefined>(undefined);

  const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);

  const handleClose = () => setOpen(false);
  const {t} = useTranslation();

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const token = getApiToken(history);
      if (!token) return;

      const tempGroups = await api.getAllGroups(token);
      setGroups(tempGroups);

      const tempObjects = await api.getUserObjectUnits(token);
      setObjects(tempObjects);


      if (isMultiple){
        setSelectedObjects(tempObjects.filter(x => selectedCombinationObject.some((y:{id: string})=>y.id == x.id)));
      }else{
        setSelectedObject(tempObjects.filter(x => x.id === selectedObjectId)[0]);

      }

    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={false}
    >
      <DefaultDialogTitle
        title={`Wähle Objekt für ${isMultiple ? "Mehrfachauswertung" : "Einzelauswertung"}`}
        handleClose={handleClose}
      />

      <DialogContent>
        <ObjectSelectionDataTable rows={objects} groups={groups} currentGroup={null}
          selectedObjects={
            isMultiple ? selectedObjects :
              selectedObject ? [selectedObject] : []
          }
          onSelectionChange={(selection: string[]) => {
            if (isMultiple){
              setSelectedObjects(objects.filter(x => selection.includes(x.id)));
            }else{
              let selected = selection[selection.length - 1];
              setSelectedObject(objects.filter(x => selected === x.id)[0]);
            }

          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<TouchApp />}
          disabled={isMultiple ? selectedObjects.length < 1 : !selectedObject}
          onClick={() => {

            if (isMultiple){
              
              let saveToStorageItems = selectedObjects.map(x=> {return {id: x.id}});
              setObjectStorageForMultipleDrawer(saveToStorageItems);
              console.log("machu", saveToStorageItems);
              setSelectedCombinationObject(saveToStorageItems);
              history.push(`/portal/object/multiple/sum?`);
              handleClose();
            }else{

              if (!selectedObject) return;
              
              setSelectedObjectId(selectedObject.id);
              setSelectedObjectName(selectedObject.name);
              setObjectStorageForDrawer(selectedObject);
  
              history.push(`/portal/object/${selectedObject.id}/sum`);
              handleClose();
            }

          }}
        >
          Objekt auswählen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));