import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Checkbox, CircularProgress, Collapse, Container, DialogActions, DialogContent, Divider, FormControlLabel, Grid, IconButton, ImageListItem, ImageListItemBar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Switch, Theme, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, AddBox, Apartment, Backspace, Cancel, ChangeCircle, Close, CloudCircle, CloudRounded, Delete, DeleteForever, Download, DriveFileRenameOutline, ExpandLess, ExpandMore, FileCopy, Folder, FolderOpen, FolderShared, FolderSharedOutlined, Image, InsertDriveFile, NavigateNext, NextPlan, PictureAsPdf, Preview, Remove, Save, SelectAll, Share, TouchApp, Visibility } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { getApiToken } from '../../../../helpers/storage-management';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { DataFileTree, DataFileTreeType, DATA_FILE_TREE, getExtension, ObjectFile, ObjectRenameFile, ObjectSharedFolderTable } from '../../../../models/DataFileTree';
import { AddFolderDialog } from './AddFolderDialog';
import { AddFileDialog } from './AddFileDialog';
import { api } from '../../../../api';
import { formatEuropeanDateTime } from '../../../../helpers/date-management';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { lightgrey, white } from '../../../../styles/colors';
import { AddSharedFolderDialog } from './AddSharedFolder';
import { AssignObjectToSharedFolderDialog } from './AssignObjectToSharedFolderDialog';
import { ObjectSharedFolderSelectionDialog } from './ObjectSharedFolderSelectionDialog';
import { AssignObjectSharedFolderSelectionDialog } from './AssignObjectSharedFolderSelectionDialog';
import { RenameFileDialog } from './RenameFileDialog';
import { useTranslation } from 'react-i18next';

export const ObjectFolderDialog = ({
  open,
  setOpen,
  currentObject,
  objects,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  currentObject: ObjectUnit,
  objects: ObjectUnit[]
  onRefresh: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = React.useState<DataFileTree[]>([]);
  const [isAddFolderDialogVisible, setIsAddFolderDialogVisible] = React.useState(false);
  const [isRenameFileDialogVisible, setIsRenameFileDialogVisible] = React.useState(false);


  const [isAddFileDialogVisible, setIsAddFileDialogVisible] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [addNewFolderName, setAddNewFolderName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);

  const [images, setImages] = React.useState<any[]>([]);

  const [numPages, setNumPages] = React.useState<any>(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const [sharedStructures, setSharedStructures] = React.useState<{ dataFileTree: DataFileTree[], objectSharedFolder: ObjectSharedFolderTable, selectedItems: DataFileTree[] }[]>();

  //shared
  const [isRenameSharedFileDialogVisible, setIsRenameSharedFileDialogVisible] = React.useState(false);
  const [selectedSharedItems, setSelectedSharedItems] = React.useState<DataFileTree[]>([]);
  const [selectedSharedObjectFolder, setSelectedSharedObjectFolder] = React.useState<ObjectSharedFolderTable>();
  const [selectedSharedObjectFolders, setSelectedSharedObjectFolders] = React.useState<ObjectSharedFolderTable[]>([]);
  const [isAddSharedFileDialogVisible, setIsAddSharedFileDialogVisible] = React.useState(false);
  const [addNewSharedFolderName, setAddNewSharedFolderName] = React.useState("");
  const [isAddSharedFolderDialogVisible, setIsAddSharedFolderDialogVisible] = React.useState(false);

  const [isAddObjectSharedFolderDialogVisible, setIsAddObjectSharedFolderDialogVisible] = React.useState(false);
  const [isAssignObjectToSharedFolderDialogVisible, setIsAssignObjectToSharedFolderDialogVisible] = React.useState(false);

  const [isObjectSharedFoldersTableDialogVisible, setIsObjectSharedFoldersTableDialogVisible] = React.useState(false);
  const [isObjectSharedFoldersTableAssignSelectionDialogVisible, setIsObjectSharedFoldersTableAssignSelectionDialogVisible] = React.useState(false);

  const [selectedSharedObjectFolderForAssign, setSelectedSharedObjectFolderForAssign] = React.useState<ObjectSharedFolderTable[]>([]);
  const [isAssignSharedFoldersToObjectDialogVisible, setIsAssignSharedFoldersToObjectDialogVisible] = React.useState(false);
  const [deleteSharedFileConfirmationDialogVisible, setDeleteSharedFileConfirmationDialogVisible] = React.useState(false);



  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  }


  const handleClose = () => {
    setOpen(false);
  }

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;

      await loadData(tempToken);

      setToken(tempToken);

    })();

  }, [open]);

  const organizeTreeData = (files: ObjectFile[], fileTree: DataFileTree[], isShared: boolean = false, objectFolderId: string) => {
    files.map(curFile => {
      let firstLevelFolder = fileTree.find(x => x.name == curFile.folders[0]);
      if (!firstLevelFolder) {
        firstLevelFolder = new DataFileTree(curFile.folders[0] + "/", curFile.folders[0], DataFileTreeType.Folder, [], undefined, "", "", isShared, objectFolderId);
        fileTree.push(firstLevelFolder);
      }

      let diveToFolder = firstLevelFolder;
      let currentFullPath = curFile.folders[0] + "/";
      let currentFolder: DataFileTree;
      curFile.folders.forEach((foldername, index) => {
        if (index != 0) {
          currentFullPath += foldername + "/";
          let folderExists = diveToFolder?.content?.find(x => x.fullPath == currentFullPath);
          console.log(currentFullPath, folderExists, "exists");
          if (!folderExists) {
            currentFolder = new DataFileTree(currentFullPath, foldername, DataFileTreeType.Folder, [], undefined, "", "", isShared, objectFolderId)
            diveToFolder?.content?.push(currentFolder);
            diveToFolder = currentFolder;
          } else if (folderExists) {
            diveToFolder = folderExists;
          }
        }
      });

      diveToFolder.content?.push(new DataFileTree(currentFullPath, curFile.filename, DataFileTreeType.File, null, DataFileTree.getSizeReadable(parseInt(curFile.contentLength)), formatEuropeanDateTime(new Date(curFile.createdTimestamp)), curFile.contentType, isShared, objectFolderId))


    });
    setLoading(false);
    return [...fileTree];
  }

  const loadData = async (tempToken: any) => {
    setLoading(true);
    let files = await api.listObjectFiles(currentObject.id, tempToken, enqueueSnackbar);
    let newFileTree = organizeTreeData(files, [], false, currentObject.id);
    setFileTree([...newFileTree]);
    setSelectedItems([]);
    setLoading(false);
  }

  const loadSharedData = async (tempToken: any, selectedRows: ObjectSharedFolderTable[]) => {
    setLoading(true);
    const structures = selectedRows.map(async (x) => {
      var fileTreeLocal = await api.listObjectSharedFiles(x.objectCreatorId, x.id, token, enqueueSnackbar);
      let newFileTree: DataFileTree[] = [];
      newFileTree = organizeTreeData(fileTreeLocal, newFileTree, true, x.id);
      return { objectSharedFolder: x, dataFileTree: [...newFileTree], selectedItems: [] as DataFileTree[] }
    });
    setSharedStructures(await Promise.all(structures));
  }


  const [fileTree, setFileTree] = React.useState(DATA_FILE_TREE);

  const expandItem = (x: DataFileTree) => {
    x.expanded = !x.expanded;
    setFileTree([...fileTree]);
  }

  const selectItem = (x: DataFileTree) => {
    if (!x.selected) {
      selectedItems.push(x);
    } else {
      setSelectedItems(selectedItems.filter(y => !(x.fullPath == y.fullPath && x.name == y.name)));
    }
    x.selected = !x.selected;
    setFileTree([...fileTree]);
  }

  const selectSharedItem = (x: DataFileTree, objectFolderId: string) => {
    let structure = sharedStructures?.find(x => x.objectSharedFolder.id == objectFolderId);
    if (!structure) return;
    if (!x.selected) {
      structure?.selectedItems.push(x);
    } else {
      structure.selectedItems = structure.selectedItems.filter(y => !(x.fullPath == y.fullPath && x.name == y.name));
    }
    x.selected = !x.selected;
    setSharedStructures(sharedStructures?.map(x => x));
  }

  const getDataFileIcon = (x: DataFileTree) => {
    return x.isShared ? <FolderShared /> : <Folder />
  }

  const getDataFileIconOpened = (x: DataFileTree) => {
    return x.isShared ? <FolderSharedOutlined /> : <FolderOpen />
  }

  const mapFileIcon = (type: string) => {
    switch (type) {
      case "png":
      case "jpg":
      case "jpeg":
        return <Image color='primary' />
      case "pdf":
        return <PictureAsPdf color='error' />
      default:
        return <InsertDriveFile />
    }
  }

  const buildFileList = (fileTree: DataFileTree[], index = 0, objectSharedFolderId: string) => {

    const executeFun = (x: DataFileTree) => {
      if (x.isShared) {
        selectSharedItem(x, objectSharedFolderId);
      } else {
        selectItem(x);
      }
    }

    return (
      fileTree.map(x => {
        return (
          <>
            {x.type == DataFileTreeType.File &&
              <ListItem style={{ backgroundColor: x.selected ? lightgrey : white }}>
                <FormControlLabel
                  control={<Checkbox
                    checked={x.selected}
                    onChange={(event: any) => {
                      executeFun(x)
                    }}
                    name="isEnabled"
                    color="primary" />}
                  label={undefined}
                />
                <ListItemButton onClick={() => executeFun(x)}>

                  <div style={{ position: 'absolute', left: 600 }}>
                    <Typography>{x.creationTimestamp}</Typography>
                  </div>
                  <div style={{ position: 'absolute', left: 800 }}>
                    <Typography>{x.size}</Typography>
                  </div>
                  <ListItemIcon style={{ marginLeft: 20 * index }}>
                    {mapFileIcon(getExtension(x.name || "") || "")}
                  </ListItemIcon>
                  <Grid container direction={"row"} alignItems="center">
                    <Grid item xs={3}>
                      <Typography>{x.name}</Typography>
                    </Grid>
                  </Grid>
                </ListItemButton>
              </ListItem>
            }
            {(x.type == DataFileTreeType.Folder) &&
              <>
                <ListItem>
                  <FormControlLabel
                    control={<Checkbox
                      checked={x.selected}
                      onChange={(event: any) => { executeFun(x) }}
                      name="isEnabled"
                      color="primary" />}
                    label={undefined}
                  />
                  <ListItemButton onClick={() => expandItem(x)}>
                    <div style={{ position: 'absolute', left: 600 }}>
                      <Typography>{x.creationTimestamp}</Typography>
                    </div>
                    <ListItemIcon style={{ marginLeft: 20 * index }}>
                      {x.expanded ? getDataFileIconOpened(x) : getDataFileIcon(x)}
                    </ListItemIcon>
                    <Grid container direction={"row"} alignItems="center">
                      <Grid item xs={3}>
                        <Typography >{x.name}</Typography>
                      </Grid>
                    </Grid>
                    {x.expanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>

                <Collapse in={x.expanded} timeout="auto" unmountOnExit>
                  <List sx={{ width: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader">
                    {buildFileList(x.content || [], index + 1, x.objectFolderId)}
                  </List>
                </Collapse>
              </>
            }
          </>
        )
      })
    )
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen
    >
      <DefaultDialogTitle
        title={<React.Fragment>{t("shared-folders-objects-all.objectFolder")}: {currentObject.name}</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent >
        <Grid container direction={"row"} justifyContent="center" style={{ marginTop: 15 }}>
          <Grid item lg={7}>
            <Container maxWidth="xl">
              <Paper elevation={5} style={{ padding: 30, borderRadius: 0 }}>
                <Grid container direction={"column"} spacing={3} >
                  <Grid item>
                    <Typography variant='h4' fontWeight={"bold"} color="primary">Geteilte Objektmappe</Typography>
                  </Grid>
                  <Grid item container direction={"row"} spacing={2}>
                    <Grid item lg={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        endIcon={<FolderShared />}
                        startIcon={<Visibility />}
                        onClick={async () => {
                          setIsObjectSharedFoldersTableDialogVisible(true);
                        }}
                      >
                        Geteilte Ordner des Objekts hier anzeigen
                      </Button>

                    </Grid>
                    <Grid item lg={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={<FolderShared />}
                        endIcon={<NavigateNext />}
                        onClick={async () => {
                          history.push(`/portal/object/all/shared-folders`);
                        }}
                      >
                        Navigiere zum Geteilten Ordner Management
                      </Button>

                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant='h4' fontWeight={"bold"} color="primary">Objektmappe - Optionen</Typography>
                  </Grid>
                  <Grid item container direction={"row"} spacing={2}>
                    <Grid item lg={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        endIcon={<Folder />}
                        startIcon={<Add />}
                        disabled={selectedItems.filter(x => x.type == DataFileTreeType.Folder).length > 1 || selectedItems.some(x => x.type == DataFileTreeType.File)}
                        //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                        onClick={async () => {
                          setIsAddFolderDialogVisible(true);
                        }}
                      >
                        Ordner hinzufügen
                      </Button>

                    </Grid>
                    <Grid item lg={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={selectedItems.filter(x => x.type == DataFileTreeType.Folder).length != 1 || selectedItems.some(x => x.type == DataFileTreeType.File)}
                        endIcon={<InsertDriveFile />}
                        startIcon={<Add />}
                        //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                        onClick={async () => {
                          setIsAddFileDialogVisible(true);
                        }}
                      >
                        {t("shared-folders-objects-all.addFile")}
                      </Button>

                    </Grid>
                    <Grid item lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={selectedItems.filter(x => x.type == DataFileTreeType.File).length < 1 || selectedItems.some(x => x.type == DataFileTreeType.Folder)}
                        endIcon={<Download />}
                        startIcon={<CloudRounded />}
                        //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                        onClick={async () => {
                          setLoading(true);
                          let requests = selectedItems.map(async (item) => {
                            return await api.DownloadObjectFileAsync(currentObject.id, item.fullPath + item.name, token, enqueueSnackbar, item.name || "");
                          });
                          let files = await Promise.all(requests);


                          setLoading(false);
                        }}
                      >
                        {t("shared-folders-objects-all.downloadFile")}
                      </Button>
                    </Grid>
                    <Grid item lg={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="error"
                        disabled={selectedItems.filter(x => x.type == DataFileTreeType.File).length < 1 || selectedItems.some(x => x.type == DataFileTreeType.Folder)}
                        endIcon={<InsertDriveFile />}
                        startIcon={<Delete />}
                        //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                        onClick={async () => {
                          setDeleteConfirmationDialogVisible(true);
                        }}
                      >
                        {t("shared-folders-objects-all.removeFile")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item container direction={"row"} spacing={2}>
                    <Grid item lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={selectedItems.filter(x => x.type == DataFileTreeType.File).length < 1 || selectedItems.some(x => x.type == DataFileTreeType.Folder)}
                        endIcon={<DriveFileRenameOutline />}
                        startIcon={<CloudCircle />}
                        //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                        onClick={async () => {
                          setIsRenameFileDialogVisible(true);
                        }}
                      >
                        {t("shared-folders-objects-all.renameFile")}
                      </Button>
                    </Grid>
                    <Grid item lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={selectedItems.filter(x => x.type == DataFileTreeType.File).length < 1 || selectedItems.some(x => x.type == DataFileTreeType.Folder)}
                        endIcon={<Preview />}
                        //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                        onClick={async () => {
                          setLoading(true);
                          let requests = selectedItems.map(async (item) => {
                            if (!item.name) return;
                            let res = await api.DownloadObjectFileAsync(currentObject.id, item.fullPath + item.name, token, enqueueSnackbar, item.name || "", true);

                            return { contentUrl: res.url, contentType: getExtension(item.name), name: item.name }
                          });
                          let files = await Promise.all(requests);
                          setImages(files);

                          setLoading(false);
                        }}
                      >
                        {t("shared-folders-objects-all.filePreview")}
                      </Button>
                    </Grid>

                  </Grid>

                  <Grid item container direction={"row"} spacing={2} alignItems={"center"}>
                    <Grid item>
                      <Typography fontWeight={"bold"} fontSize={20}>Selektiert: {selectedItems.length}</Typography>
                    </Grid>
                    <Grid item lg={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="error"
                        disabled={selectedItems.length < 1}
                        endIcon={<SelectAll />}
                        startIcon={<Remove />}
                        //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                        onClick={async () => {
                          selectedItems.forEach(x => {
                            x.selected = false;
                          });
                          setSelectedItems([]);
                        }}
                      >
                        Selektion aufheben
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant='h4' fontWeight={"bold"} color="primary">Digitale Objektmappe - {currentObject.name}</Typography>
                  </Grid>
                  {loading &&
                    <Grid item>
                      <CircularProgress size={64} />
                    </Grid>
                  }
                  <Grid item>

                    <List
                      sx={{ width: '100%', bgcolor: 'background.paper' }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      <ListItem style={{ marginLeft: 60 }}>
                        <Typography color={"primary"} fontSize={24} fontWeight="bold">Name</Typography>
                        <div style={{ position: 'absolute', left: 600 }}>
                          <Typography color={"primary"} fontSize={24} fontWeight="bold">{t("shared-folders-objects-all.creationDate")}</Typography>
                        </div>
                        <div style={{ position: 'absolute', left: 800 }}>
                          <Typography color={"primary"} fontSize={24} fontWeight="bold">{t("shared-folders-objects-all.fileSize")}</Typography>
                        </div>
                      </ListItem>
                      <Divider style={{ marginTop: 20 }} />
                      {buildFileList(fileTree, 0, currentObject.id)}
                    </List>
                  </Grid>
                </Grid>
              </Paper>
              {sharedStructures && sharedStructures?.length > 0 &&
                <Paper elevation={5} style={{ padding: 30, borderRadius: 0 }}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item container direction={"row"} justifyContent="space-between">
                      <Grid item>
                        <Typography variant='h4' color={"primary"} fontWeight="bold">Geteilte Ordner: {sharedStructures?.length}</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          fullWidth
                          color="error"
                          endIcon={<FolderSharedOutlined />}
                          startIcon={<Cancel />}
                          //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                          onClick={async () => {
                            setSharedStructures([]);
                          }}
                        >
                          Geteilte Ordner ausblenden
                        </Button>
                      </Grid>
                    </Grid>
                    {sharedStructures?.map(x => {
                      return (
                        <Grid item>
                          <Accordion className={classes.hover}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header">
                              <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                <Grid item>
                                  <FolderShared color='primary' fontSize={"large"} />
                                </Grid>
                                <Grid item>
                                  <Typography variant="h4">{x.objectSharedFolder.name}</Typography>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid item container direction={"row"} spacing={2}>
                                <Grid item lg={3}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    endIcon={<Folder />}
                                    startIcon={<Add />}
                                    disabled={x.selectedItems.filter(x => x.type == DataFileTreeType.Folder).length > 1 || x.selectedItems.some(x => x.type == DataFileTreeType.File)}
                                    //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                                    onClick={async () => {
                                      setSelectedSharedItems(x.selectedItems);
                                      setSelectedSharedObjectFolder(x.objectSharedFolder);
                                      setIsAddSharedFolderDialogVisible(true);
                                    }}
                                  >
                                    Ordner hinzufügen
                                  </Button>

                                </Grid>
                                <Grid item lg={3}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    disabled={x.selectedItems.filter(x => x.type == DataFileTreeType.Folder).length != 1 || x.selectedItems.some(x => x.type == DataFileTreeType.File)}
                                    endIcon={<InsertDriveFile />}
                                    startIcon={<Add />}
                                    onClick={async () => {
                                      setSelectedSharedItems(x.selectedItems);
                                      setSelectedSharedObjectFolder(x.objectSharedFolder);
                                      setIsAddSharedFileDialogVisible(true);
                                    }}
                                  >
                                    {t("shared-folders-objects-all.addFile")}
                                  </Button>

                                </Grid>
                                <Grid item lg={3}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={x.selectedItems.filter(x => x.type == DataFileTreeType.File).length < 1 || x.selectedItems.some(x => x.type == DataFileTreeType.Folder)}
                                    endIcon={<Download />}
                                    startIcon={<CloudRounded />}
                                    onClick={async () => {
                                      setLoading(true);
                                      let requests = x.selectedItems.map(async (item) => {
                                        return await api.DownloadObjectSharedFileAsync(currentObject.id, x.objectSharedFolder.id, item.fullPath + item.name, token, enqueueSnackbar, item.name || "");
                                      });
                                      let files = await Promise.all(requests);

                                      setLoading(false);
                                    }}
                                  >
                                    {t("shared-folders-objects-all.downloadFile")}
                                  </Button>
                                </Grid>
                                <Grid item lg={3}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="error"
                                    disabled={x.selectedItems.filter(x => x.type == DataFileTreeType.File).length < 1 || selectedItems.some(x => x.type == DataFileTreeType.Folder)}
                                    endIcon={<InsertDriveFile />}
                                    startIcon={<Delete />}
                                    onClick={async () => {
                                      setSelectedSharedItems(x.selectedItems);
                                      setSelectedSharedObjectFolder(x.objectSharedFolder);
                                      setDeleteSharedFileConfirmationDialogVisible(true);
                                    }}
                                  >
                                    {t("shared-folders-objects-all.removeFile")}
                                  </Button>
                                </Grid>
                                <Grid item lg={3}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={x.selectedItems.filter(x => x.type == DataFileTreeType.File).length < 1 || selectedItems.some(x => x.type == DataFileTreeType.Folder)}
                                    endIcon={<DriveFileRenameOutline />}
                                    startIcon={<CloudCircle />}
                                    //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                                    onClick={async () => {
                                      setSelectedSharedItems(x.selectedItems);
                                      setSelectedSharedObjectFolder(x.objectSharedFolder);
                                      setIsRenameSharedFileDialogVisible(true);
                                    }}
                                  >
                                    {t("shared-folders-objects-all.renameFile")}
                                  </Button>
                                </Grid>
                                <Grid item lg={3}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={x.selectedItems.filter(x => x.type == DataFileTreeType.File).length < 1 || x.selectedItems.some(x => x.type == DataFileTreeType.Folder)}
                                    endIcon={<Preview />}
                                    //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                                    onClick={async () => {
                                      setLoading(true);
                                      let requests = x.selectedItems.map(async (item) => {
                                        if (!item.name) return;
                                        let res = await api.DownloadObjectSharedFileAsync(currentObject.id, x.objectSharedFolder.id, item.fullPath + item.name, token, enqueueSnackbar, item.name || "");

                                        return { contentUrl: res.url, contentType: getExtension(item.name), name: item.name }
                                      });
                                      let files = await Promise.all(requests);
                                      setImages(files);

                                      setLoading(false);
                                    }}
                                  >
                                    {t("shared-folders-objects-all.filePreview")}
                                  </Button>
                                </Grid>
                              </Grid>

                              <Grid item>
                                <List
                                  sx={{ width: '100%', bgcolor: 'background.paper' }}
                                  component="nav"
                                  aria-labelledby="nested-list-subheader"
                                >
                                  <ListItem style={{ marginLeft: 60 }}>
                                    <Typography color={"primary"} fontSize={24} fontWeight="bold">Name</Typography>
                                    <div style={{ position: 'absolute', left: 600 }}>
                                      <Typography color={"primary"} fontSize={24} fontWeight="bold">{t("shared-folders-objects-all.creationDate")}</Typography>
                                    </div>
                                    <div style={{ position: 'absolute', left: 800 }}>
                                      <Typography color={"primary"} fontSize={24} fontWeight="bold">{t("shared-folders-objects-all.fileSize")}</Typography>
                                    </div>
                                  </ListItem>
                                  <Divider style={{ marginTop: 20 }} />
                                  {buildFileList(x.dataFileTree, 0, x.objectSharedFolder.id)}
                                </List>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Paper>
              }
            </Container>
          </Grid>
          {images.length > 0 &&
            <Grid item lg={5}>
              <Container maxWidth="lg">
                <AppBar position="sticky">
                  <Toolbar>
                    <Typography className={classes.title} component="div" variant="h6">{`${images.length} Vorschau ausgewählt` || ''}</Typography>
                    {
                      <IconButton color="inherit" onClick={() => { setImages([]) }} size="large">
                        <Close />
                      </IconButton>
                    }
                  </Toolbar>
                </AppBar>
                <Paper elevation={5} style={{ padding: 10 }}>
                  <Grid container direction={"column"} spacing={2}>
                    {images.map((item, index) => {
                      return (
                        <>
                          <Grid item>
                            {item.contentType != "pdf" &&
                              <ImageListItem key={index}>
                                <img
                                  src={item.contentUrl}
                                  loading="lazy"
                                  style={{ width: '100%', objectFit: 'contain' }}
                                />
                                <ImageListItemBar
                                  title={`${item.name}`}

                                  actionIcon={
                                    <>
                                      <Button
                                        size="small"
                                        startIcon={<Download />}
                                        variant={'contained'}
                                        onClick={() => {
                                          var link = document.createElement("a"); // Or maybe get it from the current document
                                          link.href = item.contentUrl;
                                          link.download = `${item.name}`;
                                          link.click();
                                        }}
                                        color="primary">
                                        Download Bild
                                      </Button>
                                    </>
                                  }

                                />
                              </ImageListItem>
                            }
                            {item.contentType == "pdf" &&
                              <div>
                                <Document file={item.contentUrl} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
                                  <Page pageNumber={pageNumber} />
                                </Document>
                                <Grid container direction={"row"} spacing={3} alignItems={"center"}>
                                  <Grid item style={{ flexGrow: 1 }}>
                                    <Typography>{item.name}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      startIcon={<Backspace />}
                                      onClick={async () => {
                                        if (pageNumber > 1)
                                          setPageNumber(prev => prev - 1);
                                      }}
                                    >
                                      Zurück
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      style={{ marginLeft: 10 }}
                                      variant="contained"
                                      color="primary"
                                      startIcon={<NextPlan />}
                                      onClick={async () => {
                                        if (pageNumber < numPages)
                                          setPageNumber(prev => prev + 1);
                                      }}
                                    >
                                      Next
                                    </Button>
                                  </Grid>

                                </Grid>
                              </div>
                            }
                          </Grid>
                          <Grid item>
                            <Divider />
                          </Grid>
                        </>
                      )
                    })}
                  </Grid>
                </Paper>
              </Container>
            </Grid>
          }
        </Grid>

        <AssignObjectToSharedFolderDialog onRefresh={() => { }} open={isAssignObjectToSharedFolderDialogVisible} setOpen={setIsAssignObjectToSharedFolderDialogVisible}
          objects={objects} groups={[]} selectedObjectSharedFolders={selectedSharedObjectFolderForAssign} />

        <ObjectSharedFolderSelectionDialog
          onShowNewViews={async (selectedRows: ObjectSharedFolderTable[]) => {
            await loadSharedData(token, selectedRows);
            setSelectedSharedObjectFolders(selectedRows);
          }}
          currentObject={currentObject} open={isObjectSharedFoldersTableDialogVisible} setOpen={setIsObjectSharedFoldersTableDialogVisible} />

        <AssignObjectSharedFolderSelectionDialog
          onAssignObjectsToSharedFolders={async (selectedRows: ObjectSharedFolderTable[]) => {
            setSelectedSharedObjectFolderForAssign(selectedRows);
            setIsAssignObjectToSharedFolderDialogVisible(true);
          }}
          currentObject={currentObject} open={isObjectSharedFoldersTableAssignSelectionDialogVisible} setOpen={setIsObjectSharedFoldersTableAssignSelectionDialogVisible} />

        <AddSharedFolderDialog
          selectedObjectUnit={currentObject}
          objects={objects}
          onFinished={async (name: string) => {
            var sharedFolder = await api.createObjectSharedFolderAsync(currentObject.id, [currentObject.id], name, token, enqueueSnackbar);
            if (sharedFolder) {
              setSelectedSharedObjectFolderForAssign([sharedFolder]);
              setIsAssignObjectToSharedFolderDialogVisible(true);
            }

          }} open={isAddObjectSharedFolderDialogVisible} setOpen={setIsAddObjectSharedFolderDialogVisible} />

        <RenameFileDialog selectedItems={selectedItems} onAdd={async (objectRenameFiles: ObjectRenameFile[]) => {
          for (let objectRenameFile of objectRenameFiles) {
            await api.RenameObjectFileAsync(currentObject.id, objectRenameFile, token, enqueueSnackbar)
          }
          loadData(token);
        }} open={isRenameFileDialogVisible} setOpen={setIsRenameFileDialogVisible} />

        <RenameFileDialog selectedItems={selectedSharedItems} onAdd={async (objectRenameFiles: ObjectRenameFile[]) => {
          if (!selectedSharedObjectFolder) return;
          for (let objectRenameFile of objectRenameFiles) {
            await api.RenameObjectSharedFileAsync(selectedSharedObjectFolder?.objectCreatorId, selectedSharedObjectFolder?.id, objectRenameFile, token, enqueueSnackbar)
          }
          await loadSharedData(token, selectedSharedObjectFolders);
        }} open={isRenameSharedFileDialogVisible} setOpen={setIsRenameSharedFileDialogVisible} />

        <AddFolderDialog onAdd={(name: string) => {
          setAddNewFolderName(name);
          setIsAddFileDialogVisible(true);
        }} open={isAddFolderDialogVisible} setOpen={setIsAddFolderDialogVisible} />

        <AddFolderDialog onAdd={(name: string) => {
          setAddNewSharedFolderName(name);
          setIsAddSharedFileDialogVisible(true);
        }} open={isAddSharedFolderDialogVisible} setOpen={setIsAddSharedFolderDialogVisible} />

        <AddFileDialog onAdd={async (files: File[]) => {
          if (selectedItems.length < 1) {
            for (let file of files) {
              await api.uploadObjectFile(file, addNewFolderName + "/", currentObject.id, token, enqueueSnackbar)
            }
          } else {
            let fullPath = (selectedItems[0].fullPath);
            if (addNewFolderName) {
              fullPath = (selectedItems[0].fullPath) + addNewFolderName + "/";
            }
            for (let file of files) {
              await api.uploadObjectFile(file, fullPath, currentObject.id, token, enqueueSnackbar)
            }
          }
          setAddNewFolderName("");
          await loadData(token);
        }} open={isAddFileDialogVisible} setOpen={setIsAddFileDialogVisible} />

        <AddFileDialog onAdd={async (files: File[]) => {
          if (!selectedSharedObjectFolder) return;

          if (selectedSharedItems.length < 1) {
            for (let file of files) {
              await api.uploadObjectSharedFileAsync(file, addNewSharedFolderName + "/", currentObject.id, selectedSharedObjectFolder.id, token, enqueueSnackbar)
            }
          } else {
            let fullPath = (selectedSharedItems[0].fullPath);
            if (addNewSharedFolderName) {
              fullPath = (selectedSharedItems[0].fullPath) + addNewSharedFolderName + "/";
            }
            for (let file of files) {
              await api.uploadObjectSharedFileAsync(file, fullPath, currentObject.id, selectedSharedObjectFolder.id, token, enqueueSnackbar)
            }
          }
          setAddNewSharedFolderName("");
          await loadSharedData(token, selectedSharedObjectFolders);
        }} open={isAddSharedFileDialogVisible} setOpen={setIsAddSharedFileDialogVisible} />

        <ConfirmationDialog
          visible={deleteConfirmationDialogVisible}
          title="Sind Sie sicher, diese Dateien zu löschen ?"
          onClose={() => {
            setDeleteConfirmationDialogVisible(false);
          }}
          onConfirmed={async () => {
            setLoading(true);
            let requests = selectedItems.map(async (item) => {
              await api.DeleteObjectFileAsync(currentObject.id, item.fullPath + item.name, token, enqueueSnackbar);
            });
            await Promise.all(requests);
            await loadData(token);
            setDeleteConfirmationDialogVisible(false);

          }}
        />

        <ConfirmationDialog
          visible={deleteSharedFileConfirmationDialogVisible}
          title="Sind Sie sicher, diese Geteilten Dateien zu löschen ?"
          onClose={() => {
            setDeleteSharedFileConfirmationDialogVisible(false);
          }}
          onConfirmed={async () => {
            if (!selectedSharedObjectFolder) return;

            setLoading(true);
            console.log(selectedSharedItems, selectedSharedObjectFolder, "sldflsdflsdlf");
            let requests = selectedSharedItems.map(async (item) => {
              await api.DeleteObjectSharedFileAsync(currentObject.id, selectedSharedObjectFolder.id, item.fullPath + item.name, token, enqueueSnackbar);
            });
            await Promise.all(requests);
            await loadSharedData(token, selectedSharedObjectFolders);
            setDeleteSharedFileConfirmationDialogVisible(false);

          }}
        />
      </DialogContent>

    </Dialog >
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  title: {
    flexGrow: 1,
  }
  ,
  hover: {
    "&:hover": {
      backgroundColor: lightgrey
    }
  }
}));