import axios from "axios"
import { headers } from "../../api";

export const verifyUserToken = async (token: string): Promise<boolean> => {
    try {

        await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/auth/verify-user-token`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return true;

    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        return false;
    }
}