import axios from "axios"
import { headers } from "../../api";
import { BackendErrorCodes } from "../../models/ErrorCodes";
import { Session } from "../../models/Session";

export const login = async (email: string, password: string, enqueueSnackbar: any): Promise<Session | undefined> => {
    try {

        let ret = await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/auth/by-username-password`,
            { mail: email, password: password, tokenRights: 798 },
            { headers: headers });

        return ret.data;

    } catch (err : any) {

        switch (err?.response?.data?.code) {
            case BackendErrorCodes.ForbiddenEmailNotConfirmed:
                enqueueSnackbar("Email muss verifiziert werden! Prüfen sie ihre Emails!", { variant: 'error' });
                break;
            case BackendErrorCodes.ForbiddenNotActivated:
                enqueueSnackbar("Ihre Email ist noch nicht aktiviert. Kontaktieren Sie bitte die Administration", { variant: 'error' });
                break;
            default:
                enqueueSnackbar("Passwort Bestätigung fehlgeschlagen", { variant: 'error' });
                break;
        }

        return undefined;
    }
}