import { ProductType, ItemProductType } from '../components/authorized/ProductType';
import { LicenseType } from '../models/LicenseType';
import { PurchaseReasonType } from './GroupLicense';

export class Product {
    public purchaseReasonType !: PurchaseReasonType;
    public id!: string;
    public parentId!: string | "";
    public systemETag!: string;
    public name!: string;
    public articleNumber! : string;
    public days! : number;
    public lengthDescription : string | undefined;
    public checkpointCount! : number;
    public qmCount! : number;
    public publicFeedbackCount !: number;
    public payPerServiceCount !: number;
    public calendarProCount !: number;
    public taqtFeedbackCount !: number;
    public taqtTimeCount !: number;

    public description!: string;
    public image!: string;
    public type!: ProductType;
    public unitPrice: number = 0;
    public sensorCount: number = 0;
    public licenseType: LicenseType = 99 ;
    public isVisibleInShop: boolean = false;
    public itemProduct!: ItemProduct[];
    public itemOption!: ItemOption[];
    public priority !: number;
    static Columns = {
        id: 'id',
        name: 'name',
        description: 'description',
        type: 'type',
        unitPrice: 'unitPrice',
        actions: 'actions',
        actions2: 'actions2',
        filter: 'filter',
        priority: 'priority'
    }

    static AvailableTypes = [
        
        { value: ProductType.LicenseHardwarePackage12Months, label: 'Basis Lizenz mit Hardware - 12 Monate' },
        { value: ProductType.LicenseHardwarePackage24Months, label: 'Basis Lizenz mit Hardware - 24 Monate' },
        { value: ProductType.LicenseHardwarePackage36Months, label: 'Basis Lizenz mit Hardware - 36 Monate' },
        { value: ProductType.LicenseHardwarePackageTest, label: 'Test Basis Lizenz mit Hardware - Dynamisch Tage' },
        { value: ProductType.ExtensionLicenseSensorExtendedOne12Months, label: 'Erweiterungssensor für 2. Raum - 12 Monate' },
        { value: ProductType.ExtensionLicenseSensorExtendedOne24Months, label: 'Erweiterungssensor für 2. Raum - 24 Monate' },
        { value: ProductType.ExtensionLicenseSensorExtendedOne36Months, label: 'Erweiterungssensor für 2. Raum - 36 Monate' },
        { value: ProductType.ExtensionLicenseSensorExtendedTwo12Months, label: 'Erweiterungssensor für 3. Raum - 12 Monate' },
        { value: ProductType.ExtensionLicenseSensorExtendedTwo24Months, label: 'Erweiterungssensor für 3. Raum - 24 Monate' },
        { value: ProductType.ExtensionLicenseSensorExtendedTwo36Months, label: 'Erweiterungssensor für 3. Raum - 36 Monate' },

        { value: ProductType.ExtensionContentManagementTest, label: 'Contentmanagement & Werbung - Test' },
        { value: ProductType.ExtensionContentManagement12Months, label: 'Contentmanagement & Werbung - 12 Monate' },
        { value: ProductType.ExtensionContentManagement24Months, label: 'Contentmanagement & Werbung - 24 Monate' },
        { value: ProductType.ExtensionContentManagement36Months, label: 'Contentmanagement & Werbung - 36 Monate' },
        { value: ProductType.LicenseLoraSensor12Months, label: 'Lora Erweiterungssensor für zusätzlichen Raum - 12 Monate' },
        { value: ProductType.LicenseLoraSensor24Months, label: 'Lora Erweiterungssensor für zusätzlichen Raum - 24 Monate' },
        { value: ProductType.LicenseLoraSensor36Months, label: 'Lora Erweiterungssensor für zusätzlichen Raum - 36 Monate' },

        { value: ProductType.ReplacementHardware, label: 'Ersatzteil - Hardware' },
        { value: ProductType.ReplacementSensor, label: 'Ersatzteil - Sensor' },
        { value: ProductType.ReplacementRFIdKeys, label: 'Ersatzteil - RFID Keys' },
        { value: ProductType.ReplacementDigitalCheckpoint, label: 'Ersatzteil - Digital Checkpoint' },
        { value: ProductType.ReplacementNFCKeys, label: 'Ersatzteil - NFC Chips' },
        { value: ProductType.ReplacementTaqtFeedbackHardware, label: 'Ersatzteil - Taqt Feedback' },
        { value: ProductType.ReplacementTaqtTimeHardware, label: 'Ersatzteil - Taqt Time' },
        { value: ProductType.ReplacementBatteryDisplayHardware, label: 'Ersatzteil - Batterie Display' },

        { value: ProductType.ReplacementTaqtOnlyFeedback, label: 'Feedback Only Taqt' },
        { value: ProductType.ReplacementTaqtOnlyTime, label: 'Time Only Taqt' },


        { value: ProductType.LicenseBasic12Months, label: 'Basis Lizenz ohne Hardware - 12 Monate' },
        { value: ProductType.LicenseBasic24Months, label: 'Basis Lizenz ohne Hardware - 24 Monate' },
        { value: ProductType.LicenseBasic36Months, label: 'Basis Lizenz ohne Hardware - 36 Monate' },
        { value: ProductType.OnlyLicenseSensorExtendedOne12Months, label: 'Sensor Lizenz  - 12 Monate' },
        { value: ProductType.OnlyLicenseSensorExtendedOne24Months, label: 'Sensor Lizenz - 24 Monate' },
        { value: ProductType.OnlyLicenseSensorExtendedOne36Months, label: 'Sensor Lizenz - 36 Monate' },
        { value: ProductType.OnlyLicenseSensorExtendedTwo12Months, label: '2 X Sensor Lizenz - 12 Monate' },
        { value: ProductType.OnlyLicenseSensorExtendedTwo24Months, label: '2 X Sensor Lizenz  - 24 Monate' },
        { value: ProductType.OnlyLicenseSensorExtendedTwo36Months, label: '2 X Sensor Lizenz  - 36 Monate' },
        { value: ProductType.LicenseCheckpointPackageTest, label: 'Checkpoint Lizenz - Test' },
        { value: ProductType.LicenseCheckpointPackage12Months, label: 'Checkpoint Lizenz - 12 Monate' },
        { value: ProductType.LicenseCheckpointPackage24Months, label: 'Checkpoint Lizenz  - 24 Monate' },
        { value: ProductType.LicenseCheckpointPackage36Months, label: 'Checkpoint Lizenz  - 36 Monate' },
        { value: ProductType.LicenseGroupUnitQualityCheckPackage12Months, label: 'Qualitätskontrolle-Objektleiter Lizenz - 12 Monate' },
        { value: ProductType.LicenseGroupUnitQualityCheckPackage24Months, label: 'Qualitätskontrolle-Objektleiter Lizenz  - 24 Monate' },
        { value: ProductType.LicenseGroupUnitQualityCheckPackage36Months, label: 'Qualitätskontrolle-Objektleiter Lizenz  - 36 Monate' },
        { value: ProductType.LicenseGroupUnitQualityCheckPackageTest, label: 'Qualitätskontrolle-Objektleiter Lizenz  - Test' },
        { value: ProductType.LicenseObjectStandalonePackageTest, label: 'Standalone Objekt Lizenz - Test' },
        { value: ProductType.LicenseObjectStandalonePackage12Months, label: 'Standalone Objekt Lizenz - 12 Monate' },
        { value: ProductType.LicenseObjectStandalonePackage24Months, label: 'Standalone Objekt Lizenz - 24 Monate' },
        { value: ProductType.LicenseObjectStandalonePackage36Months, label: 'Standalone Objekt Lizenz - 36 Monate' },


        { value: ProductType.OnlyLicenseTimeTrackingPro1, label: 'Zeiterfassung Pro - 12 Monate' },
        { value: ProductType.OnlyLicenseTimeTrackingPro2, label: 'Zeiterfassung Pro - 24 Monate' },
        { value: ProductType.OnlyLicenseTimeTrackingPro3, label: 'Zeiterfassung Pro - 36 Monate' },

        { value: ProductType.OnlyLicenseMaterialStock1, label: 'Materialwirtschaft - 12 Monate' },
        { value: ProductType.OnlyLicenseMaterialStock2, label: 'Materialwirtschaft - 24 Monate' },
        { value: ProductType.OnlyLicenseMaterialStock3, label: 'Materialwirtschaft - 36 Monate' },

        { value: ProductType.OnlyLicenseCO2Documentation1, label: 'Co2 Dokumentation - 12 Monate' },
        { value: ProductType.OnlyLicenseCO2Documentation2, label: 'Co2 Dokumentation - 24 Monate' },
        { value: ProductType.OnlyLicenseCO2Documentation3, label: 'Co2 Dokumentation - 36 Monate' },



        { value: ProductType.LicenseHardwarePackageBssdBasicTest, label: 'Battery Smart Service Display: Basis Lizenz mit Hardware - Test' },
        { value: ProductType.LicenseHardwarePackageBssdBasic12Months, label: 'Battery Smart Service Display: Basis Lizenz mit Hardware - 12 Monate' },
        { value: ProductType.LicenseHardwarePackageBssdBasic24Months, label: 'Battery Smart Service Display: Basis Lizenz mit Hardware - 24 Monate' },
        { value: ProductType.LicenseHardwarePackageBssdBasic36Months, label: 'Battery Smart Service Display: Basis Lizenz mit Hardware - 36 Monate' },

        { value: ProductType.ExtensionLicenseBssdSensorExtendedOne12Months, label: 'Battery Smart Service Display: Erweiterungssensor - 12 Monate' },
        { value: ProductType.ExtensionLicenseBssdSensorExtendedOne24Months, label: 'Battery Smart Service Display: Erweiterungssensor - 24 Monate' },
        { value: ProductType.ExtensionLicenseBssdSensorExtendedOne36Months, label: 'Battery Smart Service Display: Erweiterungssensor - 36 Monate' },


        { value: ProductType.LicenseGroupPublicFeedbackPackage12Months, label: 'Public Feedback Lizenz - 12 Monate' },
        { value: ProductType.LicenseGroupPublicFeedbackPackage24Months, label: 'Public Feedback Lizenz  - 24 Monate' },
        { value: ProductType.LicenseGroupPublicFeedbackPackage36Months, label: 'Public Feedback Lizenz  - 36 Monate' },
        { value: ProductType.LicenseGroupPublicFeedbackPackageTest, label: 'Public Feedback Lizenz  - Test' },


        { value: ProductType.LicenseGroupPayPerServicePackage12Months, label: 'PayPerService Lizenz - 12 Monate' },
        { value: ProductType.LicenseGroupPayPerServicePackage24Months, label: 'PayPerService Lizenz  - 24 Monate' },
        { value: ProductType.LicenseGroupPayPerServicePackage36Months, label: 'PayPerService Lizenz  - 36 Monate' },
        { value: ProductType.LicenseGroupPayPerServicePackageTest, label: 'PayPerService Lizenz  - Test' },

        { value: ProductType.LicenseCalendarProPackage12Months, label: 'KalenderPro Lizenz - 12 Monate' },
        { value: ProductType.LicenseCalendarProPackage24Months, label: 'KalenderPro Lizenz  - 24 Monate' },
        { value: ProductType.LicenseCalendarProPackage36Months, label: 'KalenderPro Lizenz  - 36 Monate' },
        { value: ProductType.LicenseCalendarProPackageTest, label: 'KalenderPro Lizenz  - Test' },

        { value: ProductType.LicenseGroupTaqtFeedbackPackage12Months, label: 'Taqt Feedback Lizenz - 12 Monate' },
        { value: ProductType.LicenseGroupTaqtFeedbackPackage24Months, label: 'Taqt Feedback Lizenz  - 24 Monate' },
        { value: ProductType.LicenseGroupTaqtFeedbackPackage36Months, label: 'Taqt Feedback Lizenz  - 36 Monate' },
        { value: ProductType.LicenseGroupTaqtFeedbackPackageTest, label: 'Taqt Feedback Lizenz  - Test' },

        { value: ProductType.LicenseGroupTaqtTimePackage12Months, label: 'Taqt Time Lizenz - 12 Monate' },
        { value: ProductType.LicenseGroupTaqtTimePackage24Months, label: 'Taqt Time Lizenz  - 24 Monate' },
        { value: ProductType.LicenseGroupTaqtTimePackage36Months, label: 'Taqt Time Lizenz  - 36 Monate' },
        { value: ProductType.LicenseGroupTaqtTimePackageTest, label: 'Taqt Time Lizenz  - Test' },

    ];

    static GetLabelByType = (type: ProductType) => Product.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
}

export class ItemProduct {
    public id!: string;
    public systemETag!: string;
    public name!: string;
    public itemProductType!: ItemProductType;
    public unitCount!: number;
    public price: number = 0;
    public isVisible!: boolean;

    static Labels = [
        { value: ItemProductType.None, label: 'Keine'},
        { value: ItemProductType.CheckmenowLicense, label: 'Check me now - Basis-Lizenz'},
        { value: ItemProductType.ContentManagementSystenLicense, label: 'Lizenz für Contentmanagement'},
        { value: ItemProductType.SensorLicense, label: 'Lizenz für Erweiterungssensor'},
        { value: ItemProductType.Sensor, label: 'Erweiterungssensor'},
        { value: ItemProductType.LoraSensor, label: 'Lora Erweiterungssensor'},
        { value: ItemProductType.LoraSensorLicense, label: 'Lizenz für Lora Erweiterungssensor'},
        { value: ItemProductType.QualityCheckLicense, label: 'Lizenz für Qualitätssicherung'},
        { value: ItemProductType.RFIDKey, label: 'RFID-Key'},
        { value: ItemProductType.RPLDisplay, label: 'Check me now Hardware (UNIT)'},
        { value: ItemProductType.RPLDigitalCheckpoint, label: 'Digital Checkpoint Hardware'},
        { value: ItemProductType.RPLSensor, label: 'Ersatzsensor' },
        { value: ItemProductType.Checkpoint, label: 'Checkpoint mit QR-Code' },
        { value: ItemProductType.BssdCheckmenowLicense, label: 'Battery Smart Service Display - Basis Lizenz' },
        { value: ItemProductType.BssdSensorLicense, label: 'Battery Smart Service Display - Sensor Lizenz' },
        { value: ItemProductType.BssdDisplay, label: 'Battery Smart Service Display - Battery Display Gerät' },
        { value: ItemProductType.BssdSensor, label: 'Battery Smart Service Display - Battery Sensor Gerät' },
        { value: ItemProductType.BssdGateway, label: 'Battery Smart Service Display - Gateway Gerät' },
        { value: ItemProductType.PublicFeedbackLicense, label: 'Lizenz für Public Feedback'},
        { value: ItemProductType.PayPerServiceLicense, label: 'Lizenz für PayPerService'},
        { value: ItemProductType.CalendarProLicense, label: 'Lizenz für KalenderPro'},
        { value: ItemProductType.TaqtFeedbackLicense, label: 'Lizenz für Touchless-Feedback'},
        { value: ItemProductType.TaqtTimeLicense, label: 'Lizenz für Touchless-Time'},


    ];

    static CreateInstance = (type = ItemProductType.CheckmenowLicense) =>{
        return {
            id: null,
            systemETag: null,
            name: "",
            itemProductType: type,
            unitCount: 1,
            price: 0,
            isVisible: false
        } as unknown as ItemProduct;
    }
}

export class ItemOption {
    public id!: string;

    public ItemOption(id: string)
    {
        this.id = id;
    }

    constructor (id: string)
    {
        this.id = id;
    }
}