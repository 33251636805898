import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, ValueAxis, Tooltip, ZoomAndPan, Size, Font } from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { buildBarLabel, chartHeight, ChartLabelType, customizeLegendTextBarLineChart, customizePercentageText, customizeTooltipWithType, mapLabels, NoDataChartLoader } from './Base';
import { ChartHeightField, FullscreenButton, ResetButtonZoom } from './StackedChart';
import { Grid } from '@mui/material';
import React from 'react';
import { FullScreenChartDialog } from './dialog/FullScreenChartDialog';
import { ChartLabelAlternative } from './LineChart';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});



export const StackDraw = ({ data, dataField, text, loadingCompleted, chartLabelType, chartLabelAlternative = ChartLabelAlternative.Standard, childRefChart, colors }: { data: any[], dataField: any[], text?: string, loadingCompleted: boolean, chartLabelType?: ChartLabelType, chartLabelAlternative? : ChartLabelAlternative, childRefChart?: any, colors?: any }) => {
    const [isFullScreenChartVisible, setIsFullscreenChartVisible] = React.useState(false);
    const [chartHeightNow, setChartHeightNow] = React.useState(chartHeight);


    if (data.length === 0)
        return <NoDataChartLoader title={text} data={data} loadingCompleted={loadingCompleted} />;

    const buildLabel = () => {
        switch (chartLabelType) {
            case ChartLabelType.Percent:
                return (
                    <ValueAxis allowDecimals={true}>
                        <Label customizeText={customizePercentageText} />
                    </ValueAxis>
                )
            case ChartLabelType.Comma:
                return (
                    <ValueAxis allowDecimals={true}>
                        <Label customizeText={({ valueText }: any) => { return `${valueText}` }} />
                    </ValueAxis>
                )
            default:
                return <ValueAxis allowDecimals={false} />
        }
    }


    const buildChart = () => {
        return (
            <>
                <Chart
                    ref={childRefChart}
                    title={text}
                    dataSource={data}
                    id="chart"
                    palette={colors}
                >
                    <Size
                        height={chartHeightNow}
                        width={'100%'}
                    />
                    <CommonSeriesSettings
                        argumentField="dateDisplay"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                    >
                        {buildBarLabel(chartLabelType)}
                    </CommonSeriesSettings>
                    <ZoomAndPan
                        valueAxis="both"
                        argumentAxis="both"
                        dragToZoom={true}
                        allowMouseWheel={false}
                        panKey="shift" />
                    {dataField.map((el: any, id: any) => {
                        return (
                            <Series
                                valueField={el}
                                argumentField="dateDisplay"
                                name={el}
                                key={id}
                            />
                        );
                    })}
                    {buildLabel()}
                    <Tooltip
                        enabled={true}
                        location="edge"
                        customizeTooltip={(arg: any)=>customizeTooltipWithType(arg, chartLabelType,chartLabelAlternative)}
                    />
                    <Legend verticalAlignment="bottom" horizontalAlignment="center" customizeText={customizeLegendTextBarLineChart}></Legend>
                    <Export enabled={true} />
                </Chart>
                <Grid container direction="row" spacing={3} alignItems="center">
                    <Grid item>
                        <ChartHeightField chartHeight={chartHeightNow} setChartHeight={setChartHeightNow} />
                    </Grid>
                    <Grid item>
                        <ResetButtonZoom chartRef={childRefChart} />
                    </Grid>
                    <Grid item>
                        <FullscreenButton isFullScreenVisible={isFullScreenChartVisible} setIsFullscreenChartVisible={setIsFullscreenChartVisible} setChartHeight={setChartHeightNow} />
                    </Grid>

                </Grid>
            </>
        )
    }

    return isFullScreenChartVisible ? <FullScreenChartDialog setChartHeight={setChartHeightNow} visible={isFullScreenChartVisible} title={text || ""} onClose={() => { setIsFullscreenChartVisible(false) }} buildChart={buildChart} /> : buildChart();

}

export const SideBySideBarChart = withStyles(demoStyles, { name: 'Demo' })(StackDraw);