import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { AccountBox, Add, AddToQueue, AdminPanelSettings, AllInclusive, Apartment, Category, CopyAll, Dashboard, Description, EventNote, ExpandLess, ExpandMore, FormatListBulleted, GridOn, GroupAdd, Help, Home, Info, Lan, MeetingRoom, PeopleOutlineRounded, PointOfSale, Receipt, Report, Sensors, Settings, SettingsApplications, ShoppingCart } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, Grid, ListItemButton, Typography, useTheme } from '@mui/material';
import { UserAccount, UserMenuVisibility } from '../../models/UserAccount';
import { UserType } from "../../models/UserType";
import { GroupCombinationDialog } from '../dialogs/GroupCombination';
import License from '../../assets/svgs/license_grey.svg'
import { GroupSelectionEvaluationDialog } from '../dialogs/GroupSelectionEvaluation';
import { GroupSelectionCombinationEvaluationDialog } from '../dialogs/GroupSelectionCombinationEvaluation';
import { GroupSelectionAllEvaluationDialog } from '../dialogs/GroupSelectionAllEvaluation';
import { trimPathname } from '../../helpers/common';
import { UserSingleSelectionEvaluationDialog } from '../dialogs/UserSingleSelection';
import { AdminUserChoice } from './AdminUserChoice';
import { ChartsDrawerPoints } from './ChartsDrawerPoints';
import { DrawerListSection } from './DrawerListSection';
import { GroupType } from '../../models/Group';
import { AddTask } from '@mui/icons-material';
import WindowIcon from '@mui/icons-material/Window';
import { GROUP_ID } from '../../constants';
import { DrawerMenuItemDisplayType, ObjectMenuPointDrawer } from './ObjectMenuPointDrawer';
import { GlobalContext } from './PortalPage';
import { ObjectSelectionEvaluationDialog } from '../dialogs/objects/ObjectSelectionEvaluationDialog';
import { ServiceWorkerMenuPointDrawer } from './ServiceWorkerMenuPointDrawer';
import { ServiceWorkerSelectionEvaluationDialog } from '../dialogs/serviceWorkers/ServiceWorkerSelectionEvaluationDialog';
import { LicenseBlack } from '../../assets';
import { red } from '../../styles/colors';
import { AdminSetting, AdminSettingNewsType } from '../../models/AdminSetting';
import OverviewHomePng from '../../assets/image/home/overview_home.png'
import LicenseOverViewDrawerPng from '../../assets/image/home/license_overview_drawer.png'
import { SettingsDrawer } from './SettingsDrawer';
import { useTranslation } from 'react-i18next';

export const DrawerList = ({
    selectedCombinationGroup,
    selectedGroupName,
    setSelectedGroupName,
    selectedGroupId,
    user,
    setSelectedCombinationGroup,
    selectedUserFromAdmin,
    setSelectedUserFromAdmin,
    setSelectedGroupId,
    selectedGroupType,
    setSelectedGroupType,
    openSingleEvalDialog,
    setOpenSingleEvalDialog
}: {
    selectedCombinationGroup: any,
    selectedGroupName: string,
    setSelectedGroupName: any,
    selectedGroupId: string,
    user: UserAccount | undefined,
    setSelectedCombinationGroup: any,
    selectedUserFromAdmin: UserAccount | undefined,
    setSelectedUserFromAdmin: any,
    setSelectedGroupId: any,
    selectedGroupType: GroupType,
    setSelectedGroupType: any,
    openSingleEvalDialog: boolean,
    setOpenSingleEvalDialog: any
}) => {


    const { selectedObjectId, selectedObjectName, selectedCombinationObject,
        selectedServiceWorkerId, selectedServiceWorkerName, selectedCombinationServiceWorker }: any = useContext(GlobalContext);
    const { adminSetting }: { adminSetting: AdminSetting } = useContext(GlobalContext);
    const { isEvalGroupsVisible,
        isEvalServiceWorkersVisible,
        isEvalObjectsVisible,
        isEvalDashboardVisible
    }: any = useContext(GlobalContext);

    const { isEvalSettingsVisible,
        isEvalAccountVisible,
        isEvalNewsInfoVisible,
        isEvalShopVisible
    }: any = useContext(GlobalContext);


    const { openOverviewItems,
        setOpenOverviewItems,
        openNewsInfos,
        setOpenNewsInfos,
        openSingleEvalObjectDialog,
        setOpenSingleEvalObjectDialog,
        openEvalMultipleObjectMenu,
        setOpenEvalMultipleObjectMenu,
        openSingleEvalAllObjectMenu,
        setOpenSingleEvalAllObjectMenu,
        openSingleEvalServiceWorkerDialog,
        setOpenSingleEvalServiceWorkerDialog,
        openMultipleEvalServiceWorkerDialog,
        setOpenMultipleEvalServiceWorkerDialog,
        openAllEvalServiceWorkerDialog,
        setOpenAllEvalServiceWorkerDialog,
        openMultipleEvalDialog,
        setOpenMultipleEvalDialog,
        openAllEvalDialog,
        setOpenAllEvalDialog,
        dataEvaluationListOpen,
        setDataEvaluationListOpen,
        dataEvaluationObjectListOpen,
        setDataEvaluationObjectListOpen,
        dataEvaluationServiceWorkerListOpen,
        setDataEvaluationServiceWorkerListOpen,

        openDrawerSettings,
        setOpenDrawerSettings,
        openDrawerAdmin,
        setOpenDrawerAdmin,
        openDrawerCustomDashboard,
        setOpenDrawerCustomDashboard,
        openDrawerShop,
        setOpenDrawerShop,
    }: {
        openOverviewItems: boolean,
        setOpenOverviewItems: any,
        openNewsInfos: boolean,
        setOpenNewsInfos: any,
        openSingleEvalObjectDialog: boolean,
        setOpenSingleEvalObjectDialog: any,
        openEvalMultipleObjectMenu: boolean,
        setOpenEvalMultipleObjectMenu: any,
        openSingleEvalAllObjectMenu: boolean,
        setOpenSingleEvalAllObjectMenu: any,
        openSingleEvalServiceWorkerDialog: boolean,
        setOpenSingleEvalServiceWorkerDialog: any,
        openMultipleEvalServiceWorkerDialog: boolean,
        setOpenMultipleEvalServiceWorkerDialog: any,
        openAllEvalServiceWorkerDialog: boolean,
        setOpenAllEvalServiceWorkerDialog: any,
        openMultipleEvalDialog: boolean,
        setOpenMultipleEvalDialog: any,
        openAllEvalDialog: boolean,
        setOpenAllEvalDialog: any,
        dataEvaluationListOpen: boolean,
        setDataEvaluationListOpen: any,
        dataEvaluationObjectListOpen: boolean,
        setDataEvaluationObjectListOpen: any,
        dataEvaluationServiceWorkerListOpen: boolean,
        setDataEvaluationServiceWorkerListOpen: any,


        openDrawerSettings: boolean,
        setOpenDrawerSettings: any,
        openDrawerAdmin: boolean,
        setOpenDrawerAdmin: any,
        openDrawerCustomDashboard: boolean,
        setOpenDrawerCustomDashboard: any,
        openDrawerShop: boolean,
        setOpenDrawerShop: any,
    } = useContext(GlobalContext);

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const { t } = useTranslation();

    const [openUsersSingleSelectionEvaluationDialog, setOpenUsersSingleSelectionEvaluationDialog] = React.useState(false);
    const [openGroupSelectionEvaluationDialog, setOpenGroupSelectionEvaluationDialog] = React.useState(false);
    const [openGroupSelectionCombinationEvaluationDialog, setOpenGroupSelectionCombinationEvaluationDialog] = React.useState(false);
    const [openGroupSelectionAllEvaluationDialog, setOpenGroupSelectionAllEvaluationDialog] = React.useState(false);


    const [openObjectSelectionEvaluationDialog, setOpenObjectSelectionEvaluationDialog] = React.useState(false);
    const [openMultipleObjectSelectionEvaluationDialog, setOpenMultipleObjectSelectionEvaluationDialog] = React.useState(false);

    const [openServiceWorkerSelectionEvaluationDialog, setOpenServiceWorkerSelectionEvaluationDialog] = React.useState(false);
    const [openMultipleServiceWorkerSelectionEvaluationDialog, setOpenMultipleServiceWorkerSelectionEvaluationDialog] = React.useState(false);




    let { pathname } = useLocation();
    pathname = trimPathname(pathname);

    let username = user?.userType == UserType.Admin ?
        `${selectedUserFromAdmin?.title} ${selectedUserFromAdmin?.firstname} ${selectedUserFromAdmin?.lastname}` :
        `${user?.title} ${user?.firstname} ${user?.lastname}`;

    const managerEntry =
        (user?.userType == UserType.Owner || user?.userType == UserType.Admin || user?.userType == UserType.Manager) ?
            {
                title: "Benutzer - Verwaltung (Portal)",
                icon: <PeopleOutlineRounded />,
                path: "/portal/manager-customer"
            } : null;

    const templateEntry =
    {
        title: "Vorlagen - Settings",
        icon: <CopyAll />,
        path: "/portal/settings"
    }

    const settingsEntry =
    {
        title: "Meine Einheiten - Settings",
        icon: <Settings />,
        path: "/portal/einheiten/settings"
    }

    const entriesGroup = [
        {
            title: "Zuweisungstabelle",
            icon: <AllInclusive />,
            path: "/portal/gesamtverwaltung"
        },
        {
            title: t("drawerList.inputHelpApp"),
            icon: <AddTask />,
            path: "/portal/eingabehilfeapp"
        },
        {
            title: t("objects"),
            icon: <Apartment />,
            path: "/portal/objekte"
        },
        {
            title: t("properties"),
            icon: <WindowIcon />,
            path: "/portal/merkmale"
        },
        {
            title: t("serviceworkers"),
            icon: <GroupAdd />,
            path: "/portal/servicekräfte"
        },
        {
            title: "Standard Items",
            icon: <Lan />,
            path: "/portal/standarditems"
        },
        {
            title: t("drawerList.calendarPro"),
            icon: <EventNote />,
            path: "/portal/interval"
        },
        {
            title: t("drawerList.myReportsDashboard"),
            icon: <GridOn />,
            path: "/portal/dashboardverwaltung"
        },


    ];

    if (user?.userType != UserType.ObjectCustomer) {
        entriesGroup.push(templateEntry);
        entriesGroup.push(settingsEntry);
    }

    if (managerEntry)
        entriesGroup.push(managerEntry)


    entriesGroup.push(
        {
            title: t("drawerList.addNewUnit"),
            icon: <AddToQueue />,
            path: "/portal/license-einheit-management"
        }
    );


    return (
        <div className={classes.root}>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
            >
                <ListItemButton
                    component={Link}
                    to="/portal"
                    selected={pathname === "/portal"}
                    style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    <Grid container direction="column" justifyContent={"center"} alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography variant="h5">{t("drawerList.portalOverview")}</Typography>
                        </Grid>
                        <Grid item>
                            <img style={{ height: 100, borderRadius: 5 }} src={OverviewHomePng} />
                        </Grid>
                    </Grid>

                </ListItemButton>

                {
                    user &&
                    user.userType === UserType.Admin &&
                    <DrawerListSection
                        open={openDrawerAdmin}
                        setOpen={setOpenDrawerAdmin}
                        title="Administration"
                        pathname={pathname}
                        items={[
                            {
                                title: t("drawerList.products"),
                                icon: <Category />,
                                path: "/portal/produkte"
                            },
                            {
                                title: t("drawerList.pdfTemplates"),
                                icon: <Description />,
                                path: "/portal/templates"
                            },
                            {
                                title: t("drawerList.usermanagement"),
                                icon: <GroupAdd />,
                                path: "/portal/usermanagement"
                            },
                            {
                                title: t("drawerList.invoices"),
                                icon: <Receipt />,
                                path: "/portal/invoices"
                            },
                            {
                                title: t("drawerList.licenses"),
                                icon: <LicenseBlack />,
                                path: "/portal/admin/licenses"
                            },
                            {
                                title: "Standarditems",
                                icon: <FormatListBulleted />,
                                path: "/portal/admin/standarditems"
                            },
                            {
                                title: "Raumarten",
                                icon: <MeetingRoom />,
                                path: "/portal/admin/room-type"
                            },
                            {
                                title: "Global Admin Settings",
                                icon: <AdminPanelSettings />,
                                path: "/portal/admin/settings"
                            },
                            {
                                title: t("drawerList.lora"),
                                icon: <Sensors />,
                                path: "/portal/admin/lorasensor"
                            }
                        ]}
                    />
                }


                <Divider></Divider>


                {(pathname.includes("/portal/license-general") || pathname.includes("/portal/license-einheit-management") || pathname.includes("/portal/objekte")) &&
                    <ListItemButton
                        component={Link}
                        to="/portal/licenses"
                        selected={pathname === "/portal/licenses"}
                        style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, marginTop: 15 }}
                    >
                        <Grid container direction="column" justifyContent={"center"} alignItems="center" spacing={3}>
                            <Grid item>
                                <Typography variant="h5">{t("drawerList.licenseMainmenu")}</Typography>
                            </Grid>
                            <Grid item>
                                <img style={{ height: 100, borderRadius: 5 }} src={LicenseOverViewDrawerPng} />
                            </Grid>
                        </Grid>

                    </ListItemButton>
                }
            </List>
            {
                user?.userType != UserType.Admin && isEvalAccountVisible &&
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.root}
                >
                    <ListItem
                        button
                        onClick={() => setOpenOverviewItems(!openOverviewItems)}
                        style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
                    >
                        <ListItemIcon className="white-font">
                            {openOverviewItems ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                        <Typography variant={"h5"} >{t("drawerList.overview")}</Typography>

                    </ListItem>
                    <Collapse in={openOverviewItems} timeout="auto" unmountOnExit>
                        <UserItems pathname={pathname} user={user} />
                    </Collapse>
                </List>
            }

            {
                user &&
                user.userType === UserType.Admin && isEvalAccountVisible &&
                <AdminUserChoice
                    classes={classes}
                    openSearchDialog={() => setOpenUsersSingleSelectionEvaluationDialog(true)}
                    user={user}
                    selectedUserFromAdmin={selectedUserFromAdmin} open={openOverviewItems} setOpen={setOpenOverviewItems} />
            }


            {
                user &&
                (user.userType === UserType.Admin || user.userType === UserType.Owner || user.userType == UserType.Manager || user.userType == UserType.ObjectCustomer) && isEvalSettingsVisible &&
                <SettingsDrawer pathname={pathname} user={user} />
            }



            {
                isEvalDashboardVisible &&
                <DrawerListSection

                    open={openDrawerCustomDashboard}
                    setOpen={setOpenDrawerCustomDashboard}
                    title={t("homeDrawerFilter.myReportsDashboard")}
                    pathname={pathname}
                    items={[
                        {
                            title: t("drawerList.myReportsDashboard"),
                            icon: <GridOn />,
                            path: "/portal/dashboardverwaltung"
                        },
                        {
                            title: t("drawerList.reports"),
                            icon: <Dashboard />,
                            path: "/portal/dashboard"
                        },
                    ]}
                />
            }

            <Divider></Divider>

            {
                (user?.userType == UserType.Owner || user?.userType == UserType.Admin) && isEvalShopVisible &&
                <DrawerListSection
                    open={openDrawerShop}
                    setOpen={setOpenDrawerShop}
                    title="Shop"
                    pathname={pathname}
                    items={[
                        {
                            title: "Shop",
                            icon: <ShoppingCart />,
                            path: "/portal/shop"
                        },
                    ]}
                />
            }

            <Divider></Divider>

            {
                isEvalNewsInfoVisible &&
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.root}
                >
                    <ListItem
                        button
                        onClick={() => setOpenNewsInfos(!openNewsInfos)}
                        style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                    >
                        <ListItemIcon className={classes.icon}>
                            {openNewsInfos ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                        <Typography variant="h5">News + Information</Typography>

                    </ListItem>
                    <Collapse in={openNewsInfos} timeout="auto" unmountOnExit>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItem
                                button
                                component={Link}
                                to="/portal/news"
                                selected={pathname === "/portal/news"}
                            >
                                <ListItemIcon>
                                    <Info />
                                </ListItemIcon>
                                {adminSetting && adminSetting.adminSettingNewsType == AdminSettingNewsType.Important ?
                                    <>
                                        <Typography style={{ color: red, fontWeight: 'bolder' }}>News</Typography>
                                        <ListItemIcon>
                                            <Report style={{ color: red }} />
                                        </ListItemIcon>
                                    </>

                                    :
                                    <>
                                        <Typography>News</Typography>
                                    </>
                                }
                            </ListItem>

                            <ListItem
                                button
                                component={Link}
                                to="/portal/instructions"
                                selected={pathname === "/portal/instructions"}
                            >
                                <ListItemIcon>
                                    <Help />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.instructions")} />
                            </ListItem>


                        </List>
                    </Collapse>
                </List>

            }

            <Divider />

            {isEvalGroupsVisible &&
                <List
                    component="nav"
                    className={classes.root}
                >
                    <ListItem
                        button
                        onClick={() => setDataEvaluationListOpen(!dataEvaluationListOpen)}
                        style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                    >
                        <ListItemIcon className={classes.icon}>
                            {dataEvaluationListOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                        <Typography variant="h5">{t("drawerList.unitEval")}</Typography>

                    </ListItem>

                    <Collapse in={dataEvaluationListOpen} timeout="auto" unmountOnExit>
                        <ChartsDrawerPoints
                            user={user}
                            openSearchDialog={() => setOpenGroupSelectionEvaluationDialog(true)}
                            selectedCombinationGroup={[]}
                            title={selectedGroupName}
                            classes={classes}
                            subHeaderText={t(subHeaderTextGroup)}
                            path={`/portal/group/${selectedGroupId}`}
                            selectedGroupId={selectedGroupId}
                            selectedGroupType={selectedGroupType}
                            open={openSingleEvalDialog}
                            setOpen={setOpenSingleEvalDialog}
                            hasAccessTo={UserMenuVisibility.hasAccessToSingle}
                        />

                        <Divider />
                        <ChartsDrawerPoints
                            user={user}
                            setOpenGroupCombinationDialog={setOpen}
                            openSearchDialog={() => setOpenGroupSelectionCombinationEvaluationDialog(true)}
                            selectedCombinationGroup={selectedCombinationGroup}
                            title={`Einheiten Kombination von ${selectedCombinationGroup.length}`}
                            classes={classes}
                            subHeaderText={t(subHeaderTextMultipleGroup)}
                            setSelectedCombinationGroup={setSelectedCombinationGroup}
                            subHeaderText2={"(Kombination Einheiten)"}
                            path={`/portal/groups/services`}
                            selectedGroupId={selectedGroupId}
                            open={openMultipleEvalDialog}
                            setOpen={setOpenMultipleEvalDialog}
                            hasAccessTo={UserMenuVisibility.hasAccessToMultiple}
                        />

                        <Divider />
                        <ChartsDrawerPoints
                            user={user}
                            openSearchDialog={() => setOpenGroupSelectionAllEvaluationDialog(true)}
                            selectedCombinationGroup={[]}
                            title={username}
                            classes={classes}
                            subHeaderText={t("drawerList.overallEvaluation")}
                            subHeaderText2={"(Alle Einheiten)"}
                            path={`/portal/users/services`}
                            selectedGroupId={selectedGroupId}
                            open={openAllEvalDialog}
                            setOpen={setOpenAllEvalDialog}
                            hasAccessTo={UserMenuVisibility.hasAccessToAll}
                        />
                    </Collapse>
                </List>
            }
            <Divider />
            {isEvalObjectsVisible &&
                <List
                    component="nav"
                    className={classes.root}
                >
                    <ListItem
                        button
                        onClick={() => setDataEvaluationObjectListOpen(!dataEvaluationObjectListOpen)}
                        style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                    >
                        <ListItemIcon className={classes.icon}>
                            {dataEvaluationObjectListOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                        <Typography variant="h5">{t("drawerList.objectEval")}</Typography>

                    </ListItem>
                    <Collapse in={dataEvaluationObjectListOpen} timeout="auto" unmountOnExit>
                        <ObjectMenuPointDrawer
                            user={user}
                            openSearchDialog={() => setOpenObjectSelectionEvaluationDialog(true)}
                            title={selectedObjectName}
                            classes={classes}
                            subHeaderText={t(subHeaderTextGroup)}
                            path={`/portal/object/${selectedObjectId}`}
                            open={openSingleEvalObjectDialog}
                            setOpen={setOpenSingleEvalObjectDialog}
                            hasAccessTo={UserMenuVisibility.hasAccessToSingle}
                        />
                        <Divider />
                        <ObjectMenuPointDrawer
                            user={user}
                            openSearchDialog={() => setOpenMultipleObjectSelectionEvaluationDialog(true)}
                            title={`Objekte Kombination von ${selectedCombinationObject.length}`}
                            classes={classes}
                            subHeaderText={t(subHeaderTextMultipleGroup)}
                            path={`/portal/object/multiple`}
                            open={openEvalMultipleObjectMenu}
                            setOpen={setOpenEvalMultipleObjectMenu}
                            displayType={DrawerMenuItemDisplayType.Multiple}
                            hasAccessTo={UserMenuVisibility.hasAccessToMultiple}
                        />
                        <Divider />
                        <ObjectMenuPointDrawer
                            user={user}
                            openSearchDialog={() => null}
                            title={`Alle Objekte`}
                            classes={classes}
                            subHeaderText={t("drawerList.overallEvaluation")}
                            path={`/portal/object/all`}
                            open={openSingleEvalAllObjectMenu}
                            setOpen={setOpenSingleEvalAllObjectMenu}
                            displayType={DrawerMenuItemDisplayType.All}
                            hasAccessTo={UserMenuVisibility.hasAccessToAll}
                        />
                    </Collapse>

                </List>
            }
            {user?.userType != UserType.Customer && isEvalServiceWorkersVisible &&
                <List
                    component="nav"
                    className={classes.root}
                >
                    <ListItem
                        button
                        onClick={() => setDataEvaluationServiceWorkerListOpen(!dataEvaluationServiceWorkerListOpen)}
                        style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                    >
                        <ListItemIcon className={classes.icon}>
                            {dataEvaluationServiceWorkerListOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                        <Typography variant="h5">{t("drawerList.serviceworkerEval")}</Typography>

                    </ListItem>
                    <Collapse in={dataEvaluationServiceWorkerListOpen} timeout="auto" unmountOnExit>
                        <ServiceWorkerMenuPointDrawer
                            openSearchDialog={() => setOpenServiceWorkerSelectionEvaluationDialog(true)}
                            title={selectedServiceWorkerName}
                            classes={classes}
                            subHeaderText={t(subHeaderTextGroup)}
                            path={`/portal/service-worker/${selectedServiceWorkerId}`}
                            open={openSingleEvalServiceWorkerDialog}
                            setOpen={setOpenSingleEvalServiceWorkerDialog}
                            hasAccessTo={UserMenuVisibility.hasAccessToSingle}
                        />
                        <Divider />
                        <ServiceWorkerMenuPointDrawer
                            openSearchDialog={() => setOpenMultipleServiceWorkerSelectionEvaluationDialog(true)}
                            title={`Servicekräfte Kombination von ${selectedCombinationServiceWorker.length}`}
                            classes={classes}
                            subHeaderText={t(subHeaderTextMultipleGroup)}
                            path={`/portal/service-worker/multiple`}
                            open={openMultipleEvalServiceWorkerDialog}
                            setOpen={setOpenMultipleEvalServiceWorkerDialog}
                            displayType={DrawerMenuItemDisplayType.Multiple}
                            hasAccessTo={UserMenuVisibility.hasAccessToMultiple}
                        />
                        <Divider />
                        <ServiceWorkerMenuPointDrawer
                            openSearchDialog={() => null}
                            title={`Alle Servicekräfte`}
                            classes={classes}
                            subHeaderText={t("drawerList.overallEvaluation")}
                            path={`/portal/service-worker/all`}
                            open={openAllEvalServiceWorkerDialog}
                            setOpen={setOpenAllEvalServiceWorkerDialog}
                            displayType={DrawerMenuItemDisplayType.All}
                            hasAccessTo={UserMenuVisibility.hasAccessToAll}
                        />
                    </Collapse>
                </List>
            }
            <Divider />


            <ObjectSelectionEvaluationDialog open={openObjectSelectionEvaluationDialog} setOpen={setOpenObjectSelectionEvaluationDialog} />
            <ObjectSelectionEvaluationDialog open={openMultipleObjectSelectionEvaluationDialog} setOpen={setOpenMultipleObjectSelectionEvaluationDialog} isMultiple={true} />

            <ServiceWorkerSelectionEvaluationDialog open={openServiceWorkerSelectionEvaluationDialog} setOpen={setOpenServiceWorkerSelectionEvaluationDialog} />
            <ServiceWorkerSelectionEvaluationDialog open={openMultipleServiceWorkerSelectionEvaluationDialog} setOpen={setOpenMultipleServiceWorkerSelectionEvaluationDialog} isMultiple={true} />

            <UserSingleSelectionEvaluationDialog
                open={openUsersSingleSelectionEvaluationDialog}
                setOpen={setOpenUsersSingleSelectionEvaluationDialog}
                setSelectedCombinationGroup={setSelectedCombinationGroup}
                setSelectedGroupName={setSelectedGroupName}
                setSelectedGroupId={setSelectedGroupId}
                setSelectedUserFromAdmin={setSelectedUserFromAdmin}
                user={user}
            />

            <GroupCombinationDialog
                open={open}
                setOpen={setOpen}
                items={selectedCombinationGroup}
            />

            <GroupSelectionEvaluationDialog
                open={openGroupSelectionEvaluationDialog}
                setOpen={setOpenGroupSelectionEvaluationDialog}
                setSelectedGroupName={setSelectedGroupName}
                selectedGroupId={selectedGroupId}
                setSelectedGroupId={setSelectedGroupId}
                user={user}
                setSelectedCombinationGroup={setSelectedCombinationGroup}
                setSelectedGroupType={setSelectedGroupType}
            />



            <GroupSelectionCombinationEvaluationDialog
                open={openGroupSelectionCombinationEvaluationDialog}
                setOpen={setOpenGroupSelectionCombinationEvaluationDialog}
                selectedCombinationGroup={selectedCombinationGroup}
                setSelectedCombinationGroup={setSelectedCombinationGroup}
            />

            <GroupSelectionAllEvaluationDialog
                open={openGroupSelectionAllEvaluationDialog}
                setOpen={setOpenGroupSelectionAllEvaluationDialog}
                selectedCombinationGroup={selectedCombinationGroup}
                setSelectedCombinationGroup={setSelectedCombinationGroup}
                setSelectedGroupName={setSelectedGroupName}
                setSelectedGroupId={setSelectedGroupId}
                setSelectedGroupType={setSelectedGroupType}
            />

        </div>
    );
}

export const UserItems = ({ pathname, user }: { pathname: string, user: any }) => {
    const { t } = useTranslation();

    return (
        <List component="nav" aria-label="main mailbox folders">
            <ListItem
                button
                component={Link}
                to="/portal"
                selected={pathname === "/portal"}
            >
                <ListItemIcon>
                    <Home />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>

            <ListItem
                button
                component={Link}
                to="/portal/account"
                selected={pathname === "/portal/account"}
            >
                <ListItemIcon>
                    <AccountBox />
                </ListItemIcon>
                <ListItemText primary={t("drawerList.myAccount")} />
            </ListItem>
            {
                (user?.userType == UserType.Owner || user?.userType == UserType.Manager || user?.userType == UserType.Admin) &&
                <ListItem
                    button
                    component={Link}
                    to="/portal/licenses"
                    selected={pathname === "/portal/licenses"}
                >
                    <ListItemIcon>
                        <img src={License} />
                    </ListItemIcon>
                    <ListItemText primary={t("drawerList.licenseManagment")} />
                </ListItem>
            }
            {
                (user?.userType == UserType.Owner || user?.userType == UserType.Manager || user?.userType == UserType.Admin) &&
                <ListItem
                    button
                    component={Link}
                    to="/portal/bestellungen"
                    selected={pathname === "/portal/bestellungen"}
                >
                    <ListItemIcon>
                        <PointOfSale />
                    </ListItemIcon>
                    <ListItemText primary={t("drawerList.myOrders")} />
                </ListItem>
            }


        </List>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    icon: {
        color: theme.palette.primary.contrastText
    }
}));

export const subHeaderTextAdmin = "drawerList.userChoice";
export const subHeaderTextGroup = "drawerList.singleEvaluation";
export const subHeaderTextMultipleGroup = "drawerList.multipleEvaluation";