import Dialog from '@mui/material/Dialog';
import React, { useRef } from 'react';

import { Button, DialogActions, DialogContent, Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { useHistory } from 'react-router-dom';
import { ObjectLeaderIncidentReportChart } from './ObjectLeaderIncidentReportChart';
import { DefaultDialogTitle } from '../../DefaultDialogTitle';
import { getApiToken } from '../../../../helpers/storage-management';
import { api } from '../../../../api';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { ObjectLeaderQualityCheckModel } from '../../../../models/ObjectLeaderQualityCheckModel';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { generateSumChartData, PeriodChartFilterType, DataFilterType, generateChartData } from '../../../../helpers/charts/base';
import { generateIncidentReportChartDataByObjectLeaderQualityChecks, generateMaterialsChartDataByObjectLeaderQualityChecks, generateServicesChartDataByObjectLeaderQualityChecks, getAllIncidentsByObjectLeaderQualityChecks, getAllMaterialsByObjectLeaderQualityChecks, getAllServicesByObjectLeaderQualityChecks } from '../../../../helpers/charts/objectLeader/dataGenerator';
import { ObjectLeaderQualityCheckServicesChart } from './ObjectLeaderQualityCheckServicesChart';
import { ObjectLeaderQualityCheckMaterialChart } from './ObjectLeaderQualityCheckMaterialChart';
import { useTranslation } from 'react-i18next';

export const ObjectLeaderQualityCheckChartDialog = ({
  open,
  setOpen,
  userServiceWorker
}: {
  open: boolean,
  setOpen: any,
  userServiceWorker: UserServiceWorker
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [objectLeaderQualityChecks, setObjectLeaderQualityChecks] = React.useState<ObjectLeaderQualityCheckModel[]>([]);
  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);

  const [incidentReportData, setIncidentReportData] = React.useState<any>([]);
  const [incidentReportAllData, setIncidentReportAllData] = React.useState<any>([]);
  const [incidentReportDataFields, setIncidentReportDataFields] = React.useState<any>([]);

  const [servicesData, setServicesData] = React.useState<any>([]);
  const [servicesAllData, setServicesAllData] = React.useState<any>([]);
  const [servicesDataFields, setServicesDataFields] = React.useState<any>([]);

  const [materialsData, setMaterialsData] = React.useState<any>([]);
  const [materialsAllData, setMaterialsAllData] = React.useState<any>([]);
  const [materialsDataFields, setMaterialsDataFields] = React.useState<any>([]);

  const childRefIncidents: any = useRef();
  const childRefIncidentsChart: any = useRef();
  const [loadingCompleted, setLoadingCompleted] = React.useState(true);

  const childRefServices: any = useRef();
  const childRefServicesChart: any = useRef();

  const childRefMaterials: any = useRef();
  const childRefMaterialsChart: any = useRef();
  const {t} = useTranslation();

  const handleClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      if (!open) return;


      const token = getApiToken(history);
      if (!token) return;
      setLoadingCompleted(false)
      const qualityChecks = await api.getQualityChecksByServiceWorkerId(token, userServiceWorker.id);
      setObjectLeaderQualityChecks(qualityChecks);

      let allIncidentTemp = generateIncidentReportChartDataByObjectLeaderQualityChecks(qualityChecks);
      setIncidentReportAllData(allIncidentTemp);
      let allIncidents = getAllIncidentsByObjectLeaderQualityChecks(qualityChecks)
      let incidentTemp = generateChartData(allIncidentTemp, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allIncidents);
      setIncidentReportDataFields(allIncidents);
      setIncidentReportData(incidentTemp);

      let allServicesTemp = generateServicesChartDataByObjectLeaderQualityChecks(qualityChecks);
      setServicesAllData(allServicesTemp);
      let allServicesBottomRow = getAllServicesByObjectLeaderQualityChecks(qualityChecks)
      let serviceTemp = generateChartData(allServicesTemp, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allServicesBottomRow);
      setServicesDataFields(allServicesBottomRow);
      setServicesData(serviceTemp);

      let allMaterialsTemp = generateMaterialsChartDataByObjectLeaderQualityChecks(qualityChecks);
      setMaterialsAllData(allMaterialsTemp);
      let allMaterialsBottomRow = getAllMaterialsByObjectLeaderQualityChecks(qualityChecks)
      let materialsTemp = generateChartData(allMaterialsTemp, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allMaterialsBottomRow);
      setMaterialsDataFields(allMaterialsBottomRow);
      setMaterialsData(materialsTemp);

      setLoadingCompleted(true);

      const tempGroups = await api.getAllGroups(token);
      setGroups(tempGroups);


    })();

  }, [open]);

  const updateStartDateFromParent = (event: any) => {

    if (childRefIncidents.current != null)
      childRefIncidents.current.updateStartDateFromParent(event);
  }
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen
    >
      <DefaultDialogTitle
        title={`Qualitätssicherung Objektleiter: ${userServiceWorker.name} ${userServiceWorker.email}`}
        handleClose={handleClose}
      />

      <DialogContent dividers style={{ height: "100%" }}>
        <Grid container direction="column" spacing={3}>
          <Grid item container justifyContent={"center"} spacing={2}>
            <Grid item xs={12}>
              {userServiceWorker &&
                <ObjectLeaderQualityCheckServicesChart
                  servicesData={servicesData}
                  dataField={servicesDataFields}
                  allData={servicesAllData}
                  setChartData={setServicesData}
                  childRefChart={childRefServicesChart}
                  childRef={childRefServices}
                  loadingCompleted={loadingCompleted}
                />
              }
            </Grid>
            <Grid item xs={12}>
              {userServiceWorker &&
                <ObjectLeaderQualityCheckMaterialChart
                  materialData={materialsData}
                  dataField={materialsDataFields}
                  allData={materialsAllData}
                  setChartData={setMaterialsData}
                  childRefChart={childRefMaterialsChart}
                  childRef={childRefMaterials}
                  loadingCompleted={loadingCompleted}
                />
              }
            </Grid>
          </Grid>
          <Grid item>
            {userServiceWorker &&
              <ObjectLeaderIncidentReportChart
                incidentReportData={incidentReportData}
                dataField={incidentReportDataFields}
                allData={incidentReportAllData}
                setChartData={setIncidentReportData}
                childRefChart={childRefIncidentsChart}
                childRef={childRefIncidents}
                loadingCompleted={loadingCompleted}
              />

            }
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          Dialog {t("myUnitsSettings.close")}
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));