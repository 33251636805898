import React, { useCallback, useRef, useState } from 'react';
import { DataTypeProvider, FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, IntegratedSummary, PagingState, SelectionState, SortingState, SummaryState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, TableSummaryRow, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Paper, Typography } from '@mui/material';
import { formatUnixSecToDate, formatUnixSecToTime, formatEuropeanDateTime } from '../../../helpers/date-management';
import { saveExcelFile } from '../../../helpers/tables/common';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { Product } from '../../../models/Product';
import { CheckboxCell, FilterCellCheck } from '../ServiceModeTable';
import { getSmileForSumFeedbackTable } from '../../../helpers/charts/objectLeader/dataGenerator';
import { FeedbackQuestionTotalTable } from '../../../models/GroupFeedbackQuestion';
import { maxContentWrapperPortalStyle } from '../../authorized/PortalPage';

export const AvgTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={(value: any) => {
            return Math.floor(value.value) + "%";
        }}
        {...props}
    />
);

export const FeedbackQuestionTotalTableComponent = ({ rows, groups, selectedEntries, onSelectionChange, }
    : { rows: FeedbackQuestionTotalTable[], groups: GroupLicenseTable[], selectedEntries: FeedbackQuestionTotalTable[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);
    const [sorting, setSorting] = useState([]);



    React.useEffect(() => {
        let feedbackQuestionItems = rows.reduce((prevVal, curVal) => {
            console.log(curVal, "test");
            if (!curVal?.items) return prevVal;
            curVal.items.forEach(curItem => {
                let newItem = prevVal.find(preItem => preItem == curItem.item);
                if (!newItem) {
                    prevVal.push(curItem.item);
                }
            })
            return [...prevVal];
        }, [] as string[])
        let newItems = feedbackQuestionItems.map(x => {
            return {
                name: x,
                title: x,
                getCellValue: (row: FeedbackQuestionTotalTable) => {
                    let entryTotal = row.items.find(curItem => curItem.item == x);
                    return entryTotal?.amount || 0;
                },
            }
        });
        let newColumns = [...columns, ...newItems];
        let dateColumnsWidth = newColumns.map(el => {
            return { columnName: el.name, width: 180 } as TableColumnWidthInfo;
        });
        setColumns(newColumns);
        setColumnWidths([...dateColumnsWidth]);
        setTotalSummaryItems(
            [...newItems.map(item => {
                return { columnName: item.name, type: 'sum', alignByColumn: false };
            }), { columnName: FeedbackQuestionTotalTable.Columns.totalAmount, type: 'sum', alignByColumn: true } ]
        );
    }, [rows]);

    const [columns, setColumns] = React.useState([
        {
            name: FeedbackQuestionTotalTable.Columns.groupId,
            title: "Einheit",
            getCellValue: (row: FeedbackQuestionTotalTable) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: FeedbackQuestionTotalTable.Columns.levelName,
            title: "Ebene",
            getCellValue: (row: FeedbackQuestionTotalTable) => groups.find(x => x.id == row.groupId)?.levelName,
        },
        {
            name: FeedbackQuestionTotalTable.Columns.objectName,
            title: "Objekt",
            getCellValue: (row: FeedbackQuestionTotalTable) => groups.find(x => x.id == row.groupId)?.objectName,
        },
        {
            name: FeedbackQuestionTotalTable.Columns.totalAmount,
            title: "Total",
            getCellValue: (row: FeedbackQuestionTotalTable) => row.totalAmount,
        }
    ]);


    let [columnWidths, setColumnWidths] = React.useState<TableColumnWidthInfo[]>(columns.map(el => {
        return { columnName: el.name, width: 180 } as TableColumnWidthInfo;
    }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Feedback');
        });
    };

    const Cell = (props: any) => {
        const { column, row, value } = props;
        /*        if (column.name === FeedbackQuestionTotalTable.Columns.positiveAmountPercent) {
                    return (
                        <Table.Cell {...props}>
                            <div style={{ textAlign: 'center', verticalAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Typography style={{marginRight: 10}}>{value}%  </Typography>
                            {getSmileForSumFeedbackTable(value, 40)}
                            </div>
                        </Table.Cell>
                    )
                } */
        return <Table.Cell {...props} />;
    };

    const [totalSummaryItems, setTotalSummaryItems] = useState([
        { columnName: FeedbackQuestionTotalTable.Columns.totalAmount, type: 'sum', alignByColumn: true },
    ]);

    return (
        <div style={maxContentWrapperPortalStyle}>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting as any}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <IntegratedSummary />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table cellComponent={Cell} />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableSummaryRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow
                />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll selectByRowClick />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
                totalSummaryItems={totalSummaryItems}
            />
        </div>
    );
};
