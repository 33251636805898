import { Typography } from "@mui/material";
import { useOverlayStyles } from "../../styles/overlay";

export const ImageErrorOverlay = ({ text, hidden = false }: { text: string, hidden: boolean }) => {

    const overlayStyles = useOverlayStyles();

    if (hidden)
        return null;

    return (
        <div className={overlayStyles.root}>
            <Typography style={{ textAlign: 'center' }}>{text}</Typography>
        </div>
    );
}