import React, { useContext } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { MenuVisibilityType, UserAccount, UserMenuVisibility } from '../../../models/UserAccount';
import { GlobalContext } from '../PortalPage';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { useTranslation } from 'react-i18next';

export const UserMenuVisibilityEditingDialog = ({ visible, setVisible, userAccount, setUserAccount, updateUserByIdFromOwner }
    : { visible: boolean, setVisible: any, userAccount: UserAccount, setUserAccount: any, updateUserByIdFromOwner: any }) => {

    const classes = usePortalAccountStyles();
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    
    React.useEffect(() => {

    }, [visible]);

    const onClose = React.useCallback(() => {
        setVisible(false);
    }, [])

    const keys = React.useMemo(() => {
        if (!userAccount?.userMenuVisibility) return [];
        return Object.keys(userAccount.userMenuVisibility);
    }, [userAccount])

    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title="Benutzer Menü bearbeiten"
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">Details</Typography>
                        </Grid>
                        {keys.map((x) => {
                            return (
                                <Grid item xs={12} lg={12}>
                                    <RadioButtonsUserMenu userMenuVisibility={userAccount.userMenuVisibility} userMenuKey={x} setUserAccount={setUserAccount} userAccount={userAccount} />
                                </Grid>
                            )
                        })
                        }

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    startIcon={loading && <CircularProgress size={32} />}
                    disabled={loading}
                    onClick={async () => {

                        setLoading(true);
                        await updateUserByIdFromOwner(userAccount);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);

interface RadioButtonsUserMenuInterface {
    userMenuVisibility: any;
    setUserAccount: React.Dispatch<React.SetStateAction<UserAccount | undefined>>;
    userAccount: UserAccount;
    userMenuKey: string;
}

export const RadioButtonsUserMenu = (props: RadioButtonsUserMenuInterface) => {

    const { t } = useTranslation();

    const translateKey = () => {
        switch (props.userMenuKey) {
            case "qualityChecksDashboard":
                return t("drawerList.qualitycheck")
            case "servicesDashboard":
                return t("drawerList.servicedocumentation")
            case "serviceModeDashboardTable":
                return t("drawerList.servicedocumentationTable")
            case "serviceWorkerServicesDashboard":
                return t("drawerList.servicedocumentationServiceworker")


            case "qmTriggerDashboard":
                return t("drawerList.qmtriggers")
            case "cleaningTriggerDashboard":
                return t("drawerList.servicetriggers")

            case "setPointAndActualValueDashboard":
                return t("drawerList.targetactual")
            case "onlineStatusDashboard":
                return t("drawerList.touchOnlineStatus")
            case "batteryDisplayOnlineStatusDashboard":
                return t("drawerList.batteryOnlineStatus")
            case "hitListDashboard":
                return t("drawerList.hitlist")

            case "feedbackDashboard":
                return t("drawerList.feedback")

            case "frequencyDashboard":
                return t("drawerList.frequency")

            case "frequencyLoraDashboard":
                return t("drawerList.frequencylora")

            case "customDashboard":
                return t("drawerList.myReports")

            case "customSum":
                return "Standard Cockpit"

            case "objectSessionAnalytic":
                return t("drawerList.objectSession")

            case "objectCarbonDioxideDashboard":
                return t("drawerList.co2Consumption")

            case "objectStockDashboard":
                return t("drawerList.objectStock")

            case "objectCleaningTriggerDashboard":
                return t("drawerList.objectCleaningTriggerDashboard")

            case "objectSharedFolderDashboard":
                return t("drawerList.objectSharedFolderDashboard")

            case "serviceWorkerSession":
                return t("drawerList.serviceWorkerSession")

            default:
                return props.userMenuKey
        }
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{translateKey()} {t("view")}</FormLabel>
            <RadioGroup
                row
                value={props.userMenuVisibility[props.userMenuKey]}
                onChange={(event) => {
                    props.userMenuVisibility[props.userMenuKey] = parseInt(event.target.value);
                    props.setUserAccount({ ...props.userAccount })
                    //      props.setUserAccount({ ...props.userAccount, userMenuVisibility: {...props.userAccount.userMenuVisibility, customSum : parseInt(event.target.value)}  })
                }}
            >
                <FormControlLabel
                    value={MenuVisibilityType.All}
                    control={<Radio />}
                    label={UserMenuVisibility.GetLabelByType(MenuVisibilityType.All)}
                />
                <FormControlLabel
                    value={MenuVisibilityType.OnlySingle}
                    control={<Radio />}
                    label={UserMenuVisibility.GetLabelByType(MenuVisibilityType.OnlySingle)}
                />
                <FormControlLabel
                    value={MenuVisibilityType.SingleAndMultiple}
                    control={<Radio />}
                    label={UserMenuVisibility.GetLabelByType(MenuVisibilityType.SingleAndMultiple)}
                />
                <FormControlLabel
                    value={MenuVisibilityType.Nothing}
                    control={<Radio />}
                    label={UserMenuVisibility.GetLabelByType(MenuVisibilityType.Nothing)}
                />
            </RadioGroup>
        </FormControl>
    )
}
