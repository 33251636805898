import axios from 'axios';
import { headers } from '../api';
import { TemplateInfo } from '../models/TemplateInfo';

export const ListTemplateInfosAsync = async (token: string, enqueueSnackbar: any): Promise<TemplateInfo[]> => {

    return await axios
        .get(
            `${process.env.REACT_APP_BASIC_URI}/templates`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            enqueueSnackbar("Fehler beim Laden von Vorlagen aufgetreten", { variant: 'error' });
            return [];
        });
};
