import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const UnAssignUserServiceWorkerFromGroup = async (groupId: string, userServiceWorkerId: string, token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    let userId = getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/service-worker/${userServiceWorkerId}/un-assign`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Servicekraft wurde von der Einheit erfolgreich entfernt", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Entfernung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};

export const UnAssignUserServiceWorkersFromGroup = async (groupId: string, userServiceWorkerIds: string[], token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    let userId = getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/service-workers/un-assign`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` }, data: {stringArray: userServiceWorkerIds} }
    ).then((res) => {
        enqueueSnackbar("Servicekräfte wurden von der Einheit erfolgreich entfernt", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Entfernung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};