import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DataFilterType, PeriodChartFilterType, generateChartData } from '../../helpers/charts/base';
import { InlineDateTimePicker } from './InlineDateTimePicker';
import { Grid, TextField } from '@mui/material';
import { defaultEndDate, defaultPastDays, defaultStartDate } from '../../helpers/date-management';
import { Autocomplete } from '@mui/material';
import { setTimeBasedSumChartData } from './TimeChoiceSum';
import DataFilterTypeChoiceDialog from '../dialogs/DataFilterTypeChoiceDialog';
import { useTranslation } from 'react-i18next';

export const TimeUserChoiceSum = forwardRef(({ data, setChartData, fields, currentCleaner, setCurrentCleaner }: any, ref: any) => {
  useImperativeHandle(
    ref,
    () => ({
      updateEndDateFromParent(date: any) {
        setSelectedEndDate(date);
      },
      updateStartDateFromParent(date: any) {
        setSelectedStartDate(date);
      },
      updateDataFilterTypeFromParent(dataFilterType: any) {
        setDataFilterType(dataFilterType);
      },
      updateChosenPastDays(days: any) {
        setChosenPastDays(parseInt(days));
      }
    }),
  )
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);
  const [chosenPastDays, setChosenPastDays] = React.useState(defaultPastDays);
  const isInitialMount = useRef(true);
  const [dataFilterType, setDataFilterType] = React.useState<DataFilterType>(DataFilterType.StartEnd);
  const {t} = useTranslation();

  React.useEffect(() => {
    changeData(currentCleaner);
  }, [selectedEndDate, selectedStartDate, chosenPastDays, dataFilterType]);


  const changeData = (user: string) => {
    let filteredUserData = data.filter((el: any) => {
      return el.cleaner === user
    });
    setTimeBasedSumChartData(isInitialMount, PeriodChartFilterType.Day, dataFilterType, selectedEndDate, selectedStartDate, filteredUserData, fields, chosenPastDays, setChartData);
  }

  const handleChangeUser = (event: any, value: any) => {
    setCurrentCleaner(value)
    changeData(value);
  }

  let cleaners = getUniqueValues(data.map((el: any) => el.cleaner));

  const buildFields = () => {
    switch (dataFilterType) {
      case DataFilterType.StartEnd:
        return (
          <Grid container item direction={'row'} spacing={2}>
            <Grid item>
              <InlineDateTimePicker title={t("serviceDocumentationTable.startDate")} selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
          </Grid>
        );
      case DataFilterType.EndDatePastDays:
        return (
          <Grid container item xs={12} direction={'row'}>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 100, marginLeft: 30 }}
                inputProps={{ min: 1, style: {} }}
                id="filled-number"
                label="Tage zurück"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                value={chosenPastDays}
                onChange={(event) => { setChosenPastDays(parseInt(event.target.value)) }}
              />
            </Grid>
          </Grid>
        );
    }
  }

  return (
    <Grid container spacing={2} style={{ padding: 10, paddingBottom: 10 }}>
      <Grid item xs={12}>
        <Autocomplete
          id="combo-box-demo"
          options={cleaners}
          value={currentCleaner}
          getOptionLabel={(option: any) => option}
          onInputChange={handleChangeUser}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Servicekräfte Auswahl" variant="filled" />}
        />
      </Grid>
      <DataFilterTypeChoiceDialog dataFilterType={dataFilterType} setDataFilterType={setDataFilterType}/>
      {buildFields()}
    </Grid>
  );
})

export const getUniqueValues = (arr: string[]) => {
  return arr.reduce(
    (accumulator: any, current: any) => {
      if (current && !accumulator.some((x: any) => x === current)) {
        accumulator.push(current)
      }
      return accumulator;
    }, []
  )
}