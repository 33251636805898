import axios from 'axios';
import { UserAccountBasic } from '../../models/UserAccountLight';
import { getUserIdFromStorage } from '../../helpers/common';
import { handleApiError, headers } from '../../api';

export const getBasicUserInformationByUserIds = async (token: string, userIds : string[]): Promise<UserAccountBasic[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/basic-information`, {stringArray : userIds},{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}