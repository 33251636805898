import axios from 'axios';
import { USER_ID } from '../constants';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const getDefaultMaterialsAsync = async (token: String): Promise<string[]> => {
    try {
        let userId = getUserIdFromStorage();

        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/default-materials`,
        {    
            headers: { ...headers, 'Authorization': 'Bearer ' + token },
        })
        return ret.data;
    } catch (err) {
        handleApiError(err);
    }
    return null as any;
}