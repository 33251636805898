import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { CreateLicenseInvoiceResponse } from "../models/CreateLicenseInvoiceResponse";
import { GroupLicense } from "../models/GroupLicense";
import { UserAddress } from "../models/UserAddress";

export const createGroupLicenseInvoice = async (
    deliveryAddress: UserAddress | undefined,
    paymentAddress: UserAddress | undefined,
    licenses: Array<GroupLicense | undefined>,
    token: string,
    enqueueSnackbar: any)
    : Promise<CreateLicenseInvoiceResponse | null> => {
    try {

        let userId = getUserIdFromStorage();

        var response = await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/invoices/license`,
            {
                deliveryAddressId: deliveryAddress?.id,
                paymentAddressId: paymentAddress?.id,
                licenseIds: licenses.map(x => x?.id)
            },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        enqueueSnackbar("Rechnung wurde erfolgreich gespeichert", { variant: 'success' });

        return response.data;

    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Rechnung wurde nicht erstellt", { variant: 'error' });
        return null;
    }
}

