import { Base } from "./Base";

export class GroupTriggerBase extends Base {
    public groupId!: string;
    public createdTimestamp!: Date;
    public finishedAtTimestamp!: Date;

    public isServiceStillWithinTime!:boolean;
    public isServiceDone!: boolean;
    public reactionTime!: number;
    public roomName!: string;
    public levelName!: string;
    public notificationName!: string;
    public worksOnServiceWorkerId!: string;
    public doneByServiceWorkerId !: string;
    public doneByQualityCheckId !: string;
    public constructor(groupId: string, createdTimestamp: Date) {
        super(null);
        this.groupId = groupId;
        this.createdTimestamp = createdTimestamp;
    }

    static Columns = {
        id: 'id',
        groupId: "groupId",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        reactionTime: 'reactionTime',
        roomName: "roomName",
        levelName: "levelName",
        notificationName: "notificationName",
        worksOnServiceWorkerId: "worksOnServiceWorkerId",
        doneByServiceWorkerId: "doneByServiceWorkerId",
        finishedAtTimestamp: "finishedAtTimestamp",
        finishedAtTimestampOnlyTime: "finishedAtTimestampOnlyTime",
        services: "services",


    }

}

export class CleaningTriggerExtraServices {
    public text !: string;
    public isDone !: boolean;
    public standardItemId !: string;
}

