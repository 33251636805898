import { UserServiceWorkerObjectSession } from "./UserServiceWorkerObjectSession";

export enum ObjectCleaningTriggerInTimeState
{
    NotDefined = 0,
    Done = 1,
    Missed = 2
}
export class ObjectCleaningTrigger {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public objectId!: string;
    public userId!: string;
    public createdTimestamp!: string;
    public objectScheduleStart!: string;
    public deviationTime!: string;
    public triggerMoment!: string;
    public reactionTimeMinutes!: number;
    public objectCleaningTriggerInTimeState!: ObjectCleaningTriggerInTimeState;

    public isAllServiceWorkersMissing : boolean = false;
    public isNotEnoughServiceWorkerSessions : boolean = false;
    public minimums !: MinimumsOfObjectTrigger;


    
    static Columns = {
        objectId: 'objectId',
        userId: "userId",
        cleaningTriggerType: "cleaningTriggerType",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        objectScheduleStart: "objectScheduleStart",
        objectScheduleStartTime: "objectScheduleStartTime",
        deviationTime: "deviationTime",
        triggerMoment: "triggerMoment",
        reactionTimeMinutes: "reactionTimeMinutes",


        isAllServiceWorkersMissing : "isAllServiceWorkersMissing",
        isNotEnoughServiceWorkerSessions : "isNotEnoughServiceWorkerSessions",
        objectCleaningTriggerInTimeState: 'objectCleaningTriggerInTimeState',
        minimumTime: 'minimumTime',
        minimumServiceWorker: 'minimumServiceWorker',
        minimumServiceWorker2: 'minimumServiceWorker2',
        minimumServiceWorker3: 'minimumServiceWorker3',

        durationOfObjectSessions: 'durationOfObjectSessions',
        sessions: 'sessions'

    }
    static GetLabelByInTimeStateType = (type: ObjectCleaningTriggerInTimeState) => ObjectCleaningTrigger.AvailableInTimeStateTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
    static AvailableInTimeStateTypes = [
        
        { value: ObjectCleaningTriggerInTimeState.NotDefined, label: 'Noch nicht festgelegt. Warte bis 00:00' },
        { value: ObjectCleaningTriggerInTimeState.Done, label: 'Ja, erledigt' },
        { value: ObjectCleaningTriggerInTimeState.Missed, label: 'Nein, verpasst' },
    ];
}
interface ServiceWorkerObjectCleanerTriggerSession {
    objectId : string;
    userId : string;
    serviceWorkerId : string;
    signedInObjectSinceTimestamp : number;
    signedOutOfObjectSinceTimestamp : number;
    creationTimestamp : number;
}

interface MinimumsOfObjectTrigger {
    minimumTime : string;
    minimumServiceWorker : number;
    durationOfObjectSessions : DurationOfObjectSession[];
}

export interface DurationOfObjectSession {
    durationLoggedIn : string;
    diffTime : string;
    session : ServiceWorkerObjectCleanerTriggerSession;
    isDurationOfSessionTooShort: boolean;
}


