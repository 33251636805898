import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const deleteServiceWorkerFromObject = async (objectId: string, userServiceWorkerId: string, token: string, enqueueSnackbar: any) => {

    if (!token || !enqueueSnackbar) return;
    let userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-workers/${userServiceWorkerId}/objects/${objectId}/delete`,null,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Servicekraft wurde von dem Objekt erfolgreich gelöscht", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Fehler beim Löschen aufgetreten", { variant: 'error' });
    });
};

export const unassignServiceWorkersFromObject = async (objectId: string, userServiceWorkerIds: string[], token: string, enqueueSnackbar: any) => {

    if (!token || !enqueueSnackbar) return;
    let userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-workers/objects/${objectId}/un-assign`,{stringArray : userServiceWorkerIds},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Servicekräfte wurden von dem Objekt erfolgreich gelöscht", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Fehler beim Löschen aufgetreten", { variant: 'error' });
    });
};