
export class LoraDeviceModel {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public creationTimestamp!: string;
    public userId!: string;     
    public deviceId !: string;
    public groupId!: string;


    static Columns = {
        id : 'id',
        creationTimestamp: 'creationTimestamp',
        userId: 'userId',
        version: 'version',
        availableDisplays: 'availableDisplays',
        availableSensors: 'availableSensors',
        deviceId: 'deviceId',
        groupId: 'groupId',
        assignSensorLicense: 'assignSensorLicense',
        groupName: 'groupName',
        deleteButton: 'deleteButton',
        username: 'username'
    };


}