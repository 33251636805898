import axios from 'axios';
import { UserStock } from '../../models/UserStock';
import { getUserIdFromStorage } from '../../helpers/common';
import { headers } from '../../api';

export const createUserStock = async (userStock: UserStock, token: string, enqueueSnackbar: any): Promise<UserStock> => {
    try {
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userStock.userId}/user-stock`,
            { ...userStock },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Lagerung Speichern erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Lagerung Speichern error", { variant: 'error' });
    }
    return null as any;
}