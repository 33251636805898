import {  formatISO } from "date-fns";
import { CleaningTriggerType, GroupCleaningTrigger } from "../../models/GroupCleaningTrigger";
import { GroupQmTrigger, QmTriggerType } from "../../models/GroupQmTrigger";

export const generateQmChartData = (groupQmTriggers : GroupQmTrigger[]) => {
    let arr = groupQmTriggers.map(el => {
        return {date : el.createdTimestamp, [QmTriggerType[el.qmTriggerType]]: 1}
    });
    return arr;
}
export const generateCleaningTriggerSumChartData = (groupCleaningTriggers : GroupCleaningTrigger[]) => {
    let arr = groupCleaningTriggers.map(el => {
        return {value : 1, key: CleaningTriggerType[el.cleaningTriggerType],date : el.createdTimestamp}
    });
    return arr;
}
export const getAllQmTriggerTypes = () => {
  //  let res = Object.keys(CleaningTriggerType).filter(k => (Number(k).toString() === Number.NaN.toString()));
    return ["Planned", "Manual"];
}
export function getStringValuesFromEnum<T>(myEnum: T): (keyof T)[] {
    return Object.keys(myEnum).filter(k => typeof (myEnum as any)[k] === 'number') as any;
  }