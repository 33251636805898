import axios from 'axios';
import { headers } from '../api';
import { UserAssignedGroup } from '../models/UserAssignedGroup';


export const createUserAssignedGroup = async (userAssignedGroup: UserAssignedGroup | undefined,token: string,enqueueSnackbar:any) : Promise<UserAssignedGroup> => {
    try {
        if (!userAssignedGroup) throw(null);
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userAssignedGroup.ownerUserId}/create-assigned-group`,
        {...userAssignedGroup},
        {headers: {...headers,'Authorization': `Bearer ${token}` }})
        enqueueSnackbar("User Zugriff-Änderung erfolgreich",{variant:'success'});
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err,null,2));
        enqueueSnackbar("User Zugriff-Änderung error",{variant:'error'});
    }
    return null as any;
}