import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { NotificationTelegramGroup } from '../models/GroupSettings';

export const telegramGetGroupChatsAsync = async (phoneNumber: string, token: any, enqueueSnackbar: any): Promise<NotificationTelegramGroup[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/telegram/${phoneNumber}/groups`,
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar, "Gruppen Chats konnten nicht aufgerufen werden!");
        return [];
    }
}