import axios from 'axios';
import { headers } from '../api';
import { GROUP_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupSettings } from '../models/GroupSettings';
import { UserAccount } from '../models/UserAccount';
import IconCMN from '../assets/svgs/check-me-now_icon.png'

export const downloadQrCodeImageAsync = async (token: string): Promise<any> => {

    try {
        let groupId = localStorage.getItem(GROUP_ID);
        const userId = getUserIdFromStorage();
        const ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/qr-code`, null,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return null;
    }
}

export const downloadQrCodeImageByGroupIdAsync = async (token: string, groupId: string): Promise<any> => {

    try {
        const userId = getUserIdFromStorage();
        const ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/qr-code`, null,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return null;
    }
}

export const downloadQrCodeObjectImageAsync = async (token: string, objectId: string): Promise<any> => {

    try {
        const userId = getUserIdFromStorage();

        const ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/objects/${objectId}/qr-code`, null,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return null;
    }
}

export const downloadQrCodeObjectImageWithUrlAsync = async (token: string, objectId: string, url2: string): Promise<any> => {

    try {
        const userId = getUserIdFromStorage();

        const ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/objects/${objectId}/qr-code/url/${url2}`, null,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return null;
    }
}

export const downloadLogoImageAsync = async (row: GroupSettings, token: string): Promise<any> => {

    if (!row.logo) return;
    try {

        const ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/groups/${row.id}/images/logos/${row.logo.fileName}`,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return null;
    }
}

export const downloadUserLogoAsync = async (user: UserAccount, token: string): Promise<any> => {

    if (!user.logo) return "";
    try {

        const ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${user.id}/logos/${user.logo?.fileName}`,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return null;
    }
}

export const downloadAnyLogoImageAsync = async (filename: string, groupId: string, token: string): Promise<any> => {

    try {

        const ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/images/logos/${filename}`,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return null;
    }
}