import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { GroupSelectionSettings } from '../tables/GroupSelectionSettings';
import { GroupSelectionLoadTemplateSettings } from '../../models/GroupSelectionLoadTemplateSettings';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../api';
import { useSnackbar } from 'notistack';
import { GroupSettingsUpdateType } from '../../models/GroupSettings';
import { updateGroupSettingsByTemplate } from '../../api/UpdateGroupSettings';
import { SaveAlt, SettingsSuggest } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { GroupSettingsTemplateOptionChooserDialog } from './template/GroupSettingsTemplateOptionChooserDialog';
import { GroupSettingsTemplateOption } from '../../models/GroupSettingsTemplateOption';
import { GroupSelectionTable } from '../tables/GroupSelectionTable';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { useTranslation } from 'react-i18next';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const GroupSelectionTemplateSettingsLoadDialog = ({ open, setOpen, currentSelectedGroupSettingsLoadRow, groups, token }: { open: boolean, setOpen: any, currentSelectedGroupSettingsLoadRow: any, groups: GroupLicenseTable[], token: string }) => {

  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [templateOptions, setTemplateOptions] = React.useState<GroupSettingsTemplateOption>(new GroupSettingsTemplateOption());
  const [count, setCount] = React.useState(0);
  const [isFinishPressed, setIsFinishedPressed] = React.useState(false);

  const [openTemplateOptionDialog, setOpenTemplateOptionDialog] = React.useState(false);
  const {t} = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <div>
      <Dialog fullWidth maxWidth={"xl"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          Lade Vorlage {currentSelectedGroupSettingsLoadRow && currentSelectedGroupSettingsLoadRow.name} auf Einheiten
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <GroupSelectionTable
            groups={groups}
            selectedGroups={selectedGroups}
            onSelectionChange={(selection: string[]) => {
              setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button size="large"
            startIcon={<CancelIcon />} variant={'outlined'} onClick={handleClose} color="primary">
            {t("myUnitsSettings.cancel")}
          </Button>
          <Button size="large"
            startIcon={<SettingsSuggest />} variant={'contained'}
            disabled={loading}
            endIcon={loading && <CircularProgress size={32} />}
            onClick={async () => {
              setOpenTemplateOptionDialog(true);
            }} color="primary">
            Vorlagen Optionen
          </Button>
          <Button size="large"
            startIcon={<SaveAlt />} variant={'contained'}
            disabled={selectedGroups.length < 1 || loading}
            endIcon={loading && <CircularProgress size={32} />}
            onClick={async () => {
              setOpenTemplateOptionDialog(true);
              setIsFinishedPressed(true);
            }} color="primary">
            Auf {loading ? count : selectedGroups.length} Einheit Zuweisen
          </Button>
        </DialogActions>
        <GroupSettingsTemplateOptionChooserDialog onFinish={async () => {
          if (!isFinishPressed) return;
          let templateId = currentSelectedGroupSettingsLoadRow.id;
          setLoading(true);
          setCount(selectedGroups.length);
          for (let el of selectedGroups) {
            try {
              let group = groups.find(x => x.id == el.id);
              enqueueSnackbar(`Vorlage ${currentSelectedGroupSettingsLoadRow.name} auf Einheit ${group?.notificationName} zuweisen`, { variant: 'info' });
              await updateGroupSettingsByTemplate({ ...currentSelectedGroupSettingsLoadRow }, token, enqueueSnackbar, templateId, el.id, templateOptions);
              setSelectedGroups((selectedGroups) => selectedGroups.filter(x => el.id != x.id));
              setCount(prev => prev - 1);
            } catch {
            }
          }
          setLoading(false);
          handleClose();
        }} open={openTemplateOptionDialog} setOpen={setOpenTemplateOptionDialog} templateOptions={templateOptions} setTemplateOptions={setTemplateOptions} />
      </Dialog>
    </div>
  );
}