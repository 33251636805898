import axios from "axios";
import { handleApiError, headers } from "../../api";
import { getUserIdFromStorage } from "../../helpers/common";
import { GroupFeedback } from "../../models/GroupFeedback";

export const getFeedbacksByObjectId = async (token: string, objectIds: string[]): Promise<Array<GroupFeedback>> => {

    try {

        let userId = getUserIdFromStorage();

        if (!userId) return [];

        let ret = await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/feedbacks-by-objects`,{stringArray: objectIds},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return ret.data;

    } catch (err) {
        handleApiError(err);
        return [];
    }
}