import { GroupServiceModeIncident } from "./GroupServiceMode";

export enum IncidentOriginType {
    Device = 0,
    Public = 1
}
export class GroupIncidentVisitor {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public items!: GroupServiceModeIncident [];
    public createdTimestamp!: number;
    public customTexts !: GroupServiceModeIncident [];
    public messenger!: string;
    public incidentOriginType !: IncidentOriginType;
    public createdTimestampString !: string;

    public constructor (groupId: string, userId: string, items: GroupServiceModeIncident[], createdTimestamp: number, customTexts: GroupServiceModeIncident[], messenger: string) {
        this.systemETag = null;
        this.groupId = groupId;
        this.userId = userId;
        this.items = items;
        this.createdTimestamp = createdTimestamp;
        this.customTexts = customTexts;
        this.messenger = messenger;
    }

    static AvailableTypes = [
        
        { value: IncidentOriginType.Device, label: 'Display Gerät' },
        { value: IncidentOriginType.Public, label: 'Öffentlich' },


    ];

    static Columns = {
        id: 'id',
        groupId: "groupId",
        userId: "userId",
        items: "items",
        createdTimestamp: "createdTimestamp",
        createdTime: "createdTime",
        neutralAmount: "neutralAmount",
        customTexts: "customTexts",
        messenger: "messenger",
        incidentOriginType : "incidentOriginType"
    }

    static GetLabelByType = (type: IncidentOriginType) => GroupIncidentVisitor.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
}