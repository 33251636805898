import React from 'react';
import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AccountCircle, NavigateNext, Save, SwitchCamera, Window } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { UserProperty } from '../../../../models/UserProperty';
import { StringHelper } from '../../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../../styles/usePortalAccountStyles';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { ToiletType } from '../../../../models/GroupSettings';
import { useHistory } from 'react-router-dom';
import { GroupSelectionAssignGroupToObjectDialog } from './GroupSelectionAssignGroupToObjectDialog';
import { GroupSelectionAssignGroupToPropertyDialog } from './GroupSelectionAssignGroupToPropertyDialog';
import { GroupSelectionAssignGroupToServiceWorkerDialog } from './GroupSelectionAssignGroupToServiceWorkerDialog';
import AccordionForStringArray from '../../../common/AccordionForStringArray';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { GroupSettingsPage } from '../../portal/Dashboard/settings/GroupSettings';
import { useTranslation } from 'react-i18next';

export const GroupSettingsQuickEditDialog = ({ visible, setVisible, group,onRefresh }
    : { visible: boolean, setVisible: any, group: GroupLicenseTable,  onRefresh:any }) => {


    const [currentGroup, setCurrentGroup] = React.useState<GroupLicenseTable>(new GroupLicenseTable());
    const {t} = useTranslation();

    const onClose = () => {
        setCurrentGroup(new GroupLicenseTable());
        setVisible(false);
        onRefresh();
    }




    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title={`${t("myUnitsSettings.unit")} ${group.notificationName} ${t("myUnitsSettings.edit")}`}
                handleClose={onClose}
            />

            <DialogContent>
                <GroupSettingsPage />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.close")}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
