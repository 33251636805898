import { PeriodChartFilterType } from "../helpers/date-management";
import { GroupSettingsLogo } from "./GroupSettings";

export class UserSavedCombinationGroup {
    public id!: string;
    public userId!: string;
    public groupIds! : string[];
    public name! : string;
    public logo! : GroupSettingsLogo;
    public customDashboardId !: string;
    public startDateEval : Date = new Date();
    public endDateEval : Date = new Date();
    public isCustomDateEnabled !: boolean;
    public includeHours !: boolean;
    public periodChartFilterType !: number;
    public systemETag : string = "";
    constructor(id : string, userId: string, groupIds: string[], name: string){
        this.id = id;
        this.userId = userId;
        this.groupIds = groupIds;
        this.name = name;
    }
}