import { Grid, Paper } from "@mui/material"
import React from "react"
import { GroupServiceCompletedCleaningTasks } from "../../../../../models/GroupServiceMode"
import { elevationPaper, paperStyle } from "../../../../../styles/container"
import { RotatedBarChart } from "../../../../charts/RotatedBarChart"
import { TimeChoice } from "../../../../core/TimeChoice"
import { useTranslation } from "react-i18next"

export const CleaningTasksCompletedDashboard = ({ data, dataName1, dataName2, allData, setChartData, childRef, loadingCompleted, childRefChart }
    : { data: GroupServiceCompletedCleaningTasks[], dataName1: any, dataName2: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any }) => {
    
    const {t} = useTranslation();
    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        <RotatedBarChart
                            childRefChart={childRefChart}
                            data={data}
                            dataField1={'isCompleted'}
                            dataField2={'isIncomplete'}
                            text={t("servicedocumentationChart.serviceModePartialAll")}
                            loadingCompleted={loadingCompleted} />
                    </Grid>
                    <Grid item>
                        <TimeChoice
                            ref={childRef}
                            fields={[dataName1, dataName2]}
                            data={allData}
                            setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}