import axios from 'axios';
import { handleApiError, headers } from '../api';
import { StripeOrderDetails } from "../models/StripeOrderDetails";
import { getUserIdFromStorage } from '../helpers/common';
import { InvoicePaymentOrderDetails } from '../models/InvoicePaymentOrderDetails';
import { Product } from '../models/Product';
import { GroupLicense } from '../models/GroupLicense';

export const orderByAdminWithoutInvoice = async ( product: Product, userId: string, token: string, enqueueSnackbar: any): Promise<GroupLicense> => {

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/order-by-admin-without-invoice`,
            product,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar(`Lizenz hinzugefügt ${product.name}`, { variant: 'success' });
           return res.data;
        })
        .catch(error => {
            enqueueSnackbar(`Lizenz error ${product.name}`, { variant: 'success' });
            handleApiError(error);
            return null;
        });
};
