import axios from 'axios';
import { headers } from '../api';
import { getFilterBetweenSpecifiedTimeBackend, getQueryParamFromApiDataAmountType, getQueryParamsAdminDataFilter, getQueryParamsTimeBackendFilter } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupFeedback } from '../models/GroupFeedback';

export const GetFeedbacksByGroupIds = async (token: string, groupIds: string[]): Promise<Array<GroupFeedback>> => {

    const userId = getUserIdFromStorage();


    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/groups/feedback?${getQueryParamFromApiDataAmountType()}&${getQueryParamsTimeBackendFilter()}&${getQueryParamsAdminDataFilter()}`,{StringArray: groupIds},
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}