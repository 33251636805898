import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';
import { UserAddress } from '../models/UserAddress';
import { ObjectLeaderQualityCheckModel } from '../models/ObjectLeaderQualityCheckModel';

export const getQualityChecksByServiceWorkerId = async (token: string, userServiceWorkerId: string): Promise<ObjectLeaderQualityCheckModel[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/service-workers/${userServiceWorkerId}/quality-checks`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}