import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, ManageAccounts, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable, SensorLicense } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { BatteryDisplaySelectionTable } from './BatteryDisplaySelectionTable';
import { ReactComponent as MotionSensorSvg } from '../../../../assets/svgs/motion-sensor.svg';
import { AssignLicenseChoosingTableDialog, ShowCountType } from '../../../dialogs/AssignLicenseChoosingTableDialog';
import { LicenseType } from '../../../../models/LicenseType';
import { GroupLicense } from '../../../../models/GroupLicense';
import { AssignSensorLicenseChoosingTableDialog } from '../../../dialogs/AssignSensorLicenseChoosingTableDialog';
import { useTranslation } from 'react-i18next';

export enum BatteryDisplayDialogChoice {
  ExtendBatteryDevice = 0,
  ExtendSensor = 1,
  None = 255
}

export const BatteryDisplaySelectionDialog = ({
  open,
  setOpen,
  groups,
  onEdit,
  onNavigate,
  freeLicenses,
  loadData,
  dialogChoice = BatteryDisplayDialogChoice.None,
  preSelectedGroup = null
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable[],
  onEdit: any,
  onNavigate: any,
  freeLicenses: GroupLicense[],
  loadData: any,
  dialogChoice: BatteryDisplayDialogChoice,
  preSelectedGroup?: GroupLicenseTable | null
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [token, setToken] = React.useState("");
  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);


  const [assignBssdExtendedLicenseDialogVisible, setAssignBssdExtendedLicenseDialogVisible] = React.useState(false);
  const [currentSelectedBssdGroupId, setCurrentSelectedBssdGroupId] = React.useState<string>("");
  const [assignBssdSensorLicenseDialogVisible, setAssignBssdSensorLicenseDialogVisible] = React.useState(false);
  const [currentSelectedBssdGroupLicenseTable, setCurrentSelectedBssdGroupLicenseTable] = React.useState<GroupLicenseTable>();
  const [selectedLicense, setSelectedLicense] = React.useState<GroupLicense>();
  const [assignBssdExtendedSensorLicenseChooserDialogVisible, setAssignBssdExtendedSensorLicenseChooserDialogVisible] = React.useState(false);
  const {t} = useTranslation();

  const handleClose = () => setOpen(false);

  const onExtendLicense = async (group: GroupLicenseTable) => {
    setCurrentSelectedBssdGroupId(group.id);
    setCurrentSelectedBssdGroupLicenseTable(group);
    setAssignBssdExtendedLicenseDialogVisible(true);
  }

  const extendBssdSensorLicense = async (group: GroupLicenseTable) => {
    setCurrentSelectedBssdGroupId(group.id);
    setCurrentSelectedBssdGroupLicenseTable(group);
    setAssignBssdSensorLicenseDialogVisible(true);
  }

  const assignBssdLicenseWithSensor = async (selectedLicense: GroupLicense, setVisibleDialog: any) => {
    if (!selectedLicense || !currentSelectedBssdGroupId) return;

    setVisibleDialog(false);
    if (selectedLicense.batterySmartServiceDisplaySensorCount > 0) {
      setSelectedLicense(selectedLicense);
      setAssignBssdExtendedSensorLicenseChooserDialogVisible(true);
    } else {
      await assignBssdLicense(selectedLicense);
    }
  }

  const assignBssdLicense = async (selectedLicense: GroupLicense, setVisibleDialog: any = null) => {
    if (!selectedLicense || !currentSelectedBssdGroupId) return;

    if (setVisibleDialog) setVisibleDialog(false);

    await api.assignLicenseToGroup(currentSelectedBssdGroupId, selectedLicense, token, enqueueSnackbar);
    await loadData();
  }

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const tempObjects = await api.getUserObjectUnits(tempToken);
      setObjects(tempObjects);

      setSelectedGroups(preSelectedGroup ? [preSelectedGroup] : []);

      if (preSelectedGroup) {
        switch (dialogChoice) {
          case BatteryDisplayDialogChoice.ExtendSensor:
            setAssignBssdSensorLicenseDialogVisible(true);
            break;
          case BatteryDisplayDialogChoice.ExtendBatteryDevice:
            onExtendLicense(preSelectedGroup);
            break;
        }
      }

    })();

  }, [open]);

  const disabled = selectedGroups.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Batterie Smart-Service-Displays Anzeige`}
        handleClose={handleClose}
      />

      <DialogContent>
        <BatteryDisplaySelectionTable
          onEdit={onEdit}
          onExtendLicense={onExtendLicense}
          extendBssdSensorLicense={extendBssdSensorLicense}
          onNavigate={onNavigate}
          groups={groups} objects={objects}
          selectedGroups={selectedGroups}
          onSelectionChange={(selection: string[]) => {
            let selected = selection[selection.length - 1];
            setSelectedGroups(groups.filter(x => selected === x.id));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
      </DialogActions>
      <AssignLicenseChoosingTableDialog
        showCountType={ShowCountType.BssdSensor}
        visible={assignBssdExtendedLicenseDialogVisible}
        licenses={freeLicenses.filter(x => x.type === LicenseType.BatteryDisplay)}
        onClose={() => {
          setAssignBssdExtendedLicenseDialogVisible(false);
        }}
        onLicenseSelected={async (selectedLicense: GroupLicense) => {
          assignBssdLicenseWithSensor(selectedLicense, setAssignBssdExtendedLicenseDialogVisible);
        }}
      />
      <AssignLicenseChoosingTableDialog
        showCountType={ShowCountType.BssdSensor}
        visible={assignBssdSensorLicenseDialogVisible}
        licenses={freeLicenses.filter(x => x.type === LicenseType.BatterySensor)}
        onClose={() => {
          setAssignBssdSensorLicenseDialogVisible(false);
        }}
        onLicenseSelected={async (selectedLicense: GroupLicense) => {
          assignBssdLicenseWithSensor(selectedLicense, setAssignBssdSensorLicenseDialogVisible);
        }}
      />
      {currentSelectedBssdGroupLicenseTable && selectedLicense && currentSelectedBssdGroupLicenseTable.sensorLicenses &&
        <AssignSensorLicenseChoosingTableDialog
          visible={assignBssdExtendedSensorLicenseChooserDialogVisible}
          licenses={currentSelectedBssdGroupLicenseTable.sensorLicenses}
          currentSelectedLicense={selectedLicense}
          sensorCount={selectedLicense.batterySmartServiceDisplaySensorCount}
          onClose={() => {
            setAssignBssdExtendedSensorLicenseChooserDialogVisible(false);
          }}
          onLicenseSelected={async (selectedSensorLicenses: SensorLicense[]) => {
            selectedLicense.sensorLicenses = selectedSensorLicenses;
            if (selectedLicense) await assignBssdLicense(selectedLicense, setAssignBssdExtendedSensorLicenseChooserDialogVisible);
          }}
        />
      }
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));