import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, FolderCopy, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../../../../api';
import { getApiToken } from '../../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { errorColor, white } from '../../../../../styles/colors';
import { DefaultDialogTitle } from '../../../../dialogs/DefaultDialogTitle';
import { ObjectSelectionDataTable } from '../../../extendedapp/entireEval/ObjectSelectionDataTable';
import { useTranslation } from 'react-i18next';

export const ObjectSelectionCopySharedFolderStructureDialog = ({
  open,
  setOpen,
  groups,
  onFinish
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable[],
  onFinish:any
}) => {

  const history = useHistory();

  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [token, setToken] = React.useState("");
  const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);
  const [loading, setLoading] = React.useState(false);
  const {t} = useTranslation();

  const handleClose = () => {
    setSelectedObjects([]);
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const tempObjects = await api.getUserObjectUnits(tempToken);
      setObjects(tempObjects);

    })();

  }, [open]);

  const disabled = selectedObjects.length < 1 || loading;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Kopiere Ordner Struktur auf {selectedObjects.length} Objekte</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <ObjectSelectionDataTable rows={objects} groups={groups} currentGroup={null}
          selectedObjects={selectedObjects}
          onSelectionChange={(selection: string[]) => {
            setSelectedObjects(objects.filter(x => selection.includes(x.id)));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={loading && <CircularProgress size={32}/>}
          endIcon={<FolderCopy />}
          disabled={disabled}
          onClick={async () => {
            setLoading(true);
            await onFinish(selectedObjects);
            setLoading(false);
            handleClose();
          }}
        >
          Kopieren
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));