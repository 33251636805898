import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupSettings } from '../models/GroupSettings';

export const GetGroupSettingsByUserAsync = async (token: string): Promise<GroupSettings[]> => {

    const userId = getUserIdFromStorage();

    return await axios
        .get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/user-groups-settings`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
}

export const GetGroupSettingsByUserGroupIdsAsync = async (token: string, userId: string, groupIds: string[]): Promise<GroupSettings[]> => {

    return await axios
        .post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/user-groups-settings`,{stringArray : groupIds},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
}