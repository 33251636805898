import { Chart, Connector, Label, Series, Size } from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { getColorOfSmileQM } from '../../../../dialogs/tables/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageTable';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});

export const BuildStandardBarChartSimple = ({ dataSource, name = "QM Einheiten Gesamt", height = 800 }: { dataSource: any[], name?: string, height?: number }) => {

    return (
        <Chart id="chart" rotated={true} dataSource={dataSource} customizePoint={(pointInfo) => {
            let color = getColorOfSmileQM(pointInfo.value);
            return { color: color, hoverStyle: { color: color } };
        }}>
            <Size
                height={height}
                width={'100%'}
            />
            <Series
                valueField="valueFieldService"
                argumentField="argumentFieldService"
                name={name}
                type="bar" >
                <Label visible={true}>
                    <Connector visible={true} />
                </Label>
            </Series>
        </Chart>
    );
}

export const StandardBarChartSimpleColorFeed = withStyles(demoStyles, { name: 'Demo' })(BuildStandardBarChartSimple);