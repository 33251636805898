import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Checkbox, CircularProgress, Divider, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Slider, Switch, TextField, Typography } from "@mui/material";
import { Add, CloudUpload, ExpandMore, Visibility, Info, AddBox, Remove, RestoreFromTrash, DeleteForever, Delete, SwitchCamera, AssignmentReturn, ManageAccounts, FindReplace } from "@mui/icons-material";
import { Content, ContentCode, ContentCombinationItem, ContentNews, ContentWeather, GroupSettings, GroupSettingsLogo, GroupSettingsType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { api } from "../../api";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getApiToken } from "../../helpers/storage-management";
import { diffDays } from "../../helpers/date-management";
import { GroupLogoDialog } from "../dialogs/GroupLogoDialog";
import { Group } from "../../models/Group";
import { GroupLogosDialog } from "../dialogs/GroupLogosDialog";
import { UserLogo } from "../../models/UserLogo";
import { ProgressDialogComponent } from "./components/ProgressDialogComponent";
import { useTranslation } from "react-i18next";

export const GroupSettingFieldsLogo = ({ row, onChange, type, group, showTip = false, onRefresh }
    : { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, type: GroupSettingsType, group: Group | undefined, showTip?: boolean, onRefresh?: any }) => {
    const classes = GroupSettingFieldsUseStyles();
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [token, setToken] = React.useState("");
    const [GroupLogoDialogIsOpen, setOpenGroupLogoDialog] = React.useState(false);
    const [groupLogosDialogIsOpen, setOpenGroupLogosDialog] = React.useState(false);
    const [logos, setLogos] = React.useState<UserLogo[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [isUploadingFiles, setIsUploadingFiles] = React.useState(false);
    const [progressData, setProgressData] = React.useState();
    const {t} = useTranslation();

    const updateLogo = async (file: File) => {

        setIsUploadingFiles(true);
        const newGroupSettings = await api.updateGroupLogo(file, row, type, token, enqueueSnackbar, setProgressData);
        onChange({ target: { name: 'logo', value: JSON.stringify({ systemETag: newGroupSettings.systemETag, logo: newGroupSettings.logo }) } });
        if (onRefresh){
            onRefresh(newGroupSettings.logo?.fileName);
        }
        setIsUploadingFiles(false);
    }

    const onLogoFileChosen = async (event: any) => {
        if (!row) return;

        const file = event.target.files[0];
        updateLogo(file);

        if (event.onChanged)
            event.onChanged();
    }

    const onLogoFileDelete = async () => {
        if (!row) return;

        onChange({ target: { name: 'logo', value: JSON.stringify({ systemETag: null, logo: new GroupSettingsLogo() }) } });

        enqueueSnackbar("Logo Greenbird wird angewendet. Nicht Speichern vergessen!", { variant: 'success' });

    }


    React.useEffect(() => {

        const apiToken = getApiToken(history);
        setToken(apiToken);

    }, []);


    return (

        <Grid container item>

            <Grid container xs={12} item direction="row" spacing={1} alignItems="center">
                <Grid item md={3}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUpload />}
                        component="label">
                        {t("myUnitsSettings.uploadLogo")}
                        <input
                            type="file"
                            name="updateLogo"
                            onChange={onLogoFileChosen}
                            hidden
                            accept="image/png, image/jpeg, image/jpg" />
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <Button
                        size="small"
                        startIcon={<Visibility />}
                        variant={'contained'}
                        onClick={() => setOpenGroupLogoDialog(true)}
                        color="primary">
                        {t("myUnitsSettings.viewLogo")}
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <Button
                        size="small"
                        startIcon={loading ? <CircularProgress color="inherit" /> : <FindReplace />}
                        variant={'contained'}
                        disabled={loading}
                        onClick={async () => {
                            if (!row.id) return;
                            setLoading(true);
                            let logos = await api.getLogosOfGroup(token);
                            setLoading(false);
                            setLogos(logos);
                            setOpenGroupLogosDialog(true);

                            logos.forEach(async (logo) => {
                                let res = await api.downloadAnyLogoImageAsync(logo.filename, logo.groupId, token);
                                logo.objectUrl = res;
                                setLogos([...logos]);
                            })


                        }}
                        color="primary">
                        {t("myUnitsSettings.viewSavedLogos")}
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <Button
                        size="small"
                        startIcon={<SwitchCamera />}
                        variant={'contained'}
                        onClick={() => onLogoFileDelete()}
                        color="primary">
                        {t("myUnitsSettings.useStandardLogo")}
                    </Button>
                </Grid>
                {showTip &&
                    <Grid item>
                        <Alert severity="info">{t("myUnitsSettings.standardOrServiceProviderInformation")}</Alert>
                    </Grid>
                }
            </Grid>


            <Grid item hidden>
                <GroupLogoDialog
                    groupSettings={row}
                    type={type}
                    open={GroupLogoDialogIsOpen}
                    onClose={() => setOpenGroupLogoDialog(false)} />
            </Grid>
            <GroupLogosDialog logos={logos} visible={groupLogosDialogIsOpen}
                onConfirmed={async (item: UserLogo) => {
                    setOpenGroupLogosDialog(false);
                    let blob = await fetch(item.objectUrl).then(r => r.blob());
                    const file = new File([blob], row.locationInformation?.notificationName || "test", { type: blob.type })
                    updateLogo(file);
                }}
                onClose={() => {
                    setOpenGroupLogosDialog(false);
                }
                }
                onDelete={async (item: UserLogo) => {
                    let settings = await api.getGroupSettings(item.groupId, token);
                    if (settings.logo?.fileName == item.filename) {
                        enqueueSnackbar("Logo wird gerade von der Einheit verwendet! Kann nicht gelöscht werden", { variant: 'error' });
                        return;
                    }
                    await api.deleteGroupLogoAsync(item.groupId, item.filename, token, enqueueSnackbar)
                }}
            />
            <ProgressDialogComponent progressData={progressData} open={isUploadingFiles} setOpen={setIsUploadingFiles} title={"Logo wird hochgeladen. Bitte warten!"} />
        </Grid>
    );
}