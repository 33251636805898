import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';
import { UserAddress } from '../models/UserAddress';
import { UserProperty } from '../models/UserProperty';

export const inviteUserServiceWorkerToTelegram = async (phoneNumber: string, token: string, enqueueSnackbar: any): Promise<UserServiceWorker> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/phone-number/${phoneNumber}/invite-to-telegram`,
            null,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Telegram Einladung erfolgreich gesendet", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Telegram Einladung error", { variant: 'error' });
    }
    return null as any;
}