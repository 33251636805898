import axios from 'axios';
import { headers, showDefaultError } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { UserAddress } from '../models/UserAddress';

export const createObjectSharedFolderAsync = async (objectId: string, objectIds: string[], name: string, token: string, enqueueSnackbar: any): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/users/${userId}/shared-folder`,
            { stringArray: objectIds, text: name },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Geteilter Ordner Speichern erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err: any) {
        showDefaultError(err,enqueueSnackbar);
        enqueueSnackbar("Geteilter Ordner Speichern error", { variant: 'error' });
    }
    return null as any;
}

