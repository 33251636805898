import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@mui/material';
import { DefaultDialogTitle } from './DefaultDialogTitle';
import { ObjectSharedFolderDashboard } from '../authorized/portal/object/ObjectSharedFolderDashboard';
import { DrawerMenuItemDisplayType } from '../authorized/ObjectMenuPointDrawer';
import { useTranslation } from 'react-i18next';
export const ObjectSharedFolderDashboardDialog = ({ visible, setVisible }
    : { visible: boolean, setVisible: any }) => {

    const onClose = () => {
        setVisible(false);
    }
    const {t} = useTranslation();

    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title={t("myObjectSettings.objectData")}
                handleClose={onClose}
            />

            <DialogContent>
                <ObjectSharedFolderDashboard  displayType={DrawerMenuItemDisplayType.All}/>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
            </DialogActions>
        </Dialog >
    );
};