import { DialogTitle, IconButton, Theme, Typography, AppBar, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';

export const DefaultDialogTitle = ({ title, handleClose }: { title?: any, handleClose?: () => void }) => {

    const classes = useStyles();

    return (
        <DialogTitle className={classes.root}>
            <AppBar position="sticky">
                <Toolbar>
                    <Typography className={classes.title} component="div" variant="h6">{title || ''}</Typography>
                    {
                        handleClose &&
                        <IconButton color="inherit" onClick={handleClose} size="large">
                            <Close />
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
        </DialogTitle>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 0,
    },
    title: {
        flexGrow: 1,
    }
}));
