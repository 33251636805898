import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { DialogActions, DialogContent, Fab, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../../helpers/storage-management';
import { CloudUpload, Remove, Shuffle } from '@mui/icons-material';
import { errorColor, white } from '../../../../styles/colors';
import { useTranslation } from 'react-i18next';

export const AddFileDialog = ({ open, setOpen, onAdd }
    : { open: boolean, setOpen: any, onAdd: any }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [text, setText] = React.useState("");
    const [files, setFiles] = React.useState<(File | null)[]>([]);
    const {t} = useTranslation();
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    {t("shared-folders-objects-all.addFile")}
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container direction={'row'} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                startIcon={<CloudUpload />}
                                component="label">
                                Datei hochladen
                                <input
                                    type="file"
                                    name="uploadAdvertisements"
                                    onChange={(event) => {
                                        if (!event.target.files) return;
                                        const newFiles = Object.keys(event.target.files).map((x: any) => event.target.files ? event.target.files[x] : null);
                                        setFiles([...files, ...newFiles]);
                                    }
                                    }
                                    hidden
                                    multiple />
                            </Button>
                        </Grid>
                        {files.map(x => {
                            return (
                                <>
                                    <Grid item xs={8}>
                                        <TextField
                                            style={{ width: '100%' }}
                                            id="filled-number"
                                            label="Dateiname"
                                            variant="filled"
                                            value={x?.name}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Fab
                                            size="small"
                                            style={{ backgroundColor: errorColor, color: white }}
                                            onClick={() => {
                                                setFiles(files.filter(y => y?.name != x?.name));
                                            }}
                                        >
                                            <Remove />
                                        </Fab>
                                    </Grid>
                                </>
                            )
                        })}

                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>

                    <Button
                        style={{marginRight: 'auto'}}
                        size="large"
                        startIcon={<Shuffle />}
                        variant={'contained'}
                        color="primary"
                        onClick={async () => {
                            var file = new File(["d"], "dummy.txt", {
                                type: "text/plain",
                              });

                            onAdd([file]);
                            setFiles([]);
                            handleClose();
                        }}
                    >
                        Dummy-File verwenden!
                    </Button>

                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>

                    <Button
                        size="large"
                        startIcon={<AddBoxIcon />}
                        variant={'contained'}
                        color="primary"
                        onClick={async () => {
                            onAdd(files);
                            setFiles([]);
                            handleClose();
                        }}
                    >
                        Hochladen!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));