import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ExpandLess, ExpandMore, Help, Search } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { Collapse, Grid, IconButton, ListItemSecondaryAction, Typography, useTheme } from '@mui/material';
import { UserAccount } from '../../models/UserAccount';
import { trimPathname } from '../../helpers/common';
import { subHeaderTextAdmin, UserItems } from './DrawerList';
import { useTranslation } from 'react-i18next';


export const AdminUserChoice = ({ classes, openSearchDialog, user, selectedUserFromAdmin, open, setOpen }: { classes: any; openSearchDialog: any; user: UserAccount; selectedUserFromAdmin: UserAccount | undefined; open: boolean; setOpen: any }) => {

    const theme = useTheme();

    let showContent = !!selectedUserFromAdmin;
    let { pathname } = useLocation();
    pathname = trimPathname(pathname);

    const handleClick = () => setOpen(!open);
    const { t } = useTranslation();

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <ListItem role={undefined} button onClick={showContent ? handleClick : openSearchDialog} className="white-font" style={{ backgroundColor: theme.palette.primary.main }}>
                {showContent ?
                    <ListItemIcon className="white-font">
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon> : <ListItemIcon className="white-font"><Help /></ListItemIcon>}
                <Grid container direction={"row"} justifyContent={"space-between"} alignContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h5"}>{t(subHeaderTextAdmin)}</Typography>
                        {!!selectedUserFromAdmin ?
                            <Fragment>
                                <Typography style={{ fontSize: 16 }}>{t("drawerList.selectedUser")}</Typography>
                                <Typography style={{ fontSize: 16 }}>{selectedUserFromAdmin.mail}</Typography>
                                <Typography style={{ fontSize: 16 }}> {selectedUserFromAdmin.firstname} {selectedUserFromAdmin.lastname}</Typography>
                            </Fragment> :
                            <Fragment>
                                <Typography style={{ fontSize: 16 }}>Kein User ausgewählt</Typography>
                            </Fragment>}
                    </Grid>
                </Grid>
                <ListItemSecondaryAction>
                    <IconButton style={{ color: "white" }} onClick={openSearchDialog} size="large">
                        <Search />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            {showContent &&
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <UserItems pathname={pathname} user={user} />
                </Collapse>}
        </List>
    );
};
