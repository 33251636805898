import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';
import { UserAddress } from '../models/UserAddress';
import { UserServiceWorkerObjectSession } from '../models/UserServiceWorkerObjectSession';
import { ObjectCleaningTrigger } from '../models/ObjectCleaningTrigger';
import { StandardWorkItem, StandardWorkItemType } from '../models/StandardItem';

export const GetStandardWorkItems = async (token: string): Promise<StandardWorkItem[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/standardworkitems`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;
}

export const GetAdminStandardWorkItems = async (token: string): Promise<StandardWorkItem[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/standardworkitems-admin`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;
}

export const GetStandardWorkItemsByType = async (token: string, type:  StandardWorkItemType, userId: string | null = ""): Promise<StandardWorkItem[]> => {
    try {
        if (!userId) userId = getUserIdFromStorage();
        if (token){
            let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/types/${type}/standardworkitems`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
            return ret.data;
        }else{
            let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/types/${type}/public-standardworkitems`, { headers: { ...headers } })
            return ret.data;
        }

    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}

export const GetAdminStandardWorkItemsByType = async (token: string, type:  StandardWorkItemType): Promise<StandardWorkItem[]> => {
    try {
        let userId = getUserIdFromStorage();
        if (token){
            let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/types/${type}/standardworkitems-admin`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
            return ret.data;
        }

    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}