import axios from 'axios';
import { headers, handleApiError } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { GridStackCustom } from '../../models/GridStackCustom';

export const getUsergridStackCustoms = async (token: string): Promise<GridStackCustom[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/grid-stack-customs`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}