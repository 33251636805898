import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { UserAddress } from '../../../../models/UserAddress';
import { Button } from '@mui/material';
import { ArrowDownward, Save } from '@mui/icons-material';
import { api } from '../../../../api';
import { useSnackbar } from 'notistack';
import { UserAdressesDialog } from '../../../dialogs/UserAddresses';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../../helpers/storage-management';
import { getUserIdFromStorage } from '../../../../helpers/common';
import CountrySelect from '../../../common/CountryPicker';
import { useTranslation } from 'react-i18next';

export const AddressForm = ({ address, setAddress, title, userAddresses, setUserAddresses }
  : { address?: UserAddress | null | undefined, setAddress: any, title: string, userAddresses: UserAddress[], setUserAddresses: React.Dispatch<React.SetStateAction<UserAddress[]>> }) => {

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [saving, setSaving] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const {t} = useTranslation();

  React.useEffect(() => {
    (async () => {
      const tokenTemp = getApiToken(history);
      if (!tokenTemp) return;
      let user = await api.getUser(tokenTemp, getUserIdFromStorage());
      setAddress({ ...address, firstName: user.firstname, lastName: user.lastname, company: user.company, uid: user.uid, atu: user.atu });

    })();

  }, [])

  console.log(address?.company, address?.company != undefined);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <TextField
            id="company"
            name="company"
            label="Firma"
            value={address?.company}
            InputLabelProps={{ shrink: address?.company != undefined }}
            onChange={(event) => setAddress({ ...address, company: event.target.value })}
            fullWidth
            autoComplete="company"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            id="uid"
            name="uid"
            label="UID"
            value={address?.uid}
            InputLabelProps={{ shrink: !!address?.uid }}
            onChange={(event) => setAddress({ ...address, uid: event.target.value })}
            fullWidth
            autoComplete="uid"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            id="atu"
            name="atu"
            label="FN"
            value={address?.atu}
            InputLabelProps={{ shrink: !!address?.atu }}
            onChange={(event) => setAddress({ ...address, atu: event.target.value })}
            fullWidth
            autoComplete="atu"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Vorname"
            value={address?.firstName}
            InputLabelProps={{ shrink: !!address?.firstName }}
            onChange={(event) => setAddress({ ...address, firstName: event.target.value })}
            fullWidth
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Nachname"
            InputLabelProps={{ shrink: !!address?.lastName }}
            value={address?.lastName}
            onChange={(event) => setAddress({ ...address, lastName: event.target.value })}
            fullWidth
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Straße, Hausnummer, Gebäude, Etage usw."
            InputLabelProps={{ shrink: !!address?.addressLine }}
            value={address?.addressLine}
            onChange={(event) => setAddress({ ...address, addressLine: event.target.value })}
            fullWidth
            autoComplete="shipping address-line1"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label={t("myUnitsSettings.city")}
            InputLabelProps={{ shrink: !!address?.city }}
            value={address?.city}
            defaultValue=""
            onChange={(event) => setAddress({ ...address, city: event.target.value })}
            fullWidth

            autoComplete="shipping address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label={t("myUnitsSettings.state")}
            InputLabelProps={{ shrink: !!address?.state }}
            value={address?.state}
            onChange={(event) => setAddress({ ...address, state: event.target.value })}
            fullWidth
            autoComplete="province"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Postleitzahl"
            value={address?.zipCode}
            InputLabelProps={{ shrink: !!address?.zipCode }}
            onChange={(event) => setAddress({ ...address, zipCode: event.target.value })}
            fullWidth
            autoComplete="shipping postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {address &&
            <CountrySelect userAddress={address} setAddress={setAddress}/>
          }
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address1"
            name="address1"
            label={t("myUnitsSettings.phoneNumber")}
            value={address?.phoneNumber}
            InputLabelProps={{ shrink: !!address?.phoneNumber }}
            onChange={(event) => setAddress({ ...address, phoneNumber: event.target.value })}
            fullWidth
            autoComplete="phone"
          />
        </Grid>
        <Grid item>
          <Button
            size="large"
            disabled={saving}
            startIcon={<ArrowDownward />}
            variant={'contained'}
            color="primary"
            onClick={async () => setOpen(true)}
          >
            {title} auswählen
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="large"
            disabled={saving}
            startIcon={<Save />}
            variant={'contained'}
            color="primary"
            onClick={async () => {

              if (!address) {
                enqueueSnackbar("Leere Adresse wird nicht gespeichert", { variant: 'warning' });
                return;
              }

              setSaving(true);
              const token = getApiToken(history);
              const savedAddress = await api.createUserAddress(address, token, enqueueSnackbar);
              setUserAddresses(addresses => [...addresses, savedAddress]);
              setSaving(false);
            }}
          >
            Speichern
          </Button>
        </Grid>

      </Grid>
      <UserAdressesDialog
        setAddress={setAddress}
        userAddresses={userAddresses}
        setUserAddresses={setUserAddresses}
        open={open}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
}