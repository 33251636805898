import { useContext } from "react";
import { GlobalContext } from "../components/authorized/PortalPage";

export const useToken = () => {
   const {token} : {token: string} = useContext(GlobalContext);
   return token;
}

export class ApiHelper {

    static hasForbiddenDataRestrictedError = (error: any, message?: string, enqueueSnackbar?: any): boolean => {
        if (!error?.response?.data ||
            error.response.data.code !== ApiErrorCode.ForbiddenDataRestricted)
            return false;

        message = message || "Sie haben keine Berechtigung, um diese Aktion durchzuführen";

        if (enqueueSnackbar)
            enqueueSnackbar(message, { variant: 'error' });

        console.log(message, JSON.stringify(error, null, 2));

        return true;
    }


    static handleUnexpectedError = (error: any, message?: string, enqueueSnackbar?: any) => {
        if (!error) return;

        console.log(JSON.stringify(error, null, 2));

        if (enqueueSnackbar)
            enqueueSnackbar(message || "Es ist ein Fehler aufgetreten", { variant: 'error' });
    }
}

export enum ApiErrorCode {
    BadRequest = 400000,
    CannotParseBody = 400001,
    CannotParseQuery = 400002,
    DataLimitExceeded = 4002009,
    ForbiddenNotAuthenticated = 403001,
    ForbiddenInvalidToken = 403002,
    ForbiddenInvalidCredentials = 403003,
    ForbiddenInsufficientRights = 403004,
    ForbiddenEmailNotConfirmed = 403005,
    ForbiddenExpiredToken = 403006,
    ForbiddenNotActivated = 403007,
    ForbiddenActivationExpired = 403008,
    ForbiddenLicenseExpired = 403010,
    ForbiddenDataRestricted = 403020,
    NotFoundRecordNotFound = 404001,
    OptimisticLocking = 409001,
    KeyConflict = 409002,
    KeyConflictDeviceRegistered = 409003,
    KeyConflictUserRegistered = 409004,
    KeyConflictWhatsAppGroupRegistered = 409005,
    InternalServerError = 500000,
}

