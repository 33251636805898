import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import { GridStackCustom, GridStackCustomItem } from '../../models/GridStackCustom';
import { CustomDashboardContext } from '../authorized/portal/Dashboard/CustomDashboard';
import { ChartLabelType } from '../charts/Base';

export enum WithZeroValuesChoiceType {
  WithoutZero = "0",
  All = "1"
}

export function WithZeroValueChoice({ value, setValue }: { value: WithZeroValuesChoiceType, setValue: any }) {


  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset" style={{ paddingLeft: 10 }}>
      <FormLabel component="legend">Balkenfilter</FormLabel>
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} row>
        <FormControlLabel value={WithZeroValuesChoiceType.WithoutZero} control={<Radio />} label="Ohne 0 Werte" />
        <FormControlLabel value={WithZeroValuesChoiceType.All} control={<Radio />} label="Alle Werte" />
      </RadioGroup>
    </FormControl>
  );
}