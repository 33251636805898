import axios from 'axios';
import { UserServiceWorkerObjectSession } from '../../models/UserServiceWorkerObjectSession';
import { handleApiError, handleDates, headers } from '../../api';


export const getActiveObjectSessionByStaffNumberAsync = async (userId: string, objectUnitId: string, staffNumber: string, enqueueSnackbar: any): Promise<UserServiceWorkerObjectSession> => {
    try {
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/object-units/${objectUnitId}/staff-number/${staffNumber}/active-sessions`,{ headers: { ...headers } });
        return handleDates(ret.data) as UserServiceWorkerObjectSession;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}