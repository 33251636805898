import { GridStack, GridStackNode } from "gridstack";
import 'gridstack/dist/gridstack.min.css';
import React, { createRef, Fragment, useRef } from "react";

import { useEffect } from "react";
import { ChartDisplaySizeType, GridStackCustom, GridStackCustomItem, GridStackItemType } from "../../../../models/GridStackCustom";

import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, Radio, RadioGroup, Switch, TextField, Typography, useTheme } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { AggregateType, APIDataAmountType, ChartDisplaySizeCustomDashboard, extractSensorsArray, generateChartData, getCleanerFeedbackByUserGroupIds, getDataFromGroupIdsSensors, getFeedbackData, getFeedbackQuestionsByUserGroupIds, getIncidentVisitorByGroupIds, getLoraMotionData, getMotionData, getOnlineStatusData, getQualityChecksByUserIdGroupIds, getServiceData, getServiceTriggerByUserIdGroupIds, getSettingsServicesByGroups } from "../../../../helpers/charts/base";
import { getCompletedCleaningTaskFields, getIsOnTimeCleaningTaskFields } from "../../../../helpers/charts/dataGenerator";
import { calcKeySum, calcSum, getEvaluationChoice, getGroupIdsForPrint, getProportion } from "../../../../helpers/common";
import { getApiToken } from "../../../../helpers/storage-management";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupServiceMode } from "../../../../models/GroupServiceMode";
import { CleaningOperation, FeedbackDisplayType, GroupSettings, LocationInformation, Sensor } from "../../../../models/GroupSettings";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { loadMotionData, loadMotionDataForMultipleSensors, loadMotionDataForSensor } from "./FrequencyDashboard";
import { ChartSumType, CustomSumOptions } from "./customSum/CustomSumOptions";
import { CustomSumUserOptions } from "./customSum/CustomSumUserOptions";
import { BuildGroupFeedbackQuestionsSimple, FEEDBACK_QUESTION_NEGATIVE_CHART_SIMPLE_TITLE, FEEDBACK_QUESTION_NEGATIVE_CHART_TITLE, getFeedbackColorsSum, loadCleanerFeedbackData, loadFeedbackData, loadFeedbackMotionCombData, loadFeedbackQuestionData, loadIncidentVisitorData } from "./FeedbackDashboard";
import { BuildTaskDoneChart, BuildTaskNotDoneChart, EvalApiType, loadTriggeredCleaningDataAsync, manageServicesData } from "./Services";
import { manageServiceUserData } from "./ServiceWorkerServicesDashboard";
import CloseCircleSvg from '../../../../assets/svgs/close-circle.svg'
import FormatListChecksWhiteSvg from '../../../../assets/svgs/format-list-checks_white.svg'
import AlertSvg from '../../../../assets/svgs/alert_white.svg'
import PaperRollWhiteSvg from '../../../../assets/svgs/paper-roll_white.svg'
import EmoticonHappyWhiteSvg from '../../../../assets/svgs/emoticon-happy_white.svg'
import EmoticonSadWhiteSvg from '../../../../assets/svgs/emoticon-sad_white.svg'
import CheckCircleWhiteSvg from '../../../../assets/svgs/check-circle_white.svg'
import { red, yellow } from "../../../../styles/colors";
import { green } from "../../../../styles/colors";
import { mapLabels } from "../../../charts/Base";
import { SimplePanel, SimplePanelRouteType } from "../../../common/SimplePanel";
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import InfiniteScroll from "react-infinite-scroll-component";
import { CallEnd, Edit, EditOff, ExpandMore, Save } from "@mui/icons-material";
import { GroupFeedbackEntireChartsSimple } from "./feedbacks/GroupFeedbackEntireChartsSimple";
import { GroupFeedback } from "../../../../models/GroupFeedback";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";
import { GlobalContext } from "../../PortalPage";
import useNoInitialEffect from "../../../../helpers/extensions";
import { CustomSumOptionsFeedbacks } from "./customSum/CustomSumOptionsFeedbacks";
import { GetGroupFeedbacksQualityMotionComb } from "./customerUsage/GetGroupFeedbacksQualityMotionComb";
import { GetGroupFeedbackQuestions } from "./customerUsage/GetGroupFeedbackQuestions";
import { GroupFeedbackQuestion } from "../../../../models/GroupFeedbackQuestion";
import { GridsStackCustomManagementDialog } from "./customDashboard/dialog/GridsStackCustomManagementDialog";
import { FEEDBACK_DISPLAY_TYPE_CHART, GLOBAL_SELECTED_GRID_STACK_CUSTOM } from "../../../../constants";
import { api } from "../../../../api";
import { useSnackbar } from "notistack";
import { GroupCleaningTrigger } from "../../../../models/GroupCleaningTrigger";
import { GroupMotionChart } from "./customerUsage/GetGroupMotions";
import { CleaningTasksOnTimeDashboard } from "./services/CleaningTaskOnTime";
import { CleaningTasksCompletedDashboard } from "./services/CleaningTaskCompleted";
import { IncidentReportDashboard } from "./services/IncidentReport";
import { GetGroupMotionsMaterialsCount } from "./services/GetGroupMotionsMaterialsCount";
import { GetGroupRatioMotionsMaterials } from "./services/GetGroupRatioMotionsMaterials";
import { MaterialConsumptionDashboard } from "./services/MaterialConsumption";
import { CleaningTasksDashboard } from "./services/CleaningTasks";
import { DoneByUserCleaningTaskDashboard } from "./user/DoneByUserCleaningTasks";
import { DoneByUserIncidentReportDashboard } from "./user/DoneByUserIncidentReport";
import { DoneByUserMaterialConsumptionDashboard } from "./user/DoneByUserMaterialConsumption";
import { TriggeredCleaningTask } from "./cleaningTrigger/TriggeredCleaningTask";
import { generateCleaningTriggerChartData, getAllCleaningTriggerTypes } from "../../../../helpers/charts/cleaningTriggerGenerator";
import { DataFilterType, getDefaultPeriod, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../helpers/date-management";
import { BuildOnlineStatusSimpleChartDetails } from "./OnlineStatusDashboard";
import { GroupOnlineStatus } from "../../../../models/GroupOnlineStatus";
import clsx from 'clsx';
import { BuildSimpleChartFeedbacksAvgDetails, BuildSimpleChartFrequencyCountDetails, BuildSimpleChartServiceCountDetails, BuildSimpleChartServicePunctualCountDetails } from "./HitListDashboard";
import { GroupMotion } from "../../../../models/GroupMotion";
import { GetGroupFeedbacksMotionComb } from "./customerUsage/GetGroupFeedbacksMotionComb";
import { IncidentVisitorChart, IncidentVisitorChartType } from "./customerUsage/IncidentVisitorChart";
import { GetCleanerGroupFeedbacks } from "./customerUsage/GetCleanerGroupFeedbacks";
import { GetGroupRatioMotionsFeedbacks } from "./customerUsage/GetGroupRatioMotionsFeedbacks";
import { GetGroupFeedbackPerVisitor } from "./customerUsage/GetGroupFeedbackPerVisitor";
import { GetGroupFeedbacksPercent } from "./customerUsage/GetGroupFeedbacksPercent";
import { GetGroupFeedbacks } from "./customerUsage/GetGroupFeedbacks";
import { GroupCleanerFeedback } from "../../../../models/GroupCleanerFeedback";
import { generateMotionsChartData, getAllCleanerFeebacksTypes, getAllFeebacksTypes, getAllMotionTypes, getFeebacksTypesThreeSmiles } from "../../../../helpers/charts/customerUsageGenerator";
import { ChartCalcType, getRatioMotionFeedbackThreeSmilesType, getRatioMotionFeedbackType } from "../../../core/TimeChoice";
import { FrequencyAvgPercentChart } from "./frequency/FrequencyAvgPercentChart";
import { FrequencyGroupsChart } from "./frequency/FrequencyGroupsChart";
import { SensorFilterType, SpecificSensorCharts } from "./customerUsage/specificSensor/SpecificSensorCharts";
import { MotionsAggSumDashboard } from "./MotionsAggSumDashboard";
import { GroupMotionLoraChart } from "./lora/GroupMotionLoraChart";
import { LoraMotionWithGroupsChartSimple } from "./lora/LoraMotionWithGroupsChartSimple";
import { LoraGroupMotion } from "../../../../models/LoraGroupMotion";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { ObjectLeaderQualityCheckServicesChart } from "../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckServicesChart";
import { ObjectLeaderQualityCheckMaterialChart } from "../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckMaterialChart";
import { ObjectLeaderIncidentReportChart } from "../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderIncidentReportChart";
import { ObjectLeaderQualityCheckAverageMaterialsChart } from "../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageMaterialsChart";
import { ObjectLeaderQualityCheckAverageServicesChart } from "../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageServicesChart";
import { ObjectLeaderQualityCheckAverageServicesAllGroupsCombined } from "../../../dialogs/charts/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageServicesAllGroupsCombinedChart";
import { AverageGroupsSimpleChart, AverageServicesSimpleChart } from "./qualityChecks/QualityChecksDashboard";
import { ObjectLeaderQualityCheckModel } from "../../../../models/ObjectLeaderQualityCheckModel";
import { generateAverageGroupCombinedMaterialsQualityChecksChartDataByObjectLeader, generateAverageGroupCombinedQualityChecksChartDataByObjectLeader, generateAverageGroupQualityChecksChartDataByObjectLeader, generateAverageMaterialsChartDataByObjectLeaderQualityChecks, generateAverageMaterialsGroupQualityChecksChartDataByObjectLeader, generateAverageServicesChartDataByObjectLeaderQualityChecks, generateIncidentReportChartDataByObjectLeaderQualityChecks, generateMaterialsChartDataByObjectLeaderQualityChecks, generateServicesChartDataByObjectLeaderQualityChecks, getAllAverageMaterialsByObjectLeaderQualityChecks, getAllAverageServicesByObjectLeaderQualityChecks, getAllIncidentsByObjectLeaderQualityChecks, getAllMaterialsByObjectLeaderQualityChecks, getAllServicesByObjectLeaderQualityChecks } from "../../../../helpers/charts/objectLeader/dataGenerator";
import { useQuery } from "../checkout/ShopPage";
import { StringHelper } from "../../../../helpers/string-helper";
import { BuildGroupFeedbackQuestionsWithGroupsSimple, FEEDBACK_QUESTION_NEGATIVE_WITH_GROUPS_SIMPLE_TITLE } from "./feedbacks/BuildGroupFeedbackQuestionsWithGroupsSimple";
import { FeedbackQualityVerticalChart } from "./feedbacks/FeedbackQualityVerticalChart";
import { useTranslation } from "react-i18next";

export const CustomDashboardContext = React.createContext<any>({});


export const CustomDashboard = ({ selectedGroupId, groupIds, selectedCombinationGroup, drawerDisplayType, serviceDisplayType }
    : { selectedGroupId: string, groupIds: string[], selectedCombinationGroup: GroupLicenseTable[], drawerDisplayType: DrawerMenuItemDisplayType, serviceDisplayType: EvalApiType }) => {
    const [isFullScreenChartVisible, setIsFullscreenChartVisible] = React.useState(false);
    const {t} = useTranslation();

    const [currentLoadingLabel, setCurrentLoadingLabel] = React.useState("");
    const [selectedGridStackCustom, setSelectedGridStackCustom] = React.useState<GridStackCustom>(GridStackCustom.CreateInstance());
    const [isEditModeEnabled, setIsEditModeEnabled] = React.useState(false);
    const [loadingSaveGridStack, setLoadingSaveGridStack] = React.useState(false);

    const [triggeredCleaningTasks, setTriggeredCleaningTasks] = React.useState<any>([]);
    const [triggeredCleaningAllTasks, setTriggeredCleaningAllTasks] = React.useState<any>([]);
    const [triggerCleaningType, setTriggerCleaningType] = React.useState<any>([]);
    const { groupId } = useParams<{ groupId: string }>();
    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);
    const { enqueueSnackbar } = useSnackbar();
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();

    //group
    const [materialConsumptionAllData, setMaterialConsumptionAllData] = React.useState<any>([]);
    const [materialConsumptionData, setMaterialConsumptionData] = React.useState<any>([]);
    const [materialConsumptionDataFields, setMaterialConsumptionDataFields] = React.useState<any>([]);
    const [incidentReportData, setIncidentReportData] = React.useState<any>([]);
    const [incidentReportAllData, setIncidentReportAllData] = React.useState<any>([]);
    const [incidentReportDataFields, setIncidentReportDataFields] = React.useState<any>([]);
    const [cleaningTaskData, setCleaningTaskData] = React.useState<any>([]);
    const [cleaningTaskAllData, setCleaningTaskAllData] = React.useState<any>([]);
    const [cleaningTaskDataFields, setCleaningTaskDataFields] = React.useState<any>([]);
    const [cleaningTaskIsCompletedData, setCleaningTaskIsCompletedData] = React.useState<any>([]);
    const [cleaningTaskIsCompletedAllData, setCleaningTaskIsCompletedAllData] = React.useState<any>([]);
    const [cleaningTaskIsOnTimeData, setCleaningTaskIsOnTimeData] = React.useState<any>([]);
    const [cleaningTaskIsOnTimeAllData, setCleaningTaskIsOnTimeAllData] = React.useState<any>([]);

    //user cleaner
    const [currentCleanerMaterials, setCurrentCleanerMaterials] = React.useState("");
    const [currentCleanerIncidents, setCurrentCleanerIncidents] = React.useState("");
    const [currentCleanerTasks, setCurrentCleanerTasks] = React.useState("");
    const [groupsServiceMode, setGroupsServiceMode] = React.useState<GroupServiceMode[]>([]);
    const [cleaningTaskUserData, setCleaningTaskUserData] = React.useState<any>([]);
    const [cleaningTaskUserAllData, setCleaningTaskUserAllData] = React.useState<any>([]);
    const [cleaningTaskUserDataFields, setCleaningTaskUserDataFields] = React.useState<any>([]);
    const [incidentReportUserData, setIncidentReportUserData] = React.useState<any>([]);
    const [incidentReportUserAllData, setIncidentReportUserAllData] = React.useState<any>([]);
    const [incidentReportUserDataFields, setIncidentReportUserDataFields] = React.useState<any>([]);
    const [materialUserData, setMaterialUserData] = React.useState<any>([]);
    const [materialUserAllData, setMaterialUserAllData] = React.useState<any>([]);
    const [materialUserDataFields, setMaterialUserDataFields] = React.useState<any>([]);
    const history = useHistory();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);

    //usage


    const [feedbackData, setFeedbackData] = React.useState<any>([]);
    const [feedbackAllData, setFeedbackAllData] = React.useState<any>([]);
    const [feedbackDataType, setFeedbackDataType] = React.useState<any>([]);
    const [groupMotionsSum, setGroupMotionsSum] = React.useState<any>([]);
    const [groupMotionsAllSum, setGroupMotionsAllSum] = React.useState<any>([]);
    const [groupMotionTypeSum, setGroupMotionTypeSum] = React.useState<any>([]);
    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);


    const [loadingFeedbackDataCompleted, setLoadingFeedbackDataCompleted] = React.useState(false);
    const [loadingUsageDataCompleted, setLoadingUsageDataCompleted] = React.useState(false);
    const [loadingServiceUserDataCompleted, setLoadingServiceUserDataCompleted] = React.useState(false);
    const [loadingServicesDataCompleted, setLoadingServicesDataCompleted] = React.useState(false);
    const [loadingTriggeredCleaningDataCompleted, setLoadingTriggerCleaningDataCompleted] = React.useState(false);

    const childRefFeedbacks: any = useRef();
    const childRefVisitors: any = useRef();
    const childRefTriggers: any = useRef();
    const childRefTaskIsCompleted: any = useRef();
    const childRefIsTaskOnTime: any = useRef();
    const childRefMaterialConsumption: any = useRef();
    const childRefIncidentReport: any = useRef();
    const childRefCleaningTask: any = useRef();
    const childRefCleanerTask: any = useRef();
    const childRefCleanerIncidents: any = useRef();
    const childRefCleanerMaterials: any = useRef();

    const childRefFeedbacksChart: any = useRef();
    const childRefVisitorsChart: any = useRef();
    const childRefTriggersChart: any = useRef();
    const childRefTaskIsCompletedChart: any = useRef();
    const childRefIsTaskOnTimeChart: any = useRef();
    const childRefMaterialConsumptionChart: any = useRef();
    const childRefIncidentReportChart: any = useRef();
    const childRefCleaningTaskChart: any = useRef();
    const childRefCleanerTaskChart: any = useRef();
    const childRefCleanerIncidentsChart: any = useRef();
    const childRefCleanerMaterialsChart: any = useRef();

    const [feedbackColors, setFeedbackColors] = React.useState<string[]>([]);

    const [tableFeedbackData, setTableFeedbackData] = React.useState<GroupFeedback[]>([]);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const refGroupFeedbackEntireChartsSimple = useRef<{ updateStartDateFeedbackWithGroupsSimpleData(newVal: Date): void, updateEndDateFeedbackWithGroupsSimpleData(newVal: Date): void }>();
    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible, chartDisplaySizeType, setChartDisplaySizeType }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any, chartDisplaySizeType: ChartDisplaySizeType, setChartDisplaySizeType: any } = React.useContext(GlobalContext);



    const [feedbackMotionCombChartData, setFeedbackMotionCombChartData] = React.useState<any>([]);
    const [feedbackCombDataFields, setFeedbackCombDataFields] = React.useState<any>([]);
    const [motionCombDataFields, setMotionCombDataFields] = React.useState<any>([]);
    const [feedbackMotionCombChartDataAll, setFeedbackMotionCombChartDataAll] = React.useState<any>([]);
    const [feedbackQualityMotionCombChartData, setFeedbackQualityMotionCombChartData] = React.useState<any>([]);
    const childRefFeedbackQualityMotionComb: any = useRef();
    const [feedbackDisplayTypeChart, setFeedbackDisplayTypeChart] = React.useState(FeedbackDisplayType.PositiveNegativeNeutral);
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);


    const childRefFeedbackQuestion: any = useRef();
    const childRefFeedbackQuestionChart: any = useRef();
    const [feedbackQuestionData, setFeedbackQuestionData] = React.useState<any>([]);
    const [feedbackQuestionAllData, setFeedbackQuestionAllData] = React.useState<any>([]);
    const [feedbackQuestionDataFields, setFeedbackQuestionDataFields] = React.useState<any>([]);
    //Cockpit end------------------------------------------------------------------------------------------

    // service mode charts
    const [serviceModesratioMotionsMaterialConsumptionAllData, setserviceModesRatioMotionsMaterialConsumptionAllData] = React.useState<any>([]);
    const [serviceModesratioMotionsMaterialConsumptionData, setserviceModesRatioMotionsMaterialConsumptionData] = React.useState<any>([]);
    const [serviceModesratioMotionsMaterialConsumptionDataFields, setserviceModesRatioMotionsMaterialConsumptionDataFields] = React.useState<any>([]);

    const [serviceModeshundredVisitorHowManyMaterialsAllData, setserviceModesHundredVisitorHowManyMaterialsAllData] = React.useState<any>([]);
    const [serviceModeshundredVisitorHowManyMaterialsData, setserviceModesHundredVisitorHowManyMaterialsData] = React.useState<any>([]);
    const [serviceModeshundredVisitorHowManyMaterialsDataFields, setserviceModesHundredVisitorHowManyMaterialsDataFields] = React.useState<any>([]);

    const [serviceModesMaterialConsumptionAllData, setServiceModesMaterialConsumptionAllData] = React.useState<any>([]);
    const [serviceModesMaterialConsumptionData, setServiceModesMaterialConsumptionData] = React.useState<any>([]);
    const [serviceModesmaterialConsumptionDataFields, setserviceModesMaterialConsumptionDataFields] = React.useState<any>([]);
    const [serviceModesincidentReportData, setServiceModesIncidentReportData] = React.useState<any>([]);
    const [serviceModesincidentReportAllData, setserviceModesIncidentReportAllData] = React.useState<any>([]);
    const [serviceModesincidentReportDataFields, setServiceModesIncidentReportDataFields] = React.useState<any>([]);
    const [serviceModescleaningTaskData, setserviceModesCleaningTaskData] = React.useState<any>([]);
    const [serviceModescleaningTaskAllData, setserviceModesCleaningTaskAllData] = React.useState<any>([]);
    const [serviceModescleaningTaskDataFields, setserviceModesCleaningTaskDataFields] = React.useState<any>([]);
    const [serviceModescleaningTaskIsCompletedData, setserviceModesCleaningTaskIsCompletedData] = React.useState<any>([]);
    const [serviceModescleaningTaskIsCompletedAllData, setserviceModesCleaningTaskIsCompletedAllData] = React.useState<any>([]);
    const [serviceModescleaningTaskIsOnTimeData, setserviceModesCleaningTaskIsOnTimeData] = React.useState<any>([]);
    const [serviceModescleaningTaskIsOnTimeAllData, setserviceModesCleaningTaskIsOnTimeAllData] = React.useState<any>([]);


    const [allServiceMode, setAllServiceMode] = React.useState<GroupServiceMode[]>([]);
    const [allCleaningTasks, setAllCleaningTasks] = React.useState<CleaningOperation[]>([]);

    const childRefRatioMotionsMaterialConsumption: any = useRef();
    const childRefHundredVisitorHowManyMaterials: any = useRef();

    const serviceModeschildRefCleaningTasks: any = useRef();
    const serviceModeschildRefMaterialConsumption: any = useRef();
    const serviceModeschildRefIncidentReport: any = useRef();
    const serviceModeschildRefCompletedTask: any = useRef();
    const serviceModeschildRefTaskOnTime: any = useRef();

    const serviceModeschildRefCleaningTasksChart: any = useRef();
    const serviceModeschildRefMaterialConsumptionChart: any = useRef();
    const serviceModeschildRefIncidentReportChart: any = useRef();
    const serviceModeschildRefCompletedTaskChart: any = useRef();
    const serviceModeschildRefTaskOnTimeChart: any = useRef();

    const serviceModesserviceModeschildRefMotionChart: any = useRef();
    const serviceModeschildRefMotion: any = useRef();
    const refBuildTaskDoneChart = React.useRef<any>();
    const refBuildTaskNotDoneChart = React.useRef<any>();

    //Service Mode Charts end--------------------------------------------------------------------------------------------------------------

    // service modes serviceworker specfic

    const [serviceWorkerServiceModescleaningTaskUserData, setserviceWorkerServiceModesCleaningTaskUserData] = React.useState<any>([]);
    const [serviceWorkerServiceModescleaningTaskUserAllData, setserviceWorkerServiceModesCleaningTaskUserAllData] = React.useState<any>([]);
    const [serviceWorkerServiceModescleaningTaskUserDataFields, setserviceWorkerServiceModesCleaningTaskUserDataFields] = React.useState<any>([]);
    const [serviceWorkerServiceModesincidentReportUserData, setserviceWorkerServiceModesIncidentReportUserData] = React.useState<any>([]);
    const [serviceWorkerServiceModesincidentReportUserAllData, setserviceWorkerServiceModesIncidentReportUserAllData] = React.useState<any>([]);
    const [serviceWorkerServiceModesincidentReportUserDataFields, setserviceWorkerServiceModesIncidentReportUserDataFields] = React.useState<any>([]);
    const [serviceWorkerServiceModesmaterialUserData, setserviceWorkerServiceModesMaterialUserData] = React.useState<any>([]);
    const [serviceWorkerServiceModesmaterialUserAllData, setserviceWorkerServiceModesMaterialUserAllData] = React.useState<any>([]);
    const [serviceWorkerServiceModesmaterialUserDataFields, setserviceWorkerServiceModesMaterialUserDataFields] = React.useState<any>([]);
    const [serviceWorkerServiceModescurrentCleanerTasks, setserviceWorkerServiceModesCurrentCleanerTasks] = React.useState("");
    const [serviceWorkerServiceModescurrentCleanerIncidents, setserviceWorkerServiceModesCurrentCleanerIncidents] = React.useState("");
    const [serviceWorkerServiceModescurrentCleanerMaterials, setserviceWorkerServiceModesCurrentCleanerMaterials] = React.useState("");

    const childRefTaskDoneByUser: any = useRef();
    const childRefIncidentReportDoneByUser: any = useRef();
    const childRefMaterialByUser: any = useRef();

    const childRefTaskDoneByUserChart: any = useRef();
    const childRefIncidentReportDoneByUserChart: any = useRef();
    const childRefMaterialByUserChart: any = useRef();

    //service modes serviceworker end--------------------------------------------------------

    //service trigger

    const [ServiceTriggerstriggeredCleaningTasks, setServiceTriggersTriggeredCleaningTasks] = React.useState<any>([]);
    const [serviceTriggerstriggeredCleaningAllTasks, setserviceTriggersTriggeredCleaningAllTasks] = React.useState<any>([]);
    const [serviceTriggerstriggerCleaningType, setserviceTriggersTriggerCleaningType] = React.useState<any>([]);

    const childRefTriggeredCleaningTask: any = useRef();
    const childRefTriggeredCleaningTaskChart: any = useRef();


    //online status
    const [onlineStatusTableData, setOnlineStatusTableData] = React.useState<GroupOnlineStatus[]>([]);
    const refOnlineStatusSimpleChart: any = useRef();
    const refOnlineStatusSimpleChartPercent: any = useRef();

    //feedback dashboard
    const [feedbackDashboardfeedbackMotionCombChartData, setfeedbackDashboardFeedbackMotionCombChartData] = React.useState<any>([]);
    const [feedbackDashboardfeedbackMotionCombChartDataAll, setfeedbackDashboardFeedbackMotionCombChartDataAll] = React.useState<any>([]);
    const [feedbackDashboardfeedbackCombDataFields, setfeedbackDashboardFeedbackCombDataFields] = React.useState<any>([]);
    const [feedbackDashboardmotionCombDataFields, setfeedbackDashboardMotionCombDataFields] = React.useState<any>([]);
    const feedbackDashboardchildRefFeedbackMotionComb: any = useRef();
    const feedbackDashboardchildRefFeedbackMotionCombChart: any = useRef();




    const [feedbackDashboardfeedbackData, setfeedbackDashboardFeedbackData] = React.useState<any>([]);
    const [feedbackDashboardfeedbackAllData, setfeedbackDashboardFeedbackAllData] = React.useState<any>([]);
    const [feedbackDashboardfeedbackDataType, setfeedbackDashboardFeedbackDataType] = React.useState<any>([]);

    const [cleanerfeedbackDashboardFeedbackData, setFeedbackDasboardCleanerFeedbackData] = React.useState<any>([]);
    const [feedbackDashboardcleanerfeedbackDashboardFeedbackAllData, setfeedbackDashboardCleanerfeedbackDashboardFeedbackAllData] = React.useState<any>([]);
    const [cleanerfeedbackDashboardFeedbackDataType, setFeedbackDasboardCleanerFeedbackDataType] = React.useState<any>([]);


    const [incidentVisitorData, setIncidentVisitorData] = React.useState<any>([]);
    const [incidentVisitorAllData, setIncidentVisitorAllData] = React.useState<any>([]);
    const [incidentVisitorDataFields, setIncidentVisitorDataFields] = React.useState<any>([]);
    const [incidentVisitorTypeChart, setIncidentVisitorTypeChart] = React.useState(IncidentVisitorChartType.Standard);

    const feedbackDashboardchildRefFeedback: any = useRef();
    const feedbackDashboardchildRefFeedbackChart: any = useRef();

    const feedbackDashboardchildRefFeedbackPercent: any = useRef();
    const feedbackDashboardchildRefFeedbackChartPercent: any = useRef();

    const feedbackDashboardchildRefCleanerFeedback: any = useRef();
    const feedbackDashboardchildRefCleanerFeedbackChart: any = useRef();

    const childRefIncidentVisitor: any = useRef();
    const childRefIncidentVisitorChart: any = useRef();

    const feedbackDashboardchildRefGroupRatioMotionsFeedback: any = useRef();
    const [feedbackDashboardgroupRatioMotionsFeedback, setfeedbackDashboardGroupRatioMotionsFeedback] = React.useState<any>([]);
    const [feedbackDashboardgroupRatioMotionsFeedbackAll, setfeedbackDashboardGroupRatioMotionsFeedbackAll] = React.useState<any>([]);
    const [feedbackDashboardgroupRatioMotionsFeedbackType, setfeedbackDashboardGroupRatioMotionsFeedbackType] = React.useState<any>([]);


    const feedbackDashboardchildRefGroupFeedbackPerVisitor: any = useRef();
    const [feedbackDashboardGroupFeedbackPerVisitor, setfeedbackDashboardGroupFeedbackPerVisitor] = React.useState<any>([]);
    const [feedbackDashboardGroupFeedbackPerVisitorAll, setfeedbackDashboardGroupFeedbackPerVisitorAll] = React.useState<any>([]);
    const [groupfeedbackDashboardGroupFeedbackPerVisitorType, setfeedbackDashboardGroupFeedbackPerVisitorType] = React.useState<any>([]);

    const refGroupFeedbackQuestionsWithGroupsSimple = useRef<{ updateStartDateFeedbackQuestionWithGroupsSimpleData(newVal: Date): void, updateEndDateFeedbackQuestionWithGroupsSimpleData(newVal: Date): void }>();


    //frequency dashboard-------------------------------------------------------

    const [motionTableData, setMotionTableData] = React.useState<GroupMotion[]>([]);
    const [groupMotions, setGroupMotions] = React.useState<any>([]);
    const [groupMotionsAll, setGroupMotionsAll] = React.useState<any>([]);
    const [groupMotionType, setGroupMotionType] = React.useState<any>([]);

    const [frequencyAvgPercentMotions, setFrequencyAvgPercentMotions] = React.useState<any>([]);
    const [sensors, setSensors] = React.useState<any>([]);
    const [currentSensor, setCurrentSensor] = React.useState<Sensor>();
    const [currentSensorFilterSession, setCurrentSensorFilterSession] = React.useState<Sensor>();
    const [specificSensorMotionData, setSpecificSensorMotionData] = React.useState([]);
    const [specificSensorMotionDataBySensorId, setSpecificSensorMotionDataBySensorId] = React.useState([]);
    const [specificSensorMotionIncreaseSumData, setSpecificSensorMotionIncreaseSumData] = React.useState([]);
    const [specificSensorMotionIncreaseSumDataBySensorId, setSpecificSensorMotionIncreaseSumDataBySensorId] = React.useState([]);

    const childRefMotionIncrease: any = useRef();
    const childRefMotion: any = useRef();

    const childRefMotionIncreaseChart: any = useRef();
    const childRefMotionChart: any = useRef();

    const childRefFrequencyAvgPercent: any = useRef();
    const childRefFrequencyAvgPercentChart: any = useRef();

    const childRefFrequencyGroups: any = useRef();
    const childRefFrequencyGroupsChart: any = useRef();


    const childRefMotionsBySensor: any = useRef();
    const childRefMotionsBySensorChart: any = useRef();

    const childRefMotionsSumIncreaseBySensor: any = useRef();
    const childRefMotionsSumIncreaseBySensorChart: any = useRef();

    const childRefMotionsBySensorFilterId: any = useRef();
    const childRefMotionsBySensorFilterIdChart: any = useRef();

    const childRefMotionsSumIncreaseBySensorFilterId: any = useRef();
    const childRefMotionsSumIncreaseBySensorFilterIdChart: any = useRef();

    const childRefMotionMultipleRoomsIncrease: any = useRef();
    const childRefMotionMultipleRooms: any = useRef();
    const childRefMotionMultipleRoomsChart: any = useRef();

    const [motionsFromApi, setMotionsFromApi] = React.useState<GroupMotion[]>([]);


    const [multipleSensorsMotionData, setMultipleSensorsMotionData] = React.useState([]);
    const [allRoomNames, setAllRoomNames] = React.useState<string[]>([]);

    //lora sensoren---------------------------------------------------------------
    const [groupLoraMotionsTableData, setGroupLoraMotionsTableData] = React.useState<LoraGroupMotion[]>([]);

    const [groupLoraMotions, setGroupLoraMotions] = React.useState<any>([]);
    const [groupLoraMotionsAll, setGroupLoraMotionsAll] = React.useState<any>([]);
    const [groupLoraMotionType, setGroupLoraMotionType] = React.useState<any>([]);

    const childRefLoraMotion: any = useRef();
    const childRefLoraMotionChart: any = useRef();


    const refGroupLoraMotionsWithGroupsSimple = useRef<{ updateStartDateLoraMotionWithGroupsSimpleData(newVal: Date): void, updateEndDateLoraMotionWithGroupsSimpleData(newVal: Date): void }>();


    //quality checks

    const [allQualityChecks, setAllQualityChecks] = React.useState<ObjectLeaderQualityCheckModel[]>();

    const [objectLeaderIncidentReportData, setObjectLeaderIncidentReportData] = React.useState<any>([]);
    const [objectLeaderIncidentReportAllData, setObjectLeaderIncidentReportAllData] = React.useState<any>([]);
    const [objectLeaderIncidentReportDataFields, setObjectLeaderIncidentReportDataFields] = React.useState<any>([]);

    const [objectLeaderServicesData, setObjectLeaderServicesData] = React.useState<any>([]);
    const [objectLeaderServicesAllData, setObjectLeaderServicesAllData] = React.useState<any>([]);
    const [objectLeaderServicesDataFields, setObjectLeaderServicesDataFields] = React.useState<any>([]);

    const [objectLeaderAverageServicesData, setObjectLeaderAverageServicesData] = React.useState<any>([]);
    const [objectLeaderAverageServicesAllData, setObjectLeaderAverageServicesAllData] = React.useState<any>([]);
    const [objectLeaderAverageServicesDataFields, setObjectLeaderAverageServicesDataFields] = React.useState<any>([]);

    const [objectLeaderAverageGroupsServiceData, setObjectLeaderAverageGroupsServiceData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsServiceAllData, setObjectLeaderAverageGroupsServiceAllData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsServiceDataFields, setObjectLeaderAverageGroupsServiceDataFields] = React.useState<any>([]);

    const [objectLeaderAverageGroupsCombinedServiceData, setObjectLeaderAverageGroupsCombinedServiceData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsCombinedServiceAllData, setObjectLeaderAverageGroupsCombinedServiceAllData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsCombinedServiceDataFields, setObjectLeaderAverageGroupsCombinedServiceDataFields] = React.useState<any>([]);

    const [objectLeaderMaterialsData, setObjectLeaderMaterialsData] = React.useState<any>([]);
    const [objectLeadermaterialsAllData, setObjectLeaderMaterialsAllData] = React.useState<any>([]);
    const [objectLeaderMaterialsDataFields, setObjectLeaderMaterialsDataFields] = React.useState<any>([]);

    const [objectLeaderAverageMaterialsData, setObjectLeaderAverageMaterialsData] = React.useState<any>([]);
    const [objectLeaderAverageMaterialsAllData, setObjectLeaderAverageMaterialsAllData] = React.useState<any>([]);
    const [objectLeaderAverageMaterialsDataFields, setObjectLeaderAverageMaterialsDataFields] = React.useState<any>([]);

    const [objectLeaderAverageGroupsMaterialsData, setObjectLeaderAverageGroupsMaterialsData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsMaterialsAllData, setObjectLeaderAverageGroupsMaterialsAllData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsMaterialsDataFields, setObjectLeaderAverageGroupsMaterialsDataFields] = React.useState<any>([]);

    const [objectLeaderAverageGroupsCombinedMaterialsData, setObjectLeaderAverageGroupsCombinedMaterialsData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsCombinedMaterialsAllData, setObjectLeaderAverageGroupsCombinedMaterialsAllData] = React.useState<any>([]);
    const [objectLeaderAverageGroupsCombinedMaterialsDataFields, setObjectLeaderAverageGroupsCombinedMaterialsDataFields] = React.useState<any>([]);

    const childRefObjectLeaderIncidents: any = useRef();
    const childRefObjectLeaderIncidentsChart: any = useRef();

    const childRefObjectLeaderServices: any = useRef();
    const childRefObjectLeaderServicesChart: any = useRef();

    const childRefObjectLeaderAverageServices: any = useRef();
    const childRefObjectLeaderAverageServicesChart: any = useRef();

    const childRefObjectLeaderAverageGroupsServices: any = useRef();
    const childRefObjectLeaderAverageGroupsServicesChart: any = useRef();

    const childRefObjectLeaderAverageGroupsCombinedServices: any = useRef();
    const childRefObjectLeaderAverageGroupsCombinedServicesChart: any = useRef();

    const childRefObjectLeaderMaterials: any = useRef();
    const childRefObjectLeaderMaterialsChart: any = useRef();

    const childRefObjectLeaderAverageMaterials: any = useRef();
    const childRefObjectLeaderAverageMaterialsChart: any = useRef();

    const childRefObjectLeaderAverageGroupsMaterials: any = useRef();
    const childRefObjectLeaderAverageGroupsMaterialsChart: any = useRef();

    const childRefObjectLeaderAverageGroupsCombinedMaterials: any = useRef();
    const childRefObjectLeaderAverageGroupsCombinedMaterialsChart: any = useRef();

    const refAverageServicesSimpleChart = useRef<any>();
    const refAverageGroupsSimpleChart = useRef<any>();


    const refFeedbackQualityVerticalChart = useRef<any>();

    //hitliste--------------------------------------------------------------------------------
    const [hitlistgroupMotions, setHitlistGroupMotions] = React.useState<GroupMotion[]>([]);
    const [feedbackQuestionTableData, setFeedbackQuestionTableData] = React.useState<GroupFeedbackQuestion[]>([]);

    const hitlistRefFrequencyCountSimpleChart: any = useRef()
    const hitlistRefServicesCountSimpleChart: any = useRef()
    const hitlistRefServicesPunctualCountSimpleChart: any = useRef()
    const hitlistRefFeedbackQuestionSimpleChart: any = useRef()
    const hitlistRefFeedbacksQualitySimpleChart: any = useRef()

    React.useEffect(() => {

    }, [chartDisplaySizeType])

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);
    const queryString = useQuery(history);
    const refs = useRef<any>({})
    const gridRef = useRef<any>()

    const intializeGrid = () => {
        let selectedGridStackCustom: GridStackCustom | null = JSON.parse(localStorage.getItem(GLOBAL_SELECTED_GRID_STACK_CUSTOM) || "null");
        if (!selectedGridStackCustom) return;

        setSelectedGridStackCustom(selectedGridStackCustom);

        gridRef.current = GridStack.init({ disableDrag: true, disableResize: true, float: true, cellHeight: selectedGridStackCustom.cellHeight, margin: selectedGridStackCustom.columnCount });


        gridRef.current.on('change', function (event: Event, griditems: GridStackNode[]) {


            if (griditems && selectedGridStackCustom) {
                let currentGridItemDb = selectedGridStackCustom.items.map(itemDb => {
                    let gridItem = griditems.find(gridItem => gridItem.id == itemDb.id);
                    if (gridItem) {
                        itemDb.height = gridItem.h || 0;
                        itemDb.width = gridItem.w || 0;
                        itemDb.posX = gridItem.x || 0;
                        itemDb.posY = gridItem.y || 0;
                    }
                    return itemDb;
                });
                selectedGridStackCustom.items = currentGridItemDb;
                setSelectedGridStackCustom(prev => {
                    if (selectedGridStackCustom) {
                        return { ...prev, items: [...selectedGridStackCustom.items] }

                    } else {
                        return { ...prev }

                    }
                });
            }

        });
    }

    React.useEffect(() => {
        intializeGrid();

        //     grid.load(dummyItems.map(x => mapGridStackItem(x)));
    }, []);



    if (Object.keys(refs.current).length !== selectedGridStackCustom.items.length) {
        selectedGridStackCustom.items.forEach(({ id }) => {
            refs.current[id] = refs.current[id] || createRef()
        })
    }

    React.useEffect(() => {
        const grid = gridRef.current
        grid.batchUpdate()
        grid.removeAll(false);
        selectedGridStackCustom.items.forEach(({ id }) => grid.makeWidget(refs.current[id].current))
        grid.batchUpdate(false)
    }, [selectedGridStackCustom]);

    const mapGridStackItem = (item: GridStackCustomItem) => {
        switch (item.gridStackItemType) {
            case GridStackItemType.HeaderTitle:
                return (
                    <GroupTypePrintDisplay title={selectedGridStackCustom.name} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                )
            case GridStackItemType.FooterTitle:
                if (evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) {
                    return (

                        <Fragment>
                            <div className="page-break"></div>
                            <div>
                                <GroupTypePrintDisplay title={"Feedback Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                                <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                            </div>
                        </Fragment>
                    )
                } else {
                    return (

                        <Fragment>
                            <div className="page-break"></div>
                            <div>
                                <Typography>Eine Einheit</Typography>
                                <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                            </div>
                        </Fragment>
                    )
                }

            case GridStackItemType.CockpitFeedback:
                return buildCockpitFeedbackChart();
            case GridStackItemType.CockpitVisitor:
                return buildCockpitVisitorsChart();
            case GridStackItemType.CockpitServiceTrigger:
                return buildCockpitServiceTriggerChart();
            case GridStackItemType.CockpitFeedbackCombFeedbackQualityMotion:
                return buildGetGroupFeedbacksQualityMotionComb();
            case GridStackItemType.CockpitMaterials:
                return buildMaterialChart();
            case GridStackItemType.CockpitIncidents:
                return buildIncidentsChart();
            case GridStackItemType.CockpitCleaningTasks:
                return buildServicesChart();
            case GridStackItemType.CockpitServiceWorkerCleaningTasks:
                return buildServiceForUserChart();
            case GridStackItemType.CockpitServiceWorkerMaterials:
                return buildMaterialForUserChart();
            case GridStackItemType.CockpitServiceWorkerIncidents:
                return buildIncidentsForUserChart();
            case GridStackItemType.CockpitGroupFeedbackEntireChartsSimple:
                return <GroupFeedbackEntireChartsSimple ref={refGroupFeedbackEntireChartsSimple} tableFeedbackData={tableFeedbackData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
            case GridStackItemType.CockpitServiceCompareChart:
                return buildServiceCompareChart();
            case GridStackItemType.CockpitServiceComparePunctualChart:
                return buildServiceComparePunctualChart();
            case GridStackItemType.CockpitFeedbacksFeedbackQuestion:
                return buildGetGroupFeedbackQuestions();
            //servicemodes

            case GridStackItemType.ServiceModesServicesChart:
                return buildServiceModesCleaningTasksDashboard();
            case GridStackItemType.ServiceModesMaterialsChart:
                return buildServiceModesMaterialConsumptionDashboard();
            case GridStackItemType.ServiceModesRatioMotionsMaterials:
                return buildServiceModesGetGroupRatioMotionsMaterials();
            case GridStackItemType.ServiceModesMotionMaterialCount:
                return buildServiceModesGetGroupMotionsMaterialsCount();
            case GridStackItemType.ServicesModesIncidentChart:
                return buildServiceModesIncidentReportDashboard();
            case GridStackItemType.ServiceModesServicesCompleted:
                return buildServiceModesCleaningTasksCompletedDashboard();
            case GridStackItemType.ServiceModesServicesOnTime:
                return buildServiceModesCleaningTasksOnTimeDashboard();
            case GridStackItemType.ServiceModesGroupMotion:
                return buildServiceModesGetGroupMotion();
            case GridStackItemType.ServiceModesTaskDoneChartSimple:
                return <BuildTaskDoneChart ref={refBuildTaskDoneChart} allServiceMode={allServiceMode} allCleaningTasks={allCleaningTasks} loadingCompletedCleaningTasks={loadingCompleted} />
            case GridStackItemType.ServiceModesTaskNotDoneChartSimple:
                return <BuildTaskNotDoneChart ref={refBuildTaskNotDoneChart} allServiceMode={allServiceMode} allCleaningTasks={allCleaningTasks} loadingCompletedCleaningTasks={loadingCompleted} />
            case GridStackItemType.ServiceWorkerServiceModesServices:
                return buildDoneByUserCleaningTaskDashboard();
            case GridStackItemType.ServiceWorkerServiceModesMaterial:
                return buildDoneByUserMaterialConsumptionDashboard();
            case GridStackItemType.ServiceWorkerServiceModesIncident:
                return buildDoneByUserIncidentReportDashboard();
            case GridStackItemType.ServiceTriggers:
                return buildServiceTriggerChart();
            //online status
            case GridStackItemType.OnlineStatusGroups:
                return <BuildOnlineStatusSimpleChartDetails ref={refOnlineStatusSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} onlineStatusTableData={onlineStatusTableData} />
            case GridStackItemType.OnlineStatusGroupsPercent:
                return <BuildOnlineStatusSimpleChartDetails ref={refOnlineStatusSimpleChartPercent} groupsLicenseTableEntries={groupsLicenseTableEntries} onlineStatusTableData={onlineStatusTableData} isPercentEnabled={true} />
            case GridStackItemType.HitlistFeedbackAvgDetails:
                return <BuildSimpleChartFeedbacksAvgDetails ref={hitlistRefFeedbacksQualitySimpleChart} groupFeedbacksData={tableFeedbackData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
            case GridStackItemType.HitlistFeedbackQuestion:
                return <BuildGroupFeedbackQuestionsSimple title={FEEDBACK_QUESTION_NEGATIVE_CHART_SIMPLE_TITLE} ref={hitlistRefFeedbackQuestionSimpleChart} feedbackQuestionTableData={feedbackQuestionTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
            case GridStackItemType.HitlistFrequencyCountDetails:
                return <BuildSimpleChartFrequencyCountDetails ref={hitlistRefFrequencyCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} frequencyData={hitlistgroupMotions} />
            case GridStackItemType.HitlistServicesCountDetails:
                return <BuildSimpleChartServiceCountDetails ref={hitlistRefServicesCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} serviceModesData={allServiceMode} />
            case GridStackItemType.HitlistServicePunctualCount:
                return <BuildSimpleChartServicePunctualCountDetails ref={hitlistRefServicesPunctualCountSimpleChart} groupsLicenseTableEntries={groupsLicenseTableEntries} serviceModesData={allServiceMode} />

            case GridStackItemType.FeedbacksGetGroupFeedback:
                return buildGetGroupFeedbacks();
            case GridStackItemType.FeedbacksGetGroupFeedbacksPercent:
                return buildGetGroupFeedbacksPercent();
            case GridStackItemType.FeedbacksGetGroupFeedbacksMotionComb:
                return buildGetGroupFeedbacksMotionComb();
            case GridStackItemType.FeedbacksGetGroupRatioMotionsFeedbacks:
                return buildGetfeedbackDashboardGroupRatioMotionsFeedbacks();
            case GridStackItemType.FeedbacksGetGroupFeedbackPerVisitor:
                return buildGetfeedbackDashboardGroupFeedbackPerVisitor();
            case GridStackItemType.FeedbackGroupFeedbackQuestionsWithGroupsSimple:
                return <BuildGroupFeedbackQuestionsWithGroupsSimple titleHeader={FEEDBACK_QUESTION_NEGATIVE_WITH_GROUPS_SIMPLE_TITLE} ref={refGroupFeedbackQuestionsWithGroupsSimple} feedbackQuestionTableData={feedbackQuestionTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
            case GridStackItemType.FeedbackIncidentVisitorChart:
                return buildIncidentVisitorChart();
            case GridStackItemType.FeedbackGetCleanerGroupFeedbacks:
                return buildGetCleanerGroupFeedbacks();


            case GridStackItemType.FrequencyAvgPercentChart:
                return buildFrequencyAvgPercentChart();
            case GridStackItemType.FrequencyGroupChart:
                return buildFrequencyGroupChart();
            case GridStackItemType.MotionsAggSumDashboard:
                return buildMotionsAggSumDashboard();
            case GridStackItemType.SpecifcGetGroupMotion:
                return buildSpecifcGetGroupMotion();
            case GridStackItemType.SpecificSensorCharts:
                return buildSpecificSensorCharts();
            case GridStackItemType.SpecificSensorChartsIncreaseSum:
                return buildSpecificSensorChartsIncreaseSum();
            case GridStackItemType.SpecificSensorChartsFilterSession:
                return buildpecificSensorChartsFilterSession();
            case GridStackItemType.SpecifcSensorChartsFilterSessionIncreaseSum:
                return buildSpecifcSensorChartsFilterSessionIncreaseSum();

            case GridStackItemType.LoraFrequencyGetGroupLoraMotion:
                return buildGetGroupLoraMotion();
            case GridStackItemType.LoraFrequencyLoraMotionWithGroupsChartSimple:
                return buildLoraMotionWithGroupsChartSimple();


            case GridStackItemType.QualityCheckAverageGroupsSimpleChart:
                return <AverageGroupsSimpleChart ref={refAverageGroupsSimpleChart} allQualityChecks={allQualityChecks} loadingCompleted={loadingCompleted} groupsLicenseTableEntries={groupsLicenseTableEntries} />
            case GridStackItemType.QualityCheckAverageServicesSimpleChart:
                return <AverageServicesSimpleChart ref={refAverageServicesSimpleChart} allQualityChecks={allQualityChecks} loadingCompleted={loadingCompleted} />
            case GridStackItemType.ObjectLeaderQualityCheckServicesChart:
                return buildObjectLeaderQualityCheckServicesChart();
            case GridStackItemType.ObjectLeaderQualityCheckMaterialChart:
                return buildObjectLeaderQualityCheckMaterialChart();
            case GridStackItemType.ObjectLeaderIncidentReportChart:
                return buildObjectLeaderIncidentReportChart();
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesChart:
                return buildObjectLeaderQualityCheckAverageServicesChart();
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsChart:
                return buildObjectLeaderQualityCheckAverageMaterialsChart();
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesAllGroupsCombined:
                return buildObjectLeaderQualityCheckAverageServicesAllGroupsCombined();
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined:
                return buildObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined();
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesGroupsChart:
                return buildObjectLeaderQualityCheckAverageServicesGroupsChart();
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsGroupsChart:
                return buildObjectLeaderQualityCheckAverageMaterialsGroupsChart();

            case GridStackItemType.FeedbackQualityVerticalChart:
                return <FeedbackQualityVerticalChart ref={refFeedbackQualityVerticalChart} feedbackTableData={tableFeedbackData} motionTableData={motionTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />

            default:
                return buildCockpitFeedbackChart();
        }
    }

    const increaseBarProgress = () => {
        setLoadingProgress(prev => prev + 5);
        setValueBuffer(prev => prev + 5);
    }


    useNoInitialEffect(() => {
        window.scrollTo(0, 0);
        (async () => {
            let endDate = getEndDateFromUrlOrStorage(queryString);
            let startDate = getStartDateFromUrlOrStorage(queryString);
            const token = getApiToken(history);
            if (!token) return;
            setLoadingProgress(0);
            setValueBuffer(10);
            let selectedGridStackCustom: GridStackCustom | null = JSON.parse(localStorage.getItem(GLOBAL_SELECTED_GRID_STACK_CUSTOM) || "null");
            if (!selectedGridStackCustom) return;
            setEvaluationChoice(getEvaluationChoice(groupId, groupIds));


            let isLoadFeedbackDataEnabled = false;
            setLoadingFeedbackDataCompleted(false);

            let isLoadMotionDataEnabled = false;
            setLoadingUsageDataCompleted(false);

            let isLoadFeedbackQuestionDataEnabled = false;

            let isLoadServiceTriggerDataEnabled = false;
            setLoadingTriggerCleaningDataCompleted(false);

            let isLoadServiceMaterialIncidentEnabled = false;
            let isLoadCleaningTasksEnabled = false;

            let isLoadOnlineStatusEnabled = false;

            let isLoadCleanerFeedback = false;
            let isLoadIncidentVisitor = false;

            let isLoadSensorsEnabled = false;

            let isLoadLoraMotionEnabled = false;

            let isLoadQualityChecksEnabled = false;

            for (let item of selectedGridStackCustom.items) {
                switch (item.gridStackItemType) {
                    case GridStackItemType.CockpitFeedbacksFeedbackQuestion:
                    case GridStackItemType.HitlistFeedbackQuestion:
                    case GridStackItemType.FeedbackGroupFeedbackQuestionsWithGroupsSimple:
                        isLoadFeedbackQuestionDataEnabled = true;
                        break;
                    case GridStackItemType.CockpitFeedbackCombFeedbackQualityMotion:
                    case GridStackItemType.FeedbacksGetGroupFeedbacksMotionComb:
                        isLoadFeedbackDataEnabled = true;
                        isLoadMotionDataEnabled = true;
                        break;
                    case GridStackItemType.CockpitGroupFeedbackEntireChartsSimple:
                    case GridStackItemType.CockpitFeedback:
                    case GridStackItemType.FeedbacksGetGroupFeedback:
                    case GridStackItemType.HitlistFeedbackAvgDetails:
                        setFeedbackData([]);
                        setFeedbackColors(getFeedbackColorsSum());
                        isLoadFeedbackDataEnabled = true;
                        break;
                    case GridStackItemType.CockpitVisitor:
                    case GridStackItemType.ServiceModesGroupMotion:
                    case GridStackItemType.HitlistFrequencyCountDetails:
                    case GridStackItemType.ServiceModesRatioMotionsMaterials:
                    case GridStackItemType.FeedbacksGetGroupFeedbacksMotionComb:
                    case GridStackItemType.FeedbacksGetGroupRatioMotionsFeedbacks:
                    case GridStackItemType.FeedbacksGetGroupFeedbackPerVisitor:
                    case GridStackItemType.ServiceModesGroupMotion:
                    case GridStackItemType.FrequencyAvgPercentChart:
                    case GridStackItemType.FrequencyGroupChart:
                    case GridStackItemType.MotionsAggSumDashboard:
                        setGroupMotions([]);
                        setMotionTableData([]);
                        isLoadMotionDataEnabled = true;
                        break;
                    case GridStackItemType.FeedbackQualityVerticalChart:
                        setFeedbackData([]);
                        setMotionTableData([]);
                        isLoadFeedbackDataEnabled = true;
                        isLoadMotionDataEnabled = true;
                        break;
                    case GridStackItemType.CockpitServiceTrigger:
                    case GridStackItemType.ServiceTriggers:
                        setTriggeredCleaningTasks([]);
                        isLoadServiceTriggerDataEnabled = true;
                        break;
                    case GridStackItemType.CockpitCleaningTasks:
                    case GridStackItemType.CockpitMaterials:
                    case GridStackItemType.CockpitIncidents:

                    case GridStackItemType.ServiceModesServicesChart:
                    case GridStackItemType.ServiceModesMaterialsChart:
                    case GridStackItemType.ServiceModesRatioMotionsMaterials:
                    case GridStackItemType.ServiceModesMotionMaterialCount:
                    case GridStackItemType.ServicesModesIncidentChart:
                    case GridStackItemType.ServiceModesServicesCompleted:
                    case GridStackItemType.ServiceModesServicesOnTime:


                    case GridStackItemType.ServiceWorkerServiceModesMaterial:
                    case GridStackItemType.ServiceWorkerServiceModesServices:
                    case GridStackItemType.ServiceWorkerServiceModesIncident:
                        setserviceWorkerServiceModesCleaningTaskUserData([]);
                        setserviceWorkerServiceModesIncidentReportUserData([]);
                        setserviceWorkerServiceModesMaterialUserData([]);

                        setCleaningTaskUserData([]);
                        setIncidentReportUserData([]);
                        setCleaningTaskData([]);
                        setLoadingServicesDataCompleted(false);

                        setserviceModesCleaningTaskData([]);
                        setServiceModesMaterialConsumptionData([]);



                        isLoadServiceMaterialIncidentEnabled = true;
                        break;
                    case GridStackItemType.CockpitServiceWorkerCleaningTasks:
                    case GridStackItemType.CockpitServiceWorkerMaterials:
                    case GridStackItemType.CockpitServiceWorkerIncidents:

                    case GridStackItemType.HitlistServicePunctualCount:
                    case GridStackItemType.HitlistServicesCountDetails:
                        setLoadingServiceUserDataCompleted(false);
                        isLoadServiceMaterialIncidentEnabled = true;
                        break;
                    case GridStackItemType.ServiceModesTaskDoneChartSimple:
                    case GridStackItemType.ServiceModesTaskNotDoneChartSimple:
                        isLoadCleaningTasksEnabled = true;
                        isLoadServiceMaterialIncidentEnabled = true;
                        break;
                    case GridStackItemType.OnlineStatusGroups:
                    case GridStackItemType.OnlineStatusGroupsPercent:
                        isLoadOnlineStatusEnabled = true;
                        break;
                    case GridStackItemType.FeedbackGetCleanerGroupFeedbacks:
                        isLoadCleanerFeedback = true;
                        break;
                    case GridStackItemType.FeedbackIncidentVisitorChart:
                        isLoadIncidentVisitor = true;
                        break;
                    case GridStackItemType.SpecifcGetGroupMotion:
                    case GridStackItemType.SpecificSensorCharts:
                    case GridStackItemType.SpecificSensorChartsIncreaseSum:
                    case GridStackItemType.SpecificSensorChartsFilterSession:
                    case GridStackItemType.SpecifcSensorChartsFilterSessionIncreaseSum:
                        isLoadSensorsEnabled = true;
                        break;
                    case GridStackItemType.LoraFrequencyGetGroupLoraMotion:
                    case GridStackItemType.LoraFrequencyLoraMotionWithGroupsChartSimple:
                        isLoadLoraMotionEnabled = true;
                        break;
                    case GridStackItemType.QualityCheckAverageGroupsSimpleChart:
                    case GridStackItemType.QualityCheckAverageServicesSimpleChart:
                    case GridStackItemType.ObjectLeaderQualityCheckServicesChart:
                    case GridStackItemType.ObjectLeaderQualityCheckMaterialChart:
                    case GridStackItemType.ObjectLeaderIncidentReportChart:
                    case GridStackItemType.ObjectLeaderQualityCheckAverageServicesChart:
                    case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsChart:
                    case GridStackItemType.ObjectLeaderQualityCheckAverageServicesAllGroupsCombined:
                    case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined:
                    case GridStackItemType.ObjectLeaderQualityCheckAverageServicesGroupsChart:
                    case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsGroupsChart:
                        isLoadQualityChecksEnabled = true;
                        break;


                }
            }

            setApiDataLoading(true);
            setCurrentLoadingLabel("Lade Einheiten");

            let groupsPortal: GroupLicenseTable[] = [];
            if (serviceDisplayType == EvalApiType.Objects) {
                groupsPortal = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
                groupIds = groupsPortal.map(x => x.id);
                setGroupsLicenseTableEntries(groupsPortal);
            }
            if (serviceDisplayType == EvalApiType.Groups) {
                groupsPortal = await getPortalGroups(groupIds, token, groupId);
                setGroupsLicenseTableEntries(groupsPortal);
            }
            if (serviceDisplayType == EvalApiType.ServiceWorkers) {
                let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
                groupIds = groups.map(x => x.id);
                setGroupsLicenseTableEntries(groups);
            }

            increaseBarProgress();



            let feedbacks: any[] = [];
            if (isLoadFeedbackDataEnabled) {
                setCurrentLoadingLabel("Feedback");
                feedbacks = await getFeedbackData(groupId, token, groupIds);
            }
            let motions: any[] = [];
            let allMotionsGenerated: any[] = [];
            if (isLoadMotionDataEnabled) {
                setCurrentLoadingLabel("Besucher");
                motions = await getMotionData(groupId, token, groupIds);
                allMotionsGenerated = await loadMotionData(
                    setGroupMotionsAll,
                    setGroupMotionType,
                    setGroupMotions,
                    setLoadingCompleted,
                    AggregateType.Time,
                    motions,
                    null);
                setMotionTableData(motions);
            }
            increaseBarProgress();
            let tempGroupFeedbacks: GroupFeedbackQuestion[] = [];

            if (isLoadFeedbackQuestionDataEnabled) {
                setCurrentLoadingLabel("Feedbackfragen");
                tempGroupFeedbacks = await getFeedbackQuestionsByUserGroupIds(groupId, token, groupIds);
            }
            increaseBarProgress();
            let serviceTriggers: GroupCleaningTrigger[] = [];
            if (isLoadServiceTriggerDataEnabled) {
                setCurrentLoadingLabel("Serviceauslösungen");
                serviceTriggers = await getServiceTriggerByUserIdGroupIds(groupId, token, groupIds);
            }
            increaseBarProgress();
            let servicesModes: GroupServiceMode[] = []
            if (isLoadServiceMaterialIncidentEnabled) {
                setCurrentLoadingLabel("Service Modes");
                servicesModes = await getServiceData(groupId, token, groupIds);
                setAllServiceMode(servicesModes);
            }
            increaseBarProgress();
            let cleaningTasks: CleaningOperation[] = [];
            if (isLoadCleaningTasksEnabled) {
                setCurrentLoadingLabel("Serviceleistungen von Settings");
                cleaningTasks = await getSettingsServicesByGroups(groupId, token, groupIds);
                setAllCleaningTasks(cleaningTasks);
            }
            increaseBarProgress();
            let onlineStatuse: GroupOnlineStatus[] = [];
            if (isLoadOnlineStatusEnabled) {
                setCurrentLoadingLabel("Onlinestatus");
                onlineStatuse = await getOnlineStatusData(groupId, token, groupIds);
                increaseBarProgress();
            }
            let cleanerFeedbacks: GroupCleanerFeedback[] = [];
            if (isLoadCleanerFeedback) {
                setCurrentLoadingLabel("Servicekraftfeedback");
                cleanerFeedbacks = await getCleanerFeedbackByUserGroupIds(groupId, token, groupIds);
                increaseBarProgress();
            }
            let tempIncidentVisitorData: any[] = [];
            if (isLoadIncidentVisitor) {
                setCurrentLoadingLabel("Besucher Vorfälle");
                tempIncidentVisitorData = await getIncidentVisitorByGroupIds(groupId, token, groupIds);
                increaseBarProgress();

            }
            let remoteSensorsTemp: any[] = [];
            if (isLoadSensorsEnabled) {
                setCurrentLoadingLabel("Sensoren");
                if (groupIds && groupIds.length > 0) {
                    remoteSensorsTemp = await getDataFromGroupIdsSensors(groupIds, token);
                }
                else if (groupId) {
                    remoteSensorsTemp = await api.getGroupSensorsById(token, groupId);
                } else {
                    let allSensors = await api.getGroupSensorsByUserId(token);
                    remoteSensorsTemp = extractSensorsArray(allSensors);
                }
                setSensors(remoteSensorsTemp);

                let currentSensor1 = remoteSensorsTemp[0];
                setCurrentSensorFilterSession(currentSensor1)
                let allRoomsTemp = remoteSensorsTemp.map((el) => el.sensorRoomName);
                setAllRoomNames(allRoomsTemp);

                increaseBarProgress();

            }

            let loraMotionsTableData: LoraGroupMotion[] = [];
            if (isLoadLoraMotionEnabled) {
                setCurrentLoadingLabel("Lora Motion");
                loraMotionsTableData = await getLoraMotionData(drawerDisplayType, token, groupIds, groupId);
                increaseBarProgress();
            }
            let qualityChecks: ObjectLeaderQualityCheckModel[] = [];
            if (isLoadQualityChecksEnabled) {
                setCurrentLoadingLabel("Qualitätssicherung");
                qualityChecks = await getQualityChecksByUserIdGroupIds(groupId, token, groupIds);
                setAllQualityChecks(qualityChecks);
                increaseBarProgress();
            }



            setCurrentLoadingLabel("Fertig");
            setLoadingProgress(100);
            setValueBuffer(100);

            for (let item of selectedGridStackCustom.items) {
                switch (item.gridStackItemType) {
                    case GridStackItemType.FeedbackQualityVerticalChart:
                        setTableFeedbackData(feedbacks);
                        setMotionTableData(motions);
                        break;
                    case GridStackItemType.HitlistFeedbackAvgDetails:
                        setTableFeedbackData(feedbacks);
                        break;
                    case GridStackItemType.HitlistFeedbackQuestion:
                    case GridStackItemType.FeedbackGroupFeedbackQuestionsWithGroupsSimple:
                        setFeedbackQuestionTableData(tempGroupFeedbacks);
                        break;
                    case GridStackItemType.CockpitFeedbacksFeedbackQuestion:
                        await loadFeedbackQuestionData(tempGroupFeedbacks,
                            setFeedbackQuestionAllData,
                            setFeedbackQuestionDataFields,
                            setFeedbackQuestionData,
                            setLoadingCompleted,
                            AggregateType.Time);
                        break;
                    case GridStackItemType.CockpitFeedbackCombFeedbackQualityMotion:
                        await loadFeedbackMotionCombData(setFeedbackMotionCombChartDataAll, setFeedbackCombDataFields, setMotionCombDataFields, setFeedbackQualityMotionCombChartData, setLoadingCompleted, feedbacks, motions);
                        break;
                    case GridStackItemType.FeedbacksGetGroupFeedbacksMotionComb:
                        await loadFeedbackMotionCombData(setfeedbackDashboardFeedbackMotionCombChartDataAll, setfeedbackDashboardFeedbackCombDataFields, setfeedbackDashboardMotionCombDataFields, setfeedbackDashboardFeedbackMotionCombChartData, setLoadingCompleted, feedbacks, motions);
                        break;
                    case GridStackItemType.FeedbacksGetGroupFeedback:
                        await loadFeedbackData(
                            setfeedbackDashboardFeedbackAllData,
                            setfeedbackDashboardFeedbackDataType,
                            setfeedbackDashboardFeedbackData,
                            setLoadingCompleted,
                            AggregateType.Time,
                            false,
                            feedbacks,
                            null);
                        break;
                    case GridStackItemType.CockpitGroupFeedbackEntireChartsSimple:
                    case GridStackItemType.CockpitFeedback:
                        loadFeedbackData(
                            setFeedbackAllData,
                            setFeedbackDataType,
                            setFeedbackData,
                            setLoadingFeedbackDataCompleted,
                            AggregateType.Sum,
                            true,
                            feedbacks,
                            setTableFeedbackData);
                        break;
                    case GridStackItemType.HitlistFrequencyCountDetails:
                        setHitlistGroupMotions(motions);
                        break;
                    case GridStackItemType.CockpitVisitor:
                        const r1 = await loadMotionData(
                            setGroupMotionsAllSum,
                            setGroupMotionTypeSum,
                            setGroupMotionsSum,
                            setLoadingUsageDataCompleted,
                            AggregateType.Sum,
                            motions);
                        break;
                    case GridStackItemType.CockpitServiceTrigger:
                        await loadTriggeredCleaningDataAsync(
                            serviceTriggers,
                            setTriggeredCleaningAllTasks,
                            setTriggerCleaningType,
                            setTriggeredCleaningTasks,
                            setLoadingTriggerCleaningDataCompleted);
                        break;
                    case GridStackItemType.CockpitServiceWorkerCleaningTasks:
                    case GridStackItemType.CockpitServiceWorkerMaterials:
                    case GridStackItemType.CockpitServiceWorkerIncidents:
                        const r2 = await manageServiceUserData(
                            servicesModes,
                            setGroupsServiceMode,
                            setCleaningTaskUserAllData,
                            setCleaningTaskUserDataFields,
                            setCleaningTaskUserData,
                            setIncidentReportUserAllData,
                            setIncidentReportUserDataFields,
                            setIncidentReportUserData,
                            setMaterialUserAllData,
                            setMaterialUserDataFields,
                            setMaterialUserData,
                            setCurrentCleanerIncidents,
                            setCurrentCleanerTasks,
                            setCurrentCleanerMaterials,
                            setLoadingServiceUserDataCompleted,
                            AggregateType.Sum);
                        break;
                    case GridStackItemType.CockpitCleaningTasks:
                    case GridStackItemType.CockpitMaterials:
                    case GridStackItemType.CockpitIncidents:
                    case GridStackItemType.CockpitServiceComparePunctualChart:
                    case GridStackItemType.CockpitServiceCompareChart:
                        const r3 = await manageServicesData(
                            servicesModes,
                            setMaterialConsumptionAllData,
                            setMaterialConsumptionDataFields,
                            setMaterialConsumptionData,
                            setIncidentReportAllData,
                            setIncidentReportDataFields,
                            setIncidentReportData,
                            setCleaningTaskAllData,
                            setCleaningTaskDataFields,
                            setCleaningTaskData,
                            setCleaningTaskIsCompletedAllData,
                            setCleaningTaskIsCompletedData,
                            setCleaningTaskIsOnTimeAllData,
                            setCleaningTaskIsOnTimeData,
                            setLoadingServicesDataCompleted,
                            AggregateType.Sum);
                        break;
                    case GridStackItemType.ServiceModesRatioMotionsMaterials:
                    case GridStackItemType.FeedbacksGetGroupFeedbacksMotionComb:
                    case GridStackItemType.FeedbacksGetGroupRatioMotionsFeedbacks:
                    case GridStackItemType.FeedbacksGetGroupFeedbackPerVisitor:
                    case GridStackItemType.ServiceModesGroupMotion:
                        let currentFeedbackDisplayType = localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString();
                        setFeedbackDisplayTypeChart(JSON.parse(currentFeedbackDisplayType));
                        updateGlobalFeedbackDisplayTypeChart(currentFeedbackDisplayType);

                        /*    await loadMotionData(
                                setGroupMotionsAll,
                                setGroupMotionType,
                                setGroupMotions,
                                setLoadingCompleted,
                                AggregateType.Time,
                                motions); */
                        break;
                    case GridStackItemType.FrequencyAvgPercentChart:
                    case GridStackItemType.FrequencyGroupChart:
                        setFrequencyAvgPercentMotions(allMotionsGenerated);
                        break;
                    case GridStackItemType.MotionsAggSumDashboard:
                        setMotionsFromApi(motions);
                        break;
                    case GridStackItemType.SpecifcGetGroupMotion:

                        await loadMotionDataForMultipleSensors(remoteSensorsTemp, allMotionsGenerated, setMultipleSensorsMotionData);
                        break;
                    case GridStackItemType.SpecificSensorCharts:
                        await loadMotionDataForSensor(remoteSensorsTemp, setCurrentSensor, allMotionsGenerated, setSpecificSensorMotionData);
                        break
                    case GridStackItemType.SpecificSensorChartsFilterSession:
                        await loadMotionDataForSensor(remoteSensorsTemp, setCurrentSensor, allMotionsGenerated, setSpecificSensorMotionDataBySensorId);
                        break;
                    case GridStackItemType.SpecificSensorChartsIncreaseSum:
                    case GridStackItemType.SpecifcSensorChartsFilterSessionIncreaseSum:
                        break;
                    case GridStackItemType.ServiceModesServicesChart:
                    case GridStackItemType.ServiceModesMaterialsChart:
                    case GridStackItemType.ServiceModesRatioMotionsMaterials:
                    case GridStackItemType.ServiceModesMotionMaterialCount:
                    case GridStackItemType.ServicesModesIncidentChart:
                    case GridStackItemType.ServiceModesServicesCompleted:
                    case GridStackItemType.ServiceModesServicesOnTime:
                        await manageServicesData(servicesModes,
                            setServiceModesMaterialConsumptionAllData,
                            setserviceModesMaterialConsumptionDataFields,
                            setServiceModesMaterialConsumptionData,
                            setserviceModesIncidentReportAllData,
                            setServiceModesIncidentReportDataFields,
                            setServiceModesIncidentReportData,
                            setserviceModesCleaningTaskAllData,
                            setserviceModesCleaningTaskDataFields,
                            setserviceModesCleaningTaskData,
                            setserviceModesCleaningTaskIsCompletedAllData,
                            setserviceModesCleaningTaskIsCompletedData,
                            setserviceModesCleaningTaskIsOnTimeAllData,
                            setserviceModesCleaningTaskIsOnTimeData,
                            setLoadingCompleted,
                            AggregateType.Time,
                            "");
                        break;
                    case GridStackItemType.ServiceWorkerServiceModesMaterial:
                    case GridStackItemType.ServiceWorkerServiceModesServices:
                    case GridStackItemType.ServiceWorkerServiceModesIncident:
                        await manageServiceUserData(
                            servicesModes,
                            setGroupsServiceMode,
                            setserviceWorkerServiceModesCleaningTaskUserAllData,
                            setserviceWorkerServiceModesCleaningTaskUserDataFields,
                            setserviceWorkerServiceModesCleaningTaskUserData,
                            setserviceWorkerServiceModesIncidentReportUserAllData,
                            setserviceWorkerServiceModesIncidentReportUserDataFields,
                            setserviceWorkerServiceModesIncidentReportUserData,
                            setserviceWorkerServiceModesMaterialUserAllData,
                            setserviceWorkerServiceModesMaterialUserDataFields,
                            setserviceWorkerServiceModesMaterialUserData,
                            setserviceWorkerServiceModesCurrentCleanerIncidents,
                            setserviceWorkerServiceModesCurrentCleanerTasks,
                            setserviceWorkerServiceModesCurrentCleanerMaterials,
                            setLoadingCompleted,
                            AggregateType.Time);
                        break;
                    case GridStackItemType.ServiceTriggers:
                        let allCleaningTriggerTypes = getAllCleaningTriggerTypes();
                        let cleaningTriggerChartAllData = generateCleaningTriggerChartData(serviceTriggers);
                        setserviceTriggersTriggeredCleaningAllTasks(cleaningTriggerChartAllData);
                        let cleaningTriggerChartData = generateChartData(cleaningTriggerChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allCleaningTriggerTypes);
                        setserviceTriggersTriggerCleaningType(allCleaningTriggerTypes);
                        setServiceTriggersTriggeredCleaningTasks(cleaningTriggerChartData);
                        break;
                    case GridStackItemType.OnlineStatusGroups:
                    case GridStackItemType.OnlineStatusGroupsPercent:
                        setOnlineStatusTableData(onlineStatuse);
                        break;
                    case GridStackItemType.FeedbackGetCleanerGroupFeedbacks:
                        await loadCleanerFeedbackData(groupId,
                            setfeedbackDashboardCleanerfeedbackDashboardFeedbackAllData,
                            setFeedbackDasboardCleanerFeedbackDataType,
                            setFeedbackDasboardCleanerFeedbackData,
                            setLoadingCompleted,
                            AggregateType.Time,
                            null,
                            cleanerFeedbacks);
                        break;
                    case GridStackItemType.FeedbackIncidentVisitorChart:
                        await loadIncidentVisitorData(tempIncidentVisitorData,
                            setIncidentVisitorAllData,
                            setIncidentVisitorDataFields,
                            setIncidentVisitorData,
                            setLoadingCompleted,
                            AggregateType.Time,
                            IncidentVisitorChartType.Standard);
                        break;
                    case GridStackItemType.LoraFrequencyGetGroupLoraMotion:
                        await loadMotionData(
                            setGroupLoraMotionsAll,
                            setGroupLoraMotionType,
                            setGroupLoraMotions,
                            setLoadingCompleted,
                            AggregateType.Time,
                            loraMotionsTableData,
                            null);
                        break;
                    case GridStackItemType.LoraFrequencyLoraMotionWithGroupsChartSimple:
                        setGroupLoraMotionsTableData(loraMotionsTableData);
                        break;
                    case GridStackItemType.ObjectLeaderQualityCheckServicesChart:
                        let allServicesTemp = generateServicesChartDataByObjectLeaderQualityChecks(qualityChecks);
                        setObjectLeaderServicesAllData(allServicesTemp);
                        let allServicesBottomRow = getAllServicesByObjectLeaderQualityChecks(qualityChecks)
                        let serviceTemp = generateChartData(allServicesTemp, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allServicesBottomRow);
                        setObjectLeaderServicesDataFields(allServicesBottomRow);
                        setObjectLeaderServicesData(serviceTemp);
                        break;
                    case GridStackItemType.ObjectLeaderQualityCheckMaterialChart:
                        let allMaterialsTemp = generateMaterialsChartDataByObjectLeaderQualityChecks(qualityChecks);
                        setObjectLeaderMaterialsAllData(allMaterialsTemp);
                        let allMaterialsBottomRow = getAllMaterialsByObjectLeaderQualityChecks(qualityChecks)
                        let materialsTemp = generateChartData(allMaterialsTemp, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allMaterialsBottomRow);
                        setObjectLeaderMaterialsDataFields(allMaterialsBottomRow);
                        setObjectLeaderMaterialsData(materialsTemp);
                        break;
                    case GridStackItemType.ObjectLeaderIncidentReportChart:
                        let allIncidentTemp = generateIncidentReportChartDataByObjectLeaderQualityChecks(qualityChecks);
                        setObjectLeaderIncidentReportAllData(allIncidentTemp);
                        let allIncidents = getAllIncidentsByObjectLeaderQualityChecks(qualityChecks)
                        let incidentTemp = generateChartData(allIncidentTemp, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allIncidents);
                        setObjectLeaderIncidentReportDataFields(allIncidents);
                        setObjectLeaderIncidentReportData(incidentTemp);
                        break;
                    case GridStackItemType.ObjectLeaderQualityCheckAverageServicesChart:
                        let allAverageServicesBottomRow = getAllAverageServicesByObjectLeaderQualityChecks(qualityChecks)
                        let allAverageServicesTemp = generateAverageServicesChartDataByObjectLeaderQualityChecks(qualityChecks, allAverageServicesBottomRow);
                        setObjectLeaderAverageServicesAllData(allAverageServicesTemp);
                        setObjectLeaderAverageServicesDataFields(allAverageServicesBottomRow);
                        break;
                    case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsChart:
                        let allAverageMaterialsBottomRow = getAllAverageMaterialsByObjectLeaderQualityChecks(qualityChecks)
                        let allAverageMaterialsTemp = generateAverageMaterialsChartDataByObjectLeaderQualityChecks(qualityChecks, allAverageMaterialsBottomRow);
                        setObjectLeaderAverageMaterialsAllData(allAverageMaterialsTemp);
                        setObjectLeaderAverageMaterialsDataFields(allAverageMaterialsBottomRow);
                        break;
                    case GridStackItemType.ObjectLeaderQualityCheckAverageServicesAllGroupsCombined:
                        let allAverageGroupsCombinedServicesTemp = generateAverageGroupCombinedQualityChecksChartDataByObjectLeader(qualityChecks, groupsPortal);
                        setObjectLeaderAverageGroupsCombinedServiceAllData(allAverageGroupsCombinedServicesTemp);
                        setObjectLeaderAverageGroupsCombinedServiceDataFields(["Gesamt"]);
                        setObjectLeaderAverageGroupsCombinedServiceData(generateChartData(allAverageGroupsCombinedServicesTemp, getDefaultPeriod(), DataFilterType.StartEnd, startDate, endDate, ["Gesamt"]));
                        buildObjectLeaderQualityCheckAverageServicesAllGroupsCombined();
                        break;
                    case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined:
                        let allAverageGroupsCombinedMaterialsTemp = generateAverageGroupCombinedMaterialsQualityChecksChartDataByObjectLeader(qualityChecks, groupsPortal);
                        setObjectLeaderAverageGroupsCombinedMaterialsAllData(allAverageGroupsCombinedMaterialsTemp);
                        setObjectLeaderAverageGroupsCombinedMaterialsDataFields(["Gesamt"]);
                        break;
                    case GridStackItemType.ObjectLeaderQualityCheckAverageServicesGroupsChart:
                        let allAverageGroupsBottomRow = groupsPortal.filter(x => qualityChecks?.some(y => y.groupId == x.id)).filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName)).map(x => x.notificationName);
                        let allAverageGroupsServicesTemp = generateAverageGroupQualityChecksChartDataByObjectLeader(qualityChecks, groupsPortal);
                        setObjectLeaderAverageGroupsServiceAllData(allAverageGroupsServicesTemp);
                        setObjectLeaderAverageGroupsServiceDataFields(allAverageGroupsBottomRow);
                        break;
                    case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsGroupsChart:
                        let allAverageGroupsBottomRow2 = groupsPortal.filter(x => qualityChecks?.some(y => y.groupId == x.id)).filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName)).map(x => x.notificationName);
                        let allAverageGroupsMaterialsTemp = generateAverageMaterialsGroupQualityChecksChartDataByObjectLeader(qualityChecks, groupsPortal);
                        setObjectLeaderAverageGroupsMaterialsAllData(allAverageGroupsMaterialsTemp);
                        setObjectLeaderAverageGroupsMaterialsDataFields(allAverageGroupsBottomRow2);
                        break;

                }
            }
            setLoadingCompleted(true);



            showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);



            setLoadingProgress(100);
            setValueBuffer(100);
            setApiDataLoading(false);


        })();
    }, [apiDataAmountType, reloadDataFromApi]);

    React.useEffect(() => {

        if (groupMotionsAll.length > 0 && feedbackDashboardfeedbackAllData.length > 0) {

            for (let item of selectedGridStackCustom.items) {
                switch (item.gridStackItemType) {
                    case GridStackItemType.FeedbacksGetGroupRatioMotionsFeedbacks:
                    case GridStackItemType.FeedbacksGetGroupFeedbackPerVisitor:
                        setfeedbackDashboardGroupRatioMotionsFeedbackAll([...groupMotionsAll, ...feedbackDashboardfeedbackAllData]);
                        setfeedbackDashboardGroupFeedbackPerVisitorAll([...groupMotionsAll, ...feedbackDashboardfeedbackAllData]);
                        showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
                        break;
                }

            }
        }
    }, [groupMotionsAll, feedbackDashboardfeedbackAllData]);



    const updateEndDateFromParent = (event: any) => {
        if (event == null) return;

        if (childRefFeedbacks.current) childRefFeedbacks.current.updateEndDateFromParent(event);
        if (childRefVisitors.current) childRefVisitors.current.updateEndDateFromParent(event);
        if (childRefTriggers.current) childRefTriggers.current.updateEndDateFromParent(event);
        if (childRefTaskIsCompleted.current) childRefTaskIsCompleted.current.updateEndDateFromParent(event);
        if (childRefIsTaskOnTime.current) childRefIsTaskOnTime.current.updateEndDateFromParent(event);
        if (childRefMaterialConsumption.current) childRefMaterialConsumption.current.updateEndDateFromParent(event);
        if (childRefIncidentReport.current) childRefIncidentReport.current.updateEndDateFromParent(event);
        if (childRefCleaningTask.current) childRefCleaningTask.current.updateEndDateFromParent(event);
        if (childRefCleanerTask.current) childRefCleanerTask.current.updateEndDateFromParent(event);
        if (childRefCleanerIncidents.current) childRefCleanerIncidents.current.updateEndDateFromParent(event);
        if (childRefCleanerMaterials.current) childRefCleanerMaterials.current.updateEndDateFromParent(event);

        if (refGroupFeedbackEntireChartsSimple.current)
            refGroupFeedbackEntireChartsSimple.current.updateEndDateFeedbackWithGroupsSimpleData(event);


        if (childRefFeedbackQualityMotionComb?.current)
            childRefFeedbackQualityMotionComb.current.updateEndDateFromParent(event);

        //Cockpit end

        //Services ref

        if (serviceModeschildRefCleaningTasks.current != null)
            serviceModeschildRefCleaningTasks.current.updateEndDateFromParent(event);

        if (serviceModeschildRefMaterialConsumption.current != null)
            serviceModeschildRefMaterialConsumption.current.updateEndDateFromParent(event);

        if (serviceModeschildRefIncidentReport.current != null)
            serviceModeschildRefIncidentReport.current.updateEndDateFromParent(event);

        if (serviceModeschildRefCompletedTask.current != null)
            serviceModeschildRefCompletedTask.current.updateEndDateFromParent(event);

        if (serviceModeschildRefTaskOnTime.current != null)
            serviceModeschildRefTaskOnTime.current.updateEndDateFromParent(event);

        if (childRefRatioMotionsMaterialConsumption.current != null)
            childRefRatioMotionsMaterialConsumption.current.updateEndDateFromParent(event);

        if (childRefHundredVisitorHowManyMaterials.current != null)
            childRefHundredVisitorHowManyMaterials.current.updateEndDateFromParent(event);

        if (serviceModeschildRefMotion.current != null)
            serviceModeschildRefMotion.current.updateEndDateFromParent(event);

        if (refBuildTaskDoneChart.current && refBuildTaskDoneChart.current.updateSimpleDataEnd) {
            refBuildTaskDoneChart.current.updateSimpleDataEnd(event);
        }

        if (refBuildTaskNotDoneChart.current && refBuildTaskNotDoneChart.current.updateSimpleDataEnd) {
            refBuildTaskNotDoneChart.current.updateSimpleDataEnd(event);
        }

        //service modes end

        //service modes service worker start

        if (childRefTaskDoneByUser.current != null)
            childRefTaskDoneByUser.current.updateEndDateFromParent(event);

        if (childRefIncidentReportDoneByUser?.current != null)
            childRefIncidentReportDoneByUser.current.updateEndDateFromParent(event);

        if (childRefMaterialByUser?.current != null)
            childRefMaterialByUser.current.updateEndDateFromParent(event);

        //service mode serviceworker end

        //servicestrigger
        if (childRefTriggeredCleaningTask.current) {
            childRefTriggeredCleaningTask.current.updateEndDateFromParent(event);
        }

        //online status

        if (refOnlineStatusSimpleChart.current) {
            refOnlineStatusSimpleChart.current.updateEndDateOnlineStatusSimpleChartData(event);
        }


        if (refOnlineStatusSimpleChartPercent.current) {
            refOnlineStatusSimpleChartPercent.current.updateEndDateOnlineStatusSimpleChartData(event);
        }


        //feedback


        if (feedbackDashboardchildRefFeedback?.current)
            feedbackDashboardchildRefFeedback.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefFeedbackPercent?.current)
            feedbackDashboardchildRefFeedbackPercent.current.updateEndDateFromParent(event);


        if (feedbackDashboardchildRefCleanerFeedback?.current)
            feedbackDashboardchildRefCleanerFeedback.current.updateEndDateFromParent(event);

        if (childRefFeedbackQuestion?.current)
            childRefFeedbackQuestion.current.updateEndDateFromParent(event);

        if (childRefMotion?.current)
            childRefMotion.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefGroupRatioMotionsFeedback?.current)
            feedbackDashboardchildRefGroupRatioMotionsFeedback.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefGroupFeedbackPerVisitor?.current)
            feedbackDashboardchildRefGroupFeedbackPerVisitor.current.updateEndDateFromParent(event);


        if (feedbackDashboardchildRefFeedbackMotionComb?.current)
            feedbackDashboardchildRefFeedbackMotionComb.current.updateEndDateFromParent(event);


        if (feedbackDashboardchildRefGroupFeedbackPerVisitor?.current)
            feedbackDashboardchildRefGroupFeedbackPerVisitor.current.updateEndDateFromParent(event);

        if (refGroupFeedbackQuestionsWithGroupsSimple.current)
            refGroupFeedbackQuestionsWithGroupsSimple.current.updateEndDateFeedbackQuestionWithGroupsSimpleData(event);


        if (refGroupFeedbackEntireChartsSimple.current)
            refGroupFeedbackEntireChartsSimple.current.updateEndDateFeedbackWithGroupsSimpleData(event);

        if (refFeedbackQualityVerticalChart.current)
            refFeedbackQualityVerticalChart.current.updateEndDateFeedbackQualityVerticalChartData(event);


        //frequency
        if (childRefMotionIncrease?.current)
            childRefMotionIncrease.current.updateEndDateFromParent(event);

        if (childRefFrequencyAvgPercent?.current)
            childRefFrequencyAvgPercent.current.updateEndDateFromParent(event);

        if (childRefFrequencyGroups?.current)
            childRefFrequencyGroups.current.updateEndDateFromParent(event);

        if (childRefMotionsBySensor?.current)
            childRefMotionsBySensor.current.updateEndDateFromParent(event);

        if (childRefMotionsSumIncreaseBySensor?.current)
            childRefMotionsSumIncreaseBySensor.current.updateEndDateFromParent(event);

        if (childRefMotionsBySensorFilterId?.current)
            childRefMotionsBySensorFilterId.current.updateEndDateFromParent(event);

        if (childRefMotionsSumIncreaseBySensorFilterId?.current)
            childRefMotionsSumIncreaseBySensorFilterId.current.updateEndDateFromParent(event);

        if (childRefMotionMultipleRoomsIncrease?.current)
            childRefMotionMultipleRoomsIncrease.current.updateEndDateFromParent(event);

        if (childRefMotionMultipleRooms?.current)
            childRefMotionMultipleRooms.current.updateEndDateFromParent(event);

        //lora motion

        if (childRefLoraMotion?.current)
            childRefLoraMotion.current.updateEndDateFromParent(event);


        if (refGroupLoraMotionsWithGroupsSimple.current)
            refGroupLoraMotionsWithGroupsSimple.current.updateEndDateLoraMotionWithGroupsSimpleData(event);

        //quality checks

        if (childRefObjectLeaderServices.current) childRefObjectLeaderServices.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices.current) childRefObjectLeaderAverageGroupsServices.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsCombinedServices.current) childRefObjectLeaderAverageGroupsCombinedServices.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderMaterials.current) childRefObjectLeaderMaterials.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageMaterials.current) childRefObjectLeaderAverageMaterials.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsMaterials.current) childRefObjectLeaderAverageGroupsMaterials.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsCombinedMaterials.current) childRefObjectLeaderAverageGroupsCombinedMaterials.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderIncidents.current) childRefObjectLeaderIncidents.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageServices.current) childRefObjectLeaderAverageServices.current.updateEndDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices.current) childRefObjectLeaderAverageGroupsServices.current.updateEndDateFromParent(event);

        if (refAverageServicesSimpleChart.current) {
            refAverageServicesSimpleChart.current.updateEndDateAverageServicesSimpleChartData(event);
        }
        if (refAverageGroupsSimpleChart.current) {
            refAverageGroupsSimpleChart.current.updateEndDateAverageGroupsSimpleChartData(event);
        }



        //hitlist
        if (hitlistRefServicesCountSimpleChart.current) {
            hitlistRefServicesCountSimpleChart.current.updateEndDateServicesChartSimpleCount(event);
        }
        if (hitlistRefServicesPunctualCountSimpleChart.current) {
            hitlistRefServicesPunctualCountSimpleChart.current.updateEndDateServicesChartSimpleCount(event);
        }
        if (hitlistRefFeedbackQuestionSimpleChart.current) {
            hitlistRefFeedbackQuestionSimpleChart.current.updateEndDateFeedbackQuestionSimpleData(event);
        }
        if (hitlistRefFeedbacksQualitySimpleChart.current) {
            hitlistRefFeedbacksQualitySimpleChart.current.updateEndDateFeedbacksQualityChartSimpleCount(event);
        }
        if (hitlistRefFrequencyCountSimpleChart.current) {
            hitlistRefFrequencyCountSimpleChart.current.updateEndDateFrequenciesChartSimpleCount(event);
        }






    }

    const updateStartDateFromParent = (event: any) => {
        if (event == null) return;

        if (childRefFeedbacks.current) childRefFeedbacks.current.updateStartDateFromParent(event);
        if (childRefVisitors.current) childRefVisitors.current.updateStartDateFromParent(event);
        if (childRefTriggers.current) childRefTriggers.current.updateStartDateFromParent(event);
        if (childRefTaskIsCompleted.current) childRefTaskIsCompleted.current.updateStartDateFromParent(event);
        if (childRefIsTaskOnTime.current) childRefIsTaskOnTime.current.updateStartDateFromParent(event);
        if (childRefMaterialConsumption.current) childRefMaterialConsumption.current.updateStartDateFromParent(event);
        if (childRefIncidentReport.current) childRefIncidentReport.current.updateStartDateFromParent(event);
        if (childRefCleaningTask.current) childRefCleaningTask.current.updateStartDateFromParent(event);
        if (childRefCleanerTask.current) childRefCleanerTask.current.updateStartDateFromParent(event);
        if (childRefCleanerIncidents.current) childRefCleanerIncidents.current.updateStartDateFromParent(event);
        if (childRefCleanerMaterials.current) childRefCleanerMaterials.current.updateStartDateFromParent(event);

        if (refGroupFeedbackEntireChartsSimple.current)
            refGroupFeedbackEntireChartsSimple.current.updateStartDateFeedbackWithGroupsSimpleData(event);


        if (childRefFeedbackQualityMotionComb?.current)
            childRefFeedbackQualityMotionComb.current.updateStartDateFromParent(event);


        //Cockpit end

        //Services Ref

        if (serviceModeschildRefCleaningTasks.current != null)
            serviceModeschildRefCleaningTasks.current.updateStartDateFromParent(event);

        if (serviceModeschildRefMaterialConsumption.current != null)
            serviceModeschildRefMaterialConsumption.current.updateStartDateFromParent(event);

        if (serviceModeschildRefIncidentReport.current != null)
            serviceModeschildRefIncidentReport.current.updateStartDateFromParent(event);

        if (serviceModeschildRefCompletedTask.current != null)
            serviceModeschildRefCompletedTask.current.updateStartDateFromParent(event);

        if (serviceModeschildRefTaskOnTime.current != null)
            serviceModeschildRefTaskOnTime.current.updateStartDateFromParent(event);

        if (childRefRatioMotionsMaterialConsumption.current != null)
            childRefRatioMotionsMaterialConsumption.current.updateStartDateFromParent(event);

        if (childRefHundredVisitorHowManyMaterials.current != null)
            childRefHundredVisitorHowManyMaterials.current.updateStartDateFromParent(event);

        if (serviceModeschildRefMotion.current != null)
            serviceModeschildRefMotion.current.updateStartDateFromParent(event);

        if (refBuildTaskDoneChart.current && refBuildTaskDoneChart.current) {
            refBuildTaskDoneChart.current.updateSimpleDataStart(event);
        }

        if (refBuildTaskNotDoneChart.current && refBuildTaskNotDoneChart.current) {
            refBuildTaskNotDoneChart.current.updateSimpleDataStart(event);
        }

        //service modes service worker--------------------------------------

        if (childRefTaskDoneByUser?.current)
            childRefTaskDoneByUser.current.updateStartDateFromParent(event);

        if (childRefIncidentReportDoneByUser?.current)
            childRefIncidentReportDoneByUser.current.updateStartDateFromParent(event);

        if (childRefMaterialByUser?.current)
            childRefMaterialByUser.current.updateStartDateFromParent(event);

        //service trigger
        if (childRefTriggeredCleaningTask.current) {
            childRefTriggeredCleaningTask.current.updateStartDateFromParent(event);
        }

        //online status
        if (refOnlineStatusSimpleChart.current) {
            refOnlineStatusSimpleChart.current.updateStartDateOnlineStatusSimpleChartData(event);
        }

        if (refOnlineStatusSimpleChartPercent.current) {
            refOnlineStatusSimpleChartPercent.current.updateStartDateOnlineStatusSimpleChartData(event);
        }


        //feedback

        if (feedbackDashboardchildRefFeedback?.current)
            feedbackDashboardchildRefFeedback.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefFeedbackPercent?.current)
            feedbackDashboardchildRefFeedbackPercent.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefCleanerFeedback?.current)
            feedbackDashboardchildRefCleanerFeedback.current.updateStartDateFromParent(event);

        if (childRefFeedbackQuestion?.current)
            childRefFeedbackQuestion.current.updateStartDateFromParent(event);

        if (childRefMotion?.current)
            childRefMotion.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefGroupRatioMotionsFeedback?.current)
            feedbackDashboardchildRefGroupRatioMotionsFeedback.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefGroupFeedbackPerVisitor?.current)
            feedbackDashboardchildRefGroupFeedbackPerVisitor.current.updateStartDateFromParent(event);


        if (feedbackDashboardchildRefFeedbackMotionComb?.current)
            feedbackDashboardchildRefFeedbackMotionComb.current.updateStartDateFromParent(event);

        if (childRefFeedbackQualityMotionComb?.current)
            childRefFeedbackQualityMotionComb.current.updateStartDateFromParent(event);

        if (refGroupFeedbackQuestionsWithGroupsSimple.current)
            refGroupFeedbackQuestionsWithGroupsSimple.current.updateStartDateFeedbackQuestionWithGroupsSimpleData(event);


        if (refGroupFeedbackEntireChartsSimple.current)
            refGroupFeedbackEntireChartsSimple.current.updateStartDateFeedbackWithGroupsSimpleData(event);

        if (refFeedbackQualityVerticalChart.current)
            refFeedbackQualityVerticalChart.current.updateStartDateFeedbackQualityVerticalChartData(event);



        //frequency
        if (childRefMotionIncrease?.current)
            childRefMotionIncrease.current.updateStartDateFromParent(event);


        if (childRefFrequencyAvgPercent?.current)
            childRefFrequencyAvgPercent.current.updateStartDateFromParent(event);

        if (childRefFrequencyGroups?.current)
            childRefFrequencyGroups.current.updateStartDateFromParent(event);

        if (childRefMotionsBySensor?.current)
            childRefMotionsBySensor.current.updateStartDateFromParent(event);

        if (childRefMotionsSumIncreaseBySensor?.current)
            childRefMotionsSumIncreaseBySensor.current.updateStartDateFromParent(event);

        if (childRefMotionsBySensorFilterId?.current)
            childRefMotionsBySensorFilterId.current.updateStartDateFromParent(event);

        if (childRefMotionsSumIncreaseBySensorFilterId?.current)
            childRefMotionsSumIncreaseBySensorFilterId.current.updateStartDateFromParent(event);

        if (childRefMotionMultipleRoomsIncrease?.current)
            childRefMotionMultipleRoomsIncrease.current.updateStartDateFromParent(event);

        if (childRefMotionMultipleRooms?.current)
            childRefMotionMultipleRooms.current.updateStartDateFromParent(event);


        //lora motion

        if (childRefLoraMotion?.current)
            childRefLoraMotion.current.updateStartDateFromParent(event);

        if (refGroupLoraMotionsWithGroupsSimple.current)
            refGroupLoraMotionsWithGroupsSimple.current.updateStartDateLoraMotionWithGroupsSimpleData(event);

        // quality checks

        if (childRefObjectLeaderServices.current) childRefObjectLeaderServices.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices.current) childRefObjectLeaderAverageGroupsServices.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsCombinedServices.current) childRefObjectLeaderAverageGroupsCombinedServices.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderMaterials.current) childRefObjectLeaderMaterials.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageMaterials.current) childRefObjectLeaderAverageMaterials.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsMaterials.current) childRefObjectLeaderAverageGroupsMaterials.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsCombinedMaterials.current) childRefObjectLeaderAverageGroupsCombinedMaterials.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderIncidents.current) childRefObjectLeaderIncidents.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageServices.current) childRefObjectLeaderAverageServices.current.updateStartDateFromParent(event);
        if (childRefObjectLeaderAverageGroupsServices.current) childRefObjectLeaderAverageGroupsServices.current.updateStartDateFromParent(event);


        if (refAverageServicesSimpleChart.current) {
            refAverageServicesSimpleChart.current.updateStartDateAverageServicesSimpleChartData(event);
        }
        if (refAverageGroupsSimpleChart.current) {
            refAverageGroupsSimpleChart.current.updateStartDateAverageGroupsSimpleChartData(event);
        }

        //hitlist
        if (hitlistRefServicesCountSimpleChart.current) {
            hitlistRefServicesCountSimpleChart.current.updateStartDateServicesSimpleChartCount(event);
        }
        if (hitlistRefServicesPunctualCountSimpleChart.current) {
            hitlistRefServicesPunctualCountSimpleChart.current.updateStartDateServicesSimpleChartCount(event);
        }
        if (hitlistRefFeedbackQuestionSimpleChart.current) {
            hitlistRefFeedbackQuestionSimpleChart.current.updateStartDateFeedbackQuestionSimpleData(event);
        }
        if (hitlistRefFeedbacksQualitySimpleChart.current) {
            hitlistRefFeedbacksQualitySimpleChart.current.updateStartDateFeedbacksQualitySimpleChartCount(event);
        }
        if (hitlistRefFrequencyCountSimpleChart.current) {
            hitlistRefFrequencyCountSimpleChart.current.updateStartDateFrequenciesSimpleChartCount(event);
        }

    }

    const updateDataFilterTypeFromParent = (event: any) => {
        if (event == null) return;

        if (childRefFeedbacks.current) childRefFeedbacks.current.updateDataFilterTypeFromParent(event);
        if (childRefVisitors.current) childRefVisitors.current.updateDataFilterTypeFromParent(event);
        if (childRefTriggers.current) childRefTriggers.current.updateDataFilterTypeFromParent(event);
        if (childRefTaskIsCompleted.current) childRefTaskIsCompleted.current.updateDataFilterTypeFromParent(event);
        if (childRefIsTaskOnTime.current) childRefIsTaskOnTime.current.updateDataFilterTypeFromParent(event);
        if (childRefMaterialConsumption.current) childRefMaterialConsumption.current.updateDataFilterTypeFromParent(event);
        if (childRefIncidentReport.current) childRefIncidentReport.current.updateDataFilterTypeFromParent(event);
        if (childRefCleaningTask.current) childRefCleaningTask.current.updateDataFilterTypeFromParent(event);
        if (childRefCleanerTask.current) childRefCleanerTask.current.updateDataFilterTypeFromParent(event);
        if (childRefCleanerIncidents.current) childRefCleanerIncidents.current.updateDataFilterTypeFromParent(event);
        if (childRefCleanerMaterials.current) childRefCleanerMaterials.current.updateDataFilterTypeFromParent(event);

        if (childRefFeedbackQualityMotionComb?.current)
            childRefFeedbackQualityMotionComb.current.updateDataFilterTypeFromParent(event);
    }

    const updateChosenPastDaysFromParent = (event: any) => {
        if (!event) return;

        if (childRefFeedbacks.current) childRefFeedbacks.current.updateChosenPastDays(event.target.value);
        if (childRefVisitors.current) childRefVisitors.current.updateChosenPastDays(event.target.value);
        if (childRefTriggers.current) childRefTriggers.current.updateChosenPastDays(event.target.value);
        if (childRefTaskIsCompleted.current) childRefTaskIsCompleted.current.updateChosenPastDays(event.target.value);
        if (childRefIsTaskOnTime.current) childRefIsTaskOnTime.current.updateChosenPastDays(event.target.value);
        if (childRefMaterialConsumption.current) childRefMaterialConsumption.current.updateChosenPastDays(event.target.value);
        if (childRefIncidentReport.current) childRefIncidentReport.current.updateChosenPastDays(event.target.value);
        if (childRefCleaningTask.current) childRefCleaningTask.current.updateChosenPastDays(event.target.value);
        if (childRefCleanerTask.current) childRefCleanerTask.current.updateChosenPastDays(event.target.value);
        if (childRefCleanerIncidents.current) childRefCleanerIncidents.current.updateChosenPastDays(event.target.value);
        if (childRefCleanerMaterials.current) childRefCleanerMaterials.current.updateChosenPastDays(event.target.value);
    }

    const buildCockpitFeedbackChart = () => {
        return (
            <CustomSumOptionsFeedbacks
                childRef={childRefFeedbacks}
                childRefChart={childRefFeedbacksChart}
                defaultChoice={'1'}
                text={"Feedback-Meldungen"}
                setChartData={setFeedbackData}
                allData={feedbackAllData}
                data={feedbackData}
                dataField={feedbackDataType}
                colors={feedbackColors}
                loadingCompleted={loadingFeedbackDataCompleted} />
        )
    }

    const buildCockpitVisitorsChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefVisitorsChart}
                childRef={childRefVisitors}
                defaultChoice={'1'}
                text={"Besucher"}
                setChartData={setGroupMotionsSum}
                allData={groupMotionsAllSum}
                data={groupMotionsSum}
                dataField={groupMotionTypeSum}
                chartSumType={ChartSumType.Motion}
                loadingCompleted={loadingUsageDataCompleted} />
        )
    }

    const buildCockpitServiceTriggerChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefTriggersChart}
                childRef={childRefTriggers}
                defaultChoice={'1'}
                text={"Serviceauslösung"}
                setChartData={setTriggeredCleaningTasks}
                allData={triggeredCleaningAllTasks}
                data={triggeredCleaningTasks}
                dataField={triggerCleaningType}
                loadingCompleted={loadingUsageDataCompleted && loadingTriggeredCleaningDataCompleted} />
        )
    }

    const buildServiceCompareChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefTaskIsCompletedChart}
                childRef={childRefTaskIsCompleted}
                defaultChoice={'1'}
                text={"Service erledigt: Alle & Teilweise"}
                setChartData={setCleaningTaskIsCompletedData}
                allData={cleaningTaskIsCompletedAllData}
                data={cleaningTaskIsCompletedData}
                dataField={getCompletedCleaningTaskFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildServiceComparePunctualChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefIsTaskOnTimeChart}
                childRef={childRefIsTaskOnTime}
                defaultChoice={'1'}
                text={"Service: Verpasst & Pünktlich"}
                setChartData={setCleaningTaskIsOnTimeData}
                allData={cleaningTaskIsOnTimeAllData}
                data={cleaningTaskIsOnTimeData}
                dataField={getIsOnTimeCleaningTaskFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildMaterialChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefMaterialConsumptionChart}
                childRef={childRefMaterialConsumption}
                defaultChoice={'1'}
                text={t("servicedocumentationChart.materialUsage")}
                setChartData={setMaterialConsumptionData}
                allData={materialConsumptionAllData}
                data={materialConsumptionData}
                dataField={materialConsumptionDataFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildIncidentsChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefIncidentReportChart}
                childRef={childRefIncidentReport}
                defaultChoice={'1'}
                text={t("serviceDocumentationTable.incidentReports")}
                setChartData={setIncidentReportData}
                allData={incidentReportAllData}
                data={incidentReportData}
                dataField={incidentReportDataFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildServicesChart = () => {
        return (
            <CustomSumOptions
                childRefChart={childRefCleaningTaskChart}
                childRef={childRefCleaningTask}
                defaultChoice={'0'}
                text={t("servicedocumentationChart.services")}
                setChartData={setCleaningTaskData}
                allData={cleaningTaskAllData}
                data={cleaningTaskData}
                dataField={cleaningTaskDataFields}
                loadingCompleted={loadingServicesDataCompleted} />
        )
    }

    const buildServiceForUserChart = () => {
        return (
            <CustomSumUserOptions
                childRefChart={childRefCleanerTaskChart}
                childRef={childRefCleanerTask}
                currentCleaner={currentCleanerTasks}
                setCurrentCleaner={setCurrentCleanerTasks}
                defaultChoice={'0'}
                text={`Serviceleistungen durchgeführt durch Servicekräfte ${currentCleanerTasks}`}
                setChartData={setCleaningTaskUserData}
                allData={cleaningTaskUserAllData}
                data={cleaningTaskUserData}
                dataField={cleaningTaskUserDataFields}
                loadingCompleted={loadingServiceUserDataCompleted} />
        )
    }

    const buildIncidentsForUserChart = () => {
        return (
            <CustomSumUserOptions
                childRefChart={childRefCleanerIncidentsChart}
                childRef={childRefCleanerIncidents}
                currentCleaner={currentCleanerIncidents}
                setCurrentCleaner={setCurrentCleanerIncidents}
                defaultChoice={'1'}
                text={`Vorfallmeldung durchgeführt durch Servicekräfte ${currentCleanerIncidents}`}
                setChartData={setIncidentReportUserData}
                allData={incidentReportUserAllData}
                data={incidentReportUserData}
                dataField={incidentReportUserDataFields}
                loadingCompleted={loadingServiceUserDataCompleted} />
        )
    }

    const buildMaterialForUserChart = () => {
        return (
            <CustomSumUserOptions
                childRefChart={childRefCleanerMaterialsChart}
                childRef={childRefCleanerMaterials}
                currentCleaner={currentCleanerMaterials}
                setCurrentCleaner={setCurrentCleanerMaterials}
                defaultChoice={'1'}
                text={`Material verwendet durch Servicekräfte ${currentCleanerMaterials}`}
                setChartData={setMaterialUserData}
                allData={materialUserAllData}
                data={materialUserData}
                dataField={materialUserDataFields}
                loadingCompleted={loadingServiceUserDataCompleted} />
        )
    }

    const buildGetGroupFeedbacksQualityMotionComb = () => {
        return (
            <GetGroupFeedbacksQualityMotionComb feedbackDisplayTypeChart={feedbackDisplayTypeChart} data={feedbackQualityMotionCombChartData} allData={feedbackMotionCombChartDataAll} setChartData={setFeedbackQualityMotionCombChartData} childRef={childRefFeedbackQualityMotionComb} loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbackQuestions = () => {
        return (
            <GetGroupFeedbackQuestions
                title={FEEDBACK_QUESTION_NEGATIVE_CHART_TITLE}
                childRefChart={childRefFeedbackQuestionChart}
                childRef={childRefFeedbackQuestion}
                setChartData={setFeedbackQuestionData}
                allData={feedbackQuestionAllData}
                data={feedbackQuestionData}
                dataField={feedbackQuestionDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    //Cockpit End -------------------------------------------------------------------

    // Services Charts ----------------------------------------------------------------

    const buildServiceModesCleaningTasksDashboard = () => {
        return (
            <CleaningTasksDashboard
                childRefChart={serviceModeschildRefCleaningTasksChart}
                childRef={serviceModeschildRefCleaningTasks}
                setChartData={setserviceModesCleaningTaskData}
                allData={serviceModescleaningTaskAllData}
                data={serviceModescleaningTaskData}
                dataField={serviceModescleaningTaskDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildServiceModesMaterialConsumptionDashboard = () => {
        return (
            <MaterialConsumptionDashboard
                childRefChart={serviceModeschildRefMaterialConsumptionChart}
                childRef={serviceModeschildRefMaterialConsumption}
                setChartData={setServiceModesMaterialConsumptionData}
                materialConsumptionData={serviceModesMaterialConsumptionData}
                dataField={serviceModesmaterialConsumptionDataFields}
                allData={serviceModesMaterialConsumptionAllData}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildServiceModesGetGroupRatioMotionsMaterials = () => {
        return (
            <GetGroupRatioMotionsMaterials
                childRef={childRefRatioMotionsMaterialConsumption}
                setChartData={setserviceModesRatioMotionsMaterialConsumptionData}
                data={serviceModesratioMotionsMaterialConsumptionData}
                dataField={serviceModesratioMotionsMaterialConsumptionDataFields}
                allData={serviceModesratioMotionsMaterialConsumptionAllData}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildServiceModesGetGroupMotionsMaterialsCount = () => {
        return (
            <GetGroupMotionsMaterialsCount
                childRef={childRefHundredVisitorHowManyMaterials}
                setChartData={setserviceModesHundredVisitorHowManyMaterialsData}
                data={serviceModeshundredVisitorHowManyMaterialsData}
                dataField={serviceModeshundredVisitorHowManyMaterialsDataFields}
                allData={serviceModeshundredVisitorHowManyMaterialsAllData}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildServiceModesIncidentReportDashboard = () => {
        return (
            <IncidentReportDashboard
                childRefChart={serviceModeschildRefIncidentReportChart}
                childRef={serviceModeschildRefIncidentReport}
                setChartData={setServiceModesIncidentReportData}
                allData={serviceModesincidentReportAllData}
                incidentReportData={serviceModesincidentReportData}
                dataField={serviceModesincidentReportDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildServiceModesCleaningTasksCompletedDashboard = () => {
        return (
            <CleaningTasksCompletedDashboard
                childRefChart={serviceModeschildRefCompletedTaskChart}
                childRef={serviceModeschildRefCompletedTask}
                setChartData={setserviceModesCleaningTaskIsCompletedData}
                allData={serviceModescleaningTaskIsCompletedAllData}
                data={serviceModescleaningTaskIsCompletedData}
                dataName1={'Alle'}
                dataName2={"Teilweise"}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildServiceModesCleaningTasksOnTimeDashboard = () => {
        return (
            <CleaningTasksOnTimeDashboard
                childRefChart={serviceModeschildRefTaskOnTimeChart}
                childRef={serviceModeschildRefTaskOnTime}
                setChartData={setserviceModesCleaningTaskIsOnTimeData}
                allData={serviceModescleaningTaskIsOnTimeAllData}
                data={serviceModescleaningTaskIsOnTimeData}
                dataName1={'Püntklich'}
                dataName2={"Verpasst"}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildServiceModesGetGroupMotion = () => {
        return (
            <GroupMotionChart
                childRefChart={serviceModesserviceModeschildRefMotionChart}
                childRef={serviceModeschildRefMotion}
                setChartData={setGroupMotions}
                allData={groupMotionsAll}
                data={groupMotions}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }

    //services charts end-----------------------------------------


    //service worker charts start--------------------------------------

    const buildDoneByUserCleaningTaskDashboard = () => {
        return (
            <DoneByUserCleaningTaskDashboard
                childRefChart={childRefTaskDoneByUserChart}
                childRef={childRefTaskDoneByUser}
                setCurrentCleaner={setserviceWorkerServiceModesCurrentCleanerTasks}
                currentCleaner={serviceWorkerServiceModescurrentCleanerTasks}
                setChartData={setserviceWorkerServiceModesCleaningTaskUserData}
                allData={serviceWorkerServiceModescleaningTaskUserAllData}
                data={serviceWorkerServiceModescleaningTaskUserData}
                dataField={serviceWorkerServiceModescleaningTaskUserDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildDoneByUserIncidentReportDashboard = () => {
        return (
            <DoneByUserIncidentReportDashboard
                childRefChart={childRefIncidentReportDoneByUserChart}
                childRef={childRefIncidentReportDoneByUser}
                setCurrentCleaner={setserviceWorkerServiceModesCurrentCleanerIncidents}
                currentCleaner={serviceWorkerServiceModescurrentCleanerIncidents}
                setChartData={setserviceWorkerServiceModesIncidentReportUserData}
                allData={serviceWorkerServiceModesincidentReportUserAllData}
                data={serviceWorkerServiceModesincidentReportUserData}
                dataField={serviceWorkerServiceModesincidentReportUserDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildDoneByUserMaterialConsumptionDashboard = () => {
        return (
            <DoneByUserMaterialConsumptionDashboard
                childRefChart={childRefMaterialByUserChart}
                childRef={childRefMaterialByUser}
                setCurrentCleaner={setserviceWorkerServiceModesCurrentCleanerMaterials}
                currentCleaner={serviceWorkerServiceModescurrentCleanerMaterials}
                setChartData={setserviceWorkerServiceModesMaterialUserData}
                allData={serviceWorkerServiceModesmaterialUserAllData}
                data={serviceWorkerServiceModesmaterialUserData}
                dataField={serviceWorkerServiceModesmaterialUserDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    //service worker chart end----------------------------------

    //service trigger

    const buildServiceTriggerChart = () => {
        return (
            <TriggeredCleaningTask
                childRefChart={childRefTriggeredCleaningTaskChart}
                childRef={childRefTriggeredCleaningTask}
                setChartData={setServiceTriggersTriggeredCleaningTasks}
                allData={serviceTriggerstriggeredCleaningAllTasks}
                data={ServiceTriggerstriggeredCleaningTasks}
                dataField={serviceTriggerstriggerCleaningType}
                loadingCompleted={loadingCompleted} />
        )
    }

    // end service trigger

    //start feedback charts-----------------------------------------------------------

    const buildGetGroupFeedbacksMotionComb = () => {
        return (
            <GetGroupFeedbacksMotionComb feedbackDisplayTypeChart={feedbackDisplayTypeChart} data={feedbackDashboardfeedbackMotionCombChartData} dataFieldBars={feedbackDashboardmotionCombDataFields} dataFieldLines={feedbackDashboardfeedbackCombDataFields} allData={feedbackDashboardfeedbackMotionCombChartDataAll} setChartData={setfeedbackDashboardFeedbackMotionCombChartData}
                childRefChart={feedbackDashboardchildRefFeedbackMotionCombChart}
                childRef={feedbackDashboardchildRefFeedbackMotionComb} loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbacks = () => {
        return (
            <GetGroupFeedbacks
                childRefChart={feedbackDashboardchildRefFeedbackChart}
                childRef={feedbackDashboardchildRefFeedback}
                setChartData={setfeedbackDashboardFeedbackData}
                allData={feedbackDashboardfeedbackAllData}
                data={feedbackDashboardfeedbackData}
                dataField={feedbackDashboardfeedbackDataType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbacksPercent = () => {
        return (
            <GetGroupFeedbacksPercent
                childRefChart={feedbackDashboardchildRefFeedbackChartPercent}
                childRef={feedbackDashboardchildRefFeedbackPercent}
                setChartData={setfeedbackDashboardFeedbackData}
                allData={feedbackDashboardfeedbackAllData}
                data={feedbackDashboardfeedbackData}
                dataField={feedbackDashboardfeedbackDataType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingCompleted} />
        )
    }


    const buildGetfeedbackDashboardGroupRatioMotionsFeedbacks = () => {
        return (
            <GetGroupRatioMotionsFeedbacks
                childRef={feedbackDashboardchildRefGroupRatioMotionsFeedback}
                setChartData={setfeedbackDashboardGroupRatioMotionsFeedback}
                allData={feedbackDashboardgroupRatioMotionsFeedbackAll}
                data={feedbackDashboardgroupRatioMotionsFeedback}
                dataField={feedbackDashboardgroupRatioMotionsFeedbackType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetfeedbackDashboardGroupFeedbackPerVisitor = () => {
        return (
            <GetGroupFeedbackPerVisitor
                childRef={feedbackDashboardchildRefGroupFeedbackPerVisitor}
                setChartData={setfeedbackDashboardGroupFeedbackPerVisitor}
                allData={feedbackDashboardGroupFeedbackPerVisitorAll}
                data={feedbackDashboardGroupFeedbackPerVisitor}
                dataField={groupfeedbackDashboardGroupFeedbackPerVisitorType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingCompleted} />
        )
    }


    const buildIncidentVisitorChart = () => {
        return (
            <Paper>
                <IncidentVisitorChart
                    childRefChart={childRefIncidentVisitorChart}
                    childRef={childRefIncidentVisitor}
                    setChartData={setIncidentVisitorData}
                    allData={incidentVisitorAllData}
                    data={incidentVisitorData}
                    dataField={incidentVisitorDataFields}
                    loadingCompleted={loadingCompleted} />
            </Paper>

        )
    }

    const buildGetCleanerGroupFeedbacks = () => {
        return (
            <GetCleanerGroupFeedbacks
                childRefChart={feedbackDashboardchildRefCleanerFeedbackChart}
                childRef={feedbackDashboardchildRefCleanerFeedback}
                setChartData={setFeedbackDasboardCleanerFeedbackData}
                allData={feedbackDashboardcleanerfeedbackDashboardFeedbackAllData}
                data={cleanerfeedbackDashboardFeedbackData}
                dataField={cleanerfeedbackDashboardFeedbackDataType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildFeedbackCountChoice = () => {

        return (
            <Paper style={{ padding: 10 }}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("myUnitsSettings.smileType")}</FormLabel>
                    <RadioGroup

                        name="feedbackDisplayTypeChart"
                        value={feedbackDisplayTypeChart}
                        onChange={(event) => {
                            updateGlobalFeedbackDisplayTypeChart(event.target.value);
                        }}
                    >
                        <FormControlLabel value={FeedbackDisplayType.PositiveNegativeNeutral} control={<Radio />} label="3 Smiles" />
                        <FormControlLabel value={FeedbackDisplayType.AdvancedWithVeryPostiveNegative} control={<Radio />} label="5 Smiles" />
                    </RadioGroup>
                </FormControl>
            </Paper>
        )
    }

    const updateGlobalFeedbackDisplayTypeChart = (value: string) => {
        if (value == FeedbackDisplayType.PositiveNegativeNeutral.toString()) {
            setFeedbackDisplayTypeChart(FeedbackDisplayType.PositiveNegativeNeutral);
            setfeedbackDashboardFeedbackDataType(getFeebacksTypesThreeSmiles());
            setFeedbackDasboardCleanerFeedbackDataType(getFeebacksTypesThreeSmiles());
            setfeedbackDashboardGroupRatioMotionsFeedbackType(getRatioMotionFeedbackThreeSmilesType());
            setfeedbackDashboardGroupFeedbackPerVisitorType(["allFeedback"]);
            localStorage.setItem(FEEDBACK_DISPLAY_TYPE_CHART, FeedbackDisplayType.PositiveNegativeNeutral.toString());
        } else {
            setFeedbackDisplayTypeChart(FeedbackDisplayType.AdvancedWithVeryPostiveNegative);
            setfeedbackDashboardFeedbackDataType(getAllFeebacksTypes());
            setFeedbackDasboardCleanerFeedbackDataType(getAllCleanerFeebacksTypes());
            setfeedbackDashboardGroupRatioMotionsFeedbackType(getRatioMotionFeedbackType());
            setfeedbackDashboardGroupFeedbackPerVisitorType(["allFeedback"]);
            localStorage.setItem(FEEDBACK_DISPLAY_TYPE_CHART, FeedbackDisplayType.AdvancedWithVeryPostiveNegative.toString());
        }
    }


    //feedbacks end

    //start frequenz analyse

    const buildFrequencyAvgPercentChart = () => {
        return (
            <FrequencyAvgPercentChart
                groups={groupsLicenseTableEntries}
                childRefChart={childRefFrequencyAvgPercentChart}
                childRef={childRefFrequencyAvgPercent}
                setChartData={setFrequencyAvgPercentMotions}
                allData={groupMotionsAll}
                data={frequencyAvgPercentMotions}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildFrequencyGroupChart = () => {
        return (
            <FrequencyGroupsChart
                groups={groupsLicenseTableEntries}
                childRefChart={childRefFrequencyAvgPercentChart}
                childRef={childRefFrequencyAvgPercent}
                setChartData={setFrequencyAvgPercentMotions}
                allData={groupMotionsAll}
                data={frequencyAvgPercentMotions}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }



    const buildSpecifcGetGroupMotion = () => {
        return (
            <GroupMotionChart
                childRefChart={childRefMotionMultipleRoomsChart}
                childRef={childRefMotionMultipleRooms}
                setChartData={setMultipleSensorsMotionData}
                allData={groupMotionsAll}
                data={multipleSensorsMotionData}
                dataField={allRoomNames}
                loadingCompleted={loadingCompleted}
                title={"Besucher aller Räume"} />
        )
    }

    const buildSpecificSensorCharts = () => {
        return (
            <SpecificSensorCharts
                sensors={sensors}
                sensorFilterType={SensorFilterType.SensorRoomName}
                childRefChart={childRefMotionsBySensorChart}
                childRef={childRefMotionsBySensor}
                setCurrentSensor={setCurrentSensor}
                currentSensor={currentSensor}
                setChartData={setSpecificSensorMotionData}
                allData={groupMotionsAll}
                data={specificSensorMotionData}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted}
            />
        )
    }

    const buildSpecificSensorChartsIncreaseSum = () => {
        return (
            <SpecificSensorCharts
                sensors={sensors}
                sensorFilterType={SensorFilterType.SensorRoomName}
                childRefChart={childRefMotionsSumIncreaseBySensorChart}
                childRef={childRefMotionsSumIncreaseBySensor}
                setCurrentSensor={setCurrentSensor}
                currentSensor={currentSensor}
                setChartData={setSpecificSensorMotionIncreaseSumData}
                allData={groupMotionsAll}
                data={specificSensorMotionIncreaseSumData}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted}
                chartCalcType={ChartCalcType.IncreaseSum}
            />
        )
    }

    const buildpecificSensorChartsFilterSession = () => {
        return (
            <SpecificSensorCharts
                sensors={sensors}
                sensorFilterType={SensorFilterType.SensorId}
                childRefChart={childRefMotionsBySensorFilterIdChart}
                childRef={childRefMotionsBySensorFilterId}
                setCurrentSensor={setCurrentSensorFilterSession}
                currentSensor={currentSensorFilterSession}
                setChartData={setSpecificSensorMotionDataBySensorId}
                allData={groupMotionsAll}
                data={specificSensorMotionDataBySensorId}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted}
            />
        )
    }

    const buildSpecifcSensorChartsFilterSessionIncreaseSum = () => {
        return (
            <SpecificSensorCharts
                sensors={sensors}
                sensorFilterType={SensorFilterType.SensorId}
                childRefChart={childRefMotionsSumIncreaseBySensorFilterIdChart}
                childRef={childRefMotionsSumIncreaseBySensorFilterId}
                setCurrentSensor={setCurrentSensorFilterSession}
                currentSensor={currentSensorFilterSession}
                setChartData={setSpecificSensorMotionIncreaseSumDataBySensorId}
                allData={groupMotionsAll}
                data={specificSensorMotionIncreaseSumDataBySensorId}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted}
                chartCalcType={ChartCalcType.IncreaseSum}
            />
        )
    }

    const buildMotionsAggSumDashboard = () => {
        return (
            <MotionsAggSumDashboard
                childRefChart={childRefMotionIncreaseChart}
                updateEndDateFromParent={updateEndDateFromParent}
                updateStartDateFromParent={updateStartDateFromParent}
                updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                childRefMotion={childRefMotionIncrease}
                motions={motionsFromApi} groupIds={groupIds} sensors={sensors}>

            </MotionsAggSumDashboard>
        )
    }

    //lora sensoren

    const buildGetGroupLoraMotion = () => {
        return (
            <GroupMotionLoraChart
                childRefChart={childRefLoraMotionChart}
                childRef={childRefLoraMotion}
                setChartData={setGroupLoraMotions}
                allData={groupLoraMotionsAll}
                data={groupLoraMotions}
                dataField={groupLoraMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildLoraMotionWithGroupsChartSimple = () => {
        return (
            <LoraMotionWithGroupsChartSimple
                ref={refGroupLoraMotionsWithGroupsSimple}
                loraMotionsTableData={groupLoraMotionsTableData}
                groupsLicenseTableEntries={groupsLicenseTableEntries} />
        )
    }

    //quality checks-------------------------------------------------------------------------------------------------------

    const buildObjectLeaderQualityCheckServicesChart = () => {
        return (
            <ObjectLeaderQualityCheckServicesChart
                servicesData={objectLeaderServicesData}
                dataField={objectLeaderServicesDataFields}
                allData={objectLeaderServicesAllData}
                setChartData={setObjectLeaderServicesData}
                childRefChart={childRefObjectLeaderServicesChart}
                childRef={childRefObjectLeaderServices}
                loadingCompleted={loadingCompleted}
            />
        )
    }

    const buildObjectLeaderQualityCheckMaterialChart = () => {
        return (
            <ObjectLeaderQualityCheckMaterialChart
                materialData={objectLeaderMaterialsData}
                dataField={objectLeaderMaterialsDataFields}
                allData={objectLeadermaterialsAllData}
                setChartData={setObjectLeaderMaterialsData}
                childRefChart={childRefObjectLeaderMaterialsChart}
                childRef={childRefObjectLeaderMaterials}
                loadingCompleted={loadingCompleted}
            />
        )
    }

    const buildObjectLeaderIncidentReportChart = () => {
        return (
            <ObjectLeaderIncidentReportChart
                incidentReportData={objectLeaderIncidentReportData}
                dataField={objectLeaderIncidentReportDataFields}
                allData={objectLeaderIncidentReportAllData}
                setChartData={setObjectLeaderIncidentReportData}
                childRefChart={childRefObjectLeaderIncidentsChart}
                childRef={childRefObjectLeaderIncidents}
                loadingCompleted={loadingCompleted}
            />
        )
    }




    const buildObjectLeaderQualityCheckAverageMaterialsChart = () => {
        return (<Grid item xs={12}>
            <ObjectLeaderQualityCheckAverageMaterialsChart
                materialsData={objectLeaderAverageMaterialsData}
                dataField={objectLeaderAverageMaterialsDataFields}
                allData={objectLeaderAverageMaterialsAllData}
                setChartData={setObjectLeaderAverageMaterialsData}
                childRefChart={childRefObjectLeaderAverageMaterialsChart}
                childRef={childRefObjectLeaderAverageMaterials}
                loadingCompleted={loadingCompleted}
            />
        </Grid>)
    }
    const buildObjectLeaderQualityCheckAverageServicesChart = () => {
        return (<Grid item xs={12}>
            <ObjectLeaderQualityCheckAverageServicesChart
                servicesData={objectLeaderAverageServicesData}
                dataField={objectLeaderAverageServicesDataFields}
                allData={objectLeaderAverageServicesAllData}
                setChartData={setObjectLeaderAverageServicesData}
                childRefChart={childRefObjectLeaderAverageServicesChart}
                childRef={childRefObjectLeaderAverageServices}
                loadingCompleted={loadingCompleted}
            />
        </Grid>)
    }

    const buildObjectLeaderQualityCheckAverageServicesAllGroupsCombined = () => {
        return (
            <Grid item xs={12}>
                <ObjectLeaderQualityCheckAverageServicesAllGroupsCombined
                    servicesData={objectLeaderAverageGroupsCombinedServiceData}
                    dataField={objectLeaderAverageGroupsCombinedServiceDataFields}
                    allData={objectLeaderAverageGroupsCombinedServiceAllData}
                    setChartData={setObjectLeaderAverageGroupsCombinedServiceData}
                    childRefChart={childRefObjectLeaderAverageGroupsCombinedServicesChart}
                    childRef={childRefObjectLeaderAverageGroupsCombinedServices}
                    loadingCompleted={loadingCompleted}
                    title={"Bewertungsverlauf Alle Einheiten Zusammen: Services"}
                />
            </Grid>
        )
    }

    const buildObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined = () => {
        return (
            <Grid item xs={12}>
                <ObjectLeaderQualityCheckAverageServicesAllGroupsCombined
                    servicesData={objectLeaderAverageGroupsCombinedMaterialsData}
                    dataField={objectLeaderAverageGroupsCombinedMaterialsDataFields}
                    allData={objectLeaderAverageGroupsCombinedMaterialsAllData}
                    setChartData={setObjectLeaderAverageGroupsCombinedMaterialsData}
                    childRefChart={childRefObjectLeaderAverageGroupsCombinedMaterialsChart}
                    childRef={childRefObjectLeaderAverageGroupsCombinedMaterials}
                    loadingCompleted={loadingCompleted}
                    title={"Bewertungsverlauf Alle Einheiten Zusammen: Materialverbrauch"}
                />
            </Grid>
        )
    }

    const buildObjectLeaderQualityCheckAverageServicesGroupsChart = () => {
        return (
            <Grid item xs={12}>
                <ObjectLeaderQualityCheckAverageServicesChart
                    servicesData={objectLeaderAverageGroupsServiceData}
                    dataField={objectLeaderAverageGroupsServiceDataFields}
                    allData={objectLeaderAverageGroupsServiceAllData}
                    setChartData={setObjectLeaderAverageGroupsServiceData}
                    childRefChart={childRefObjectLeaderAverageGroupsServicesChart}
                    childRef={childRefObjectLeaderAverageGroupsServices}
                    loadingCompleted={loadingCompleted}
                    title={"Einheiten Gesamt Bewertung Services"}
                />
            </Grid>
        )
    }

    const buildObjectLeaderQualityCheckAverageMaterialsGroupsChart = () => {
        return (
            <Grid item xs={12}>
                <ObjectLeaderQualityCheckAverageServicesChart
                    servicesData={objectLeaderAverageGroupsMaterialsData}
                    dataField={objectLeaderAverageGroupsMaterialsDataFields}
                    allData={objectLeaderAverageGroupsMaterialsAllData}
                    setChartData={setObjectLeaderAverageGroupsMaterialsData}
                    childRefChart={childRefObjectLeaderAverageGroupsMaterialsChart}
                    childRef={childRefObjectLeaderAverageGroupsMaterials}
                    loadingCompleted={loadingCompleted}
                    title={"Einheiten Gesamt Bewertung Materialverbrauch"}
                />
            </Grid>
        )
    }

    const buildGridContent = () => {

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Cockpit</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Lade Daten: {currentLoadingLabel}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                        updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                        updateStartDateFromParent={updateStartDateFromParent}
                        updateEndDateFromParent={updateEndDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    {buildFeedbackCountChoice()}
                </Grid>
            </Grid>
        );
    }


    const updateGridStack = (currentGridStackCustom: GridStackCustomItem) => {
        selectedGridStackCustom.items = selectedGridStackCustom.items.map(x => x.id == currentGridStackCustom.id ? currentGridStackCustom : x);
        setSelectedGridStackCustom({ ...selectedGridStackCustom });
    }


    const renderGridDetails = (currentGridStackCustom: GridStackCustomItem) => {
        return (
            <Paper style={{ padding: 10 }}>
                <Grid container direction="row">
                    <Grid item>
                        <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} type={"number"} value={currentGridStackCustom.posX} label={"Position X"}
                            onChange={(event) => {
                                if (isNaN(parseInt(event.target.value))) return;
                                currentGridStackCustom.posX = parseInt(event.target.value);
                                updateGridStack(currentGridStackCustom);
                            }} />
                    </Grid>
                    <Grid item>
                        <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', autoCorrect: 'off' }} type={"number"} value={currentGridStackCustom.posY} label={"Position Y"}
                            onChange={(event) => {
                                if (isNaN(parseInt(event.target.value))) return;
                                currentGridStackCustom.posY = parseInt(event.target.value);
                                updateGridStack(currentGridStackCustom);

                            }} />
                    </Grid>
                    <Grid item>
                        <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} type={"number"} value={currentGridStackCustom.width} label={"Breite"}
                            onChange={(event) => {
                                if (isNaN(parseInt(event.target.value))) return;
                                currentGridStackCustom.width = parseInt(event.target.value);
                                updateGridStack(currentGridStackCustom);

                            }} />
                    </Grid>
                    <Grid item>
                        <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} type={"number"} value={currentGridStackCustom.height} label={"Höhe"}
                            onChange={(event) => {
                                if (isNaN(parseInt(event.target.value))) return;
                                currentGridStackCustom.height = parseInt(event.target.value);
                                updateGridStack(currentGridStackCustom);

                            }} />
                    </Grid>

                    <Grid item xs={12}></Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={currentGridStackCustom.isStandardChartHeightEnabled}
                                    onChange={(event: any) => {
                                        currentGridStackCustom.isStandardChartHeightEnabled = event.target.checked;
                                        updateGridStack(currentGridStackCustom);
                                    }}
                                    name="isEnabled"
                                    color="primary"
                                />
                            }
                            label={t("dashboardManagement.useDefaultChartHeight")}
                        />
                    </Grid>
                    {!currentGridStackCustom.isStandardChartHeightEnabled &&
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentGridStackCustom.isAutoChartHeightEnabled}
                                        onChange={(event: any) => {
                                            currentGridStackCustom.isAutoChartHeightEnabled = event.target.checked;
                                            updateGridStack(currentGridStackCustom);
                                        }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label="Automatische Höhenanpassung des Diagramms"
                            />
                        </Grid>
                    }
                    {!currentGridStackCustom.isStandardChartHeightEnabled && !currentGridStackCustom.isAutoChartHeightEnabled &&
                        <Grid item>
                            <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} type={"number"} value={currentGridStackCustom.chartHeight} label={"Diagramm-Höhe"}
                                onChange={(event) => {
                                    if (isNaN(parseInt(event.target.value))) return;
                                    currentGridStackCustom.chartHeight = parseInt(event.target.value);
                                    updateGridStack(currentGridStackCustom);

                                }} />
                        </Grid>
                    }
                </Grid>
            </Paper>
        )
    }

    const menuStyle = clsx({
        [`grid-stack grid-stack-print controlled`]: true, //always applies
        [`page-size`]: ChartDisplaySizeCustomDashboard == ChartDisplaySizeType.Print //only when open === true

    });

    const buildSeperators = () => {
        let res = [];
        for (let i = 0; i < selectedGridStackCustom.pageCount; i++) {
            res.push(<div style={{ height: "553mm", width: "100%", borderBottom: "5px solid black" }}></div>)
        }
        return res
    }

    const buildItemsPrint = () => {
        return (
            <div ref={refPrint} className={menuStyle}>
                {selectedGridStackCustom.isPageCountEnabled &&
                    <div style={{ height: "550mm", width: "100%", borderBottom: "5px solid black" }}>
                    </div>
                }
                {selectedGridStackCustom.isPageCountEnabled && buildSeperators()}

                {selectedGridStackCustom.items.map((item, i) => {
                    return (
                        <div ref={refs.current[item.id]} key={item.id} gs-id={item.id} className={'grid-stack-item'} gs-x={`${item.posX}`} gs-y={`${item.posY}`} gs-w={`${item.width}`} gs-h={`${item.height}`}
                        >
                            <div className="grid-stack-item-content" >
                                <CustomDashboardContext.Provider value={{ item: item, updateGridStack: updateGridStack, selectedGridStackCustom: selectedGridStackCustom }}>
                                    {isEditModeEnabled && renderGridDetails(item)}
                                    {mapGridStackItem(item)}
                                </CustomDashboardContext.Provider>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const buildActualContent = () => {
        return (
            <div>
                {buildGridContent()}
                <Paper style={{ padding: 10 }}>
                    <Grid container direction={"row"} alignItems={"center"} spacing={3}>
                        <Grid item>
                            {isEditModeEnabled ? <Edit color="primary" fontSize={"large"} /> : <EditOff color="error" fontSize={"large"} />}
                        </Grid>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        size="medium"
                                        onChange={(event) => {
                                            if (!event.target.checked) {
                                                gridRef.current.disable();
                                            } else {
                                                gridRef.current.enable();
                                            }
                                            setIsEditModeEnabled(event.target.checked);
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } label="Edit Modus" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Paper>
                {isEditModeEnabled &&
                    <Button disabled={loadingSaveGridStack} startIcon={loadingSaveGridStack && <CircularProgress size={32} />} variant='contained' endIcon={<Save />} onClick={async () => {
                        setLoadingSaveGridStack(true);
                        const token = getApiToken(history);
                        let gridStack = await api.putUserGridStackCustom(selectedGridStackCustom, token, enqueueSnackbar);
                        localStorage.setItem(GLOBAL_SELECTED_GRID_STACK_CUSTOM, JSON.stringify(gridStack));
                        setSelectedGridStackCustom(gridStack);
                        setLoadingSaveGridStack(false);
                    }}>
                        Speichern
                    </Button>
                }
                {isEditModeEnabled &&
                    <Grid container direction={"row"}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedGridStackCustom.isPageCountEnabled}
                                        onChange={(event: any) => {
                                            setSelectedGridStackCustom({ ...selectedGridStackCustom, isPageCountEnabled: event.target.checked });
                                        }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label="Seitentrennung anzeigen"
                            />
                        </Grid>

                        {selectedGridStackCustom.isPageCountEnabled &&
                            <Grid item>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label="Seitenanzahl"
                                    variant="filled"
                                    type={"number"}
                                    value={selectedGridStackCustom.pageCount}
                                    onChange={(event: any) => { setSelectedGridStackCustom({ ...selectedGridStackCustom, pageCount: event.target.value }) }}
                                />
                            </Grid>
                        }
                    </Grid>
                }
                {buildItemsPrint()}
            </div>
        )
    }

    return buildActualContent();



}



