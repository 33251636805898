import axios from 'axios';
import { headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { DeviceType } from '../../models/bssd/SsdDeviceModel';

export const deleteLoraDeviceAdmin = async (deviceId: string,token: string, enqueueSnackbar: any): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.delete(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/lora-devices/${deviceId}/delete`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Device erfolgreich gelöscht", { variant: 'success' });
        return ret.data;
    } catch (err: any) {
        if (err.response){
            enqueueSnackbar(err.response.data, { variant: 'error' });
        }
        enqueueSnackbar("Device konnte nicht gelöscht werden", { variant: 'error' });
    }
    return null as any;
}