import axios from 'axios';
import { handleApiError, headers } from '../api';
import { GROUP_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupMotion } from '../models/GroupMotion';


export const getLastMotionsByGroup = async (token: string): Promise<GroupMotion[]> => {
    const userId = getUserIdFromStorage();
    try {
        let groupId = localStorage.getItem(GROUP_ID);
        if (!groupId) {
            return null as any;
        }
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/groups/${groupId}/users/${userId}/last-motions`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err, null, "");
    }
    return null as any;
}

export const getLastMotionsByGroupId = async (token: string, groupId: string): Promise<GroupMotion[]> => {
    const userId = getUserIdFromStorage();
    try {
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/groups/${groupId}/users/${userId}/last-motions`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err, null, "");
    }
    return null as any;
}