import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { AppBar, Container, DialogActions, DialogContent, Divider, Grid, IconButton, ImageListItem, ImageListItemBar, Paper, TextField, Theme, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../../helpers/storage-management';
import { DisplayModeObjectFolder } from './ObjectSharedFolders';
import { Backspace, Close, Download, NextPlan } from '@mui/icons-material';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useTranslation } from 'react-i18next';

export const PreviewFileDialog = ({ open, setOpen, onAdd, images, setImages }
    : { open: boolean, setOpen: any, onAdd: any, images: any[], setImages: any }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [numPages, setNumPages] = React.useState<any>(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    const {t} = useTranslation();

    const handleClose = () => setOpen(false);

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }

    return (
        <div>
            <Dialog
                fullScreen
                onClose={handleClose}
                open={open}
            >
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography component="div" variant="h6">{`${images.length} Vorschau ausgewählt` || ''}</Typography>
                        {
                            <IconButton color="inherit" onClick={() => { handleClose() }} size="large">
                                <Close />
                            </IconButton>
                        }
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.dialogContent} dividers>

                    {images.length > 0 &&
                        <Container maxWidth="xl">

                            <Paper elevation={5} style={{ padding: 10 }}>
                                <Grid container direction={"column"} spacing={2}>
                                    {images.map((item, index) => {
                                        return (
                                            <>
                                                <Grid item>
                                                    {item.contentType != "pdf" &&
                                                        <ImageListItem key={index}>
                                                            <img
                                                                src={item.contentUrl}
                                                                loading="lazy"
                                                                style={{ width: '100%', objectFit: 'contain' }}
                                                            />
                                                            <ImageListItemBar
                                                                title={`${item.name}`}

                                                                actionIcon={
                                                                    <>
                                                                        <Button
                                                                            size="small"
                                                                            startIcon={<Download />}
                                                                            variant={'contained'}
                                                                            onClick={() => {
                                                                                var link = document.createElement("a"); // Or maybe get it from the current document
                                                                                link.href = item.contentUrl;
                                                                                link.download = `${item.name}`;
                                                                                link.click();
                                                                            }}
                                                                            color="primary">
                                                                            Download Bild
                                                                        </Button>
                                                                    </>
                                                                }

                                                            />
                                                        </ImageListItem>
                                                    }
                                                    {item.contentType == "pdf" &&
                                                        <div>
                                                            <Document file={item.contentUrl} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
                                                                <Page pageNumber={pageNumber} />
                                                            </Document>
                                                            <Grid container direction={"row"} spacing={3} alignItems={"center"}>
                                                                <Grid item style={{ flexGrow: 1 }}>
                                                                    <Typography>{item.name}</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        startIcon={<Backspace />}
                                                                        onClick={async () => {
                                                                            if (pageNumber > 1)
                                                                                setPageNumber(prev => prev - 1);
                                                                        }}
                                                                    >
                                                                        Zurück
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        style={{ marginLeft: 10 }}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        startIcon={<NextPlan />}
                                                                        onClick={async () => {
                                                                            if (pageNumber < numPages)
                                                                                setPageNumber(prev => prev + 1);
                                                                        }}
                                                                    >
                                                                        Next
                                                                    </Button>
                                                                </Grid>

                                                            </Grid>
                                                        </div>
                                                    }
                                                </Grid>
                                                <Grid item>
                                                    <Divider />
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                            </Paper>
                        </Container>
                    }
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        color="primary"
                    >
                        {t("myUnitsSettings.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));