import axios from 'axios';
import { handleApiError, headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { ObjectUnit } from '../../models/ObjectUnit';
import { UserServiceWorker } from '../../models/UserServiceWorker';

export const getServiceWorkersByName = async (token: string, names : string[]): Promise<UserServiceWorker[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/service-workers-by-name`, {stringArray : names},{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}