import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    Grid,
    Paper,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close } from "@mui/icons-material";
import React from "react";
import { api } from "../../../api";
import { getApiToken } from "../../../helpers/storage-management";
import { GroupLicenseTable, SensorLicense } from "../../../models/GroupLicenseTable";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { green, red, white, yellow } from "../../../styles/colors";
import { UserInvoice } from '../../../models/UserInvoice';
import { AdminInvoicesTable } from "../../dialogs/AdminInvoicesTable";
import { UserAccount } from "../../../models/UserAccount";
import { getOwnUserIdFromStorage } from "../../../helpers/common";
import { UserInvoicesTable } from "../../dialogs/UserInvoicesTable";
import { useTranslation } from "react-i18next";

export const InvoiceOwnerPage = ({ userAccount }: { userAccount: UserAccount | undefined }) => {

    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState("");
    const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | null>(null);
    const [invoices, setInvoices] = React.useState<UserInvoice[]>([]);
    const {t} = useTranslation();


    const [loading, setLoading] = React.useState(false);


    const loadDataAsync = async () => {

        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setSelectedGroup(null);
        setLoading(true);


        const tempInvoices = await api.getAllInvoicesAsync(tempToken, getOwnUserIdFromStorage() || "");
        let newInvoices = tempInvoices.reduce((previousVal: UserInvoice[], currentVal) => {
            let ret = currentVal.items.map((el) => {
                let newVal = { ...currentVal };
                newVal.displayItem = el;
                return newVal;
            })
            return [...previousVal, ...ret];
        }, []);
        setInvoices(newInvoices);


        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();
        })();
    }, []);

    return (
        <Grid
            container
            direction="row"
            spacing={3}
        >


            {
                invoices &&
                token &&
                <Grid item xs={12}>
                    <Accordion expanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        {t("orders.myOrders")} {loading ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {invoices.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UserInvoicesTable
                                rows={invoices}
                                token={token}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }



        </Grid >
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));