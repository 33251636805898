import { Grid, Paper } from "@mui/material"
import React from "react"
import { elevationPaper, paperStyle } from "../../../../../styles/container"
import { LineChart } from "../../../../charts/LineChart"
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart"
import { StackedChart } from "../../../../charts/StackedChart"
import { ChartChoice } from "../../../../core/ChartChoice"
import { TimeChoice } from "../../../../core/TimeChoice"
import { useTranslation } from "react-i18next"

export const IncidentReportDashboard = ({ incidentReportData, dataField, allData, setChartData, childRef, loadingCompleted, childRefChart }: { incidentReportData: any, dataField: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any }) => {
    const [chartChoice, setChartChoice] = React.useState('0');
    const {t} = useTranslation();

    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <LineChart
                    childRefChart={childRefChart}
                    data={incidentReportData}
                    dataField={dataField}
                    text={t("serviceDocumentationTable.incidentReports")}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    childRefChart={childRefChart}
                    data={incidentReportData}
                    dataField={dataField}
                    text={t("serviceDocumentationTable.incidentReports")}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    childRefChart={childRefChart}
                    data={incidentReportData}
                    dataField={dataField}
                    text={t("serviceDocumentationTable.incidentReports")}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}