import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { FeedbackQuestionType, GroupFeedbackQuestion } from '../../../../../models/GroupFeedbackQuestion';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { formatEuropeanDate, formatEuropeanTime } from '../../../../../helpers/date-management';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { green, red } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

export const FeedbackQuestionDataTable = ({ rows, groups, selectedEntries, onSelectionChange, }
    : { rows: GroupFeedbackQuestion[], groups: GroupLicenseTable[], selectedEntries: GroupFeedbackQuestion[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);
    const {t} = useTranslation();

    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupFeedbackQuestion } = props;
        if (column.name == GroupFeedbackQuestion.Columns.items && row.items) {
            return buildCellStringArray(props, row.items, `${t("myObjectSettings.contains")} ${row.items.length} Vorgefertigte Texte`);
        } else if (column.name == GroupFeedbackQuestion.Columns.customTexts && row.customTexts) {
            return buildCellStringArray(props, row.customTexts, `${t("myObjectSettings.contains")} ${row.customTexts.length} Frei-Texte`);
        }

        return <Table.Cell {...props} />;
    };


    const TableRow = ({ row, ...restProps } : any) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            style={{
                backgroundColor: row.feedbackQuestionType == FeedbackQuestionType.Positive ? green[100] : red[100]
            }}
        />
    );



    const columns = [
        {
            name: GroupFeedbackQuestion.Columns.feedbackQuestionOriginType,
            title: "Ursprung",
            getCellValue: (row: GroupFeedbackQuestion) => GroupFeedbackQuestion.GetLabelByType(row.feedbackQuestionOriginType),
        },
        {
            name: GroupFeedbackQuestion.Columns.feedbackQuestionType,
            title: "Feedbackfrage Typ",
            getCellValue: (row: GroupFeedbackQuestion) => GroupFeedbackQuestion.GetLabelByFeedbackQuestionType(row.feedbackQuestionType),
        },
        {
            name: GroupFeedbackQuestion.Columns.id,
            title: t("serviceDocumentationTable.unitName"),
            getCellValue: (row: GroupFeedbackQuestion) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: GroupFeedbackQuestion.Columns.messenger,
            title: "Melder",
            getCellValue: (row: GroupFeedbackQuestion) => row.messenger,
        },
        {
            name: GroupFeedbackQuestion.Columns.items,
            title: "Vorgefertigte Texte",
            getCellValue: (row: GroupFeedbackQuestion) => row.items ? row.items.join(',') : "",
        },
        {
            name: GroupFeedbackQuestion.Columns.customTexts,
            title: "Frei-Texte",
            getCellValue: (row: GroupFeedbackQuestion) => row.customTexts ? row.customTexts.join(',') : "",
        },
        {
            name: GroupFeedbackQuestion.Columns.createdTimestamp,
            title: t("dashboard.creationDate"),
            getCellValue: (row: GroupFeedbackQuestion) => formatEuropeanDate(row.createdTimestampString),
        },
        {
            name: GroupFeedbackQuestion.Columns.createdTime,
            title: t("dashboard.creationTime"),
            getCellValue: (row: GroupFeedbackQuestion) => formatEuropeanTime(row.createdTimestampString),
        }
    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == GroupFeedbackQuestion.Columns.items || el.name == GroupFeedbackQuestion.Columns.customTexts) {
            return { columnName: el.name, width: 300 }
        }
        return { columnName: el.name, width: 180 }
    }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Feedbackfragen');
        });
    };

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                    rowComponent={TableRow}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
