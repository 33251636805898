import { DialogTitle as DefaultDialogTitle, IconButton, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';

export const DialogTitle = (props: Props) => {

    const { children, onClose, ...other } = props;
    const classes = useStyles();

    return (
        <DefaultDialogTitle
            className={classes.root}
            {...other}
        >
            <Typography variant="h6">{children}</Typography>

            {
                onClose && (
                    <IconButton
                        className={classes?.closeButton}
                        aria-label="close"
                        onClick={onClose}
                        size="large">
                        <Close />
                    </IconButton>
                )
            }
        </DefaultDialogTitle>
    );
};

interface Props {
    children: React.ReactNode,
    onClose: () => void,
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeButton: {

    },
}));