import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close, Downloading, Delete } from "@mui/icons-material";
import React from "react";
import { api } from "../../../api";
import { getApiToken } from "../../../helpers/storage-management";
import { GroupLicenseTable, SensorLicense } from "../../../models/GroupLicenseTable";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { green, red, white, yellow } from "../../../styles/colors";
import { UserInvoice } from '../../../models/UserInvoice';
import { AdminInvoicesTable } from "../../dialogs/AdminInvoicesTable";
import { UserAccount } from "../../../models/UserAccount";
import { LoraDeviceModel } from "../../../models/lora/LoraDeviceModel";
import { LoraDevicesTableAdmin } from "./table/LoraDevicesTable";

export const LoraSensorAdminPage = () => {

    const history = useHistory();

    const [token, setToken] = React.useState("");
    const [loraDevices, setLoraDevices] = React.useState<LoraDeviceModel[]>([]);


    const [loading, setLoading] = React.useState(false);
    const [loadingGroups, setLoadingGroups] = React.useState(false);
    const [users, setUsers] = React.useState<UserAccount[]>([]);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [selection, setSelection] = React.useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [newDeviceId, setNewDeviceId] = React.useState("");
    const [addingLoraSensorLoading, setAddingLoraSensorLoading] = React.useState(false);

    const [deletingLoraSensorLoading, setDeletingLoraSensorLoading] = React.useState(false);


    const loadDataAsync = async () => {

        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setLoading(true);


        let loraDevices = await api.getAllLoraDevices(tempToken);
        setLoraDevices(loraDevices);

        const tempUsers = await api.getAllUsersInclusiveNonActivatedByAdmin(tempToken);
        /*   let allGroupsTemp = await Promise.all(tempUsers.map(async (el) => {
               let groupLicenses = await api.GetAllGroupsByUserIdAsync(tempToken, el.id);
               return new UserManagementGroups(el.id,groupLicenses);
           }));
           setGroups(allGroupsTemp);*/
        setUsers(tempUsers);


        setLoading(false);
    }

    const loadGroups = async () => {
        setLoadingGroups(true);
        let allGroupsTemp = await Promise.all(users.map(async (el) => {
            let groupLicenses = await api.GetAllGroupsByUserIdAsync(token, el.id);
            return groupLicenses.filter(x => loraDevices.some(y => y.groupId == x.id));
        }));
        setGroups(allGroupsTemp.flatMap(x => x));
        setLoraDevices([...loraDevices]);
        setLoadingGroups(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();
        })();
    }, []);

    return (
        <Grid
            container
            direction="row"
            spacing={3}
        >
            {
                loraDevices &&
                token &&
                <Grid item xs={12}>
                    <Accordion expanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid container direction={"row"} spacing={3} alignItems="center">
                                <Grid
                                    item
                                    xs={6}
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                >
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            component="p"
                                            color="textSecondary"
                                        >
                                            Alle Lora-Sensoren {loading ? "(wird geladen)" : ""}
                                        </Typography >
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className="Paper-Result"
                                            component="p"
                                        >
                                            {loraDevices.length}
                                        </Typography >
                                    </Grid>
                                </Grid>
                                {!loading &&
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<Downloading />}
                                            disabled={loadingGroups}
                                            endIcon={loadingGroups && <CircularProgress size={32} />}
                                            onClick={async () => {
                                                await loadGroups();
                                            }}
                                        >
                                            Alle Einheiten von {users.length} User laden
                                        </Button>
                                    </Grid>
                                }
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        label="Sensor ID"
                                        disabled={addingLoraSensorLoading}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">ID</InputAdornment>
                                        }}
                                        value={newDeviceId}
                                        onChange={(event: any) => {
                                            setNewDeviceId(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Downloading />}
                                        disabled={addingLoraSensorLoading}
                                        endIcon={addingLoraSensorLoading && <CircularProgress size={32} />}
                                        onClick={async () => {
                                            setAddingLoraSensorLoading(true);
                                            let loraDevice = await api.addLoraDeviceAdmin(newDeviceId, token, enqueueSnackbar)
                                            setAddingLoraSensorLoading(false);
                                            if (loraDevice){
                                                setLoraDevices([loraDevice, ...loraDevices])
                                            }
                                        }}
                                    >
                                        Lora Sensor hinzufügen
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        startIcon={<Delete />}
                                        disabled={deletingLoraSensorLoading || selection.length < 1}
                                        endIcon={deletingLoraSensorLoading && <CircularProgress size={32} />}
                                        onClick={async () => {
                                            setDeletingLoraSensorLoading(true);
                                            for (let id of selection){
                                                let row = loraDevices.find(x=>x.id == id);
                                                if (row && row.groupId){
                                                    await api.UnAssignLoraDeviceToGroupByIdAsync(row.groupId, row.deviceId, token, enqueueSnackbar);
                                                }
                                                let loraDevice = await api.deleteLoraDeviceAdmin(id, token, enqueueSnackbar)
                                            }
                                            await loadDataAsync();
                                            setSelection([]);
                                            setDeletingLoraSensorLoading(false);
                                        }}
                                    >
                                      {selection.length} Selektierte Lora Sensor entfernen
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {!loading &&
                                <LoraDevicesTableAdmin
                                    users={users}
                                    rows={loraDevices}
                                    selection={selection}
                                    setSelection={setSelection}
                                    onDeleted={null}
                                    assignBssdSensorLicense={() => {

                                    }}
                                    groups={groups}
                                />
                            }
                            {loading &&
                                <CircularProgress size={64} />
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }



        </Grid >
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));