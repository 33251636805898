import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
  IntegratedSummary,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { FormattedNumber } from 'react-intl';
import { Typography } from '@mui/material';
import { GroupDistanceFromServiceWorker } from '../../models/GroupDistanceFromServiceWorker';
import { co2PerKm } from '../authorized/portal/Dashboard/SetPointActualValueDashboard';
import { green } from '../../styles/colors';
import { useTranslation } from 'react-i18next';

export const UserServiceWorkerGroupDistanceTable = ({ rows, savedServiceDatesCount }: { rows: GroupDistanceFromServiceWorker[], savedServiceDatesCount: number }) => {

  const {t} = useTranslation();

  const [columns] = useState([
    { name: 'notificationName', title: t("serviceDocumentationTable.unitName") },
    { name: 'serviceWorkerName', title: 'Servicekraftname' },
    { name: 'distanceFromGroup', title: 'Distanz zur Einheit (km)' },
    {
      name: 'co2Consumption',
      title: 'kg Co2 pro Fahrt',
      getCellValue: (row: GroupDistanceFromServiceWorker) => (co2PerKm * row.distanceFromGroup) * 2,
    },
    {
      name: 'savedCo2Consumption',
      title: 'Eingesparte kg Co2',
      getCellValue: (row: GroupDistanceFromServiceWorker) => (co2PerKm * row.distanceFromGroup) * 2 * savedServiceDatesCount,
    },
  ]);

  const [totalSummaryItems] = useState([
    { columnName: 'notificationName', type: 'count' },
    { columnName: 'distanceFromGroup', type: 'sum' },
    { columnName: 'co2Consumption', type: 'sum' },
    { columnName: 'savedCo2Consumption', type: 'sum' },

  ]);

  const [groupSummaryItems] = useState([
    { columnName: 'notificationName', type: 'count' },
    { columnName: 'distanceFromGroup', type: 'sum' },
    {
      columnName: 'distanceFromGroup', type: 'max', showInGroupFooter: false, alignByColumn: true,
    },
    { columnName: 'co2Consumption', type: 'sum' },
    {
      columnName: 'co2Consumption', type: 'max', showInGroupFooter: false, alignByColumn: true,
    },
    { columnName: 'savedCo2Consumption', type: 'sum' },
    {
      columnName: 'savedCo2Consumption', type: 'max', showInGroupFooter: false, alignByColumn: true,
    },
  ]);
  const [currencyColumns] = useState(['co2Consumption', 'savedCo2Consumption', 'distanceFromGroup']);

  const Cell = (props: any) => {
    const { column, row, value } = props;
    if (column.name === "notificationName") {
      return (
        <Table.Cell {...props}>
          <Typography>{row.notificationName} {row.levelName}</Typography>
        </Table.Cell>
      )
    }
    if (column.name == "distanceFromGroup") {
      return (
        <Table.Cell {...props}>
          <Typography><FormattedNumber value={value} />km</Typography>
        </Table.Cell>
      )
    }
    if (column.name == "co2Consumption") {
      return (
        <Table.Cell {...props}>
          <FormattedNumber value={value} />kg
        </Table.Cell>
      )
    }
    if (column.name == "savedCo2Consumption") {
      return (
        <Table.Cell {...props}>
          <Typography style={{ color: green }}>
            <FormattedNumber value={value} />kg
          </Typography>
        </Table.Cell>
      )
    }
    return <Table.Cell {...props} />;
  };

  const CellGroupRow = (props: any) => {
    const { column, row, value } = props;

    return (
      <TableGroupRow.Content {...props} >
        {row.notificationName}
      </TableGroupRow.Content>
    );
  };

  const GroupCellComponent = (props: any) => {
    const { column, row, value } = props;

    return (
      <Table.Cell {...props} >
        <Typography style={{ color: green }}>
          <FormattedNumber value={value} />kg
        </Typography>
      </Table.Cell>
    );
  };

  const CurrencyFormatter = (props: any) => {
    if (props.column.name == "areaPerformancePerSquareMeter") {
      return props.value.toLocaleString('de') + "h"
    } else if (props.column.name == "co2Consumption") {
      return props.value.toLocaleString('de') + "kg"
    }
    else if (props.column.name == "savedCo2Consumption") {
      return <Typography style={{color: green}}>{props.value.toLocaleString('de') + "kg"}</Typography> 
    }
    return props.value.toLocaleString('de') + "km"
  }

  const CurrencyTypeProvider = (props: any) => {
    return (
      <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
      >
      </DataTypeProvider>
    )
  };

  const HeaderCell = ({ classes, column, className, ...restProps }: any) => {
    if (column.name == 'savedCo2Consumption') {
      return (
        <TableHeaderRow.Cell
          {...restProps}
          className={className}
          sx={{ color: green }}
        />
      )
    }
    return (
      <TableHeaderRow.Cell
        {...restProps}
        className={className}
      />
    )

  };



  return (
    <Paper>
      <Grid
        rows={rows}
        columns={columns}
      >
        <CurrencyTypeProvider
          for={currencyColumns}
        />
        <GroupingState
          grouping={[{ columnName: 'notificationName' }]}
        />
        <SummaryState
          totalItems={totalSummaryItems}
          groupItems={groupSummaryItems}

        />
        <IntegratedGrouping />
        <IntegratedSummary />
        <Table cellComponent={Cell} />
        <TableHeaderRow
          cellComponent={HeaderCell}
        />
        <TableGroupRow showColumnsWhenGrouped contentComponent={CellGroupRow} />
        <TableSummaryRow />
      </Grid>
    </Paper>
  );
};
