import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    TextField,
    Theme,
    Grid,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { DialogTitle } from '../common/DialogTitle';
import { Send } from '@mui/icons-material';
import { api } from '../../api';
import { SupportContactRequest } from '../../models/SupportContactRequest';
import { StringHelper } from '../../helpers/string-helper';

export const PortalContactSupportDialog = ({ visible, setVisible, token, userAccount }
    : { visible: boolean, setVisible: any, token: string, userAccount: any }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [contactRequest, setContactRequest] = React.useState<SupportContactRequest>(new SupportContactRequest());
    const [loading, setLoading] = React.useState(false);
    const [topics, setTopics] = React.useState<string[] | null>(null);

    const [hasLastnameError, setLastnameError] = React.useState(false);
    const [hasEmailError, setEmailError] = React.useState(false);
    const [hasTopicError, setTopicError] = React.useState(false);

    const closeDialog = () => {
        setVisible(false);
        setContactRequest(new SupportContactRequest());
        setLastnameError(false);
        setEmailError(false);
        setTopicError(false);
    }

    const hasError = (): boolean => {

        let hasValidationError = false;

        if (StringHelper.IsNullOrWhiteSpace(contactRequest?.lastname)) {
            setLastnameError(true);
            hasValidationError = true;
        }

        if (!StringHelper.IsValidEmail(contactRequest?.email)) {
            setEmailError(true);
            hasValidationError = true;
        }

        if (StringHelper.IsNullOrWhiteSpace(contactRequest?.topic)) {
            setTopicError(true);
            hasValidationError = true;
        }

        return hasValidationError;
    }

    const onSubmit = async () => {

        if (hasError()) return;

        setLoading(true);

        await api.sendSupportContactRequest(token, contactRequest);

        setLoading(false);

        closeDialog();
        enqueueSnackbar('Nachricht wurde erfolgreich gesendet', { variant: 'success' });
    }

    React.useEffect(() => {
        (async () => {
            if (!token || topics) return;
            const tempTopics = await api.getSupportContactTopics(token);
            setTopics(tempTopics || []);
        })();

        (async () => {
            setContactRequest({
                ...contactRequest,
                firstname: userAccount?.firstname,
                lastname: userAccount?.lastname,
                email: userAccount?.mail,
            });
        })();
        return () => {

        }
    }, [visible, userAccount]);

    return (
        <Dialog open={visible} onClose={closeDialog} fullWidth={true} maxWidth="sm">
            <DialogTitle onClose={closeDialog}>
                Support
            </DialogTitle>
            <DialogContent>
                <form
                    className={classes.container}
                    noValidate
                    onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                id="firstname"
                                label="Vorname"
                                margin="dense"
                                name="firstname"
                                variant="outlined"
                                value={contactRequest?.firstname || ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setContactRequest({ ...contactRequest, firstname: event.target.value });
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="lastname"
                                disabled={loading}
                                error={hasLastnameError}
                                fullWidth
                                label="Nachname"
                                margin="dense"
                                name="lastname"
                                required={true}
                                variant="outlined"
                                value={contactRequest?.lastname || ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setContactRequest({ ...contactRequest, lastname: event.target.value });
                                    setLastnameError(false);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                disabled={loading}
                                error={hasEmailError}
                                fullWidth
                                label="Email Addresse"
                                margin="dense"
                                name="email"
                                required={true}
                                variant="outlined"
                                type="email"
                                value={contactRequest?.email || ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setContactRequest({ ...contactRequest, email: event.target.value });
                                    setEmailError(false);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                className={classes.topicSelect}
                                error={hasTopicError}
                                label="Thema"
                                margin="dense"
                                name="topic"
                                disabled={loading}
                                required={true}
                                value={contactRequest?.topic || ""}
                                variant="outlined"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setContactRequest({ ...contactRequest, topic: event.target.value });
                                    setTopicError(false);
                                }}
                            >
                                {
                                    topics &&
                                    topics.map((topic) => (
                                        <MenuItem
                                            key={topic}
                                            value={topic}>
                                            {topic}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                label="Kommentar"
                                multiline
                                margin="dense"
                                name="comment"
                                variant="outlined"
                                rows={8}
                                value={contactRequest?.comment}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setContactRequest({ ...contactRequest, comment: event.target.value });
                                }}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Send />}
                    disabled={loading}
                    onClick={onSubmit}
                >
                    Anfrage senden
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
        },
        title: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        topicSelect: {
            width: '100%',
            marginTop: 8,
        },
    }),
);