import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { AdminSetting } from '../models/AdminSetting';
import { setFromStorageAdminSettingPortal } from '../constants';


export const GetAdminSettings = async (token: string): Promise<AdminSetting[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/admin-settings`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;
}

export const GetAdminSettingPortal = async (token: string, adminSetting: AdminSetting): Promise<AdminSetting> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/admin-settings-portal/if-changed/${adminSetting.systemETag}`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        if (ret.data){
            setFromStorageAdminSettingPortal(ret.data);
            return ret.data;
        }else {
            return adminSetting;
        }
    } catch (err) {
        handleApiError(err);

    }
    return null as any;
}