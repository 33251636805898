import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CardElement } from '@stripe/react-stripe-js';
import { FormControl, Radio, RadioGroup } from '@mui/material';
import { UserAddress } from '../../../../models/UserAddress';
import { StripePaymentMethod } from '../../../../models/PaymentMethod';
import { AddressForm } from './AddressForm';

export const PaymentForm = ({ address, setAddress, chosenPaymentMethod, setChosenPaymentMethod, userAddresses, setUserAddresses, hidden = false }
  : { address?: UserAddress | null | undefined, setAddress: any, chosenPaymentMethod: any, setChosenPaymentMethod: any, userAddresses: UserAddress[], setUserAddresses: any, hidden?: boolean }) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChosenPaymentMethod((event.target as HTMLInputElement).value);
  };

  const buildChosenPaymentMethod = () => {
    switch (chosenPaymentMethod) {
      case StripePaymentMethod.Card:
        return (
          <Grid item xs={12}>
            <div style={{ borderBottom: "1px solid", marginBottom: 20 }}>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </Grid>
        )
      case StripePaymentMethod.Sofort:
        return (
          <Grid item xs={12}>

          </Grid>
        )
    }
  }


  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Bezahldetails
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={chosenPaymentMethod}
              onChange={handleChange}
            >
              <FormControlLabel
                value={StripePaymentMethod.Card}
                control={<Radio />}
                label="Kreditkarte/Debitkarte"
              />
              <FormControlLabel
                value={StripePaymentMethod.Sofort}
                control={<Radio />}
                label="Sofortüberweisung"
              />
              <FormControlLabel
                value={StripePaymentMethod.Invoice}
                control={<Radio />}
                label="Auf Rechnung"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {buildChosenPaymentMethod()}
      </Grid>
      <AddressForm
        title={"RechnungsAdresse"}
        address={address}
        setAddress={setAddress}
        userAddresses={userAddresses}
        setUserAddresses={setUserAddresses}
      />
    </div>
  );
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      fontSize: "30px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
