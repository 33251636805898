import axios from 'axios';
import { GroupSuggestion } from '../../models/GroupSuggestion';
import { getUserIdFromStorage } from '../../helpers/common';
import { headers } from '../../api';
import { getQueryParamFromApiDataAmountType } from '../../helpers/charts/base';

export const getSuggestionsByUserGroupIds = async (token: string, groupIds: string[]): Promise<Array<GroupSuggestion>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/suggestion?${getQueryParamFromApiDataAmountType()}`, {StringArray: groupIds},
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}