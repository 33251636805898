import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const deleteGroupLogoAsync = async (groupId: string, filename: string, token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar)
        return;
    const userId = getUserIdFromStorage();
    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/images/${filename}/logo`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((response) => {
        enqueueSnackbar("Logo wurde erfolgreich gelöscht", { variant: 'success' });
    }).catch((error) => {
        console.log(JSON.stringify(error, null, 2));
        enqueueSnackbar("Es ist ein Fehler bei der Löschung der Logo aufgetreten", { variant: 'error' });
    });
};
