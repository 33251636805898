import axios from 'axios';
import { headers } from '../api';
import { GroupSettings } from '../models/GroupSettings';


export const extendLicense = async (groupId: string, licenseId: string, token: string, enqueueSnackbar:any) : Promise<GroupSettings> => {
    try {
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/license/${licenseId}`,
        null,
        {headers: {...headers,'Authorization': `Bearer ${token}` }})
        enqueueSnackbar("Speichern erfolgreich",{variant:'success'});
        return ret.data;
    } catch (err) {
        console.log(err);
        enqueueSnackbar("Speichern error",{variant:'error'});
    }
    return null as any;
}