import axios from 'axios';
import { headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';


export const DeleteObjectSharedFileAsync = async (objectId: string, sharedObjectFolderId: string, filename: string, token: string, enqueueSnackbar: any) => {

    if (!objectId || !token || !enqueueSnackbar)
        return;
    const userId = await getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/shared-folders/${sharedObjectFolderId}/users/${userId}/file`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` }, data: { text: filename } }
    ).then((response: any) => {

        enqueueSnackbar("Objekt Datei wurde erfolgreich gelöscht", { variant: 'success' });
    }).catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }
        enqueueSnackbar("Es ist ein Fehler bei der Löschung der Objekt Datei aufgetreten", { variant: 'error' });
    });
};
