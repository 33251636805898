import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle, Button, CircularProgress, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, AddBox, Close, Delete, ManageAccounts, Publish, RowingSharp, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoraDevicesTable } from './LoraDevicesTable';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { LoraDeviceModel } from '../../../models/lora/LoraDeviceModel';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { AddRegisteredSensorToBatteryDisplaySelectionDialog } from '../../authorized/extendedapp/batteryDisplay/AddRegisteredSensorToBatteryDisplaySelectionDialog';
import { AddLoraSensorToGroupSelectionDialog } from './AddLoraSensorToGroupSelectionDialog';
import { LicenseBlack, LicenseWhite } from '../../../assets';
import { useTranslation } from 'react-i18next';

export const LoraDevicesSelectionDialog = ({
  open,
  setOpen,
  devices,
  assignSensorLicense,
  groups,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  devices: LoraDeviceModel[],
  assignSensorLicense: any,
  groups: GroupLicenseTable[],
  onRefresh: any
}) => {

  const {t} = useTranslation();


  const handleClose = () => setOpen(false);
  const [openAddSensorDialog, setOpenAddSensorDialog] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const closeFromSensorAdd = async () => {
    setOpenAddSensorDialog(false);
    setLoading(true);
    setSelection([]);
    await onRefresh();
    setLoading(false);
  }


  const [selection, setSelection] = React.useState<any[]>([]);


  const buildVerifyButton = () => {
    return (
      <Button
        size="large"
        startIcon={<AddBox />}
        disabled={selection.length != 1 || loading}
        endIcon={loading && <CircularProgress size={32} />}
        variant={'contained'}
        color="primary"
        onClick={async () => {
          //   handleClose();
          setOpenAddSensorDialog(true);
        }}
      >
        Lora-Sensor zur Einheit hinzufügen!
      </Button>
    );
  }
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Verfügbare Geräte`}
        handleClose={handleClose}
      />
      {loading ? <CircularProgress style={{ padding: 30 }} size={128} /> :
        <DialogContent>
          <Grid container direction={'row'} alignItems="center" justifyContent={"center"} spacing={3} style={{ marginTop: 10 }}>
            <Grid item md={6}>
              <Alert severity="info">
                <AlertTitle>Sensoren aktivieren</AlertTitle>
                Hier können Sie ein Sensor zu einer Einheit hinzufügen, die ausreichend Sensor Lizenzen besitzt.<br />
                Wenn Einheiten nicht genügend Sensor Lizenzen besitzen, navigieren Sie zu alle Lizenzierte Einheiten:
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<LicenseWhite />}
                  onClick={() => {
                    assignSensorLicense();
                  }}
                >
                  Lorasensorlizenzen zu Einheiten zuweisen
                </Button>
              </Alert>

            </Grid>
          </Grid>
          <LoraDevicesTable
            rows={devices}
            selection={selection}
            setSelection={setSelection}
            onDeleted={closeFromSensorAdd}
            assignBssdSensorLicense={()=>{
              
            }}
            groups={groups}
          />
        </DialogContent>
      }
      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.close")}
        </Button>
        {buildVerifyButton()}

      </DialogActions>
      {selection.length == 1 &&
        <AddLoraSensorToGroupSelectionDialog closeFromSensorAdd={closeFromSensorAdd} open={openAddSensorDialog} setOpen={setOpenAddSensorDialog} groups={groups} selectedDeviceId={selection[0]}
          onSelected={() => {

          }} />
      }

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));