import { useLocation } from 'react-router-dom';
import { api } from "../../api";
import { EvaluationChoice } from "../../models/EvaluationChoice";
import { saveAs } from 'file-saver';
import { formatEuropeanDateSpecific } from '../date-management';
import { EvalApiType } from '../../components/authorized/portal/Dashboard/Services';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { GroupFeedback } from '../../models/GroupFeedback';
import { getDataFromGroupIdsFeedbackQuestions, getFeedbackQuestionsByUserGroupIds } from '../charts/base';
import { GroupFeedbackQuestion } from '../../models/GroupFeedbackQuestion';
import { GroupOnlineStatus } from '../../models/GroupOnlineStatus';
import { DrawerMenuItemDisplayType } from '../../components/authorized/ObjectMenuPointDrawer';
import { OBJECT_COMBINATION, SERVICE_WORKER_COMBINATION } from '../../constants';

export const virtualTableHeight = 800;

export const getChosenGroups = async (evaluationChoice: EvaluationChoice | undefined, setGroupsSettings: any, groupIds: any, token: any) => {
    switch (evaluationChoice) {
        case EvaluationChoice.Single:
        case EvaluationChoice.Multiple:
            if (setGroupsSettings) {
                let groups = await Promise.all(groupIds.map(async (groupId: any) => await api.getGroupSettings(groupId, token)));
                setGroupsSettings(groups);
            }
            break;
        case EvaluationChoice.All:
            let allGroups = await api.getAllGroupsSettingsByUser(token);
            setGroupsSettings(allGroups);
            break;
    }
}

export const useSearchParams = () => {
    const location = useLocation();

    return new URLSearchParams(location?.search);
}

export const saveExcelFile = (blob: Blob, name: string) => {
    saveAs(blob, `${name}_${formatEuropeanDateSpecific(new Date())}.xlsx`);
}

export const saveCSVFile = (blob: Blob, name: string) => {
    saveAs(blob, `${name}_${formatEuropeanDateSpecific(new Date())}.csv`);
}


//with license data
export const getPortalGroups = async (groupIds: string[], token: string, groupId: string) => {
    let groupsPortal = [];

    if (groupIds && groupIds.length > 0) {
        groupsPortal.push(...(await api.GetAllGroupsByGroupIdsAsync(token, groupIds)));
    }
    else if (groupId) {
        groupsPortal.push(...(await api.GetAllGroupsByGroupIdsAsync(token, [groupId])));

    } else {
        groupsPortal.push(...(await api.getAllGroups(token)));
    }

    return groupsPortal;
}

export const getPortalGroupsByObjectIds = async (token: string, selectedObjectId: string, drawerDisplayType: DrawerMenuItemDisplayType) => {
    let objectIds: string[] = [];
    if (drawerDisplayType == DrawerMenuItemDisplayType.All) {

    } else if (drawerDisplayType == DrawerMenuItemDisplayType.Multiple) {
        const tempCombObject: any[] = JSON.parse(localStorage.getItem(OBJECT_COMBINATION) || '[]');
        objectIds = tempCombObject.map(x=>x.id);
    } else {
        objectIds = [selectedObjectId];
    }
    return await api.getGroupsPortalByObjectId(token, objectIds);
}

export const getPortalGroupsByServiceWorkerIds = async (token: string, selectedServiceWorkerId: string, drawerDisplayType: DrawerMenuItemDisplayType) => {
    let serviceWorkerIds: string[] = [];
    if (drawerDisplayType == DrawerMenuItemDisplayType.All) {

    } else if (drawerDisplayType == DrawerMenuItemDisplayType.Multiple) {
        const tempCompServiceWorkerId: any[] = JSON.parse(localStorage.getItem(SERVICE_WORKER_COMBINATION) || '[]');
        serviceWorkerIds = tempCompServiceWorkerId.map(x=>x.id);
    } else {
        serviceWorkerIds = [selectedServiceWorkerId];
    }
    return await api.getGroupsPortalByServiceWorkerIds(token, serviceWorkerIds);
}


//only locationinformation
export const getPortalGroupsForEval = async (ids: string[], token: string, id: string, servicesDisplayType: EvalApiType = EvalApiType.Groups) => {
    let groupsPortal: GroupLicenseTable[] = [];

    switch (servicesDisplayType) {
        case EvalApiType.Groups:
            if (ids && ids.length > 0) {
                groupsPortal.push(...(await api.GetAllGroupsByGroupIdsAsync(token, ids)));
            }
            else if (id) {
                groupsPortal.push(...(await api.GetAllGroupsByGroupIdsAsync(token, [id])));

            } else {
                groupsPortal.push(...(await api.getAllGroups(token)));
            }
            break;
        case EvalApiType.Objects:
            let settings = await api.getGroupSettingsByObjectId(token, ids);
            let newItems = settings.map(x => GroupLicenseTable.CreateInstanceFromSettings(x));
            groupsPortal.push(...newItems);
            break;

    }
    return groupsPortal;
}


export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};

export const getFeedbacksForEval = async (ids: string[], token: string, id: string, servicesDisplayType: EvalApiType = EvalApiType.Groups) => {
    let tempGroupFeedbacks: GroupFeedback[] = [];

    switch (servicesDisplayType) {
        case EvalApiType.Groups:
            if (ids && ids.length > 0) {
                tempGroupFeedbacks.push(...(await api.GetFeedbacksByGroupIds(token, ids)));
            }
            else if (id) {
                tempGroupFeedbacks.push(...(await api.getFeedbacksByGroup(token, id)));

            } else {
                tempGroupFeedbacks.push(...(await api.getFeedbacksByUser(token)));
            }
            break;
        case EvalApiType.Objects:
            let feedbacks = await api.getFeedbacksByObjectId(token, ids);
            tempGroupFeedbacks.push(...feedbacks)
            break;

    }
    return tempGroupFeedbacks;
}

export const getFeedbackQuestionsForEval = async (ids: string[], token: string, id: string, servicesDisplayType: EvalApiType = EvalApiType.Groups) => {
    let tempGroupFeedbacksQuestions: GroupFeedbackQuestion[] = [];

    switch (servicesDisplayType) {
        case EvalApiType.Groups:
            tempGroupFeedbacksQuestions = await getFeedbackQuestionsByUserGroupIds(id, token, ids);
            break;
        case EvalApiType.Objects:
            let feedbacks = await api.getFeedbackQuestionsByObjectId(token, ids);
            tempGroupFeedbacksQuestions.push(...feedbacks)
            break;

    }
    return tempGroupFeedbacksQuestions;
}
