import axios from "axios"
import { headers } from "../../api";

export const requestResetPassword = async (email: string, enqueueSnackbar: any): Promise<boolean> => {

    await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/auth/request-reset-password`,
        { email },
        { headers: headers })
        .then((response) => {
            enqueueSnackbar('Passwort wurde angefordert', { variant: 'success' });
            return true;
        })
        .catch((error) => {
            handleApiError(error, enqueueSnackbar);

            return false;
        });

    return false;
}

const handleApiError = (error: any, enqueueSnackbar: any) => {

    enqueueSnackbar('Passwort Zurücksetzung wurde abgebrochen', { variant: 'error' });
    console.log(JSON.stringify(error, null, 2));

    if (error.response.status === 400 && error.response.data === 'Max request count per hour exceeded')
        enqueueSnackbar('Maximale Anzahl von Passwortzurücksetzung Anforderung wurden überschritten', { variant: 'error' });
}
