import axios from "axios"
import { headers } from "../../api";
import { Session } from "../../models/Session";
import { UserType } from "../../models/UserType";

export const registerNewUser = async (
    email: string,
    password: string,
    title: string,
    firstName: string,
    lastName: string,
    company: string,
    userType: UserType,
    invitedFromUserId: string,
    enqueueSnackbar: any,
    onSuccess?: any,
    onError?: any): Promise<Session | undefined> => {

    return await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/auth/register`,
        {
            mail: email,
            password: password,
            title: title,
            firstname: firstName,
            lastname: lastName,
            company: company,
            userType: userType,
            invitedFromUserId: invitedFromUserId
        },
        { headers: headers }
    ).then(res => {
        enqueueSnackbar("Registrierung erfolgreich! Eingeladener User muss die Email bestätigen", { variant: 'success' });
        if (onSuccess) onSuccess();

        return res.data;
    }).catch(error => {
        enqueueSnackbar("Registrierung fehlgeschlagen", { variant: 'error' });
        if (onError) onError(error);

        return undefined;
    })
}

export const reSendRegistrationMail = async (
    userId: string,
    enqueueSnackbar: any,
    token: any): Promise<Session | undefined> => {

    return await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/auth/users/${userId}/resend-register-mail`,
        {
        },
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then(res => {
        enqueueSnackbar("Eingeladener User muss die Email bestätigen", { variant: 'success' });

        return res.data;
    }).catch(error => {
        enqueueSnackbar("Email senden fehlgeschlagen", { variant: 'error' });

        return undefined;
    })
}

export const registerNewUserFromAdmin = async (
    email: string,
    password: string,
    title: string,
    firstName: string,
    lastName: string,
    company: string,
    userType: UserType,
    invitedFromUserId: string,
    activated: boolean,
    token: string) => {

    return await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/auth/register/from-admin`,
        {
            mail: email,
            password: password,
            title: title,
            firstname: firstName,
            lastname: lastName,
            company: company,
            userType: userType,
            invitedFromUserId: invitedFromUserId,
            activated: activated
        },
        { headers: {...headers, 'Authorization': `Bearer ${token}`} }
    ).then(res => {

        return res.data;
    }).catch(error => {

        return undefined;
    })
}