import React from 'react';
import { Grid, PagingPanel, Table, TableColumnVisibility, TableFilterRow, TableHeaderRow, } from '@devexpress/dx-react-grid-material-ui';
import { GroupLicense } from '../../models/GroupLicense';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { LicenseType } from '../../models/LicenseType';
import { LicenseHelper } from "../../helpers/LicenseHelper";
import { convertTimespanToDuration, formatEuropeanDate } from '../../helpers/date-management';
import { StringHelper } from '../../helpers/string-helper';
import { Button, ButtonGroup } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { api } from '../../api';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { UserAccount } from '../../models/UserAccount';
import { UserType } from "../../models/UserType";
import { getUserIdFromStorage } from '../../helpers/common';
import { useTranslation } from 'react-i18next';

export const LicensesAdminTable = ({ licenses, setLicenses, onComplete, user }
    : { licenses: GroupLicense[]; setLicenses: any; onComplete: any; user: UserAccount | undefined }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);

    const [selection, setSelection] = React.useState<GroupLicense | undefined>(undefined);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [hiddenColumnNames, setHiddenColumnNames] = React.useState<string[]>([]);

    const {t} = useTranslation();

    React.useEffect(() => {

        (async () => {

            if (!user) return;

            switch (user.userType) {
                case UserType.Admin:
                    setHiddenColumnNames([keys.purchaseTimestampUnixTimeSeconds]);
                    break;
                default:
                    setHiddenColumnNames([keys.purchaseTimestampUnixTimeSeconds, keys.actions]);
                    break;
            }

        })();

    }, [user?.userType])

    return (
        <Grid
            getRowId={(row) => row.id}
            rows={licenses}
            columns={[
                {
                    name: keys.purchaseTimestamp,
                    title: t("license-general.purchaseDate"),
                    getCellValue: (row: GroupLicense) => formatEuropeanDate(row.purchaseTimestamp),
                },
                {
                    name: keys.purchaseTimestampUnixTimeSeconds,
                    getCellValue: (row: GroupLicense) => row.purchaseTimestampUnixTimeSeconds,
                },
                {
                    name: keys.duration,
                    title: t("license-general.duration"),
                    getCellValue: (row: GroupLicense) => `${convertTimespanToDuration(row.duration).days} Tage`,
                },
                {
                    name: keys.type,
                    title: t("license-general.type"),
                    getCellValue: (row: GroupLicense) => LicenseHelper.toLicenseTypeString(row?.type || LicenseType.Device)
                },
                {
                    name: keys.checkpointCount,
                    title: t("license-general.availableCheckpoints"),
                    getCellValue: (row: GroupLicense) => `${row.checkpointCount}`,
                },
                {
                    name: keys.qualityCheckCount,
                    title: t("license-general.numberQualityAssurance"),
                    getCellValue: (row: GroupLicense) => `${row.qualityCheckCount}`,
                },
                {
                    name: keys.publicFeedbackCount,
                    title: t("license-general.numberPublicFeedback"),
                    getCellValue: (row: GroupLicense) => `${row.publicFeedbackCount}`,
                },
                {
                    name: keys.payPerServiceCount,
                    title: t("license-general.numberPayPerService"),
                    getCellValue: (row: GroupLicense) => `${row.payPerServiceCount}`,
                },
                {
                    name: keys.calendarProCount,
                    title: t("license-general.numberCalendarPro"),
                    getCellValue: (row: GroupLicense) => `${row.calendarProCount}`,
                },
                {
                    name: keys.loraSensorCount,
                    title: t("license-general.loraSensor"),
                    getCellValue: (row: GroupLicense) => row?.loraSensorCount
                },
                {
                    name: keys.sensorCount,
                    title: t("license-general.numberSensor"),
                    getCellValue: (row: GroupLicense) => row?.sensorCount
                },
                {
                    name: keys.batterySmartServiceDisplaySensorCount,
                    title: t("license-general.numberBatterySensor"),
                    getCellValue: (row: GroupLicense) => row?.batterySmartServiceDisplaySensorCount
                },
                {
                    name: keys.assigned,
                    title: t("license-general.assigned"),
                    getCellValue: (row: GroupLicense) => StringHelper.IsNullOrWhiteSpace(row.usedByGroup) ? t("license-general.free") : t("license-general.assigned"),
                }
            ]}
        >

            <FilteringState
                columnExtensions={[
                    { columnName: keys.actions, filteringEnabled: false, },
                ]}
            />
            <IntegratedFiltering />

            <SortingState
                sorting={[{ columnName: keys.purchaseTimestampUnixTimeSeconds, direction: 'asc' }]}
            />
            <IntegratedSorting />

            <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />

            <Table />
            <TableHeaderRow />
            <TableFilterRow />
            <PagingPanel pageSizes={[10, 15, 20, 25, 0]} />

            <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
            />

        </Grid>
    );
};

const keys = {
    purchaseTimestamp: 'purchaseTimestamp',
    purchaseTimestampUnixTimeSeconds: 'purchaseTimestampUnixTimeSeconds',
    duration: 'duration',
    type: 'type',
    assigned: 'assigned',
    sensorCount: 'sensorCount',
    loraSensorCount: 'loraSensorCount',
    checkpointCount: 'checkpointCount',
    qualityCheckCount: 'qualityCheckCount',
    publicFeedbackCount : 'publicFeedbackCount',
    payPerServiceCount : 'payPerServiceCount',
    calendarProCount : 'calendarProCount',
    batterySmartServiceDisplaySensorCount: 'batterySmartServiceDisplaySensorCount',
    actions: 'actions',
}