import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Paper,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close, AlarmOn, Edit, PhonelinkSetup, Settings, AddBox, QrCode2, TabletMac, DoneAll, Window, Groups, Apartment, People } from "@mui/icons-material";
import React from "react";
import { useHistory } from "react-router-dom";
import LicenseSvg from '../../../assets/svgs/license.svg'
import RunFastSvg from '../../../assets/svgs/run-fast.svg'
import KeyChainVariant from '../../../assets/svgs/key-chain-variant.svg'
import KeyPlusIcon from '../../../assets/svgs/key-plus.svg'
import LockWhite from '../../../assets/svgs/lock_white.svg'
import { useSnackbar } from "notistack";
import { api } from "../../../../api";
import { GROUP_ID, GROUP_NAME, GROUP_TYPE } from "../../../../constants";
import { getApiToken, setStorageForDrawer } from "../../../../helpers/storage-management";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupLicense } from "../../../../models/GroupLicense";
import { UserKey } from "../../../../models/UserKey";
import { white } from "../../../../styles/colors";
import { LoadingIcon } from "../../../common/LoadingIcon";
import { handleSelectedCombinationGroup } from "../../../tables/Base";
import { GroupSelectionTable } from "../../../tables/GroupSelectionTable";
import { useQuery } from "../../portal/checkout/ShopPage";
import { GroupEntireEvalTable } from "./GroupEntireEvalTable";
import { UserProperty } from "../../../../models/UserProperty";
import { UserServiceWorker } from "../../../../models/UserServiceWorker";
import { ObjectUnit } from "../../../../models/ObjectUnit";
import { UserGroupSettingsTemplate } from "../../../../models/UserGroupSettingsTemplate";
import { GroupSelectionAssignMultupleGroupToTemplateSettingDialog } from "./GroupSelectionAssignMultupleGroupToTemplateSettingDialog";
import { CopyAll } from "@mui/icons-material";
import { GroupType } from "../../../../models/Group";
import { GroupSelectionAssignMultupleGroupToObjectDialog } from "./GroupSelectionAssignMultupleGroupToObjectDialog";
import { GroupSelectionAssignMultupleGroupToServiceWorkerDialog } from "./GroupSelectionAssignMultupleGroupToServiceWorkerDialog";
import { GroupSelectionAssignMultipleGroupToPropertyDialog } from "./GroupSelectionAssignMultipleGroupToPropertyDialog";
import { PropertySelectionDataTable } from "./PropertySelectionDataTable";
import { FilterPropertyDialog } from "./FilterPropertyDialog";
import { FilterServiceWorkerDialog } from "./FilterServiceWorkerDialog";
import { FilterObjectDialog } from "./FilterObjectDialog";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";

export const GroupEntireEvalManagement = ({ onUpdateDisplayGroup }: { onUpdateDisplayGroup: any }) => {

    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState("");
    const [allGroups, setAllGroups] = React.useState<GroupLicenseTable[]>([]);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [userKeys, setUserKeys] = React.useState<UserKey[]>([]);
    const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | null>(null);



    const [currentGroupTypeShown, setCurrentGroupTypeShown] = React.useState(GroupType.All);

    const [loading, setLoading] = React.useState(false);

    const chosenUnit = useQuery(history).get("chosenUnit");
    const [isAccordionExpandedLicenseTable, setIsAccordionExpandedLicenseTable] = React.useState(false);

    const [properties, setProperties] = React.useState<UserProperty[]>([]);
    const [selectedFilterProperties, setSelectedFilterProperties] = React.useState([]);

    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [selectedUserServiceWorkers, setSelectedUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);

    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);

    const [userGroupSettingsTemplate, setUserGroupSettingsTemplate] = React.useState<UserGroupSettingsTemplate[]>([]);
    const [groupAssignTemplateSettingMultipleDialogVisible, setGroupAssignTemplateSettingMultipleDialogVisible] = React.useState(false);
    const [groupAssignObjectMultipleDialogVisible, setGroupAssignObjectMultipleDialogVisible] = React.useState(false);
    const [groupAssignServiceWorkerMultipleDialogVisible, setGroupAssignServiceWorkerMultipleDialogVisible] = React.useState(false);
    const [groupAssignPropertyMultipleDialogVisible, setGroupAssignPropertyMultipleDialogVisible] = React.useState(false);

    const [filterPropertyDialogVisible, setFilterPropertyDialogVisible] = React.useState(false);
    const [filterUserServiceWorkerDialogVisible, setFilterUserServiceWorkerDialogVisible] = React.useState(false);
    const [filterObjectDialogVisible, setFilterObjectDialogVisible] = React.useState(false);


    const [filters, setFilters]: any = React.useState([]);

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);

    const loadDataAsync = async () => {
        setLoadingProgress(0);
        setValueBuffer(20);
        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setSelectedGroup(null);
        setSelectedGroups([]);
        setLoading(true);

        const tempGroups = await api.getAllGroups(tempToken);
        setLoadingProgress(20);
        setValueBuffer(40);
        setGroups(tempGroups);
        setAllGroups(tempGroups);
        let isGroupPreChosen = tempGroups.find(el => el.id == chosenUnit);
        if (isGroupPreChosen) {
            setSelectedGroup(isGroupPreChosen);
            setSelectedGroups([isGroupPreChosen]);
        }
        const tempProps = await api.getUserProperties(tempToken);
        setProperties(tempProps);
        setLoadingProgress(40);
        setValueBuffer(60);
        const tempServiceWorkers = await api.getServiceWorkers(tempToken);
        setUserServiceWorkers(tempServiceWorkers);

        setLoadingProgress(70);
        setValueBuffer(80);

        const tempObjects = await api.getUserObjectUnits(tempToken);
        setObjects(tempObjects);

        setLoadingProgress(80);
        setValueBuffer(100);

        const temp = await api.getGroupSettingsTemplateByUser(tempToken);
        setUserGroupSettingsTemplate(temp);
        setLoadingProgress(100);
        setValueBuffer(100);
        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();
        })();
    }, []);

    const navigateToSettings = (currentGroup: GroupLicenseTable) => {
        onUpdateDisplayGroup(currentGroup);
        setStorageForDrawer(currentGroup);
        history.push(`/portal/group/${currentGroup.id}/settings`);
    }

    const newSettingsChose = (currentGroup: GroupLicenseTable) => {
        onUpdateDisplayGroup(currentGroup, false);
    }

    const buildActionRow = () => {
        return (
            <Grid item container spacing={3}>
                {currentGroupTypeShown != GroupType.All &&
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            startIcon={<DoneAll />}
                            onClick={() => {
                                setCurrentGroupTypeShown(GroupType.All);
                                setGroups(allGroups);
                            }}
                        >
                            Alle Einheiten anzeigen
                        </Button>
                    </Grid>
                }
                {currentGroupTypeShown != GroupType.Checkpoint &&
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            startIcon={<QrCode2 />}
                            onClick={() => {
                                setCurrentGroupTypeShown(GroupType.Checkpoint);
                                setGroups(allGroups.filter(x => x.groupType == GroupType.Checkpoint));
                            }}
                        >
                            Nur Checkpoints anzeigen
                        </Button>
                    </Grid>
                }
                {currentGroupTypeShown != GroupType.DisplayWallApp &&
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            startIcon={<TabletMac />}
                            onClick={() => {
                                setCurrentGroupTypeShown(GroupType.DisplayWallApp);
                                setGroups(allGroups.filter(x => x.groupType == GroupType.DisplayWallApp));
                            }}
                        >
                            Nur Smart-Service-Displays anzeigen
                        </Button>
                    </Grid>
                }
                <div style={{ flexGrow: 1 }}></div>
                <Grid item>
                    <Button
                        onClick={async () => {
                            history.push({
                                pathname: '/portal/licenses',
                                search: `navigationForAddingCheckpoint=${true}`
                            });
                        }}
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<AddBox />}
                    >
                        Checkpoint Hinzufügen
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid
            container
            direction="row"
            spacing={3}
        >
            <Grid item xs={12}>
                <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
            </Grid>
            <Grid item xs={12}>
                <Accordion expanded={true} >
                    <AccordionSummary onClick={() => { setIsAccordionExpandedLicenseTable(!isAccordionExpandedLicenseTable) }} expandIcon={<ExpandMore />}                    >
                        <Typography component="h4" variant="h4">Gesamtverwaltung (Zuweisungen für Einheiten)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction="column" spacing={3}>
                            {loading &&
                                <Grid item>
                                    <CircularProgress size={64} />
                                </Grid>
                            }
                            <Grid item container direction="row" spacing={3}>
                                <Grid item container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography component="h5" variant="h5">Mehrfachzuweisung</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={selectedGroups.length < 1}
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            startIcon={<CopyAll />}
                                            onClick={() => {
                                                setGroupAssignTemplateSettingMultipleDialogVisible(true);
                                            }}
                                        >
                                            Vorlage
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={selectedGroups.length < 1}
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            startIcon={<Apartment />}
                                            onClick={() => {
                                                setGroupAssignObjectMultipleDialogVisible(true);
                                            }}
                                        >
                                            Objekt
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={selectedGroups.length < 1}
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            startIcon={<Groups />}
                                            onClick={() => {
                                                setGroupAssignServiceWorkerMultipleDialogVisible(true);
                                            }}
                                        >
                                            Servicekraft
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={selectedGroups.length < 1}
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            startIcon={<Window />}
                                            onClick={() => {
                                                setGroupAssignPropertyMultipleDialogVisible(true);
                                            }}
                                        >
                                            Merkmal
                                        </Button>
                                    </Grid>
                                    <div style={{ flexGrow: 1 }}></div>
                                    <Grid item>
                                        <Typography variant="h5">
                                            <Edit /> Bearbeiten
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5">
                                            <Settings /> Settings Bearbeiten
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                <Grid item container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography component="h5" variant="h5">Filteroptionen</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            startIcon={<Apartment />}
                                            onClick={() => {
                                                setFilterObjectDialogVisible(true);
                                            }}
                                        >
                                            Objekt
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            startIcon={<People />}
                                            onClick={() => {
                                                setFilterUserServiceWorkerDialogVisible(true);
                                            }}
                                        >
                                            Servicekraft
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            startIcon={<Window />}
                                            onClick={() => {
                                                setFilterPropertyDialogVisible(true);
                                            }}
                                        >
                                            Merkmal
                                        </Button>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>
                                    <GroupEntireEvalTable
                                        buildActionRow={buildActionRow}
                                        userGroupSettingsTemplate={userGroupSettingsTemplate}
                                        token={token}
                                        objects={objects}
                                        userServiceWorkers={userServiceWorkers}
                                        properties={properties}
                                        groups={groups}
                                        filters={filters}
                                        onEditSettingsInDialog={newSettingsChose}
                                        setFilters={setFilters}
                                        onRefresh={async () => { await loadDataAsync() }}
                                        onEditSettings={navigateToSettings}
                                        selectedGroups={selectedGroups}
                                        onSelectionChange={(selection: string[]) => {
                                            setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
                                            setSelectedGroup(selection.length === 1 ? groups.filter(x => selection.some(s => s === x.id))[0] : null);
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {
                                (!groups || groups.length === 0) && !loading &&
                                <Grid item>
                                    <Paper className="Paper">
                                        <Grid container direction="row" alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <Typography component="h5" variant="h5"
                                                >
                                                    Keine Einheiten Gefunden!
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <FilterObjectDialog selectedObjects={selectedObjects} setSelectedObjects={setSelectedObjects} groups={allGroups} objects={objects} setGroups={setGroups} open={filterObjectDialogVisible} setOpen={setFilterObjectDialogVisible} />
            <FilterPropertyDialog selectedProperties={selectedFilterProperties} setSelectedProperties={setSelectedFilterProperties} groups={allGroups} properties={properties} setGroups={setGroups} open={filterPropertyDialogVisible} setOpen={setFilterPropertyDialogVisible} />
            <FilterServiceWorkerDialog selectedUserServiceWorkers={selectedUserServiceWorkers} setSelectedUserServiceWorkers={setSelectedUserServiceWorkers} groups={allGroups} userServiceWorkers={userServiceWorkers} setGroups={setGroups} open={filterUserServiceWorkerDialogVisible} setOpen={setFilterUserServiceWorkerDialogVisible} />

            <GroupSelectionAssignMultipleGroupToPropertyDialog
                open={groupAssignPropertyMultipleDialogVisible} setOpen={setGroupAssignPropertyMultipleDialogVisible} groups={groups}
                currentGroups={selectedGroups} onRefresh={loadDataAsync} />

            <GroupSelectionAssignMultupleGroupToServiceWorkerDialog
                open={groupAssignServiceWorkerMultipleDialogVisible} setOpen={setGroupAssignServiceWorkerMultipleDialogVisible} allGroups={groups}
                objects={objects} currentGroups={selectedGroups} userServiceWorkers={userServiceWorkers} onRefresh={loadDataAsync} />


            <GroupSelectionAssignMultupleGroupToObjectDialog
                open={groupAssignObjectMultipleDialogVisible} setOpen={setGroupAssignObjectMultipleDialogVisible} allGroups={groups}
                userObjects={objects} currentGroups={selectedGroups} onRefresh={loadDataAsync} />

            <GroupSelectionAssignMultupleGroupToTemplateSettingDialog
                open={groupAssignTemplateSettingMultipleDialogVisible} setOpen={setGroupAssignTemplateSettingMultipleDialogVisible}
                userGroupTemplateSettings={userGroupSettingsTemplate} currentGroups={selectedGroups} onRefresh={loadDataAsync} />
        </Grid >
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));