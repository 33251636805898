import axios from 'axios';
import { headers } from '../api';

export const getSupportContactTopics = async (token: string): Promise<string[]> => {
    try {

        if (!token) return [];

        const ret = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/settings/app-settings/support-contact-topics`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return ret.data as string[];

    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
        return [];
    }
};
