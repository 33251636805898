import axios from 'axios';
import { handleApiError, headers } from '../../api';
import { getQueryParamFromApiDataAmountType } from '../../helpers/charts/base';
import { getUserIdFromStorage } from '../../helpers/common';
import { LoraGroupMotion } from '../../models/LoraGroupMotion';

export const getLoraMotionsByGroupIds = async (token: string, groupIds: string[]): Promise<Array<LoraGroupMotion>> => {

    try {

        let userId = getUserIdFromStorage();

        if (!userId) return [];

        let ret = await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/lora-motion?${getQueryParamFromApiDataAmountType()}`,{stringArray: groupIds},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return ret.data;

    } catch (err) {
        handleApiError(err);
        return [];
    }
}