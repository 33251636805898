import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AUTO_CHART_HEIGHT, CHART_BAR_FONT_SIZE, CHART_BAR_SIZE, getFromStorageAdminSettingPortal, GLOBAL_CHART_DISPLAY_SIZE_TYPE, GLOBAL_END_TIME_BACKEND_FILTER, GLOBAL_GRID_STACK_CUSTOMS, GLOBAL_IS_EVAL_ACCOUNT_VISIBLE, GLOBAL_IS_EVAL_DASHBOARD_VISIBLE, GLOBAL_IS_EVAL_GROUPS_VISIBLE, GLOBAL_IS_EVAL_NEWS_INFO_VISIBLE, GLOBAL_IS_EVAL_OBJECTS_VISIBLE, GLOBAL_IS_EVAL_SERVICE_WORKERS_VISIBLE, GLOBAL_IS_EVAL_SETTINGS_VISIBLE, GLOBAL_IS_EVAL_SHOP_VISIBLE, GLOBAL_START_TIME_BACKEND_FILTER, GROUP_COMBINATION, GROUP_ID, GROUP_NAME, GROUP_TYPE, MAX_CHART_ITEM_COUNT, OBJECT_COMBINATION, OBJECT_ID, OBJECT_NAME, SELECTED_USER_ID_FROM_ADMIN, SERVICE_WORKER_COMBINATION, SERVICE_WORKER_ID, SERVICE_WORKER_NAME } from '../../constants';
import { DrawerList } from './DrawerList';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Dashboard } from './portal/Dashboard';
import { CircularProgress, createTheme, Divider, IconButton, Tooltip, useTheme } from '@mui/material';
import { Block, CloudDownload, ExitToApp, LiveHelp } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { SettingsPage } from './portal/SettingsPage';
import { EvalApiType, Services } from './portal/Dashboard/Services';
import { ServiceWorkerServicesDashboard } from './portal/Dashboard/ServiceWorkerServicesDashboard';
import { CleaningTriggerDashboard } from './portal/Dashboard/CleaningTriggerDashboard';
import { FrequencyDashboard } from './portal/Dashboard/FrequencyDashboard';
import { ServiceModeDashboardTable } from './portal/Dashboard/ServiceModeDashboardTable';
import { GroupSettingsPage } from './portal/Dashboard/settings/GroupSettings';
import { UserAccountPage } from './portal/UserAccountPage';
import { api } from '../../api';
import { getApiToken, setStorageForDrawer } from '../../helpers/storage-management';
import { UserAccount, UserMenuVisibility } from '../../models/UserAccount';
import { UserType } from "../../models/UserType";
import { CustomSum } from './portal/Dashboard/CustomSum';
import CheckMeNowIcon from '../../assets/svgs/check-me-now_icon.svg'
import GreenbirdLogo from '../../assets/svgs/greenbird_logo.svg'
import { FeedbackDashboard } from './portal/Dashboard/FeedbackDashboard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import { Copyright } from '../Copyright';
import { logout } from '../../api/authorization/logout';
import { ManagerCustomers } from './portal/ManagerCustomers';
import { getOwnUserIdFromStorage, getUserIdFromStorage, mapLabelUserType } from '../../helpers/common';
import { PortalContactSupportDialog } from '../dialogs/Portal.ContactSupportDialog';
import { ShopPage, useQuery } from './portal/checkout/ShopPage';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { ProductManagementPage } from './ProductManagementPage';
import { TemplateManagementPage } from './TemplateManagementPage';
import { PaymentResultPage as PaymentResultPage } from './portal/checkout/PaymentResultPage';
import { PaymentResultPageSuccess } from './portal/checkout/PaymentResultPageSuccess';
import { PaymentResultPageFailed } from './portal/checkout/PaymentResultPageFailed';
import { getEndTimeBackendFilter, getFilterBetweenSpecifiedTime, getFilterBetweenSpecifiedTimeBackend, getIncludeHourFilter, getStartTimeBackendFilter, PeriodChartFilterType } from '../../helpers/charts/base';
import { News } from './portal/News';
import { UserManagementPage } from './admin/UserManagementPage';
import { AllInvoicesPage } from './admin/AllInvoicesPage';
import { ShopPagePublic } from '../unauthorized/ShopPagePublic';
import { ObjectManagementPage } from './extendedapp/ObjectManagementPage';
import { PropertyManagementPage } from './extendedapp/property/PropertyManagementPage';
import { ServiceWorkerManagementPage } from './extendedapp/serviceWorker/ServiceWorkerManagementPage';
import { GroupEntireEvalManagement } from './extendedapp/entireEval/GroupEntireEvalManagement';
import { GroupType } from '../../models/Group';
import SetupExtendedAppFeature from './extendedapp/supportInput/SetupExtendedAppFeatures';
import { SettingsChooserManagement } from './extendedapp/settingsChooser/SettingsChooserManagement';
import { useSnackbar } from 'notistack';
import { QualityChecksDashboard } from './portal/Dashboard/qualityChecks/QualityChecksDashboard';
import { ServiceWorkerSession } from './portal/service-worker/ServiceWorkerSession';
import { DrawerMenuItemDisplayType } from './ObjectMenuPointDrawer';
import { GroupLicenseAdminPage } from './admin/GroupLicenseAdminPage';
import { ObjectCleaningTriggerDashboard } from './portal/object/ObjectCleaningTriggerDashboard';
import { OnlineStatusDashboard } from './portal/Dashboard/OnlineStatusDashboard';
import { ObjectSessionAnalytic } from './portal/object/ObjectSessionAnalytic';
import { Instructions } from './blobs/Instructions';
import { ObjectSharedFolderDashboard } from './portal/object/ObjectSharedFolderDashboard';
import { StandardItemAdminPage } from './admin/StandardItemAdminPage';
import { AdminSettingsPage } from './admin/AdminSettingsPage';
import { AdminSetting } from '../../models/AdminSetting';
import { IntervalControlPage } from './extendedapp/interval/IntervalControlPage';
import { IntervalControl } from '../../models/IntervalControl';
import { HitListDashboard } from './portal/Dashboard/HitListDashboard';
import { NetworkImageNonDialog } from '../images/NetworkImageNonDialog';
import { defaultAPIDataAmountType, defaultEndDate, defaultPeriodChartFilterType, defaultStartDate } from '../../helpers/date-management';
import { CountdownDialog } from '../dialogs/app/AutoLogoutDialog';
import { TermsOfUseDialog } from '../dialogs/app/TermsOfUseDialog';
import { LoraSensorAdminPage } from './admin/LoraSensorAdminPage';
import { PreFilterDialog } from '../dialogs/app/PreFilterDialog';
import useNoInitialEffect from '../../helpers/extensions';
import { FrequencyLoraDashboard } from './portal/Dashboard/FrequencyLoraDashboard';
import { CustomDashboard } from './portal/Dashboard/CustomDashboard';
import { GridsStackCustomManagementDialog } from './portal/Dashboard/customDashboard/dialog/GridsStackCustomManagementDialog';
import { ChartDisplaySizeType, GridStackCustom } from '../../models/GridStackCustom';
import { HomeDrawerFilter } from './portal/HomeDrawerFilter';
import { LicenseOverview } from './portal/LicenseOverview';
import { LicenseManagementGeneral } from './portal/LicenseManagementGeneral';
import { LicenseAndAddGroupComponent } from './portal/LicenseAndAddGroupComponent';
import { InvoiceOwnerPage } from './portal/InvoiceOwnerPage';
import { SetPointAndActualValueDashboard } from './portal/Dashboard/SetPointActualValueDashboard';
import { BatteryDisplayOnlineStatusDashboard } from './portal/Dashboard/BatteryDisplayOnlineStatusDashboard';
import { ObjectCarbonDioxideDashboard } from './portal/object/ObjectCarbonDioxideDashboard';
import LanguagePickerI18n from '../core/components/languagePickerI18n';
import { useTranslation } from 'react-i18next';
import { IntervalType } from '../core/intervalControl/GroupSettingFieldsIntervalControl';
import { StandardWorkItemType } from '../../models/StandardItem';
import { ObjectStockDashboard } from './portal/object/ObjectStockDashboard';
import { QmTriggerDashboard } from './portal/Dashboard/QmTriggerDashboard';

export const GlobalContext = React.createContext<any>({});
export const IntervalContext = React.createContext<{ intervalType: IntervalType, refreshSettings: () => any }>({ intervalType: IntervalType.Group, refreshSettings: () => { } });
export const SettingsContext = React.createContext<any>({});
export const SettingsEditContext = React.createContext<{ simId: string, setSimId: any, teamViewerId: string, setTeamViewerId: any }>({ simId: "", setSimId: null, teamViewerId: "", setTeamViewerId: null });


export const PortalPage = () => {

  const classes = useStylesPortalPage();
  const history = useHistory();
  const queryString = useQuery(history);
  const doPrintOnceRef = React.useRef(JSON.parse(queryString.get("print") || 'false'));

  const [gridStackCustoms, setGridStackCustoms] = React.useState<GridStackCustom[]>([]);

  const [reloadDataFromApi, setReloadDataFromApi] = React.useState(0);
  const [periodChartFilter, setPeriodChartFilter] = React.useState(defaultPeriodChartFilterType);
  const [apiDataAmountType, setApiDataAmountType] = React.useState(defaultAPIDataAmountType);
  const [selectedUserFromAdmin, setSelectedUserFromAdmin] = React.useState<UserAccount>();
  const [selectedGroupName, setSelectedGroupName] = React.useState<string>("");
  const [selectedGroupId, setSelectedGroupId] = React.useState<string>("");
  const [selectedGroupType, setSelectedGroupType] = React.useState(GroupType.DisplayWallApp);

  const [selectedObjectId, setSelectedObjectId] = React.useState("");
  const [selectedObjectName, setSelectedObjectName] = React.useState<string>("");
  const [selectedCombinationObject, setSelectedCombinationObject] = React.useState<GroupLicenseTable[]>([]);


  const [selectedServiceWorkerId, setSelectedServiceWorkerId] = React.useState("");
  const [selectedServiceWorkerName, setSelectedServiceWorkerName] = React.useState<string>("");
  const [selectedCombinationServiceWorker, setSelectedCombinationServiceWorker] = React.useState<GroupLicenseTable[]>([]);

  const [selectedCombinationGroup, setSelectedCombinationGroup] = React.useState<GroupLicenseTable[]>([]);
  const [contactSupportDialogVisible, setContactSupportDialogVisible] = React.useState(false);
  const [isPreFilterDialogVisible, setIsPreFilterDialogVisible] = React.useState(false);

  const [token, setToken] = React.useState("");
  const [userAccount, setUserAccount] = React.useState<UserAccount>();
  const [adminSetting, setAdminSetting] = React.useState<AdminSetting>(getFromStorageAdminSettingPortal());

  const [filterHour, setFilterHour] = React.useState(false);
  const [showStatus, setShowStatus] = React.useState(false);

  const { path } = useRouteMatch();
  const query = new URLSearchParams(useLocation().search)

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [includeFilterHours, setIncludeFilterHours] = React.useState(false);
  const [filterBetweenSpecifiedTime, setFilterBetweenSpecifiedTime] = React.useState(false);
  const [filterBetweenSpecifiedTimeBackend, setFilterBetweenSpecifiedTimeBackend] = React.useState(false);
  const [startTimeBackendFilter, setStartTimeBackendFilter] = React.useState(new Date());
  const [endTimeBackendFilter, setEndTimeBackendFilter] = React.useState(new Date());


  const [maxChartItemCount, setMaxChartItemCount] = React.useState(10);
  const [barSize, setBarSize] = React.useState(40);
  const [chartBarFontSize, setChartBarFontSize] = React.useState(22);
  const [autoHeightChart, setAutoHeightChart] = React.useState(true);
  const [sourceLogoUser, setSourceLogoUser] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [intervalControls, setIntervalControls] = React.useState<IntervalControl[]>([]);

  const [autoLogoutDialog, setAutoLogoutDialog] = React.useState(false);
  const [apiDataLoading, setApiDataLoading] = React.useState(false);
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);
  const [chartDisplaySizeType, setChartDisplaySizeType] = React.useState<ChartDisplaySizeType>();



  const [isEvalGroupsVisible, setIsEvalGroupsVisible] = React.useState(false);
  const [isEvalServiceWorkersVisible, setIsEvalServiceWorkersVisible] = React.useState(false);
  const [isEvalObjectsVisible, setIsEvalObjectsVisible] = React.useState(false);
  const [isEvalDashboardVisible, setIsEvalDashboardVisible] = React.useState(false);

  const [isEvalSettingsVisible, setIsEvalSettingsVisible] = React.useState(false);
  const [isEvalAccountVisible, setIsEvalAccountVisible] = React.useState(false);
  const [isEvalNewsInfoVisible, setIsEvalNewsInfoVisible] = React.useState(false);
  const [isEvalShopVisible, setIsEvalShopVisible] = React.useState(false);

  const [filtersGroupLicenseTable, setFiltersGroupLicenseTable] = React.useState([{ columnName: GroupLicenseTable.Columns.groupType, value: "" }]);
  const { t } = useTranslation();


  React.useEffect(() => {

    setIncludeFilterHours(getIncludeHourFilter());
    setFilterBetweenSpecifiedTime(getFilterBetweenSpecifiedTime());
    setFilterBetweenSpecifiedTimeBackend(getFilterBetweenSpecifiedTimeBackend());
    setStartTimeBackendFilter(getStartTimeBackendFilter());
    setEndTimeBackendFilter(getEndTimeBackendFilter());

    (async () => {

      let isEvalGroupsVisible = JSON.parse(localStorage.getItem(GLOBAL_IS_EVAL_GROUPS_VISIBLE) || "false");
      setIsEvalGroupsVisible(isEvalGroupsVisible);

      let isEvalServiceWorkerVisible = JSON.parse(localStorage.getItem(GLOBAL_IS_EVAL_SERVICE_WORKERS_VISIBLE) || "false");
      setIsEvalServiceWorkersVisible(isEvalServiceWorkerVisible);

      let isEvalObjectsVisible = JSON.parse(localStorage.getItem(GLOBAL_IS_EVAL_OBJECTS_VISIBLE) || "false");
      setIsEvalObjectsVisible(isEvalObjectsVisible);

      let isEvalDashboardVisible = JSON.parse(localStorage.getItem(GLOBAL_IS_EVAL_DASHBOARD_VISIBLE) || "false");
      setIsEvalDashboardVisible(isEvalDashboardVisible);

      let isEvalSettingsVisible = JSON.parse(localStorage.getItem(GLOBAL_IS_EVAL_SETTINGS_VISIBLE) || "false");
      setIsEvalSettingsVisible(isEvalSettingsVisible);

      let isEvalAccountVisible = JSON.parse(localStorage.getItem(GLOBAL_IS_EVAL_ACCOUNT_VISIBLE) || "false");
      setIsEvalAccountVisible(isEvalAccountVisible);

      let isEvalNewsInfosVisible = JSON.parse(localStorage.getItem(GLOBAL_IS_EVAL_NEWS_INFO_VISIBLE) || "false");
      setIsEvalNewsInfoVisible(isEvalNewsInfosVisible);

      let isEvalShopVisible = JSON.parse(localStorage.getItem(GLOBAL_IS_EVAL_SHOP_VISIBLE) || "false");
      setIsEvalShopVisible(isEvalShopVisible);


      let chartSize = localStorage.getItem(GLOBAL_CHART_DISPLAY_SIZE_TYPE) || "0";
      setChartDisplaySizeType(parseInt(chartSize))

      const barSize = JSON.parse(localStorage.getItem(CHART_BAR_SIZE) || "40");
      setBarSize(barSize);

      const chartBarFontSize = JSON.parse(localStorage.getItem(CHART_BAR_FONT_SIZE) || "22");
      setChartBarFontSize(chartBarFontSize);

      const maxChartItemCount = JSON.parse(localStorage.getItem(MAX_CHART_ITEM_COUNT) || "10");
      setMaxChartItemCount(maxChartItemCount);

      const autoHeightChart = JSON.parse(localStorage.getItem(AUTO_CHART_HEIGHT) || "true");
      setAutoHeightChart(autoHeightChart);

      const tempGroupName = localStorage.getItem(GROUP_NAME);
      setSelectedGroupName(tempGroupName || '');

      const tempGroupId = localStorage.getItem(GROUP_ID);
      setSelectedGroupId(tempGroupId || '');

      const tempObjectId = localStorage.getItem(OBJECT_ID);
      setSelectedObjectId(tempObjectId || '');

      const tempObjectName = localStorage.getItem(OBJECT_NAME);
      setSelectedObjectName(tempObjectName || '');

      const tempCombObject = localStorage.getItem(OBJECT_COMBINATION) || "[]";
      setSelectedCombinationObject(JSON.parse(tempCombObject));

      const tempCombServiceWorker = localStorage.getItem(SERVICE_WORKER_COMBINATION) || "[]";
      setSelectedCombinationServiceWorker(JSON.parse(tempCombServiceWorker));

      const tempServiceWorkerId = localStorage.getItem(SERVICE_WORKER_ID);
      setSelectedServiceWorkerId(tempServiceWorkerId || '');

      const tempServiceWorkerName = localStorage.getItem(SERVICE_WORKER_NAME);
      setSelectedServiceWorkerName(tempServiceWorkerName || '');

      const tempGroupType = JSON.parse(localStorage.getItem(GROUP_TYPE) || "0");
      setSelectedGroupType(tempGroupType);

      const tempCombGroups = localStorage.getItem(GROUP_COMBINATION) || "[]";
      setSelectedCombinationGroup(JSON.parse(tempCombGroups));

      const gridStackCustoms = JSON.parse(localStorage.getItem(GLOBAL_GRID_STACK_CUSTOMS) || "[]");
      setGridStackCustoms(gridStackCustoms);

      const tokenTemp = getApiToken(history);

      if (tempGroupId) {
        setDataEvaluationListOpen(true);
        setOpenSingleEvalDialog(true);
      }
      /*
            if (!tokenTemp)
              logout(history);
      
      
            if (!(await api.verifyUserToken(tokenTemp))) {
              enqueueSnackbar("Token Verifikation fehlgeschlagen", { variant: 'error' });
              logout(history);
            }
            /*
            setTimeout(async () => {
              if (!(await api.verifyUserToken(tokenTemp))) {
                enqueueSnackbar("Token Verifikation fehlgeschlagen", { variant: 'error' });
                logout(history);
              }
            }, process.env.REACT_APP_STATUS_REFRESH_INTERVAL * 1000);
      */
      setTimeout(async () => {
        enqueueSnackbar("Automatischer Logout", { variant: 'error' });
        setAutoLogoutDialog(true);
      }, 1000 * 60 * 120);

      const user = await api.getUser(tokenTemp, getOwnUserIdFromStorage());
      if (user.invitedFromUserId) {
        localStorage.setItem(SELECTED_USER_ID_FROM_ADMIN, user.invitedFromUserId);
      }
      setUserAccount(user);
      const adminSetting = await api.GetAdminSettingPortal(tokenTemp, getFromStorageAdminSettingPortal());
      setAdminSetting(adminSetting);



      let selectedUser = null as any;
      if (user && user.userType === UserType.Admin) {
        selectedUser = await api.getUser(tokenTemp, getUserIdFromStorage());
        setSelectedUserFromAdmin(selectedUser);
      }

      let source = "";

      if (selectedUser && selectedUser?.logo?.fileName) {
        source = await api.downloadUserLogoAsync(selectedUser, tokenTemp);
      } else if (user && user?.logo?.fileName) {
        source = await api.downloadUserLogoAsync(user, tokenTemp);
      }
      setSourceLogoUser(source);
      setToken(tokenTemp);
    })();
  }, [history]);

  useNoInitialEffect(() => {
    setReloadDataFromApi(prev => prev + 1);
  }, [])
  //  }, [includeFilterHours, filterBetweenSpecifiedTime])

  const onUpdateDisplayGroup = (selectedCurrentGroup: GroupLicenseTable | undefined, navigate = true) => {
    if (!selectedCurrentGroup) return;

    setDataEvaluationListOpen(true);
    setOpenSingleEvalDialog(true);
    setSelectedGroupId(selectedCurrentGroup.id);
    setSelectedGroupName(selectedCurrentGroup.notificationName)
    setSelectedGroupType(selectedCurrentGroup.groupType);
    setStorageForDrawer(selectedCurrentGroup);
    if (navigate) {
      history.push(`/portal/group/${selectedCurrentGroup.id}`);

    }
  }
  const [openSingleEvalDialog, setOpenSingleEvalDialog] = React.useState(false);

  const [openOverviewItems, setOpenOverviewItems] = React.useState(true);
  const [openNewsInfos, setOpenNewsInfos] = React.useState(false);
  const [dataEvaluationListOpen, setDataEvaluationListOpen] = React.useState(false);
  const [dataEvaluationObjectListOpen, setDataEvaluationObjectListOpen] = React.useState(false);
  const [dataEvaluationServiceWorkerListOpen, setDataEvaluationServiceWorkerListOpen] = React.useState(false);
  const [openDrawerSettings, setOpenDrawerSettings] = React.useState(false);
  const [openDrawerAdmin, setOpenDrawerAdmin] = React.useState(false);
  const [openDrawerCustomDashboard, setOpenDrawerCustomDashboard] = React.useState(false);
  const [openDrawerShop, setOpenDrawerShop] = React.useState(false);


  const [openSingleEvalObjectDialog, setOpenSingleEvalObjectDialog] = React.useState(false);
  const [openEvalMultipleObjectMenu, setOpenEvalMultipleObjectMenu] = React.useState(false);
  const [openSingleEvalAllObjectMenu, setOpenSingleEvalAllObjectMenu] = React.useState(false);

  const [openSingleEvalServiceWorkerDialog, setOpenSingleEvalServiceWorkerDialog] = React.useState(false);
  const [openMultipleEvalServiceWorkerDialog, setOpenMultipleEvalServiceWorkerDialog] = React.useState(false);
  const [openAllEvalServiceWorkerDialog, setOpenAllEvalServiceWorkerDialog] = React.useState(false);

  const [openMultipleEvalDialog, setOpenMultipleEvalDialog] = React.useState(false);
  const [openAllEvalDialog, setOpenAllEvalDialog] = React.useState(false);


  const globalFields = {
    token: token,
    doPrintOnceRef: doPrintOnceRef,
    sourceLogoUser: sourceLogoUser,
    startTimeBackendFilter: startTimeBackendFilter,
    setStartTimeBackendFilter: setStartTimeBackendFilter,
    endTimeBackendFilter: endTimeBackendFilter,
    setEndTimeBackendFilter: setEndTimeBackendFilter,
    openOverviewItems: openOverviewItems,
    setOpenOverviewItems: setOpenOverviewItems,
    openNewsInfos: openNewsInfos,
    setOpenNewsInfos: setOpenNewsInfos,
    openSingleEvalObjectDialog: openSingleEvalObjectDialog,
    setOpenSingleEvalObjectDialog: setOpenSingleEvalObjectDialog,
    openEvalMultipleObjectMenu: openEvalMultipleObjectMenu,
    setOpenEvalMultipleObjectMenu: setOpenEvalMultipleObjectMenu,
    openSingleEvalAllObjectMenu: openSingleEvalAllObjectMenu,
    setOpenSingleEvalAllObjectMenu: setOpenSingleEvalAllObjectMenu,
    openSingleEvalServiceWorkerDialog: openSingleEvalServiceWorkerDialog,
    setOpenSingleEvalServiceWorkerDialog: setOpenSingleEvalServiceWorkerDialog,
    openMultipleEvalServiceWorkerDialog: openMultipleEvalServiceWorkerDialog,
    setOpenMultipleEvalServiceWorkerDialog: setOpenMultipleEvalServiceWorkerDialog,
    openAllEvalServiceWorkerDialog: openAllEvalServiceWorkerDialog,
    setOpenAllEvalServiceWorkerDialog: setOpenAllEvalServiceWorkerDialog,
    openMultipleEvalDialog: openMultipleEvalDialog,
    setOpenMultipleEvalDialog: setOpenMultipleEvalDialog,
    openAllEvalDialog: openAllEvalDialog,
    setOpenAllEvalDialog: setOpenAllEvalDialog,


    openDrawerSettings: openDrawerSettings,
    setOpenDrawerSettings: setOpenDrawerSettings,
    openDrawerAdmin: openDrawerAdmin,
    setOpenDrawerAdmin: setOpenDrawerAdmin,
    openDrawerCustomDashboard: openDrawerCustomDashboard,
    setOpenDrawerCustomDashboard: setOpenDrawerCustomDashboard,
    openDrawerShop: openDrawerShop,
    setOpenDrawerShop: setOpenDrawerShop,

    dataEvaluationListOpen: dataEvaluationListOpen,
    setDataEvaluationListOpen: setDataEvaluationListOpen,
    dataEvaluationObjectListOpen: dataEvaluationObjectListOpen,
    setDataEvaluationObjectListOpen: setDataEvaluationObjectListOpen,
    dataEvaluationServiceWorkerListOpen: dataEvaluationServiceWorkerListOpen,
    setDataEvaluationServiceWorkerListOpen: setDataEvaluationServiceWorkerListOpen,


    filtersGroupLicenseTable: filtersGroupLicenseTable,
    setFiltersGroupLicenseTable: setFiltersGroupLicenseTable,


    isEvalGroupsVisible: isEvalGroupsVisible,
    setIsEvalGroupsVisible: setIsEvalGroupsVisible,
    isEvalServiceWorkersVisible: isEvalServiceWorkersVisible,
    setIsEvalServiceWorkersVisible: setIsEvalServiceWorkersVisible,
    isEvalObjectsVisible: isEvalObjectsVisible,
    setIsEvalObjectsVisible: setIsEvalObjectsVisible,
    isEvalDashboardVisible: isEvalDashboardVisible,
    setIsEvalDashboardVisible: setIsEvalDashboardVisible,


    isEvalSettingsVisible: isEvalSettingsVisible,
    setIsEvalSettingsVisible: setIsEvalSettingsVisible,
    isEvalAccountVisible: isEvalAccountVisible,
    setIsEvalAccountVisible: setIsEvalAccountVisible,
    isEvalNewsInfoVisible: isEvalNewsInfoVisible,
    setIsEvalNewsInfoVisible: setIsEvalNewsInfoVisible,
    isEvalShopVisible: isEvalShopVisible,
    setIsEvalShopVisible: setIsEvalShopVisible,


    chartDisplaySizeType: chartDisplaySizeType,
    setChartDisplaySizeType: setChartDisplaySizeType,
    isPreFilterDialogVisible: isPreFilterDialogVisible,
    setSelectedEndDate: setSelectedEndDate,
    selectedEndDate: selectedEndDate,
    setSelectedStartDate: setSelectedStartDate,
    selectedStartDate: selectedStartDate,
    gridStackCustoms: gridStackCustoms,
    setGridStackCustoms: setGridStackCustoms,
    filterBetweenSpecifiedTime: filterBetweenSpecifiedTime,
    setFilterBetweenSpecifiedTime: setFilterBetweenSpecifiedTime,
    filterBetweenSpecifiedTimeBackend: filterBetweenSpecifiedTimeBackend,
    setFilterBetweenSpecifiedTimeBackend: setFilterBetweenSpecifiedTimeBackend,
    setIsPreFilterDialogVisible: setIsPreFilterDialogVisible,
    chartBarFontSize: chartBarFontSize,
    setChartBarFontSize: setChartBarFontSize,
    barSize: barSize,
    setBarSize: setBarSize,
    apiDataLoading: apiDataLoading,
    setApiDataLoading: setApiDataLoading,
    reloadDataFromApi: reloadDataFromApi,
    setReloadDataFromApi: setReloadDataFromApi,
    apiDataAmountType: apiDataAmountType,
    setApiDataAmountType: setApiDataAmountType,
    periodChartFilter: periodChartFilter,
    setPeriodChartFilter: setPeriodChartFilter,
    autoHeightChart: autoHeightChart,
    setAutoHeightChart: setAutoHeightChart,
    maxChartItemCount: maxChartItemCount,
    setMaxChartItemCount: setMaxChartItemCount,
    adminSetting: adminSetting,
    showStatus: showStatus, setShowStatus: setShowStatus,
    setUserAccount: setUserAccount,
    userAccount: userAccount, includeFilterHours: includeFilterHours, setIncludeFilterHours: setIncludeFilterHours, onUpdateDisplayGroup: onUpdateDisplayGroup,
    selectedObjectId: selectedObjectId, selectedObjectName: selectedObjectName, setSelectedObjectName: setSelectedObjectName, selectedCombinationObject: selectedCombinationObject, setSelectedCombinationObject: setSelectedCombinationObject, setSelectedObjectId: setSelectedObjectId,
    setSelectedGroupId: setSelectedGroupId, setSelectedGroupName: setSelectedGroupName, selectedCombinationServiceWorker: selectedCombinationServiceWorker, setSelectedCombinationServiceWorker: setSelectedCombinationServiceWorker,
    setSelectedServiceWorkerName: setSelectedServiceWorkerName, setSelectedServiceWorkerId: setSelectedServiceWorkerId, selectedServiceWorkerId: selectedServiceWorkerId, selectedServiceWorkerName: selectedServiceWorkerName, setSelectedCombinationGroup: setSelectedCombinationGroup
  }

  let single = DrawerMenuItemDisplayType.Single;

  let multiple = DrawerMenuItemDisplayType.Multiple;
  let all = DrawerMenuItemDisplayType.All;

  return <>
    <GlobalContext.Provider value={globalFields}>
      <div className={classes.root}>
        <AppBar position="fixed" className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
              size="large">
              <MenuIcon />
            </IconButton>
            <img style={{ marginRight: 10 }} src={CheckMeNowIcon} height={60} alt="check-me-now-icon" />
            <Typography variant="h6" noWrap>
              Portal: Check Me Now
            </Typography>
            <div className={classes.grow} />
            {sourceLogoUser ?
              <div style={{ height: 50 }}>
                <img src={sourceLogoUser} height={50} alt="greenbird-logo" />
              </div>
              :
              <img src={GreenbirdLogo} height={50} alt="greenbird-logo" />
            }
            <div className={classes.grow} />
            <Typography variant="h6" noWrap>
              {t("portalPage.loggedInAs")}: {mapLabelUserType(userAccount?.userType)}
            </Typography>
            <Tooltip title="Kontaktiere Support" aria-label="add">
              <IconButton
                onClick={() => { setContactSupportDialogVisible(true); }}
                color="inherit"
                size="large">
                <LiveHelp />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout" aria-label="add">
              <IconButton onClick={() => logout(history)} color="inherit" size="large">
                <ExitToApp />
              </IconButton>
            </Tooltip>
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              <LanguagePickerI18n />
            </div>
            <Tooltip title="Portal-App-Version" aria-label="add">
              <Typography>
                {process.env.REACT_APP_VERSION}
              </Typography>
            </Tooltip>

          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => setOpen(false)} size="large">
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <DrawerList
            user={userAccount}
            selectedCombinationGroup={selectedCombinationGroup}
            setSelectedCombinationGroup={setSelectedCombinationGroup}
            selectedGroupName={selectedGroupName}
            setSelectedGroupName={setSelectedGroupName}
            selectedGroupId={selectedGroupId}
            selectedUserFromAdmin={selectedUserFromAdmin}
            setSelectedUserFromAdmin={setSelectedUserFromAdmin}
            setSelectedGroupId={setSelectedGroupId}
            selectedGroupType={selectedGroupType}
            setSelectedGroupType={setSelectedGroupType}
            openSingleEvalDialog={openSingleEvalDialog}
            setOpenSingleEvalDialog={setOpenSingleEvalDialog}
          />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>

            <Route exact path={path} key={document.location.href}>
              <HomeDrawerFilter
              />
            </Route>
            <Route exact path={`${path}/dashboard`} key={document.location.href}>
              <Dashboard
                selectedCombinationGroup={selectedCombinationGroup}
                setSelectedCombinationGroup={setSelectedCombinationGroup}
                selectedGroupId={selectedGroupId}
              />
            </Route>
            <Route exact path={`${path}/licenses`} key={document.location.href}>
              <LicenseOverview
              />
            </Route>
            <Route exact path={`${path}/license-general`} key={document.location.href}>
              <LicenseManagementGeneral
                selectedCombinationGroup={selectedCombinationGroup}
                setSelectedCombinationGroup={setSelectedCombinationGroup}
                userAccount={userAccount}
                selectedGroupId={selectedGroupId}
              />
            </Route>
            <Route exact path={`${path}/license-einheit-management`} key={document.location.href}>
              <LicenseAndAddGroupComponent
                selectedCombinationGroup={selectedCombinationGroup}
                setSelectedCombinationGroup={setSelectedCombinationGroup}
                userAccount={userAccount}
                selectedGroupId={selectedGroupId}
              />
            </Route>
            <Route path={`${path}/bestellungen`} component={InvoiceOwnerPage} >
              <InvoiceOwnerPage userAccount={userAccount} />
            </Route>
            <Route path={`${path}/account`} key={document.location.href}>
              <UserAccountPage setDrawerUserAccount={setUserAccount} currentLoggedInUser={userAccount} />
            </Route>
            <Route path={`${path}/dashboardverwaltung`} key={document.location.href}>
              <GridsStackCustomManagementDialog />
            </Route>
            <Route path={`${path}/news`} key={document.location.href}>
              <News />
            </Route>
            <Route path={`${path}/instructions`} key={document.location.href}>
              <Instructions />
            </Route>
            <Route path={`${path}/shop`} key={document.location.href} component={ShopPage} />
            {/* <Route path={`${path}/shop/:pricingId`} key={document.location.href} component={ShopPage} /> */}
            <Route path={`${path}/payment-results/:id`} key={document.location.href} component={PaymentResultPage} />
            <Route
              path={`${path}/manager-customer`}
              key={document.location.href}
              component={ManagerCustomers}
            />
            <Route exact path={`${path}/group/:groupId/qualitätssicherung`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={DrawerMenuItemDisplayType.Single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/qualitätssicherung`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={DrawerMenuItemDisplayType.Multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/qualitätssicherung`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={DrawerMenuItemDisplayType.All} />} />
            </Route>

            <Route exact path={`${path}/object/multiple/qualitätssicherung`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/qualitätssicherung`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/qualitätssicherung`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>


            <Route exact path={`${path}/service-worker/multiple/qualitätssicherung`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.ServiceWorkers} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/qualitätssicherung`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/qualitätssicherung`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='qualityChecksDashboard' menu={<QualityChecksDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={DrawerMenuItemDisplayType.Single} />} />
            </Route>



            <Route exact path={`${path}/group/:groupId`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={DrawerMenuItemDisplayType.Multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>



            <Route exact path={`${path}/object/multiple/services`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/services`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.Objects} drawerDisplayType={DrawerMenuItemDisplayType.All} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/services`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.Objects} drawerDisplayType={DrawerMenuItemDisplayType.Single} />} />

            </Route>


            <Route exact path={`${path}/service-worker/multiple/services`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.ServiceWorkers} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/services`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/services`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='servicesDashboard' menu={<Services serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />
            </Route>


            <Route exact path={`${path}/group/:groupId/table`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='serviceModeDashboardTable' menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>

            <Route exact path={`${path}/groups/services/table`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='serviceModeDashboardTable' menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={DrawerMenuItemDisplayType.Multiple} />} />
            </Route>

            <Route exact path={`${path}/users/services/table`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='serviceModeDashboardTable'
                menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>




            <Route exact path={`${path}/object/multiple/table`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='serviceModeDashboardTable' menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/table`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='serviceModeDashboardTable' menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/table`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='serviceModeDashboardTable' menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>


            <Route exact path={`${path}/service-worker/multiple/table`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='serviceModeDashboardTable' menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.ServiceWorkers} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/table`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='serviceModeDashboardTable' menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/table`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='serviceModeDashboardTable' menu={<ServiceModeDashboardTable serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />
            </Route>






            <Route exact path={`${path}/group/:groupId/user`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/user`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/user`}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>

            <Route exact path={`${path}/object/multiple/user`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/user`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/user`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />

            </Route>


            <Route exact path={`${path}/service-worker/multiple/user`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.ServiceWorkers} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/user`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/user`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='serviceWorkerServicesDashboard' menu={<ServiceWorkerServicesDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />
            </Route>


            <Route exact path={`${path}/group/:groupId/qm-trigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='qmTriggerDashboard' menu={<QmTriggerDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/qm-trigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='qmTriggerDashboard' menu={<QmTriggerDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/qm-trigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='qmTriggerDashboard' menu={<QmTriggerDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>

            <Route exact path={`${path}/object/multiple/qm-trigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='qmTriggerDashboard' menu={<QmTriggerDashboard serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/qm-trigger`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='qmTriggerDashboard' menu={<QmTriggerDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/qm-trigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='qmTriggerDashboard' menu={<QmTriggerDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>



            <Route exact path={`${path}/group/:groupId/reinigungstrigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/reinigungstrigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/reinigungstrigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>

            <Route exact path={`${path}/object/multiple/reinigungstrigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/reinigungstrigger`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/reinigungstrigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>

            <Route exact path={`${path}/service-worker/multiple/reinigungstrigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.ServiceWorkers} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/reinigungstrigger`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/reinigungstrigger`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='cleaningTriggerDashboard' menu={<CleaningTriggerDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />

            </Route>


            <Route exact path={`${path}/group/:groupId/soll-ist`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='setPointAndActualValueDashboard' menu={<SetPointAndActualValueDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/soll-ist`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='setPointAndActualValueDashboard' menu={<SetPointAndActualValueDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/soll-ist`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='setPointAndActualValueDashboard' menu={<SetPointAndActualValueDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>





            <Route exact path={`${path}/group/:groupId/online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>

            <Route exact path={`${path}/object/multiple/online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/online-status`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>

            <Route exact path={`${path}/service-worker/multiple/online-status`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.ServiceWorkers} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/online-status`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='onlineStatusDashboard' menu={<OnlineStatusDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />
            </Route>



            <Route exact path={`${path}/group/:groupId/battery-online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/battery-online-status`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/battery-online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />

            </Route>

            <Route exact path={`${path}/object/multiple/battery-online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/battery-online-status`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/battery-online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>

            <Route exact path={`${path}/service-worker/multiple/battery-online-status`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.ServiceWorkers} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/battery-online-status`} key={document.location.href} >

              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/battery-online-status`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='batteryDisplayOnlineStatusDashboard' menu={<BatteryDisplayOnlineStatusDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />
            </Route>



            <Route exact path={`${path}/group/:groupId/hitlist`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/hitlist`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/hitlist`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>

            <Route exact path={`${path}/object/multiple/hitlist`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.Objects} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/object/all/hitlist`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/hitlist`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>

            <Route exact path={`${path}/service-worker/multiple/hitlist`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.ServiceWorkers} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/hitlist`} key={document.location.href} >
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/hitlist`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='hitListDashboard' menu={<HitListDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />
            </Route>

            <Route exact path={`${path}/group/:groupId/feedback`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />

            </Route>
            <Route exact path={`${path}/groups/services/feedback`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.Groups} groupIds={query.getAll("id")} drawerDisplayType={multiple} />} />
            </Route>
            <Route exact path={`${path}/users/services/feedback`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>



            <Route exact path={`${path}/object/multiple/feedback`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />
            </Route>
            <Route exact path={`${path}/object/all/feedback`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/feedback`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>


            <Route exact path={`${path}/service-worker/multiple/feedback`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/feedback`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/feedback`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='feedbackDashboard' menu={<FeedbackDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />

            </Route>

            <Route exact path={`${path}/group/:groupId/frequency`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/frequency`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />
            </Route>
            <Route exact path={`${path}/users/services/frequency`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>


            <Route exact path={`${path}/object/multiple/frequency`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />
            </Route>
            <Route exact path={`${path}/object/all/frequency`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>

            <Route exact path={`${path}/object/:selectedObjectId/frequency`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />
            </Route>


            <Route exact path={`${path}/service-worker/multiple/frequency`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/frequency`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/frequency`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='frequencyDashboard' menu={<FrequencyDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />
            </Route>

            <Route exact path={`${path}/group/:groupId/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single} />} />
            </Route>
            <Route exact path={`${path}/groups/services/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />
            </Route>
            <Route exact path={`${path}/users/services/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all} />} />
            </Route>

            <Route exact path={`${path}/object/multiple/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />
            </Route>
            <Route exact path={`${path}/object/all/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>

            <Route exact path={`${path}/object/:selectedObjectId/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />
            </Route>


            <Route exact path={`${path}/service-worker/multiple/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />
            </Route>
            <Route exact path={`${path}/service-worker/all/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />
            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/frequency-lora`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='frequencyLoraDashboard' menu={<FrequencyLoraDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />
            </Route>






            <Route path={`${path}/group/:groupId/custom-sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard
                groupIds={query.getAll("id") || []}
                drawerDisplayType={single}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.Groups}
              />} />


            </Route>
            <Route path={`${path}/groups/services/custom-sum`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard
                drawerDisplayType={multiple}
                groupIds={query.getAll("id") || []}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.Groups}
              />} />
            </Route>
            <Route path={`${path}/users/services/custom-sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard
                groupIds={[]}
                drawerDisplayType={all}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.Groups} />} />

            </Route>

            <Route path={`${path}/object/multiple/custom-sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={multiple} groupIds={query.getAll("id")} selectedGroupId={''} selectedCombinationGroup={[]} />} />
            </Route>
            <Route path={`${path}/object/all/custom-sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} selectedGroupId={''} groupIds={query.getAll("id")} selectedCombinationGroup={[]} />} />
            </Route>

            <Route path={`${path}/object/:selectedObjectId/custom-sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} selectedGroupId={''} groupIds={[]} selectedCombinationGroup={[]} />} />
            </Route>

            <Route path={`${path}/service-worker/multiple/custom-sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={multiple} groupIds={query.getAll("id")} selectedGroupId={''} selectedCombinationGroup={[]} />} />
            </Route>
            <Route path={`${path}/service-worker/all/custom-sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} selectedGroupId={''} groupIds={[]} selectedCombinationGroup={[]} />} />
            </Route>
            <Route path={`${path}/service-worker/:selectedServiceWorkerId/custom-sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='customDashboard' menu={<CustomDashboard serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} selectedGroupId={''} groupIds={[]} selectedCombinationGroup={[]} />} />
            </Route>


            <Route exact path={`${path}/group/:groupId/sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                serviceDisplayType={EvalApiType.Groups} drawerDisplayType={single}
                groupIds={[]}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
              />} />


            </Route>
            <Route exact path={`${path}/groups/services/sum`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                serviceDisplayType={EvalApiType.Groups} drawerDisplayType={multiple}
                groupIds={query.getAll("id") || []}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
              />} />
            </Route>
            <Route exact path={`${path}/users/services/sum`} key={document.location.href}>

              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                serviceDisplayType={EvalApiType.Groups} drawerDisplayType={all}
                groupIds={[]}
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
              />} />
            </Route>

            <Route exact path={`${path}/object/multiple/sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.Objects} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />

            </Route>
            <Route exact path={`${path}/object/all/sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.Objects} drawerDisplayType={all} />} />

            </Route>
            <Route exact path={`${path}/object/:selectedObjectId/sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.Objects} drawerDisplayType={single} />} />

            </Route>


            <Route exact path={`${path}/service-worker/multiple/sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={multiple} groupIds={query.getAll("id")} />} />

            </Route>
            <Route exact path={`${path}/service-worker/all/sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={all} />} />

            </Route>
            <Route exact path={`${path}/service-worker/:selectedServiceWorkerId/sum`} key={document.location.href}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='customSum' menu={<CustomSum
                selectedGroupId={selectedGroupId}
                selectedCombinationGroup={selectedCombinationGroup}
                serviceDisplayType={EvalApiType.ServiceWorkers} drawerDisplayType={single} />} />

            </Route>





            <Route path={`${path}/group/:groupId/settings`} key={document.location.href} component={GroupSettingsPage} />
            <Route path={`${path}/settings`} key={document.location.href} component={SettingsPage} />
            <Route path={`${path}/produkte`} component={ProductManagementPage} />
            <Route exact path={`${path}/admin/standarditems`} key={document.location.href}>
              <StandardItemAdminPage filterByItemType={false} isAdminPage={true} />
            </Route>
            <Route exact path={`${path}/standarditems`} key={document.location.href}>
              <StandardItemAdminPage filterByItemType={false} isAdminPage={false} />
            </Route>
            <Route exact path={`${path}/materials`} key={document.location.href}>
              <StandardItemAdminPage standardWorkItemType={StandardWorkItemType.Material} filterByItemType={true} isAdminPage={false} />
            </Route>
            <Route exact path={`${path}/admin/room-type`} key={document.location.href}>
              <StandardItemAdminPage standardWorkItemType={StandardWorkItemType.RoomType} filterByItemType={true} isAdminPage={true} />
            </Route>
            <Route exact path={`${path}/admin/settings`} key={document.location.href}>
              <AdminSettingsPage />
            </Route>
            <Route path={`${path}/checkout/success`} component={PaymentResultPageSuccess} />
            <Route path={`${path}/checkout/failed`} component={PaymentResultPageFailed} />
            <Route path={`${path}/usermanagement`} component={UserManagementPage} />
            <Route path={`${path}/objekte`} component={ObjectManagementPage} />
            <Route path={`${path}/merkmale`} component={PropertyManagementPage} />
            <Route path={`${path}/interval`}>
              <IntervalControlPage intervalControls={intervalControls} setIntervalControls={setIntervalControls} />
            </Route>
            <Route path={`${path}/eingabehilfeapp`} component={SetupExtendedAppFeature} />
            <Route path={`${path}/servicekräfte`} component={ServiceWorkerManagementPage} />


            <Route path={`${path}/object/all/service-worker/sessions`}>

              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='objectSessionAnalytic' menu={<ObjectSessionAnalytic displayType={all} />} />
            </Route>
            <Route path={`${path}/object/multiple/service-worker/sessions`}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='objectSessionAnalytic' menu={<ObjectSessionAnalytic displayType={multiple} />} />

            </Route>
            <Route path={`${path}/object/:selectedObjectId/service-worker/sessions`}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='objectSessionAnalytic' menu={<ObjectSessionAnalytic />} />

            </Route>


            <Route path={`${path}/object/all/carbon-dioxide-consumption`}>

              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='objectCarbonDioxideDashboard' menu={<ObjectCarbonDioxideDashboard displayType={all} />} />
            </Route>
            <Route path={`${path}/object/multiple/carbon-dioxide-consumption`}>

              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='objectCarbonDioxideDashboard' menu={<ObjectCarbonDioxideDashboard displayType={multiple} />} />

            </Route>
            <Route path={`${path}/object/:selectedObjectId/carbon-dioxide-consumption`}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='objectCarbonDioxideDashboard' menu={<ObjectCarbonDioxideDashboard />} />

            </Route>


            <Route path={`${path}/object/all/objects/stocks`}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='objectStockDashboard' menu={<ObjectStockDashboard displayType={all} />} />
            </Route>
            <Route path={`${path}/object/multiple/objects/stocks`}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='objectStockDashboard' menu={<ObjectStockDashboard displayType={multiple} />} />
            </Route>
            <Route path={`${path}/object/:selectedObjectId/objects/stocks`}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='objectStockDashboard' menu={<ObjectStockDashboard />} />
            </Route>

            <Route path={`${path}/object/all/objects/interval-trigger`}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='objectCleaningTriggerDashboard' menu={<ObjectCleaningTriggerDashboard displayType={all} />} />
            </Route>
            <Route path={`${path}/object/multiple/objects/interval-trigger`}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='objectCleaningTriggerDashboard' menu={<ObjectCleaningTriggerDashboard displayType={multiple} />} />
            </Route>
            <Route path={`${path}/object/:selectedObjectId/objects/interval-trigger`}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='objectCleaningTriggerDashboard' menu={<ObjectCleaningTriggerDashboard />} />
            </Route>
            <Route path={`${path}/object/all/shared-folders`}>
              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='objectSharedFolderDashboard' menu={<ObjectSharedFolderDashboard displayType={all} />} />

            </Route>
            <Route path={`${path}/object/multiple/shared-folders`}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='objectSharedFolderDashboard' menu={<ObjectSharedFolderDashboard displayType={multiple} />} />
            </Route>
            <Route path={`${path}/object/:selectedObjectId/shared-folders`}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='objectSharedFolderDashboard' menu={<ObjectSharedFolderDashboard />} />
            </Route>



            <Route path={`${path}/service-worker/all/objects/sessions`}>

              <AccessToMenu drawerDisplayType={all} userAccount={userAccount} userMenuKey='serviceWorkerSession' menu={<ServiceWorkerSession displayType={all} />} />
            </Route>
            <Route path={`${path}/service-worker/multiple/objects/sessions`}>
              <AccessToMenu drawerDisplayType={multiple} userAccount={userAccount} userMenuKey='serviceWorkerSession' menu={<ServiceWorkerSession displayType={multiple} />} />
            </Route>
            <Route path={`${path}/service-worker/:selectedServiceWorkerId/objects/sessions`}>
              <AccessToMenu drawerDisplayType={single} userAccount={userAccount} userMenuKey='serviceWorkerSession' menu={<ServiceWorkerSession />} />
            </Route>

            <Route path={`${path}/gesamtverwaltung`}>
              <GroupEntireEvalManagement onUpdateDisplayGroup={onUpdateDisplayGroup} />
            </Route>
            <Route path={`${path}/admin/licenses`} >
              <GroupLicenseAdminPage />
            </Route>
            <Route path={`${path}/admin/lorasensor`} >
              <LoraSensorAdminPage />
            </Route>
            <Route path={`${path}/invoices`} component={AllInvoicesPage} >
              <AllInvoicesPage userAccount={userAccount} />
            </Route>
            <Route path={`${path}/einheiten/settings`}>
              <SettingsChooserManagement onUpdateDisplayGroup={onUpdateDisplayGroup} />
            </Route>
          </Switch>
          <Copyright />
        </main>
      </div>
      {userAccount &&
        <TermsOfUseDialog visible={!userAccount?.isTermsOfUseAccepted} title={''} onConfirmed={async () => {
          if (!userAccount) {
            enqueueSnackbar("User nicht geladen", { variant: 'error' });
            return;
          }
          let user = await api.acceptTermsOfUseForUser(userAccount, token, enqueueSnackbar);
          setUserAccount(user);
        }} onClose={() => {
          logout(history);
        }} />
      }
      <CountdownDialog visible={autoLogoutDialog} title={"Automatischer Logout"} onConfirmed={() => {
        logout(history);
      }} onClose={() => { setAutoLogoutDialog(false) }} />
      <PortalContactSupportDialog
        visible={contactSupportDialogVisible}
        setVisible={setContactSupportDialogVisible}
        token={token}
        userAccount={userAccount}
      />
      <PreFilterDialog title={t("filterSettings.selectTimePeriod")}
        onClose={() => {
          setIsPreFilterDialogVisible(false);
          setReloadDataFromApi((prev: number) => prev + 1);
        }} />
    </GlobalContext.Provider>
  </>;
}

export const drawerWidth = 370;
const theme = createTheme();
export const drawerPadding = theme.spacing(3);
export const paperPadding = theme.spacing(2);
export const maxContentWrapperPortalStyle = { maxWidth: `calc(100vw - ${drawerWidth}px - ${drawerPadding} - ${drawerPadding} - ${paperPadding} - ${paperPadding})` };
export const standardHeightVirtualTable = "1000px";

export const useStylesPortalPage = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    width: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export const AccessToMenu = ({ drawerDisplayType, userAccount, userMenuKey, menu }: { drawerDisplayType: DrawerMenuItemDisplayType, userAccount: any, userMenuKey: string, menu: any }) => {
  if (!userAccount) {
    return (
      <>
        <Typography style={{ fontWeight: 'bold', fontSize: 22 }}>User wird geladen</Typography>
        <CircularProgress size={64} />
      </>
    )
  }
  if (drawerDisplayType == DrawerMenuItemDisplayType.Single && UserMenuVisibility.hasAccessToSingle(userAccount?.userMenuVisibility[userMenuKey])) {
    return menu;
  }
  if (drawerDisplayType == DrawerMenuItemDisplayType.Multiple && UserMenuVisibility.hasAccessToMultiple(userAccount?.userMenuVisibility[userMenuKey])) {
    return menu;
  }
  if (drawerDisplayType == DrawerMenuItemDisplayType.All && UserMenuVisibility.hasAccessToAll(userAccount?.userMenuVisibility[userMenuKey])) {
    return menu;
  }
  return (
    <>
      <Typography style={{ fontWeight: 'bold', fontSize: 22 }}>Sie haben keinen Zugriff!</Typography>
      <Block color='error' style={{ fontSize: 100, fontWeight: 'bold' }} />
    </>
  );
}
