import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserAddress } from '../models/UserAddress';

export const deleteUserProperty = async (objectId: string, token: string, enqueueSnackbar: any): Promise<boolean> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.delete(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/property/${objectId}`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Merkmal Löschen erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Merkmal Löschen error", { variant: 'error' });
    }
    return null as any;
}