import { Chart, Connector, ConstantLine, Export, Font, Label, Series, Size, Subtitle, Title, ValueAxis } from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { getColorOfSmileQM } from '../dialogs/tables/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckAverageTable';
import { green } from '../../styles/colors';
import { Typography } from '@mui/material';
import React from 'react';
import { GlobalContext } from '../authorized/PortalPage';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});

export const BuildStandardBarChartSimple = (
    { dataSource, name = "QM Einheiten Gesamt", height = 800, color, extraLabel = "", valueFieldService = "valueFieldService", argumentFieldService = "argumentFieldService", avgVal = -1, title = "", subTitle = "" }:
        { dataSource: any[], name?: string, height?: number, color?: string, extraLabel?: string, valueFieldService?: string, argumentFieldService?: string, avgVal?: number, title?: string, subTitle?: string }) => {

    const { chartBarFontSize }:
        { chartBarFontSize: number } = React.useContext(GlobalContext);

    const customizeText = (pointInfo: any) => {
        return `${pointInfo.value}${extraLabel}`;
    }


    return (
        <Chart className='no-page-break' id="chart" rotated={true} dataSource={dataSource} customizePoint={color ?
            (pointInfo) => {
                return { color: color, hoverStyle: { color: color } };
            }
            :
            (pointInfo) => {
                let color = getColorOfSmileQM(pointInfo.value);
                return { color: color, hoverStyle: { color: color } };
            }} >
            {title &&
                <Title text={title}>
                    <Font color="black" />
                    {subTitle &&
                        <Subtitle text={subTitle}>
                            <Font color="black" />
                        </Subtitle>
                    }
                </Title>
            }

            <Size

                height={height}
                width={'100%'}
            />
            <Series
                valueField={valueFieldService}
                argumentField={argumentFieldService}
                name={name}

                showInLegend={false}
                type="bar" >
                <Label font={{ size: chartBarFontSize, color: 'white' }} customizeText={customizeText} visible={true}>
                    <Connector visible={true} />
                </Label>

            </Series>
            {
                avgVal > -1 &&
                <ValueAxis>
                    <ConstantLine
                        width={10}
                        value={avgVal}
                        color={green}
                    >
                        <Label font={{ size: 44, color: green, weight: 900 }} text="Ø" />
                    </ConstantLine>
                </ValueAxis>
            }
            <Export enabled={true} />
        </Chart >
    );
}

export const StandardBarChartSimpleColor = withStyles(demoStyles, { name: 'Demo' })(BuildStandardBarChartSimple);