import axios from 'axios';
import { headers } from '../api';
import { UserSavedCombinationGroup } from '../models/UserSavedCombinationGroup';


export const createUserSavedCombinationGroup = async (userSavedCombinationGroup: UserSavedCombinationGroup | undefined,token: string,enqueueSnackbar:any) : Promise<UserSavedCombinationGroup> => {
    try {
        if (!userSavedCombinationGroup) throw(null);
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userSavedCombinationGroup.userId}/create-saved-combination-group`,
        {...userSavedCombinationGroup},
        {headers: {...headers,'Authorization': `Bearer ${token}` }})
        enqueueSnackbar("User Einheit Kombination erfolgreich erstellt",{variant:'success'});
        return ret.data;
    } catch (err) {
        enqueueSnackbar("User Einheit Kombination Erstellung error",{variant:'error'});
    }
    return null as any;
}