import React, { useCallback, useRef } from 'react';
import { PaymentMethod, UserInvoice } from '../../models/UserInvoice';
import { Grid as TableGrid, PagingPanel, Table, TableHeaderRow, SearchPanel, Toolbar, ExportPanel, TableFilterRow, TableColumnVisibility, ColumnChooser, TableColumnResizing, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SearchState, SelectionState, Sorting, SortingState } from '@devexpress/dx-react-grid';
import { Button, Grid, useTheme } from '@mui/material';
import { GetApp, InfoOutlined } from '@mui/icons-material';
import { getUserIdFromStorage } from '../../helpers/common';
import { UserInvoicesTableInvoiceDetailDialog } from './UserInvoicesTableInvoiceDetailDialog';
import { CurrencyHelper } from '../../helpers/CurrencyHelper';
import { formatEuropeanDate } from '../../helpers/date-management';
import { StripePaymentMethod } from '../../models/PaymentMethod';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { saveCSVFile, saveExcelFile } from '../../helpers/tables/common';

const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveCSVFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Rechnungen');
    });


};

export const AdminInvoicesTable = ({ rows, token }: { rows: UserInvoice[]; token: string }) => {

    const theme = useTheme();
    const userId = getUserIdFromStorage();

    const [searchValue, setSearchValue] = React.useState<string>();
    const [sorting, setSorting] = React.useState<Sorting[]>([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const [selection, setSelection] = React.useState<UserInvoice>();
    const [detailDialogVisible, setDetailDialogVisible] = React.useState(false);

    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        if (exporterRef && exporterRef.current) exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const columns = [
        {
            name: UserInvoice.Columns.invoiceNumber,
            title: 'RECHNUNGSNUMMER',
            getCellValue: (row: UserInvoice) => row.invoiceNumber
        },
        {
            name: UserInvoice.Columns.date,
            title: 'RECHNUNGSDATUM',
            getCellValue: (row: UserInvoice) => formatEuropeanDate(row.date),
        },
        {
            name: UserInvoice.Columns.customerNumber,
            title: 'KUNDENNUMMER',
            getCellValue: (row: UserInvoice) => row.user.customerNumber,
        },
        {
            name: UserInvoice.Columns.responsible,
            title: 'VERANTWORTLICH',
            getCellValue: (row: UserInvoice) => "Harald Peterka",
        },
        {
            name: UserInvoice.Columns.invoiceType,
            title: 'Rechnungstyp',
            getCellValue: (row: UserInvoice) => "",
        },
        {
            name: UserInvoice.Columns.invoiceState,
            title: 'Rechnungsstatus',
            getCellValue: (row: UserInvoice) => "",
        },
        {
            name: UserInvoice.Columns.articleNumber,
            title: 'ARTIKELNUMMER',
            getCellValue: (row: UserInvoice) => row.displayItem.product.articleNumber,
        },
        {
            name: UserInvoice.Columns.description,
            title: 'Beschreibung',
            getCellValue: (row: UserInvoice) => row.displayItem.product.name,
        },
        {
            name: UserInvoice.Columns.quantity,
            title: 'MENGE',
            getCellValue: (row: UserInvoice) => row.displayItem.count,
        },
        {
            name: UserInvoice.Columns.totalCost,
            title: 'PREIS',
            getCellValue: (row: UserInvoice) => `${CurrencyHelper.toCurrencyCommaString(row.displayItem.product.unitPrice)}`
        },
        {
            name: UserInvoice.Columns.taxes,
            title: 'Steuersatz',
            getCellValue: (row: UserInvoice) => `${row.taxRate}`
        },
        {
            name: UserInvoice.Columns.discount,
            title: 'Rabatt (%)',
            getCellValue: (row: UserInvoice) => ""
        },
        {
            name: UserInvoice.Columns.shippingArticle,
            title: 'Versandkostenartikel',
            getCellValue: (row: UserInvoice) => ""
        },
        {
            name: UserInvoice.Columns.actions,
            title: 'Actions',
            getCellValue: (row: UserInvoice) => (
                <Grid container direction="row" spacing={1}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<InfoOutlined />}
                            onClick={async () => {
                                setSelection(row);
                                setDetailDialogVisible(true);
                            }}
                        >
                            Details
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<GetApp />}
                            target="_blank"
                            href={`${process.env.REACT_APP_BASIC_URI}/users/${userId}/invoices/${row.id}?code=${process.env.REACT_APP_FUNCTIONS_KEY}&authorization=Bearer ${token}`}
                        >
                            Rechnung
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    ];

    const [columnWidths, setColumnWidths]: [any, any] = React.useState(columns.map((x) => { return { columnName: x.name, width: 100 } }));
    const [tableSelection, setTableSelection]: [any, any] = React.useState([]);

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                disabled={tableSelection.length < 1}
                startIcon={<GetApp />}
                onClick={() => {
                    console.log(tableSelection);
                    tableSelection.forEach((sel: any) => {
                        let fileURL = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/invoices/${rows[sel].id}?code=${process.env.REACT_APP_FUNCTIONS_KEY}&authorization=Bearer ${token}`;
                        let fileName = ""
                        var save: any = document.createElement('a');
                        save.href = fileURL;
                        save.target = '_blank';
                        var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
                        save.download = fileName || filename;
                        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                            document.location = save.href;
                            // window event not working here
                        } else {
                            var evt = new MouseEvent('click', {
                                'view': window,
                                'bubbles': true,
                                'cancelable': false
                            });
                            save.dispatchEvent(evt);
                            (window.URL || window.webkitURL).revokeObjectURL(save.href);
                        }
                    });
                }}
            >
                Ausgewählte Rechnungen Downloaden
            </Button>
            <TableGrid
                rows={rows}
                columns={columns}
            >
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                />
                <IntegratedSorting />

                <SearchState
                    value={searchValue}
                    onValueChange={setSearchValue}
                />
                <SelectionState selection={tableSelection} onSelectionChange={setTableSelection} />
                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering />
                <IntegratedSelection />
                <Table
                    columnExtensions={[
                        { columnName: UserInvoice.Columns.number, align: 'left' },
                        { columnName: UserInvoice.Columns.date, align: 'left' },
                        { columnName: UserInvoice.Columns.totalCost, align: 'left' },
                        { columnName: UserInvoice.Columns.actions, align: 'right' },
                    ]}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                />

                <TableHeaderRow showSortingControls />
                <TableSelection showSelectAll />
                <TableColumnVisibility
                    defaultHiddenColumnNames={[]}
                />
                <TableFilterRow />
                <Toolbar />
                <ColumnChooser />
                <SearchPanel />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                />
                <PagingPanel
                    pageSizes={[5, 10, 15, 0]}
                />
                <ExportPanel startExport={startExport} />
            </TableGrid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns.filter(x => x.name != "actions")}
                selection={tableSelection}
                onSave={onSave}
            />

            <UserInvoicesTableInvoiceDetailDialog
                visible={detailDialogVisible}
                setVisible={setDetailDialogVisible}
                invoice={selection as UserInvoice}
            />
        </div>
    );
};
