import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, Grid, IconButton, TextField, Toolbar, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useContext, useRef } from "react";
import { api } from "../../../api";
import { getApiToken } from "../../../helpers/storage-management";
import { formatGroupLicenseTable, formatLicenses } from "../../../helpers/tables/licenseGenerator";
import { GroupLicenseTable } from "../../../models/GroupLicenseTable";
import { GroupLicense } from "../../../models/GroupLicense";
import { useHistory } from "react-router-dom";
import LicenseSvg from '../../../assets/svgs/license.svg'
import RunFastSvg from '../../../assets/svgs/run-fast.svg'
import LockWhite from '../../../assets/svgs/lock_white.svg'
import { lightGreen, red, yellow } from "../../../styles/colors";
import { green } from "../../../styles/colors";
import { getUserIdFromStorage } from "../../../helpers/common";
import { SimplePanel, SimplePanelRouteType } from "../../common/SimplePanel";
import { GroupCombinationSelection } from "../../tables/GroupCombinationSelection";
import { Add, CalendarMonth, Close, CloudUpload, DashboardCustomize, DashboardOutlined, Details, EventAvailable, ExpandMore, GridView, MergeType, Save, Visibility } from "@mui/icons-material";
import { handleSelectedCombinationGroup, handleSelectedCombinationGroupGridStackCustom } from "../../tables/Base";
import { UserSavedCombinationGroup } from "../../../models/UserSavedCombinationGroup";
import { useSnackbar } from "notistack";
import { ReactComponent as CheckMeNowIcon } from '../../../assets/svgs/check-me-now_icon.svg'
import { GroupCombinationDialog } from "../../dialogs/GroupCombination";
import { ConfirmationDialog } from "../../dialogs/ConfirmationDialog";
import { NetworkImage } from "../../images/NetworkImage";
import { GridStackSelectionDialog } from "../../dialogs/dashboard/GridStackSelectionDialog";
import { GridStackCustom } from "../../../models/GridStackCustom";
import { GLOBAL_DATE_CHARTS, GLOBAL_GRID_STACK_CUSTOMS, GLOBAL_PERIOD_CHART_FILTER, GLOBAL_START_DATE_CHARTS } from "../../../constants";
import { GlobalContext } from "../PortalPage";
import { GridStackCustomManagementPageEditingDialog } from "./Dashboard/customDashboard/dialog/GridStackCustomManagementPageEditingDialog";
import { SetPeriodForGroupCombinationDialog } from "../../dialogs/dashboard/SetPeriodForGroupCombinationDialog";
import { setDefaultEndDate, setDefaultStartDate } from "../../../helpers/date-management";
import { setIncludeHourFilter } from "../../../helpers/charts/base";
import { useTranslation } from "react-i18next";

export const Dashboard = ({ selectedGroupId, selectedCombinationGroup, setSelectedCombinationGroup }
    : { selectedGroupId: string, selectedCombinationGroup: GroupLicenseTable[], setSelectedCombinationGroup: any }) => {

    const [token, setToken] = React.useState("");
    const [licenses, setLicenses] = React.useState<GroupLicense[]>([]);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [savedCombinationGroups, setSavedCombinationGroups] = React.useState<UserSavedCombinationGroup[]>([]);
    const history = useHistory();
    const theme = useTheme();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const { enqueueSnackbar } = useSnackbar();

    const [selection, setSelection] = React.useState<string[]>([]);
    const [name, setName] = React.useState("");
    const [currentDetails, setCurrentDetails] = React.useState<any[]>([]);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [currentSelectedCombinationForDelete, setCurrentSelectedCombinationForDelete] = React.useState<UserSavedCombinationGroup>();
    const [userId, setUserId] = React.useState("");
    const [loadingSavedCombGroups, setLoadingSavedCombGroups] = React.useState(false);
    const [loadingGroups, setLoadingGroups] = React.useState(false);
    const { gridStackCustoms, setGridStackCustoms, setPeriodChartFilter }: { gridStackCustoms: GridStackCustom[], setGridStackCustoms: React.Dispatch<React.SetStateAction<GridStackCustom[]>>, setPeriodChartFilter: any } = useContext(GlobalContext);


    const [selectedGridCustom, setSelectedGridStackCustom] = React.useState<GridStackCustom>();
    const [editSettingDialogVisible, setEditSettingDialogVisible] = React.useState(false);

    const [isGridStackSelectionDialogVisible, setIsGridStackSelectionDialogVisible] = React.useState(false);
    const [currentSavedCombinationGroups, setCurrentsavedCombinationGroups] = React.useState<UserSavedCombinationGroup>();

    const [isSetPeriodForGroupCombinationDialogVisible, setIsSetPeriodForGroupCombinationDialogVisible] = React.useState(false);
    //servirces data

    const { setIncludeFilterHours }: { setIncludeFilterHours: any } = useContext(GlobalContext);

    const { t } = useTranslation();


    React.useEffect(() => {
        (async () => {
            let userId = getUserIdFromStorage();
            if (userId) {
                setUserId(userId);
            }
            let token = getApiToken(history);
            if (!token) return;
            setToken(token);
            setLoadingGroups(true);
            let tempGroups = await api.getAllGroups(token);
            let resGroups = formatGroupLicenseTable(tempGroups);
            setGroups(resGroups);
            setLoadingGroups(false);
            setLoadingSavedCombGroups(true);
            let tempSaved = await api.getUserSavedCombinationGroupsByUserId(token);
            setSavedCombinationGroups(tempSaved);
            setLoadingSavedCombGroups(false);
            let temp = await api.getLicensesAsync(token);
            temp = formatLicenses(temp);
            setLicenses(temp.filter(el => !el.isUsed));



        })();
    }, []);

    const [openGroupCombinationDialog, setOpenGroupCombinationDialog] = React.useState(false);

    const buildGridPage = () => {
        return (
            <Grid
                container
                spacing={2}
            >
                <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} md={6} lg={3}>

                        <SimplePanel
                            simplePanelRouteType={SimplePanelRouteType.LicenseManagement}
                            color={green}
                            svgIcon={LicenseSvg}
                            title={t("dashboard.activatedUnits")}
                            result={groups.length}
                            selectedGroupId={selectedGroupId}
                            selectedCombinationGroup={selectedCombinationGroup} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <SimplePanel
                            simplePanelRouteType={SimplePanelRouteType.LicenseManagement}
                            color={yellow}
                            svgIcon={LicenseSvg}
                            title={t("dashboard.unassignedLicenses")}
                            result={licenses.filter(el => el.purchaseTimestamp).length}
                            selectedGroupId={selectedGroupId}
                            selectedCombinationGroup={selectedCombinationGroup} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <SimplePanel
                            simplePanelRouteType={SimplePanelRouteType.LicenseManagement}
                            color={red}
                            svgIcon={LockWhite}
                            title={t("dashboard.expiredLicenses")}
                            result={groups.filter(el => el.deviceLicenseIsExpired || el.adLicenseIsExpired || el.qualityCheckLicenseIsExpired).length}
                            selectedGroupId={selectedGroupId}
                            selectedCombinationGroup={selectedCombinationGroup} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <SimplePanel
                            simplePanelRouteType={SimplePanelRouteType.LicenseManagement}
                            color={green}
                            svgIcon={RunFastSvg}
                            title={t("dashboard.activeLicenses")}
                            result={groups.filter(el => !el.deviceLicenseIsExpired).length}
                            selectedGroupId={selectedGroupId}
                            selectedCombinationGroup={selectedCombinationGroup} />
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Accordion >
                        <AccordionSummary onClick={() => { }} expandIcon={<ExpandMore />}                    >
                            <Grid container spacing={3} alignItems="center">
                                <Grid item>
                                    <Typography component="h4" variant="h4">{t("dashboard.createUnitCombination")}</Typography>
                                </Grid>
                                <Grid item>
                                    {loadingGroups && <CircularProgress size={128} />}
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column">
                                <Grid item container spacing={3} alignItems="center" direction="row">
                                    <Grid item>
                                        <TextField
                                            style={{ width: 250 }}
                                            label={t("dashboard.combinationName")}
                                            onChange={(event: any) => {
                                                setName(event.target.value);
                                            }}
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={selection.length < 1 || name.length < 1}
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            endIcon={<Save />}
                                            onClick={async () => {
                                                if (userId) {
                                                    console.log(selection);
                                                    let newItem = new UserSavedCombinationGroup("", userId, selection.map(el => el), name);
                                                    newItem = await api.createUserSavedCombinationGroup(newItem, token, enqueueSnackbar);
                                                    setSavedCombinationGroups([...savedCombinationGroups, newItem]);
                                                }
                                            }}
                                        >
                                            {t("dashboard.saveUnitCombination")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={selection.length < 1}
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            endIcon={<MergeType />}
                                            onClick={() => {
                                                handleSelectedCombinationGroup(selection, groups, setSelectedCombinationGroup, history);
                                            }}
                                        >
                                            {t("dashboard.combineUnits")}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <GroupCombinationSelection
                                        groups={groups}
                                        selection={selection}
                                        setSelection={setSelection}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </Grid>

                <Grid item xs={12}>
                    <Accordion expanded={true}>
                        <AccordionSummary onClick={() => { }} expandIcon={<ExpandMore />}                    >
                            <Typography component="h4" variant="h4">{t("dashboard.myUnitCombinations")}</Typography>
                            {loadingSavedCombGroups && <CircularProgress size={128} />}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="row" spacing={3}>
                                {savedCombinationGroups.map(el => {
                                    return (
                                        <Grid item lg={4}>
                                            <Card>
                                                <AppBar position="sticky">
                                                    <Toolbar>
                                                        <Typography variant="h5">
                                                            {t("dashboard.combination")}: {el.name}
                                                        </Typography>
                                                        <div style={{ flexGrow: 1 }}></div>
                                                        <Tooltip title="Zeitraum festsetzen">
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={async () => {
                                                                    setCurrentsavedCombinationGroups(el);
                                                                    setIsSetPeriodForGroupCombinationDialogVisible(true);
                                                                }}
                                                                size="large">
                                                                {el.isCustomDateEnabled ? <EventAvailable style={{ color: lightGreen }} /> : <CalendarMonth />}
                                                            </IconButton>
                                                        </Tooltip>

                                                        {el.customDashboardId &&
                                                            <Tooltip title="Aktuelles Dashboard anzeigen">
                                                                <IconButton
                                                                    color="inherit"
                                                                    onClick={async () => {
                                                                        const currentGridStack = gridStackCustoms.find(x => x.id == el.customDashboardId);
                                                                        setSelectedGridStackCustom(currentGridStack);
                                                                        setEditSettingDialogVisible(true);
                                                                    }}
                                                                    size="large">
                                                                    <GridView />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        <Tooltip title="Eigenes Dashboard zuweisen">
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={async () => {
                                                                    if (gridStackCustoms.length < 1) {
                                                                        const tempgridStackCustoms = await api.getUsergridStackCustoms(token);
                                                                        setGridStackCustoms(tempgridStackCustoms);
                                                                        localStorage.setItem(GLOBAL_GRID_STACK_CUSTOMS, JSON.stringify(tempgridStackCustoms))
                                                                    }
                                                                    setIsGridStackSelectionDialogVisible(true);
                                                                    setCurrentsavedCombinationGroups(el);
                                                                }}
                                                                size="large">
                                                                <DashboardCustomize />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Logo Upload">
                                                            <IconButton color="inherit" component="label" size="large">
                                                                <CloudUpload />
                                                                <input
                                                                    type="file"
                                                                    name="updateLogo"
                                                                    onChange={async (event: any) => {
                                                                        if (el) {
                                                                            const file = event.target.files[0];
                                                                            const newUserSavedCombinationGroup = await api.updateUserSavedCombinationGroupLogo(file, el.id, token, enqueueSnackbar);
                                                                            el.logo = newUserSavedCombinationGroup.logo;
                                                                            setSavedCombinationGroups(savedCombinationGroups.map(el => el));
                                                                        }
                                                                    }}
                                                                    hidden
                                                                    accept="image/png, image/jpeg, image/jpg" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="Einheiten Anzeigen">
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={async () => {
                                                                    setCurrentDetails(groups.filter(item => el.groupIds.some(x => x == item.id)).map(x => { return { name: x.notificationName } }));
                                                                    setOpenGroupCombinationDialog(true);
                                                                }}
                                                                size="large">
                                                                <Visibility />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Kombination Löschen">
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={async () => {
                                                                    setCurrentSelectedCombinationForDelete(el);
                                                                    setDeleteConfirmationDialogVisible(true);
                                                                }}
                                                                size="large">
                                                                <Close />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Toolbar>
                                                </AppBar>
                                                <CardActionArea style={{ textAlign: 'center' }} onClick={() => {
                                                    if (el.isCustomDateEnabled) {
                                                        localStorage.setItem(GLOBAL_DATE_CHARTS, el.endDateEval as any);
                                                        setDefaultEndDate(new Date(el.endDateEval));
                                                        localStorage.setItem(GLOBAL_START_DATE_CHARTS, el.startDateEval as any);
                                                        setDefaultStartDate(new Date(el.startDateEval));
                                                        setIncludeHourFilter(el.includeHours);
                                                        setIncludeFilterHours(el.includeHours);
                                                        let periodChartFilter = localStorage.setItem(GLOBAL_PERIOD_CHART_FILTER, el.periodChartFilterType.toString());
                                                        setPeriodChartFilter(el.periodChartFilterType.toString());
                                                    }


                                                    const currentGridStack = gridStackCustoms.find(x => x.id == el.customDashboardId);
                                                    if (currentGridStack) {
                                                        handleSelectedCombinationGroupGridStackCustom(el.groupIds, groups, setSelectedCombinationGroup, history, currentGridStack);
                                                    } else {
                                                        handleSelectedCombinationGroup(el.groupIds, groups, setSelectedCombinationGroup, history);
                                                    }
                                                }}>
                                                    {el && el.logo && el.logo.fileName ?
                                                        <div style={{ height: 140 }}>
                                                            <NetworkImage src={`${process.env.REACT_APP_BASIC_URI}/users/${userId}/saved-combination-group/${el.id}/images/logos/${el.logo?.fileName}`} />
                                                        </div> :
                                                        <CheckMeNowIcon height={140}></CheckMeNowIcon>
                                                    }
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            Gespeicherte Einheit-Kombination <br></br> <b>{el.name}</b> verwenden
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Klick mich, um zur Kombination zu navigieren! <br></br>
                                                            Kombination besteht aus <b>{el.groupIds.length} Einheiten</b>
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    );
                                })
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </Grid>
                <GridStackSelectionDialog open={isGridStackSelectionDialogVisible} setOpen={setIsGridStackSelectionDialogVisible} onRefresh={async (gridStackCustom: GridStackCustom) => {
                    if (currentSavedCombinationGroups && gridStackCustom) {
                        currentSavedCombinationGroups.customDashboardId = gridStackCustom.id;
                        await api.createUserSavedCombinationGroup(currentSavedCombinationGroups, token, enqueueSnackbar);
                    }
                }} gridStackCustoms={gridStackCustoms} />
                <GroupCombinationDialog
                    open={openGroupCombinationDialog}
                    setOpen={setOpenGroupCombinationDialog}
                    items={currentDetails}
                />
                {currentSelectedCombinationForDelete &&
                    <ConfirmationDialog
                        visible={deleteConfirmationDialogVisible}
                        title={`Sind Sie sicher, die Einheit-Kombination ${currentSelectedCombinationForDelete.name} zu löschen ?`}
                        onClose={() => {
                            setDeleteConfirmationDialogVisible(false);
                        }}
                        onConfirmed={async () => {
                            if (currentSelectedCombinationForDelete.logo && currentSelectedCombinationForDelete.logo.fileName) await api.deleteLogoUserSavedCombinationGroup(currentSelectedCombinationForDelete, token, enqueueSnackbar);
                            await api.deleteUserSavedCombinationGroup(currentSelectedCombinationForDelete, token, enqueueSnackbar);
                            setSavedCombinationGroups(savedCombinationGroups.filter(item => item.id != currentSelectedCombinationForDelete.id));
                            setDeleteConfirmationDialogVisible(false);
                        }}
                    />
                }

                <GridStackCustomManagementPageEditingDialog
                    visible={editSettingDialogVisible}
                    setVisible={setEditSettingDialogVisible}
                    gridStackCustom={selectedGridCustom}
                    onSave={async (object: GridStackCustom) => {


                        const updated = await api.putUserGridStackCustom(object, token, enqueueSnackbar);

                        if (!updated) return;

                        setGridStackCustoms(p => p.map((item: GridStackCustom) => item.id === updated.id ? updated : item));

                    }}
                />
                {currentSavedCombinationGroups &&
                    <SetPeriodForGroupCombinationDialog groupCombination={currentSavedCombinationGroups} open={isSetPeriodForGroupCombinationDialogVisible} setOpen={setIsSetPeriodForGroupCombinationDialogVisible} onRefresh={async (startDate: Date, endDate: Date, isCustomDateEnabled: boolean, includeHours: boolean, periodChartFilterType: string) => {
                        if (currentSavedCombinationGroups) {
                            currentSavedCombinationGroups.startDateEval = startDate;
                            currentSavedCombinationGroups.endDateEval = endDate;
                            currentSavedCombinationGroups.isCustomDateEnabled = isCustomDateEnabled;
                            currentSavedCombinationGroups.includeHours = includeHours;
                            currentSavedCombinationGroups.periodChartFilterType = parseInt(periodChartFilterType);
                            let newItem = await api.createUserSavedCombinationGroup(currentSavedCombinationGroups, token, enqueueSnackbar);
                            currentSavedCombinationGroups.systemETag = newItem.systemETag;
                        }
                    }} />
                }



            </Grid >
        );
    }

    return (
        buildGridPage()
    );
}



