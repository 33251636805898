import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupServiceMode } from '../models/GroupServiceMode';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserAddress } from '../models/UserAddress';
import { UserProperty } from '../models/UserProperty';

export const CreateGroupServiceModeManual = async (groupServiceMode: GroupServiceMode | undefined, token: string, enqueueSnackbar: any): Promise<GroupServiceMode> => {
    try {
        if (!groupServiceMode) throw (null);
        let userId = getUserIdFromStorage();
        groupServiceMode.userId = userId || "";
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/groups/${groupServiceMode?.groupId}/users/${userId}/create-service-mode-manual`,
            { ...groupServiceMode },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Service Mode Speichern erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Service Mode Speichern error", { variant: 'error' });
    }
    return null as any;
}