import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const telegramMakeAuthAsync = async (phoneNumber: string, phoneCodeHash: string, code: string, token: any, enqueueSnackbar: any): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();

        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/telegram/make-auth`, { phoneNumber: phoneNumber, phoneCodeHash: phoneCodeHash, code: code },
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
        enqueueSnackbar("Code Authorisierung erfolgreich!", { variant: 'success' });
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar, "Code authorisierung fehlgeschlagen");
    }
    return null as any;
}