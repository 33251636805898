import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { Product } from '../../../../../models/Product';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { GroupSettings } from '../../../../../models/GroupSettings';
import { formatEuropeanDateTime, formatUnixSecTimestampEuropeanDateInMinutes, formatUnixSecToDate, formatUnixSecToTime } from '../../../../../helpers/date-management';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { GroupMotion } from '../../../../../models/GroupMotion';
import { CheckboxCell, FilterCellCheck } from '../../../../tables/ServiceModeTable';
import { LoraGroupMotion } from '../../../../../models/LoraGroupMotion';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { Button } from '@mui/material';
import { ForwardToInbox } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const LoraMotionDataTable = ({ rows, groups, selectedEntries, onSelectionChange, }
    : { rows: LoraGroupMotion[], groups: GroupLicenseTable[], selectedEntries: LoraGroupMotion[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);

    const {t} = useTranslation();

    const columns = [
        {
            name: LoraGroupMotion.Columns.activity,
            title: "Aktiv",
            getCellValue: (row: LoraGroupMotion) => row.activity ? t("myUnitsSettings.yes") : t("myUnitsSettings.no"),
        },
        {
            name: LoraGroupMotion.Columns.blockCount,
            title: "Nutzungsdauer",
            getCellValue: (row: LoraGroupMotion) => row.blockCount,
        },
        {
            name: LoraGroupMotion.Columns.motionCount,
            title: "Gesamtnutzungsdauer",
            getCellValue: (row: LoraGroupMotion) => row.motionCount,
        },
        {
            name: LoraGroupMotion.Columns.sensorRoomName,
            title: "Raum Name",
            getCellValue: (row: LoraGroupMotion) => row.sensorRoomName,
        },
        {
            name: LoraGroupMotion.Columns.sentBySensorTimestamp,
            title: "Gesendet von Sensor um",
            getCellValue: (row: LoraGroupMotion) => formatEuropeanDateTime(row.sentBySensorTimestamp),
        },
        {
            name: LoraGroupMotion.Columns.createdTimestamp,
            title: "Datum",
            getCellValue: (row: LoraGroupMotion) => formatUnixSecToDate(row.createdTimestamp),
        },
        {
            name: LoraGroupMotion.Columns.createdTimestampTime,
            title: "Zeitpunkt",
            getCellValue: (row: LoraGroupMotion) => formatUnixSecToTime(row.createdTimestamp),
        },
        {
            name: LoraGroupMotion.Columns.sensorId,
            title: "Sensor ID",
            getCellValue: (row: LoraGroupMotion) => row.sensorId,
        },
        {
            name: LoraGroupMotion.Columns.groupId,
            title: t("serviceDocumentationTable.unitName"),
            getCellValue: (row: LoraGroupMotion) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: LoraGroupMotion.Columns.rssi,
            title: "Signalstärke (rssi)",
            getCellValue: (row: LoraGroupMotion) => row.rssi,
        },
        {
            name: LoraGroupMotion.Columns.snr,
            title: "Snr (Signal to noise ratio)",
            getCellValue: (row: LoraGroupMotion) => row.snr + " dB",
        },
        {
            name: LoraGroupMotion.Columns.seqno,
            title: "Gateway Seqno",
            getCellValue: (row: LoraGroupMotion) => row.seqno,
        },
        {
            name: LoraGroupMotion.Columns.macAddressGateway,
            title: "Mac-Addresse-Gateway",
            getCellValue: (row: LoraGroupMotion) => row.macAddressGateway,
        },
        {
            name: LoraGroupMotion.Columns.fcnt,
            title: "Framecount (Sensor seqno)",
            getCellValue: (row: LoraGroupMotion) => row.fcnt,
        },
        {
            name: LoraGroupMotion.Columns.humidity,
            title: "Luftfeuchtigkeit",
            getCellValue: (row: LoraGroupMotion) => row.humidity,
        },
        {
            name: LoraGroupMotion.Columns.temperature,
            title: "Temperatur",
            getCellValue: (row: LoraGroupMotion) => row.temperature,
        },
        {
            name: LoraGroupMotion.Columns.toa,
            title: "Time of Air in Sekunden(toa)",
            getCellValue: (row: LoraGroupMotion) => row.toa,
        },
        {
            name: LoraGroupMotion.Columns.motionEvent,
            title: "Auslösung durch Motion",
            getCellValue: (row: LoraGroupMotion) => row.motionEvent ? t("myUnitsSettings.yes") : t("myUnitsSettings.no"),
        },
        {
            name: LoraGroupMotion.Columns.pushEvent,
            title: "Auslösung durch Sensorbutton",
            getCellValue: (row: LoraGroupMotion) => row.pushEvent ? t("myUnitsSettings.yes") : t("myUnitsSettings.no"),
        },
        {
            name: LoraGroupMotion.Columns.isSummarizedMonth,
            title: "Zusammengefasst",
            getCellValue: (row: LoraGroupMotion) => row.isSummarizedMonth,
        },
        {
            name: LoraGroupMotion.Columns.summarizedFrom,
            title: "Start Zusammenfassung",
            getCellValue: (row: LoraGroupMotion) => row.isSummarizedMonth ? formatEuropeanDateTime(row.summarizedFrom) : "nicht zusammengefasst",
        },
        {
            name: LoraGroupMotion.Columns.summarizedUntil,
            title: "Ende Zusammenfassung",
            getCellValue: (row: LoraGroupMotion) => row.isSummarizedMonth ? formatEuropeanDateTime(row.summarizedUntil) : "nicht zusammengefasst",
        },
        {
            name: LoraGroupMotion.Columns.serviceBusDeliveryCount,
            title: "ServiceBus Delivery Count",
            getCellValue: (row: LoraGroupMotion) => row.serviceBusDeliveryCount,
        },
        {
            name: LoraGroupMotion.Columns.serviceBusEnqueuedTime,
            title: "ServiceBus EnqueuedTime",
            getCellValue: (row: LoraGroupMotion) => formatEuropeanDateTime(row.serviceBusEnqueuedTime),
        },
        {
            name: LoraGroupMotion.Columns.serviceBusMessageId,
            title: "ServiceBus MessageId",
            getCellValue: (row: LoraGroupMotion) => row.serviceBusMessageId,
        },
        {
            name: LoraGroupMotion.Columns.serviceBusSequenceNumber,
            title: "ServiceBus SequenceNumber",
            getCellValue: (row: LoraGroupMotion) => row.serviceBusSequenceNumber,
        },

    ];

    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Frequenz');
        });
    };

    const Cell = (props: any) => {
        const { column, row } = props;
        if (column.name === LoraGroupMotion.Columns.isSummarizedMonth) {
            return <CheckboxCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    const FilterCell = (props: any) => {
        const { column } = props;
        if (column.name === LoraGroupMotion.Columns.isSummarizedMonth) {
            return <FilterCellCheck {...props} />;
        }
        return <TableFilterRow.Cell {...props} />;
    };

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 180 } }));
    const [defaultHiddenColumnNames] = React.useState([]);

    const serviceBusColumns = React.useMemo(() => [LoraGroupMotion.Columns.serviceBusSequenceNumber, LoraGroupMotion.Columns.serviceBusMessageId,
    LoraGroupMotion.Columns.serviceBusEnqueuedTime, LoraGroupMotion.Columns.serviceBusDeliveryCount], []);
    const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>([...serviceBusColumns]);
    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //            sorting={[{ columnName: LoraGroupMotion.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <TableFilterRow
                    cellComponent={FilterCell}
                />
                <TableColumnVisibility
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >
                    <TemplatePlaceholder />
                    {!hiddenColumnNames.some(column => serviceBusColumns.includes(column)) ?
                        <Button color='error' onClick={() => {
                            setHiddenColumnNames([...hiddenColumnNames, ...serviceBusColumns]);
                        }} variant="contained" endIcon={<ForwardToInbox />}>
                            Hide ServiceBus Columns
                        </Button>
                        :
                        <Button onClick={() => {
                            setHiddenColumnNames([...hiddenColumnNames.filter(column => !serviceBusColumns.includes(column))]);
                        }} variant="contained" endIcon={<ForwardToInbox />}>
                            Show ServiceBus Columns
                        </Button>
                    }
                </Template>
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
                <ExportPanel startExport={startExport} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
