import React, { useCallback, useRef, useState } from 'react';
import { DataTypeProvider, FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, IntegratedSummary, PagingState, SelectionState, SortingState, SummaryState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, TableSummaryRow, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Paper, Typography } from '@mui/material';
import { formatUnixSecToDate, formatUnixSecToTime, formatEuropeanDateTime } from '../../../helpers/date-management';
import { saveExcelFile } from '../../../helpers/tables/common';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { Product } from '../../../models/Product';
import { CheckboxCell, FilterCellCheck } from '../ServiceModeTable';
import { FeedbackTotalTable } from '../../../models/GroupFeedback';
import { getSmileForSumFeedbackTable } from '../../../helpers/charts/objectLeader/dataGenerator';

export const AvgTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={(value: any) => {
            return Math.floor(value.value) + "%";
        }}
        {...props}
    />
);

export const FeedbackTotalTableComponent = ({ rows, groups, selectedEntries, onSelectionChange, }
    : { rows: FeedbackTotalTable[], groups: GroupLicenseTable[], selectedEntries: FeedbackTotalTable[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);
    const [sorting, setSorting] = useState([]);


    const getAvgOfFeedback = React.useCallback((total: number, feedbackCount: number) => {
        if (feedbackCount < 1 || total < 1) {
            return 0;
        }
        return Math.floor((feedbackCount / total) * 100);
    }, [])

    const columns = [
        {
            name: FeedbackTotalTable.Columns.groupId,
            title: "Einheit",
            getCellValue: (row: FeedbackTotalTable) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: FeedbackTotalTable.Columns.levelName,
            title: "Ebene",
            getCellValue: (row: FeedbackTotalTable) => groups.find(x => x.id == row.groupId)?.levelName,
        },
        {
            name: FeedbackTotalTable.Columns.objectName,
            title: "Objekt",
            getCellValue: (row: FeedbackTotalTable) => groups.find(x => x.id == row.groupId)?.objectName,
        },
        {
            name: FeedbackTotalTable.Columns.veryPositiveAmount,
            title: "Sehr Positiv",
            getCellValue: (row: FeedbackTotalTable) => row.veryPositiveAmount,
        },
        {
            name: FeedbackTotalTable.Columns.positiveAmount,
            title: "Positiv",
            getCellValue: (row: FeedbackTotalTable) => row.positiveAmount,
        },
        {
            name: FeedbackTotalTable.Columns.neutralAmount,
            title: "Neutral",
            getCellValue: (row: FeedbackTotalTable) => row.neutralAmount,
        },
        {
            name: FeedbackTotalTable.Columns.negativeAmount,
            title: "Negativ",
            getCellValue: (row: FeedbackTotalTable) => row.negativeAmount,
        },
        {
            name: FeedbackTotalTable.Columns.veryNegativeAmount,
            title: "Sehr Negativ",
            getCellValue: (row: FeedbackTotalTable) => row.veryNegativeAmount,
        },
        {
            name: FeedbackTotalTable.Columns.totalAmount,
            title: "Total",
            getCellValue: (row: FeedbackTotalTable) => row.totalAmount,
        },
        {
            name: FeedbackTotalTable.Columns.veryPositiveAmountPercent,
            title: "Sehr Positiv Prozentanteil",
            getCellValue: (row: FeedbackTotalTable) => getAvgOfFeedback(row.totalAmount, row.veryPositiveAmount),
        },
        {
            name: FeedbackTotalTable.Columns.positiveAmountPercent,
            title: "Positiv Prozentanteil",
            getCellValue: (row: FeedbackTotalTable) => getAvgOfFeedback(row.totalAmount, row.positiveAmount),
        },
        {
            name: FeedbackTotalTable.Columns.neutralAmountPercent,
            title: "Neutral Prozentanteil",
            getCellValue: (row: FeedbackTotalTable) => getAvgOfFeedback(row.totalAmount, row.neutralAmount),
        },
        {
            name: FeedbackTotalTable.Columns.negativeAmountPercent,
            title: "Negativ Prozentanteil",
            getCellValue: (row: FeedbackTotalTable) => getAvgOfFeedback(row.totalAmount, row.negativeAmount),
        },
        {
            name: FeedbackTotalTable.Columns.veryNegativeAmountPercent,
            title: "Sehr Negativ Prozentanteil",
            getCellValue: (row: FeedbackTotalTable) => getAvgOfFeedback(row.totalAmount, row.veryNegativeAmount),
        },
    ];

    const percentCols = [FeedbackTotalTable.Columns.veryPositiveAmountPercent, FeedbackTotalTable.Columns.positiveAmountPercent,
    FeedbackTotalTable.Columns.neutralAmountPercent, FeedbackTotalTable.Columns.negativeAmountPercent,
    FeedbackTotalTable.Columns.veryNegativeAmountPercent];

    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (percentCols.includes(el.name)) {
            return { columnName: el.name, width: 250 }
        }
        return { columnName: el.name, width: 150 }
    }));
    const [defaultHiddenColumnNames] = React.useState([FeedbackTotalTable.Columns.veryPositiveAmountPercent,FeedbackTotalTable.Columns.veryNegativeAmountPercent]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Feedback');
        });
    };

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === FeedbackTotalTable.Columns.positiveAmountPercent) {
            return (
                <Table.Cell {...props}>
                    <div style={{ textAlign: 'center', verticalAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Typography style={{marginRight: 10}}>{value}%  </Typography>
                    {getSmileForSumFeedbackTable(value, 40)}
                    </div>
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };

    const [totalSummaryItems] = useState([
        { columnName: FeedbackTotalTable.Columns.totalAmount, type: 'sum', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.veryNegativeAmount, type: 'sum', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.negativeAmount, type: 'sum', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.neutralAmount, type: 'sum', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.positiveAmount, type: 'sum', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.veryPositiveAmount, type: 'sum', alignByColumn: true },

        { columnName: FeedbackTotalTable.Columns.veryPositiveAmountPercent, type: 'avg', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.positiveAmountPercent, type: 'avg', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.neutralAmountPercent, type: 'avg', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.negativeAmountPercent, type: 'avg', alignByColumn: true },
        { columnName: FeedbackTotalTable.Columns.veryNegativeAmountPercent, type: 'avg', alignByColumn: true },


    ]);

    return (
        <Paper style={{}}>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting as any}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <AvgTypeProvider
                    for={percentCols}
                />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <IntegratedSummary />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table cellComponent={Cell} />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableSummaryRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow
                />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll selectByRowClick />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
                totalSummaryItems={totalSummaryItems}
            />
        </Paper>
    );
};
