import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle, Button, CircularProgress, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, AddBox, Close, Delete, ManageAccounts, Publish, RowingSharp, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { SsdUnregisteredDevicesTable } from '../bssdHub/SsdUnregisteredDevicesTable';
import { DeviceType, SsdDeviceModel } from '../../../../models/bssd/SsdDeviceModel';
import { SsdRegisteredDevicesTable } from './SsdRegisteredDevicesTable';
import { BssdRegisteredDeviceModel } from '../../../../models/bssd/BssdRegisteredDeviceModel';
import { AddRegisteredSensorToBatteryDisplaySelectionDialog } from './AddRegisteredSensorToBatteryDisplaySelectionDialog';
import { useTranslation } from 'react-i18next';

export const RegisteredDevicesSelectionDialog = ({
  open,
  setOpen,
  onFinish,
  devices,
  assignBssdSensorLicense,
  assignBssdDisplayLicense,
  groups,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  onFinish: any,
  devices: BssdRegisteredDeviceModel[],
  assignBssdSensorLicense: any,
  assignBssdDisplayLicense: any,
  groups: GroupLicenseTable[],
  onRefresh: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [token, setToken] = React.useState("");
  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);

  const handleClose = () => setOpen(false);
  const [openAddSensorDialog, setOpenAddSensorDialog] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const {t} = useTranslation();

  const closeFromSensorAdd = async () => {
    setOpenAddSensorDialog(false);
    setLoading(true);
    await onRefresh();
    setLoading(false);
  }

  const [chosenDeviceType, setChosenDeviceType] = React.useState(DeviceType.Display);


  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

    })();

  }, [open]);

  const disabled = selectedGroups.length < 1;
  const [selection, setSelection] = React.useState<any[]>([]);

  const filterDevice = React.useCallback((devices: BssdRegisteredDeviceModel[], deviceType: DeviceType) => {
    switch (deviceType) {
      case DeviceType.Display:
        return devices.filter(x => x.deviceType == DeviceType.Display)
      case DeviceType.Sensor:
        return devices.filter(x => x.deviceType == DeviceType.Sensor)
    }
  }, []);

  const buildVerifyButton = React.useCallback((chosenDeviceType: DeviceType, selection: any[]) => {
    switch (chosenDeviceType) {
      case DeviceType.Display:
        return (
          <Button
            size="large"
            startIcon={<AddBox />}
            disabled={selection.length < 1}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              setLoading(true);
              assignBssdDisplayLicense(selection[0]);
              setLoading(false);
            }}
          >
            Battery Smart Service Display als Einheit aktivieren!
          </Button>
        );
      case DeviceType.Sensor:
        return (
          <Button
            size="large"
            startIcon={<AddBox />}
            disabled={selection.length < 1 || loading}
            endIcon={loading && <CircularProgress size={32} />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              //   handleClose();
              setOpenAddSensorDialog(true);
            }}
          >
            Battery Sensor zur Einheit hinzufügen!
          </Button>
        );
    }
  }, []);


  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Verfügbare Geräte`}
        handleClose={handleClose}
      />
      {loading ? <CircularProgress style={{ padding: 30 }} size={128} /> :
        <DialogContent>
          <Grid container direction={'row'} alignItems="center" justifyContent={"center"} spacing={3} style={{ marginTop: 10 }}>
            <Grid item md={6}>
              {chosenDeviceType == DeviceType.Display &&
                <Alert severity="info">
                  <AlertTitle>Einheiten aktivieren</AlertTitle>
                  Hier können Sie ein Display als Einheit aktivieren.
                </Alert>
              }
              {chosenDeviceType == DeviceType.Sensor &&
                <Alert severity="info">
                  <AlertTitle>Sensoren aktivieren</AlertTitle>
                  Hier können Sie ein Sensor zu einer Einheit hinzufügen, die ausreichend Sensor Lizenzen besitzt.<br />
                  Wenn Einheiten nicht genügend Sensor Lizenzen besitzen:
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Publish />}
                    onClick={() => {
                      assignBssdSensorLicense(null);
                    }}
                  >
                    Lizenzen zuweisen
                  </Button>
                </Alert>
              }
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Geräte Typ</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={chosenDeviceType}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setSelection([]); setChosenDeviceType(parseInt((event.target as HTMLInputElement).value)); }}
                >
                  <FormControlLabel value={DeviceType.Display} control={<Radio />} label="Battery Smart Service Display" />
                  <FormControlLabel value={DeviceType.Sensor} control={<Radio />} label="Battery Sensor" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <SsdRegisteredDevicesTable
            rows={filterDevice(devices, chosenDeviceType)}
            selection={selection}
            setSelection={setSelection}
            onDeleted={closeFromSensorAdd}
            chosenDeviceType={chosenDeviceType}
            assignBssdSensorLicense={assignBssdSensorLicense}
            groups={groups}
          />
        </DialogContent>
      }
      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.close")}
        </Button>
        {buildVerifyButton(chosenDeviceType, selection)}

      </DialogActions>
      <AddRegisteredSensorToBatteryDisplaySelectionDialog closeFromSensorAdd={closeFromSensorAdd} open={openAddSensorDialog} setOpen={setOpenAddSensorDialog} groups={groups} selectedDeviceId={selection[0]}
        onSelected={() => {

        }} />

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));