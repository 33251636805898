import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress, DialogActions, DialogContent, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useTranslation } from 'react-i18next';

export const InputTextBoxDialog = ({ open, setOpen, onExecute, title, label, inputDefaultValue }
    : { open: boolean, setOpen: any, onExecute: any, title: string, label: string, inputDefaultValue: string }) => {

    const classes = useStyles();

    const [inputText, setInputText] = React.useState(inputDefaultValue);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    React.useEffect(()=>{
        if (open){
            setInputText(inputDefaultValue);
        }
    },[open])

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container direction={'row'} alignItems="center" spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: '100%' }}
                                id="filled-number"
                                label={label}
                                variant="filled"
                                value={inputText}
                                onChange={(event: any) => { setInputText(event.target.value) }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        disabled={loading}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button
                        size="large"
                        startIcon={<AddBoxIcon />}
                        endIcon={loading && <CircularProgress size={32}/>}
                        variant={'contained'}
                        color="primary"
                        disabled={loading}
                        onClick={async () => {
                            setLoading(true);
                            await onExecute(inputText);
                            handleClose();
                            setLoading(false);
                        }}
                    >
                        Zuweisen!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));