import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const usePortalAccountStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: 30,
        },
        title: {
            padding: 16,
        },
        container: {
            paddingLeft: 16,
            margin: 10
        }
    }),
);

