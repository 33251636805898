import axios from 'axios';
import { headers } from '../api';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupFeedback } from '../models/GroupFeedback';
import { GroupFeedbackQuestion } from '../models/GroupFeedbackQuestion';
import { GroupIncidentVisitor } from '../models/GroupIncidentVisitor';
import { GroupPayPerService } from '../models/GroupPayPerService';

export const GetPayPerServiceByUser = async (token: string, groupIds: string[]): Promise<Array<GroupPayPerService>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/pay-per-service?${getQueryParamFromApiDataAmountType()}`, {StringArray: groupIds},
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}