import axios from 'axios';
import { headers, showDefaultError } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { UserAddress } from '../../models/UserAddress';

export const assignObjectToSharedFolderAsync = async (objectId: string, objectIds: string[], sharedObjectFolderId: string,token: string, enqueueSnackbar: any): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/shared-folders/${sharedObjectFolderId}/users/${userId}/assign`,
            { stringArray:  objectIds},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Zuweisung erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        showDefaultError(err, enqueueSnackbar);
        enqueueSnackbar("Zuweisung error", { variant: 'error' });
    }
    return null as any;
}