import { Grid, Paper } from "@mui/material";
import React from "react";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { ChartLabelAlternative, LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { ChartCalcType, TimeChoice } from "../../../../core/TimeChoice";
import { DurationChoice, DurationChoiceType, getChartLabelType, getLabelOfDurationChoice } from "../../../../core/DurationChoice";
import { ChartLabelType } from "../../../../charts/Base";
import { deepCopy } from "../../../../../helpers/general";
import { useTranslation } from "react-i18next";


export const GroupMotionLoraChart = ({ data, dataField, allData, setChartData, childRef, loadingCompleted, chartCalcType = ChartCalcType.Sum, childRefChart, title }
    : { data: any[], dataField: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, chartCalcType?: ChartCalcType, childRefChart?: any, title?: string }) => {
    const [chartChoice, setChartChoice] = React.useState('1');
    const [durationChoiceType, setDurationChoiceType] = React.useState(DurationChoiceType.minutes);

    const chartLabelType = getChartLabelType(durationChoiceType);
    const {t} = useTranslation();


    let sumBlockCount = 0;

    const newData = React.useMemo(() => data.reduce((prevVal, x: any) => {
        let currentValue = structuredClone(x);

        switch (durationChoiceType) {
            case DurationChoiceType.hours:
                currentValue.motionCount = Math.round(currentValue.motionCount / 60);
                break;
            case DurationChoiceType.minutes:
                currentValue.motionCount = currentValue.motionCount;
                break;
            default:
                currentValue.motionCount = currentValue.motionCount * 60;
                break;
        }
        sumBlockCount = sumBlockCount + currentValue.motionCount;
        return [...prevVal,currentValue];
    }, []), [data, durationChoiceType]);


    if (!title) {
        if (chartCalcType == ChartCalcType.IncreaseSum) {
            title = `Besucher Verlauf Gesamt<br>Gesamtsumme: <b>${sumBlockCount} Besucher</b>`;
        } else {
            title = `Belegung Gesamt (${getLabelOfDurationChoice(durationChoiceType, t)}) <br>Gesamtsumme: <b>${sumBlockCount} ${getLabelOfDurationChoice(durationChoiceType, t)}</b>`;
        }
    }




    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <LineChart
                    chartLabelType={chartLabelType}
                    chartLabelAlternative={ChartLabelAlternative.Lora}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    chartLabelType={chartLabelType}
                    chartLabelAlternative={ChartLabelAlternative.Lora}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    chartLabelType={chartLabelType}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={title}
                    chartLabelAlternative={ChartLabelAlternative.Lora}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        <DurationChoice value={durationChoiceType} setValue={setDurationChoiceType} />
                    </Grid>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} chartCalcType={chartCalcType} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}