import axios from "axios";
import { multipartHeaders } from "../../api";
import { getUserIdFromStorage } from "../../helpers/common";

export const copySharedFolderStructrueToObjectsAsync = async (file: File, objectIds: string[], sharedObjectFolderId: string,token: string, enqueueSnackbar: any): Promise<any> => {
    try {
        const userId = getUserIdFromStorage();
        const formData = new FormData();
        formData.append('body', file);
        formData.append('paths', JSON.stringify(objectIds));
        const ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/objectunits/shared-folders/${sharedObjectFolderId}/users/${userId}/copy-folder-structure-to-objects`, formData, { headers: { ...multipartHeaders, 'Authorization': `Bearer ${token}` } });

        enqueueSnackbar("Geteilte Objekt Datei wurde erfolgreich hochgeladen", { variant: 'success' });

        return ret.data;
    }
    catch (err: any) {
        if (err.response && err.response.data && err.response.data.message){
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }
        enqueueSnackbar("Geteilte Objekt Datei kann nicht hochgeladen werden", { variant: 'error' });
    }
    return null as any;
}
