import { User } from './GroupSettings';
import { Product } from './Product';
import { UserAccount } from './UserAccount';
import { UserInvoiceAddress } from './UserInvoiceAddress';

export enum PaymentMethod {
    Card = 0,
    Sofort = 1,
    Invoice = 2
}
export class UserInvoice {
    public id!: string;
    public number!: number;
    public invoiceNumber!: number;
    public user!: UserAccount;
    public deliveryAddress!: UserInvoiceAddress;
    public paymentAddress!: UserInvoiceAddress;
    public items: UserInvoiceItem[] = [];
    public date!: Date;
    public netTotal!: number;
    public taxTotal!: number;
    public taxRate!: number;
    public grossTotal!: number;
    public paymentMethod !: PaymentMethod;
    public displayItem !: UserInvoiceItem;

    static Columns = {
        number: 'number',
        invoiceNumber: 'invoiceNumber',
        totalCost: 'totalCost',
        taxes : 'taxes',
        discount: 'discount',
        shippingArticle: 'shippingArticle',
        itemCount: 'itemCount',
        description: 'description',
        quantity: 'quantity',
        date: 'date',
        customerNumber: 'customerNumber',
        responsible: 'responsible',
        invoiceType: 'invoiceType',
        invoiceState: 'invoiceState',
        articleNumber : 'articleNumber',
        actions: 'actions',
    };
    static getPaymentMethodName = (paymentMethod: PaymentMethod) => {
        switch (paymentMethod) {
            case PaymentMethod.Card:
                return "CREDIT_NOTE"
            case PaymentMethod.Invoice:
                return "STANDARD_INVOICE"
            case PaymentMethod.Sofort:
                return "CREDIT_NOTE"
        }
    }
}

export class UserInvoiceItem {
    public count: number = 0;
    public product!: Product;
    public netTotal!: number;
}