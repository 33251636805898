import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { IntervalControl } from '../models/IntervalControl';
import { StandardWorkItem } from '../models/StandardItem';


export const DeleteIntervalControlAsync = async (standarditem : IntervalControl, token: string, enqueueSnackbar: any) => {

    if (!token || !enqueueSnackbar)
        return;

    const userId = getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/delete-interval-control`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` }, data: {...standarditem} }
    ).then((response) => {
        enqueueSnackbar("Intervall wurde erfolgreich gelöscht", { variant: 'success' });
    }).catch((error) => {
        console.log(JSON.stringify(error, null, 2));
        enqueueSnackbar("Es ist ein Fehler bei der Löschung des Intervalls aufgetreten", { variant: 'error' });
    });
};
