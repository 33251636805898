import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { UserGroupSettingsTemplate } from '../models/UserGroupSettingsTemplate';


export const createUserGroupSettingsTemplate = async (template: UserGroupSettingsTemplate, token: string, enqueueSnackbar: any): Promise<UserGroupSettingsTemplate> => {
    try {

        const userId = getUserIdFromStorage();
        const ret = await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template`,
            { ...template },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        enqueueSnackbar("Änderung wurde gespeichert", { variant: 'success' });
        return ret.data;

    } catch (err) {
        handleApiError(err, enqueueSnackbar, "Speichern fehlgeschlagen");
    }
    return null as any;
};
