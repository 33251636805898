import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const useChangeUserEmailStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));