import { AssignmentTurnedIn, Backspace, NextPlan, Warning } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useTranslation } from 'react-i18next';

const isBrowser = typeof document !== 'undefined';

export function useEventListener(element: any, type: any, listener: any) {
    React.useEffect(() => {
        if (!element) {
            return undefined;
        }

        element.addEventListener(type, listener);

        return () => {
            element.removeEventListener(type, listener);
        };
    }, [element, type, listener]);
}

export function useWindowWidth() {
    const [windowWidth, setWindowWidth] = React.useState(isBrowser ? window.innerWidth : null);

    const getWindowWidth = React.useCallback(() => setWindowWidth(window.innerWidth), []);

    useEventListener(isBrowser ? window : null, 'resize', getWindowWidth);

    return windowWidth;
}

export const TermsOfUseDialog = ({ visible, title, onConfirmed, onClose }
    : { visible: boolean; title: string, onConfirmed: any; onClose: any; }) => {
    const [loading, setLoading] = React.useState(false);

    const [countdown, setCountdown] = React.useState(10);
    const [numPages, setNumPages] = React.useState<any>(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const {t} = useTranslation();

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }

    const width: any = useWindowWidth();
    const pdfLink = 'https://restroombird.blob.core.windows.net/termsofuse/terms_of_use.pdf';

    return (
        <Dialog fullScreen open={visible} onClose={onClose}>
            <DefaultDialogTitle
                title={`Nutzungsbedienungen`}
                handleClose={onClose}
            />
            <DialogContent>
                <Grid container direction={"row"} spacing={3} justifyContent="center" alignContent={"center"}>
                    <Grid item xs={12}>
                        <Link style={{fontWeight: 'bold', fontSize: 40, textAlign: 'center'}} href={pdfLink}>Nutzungsbedienungen Download</Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Document file={pdfLink} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page
                                width={Math.min(width * 0.9)} // width: 90vw; max-width: 400px
                                pageNumber={pageNumber} />
                        </Document>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<Backspace />}
                            onClick={async () => {
                                if (pageNumber > 1)
                                    setPageNumber(prev => prev - 1);
                            }}
                        >
                            Zurück
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            style={{ marginLeft: 10 }}
                            variant="contained"
                            color="primary"
                            startIcon={<NextPlan />}
                            onClick={async () => {
                                if (pageNumber < numPages)
                                    setPageNumber(prev => prev + 1);
                            }}
                        >
                            Nächste
                        </Button>
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>{t("myUnitsSettings.cancel")}</Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={loading && <CircularProgress size={32} />}
                    endIcon={<AssignmentTurnedIn />}
                    disabled={loading}
                    onClick={async () => {

                        setLoading(true);
                        await onConfirmed();
                        setLoading(false);
                    }}
                >
                    Akzeptieren
                </Button>
            </DialogActions>

        </Dialog>
    );
};
