import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import React, { useCallback, useRef } from 'react';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Check, CheckCircle, Edit, EditOutlined, Error, Extension, Navigation } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { formatEuropeanDate, formatEuropeanDateTime } from '../../../../helpers/date-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { mapToiletTypeKey } from '../../../tables/Base';
import { toiletTypePredicate } from '../../../tables/components/Cells';
import { buildCellObject } from '../serviceWorker/UserServiceWorkerManagementPageDataTable';
import { ReactComponent as LicenseSvg } from '../../../../assets/svgs/license_grey.svg'
import { primaryColor } from '../../../../App';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { buildCellAssignedColumn } from '../../../tables/GroupSelectionTable';
import { GroupType } from '../../../../models/Group';
import { InputTextBoxDialog } from '../../../../helpers/dialogs/InputTextBoxDialog';
import { Row } from 'devextreme-react/responsive-box';
import { useToken } from '../../../../helpers/apiHelper';
import { useSnackbar } from 'notistack';
import { api } from '../../../../api';
import { useTranslation } from 'react-i18next';

const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Checkpoint');
    });
};


export const CheckpointSelectionTable = ({ groups, selectedGroups, onSelectionChange,
    selectedObjectId, selectedServiceWorkerId, objects, userServiceWorkers,
    onEdit, onExtendLicense, onNavigate, groupType }
    : {
        groups: GroupLicenseTable[], selectedGroups: GroupLicenseTable[], onSelectionChange: any,
        selectedObjectId?: string | undefined, selectedServiceWorkerId?: string | undefined, objects?: ObjectUnit[] | undefined, userServiceWorkers?: UserServiceWorker[] | undefined,
        onEdit: any, onExtendLicense: any, onNavigate: any, groupType: GroupType
    }) => {

    const exporterRef: any = useRef(null);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);


    const [isAssignTaqtDeviceIdVisible, setIsAssignTaqtDeviceIdVisible] = React.useState(false);
    const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable>();
    const token = useToken();
    const { enqueueSnackbar } = useSnackbar();
    const {t} = useTranslation();

    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupLicenseTable } = props;
        if (column.name == GroupLicenseTable.Columns.currentAssignedObject) {
            return buildCellObject(objects?.find(x => x.id == row.objectUnitId), props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedObject) {
            return buildCellAssignedColumn(row.objectUnitId == selectedObjectId, props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedServiceWorker) {
            return buildCellAssignedColumn(row.userServiceWorkerIds.includes(selectedServiceWorkerId || ""), props);
        }
        if (column.name == GroupLicenseTable.Columns.currentAssignedServiceWorkers && userServiceWorkers) {
            let userServiceWorkersForRow = userServiceWorkers.filter(x => row.userServiceWorkerIds.includes(x.id)).map(x => `${x.name} ${x.phoneNumber}`);
            return buildCellStringArray(props, userServiceWorkersForRow, `${t("myObjectSettings.contains")} ${userServiceWorkersForRow.length} Servicekräfte`);
        }
        if (column.name == GroupLicenseTable.Columns.deviceIdTaqt) {
            return (
                <Table.Cell {...props}>
                    <Button startIcon={<EditOutlined />} variant='contained' onClick={() => {
                        setSelectedGroup(row);
                        setIsAssignTaqtDeviceIdVisible(true);
                    }}>
                        {row.deviceId ? "Bearbeiten" : "Neu"}
                    </Button>
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };

    let columns = [
        {
            name: GroupLicenseTable.Columns.actions,
            title: 'Bearbeiten',
            getCellValue: (row: GroupLicenseTable) => {
                return <>
                    <Tooltip title={t("myUnitsSettings.edit")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Lizenz erweitern">
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onExtendLicense(row);
                            }}
                            size="large">
                            <LicenseSvg fill={primaryColor} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Checkpoint verwalten">
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onNavigate(row);
                            }}
                            size="large">
                            <Navigation />
                        </IconButton>
                    </Tooltip>
                </>;
            }
        },
        {
            name: GroupLicenseTable.Columns.notificationName,
            title: t("serviceDocumentationTable.unitName"),
            getCellValue: (row: GroupLicenseTable) => row.notificationName,
        },
        {
            name: GroupLicenseTable.Columns.objectName,
            title: t("myUnitsSettings.objectName"),
            getCellValue: (row: GroupLicenseTable) => row.objectName,
        },
        {
            name: GroupLicenseTable.Columns.levelName,
            title: t("myUnitsSettings.level"),
            getCellValue: (row: GroupLicenseTable) => row.levelName,
        },
        {
            name: GroupLicenseTable.Columns.roomName,
            title: t("myUnitsSettings.customer"),
            getCellValue: (row: GroupLicenseTable) => row.roomName,
        },
        {
            name: GroupLicenseTable.Columns.toiletType,
            title: t("myUnitsSettings.roomType"),
            getCellValue: (row: GroupLicenseTable) => mapToiletTypeKey(row.toiletType),
        },
        {
            name: GroupLicenseTable.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: GroupLicenseTable) => row.userPropertyName,
        },
        {
            name: GroupLicenseTable.Columns.deviceLicenseValidUntil,
            title: 'Checkpoint Gültig bis',
            getCellValue: (row: GroupLicenseTable) => row.deviceLicenseIsValid ? (row.deviceLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.deviceLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.qualityCheckLicenseValidUntil,
            title: t("myUnitsSettings.qualityControlLicenseValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.qualityCheckLicenseIsValid ? (row.qualityCheckLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.qualityCheckLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.groupCreatedDate,
            title: t("myUnitsSettings.unitActivatedOn"),
            getCellValue: (row: GroupLicenseTable) => formatEuropeanDateTime(new Date(row.groupCreatedDate))
        }
    ];

    let columnWidth = Object.keys(GroupLicenseTable.Columns).map(el => {
        if (el == GroupLicenseTable.Columns.currentAssignedObject) {
            return { columnName: el, width: 170 }
        }
        if (el == GroupLicenseTable.Columns.currentAssignedServiceWorkers) {
            return { columnName: el, width: 300 }
        }
        return { columnName: el, width: 170 }
    });

    if (groupType == GroupType.TaqtFeedback || groupType == GroupType.TaqtTime) {
        const taqtDeviceIdColumn = {
            name: GroupLicenseTable.Columns.deviceIdTaqt,
            title: 'Taqt Device ID',
            getCellValue: (row: GroupLicenseTable) => row.deviceId
        }

        columns = [taqtDeviceIdColumn, ...columns];
    }

    if (selectedObjectId) {
        const objectAssignedColumn = {
            name: GroupLicenseTable.Columns.assignedObject,
            title: t("myObjectSettings.assigned"),
            getCellValue: (row: GroupLicenseTable) => row.objectUnitId == selectedObjectId ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")
        }
        const currentObjectAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedObject,
            title: t("myObjectSettings.currentObjectAssigned"),
            getCellValue: (row: GroupLicenseTable) => {
                let object = objects?.find(x => x.id == row.objectUnitId);
                return (
                    !object ? "Keines Zugewiesen" : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            }
        }

        columns = [objectAssignedColumn, currentObjectAssignedColumn, ...columns];

    }

    if (selectedServiceWorkerId) {
        const serviceWorkerAssignedColumn = {
            name: GroupLicenseTable.Columns.assignedServiceWorker,
            title: t("myObjectSettings.assigned"),
            getCellValue: (row: GroupLicenseTable) => row.userServiceWorkerIds.includes(selectedServiceWorkerId) ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")
        }
        const currentServiceWorkersAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedServiceWorkers,
            title: 'Aktuelle Servicekräfte zugewiesen',
            getCellValue: (row: GroupLicenseTable) => {
                let userServiceWorkersForRow = userServiceWorkers?.filter(x => row.userServiceWorkerIds.includes(x.id));
                return (
                    !userServiceWorkersForRow ? "Keine Zugewiesen" : `${userServiceWorkersForRow.map(x => `${x.name} ${x.phoneNumber}`).join(',')}`
                );
            }
        }
        columns = [serviceWorkerAssignedColumn, currentServiceWorkersAssignedColumn, ...columns];
    }

    return (
        <>
            <Grid
                getRowId={(row) => row.id}
                rows={groups}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering columnExtensions={[
                    { columnName: GroupLicenseTable.Columns.toiletType, predicate: toiletTypePredicate },
                ]} />

                <SortingState
                    defaultSorting={[{ columnName: GroupLicenseTable.Columns.notificationName, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedGroups.map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={columnWidth} />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <TableHeaderRow />
                <TableFilterRow />
                <PagingPanel
                    pageSizes={[5, 10, 15, 0]}
                />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={groups}
                columns={columns}
                onSave={onSave}
            />
            {selectedGroup &&
                <InputTextBoxDialog inputDefaultValue={selectedGroup.deviceId} open={isAssignTaqtDeviceIdVisible} setOpen={setIsAssignTaqtDeviceIdVisible}
                    onExecute={async(id: string) => {
                        await api.assignDeviceIdToGroup(id, selectedGroup.id, token, enqueueSnackbar)
                        selectedGroup.deviceId = id;
                    }} title={"Geben Sie Die Device Mac Address an"} label={"Device Mac Address"} />
            }
        </>
    );
};