import React from "react"
import { Grid, Paper } from "@mui/material"
import { elevationPaper, paperStyle } from "../../../../../styles/container"
import { LineChart } from "../../../../charts/LineChart"
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart"
import { StackedChart } from "../../../../charts/StackedChart"
import { ChartChoice } from "../../../../core/ChartChoice"
import { TimeChoice } from "../../../../core/TimeChoice"
import { ChartLabelType, chartHeightBig } from "../../../../charts/Base"
import { SetPointActualValueModel } from "../../../../../models/SetPointActualValueModel"
import { DurationChoiceType } from "../../../../core/DurationChoice"
import { NumberHelper } from "../../../../../helpers/number-helper"

export const CarbonDioxideConsumptionChart = ({ data, setChartData, dataField, loadingCompleted, childRefChart, title, allData, chartLabelType, childRef }
    : { data: any, setChartData: any, dataField: any, loadingCompleted: any, childRefChart?: any, title: string, allData: any[], chartLabelType: ChartLabelType, childRef: any }) => {
    const [chartChoice, setChartChoice] = React.useState('1');


    const newData = React.useMemo(() => data.map((x: any) => {
        return {
            date: x.date,
            dateDisplay: x.dateDisplay,
            actualCarbonDixoideConsumption: NumberHelper.roundDigitsAfterComma(x.actualCarbonDixoideConsumption,10),
            setPointcarbonDixoideConsumption: NumberHelper.roundDigitsAfterComma(x.setPointcarbonDixoideConsumption,10),
            savedCarbonDixoideConsumption: x.setPointcarbonDixoideConsumption - x.actualCarbonDixoideConsumption > 0 ? NumberHelper.roundDigitsAfterComma(x.setPointcarbonDixoideConsumption - x.actualCarbonDixoideConsumption,10) : 0
        }
    }), [data]);


    const buildGridItem = () => {

        switch (chartChoice) {
            case '0':
                return <LineChart
                    chartLabelType={chartLabelType}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    chartLabelType={chartLabelType}
                    chartHeight={chartHeightBig}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    chartLabelType={chartLabelType}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>{buildGridItem()}</Grid>
                    <Grid item>
                        <Grid item>
                            <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} />
                        </Grid>
                        <Grid item>
                            <ChartChoice value={chartChoice} setValue={setChartChoice} />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}