import Dialog from '@mui/material/Dialog';
import React from 'react';
import { GroupSingleSelection } from '../tables/GroupSingleSelection';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { getApiToken, setStorageForDrawer } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import { DefaultDialogTitle } from './DefaultDialogTitle';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, TouchApp } from '@mui/icons-material';
import { GROUP_ID, GROUP_NAME } from '../../constants';
import { ConfirmationDialog } from './ConfirmationDialog';
import { UserAccount } from '../../models/UserAccount';
import { UserType } from "../../models/UserType";
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

export const GroupSelectionEvaluationDialog = ({
  open,
  setOpen,
  selectedGroupId,
  setSelectedGroupName,
  setSelectedGroupId,
  user,
  setSelectedCombinationGroup,
  setSelectedGroupType
}: {
  open: boolean,
  setOpen: any,
  selectedGroupId: string,
  setSelectedGroupName: any,
  setSelectedGroupId: any,
  user: UserAccount | undefined,
  setSelectedCombinationGroup: any,
  setSelectedGroupType: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
  const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | undefined>(undefined);
  const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
  const {t} = useTranslation();

  const handleClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const token = getApiToken(history);
      if (!token) return;

      const tempGroups = await api.getAllGroups(token);
      setGroups(tempGroups);

      setSelectedGroup(groups.filter(x => x.id === selectedGroupId)[0]);

    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={false}
    >
      <DefaultDialogTitle
        title="Wähle Einheit für Einzelauswertung"
        handleClose={handleClose}
      />

      <DialogContent>
        <GroupSingleSelection
          groups={groups}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{marginLeft: 'auto'}}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<TouchApp />}
          disabled={!selectedGroup}
          onClick={() => {

            if (!selectedGroup) return;

            setSelectedGroupName(selectedGroup.notificationName);
            setSelectedGroupId(selectedGroup.id);
            setSelectedGroupType(selectedGroup.groupType);
            setStorageForDrawer(selectedGroup);

            history.push(`/portal/group/${selectedGroup.id}/settings`);
            handleClose();
          }}
        >
          Einheit auswählen
        </Button>
      </DialogActions>

      <ConfirmationDialog
        visible={deleteConfirmationDialogVisible}
        title={`Sind Sie sicher, die Einheit '${selectedGroup?.notificationName}' zu löschen ?`}
        onClose={() => setDeleteConfirmationDialogVisible(false)}
        onConfirmed={async () => {

          if (!selectedGroup) return;

          const token = getApiToken(history);
          await api.deleteGroupAsync(selectedGroup.id, token, enqueueSnackbar);

          const updatedGroups = groups.filter(x => x.id !== selectedGroup.id);
          setGroups(updatedGroups);

          if (updatedGroups.length > 0) {
            const chosenGroup = updatedGroups[0];
            setSelectedGroup(chosenGroup);
            setSelectedGroupName(chosenGroup.notificationName);
            setSelectedGroupId(chosenGroup.id);
            setSelectedGroupType(chosenGroup.groupType);
            setStorageForDrawer(chosenGroup);
            history.push(`/portal/group/${chosenGroup.id}`);
            setDeleteConfirmationDialogVisible(false);
            handleClose();
            return;
          }

          if (updatedGroups.length === 0) {
            setSelectedGroupName(null);
            setSelectedGroupId(null);
            setSelectedCombinationGroup([]);
            history.push(`/portal/account/${user?.id}`);
            setDeleteConfirmationDialogVisible(false);
            handleClose();
            return;
          }

          setDeleteConfirmationDialogVisible(false);
        }}
      />

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));