import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../api';
import { useSnackbar } from 'notistack';
import { DialogActions, DialogContent, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../helpers/storage-management';
import { useTranslation } from 'react-i18next';

export const AddUserKeyDialog = ({ open, setOpen, setUserKeys }
    : { open: boolean, setOpen: any, setUserKeys: any }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [notice, setNotice] = React.useState("");
    const {t} = useTranslation();

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"sm"}
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    Lizenz-Key hinzufügen
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container direction={'row'} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: '100%' }}
                                id="filled-number"
                                label="Notiz"
                                variant="filled"
                                value={notice}
                                onChange={(event: any) => { setNotice(event.target.value) }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button
                        size="large"
                        startIcon={<AddBoxIcon />}
                        variant={'contained'}
                        color="primary"
                        onClick={async () => {

                            const token = getApiToken(history);
                            await api.addNewUserKey(token, enqueueSnackbar, notice);
                            let tempUserKeys = await api.getUserKeys(token);
                            setUserKeys(tempUserKeys);
                            handleClose();
                        }}
                    >
                        Hinzufügen!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));