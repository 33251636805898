import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useResetPasswordStyles } from '../../styles/reset-password';
import { api } from '../../api';
import { ResetPasswordRequest } from '../../models/ResetPasswordRequest';
import { FormControl, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

export const ResetPasswordPage = (props: any) => {

  const [starting, setStarting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [redirecting, setRedirecting] = React.useState(false);
  const [buttonText, setButtonText] = React.useState<string>('Passwort zurücksetzen');
  const [expiredNotificationText, setExpiredNotificationText] = React.useState<string | null>();
  const [waitingTime, setWaitingTime] = React.useState(6);
  const [data, setData] = React.useState<ResetPasswordRequest | null>(null);
  const [password, setPassword] = React.useState<string | null>(null);

  const history = useHistory();
  const classes = useResetPasswordStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { match: { params: { requestId } } } = props;

  const onSubmit = async () => {

    if (!password) return;

    setLoading(true);
    const updateSucceed = await api.changePasswordByRequestIdAsync(requestId, password, enqueueSnackbar);
    setLoading(false);

    if (!updateSucceed) return;

    startRedirecting();
  }

  const startAsync = async () => {
    setStarting(true);
    const request = await api.getResetPasswordRequestAsync(requestId);
    setData(request);
    setStarting(false);

    if (!request || request.expired)
      startRedirecting();
  }

  const startRedirecting = () => {
    setRedirecting(true);
    setWaitingTime(time => time - 1);
  }

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
  }

  const buildForm = () => {

    if (!data || data.expired) return null;

    return (
      <FormControl className={classes.form} component="fieldset" onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              value={password}
              onChange={onPasswordChange}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              disabled={loading || redirecting}
            />
          </Grid>
        </Grid>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submitButton}
          disabled={loading || redirecting}
          onClick={onSubmit}>
          {buttonText}
        </Button>

      </FormControl>
    );
  }

  const buildNotification = () => {

    if (starting)
      return (
        <Typography style={{ width: '100%', marginTop: 8 }} className="Info" component="p" variant="body1">
          Benutzerdaten wird geladen ...
        </Typography>
      );

    if (expiredNotificationText && (!data || data.expired))
      return (
        <Typography style={{ width: '100%', marginTop: 8 }} className="Info" component="p" variant="body1">
          {expiredNotificationText}
        </Typography>
      );

    return null;
  }

  // Initialization
  React.useEffect(() => {

    startAsync();

    return () => {

    }
  }, []);

  // Redirects user to login page after submitting
  React.useEffect(() => {

    if (!redirecting) return;

    if (waitingTime < 0) {
      history.push('/login');
      return;
    }

    const timer = setTimeout(() => {
      setButtonText(`Passwort wurde zurückgesetzt. Sie werden in ${waitingTime} Sekunden weitergeleitet`);
      setExpiredNotificationText(`Anfrage ist bereits abgelaufen. Bitte beantragen Sie neu unter 'Password vergessen'. Sie werden in ${waitingTime}s weitergeleitet`);
      startRedirecting();
    }, 1000);

    return () => {
      clearTimeout(timer);
    }
  }, [waitingTime]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.container}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Passwort zurücksetzen</Typography>
        {buildNotification()}
        {buildForm()}
      </div>
    </Container>
  );
}
