import axios from 'axios';
import { headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { DeviceType } from '../../models/bssd/SsdDeviceModel';

export const createRegisteredBssdDevice = async (hubId : string, deviceId: string, type: DeviceType,token: string, enqueueSnackbar: any): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/hubs/${hubId}/bssd-registered-devices/${deviceId}/type/${type}`,
            { hubId: hubId },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Device erfolgreich reserviert", { variant: 'success' });
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Device error beim reservieren", { variant: 'error' });
    }
    return null as any;
}