import axios from 'axios';
import { headers } from '../api';
import { TemplateInfo } from '../models/TemplateInfo';

export const DeleteTemplateInfoAsync = async (templateInfo: TemplateInfo, token: string, enqueueSnackbar: any) => {
    return await axios
        .delete(`${process.env.REACT_APP_BASIC_URI}/templates/${templateInfo.id}`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Löschen von Vorlagen erfolgreich", { variant: 'success' });
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Beim Löschen von Vorlagen ist ein Fehler aufgetreten", { variant: 'error' });
        });
};
