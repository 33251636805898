import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Typography } from "@mui/material";
import React, { Fragment, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { AggregateType, DataFilterType, PeriodChartFilterType, extractSensorsArray, generateChartData, generateChartDataWMissingDay, generateSumChartData, getDataFromGroupIdsMotion, getDataFromGroupIdsSensors, getMotionData, getMotionsByObjectIds, getMotionsByServiceWorkerIds, APIDataAmountType } from "../../../../helpers/charts/base";
import { generateMotionsChartData, generateMotionsChartDataSum, getAllMotionTypes } from "../../../../helpers/charts/customerUsageGenerator";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { getApiToken } from "../../../../helpers/storage-management";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupSettings, LocationInformation, Sensor } from "../../../../models/GroupSettings";
import { mapChartKeyFeedback } from "../../../charts/Base";
import { prepareSensorsForChartAutoComplete } from "../../../../helpers/charts/dataGenerator";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { GroupMotionChart } from "./customerUsage/GetGroupMotions";
import { MotionsAggSumDashboard } from "./MotionsAggSumDashboard";
import { ChartCalcType } from "../../../core/TimeChoice";
import { SensorFilterType, SpecificSensorCharts } from "./customerUsage/specificSensor/SpecificSensorCharts";
import { MotionDataTable } from "./customerUsage/MotionDataTable";
import { ExpandMore } from "@mui/icons-material";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { GroupMotion } from "../../../../models/GroupMotion";
import InfiniteScroll from "react-infinite-scroll-component";
import { EvalApiType } from "./Services";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { OBJECT_COMBINATION, SERVICE_WORKER_COMBINATION } from "../../../../constants";
import { getMotionsByServiceWorker } from "../../../../api/GetMotionsByUser";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";
import { drawerPadding, drawerWidth, GlobalContext, maxContentWrapperPortalStyle, paperPadding } from "../../PortalPage";
import { FrequencyAvgPercentChart } from "./frequency/FrequencyAvgPercentChart";
import { getDefaultPeriod } from "../../../../helpers/date-management";
import { deepCopy } from "../../../../helpers/general";
import { FrequencyGroupsChart } from "./frequency/FrequencyGroupsChart";
import useNoInitialEffect from "../../../../helpers/extensions";


export const FrequencyDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any }) => {
    const [frequencyAvgPercentMotions, setFrequencyAvgPercentMotions] = React.useState<any>([]);
    const [groupMotions, setGroupMotions] = React.useState<any>([]);
    const [groupMotionsAll, setGroupMotionsAll] = React.useState<any>([]);
    const [groupMotionType, setGroupMotionType] = React.useState<any>([]);
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const [sensors, setSensors] = React.useState<any>([]);
    const [currentSensor, setCurrentSensor] = React.useState<Sensor>();
    const [currentSensorFilterSession, setCurrentSensorFilterSession] = React.useState<Sensor>();
    const [specificSensorMotionData, setSpecificSensorMotionData] = React.useState([]);
    const [specificSensorMotionDataBySensorId, setSpecificSensorMotionDataBySensorId] = React.useState([]);
    const [specificSensorMotionIncreaseSumData, setSpecificSensorMotionIncreaseSumData] = React.useState([]);
    const [specificSensorMotionIncreaseSumDataBySensorId, setSpecificSensorMotionIncreaseSumDataBySensorId] = React.useState([]);


    const [multipleSensorsMotionData, setMultipleSensorsMotionData] = React.useState([]);
    const [allRoomNames, setAllRoomNames] = React.useState<string[]>([]);

    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();

    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();

    const childRefMotionIncrease: any = useRef();
    const childRefMotion: any = useRef();

    const childRefMotionIncreaseChart: any = useRef();
    const childRefMotionChart: any = useRef();

    const childRefFrequencyAvgPercent: any = useRef();
    const childRefFrequencyAvgPercentChart: any = useRef();

    const childRefFrequencyGroups: any = useRef();
    const childRefFrequencyGroupsChart: any = useRef();


    const childRefMotionsBySensor: any = useRef();
    const childRefMotionsBySensorChart: any = useRef();

    const childRefMotionsSumIncreaseBySensor: any = useRef();
    const childRefMotionsSumIncreaseBySensorChart: any = useRef();

    const childRefMotionsBySensorFilterId: any = useRef();
    const childRefMotionsBySensorFilterIdChart: any = useRef();

    const childRefMotionsSumIncreaseBySensorFilterId: any = useRef();
    const childRefMotionsSumIncreaseBySensorFilterIdChart: any = useRef();

    const childRefMotionMultipleRoomsIncrease: any = useRef();
    const childRefMotionMultipleRooms: any = useRef();
    const childRefMotionMultipleRoomsChart: any = useRef();

    const [motionsFromApi, setMotionsFromApi] = React.useState<GroupMotion[]>([]);
    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);
    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);




    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);

    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const [motionTableData, setMotionTableData] = React.useState<GroupMotion[]>([]);

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(30);

    const loadDataAsync = async () => {
        const token = getApiToken(history);
        if (!token) return;
        let remoteSensors: Sensor[] = [];
        let motions = [];
        setApiDataLoading(true);
        setLoadingProgress(0);
        setValueBuffer(30);
        setLoadingCompleted(false);

        if (serviceDisplayType == EvalApiType.Objects) {
            let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }


        motions = await getMotionData(groupId, token, groupIds);
        setMotionsFromApi(motions);
        setLoadingProgress(50);
        setValueBuffer(80);
        let motionChartAllData = await loadMotionData(
            setGroupMotionsAll,
            setGroupMotionType,
            setGroupMotions,
            setLoadingCompleted,
            AggregateType.Time,
            motions,
            setMotionTableData);


        setFrequencyAvgPercentMotions(generateMotionsChartData(motions));
        if (groupIds && groupIds.length > 0) {
            remoteSensors = await getDataFromGroupIdsSensors(groupIds, token);
        }
        else if (groupId) {
            remoteSensors = await api.getGroupSensorsById(token, groupId);
        } else {
            let allSensors = await api.getGroupSensorsByUserId(token);
            remoteSensors = extractSensorsArray(allSensors);
        }
        setSensors(remoteSensors);
        setLoadingProgress(80);
        setValueBuffer(100);

        await loadMotionDataForSensor(remoteSensors, setCurrentSensor, motionChartAllData, setSpecificSensorMotionData);
        await loadMotionDataForSensor(remoteSensors, setCurrentSensor, motionChartAllData, setSpecificSensorMotionDataBySensorId);
        let currentSensor = remoteSensors[0];
        setCurrentSensorFilterSession(currentSensor)

        let allRoomsTemp = remoteSensors.map((el) => el.sensorRoomName);
        setAllRoomNames(allRoomsTemp);
        await loadMotionDataForMultipleSensors(remoteSensors, motionChartAllData, setMultipleSensorsMotionData);

        if (serviceDisplayType == EvalApiType.Groups) {
            let groupsPortal = await getPortalGroups(groupIds, token, groupId);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        setLoadingProgress(100);
        setValueBuffer(100);
        setApiDataLoading(false);

        showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
    }


    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);

    useNoInitialEffect(() => {
        loadDataAsync();
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
    }, [apiDataAmountType, reloadDataFromApi]);

    const updateChosenPastDaysFromParent = (event: any) => {

        if (childRefMotionIncrease?.current)
            childRefMotionIncrease.current.updateChosenPastDays(event.target.value);

        if (childRefMotion?.current)
            childRefMotion.current.updateChosenPastDays(event.target.value);

        if (childRefFrequencyAvgPercent?.current)
            childRefFrequencyAvgPercent.current.updateChosenPastDays(event.target.value);

        if (childRefFrequencyGroups?.current)
            childRefFrequencyGroups.current.updateChosenPastDays(event.target.value);

        if (childRefMotionsBySensor?.current)
            childRefMotionsBySensor.current.updateChosenPastDays(event.target.value);

        if (childRefMotionsSumIncreaseBySensor?.current)
            childRefMotionsSumIncreaseBySensor.current.updateChosenPastDays(event.target.value);

        if (childRefMotionsBySensorFilterId?.current)
            childRefMotionsBySensorFilterId.current.updateChosenPastDays(event.target.value);

        if (childRefMotionsSumIncreaseBySensorFilterId?.current)
            childRefMotionsSumIncreaseBySensorFilterId.current.updateChosenPastDays(event.target.value);

        if (childRefMotionMultipleRoomsIncrease?.current)
            childRefMotionMultipleRoomsIncrease.current.updateChosenPastDays(event.target.value);

        if (childRefMotionMultipleRooms?.current)
            childRefMotionMultipleRooms.current.updateChosenPastDays(event.target.value);

    }

    const updateEndDateFromParent = (event: any) => {

        if (childRefMotionIncrease?.current)
            childRefMotionIncrease.current.updateEndDateFromParent(event);

        if (childRefMotion?.current)
            childRefMotion.current.updateEndDateFromParent(event);

        if (childRefFrequencyAvgPercent?.current)
            childRefFrequencyAvgPercent.current.updateEndDateFromParent(event);

        if (childRefFrequencyGroups?.current)
            childRefFrequencyGroups.current.updateEndDateFromParent(event);

        if (childRefMotionsBySensor?.current)
            childRefMotionsBySensor.current.updateEndDateFromParent(event);

        if (childRefMotionsSumIncreaseBySensor?.current)
            childRefMotionsSumIncreaseBySensor.current.updateEndDateFromParent(event);

        if (childRefMotionsBySensorFilterId?.current)
            childRefMotionsBySensorFilterId.current.updateEndDateFromParent(event);

        if (childRefMotionsSumIncreaseBySensorFilterId?.current)
            childRefMotionsSumIncreaseBySensorFilterId.current.updateEndDateFromParent(event);

        if (childRefMotionMultipleRoomsIncrease?.current)
            childRefMotionMultipleRoomsIncrease.current.updateEndDateFromParent(event);

        if (childRefMotionMultipleRooms?.current)
            childRefMotionMultipleRooms.current.updateEndDateFromParent(event);
    }

    const updateStartDateFromParent = (event: any) => {

        if (childRefMotionIncrease?.current)
            childRefMotionIncrease.current.updateStartDateFromParent(event);

        if (childRefMotion?.current)
            childRefMotion.current.updateStartDateFromParent(event);

        if (childRefFrequencyAvgPercent?.current)
            childRefFrequencyAvgPercent.current.updateStartDateFromParent(event);

        if (childRefFrequencyGroups?.current)
            childRefFrequencyGroups.current.updateStartDateFromParent(event);

        if (childRefMotionsBySensor?.current)
            childRefMotionsBySensor.current.updateStartDateFromParent(event);

        if (childRefMotionsSumIncreaseBySensor?.current)
            childRefMotionsSumIncreaseBySensor.current.updateStartDateFromParent(event);

        if (childRefMotionsBySensorFilterId?.current)
            childRefMotionsBySensorFilterId.current.updateStartDateFromParent(event);

        if (childRefMotionsSumIncreaseBySensorFilterId?.current)
            childRefMotionsSumIncreaseBySensorFilterId.current.updateStartDateFromParent(event);

        if (childRefMotionMultipleRoomsIncrease?.current)
            childRefMotionMultipleRoomsIncrease.current.updateStartDateFromParent(event);

        if (childRefMotionMultipleRooms?.current)
            childRefMotionMultipleRooms.current.updateStartDateFromParent(event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {

        if (childRefMotionIncrease?.current)
            childRefMotionIncrease.current.updateDataFilterTypeFromParent(event);

        if (childRefMotion?.current)
            childRefMotion.current.updateDataFilterTypeFromParent(event);

        if (childRefFrequencyAvgPercent?.current)
            childRefFrequencyAvgPercent.current.updateDataFilterTypeFromParent(event);

        if (childRefFrequencyGroups?.current)
            childRefFrequencyGroups.current.updateDataFilterTypeFromParent(event);

        if (childRefMotionsBySensor?.current)
            childRefMotionsBySensor.current.updateDataFilterTypeFromParent(event);

        if (childRefMotionsSumIncreaseBySensor?.current)
            childRefMotionsSumIncreaseBySensor.current.updateDataFilterTypeFromParent(event);

        if (childRefMotionsBySensorFilterId?.current)
            childRefMotionsBySensorFilterId.current.updateDataFilterTypeFromParent(event);

        if (childRefMotionsSumIncreaseBySensorFilterId?.current)
            childRefMotionsSumIncreaseBySensorFilterId.current.updateDataFilterTypeFromParent(event);


        if (childRefMotionMultipleRoomsIncrease?.current)
            childRefMotionMultipleRoomsIncrease.current.updateDataFilterTypeFromParent(event);

        if (childRefMotionMultipleRooms?.current)
            childRefMotionMultipleRooms.current.updateDataFilterTypeFromParent(event);
    }

    const buildMotionDataTable = () => {
        return (
            <Paper>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Frequenz Log Tabelle
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {motionTableData.length}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MotionDataTable groups={groupsLicenseTableEntries} rows={motionTableData} selectedEntries={[]} onSelectionChange={() => { }} />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        )
    }

    const buildGetGroupMotion = () => {
        return (
            <GroupMotionChart
                childRefChart={childRefMotionChart}
                childRef={childRefMotion}
                setChartData={setGroupMotions}
                allData={groupMotionsAll}
                data={groupMotions}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildFrequencyAvgPercentChart = () => {
        return (
            <FrequencyAvgPercentChart
                groups={groupsLicenseTableEntries}
                childRefChart={childRefFrequencyAvgPercentChart}
                childRef={childRefFrequencyAvgPercent}
                setChartData={setFrequencyAvgPercentMotions}
                allData={groupMotionsAll}
                data={frequencyAvgPercentMotions}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildFrequencyGroupChart = () => {
        return (
            <FrequencyGroupsChart
                groups={groupsLicenseTableEntries}
                childRefChart={childRefFrequencyAvgPercentChart}
                childRef={childRefFrequencyAvgPercent}
                setChartData={setFrequencyAvgPercentMotions}
                allData={groupMotionsAll}
                data={frequencyAvgPercentMotions}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }


    const buildSpecifcGetGroupMotion = () => {
        return (
            <GroupMotionChart
                childRefChart={childRefMotionMultipleRoomsChart}
                childRef={childRefMotionMultipleRooms}
                setChartData={setMultipleSensorsMotionData}
                allData={groupMotionsAll}
                data={multipleSensorsMotionData}
                dataField={allRoomNames}
                loadingCompleted={loadingCompleted}
                title={"Besucher aller Räume"} />
        )
    }

    const buildSpecificSensorCharts = () => {
        return (
            <SpecificSensorCharts
                sensors={sensors}
                sensorFilterType={SensorFilterType.SensorRoomName}
                childRefChart={childRefMotionsBySensorChart}
                childRef={childRefMotionsBySensor}
                setCurrentSensor={setCurrentSensor}
                currentSensor={currentSensor}
                setChartData={setSpecificSensorMotionData}
                allData={groupMotionsAll}
                data={specificSensorMotionData}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted}
            />
        )
    }

    const buildSpecificSensorChartsIncreaseSum = () => {
        return (
            <SpecificSensorCharts
                sensors={sensors}
                sensorFilterType={SensorFilterType.SensorRoomName}
                childRefChart={childRefMotionsSumIncreaseBySensorChart}
                childRef={childRefMotionsSumIncreaseBySensor}
                setCurrentSensor={setCurrentSensor}
                currentSensor={currentSensor}
                setChartData={setSpecificSensorMotionIncreaseSumData}
                allData={groupMotionsAll}
                data={specificSensorMotionIncreaseSumData}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted}
                chartCalcType={ChartCalcType.IncreaseSum}
            />
        )
    }

    const buildpecificSensorChartsFilterSession = () => {
        return (
            <SpecificSensorCharts
                sensors={sensors}
                sensorFilterType={SensorFilterType.SensorId}
                childRefChart={childRefMotionsBySensorFilterIdChart}
                childRef={childRefMotionsBySensorFilterId}
                setCurrentSensor={setCurrentSensorFilterSession}
                currentSensor={currentSensorFilterSession}
                setChartData={setSpecificSensorMotionDataBySensorId}
                allData={groupMotionsAll}
                data={specificSensorMotionDataBySensorId}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted}
            />
        )
    }

    const buildSpecifcSensorChartsFilterSessionIncreaseSum = () => {
        return (
            <SpecificSensorCharts
                sensors={sensors}
                sensorFilterType={SensorFilterType.SensorId}
                childRefChart={childRefMotionsSumIncreaseBySensorFilterIdChart}
                childRef={childRefMotionsSumIncreaseBySensorFilterId}
                setCurrentSensor={setCurrentSensorFilterSession}
                currentSensor={currentSensorFilterSession}
                setChartData={setSpecificSensorMotionIncreaseSumDataBySensorId}
                allData={groupMotionsAll}
                data={specificSensorMotionIncreaseSumDataBySensorId}
                dataField={groupMotionType}
                loadingCompleted={loadingCompleted}
                chartCalcType={ChartCalcType.IncreaseSum}
            />
        )
    }

    const buildMotionsAggSumDashboard = () => {
        return (
            <MotionsAggSumDashboard
                childRefChart={childRefMotionIncreaseChart}
                updateEndDateFromParent={updateEndDateFromParent}
                updateStartDateFromParent={updateStartDateFromParent}
                updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                childRefMotion={childRefMotionIncrease}
                motions={motionsFromApi} groupIds={groupIds} sensors={sensors}>

            </MotionsAggSumDashboard>
        )
    }

    const buildPrintContent = () => {
        return (
            <div ref={refPrint} className="page-size">
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Frequenzanalyse Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[childRefMotionChart, childRefMotionIncreaseChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetGroupMotion()}
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildMotionsAggSumDashboard()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildSpecifcGetGroupMotion()}
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildSpecificSensorCharts()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildSpecificSensorChartsIncreaseSum()}
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildpecificSensorChartsFilterSession()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildSpecifcSensorChartsFilterSessionIncreaseSum()}
                    </Grid>
                </Grid>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Frequenzanalyse Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }

    const firstPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    {buildMotionDataTable()}
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[childRefMotionChart, childRefMotionIncreaseChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    {buildGetGroupMotion()}
                </Grid>
            </Grid>
        )
    }

    const secondPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12} lg={12}>
                    {buildFrequencyAvgPercentChart()}
                </Grid>
                <Grid item xs={12} lg={12}>
                    {buildFrequencyGroupChart()}
                </Grid>
                <Grid item xs={12} lg={12}>
                    {buildMotionsAggSumDashboard()}
                </Grid>
                <Grid item xs={12} lg={12}>
                    {buildSpecifcGetGroupMotion()}
                </Grid>
            </Grid>
        )
    }

    const thirdPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12} lg={6}>
                    {buildSpecificSensorCharts()}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildSpecificSensorChartsIncreaseSum()}
                </Grid>
            </Grid>
        )
    }

    const fourthPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12} lg={6}>
                    {buildpecificSensorChartsFilterSession()}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildSpecifcSensorChartsFilterSessionIncreaseSum()}
                </Grid>
            </Grid>
        )
    }

    const [page, setPage] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(true);
    const allItems = [firstPartContent, secondPartContent, thirdPartContent, fourthPartContent];
    const fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        if (page <= allItems.length - 1) {
            setPage((curVal) => curVal + 1);
        } else {
            setHasMore(false);
        }
    };

    const buildGridContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Frequenz</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
                </Grid>
                <Grid item style={{ minHeight: 300 }}>
                    <InfiniteScroll
                        style={maxContentWrapperPortalStyle}
                        dataLength={page} //This is important field to render the next data
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <Paper style={{ margin: 5 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size={"large"}
                                    startIcon={<ExpandMore />}
                                    //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                                    onClick={async () => {
                                        fetchMoreData();
                                    }}
                                >
                                    Mehr Laden...
                                </Button>
                            </Paper>
                        }
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Ende der Frequenzanalyse erreicht!</b>
                            </p>
                        }
                    // below props only if you need pull down functionality
                    //        refreshFunction={this.refresh}
                    >
                        <Grid container direction={"column"} spacing={2}>
                            {page >= 0 && firstPartContent()}
                            {page >= 1 && secondPartContent()}
                            {page >= 2 && thirdPartContent()}
                            {page >= 3 && fourthPartContent()}
                        </Grid>
                    </InfiniteScroll>
                </Grid>
            </Grid>
        );
    }

    return isPrintPage ? buildPrintContent() : buildGridContent();

}

export const halfMotions = (motionChartAllData: GroupMotion[]) => {
    motionChartAllData.forEach((element: any) => {
        if (element.blockCount && element.blockCount >= 1) element.blockCount = Math.round(element.blockCount / 2);
    });
}
export const loadMotionDataForSensor = async (sensors: Sensor[], setCurrentSensor: any, motionChartAllData: any, setSpecificSensorMotionData: any) => {
    let currentSensor = sensors[0];
    setCurrentSensor(currentSensor);
    let allMotionTypes = getAllMotionTypes();
    let specificSensorMotionData = generateChartData(motionChartAllData, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allMotionTypes);
    //  setCleaningTaskUserDataFields(allUserItems);
    setSpecificSensorMotionData(specificSensorMotionData);
}

export const loadMotionDataForMultipleSensors = async (sensors: Sensor[], motionChartAllData: any, setMultipleSensorMotionData: any) => {
    let allRoomNames = sensors.map(el => el.sensorRoomName);
    let realData = motionChartAllData.map((el: any) => {
        let room = el["sensorRoomName"];
        el[room] = el.blockCount;
        return el;
    })
    let specificSensorMotionData = generateChartData(realData, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allRoomNames);
    //  setCleaningTaskUserDataFields(allUserItems);
    console.log(realData, "realDataForMultipl");
    setMultipleSensorMotionData(specificSensorMotionData);
}

export const filterAfterSensorRoomName = (data: any, sensor: Sensor) => {
    return data.filter((el: any) => {
        return el.sensorRoomName === sensor.sensorRoomName
    });
}

export const filterAfterSensorId = (data: any, sensor: Sensor) => {
    return data.filter((el: any) => {
        return el.id === sensor.id
    });
}

export const loadMotionData = async (
    setGroupMotionsAll: any,
    setGroupMotionType: any,
    setGroupMotions: any,
    setLoadingCompleted: any,
    aggregateType: AggregateType,
    motions: any = null,
    setMotionTableData: any = null,
    allMotionTypes: string[] = []) => {

    if (setMotionTableData) {
        setMotionTableData([...motions])
    }

    if (allMotionTypes.length == 0) {
        allMotionTypes = getAllMotionTypes();
    }

    let motionChartAllData = [];
    if (aggregateType === AggregateType.Time || aggregateType === AggregateType.SumIncrease) {

        motionChartAllData = generateMotionsChartData(motions);
        setGroupMotionsAll(motionChartAllData);
        let motionChartData = generateChartDataWMissingDay(motionChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allMotionTypes, true);
        setGroupMotionType(allMotionTypes)
        setGroupMotions(motionChartData);


    } else if (aggregateType === AggregateType.Sum) {

        motionChartAllData = generateMotionsChartDataSum(motions);
        motionChartAllData = mapChartKeyFeedback(motionChartAllData);
        //       motionChartAllData.forEach((element: any) => {
        //           if (element.value && element.value > 1) element.value = Math.round(element.value / 2);
        //       });

        setGroupMotionsAll(motionChartAllData);
        let motionChartData = generateSumChartData(motionChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allMotionTypes);
        setGroupMotionType(allMotionTypes)
        setGroupMotions(motionChartData);
    }

    setLoadingCompleted(true);
    return motionChartAllData;
}
