export class SetPointActualValueModel {
    public uri!: string;
    public date !: Date;
    public dateDisplay !: string;

    public setPointServiceTime!: number;
    public actualServiceTime!: number;

    public setPointcarbonDixoideConsumption !: number;
    public actualCarbonDixoideConsumption !: number;

    public setPointAreaSize !: number;
    public actualAreaSize !: number;

    public setPointServiceModesCount !: number;
    public actualServiceModesCount !: number;
}