import axios from 'axios';
import { headers } from '../api';
import { AzureStorageBrowserModel } from '../models/AzureStorageBrowserModel';
import { UserAccount } from '../models/UserAccount';

export const getNews = async (): Promise<AzureStorageBrowserModel[]> => {
    try {
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/blobs/news`, { headers: { ...headers} })
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
    }
    return null as any;
}