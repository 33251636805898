import {  formatISO } from "date-fns";
import { GroupServiceCompletedCleaningTasks, GroupServiceIsOnTimeCleaningTasks, GroupServiceMode, GroupServiceModeMaterialConsumption } from "../../models/GroupServiceMode";
import { Sensor } from "../../models/GroupSettings";

export const generateMaterialConsumptionChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let arr = groupsServiceMode.map(el => {
        let materials = el.materialConsumptionItems.reduce((acc:any, curVal:GroupServiceModeMaterialConsumption) => {
            return {...acc, [curVal.text] : curVal.amount}
        },{});
        return {date : formatISO(new Date(el.createdTimestamp * 1000)), ...materials}
    });
   return arr;
}
export const generateMaterialConsumptionSumChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        let materials = el.materialConsumptionItems.reduce((acc:any, curVal:GroupServiceModeMaterialConsumption) => {
            return {...acc, [curVal.text] : curVal.amount}
        },{});
        Object.keys(materials).forEach(key=>{
            res.push({date : formatISO(new Date(el.createdTimestamp * 1000)), key: key, value: materials[key]});
        })
    });
   return res;
}

export const getAllMaterial = (groupsServiceMode : GroupServiceMode[]) => {
    let allMaterials = groupsServiceMode.reduce((acc: any[], curVal :GroupServiceMode) => {
        let res = curVal.materialConsumptionItems.filter(el => !acc.includes(el.text)).map(el=>el.text);
        return [...acc, ...res];
    },[]);
    return allMaterials;
}
export const generateIncidentReportChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let arr = groupsServiceMode.map(el => {
        let incidents = el.incidentReportItems.reduce((acc:any, curVal: any) => {
            return {...acc, [curVal] : 1}
        },{});
        return {date : formatISO(new Date(el.createdTimestamp * 1000)), ...incidents}
    });
    return arr;
}

export const generateIncidentReportSumChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        let incidents = el.incidentReportItems.reduce((acc:any, curVal:any) => {
            return {...acc, [curVal] : 1}
        },{});
        Object.keys(incidents).forEach(key=>{
            res.push({date : formatISO(new Date(el.createdTimestamp * 1000)), key: key, value: incidents[key]});
        })
    });
   return res;
}

export const getAllIncidents = (groupsServiceMode : GroupServiceMode[]) => {
    let allIncidents = groupsServiceMode.reduce((acc: any[], curVal :GroupServiceMode) => {
        let res = curVal.incidentReportItems.filter(el => !acc.includes(el));
        return [...acc, ...res];
    },[]);
    return allIncidents;
}
export const generateCleaningTaskChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let arr = groupsServiceMode.map(el => {
        let tasks = el.cleaningTaskItems.reduce((acc:any, curVal: any) => {
            return {...acc, [curVal] : 1}
        },{});
        return {date : formatISO(new Date(el.createdTimestamp * 1000)), ...tasks}
    });
    return arr;
}

export const generateCleaningTaskSumChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        let tasks = el.cleaningTaskItems.reduce((acc:any, curVal:any) => {
            return {...acc, [curVal] : 1}
        },{});
        Object.keys(tasks).forEach(key=>{
            res.push({date : formatISO(new Date(el.createdTimestamp * 1000)), key: key, value: tasks[key]});
        })
    });
   return res;
}

export const getAllCleaningTasks = (groupsServiceMode : GroupServiceMode[]) => {
    let allIncidents = groupsServiceMode.reduce((acc: any[], curVal :GroupServiceMode) => {
        let res = curVal.cleaningTaskItems.filter(el => !acc.includes(el));
        return [...acc, ...res];
    },[]);
    return allIncidents;
}



export const generateCompletedCleaningTaskChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let arr: GroupServiceCompletedCleaningTasks[] = groupsServiceMode.map(el => {
        return new GroupServiceCompletedCleaningTasks(formatISO(new Date(el.createdTimestamp * 1000)),  el.isCompleted ? 1 : 0, el.isCompleted ? 0 : -1);
    });
    return arr;
}

export const generateCompletedCleaningTaskSumChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        res.push({value : el.isCompleted ? 1 : 0, key: getCompletedCleaningTaskFields[0],date : formatISO(new Date(el.createdTimestamp * 1000))});
        res.push({value : el.isCompleted ? 0 : 1, key: getCompletedCleaningTaskFields[1],date : formatISO(new Date(el.createdTimestamp * 1000))});

    });
    return res;
}

export const getCompletedCleaningTaskFields = ['Alle Services erledigt','Services Teilweise erledigt'];

export const generateIsOnTimeCleaningTaskChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let arr: GroupServiceIsOnTimeCleaningTasks[] = groupsServiceMode.map(el => {
        return new GroupServiceIsOnTimeCleaningTasks(formatISO(new Date(el.createdTimestamp * 1000)),  el.isOnTime ? 1 : 0, el.isOnTime ? 0 : -1);
    });
    return arr;
}

export const generateIsOnTimeCleaningTaskSumChartData = (groupsServiceMode : GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        res.push({value : el.isOnTime ? 1 : 0, key: getIsOnTimeCleaningTaskFields[0],date : formatISO(new Date(el.createdTimestamp * 1000))});
        res.push({value : el.isOnTime ? 0 : 1, key: getIsOnTimeCleaningTaskFields[1],date : formatISO(new Date(el.createdTimestamp * 1000))});

    });
    return res;
}

export const prepareSensorsForChartAutoComplete = (sensors: Sensor[]) => {
    if(sensors)
    {
        let arr = sensors.map((el: Sensor) => {
            return { id: el.id, label: (el.sensorRoomName) }
        })
        return arr;
    }
}

export const getIsOnTimeCleaningTaskFields = ['Püntklich','Verpasst'];