import axios from 'axios';
import { getStorage } from '../../helpers/storage-management';
import { PUBLIC_CURRENT_USER_SERVICE_WORKER, PUBLIC_SERVICEWORKER_TOKEN } from '../../constants';
import { UserServiceWorker } from '../../models/UserServiceWorker';
import { GroupServiceMode } from '../../models/GroupServiceMode';
import { headers } from '../../api';



export const createGroupServiceModePublic = async (groupServiceMode: GroupServiceMode, enqueueSnackbar: any): Promise<GroupServiceMode> => {
  try {
    let token = localStorage.getItem(PUBLIC_SERVICEWORKER_TOKEN);
    let serviceWorkers = await getStorage<UserServiceWorker>(PUBLIC_CURRENT_USER_SERVICE_WORKER);
    groupServiceMode.userServiceWorkerId = serviceWorkers.id;

    let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/service-workers/${serviceWorkers.id}/groups/${groupServiceMode.groupId}/service-mode`,
      { ...groupServiceMode },
      { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
    enqueueSnackbar("Service gestartet", { variant: 'success' });

    return ret.data;
  } catch (err) {
    enqueueSnackbar("Error beim Service starten", { variant: 'error' });
  }
  return groupServiceMode;
}