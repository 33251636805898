import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useGroupLogoDialogStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minWidth: 128,
        minHeight: 128,
        maxWidth: '100%',
        maxHeight: '100%',
    },
})
);
