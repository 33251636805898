import { objectCustomerName } from "../components/authorized/portal/ManagerCustomers";
import { SELECTED_USER_ID_FROM_ADMIN, USER_ID } from "../constants";
import { EvaluationChoice } from "../models/EvaluationChoice";
import { AppointmentCosmosDbModel } from "../models/GroupSettings";
import { UserType } from "../models/UserType";

export const getUserIdFromStorage = () => {
    let userId;
    userId = localStorage.getItem(SELECTED_USER_ID_FROM_ADMIN);

    if (!userId) userId = localStorage.getItem(USER_ID);
    return userId || "";
}

export const getOwnUserIdFromStorage = () => {
    let userId;
    userId = localStorage.getItem(USER_ID);
    return userId;
}


export const toMoneyString = (val: any) => {
    return val.toLocaleString(undefined, { minimumFractionDigits: 2 });
}

export const toCurrencyString = (value: any) => value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });

export const getGrossAmount = (val: any) => {
    return val * 20 / 100;
}

export const getProportion = (x: any, y: any) => {
    if (!x || !y) return 0;
    return (y / x * 100).toFixed(2);
}

export const getRatioNumber = (x: any, y: any) => {
    if (!x || !y) return 0;
    return parseFloat((y / x * 100).toFixed(2));
}

export const getHowManyVisitorNeedOneMaterial = (visitors: any, materials: any) => {
    if (!visitors || !materials) return null;
    return (visitors / materials).toFixed(2);
}

export const getHundredVisitorNeedHowManyMaterials = (visitors: any, materials: any) => {
    if (!visitors || !materials) return 0;
    return ((materials / visitors) * 100)
}

export const getCountNumber = (x: any, y: any) => {
    if (!x || !y) return 0;
    return Math.round(x / y);
}

export const getAvgFromSimpleChart = (res: any[]) => {
    return Math.round(res.reduce((prevVal, curVal) => {
        return prevVal + curVal.valueFieldService;
    }, 0) / res.length)
}

export const getAvgFromSimpleChartValueField = (res: any[]) => {
    return Math.round(res.reduce((prevVal, curVal) => {
        return prevVal + curVal.valueField;
    }, 0) / res.length)
}

export const mapLabelUserType = (userType: UserType | undefined): string => {
    switch (userType) {
        case UserType.Owner:
            return "Besitzer";
        case UserType.Customer:
            return "Kunde"
        case UserType.Manager:
            return "Manager"
        case UserType.Admin:
            return "Admin";
        case UserType.ObjectCustomer:
            return objectCustomerName;
    }

    return '';
}

export const getEvaluationChoice = (groupId: any, groupIds: any[]) => {
    if (groupIds && groupIds.length > 0) {
        return EvaluationChoice.Multiple;
    } else if (groupId) {
        return EvaluationChoice.Single;
    } else {
        return EvaluationChoice.All;
    }
}

export const getGroupIdsForPrint = (groupId: any, groupIds: any) => {
    if (groupId) {
        return [groupId];
    } else if (groupIds && groupIds.length > 0) {
        return groupIds;
    } else {
        return [];
    }
}

export const trimPathname = (pathname: string) => {
    if (pathname.endsWith("/")) {
        return pathname.substring(0, pathname.length - 1);
    }
    return pathname;
}

export const calcSum = (data: any) => {
    return data.reduce((s: any, p: any) => s + p.value, 0);
}

export const calcKeySum = (data: any, key: any) => {
    if (!data) return 0
    return data.reduce((s: any, p: any) => {
        if (key === p.key) {
            return s + p.value;
        }
        return s;
    }, 0);
}

export const sumNumberArray = (arr: number[]) => {
    return arr.reduce((previous: number, current: number) => previous + current, 0);
}

export const makeValidAppointments = (appointments: AppointmentCosmosDbModel[]) => {
    return appointments.map(x => {
        x.startDate = new Date(x.startDate);
        x.endDate = new Date(x.endDate);
        return x;
    });
}