import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    Legend,
    Margin,
    Tooltip,
    Grid,
    ValueAxis,
    Label,
    ZoomAndPan,
    Size
} from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { chartHeight, ChartLabelType, customizeLegendTextBarLineChart, customizePercentageText, mapLabels, NoDataChartLoader } from './Base';
import { ChartHeightField, FullscreenButton, ResetButtonZoom } from './StackedChart';
import React from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { FullScreenChartDialog } from './dialog/FullScreenChartDialog';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});

export enum ChartLabelAlternative {
    Standard = 0,
    Lora = 1,
}

export const LineDraw = ({ data, dataField, text, loadingCompleted, chartLabelType, chartLabelAlternative = ChartLabelAlternative.Standard, childRefChart, colors }: { data: any[], dataField: any[], text?: string, loadingCompleted: boolean, chartLabelType?: ChartLabelType, chartLabelAlternative? : ChartLabelAlternative, childRefChart?: any, colors?: any }) => {
    const [isFullScreenChartVisible, setIsFullscreenChartVisible] = React.useState(false);
    const [chartHeightNow, setChartHeightNow] = React.useState(chartHeight);

    if (!data || data.length === 0)
        return <NoDataChartLoader title={text} data={data} loadingCompleted={loadingCompleted} />;

    const buildLabel = () => {
        switch (chartLabelType) {
            case ChartLabelType.Percent:
                return (
                    <ValueAxis>
                        <Label customizeText={customizePercentageText} />
                    </ValueAxis>
                )
            case ChartLabelType.Comma:
                return (
                    <ValueAxis>
                        <Label customizeText={({ valueText }: any) => { return `${valueText}` }} />
                    </ValueAxis>
                )
            default:
                return <ValueAxis allowDecimals={false} />
        }
    }

    const customizeTooltip = (pointInfo: any, chartLabelAlternative: ChartLabelAlternative = ChartLabelAlternative.Standard) => {
        let htmlContent = ""
        for (let point of pointInfo.points.slice(1)){
            let newContent = `<div class="series-name"><span class='bottom-series-name'>${mapLabels(point.seriesName, chartLabelAlternative)}</span>: </div><div class="value-text"><span class='bottom-series-value'>${point.valueText}</span>${ChartLabelType.Percent == chartLabelType ? "%" : ""}</div>`;
            htmlContent += newContent;
        }
        return {
            html: `<div><div class="tooltip-header">${pointInfo.argumentText}</div><div class="tooltip-body"><div class="series-name">
            <span class='top-series-name'>${mapLabels(pointInfo.points[0].seriesName, chartLabelAlternative)}</span>: </div><div class="value-text"><span class='top-series-value'>${pointInfo.points[0].valueText}</span></div>${htmlContent}
            </div>`,
        };
    }

    const buildChart = () => {
        return (
            <>
                <Chart title={text} dataSource={data}
                    ref={childRefChart}
                    palette={colors} >
                    <Size
                        height={chartHeightNow}
                        width={'100%'}
                    />
                    <CommonSeriesSettings argumentField="country" type="line" />
                    {
                        dataField.map((field: any, id: any) => {
                            return <Series valueField={field} argumentField="dateDisplay" name={field} key={id} />;
                        })
                    }
                    <ZoomAndPan
                        valueAxis="both"
                        argumentAxis="both"
                        dragToZoom={true}
                        allowMouseWheel={false}
                        panKey="shift" />
                    <Margin bottom={20} />
                    <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode="crossLabels"                >
                        <Grid visible={true} />
                    </ArgumentAxis>
                    {buildLabel()}
                    <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom" customizeText={customizeLegendTextBarLineChart} />
                    <Export enabled={true} />
                    <Tooltip shared={true} enabled={true} customizeTooltip={(point: any)=>customizeTooltip(point, chartLabelAlternative)} />
                </Chart>
                <MuiGrid container direction="row" spacing={3} alignItems="center">
                    <MuiGrid item>
                        <ChartHeightField chartHeight={chartHeightNow} setChartHeight={setChartHeightNow} />
                    </MuiGrid>
                    <MuiGrid item>
                        <ResetButtonZoom chartRef={childRefChart} />
                    </MuiGrid>
                    <MuiGrid item>
                        <FullscreenButton isFullScreenVisible={isFullScreenChartVisible} setIsFullscreenChartVisible={setIsFullscreenChartVisible} setChartHeight={setChartHeightNow} />
                    </MuiGrid>
                </MuiGrid>
            </>
        )
    }

    return isFullScreenChartVisible ? <FullScreenChartDialog setChartHeight={setChartHeightNow} visible={isFullScreenChartVisible} title={text || ""} onClose={() => { setIsFullscreenChartVisible(false) }} buildChart={buildChart} /> : buildChart();
}

export const LineChart = withStyles(demoStyles, { name: 'Demo' })(LineDraw);
