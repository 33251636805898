import { Downloading, FilterAlt, Warning } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { GlobalContext } from '../../authorized/PortalPage';
import { BuildDateFields } from '../../core/GlobalTimeChoice';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { useTranslation } from 'react-i18next';

export const PreFilterDialog = ({ title, onClose }
    : { title: string, onClose: any; }) => {

    const { isPreFilterDialogVisible }: { isPreFilterDialogVisible: boolean } = React.useContext(GlobalContext);

    const {t} = useTranslation();

    return (
        <Dialog fullWidth maxWidth="xl" style={{ backgroundColor: 'rgb(0,0,0,0.9)' }} open={isPreFilterDialogVisible} onClose={onClose}>
            <DefaultDialogTitle
                title={t("filterSettings.filterSetting")}
                handleClose={onClose}
            />
            <DialogContent style={{ padding: 30 }}>
                <Grid container alignItems={"center"} spacing={4}>
                    <Grid item>
                        <FilterAlt style={{ fontSize: 45 }} color='primary' />
                    </Grid>
                    <Grid item>
                        <Typography color={"primary"} style={{ fontSize: 40, fontWeight: 'bold' }}>{title}</Typography>
                    </Grid>

                </Grid>
                <BuildDateFields updateEndDateFromParent={undefined} updateStartDateFromParent={undefined} updateChosenPastDaysFromParent={undefined} updateDataFilterTypeFromParent={undefined} showLoadingButton={false} />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Downloading />}
                    onClick={async () => {
                        onClose();
                    }}
                >
                    {t("filterSettings.load")}
                </Button>
            </DialogActions>

        </Dialog>
    );
};
