import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Paper, Typography, Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import { api } from "../../../../api";
import { getGroupPayPerServiceByGroupIds } from "../../../../helpers/charts/base";
import { getUserIdFromStorage } from "../../../../helpers/common";
import { defaultEndDate, defaultStartDate } from "../../../../helpers/date-management";
import { getApiToken } from "../../../../helpers/storage-management";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { AnalyticSummaryIncidentVisitorDataTable } from "./feedbacks/AnalyticSummaryIncidentVisitorDataTable";
import { InlineDateTimePicker } from "../../../core/InlineDateTimePicker";
import { GroupPayPerService } from "../../../../models/GroupPayPerService";
import { GroupPayPerServiceDataTable } from "./feedbacks/GroupPayPerServiceDataTable";
import React from "react";
import { GroupSettingsIncidenReport } from "../../../../models/GroupSettings";
import { endOfDay, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";

export const AnalyticSummaryPayPerServiceDataTable = ({ groupsLicenseTableEntries, groupId, groupIds }: { groupsLicenseTableEntries: GroupLicenseTable[], groupId: string, groupIds: string[] }) => {
    const [selectedEndDate, setSelectedEndDate] = React.useState(endOfDay(defaultEndDate));
    const [selectedStartDate, setSelectedStartDate] = React.useState(startOfDay(defaultStartDate));
    const [settings, setSettings] = React.useState<GroupSettingsIncidenReport[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [payPerServiceItems, setPayPerServiceItems] = React.useState<GroupPayPerService[]>([]);
    const history = useHistory();
    const {t} = useTranslation();
    const buildEndDateField = () => {
        return (
            <Grid item>
                <InlineDateTimePicker includeHours={true} title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={(event: any) => {
                    let date = new Date(event);
                    date.setHours(23,59,59);
                    setSelectedEndDate(date);
                }}></InlineDateTimePicker>
            </Grid>
        )
    }
    const buildStartDateField = () => {
        return (
            <Grid item>
                <InlineDateTimePicker includeHours={true} title={t("serviceDocumentationTable.startDate")} selectedDate={selectedStartDate} setSelectedDate={(event: any) => {
                    setSelectedStartDate(new Date(event));
                }}></InlineDateTimePicker>
            </Grid>
        )
    }

    const buildPayPerServiceDataTable = () => {
        return (
            <Paper>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Pay Per Service Log Tabelle
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {payPerServiceItems.length}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GroupPayPerServiceDataTable groups={groupsLicenseTableEntries} rows={payPerServiceItems} selectedEntries={[]} onSelectionChange={() => { }} />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        )
    }

    return (
        <Paper>
            <Accordion onChange={async (event, expanded) => {
                if (expanded) {
                    const token = getApiToken(history);
                    setLoading(true);

                    let payPerServiceData = await getGroupPayPerServiceByGroupIds(groupId, token, groupIds)
                    setPayPerServiceItems(payPerServiceData);

                    let settings = await api.GetGroupSettingsByUserGroupIdsAsync(token, getUserIdFromStorage() || "", groupsLicenseTableEntries.map(x => x.id));
                    let incidentReports = settings.flatMap(x => {
                        x.payPerServiceControl?.payPerServiceItems.forEach(payPerServiceItem => {
                            payPerServiceItem.groupId = x.id || "";
                        })
                        return x.payPerServiceControl?.payPerServiceItems || [];
                    });
                    setSettings(incidentReports);
                    setLoading(false);
                }

            }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        md={6}
                    >
                        <Grid item>
                            <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                            >
                                Pay Per Service - Analyse
                            </Typography >
                        </Grid>
                        <Grid item>
                            <Typography
                                className="Paper-Result"
                                component="p"
                            >
                                {settings.length > 0 && !loading ? settings.length : "Öffnen, um zu laden"}
                            </Typography >
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            {buildPayPerServiceDataTable()}
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">Kalendar Ansicht Täglich</Typography>
                        </Grid>
                        <Grid item container direction="row" spacing={3}>
                            {buildStartDateField()}
                            {buildEndDateField()}
                        </Grid>

                        {loading && <Grid item><CircularProgress size={64} /></Grid>}
                        {settings.length > 0 && !loading &&
                            <Grid item>
                                <AnalyticSummaryIncidentVisitorDataTable groups={groupsLicenseTableEntries} groupPayPerService={payPerServiceItems} rows={settings} selectedEntries={[]} onSelectionChange={() => { }} startDate={selectedStartDate} endDate={selectedEndDate} />
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}