import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { CircularProgress, DialogActions, DialogContent, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ObjectSelectionDataTable } from '../entireEval/ObjectSelectionDataTable';
import { Apartment, TouchApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const AddSharedFolderDialog = ({ open, setOpen, onFinished, selectedObjectUnit, setSelectedObjectUnit, objects }
    : { open: boolean, setOpen: any, onFinished: any, selectedObjectUnit?: ObjectUnit | undefined, setSelectedObjectUnit?: any, objects: ObjectUnit[] }) => {

    const classes = useStyles();
    const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    React.useEffect(() => {
        if (open && selectedObjectUnit) {
            (async () => {
                await onFinished(name);
                setLoading(false);
                handleClose();
            })();


        }
    }, [selectedObjectUnit])

    const [name, setName] = React.useState("");

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"lg"}
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    Geteilter Ordner hinzufügen
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container direction={'column'} alignItems="center" spacing={3}>
                        <Grid item container direction={'row'} alignItems="center" spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    style={{ width: '100%' }}
                                    id="filled-number"
                                    label="Name des Ordners"
                                    variant="filled"
                                    value={name}
                                    onChange={(event: any) => { setName(event.target.value) }}
                                />
                            </Grid>

                        </Grid>
                        <Grid item container direction={'column'} alignItems="center" spacing={3}>

                            <Grid item style={{ maxWidth: "-webkit-fill-available" }}>
                                <ObjectSelectionDataTable rows={objects} groups={[]} currentGroup={null}
                                    selectedObjects={selectedObjects}
                                    onSelectionChange={(selection: string[]) => {
                                        let selected = selection[selection.length - 1];
                                        let newObject = objects.find(x => x.id == selected);
                                        if (newObject) {
                                            setSelectedObjects([newObject]);
                                        }
                                    }}
                                />
                            </Grid>

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button
                        size="large"
                        startIcon={<AddBoxIcon />}
                        disabled={!name || name.length < 1 || selectedObjects.length < 1 || loading}
                        variant={'contained'}
                        endIcon={loading && <CircularProgress size={32} />}
                        color="primary"
                        onClick={async () => {
                            setLoading(true);
                            setSelectedObjectUnit(() => selectedObjects[0]);

                        }}
                    >
                        Objekt Auswählen
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));