import { getUserIdFromStorage } from '../../helpers/common';
import { GroupSettingsSideBarImageItem, GroupSettingsType } from '../../models/GroupSettings';
import { NetworkMedia } from '../images/NetworkMedia';

export const GroupSideBarImagesDialogSingleImageView = ({ groupId, item, type }: { groupId: string, item: GroupSettingsSideBarImageItem | null, type: GroupSettingsType }) => {

    if (!item) return null;

    const userId = getUserIdFromStorage();

    let uri = '';
    switch (type) {
        case GroupSettingsType.Template:
            uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${groupId}/images/sidebars/${item.fileName}`;
            break;
        default:
            uri = `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/images/sidebars/${item.fileName}`;
            break;
    }

    return <NetworkMedia
        src={uri}
        contentType={item.contentType}
        videoControlsVisible={true} />;
};
