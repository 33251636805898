import { GroupSettingsIntervalControl } from "./GroupSettings";

export class IntervalControl {
    public id!: string;
    public systemETag!: string;
    public systemTimestamp!: string;
    public userId !: string;
    public createdTimestamp !: string;
    public name !: string;

    public interval !: GroupSettingsIntervalControl

    static Columns = {
        id: 'id',
        intervalControl: 'intervalControl',
        actions: "actions",
        name: "name",
        createdTimestamp : "createdTimestamp",
        deviationTime: "deviationTime",
        entry: "entry",
        reactionTime: "reactionTime",
        cleaningOperations: "cleaningOperations",
        weekdays: 'weekdays'
    }

    constructor () {

    }

    static CreateInstance = () => {
        let intervalControl = new IntervalControl();
        intervalControl.interval = new GroupSettingsIntervalControl();
        return intervalControl;
    }

}
