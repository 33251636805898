import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { DEFAULT_DAYS_OF_WEEK_ENABLED } from '../models/defaults';
import { GroupSettings } from '../models/GroupSettings';
import { GroupSettingsExtended, GroupSettingsLight } from '../models/GroupSettingsExtended';

export const getGroupSettings = async (groupId: string, userToken: string): Promise<GroupSettings> => {
    try {
        let userId = getUserIdFromStorage();
        const ret = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/settings`,
            { headers: { ...headers, 'Authorization': `Bearer ${userToken}` } });
        return ret.data;

    } catch (err) {
        handleApiError(err);
    }
    return null as any;
}

export const getGroupSettingsExtended = async (groupId: string, userToken: string): Promise<GroupSettingsExtended> => {
    let userId = getUserIdFromStorage();
    try {

        const ret = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/settings-extended`,
            { headers: { ...headers, 'Authorization': `Bearer ${userToken}` } });
        return ret.data;

    } catch (err) {
        handleApiError(err);
    }
    return null as any;
}

export const getGroupSettingsLight = async (groupId: string, userId: string): Promise<GroupSettingsLight> => {
    try {

        const ret = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/settings-light`,
            { headers: { ...headers } });
        return ret.data;

    } catch (err) {
        handleApiError(err);
    }
    return null as any;
}