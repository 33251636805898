import axios from 'axios';
import { getStorage } from '../../helpers/storage-management';
import { PUBLIC_CURRENT_USER_SERVICE_WORKER, PUBLIC_SERVICEWORKER_TOKEN } from '../../constants';
import { UserServiceWorker } from '../../models/UserServiceWorker';
import { GroupServiceMode } from '../../models/GroupServiceMode';
import { handleDates, headers } from '../../api';
import { UserServiceWorkerObjectSession } from '../../models/UserServiceWorkerObjectSession';



export const createServiceWorkerObjectSessionPublic = async (serviceWorkerSession: UserServiceWorkerObjectSession, enqueueSnackbar: any): Promise<UserServiceWorkerObjectSession> => {
  try {

    let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${serviceWorkerSession.userId}/create-service-worker-object-session-public`,
      { ...serviceWorkerSession },
      { headers: { ...headers } });
    enqueueSnackbar("Login gestartet", { variant: 'success' });

    return handleDates(ret.data) as UserServiceWorkerObjectSession;
  } catch (err) {
    enqueueSnackbar("Error beim Login starten", { variant: 'error' });
  }
  return serviceWorkerSession;
}