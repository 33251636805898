import axios from 'axios';
import { headers } from '../api';
import { USER_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';

export const requestChangeUserEmailAsync = async (email: string, token: string, enqueueSnackbar: any) => {

    try {
        const userId = getUserIdFromStorage();

        await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/email/change`,
            { email },
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } });

        enqueueSnackbar("Email Änderung wurde beantragt", { variant: 'success' });

    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Email Änderung Antrag ist fehlgeschlagen", { variant: 'error' });
    }
}