import React from 'react';
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, DeleteForever, Group, TouchApp } from '@mui/icons-material';
import { formatEuropeanDate } from '../../helpers/date-management';
import { GroupLicense } from '../../models/GroupLicense';
import { GroupLicenseTable, SensorLicense } from '../../models/GroupLicenseTable';
import { ReactComponent as LicenseSvg } from '../../assets/svgs/license_grey.svg'
import { ReactComponent as MotionSensorSvg } from '../../assets/svgs/motion-sensor.svg';
import { red, white } from '../../styles/colors';
import { MAX_SENSOR_COUNT } from '../../models/defaults';
import { useTranslation } from 'react-i18next';

export const AssignSensorLicenseChoosingTableDialog = ({ visible, onClose, currentSelectedLicense, licenses, onLicenseSelected, sensorCount }
    : { visible: boolean, onClose: any, currentSelectedLicense: GroupLicense, licenses: SensorLicense[], onLicenseSelected: any, sensorCount: number }) => {

    const classes = useStyles();

    const [selection, setSelection] = React.useState<SensorLicense[]>([]);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    React.useEffect(() => {

        setSelection([]);
        setLoading(false);

    }, [visible])

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={visible}
            onClose={onClose}
        >
            <DialogTitle className={classes.dialogTitle}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography className={classes.dialogTitleText}>{t("myUnitsSettings.selectSensorLicense")}</Typography>
                        <IconButton color="inherit" onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography>{sensorCount} {t("myUnitsSettings.sensorForManagement")}</Typography>
                <List>
                    {
                        licenses &&
                        licenses.length > 0 &&
                        licenses.map((item: SensorLicense, index: number) => (
                            <ListItem
                                key={index}
                                button
                                onClick={() => {
                                    if (selection.some(x => x.id == item.id)) {
                                        setSelection(selection.filter(x => x.id != item.id));
                                    } else if (selection.length < sensorCount) {
                                        setSelection([...selection, item]);
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selection.some(x => x.id == item.id)}
                                        tabIndex={-1}
                                        inputProps={{}}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={`${"myUnitsSettings.validUntil"}: ${formatEuropeanDate(item.sensorLicenseValidUntilDate)}`} />
                                <ListItemIcon>
                                    <MotionSensorSvg />
                                </ListItemIcon>
                            </ListItem>
                        ))
                    }
                </List>
                {licenses.length + selection.filter(el => el.id == "").length < MAX_SENSOR_COUNT &&
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            disabled={selection.length >= sensorCount}
                            onClick={async () => {
                                setSelection([...selection, new SensorLicense()]);
                            }}
                        >
                            {t("myUnitsSettings.addNewSensorRoom")}
                        </Button>
                    </>
                }
                <List>
                    {
                        selection &&
                        selection.length > 0 &&
                        selection.filter(el => el.id == "").map((item: SensorLicense, index: number) => (
                            <ListItem
                                key={index}
                            >
                                <ListItemIcon>
                                    <MotionSensorSvg />
                                </ListItemIcon>

                                <ListItemText primary={`${t("myUnitsSettings.newSensorRoom")}`} />
                                <ListItemIcon onClick={() => {
                                }}>
                                    <Button
                                        style={{ marginLeft: 30 }}
                                        variant="contained"
                                        className={classes.deleteButton}
                                        startIcon={<Delete />}
                                        onClick={async () => {
                                            selection.splice(selection.findIndex(el=>el.id == ""), 1);
                                            setSelection([...selection]);
                                        }}
                                    >
                                        {t("myUnitsSettings.removeNewSensorRoom")}
                                    </Button>
                                </ListItemIcon>
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={selection.length < sensorCount}
                    startIcon={<TouchApp />}
                    onClick={async () => {

                        if (!selection) return;

                        await onLicenseSelected(selection);
                    }}
                >
                    {t("myUnitsSettings.UseSensorLicenseNow")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 0,
        flexGrow: 1,
    },
    dialogTitleText: {
        flexGrow: 1,
    },
    dialogContent: {
        padding: theme.spacing(2),
        height: 320,
    },
    dialogContentRadio: {
        padding: theme.spacing(1),
    },
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));