import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupSettingsSideBarImageItem, GroupSettingsType } from "../models/GroupSettings";

export const listGroupSettingsSideBarSettingsAsync = async (groupId: string, type: GroupSettingsType, token: string): Promise<GroupSettingsSideBarImageItem[]> => {

    try {

        const userId = getUserIdFromStorage();

        let uri: string = '';
        switch (type) {
            case GroupSettingsType.Template:
                uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${groupId}/sidebar-settings`;
                break;
            default:
                uri = `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/sidebar-settings`;
                break;
        }

        const ret = await axios.get(uri, { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return ret.data;

    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        return null as any;
    }
};
