import { Button, CircularProgress, Divider, Grid, IconButton, Paper, TextField, Toolbar, Tooltip, Typography } from "@mui/material"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { api } from "../../../../../api";
import { mapGroupSettings } from "../../../../../helpers/group-settings-management";
import { getApiToken } from "../../../../../helpers/storage-management";
import { GroupSettings, GroupSettingsType } from "../../../../../models/GroupSettings";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { GroupSettingFields } from "../../../../core/GroupSettingFields";
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { GROUP_ID, GROUP_NAME } from "../../../../../constants";
import { Grouping } from 'devextreme-react/data-grid';
import { DEFAULT_DAYS_OF_WEEK_ENABLED } from "../../../../../models/defaults";
import { UserGroupSettingsTemplate } from "../../../../../models/UserGroupSettingsTemplate";
import { TemplateSettingsSaveDialog } from "../../../../dialogs/TemplateSettingsSaveDialog";
import { Group, PortalSettings } from "../../../../../models/Group";
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import { LiveHelp } from "@mui/icons-material";
import CheckMeNowIcon from '../../../../../assets/svgs/check-me-now_icon.svg'
import { UserAccount } from "../../../../../models/UserAccount";
import { GlobalContext, SettingsEditContext } from "../../../PortalPage";
import { UserType } from "../../../../../models/UserType";
import { RightsHelper } from "../../../../../helpers/rights-helper";
import { useTranslation } from "react-i18next";

export const GroupSettingsPage = ({ }: {}) => {
    const { enqueueSnackbar, } = useSnackbar();
    const [row, setGroupSettings] = React.useState<GroupSettings>();
    const [group, setGroup] = React.useState<Group>();
    const [token, setToken] = React.useState("");
    const history = useHistory();
    const [disabled, setDisabled] = React.useState(false);
    const [groupName, setGroupName] = React.useState("");
    const [isTemplateDialogVisible, setIsTemplateDialogVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();

    const { groupIdFromQuery } = useParams<{ groupIdFromQuery: string }>();

    const [groupId, setGroupId] = React.useState(groupIdFromQuery);
    const { userAccount }: { userAccount: UserAccount } = React.useContext(GlobalContext);

    const [teamViewerId, setTeamViewerId] = React.useState("");
    const [simId, setSimId] = React.useState("");

    const onChange = (event: any) => setGroupSettings(mapGroupSettings(row, event.target.name, event.target.value));

    const onSave = async () => {

        if (!row?.id) return;

        setDisabled(true);
        let portalSettings = new PortalSettings();
        portalSettings.identifierDetails.teamViewerId = teamViewerId;
        portalSettings.identifierDetails.simId = simId;

        await api.patchGroupPortalSettings(row.id, portalSettings, token, enqueueSnackbar);
        await api.updateGroupSettings(row, token, enqueueSnackbar);
        let temp = await api.getGroupSettings(groupId, token);
        setGroupSettings(temp);
        setDisabled(false);
    }



    React.useEffect(() => {

        (async () => {
            await loadData();

        })();
    }, []);

    const loadData = async () => {
        setGroupSettings(undefined);
        const tempGroupname = localStorage.getItem(GROUP_NAME);

        setGroupName(tempGroupname || "");

        const tokenTemp = getApiToken(history);
        setToken(tokenTemp);

        if (!tokenTemp) return;
        let tempGroupId = groupId;
        if (!tempGroupId) {
            tempGroupId = localStorage.getItem(GROUP_ID) || "";
        }
        setLoading(true);
        const tempGroupSettings = await api.getGroupSettingsExtended(tempGroupId, tokenTemp);
        setLoading(false);
        setGroupId(tempGroupId);
        setGroupSettings(tempGroupSettings.groupSettings);
        setGroup(tempGroupSettings.group);
        setTeamViewerId(tempGroupSettings?.group?.portalSettings?.identifierDetails?.teamViewerId ?? "");
        setSimId(tempGroupSettings?.group?.portalSettings?.identifierDetails?.simId ?? "");
        return tempGroupSettings;
    }

    if (loading) {
        return (
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Typography variant="h3">{t("settingsPage.loading")} Settings...</Typography>
                <CircularProgress size={200} />
            </Paper>
        )
    }


    return (
        <Grid container direction="column" justifyContent="center" alignItems="stretch" style={{ marginLeft: -24, width: 'calc(100% + 48px)' }}>
            <SettingsEditContext.Provider value={{
                teamViewerId: teamViewerId,
                setTeamViewerId: setTeamViewerId,
                simId : simId,
                setSimId : setSimId
            }}>
                <Grid item>
                    <AppBar position="sticky">
                        <Toolbar>
                            <Grid item xs={12} container direction={'row'} spacing={3} alignItems="center">
                                <Grid item>
                                    <img style={{ marginRight: 10 }} src={CheckMeNowIcon} height={60} alt="check-me-now-icon" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">Settings {t("settingsPage.for")} {groupName}</Typography>
                                </Grid>

                                <Grid item style={{ flexGrow: 1 }}></Grid>
                                {RightsHelper.hasWritePermission(userAccount) &&
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                setIsTemplateDialogVisible(true);
                                            }}
                                            disabled={disabled || !row?.id}
                                            variant="contained"
                                            size="large"
                                            color="info"
                                            startIcon={<SaveIcon />}
                                        >
                                            {t("settingsPage.saveNewTemplate")}
                                        </Button>
                                    </Grid>
                                }
                                {RightsHelper.hasWritePermission(userAccount) &&
                                    <Grid item>
                                        <Button
                                            onClick={onSave}
                                            disabled={disabled}
                                            color="info"
                                            variant="contained"
                                            size="large"
                                            startIcon={<SaveIcon />}
                                        >
                                            {t("settingsPage.save")}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Paper elevation={elevationPaper} style={paperStyle}>

                        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>


                            <Divider />
                            <Grid item>
                                {
                                    row && group &&
                                    <GroupSettingFields
                                        loadData={loadData}
                                        row={row}
                                        onChange={onChange}
                                        type={GroupSettingsType.Default}
                                        group={group}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={onSave}
                                    disabled={disabled}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<SaveIcon />}
                                >
                                    {t("settingsPage.save")}
                                </Button>
                            </Grid>
                        </Grid>
                        {row &&
                            <TemplateSettingsSaveDialog visible={isTemplateDialogVisible} onClose={() => setIsTemplateDialogVisible(false)} row={row} />
                        }
                    </Paper>
                </Grid>
            </SettingsEditContext.Provider>
        </Grid>
    );
}