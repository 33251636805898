import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

function ListItemLink(props: any) {
    return <ListItem button component="a" {...props} />;
}

export default function SimpleList({ data }: { data: any[] }) {

    return (
        <List>
            {data.map((el) => {
                return (
                    <ListItem button>
                        <ListItemIcon>
                            <VpnKeyIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${addLinesUserKey(el.id)}`} secondary={`${el.notice ? el.notice : ""}`}/>
                    </ListItem>
                );
            })}

        </List>
    );
}

export const addLinesUserKey = (userkey: string) => {
    return `${userkey.substring(0,4)} - ${userkey.substring(4,8)} - ${userkey.substring(8,12)} - ${userkey.substring(12,16)}`
}