import axios from 'axios';
import { api, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { StringHelper } from '../helpers/string-helper';
import { GroupSettings, GroupSettingsUpdateType } from '../models/GroupSettings';
import { GroupSettingsTemplateOption } from '../models/GroupSettingsTemplateOption';
import { PortalSettings } from '../models/Group';

export const patchGroupPortalSettings = async (id: string,groupSettings: PortalSettings, token: string, enqueueSnackbar: any): Promise<GroupSettings> => {
    const userId = getUserIdFromStorage();

    console.log(groupSettings, "groupsettings speichern");

    return await axios
        .patch(`${process.env.REACT_APP_BASIC_URI}/groups/${id}/users/${userId}/settings-portal`,
            { ...groupSettings, systemETag: null },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar(`Speichern von Portal-Einstellungen erfolgreich.`, { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            enqueueSnackbar(`Beim Speichern von Portal-Einstellungen ist ein Fehler aufgetreten.`, { variant: 'error' });
        });
}