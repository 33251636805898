import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import React, { Fragment, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { DataFilterType, PeriodChartFilterType, generateChartData, getDataFromGroupIdsTrigger, APIDataAmountType, getServiceTriggerByUserIdGroupIds } from "../../../../helpers/charts/base";
import { generateCleaningTriggerChartData, getAllCleaningTriggerTypes } from "../../../../helpers/charts/cleaningTriggerGenerator";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { getDefaultPeriod } from "../../../../helpers/date-management";
import useNoInitialEffect from "../../../../helpers/extensions";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupCleaningTrigger } from "../../../../models/GroupCleaningTrigger";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { CleaningTriggerDataTable } from "./cleaningTrigger/CleaningTriggerDataTable";
import { TriggeredCleaningTask } from "./cleaningTrigger/TriggeredCleaningTask";
import { EvalApiType } from "./Services";

export const CleaningTriggerDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any }) => {
    const [ServiceTriggerstriggeredCleaningTasks, setServiceTriggersTriggeredCleaningTasks] = React.useState<any>([]);
    const [serviceTriggerstriggeredCleaningAllTasks, setserviceTriggersTriggeredCleaningAllTasks] = React.useState<any>([]);
    const [serviceTriggerstriggerCleaningType, setserviceTriggersTriggerCleaningType] = React.useState<any>([]);
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);
    const { setApiDataLoading, setIsPreFilterDialogVisible }: { setApiDataLoading: any, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);

    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();
    const childRefTriggeredCleaningTask: any = useRef();
    const childRefTriggeredCleaningTaskChart: any = useRef();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);
    const [cleaningTriggerTableData, setCleaningTriggerTableData] = React.useState<GroupCleaningTrigger[]>([]);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();


    const loadDataAsync = async () => {

        setApiDataLoading(true);
        setLoadingCompleted(false);
        setServiceTriggersTriggeredCleaningTasks([]);
        const token = getApiToken(history);
        if (!token) return;

        if (serviceDisplayType == EvalApiType.Objects){
            let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        else if (serviceDisplayType == EvalApiType.ServiceWorkers){
            let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        
        let serviceTriggers = await getServiceTriggerByUserIdGroupIds(groupId, token, groupIds);

        setCleaningTriggerTableData(serviceTriggers);
        let allCleaningTriggerTypes = getAllCleaningTriggerTypes();
        let cleaningTriggerChartAllData = generateCleaningTriggerChartData(serviceTriggers);
        setserviceTriggersTriggeredCleaningAllTasks(cleaningTriggerChartAllData);
        let cleaningTriggerChartData = generateChartData(cleaningTriggerChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allCleaningTriggerTypes);
        setserviceTriggersTriggerCleaningType(allCleaningTriggerTypes);
        setServiceTriggersTriggeredCleaningTasks(cleaningTriggerChartData);
        showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
        if (serviceDisplayType == EvalApiType.Groups){
            let groupsPortal = await getPortalGroups(groupIds, token, groupId);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        setLoadingCompleted(true);
        setApiDataLoading(false);
    }

    React.useEffect(()=>{
        setIsPreFilterDialogVisible(true);
    },[]);

    useNoInitialEffect(() => {
        loadDataAsync();
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
        (async () => {
            const token = getApiToken(history);

        })();

    }, [apiDataAmountType, reloadDataFromApi]);

    const updateChosenPastDaysFromParent = (event: any) => {
        childRefTriggeredCleaningTask.current.updateChosenPastDays(event.target.value);
    }

    const updateEndDateFromParent = (event: any) => {
        childRefTriggeredCleaningTask.current.updateEndDateFromParent(event);
    }

    const updateStartDateFromParent = (event: any) => {
        childRefTriggeredCleaningTask.current.updateStartDateFromParent(event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {
        childRefTriggeredCleaningTask.current.updateDataFilterTypeFromParent(event);
    }

    const buildPrintContent = () => {
        return (
            <div className="page-size" ref={refPrint}>
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[childRefTriggeredCleaningTaskChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                    <Grid item xs={12}>
                        <TriggeredCleaningTask
                            childRefChart={childRefTriggeredCleaningTaskChart}
                            childRef={childRefTriggeredCleaningTask}
                            setChartData={setServiceTriggersTriggeredCleaningTasks}
                            allData={serviceTriggerstriggeredCleaningAllTasks}
                            data={ServiceTriggerstriggeredCleaningTasks}
                            dataField={serviceTriggerstriggerCleaningType}
                            loadingCompleted={loadingCompleted} />
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }

    const buildGridContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Serviceauslösungen {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {cleaningTriggerTableData.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CleaningTriggerDataTable rows={cleaningTriggerTableData} groups={groupsLicenseTableEntries} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[childRefTriggeredCleaningTaskChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    <TriggeredCleaningTask
                        childRefChart={childRefTriggeredCleaningTaskChart}
                        childRef={childRefTriggeredCleaningTask}
                        setChartData={setServiceTriggersTriggeredCleaningTasks}
                        allData={serviceTriggerstriggeredCleaningAllTasks}
                        data={ServiceTriggerstriggeredCleaningTasks}
                        dataField={serviceTriggerstriggerCleaningType}
                        loadingCompleted={loadingCompleted} />
                </Grid>

            </Grid>
        );
    }

    return isPrintPage ? buildPrintContent() : buildGridContent();

}