import { Box, Link, Typography } from "@mui/material";
import React from "react";

export function Copyright() {
  return (
    <Box marginTop={10}> 
      <Typography variant="body2" color="textSecondary" align="center" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        {'Copyright © '}
        <Link color="inherit" href="https://greenbird.fm/">
          Greenbird GmbH
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
}