import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import { UserAddress } from '../../../../models/UserAddress';
import { OrderOverViewTable } from './OrderOverViewTable';
import { CheckoutItem } from '../../../../models/CheckoutItem';
import { ReviewAddress } from './ReviewAddress';

export const Review = ({ items, deliveryAddress, paymentAddress, hidden = false }
  : { items: CheckoutItem[], deliveryAddress: UserAddress | null | undefined, paymentAddress: UserAddress | null | undefined, hidden?: boolean }) => {

  const classes = useStyles();

  return (
    <Grid container className={classes.root} style={{ display: hidden ? 'none' : 'block' }}>

      <Grid item xs={12}>
        <OrderOverViewTable items={items} userAddress={paymentAddress || null}/>
      </Grid>

      <Grid item container xs={12} direction="row">
        <Grid item xs={6}>
          <ReviewAddress
            title="LieferAdresse"
            address={deliveryAddress}
          />
        </Grid>
        <Grid item xs={6}>
          <ReviewAddress
            title="RechnungsAdresse"
            address={paymentAddress}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));


