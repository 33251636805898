import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DataFilterType, PeriodChartFilterType, generateChartData } from '../../helpers/charts/base';
import { InlineDateTimePicker } from './InlineDateTimePicker';
import { Grid, TextField } from '@mui/material';
import { defaultEndDate, defaultPastDays, defaultStartDate, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from '../../helpers/date-management';
import { Autocomplete } from '@mui/material';
import { setTimeBasedChartData } from './TimeChoice';
import DataFilterTypeChoiceDialog from '../dialogs/DataFilterTypeChoiceDialog';
import { useHistory } from 'react-router-dom';
import useNoInitialEffect from '../../helpers/extensions';
import { GridStackCustomItem, GridStackCustom } from '../../models/GridStackCustom';
import { useQuery } from '../authorized/portal/checkout/ShopPage';
import { CustomDashboardContext } from '../authorized/portal/Dashboard/CustomDashboard';
import { GlobalContext } from '../authorized/PortalPage';
import { useTranslation } from 'react-i18next';

export const TimeUserChoice = forwardRef(({ data, setChartData, fields, currentCleaner, setCurrentCleaner }: any, ref: any) => {
  useImperativeHandle(
    ref,
    () => ({
      updateEndDateFromParent(date: any) {
        if (item) {
          handleChange({ target: { value: item.periodChartFilterType } });
        }
        setSelectedEndDate(date);
      },
      updateStartDateFromParent(date: any) {
        setSelectedStartDate(date);
      },
      updateDataFilterTypeFromParent(dataFilterType: any) {
        setDataFilterType(dataFilterType);
      },
      updateChosenPastDays(days: any) {
        setChosenPastDays(parseInt(days));
      }
    }),
  )
  const [value, setValue] = React.useState(PeriodChartFilterType.Day);
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);
  const [chosenPastDays, setChosenPastDays] = React.useState(defaultPastDays);
  const isInitialMount = useRef(true);
  const [dataFilterType, setDataFilterType] = React.useState<DataFilterType>(DataFilterType.StartEnd);
  const { item, updateGridStack, selectedGridStackCustom }: { item: GridStackCustomItem, updateGridStack: (currentGridStackCustom: GridStackCustomItem) => void, selectedGridStackCustom: GridStackCustom } = React.useContext(CustomDashboardContext);

  const { periodChartFilter, setPeriodChartFilter, isPreFilterDialogVisible }: { periodChartFilter: PeriodChartFilterType, setPeriodChartFilter: any, isPreFilterDialogVisible: boolean } = React.useContext(GlobalContext);
  const { t } = useTranslation();



  const history = useHistory();
  const queryString = useQuery(history);
  useNoInitialEffect(() => {
    let endDate = getEndDateFromUrlOrStorage(queryString);
    let startDate = getStartDateFromUrlOrStorage(queryString);
    setSelectedEndDate(endDate);
    setSelectedStartDate(startDate);
  }, [isPreFilterDialogVisible])

  React.useEffect(() => {
    changeData(currentCleaner);
  }, [value, selectedEndDate, selectedStartDate, chosenPastDays, dataFilterType]);


  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (event.target.value === PeriodChartFilterType.Hour) {
      setChosenPastDays(1);
    }
    else if (event.target.value === PeriodChartFilterType.Day) {
      setChosenPastDays(10)
    }
    else if (event.target.value === PeriodChartFilterType.Week) {
      setChosenPastDays(30);
    }
    else if (event.target.value === PeriodChartFilterType.Month) {
      setChosenPastDays(90);
    }
  };

  const changeData = (user: string) => {
    let filteredUserData = data.filter((el: any) => {
      return el.cleaner === user
    });
    setTimeBasedChartData(dataFilterType, isInitialMount, value, selectedEndDate, selectedStartDate, filteredUserData, fields, chosenPastDays, setChartData);
  }

  const handleChangeUser = (event: any, value: any) => {
    setCurrentCleaner(value)
    changeData(value);
  }

  let cleaners = getUniqueValues(data.map((el: any) => el.cleaner));

  const buildFields = () => {
    switch (dataFilterType) {
      case DataFilterType.StartEnd:
        return (
          <Grid container item direction={'row'} spacing={2}>
            <Grid item>
              <InlineDateTimePicker title={t("serviceDocumentationTable.startDate")} selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
          </Grid>
        );
      case DataFilterType.EndDatePastDays:
        return (
          <Grid container item xs={12} direction={'row'}>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 100, marginLeft: 30 }}
                inputProps={{ min: 1, style: {} }}
                id="filled-number"
                label="Tage zurück"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                value={chosenPastDays}
                onChange={(event) => { setChosenPastDays(parseInt(event.target.value)) }}
              />
            </Grid>
          </Grid>
        );
    }
  }

  return (
    <Grid container spacing={2} style={{ padding: 10, paddingBottom: 10 }}>
      <Grid item xs={12}>
        <Autocomplete
          id="combo-box-demo"
          options={cleaners}
          value={currentCleaner}
          getOptionLabel={(option: any) => option}
          onInputChange={handleChangeUser}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Servicekräfte Auswahl" variant="filled" />}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t("dashboard.timeSelection")}</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} row>
            <FormControlLabel value={PeriodChartFilterType.Hour} control={<Radio />} label={t("dashboard.hourly")} />
            <FormControlLabel value={PeriodChartFilterType.Day} control={<Radio />} label={t("dashboard.daily")} />
            <FormControlLabel value={PeriodChartFilterType.Week} control={<Radio />} label={t("dashboard.weekly")} />
            <FormControlLabel value={PeriodChartFilterType.Month} control={<Radio />} label={t("dashboard.monthly")} />
          </RadioGroup>
        </FormControl>
      </Grid>
      <DataFilterTypeChoiceDialog dataFilterType={dataFilterType} setDataFilterType={setDataFilterType} />
      {buildFields()}
    </Grid >
  );
});

export const getUniqueValues = (arr: string[]) => {
  return arr.reduce(
    (accumulator: any, current: any) => {
      if (current && !accumulator.some((x: any) => x === current)) {
        accumulator.push(current)
      }
      return accumulator;
    }, []
  )
}