import axios from 'axios';
import { headers } from '../api';
import { ResetPasswordRequest } from '../models/ResetPasswordRequest';

export const getResetPasswordRequestAsync = async (requestId: string): Promise<ResetPasswordRequest | null> => {

    try {

        let response = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/auth/reset-password/${requestId}`,
            { headers: headers });

        return response.data;

    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        return null;
    }
}