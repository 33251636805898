import { CircularProgress } from "@mui/material";
import { useOverlayStyles } from "../../styles/overlay";

export const CircleProgressOverlay = ({ hidden = false }: { hidden?: boolean }) => {

    const overlayStyles = useOverlayStyles();

    const circularLoader = () => {
        return (
            <div className={overlayStyles.root}>
                <CircularProgress />
            </div>
        );
    }

    if (hidden)
        return null;

    return circularLoader();
}