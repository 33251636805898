import { addDays, differenceInDays, format, fromUnixTime, isBefore, subDays, subMinutes } from 'date-fns';
import add from 'date-fns/add'
import { formatISO } from "date-fns/fp";
import { GLOBAL_CHOSEN_PAST_DAYS_CHARTS, GLOBAL_DATA_FILTER_TYPE, GLOBAL_DATE_CHARTS, GLOBAL_PERIOD_CHART_FILTER, GLOBAL_START_DATE_CHARTS } from '../constants';
import { de } from 'date-fns/locale'
import { getQuery, useQuery } from '../components/authorized/portal/checkout/ShopPage';
import { useHistory } from 'react-router-dom';

export enum PeriodChartFilterType {
    Hour = "1",
    Day = "2",
    Week = "3",
    Month = "4"
}

export enum APIDataAmountType {
    All = "1",
    DateRange = "2"
}

export enum DataFilterType {
    "StartEnd" = 0,
    "EndDatePastDays" = 1
}

export const convertMinuteToHours = (minutes: number) => {
    return (minutes / 60).toFixed(2)
}

export const convertTimespanToDuration = (timespan: string): Duration => {
    if (!timespan) return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    let [days, rest] = timespan.split(".");
    if (!rest) return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    let [hours, minutes, seconds] = rest.split(":");

    return { days: parseInt(days), hours: parseInt(hours), minutes: parseInt(minutes), seconds: parseInt(seconds) }
}

export const addToDate = (date: Date, timespan: string): Date => add(date, convertTimespanToDuration(timespan));

export const diffDays = (dateLeft: Date, dateRight: Date): number => differenceInDays(dateLeft, dateRight);

export const formatEuropeanDateSec = (num: number): string => formatEuropeanDate(new Date(getUnixMillisec(num)));

export const formatEuropeanDate = (date: any): string => format(new Date(date), 'dd.MM.yyyy');

export const formatEuropeanTime = (date: any): string => format(new Date(date), 'HH:mm');

export const formatEuropeanDateTime = (date: any): string => format(new Date(date), 'dd.MM.yyyy HH:mm');

export const formatEuropeanCurrentDateForExcelExport = (): string => format(new Date(), 'dd.MM.yyyy');

export const formatDateRange = (startDate : Date, endDate: Date) => {
    return `${formatEuropeanDateTime(startDate)}-${formatEuropeanDateTime(endDate)}`
}


var daysArray = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

export const formatEuropeanDateWithDay = (date: any): string => daysArray[new Date(date).getDay()] + " " + formatEuropeanDate(date);

export const formatDateInHours = (date: Date) => {
    var dateNextHour = new Date(date);
    dateNextHour.setHours(dateNextHour.getHours() + 1);
    let result = format(date, `HH:00`, { locale: de });
    return result;
}

export const formatUnixSecTimestampEuropeanDateInMinutes = (num: number): string => {
    if (!num) return "";
    var date = new Date(getUnixMillisec(num));
    const result = format(date, `dd.MM.yyyy HH:mm`, { locale: de });
    return result;
}

export const formatUnixSecToDate = (num: number): string => {
    if (!num) return "";
    var date = new Date(getUnixMillisec(num));
    const result = format(date, `dd.MM.yyyy`, { locale: de });
    return result;
}

export const formatUnixSecToTime = (num: number): string => {
    if (!num) return "";
    var date = new Date(getUnixMillisec(num));
    const result = format(date, `HH:mm`, { locale: de });
    return result;
}

export const formatUnixSecToTimeWithSec = (num: number): string => {
    if (!num) return "";
    var date = new Date(getUnixMillisec(num));
    const result = format(date, `HH:mm:ss`, { locale: de });
    return result;
}

export const isOlderThanFifteenMinutes = (num: number) => {
    let date = fromUnixTime(num);
    let beforeSpecifiedTime = subMinutes(new Date(), 15);
    return isBefore(date, beforeSpecifiedTime);
}

export const isOlderThan60Minutes = (num: number) => {
    let date = fromUnixTime(num);
    let beforeSpecifiedTime = subMinutes(new Date(), 60);
    return isBefore(date, beforeSpecifiedTime);
}

export const dayNameOfJsWeekDay = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];


export const formatDateInWeeks = (date: Date): string => format(date, "w 'Woche', MMMM yyyy");

export const formatDateInMonths = (date: Date): string => format(date, 'MMMM');

export const formatToTime = (date: Date): string => format(date, 'HH:mm:ss');

export const formatToISO = (date: Date): string => formatISO(date);

export const formatEuropeanDateSpecifcSec = (num: number): string => formatEuropeanDateSpecific(new Date(getUnixMillisec(num)));

export const formatEuropeanDateSpecific = (date: Date): string => format(date, 'dd.MM.yyyy HH:mm:ss');

export const getUnixMillisec = (num: number): number => num * 1000;

export const adjustForUTCOffset = (date: Date) => {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );
  };
  

export const convertMinutesToTimespan = (n: number) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return `${rhours}:${rminutes}`;
}

export const convertTimespanToMinutes = (timespan: string | null) => {
    if (isTimespan(timespan) && timespan != null) {
        let [hours, minutes] = timespan.split(":");
        return (parseInt(hours) * 60 + parseInt(minutes)).toString();
    } else {
        return "0";
    }
}

export const isTimespan = (timespan: string | null) => {
    return timespan && timespan.split(":").length >= 2
}

export const chartDataFormat = 'yyyy-MM-ddTHH:MM:SS';

const today = addDays(new Date(), 1);
export let defaultPastDays = 10;
export let defaultEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
export let defaultStartDate = subDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0), 10);


export let defaultPeriodChartFilterType = PeriodChartFilterType.Month;
export let defaultAPIDataAmountType = APIDataAmountType.DateRange;
export let defaultDataFilterType = DataFilterType.StartEnd;


export const getDefaultPeriod = () => {
    let periodChartFilter = localStorage.getItem(GLOBAL_PERIOD_CHART_FILTER) || "" + defaultPeriodChartFilterType;
    return periodChartFilter as PeriodChartFilterType;
}

export const setDefaultDataFilterType = (val: any) => {
    defaultDataFilterType = val;
}

export const setDefaultPastDays = (val: any) => {
    defaultPastDays = val;
}

export const setDefaultEndDate = (val: any) => {
    defaultEndDate = val;
}

export const setDefaultStartDate = (val: any) => {
    defaultStartDate = val;
}
export const getEndDateFromUrlOrStorage = (queryString: URLSearchParams) => {
    const endDateFromQueryString = queryString.get("endDate"); //1996-10-15T00:05:32.000Z
    let endDate = localStorage.getItem(GLOBAL_DATE_CHARTS) ? new Date(localStorage.getItem(GLOBAL_DATE_CHARTS) || "") : defaultEndDate;
    if (endDateFromQueryString){
        endDate = new Date(endDateFromQueryString);
    }
    return endDate;
}

export const checkIfDateIsEmpty = (timestamp: string) => {
    return new Date(timestamp).getTime() < 1
}

export const getStartDateFromUrlOrStorage = (queryString: URLSearchParams) => {
    const startDateFromQueryString = queryString.get("startDate"); //1996-10-15T00:05:32.000Z
    let startDate = localStorage.getItem(GLOBAL_START_DATE_CHARTS) ? new Date(localStorage.getItem(GLOBAL_START_DATE_CHARTS) || "") : defaultStartDate;
    if (startDateFromQueryString){
        startDate = new Date(startDateFromQueryString)
    }
    return startDate;
}
export const setDefaultTimes = () => {
    const queryString = getQuery();
    let startDate = getStartDateFromUrlOrStorage(queryString);
    let endDate = getEndDateFromUrlOrStorage(queryString);
    endDate = endDate || defaultEndDate;
    setDefaultEndDate(endDate);
    startDate = startDate || defaultStartDate;
    setDefaultStartDate(startDate);
    let pastDays = localStorage.getItem(GLOBAL_CHOSEN_PAST_DAYS_CHARTS) || "" + defaultPastDays;
    setDefaultPastDays(parseInt(pastDays));
    let dataFilterType = localStorage.getItem(GLOBAL_DATA_FILTER_TYPE) || "" + defaultDataFilterType;
    setDefaultDataFilterType(parseInt(dataFilterType));
}

setDefaultTimes();