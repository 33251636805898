import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";

import ShoppingImage from '../../assets/image/shop/shopping-icon.png';
import SignInLogo from '../../assets/image/signin-logo.png';
import { Card, CardActionArea, CardMedia, Checkbox, Divider, FormControlLabel, IconButton } from '@mui/material';
import { AttachFile, BluetoothDisabled, BluetoothSearching, Scanner, Send } from '@mui/icons-material';
import { GroupSettingsExtended } from '../../../models/GroupSettingsExtended';
import { GroupSettings } from '../../../models/GroupSettings';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { group } from 'console';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let bluetoothDevice: BluetoothDevice | null;
let charNotify: any;
let charWrite: any;
let timeCharWrite: any;

export const DigitalCheckpointConfigLoggedIn = ({ groupSettings, visible, objectUnit }: { groupSettings: GroupSettings | undefined, visible: boolean, objectUnit: ObjectUnit | undefined }) => {






  const DEFAULT_BLE_MTU = 20;
  const TXT_ONLY_MAGIC = 0x0D;

  const SERVICE_UUID = 0xfff0;
  const CHAR_NOTIFY = "0000fff2-0000-1000-8000-00805f9b34fb"; // 0xFFF1
  const CHAR_WRITE = "0000fff2-0000-1000-8000-00805f9b34fb"; // 0xFFF2

  const TIME_SERVICE_UUID = 0x1805
  const TIME_CHAR_WRITE = "00002a2b-0000-1000-8000-00805f9b34fb" //0x2a2b

  const ACK_TYPE_LINK = 0x00;
  const ACK_SUBTYPE_LINK_OK = 0x00;
  const ACK_SUBTYPE_LINK_ERR = 0x01;

  const ACK_TYPE_DRAW = 0x01;
  const ACK_SUBTYPE_DRAW_OK = 0x00;
  const ACK_SUBTYPE_DRAW_ERR = 0x01;

  let bleMtuSize = DEFAULT_BLE_MTU;


  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [isSendDisabled, setIsSendDisabled] = React.useState(true);
  const [isDisconnectDisabled, setIsDisconnectDisabled] = React.useState(true);
  const [dataSentText, setDataSentText] = React.useState("N");
  const [drawScreenText, setDrawScreenText] = React.useState("N");


  const [dataTimeText, setDataTimeText] = React.useState("N");
  const [isSendTimeDisabled, setIsSendTimeDisabled] = React.useState(true);
  const [sendTimeStatus, setSendTimeStatus] = React.useState("N");
  const [filterScan, setFilterScan] = React.useState("greenbird");
  const [customField1, setCustomField1] = React.useState("");
  const [customField2, setCustomField2] = React.useState("");
  const [displayIdText, setDisplayIdText] = React.useState("");
  const [customField4, setCustomField4] = React.useState("");
  const [qrCodeText, setQrCodeText] = React.useState("");
  const [qrCodeTextFull, setQrCodeTextFull] = React.useState("");
  const [showParamQrCode, setShowParamQrCode] = React.useState(false);
  const queryParamQrCode = React.useRef<string>("");

  const {t} = useTranslation();


  React.useEffect(() => {
    if (groupSettings) {
      queryParamQrCode.current = `?id=${groupSettings?.id}&ts=<TIMESTAMP>`;
      setCustomField1(groupSettings.locationInformation?.notificationName || "");
      setCustomField2(groupSettings.locationInformation?.levelName || "");
      setDisplayIdText(getObjectConfigName(objectUnit, groupSettings) || "");
      setCustomField4(getObjectConfigCustomerName(objectUnit, groupSettings) || "");

    } else if (objectUnit) {
      queryParamQrCode.current = `?id=${objectUnit.id}&ts=<TIMESTAMP>`;
      setCustomField1(objectUnit?.name || "");
      setCustomField2(objectUnit?.customerName || "");
      setDisplayIdText(objectUnit?.city || "");
      setCustomField4(objectUnit?.addressLine || "");
    }
    setQrCodeText(`https://smart-service-display.at/`);
    setQrCodeTextFull(`https://smart-service-display.at/${queryParamQrCode.current}`);


  }, [visible])

  const onSendTimeDate = async () => {
    let timestamp = Math.floor(Date.now() / 1000);
    let date = new Date(timestamp * 1000);
    date.setUTCHours(date.getUTCHours() + 8);
    //here was the problem
    // let date = new Date();
    // date.setUTCHours(date.getUTCHours()); 
    let utcYear = date.getUTCFullYear();
    let utcMonth = date.getUTCMonth() + 1;
    //this code is one month behind also
    // let utcMonth = date.getUTCMonth();
    let utcDateOfMonth = date.getUTCDate();
    let utcHour = date.getUTCHours();
    let utcMinute = date.getUTCMinutes();
    let utcSecond = date.getUTCSeconds();
    let utcDay = date.getUTCDay();
    let dateTime = `${utcDateOfMonth}/${utcMonth + 1}/${utcYear} ${utcDay} ${utcHour}:${utcMinute}:${utcSecond}`
    setDataTimeText(dateTime);
    let data = new Uint8Array([
      utcYear & 0xff,
      utcYear >> 8,
      utcMonth,
      utcDateOfMonth,
      utcHour,
      utcMinute,
      utcSecond,
      utcDay,
      0x00,
      0x01,
    ]);
    try {
      // updateDeviceStatus("RESET", null);
      console.log("start send ...")
      // Send current time to display
      await writeTimeDataChunk(data);
    } catch (error) {
      console.error(error);
    }
    console.log(data);
  }

  async function writeTimeDataChunk(data: any) {
    // send data splited by MTU, then send the remaining in recursion
  
    if (data === "" || data === null || data === undefined) return;
  
    let pkt = data.slice(0, bleMtuSize); // splite by MTU
    let remain = data.slice(bleMtuSize);
    let typedArray = new Uint8Array(pkt);
    console.log("sending chunk", typedArray.buffer);
    console.log(timeCharWrite)
    await timeCharWrite
      .writeValueWithResponse(typedArray.buffer)
      .then(async () => {
        console.log("data sent", typedArray.length);
        if (remain.length) {
          await writeTimeDataChunk(remain);
        } else {
          console.log("All chunks(s) sent.");
         
        }
      })
      .catch((e: any) => {
      
        console.log(e);
      });
  }


  const onScanButtonClick = () => {
    let options = { filters: [{ services: [SERVICE_UUID, TIME_SERVICE_UUID] }, { name: 'SyncSign-Display-Lite' }, { namePrefix: filterScan }] };

    bluetoothDevice = null;
    console.log("Requesting Bluetooth Device...");
    navigator.bluetooth
      .requestDevice(options)
      .then((device) => {
        bluetoothDevice = device;
        bluetoothDevice.addEventListener(
          "gattserverdisconnected",
          onDisconnected
        );
        return connect();
      })
      .catch((error) => {
        console.log("Argh! " + error);
      });
  }

  function onDisconnectButtonClick() {
    if (!bluetoothDevice) {
      return alert("No Bluetooht Device");
    }
    console.log("Disconnecting from Bluetooth Device...");
    if (bluetoothDevice?.gatt?.connected) {
      if (charNotify) {
        charNotify
          .stopNotifications()
          .then((_: any) => {
            console.log("> Notifications stopped");
            charNotify.removeEventListener(
              "characteristicvaluechanged",
              handleNotifications
            );
          })
          .then((_: any) => {
            bluetoothDevice?.gatt?.disconnect();
            updateDeviceStatus("RESET", null);
          })
          .catch((error: any) => {
            console.log("Argh! " + error);
          });
      } else {
        bluetoothDevice.gatt.disconnect();
        updateDeviceStatus("RESET", null);
      }
    } else {
      console.log("> Bluetooth Device is already disconnected");
    }
  }

  const onDisconnected = (event: any) => {
    // Object event.target is Bluetooth Device getting disconnected.
    console.log("> Bluetooth Device disconnected");
    setIsSendDisabled(true);
    setIsDisconnectDisabled(true);
    setIsSendTimeDisabled(true);
  }

  const connect = async () => {
    try {
      charWrite = null;
      charNotify = null;
      timeCharWrite = null;
      console.log("Connecting to Bluetooth Device...");
      let server = await bluetoothDevice?.gatt?.connect();
      if (!server) return;


      console.log("> Bluetooth Device connected");
      console.log(">>> getPrimaryService", server.getPrimaryService(SERVICE_UUID));
      let service = await server.getPrimaryService(SERVICE_UUID);
      console.log("service", service);
      console.log("Getting Characteristic...");
      await enumerateGatt(server);
  
      if (charNotify) {
        console.log(`Found Characteristic: ${charNotify.uuid}`);
        await charNotify.startNotifications();
        console.log("> Notifications started");
        charNotify.addEventListener(
          "characteristicvaluechanged",
          handleNotifications
        );
        onSendTimeDate();
      }
    } catch (error) {
      setIsSendDisabled(true);
      setIsDisconnectDisabled(true);
      setIsSendTimeDisabled(true);
      console.error("Argh! " + error);
    }
  }

  const handleNotifications = (event: any) => {
    let value = event.target.value;
    let data: any[] = [];
    for (let i = 0; i < value.byteLength; i++) {
      data.push("0x" + ("00" + value.getUint8(i).toString(16)).slice(-2));
    }
    const magic = data[0];
    if (parseFloat(magic) != TXT_ONLY_MAGIC) {
      console.log("invalid response");
      return;
    }
    const payloadLength = data[1] | (data[2] << 8);
    const scope = data[3];
    const result = data[4];
    const errCode = data[5];
    if (scope == ACK_TYPE_LINK) {
      if (result == ACK_SUBTYPE_LINK_OK) {
        console.log("Data Sent OK");
        updateDeviceStatus("LINK", true);
      } else if (result == ACK_SUBTYPE_LINK_ERR) {
        console.log("Data Sent Failed", errCode);
        updateDeviceStatus("LINK", false);
      } else {
        console.log("Data Sent Result:", result, errCode);
        updateDeviceStatus("LINK", false);
      }
    } else if (scope == ACK_TYPE_DRAW) {
      if (result == ACK_SUBTYPE_DRAW_OK) {
        console.log("Draw Screen OK");
        updateDeviceStatus("DRAW", true);
      } else if (result == ACK_SUBTYPE_DRAW_ERR) {
        console.log("Draw Screen Failed", errCode);
        updateDeviceStatus("DRAW", false);
      } else {
        console.log("Draw Screen Result:", result, errCode);
        updateDeviceStatus("DRAW", false);
      }
    } else {
      console.log("scope:", scope, "result:", result, "errCode:", errCode);
    }
    console.log("> " + data.join(" "));
  }

  const updateDeviceStatus = (scope: string, result: boolean | null) => {
    if (scope === "LINK") {
      setDataSentText(result ? "Data Sent OK" : "Data Sent Failed");
    } else if (scope === "DRAW") {
      setDrawScreenText(result ? "Draw Screen OK" : "Draw Screen Failed");
    } else if (scope === "RESET") {
      setDataSentText("N");
      setDrawScreenText("N");
    }
  }

  const enumerateGatt = async (server: BluetoothRemoteGATTServer) => {
    const services = await server.getPrimaryServices();
    // console.log("services", services);
    const sPromises = services.map(async (service) => {
      const characteristics = await service.getCharacteristics();
      const cPromises = characteristics.map(async (characteristic) => {
        // issue: https://github.com/WebBluetoothCG/web-bluetooth/issues/532
        // let descriptors = await characteristic.getDescriptors();
        // descriptors = descriptors.map(
        //   (descriptor) => `\t\t|_descriptor: ${descriptor.uuid}`
        // );
        // console.log("characteristic", characteristic)
        let descriptors = []
        descriptors.unshift(`\t|_characteristic: ${characteristic.uuid}`);
        if (characteristic.uuid === CHAR_NOTIFY) {
          charNotify = characteristic;
        }
        if (characteristic.uuid === CHAR_WRITE) {
          charWrite = characteristic;
          setIsSendDisabled(false);
          setIsDisconnectDisabled(false);
        }
        if (characteristic.uuid === TIME_CHAR_WRITE) {
          timeCharWrite = characteristic;
          setIsSendTimeDisabled(false);
        }
        return descriptors.join("\n");
      });
  
      const descriptors = await Promise.all(cPromises);
      descriptors.unshift(`service: ${service.uuid}`);
      return descriptors.join("\n");
    });
  
    const result = await Promise.all(sPromises);
    console.log(result.join("\n"));

  }
  function replaceUmlauts(input: string): string {
    const umlautMap: { [key: string]: string } = {
      'ü': 'ue',
      'ä': 'ae',
      'ö': 'oe',
      'Ü': 'Ue',
      'Ä': 'Ae',
      'Ö': 'Oe',
      'ß': 'ss',
    };
  
    return input.replace(/[üäöÜÄÖß]/g, match => umlautMap[match] || match);
  }
  const onSendTextOnly = async () => {
    try {
      updateDeviceStatus("RESET", null);
      await sendTextOnly(0, [
        replaceUmlauts(customField1),
        replaceUmlauts(customField2),
        replaceUmlauts(displayIdText),
        replaceUmlauts(customField4),
        showParamQrCode ? qrCodeTextFull : qrCodeText + queryParamQrCode.current,
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  const sendTextOnly = async (templateId: any, strings: any) => {
    // [TXT_ONLY_MAGIC:1] [TotalLength:2] [TemplateId:1] [Reserved:3] [TextStrings:N]
    // Please note that the [TextStrings:N] is formated as:
    //      [LEN:1][TEXT:LEN] [LEN:1][TEXT:LEN]...[LEN:1][TEXT:LEN]

    console.log(strings);
    let textData = new Uint8Array();
    for (let i = 0; i < strings.length; i++) {
      if (strings[i].length <= 0x7f) {
        let enc = new TextEncoder();
        let len = new Uint8Array([enc.encode(strings[i]).length & 0x7f]);
        //I think here is the problem
       // let len = new Uint8Array([strings[i].length & 0x7f]);
        let text = enc.encode(strings[i]);
        let mergedArray = new Uint8Array(
          textData.length + len.length + text.length
        );
        mergedArray.set(textData);
        mergedArray.set(len, textData.length);
        mergedArray.set(text, textData.length + len.length);
        textData = mergedArray;
      }
    }
    let totalLength = 1 + 3 + textData.length;

    let data = new Uint8Array([
      TXT_ONLY_MAGIC,
      totalLength & 0xff,
      (totalLength >> 8) & 0xff,
      templateId,
      0x00,
      0x00,
      0x00,
    ]);

    data = concatArrayBuffer(data, textData);
    console.log("data:", data);
    await writeDataChunk(data);
    console.log("sendTextOnly TPL ID=%d Strings=%s done", templateId, strings);
  }

  const writeDataChunk = async (data: any) => {
    // send data splited by MTU, then send the remaining in recursion

    if (data === "" || data === null || data === undefined) return;

    let pkt = data.slice(0, bleMtuSize); // splite by MTU
    let remain = data.slice(bleMtuSize);
    let typedArray = new Uint8Array(pkt);
    console.log("   sending chunk", typedArray.buffer);
    if (charWrite) {
      await charWrite
        .writeValueWithResponse(typedArray.buffer)
        .then(async () => {
          console.log("data sent", typedArray.length);
          if (remain.length) {
            await writeDataChunk(remain);
          } else {
            console.log("All chunks(s) sent.");
          }
        })
        .catch((e: any) => {
          alert(JSON.stringify(e));
          console.log(e);
        });
    }

  }

  function concatArrayBuffer(arrayOne: any, arrayTwo: any) {
    // a, b TypedArray of same type

    let mergedArray = new Uint8Array(arrayOne.length + arrayTwo.length);
    mergedArray.set(arrayOne);
    mergedArray.set(arrayTwo, arrayOne.length);
    return mergedArray;
  }


  return (
    <div className={classes.paper}>
      <Grid container direction={"column"} spacing={3}>
        <Grid item>
          <Typography variant='h4'>{groupSettings ? "Digital Checkpoint konfigurieren" : "Digitales Objekt konfigurieren"}</Typography>
        </Grid>
        <Grid item container direction={"row"} alignItems="center" spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={"Filter"}
              placeholder="Weniger als 12 Zeichen"
              disabled={loading}
              value={filterScan}
              onChange={(event: any) => {
                setFilterScan(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size='large'
              onClick={() => {
                onScanButtonClick();
              }}
              endIcon={<BluetoothSearching />}
            >
              Scannen
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size='large'
              disabled={isDisconnectDisabled}
              onClick={() => {
                onDisconnectButtonClick();
              }}
              endIcon={<BluetoothDisabled />}
            >
              Verbindung Trennen
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label={groupSettings ? "Einheit Name" : "Objekt Name"}
            placeholder="Weniger als 12 Zeichen"
            disabled={loading}
            value={customField1}
            onChange={(event: any) => {
              setCustomField1(event.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label={groupSettings ? "Ebene" : "Kunden Name"}
            placeholder="Weniger als 16 Zeichen"
            disabled={loading}
            value={customField2}
            onChange={(event: any) => {
              setCustomField2(event.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label={groupSettings ? "Objekt Name" : t("myUnitsSettings.city")}
            placeholder="weniger als 12 Zeichen"
            disabled={loading}
            value={displayIdText}
            onChange={(event: any) => {
              setDisplayIdText(event.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label={groupSettings ? t("myUnitsSettings.customerName") : t("myUnitsSettings.address")}
            placeholder="weniger als 32 Zeichen"
            disabled={loading}
            value={customField4}
            onChange={(event: any) => {
              setCustomField4(event.target.value);
            }}
          />
        </Grid>
        <Grid item container direction={"row"} alignItems="center" spacing={2}>

          <Grid item xs={10}>
            {showParamQrCode ?
              <TextField
                fullWidth
                variant="outlined"
                label={"Qr Code Url"}
                type="url"
                placeholder="https://xxx"
                disabled={loading}
                value={qrCodeTextFull}
                onChange={(event: any) => {
                  setQrCodeTextFull(event.target.value);
                }}
              /> :
              <TextField
                fullWidth
                variant="outlined"
                label={"Qr Code Url"}
                type="url"
                placeholder="https://xxx"
                disabled={loading}
                value={qrCodeText}
                onChange={(event: any) => {
                  setQrCodeText(event.target.value);
                }}
              />
            }
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showParamQrCode}
                  onChange={(event: any) => {
                    setShowParamQrCode(event.target.checked);
                  }}
                  name="isEnabled"
                  color="primary"
                />
              }
              label="Parameter einblenden"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size='large'
            disabled={isSendDisabled}
            onClick={() => {
              onSendTextOnly();
            }}
            endIcon={<Send />}
          >
            Zum Display (Digital-Checkpoint) senden
          </Button>
        </Grid>

        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Typography>{drawScreenText}/{dataSentText}</Typography>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size='large'
            disabled={isSendTimeDisabled}
            onClick={() => {
              onSendTimeDate();
            }}
            endIcon={<Send />}
          >
            Zeit Kalibrieren
          </Button>
        </Grid>

        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Typography>{dataTimeText}/{sendTimeStatus}</Typography>
        </Grid>
      </Grid>

    </div>
  );
}

export const getObjectConfigName = (objectUnit: ObjectUnit | undefined, groupSettings: GroupSettings) => {
  return objectUnit ? objectUnit.name : groupSettings.locationInformation?.objectName;
}

export const getObjectConfigCustomerName = (objectUnit: ObjectUnit | undefined, groupSettings: GroupSettings) => {
  return objectUnit ? objectUnit.customerName : groupSettings.locationInformation?.roomName;
}