import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Accessibility } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { differenceInHours, differenceInMinutes, formatDistance, fromUnixTime } from 'date-fns';
import { de } from 'date-fns/locale';
import { ObjectCleaningTrigger } from '../../../../../models/ObjectCleaningTrigger';
import { UserServiceWorker } from '../../../../../models/UserServiceWorker';
import { formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime, formatEuropeanTime } from '../../../../../helpers/date-management';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { buildCellElementArray } from '../../../../common/AccordionForElementArray';
import { lightRed, lightGreen } from '../../../../../styles/colors';
import { useTranslation } from 'react-i18next';

export const ObjectPresenceTriggerTable = ({ objectCleaningTriggers, userServiceWorkers, selectedObjectUnit, objects = [] }
    : { objectCleaningTriggers: ObjectCleaningTrigger[], userServiceWorkers: UserServiceWorker[], selectedObjectUnit?: ObjectUnit, objects?: ObjectUnit[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `ObjectSession-${selectedObjectUnit?.name || "All-Objects"}-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const [pageSize, setPageSize] = React.useState(15);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: ObjectCleaningTrigger, value: any } = props;
        if (column.name === ObjectCleaningTrigger.Columns.durationOfObjectSessions) {
            let names = row.minimums.durationOfObjectSessions.map(x => {
                let serviceWorker = userServiceWorkers.find(worker => worker.id == x.session.serviceWorkerId);
                let diffText = x.isDurationOfSessionTooShort ? `${t("object-storage-single-eval.absent")}: ${x.diffTime}` : `${t("object-storage-single-eval.excess")}: ${x.diffTime}`
                return <Typography style={{ backgroundColor: x.isDurationOfSessionTooShort ? lightRed : lightGreen, padding: 20, borderRadius: 5, width: '100%', fontWeight: 'bold', fontSize: 18 }}>{`${t("object-storage-single-eval.servicestaff")}: ${serviceWorker?.name};${t("object-interval-trigger.tooShort")} ${x.isDurationOfSessionTooShort ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")};${t("object-interval-trigger.attendanceDuration")}: ${x.durationLoggedIn};${t("object-interval-trigger.minimumDuration")}: ${row.minimums.minimumTime};${diffText}`}</Typography>;
            });
            return buildCellElementArray(props, names, `${names.length} ${t("object-storage-single-eval.attendances")}`);
        }
        if (column.name === ObjectCleaningTrigger.Columns.sessions) {
            let names = row.minimums.durationOfObjectSessions.map(x => {
                let serviceWorker = userServiceWorkers.find(worker => worker.id == x.session.serviceWorkerId);
                return <Typography>Servicekraft: {serviceWorker?.name} <br /> {t("object-storage-single-eval.registration")} : {formatEuropeanDateTime(fromUnixTime(x.session.signedInObjectSinceTimestamp))}<br />{t("object-storage-single-eval.logout")}: {formatEuropeanDateTime(fromUnixTime(x.session.signedOutOfObjectSinceTimestamp))}</Typography>;
            });
            return buildCellElementArray(props, names, `${names.length} ${t("object-storage-single-eval.registration")}`);
        }
        if (column.name === ObjectCleaningTrigger.Columns.minimumServiceWorker || column.name === ObjectCleaningTrigger.Columns.isNotEnoughServiceWorkerSessions) {
            return (
                <Table.Cell  {...props} style={{ backgroundColor: row.isNotEnoughServiceWorkerSessions ? lightRed : lightGreen, borderRadius: 1, fontWeight: 'bold' }}>
                    {value}
                </Table.Cell>
            )
        }
        if (column.name === ObjectCleaningTrigger.Columns.isAllServiceWorkersMissing) {
            return (
                <Table.Cell  {...props} style={{ backgroundColor: row.isAllServiceWorkersMissing ? lightRed : lightGreen, borderRadius: 1, fontWeight: 'bold' }}>
                    {value}
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let columns = [
        {
            name: ObjectCleaningTrigger.Columns.objectId,
            title: t("object-storage-single-eval.object"),
            getCellValue: (row: ObjectCleaningTrigger) => {
                let object = objects.find(x => x.id == row.objectId);
                return (
                    !object ? t("object-storage-single-eval.notFound") : `${object?.name} ${object?.addressLine}`
                );
            }
        },
        {
            name: ObjectCleaningTrigger.Columns.objectScheduleStart,
            title: t("object-interval-trigger.plannedDate"),
            getCellValue: (row: ObjectCleaningTrigger) => formatEuropeanDate(row.objectScheduleStart),
        },
        {
            name: ObjectCleaningTrigger.Columns.objectScheduleStartTime,
            title: t("object-interval-trigger.plannedTime"),
            getCellValue: (row: ObjectCleaningTrigger) => formatEuropeanTime(row.objectScheduleStart),
        },
        {
            name: ObjectCleaningTrigger.Columns.triggerMoment,
            title: t("object-interval-trigger.reactionEndTime"),
            getCellValue: (row: ObjectCleaningTrigger) => formatEuropeanDateTime(row.triggerMoment),
        },
        {
            name: ObjectCleaningTrigger.Columns.reactionTimeMinutes,
            title: t("object-interval-trigger.reactionTime"),
            getCellValue: (row: ObjectCleaningTrigger) => row.deviationTime,
        },
        {
            name: ObjectCleaningTrigger.Columns.isAllServiceWorkersMissing,
            title: t("object-interval-trigger.totalFailure"),
            getCellValue: (row: ObjectCleaningTrigger) => row.isAllServiceWorkersMissing ? t("myUnitsSettings.yes") : t("myUnitsSettings.no"),
        },
        {
            name: ObjectCleaningTrigger.Columns.minimumServiceWorker,
            title: t("object-interval-trigger.insufficient"),
            getCellValue: (row: ObjectCleaningTrigger) => `${row.isNotEnoughServiceWorkerSessions ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}`,
        },
        {
            name: ObjectCleaningTrigger.Columns.minimumServiceWorker2,
            title: t("object-interval-trigger.actualCount"),
            getCellValue: (row: ObjectCleaningTrigger) => `${row.minimums.durationOfObjectSessions.length} ${t("object-storage-single-eval.servicestaff")}`,
        },
        {
            name: ObjectCleaningTrigger.Columns.minimumServiceWorker3,
            title: t("object-interval-trigger.targetCount"),
            getCellValue: (row: ObjectCleaningTrigger) => `${row.minimums.minimumServiceWorker} ${t("object-storage-single-eval.servicestaff")}`,
        },
        {
            name: ObjectCleaningTrigger.Columns.durationOfObjectSessions,
            title: t("object-interval-trigger.durationOfServiceStaffAttendance"),
            getCellValue: (row: ObjectCleaningTrigger) => JSON.stringify(row.minimums.durationOfObjectSessions),
        },
        {
            name: ObjectCleaningTrigger.Columns.durationOfObjectSessions,
            title: t("object-interval-trigger.durationOfServiceStaffAttendance"),
            getCellValue: (row: ObjectCleaningTrigger) => {
                let names = row.minimums.durationOfObjectSessions.map(x => {
                    let serviceWorker = userServiceWorkers.find(worker => worker.id == x.session.serviceWorkerId);
                    let diffText = x.isDurationOfSessionTooShort ? `${t("object-interval-trigger.absent")}: ${x.diffTime}` : `${t("object-interval-trigger.excess")}: ${x.diffTime}`
                    return (
                        `${t("object-storage-single-eval.servicestaff")}: ${serviceWorker?.name};${t("object-interval-trigger.tooShort")} ${x.isDurationOfSessionTooShort ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")};${t("object-interval-trigger.attendanceDuration")}: ${x.durationLoggedIn};${t("object-interval-trigger.minimumDuration")}: ${row.minimums.minimumTime};${diffText}
                        `
                    )
                }
                );
                return (
                    names.toString()
                );
            }
        },
        {
            name: ObjectCleaningTrigger.Columns.sessions,
            title: t("object-interval-trigger.serviceStaffRegistrations"),
            getCellValue: (row: ObjectCleaningTrigger) => {
                let names = row.minimums.durationOfObjectSessions.map(x => {
                    let serviceWorker = userServiceWorkers.find(worker => worker.id == x.session.serviceWorkerId);
                    return (
                        `${t("object-storage-single-eval.servicestaff")}: ${serviceWorker?.name};${t("object-interval-trigger.registration")}: ${formatEuropeanDateTime(fromUnixTime(x.session.signedInObjectSinceTimestamp))};${t("object-interval-trigger.logout")}: ${formatEuropeanDateTime(fromUnixTime(x.session.signedOutOfObjectSinceTimestamp))}
                        `
                    )
                });
                return (
                    names.toString()
                )
            }
        },
        {
            name: ObjectCleaningTrigger.Columns.objectCleaningTriggerInTimeState,
            title: t("object-interval-trigger.serviceStaffRegistrationsByEndOfDay"),
            getCellValue: (row: ObjectCleaningTrigger) => ObjectCleaningTrigger.GetLabelByInTimeStateType(row.objectCleaningTriggerInTimeState),
        },
    ];

    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: ObjectCleaningTrigger.Columns.objectId, width: 350 },
        { columnName: ObjectCleaningTrigger.Columns.objectScheduleStart, width: 150 },
        { columnName: ObjectCleaningTrigger.Columns.objectScheduleStartTime, width: 150 },
        { columnName: ObjectCleaningTrigger.Columns.triggerMoment, width: 200 },
        { columnName: ObjectCleaningTrigger.Columns.reactionTimeMinutes, width: 200 },
        { columnName: ObjectCleaningTrigger.Columns.durationOfObjectSessions, width: 400 },
        { columnName: ObjectCleaningTrigger.Columns.sessions, width: 350 },
        { columnName: ObjectCleaningTrigger.Columns.isAllServiceWorkersMissing, width: 150 },
        { columnName: ObjectCleaningTrigger.Columns.minimumServiceWorker, width: 150 },
        { columnName: ObjectCleaningTrigger.Columns.minimumServiceWorker2, width: 200 },
        { columnName: ObjectCleaningTrigger.Columns.minimumServiceWorker3, width: 200 },

        { columnName: ObjectCleaningTrigger.Columns.objectCleaningTriggerInTimeState, width: 350 },
    ]);

    return (
        <Paper>
            <Grid
                rows={objectCleaningTriggers}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={objectCleaningTriggers}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};