import { DataFileTree } from './DataFileTree';
import { GroupServiceModeIncidentPhoto } from './GroupServiceMode';
import { GroupSettingsIntervalControl } from './GroupSettings';
import { UpdateGroupSettingsByObjectIdModel } from './UpdateGroupSettingsByObjectIdModel';
import { MaterialStock } from './UserStock';

export class MaterialStockGroupSettings {
    public materialId: string = "" ;
    public amount: number = 0;
    public specificReason !: string;
    public minimumAmount : number = 0;
    public maximumAmount : number = 0;
    public alarmEmail : string = "";

    //only frontend
    public text !: string;
    public article !: string;
    public entity !: string;
    public photos !: GroupServiceModeIncidentPhoto[];
    public createdTimestamp !: string;
    public diffAmount : number = 0;
    public newAmount !: number;
    ///////////////////

    public constructor (materialId: string, amount: number, specificReason: string){
        this.materialId = materialId;
        this.amount = amount;
        this.specificReason = specificReason;
    }

    public static CreateInstanceFrontend(materialId: string, amount: number, photos: GroupServiceModeIncidentPhoto[], createdTimestamp: string, text: string, article: string, entity: string){
        let ret = new MaterialStockGroupSettings(materialId, amount, "");
        ret.photos = photos;
        ret.createdTimestamp = createdTimestamp;
        ret.text = text;
        ret.article = article;
        ret.entity = entity;
        return ret;
    }

    public static SetValuesFromMaterialStockForGroup(material: MaterialStock, standardMaterial : MaterialStockGroupSettings){
        standardMaterial.amount = material.currentGroupStockAmount;
        standardMaterial.newAmount = material.currentGroupStockAmount;
        standardMaterial.minimumAmount = material.minimumGroupAmount || 0;
        standardMaterial.maximumAmount = material.maximumGroupAmount;
        standardMaterial.diffAmount = 0;
        standardMaterial.alarmEmail = material.alarmEmailGroup;
        return standardMaterial;
    }

    public static CreateInstanceFromUserStock(material: MaterialStock){
        let ret = new MaterialStockGroupSettings(material.materialId, material.currentObjectStockAmount, "");
        return ret;
    }

    static Columns = {
        id: 'id',
        creatorUserId: 'creatorUserId',
        text: 'text',
        entity: 'entity',
        article: 'article',
        createdTimestamp: 'createdTimestamp',
        activated: 'activated',
        photoContent: 'photoContent',
        itemType: 'itemType',
        actions: 'actions',
        isAdminItem : 'isAdminItem',
        amount: 'amount',
        diffAmount: 'diffAmount',
        newAmount: 'newAmount',
        specificReason: 'specificReason',
        minimumAmount: 'minimumAmount',
        maximumAmount: 'maximumAmount',
        alarmEmail: 'alarmEmail'
    }
}