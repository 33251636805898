import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, FileCopy, SettingsSuggest, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { PropertySelectionDataTable } from './PropertySelectionDataTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ObjectSelectionDataTable } from './ObjectSelectionDataTable';
import { TemplateSettingsSelectionDataTable } from './TemplateSettingsSelectionDataTable';
import { UserGroupSettingsTemplate } from '../../../../models/UserGroupSettingsTemplate';
import { updateGroupSettingsByTemplate } from '../../../../api/UpdateGroupSettings';
import { GroupSettings } from '../../../../models/GroupSettings';
import { TemplateSettingsMultipleSelectionDataTable } from './TemplateSettingsMultipleSelectionDataTable';
import { GroupSettingsTemplateOption } from '../../../../models/GroupSettingsTemplateOption';
import { GroupSettingsTemplateOptionChooserDialog } from '../../../dialogs/template/GroupSettingsTemplateOptionChooserDialog';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAssignMultupleGroupToTemplateSettingDialog = ({
  open,
  setOpen,
  currentGroups,
  userGroupTemplateSettings,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  currentGroups: GroupLicenseTable[],
  userGroupTemplateSettings: UserGroupSettingsTemplate[],
  onRefresh: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const [token, setToken] = React.useState("");
  const [selectedUserGroupSettingsTemplate, setSelectedUserGroupSettingsTemplate] = React.useState<UserGroupSettingsTemplate[]>([]);
  const [templateOptions, setTemplateOptions] = React.useState<GroupSettingsTemplateOption>(new GroupSettingsTemplateOption());
  const [count, setCount] = React.useState(0);
  const [isFinishPressed, setIsFinishedPressed] = React.useState(false);
  const {t} = useTranslation();
  const [openTemplateOptionDialog, setOpenTemplateOptionDialog] = React.useState(false);
  const handleClose = () => {
    setSelectedUserGroupSettingsTemplate([]);
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);


    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={`Wähle eine Vorlage für die ausgewählten ${currentGroups.length} Einheiten  zugewiesen werden sollen`}
        handleClose={handleClose}
      />

      <DialogContent>
        <TemplateSettingsMultipleSelectionDataTable rows={userGroupTemplateSettings}
          selectedUserGroupSettingsTemplate={selectedUserGroupSettingsTemplate}
          onSelectionChange={(selection: string[]) => {
            let selected = selection[selection.length - 1];
            setSelectedUserGroupSettingsTemplate(userGroupTemplateSettings.filter(x => selected === x.id));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button size="large"
          startIcon={<SettingsSuggest />} variant={'contained'}
          disabled={loading}
          endIcon={loading && <CircularProgress size={32} />}
          onClick={async () => {
            setOpenTemplateOptionDialog(true);
          }} color="primary">
          Vorlagen Optionen
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={loading ? <CircularProgress /> : <FileCopy />}
          disabled={selectedUserGroupSettingsTemplate.length < 1 || loading}
          endIcon={<Add />}
          onClick={async () => {
            setOpenTemplateOptionDialog(true);
            setIsFinishedPressed(true);
          }}
        >
          Ausgewählte Vorlage zu {loading ? count : currentGroups.length} Einheiten zuweisen
        </Button>
      </DialogActions>
      <GroupSettingsTemplateOptionChooserDialog open={openTemplateOptionDialog} setOpen={setOpenTemplateOptionDialog} templateOptions={templateOptions} setTemplateOptions={setTemplateOptions} onFinish={async () => {
        if (!isFinishPressed) return;
        if (!selectedUserGroupSettingsTemplate[0].id) return;
        setLoading(true);
        setCount(currentGroups.length);
        for (let currentGroup of currentGroups) {
          let groupSettings = await api.getGroupSettings(currentGroup.id, token);
          if (!groupSettings) continue;
          if (!groupSettings.id) continue;

          await updateGroupSettingsByTemplate({ ...selectedUserGroupSettingsTemplate[0] } as GroupSettings, token, enqueueSnackbar, selectedUserGroupSettingsTemplate[0].id, groupSettings.id, templateOptions);
          setCount(prev => prev - 1);
        }
        setLoading(false);
        handleClose();
        onRefresh();
      }} />

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));