import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Checkbox, Divider, Fab, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, Slider, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, AdminPanelSettings, AddToHomeScreen, PhonelinkErase, ChargingStation, BatteryFull, Battery1Bar, Battery30, Battery60, Battery90 } from "@mui/icons-material";
import { errorColor, lightRed, white, yellow } from "../../styles/colors";
import { useSnackbar } from 'notistack';
import { GroupSettingFieldsUseStyles } from '../../styles/GroupSettingFieldsStyles';
import { CleaningOperation, GroupSettings, ServiceWorkingModeType } from '../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { useContainerStyles } from '../../styles/container';
import { Group, GroupStateType, GroupType } from "../../models/Group";
import { api } from "../../api";
import { green, lightGreen } from "@mui/material/colors";
import { HexagonSlice2, HexagonSlice3, HexagonSlice4, HexagonSlice5, HexagonSlice6 } from "../../assets";

export const GroupSettingFieldsBssd = ({ row, onChange, group, token, loadData }
    : { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, group: Group, token: any, loadData: any }) => {

    const classes = GroupSettingFieldsUseStyles();
    const containerStyles = useContainerStyles();

    const { enqueueSnackbar } = useSnackbar();

    const hours = (row.locationInformation?.bssdInformation.maxReportTimeSeconds || 3600) / 3600;





    return (
        <Accordion className={classes.hover}>
            <AccordionSummary expandIcon={<ExpandMore />}            >
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <ChargingStation style={{ fontSize: 44 }} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">Batterie Display - Einstellungen</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={3}>
                    <Grid item>
                        <Typography component="h5" variant="h5" color={"primary"} fontWeight="bold">Servicebestätigungsmodus</Typography>
                    </Grid>
                    {group.groupType == GroupType.DisplayBatteryWallApp &&
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Servicebestätigung Auswahl</FormLabel>
                                <RadioGroup
                                    row
                                    name="serviceWorkingModeType"
                                    value={row.cleaningOperations?.serviceWorkingModeType || ServiceWorkingModeType.PotentialFake}
                                    onChange={onChange}
                                >
                                    <FormControlLabel
                                        value={ServiceWorkingModeType.PotentialFake}
                                        control={<Radio />}
                                        label="Service direkt am Batterie Display (Fake Zeiten möglich)"
                                    />
                                    <FormControlLabel
                                        value={ServiceWorkingModeType.NoFakeSecure}
                                        control={<Radio />}
                                        label="App Modus (Keine Fake Zeiten am Display)"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item>
                        <Typography component="h5" variant="h5" color={"primary"} fontWeight="bold">Intervall Meldung der Aktivitäten</Typography>
                    </Grid>

                    <Grid item container direction="row" spacing={3}>

                        <Grid item xs={12} container spacing={3} alignItems="center" justifyContent={"flex-start"}>
                            <Grid item>
                                <Typography fontWeight={'bold'} color="primary" fontSize={30}>Stunden: {hours}</Typography>
                            </Grid>
                            <Grid item lg={2}>
                                <TextField
                                    label="Intervall Meldung der Aktivitäten in Sekunden"
                                    value={row.locationInformation?.bssdInformation.maxReportTimeSeconds}
                                    fullWidth
                                    inputProps={{ min: 3600, max: 86400 }}
                                    type="number"
                                    onChange={(event) => {
                                        if (!row.locationInformation?.bssdInformation) return;

                                        row.locationInformation.bssdInformation.maxReportTimeSeconds = parseInt(event.target.value);
                                        onChange({ target: { name: "row", value: "" } });
                                    }}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item lg={3}>
                                <Alert severity="info">Wie oft soll das Batterie Display, die Aktivitäten der Besucher melden. Achtung! Die Batterielaufzeit wird schlechter, wenn der Intervall niedriger eingestellt ist.</Alert>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item lg={3}>
                                {getDataCompleteState(hours)}
                            </Grid>
                            <Grid item lg={4}>
                                <Slider
                                    value={row.locationInformation?.bssdInformation?.maxReportTimeSeconds}
                                    onChange={(event, value: number | number[]) => {
                                        if (!row.locationInformation?.bssdInformation) return;

                                        row.locationInformation.bssdInformation.maxReportTimeSeconds = value as number;
                                        onChange({ target: { name: "row", value: "" } });
                                    }}
                                    min={3600}
                                    max={86400}
                                    step={3600} />
                            </Grid>
                            <Grid item lg={3}>
                                {getBatteryState(hours)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography component="h5" variant="h5" color={"primary"} fontWeight="bold">Zeitzone</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={3} alignItems="center">
                        <Grid item lg={3}>
                            <TextField
                                label="Timezoneoffset in Minuten"
                                value={row.locationInformation?.bssdInformation.timeZoneOffsetMinutes}
                                fullWidth
                                inputProps={{ min: 1, max: 12 }}
                                type="number"
                                onChange={(event) => {
                                    if (!row.locationInformation?.bssdInformation) return;

                                    row.locationInformation.bssdInformation.timeZoneOffsetMinutes = parseInt(event.target.value);
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export const getDataCompleteState = (hours: number) => {
    if (hours < 2) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <HexagonSlice6 />
                <Typography fontSize={30} fontWeight={'bold'} color={lightGreen[900]}>{"Sehr Aktuelle Daten"}</Typography>
            </div>
        )
    }
    if (hours < 8) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <HexagonSlice5 />
                <Typography fontSize={30} fontWeight={'bold'} color={lightGreen[500]}>{"Aktuelle Daten "}</Typography>
            </div>
        )
    } else if (hours < 16) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <HexagonSlice4 />
                <Typography fontSize={30} fontWeight={'bold'} color={yellow}>{"Regelmäßige Daten"}</Typography>
            </div>
        )
    } else if (hours < 24) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <HexagonSlice3 />
                <Typography fontSize={30} fontWeight={'bold'} color={lightRed}>{"Unregelmäßige Daten"}</Typography>
            </div>
        )

    }
    else {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <HexagonSlice2 />
                <Typography fontSize={30} fontWeight={'bold'} color={errorColor}>{"Sehr Unregelmäßige Daten"}</Typography>
            </div>
        )
    }
}
export const getBatteryState = (hours:number) => {
    if (hours < 2) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Battery1Bar style={{ fontSize: 150, color: errorColor }} />
                <Typography fontSize={30} fontWeight={'bold'} color={errorColor}>{"Sehr Schlechte Batterielaufzeit"}</Typography>
            </div>
        )
    }
    if (hours < 8) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Battery30 style={{ fontSize: 150, color: lightRed }} />
                <Typography fontSize={30} fontWeight={'bold'} color={lightRed}>{"Schlechte Batterielaufzeit"}</Typography>
            </div>
        )
    } else if (hours < 16) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Battery60 style={{ fontSize: 150, color: yellow }} />
                <Typography fontSize={30} fontWeight={'bold'} color={yellow}>{"Mittlere Batterielaufzeit"}</Typography>
            </div>
        )
    } else if (hours < 24) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Battery90 style={{ fontSize: 150, color: lightGreen[500] }} />
                <Typography fontSize={30} fontWeight={'bold'} color={lightGreen[500]}>{"Gute Batterielaufzeit"}</Typography>
            </div>
        )

    }
    else {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <BatteryFull style={{ fontSize: 150, color: lightGreen[900], fontWeight: 'bold' }} />
                <Typography fontSize={30} fontWeight={'bold'} color={lightGreen[900]}>{"Sehr Gute Batterielaufzeit"}</Typography>
            </div>
        )
    }
}