import { DeleteForever, ExpandMore, Height } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Divider, Grid, InputAdornment, Paper, TextField, Typography, useTheme } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { differenceInDays, differenceInMinutes, isAfter, isBefore, subDays } from "date-fns";
import { useSnackbar } from "notistack";
import React, { Fragment, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { OBJECT_COMBINATION } from "../../../../constants";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { MaxChartItemCountInput } from "../../../../helpers/compontents";
import { APIDataAmountType, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../helpers/date-management";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupOnlineStatus, GroupOnlineStatusCountTable } from "../../../../models/GroupOnlineStatus";
import { GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { ObjectUnit } from "../../../../models/ObjectUnit";
import { UserServiceWorker } from "../../../../models/UserServiceWorker";
import { ObjectSessionCountTableDataType, UserServiceWorkerObjectSession } from "../../../../models/UserServiceWorkerObjectSession";
import { UserType } from "../../../../models/UserType";
import { StandardBarChartSimpleColor } from "../../../charts/StandardBarChartSimpleColor";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { ChartHeightTextBox, GlobalTimeChoice } from "../../../core/GlobalTimeChoice";
import { ActiveServiceWorkersInObjectTable } from "../../../dialogs/tables/ActiveServiceWorkersInObjectTable";
import { ObjectSessionsByObjectIdTable } from "../../../dialogs/tables/ObjectSessionsByObjectIdTable";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { useQuery } from "../checkout/ShopPage";
import { ObjectSessionCountTable } from "./table/ObjectSessionCountTable";
import { ObjectSessionChart } from "../Dashboard/object/ObjectSessionChart";
import { loadObjectSessionData } from "./ObjectCarbonDioxideDashboard";
import { AggregateType } from "../../../../helpers/charts/base";
import { GetAllGroupsByGroupIdsAsync, getUniqueGroupsBySessions } from "../../../../api/GetAllGroupsAsync";
import useNoInitialEffect from "../../../../helpers/extensions";
import { useTranslation } from "react-i18next";

export const ObjectSessionAnalytic = ({ displayType = DrawerMenuItemDisplayType.Single }: { displayType?: DrawerMenuItemDisplayType }) => {
    const history = useHistory();

    const { userAccount, maxChartItemCount }: { userAccount: any, maxChartItemCount: number } = useContext(GlobalContext);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const [selectedObjectUnit, setSelectedObjectUnit] = React.useState<ObjectUnit>();
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [activeUserServiceWorkers, setActiveUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [loading, setLoading] = React.useState(false);

    const [loadingCompletedGroups, setLoadingCompletedGroups] = React.useState(false);
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);

    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);
    const [objectSessionTableDataFilteredByObjectDate, setObjectSessionTableDataFilteredByObjectDate] = React.useState<UserServiceWorkerObjectSession[]>([]);
    const [objectSessionTableData, setObjectSessionTableData] = React.useState<UserServiceWorkerObjectSession[]>([]);
    const [objectSessionCountTableData, setObjectSessionCountTableData] = React.useState<ObjectSessionCountTableDataType[]>([]);

    const [startDate, setStartDate] = React.useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = React.useState(new Date());


    const globalTimeChoiceChildEvents = { doPrintingProcess: (token: string) => { } };

    const [chartHeightOnlineStatusSimple, setChartHeightOnlineStatusSimple] = React.useState(500);
    const [onlineStatusSimpleCountMax, setOnlineStatusSimpleCountMax] = React.useState(0);
    const [onlineStatusSimpleChartData, setOnlineStatusSimpleChartData] = React.useState<any[]>([]);
    const [onlineStatusNoWorkersSimpleChartData, setOnlineStatusNoWorkersSimpleChartData] = React.useState<any[]>([]);
    const [onlineStatusAverageSimpleChartData, setOnlineStatusAverageSimpleChartData] = React.useState<any[]>([]);
    const [startDateOnlineStatusSimpleChartData, setStartDateOnlineStatusSimpleChartData] = React.useState(subDays(new Date(), 10));
    const [endDateOnlineStatusSimpleChartData, setEndDateOnlineStatusSimpleChartData] = React.useState(new Date());


    const childRefObjectSession: any = useRef();
    const childRefObjectSessionChart: any = useRef();

    const [loadingCompleted, setLoadingCompleted] = React.useState(false);

    const [objectSessions, setObjectSessions] = React.useState<any>([]);
    const [objectSessionsAll, setObjectSessionsAll] = React.useState<any>([]);
    const [objectSessionType, setObjectSessionType] = React.useState<any>([]);

    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const {t} = useTranslation();


    const queryString = useQuery(history);
    const doPrint = queryString.get("print");

    const { setApiDataLoading, setIsPreFilterDialogVisible }: { setApiDataLoading: any, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);

    }, [history.location.pathname]);

    useNoInitialEffect(() => {
        setApiDataLoading(true);
        setLoading(true);
        (async () => {

            await loadDataAsync();
            setLoading(false);

        })();

    }, [apiDataAmountType, reloadDataFromApi]);

    const loadDataAsync = async () => {
        const token = getApiToken(history);
        if (!token) return;
        setLoading(true);

        const tempServiceWorkers = await api.getServiceWorkers(token);
        setUserServiceWorkers(tempServiceWorkers);


        let tempSessions = null;
        if (displayType == DrawerMenuItemDisplayType.All) {
            var tempObjects = await api.getUserObjectUnits(token);
            setActiveUserServiceWorkers(tempServiceWorkers.filter(x => tempObjects.some(object => object.id == x.serviceWorkerSignedIntoObject.signedInObjectId)));
            setObjects(tempObjects);


            tempSessions = await api.getObjectSessionsByObjectIds(token, []);
            setObjectSessionTableData(tempSessions);
        } else if (displayType == DrawerMenuItemDisplayType.Multiple) {
            const tempCombObject = JSON.parse(localStorage.getItem(OBJECT_COMBINATION) || "[]").map((x:any)=>x.id);
            var tempObjects = await api.getUserObjectUnitsByIds(token,tempCombObject);
            setActiveUserServiceWorkers(tempServiceWorkers.filter(x => tempObjects.some(object => object.id == x.serviceWorkerSignedIntoObject.signedInObjectId)));
            setObjects(tempObjects);
            tempSessions = await api.getObjectSessionsByObjectIds(token, tempCombObject);
            tempSessions = tempSessions.filter(x => tempCombObject.some((y: string) => y == x.objectId))
            setObjectSessionTableData(tempSessions);
        }
        else {
            var tempObjects = await api.getUserObjectUnitsByIds(token,[selectedObjectId]);
            setActiveUserServiceWorkers(tempServiceWorkers.filter(x => tempObjects.some(object => object.id == x.serviceWorkerSignedIntoObject.signedInObjectId)));
            setObjects(tempObjects);
            setSelectedObjectUnit(tempObjects[0]);
            tempSessions = await api.getObjectSessionsByObjectIds(token, [selectedObjectId]);
            setObjectSessionTableData(tempSessions);

        }

        setGroups(await getUniqueGroupsBySessions(token, tempSessions));
        let allData = await loadObjectSessionData(
            setObjectSessionsAll,
            setObjectSessionType,
            setObjectSessions,
            setLoadingCompleted,
            AggregateType.Time,
            tempSessions);


        let tempCountTable = reloadObjectCount(tempSessions, startDate, endDate);
        setObjectSessionCountTableData(tempCountTable);
        setLoading(false);
        setLoadingCompletedGroups(true);
        setLoadingCompleted(true);

        if (JSON.parse(doPrint || 'false')) {
            if (globalTimeChoiceChildEvents.doPrintingProcess) {
                await globalTimeChoiceChildEvents.doPrintingProcess(token);

            }
        }
    }

    const updateObjectSessionSimpleChart = (startDate: Date, endDate: Date, groupOnlineStatuses: UserServiceWorkerObjectSession[]) => {
        let countMax = Math.round(differenceInMinutes(endDate, startDate) / 15);
        setOnlineStatusSimpleCountMax(countMax);


        let filteredGroupOnlineStatuses = groupOnlineStatuses.filter(x => new Date(x.creationDate) >= startDate && new Date(x.creationDate) <= endDate);
        let res = filteredGroupOnlineStatuses.reduce((sumValue, newValue: UserServiceWorkerObjectSession) => {
            const notificationName = objects.find(x => x.id == newValue.objectId)?.name;
            let entryExists = sumValue.find(x => x.argumentFieldService == notificationName);
            if (entryExists) {
                entryExists.valueFieldService += 1;
                entryExists.countService += 1;
            } else {
                sumValue = [...sumValue, { argumentFieldService: notificationName, valueFieldService: 1, countService: 1 }];
            }
            return [...sumValue];
        }, [] as any[]);
        let resNormal = res.sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount);
        setOnlineStatusSimpleChartData(resNormal);
    }

    const updateObjectSessionSimpleAverageChart = (startDate: Date, endDate: Date, groupOnlineStatuses: UserServiceWorkerObjectSession[]) => {


        let filteredGroupOnlineStatuses = groupOnlineStatuses.filter(x => new Date(x.creationDate) >= startDate && new Date(x.creationDate) <= endDate);
        let res = filteredGroupOnlineStatuses.reduce((sumValue, newValue: UserServiceWorkerObjectSession) => {
            const objectUnit = objects.find(x => x.id == newValue.objectId);
            const notificationName = objectUnit?.name;
            let entryExists = sumValue.find(x => x.argumentFieldService == notificationName);
            if (entryExists) {
                if (!entryExists.userServiceWorkerIds.includes(newValue.userServiceWorkerId)) {
                    entryExists.valueFieldService += 1;
                    entryExists.countService += 1;
                    entryExists.userServiceWorkerIds.push(newValue.userServiceWorkerId);
                }
            } else {
                sumValue = [...sumValue, { argumentFieldService: notificationName, valueFieldService: 1, countService: 1, userServiceWorkerIds: [newValue.userServiceWorkerId] }];
            }
            return [...sumValue];
        }, [] as any[]);
        let noServiceWorkerObjects = objects.filter(x => !res.some(y => y.argumentFieldService == x.name));
        let resNoWorker = noServiceWorkerObjects.map(x => { return { valueFieldService: 1, argumentFieldService: x.name } }).slice(-maxChartItemCount);
        let resAverage = res.map(x => { { return { ...x, valueFieldService: x.valueFieldService } } }).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount);
        setOnlineStatusNoWorkersSimpleChartData(resNoWorker);
        setOnlineStatusAverageSimpleChartData(resAverage);
    }

    React.useEffect(() => {
        updateEndDateOnlineStatusSimpleChartData(endDate);
    }, [maxChartItemCount])

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (objectSessionTableData && objects) {
            setStartDate(startDate);
            setEndDate(endDate);
            setStartDateOnlineStatusSimpleChartData(startDate);
            setEndDateOnlineStatusSimpleChartData(endDate);
            updateObjectSessionSimpleChart(startDate, endDate, objectSessionTableData);
            updateObjectSessionSimpleAverageChart(startDate, endDate, objectSessionTableData);

        }
    }, [objectSessionTableData, objects]);

    React.useEffect(() => {
        let temp = reloadObjectCount(objectSessionTableData, startDate, endDate);
        setObjectSessionCountTableData(temp);
    }, [startDate, endDate])

    const updateChosenPastDaysFromParent = (event: any) => {
    }

    const updateEndDateFromParent = (event: any) => {
        updateEndDateOnlineStatusSimpleChartData(event);
        setEndDate(event);
    }

    const updateStartDateFromParent = (event: any) => {
        updateStartDateOnlineStatusSimpleChartData(event);
        setStartDate(event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {
    }

    const reloadObjectCount = (objectSession: UserServiceWorkerObjectSession[], beginDate: Date | null = null, endDate: Date | null = null) => {
        let countMax = 0;
        if (beginDate && endDate) {
            objectSession = objectSession.filter(x => {
                let curDate = new Date(x.creationDate);
                return isAfter(curDate, beginDate) && isBefore(curDate, endDate);
            });
            countMax = differenceInDays(endDate, beginDate);
        }
        setObjectSessionTableDataFilteredByObjectDate(objectSession);
        return objectSession.reduce((prevVal: ObjectSessionCountTableDataType[], curVal: UserServiceWorkerObjectSession) => {
            let valExist = prevVal.find(x => x.objectId == curVal.objectId);
            if (valExist) {
                valExist.sessionCount++;
                if (!valExist.userServiceWorkerIds.includes(curVal.userServiceWorkerId)) {
                    valExist.userServiceWorkerIds.push(curVal.userServiceWorkerId);
                }
            } else {
                let sessionCount = 1;

                prevVal.push(new ObjectSessionCountTableDataType(curVal.objectId, curVal.userId, sessionCount, [curVal.userServiceWorkerId]));
            }
            return prevVal
        }, []);
    }

    const reloadServiceWorkerCount = (objectSession: UserServiceWorkerObjectSession[], beginDate: Date | null = null, endDate: Date | null = null) => {
        let countMax = 0;
        if (beginDate && endDate) {
            objectSession = objectSession.filter(x => {
                let curDate = new Date(x.creationDate);
                return isAfter(curDate, beginDate) && isBefore(curDate, endDate);
            });
            countMax = differenceInDays(endDate, beginDate);
        }
        return objectSession.reduce((prevVal: ObjectSessionCountTableDataType[], curVal: UserServiceWorkerObjectSession) => {
            let valExist = prevVal.find(x => x.objectId == curVal.objectId);
            if (valExist) {
                valExist.sessionCount++;
            } else {
                let onlineCount = 1;
                //        prevVal.push(new ObjectSessionCountTableDataType(curVal.objectId, curVal.userId, onlineCount, countMax));
            }
            return prevVal
        }, []);
    }

    const buildPrintContent = () => {
        return (
            <div ref={refPrint} className="page-root">
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Online Status Bericht"} evaluationChoice={evaluationChoice} groupsSettings={[]} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            {buildOnlineStatusSimpleChartDetails()}
                        </Paper>
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={[]} />
                            <GroupDetailsPrintTable groups={[]} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }






    const updateStartDateOnlineStatusSimpleChartData = (newVal: Date) => {
        if (!objectSessionTableData) return;
        setStartDateOnlineStatusSimpleChartData(newVal);
        updateObjectSessionSimpleChart(newVal, endDateOnlineStatusSimpleChartData, objectSessionTableData);
        updateObjectSessionSimpleAverageChart(newVal, endDateOnlineStatusSimpleChartData, objectSessionTableData);
    }

    const updateEndDateOnlineStatusSimpleChartData = (newVal: Date) => {
        if (!objectSessionTableData) return;
        setEndDateOnlineStatusSimpleChartData(newVal);
        updateObjectSessionSimpleChart(startDateOnlineStatusSimpleChartData, newVal, objectSessionTableData);
        updateObjectSessionSimpleAverageChart(startDateOnlineStatusSimpleChartData, newVal, objectSessionTableData);
    }

    const buildOnlineStatusSimpleChartDetails = () => {
        return objectSessionTableData &&
        <Grid container spacing={3} item xs={12} direction="column">
            <Grid item container direction="row" spacing={3} alignItems="center">

                <Grid item>
                    < DateTimePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={t("serviceDocumentationTable.startDate")}
                        value={startDateOnlineStatusSimpleChartData}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            updateStartDateOnlineStatusSimpleChartData(newVal);
                        }}
                        format={"dd.MM.yyyy HH:mm"}
                        
                    />
                </Grid>
                <Grid item>
                    < DateTimePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={t("serviceDocumentationTable.endDate")}
                        value={endDateOnlineStatusSimpleChartData}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            updateEndDateOnlineStatusSimpleChartData(newVal);
                        }}
                        format={"dd.MM.yyyy HH:mm"}
                        
                    />
                </Grid>
                <Grid item>
                    <ChartHeightTextBox chartHeight={chartHeightOnlineStatusSimple} setChartHeight={setChartHeightOnlineStatusSimple} />
                </Grid>
                <MaxChartItemCountInput />

            </Grid>
            <Grid item container direction={"row"}>
                <Grid item xs={6}>
                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>{t("object-sessions.buildingLocationRegistrationsPerbuildingLocationTotalReport")}</Typography>
                    <StandardBarChartSimpleColor color={blue[600]} dataSource={onlineStatusSimpleChartData} name={"Objektanmeldung Gesamt"} height={chartHeightOnlineStatusSimple} />
                </Grid>
                <Grid item xs={6}>
                    <Grid item>
                        <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>{t("object-sessions.buildingLocationWithoutServiceStaffRegistration")}</Typography>
                    </Grid>
                    <StandardBarChartSimpleColor color={red[600]} dataSource={onlineStatusNoWorkersSimpleChartData} name={"Keine Servicekräfte"} height={chartHeightOnlineStatusSimple} />
                </Grid>
            </Grid>

            <Grid item>
                <Divider />
            </Grid>
            <Grid item>
                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>{t("object-sessions.variousServiceStaffCountRegistrationPerbuildingLocation")}</Typography>
            </Grid>
            <Grid item>
                <StandardBarChartSimpleColor color={green[600]} dataSource={onlineStatusAverageSimpleChartData} name={"Anzahl Servicekräfte"} height={chartHeightOnlineStatusSimple} />
            </Grid>


        </Grid>;

    }

    const buildGetObjectSession = () => {
        return (
            <ObjectSessionChart
                childRefChart={childRefObjectSessionChart}
                childRef={childRefObjectSession}
                setChartData={setObjectSessions}
                allData={objectSessionsAll}
                data={objectSessions}
                dataField={objectSessionType}
                loadingCompleted={loadingCompleted} />
        )
    }


    const buildGridContent = () => {
        return (
            <Grid container spacing={4} direction="row">
                <Grid item xs={12}>
                    <Typography variant="h4">{t("")}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        {t("object-sessions.objectRegistrationsLogTable")} {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {objectSessionTableData.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Paper>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Typography variant='h4' component={"h4"}>{t("object-sessions.objectRegistrations")} {selectedObjectUnit?.name}</Typography>
                                    </Grid>
                                    {loading && <CircularProgress size={128} />}
                                    {(selectedObjectUnit || displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                                        <Grid item>
                                            <ObjectSessionsByObjectIdTable
                                                setUserServiceWorkerObjectSessions={setObjectSessionTableData}
                                                selectedObjectUnit={selectedObjectUnit}
                                                userServiceWorkerObjectSessions={objectSessionTableData}
                                                userServiceWorkers={userServiceWorkers}
                                                objects={objects}
                                                groups={groups}
                                                startDate={startDate}
                                                endDate={endDate}
                                                setStartDate={setStartDate}
                                                setEndDate={setEndDate}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Paper>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        {t("object-sessions.currentlyActiveServiceStaff")} {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {activeUserServiceWorkers.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Paper>
                                <Typography variant='h4' component={"h4"}>{t("object-sessions.activeServiceStaffInObject")} {selectedObjectUnit?.name}</Typography>
                                {loading && <CircularProgress size={128} />}
                                {(selectedObjectUnit || displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                                    <ActiveServiceWorkersInObjectTable
                                        selectedObjectUnit={selectedObjectUnit}
                                        userServiceWorkers={activeUserServiceWorkers}
                                        objects={objects}
                                    />
                                }
                            </Paper>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        {t("object-sessions.objectRegistrationsAnalysis")} {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {objectSessionCountTableData.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                <Grid xs={12} item container spacing={3}>
                                    <Grid item>
                                        < DateTimePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={t("serviceDocumentationTable.startDate")}
                                            value={startDate}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                setStartDate(newVal);
                                            }}
                                            format={"dd.MM.yyyy HH:mm"}
                                            
                                        />
                                    </Grid>
                                    <Grid item>
                                        < DateTimePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={t("serviceDocumentationTable.endDate")}
                                            value={endDate}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                setEndDate(newVal);
                                            }}
                                            format={"dd.MM.yyyy HH:mm"}
                                            
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {loadingCompletedGroups ?
                                        <ObjectSessionCountTable objectSessionTableData={objectSessionTableDataFilteredByObjectDate} objectSessionCountTableData={objectSessionCountTableData} objects={objects} userServiceWorkers={userServiceWorkers} />
                                        :
                                        <CircularProgress color="inherit" />
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[]}
                        updateChosenPastDaysFromParent={updateChosenPastDaysFromParent}
                        updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                        updateEndDateFromParent={updateEndDateFromParent}
                        updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    {buildGetObjectSession()}
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {buildOnlineStatusSimpleChartDetails()}
                    </Paper>
                </Grid>



            </Grid>
        );
    }

    if (userAccount && userAccount.userType == UserType.Customer) {
        return (
            <Paper>
                <Typography variant='h4' component={"h4"}>{t("object-sessions.customerCannotViewServiceStaffDataForTheirPropertyRegistrations")}</Typography>
            </Paper>
        )
    }

    if (loading) {
        return <CircularProgress size={64} />
    }

    return isPrintPage ? buildPrintContent() : buildGridContent();

}