import axios from 'axios';
import { api, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { StringHelper } from '../helpers/string-helper';
import { GroupSettings, GroupSettingsUpdateType } from '../models/GroupSettings';
import { GroupSettingsTemplateOption } from '../models/GroupSettingsTemplateOption';

export const updateGroupSettings = async (groupSettings: GroupSettings, token: string, enqueueSnackbar: any): Promise<GroupSettings> => {
    const userId = getUserIdFromStorage();
    setDefaultValues(groupSettings);

    console.log(groupSettings, "groupsettings speichern");

    return await axios
        .put(`${process.env.REACT_APP_BASIC_URI}/groups/${groupSettings.id}/users/${userId}/settings`,
            { ...groupSettings, systemETag: null },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar(`Speichern von Gerätseinstellungen erfolgreich. Einheit: ${res.data.locationInformation?.notificationName}`, { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            enqueueSnackbar(`Beim Speichern von Gerätseinstellungen ist ein Fehler aufgetreten.`, { variant: 'error' });
        });
}

export const updateGroupSettingsByTemplateWithOptions = async (groupSettings: GroupSettings, templateOptions: GroupSettingsTemplateOption, token: string, enqueueSnackbar: any): Promise<GroupSettings> => {
    const userId = getUserIdFromStorage();
    setDefaultValues(groupSettings);

    return await axios
        .put(`${process.env.REACT_APP_BASIC_URI}/groups/${groupSettings.id}/users/${userId}/settings-by-template`,
            { groupSettings:groupSettings, groupSettingsTemplateOptions: templateOptions, systemETag: null },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar(`Speichern von Gerätseinstellungen erfolgreich. Einheit: ${res.data.locationInformation?.notificationName}`, { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            enqueueSnackbar(`Beim Speichern von Gerätseinstellungen ist ein Fehler aufgetreten.`, { variant: 'error' });
        });
}

export const updateGroupSettingsByTemplate = async (currentGroup: GroupSettings, token: string, enqueueSnackbar: any, templateId: string, groupId: string, templateOptions: GroupSettingsTemplateOption = new GroupSettingsTemplateOption()) => {
    currentGroup.id = groupId;
    currentGroup.updateType = GroupSettingsUpdateType.Template;
    currentGroup.templateId = templateId;
    await updateGroupSettingsByTemplateWithOptions(currentGroup, templateOptions, token, enqueueSnackbar);
    if (templateOptions.content){
        await api.copyBlobItemsOfTemplateToGroupAsync(templateId, groupId, token, enqueueSnackbar);
    }
}


export const setDefaultValues = (groupSettings: GroupSettings) => {
    if (groupSettings.feedbacks) {
        if (groupSettings.feedbacks?.amountTriggerBad == 0 || !groupSettings.feedbacks?.amountTriggerBad) {
            groupSettings.feedbacks.amountTriggerBad = 1;
        }
        if (groupSettings.feedbacks?.amountTriggerVeryBad == 0 || !groupSettings.feedbacks?.amountTriggerVeryBad) {
            groupSettings.feedbacks.amountTriggerVeryBad = 1;
        }
        if (groupSettings.feedbacks?.amountTriggerOk == 0 || !groupSettings.feedbacks?.amountTriggerOk) {
            groupSettings.feedbacks.amountTriggerOk = 1;
        }
        if (groupSettings.feedbacks?.amountTriggerGood == 0 || !groupSettings.feedbacks?.amountTriggerGood) {
            groupSettings.feedbacks.amountTriggerGood = 1;
        }
        if (groupSettings.feedbacks?.amountTriggerVeryGood == 0 || !groupSettings.feedbacks?.amountTriggerVeryGood) {
            groupSettings.feedbacks.amountTriggerVeryGood = 1;
        }
    }
    if (groupSettings.users?.users){
        groupSettings.users.users = groupSettings.users.users.filter(x=>!StringHelper.IsNullOrWhiteSpace(x.username));
    }
}