export enum SuggestionOriginType
{
    Device = 0,
    Public = 1
}

export class GroupSuggestion {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public createdTimestamp!: number;
    public createdTimestampString!: Date;
    public customText !: string;
    public messenger!: string;
    public suggestionOriginType !: SuggestionOriginType;
    public constructor(groupId: string, userId: string, createdTimestamp: number, customText: string, messenger: string) {
        this.systemETag = null;
        this.groupId = groupId;
        this.userId = userId;
        this.createdTimestamp = createdTimestamp;
        this.customText = customText;
        this.messenger = messenger;
    }

    static Columns = {
        id: 'id',
        groupId: "groupId",
        userId: "userId",
        items: "items",
        createdTimestamp: "createdTimestamp",
        createdTime: "createdTime",
        neutralAmount: "neutralAmount",
        customTexts: "customTexts",
        messenger: "messenger",
        suggestionOriginType: "suggestionOriginType"
    }

    static AvailableTypes = [
        
        { value: SuggestionOriginType.Device, label: 'Display Gerät' },
        { value: SuggestionOriginType.Public, label: 'Öffentlich' },
    ];

    static GetLabelByType = (type: SuggestionOriginType) => GroupSuggestion.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';

}