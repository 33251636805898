import { LoraGroupMotionItemSingleValueDaily } from "./LoraGroupMotionItem";

export class LoraGroupMotion {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public sensorId!: string;
    public sensorRoomName!: string;
    public blockCount!: number;
    public motionCount!: number;
    public sentBySensorTimestamp!: number;
    public createdTimestamp!: number;
    public createdTimestampString!: string;

    public isSummarizedMonth !: boolean;
    public summarizedFrom !: string;
    public summarizedUntil !: string;

    public rssi !: number;
    public snr !: number;
    public seqno !: number;
    public macAddressGateway !: string;
    public activity !: boolean;
    public fcnt !: number;
    public humidity !: number;
    public temperature !: number;
    public toa !: number;
    public motionEvent !: boolean;
    public pushEvent !: boolean;
    public serviceBusMessageId !: string;
    public serviceBusDeliveryCount !: number;
    public serviceBusSequenceNumber !: number;
    public serviceBusEnqueuedTime !: string;

    public constructor (groupId: string, sensorId: string, sensorRoomName: string,blockCount: number, motionCount: number, sentBySensorTimestamp: number, createdTimestamp: number) {
        this.systemETag = null;
        this.groupId = groupId;
        this.sensorId = sensorId;
        this.sensorRoomName = sensorRoomName;
        this.blockCount = blockCount;
        this.motionCount = motionCount;
        this.sentBySensorTimestamp = sentBySensorTimestamp;
        this.createdTimestamp = createdTimestamp;
    }

    static Columns = {
        id: 'id',
        sensorId: "sensorId",
        sensorRoomName: "sensorRoomName",
        blockCount: "blockCount",
        motionCount: "motionCount",
        sentBySensorTimestamp: "sentBySensorTimestamp",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        rssi: "rssi",
        snr : "snr",
        seqno: "seqno",
        macAddressGateway : "macAddressGateway",
        activity: "activity",
        fcnt: "fcnt",
        humidity: "humidity",
        temperature: "temperature",
        toa: "toa",
        motionEvent: "motionEvent",
        pushEvent: "pushEvent",
        serviceBusMessageId : "ServiceBusMessageId",
        serviceBusDeliveryCount : "ServiceBusDeliveryCount",
        serviceBusSequenceNumber  : "ServiceBusSequenceNumber",
        serviceBusEnqueuedTime : "ServiceBusEnqueuedTime"

    }
}

export class LoraGroupMotionDailyTable {
    day !: Date;
    data !: LoraGroupMotion[]

    static Columns = {
        id: 'id',
        sensorId: "sensorId",
        sensorRoomName: "sensorRoomName",
        blockCount: "blockCount",
        motionCount: "motionCount",
        sentBySensorTimestamp: "sentBySensorTimestamp",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        rssi: "rssi",
        snr : "snr",
        seqno: "seqno",
        macAddressGateway : "macAddressGateway",
        activity: "activity",
        fcnt: "fcnt",
        humidity: "humidity",
        temperature: "temperature",
        toa: "toa",
        motionEvent: "motionEvent",
        pushEvent: "pushEvent",
        incident: "incident",
        sum : "sum",
        day: "day"

    }
}

export class LoraGroupMotionWeeklyTable {
    week !: Date;
    data !: LoraGroupMotion[]

    static Columns = {
        id: 'id',
        sensorId: "sensorId",
        sensorRoomName: "sensorRoomName",
        blockCount: "blockCount",
        motionCount: "motionCount",
        sentBySensorTimestamp: "sentBySensorTimestamp",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        rssi: "rssi",
        snr : "snr",
        seqno: "seqno",
        macAddressGateway : "macAddressGateway",
        activity: "activity",
        fcnt: "fcnt",
        humidity: "humidity",
        temperature: "temperature",
        toa: "toa",
        motionEvent: "motionEvent",
        pushEvent: "pushEvent",
        incident: "incident",
        sum : "sum",
        week: "week",
        day: "day"

    }
}




export class LoraGroupSummaryMotionDailyTable {
    groupId !: string;
    notificationName !: string;
    data !: LoraGroupMotion[]

    static Columns = {
        id: 'id',
        sensorId: "sensorId",
        sensorRoomName: "sensorRoomName",
        blockCount: "blockCount",
        motionCount: "motionCount",
        sentBySensorTimestamp: "sentBySensorTimestamp",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        rssi: "rssi",
        snr : "snr",
        seqno: "seqno",
        macAddressGateway : "macAddressGateway",
        activity: "activity",
        fcnt: "fcnt",
        humidity: "humidity",
        temperature: "temperature",
        toa: "toa",
        motionEvent: "motionEvent",
        pushEvent: "pushEvent",
        incident: "incident",
        sum : "sum",
        groupNotificationName : "groupNotificationName"

    }
}


export class LoraGroupSummaryMotionDailyCalendarTable {
    groupId !: string;
    notificationName !: string;
    data !: {day: Date,data : LoraGroupMotion[]}[] 

    static Columns = {
        id: 'id',
        sensorId: "sensorId",
        sensorRoomName: "sensorRoomName",
        blockCount: "blockCount",
        motionCount: "motionCount",
        sentBySensorTimestamp: "sentBySensorTimestamp",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        rssi: "rssi",
        snr : "snr",
        seqno: "seqno",
        macAddressGateway : "macAddressGateway",
        activity: "activity",
        fcnt: "fcnt",
        humidity: "humidity",
        temperature: "temperature",
        toa: "toa",
        motionEvent: "motionEvent",
        pushEvent: "pushEvent",
        incident: "incident",
        sum : "sum",
        groupNotificationName : "groupNotificationName"

    }
}


export class LoraGroupSummaryMotionHitlist {
    groupId !: string;
    notificationName !: string;
    totalMotionAmount : number = 0;
    avgMotionAmount : number = 0;
    maxMotionAmount : number = 0;
    maxMotionDate !: Date;

    totalDailyMax !: LoraGroupMotionItemSingleValueDaily;
    totalHourlyMax !: LoraGroupMotionItemSingleValueDaily;
    totalWeekdayMax !: LoraGroupMotionItemSingleValueDaily;


    static Columns = {
        id: 'id',
        sensorId: "sensorId",
        sensorRoomName: "sensorRoomName",
        blockCount: "blockCount",
        motionCount: "motionCount",
        sentBySensorTimestamp: "sentBySensorTimestamp",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        rssi: "rssi",
        snr : "snr",
        seqno: "seqno",
        macAddressGateway : "macAddressGateway",
        activity: "activity",
        fcnt: "fcnt",
        humidity: "humidity",
        temperature: "temperature",
        toa: "toa",
        motionEvent: "motionEvent",
        pushEvent: "pushEvent",
        incident: "incident",
        sum : "sum",
        groupNotificationName : "groupNotificationName",
        totalMotionAmount: 'totalMotionAmount',
        avgMotionAmount: 'avgMotionAmount',
        maxMotionAmount: 'maxMotionAmount',
        maxMotionDate : 'maxMotionDate',
        totalMotionAmountMaxHourly: 'totalMotionAmountMaxHourly',
        totalMotionAmountMaxHourlyDate: 'totalMotionAmountMaxHourlyDate',
        totalMotionAmountMaxWeekday: 'totalMotionAmountMaxWeekday',
        totalMotionAmountMaxWeekdayDate: 'totalMotionAmountMaxWeekdayDate',
        totalMotionAmountMaxDaily: 'totalMotionAmountMaxDaily',
        totalMotionAmountMaxDailyDate: 'totalMotionAmountMaxDailyDate',


    }
}