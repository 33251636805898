
export enum ProductType {
    Unknown = 0,

    LicenseHardwarePackage12Months = 1,
    LicenseHardwarePackage24Months = 2,
    LicenseHardwarePackage36Months = 3,
    LicenseHardwarePackageTest = 4,


    LicenseCheckpointPackageTest = 19,
    LicenseCheckpointPackage12Months = 20,
    LicenseCheckpointPackage24Months = 21,
    LicenseCheckpointPackage36Months = 22,

    LicenseGroupUnitQualityCheckPackage12Months = 23,
    LicenseGroupUnitQualityCheckPackage24Months = 24,
    LicenseGroupUnitQualityCheckPackage36Months = 25,
    LicenseGroupUnitQualityCheckPackageTest = 26,

    LicenseObjectStandalonePackage12Months = 30,
    LicenseObjectStandalonePackage24Months = 31,
    LicenseObjectStandalonePackage36Months = 32,
    LicenseObjectStandalonePackageTest = 33,

    LicenseGroupPublicFeedbackPackage12Months = 40,
    LicenseGroupPublicFeedbackPackage24Months = 41,
    LicenseGroupPublicFeedbackPackage36Months = 42,
    LicenseGroupPublicFeedbackPackageTest = 43,

    LicenseGroupPayPerServicePackage12Months = 50,
    LicenseGroupPayPerServicePackage24Months = 51,
    LicenseGroupPayPerServicePackage36Months = 52,
    LicenseGroupPayPerServicePackageTest = 53,

    LicenseCalendarProPackage12Months = 60,
    LicenseCalendarProPackage24Months = 61,
    LicenseCalendarProPackage36Months = 62,
    LicenseCalendarProPackageTest = 63,

    LicenseGroupTaqtFeedbackPackage12Months = 70,
    LicenseGroupTaqtFeedbackPackage24Months = 71,
    LicenseGroupTaqtFeedbackPackage36Months = 72,
    LicenseGroupTaqtFeedbackPackageTest = 73,

    LicenseGroupTaqtTimePackage12Months = 74,
    LicenseGroupTaqtTimePackage24Months = 75,
    LicenseGroupTaqtTimePackage36Months = 76,
    LicenseGroupTaqtTimePackageTest = 77,

    ExtensionLicenseSensorExtendedOne12Months = 100,
    ExtensionLicenseSensorExtendedOne24Months = 101,
    ExtensionLicenseSensorExtendedOne36Months = 102,
    ExtensionLicenseSensorExtendedTwo12Months = 103,
    ExtensionLicenseSensorExtendedTwo24Months = 104,
    ExtensionLicenseSensorExtendedTwo36Months = 105,
    ExtensionContentManagement12Months = 106,
    ExtensionContentManagement24Months = 107,
    ExtensionContentManagement36Months = 108,
    ExtensionContentManagementTest = 109,
    LicenseLoraSensor12Months = 110,
    LicenseLoraSensor24Months = 111,
    LicenseLoraSensor36Months = 112,



    ReplacementHardware = 200,
    ReplacementSensor = 201,
    ReplacementRFIdKeys = 202,
    ReplacementDigitalCheckpoint = 203,
    ReplacementNFCKeys = 204,
    ReplacementTaqtFeedbackHardware = 205,
    ReplacementTaqtTimeHardware = 206,
    ReplacementBatteryDisplayHardware = 207,
    ReplacementTaqtOnlyFeedback = 208,
    ReplacementTaqtOnlyTime = 209,



    LicenseBasic12Months = 300,
    LicenseBasic24Months = 301,
    LicenseBasic36Months = 302,

    OnlyLicenseSensorExtendedOne12Months = 310,
    OnlyLicenseSensorExtendedOne24Months = 311,
    OnlyLicenseSensorExtendedOne36Months = 312,

    OnlyLicenseSensorExtendedTwo12Months = 320,
    OnlyLicenseSensorExtendedTwo24Months = 321,
    OnlyLicenseSensorExtendedTwo36Months = 322,

    OnlyLicenseLoraSensor12Months = 323,
    OnlyLicenseLoraSensor24Months = 324,
    OnlyLicenseLoraSensor36Months = 325,

    OnlyLicenseTimeTrackingPro1 = 326,
    OnlyLicenseTimeTrackingPro2 = 327,
    OnlyLicenseTimeTrackingPro3 = 328,

    OnlyLicenseMaterialStock1 = 329,
    OnlyLicenseMaterialStock2 = 330,
    OnlyLicenseMaterialStock3 = 331,

    OnlyLicenseCO2Documentation1 = 332,
    OnlyLicenseCO2Documentation2 = 333,
    OnlyLicenseCO2Documentation3 = 334,
    

    //bssd
    LicenseHardwarePackageBssdBasicTest = 399,
    LicenseHardwarePackageBssdBasic12Months = 400,
    LicenseHardwarePackageBssdBasic24Months = 401,
    LicenseHardwarePackageBssdBasic36Months = 402,

    ExtensionLicenseBssdSensorExtendedOne12Months = 403,
    ExtensionLicenseBssdSensorExtendedOne24Months = 404,
    ExtensionLicenseBssdSensorExtendedOne36Months = 405,



}

export enum ItemProductType {
    None = 0,
    CheckmenowLicense = 1,
    ContentManagementSystenLicense = 2,
    SensorLicense = 3,
    QualityCheckLicense = 4,
    ObjectStandaloneLicense = 5,
    BssdCheckmenowLicense = 6,
    BssdSensorLicense = 7,
    LoraSensorLicense = 8,
    PublicFeedbackLicense = 50,
    PayPerServiceLicense = 51,
    CalendarProLicense = 52,
    TaqtFeedbackLicense = 53,
    TaqtTimeLicense = 54,


    //equipment
    Sensor = 10,
    RFIDKey = 11,
    Display = 12,
    Checkpoint = 13,
    BssdDisplay = 14,
    BssdSensor = 15,
    BssdGateway = 16,
    LoraSensor = 17,
    //Replacement
    RPLDisplay = 100,
    RPLSensor = 101,
    RPLDigitalCheckpoint = 102,
}
