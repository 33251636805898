import TextField from '@mui/material/TextField';
import { UserAccount } from '../../../models/UserAccount';
import { UserType } from "../../../models/UserType";
import { Button, Checkbox, CircularProgress, Divider, Fab, FormControlLabel, Grid, SwipeableDrawer } from '@mui/material';
import { Add, Mail, MarkEmailRead, Remove, Save, SupervisedUserCircle, TouchApp } from '@mui/icons-material';
import { errorColor, white } from '../../../styles/colors';
import { primaryColor } from '../../../App';
import { useHistory } from "react-router-dom";
import React from 'react';
import { objectCustomerName } from './ManagerCustomers';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { useSnackbar } from 'notistack';

export const ManagerCustomersUserItem = ({ user, setUser, userIndex, users, onManageUserUnits, onManageObjects, onInviteUser, onDeleteUser, onUpdate, onManageUserVisibility }
    : { user: UserAccount; setUser: any; userIndex: number, users: UserAccount[]; onManageUserUnits: any; onManageObjects: any; onInviteUser: any; onDeleteUser: any; onUpdate: any; onManageUserVisibility: any }) => {

    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();


    return (
        <Grid item xs={12} container direction="row" alignItems="center" alignContent="center" spacing={2}>



            <Grid item lg={2}>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label="Vorname"
                    value={user.firstname || ''}
                    onChange={(event: any) => {
                        user.firstname = event.target.value;
                        setUser([...users]);
                    }} />
            </Grid>

            <Grid item lg={2}>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label="Nachname"
                    value={user.lastname || ''}
                    onChange={(event: any) => {
                        user.lastname = event.target.value;
                        setUser([...users]);
                    }} />
            </Grid>

            <Grid item lg={2}>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label="Mail"
                    value={user.mail || ''}
                    onChange={(event: any) => {
                        user.mail = event.target.value;
                        setUser([...users]);
                    }} />
            </Grid>

            <Grid item lg={2}>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label="Company"
                    value={user.company || ''}
                    onChange={(event: any) => {
                        user.company = event.target.value;
                        setUser([...users]);
                    }} />
            </Grid>

            <Grid item lg={2}>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label="Titel"
                    value={user.title || ''}
                    onChange={(event: any) => {
                        user.title = event.target.value;
                        setUser([...users]);
                    }} />
            </Grid>
            <Grid container item lg={2} direction={"row"} spacing={3}>
                <Grid item>
                    <Fab
                        size="small"
                        style={{ backgroundColor: errorColor, color: white }}
                        aria-label="add"
                        onClick={async () => {
                            await onDeleteUser(user, userIndex);
                        }}>
                        <Remove />
                    </Fab>
                </Grid>
                {user.id &&
                    <Grid item>
                        <Fab
                            size="small"
                            style={{ backgroundColor: primaryColor, color: white }}
                            aria-label="add"
                            onClick={async () => {
                                await onUpdate(user, userIndex);
                            }}>
                            <Save />
                        </Fab>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item style={{ flexGrow: 1 }}></Grid>
            <Grid item>
                <Button
                    size="large"
                    startIcon={<MarkEmailRead />}
                    variant={'contained'}
                    disabled={loading}
                    onClick={async () => {
                        setLoading(true);
                        await api.reSendRegistrationMail(user.id, enqueueSnackbar, getApiToken(history));
                        setLoading(false);
                    }}
                    endIcon={loading && <CircularProgress size={32} />}
                    color="primary"
                >
                    Einladungsemail erneut senden
                </Button>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox
                        checked={user.activated}
                        onChange={() => {
                            user.activated = !user.activated;
                            setUser([...users]);
                        }}
                        name="usernames"
                        color="primary" />}
                    label="Aktiviert" />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox
                        checked={user.mailConfirmed}
                        onChange={() => {
                            user.mailConfirmed = !user.mailConfirmed;
                            setUser([...users]);
                        }}
                        name="usernames"
                        color="primary" />}
                    label="Email Bestätigt" />
            </Grid>

            {
                user.id &&
                <Grid item container direction="column" spacing={2} lg={2}>
                    <Grid item>
                        <Button
                            size="large"
                            startIcon={<TouchApp />}
                            variant={'contained'}
                            disabled={loading}
                            endIcon={loading && <CircularProgress size={32} />}
                            onClick={async () => {
                                setLoading(true);
                                await onManageUserUnits(user, userIndex);
                                setLoading(false);
                            }}
                            color="primary"
                        >
                            Einheiten Verwalten
                        </Button>
                    </Grid>

                </Grid>
            }
            {
                user.id &&
                <Grid item container direction="column" spacing={2} lg={2}>
                    <Grid item>
                        <Button
                            size="large"
                            startIcon={<TouchApp />}
                            variant={'contained'}
                            disabled={loading}
                            endIcon={loading && <CircularProgress size={32} />}
                            onClick={async () => {
                                setLoading(true);
                                await onManageObjects(user, userIndex);
                                setLoading(false);
                            }}
                            color="primary"
                        >
                            Objekte Verwalten
                        </Button>
                    </Grid>

                </Grid>
            }
            {
                user.id &&
                <Grid item container direction="column" spacing={2} lg={2}>
                    <Grid item>
                        <Button
                            size="large"
                            startIcon={<TouchApp />}
                            variant={'contained'}
                            disabled={loading}
                            endIcon={loading && <CircularProgress size={32} />}
                            onClick={async () => {
                                setLoading(true);
                                await onManageUserVisibility(user, userIndex);
                                setLoading(false);
                            }}
                            color="primary"
                        >
                            Usermenü Verwalten
                        </Button>
                    </Grid>

                </Grid>
            }
            {
                user.id &&
                <Grid item container direction="column" spacing={2} lg={2}>
                    <Grid item>
                        <Button
                            size="large"
                            startIcon={<SupervisedUserCircle />}
                            variant={'contained'}
                            onClick={async () => {
                                const queryParams = { name: user.lastname, email: user.mail, nfcCode: '0000000000' }
                                const queryString = new URLSearchParams(queryParams).toString()
                                history.push({
                                    pathname: '/portal/servicekräfte',
                                    search: queryString
                                });
                            }}
                            endIcon={<Add />}
                            color="primary"
                        >
                            Als Objektleiter hinzufügen
                        </Button>
                    </Grid>

                </Grid>
            }
            {
                !user.activated && !user.id &&
                <Grid item lg={2}>
                    <Button
                        size="large"
                        startIcon={<Mail />}
                        variant={'contained'}
                        onClick={async () => {
                            await onInviteUser(user, userIndex);
                        }}
                        color="primary"
                    >
                        {user.userType == UserType.Customer && "Kunden Einladen"}
                        {user.userType == UserType.Manager && "Manager Einladen"}
                        {user.userType == UserType.ObjectCustomer && `${objectCustomerName} Einladen`}
                    </Button>
                </Grid>
            }
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
};
