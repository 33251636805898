import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, QrCodeScanner, PhotoCamera, CalendarViewDay, AlarmAdd, EventNote, Edit, Groups, PersonPin } from "@mui/icons-material";
import { errorColor, white } from "../../styles/colors";
import { ReactComponent as FormatListChecksSvg } from '../../assets/svgs/format-list-checks.svg';
import { useSnackbar } from 'notistack';
import { GroupSettingFieldsUseStyles } from '../../styles/GroupSettingFieldsStyles';
import { CleaningOperation, GroupSettings, ServiceWorkingModeType } from '../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { useContainerStyles } from '../../styles/container';
import { Group, GroupType } from "../../models/Group";
import { StandardWorkItem, StandardWorkItemType } from "../../models/StandardItem";
import { api } from "../../api";
import { FileBlobType } from "../../models/FileBlobType";
import DefaultItemsDialogOld, { DefaultItemsType, getDefaultIcon } from "../dialogs/DefaultItemsDialogOld";
import { LocalMedia } from "../images/NetworkMedia";
import DefaultItemsDialog from "../dialogs/DefaultItemsDialog";
import { AssignIntervalDialog } from "../dialogs/groupSettings/AssignIntervalDialog";
import { IntervalControl } from "../../models/IntervalControl";
import { CleaningOperationIntervalDialog } from "../dialogs/groupSettings/CleaningOperationIntervalDialog";
import { useHistory } from "react-router-dom";
import { color } from "html2canvas/dist/types/css/types/color";
import { IntervalControlPage } from "../authorized/extendedapp/interval/IntervalControlPage";
import { GroupIntervalControlDialog } from "../dialogs/groupSettings/GroupIntervalControlDialog";
import { TextFieldGroupSettings } from "./components/groupSettings/TextFieldGroupSettings";
import { useTranslation } from "react-i18next";

export const GroupSettingFieldsCleaningPerformance = ({ row, onChange, group, token, loadStandardServices, standardServices, buildStandardItemIcon }
    : { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, group: Group, token: any, loadStandardServices: any, standardServices: StandardWorkItem[], buildStandardItemIcon: any }) => {

    const classes = GroupSettingFieldsUseStyles();
    const containerStyles = useContainerStyles();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { t } = useTranslation();


    const [openIntervalAssignDialog, setOpenIntervalAssignDialog] = React.useState(false);
    const [intervalControls, setIntervalControls] = React.useState<IntervalControl[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [currentCleaningOperation, setCurrentCleaningOperation] = React.useState<CleaningOperation>();

    const [openIntervalCleaningOperationsDialog, setOpenIntervalCleaningOperationsDialog] = React.useState(false);
    const [openGroupIntervalManagementDialog, setOpenGroupIntervalManagementDialog] = React.useState(false);
    const [expandedPart, setExpoandedPart] = React.useState(false);

    const [standardServicesWC, setStandardServicesWC] = React.useState<StandardWorkItem[]>([]);


    React.useEffect(() => {
        if (expandedPart) loadData();
    }, [openGroupIntervalManagementDialog]);


    const loadData = async () => {

        setLoading(true);

        let intervalControls = await api.GetIntervalControlByUser(token);
        setIntervalControls([...intervalControls]);

        setLoading(false);

    }

    const IntervallManagementButton = () => {
        return (
            <Grid item>
                <Button
                    size="large"
                    startIcon={<EventNote />}
                    endIcon={<Edit />}
                    variant={'contained'}
                    onClick={async () => {
                        //       history.push("/portal/interval");
                        setOpenGroupIntervalManagementDialog(true);
                    }}
                    color="primary"
                >
                    {t("myUnitsSettings.manageInterval")}
                </Button>
            </Grid>
        )
    }



    return (
        <Accordion className={classes.hover} onChange={async (event, expanded) => {
            if (expanded && !expandedPart && intervalControls.length < 1) {
                loadData();
                setExpoandedPart(true);
            }
            if (expanded && standardServices.length < 1) {
                loadStandardServices();
            }
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}            >
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <FormatListChecksSvg height={40} width={40} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage.serviceTasks")}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={3}>

                    <Grid item container direction={"row"} alignItems="center" spacing={3}>
                        <Grid item>
                            <FormControlLabel
                                label={<Typography>{t("myUnitsSettings.publicServiceActivated")}</Typography>}
                                control={
                                    <Checkbox
                                        name="isPublicServiceEnabled"
                                        color="primary"
                                        checked={row.cleaningOperations?.isPublicServiceEnabled}
                                        onChange={(event: any, checked: boolean) => {
                                            if (!row.cleaningOperations) return;
                                            row.cleaningOperations.isPublicServiceEnabled = checked;
                                            onChange({ target: { name: "row", value: "" } });
                                        }}
                                    />
                                }
                            />
                        </Grid>

                    </Grid>


                    <Grid item container direction={"row"} alignItems="center">
                        <Grid item>
                            <FormControlLabel
                                label={<Typography>{t("myUnitsSettings.forceServiceStartByQrCodeScanInMobileIosAndroidApp")}</Typography>}
                                control={
                                    <Checkbox
                                        name="forceScanForServiceModeStart"
                                        color="primary"
                                        checked={row.cleaningOperations?.forceScanForServiceModeStart}
                                        onChange={(event: any, checked: boolean) => {
                                            if (!row.cleaningOperations) return;
                                            row.cleaningOperations.forceScanForServiceModeStart = checked;
                                            onChange({ target: { name: "row", value: "" } });
                                        }}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item>
                            <PhotoCamera style={{ fontSize: 50 }} />
                        </Grid>
                        <Grid item>
                            <QrCodeScanner style={{ fontSize: 50 }} />
                        </Grid>
                    </Grid>


                    <Grid item container direction={"row"} alignItems="center">

                        <Grid item>
                            <FormControlLabel
                                label={<Typography>{t("myUnitsSettings.forceServiceEndByQrCodeScanInMobile")}</Typography>}
                                control={
                                    <Checkbox
                                        name="forceScanForFinishSerivceMode"
                                        color="primary"
                                        checked={row.cleaningOperations?.forceScanForFinishSerivceMode}
                                        onChange={(event: any, checked: boolean) => {
                                            if (!row.cleaningOperations) return;
                                            row.cleaningOperations.forceScanForFinishSerivceMode = checked;
                                            onChange({ target: { name: "row", value: "" } });
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item>
                            <PhotoCamera style={{ fontSize: 50 }} />
                        </Grid>
                        <Grid item>
                            <QrCodeScanner style={{ fontSize: 50 }} />
                        </Grid>
                    </Grid>

                    {group.groupType == GroupType.DisplayBatteryWallApp &&
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Servicebestätigung Auswahl</FormLabel>
                                <RadioGroup
                                    row
                                    name="serviceWorkingModeType"
                                    value={row.cleaningOperations?.serviceWorkingModeType || ServiceWorkingModeType.PotentialFake}
                                    onChange={onChange}
                                >
                                    <FormControlLabel
                                        value={ServiceWorkingModeType.PotentialFake}
                                        control={<Radio />}
                                        label={t("myUnitsSettings.serviceDirectAtBatteryDisplayFakeTimesPossible")}
                                    />
                                    <FormControlLabel
                                        value={ServiceWorkingModeType.NoFakeSecure}
                                        control={<Radio />}
                                        label={t("myUnitsSettings.appModeNoFakeTimesOnDisplay")}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item container justifyContent={"flex-start"} spacing={3}>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Add />}
                                variant={'contained'}
                                onClick={async () => {
                                    onChange({ target: { name: 'cleaningOperationsAdd', value: "" } });
                                }}
                                color="primary"
                            >
                                {t("myUnitsSettings.addServicePerformance")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <DefaultItemsDialog
                                onLoadFiles={async () => {
                                }}
                                allStandardItems={standardServices}
                                currentItems={row.cleaningOperations?.cleaningOperations.map(el => standardServices.find(x => x.id == el.standardItemId) || new StandardWorkItem()) || []}
                                manageItems={(newItems: StandardWorkItem[]) => {
                                    onChange({ target: { name: 'cleaningOperationsAddMultiple', value: newItems.map(x => CleaningOperation.CreateInstance(x.text, true, x.id)) } });
                                }}
                                itemType={StandardWorkItemType.Serivce}
                            />
                        </Grid>
                        <Grid item>
                            <DefaultItemsDialogOld
                                currentItems={row.cleaningOperations?.cleaningOperations.map(el => el.text) || []}
                                manageItems={(newItems: any[]) => {
                                    onChange({ target: { name: 'cleaningOperationsAddMultipleOld', value: newItems } });
                                }}
                                itemType={DefaultItemsType.Services}
                            />
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}> </Grid>
                        <IntervallManagementButton />
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<EventNote />}
                                variant={'contained'}
                                onClick={async () => {
                                    if (intervalControls.length < 1) {
                                        await loadData();
                                    }
                                    setOpenIntervalCleaningOperationsDialog(true);
                                }}
                                color="primary"
                            >
                                Intervallgruppe mit Services Anzeigen
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"row"} spacing={3}>
                        {
                            row.cleaningOperations &&
                            row.cleaningOperations.cleaningOperations &&
                            row.cleaningOperations.cleaningOperations.map((operation: CleaningOperation, index: number) => {
                                const currentStandardItem = standardServices.find(x => x.id == operation.standardItemId);
                                let isStandardOperation = operation?.intervalControlIds && operation?.intervalControlIds?.length < 1;
                                return (
                                    <Grid key={index} item md={12} lg={6} xl={4} container direction="row" alignItems="center" alignContent="center" justifyContent={"center"} spacing={0}>
                                        <Grid item xs={1}>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                onClick={() => {
                                                    onChange({ target: { name: 'cleaningOperationsRemove', value: JSON.stringify({ index: index }) } });
                                                }}>
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        <Grid item xs={6} className={containerStyles.marginFieldSmall}>
                                            {currentStandardItem ?
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    style={{ color: 'red' }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment variant="filled" position="end">{buildStandardItemIcon(currentStandardItem)}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    disabled={true}
                                                    name="cleaningOperations"
                                                    label={t("myUnitsSettings.standardServicePerformance")}
                                                    value={currentStandardItem.text || ''} onChange={undefined} />
                                                :
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    InputProps={{
                                                        style: { color: isStandardOperation ? "black" : 'red' },
                                                        endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(operation.text || '')}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="cleaningOperations"
                                                    label={t("myUnitsSettings.servicePerformanceAgain")}
                                                    value={operation.text || ''}
                                                    onChange={(event: any) => {
                                                        let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                        onChange(res);
                                                    }}
                                                />
                                            }
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={operation.isEnabled}
                                                    onChange={(target: any) => {
                                                        target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                        onChange(target);
                                                    }}
                                                    name="cleaningOperations"
                                                    color="primary" />
                                                }
                                                label={t("myUnitsSettings.activated")} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title="Nur Superservicekraft-Typ oder alle Servicekraft-Typen können Service durchführen">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        icon={<Groups />}
                                                        checkedIcon={<PersonPin />}
                                                        checked={operation.onlySuperServiceWorker}
                                                        onChange={(target: any) => {
                                                            target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'onlySuperServiceWorker' });
                                                            onChange(target);
                                                        }}
                                                        name="cleaningOperations"
                                                        color="primary" />
                                                    }
                                                    label={operation.onlySuperServiceWorker ? t("myUnitsSettings.superSk") : t("myUnitsSettings.allSS")} />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Typography color={isStandardOperation ? "black" : "red"}>{isStandardOperation ? `${t("myUnitsSettings.standardInterval")}` : `${t("myUnitsSettings.groupInterval")} (${operation?.intervalControlIds?.length}): ${intervalControls.filter(item => operation.intervalControlIds.includes(item.id)).map(x => x.name).join(',')}`}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton color="primary" onClick={async () => {
                                                if (intervalControls.length < 1) {
                                                    await loadData();
                                                }
                                                setOpenIntervalAssignDialog(true);
                                                setCurrentCleaningOperation(operation);
                                            }}>
                                                <AlarmAdd />
                                            </IconButton>
                                            {loading && <CircularProgress size={32} />}
                                        </Grid>

                                    </Grid>
                                );
                            })}

                    </Grid>
                </Grid>
                {currentCleaningOperation &&
                    <AssignIntervalDialog onAssign={(selectedIntervalIds: string[]) => {
                        if (currentCleaningOperation) currentCleaningOperation.intervalControlIds = selectedIntervalIds;
                        onChange({ target: { name: "row", value: "" } });
                    }} open={openIntervalAssignDialog} setOpen={setOpenIntervalAssignDialog} onRefresh={undefined} intervalControls={intervalControls} intervalControlIds={currentCleaningOperation?.intervalControlIds} IntervallButton={IntervallManagementButton} />
                }
                {row.cleaningOperations?.cleaningOperations &&
                    <CleaningOperationIntervalDialog cleaningOperations={row.cleaningOperations.cleaningOperations} open={openIntervalCleaningOperationsDialog} setOpen={setOpenIntervalCleaningOperationsDialog} onRefresh={undefined} intervalControls={intervalControls} />
                }
                {intervalControls &&
                    <GroupIntervalControlDialog open={openGroupIntervalManagementDialog} setOpen={setOpenGroupIntervalManagementDialog} onRefresh={undefined} intervalControls={intervalControls} setIntervalControls={setIntervalControls} />
                }
            </AccordionDetails>
        </Accordion>
    );
};
