import Dialog from '@mui/material/Dialog';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { CheckpointSelectionTable } from './CheckpointSelectionDataTable';
import { GroupType } from '../../../../models/Group';
import { GlobalContext } from '../../PortalPage';
import { UserType } from '../../../../models/UserType';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';
import { handleSelectedCombinationGroup } from '../../../tables/Base';
import { useTranslation } from 'react-i18next';

export const CheckpointSelectionDialog = ({
  open,
  setOpen,
  groups,
  onEdit,
  onExtendLicense,
  onNavigate,
  groupType
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable[],
  onEdit: any,
  onExtendLicense: any,
  onNavigate: any,
  groupType: GroupType
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [token, setToken] = React.useState("");
  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
  const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
  const {t} = useTranslation();

  const { userAccount, setSelectedCombinationGroup
  }: any = useContext(GlobalContext);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const tempObjects = await api.getUserObjectUnits(tempToken);
      setObjects(tempObjects);

    })();

  }, [open]);

  const disabled = selectedGroups.length < 1;

  let title = `Checkpoint Anzeige`;
  if (groupType == GroupType.TaqtFeedback || groupType == GroupType.TaqtTime) {
    title = `Taqt Anzeige`
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={title}
        handleClose={handleClose}
      />

      <DialogContent>
        <CheckpointSelectionTable
          groupType={groupType}
          onEdit={onEdit}
          onExtendLicense={onExtendLicense}
          onNavigate={onNavigate}
          groups={groups} objects={objects}
          selectedGroups={selectedGroups}
          onSelectionChange={(selection: string[]) => {
            //   let selected = selection[selection.length - 1];
            setSelectedGroups(groups.filter(x => selection.includes(x.id)));
          }}
        />
      </DialogContent>

      <DialogActions>
        {
          userAccount?.userType == UserType.Admin &&
          <Button
            color='error'
            variant="contained"
            size="large"
            startIcon={<Close />}
            disabled={selectedGroups.length < 1}
            onClick={() => {

              if (selectedGroups.length < 1) return;
              setDeleteConfirmationDialogVisible(true);
            }}
          >
            Einheit löschen
          </Button>
        }
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Add />}
          disabled={disabled}
          onClick={async () => {
            handleSelectedCombinationGroup(selectedGroups, groups, setSelectedCombinationGroup, history);
          }}
        >
          Ausgewählte Einheiten kombinieren
        </Button>
      </DialogActions>
      <ConfirmationDialog
        visible={deleteConfirmationDialogVisible}
        title={`Sind Sie sicher, die ausgewählten Einheiten zu löschen?`}
        onClose={() => setDeleteConfirmationDialogVisible(false)}
        onConfirmed={async () => {

          if (selectedGroups.length < 1) return;

          const token = getApiToken(history);
          for (let currentGroup of selectedGroups) {
            await api.deleteGroupAsync(currentGroup.id, token, enqueueSnackbar);
          }

          setDeleteConfirmationDialogVisible(false);
          handleClose();
        }}
      />

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));