import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { usePasswordForgottenFormStyles } from '../../styles/password-forgotten';
import { useHistory } from 'react-router-dom';
import { StringHelper } from '../../helpers/string-helper';
import { api } from '../../api';
import { useSnackbar } from 'notistack';

export const PasswordForgottenPage = () => {

  const [email, setEmail] = React.useState<string | null>(null);
  const [sent, setSent] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [waitingTime, setWaitingTime] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [infoText, setInfoText] = React.useState<string>('Eine E-Mail wird an die angegebene Addresse gesendet, wo Sie mit dem Link, Ihr Passwort zurücksetzen können');
  const [buttonText, setButtonText] = React.useState<string>('Passwort Zurücksetzung anfordern');
  const [helperText, setHelperText] = React.useState<string | null>(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = usePasswordForgottenFormStyles();

  const onEmailChanged = async (e: any) => {
    if (!e?.target) return;

    setEmail(e.target.value);
  }

  const onEmailClicked = async () => {
    setHelperText(null);
    setHasError(false);
  }

  const onSubmit = async () => {
    setLoading(true);

    if (!email || !StringHelper.IsValidEmail(email)) {
      setLoading(false);
      setHasError(true);
      setHelperText('Invalid email address');
      return;
    }

    const requestSucceed = await api.requestResetPassword(email, enqueueSnackbar);

    if (!requestSucceed) {
      setLoading(false);
      return;
    }

    setSent(true);
    setHelperText(null);
    setButtonText('Passwort Zurücksetzung angefordert');
    setInfoText(`Email wurde gesendet. Bitte überprüfen Sie ihre Posteingang. Sie werde in ${waitingTime} Sekunden in die Loginseite weitergeleitet`);
    setWaitingTime(time => time - 1);
  }

  React.useEffect(() => {
    if (!sent) return;

    if (waitingTime < 0) {
      history.push('/login');
      return;
    }

    const timer = setTimeout(() => {
      setInfoText(`Email wurde gesendet. Bitte überprüfen Sie ihre Posteingang. Sie werde in ${waitingTime} Sekunden in die Loginseite weitergeleitet`);
      setWaitingTime(time => time - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
    }
  }, [waitingTime])

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Passwort vergessen</Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography className="Info" component="p" variant="body1">{infoText}</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                value={email}
                onChange={onEmailChanged}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                helperText={helperText}
                disabled={loading}
                onClick={onEmailClicked}
                error={hasError}
              />
            </Grid>

          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            onClick={onSubmit}>
            {buttonText}
          </Button>
        </form>
      </div>
    </Container>
  );
}
