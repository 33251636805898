import React from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { useTranslation } from 'react-i18next';

export const PropertyObjectSelectionDataTable = ({ rows, objects, selectedProperties, onSelectionChange, currentGroup }
    : { rows: UserProperty[], objects: ObjectUnit[], selectedProperties: UserProperty[], onSelectionChange: any, currentGroup: GroupLicenseTable | null }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();
    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === ObjectUnit.Columns.id) {
            let names = objects.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).map(x => x.name);
            return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} Objekte`);
        }
        if (column.name === ObjectUnit.Columns.addressLine) {
            let names = objects.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).map(x => x.addressLine);
            return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} Objekt-Adresse`);
        }
        return <Table.Cell {...props} />;
    };

    const assignedColumn = {
        name: UserProperty.Columns.assign,
        title: t("myObjectSettings.assigned"),
        getCellValue: (row: UserProperty) => currentGroup && currentGroup.userPropertyIds.includes(row.id) ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")
    };
    let columns = [
        {
            name: UserProperty.Columns.name,
            title: "Name",
            getCellValue: (row: UserProperty) => row.name,
        },
        {
            name: ObjectUnit.Columns.id,
            title: t("myObjectSettings.assignedObjects"),
            getCellValue: (row: UserProperty) => {
                return objects.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.name) return prevVal
                    return curVal.name + ", " + prevVal;
                }, "");
            },
        },
        {
            name: ObjectUnit.Columns.addressLine,
            title: t("myUnitsSettings.address"),
            getCellValue: (row: UserProperty) => {
                return objects.filter(x => x.userPropertyIds && x.userPropertyIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.addressLine) return prevVal
                    return curVal.addressLine + ", " + prevVal;
                }, "");;
            },
        },

    ];

    if (currentGroup){
        columns = [assignedColumn, ...columns]
    }

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 300 } }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: UserProperty) => row.id}
                columns={columns}
            >

                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedProperties.map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                    columnExtensions={[
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
        </div>
    );
};
