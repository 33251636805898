import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Fab, FormControlLabel, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, LocalShipping, Category, CheckCircle, ManageSearch } from "@mui/icons-material";
import { GroupSettings, MaterialConsumption } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { TextFieldGroupSettings } from "./components/groupSettings/TextFieldGroupSettings";
import { ObjectUnit } from "../../models/ObjectUnit";
import { SettingsContext } from "../authorized/PortalPage";
import { GroupMaterialStockDialog } from "../dialogs/GroupMaterialStockDialog";
import { useTranslation } from "react-i18next";

export const GroupSettingFieldsMaterialStock = ({ currentObjectUnit, row, onChange, token }: { currentObjectUnit: ObjectUnit | undefined, row: GroupSettings | UserGroupSettingsTemplate; onChange: any; token: any }) => {

    const classes = GroupSettingFieldsUseStyles();

    const { refreshSettings } = React.useContext(SettingsContext);



    const [expanded, setExpanded] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();



    return (
        <Accordion expanded={expanded} className={classes.hover} onChange={async (event, expanded) => {
            setExpanded(expanded);
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <LocalShipping style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("drawerList.materialEconomy")}</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={row.materialConsumptions?.isUserStockEnabled}
                                onChange={(event: any) => {
                                    if (row.materialConsumptions) {
                                        row.materialConsumptions.isUserStockEnabled = event.target.checked;
                                        refreshSettings();
                                    }
                                }}
                                name=""
                                color="primary" />}
                            label={`${t("drawerList.materialEconomy")} ${t("settingsPage.activated")}`} />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={row.materialConsumptions?.isMaterialConsumptionInUserStockIncluded}
                                onChange={(event: any) => {
                                    if (row.materialConsumptions) {
                                        row.materialConsumptions.isMaterialConsumptionInUserStockIncluded = event.target.checked;
                                        refreshSettings();
                                    }
                                }}
                                name=""
                                color="primary" />}
                            label={`${t("drawerList.materialEconomyIncludedMaterialConsumption")} ${t("settingsPage.activated")}`} />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item container direction={"row"} alignItems="center" spacing={4}>
                    <Grid item xs={12}>
                        <TextFieldGroupSettings
                            style={{ width: 500 }}
                            variant={"filled"}
                            name="servicesAutoEmail"
                            label={t("myUnitsSettings.alarmEmailAllMaterialsOfTheUnit")}
                            value={row?.notification?.alarmEmailMaterialStock || ""}
                            onChange={(event) => {
                                if (row.notification) {
                                    row.notification.alarmEmailMaterialStock = event.target.value;
                                    refreshSettings();
                                }

                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            style={{ width: 500 }}
                            startIcon={<ManageSearch />}
                            variant={'contained'}
                            color="primary"
                            onClick={async () => {
                                setOpen(true);
                            }}
                        >
                            {t("myUnitsSettings.manageMaterialInTheUnit")}
                        </Button>
                    </Grid>
                </Grid>
                {row &&
                    <GroupMaterialStockDialog token={token} open={open} row={row as GroupSettings} onClose={() => {
                        setOpen(false);
                    }} />
                }
            </AccordionDetails>
        </Accordion>
    );
};
