import axios from 'axios';
import { UserStock } from '../../models/UserStock';
import { getUserIdFromStorage } from '../../helpers/common';
import { handleApiError, headers } from '../../api';

export const getLatestUserStockByObjectId = async (token: string, objectId: string): Promise<UserStock> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/objects/${objectId}/latest-user-stock`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}

export const getLatestUserStockByGroupId = async (token: string, groupId: string): Promise<UserStock> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/groups/${groupId}/latest-user-stock`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}

export const getLatestUserStockByGroupIds = async (token: string, groupIds: string []): Promise<UserStock[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/latest-user-stock-by-groupIds`, {StringArray : groupIds}, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}