import {
    FormControlLabel,
    IconButton,
    Grid,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import React from "react";
import { CurrencyHelper } from '../../../../helpers/CurrencyHelper';
import { CheckoutItem } from '../../../../models/CheckoutItem';
import { NumberInput } from '../../../common/NumberInput';
import { OrderOverViewTableSummary } from './OrderOverViewTableSummary';
import { ItemProduct } from './../../../../models/Product';
import { UserAddress } from "../../../../models/UserAddress";

export const OrderOverViewTable = ({ items, setItems, userAddress }
    : { items: CheckoutItem[], setItems?: React.Dispatch<React.SetStateAction<CheckoutItem[]>>, userAddress: UserAddress | null }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableRowCell} align="left">Bezeichnung</TableCell>
                            <TableCell className={classes.tableRowCell} align="left">Beschreibung</TableCell>
                            <TableCell className={classes.tableRowCellSmall} align="left">Einzelpreis</TableCell>
                            <TableCell className={classes.tableRowCellSmall} align="left">Anzahl</TableCell>
                            <TableCell className={classes.tableRowCellSmall} align="right">Summe</TableCell>
                            {
                                setItems &&
                                <TableCell className={classes.tableRowCell} />
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items &&
                            items.length > 0 &&
                            items
                                .sort((a, b) => a.product.type - b.product.type)
                                .map((item: CheckoutItem, rowIndex: number) => (
                                    <TableRow key={rowIndex}>
                                            <TableCell align="left">{item.product.name}</TableCell>
                                            <TableCell align="left">
                                                {item.product.description}
                                                {
                                                    item.product.itemProduct && 
                                                    item.product.itemProduct.filter(el => el.isVisible) &&
                                                    <ul>
                                                        In Ihrem Einkauf Inkludiert:
                                                        {
                                                            item.product.itemProduct
                                                            .map((itemproduct: ItemProduct) => {
                                                              return(
                                                                    <li>
                                                                        
                                                                        <Grid xs={12} direction="row">
                                                                            <Grid item xs={6}>
                                                                                {itemproduct.name}
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                {CurrencyHelper.toCurrencyString(itemproduct.price)}
                                                                            </Grid>
                                                                        </Grid>
                                                                         
                                                                    </li>
                                                                )  
                                                            })
                                                        }
                                                    </ul>
                                                    
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    CurrencyHelper.toCurrencyString(item.unitPrice)
                                                }
                                                </TableCell>
                                            <TableCell align="left">
                                                {
                                                    setItems &&
                                                    <NumberInput
                                                        value={item.count}
                                                        onValueChanged={(value: number) => {

                                                            if (isNaN(value) || !setItems) return;

                                                            item.count = value;
                                                            item.updatePrice();

                                                            setItems([...items]);
                                                        }}
                                                    />
                                                }
                                                {
                                                    !setItems &&
                                                    <Typography>{item.count}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="right">{CurrencyHelper.toCurrencyString(item.total)}</TableCell>

                                            {
                                                setItems &&
                                                <TableCell align="left">
                                                    <Tooltip title="Eintrag entfernen ?">
                                                        <IconButton
                                                            className={classes.deleteButton}
                                                            onClick={() => {
                                                                items.splice(rowIndex, 1);
                                                                setItems([...items]);
                                                            }}
                                                            size="large">
                                                            <Close />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            }
                                        
                                        {/* {
                                            item.product.itemProduct && 
                                            item.product.itemProduct
                                                .filter(el => el.isVisible)
                                                .map((itemproduct: ItemProduct) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell align="left">{itemproduct.name}</TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left">{CurrencyHelper.toCurrencyString(itemproduct.price)}</TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            
                                                        </TableRow>
                                                    )
                                                })
                                        } */}
                                        

                                    </TableRow>
                                ))
                                
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <OrderOverViewTableSummary items={items} userAddress={userAddress}/>

        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {

    },
    tableRowCell: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    tableRowCellSmall: {
        fontWeight: 'bold',
        fontSize: 16,
        width: 128,
    },
    tableCellNumber: {
        fontSize: 32,
    },
    container: {
        height: '40vh',
        '&::-webkit-scrollbar': {
            width: 12,
        },
        '&::-webkit-scrollbar-track': {
            width: 12,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 12,
            borderRadius: 16,
            backgroundColor: theme.palette.primary.main,
        },
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
}));