import { Group } from "./Group";
import { CleaningOperations, Content, Feedback, GroupSettings, GroupSettingsLogo, IncidentReports, LocationInformation, PayPerServicesControl, SensorControl, User } from "./GroupSettings";

export class GroupSettingsExtended {
    public groupSettings !: GroupSettings
    public group !: Group;
    
}

export class GroupSettingsLight {
    public locationInformation!: LocationInformation | null;
    public content!: Content | null;
    public logo!: GroupSettingsLogo | null;
    public feedbacks!: Feedback | null;
    public incidentReports!: IncidentReports | null;
    public payPerServiceControl!: PayPerServicesControl | null;
    public cleaningOperations !: CleaningOperations;
    public users !: User
    public sensorControl !: SensorControl | null;

}