import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const assignUserServiceWorkerToGroup = async (groupId: string, userServiceWorkerId: string, token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();

    await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/service-worker/${userServiceWorkerId}/assign`,null,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar(`Servicekraft wurde zur Einheit erfolgreich zugewiesen`, { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};

export const assignUserServiceWorkersToGroup = async (groupId: string, userServiceWorkerId: string[], token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();

    await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/service-workers/assign`,{stringArray: userServiceWorkerId},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar(`Servicekräfte wurden zur Einheit erfolgreich zugewiesen`, { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};