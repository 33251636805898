import axios from 'axios';
import { headers, showDefaultError } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { DeviceType } from '../../models/bssd/SsdDeviceModel';

export const createLoraDevice = async (deviceId: string,token: string, enqueueSnackbar: any): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/lora-devices/${deviceId}`,
            { },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Device erfolgreich reserviert", { variant: 'success' });
        return ret.data;
    } catch (err:any) {
        showDefaultError(err, enqueueSnackbar);
        enqueueSnackbar("Device konnte nicht registirert werden", { variant: 'error' });
    }
    return null as any;
}