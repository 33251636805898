import axios from 'axios';
import { headers } from '../api';
import { USER_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupSettings } from '../models/GroupSettings';
import { UserAccount } from '../models/UserAccount';


export const updateUser = async (user: UserAccount,token: string,enqueueSnackbar:any) : Promise<UserAccount> => {
    try {
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/users/${user.id}`,
        {...user},
        {headers: {...headers,'Authorization': `Bearer ${token}` }})
        enqueueSnackbar("User Speichern erfolgreich",{variant:'success'});
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err,null,2));
        enqueueSnackbar("User Speichern error",{variant:'error'});
    }
    return null as any;
}

export const updateUserFromAdmin = async (user: UserAccount,token: string,enqueueSnackbar:any) : Promise<UserAccount> => {
    try {
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/users/${user.id}/from-admin`,
        {...user},
        {headers: {...headers,'Authorization': `Bearer ${token}` }})
        enqueueSnackbar("User Speichern erfolgreich",{variant:'success'});
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err,null,2));
        enqueueSnackbar("User Speichern error",{variant:'error'});
    }
    return null as any;
}

export const updateUserFromOwner = async (user: UserAccount,token: string,enqueueSnackbar:any) : Promise<UserAccount> => {
    try {
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/users/${user.id}/from-owner`,
        {...user},
        {headers: {...headers,'Authorization': `Bearer ${token}` }})
        enqueueSnackbar("User Speichern erfolgreich",{variant:'success'});
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err,null,2));
        enqueueSnackbar("User Speichern error",{variant:'error'});
    }
    return null as any;
}