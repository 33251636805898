import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const DeleteObjectSharedFolder = async (objectId: string, sharedObjectFolderId: string, token: string, enqueueSnackbar: any) => {

    if (!objectId || !token || !enqueueSnackbar)
        return;

    const userId = await getUserIdFromStorage();
    
    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/users/${userId}/shared-folders/${sharedObjectFolderId}/clear`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((response) => {

        enqueueSnackbar("Geteilter Ordner wurde erfolgreich gelöscht", { variant: 'success' });
    }).catch((err:any) => {
        if (err.response && err.response.data && err.response.data.message) {
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }
        enqueueSnackbar("Es ist ein Fehler bei der Löschung des Geteilten Ordner aufgetreten", { variant: 'error' });
    });
};
