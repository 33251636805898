import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle, Button, CircularProgress, DialogActions, DialogContent, Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, AddBox, Close, Delete, ManageAccounts, RowingSharp, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { SsdUnregisteredDevicesTable } from './SsdUnregisteredDevicesTable';
import { SsdDeviceModel } from '../../../../models/bssd/SsdDeviceModel';
import { useTranslation } from 'react-i18next';

export const SsdUnregisteredDevicesDialog = ({
  open,
  setOpen,
  onFinish,
  devices
}: {
  open: boolean,
  setOpen: any,
  onFinish: any,
  devices: SsdDeviceModel[]
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [token, setToken] = React.useState("");
  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

    })();

  }, [open]);

  const disabled = selectedGroups.length < 1;
  const [selection, setSelection] = React.useState<any[]>([]);
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Verfügbare Geräte`}
        handleClose={handleClose}
      />

      <DialogContent>
        <Grid container direction={'row'} alignItems="center" justifyContent={"center"} spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={4}>
            <Alert severity="warning">
              <AlertTitle>Achtung Batterie Displays in Betrieb nehmen</AlertTitle>
              Damit der Hub ein Batterie Display erkennen kann, muss das Baterie Display in reichweite laufen
            </Alert>
          </Grid>
        </Grid>
        <SsdUnregisteredDevicesTable
          rows={devices}
          selection={selection}
          setSelection={setSelection}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          disabled={loading}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.close")}
        </Button>
        <Button
          size="large"
          startIcon={<AddBox />}

          disabled={selection.length < 1 || loading}
          endIcon={loading && <CircularProgress size={32} />}
          variant={'contained'}
          color="primary"
          onClick={async () => {
            setLoading(true);
            for (var index of selection) {
              let device = devices[index];
              if (device) api.createRegisteredBssdDevice(device.hubId, device.id, device.deviceType, token, enqueueSnackbar);
            }
            await onFinish();
            setLoading(false);
            handleClose();
          }}
        >
          Gerät Reservieren!
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));