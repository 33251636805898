import axios from 'axios';
import { handleApiError, headers } from '../api';
import { GROUP_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { Group } from '../models/Group';

export const getGroup = async (userToken: string): Promise<Group> => {
    try {
        let userId = getUserIdFromStorage();
        let groupId = localStorage.getItem(GROUP_ID);
        if (!groupId){
            return null as any;
        }

        const ret = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}`,
            { headers: { ...headers, 'Authorization': `Bearer ${userToken}` } });

        return ret.data;

    } catch (err) {
        handleApiError(err);

    }
    return null as any;
}
/*
export const getGroupById = async (userToken: string, groupId: string): Promise<Group> => {
    try {

        const ret = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}`,
            { headers: { ...headers, 'Authorization': `Bearer ${userToken}` } });

        return ret.data;

    } catch (err) {
        handleApiError(err);

    }
    return null as any;
}*/