import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserAddress } from '../models/UserAddress';
import { UserServiceWorkerObjectSession } from '../models/UserServiceWorkerObjectSession';

export const createServiceWorkerObjectSession = async (objectUnit: UserServiceWorkerObjectSession, token: string, enqueueSnackbar: any): Promise<UserServiceWorkerObjectSession> => {
    try {
        if (!objectUnit) throw (null);
        let userId = getUserIdFromStorage();
        objectUnit.userId = userId || "";
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/create-service-worker-object-session`,
            { ...objectUnit },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Objekt Anwesenheit Speichern erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Objekt Anwesenheit Speichern error", { variant: 'error' });
    }
    return null as any;
}