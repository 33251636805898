import axios from 'axios';
import { saveAs } from 'file-saver';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { FileBlobType } from '../models/FileBlobType';
import prettyBytes from 'pretty-bytes';


export const DownloadUserFile = async (id: string, fileBlobType: FileBlobType, token: string, enqueueSnackbar: any, filename: string, contentType: string, preview = false, userId?: string | null): Promise<any> => {

    if (!enqueueSnackbar)
        return "";
    if (!userId) userId = getUserIdFromStorage();

    try {
        let ret: any

        if (token) {
            ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/ids/${id}/type/${fileBlobType}/images/${filename}`,
                {
                    responseType: 'arraybuffer',
                    headers: { ...headers, 'Authorization': `Bearer ${token}` }
                });
        } else {
            ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/ids/${id}/type/${fileBlobType}/images/${filename}`,
                {
                    responseType: 'arraybuffer',
                    headers: { ...headers }
                });
        }

        const blob = new Blob([ret.data], { type: contentType });
        const url = URL.createObjectURL(blob);
//        enqueueSnackbar(`Datei Gedownloadet ${prettyBytes(blob.size)}`, { variant: 'success' });

        if (!preview) {
            saveAs(blob, filename);
        }
        return { url: url, type: blob.type };
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
        enqueueSnackbar("Es ist ein Fehler beim downloaden der User Datei aufgetreten", { variant: 'error' });
        return {url : "", };
    }



};
