export class CopyObjectUnitOption {
    public objectSettings !: boolean;
    public intervalControl !: boolean;
    public userServiceWorkers !: boolean;
    public notifications !: boolean;
    public materialStock !: boolean;
    public qmSettings!: boolean;
    public qrCodeInformation !: boolean;
    public assignProperty !: boolean;
    public isEnabledObject !: boolean;

    constructor () {
        this.qmSettings = false;
        this.qrCodeInformation = false;
        this.isEnabledObject = false;
    }
}