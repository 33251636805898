import React, { useCallback } from 'react';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { BuyerType, GroupLicense, GroupLicensePatch, PurchaseReasonType } from '../../models/GroupLicense';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { LicenseType } from '../../models/LicenseType';
import { LicenseHelper } from "../../helpers/LicenseHelper";
import { convertTimespanToDuration, formatEuropeanDate, formatEuropeanDateTime } from '../../helpers/date-management';
import { StringHelper } from '../../helpers/string-helper';
import { Button, ButtonGroup, IconButton } from '@mui/material';
import { Close, DeleteForever, Edit } from '@mui/icons-material';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { api } from '../../api';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { UserAccount } from '../../models/UserAccount';
import { UserType } from "../../models/UserType";
import { Template } from 'devextreme-react';
import { TemplatePlaceholder } from '@devexpress/dx-react-core';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { saveExcelFile } from '../../helpers/tables/common';
import { GroupLicenseEditingDialog } from './edit/GroupLicenseEditingDialog';
import { red } from '@mui/material/colors';
import { white } from '../../styles/colors';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { useTranslation } from 'react-i18next';

export const AllLicensesAdminTable = ({ licenses, setLicenses, onComplete, users, token, groups, selectedRows, setSelectedRows }
    : { licenses: GroupLicense[]; setLicenses: any; onComplete: any, users: UserAccount[], token: string | undefined; groups: GroupLicenseTable[], selectedRows: GroupLicense[], setSelectedRows: any }) => {
    const exporterRef: any = React.useRef(null);

    const {t} = useTranslation();

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const onSave = (workbook: any) => {

        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Check-Me-Now-Lizenzen${formatEuropeanDateTime(new Date())}`);
        });
    };

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [pageSize, setPageSize] = React.useState(25);
    const [currentPage, setCurrentPage] = React.useState(0);

    const [selection, setSelection] = React.useState<GroupLicense | undefined>(undefined);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [hiddenColumnNames, setHiddenColumnNames] = React.useState<string[]>([]);
    const [filters, setFilters] = React.useState([]);
    const [editLicense, setEditLicense] = React.useState<GroupLicense>();
    const [isEditLicenseDialogVisible, setIsEditLicenseDialogVisible] = React.useState(false);
    let [columns] = React.useState(
        [
            {
                name: keys.userId,
                title: 'Besitzer',
                getCellValue: (row: GroupLicense) => {
                    let user = users.find(x => x.id == row.userId);
                    return `${user?.firstname} ${user?.lastname}`
                }
            },
            {
                name: keys.company,
                title: 'Firma',
                getCellValue: (row: GroupLicense) => {
                    let user = users.find(x => x.id == row.userId);
                    return `${user?.company}`
                }
            },
            {
                name: keys.purchaseTimestamp,
                title: 'Kaufdatum',
                getCellValue: (row: GroupLicense) => formatEuropeanDate(row.purchaseTimestamp),
            },
            {
                name: keys.duration,
                title: 'Dauer',
                getCellValue: (row: GroupLicense) => `${convertTimespanToDuration(row.duration).days} Tage`,
            },
            {
                name: keys.type,
                title: 'Typ',
                getCellValue: (row: GroupLicense) => LicenseHelper.toLicenseTypeString(row?.type || LicenseType.Device)
            },
            {
                name: keys.buyerType,
                title: 'Gekauft von',
                getCellValue: (row: GroupLicense) => LicenseHelper.toBuyerTypeString(row?.buyerType || BuyerType.Normal)
            },
            {
                name: keys.buyerUserId,
                title: 'Name des Käufers',
                getCellValue: (row: GroupLicense) => {
                    let user = users.find(x => x.id == row.buyerUserId);
                    return `${UserAccount.GetLabelByType(user?.userType)} ${user?.firstname || ""} ${user?.lastname || ""}`
                }
            },
            {
                name: keys.purchaseReasonType,
                title: 'Kaufgrund Typ',
                getCellValue: (row: GroupLicense) => LicenseHelper.toPurchaseReasonTypeString(row?.purchaseReasonType)
            },
            {
                name: keys.checkpointCount,
                title: 'Verfügbare Checkpoints',
                getCellValue: (row: GroupLicense) => `${row.checkpointCount}`,
            },
            {
                name: keys.qualityCheckCount,
                title: 'Anzahl Qualitätssicherung',
                getCellValue: (row: GroupLicense) => `${row.qualityCheckCount}`,
            },
            {
                name: keys.publicFeedbackCount,
                title: 'Anzahl Öffentliches Feedback',
                getCellValue: (row: GroupLicense) => `${row.publicFeedbackCount}`,
            },
            {
                name: keys.payPerServiceCount,
                title: 'Anzahl Pay Per Service',
                getCellValue: (row: GroupLicense) => `${row.payPerServiceCount}`,
            },
            {
                name: keys.calendarProCount,
                title: 'Anzahl Kalender Pro',
                getCellValue: (row: GroupLicense) => `${row.calendarProCount}`,
            },
            {
                name: keys.loraSensorCount,
                title: 'Lora Sensor',
                getCellValue: (row: GroupLicense) => row?.loraSensorCount
            },
            {
                name: keys.sensorCount,
                title: 'Touch Dispay Sensor',
                getCellValue: (row: GroupLicense) => row?.sensorCount
            },
            {
                name: keys.batterySmartServiceDisplaySensorCount,
                title: 'Anzahl Batterie Sensor',
                getCellValue: (row: GroupLicense) => row?.batterySmartServiceDisplaySensorCount
            },
            {
                name: keys.assigned,
                title: t("myObjectSettings.assigned"),
                getCellValue: (row: GroupLicense) => StringHelper.IsNullOrWhiteSpace(row.usedByGroup) ? 'Frei' : 'Zugewiesen',
            }
        ]
    )

    if (groups.length > 0) {
        columns = [{
            name: keys.group,
            title: 'Zugewiesen auf Einheit',
            getCellValue: (row: GroupLicense) => {
                let group = groups.find(x => x.id == row.usedByGroup);
                if (group) {
                    return `${group.notificationName} ${group.levelName}`

                } else {
                    return row.usedByGroup ? `Einheit existiert nicht` : "Nicht zugewiesen"
                }
            }
        }, ...columns];

    }

    columns = [{
        name: keys.edit,
        title: 'Bearbeiten',
        getCellValue: (row: GroupLicense) => {
            return row.id
        }
    }, ...columns];

    const ButtonCell = (props: any) => {
        const { style, row, ...restProps } = props;
        return (
            <Table.Cell
                {...restProps}
                style={{
                    ...style
                }}>
                <ButtonGroup
                    color="primary"
                    variant="contained"
                >

                    <Button
                        startIcon={<Close />}
                        onClick={() => {
                            setSelection(row);
                            setDeleteConfirmationDialogVisible(true);
                        }}
                    >
                        Löschen
                    </Button>

                </ButtonGroup>
            </Table.Cell>
        )
    };

    const ButtonEditCell = (props: any) => {
        const { style, row, ...restProps } = props;
        return (
            <Table.Cell
                {...restProps}
                style={{
                    ...style
                }}>
                <IconButton
                    color='primary'
                    onClick={() => {
                        setEditLicense(row);
                        setIsEditLicenseDialogVisible(true);
                    }}
                >
                    <Edit />
                </IconButton>

                <IconButton
                    color='error'
                    onClick={() => {
                        setSelection(row);
                        setDeleteConfirmationDialogVisible(true);
                    }}
                >
                    <DeleteForever />
                </IconButton>

            </Table.Cell>
        )
    };

    const Cell = (props: any) => {
        const { column, row } = props;
        if (column.name === keys.actions) {
            return <ButtonCell {...props} />;
        }
        if (column.name === keys.edit) {
            return <ButtonEditCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    return (
        <>
            <Grid
                rows={licenses}
                columns={columns}
                getRowId={(x) => x.id}
            >

                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters as any}
                />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selectedRows.map(x => x.id)} onSelectionChange={(ids) => {
                    setSelectedRows(licenses.filter(x => ids.includes(x.id)));
                }} />
                <IntegratedSelection />
                <IntegratedPaging />

                <Table cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={Object.keys(keys).map((x: any) => ({ columnName: x, width: 170 }))} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >
                    <TemplatePlaceholder />
                </Template>
                <ExportPanel startExport={startExport} />
                <ColumnChooser />

                <TableFilterRow />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 15, 20, 25, 0]} />

                {editLicense &&
                    <GroupLicenseEditingDialog
                        users={users}
                        onSave={async (currentLicense: GroupLicense) => {
                            let patchObject = new GroupLicensePatch(currentLicense.id, currentLicense.userId, currentLicense.buyerType, currentLicense.purchaseReasonType, currentLicense.buyerUserId);
                            let res = await api.patchGroupLicenseAsync(patchObject, token || "", enqueueSnackbar);
                            setLicenses(licenses.map(x => {
                                if (x.id == res.id) {
                                    x.buyerType = res.buyerType;
                                    x.buyerUserId = res.buyerUserId;
                                    x.purchaseReasonType = res.purchaseReasonType;
                                };
                                return x;
                            }));
                        }} onRefresh={() => {

                        }} visible={isEditLicenseDialogVisible} setVisible={setIsEditLicenseDialogVisible} currentGroupLicense={editLicense} />
                }

                <ConfirmationDialog
                    visible={deleteConfirmationDialogVisible}
                    title="Sind Sie sicher, diese Lizenz zu löschen ?"
                    onClose={() => setDeleteConfirmationDialogVisible(false)}
                    onConfirmed={async () => {
                        if (!selection) return;

                        setLicenses(licenses.filter(x => x.id !== selection.id))
                        setDeleteConfirmationDialogVisible(false);

                        const token = getApiToken(history);
                        await api.deleteLicenseAsync(selection.id, selection.userId, token, enqueueSnackbar);
                        setSelection(undefined);
                        await onComplete();
                    }}
                />
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={licenses}
                columns={columns}
                filters={filters}
                onSave={onSave}
            />
        </>
    );
};

const keys = {
    purchaseTimestamp: 'purchaseTimestamp',
    purchaseTimestampUnixTimeSeconds: 'purchaseTimestampUnixTimeSeconds',
    duration: 'duration',
    type: 'type',
    assigned: 'assigned',
    sensorCount: 'sensorCount',
    loraSensorCount: 'loraSensorCount',
    checkpointCount: 'checkpointCount',
    qualityCheckCount: 'qualityCheckCount',
    publicFeedbackCount : 'publicFeedbackCount',
    payPerServiceCount : 'payPerServiceCount',
    calendarProCount : 'calendarProCount',
    batterySmartServiceDisplaySensorCount: 'batterySmartServiceDisplaySensorCount',
    actions: 'actions',
    userId: 'userId',
    company: 'company',
    buyerType: 'buyerType',
    buyerUserId: 'buyerUserId',
    purchaseReasonType: 'purchaseReasonType',
    group: 'group',
    edit: 'edit'
}