import makeStyles from '@mui/styles/makeStyles';


export const errorColor = '#cf2323';
export const white = '#FFFFFF'
export const disabledColor = '#A6A6A6';
export const lightgrey = '#f4f4f4'
export const grey = 'lightgrey'
export const red = "#FF0000";
export const green = "#0EAD1C";
export const yellow = "#D4D008";
export const lightRed = '#FF8B28';
export const lightGreen = '#2efd97';
export const lightBlue = '#c7e5ff';
export const drawerLightBlue = '#c7e5ff';
export const useColorStyles = makeStyles((theme) => ({
    headerTable: {
        backgroundColor: lightgrey
    }
}));