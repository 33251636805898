import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { DEFAULT_DAYS_OF_WEEK_ENABLED } from '../models/defaults';
import { GroupSettings } from '../models/GroupSettings';
import { UserLogo } from '../models/UserLogo';

export const getLogosOfGroup = async (userToken: string): Promise<UserLogo[]> => {
    try {
        let userId = getUserIdFromStorage();

        const ret = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/images/logos`,
            { headers: { ...headers, 'Authorization': `Bearer ${userToken}` } });
        return ret.data;

    } catch (err) {
        handleApiError(err);
    }
    return null as any;
}