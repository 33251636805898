import { CSSProperties } from 'react';
import { IconButton, Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const IconButtonWithLabel = ({ icon, label, labelStyle, onClick }: { icon: any, label: string, labelStyle?: CSSProperties, onClick: any }) => {

  const classes = useStyles();

  return (
    <IconButton className={classes.root} onClick={onClick} size="large">
      {icon}
      <div className={classes.label} style={labelStyle}>{label}</div>
    </IconButton>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 4,
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    '&:hover div': {
      opacity: 1,
    },
  },
  label: {
    marginTop: 8,
  }
}));