import { Series, Label, Format, Legend, Export, ValueAxis, ZoomAndPan, Size } from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { chartHeight, customizeLabel, customizeLabelWithPercent, mapLabels, NoDataChartLoader } from './Base';
import { PieChart } from 'devextreme-react';
import { Connector, Tooltip } from 'devextreme-react/pie-chart';
import React from 'react';
import { FullScreenChartDialog } from './dialog/FullScreenChartDialog';
import { Grid as MuiGrid } from '@mui/material';
import { ChartHeightField, FullscreenButton } from './StackedChart';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});

export const PieChartDraw = (props: any) => {
    const { data, text, name, loadingCompleted } = props;
    const [isFullScreenChartVisible, setIsFullscreenChartVisible] = React.useState(false);
    
    const [chartHeightNow, setChartHeightNow] = React.useState(chartHeight);
    if (data.length === 0)
        return <NoDataChartLoader title={text} data={data} loadingCompleted={loadingCompleted} />;


    function customizeTooltip(pointInfo: any) {
        let htmlContent = `<span class='top-series-name'>Gesamt:</span>: </div><div class="value-text"><span class='top-series-value'>${pointInfo.valueText}</span></div>`
        htmlContent += `<div class="series-name"><span class='bottom-series-name'>Prozent:</span>: </div><div class="value-text"><span class='bottom-series-value'>${pointInfo.percentText}</span></div>`;
        return {
            html: `<div><div class="tooltip-header">${pointInfo.argumentText}</div><div class="tooltip-body"><div class="series-name">
                ${htmlContent}
                </div>`,
        };
    }

    const buildChart = () => {
        return (
            <>
                <PieChart
                    ref={props.childRefChart}
                    id="pie"
                    type="doughnut"
                    title={text}
                    dataSource={data}
                    centerRender={CenterTemplate}
                    palette={props.colors ? props.colors : "Material"}
                >
                    <Size
                        height={chartHeightNow}
                        width={'100%'}
                    />
                    <Series
                        valueField={"value"}
                        argumentField="key"
                    >
                        <Label visible={true}
                            customizeText={customizeLabelWithPercent}>

                            <Connector visible={true} />
                        </Label>
                    </Series>
                    <ZoomAndPan
                        valueAxis="both"
                        argumentAxis="both"
                        dragToZoom={true}
                        allowMouseWheel={true}
                        panKey="shift" />

                    <ValueAxis allowDecimals={false} />
                    <Legend
                        visible={false}
                        horizontalAlignment="center"
                        verticalAlignment="bottom"
                    />
                    <Tooltip
                        enabled={true}
                        customizeTooltip={customizeTooltip}
                    />
                    <Export enabled={true} />

                </PieChart>
                <MuiGrid container direction="row" spacing={3} alignItems="center">
                    <MuiGrid item>
                        <ChartHeightField chartHeight={chartHeightNow} setChartHeight={setChartHeightNow} />
                    </MuiGrid>
                    <MuiGrid item>
                        <FullscreenButton isFullScreenVisible={isFullScreenChartVisible} setIsFullscreenChartVisible={setIsFullscreenChartVisible} setChartHeight={setChartHeightNow} />
                    </MuiGrid>
                </MuiGrid>
            </>

        );
    }


    return isFullScreenChartVisible ? <FullScreenChartDialog setChartHeight={setChartHeightNow} visible={isFullScreenChartVisible} title={text} onClose={() => { setIsFullscreenChartVisible(false) }} buildChart={buildChart} /> : buildChart();


}


export const CenterTemplate = (pieChart: any) => {
    const country = pieChart.getAllSeries()[0].getVisiblePoints();
    return (
        <svg>
            <circle cx="100" cy="100" r={pieChart.getInnerRadius() - 6} fill="#eee"></circle>
            <text textAnchor="middle" x="100" y="120" style={{ fontSize: 18, fill: '#494949' }}>
                <tspan x="100">{"Gesamt:"}</tspan>
                <tspan x="100" dy="20px" style={{ fontWeight: 600 }}>{
                    calculateTotal(pieChart)
                }</tspan>
            </text>
        </svg>
    );
}

export const calculateTotal = (pieChart: any) => {
    return pieChart.getAllSeries()[0].getVisiblePoints().reduce((s: any, p: any) => s + p.originalValue, 0);
}

export const PieChartComponent = withStyles(demoStyles, { name: 'Demo' })(PieChartDraw);