import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from '../helpers/common';
import { LicenseType } from '../models/LicenseType';

export const AddLicenseAsAdminAsync = async (type: LicenseType, years: number, password: string, token: string, enqueueSnackbar: any) => {

    const userId = getUserIdFromStorage();

    await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/licenses`,
        { type: type, year: years, password: password },
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Lizenz wurde erfolgreich hinzugefügt", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei dem Hinzufügen ist ein Fehler aufgetreten", { variant: 'error' });
    });
};
