import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';
import { UserAddress } from '../models/UserAddress';
import { UserProperty } from '../models/UserProperty';
import { BackendErrorCodes } from '../models/ErrorCodes';

export const createUserServiceWorker = async (property: UserServiceWorker | undefined, token: string, enqueueSnackbar: any): Promise<UserServiceWorker> => {
    try {
        if (!property) throw (null);
        let userId = getUserIdFromStorage();
        property.userId = userId || "";
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/create-service-worker`,
            { ...property },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Servicekraft Speichern erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err: any) {
        if (err.response.data.code == BackendErrorCodes.RecordAlreadyExists){
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }else{
            enqueueSnackbar("Servicekraft Speichern error", { variant: 'error' });
        }
    }
    return null as any;
}