import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';
import { UserAddress } from '../models/UserAddress';

export const getServiceWorkers = async (token: string): Promise<UserServiceWorker[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/service-workers`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return [] as any;

}

export const getServiceWorkersByIds = async (token: string, groupIds : string[]): Promise<UserServiceWorker[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/service-workers-by-ids`, {stringArray : groupIds},{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}