import { useCallback, useRef, useState } from 'react';
import {
    ColumnChooser,
    ExportPanel,
    Grid,
    PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Checkbox, FormControlLabel, TableCell, Typography } from '@mui/material';
import { GroupServiceModeTable } from '../../models/GroupServiceMode';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { GroupSettings } from '../../models/GroupSettings';
import { saveExcelFile } from '../../helpers/tables/common';
import { GroupServiceModeCountTable } from '../../models/GroupServiceModeCountTable';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { Group, GroupType } from '../../models/Group';
import { mapToiletTypeKey } from './Base';
import { buildCellStringArray } from '../common/AccordionForStringArray';
import { useTranslation } from 'react-i18next';


const CheckboxCell = (props: any) => {
    const { value, style, ...restProps } = props;
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        name="belegt"
                        color="primary"
                    />
                }
                label=""
            />
        </Table.Cell>
    )
};



const FilterCellCheck = ({ filter, onFilter, classes }: { filter: any, onFilter: any, classes: any }) => (
    <TableCell>
        <FormControlLabel
            control={
                <Checkbox
                    onChange={e => {
                        onFilter({ value: e.target.checked });
                    }}
                    color="primary"
                />
            }
            label=""
        />
    </TableCell>
);

const FilterCell = (props: any) => {
    const { column } = props;
    if (column.name === 'isOnTime' || column.name === 'isCompleted' || column.name === "isPartlyCompleted" || column.name === "isCanceled" ||
        column.name === "finishedMotionTrigger" || column.name === "finishedFeedbackTrigger" || column.name === "finishedIntervalTrigger" || column.name === "isInProgress") {
        return <FilterCellCheck {...props} />;
    }
    return <TableFilterRow.Cell {...props} />;
};

const onSave = (workbook: any) => {


    //unfreeze panes (scrolling)
    workbook._worksheets[1].views[0].state = "split";

    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Check-Me-Now-Services`);
    });
};

export const ServiceModeCountTable = ({ groupServicesMode, groupLicenseTable, allMaterials }: { groupServicesMode: GroupServiceModeCountTable[], groupLicenseTable?: GroupLicenseTable[], allMaterials: string[] }) => {
    const exporterRef: any = useRef(null);
    const {t} = useTranslation();
    const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let materalColumnsName: any = [];
    let materialColumnsWidth: any = [];
    if (groupServicesMode.length > 0) {
        materalColumnsName = allMaterials.map((col) => {
            return {
                name: col,
                title: col,
                getCellValue: (row: GroupServiceModeCountTable) => {
                    return row.materials.find(x => x.text == col)?.amount || 0;
                },
            }
        });
        materialColumnsWidth = allMaterials.map((col) => {
            return {
                columnName: col,
                width: 150
            }
        });
    }


    const [columns] = useState([
        { name: GroupServiceModeCountTable.Columns.groupId, title: t("serviceDocumentationTable.unitName"), getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.notificationName },
        {
            name: GroupServiceModeCountTable.Columns.groupType,
            title: t("myUnitsSettings.unitType"),
            getCellValue: (row: GroupServiceModeCountTable) => Group.GetLabelByType(groupLicenseTable?.find(x => x.id == row.groupId)?.groupType),
        },
        {
            name: GroupServiceModeCountTable.Columns.objectName,
            title: t("myUnitsSettings.objectName"),
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.objectName,
        },
        {
            name: GroupServiceModeCountTable.Columns.levelName,
            title: t("myUnitsSettings.level"),
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.levelName,
        },
        {
            name: GroupServiceModeCountTable.Columns.roomName,
            title: t("myUnitsSettings.customer"),
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.roomName,
        },
        {
            name: GroupServiceModeCountTable.Columns.toiletType,
            title: t("myUnitsSettings.roomType"),
            getCellValue: (row: GroupServiceModeCountTable) => mapToiletTypeKey(groupLicenseTable?.find(x => x.id == row.groupId)?.toiletType),
        },
        {
            name: GroupServiceModeCountTable.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.userPropertyName,
        },
        {
            name: GroupServiceModeCountTable.Columns.materials,
            title: 'Material',
            getCellValue: (row: GroupServiceModeCountTable) => row.materials?.reduce((partialSum, a) => partialSum + (a.amount as number), 0) || 0,
        },
        ...materalColumnsName,
    ]);




    const [defaultColumnWidths] = useState([
        { columnName: GroupServiceModeCountTable.Columns.groupId, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.groupType, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.levelName, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.objectName, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.roomName, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.toiletType, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.userPropertyName, width: 180 },
        { columnName: GroupServiceModeCountTable.Columns.materials, width: 250 },
        ...materialColumnsWidth,

    ]);




    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupServiceModeCountTable } = props;
        if (column.name === 'isOnTime' || column.name === 'isCompleted' || column.name === "isPartlyCompleted" || column.name === "isCanceled" ||
            column.name === "finishedMotionTrigger" || column.name === "finishedFeedbackTrigger" || column.name === "finishedIntervalTrigger" || column.name === "isInProgress") {
            return <CheckboxCell {...props} />;
        }
        if (column.name === GroupServiceModeCountTable.Columns.materials) {
            let materials = row?.materials?.filter(x=>(x.amount as number) > 0);
            return buildCellStringArray(props, materials.map(x => `${x.text} : ${x.amount}`) || [], `${t("myObjectSettings.contains")} ${materials?.reduce((partialSum, a) => partialSum + (a.amount as number), 0) || 0} Material`);
        }
        return <Table.Cell {...props} />;
    };

    const [filters, setFilters] = useState([]);
    return (
        <>
            <Grid
                rows={groupServicesMode}
                columns={columns}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters as any}
                />
                <IntegratedFiltering />
                <PagingState
                    defaultCurrentPage={0}
                    pageSize={10}
                />
                <SortingState
                //defaultSorting={[{ columnName: 'startTimestamp', direction: 'desc' }]}
                />
                <IntegratedSorting />

                <IntegratedPaging />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >

                    <TemplatePlaceholder />
                </Template>
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow
                    cellComponent={FilterCell}
                />
                <PagingPanel />
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={groupServicesMode}
                columns={columns}
                filters={filters}
                onSave={onSave}
            />
        </>
    );
};