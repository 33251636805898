import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useHistory } from "react-router-dom";
import { api } from "../../api";
import { formatUnixSecTimestampEuropeanDateInMinutes, isOlderThanFifteenMinutes } from "../../helpers/date-management";
import { getApiToken } from "../../helpers/storage-management";
import { ReactComponent as AntennaSvg } from '../../assets/svgs/antenna.svg'
import { LoadingIcon } from './LoadingIcon';

export const LastOnlineGroup = ({ groupId, className }: { groupId: string, className: string }) => {

    const [date, setDate] = React.useState<string>('');
    const [color, setColor] = React.useState<string>('');
    const [loading, setLoading] = React.useState(false);
    const [lastRefreshTime, setLastRefreshTime] = React.useState(new Date());

    const history = useHistory();

    const loadLastOnline = async() => {
        if (!groupId) {
            setDate('');
            return;
        }

        setLoading(true);
        const token = getApiToken(history);
        const group = await api.getGroup(token);
        setLoading(false);

        if (!group || group.lastOnline === 0) {
            setDate('');
            return;
        }

        setColor(isOlderThanFifteenMinutes(group.lastOnline) ? "#eb4034" : "#2ee656");
        setDate(formatUnixSecTimestampEuropeanDateInMinutes(group.lastOnline));
    }

    React.useEffect(() => {

        let timer: NodeJS.Timeout;

        (async () => {



            loadLastOnline();
            timer = setTimeout(() => {
                setLastRefreshTime(new Date());
            }, process.env.REACT_APP_STATUS_REFRESH_INTERVAL * 1000);


        })();

        return () => {
            clearTimeout(timer);
        }
    }, [groupId, lastRefreshTime,history]);

    if (!date) return null;

    return (
        <ListItem className={className}>
            <ListItemIcon>
                <LoadingIcon
                    icon={<AntennaSvg fill={color} />}
                    loading={loading}
                />
            </ListItemIcon>
            <ListItemText
                primary="Letzter Settings Check"
                secondary={date}
            />
        </ListItem>
    );
}

