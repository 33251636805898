import React from 'react';
import { Button, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Product } from '../../models/Product';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../helpers/storage-management';
import { Add } from '@mui/icons-material';
import { ProductManagementPageEditingDialog } from './ProductManagementPageEditingDialog';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { api } from '../../api';
import { useSnackbar } from 'notistack';
import { ProductManagementPageDataTable } from './ProductManagementPageDataTable';

export const ProductManagementPage = () => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [products, setProducts] = React.useState<Product[]>([]);
    const [addSettingDialogVisible, setAddSettingDialogVisible] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState<Product>();
    const [editSettingDialogVisible, setEditSettingDialogVisible] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);

    React.useEffect(() => {

        (async () => {

            const tempToken = getApiToken(history);
            if (!tempToken) return;

            setToken(tempToken);

            const tempProducts = await api.listProductsAsync(tempToken, enqueueSnackbar);
            setProducts(tempProducts);

        })();

    }, [history])

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Produkte
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                   //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                        onClick={async () => {
                            setAddSettingDialogVisible(true);
                        }}
                    >
                        Produkt hinzufügen
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <ProductManagementPageDataTable
                        rows={products}
                        onEdit={async (row: Product) => {
                            setSelectedProduct(row);
                            setEditSettingDialogVisible(true);
                        }}
                        onDelete={async (row: Product) => {
                            setSelectedProduct(row);
                            setDeleteConfirmationDialogVisible(true);
                        }}
                    />
                </Paper>
            </Grid>

            <ProductManagementPageEditingDialog
                visible={addSettingDialogVisible}
                setVisible={setAddSettingDialogVisible}
                products={products}
                onSave={async (product: Product) => {

                    if (products.some(p => p.type === product.type)) return;

                    const added = await api.addProductAsync(product, token, enqueueSnackbar);

                    if (!added) return;

                    setProducts([added, ...products]);
                }}
            />

            <ProductManagementPageEditingDialog
                visible={editSettingDialogVisible}
                setVisible={setEditSettingDialogVisible}
                products={products}
                product={selectedProduct}
                onSave={async (product: Product) => {

                    if (!products.some(p => p.type === product.type)) return;

                    const updated = await api.updateProductAsync(product, token, enqueueSnackbar);

                    if (!updated) return;

                    setProducts(p => p.map((item: Product) => item.type === updated.type ? updated : item));
                }}
            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title="Sind Sie sicher, diese Produkt zu löschen ?"
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {
                    if (!selectedProduct) return;

                    setProducts(products.filter(x => x.id !== selectedProduct.id));
                    setDeleteConfirmationDialogVisible(false);

                    await api.deleteProductAsync(selectedProduct, token, enqueueSnackbar);
                }}
            />

        </Grid>
    );
};