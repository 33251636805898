import { DayOfWeek } from "./datetime/DayOfWeek";

export const snackbarDuration = 7000;
export const SENSOR_COUNT_TRIGGER_DEFAULT = "10";
export const MAC_ADDRESS_DEFAULT = "";
export const IS_SWITCH_ON_SENSOR_COUNT_TRIGGER_DEAULT = true;
export const IS_SWITCH_ON_SENSOR_COUNT_TRIGGER_FOR_MULTI_SENSORS = true;
export const CURRENT_DFU_TRIES_DEFAULT = 0;

export const MAX_SENSOR_COUNT = 6;


export const DEFAULT_DAYS_OF_WEEK_ENABLED = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday]