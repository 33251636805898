import makeStyles from '@mui/styles/makeStyles';

export const useGroupSideBarImagesDialogStyles = makeStyles((theme) => ({
    root: {
    },
    dialog: {
    },
    dialogContent: {
        width: '100%',
        minWidth: 390,
        minHeight: 320,
        maxHeight: 750,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
    },
    gridListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        paddingTop: 3,
    },
    gridList: {
        width: '100%',
        height: '100%',
    },
    gridListTile: {
        width: '50%',
        height: '50%',
        padding: 16,
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'scale-down',
        backgroundColor: 'white'
    }
}));