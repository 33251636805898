import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupQmTrigger } from '../models/GroupQmTrigger';


export const GetQmTriggerByUserGroupIds = async (token: String, groupIds: string[]): Promise<Array<GroupQmTrigger>> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/qm-trigger?${getQueryParamFromApiDataAmountType()}`, {StringArray: groupIds}, {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return [] as any;

}