export class GroupMotion {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public sensorId!: string;
    public sensorRoomName!: string;
    public blockCount!: number;
    public motionCount!: number;
    public batchCount !: number;
    public sentBySensorTimestamp!: number;
    public createdTimestamp!: number;
    public motionDeviceType !: MotionDeviceType;

    public isSummarizedMonth !: boolean;
    public summarizedFrom !: string;
    public summarizedUntil !: string;

    public deviceTimestamp !: number;
    public serverTimestamp !: number;
    public messageId !: string;
    public serviceBusDeliveryCount !: number;
    public serviceBusSequenceNumber !: number;
    public serviceBusEnqueuedTime !: string;
    public batteryLevel !: number;


    public constructor (groupId: string, sensorId: string, sensorRoomName: string,blockCount: number, motionCount: number, sentBySensorTimestamp: number, createdTimestamp: number) {
        this.systemETag = null;
        this.groupId = groupId;
        this.sensorId = sensorId;
        this.sensorRoomName = sensorRoomName;
        this.blockCount = blockCount;
        this.motionCount = motionCount;
        this.sentBySensorTimestamp = sentBySensorTimestamp;
        this.createdTimestamp = createdTimestamp;
    }

    static Columns = {
        id: 'id',
        sensorId: "sensorId",
        sensorRoomName: "sensorRoomName",
        notificationName: "notificationName",
        blockCount: "blockCount",
        batchCount: "batchCount",
        motionCount: "motionCount",
        sentBySensorTimestamp: "sentBySensorTimestamp",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        motionDeviceType: 'motionDeviceType',
        systemTimestamp: 'systemTimestamp',
        diffInMinutes: 'diffInMinutes',
        deviceTimestamp : 'deviceTimestamp',
        serverTimestamp: 'serverTimestamp',
        messageId : 'messageId',
        serviceBusDeliveryCount : "ServiceBusDeliveryCount",
        serviceBusSequenceNumber  : "ServiceBusSequenceNumber",
        serviceBusEnqueuedTime : "ServiceBusEnqueuedTime",
        batteryLevel : "batteryLevel"
    }

    static MapLabelTypeMotionDeviceType = ( motionDeviceType: MotionDeviceType) => {
        switch (motionDeviceType){
            case MotionDeviceType.TouchDisplay:
                return "Touch Display";
            case MotionDeviceType.Bssd:
                return "Battery Display"
            default:
                return "Unbekannt";
        }
    }
}

export enum MotionDeviceType
{
    TouchDisplay = 0,
    Bssd = 1,
    None = 255,
} 