import { forwardRef } from 'react'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';


const PhoneInputTextField2 = (props:any, ref:any) => {
  const {t} = useTranslation();

  return (

    <TextField
      {...props}
      fullWidth
      variant={"filled"}
      name="usernames"
      label={t("myUnitsSettings.adminTelegramPhoneNumber")}
      inputRef={ref}
      size='medium'
    />
  )
}
export default forwardRef(PhoneInputTextField2);


const phoneInputTextFieldOriginal = (props:any, ref:any) => {

  return (

    <TextField
      {...props}
      fullWidth
      variant={"filled"}
      name="usernames"
      label="Telefonnummer"
      inputRef={ref}
      size='medium'
    />
  )
}
export const PhoneInputCommon = forwardRef(phoneInputTextFieldOriginal);