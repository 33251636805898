import React from 'react';
import { Button, CircularProgress, Divider, Grid, Paper, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AddBox, Folder, FolderCopy, FolderShared, SwipeOutlined, Visibility } from '@mui/icons-material';
import { getApiToken } from '../../../../helpers/storage-management';
import { api } from '../../../../api';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { DrawerMenuItemDisplayType } from '../../ObjectMenuPointDrawer';
import { OBJECT_COMBINATION } from '../../../../constants';
import { ObjectSharedFolderDataTableSelection } from '../../extendedapp/objectUnit/ObjectSharedFolderDataTable';
import { ObjectSharedFolderTable } from '../../../../models/DataFileTree';
import { DisplayModeObjectFolder, ObjectSharedFolderDialog } from '../../extendedapp/objectUnit/ObjectSharedFolders';
import { AssignObjectToSharedFolderDialog } from '../../extendedapp/objectUnit/AssignObjectToSharedFolderDialog';
import { AddSharedFolderDialog } from '../../extendedapp/objectUnit/AddSharedFolder';
import { ObjectSelectionDataTable } from '../../extendedapp/entireEval/ObjectSelectionDataTable';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { getUserIdFromStorage } from '../../../../helpers/common';
import { ObjectSelectionCopySharedFolderStructureDialog } from './dialog/ObjectSelectionCopySharedFolderStructureDialog';
import { AddFileDialog } from '../../extendedapp/objectUnit/AddFileDialog';
import { useTranslation } from 'react-i18next';

export const ObjectSharedFolderDashboard = ({ displayType = DrawerMenuItemDisplayType.Single }: { displayType?: DrawerMenuItemDisplayType }) => {


    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();


    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const [selectedObjectUnit, setSelectedObjectUnit] = React.useState<ObjectUnit>();
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();


    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [token, setToken] = React.useState("");
    const [selectedRows, setSelectedRows] = React.useState<ObjectSharedFolderTable[]>([]);
    const [rows, setRows] = React.useState<ObjectSharedFolderTable[]>([]);

    const [openSharedFoldersDialog, setOpenSharedFoldersDialog] = React.useState(false);

    const [isAddObjectSharedFolderDialogVisible, setIsAddObjectSharedFolderDialogVisible] = React.useState(false);
    const [isAssignObjectToSharedFolderDialogVisible, setIsAssignObjectToSharedFolderDialogVisible] = React.useState(false);


    const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);
    const [selection, setSelection] = React.useState<string[]>([]);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [displayModeObjectFolder, setDisplayModeObjectFolder] = React.useState(DisplayModeObjectFolder.ObjectFolder);
    const [isAddSharedFileDialogVisible, setIsAddSharedFileDialogVisible] = React.useState(false);
    const [files, setFiles] = React.useState<File[]>([]);


    const [isObjectSelectionCopySharedFolderStructureDialogVisible, setIsObjectSelectionCopySharedFolderStructureDialogVisible] = React.useState(false);



    React.useEffect(() => {
        setSelectedObjects([]);
        setSelectedObjectUnit(undefined);
        setSelectedRows([]);
        loadData();
    }, [history.location.pathname]);

    const loadData = () => {
        (async () => {

            setLoading(true);
            const token = getApiToken(history);
            if (!token) return;
            setToken(token);


            var tempObjects = await api.getUserObjectUnits(token);

            if (displayType == DrawerMenuItemDisplayType.All) {
                const tempSessions = await api.GetObjectSharedFoldersByUserId(token);
                setRows(tempSessions);
                setObjects(tempObjects);

            } else if (displayType == DrawerMenuItemDisplayType.Multiple) {
                const tempCombObject = localStorage.getItem(OBJECT_COMBINATION) || "[]";
                const tempSessions = await api.GetObjectSharedFoldersByUserId(token);
                setRows(tempSessions.filter(x => JSON.parse(tempCombObject).some((y: { id: string }) => x.objectMemberIds.includes(y.id))));
                setObjects(tempObjects.filter(x => JSON.parse(tempCombObject).some((y: { id: string }) => x.id == y.id)));
            }
            else {
                var tempObject = tempObjects.find(x => x.id == selectedObjectId);
                console.log(tempObject, tempObjects, selectedObjectId);
                if (tempObject) {
                    setSelectedObjectUnit(tempObject);
                    setSelectedObjects([tempObject]);
                    setObjects([tempObject]);
                }

                const rows = await api.GetObjectSharedFolderTableByObjectIdAsync(selectedObjectId, token, enqueueSnackbar);
                setRows(rows);
            }

            setLoading(false);

            const userId = getUserIdFromStorage();
            let allGroupsTemp = await api.GetAllGroupsByUserIdAsync(token, userId || "");
            setGroups(allGroupsTemp);

        })();
    }


    return (
        <Paper>
            <Grid container direction={"column"} spacing={3}>
                <Grid item>
                    <Typography variant='h4' fontWeight={'bold'} component={"h4"}>{t("shared-folders-objects-all.viewFolder")}</Typography>
                </Grid>
                <Grid item container direction={"row"} spacing={3}>
                    <Grid item lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            fullWidth
                            startIcon={<Visibility />}
                            endIcon={<FolderCopy />}
                            onClick={async () => {
                                setSelectedRows([...rows]);
                                setSelectedObjects([...objects]);
                                setDisplayModeObjectFolder(DisplayModeObjectFolder.ObjectSharedFolderAndObjectFolder);
                                setOpenSharedFoldersDialog(true);
                            }}
                        >
                            {t("shared-folders-objects-all.viewAllFolders")}
                        </Button>
                    </Grid>
                    <Grid item lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size='large'
                            startIcon={<Visibility />}
                            endIcon={<><FolderShared /><Folder /></>}
                            disabled={selectedRows.length < 1 || selectedObjects.length < 1}
                            onClick={async () => {
                                setDisplayModeObjectFolder(DisplayModeObjectFolder.ObjectSharedFolderAndObjectFolder);
                                setOpenSharedFoldersDialog(true);
                            }}
                        >
                            {selectedRows.length + selectedObjects.length} {t("shared-folders-objects-all.viewSharedAndObjectFolders")}
                        </Button>
                    </Grid>
                    <Grid item lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size='large'
                            startIcon={<Visibility />}
                            endIcon={<Folder />}
                            disabled={selectedObjects.length < 1}
                            onClick={async () => {
                                setDisplayModeObjectFolder(DisplayModeObjectFolder.ObjectFolder);
                                setOpenSharedFoldersDialog(true);
                            }}
                        >
                            {selectedObjects.length} {t("shared-folders-objects-all.viewObjectFolders")}
                        </Button>
                    </Grid>
                    <Grid item lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size='large'
                            startIcon={<Visibility />}
                            disabled={selectedRows.length < 1}
                            endIcon={<FolderShared />}
                            onClick={async () => {
                                setDisplayModeObjectFolder(DisplayModeObjectFolder.ObjectSharedFolder);
                                setOpenSharedFoldersDialog(true);
                            }}
                        >
                            {selectedRows.length} {t("shared-folders-objects-all.viewSharedFolders")}
                        </Button>
                    </Grid>

                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Typography variant='h4' fontWeight={'bold'} component={"h4"}>{t("shared-folders-objects-all.selectSharedFolder")}</Typography>
                </Grid>
                <Grid item container direction={"row"} spacing={3}>
                    <Grid item lg={3}>
                        <Button
                            fullWidth
                            size='large'
                            variant="contained"
                            color="primary"
                            endIcon={<FolderShared />}
                            startIcon={<AddBox />}
                            //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                            onClick={async () => {
                                setIsAddObjectSharedFolderDialogVisible(true);
                            }}
                        >
                            {t("shared-folders-objects-all.createSharedFolder")}
                        </Button>

                    </Grid>
                    <Grid item lg={3}>
                        <Button
                            size='large'
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={selectedRows.length < 1}
                            endIcon={<FolderShared />}
                            startIcon={<SwipeOutlined />}
                            onClick={async () => {
                                setIsAssignObjectToSharedFolderDialogVisible(true);
                            }}
                        >
                            {selectedRows.length} {t("shared-folders-objects-all.assignUnassignSharedFoldersToObjects")}
                        </Button>

                    </Grid>
                    <Grid item lg={3}>
                        <Button
                            size='large'
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={selectedRows.length != 1}
                            endIcon={<FolderCopy />}
                            onClick={async () => {
                                setIsAddSharedFileDialogVisible(true);
                            }}
                        >
                            {t("shared-folders-objects-all.copySharedFolderStructureToObjects")}
                        </Button>

                    </Grid>
                </Grid>

                <Grid item>
                    <Divider />
                </Grid>
                {loading && <Grid item><CircularProgress size={128} /></Grid>}
                {(selectedObjectUnit || displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                    <Grid item>
                        <ObjectSharedFolderDataTableSelection
                            onEdit={(row: ObjectSharedFolderTable) => {
                                setSelectedRows([row]);
                                setDisplayModeObjectFolder(DisplayModeObjectFolder.ObjectSharedFolder);
                                setOpenSharedFoldersDialog(true);
                            }}
                            onRefresh={loadData}
                            rows={rows}
                            selectedRows={selectedRows}
                            onSelectionChange={(selection: string[]) => {
                                setSelectedRows(rows.filter(x => selection.includes(x.id)));
                            }}
                            objects={objects}
                        />
                    </Grid>
                }

                <Grid item>
                    <Divider />
                </Grid>
                {(displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                    <Grid item>
                        <Typography variant='h4' fontWeight={'bold'} component={"h4"}>{t("shared-folders-objects-all.selectObjectFolder")}</Typography>
                    </Grid>
                }
                {(displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                    <Grid item>
                        <ObjectSelectionDataTable rows={objects} groups={groups} selectedObjects={selectedObjects}
                            onSelectionChange={(selection: string[]) => {
                                setSelectedObjects(objects.filter(x => selection.some(y => x.id == y)));
                                setSelection(selection);
                            }}
                            currentGroup={null} />
                    </Grid>
                }

            </Grid>
            <ObjectSharedFolderDialog displayModeObjectFolder={displayModeObjectFolder} setSelectedObjects={setSelectedObjects} selectedObjects={selectedObjects} open={openSharedFoldersDialog} setOpen={setOpenSharedFoldersDialog} objects={objects} sharedFolders={selectedRows} />
            <AssignObjectToSharedFolderDialog onRefresh={loadData} open={isAssignObjectToSharedFolderDialogVisible} setOpen={setIsAssignObjectToSharedFolderDialogVisible}
                objects={objects} groups={[]} selectedObjectSharedFolders={selectedRows} />
            <AddSharedFolderDialog
                setSelectedObjectUnit={setSelectedObjectUnit}
                objects={objects}
                selectedObjectUnit={selectedObjectUnit}
                onFinished={async (name: string) => {
                    if (!selectedObjectUnit) return;

                    var sharedFolder = await api.createObjectSharedFolderAsync(selectedObjectUnit.id, [selectedObjectUnit.id], name, token, enqueueSnackbar);
                    if (sharedFolder){
                        setSelectedRows([sharedFolder]);
                        setIsAssignObjectToSharedFolderDialogVisible(true);
                    }

                }} open={isAddObjectSharedFolderDialogVisible} setOpen={setIsAddObjectSharedFolderDialogVisible} />
            <AddFileDialog onAdd={async (files: File[]) => {
                setFiles(files);
                setIsObjectSelectionCopySharedFolderStructureDialogVisible(true);
            }} open={isAddSharedFileDialogVisible} setOpen={setIsAddSharedFileDialogVisible} />
            <ObjectSelectionCopySharedFolderStructureDialog open={isObjectSelectionCopySharedFolderStructureDialogVisible} setOpen={setIsObjectSelectionCopySharedFolderStructureDialogVisible} groups={[]}
                onFinish={async(object: ObjectUnit[]) => {
                    for (let file of files){
                        await api.copySharedFolderStructrueToObjectsAsync(file, object.map(x=>x.id), selectedRows[0].id,token, enqueueSnackbar);
                    }
                }} />

        </Paper>
    );
}