import React, { useRef } from 'react';
import { AppBar, DialogContent, DialogTitle, IconButton, Toolbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useGroupSideBarImagesDialogStyles } from '../../styles/useGroupSideBarImagesDialogStyles';
import { api } from '../../api';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../helpers/storage-management';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { EmptyBoxOverlay } from '../overlays/EmptyBoxOverlay';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GroupSideBarImagesAssignmentDialogGridList, GroupSideBarImagesDialogGridList } from './GroupSideBarImagesDialog.GridList';
import { ContentCode, GroupSettings, GroupSettingsSideBarImageItem, GroupSettingsType } from '../../models/GroupSettings';
import { CircleProgressOverlay } from '../overlays/CircleProgressOverlay';
import { GroupSideBarImagesDialogMode } from './GroupSideBarImagesDialogMode';
import { GroupSideBarImagesDialogSettingsView } from './GroupSideBarImagesDialog.SettingsView';
import { GroupSideBarImagesDialogSingleImageView } from './GroupSideBarImagesDialog.SingleImageView';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { AssignmentReturn } from '@mui/icons-material';

export const GroupSideBarImagesAssignmentDialog = ({ groupId, row, type, open, onClose, onChange, contentCombinationIndex }: { groupId: string, row: GroupSettings | UserGroupSettingsTemplate, type: GroupSettingsType, open: boolean, onClose: any, onChange: any, contentCombinationIndex?: number }) => {

    const styles = useGroupSideBarImagesDialogStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const token = getApiToken(history);
    const selectedImageViewRef: any = useRef();

    const [dialogMode, setDialogMode] = React.useState<GroupSideBarImagesDialogMode>(GroupSideBarImagesDialogMode.ImageListView);
    const [groupSideBarSettings, setGroupSideBarSettings] = React.useState<GroupSettingsSideBarImageItem[] | null>(null);
    const [selectedGroupSideBarSetting, setSelectedGroupSideBarSettings] = React.useState<GroupSettingsSideBarImageItem | null>(null);
    const [dialogMaxWidth, setDialogMaxWidth] = React.useState<false | "md" | "xs" | "sm" | "lg" | "xl" | undefined>("md");

    const handleClose = () => {
        onClose();
        switchToGridListView();
    };

    const switchToSingleImageView = (item: GroupSettingsSideBarImageItem) => {
        setDialogMode(GroupSideBarImagesDialogMode.SingleImageView);
        setSelectedGroupSideBarSettings(item);
        setDialogMaxWidth("lg");
    }

    const switchToGridListView = () => {
        setDialogMode(GroupSideBarImagesDialogMode.ImageListView);
        setSelectedGroupSideBarSettings(null);
        setDialogMaxWidth("md");
    }

    const switchToImageSettingView = (item: GroupSettingsSideBarImageItem) => {
        setDialogMode(GroupSideBarImagesDialogMode.ImageSettingView);
        setSelectedGroupSideBarSettings(item);
        setDialogMaxWidth("md");
    }


    const addImage = (item: GroupSettingsSideBarImageItem) => {
        console.log(row.content?.contentCode, contentCombinationIndex);
        if (row.content?.contentCode == ContentCode.Combination && contentCombinationIndex != undefined) {
            if (row.content.contentCombinationItems[contentCombinationIndex].sideBarImageItemIds.some(x => x == item.id)) {
                enqueueSnackbar("Bild ist schon zugewiesen", { variant: 'error' });
            } else {
                row.content.contentCombinationItems[contentCombinationIndex].sideBarImageItemIds.push(item.id);
                onChange({ target: { name: 'contentCombinationModify', value: JSON.stringify({ modifiyIndex: contentCombinationIndex, newVal: row.content.contentCombinationItems[contentCombinationIndex], systemETag: null }) } });
                enqueueSnackbar("Bild zugewiesen", { variant: 'success' });
            }
        }
    }

    const removeImage = (item: GroupSettingsSideBarImageItem) => {
        if (row.content?.contentCode == ContentCode.Combination && contentCombinationIndex != undefined) {
            if (!row.content.contentCombinationItems[contentCombinationIndex].sideBarImageItemIds.some(x => x == item.id)) {
                enqueueSnackbar("Bild ist schon weggewiesen", { variant: 'error' });
            } else {
                row.content.contentCombinationItems[contentCombinationIndex].sideBarImageItemIds =
                    row.content.contentCombinationItems[contentCombinationIndex].sideBarImageItemIds.filter(x => x != item.id);
                onChange({ target: { name: 'contentCombinationModify', value: JSON.stringify({ modifiyIndex: contentCombinationIndex, newVal: row.content.contentCombinationItems[contentCombinationIndex], systemETag: null }) } });
                enqueueSnackbar("Bild weggewiesen", { variant: 'success' });
            }
        }
    }

    const assignImageId = async (item: GroupSettingsSideBarImageItem) => {
        addImage(item);
        switchToGridListView();
    }

    const buildDialogContentContent = () => {

        const onOpenButtonClicked = async (item: GroupSettingsSideBarImageItem) => {
            switchToSingleImageView(item);
        }

        const onAssignmentButtonClicked = async (item: GroupSettingsSideBarImageItem) => {
            addImage(item);
        }

        if (!groupSideBarSettings)
            return <CircleProgressOverlay />;

        if (groupSideBarSettings.length === 0)
            return <EmptyBoxOverlay text="Kein Bild gefunden" />;

        switch (dialogMode) {
            case GroupSideBarImagesDialogMode.ImageListView:
                return <GroupSideBarImagesAssignmentDialogGridList
                    groupId={groupId}
                    settings={groupSideBarSettings}
                    filterToOnlyAssigend={filterImages}
                    type={type}
                    onOpenButtonClicked={onOpenButtonClicked}
                    onAssignmentButtonClicked={onAssignmentButtonClicked}
                    onRemoveAssignmentButtonClicked={removeImage} />
            case GroupSideBarImagesDialogMode.SingleImageView:
                return <GroupSideBarImagesDialogSingleImageView
                    groupId={groupId}
                    type={type}
                    item={selectedGroupSideBarSetting} />
        }
    }

    const dialogTitle = () => {

        const buildLeftIcon = () => {
            if (!selectedGroupSideBarSetting)
                return (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="back"
                        onClick={handleClose}
                        size="large">
                        <ArrowBackIcon />
                    </IconButton>
                );

            return (
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="back"
                    onClick={switchToGridListView}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
            );
        }

        const buildRightIcon = () => {
            if (!selectedGroupSideBarSetting)
                return null;

            const onAssignmentButtonClick = async () => {
                assignImageId(selectedGroupSideBarSetting);
            }

            switch (dialogMode) {
                case GroupSideBarImagesDialogMode.SingleImageView:
                    return (
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="assignment"
                            onClick={onAssignmentButtonClick}
                            size="large">
                            <AssignmentReturn />
                        </IconButton>
                    );
            }

            return (
                <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="delete"
                    onClick={onAssignmentButtonClick}
                    size="large">
                    <AssignmentReturn />
                </IconButton>
            );
        }

        return (
            <DialogTitle id="group-advertisements-dialog-title" style={{ flexGrow: 1, padding: 0 }}>
                <AppBar position="sticky">
                    <Toolbar>
                        {buildLeftIcon()}
                        <div style={{ flexGrow: 1 }}></div>
                        {buildRightIcon()}
                    </Toolbar>
                </AppBar>
            </DialogTitle>
        );
    }

    React.useEffect(() => {

        (async () => {
            if (!open) return;

            setGroupSideBarSettings(null);
            let settings = await api.listGroupSettingsSideBarSettingsAsync(groupId, type, token);
            setGroupSideBarSettings(settings);
        })();

    }, [open]);

    const filterImages = async (showOnlyAssigned: boolean) => {
        let settings = await api.listGroupSettingsSideBarSettingsAsync(groupId, type, token);
        if (showOnlyAssigned && row.content?.contentCode == ContentCode.Combination && contentCombinationIndex) {
            settings = settings.filter(x => !row.content?.contentCombinationItems[contentCombinationIndex].sideBarImageItemIds.some(y => y == x.id))
        }
        setGroupSideBarSettings(settings);
    }

    // No rendering if dialog is closed
    if (!open) return null;

    return (
        <Dialog
            className={styles.dialog}
            onClose={handleClose}
            aria-labelledby="group-advertisements-dialog-title"
            open={open}
            maxWidth={dialogMaxWidth}>
            {dialogTitle()}
            <DialogContent className={styles.dialogContent}>
                {buildDialogContentContent()}
            </DialogContent>
        </Dialog>
    );
}


