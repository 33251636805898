import { saveExcelFile } from "./tables/common";

export class ExcelHelper {

    static onSave = (workbook: any, name: string) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), name);
        });
    };

}