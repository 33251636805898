import axios from 'axios';
import { handleApiError, headers } from '../api';
import { GROUP_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupMotion } from '../models/GroupMotion';


export const getLastMotionByGroup = async (token: string): Promise<GroupMotion> => {
    try {
        const userId = getUserIdFromStorage();
        let groupId = localStorage.getItem(GROUP_ID);
        if (!groupId){
            return null as any;
        }
        
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/groups/${groupId}/users/${userId}/last-motion`, {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err, null, "");
    }
    return null as any;
}

export const getLastMotionByGroupId = async (token: string, groupId: string): Promise<GroupMotion> => {
    try {
        const userId = getUserIdFromStorage();
        
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/groups/${groupId}/users/${userId}/last-motion`, {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err, null, "");
    }
    return null as any;
}