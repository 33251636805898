import Dialog from '@mui/material/Dialog';
import React from 'react';

import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { GROUP_ID, GROUP_NAME } from '../../../constants';
import { getApiToken } from '../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { UserAccount } from '../../../models/UserAccount';
import { UserType } from '../../../models/UserType';
import { GroupSingleSelection } from '../../tables/GroupSingleSelection';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { UserServiceWorkerObjectSession } from '../../../models/UserServiceWorkerObjectSession';
import { ServiceWorkerObjectSessionTable } from './ServiceWorkerObjectSessionTable';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { getUniqueGroupsBySessions } from '../../../api/GetAllGroupsAsync';
import { useTranslation } from 'react-i18next';

export const ServiceWorkerObjectSessionDialog = ({
  open,
  setOpen,
  selectedUserServiceWorkerId,
  objects,
  userServiceWorker,
  groups
}: {
  open: boolean,
  setOpen: any,
  selectedUserServiceWorkerId: string,
  objects: ObjectUnit[],
  userServiceWorker: UserServiceWorker | undefined,
  groups: GroupLicenseTable[]
}) => {

  const history = useHistory();
  const {t} = useTranslation();


  const [userServiceWorkerObjectSessions, setUserServiceWorkerObjectSessions] = React.useState<UserServiceWorkerObjectSession[]>([]);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const token = getApiToken(history);
      if (!token) return;

      const tempSessions = await api.getObjectSessionsOfServiceWorker(token, selectedUserServiceWorkerId);
      setUserServiceWorkerObjectSessions(tempSessions);




    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Anmeldungen in Objekten der Reinigungskraft ${userServiceWorker?.name} mit Nummer: ${userServiceWorker?.phoneNumber}`}
        handleClose={handleClose}
      />

      <DialogContent>
        {userServiceWorker &&
          <ServiceWorkerObjectSessionTable
            userServiceWorker={userServiceWorker}
            userServiceWorkerObjectSessions={userServiceWorkerObjectSessions}
            objects={objects}
            serviceWorkers={[]}
            groups={groups}
          />
        }
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          Dialog {t("myUnitsSettings.close")}
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));