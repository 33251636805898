import axios from 'axios';
import { LoginServiceWorkerByEmailCodeModel } from '../../models/public/LoginServiceWorkerByEmailCodeModel';
import { PublicSession, Session } from '../../models/Session';
import { headers } from '../../api';

export const loginServiceWorkerByEmailCode = async (login: LoginServiceWorkerByEmailCodeModel, enqueueSnackbar: any): Promise<PublicSession> => {
    try {
        const ret = await axios.post<PublicSession>(
            `${process.env.REACT_APP_BASIC_URI}/auth/service-worker/by-email-code`,
            { ...login },
            { headers: { ...headers } });
        enqueueSnackbar("Login erfolgreich", { variant: 'success' });
        return ret.data;

    } catch (err) {
        enqueueSnackbar("Login fehlgeschlagen", { variant: 'error' });

        return null as any;
    }
}