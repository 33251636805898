import { Grid, Paper } from "@mui/material";
import React from "react";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { PieChartComponent } from "../../../../charts/DoughnutChart";
import { StandardBarChart } from "../../../../charts/StandardBarChart";
import { SumChartChoice } from "../../../../core/SumChartChoice";
import { TimeUserChoiceSum } from "../../../../core/TimeUserChoiceSum";

export const CustomSumUserOptions = ({ data, dataField, allData, setChartData, text, defaultChoice = '0', currentCleaner, setCurrentCleaner, childRef = null, loadingCompleted, childRefChart }
    : { data: any, dataField: any, allData: any, setChartData: any, text: string, defaultChoice: string, currentCleaner: any, setCurrentCleaner: any, childRef?: any, loadingCompleted: boolean, childRefChart?: any }) => {
    const [chartChoice, setChartChoice] = React.useState(defaultChoice);

    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <StandardBarChart
                    childRefChart={childRefChart}
                    data={data}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <PieChartComponent
                    childRefChart={childRefChart}
                    data={data}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeUserChoiceSum
                            ref={childRef}
                            currentCleaner={currentCleaner}
                            setCurrentCleaner={setCurrentCleaner}
                            fields={dataField}
                            data={allData}
                            setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                        <SumChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}