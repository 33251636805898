import React from 'react';
import {
    AppBar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Group, TouchApp } from '@mui/icons-material';
import { formatEuropeanDate } from '../../helpers/date-management';
import { GroupLicense } from '../../models/GroupLicense';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { ReactComponent as LicenseSvg } from '../../assets/svgs/license_grey.svg'
import { buildCountCol, ShowCountType } from './AssignLicenseChoosingTableDialog';
import { useTranslation } from 'react-i18next';

export const AssignMultipleLicenseChoosingTableDialog = ({ visible, onClose, licenses, onLicenseSelected, showCountType = ShowCountType.Sensor }
    : { visible: boolean, onClose: any, licenses: GroupLicense[], onLicenseSelected: any, showCountType?: ShowCountType }) => {

    const classes = useStyles();

    const [selection, setSelection] = React.useState<GroupLicense[]>([]);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    React.useEffect(() => {

        setSelection([]);
        setLoading(false);

    }, [visible])


    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={visible}
            onClose={onClose}
        >
            <DialogTitle className={classes.dialogTitle}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography className={classes.dialogTitleText}>Lizenz auswählen</Typography>
                        <IconButton color="inherit" onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <List>
                    {
                        licenses &&
                        licenses.length > 0 &&
                        licenses.map((item: GroupLicense, index: number) => (
                            <ListItem
                                key={index}
                                button
                                onClick={() => {
                                    if (selection.some(x=>x.id == item.id)){
                                        setSelection(selection.filter(x=>x.id != item.id));
                                    }else {
                                        setSelection([...selection, item]);
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selection?.some(x=>x.id  == item.id)}
                                        tabIndex={-1}
                                        inputProps={{}}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={`${t("myUnitsSettings.purchaseDate")}: ${formatEuropeanDate(item.purchaseTimestamp)}`} />
                                <ListItemText primary={`${t("myUnitsSettings.duration")}: ${item.durationYears} ${t("myUnitsSettings.years")}`} />
                                {buildCountCol(item, showCountType)}
                                <ListItemIcon>
                                    <LicenseSvg />
                                </ListItemIcon>
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={selection.length < 1 || loading}
                    startIcon={<TouchApp />}
                    endIcon={loading && <CircularProgress size={32}></CircularProgress>}
                    onClick={async () => {

                        if (!selection) return;
                        setLoading(true);
                        await onLicenseSelected(selection);
                        setLoading(false);
                    }}
                >
                    {t("myUnitsSettings.selectLicense")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 0,
        flexGrow: 1,
    },
    dialogTitleText: {
        flexGrow: 1,
    },
    dialogContent: {
        padding: theme.spacing(2),
        height: 320,
    },
    dialogContentRadio: {
        padding: theme.spacing(1),
    },
}));