import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const assignObjectsToServiceWorker = async (objectIds: string [],userServiceWorkerId: string, token: string, enqueueSnackbar: any) => {

    if (!token || !enqueueSnackbar) return;
    let userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/objects/multiple/service-workers/${userServiceWorkerId}/assign`,{stringArray: objectIds},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Objekte wurden der Servicekraft erfolgreich zugewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};

export const unAssignObjectsFromServiceWorker = async (objectIds: string [],userServiceWorkerId: string, token: string, enqueueSnackbar: any) => {

    if (!token || !enqueueSnackbar) return;
    let userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/objects/multiple/service-workers/${userServiceWorkerId}/un-assign`,{stringArray: objectIds},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Objekte wurden der Servicekraft erfolgreich weggewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};