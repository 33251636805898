import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, DialogActions, DialogContent, Divider, Grid, TextField, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, CheckCircle, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { MaterialObjectUnit, ObjectUnit } from '../../../models/ObjectUnit';
import { errorColor, white } from '../../../styles/colors';
import { MaterialSelectionTable } from '../../tables/material/MaterialSelectionTable';
import { StandardWorkItem, StandardWorkItemType } from '../../../models/StandardItem';
import { getUserIdFromStorage } from '../../../helpers/common';
import { MaterialStock, StockActionDoneFromType, StockActionType, UserStock } from '../../../models/UserStock';
import { useTranslation } from 'react-i18next';

export const MaterialsSelectionAssignToObjectDialog = ({
  open,
  setOpen,
  objectUnit,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  objectUnit: ObjectUnit,
  onRefresh: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [materials, setMaterials] = React.useState<MaterialObjectUnit[]>([]);
  const [selectedMaterials, setSelectedMaterials] = React.useState<MaterialObjectUnit[]>([]);
  const [token, setToken] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [reason, setReason] = React.useState("");

  const [countEdited, setCountEdited] = React.useState(0);
  const [latestUserStock, setLatestUserStock] = React.useState<UserStock>();
  const {t} = useTranslation();

  const handleClose = () => {
    setMaterials([]);
    setSelectedMaterials([]);
    setCountEdited(0);
    setLatestUserStock(undefined);
    setOpen(false);
  }

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      let latestUserStock = await api.getLatestUserStockByObjectId(tempToken, objectUnit.id);
      if (latestUserStock) {
        setLatestUserStock(latestUserStock);
        setCountEdited(latestUserStock.restockObjectCount + 1)
      }

      if (materials.length < 1) {
        const tempMaterials =
          (await api.GetStandardWorkItemsByType(tempToken, StandardWorkItemType.Material, getUserIdFromStorage()))
            .map(x => MaterialObjectUnit.CreateInstanceFrontend(x.id, 0, x.photos, x.createdTimestamp, x.text, x.entity, x.article));
        for (let standardMaterial of tempMaterials) {
          let material = latestUserStock?.materialStocks?.find(x => x.materialId == standardMaterial.materialId);
          if (material) {
            standardMaterial = MaterialObjectUnit.SetValuesFromMaterialStock(material, standardMaterial);
          }
        }
        setMaterials(tempMaterials);
        setSelectedMaterials(tempMaterials.filter(x => latestUserStock.materialStocks.some(y => y.materialId == x.materialId)));
      } else {
        for (let standardMaterial of materials) {
          let material = latestUserStock?.materialStocks?.find(x => x.materialId == standardMaterial.materialId);
          if (material) {
            standardMaterial = MaterialObjectUnit.SetValuesFromMaterialStock(material, standardMaterial);
          }
        }
        setSelectedMaterials(materials.filter(x => latestUserStock.materialStocks.some(y => y.materialId == x.materialId)));
      }


    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen
    >
      <DefaultDialogTitle
        title={`${t("myObjectSettings.selectMaterialsToAssignToObject")} ${objectUnit.name}`}
        handleClose={handleClose}
      />

      <DialogContent sx={{ marginTop: 3 }}>
        <Grid container spacing={2} direction={"row"}>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold', marginRight: 5 }} style={{ fontSize: 30 }}>{t("myUnitsSettings.storageEntryNo")}: {countEdited}</Typography>
          </Grid>
          <Grid item>
            <TextField value={reason} onChange={(event) => setReason(event.target.value)} label={t("myUnitsSettings.storageReason")} />
          </Grid>
          <Grid item xs={12}>
            <MaterialSelectionTable
              materials={materials}
              setMaterials={setMaterials}
              selectedMaterials={selectedMaterials}
              onSelectionChange={(selection: string[]) => {
                setSelectedMaterials(materials.filter(x => selection.some(s => s === x.materialId)));
              }}
            />
          </Grid>
        </Grid>

      </DialogContent>


      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          size="large"
          endIcon={loading ? <CircularProgress size={32} /> : <CheckCircle />}
          onClick={async () => {
            setLoading(true);
            let userId = getUserIdFromStorage();
            let materialStocks = selectedMaterials.map(x => MaterialStock.CreateInstanceStockObject(x.materialId, x.amount, x.newAmount, 0, x.specificReason, StockActionType.ManualModifyObject, x.minimumAmount, x.maximumAmount, x.alarmEmail, x.importanceLevelObject))
            let userStock = new UserStock(userId, objectUnit.id, "", "", StockActionDoneFromType.PortalObjectSettings, reason, materialStocks, countEdited, 0);
            if (latestUserStock) {
              userStock = UserStock.setLastStockObjectValues(userStock, latestUserStock);
            }
            await api.createUserStock(userStock, token, enqueueSnackbar);
            handleClose();
            onRefresh();
            setLoading(false);
          }}
        >
          {t("myUnitsSettings.adoptSelectedMaterials")}
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));