import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { formatEuropeanDate, formatEuropeanTime } from '../../../../../helpers/date-management';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { GroupSuggestion } from '../../../../../models/GroupSuggestion';
import { useTranslation } from 'react-i18next';

export const GroupSuggestionDataTable = ({ rows, groups, selectedEntries, onSelectionChange,  }
    : { rows: GroupSuggestion[], groups: GroupLicenseTable[], selectedEntries: GroupSuggestion[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);

    const {t} = useTranslation();

    const Cell = (props: any) => {
        const { column, row } : {column: any, row: GroupSuggestion} = props;
    /*    if (column.name == GroupSuggestion.Columns.items && row.items) {
            return buildCellStringArray(props, row.items, `${t("myObjectSettings.contains")} ${row.items.length} Vorgefertigte Texte`);
        } */

        return <Table.Cell {...props} />;
    };


    const columns = [
        {
            name: GroupSuggestion.Columns.suggestionOriginType,
            title: "Ursprung",
            getCellValue: (row: GroupSuggestion) => GroupSuggestion.GetLabelByType(row.suggestionOriginType),
        },
        {
            name: GroupSuggestion.Columns.id,
            title: t("serviceDocumentationTable.unitName"),
            getCellValue: (row: GroupSuggestion) => groups.find(x=>x.id == row.groupId)?.notificationName,
        },
        {
            name: GroupSuggestion.Columns.messenger,
            title: "Melder",
            getCellValue: (row: GroupSuggestion) => row.messenger,
        },
        {
            name: GroupSuggestion.Columns.customTexts,
            title: "Frei-Texte",
            getCellValue: (row: GroupSuggestion) => row.customText,
        },
        {
            name: GroupSuggestion.Columns.createdTimestamp,
            title: t("dashboard.creationDate"),
            getCellValue: (row: GroupSuggestion) => formatEuropeanDate(row.createdTimestampString),
        },
        {
            name: GroupSuggestion.Columns.createdTime,
            title: t("dashboard.creationTime"),
            getCellValue: (row: GroupSuggestion) => formatEuropeanTime(row.createdTimestampString),
        }
    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => { 
        if (el.name == GroupSuggestion.Columns.items || el.name == GroupSuggestion.Columns.customTexts){
            return { columnName: el.name, width: 300 }
        }
        return { columnName: el.name, width: 180 } 
    }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Feedbackfragen');
        });
    };

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
          //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
