import React from 'react';
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Edit, NavigateNext, Save, SwitchCamera } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { GroupSettings, ToiletType } from '../../models/GroupSettings';
import { StringHelper } from '../../helpers/string-helper';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { usePortalAccountStyles } from '../../styles/usePortalAccountStyles';
import { DefaultDialogTitle } from './DefaultDialogTitle';
import { GroupSelectionAssignGroupToObjectDialog } from '../authorized/extendedapp/entireEval/GroupSelectionAssignGroupToObjectDialog';
import { getApiToken, setStorageForDrawer } from '../../helpers/storage-management';
import { api } from '../../api';
import { GroupSelectionAssignGroupToPropertyDialog } from '../authorized/extendedapp/entireEval/GroupSelectionAssignGroupToPropertyDialog';
import { GlobalContext } from '../authorized/PortalPage';
import { GroupSelectionAssignMultupleGroupToObjectDialog } from '../authorized/extendedapp/entireEval/GroupSelectionAssignMultupleGroupToObjectDialog';
import { ObjectUnit } from '../../models/ObjectUnit';
import { useTranslation } from 'react-i18next';

export const CheckpointGroupEditingDialog = ({ visible, setVisible, group, onSave, onRefresh, defaultGroupSettings = GroupSettings.CreateCheckpointSetting("", "", "", "") }
    : { visible: boolean, setVisible: any, group: GroupSettings, onSave: any, onRefresh: any, defaultGroupSettings?: GroupSettings }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [currentGroup, setCurrentGroup] = React.useState<GroupSettings>(defaultGroupSettings);

    const [nameError, setNameError] = React.useState('');
    const [typeError, setTypeError] = React.useState('');
    const [unitPriceError, setUnitPriceError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const [groupSelectionAssignObjectDialogVisible, setGroupSelectionAssignObjectDialogVisible] = React.useState(false);
    const [groupSelectionAssignPropertyDialogVisible, setGroupSelectionAssignPropertyDialogVisible] = React.useState(false);
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [loadingOjbects, setLoadingObjects] = React.useState(false);


    const { onUpdateDisplayGroup }: any = React.useContext(GlobalContext);


    const onClose = () => {
        setCurrentGroup(defaultGroupSettings);
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();
    const {t} = useTranslation();

    const isValid = () => {
        if (!currentGroup.locationInformation) return;
        let valid = true;

        if (StringHelper.IsNullOrWhiteSpace(currentGroup.locationInformation.notificationName)) {
            setNameError("Name ist erforderlich");
            enqueueSnackbar("Name pflicht!", { variant: 'error' });
            valid = false;
        }

        return valid;
    }

    const resetErrorText = () => {
        setNameError('');
        setTypeError('');
        setUnitPriceError('');
    }


    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [currentExistingGroup, setCurrentExistingGroup] = React.useState<GroupLicenseTable>();

    const history = useHistory();

    const loadDataAsync = async () => {
        setLoading(true);
        const tempToken = getApiToken(history);
        const tempGroups = await api.getAllGroups(tempToken);
        setGroups(tempGroups);

        if (currentGroup) {
            setCurrentExistingGroup(tempGroups.find(x => x.id == group.id));
            var newGroup = await api.getGroupSettings(group.id || "", tempToken);
            setCurrentGroup(newGroup);
        } else {
            setCurrentGroup(defaultGroupSettings);
        }
        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();

            resetErrorText();

        })();

    }, [visible]);

    const navigateToSettings = (currentGroup: GroupLicenseTable) => {
        onUpdateDisplayGroup(currentGroup);
        setStorageForDrawer(currentGroup);
        history.push(`/portal/group/${currentGroup.id}/settings`);
    }


    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="md">
            <DefaultDialogTitle
                title={`Einheit ${currentGroup.locationInformation?.notificationName} bearbeiten`}
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">Details</Typography>
                            {loading && 
                            <CircularProgress size={64} />}
                        </Grid>
                        {!!currentGroup.locationInformation?.objectUnitId &&
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    endIcon={<NavigateNext />}
                                    disabled={loading}
                                    onClick={async () => {
                                        const queryParams = { chosenObject: currentGroup.locationInformation?.objectUnitId || "" }
                                        const queryString = new URLSearchParams(queryParams).toString()
                                        history.push({
                                            pathname: `/portal/objekte`,
                                            search: queryString
                                        });
                                    }}
                                >
                                    Zum Objekt, um Kunde oder Objekt Name zu ändern
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Einheit-Name"
                                variant="filled"
                                value={currentGroup.locationInformation?.notificationName}
                                onChange={(event: any) => {
                                    if (!currentGroup.locationInformation) return;
                                    currentGroup.locationInformation.notificationName = event.target.value;
                                    setCurrentGroup({ ...currentGroup });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Objekt-Name (spezifisch)"
                                disabled={!!currentGroup.locationInformation?.objectUnitId}
                                variant="filled"
                                value={currentGroup.locationInformation?.objectName}
                                onChange={(event: any) => {
                                    if (!currentGroup.locationInformation) return;
                                    currentGroup.locationInformation.objectName = event.target.value;
                                    setCurrentGroup({ ...currentGroup });
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Ebene-Name"
                                variant="filled"
                                value={currentGroup.locationInformation?.levelName}
                                onChange={(event: any) => {
                                    if (!currentGroup.locationInformation) return;
                                    currentGroup.locationInformation.levelName = event.target.value;
                                    setCurrentGroup({ ...currentGroup });
                                }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                disabled={!!currentGroup.locationInformation?.objectUnitId}
                                label="Kunde-Name (spezifisch)"
                                variant="filled"
                                value={currentGroup.locationInformation?.roomName}
                                onChange={(event: any) => {
                                    if (!currentGroup.locationInformation) return;
                                    currentGroup.locationInformation.roomName = event.target.value;
                                    setCurrentGroup({ ...currentGroup });
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Raumart</FormLabel>
                                <RadioGroup
                                    row
                                    name="sensorItems"
                                    value={currentGroup.locationInformation?.toiletType}
                                    onChange={(event: any) => {
                                        if (!currentGroup.locationInformation) return;
                                        currentGroup.locationInformation.toiletType = JSON.parse(event.target.value);
                                        setCurrentGroup({ ...currentGroup });
                                    }}
                                >
                                    <FormControlLabel value={ToiletType.Neutral} control={<Radio />} label="Neutral" />
                                    <FormControlLabel value={ToiletType.Male} control={<Radio />} label="Männlich" />
                                    <FormControlLabel value={ToiletType.Female} control={<Radio />} label="Weiblich" />
                                    <FormControlLabel value={ToiletType.Meeting} control={<Radio />} label="Meeting" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={6}>
                                <Alert severity="info">
                                    Zugewiesenes Objekt: {currentExistingGroup?.objectName}
                                </Alert>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    size='large'
                                    startIcon={loadingOjbects && <CircularProgress size={32}/>}
                                    disabled={loadingOjbects}
                                    color="primary"
                                    fullWidth
                                    endIcon={<SwitchCamera />}
                                    onClick={async () => {
                                        setLoadingObjects(true);
                                        const tempToken = getApiToken(history);
                                        const tempObjects = await api.getUserObjectUnits(tempToken);
                                        setObjects(tempObjects);
                                        await api.updateGroupSettings(currentGroup, tempToken, enqueueSnackbar);
                                        setGroupSelectionAssignObjectDialogVisible(true);
                                        setLoadingObjects(false);
                                    }}
                                >
                                    Speichern und Objekt zuweisen
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={6}>
                                <Alert severity="info">
                                    Zugewiesenes Merkmal: {currentExistingGroup?.userPropertyName || "Keines"}
                                </Alert>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    size='large'
                                    color="primary"
                                    startIcon={loadingOjbects && <CircularProgress size={32}/>}
                                    disabled={loadingOjbects}
                                    fullWidth
                                    endIcon={<SwitchCamera />}
                                    onClick={async () => {
                                        setLoadingObjects(true);
                                        const tempToken = getApiToken(history);
                                        await api.updateGroupSettings(currentGroup, tempToken, enqueueSnackbar);
                                        setGroupSelectionAssignPropertyDialogVisible(true);
                                        setLoadingObjects(false);
                                    }}
                                >
                                    Speichern und Merkmal zuweisen
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={loading && <CircularProgress size={32} />}
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        await onSave(currentGroup);
                        setLoading(false);
                        
                        if (currentExistingGroup) navigateToSettings({...currentExistingGroup, ...currentGroup.locationInformation} as any);

                        onClose();
                    }}
                >
                    Speichern & Einheit bearbeiten
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={loading && <CircularProgress size={32} />}
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        await onSave(currentGroup);
                        onRefresh();
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
            {currentExistingGroup &&
                <GroupSelectionAssignMultupleGroupToObjectDialog
                    open={groupSelectionAssignObjectDialogVisible} setOpen={setGroupSelectionAssignObjectDialogVisible} allGroups={groups}
                    userObjects={objects} currentGroups={[currentExistingGroup]} onRefresh={async () => {
                        await loadDataAsync();
                    }} />
            }
            {currentExistingGroup &&
                <GroupSelectionAssignGroupToPropertyDialog onRefresh={loadDataAsync} currentGroup={currentExistingGroup} groups={groups} open={groupSelectionAssignPropertyDialogVisible} setOpen={setGroupSelectionAssignPropertyDialogVisible} />
            }
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);


