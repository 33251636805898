export enum BackendErrorCodes {
    BadRequest = 400000,
    CannotParseBody = 400001,
    CannotParseQuery = 400002,
    ForbiddenNotAuthenticated = 403001,
    ForbiddenInvalidToken = 403002,
    ForbiddenInvalidCredentials = 403003,
    ForbiddenInsufficientRights = 403004,
    ForbiddenEmailNotConfirmed = 403005,
    ForbiddenNotActivated = 403007,
    ForbiddenLicenseExpired = 403010,
    ForbiddenDataRestricted = 403020,
    NotFoundRecordNotFound = 404001,
    OptimisticLocking = 409001,
    KeyConflict = 409002,
    KeyConflictDeviceRegistered = 409003,
    KeyConflictUserRegistered = 409004,
    KeyConflictWhatsAppGroupRegistered = 409005,
    RecordAlreadyExists = 409006,
    InternalServerError = 500000
}