import React, { useContext } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { PropertyType, UserProperty } from '../../../../models/UserProperty';
import { StringHelper } from '../../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../../styles/usePortalAccountStyles';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { AdminSetting, AdminUsageCategory } from '../../../../models/AdminSetting';
import { GlobalContext } from '../../PortalPage';
import { RruleGeneratorComponent } from '../../../../helpers/RruleGenerator';
import RRule from 'rrule';
import { useTranslation } from 'react-i18next';

export const PropertyManagementPageEditingDialog = ({ visible, setVisible, object, onSave, objects }
    : { visible: boolean, setVisible: any, object?: UserProperty, objects: UserProperty[], onSave: any }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [currentProperty, setCurrentProperty] = React.useState<UserProperty>(UserProperty.CreateUserProperty());

    const [nameError, setNameError] = React.useState('');
    const [typeError, setTypeError] = React.useState('');
    const [unitPriceError, setUnitPriceError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    const { adminSetting }: { adminSetting: AdminSetting } = useContext(GlobalContext);

    const [rRule, setRrule] = React.useState(new RRule({
        freq: RRule.WEEKLY,
        count: 30,
        interval: 1,
        byweekday: [],

    }));

    const onClose = () => {
        setCurrentProperty(UserProperty.CreateUserProperty());
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    const isValid = () => {
        let valid = true;

        if (StringHelper.IsNullOrWhiteSpace(currentProperty.name)) {
            setNameError("Name ist erforderlich");
            enqueueSnackbar("Name pflicht!", { variant: 'error' });
            valid = false;
        }

        return valid;
    }

    const resetErrorText = () => {
        setNameError('');
        setTypeError('');
        setUnitPriceError('');
    }

    React.useEffect(() => {

        (async () => {
            let rule = new RRule({
                dtstart: new Date(),
                freq: RRule.WEEKLY,
                count: 30,
                interval: 1,
                byweekday: [],
            });
            setRrule(rule);
            if (object) {
                if (object.rrule) {
                    setRrule(RRule.fromString(object.rrule))
                }
                setCurrentProperty(object);
            } else {
                setCurrentProperty(UserProperty.CreateUserProperty());
            }

            resetErrorText();

        })();

    }, [visible, object])


    let currentAdminSetting = adminSetting?.adminUsageCategories?.find(x => x.id == currentProperty.usageCategoryId);

    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title="Merkmal bearbeiten"
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">Details</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Name"
                                variant="filled"
                                value={currentProperty.name}
                                onChange={(event: any) => { setCurrentProperty({ ...currentProperty, name: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("myUnitsSettings")}</FormLabel>
                                <RadioGroup
                                    row
                                    value={currentProperty.propertyType}
                                    onChange={(event) => {
                                        setCurrentProperty({ ...currentProperty, propertyType: parseInt(event.target.value) })
                                    }}
                                >
                                    <FormControlLabel
                                        value={PropertyType.Standard}
                                        control={<Radio />}
                                        label={UserProperty.GetLabelByType(PropertyType.Standard)}
                                    />
                                    <FormControlLabel
                                        value={PropertyType.System}
                                        control={<Radio />}
                                        label={UserProperty.GetLabelByType(PropertyType.System)}
                                    />
                                    <FormControlLabel
                                        value={PropertyType.Object}
                                        control={<Radio />}
                                        label={UserProperty.GetLabelByType(PropertyType.Object)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {currentProperty.propertyType == PropertyType.System &&
                            <Grid item xs={12} lg={12}>
                                <Autocomplete
                                    value={currentAdminSetting}
                                    onChange={(_: any, newValue: AdminUsageCategory | null) => {
                                        currentProperty.usageCategoryId = newValue?.id || "";
                                        setCurrentProperty({ ...currentProperty });
                                    }}
                                    id="controllable-states-demo"
                                    options={adminSetting.adminUsageCategories.filter(x => x.isEnabled)}
                                    getOptionLabel={(option) => `${option.name} - ${option.carbonDioxideConsumption} kg CO2/m²`}
                                    renderOption={(props, option) => {
                                        let currentAdminSetting = adminSetting.adminUsageCategories.find(x => x.id == option.id);
                                        return (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <Typography>{option.name} - {currentAdminSetting?.carbonDioxideConsumption} kg CO2/m²</Typography>
                                            </Box>
                                        )
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Auswahl Nutzungsart" variant="outlined" />}
                                />
                            </Grid>
                        }
                        {currentProperty.propertyType == PropertyType.System && currentProperty.usageCategoryId &&
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold' }}>CO2 Verbrauch: {currentAdminSetting?.carbonDioxideConsumption} kg/m²</Typography>
                            </Grid>
                        }
                        {currentProperty.propertyType == PropertyType.System && currentProperty.usageCategoryId &&

                            <Grid item xs={12} lg={12}>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label={t("myUnitsSettings.areaPerformance")}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">m²/h</InputAdornment>
                                    }}
                                    type='number'
                                    variant="filled"
                                    value={currentProperty.areaPerformancePerSquareMeter}
                                    onChange={(event: any) => { setCurrentProperty({ ...currentProperty, areaPerformancePerSquareMeter: event.target.value }) }}
                                />
                            </Grid>
                        }
                        {currentProperty.propertyType == PropertyType.System && currentProperty.usageCategoryId &&
                            <RruleGeneratorComponent rRule={rRule} setRrule={setRrule} />
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    startIcon={loading && <CircularProgress size={32} />}
                    disabled={loading}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        currentProperty.rrule = rRule.toString();
                        await onSave(currentProperty);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
