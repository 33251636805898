import axios from 'axios';
import { handleApiError, headers } from '../api';
import { USER_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupMotion } from '../models/GroupMotion';

export const getDispensersFromTork = async (token: string): Promise<Array<any> | null> => {

    try {

        let userId = getUserIdFromStorage();

        if (!userId) return null;

        let ret = await axios.get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/tork/dispensers`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return ret.data.edges;

    } catch (err) {
        handleApiError(err);
        return null;
    }
}