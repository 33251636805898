import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useHistory } from "react-router-dom";
import { api } from "../../api";
import { formatUnixSecTimestampEuropeanDateInMinutes, isOlderThan60Minutes, isOlderThanFifteenMinutes } from "../../helpers/date-management";
import { getApiToken } from "../../helpers/storage-management";
import { ReactComponent as MotionSensorSvg } from '../../assets/svgs/motion-sensor.svg'
import { LoadingIcon } from './LoadingIcon';
import { SensorOccupied } from "@mui/icons-material";

export const LastLoraMotionDisplay = ({ groupId, className }: { groupId: string, className?: string }) => {

    const [color, setColor] = React.useState('');
    const [date, setDate] = React.useState('');
    const [lastRefreshTime, setLastRefreshTime] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false);

    const history = useHistory();

    const loadLastMotion = async () => {
        if (!groupId) {
            setDate('');
            return;
        }
        setLoading(true);
        const token = getApiToken(history);
        const motion = await api.getLastLoraMotionByGroup(token);
        setLoading(false);
        if (!motion || motion.createdTimestamp === 0) {
            setDate('');
            return;
        };
        setColor(isOlderThan60Minutes(motion?.createdTimestamp) ? "#eb4034" : "#2ee656");
        setDate(formatUnixSecTimestampEuropeanDateInMinutes(motion.createdTimestamp));
    }

    React.useEffect(() => {

        let timer: NodeJS.Timeout;

        (async () => {

            loadLastMotion();

            timer = setTimeout(() => {
                setLastRefreshTime(new Date());
            }, process.env.REACT_APP_STATUS_REFRESH_INTERVAL * 1000);




        })();

        return () => {
            clearTimeout(timer);
        }
    }, [groupId, lastRefreshTime, history]);

    if (!date) return null;

    return (
        <ListItem className={className}>
            <ListItemIcon>
                <LoadingIcon
                    icon={<SensorOccupied style={{color: color}} /> }
                    loading={loading}
                />
            </ListItemIcon>
            <ListItemText
                primary="Letzte Lora Sensor Nachricht"
                secondary={date}
            />
        </ListItem>
    );
}

