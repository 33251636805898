import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React from "react";
import { CurrencyHelper } from '../../../../helpers/CurrencyHelper';
import { CheckoutItem } from '../../../../models/CheckoutItem';
import { UserAddress } from "../../../../models/UserAddress";

export const OrderOverViewTableSummary = ({ items, userAddress }: { items: CheckoutItem[], userAddress: UserAddress | null }) => {

    const taxRate = userAddress?.country == 'Österreich' ? 20 : 0;
    const net = items.length > 0 ? items.map(x => x.total).reduce((a, b) => a + b) : 0;
    const tax = CurrencyHelper.taxOf(net, taxRate);
    const gross = CurrencyHelper.grossOf(net, taxRate);

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell rowSpan={3} width="60%" />
                        <TableCell align="left" colSpan={2}>Summe Netto:</TableCell>
                        <TableCell align="right">{CurrencyHelper.toCurrencyString(net)}</TableCell>
                    </TableRow>
                    {userAddress &&
                        <TableRow>
                            <TableCell align="left">Mwst</TableCell>
                            <TableCell align="left">{taxRate}%</TableCell>
                            <TableCell align="right">{CurrencyHelper.toCurrencyString(tax)}</TableCell>
                        </TableRow>
                    }
                    {userAddress &&
                        <TableRow>
                            <TableCell align="left" colSpan={2}>Summe Brutto:</TableCell>
                            <TableCell align="right">{CurrencyHelper.toCurrencyString(gross)}</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};
