import { Height } from "@mui/icons-material";
import { Paper, Typography, FormControl, FormLabel, FormControlLabel, Radio, Grid, TextField, InputAdornment, RadioGroup } from "@mui/material";
import { blue } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers";
import { subDays } from "date-fns";
import React, { useImperativeHandle } from "react";
import { MaxChartItemCountInput } from "../../../../../helpers/compontents";
import { formatDateRange } from "../../../../../helpers/date-management";
import { GroupFeedback } from "../../../../../models/GroupFeedback";
import { GroupLicenseTable } from "../../../../../models/GroupLicenseTable";
import { FeedbackDisplayType } from "../../../../../models/GroupSettings";
import { green, lightGreen, lightRed, red, yellow } from "../../../../../styles/colors";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { StandardBarChartSimpleColor } from "../../../../charts/StandardBarChartSimpleColor";
import { ChartHeightTextBox, getGlobalEndDate, getGlobalStartDate, PrintButton } from "../../../../core/GlobalTimeChoice";
import { GlobalContext } from "../../../PortalPage";
import { PrintContext } from "../HitListDashboard";
import { useTranslation } from "react-i18next";

export const GroupFeedbackEntireChartsSimple = React.forwardRef(({ tableFeedbackData, groupsLicenseTableEntries }: { tableFeedbackData: GroupFeedback[], groupsLicenseTableEntries: GroupLicenseTable[] }, ref) => {

    useImperativeHandle(ref, () => ({
        updateStartDateFeedbackWithGroupsSimpleData(newVal: Date) {
            updateStartDateFeedbackWithGroupsSimpleData(newVal)
        },
        updateEndDateFeedbackWithGroupsSimpleData(newVal: Date) {
            updateEndDateFeedbackWithGroupsSimpleData(newVal)
        },
    }))
    const { maxChartItemCount }: { maxChartItemCount: number } = React.useContext(GlobalContext);

    React.useEffect(() => {
        updateStartDateFeedbackWithGroupsSimpleData(getGlobalStartDate())
        updateEndDateFeedbackWithGroupsSimpleData(getGlobalEndDate())
        updateFeedbackWithGroupsSimpleChart(getGlobalStartDate(), getGlobalEndDate(), tableFeedbackData);
    }, [maxChartItemCount]);

    const [feedbackEntireWithGroupsSimpleChartData, setFeedbackEntireWithGroupsSimpleChartData] = React.useState<any[]>([]);
    const [feedbackVeryNegativeWithGroupsSimpleChartData, setFeedbackVeryNegativeWithGroupsSimpleChartData] = React.useState<any[]>([]);
    const [feedbackNegativeWithGroupsSimpleChartData, setFeedbackNegativeWithGroupsSimpleChartData] = React.useState<any[]>([]);
    const [feedbackNeutralWithGroupsSimpleChartData, setFeedbackNeutralWithGroupsSimpleChartData] = React.useState<any[]>([]);
    const [feedbackPositiveWithGroupsSimpleChartData, setFeedbackPositiveWithGroupsSimpleChartData] = React.useState<any[]>([]);
    const [feedbackVeryPositiveWithGroupsSimpleChartData, setFeedbackVeryPositiveWithGroupsSimpleChartData] = React.useState<any[]>([]);
    const [startDateFeedbackWithGroupsSimple, setStartDateFeedbackWithGroupsSimple] = React.useState(subDays(new Date(), 10));
    const [endDateFeedbackWithGroupsSimple, setEndDateFeedbackWithGroupsSimple] = React.useState(new Date());

    const [feedbackWithGroupsType, setFeedbackWithGroupsType] = React.useState(FeedbackDisplayType.PositiveNegativeNeutral);
    const [chartHeightFeedbackWithGroupsSimple, setChartHeightFeedbackWithGroupsSimple] = React.useState(300);
    const {t} = useTranslation();

    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = React.useContext(PrintContext);

    const updateStartDateFeedbackWithGroupsSimpleData = (newVal: Date) => {
        if (!tableFeedbackData) return;
        setStartDateFeedbackWithGroupsSimple(newVal);
        updateFeedbackWithGroupsSimpleChart(newVal, endDateFeedbackWithGroupsSimple, tableFeedbackData);
    }

    const updateEndDateFeedbackWithGroupsSimpleData = (newVal: Date) => {
        if (!tableFeedbackData) return;
        setEndDateFeedbackWithGroupsSimple(newVal);
        updateFeedbackWithGroupsSimpleChart(startDateFeedbackWithGroupsSimple, newVal, tableFeedbackData);
    }

    const updateFeedbackWithGroupsSimpleChart = (startDate: Date, endDate: Date, tableFeedbackData: GroupFeedback[]) => {
        let feedbacks = tableFeedbackData.filter(x => new Date(x.createdTimestampString) >= startDate && new Date(x.createdTimestampString) <= endDate);
        let res = feedbacks.reduce((sumValue, newValue: GroupFeedback) => {
            let newItems = newValue.groupId
            if (newItems) {
                let notificationName = groupsLicenseTableEntries.find(x => x.id == newValue.groupId)?.notificationName;
                let entryExists = sumValue.find((x: any) => x.argumentField == notificationName);
                if (entryExists) {
                    entryExists.veryNegativeAmount += newValue.veryNegativeAmount;
                    entryExists.negativeAmount += newValue.negativeAmount;
                    entryExists.neutralAmount += newValue.neutralAmount;
                    entryExists.positiveAmount += newValue.positiveAmount;
                    entryExists.veryPositiveAmount += newValue.veryPositiveAmount;

                } else {
                    sumValue.push({ argumentField: notificationName, veryNegativeAmount: newValue.veryNegativeAmount, negativeAmount: newValue.negativeAmount, neutralAmount: newValue.neutralAmount, positiveAmount: newValue.positiveAmount, veryPositiveAmount: newValue.veryPositiveAmount });
                }
            }


            return sumValue;
        }, [] as any[]);

        if (feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative) {
            setFeedbackEntireWithGroupsSimpleChartData(res.map(x => ({ argumentFieldService: x.argumentField, valueFieldService: x.veryNegativeAmount + x.negativeAmount + x.neutralAmount + x.positiveAmount + x.veryPositiveAmount })).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount));
        } else {
            setFeedbackEntireWithGroupsSimpleChartData(res.map(x => ({ argumentFieldService: x.argumentField, valueFieldService: x.negativeAmount + x.neutralAmount + x.positiveAmount })).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount));
        }
        setFeedbackVeryNegativeWithGroupsSimpleChartData(res.map(x => ({ argumentFieldService: x.argumentField, valueFieldService: x.veryNegativeAmount })).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount));
        setFeedbackNegativeWithGroupsSimpleChartData(res.map(x => ({ argumentFieldService: x.argumentField, valueFieldService: x.negativeAmount })).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount));
        setFeedbackNeutralWithGroupsSimpleChartData(res.map(x => ({ argumentFieldService: x.argumentField, valueFieldService: x.neutralAmount })).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount));
        setFeedbackPositiveWithGroupsSimpleChartData(res.map(x => ({ argumentFieldService: x.argumentField, valueFieldService: x.positiveAmount })).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount));
        setFeedbackVeryPositiveWithGroupsSimpleChartData(res.map(x => ({ argumentFieldService: x.argumentField, valueFieldService: x.veryPositiveAmount })).sort((a, b) => a.valueFieldService - b.valueFieldService).slice(-maxChartItemCount));

    }

    const buildContent = () => {
        return (
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Feedback Analyse Gruppiert Einheiten</Typography>
                <FormControl component="fieldset" >
                    <FormLabel component="legend">Feedback Anzeige Art</FormLabel>
                    <RadioGroup
                        row
                        name="feedbackWithGroupsType"
                        value={feedbackWithGroupsType}
                        onChange={(event) => {
                            setFeedbackWithGroupsType(event.target.value as any);
                            updateFeedbackWithGroupsSimpleChart(startDateFeedbackWithGroupsSimple, endDateFeedbackWithGroupsSimple, tableFeedbackData);
                        }}
                    >
                        <FormControlLabel value={FeedbackDisplayType.PositiveNegativeNeutral} control={<Radio />} label="3 Feedback" />
                        <FormControlLabel value={FeedbackDisplayType.AdvancedWithVeryPostiveNegative} control={<Radio />} label="5 Feedback" />
                    </RadioGroup>
                </FormControl>
                <Grid container direction="row" spacing={1}>
                    <Grid item>
                        < DatePicker
                            disableFuture
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={t("serviceDocumentationTable.startDate")}
                            value={startDateFeedbackWithGroupsSimple}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                updateStartDateFeedbackWithGroupsSimpleData(newVal);
                            }}
                            format={"dd.MM.yyyy"}
                            
                        />
                    </Grid>
                    <Grid item>
                        < DatePicker
                            disableFuture
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={t("serviceDocumentationTable.endDate")}
                            value={endDateFeedbackWithGroupsSimple}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                updateEndDateFeedbackWithGroupsSimpleData(newVal);

                            }}
                            format={"dd.MM.yyyy"}
                            
                        />
                    </Grid>
                    <Grid item>
                        <ChartHeightTextBox chartHeight={chartHeightFeedbackWithGroupsSimple} setChartHeight={setChartHeightFeedbackWithGroupsSimple} />
                    </Grid>
                    <MaxChartItemCountInput />
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center' }} variant="h5" color={"primary"}>Feedback Gesamt/Nutzung pro Einheit</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <StandardBarChartSimpleColor color={blue[800]} dataSource={feedbackEntireWithGroupsSimpleChartData} name={"Feedback Alle Gesamt pro Einheit"} height={chartHeightFeedbackWithGroupsSimple} />
                    </Grid>
                    {feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: 'center' }} variant="h5" color={"primary"}>Sehr Negativ Feedback pro Einheit Gesamt</Typography>
                        </Grid>
                    }
                    {feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
                        <Grid item xs={12}>
                            <StandardBarChartSimpleColor color={red} dataSource={feedbackVeryNegativeWithGroupsSimpleChartData} name={"Sehr Negativ Feedback pro Einheit"} height={chartHeightFeedbackWithGroupsSimple} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center' }} variant="h5" color={"primary"}>Negativ Feedback pro Einheit Gesamt</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <StandardBarChartSimpleColor color={feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? lightRed : red} dataSource={feedbackNegativeWithGroupsSimpleChartData} name={"Negativ Feedback pro Einheit Gesamt"} height={chartHeightFeedbackWithGroupsSimple} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center' }} variant="h5" color={"primary"}>Neutral Feedback pro Einheit Gesamt</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <StandardBarChartSimpleColor color={yellow} dataSource={feedbackNeutralWithGroupsSimpleChartData} name={"Neutral Feedback pro Einheit Gesamt"} height={chartHeightFeedbackWithGroupsSimple} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center' }} variant="h5" color={"primary"}>Positiv Feedback pro Einheit Gesamt</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <StandardBarChartSimpleColor color={feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? lightGreen : green} dataSource={feedbackPositiveWithGroupsSimpleChartData} name={"Positiv pro Einheit Gesamt"} height={chartHeightFeedbackWithGroupsSimple} />
                    </Grid>
                    {feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: 'center' }} variant="h5" color={"primary"}>Sehr Positiv Feedback pro Einheit Gesamt</Typography>
                        </Grid>
                    }
                    {feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
                        <Grid item xs={12}>
                            <StandardBarChartSimpleColor color={green} dataSource={feedbackVeryPositiveWithGroupsSimpleChartData} name={"Sehr Positiv  pro Einheit Gesamt"} height={chartHeightFeedbackWithGroupsSimple} />
                        </Grid>
                    }
                </Grid>

            </Paper>
        );
    }

    const buildPrintContent = () => {

        const titleDesc = `<b>Feedback Gesamt/Nutzung pro Einheit</b><br />${formatDateRange(startDateFeedbackWithGroupsSimple, endDateFeedbackWithGroupsSimple)}`;

        const titleDesc2 = `<b>Sehr Negativ Feedback pro Einheit Gesamt</b><br />${formatDateRange(startDateFeedbackWithGroupsSimple, endDateFeedbackWithGroupsSimple)}`;
        const titleDesc3 = `<b>Negativ Feedback pro Einheit Gesamt</b><br />${formatDateRange(startDateFeedbackWithGroupsSimple, endDateFeedbackWithGroupsSimple)}`;
        const titleDesc4 = `<b>Neutral Feedback pro Einheit Gesamt</b><br />${formatDateRange(startDateFeedbackWithGroupsSimple, endDateFeedbackWithGroupsSimple)}`;
        const titleDesc5 = `<b>Positiv Feedback pro Einheit Gesamt</b><br />${formatDateRange(startDateFeedbackWithGroupsSimple, endDateFeedbackWithGroupsSimple)}`;
        const titleDesc6 = `<b>Sehr Positiv Feedback pro Einheit Gesamt</b><br />${formatDateRange(startDateFeedbackWithGroupsSimple, endDateFeedbackWithGroupsSimple)}`;


        return (
            <>
                <PrintButton title="Feedback pro Einheit Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightFeedbackWithGroupsSimple} setChartHeight={setChartHeightFeedbackWithGroupsSimple} display={false} />
                    <Grid item xs={12}>
                        <StandardBarChartSimpleColor color={blue[800]} dataSource={feedbackEntireWithGroupsSimpleChartData} name={"Feedback Alle Gesamt pro Einheit"} height={chartHeightFeedbackWithGroupsSimple} title={titleDesc}/>
                    </Grid>
                    {feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
                        <Grid item xs={12}>
                            <StandardBarChartSimpleColor color={red} dataSource={feedbackVeryNegativeWithGroupsSimpleChartData} name={"Sehr Negativ Feedback pro Einheit"} height={chartHeightFeedbackWithGroupsSimple} title={titleDesc2}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <StandardBarChartSimpleColor color={feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? lightRed : red} dataSource={feedbackNegativeWithGroupsSimpleChartData} name={"Negativ Feedback pro Einheit Gesamt"} height={chartHeightFeedbackWithGroupsSimple} title={titleDesc3}/>
                    </Grid>
                    <Grid item xs={12}>
                        <StandardBarChartSimpleColor color={yellow} dataSource={feedbackNeutralWithGroupsSimpleChartData} name={"Neutral Feedback pro Einheit Gesamt"} height={chartHeightFeedbackWithGroupsSimple} title={titleDesc4}/>
                    </Grid>
                    <Grid item xs={12}>
                        <StandardBarChartSimpleColor color={feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? lightGreen : green} dataSource={feedbackPositiveWithGroupsSimpleChartData} name={"Positiv pro Einheit Gesamt"} height={chartHeightFeedbackWithGroupsSimple} title={titleDesc5}/>
                    </Grid>
                    {feedbackWithGroupsType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative &&
                        <Grid item xs={12}>
                            <StandardBarChartSimpleColor color={green} dataSource={feedbackVeryPositiveWithGroupsSimpleChartData} name={"Sehr Positiv  pro Einheit Gesamt"} height={chartHeightFeedbackWithGroupsSimple} title={titleDesc6}/>
                        </Grid>
                    }
                </div>
            </>

        )
    }

    if (!tableFeedbackData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )
})