import axios from 'axios';
import { USER_ID } from '../constants';
import { handleApiError, headers } from '../api';
import { UserGroupSettingsTemplate } from '../models/UserGroupSettingsTemplate';
import { getUserIdFromStorage } from '../helpers/common';

export const deleteUserGroupSettingsTemplate = async (id: string, token: string, enqueueSnackbar: any): Promise<UserGroupSettingsTemplate> => {
    try {

        const userId = getUserIdFromStorage();
        const ret = await axios.delete(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${id}`,
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } });

        enqueueSnackbar("Änderung wurde gespeichert", { variant: 'success' });

        return ret.data;
    } catch (err) {
        enqueueSnackbar("Vorlage konnte nicht gelöscht werden!", { variant: 'error' });
        handleApiError(err);
        return null as any;
    }
}