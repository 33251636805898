import { Grid, Paper } from "@mui/material";
import React from "react";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { ChartLabelType } from "../../../../charts/Base";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { ChartCalcType, TimeChoice } from "../../../../core/TimeChoice";
import { useTranslation } from "react-i18next";

export const GetGroupRatioMotionsMaterials = ({ data, dataField, allData, setChartData, childRef, loadingCompleted, childRefChart }
    : { data: any, dataField: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any }) => {
    const [chartChoice, setChartChoice] = React.useState('2');
    const {t} = useTranslation();

    const text = t("servicedocumentationChart.materialPerPieceForVisitors") // 80 Besucher benötigen 1 Rolle
    // 100 Besucher benötigen 1,25 Rollen

   // const text = "Besucher pro Material"; // Besucher / Material


    const buildGridItem = () => {

        switch (chartChoice) {
            case '0':
                return <LineChart
                    chartLabelType={ChartLabelType.Sum}
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    chartLabelType={ChartLabelType.Sum}
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    chartLabelType={ChartLabelType.Sum}
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} chartCalcType={ChartCalcType.RatioMotionMaterialConsumption} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}