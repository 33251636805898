import React from 'react';
import { UserInvoice } from '../../models/UserInvoice';
import { Grid as TableGrid, PagingPanel, Table, TableHeaderRow, SearchPanel, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SearchState, Sorting, SortingState } from '@devexpress/dx-react-grid';
import { Button, Grid, useTheme } from '@mui/material';
import { GetApp, InfoOutlined } from '@mui/icons-material';
import { getUserIdFromStorage } from '../../helpers/common';
import { UserInvoicesTableInvoiceDetailDialog } from './UserInvoicesTableInvoiceDetailDialog';
import { CurrencyHelper } from '../../helpers/CurrencyHelper';
import { formatEuropeanDate } from '../../helpers/date-management';
import { useTranslation } from 'react-i18next';

export const UserInvoicesTable = ({ rows, token }: { rows: UserInvoice[]; token: string }) => {

    const theme = useTheme();
    const userId = getUserIdFromStorage();

    const [searchValue, setSearchValue] = React.useState<string>();
    const [sorting, setSorting] = React.useState<Sorting[]>([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const {t} = useTranslation();

    const [selection, setSelection] = React.useState<UserInvoice>();
    const [detailDialogVisible, setDetailDialogVisible] = React.useState(false);

    return (
        <div>
            <TableGrid
                rows={rows}
                getRowId={(row) => row.number}
                columns={[
                    {
                        name: UserInvoice.Columns.number,
                        title: t("orders.orderNumber"),
                        getCellValue: (row: UserInvoice) => row.number
                    },
                    {
                        name: UserInvoice.Columns.date,
                        title: t("orders.date"),
                        getCellValue: (row: UserInvoice) => formatEuropeanDate(row.date),
                    },
                    {
                        name: UserInvoice.Columns.totalCost,
                        title: t("orders.total"),
                        getCellValue: (row: UserInvoice) => `€ ${CurrencyHelper.toCurrencyString(row.netTotal)}`
                    },
                    {
                        name: UserInvoice.Columns.actions,
                        title: ' ',
                        getCellValue: (row: UserInvoice) => (
                            <Grid container direction="row" spacing={1}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<InfoOutlined />}
                                        onClick={async () => {
                                            setSelection(row);
                                            setDetailDialogVisible(true);
                                        }}
                                    >
                                        {t("orders.details")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<GetApp />}
                                        target="_blank"
                                        href={`${process.env.REACT_APP_BASIC_URI}/users/${userId}/invoices/${row.id}?code=${process.env.REACT_APP_FUNCTIONS_KEY}&authorization=Bearer ${token}`}
                                    >
                                        {t("orders.invoice")}
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    },
                ]}
            >
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                />
                <IntegratedSorting />

                <SearchState
                    value={searchValue}
                    onValueChange={setSearchValue}
                />
                <IntegratedFiltering />

                <Table
                    columnExtensions={[
                        { columnName: UserInvoice.Columns.number, align: 'left' },
                        { columnName: UserInvoice.Columns.date, align: 'left' },
                        { columnName: UserInvoice.Columns.totalCost, align: 'left' },
                        { columnName: UserInvoice.Columns.actions, align: 'right' },
                    ]}
                />

                <TableHeaderRow />

                <Toolbar />
                <SearchPanel />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                />
                <PagingPanel
                    pageSizes={[5, 10, 15, 0]}
                />
            </TableGrid>

            <UserInvoicesTableInvoiceDetailDialog
                visible={detailDialogVisible}
                setVisible={setDetailDialogVisible}
                invoice={selection as UserInvoice}
            />
        </div>
    );
};
