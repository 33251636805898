import { useState } from 'react';
import Paper from '@mui/material/Paper';
import {
    ColumnChooser,
    Grid,
    PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    TableSelection,
    Toolbar,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { lightgrey } from '../../styles/colors';
import { ToiletTypeCell, toiletTypePredicate } from './components/Cells';
import React from 'react';
import { Group } from '../../models/Group';
import { buildCellAssignedColumn } from './GroupSelectionTable';
import { defaultGroupColumns, defaultGroupColumnsWidths } from './GroupSingleSelection';
import { useTranslation } from 'react-i18next';

const TableRow = (props: any) => {

    const {
        row,
        className,
        highlighted,
        selectByRowClick,
        onToggle,
        ...restProps
    } = props;
    const classes = useStyles();

    return (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            className={clsx(highlighted && [classes.selected], classes.customRow, className)}
            onClick={(e: any) => {
                if (!selectByRowClick) return;
                e.stopPropagation();
                onToggle();
            }}
        />
    );
};
const useStyles = makeStyles({
    selected: {
        backgroundColor: lightgrey
    },
    customRow: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: lightgrey
        }
    }
});

export const GroupCombinationSelection = ({ groups, selection, setSelection, assignedGroups = [] }: { groups: GroupLicenseTable[], selection?: any, setSelection?: any, assignedGroups?: string[] }) => {

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();
    let [columns, setColumns] = React.useState(defaultGroupColumns(t));




    let [columnWidths, setColumnWidths]: any = useState(defaultGroupColumnsWidths);


    React.useEffect(() => {
        let item = {
            name: "assigned",
            title: t("dashboard.assigned"),
            getCellValue: (row: GroupLicenseTable) => assignedGroups.includes(row.id) ? t("myUnitsSettings.yes") : t("myUnitsSettings.no"),
        };
        setColumns([item, ...columns]);
        setColumnWidths([{ columnName: 'assigned', width: 140 }, ...columnWidths]);

    }, []);



    const [integratedFilteringColumnExtensions] = useState([
        { columnName: 'toiletType', predicate: toiletTypePredicate },
    ]);
    const [pageSizes] = useState([5, 10, 15, 0]);

    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupLicenseTable } = props;
        if (column.name === 'toiletType') {
            return <ToiletTypeCell {...props} />;
        }
        if (column.name == "assigned") {
            return buildCellAssignedColumn(assignedGroups.includes(row.id), props);
        }

        return <Table.Cell {...props} />;
    };


    return (
        <Paper>
            <Grid
                getRowId={(row) => row.id}
                rows={groups}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />

                <SortingState
                    defaultSorting={[{ columnName: 'notificationName', direction: 'desc' }]}
                />
                <IntegratedSorting />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection}
                />
                <IntegratedPaging />
                <IntegratedSelection />

                <VirtualTable
                    height={1000}
                    cellComponent={Cell}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection
                    rowComponent={TableRow}
                    selectByRowClick
                    highlightRow
                    showSelectAll />
                <TableFilterRow
                />
                <PagingPanel
                    pageSizes={pageSizes}
                />
            </Grid>
        </Paper>
    );
};