import { GroupType } from "./Group";

export class DataFileTree {
    public fullPath !: string;
    public name !: string | null;
    public systemTimestamp!: string;
    public creationTimestamp: string = new Date().toDateString();
    public size: string = "3 Mb";
    public content!: DataFileTree[] | null;
    public type!: DataFileTreeType;
    public expanded : boolean = false;
    public bytes!: any;
    public selected : boolean = false;
    public contentType : string = "";
    public isShared !: boolean;
    public objectFolderId !: string;
    public static getSizeReadable = (Num: any =0, dec=2) =>{
        if (Num<1000) return Num+" Bytes";
        Num =("0".repeat((Num+="").length*2%3)+Num).match(/.{3}/g);
        return Number(Num[0])+"."+Num[1].substring(0,dec)+" "+"  kMGTPEZY"[Num.length]+"B";
        }

    constructor(fullPath: string,name: string, type: DataFileTreeType, content: DataFileTree[] | null, size = "3 Mb", creationTimestamp: string, contentType = "", isShared: boolean, objectFolderId : string){
        this.fullPath = fullPath;
        this.name = name;
        this.type = type;
        this.content = content;
        this.size = size;
        this.creationTimestamp = creationTimestamp;
        this.contentType = contentType;
        this.isShared = isShared;
        this.objectFolderId = objectFolderId;

    }


    static Columns = {
        id: 'id',
        creationTimestamp: 'creationTimestamp',
        userId: 'userId',
        version: 'version',
        ssid: 'ssid',
        availableDisplays: 'availableDisplays',
        availableSensors: 'availableSensors',
    };


}

export const getExtension = (str: string) => {
    return str.split(".").pop();
}

export enum DataFileTreeType {
    File = 0,
    Folder = 1,
    SharedFolder = 2
}
/*
export const DATA_FILE_TREE = [
    new DataFileTree("1","Folder1", DataFileTreeType.Folder,[new DataFileTree("2","Folder2", DataFileTreeType.Folder,[new DataFileTree("3","File2", DataFileTreeType.File, null),new DataFileTree("10","File1", DataFileTreeType.File, null)])]),
    new DataFileTree("6","Folder2", DataFileTreeType.Folder,[new DataFileTree("5","File2", DataFileTreeType.File, null)]),
]; */

export const DATA_FILE_TREE : DataFileTree[] = [];

export class ObjectFile {

    public filename : string = "";
    public folder : string = "";
    public folders : string[] = [];
    public contentLength : string = "0";
    public createdTimestamp : string = "";
    public contentType: string = "";
}

export class ObjectRenameFile {
    public filename : string = "";
    public path : string = "";
    public oldFullFilename = "";

    constructor (filename: string, path: string, oldFullFilename : string){
        this.filename = filename;
        this.path = path;
        this.oldFullFilename = oldFullFilename;
    }

}

export class InstructionFile {

    public filename : string = "";
    public folder : string = "";
    public createdTimestamp : number = 0;
    public uri: string = "";
    constructor (filename: string, folder: string, createdTimestamp: number, uri: string){
        this.filename = filename;
        this.folder = folder;
        this.createdTimestamp = createdTimestamp;
        this.uri = uri;
    }
}

export class ObjectSharedFolderTable {
    public id !: string;
    public systemTimestamp !: string;
    public userId!: string;
    public objectCreatorId !: string;
    public objectMemberIds !: string;
    public name !: string;
    public creationTimestamp !: string;

    static Columns = {
        id: 'id',
        creationTimestamp: 'creationTimestamp',
        userId: 'userId',
        objectCreatorId: 'objectCreatorId',
        objectMemberIds: 'objectMemberIds',
        name: 'name',
        tools: 'tools'
    };
}