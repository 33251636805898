import { Grid, Paper } from "@mui/material";
import React from "react";
import { GroupMotionChart } from "../../../../../helpers/charts/customerUsageGenerator";
import { deepCopy, deepCopyType } from "../../../../../helpers/general";
import { GroupLicenseTable } from "../../../../../models/GroupLicenseTable";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { ChartLabelType } from "../../../../charts/Base";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { ChartCalcType, TimeChoice } from "../../../../core/TimeChoice";
import { TimeChoiceNextGeneration } from "../../../../core/TimeChoiceNextGeneration";

export const FrequencyAvgPercentChart = ({ groups, data, dataField, allData, setChartData, childRef, loadingCompleted, childRefChart, title }
    : { groups: GroupLicenseTable[], data: GroupMotionChart[], dataField: any, allData: GroupMotionChart[], setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any, title?: string }) => {
    const [chartChoice, setChartChoice] = React.useState('1');

    title = "Besucherauslastung über Zeitraum in % pro Einheit";
    const newData = React.useMemo(() => {
        let curData = deepCopyType<GroupMotionChart[]>(data).reduce((prevVal, curVal) => {
            let existVal = prevVal.find(x => x.groupId == curVal.groupId && x.dateDisplay == curVal.dateDisplay);
            if (existVal) {
                existVal.blockCount = existVal.blockCount + curVal.blockCount;
                existVal[existVal.groupNotificationName] = existVal.blockCount;
            } else {
                let group = groups.find(x => x.id == curVal.groupId);
                curVal.groupNotificationName = group?.notificationName || "";
                curVal[curVal.groupNotificationName] = curVal.blockCount;
                prevVal.push(curVal);
            }
            return prevVal;
        }, [] as GroupMotionChart[]);

        return curData.map((mainItem => {
            let sum = curData.reduce((prevVal, curVal) => {
                if (curVal.groupId == mainItem.groupId) {
                    return prevVal + curVal.blockCount;
                }
                return prevVal;
            }, 0);
            mainItem["sum"] = sum;
            mainItem[mainItem.groupNotificationName] = Math.round((mainItem.blockCount / sum) * 100);
            return mainItem;
        }));
    }, [data]);



    const dataFields = React.useMemo(() => {
        return [...new Set(newData.map((x) => {
            return x.groupNotificationName;
        }))];
    }, [data]);


    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <LineChart
                    chartLabelType={ChartLabelType.Percent}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataFields}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    chartLabelType={ChartLabelType.Percent}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataFields}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    chartLabelType={ChartLabelType.Percent}
                    childRefChart={childRefChart}
                    data={newData}
                    dataField={dataFields}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoiceNextGeneration ref={childRef} fields={dataField} data={allData} setChartData={setChartData} chartCalcType={ChartCalcType.Nothing} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}