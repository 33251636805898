import axios from 'axios';
import { getUserIdFromStorage } from '../../helpers/common';
import { LoraGroupMotion } from '../../models/LoraGroupMotion';
import { GROUP_ID } from '../../constants';
import { handleApiError, headers } from '../../api';


export const getLastLoraMotionByGroup = async (token: string): Promise<LoraGroupMotion> => {
    try {
        const userId = getUserIdFromStorage();
        let groupId = localStorage.getItem(GROUP_ID);
        if (!groupId){
            return null as any;
        }
        
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/groups/${groupId}/users/${userId}/last-lora-motion`, {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err, null, "");
    }
    return null as any;
}
