import React from 'react';
import TextField from '@mui/material/TextField';
import { getApiToken } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { UserAccount } from '../../../models/UserAccount';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Container, Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Email, ExpandMore, FileDownload, FileOpen, Folder, Save, VpnKey } from '@mui/icons-material';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { StringHelper } from '../../../helpers/string-helper';
import { getUserIdFromStorage } from '../../../helpers/common';
import { AzureStorageBrowserModel } from '../../../models/AzureStorageBrowserModel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { formatUnixSecTimestampEuropeanDateInMinutes } from '../../../helpers/date-management';
import { InstructionFile } from '../../../models/DataFileTree';
import { UserGroupSettingsTemplate } from '../../../models/UserGroupSettingsTemplate';
import { GroupSettingFieldsUseStyles } from '../../../styles/GroupSettingFieldsStyles';
export const Instructions = () => {

    const classes = GroupSettingFieldsUseStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [newsList, setNewsList] = React.useState<AzureStorageBrowserModel[]>([]);
    const [folders, setFolders] = React.useState<string[]>([]);
    const [files, setFiles] = React.useState<InstructionFile[]>([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            let newNews = await api.GetInstructions();
            let instructions = newNews.map(x => {
                let [folder, filename] = x.fileName.split("/");
                let instruction = new InstructionFile(filename, folder, x.unixTimestamp, x.uri);
                return instruction;
            });
            let folders = instructions.reduce((prevVal: string[], curVal: InstructionFile) => {
                if (prevVal.includes(curVal.folder)) {
                    return prevVal;
                }
                prevVal.push(curVal.folder);
                return prevVal;
            }, [])
            setFiles(instructions);
            setFolders(folders);
            setLoading(false);
        })();
    }, []);

    return (
        <Container maxWidth="lg">
            <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                <Grid item>
                    <Typography component="h3" variant="h3">Anleitungen</Typography>
                </Grid>

                {loading &&
                    <Grid item>
                        <CircularProgress size={64} />
                    </Grid>
                }
                {folders.sort((a, b) => (a > b ? 1 : (a === b ? 0 : -1))).map((el) => {
                    return (
                        <Grid item>
                            <Accordion className={classes.hover}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <Folder color='primary' fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{el}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List component="nav" aria-label="main mailbox folders">
                                        {files.filter(x => x.folder == el).sort((a, b) => (a > b ? 1 : (a === b ? 0 : -1))).map((el) => {
                                            return (
                                                <React.Fragment>
                                                    <ListItem style={{ height: 100 }} button onClick={() => {
                                                        window.open(el.uri, '_blank');
                                                    }}>
                                                        <ListItemIcon color='primary'>
                                                            <FileOpen />
                                                        </ListItemIcon>
                                                        <Typography color="primary" variant="h5">{el.filename}</Typography>
                                                        <Typography style={{ marginLeft: 'auto' }} color="primary" variant="h6">Erstellt am: {formatUnixSecTimestampEuropeanDateInMinutes(el.createdTimestamp)}</Typography>
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                                            )
                                        })}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    );
}