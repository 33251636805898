import axios from 'axios';
import { headers } from '../api';
import { GroupLicense, GroupLicensePatch } from '../models/GroupLicense';


export const patchGroupLicenseAsync = async (groupLicensePatch: GroupLicensePatch,token: string,enqueueSnackbar:any) : Promise<GroupLicense> => {
    try {
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/users/${groupLicensePatch.userId}/licenses/${groupLicensePatch.id}/admin`,
        {...groupLicensePatch},
        {headers: {...headers,'Authorization': `Bearer ${token}` }})
        enqueueSnackbar("Lizenz speichern erfolgreich",{variant:'success'});
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err,null,2));
        enqueueSnackbar("Lizenz Speichern error",{variant:'error'});
    }
    return null as any;
}