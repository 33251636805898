import axios from 'axios';
import { headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { BssdRegisteredDeviceModel } from '../../models/bssd/BssdRegisteredDeviceModel';
import { SsdDeviceModel } from '../../models/bssd/SsdDeviceModel';
import { GroupCleanerFeedback } from '../../models/GroupCleanerFeedback';
import { HubModel } from '../../models/HubModel';

export const getBssdRegisteredDevices = async (token: string): Promise<Array<BssdRegisteredDeviceModel>> => {

    const userId = getUserIdFromStorage();

    try {
        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/bssd-registered-devices`,{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        return [];
    }
}