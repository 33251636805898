import makeStyles from '@mui/styles/makeStyles';

export const useNetWorkImageStyles = makeStyles((theme) => ({
  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  alt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }
}));
