import { DurationOfObjectSession, ObjectCleaningTrigger } from "./ObjectCleaningTrigger";
import { UserServiceWorkerObjectSession } from "./UserServiceWorkerObjectSession";

export enum ObjectCleaningTriggerInTimeState
{
    NotDefined = 0,
    Done = 1,
    Missed = 2
}
export class ObjectCleaningTriggerForExcel {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public objectId!: string;
    public userId!: string;
    public createdTimestamp!: string;
    public objectScheduleStart!: string;
    public deviationTime!: string;
    public triggerMoment!: string;
    public reactionTimeMinutes!: number;
    public objectCleaningTriggerInTimeState!: ObjectCleaningTriggerInTimeState;

    public isAllServiceWorkersMissing : boolean = false;
    public isNotEnoughServiceWorkerSessions : boolean = false;
    public minimumTime !: string;
    public minimumServiceWorker !: number;
    public durationLoggedIn !: string;
    public diffTime !: string;
    public session !: ServiceWorkerObjectCleanerTriggerSession | undefined;
    public isDurationOfSessionTooShort !: boolean;
    public istCountServiceWorker !: number;

    public static CreateInstance (objectCleaningTrigger : ObjectCleaningTrigger, durationOfObjectSession: DurationOfObjectSession | undefined){
        let newInstance = new ObjectCleaningTriggerForExcel();
        newInstance.createdTimestamp = objectCleaningTrigger.createdTimestamp;
        newInstance.session = durationOfObjectSession?.session ?? undefined;
        newInstance.isAllServiceWorkersMissing = objectCleaningTrigger.isAllServiceWorkersMissing;
        newInstance.isNotEnoughServiceWorkerSessions = objectCleaningTrigger.isNotEnoughServiceWorkerSessions;
        newInstance.minimumServiceWorker = objectCleaningTrigger.minimums.minimumServiceWorker;
        newInstance.isDurationOfSessionTooShort = durationOfObjectSession?.isDurationOfSessionTooShort ?? true;
        newInstance.diffTime = durationOfObjectSession?.diffTime ?? "";
        newInstance.durationLoggedIn = durationOfObjectSession?.durationLoggedIn ?? "";
        newInstance.istCountServiceWorker = objectCleaningTrigger.minimums.durationOfObjectSessions.length;
        newInstance.minimumTime = objectCleaningTrigger.minimums.minimumTime;
        newInstance.objectScheduleStart = objectCleaningTrigger.objectScheduleStart;
        newInstance.triggerMoment = objectCleaningTrigger.triggerMoment;
        newInstance.reactionTimeMinutes = objectCleaningTrigger.reactionTimeMinutes;
        newInstance.objectId= objectCleaningTrigger.objectId;
        newInstance.objectCleaningTriggerInTimeState = objectCleaningTrigger.objectCleaningTriggerInTimeState;
        return newInstance;

    }


    
    static Columns = {
        objectId: 'objectId',
        userId: "userId",
        cleaningTriggerType: "cleaningTriggerType",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        objectScheduleStart: "objectScheduleStart",
        objectScheduleStartTime: "objectScheduleStartTime",
        deviationTime: "deviationTime",
        triggerMoment: "triggerMoment",
        reactionTimeMinutes: "reactionTimeMinutes",


        isAllServiceWorkersMissing : "isAllServiceWorkersMissing",
        isNotEnoughServiceWorkerSessions : "isNotEnoughServiceWorkerSessions",
        objectCleaningTriggerInTimeState: 'objectCleaningTriggerInTimeState',
        minimumTime: 'minimumTime',
        minimumServiceWorker: 'minimumServiceWorker',
        minimumServiceWorker2: 'minimumServiceWorker2',
        minimumServiceWorker3: 'minimumServiceWorker3',

        durationOfObjectSessions: 'durationOfObjectSessions',
        sessions: 'sessions',
        serviceWorker: 'serviceWorker',
        tooShort: 'tooShort',
        sincePresence: 'sincePresence',
        minimumDuration: 'minimumDuration',
        diffText: 'diffText',
        signInAt: 'signInAt',
        signOutAt: 'signOutAt',


    }
    static GetLabelByInTimeStateType = (type: ObjectCleaningTriggerInTimeState) => ObjectCleaningTriggerForExcel.AvailableInTimeStateTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
    static AvailableInTimeStateTypes = [
        
        { value: ObjectCleaningTriggerInTimeState.NotDefined, label: 'Noch nicht festgelegt. Warte bis 00:00' },
        { value: ObjectCleaningTriggerInTimeState.Done, label: 'Ja, erledigt' },
        { value: ObjectCleaningTriggerInTimeState.Missed, label: 'Nein, verpasst' },
    ];
}
export interface ServiceWorkerObjectCleanerTriggerSession {
    objectId : string;
    userId : string;
    serviceWorkerId : string;
    signedInObjectSinceTimestamp : number;
    signedOutOfObjectSinceTimestamp : number;
    creationTimestamp : number;
}

