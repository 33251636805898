import { CircularProgress, Dialog, DialogContent, Grid, Theme, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const LoadingDialog = ({ visible, setVisible, text }: { visible: boolean, setVisible: any, text: string }) => {

    const classes = useStyles();
    const onClose = () => setVisible(false);

    return (
        <Dialog fullWidth maxWidth="xs" open={visible} onClose={onClose}>
            <DialogContent className={classes.dialogContent}>
                <Grid container direction="column">
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{text}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));