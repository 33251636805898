import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { useHistory } from 'react-router-dom';
import { Add, Cancel, Check, Save, Wc } from '@mui/icons-material';
import { DialogActions, DialogContent, Grid } from '@mui/material';

import { ReactComponent as SMIcon01 } from '../../assets/icons/tasks/sm_icon-01.svg';
import { ReactComponent as SMIcon02 } from '../../assets/icons/tasks/sm_icon-02.svg';
import { ReactComponent as SMIcon03 } from '../assets/icons/tasks/sm_icon-03.svg';
import { ReactComponent as SMIcon04 } from '../../assets/icons/tasks/sm_icon-04.svg';
import { ReactComponent as SMIcon05 } from '../../assets/icons/tasks/sm_icon-05.svg';
import { ReactComponent as SMIcon06 } from '../../assets/icons/tasks/sm_icon-06.svg';
import { ReactComponent as SMIcon07 } from '../../assets/icons/tasks/sm_icon-07.svg';
import { ReactComponent as SMIcon08 } from '../../assets/icons/tasks/sm_icon-08.svg';
import { ReactComponent as SMIcon09 } from '../../assets/icons/tasks/sm_icon-09.svg';
import { ReactComponent as SMIcon10 } from '../../assets/icons/tasks/sm_icon-10.svg';
import { ReactComponent as SMIcon11 } from '../../assets/icons/tasks/sm_icon-11.svg';
import { ReactComponent as SMIcon12 } from '../../assets/icons/tasks/sm_icon-12.svg';
import { ReactComponent as SMIcon13 } from '../../assets/icons/tasks/sm_icon-13.svg';
import { ReactComponent as SMIcon14 } from '../../assets/icons/tasks/sm_icon-14.svg';
import { ReactComponent as SMIcon15 } from '../../assets/icons/tasks/sm_icon-15.svg';
import { ReactComponent as SMIcon16 } from '../../assets/icons/tasks/sm_icon-16.svg';
import { ReactComponent as SMIcon17 } from '../../assets/icons/tasks/sm_icon-17.svg';
import { ReactComponent as SMIcon18 } from '../../assets/icons/tasks/sm_icon-18.svg';
import { ReactComponent as SMIcon19 } from '../../assets/icons/tasks/sm_icon-19.svg';
import { ReactComponent as SMIcon20 } from '../../assets/icons/tasks/sm_icon-20.svg';
import { ReactComponent as SMIcon21 } from '../../assets/icons/tasks/sm_icon-21.svg';
import { ReactComponent as SMIcon22 } from '../../assets/icons/tasks/sm_icon-22.svg';
import { ReactComponent as SMIcon23 } from '../../assets/icons/tasks/sm_icon-23.svg';
import { ReactComponent as SMIcon24 } from '../../assets/icons/tasks/sm_icon-24.svg';
import { ReactComponent as SMIcon25 } from '../../assets/icons/tasks/sm_icon-25.svg';
import { ReactComponent as SMIcon26 } from '../../assets/icons/tasks/sm_icon-26.svg';
import { ReactComponent as SMIcon27 } from '../../assets/icons/tasks/sm_icon-27.svg';
import { ReactComponent as SMIcon28 } from '../../assets/icons/tasks/sm_icon-28.svg';
import { ReactComponent as SMIcon29 } from '../../assets/icons/tasks/sm_icon-29.svg';
import { ReactComponent as SMIcon30 } from '../../assets/icons/tasks/sm_icon-30.svg';
import { ReactComponent as SMIcon31 } from '../../assets/icons/tasks/sm_icon-31.svg';
import { ReactComponent as SMIcon32 } from '../../assets/icons/tasks/sm_icon-32.svg';
import { ReactComponent as CheckMeNowIcon } from '../../assets/svgs/check-me-now_icon.svg';
import { GroupSettings, GroupSettingsIncidenName, GroupSettingsIncidenReport } from '../../../models/GroupSettings';
import { getDefaultIcon } from '../DefaultItemsDialogOld';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 800,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);



export const NameOfExceptionIncidentListDialog = ({row, incident,onClose, onChange, open} : {row: GroupSettings, incident: GroupSettingsIncidenReport,onClose: any, onChange: any, open: boolean}) => {



  const history = useHistory();

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  const handleToggle = (value: GroupSettingsIncidenName) => () => {

    if (incident.nameExceptionList.some(x => x == value.text)) {
      incident.nameExceptionList = incident.nameExceptionList.filter(x=>x != value.text);
    } else {
      incident.nameExceptionList.push(value.text);
    }
    console.log(incident);
    onChange();
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Ausgeblendete Leistungen Auswahl</DialogTitle>
      <DialogContent>
        <List className={classes.root}>
          {row.incidentReports?.incidentNames.map((value, index: number) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem key={index} role={undefined} button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={incident.nameExceptionList.some(x => x == value.text)}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.text} | ${value.price}€`} />
                <ListItemIcon>
                  {getDefaultIcon(value.text)}
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button size="large"
              startIcon={<Check />} variant={'contained'} onClick={async () => {
                handleClose();
              }} color="primary">
              Bestätigen
            </Button>
          </Grid>
        </Grid>

      </DialogActions>
    </Dialog>
  );
}