import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, FilterAlt, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { PropertySelectionDataTable } from './PropertySelectionDataTable';
import { useTranslation } from 'react-i18next';

export const FilterPropertyDialog = ({
  open,
  setOpen,
  groups,
  setGroups,
  properties,
  selectedProperties,
  setSelectedProperties
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable[],
  setGroups: any,
  properties: UserProperty[],
  selectedProperties: UserProperty[],
  setSelectedProperties: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selection, setSelection] = React.useState<string[]>([]);
  const [isExactFilteringEnabled, setIsExactFilteringEnabled] = React.useState(false);
  const {t} = useTranslation();

  const handleClose = () => {
    setOpen(false);
  }

  const disabled = selectedProperties.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Filter Einheiten nach Merkmalen</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <PropertySelectionDataTable rows={properties} groups={groups} selectedProperties={selectedProperties}
          onSelectionChange={(selection: string[]) => {
            setSelectedProperties(properties.filter(x => selection.some(y => x.id == y)));
            setSelection(selection);
          }}
          currentGroup={null} />
      </DialogContent>

      <DialogActions>
        <FormControlLabel
          control={<Checkbox
            checked={isExactFilteringEnabled}
            onChange={(event: any) => {
              setIsExactFilteringEnabled(event.target.value);
            }}
            name="isDeviceRestartTimeEnabled"
            color="primary" />}
          label="Exakte Filterung" />
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={disabled ? {} : { backgroundColor: errorColor, color: white }}
          endIcon={<Delete />}
          onClick={async () => {
            handleClose();
          }}
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          size="large"
          endIcon={<FilterAlt />}
          onClick={async () => {
            if (!isExactFilteringEnabled){
              setGroups(groups.filter(x => x.userPropertyIds.some(y => selection.some(z => z == y))));
            }else{
              setGroups(groups.filter(x => selection.every(selectedItem=> x.userPropertyIds.includes(selectedItem))));
            }
            handleClose();
          }}
        >
          Filtern
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));