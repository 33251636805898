export class LoraGroupMotionItemSingleValueDaily {
    day !: Date;
    total !: number;
    static Columns = {
        id: 'id',
        sensorId: "sensorId",
        sensorRoomName: "sensorRoomName",
        blockCount: "blockCount",
        motionCount: "motionCount",
        sentBySensorTimestamp: "sentBySensorTimestamp",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        rssi: "rssi",
        snr : "snr",
        seqno: "seqno",
        macAddressGateway : "macAddressGateway",
        activity: "activity",
        fcnt: "fcnt",
        humidity: "humidity",
        temperature: "temperature",
        toa: "toa",
        motionEvent: "motionEvent",
        pushEvent: "pushEvent",
        incident: "incident",
        sum : "sum",
        day: "day"

    }
}