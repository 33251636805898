import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, DialogActions, DialogContent, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, FolderCopy, Preview, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { OnlineStatusDashboard } from '../../portal/Dashboard/OnlineStatusDashboard';
import { GroupOnlineStatus } from '../../../../models/GroupOnlineStatus';
import { UserAccount } from '../../../../models/UserAccount';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { EvalApiType } from '../../portal/Dashboard/Services';
import { DrawerMenuItemDisplayType } from '../../ObjectMenuPointDrawer';
import { useTranslation } from 'react-i18next';

export const OnlineStatusAdminDialog = ({
  open,
  setOpen,
  groups,
  users
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable[],
  users: UserAccount[]
}) => {

  const history = useHistory();

  const [token, setToken] = React.useState("");
  const [onlineStatuse, setOnlineStatuse] = React.useState<GroupOnlineStatus[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const {t} = useTranslation();

  React.useEffect(() => {

    if (open) {
      (async () => {
        setLoading(true);
        const tokenTemp = getApiToken(history);
        setCount(users.length);
        let allOnlineStatus = await Promise.all(users.filter(x => !!x.id).map(async x => {
          let onlineStatus = await api.GetOnlineStatusByUserId(tokenTemp, x.id);
          setCount(prev => prev - 1);
          return onlineStatus;
        }));
        setLoading(false);
        setOnlineStatuse(allOnlineStatus.flatMap(x => x));

      })();
    }

  }, [open]);

  const handleClose = () => {
    setOnlineStatuse([]);
    setOpen(false);
  }


  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen
    >
      <DefaultDialogTitle
        title={<React.Fragment>Online Status für {users.length} users</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        {loading ?
          <>
            <CircularProgress size={128} />
            <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Noch {count} User Displays</Typography>
          </>
          : 
          
          onlineStatuse.length > 0 ? <OnlineStatusDashboard serviceDisplayType={EvalApiType.Groups} drawerDisplayType={DrawerMenuItemDisplayType.All} onlineStatuse={onlineStatuse} groups={groups} /> : <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Keine Online Status Daten</Typography>
        }
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));