import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { UserServiceWorkerObjectSession } from '../../../models/UserServiceWorkerObjectSession';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { lightgrey } from '../../../styles/colors';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { buildCellObject } from '../../authorized/extendedapp/serviceWorker/UserServiceWorkerManagementPageDataTable';
import { convertMinuteToHours, formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime, formatEuropeanTime } from '../../../helpers/date-management';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { Accessibility } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { saveExcelFile } from '../../../helpers/tables/common';
import { differenceInHours, differenceInMinutes, formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';
import { ApiOfflineDataContentModel } from '../../../models/ApiOfflineDataModel';
import { GroupSettings } from '../../../models/GroupSettings';

export const OfflineDataTable = ({ offlineData, groupSettings }
    : { offlineData: ApiOfflineDataContentModel[], groupSettings : GroupSettings }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `OfflineData-${groupSettings.locationInformation?.notificationName}-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let columns = [
        {
            name: ApiOfflineDataContentModel.Columns.name,
            title: 'Funktionsname',
            getCellValue: (row: ApiOfflineDataContentModel) => row.name,
        },
        {
            name: ApiOfflineDataContentModel.Columns.createdTimestamp,
            title: 'Erstellungsdatum',
            getCellValue: (row: ApiOfflineDataContentModel) => formatEuropeanDate(row.createdTimestamp),
        },        
        {
            name: ApiOfflineDataContentModel.Columns.createdTime,
            title: 'Erstellungszeit',
            getCellValue: (row: ApiOfflineDataContentModel) => formatEuropeanTime(row.createdTimestamp),
        },
    ];

    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: ApiOfflineDataContentModel.Columns.name, width: 350 },
        { columnName: ApiOfflineDataContentModel.Columns.createdTimestamp, width: 350 },
        { columnName: ApiOfflineDataContentModel.Columns.createdTime, width: 350 },
    ]);

    return (
        <Paper>
            <Grid
                rows={offlineData}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={offlineData}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};

const useStyles = makeStyles({
    selected: {
        backgroundColor: lightgrey
    },
    customRow: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: lightgrey
        }
    }
});