import React from 'react';
import { Button, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Product } from '../../../models/Product';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../helpers/storage-management';
import { Add, Downloading } from '@mui/icons-material';
import { ProductManagementPageEditingDialog } from '../ProductManagementPageEditingDialog';
import { ConfirmationDialog } from '../../dialogs/ConfirmationDialog';
import { api } from '../../../api';
import { useSnackbar } from 'notistack';
import { ProductManagementPageDataTable } from '../ProductManagementPageDataTable';
import { UserManagementPageDataTable } from './UserManagementPageDataTable';
import { UserAccount } from '../../../models/UserAccount';
import { UserManagementPageEditingDialog } from './UserManagementPageEditingDialog';
import { GroupLicenseTable, UserManagementGroups } from '../../../models/GroupLicenseTable';
import { StandardWorkItem, StandardWorkItemType } from '../../../models/StandardItem';
import { StandardItemEditingDialog } from './StandardItemEditingDialog';
import { StandardItemDataTable } from './StandardItemDataTable';
import { formatISO, sub } from 'date-fns';
import { DownloadFileImageDialog } from './DownloadFileImageDialog';
import { FileBlobType } from '../../../models/FileBlobType';

export const StandardItemAdminPage = ({ isAdminPage, filterByItemType, standardWorkItemType }: { isAdminPage: boolean, filterByItemType: boolean, standardWorkItemType?: StandardWorkItemType | undefined }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [standarditems, setStandardItems] = React.useState<StandardWorkItem[]>([]);
    const [addSettingDialogVisible, setAddSettingDialogVisible] = React.useState(false);
    const [updateSettingDialogVisible, setUpdateSettingDialogVisible] = React.useState(false);

    const [selectedStandardItem, setSelectedStandardItem] = React.useState<StandardWorkItem>();
    const [editSettingDialogVisible, setEditSettingDialogVisible] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [groups, setGroups] = React.useState<UserManagementGroups[]>([]);
    const [users, setUsers] = React.useState<UserAccount[]>([]);
    const [currentSelectedWorkingItem, setCurrentSelectedWorkingItem] = React.useState<StandardWorkItem>();
    const [showDownloadDialog, setShowDownloadDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);


    const loadData = async () => {
        const tempToken = getApiToken(history);
        if (!tempToken) return;

        setToken(tempToken);
        setLoading(true);

        if (isAdminPage) {
            if (!filterByItemType) {
                let standardItems = await api.GetAdminStandardWorkItemsByType(tempToken, StandardWorkItemType.Serivce);
                let standardItems2 = await api.GetAdminStandardWorkItemsByType(tempToken, StandardWorkItemType.Material);
                let standardItems3 = await api.GetAdminStandardWorkItemsByType(tempToken, StandardWorkItemType.Incident);
                let standardItems4 = await api.GetAdminStandardWorkItemsByType(tempToken, StandardWorkItemType.FeedbackQuestion);

                setStandardItems([...standardItems, ...standardItems2, ...standardItems3, ...standardItems4]);
            } else if (standardWorkItemType == StandardWorkItemType.RoomType) {
                let standardItems = await api.GetAdminStandardWorkItemsByType(tempToken, StandardWorkItemType.RoomType);
                setStandardItems(standardItems);
            }

        } else {
            if (!filterByItemType) {
                let standardItems1 = await api.GetStandardWorkItemsByType(tempToken, StandardWorkItemType.Serivce);
                let standardItems2 = await api.GetStandardWorkItemsByType(tempToken, StandardWorkItemType.Material);
                let standardItems3 = await api.GetStandardWorkItemsByType(tempToken, StandardWorkItemType.Incident);
                let standardItems4 = await api.GetStandardWorkItemsByType(tempToken, StandardWorkItemType.FeedbackQuestion);
                setStandardItems([...standardItems1.filter(x=>!x.addedFromAdmin), ...standardItems2.filter(x=>!x.addedFromAdmin), ...standardItems3.filter(x=>!x.addedFromAdmin), ...standardItems4.filter(x=>!x.addedFromAdmin)]);
            }else if (standardWorkItemType == StandardWorkItemType.Material){
                let standardItems2 = await api.GetStandardWorkItemsByType(tempToken, StandardWorkItemType.Material);
                setStandardItems(standardItems2.filter(x=>!x.addedFromAdmin).filter(x => x.itemType == standardWorkItemType));

            }

        }





        setLoading(false);

        const tempUsers = await api.getAllUsersInclusiveNonActivatedByAdmin(tempToken);
        setUsers(tempUsers);
    }

    React.useEffect(() => {

        (async () => {

            loadData();

        })();

    }, [history])

    const deleteStandardItem = async () => {
        if (!selectedStandardItem) return;
        if (isAdminPage) {
            await api.DeleteStandardItemAdminAsync(selectedStandardItem, token, enqueueSnackbar, subTitle);
        } else {
            await api.DeleteStandardItemAsync(selectedStandardItem, token, enqueueSnackbar, subTitle);
        }
    }

    const addStandardItem = async (standarditem: StandardWorkItem) => {
        if (isAdminPage) {
            const added = await api.createStandardItemAdmin(standarditem, token, enqueueSnackbar, subTitle);

        } else {
            const added = await api.createStandardItem(standarditem, token, enqueueSnackbar, subTitle);
        }
    }

    const onShowImages = (row: StandardWorkItem) => {
        setCurrentSelectedWorkingItem(row);
        setShowDownloadDialog(true);
    }

    const title = React.useMemo(() => {
        switch (standardWorkItemType) {
            case StandardWorkItemType.Material:
                return "Materialwirtschaft"
            case StandardWorkItemType.RoomType:
                return "Raumart"
            default:
                return "Standard Items"
        }
    }, [standardWorkItemType]);

    const subTitle = React.useMemo(() => {
        switch (standardWorkItemType) {
            case StandardWorkItemType.Material:
                return "Material"
            case StandardWorkItemType.RoomType:
                return "Raum"
            default:
                return "Standard Items"
        }
    }, [standardWorkItemType]);


    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    {title}
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={3}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={async () => {
                            setSelectedStandardItem(undefined);
                            setAddSettingDialogVisible(true);
                        }}
                    >
                        {subTitle} hinzufügen
                    </Button>
                </Grid>
            </Grid>
            {loading &&
                <Grid item xs={12}>
                    <CircularProgress size={64} />
                </Grid>
            }
            <Grid item xs={12}>
                <Paper>
                    <StandardItemDataTable users={users} rows={standarditems}
                        onShowImages={onShowImages}
                        onEdit={function (row: StandardWorkItem): any {
                            setSelectedStandardItem(row);
                            setUpdateSettingDialogVisible(true);
                        }} onDelete={(row: StandardWorkItem) => {
                            setSelectedStandardItem(row);
                            setDeleteConfirmationDialogVisible(true);
                        }} />
                </Paper>
            </Grid>

            <StandardItemEditingDialog
                subTitle={subTitle}
                standardWorkItemType={standardWorkItemType}
                onShowImages={onShowImages}
                visible={addSettingDialogVisible}
                setVisible={setAddSettingDialogVisible}
                currentStandardItem={selectedStandardItem}
                onSave={async (standarditem: StandardWorkItem) => {
                    standarditem.createdTimestamp = formatISO(new Date());
                    await addStandardItem(standarditem);
                    loadData();
                }}
            />

            <StandardItemEditingDialog
                standardWorkItemType={standardWorkItemType}
                subTitle={subTitle}
                onShowImages={onShowImages}
                visible={updateSettingDialogVisible}
                setVisible={setUpdateSettingDialogVisible}
                currentStandardItem={selectedStandardItem}
                onSave={async (row: StandardWorkItem) => {

                    await addStandardItem(row);
                    loadData();
                    //    const updated = await api.updateUserFromAdmin(user, token, enqueueSnackbar);

                    //    if (!updated) return;
                }}
            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title={`Sind Sie sicher, dieses ${subTitle} zu löschen ?`}
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {

                    await deleteStandardItem();
                    loadData();
                    setDeleteConfirmationDialogVisible(false);

                }}
            />
            {currentSelectedWorkingItem &&
                <DownloadFileImageDialog id={currentSelectedWorkingItem?.id} visible={showDownloadDialog}
                    setVisible={(visible: boolean) => {
                        setShowDownloadDialog(visible);
                        setCurrentSelectedWorkingItem(undefined);
                    }} incidentPhotos={currentSelectedWorkingItem?.photos} fileBlobType={FileBlobType.StandardItemPhoto}
                    title={StandardWorkItem.GetLabelByType(currentSelectedWorkingItem.itemType)} />
            }

        </Grid>
    );
};