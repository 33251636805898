import axios from "axios";
import { multipartHeaders } from "../api";
import { ObjectSharedFolderTable } from "../models/DataFileTree";

export const GetObjectSharedFolderTableByObjectIdAsync = async (objectId: string, token: string, enqueueSnackbar: any): Promise<ObjectSharedFolderTable[]> => {
    try {

        const ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/shared-folder-table`,  { headers: { ...multipartHeaders, 'Authorization': `Bearer ${token}` } });

        enqueueSnackbar("Objekt Daten Tabelle wurden geladen", { variant: 'success' });

        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Objekt Daten Tabelle konnten nicht geladen werden!", { variant: 'error' });
    }
    return null as any;
}
