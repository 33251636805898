import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { Add, Cancel, Chat, Check, CleaningServices, Login, LoginOutlined, Logout, LogoutOutlined, OpenInBrowser, Remove, RestartAlt, Warning } from '@mui/icons-material';
import { AppBar, Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, FormControl, FormControlLabel, FormLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup, TextField, Toolbar, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import { LAST_PUBLIC_FEEDBACK, LAST_PUBLIC_SERVICE, PUBLIC_CURRENT_USER_SERVICE_WORKER, PUBLIC_SERVICEWORKER_TOKEN, PUBLIC_USER_ID } from '../../constants';
import CheckMeNowIcon from '../../assets/svgs/check-me-now_icon.svg'
import GreenbirdLogo from '../../assets/svgs/greenbird_logo.svg'
import cmnHardware from '../../assets/image/cmn.png';

import { ReactComponent as Smiles } from '../../assets/svgs/newSmiles.svg'
import { useQuery } from '../authorized/portal/checkout/ShopPage';
import { GroupSettingsLight } from '../../models/GroupSettingsExtended';
import { GroupFeedback } from '../../models/GroupFeedback';
import { getUnixTime, subHours } from 'date-fns';
import { primaryColor } from '../../App';
import { NetworkImageNonDialog } from '../images/NetworkImageNonDialog';
import { FeedbackDisplayType, FeedbackQuestion, GroupSettingsSmilesType, GroupSettingsIncidenReport, PublicFeedbackModeType, GroupSettingsIncidenName, GroupSettings, CleaningOperation, Sensor, ToiletType } from '../../models/GroupSettings';


import { getDefaultIcon } from '../dialogs/DefaultItemsDialogOld';
import { StandardWorkItem, StandardWorkItemType } from '../../models/StandardItem';
import { loadStandardItems, StandardItemIcon } from '../core/GroupSettingFieldsFeedbackControl';
import { UserAccount } from '../../models/UserAccount';
import { GroupServiceMode, GroupServiceModeIncidenName, GroupServiceModeIncident } from '../../models/GroupServiceMode';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { ServiceWorkerType, UserServiceWorker } from '../../models/UserServiceWorker';
import { PublicSession } from '../../models/Session';
import { getStorage, setStorage } from '../../helpers/storage-management';
import { formatEuropeanDateTime } from '../../helpers/date-management';
import { getGreenSmile, PreviewView, useStylesPublicFeedback } from './PublicFeedback';
import { UserServiceWorkerObjectSession } from '../../models/UserServiceWorkerObjectSession';
import { StringHelper } from '../../helpers/string-helper';

export enum PublicServiceRoute {
  Standard = 0,
  Service = 1,
  Finish = 2
}
export const PublicServiceContext = React.createContext<IPublicServiceContext>({ isServiceActive: PublicServiceRoute.Standard, setIsServiceActive: () => { } });



interface IPublicServiceContext {
  isServiceActive: PublicServiceRoute;
  setIsServiceActive: any;
}


export const RootPagePublicEasyService = () => {
  const classes = useStylesPublicFeedback();

  const [userAccount, setUserAccount] = React.useState<UserAccount>();
  const [source, setSource] = React.useState('');
  const [sourceUserLogo, setSourceUserLogo] = React.useState('');
  const [settings, setGroupSettings] = React.useState<GroupSettingsLight>();
  const [loading, setLoading] = React.useState(true);
  const [lastPublicService, setLastPublicService] = React.useState<Date>();
  const [isServiceActive, setIsServiceActive] = React.useState(PublicServiceRoute.Standard);

  const history = useHistory();

  const groupId = useQuery(history).get("groupId");
  const userId = useQuery(history).get("userId");

  const objectId = useQuery(history).get("objectId");

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      let settings = await api.getGroupSettingsLight(groupId || "", userId || "");
      setGroupSettings(settings);
      setSource(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/images/logos/${settings.logo?.fileName}`);
      const userAccount = await api.getLightUser(userId);
      setUserAccount(userAccount);
      setSourceUserLogo(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/logos/${userAccount.logo?.fileName}`);
      let date: Date = JSON.parse(localStorage.getItem(LAST_PUBLIC_SERVICE) || "null");
      if (date) {
        setLastPublicService(new Date(date));
      }
      setLoading(false);
    })()
  }, []);



  return (
    <PublicServiceContext.Provider value={{ isServiceActive: isServiceActive, setIsServiceActive: setIsServiceActive }}>
      <Dialog fullScreen open={true}>
        <DialogTitle className={classes.dialogTitle}>
          <AppBar position="sticky">
            <Toolbar>
              <Grid item>
                <img style={{ marginRight: 10 }} src={CheckMeNowIcon} height={60} alt="check-me-now-icon" />
              </Grid>
              <Grid item >
                <Typography className={classes.dialogTitleAppBarTitle} variant="h6">
                  Service
                </Typography>
              </Grid>
              <div style={{ flexGrow: 1 }}></div>
              <Grid item style={{ height: 50, textAlign: 'center' }}>
                {sourceUserLogo && userAccount?.logo?.fileName ?
                  <NetworkImageNonDialog hideOverlay={true} src={sourceUserLogo} minWidth={0} authorize={false} /> :
                  <img src={GreenbirdLogo} width={"100%"} alt="greenbird-logo" />
                }
              </Grid>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} style={{ backgroundColor: loading ? "white" : settings?.content?.publicDisplayBackgroundColor ? settings.content.publicDisplayBackgroundColor : primaryColor, padding: 0 }}>
          <Grid style={{ height: 100, position: "relative", textAlign: "center", marginTop: 10, marginBottom: 20 }}>
            {source && settings?.logo?.fileName ?
              <NetworkImageNonDialog hideOverlay={true} src={source} minWidth={0} authorize={false} /> :
              <img src={cmnHardware} height={100} alt="greenbird-logo" />
            }
          </Grid>
          {loading ? <PreviewView title='Service' /> : <PublicServiceLogin groupId={groupId || ""} objectId={(settings?.locationInformation?.objectUnitId ?? objectId) || ""} settings={settings || new GroupSettingsLight()} userId={userId || ""} />}
        </DialogContent>
        {isServiceActive != PublicServiceRoute.Service &&
          <DialogActions className={classes.dialogActions}>
            <Button
              fullWidth
              size='large'
              style={{ width: '100%' }}
              startIcon={<OpenInBrowser />}
              variant={'contained'}
              color="primary"
              onClick={async () => {
                window.open("https://" + settings?.content?.qrCodeUrl || "www.smart-service-display.com", '_self', 'noreferrer');
              }}
            >
              Mehr über uns erfahren
            </Button>
          </DialogActions>
        }
      </Dialog>
    </PublicServiceContext.Provider>
  );

}

export const CurrentSessionDisplay = ({ currentSession }: { currentSession: UserServiceWorkerObjectSession | undefined }) => {
  if (!currentSession) return <></>;
  return (
    <Grid item>
      <Typography style={{ textAlign: 'center' }}>{getUnixTime(currentSession.signedInObjectSinceTimestamp) != 0 && `Eingeloggt seit:${formatEuropeanDateTime(currentSession.signedInObjectSinceTimestamp)}`} <br />{getUnixTime(currentSession.signedOutOfObjectSinceTimestamp) != 0 && `Ausgeloggt seit:${formatEuropeanDateTime(currentSession.signedOutOfObjectSinceTimestamp)}`}</Typography>
    </Grid>
  )
}


export const PublicServiceLogin = ({ settings, objectId, groupId, userId }: { settings: GroupSettingsLight, objectId: string, groupId: string, userId: string }) => {

  const [staffNumber, setStaffNumber] = React.useState("");
  const [currentSession, setCurrentSession] = React.useState<UserServiceWorkerObjectSession>();
  const [endSession, setEndSession] = React.useState<UserServiceWorkerObjectSession>();

  const { enqueueSnackbar } = useSnackbar();
  

  const login = React.useCallback(async () => {
    let body = new UserServiceWorkerObjectSession();
    body.groupId = groupId;
    body.objectId = objectId;
    body.signedInObjectSinceTimestamp = new Date();
    body.userId = userId;
    body.staffNumber = staffNumber;
    let currentSession = await api.createServiceWorkerObjectSessionPublic(body, enqueueSnackbar);
    setEndSession(currentSession);

  }, [staffNumber]);

  const loginState = React.useMemo(() => {
    if (!currentSession) return "";
    if (getUnixTime(currentSession.signedOutOfObjectSinceTimestamp) != 0 ||
      StringHelper.IsNullOrWhiteSpace(currentSession.id) ||
      ((getUnixTime(currentSession.signedInObjectSinceTimestamp) == 0 && getUnixTime(currentSession.signedOutOfObjectSinceTimestamp) == 0))) {
      return "Einloggen";
    }

    return "Ausloggen";

  }, [currentSession])


  const checkLoginState = React.useCallback(async () => {

    let currentSession = await api.getActiveObjectSessionByStaffNumberAsync(userId, objectId, staffNumber, enqueueSnackbar);
    setCurrentSession(currentSession);

  }, [staffNumber]);



  if (endSession) {
    return (
      <FinishComponent text={`Danke für das ${loginState}`} currentSession={endSession} />
    )
  }



  return (
    <Grid container direction={"column"} spacing={3} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12} lg={12}>
        <TextField
          fullWidth
          id="filled-name"
          InputLabelProps={{ shrink: true }}
          label="Eingabe"
          variant="filled"
          value={staffNumber}
          onChange={(event: any) => {
            setStaffNumber(event.target.value);
          }}
        />
      </Grid>
      {currentSession &&
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<LoginOutlined />}
            onClick={() => {
              login();
            }}
          >
            {loginState}
          </Button>
        </Grid>
      }
      {!currentSession &&
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={staffNumber.length < 4}
            startIcon={<LoginOutlined />}
            onClick={() => {
              checkLoginState();
            }}
          >
            Login Status Abfragen
          </Button>
        </Grid>
      }
      <CurrentSessionDisplay currentSession={currentSession} />

    </Grid>
  )
}

const FinishComponent = ({ currentSession, text }: { currentSession: UserServiceWorkerObjectSession | undefined, text: string }) => {
  return (
    <Grid container spacing={3} direction={"column"} alignItems={"center"} justifyContent={"center"}>
      <Grid item>
        <Typography style={{ fontSize: 34, fontWeight: 'bold', textAlign: 'center' }}>{text} </Typography>
      </Grid>
      <Grid container item justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={6}>
          {getGreenSmile(undefined, () => { })}
        </Grid>
      </Grid>
      <Grid container item direction={"row"} spacing={3} justifyContent={"center"}>
        <Grid item xs={12}>
          <CurrentSessionDisplay currentSession={currentSession} />

        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            size='small'
            style={{ width: '100%' }}
            startIcon={<Check />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              window.location.reload();
            }}
          >
            Neuer Login
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}