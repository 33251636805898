import axios from 'axios';
import { getUserIdFromStorage } from '../../helpers/common';
import { headers } from '../../api';
import { Group } from '../../models/Group';

export const assignDeviceIdToGroup = async (deviceId: string,groupId: string, token: string, enqueueSnackbar: any): Promise<Group> => {
    const userId = getUserIdFromStorage();
    return await axios
        .patch(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/devices/${deviceId}`,
            { },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Device ID Zuweisung erfolgreich", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Device ID Zuweisung fehlgeschlagen", { variant: 'error' });
        });
}