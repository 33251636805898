import axios from "axios";
import { multipartHeaders } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupSettings, GroupSettingsType } from "../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../models/UserGroupSettingsTemplate";

export const updateGroupLogo = async (logo: File, groupSettings: GroupSettings | UserGroupSettingsTemplate, type: GroupSettingsType, token: string, enqueueSnackbar: any, setProgressData: any): Promise<GroupSettings> => {
    try {

        const userId = getUserIdFromStorage();

        const formData = new FormData();
        formData.append('body', logo);

        let uri: string = '';
        switch (type) {
            case GroupSettingsType.Template:
                uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${groupSettings.id}/images/logos`;
                break;
            default:
                uri = `${process.env.REACT_APP_BASIC_URI}/groups/${groupSettings.id}/users/${userId}/images/logos`;
                break;
        }

        const ret = await axios.post(uri, formData, {
            headers: { ...multipartHeaders, 'Authorization': `Bearer ${token}` }, onUploadProgress: progressEvent => {
                progressEvent.index = 1;
                setProgressData(progressEvent);
            }
        });
        enqueueSnackbar("Logo wurde erfolgreich hochgeladen", { variant: 'success' });

        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Logo kann nicht hochgeladen werden", { variant: 'error' });
    }
    return null as any;
}
