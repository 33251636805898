import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserAddress } from '../models/UserAddress';
import { PropertyType, UserProperty } from '../models/UserProperty';

export const getUserProperties = async (token: string): Promise<UserProperty[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/properties`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}

export const getUserPropertiesByType = async (token: string,  type: PropertyType): Promise<UserProperty[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/types/${type}/properties`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}