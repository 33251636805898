import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupServiceMode } from '../models/GroupServiceMode';


export const getGroupServiceModeByObjects = async (token: string, objectIds: string[]): Promise<Array<GroupServiceMode>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-mode-by-objects`,{stringArray: objectIds},
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}

export const getGroupServiceModeByServiceWorker = async (token: string, serviceWorkerIds: string[]): Promise<Array<GroupServiceMode>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-mode-by-service-workers`,{stringArray: serviceWorkerIds},
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}

export const getGroupServiceModeByGroupIdsAsync = async (token: string, groupIds: string[]): Promise<Array<GroupServiceMode>> => {

    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/service-mode-multiple?${getQueryParamFromApiDataAmountType()}`, groupIds, {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return [] as any;
};
