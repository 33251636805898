import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Button } from "@mui/material";
import { ShoppingCart } from "@mui/icons-material";
import { mapLabelUserType } from "../../../helpers/common";
import { addToDate, convertTimespanToDuration, diffDays, formatEuropeanDate } from "../../../helpers/date-management";
import { mapToiletTypeKey } from "../Base";

export const ValidUntilCell = (props: any) => {
    const { style, row, ...restProps } = props;
    let val = diffDays(addToDate(new Date(row.purchaseTimestamp), row.duration), new Date());
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            {val < 0 ? `abgelaufen` : `Noch ${val} Tage gültig`}
        </Table.Cell>
    )
};

export const DurationCell = (props: any) => {
    const { style, value, ...restProps } = props;
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            {`${convertTimespanToDuration(value).days} Tage`}
        </Table.Cell>
    )
};

export const PurchaseCell = (props: any) => {
    const { style, row, ...restProps } = props;
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            <Button
                variant="contained"
                color="primary"
                endIcon={<ShoppingCart></ShoppingCart>}
            >Lizenz erweitern</Button>
        </Table.Cell>
    )
};

export const PurchaseTimestampCell = (props: any) => {
    const { style, value, ...restProps } = props;
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            {formatEuropeanDate(value)}
        </Table.Cell>
    )
};

export const ToiletTypeCell = ({ value, style, ...restProps }: any) => {
    return (
        <Table.Cell
            {...restProps}
        >
            {mapToiletTypeKey(value)}
        </Table.Cell>
    );
};

export const UserTypeCell = ({ value, style, ...restProps }: any) => {
    return (
        <Table.Cell
            {...restProps}
        >
            {mapLabelUserType(value)}
        </Table.Cell>
    );
};

const toLowerCase = (value: any) => String(value).toLowerCase();
export const toiletTypePredicate = (value: any, filter: any) => {
    return toLowerCase(mapToiletTypeKey(value)).startsWith(toLowerCase(filter.value));
};

export const userTypePredicate = (value: any, filter: any) => {
    return toLowerCase(mapLabelUserType(value)).startsWith(toLowerCase(filter.value));
};
