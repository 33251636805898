import axios from 'axios';
import { handleApiError, headers } from '../api';
import { StripeOrderDetails } from "../models/StripeOrderDetails";
import { getUserIdFromStorage } from '../helpers/common';
import { InvoicePaymentOrderDetails } from '../models/InvoicePaymentOrderDetails';

export const getInvoiceTransactionAsync = async (model: InvoicePaymentOrderDetails, token: string): Promise<InvoicePaymentOrderDetails> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/order-by-invoice`,
            { ...model },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return null;
        });
};
