import React, { useCallback, useRef, useState } from 'react';
import { Column, CustomSummary, DataTypeProvider, FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, IntegratedSummary, PagingState, SelectionState, SortingState, SummaryState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, TableSummaryRow, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GroupFeedbackQuestion } from '../../../../../models/GroupFeedbackQuestion';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { formatEuropeanDate, formatEuropeanTime } from '../../../../../helpers/date-management';
import { buildCellStringArray, useAccordionForStringArrayStyles } from '../../../../common/AccordionForStringArray';
import { GroupIncidentVisitor } from '../../../../../models/GroupIncidentVisitor';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ExpandMore, Warning } from '@mui/icons-material';
import { Grid as MuiGrid } from '@mui/material';
import { eachDayOfInterval, format, isAfter, isBefore, isSameDay } from 'date-fns';
import { GroupSettingsIncidenReport } from '../../../../../models/GroupSettings';
import { GroupPayPerService } from '../../../../../models/GroupPayPerService';

const CurrencyFormatter = ({ value }: { value: any }) => (
    value.toLocaleString('de', { style: 'currency', currency: 'EUR' })
);

const CurrencyTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
    />
);


export const AnalyticSummaryIncidentVisitorDataTable = ({ rows, groupPayPerService, groups, startDate, endDate, selectedEntries, onSelectionChange, }
    : { rows: GroupSettingsIncidenReport[], groupPayPerService: GroupPayPerService[], groups: GroupLicenseTable[], startDate: Date, endDate: Date, selectedEntries: GroupPayPerService[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);
    const classes = useAccordionForStringArrayStyles();

    const [totalSummaryItems, setTotalSummaryItems] = useState<any[]>([]);
    const [currencyColumns, setCurrencyColumns] = useState<any[]>([]);

    let days = React.useMemo(() => {
        return eachDayOfInterval({ start: startDate, end: endDate });
    }, [startDate, endDate]);


    React.useEffect(() => {

        let initialEntry = {
            name: GroupSettingsIncidenReport.Columns.incident,
            title: "Raum",
            getCellValue: (row: GroupSettingsIncidenReport) => {
                let myGroup = groups.find(x => x.id == row.groupId);
                return `${myGroup?.notificationName}: ${row.text}`;
            },
        };
        let dateColumns = days.map(day => {
            let formatDay = format(day, "dd.MM EEEEEE");
            return {
                name: formatDay,
                title: formatDay,
                getCellValue: (row: GroupSettingsIncidenReport) => {
                    let items = groupPayPerService.filter(x => isSameDay(day, new Date(x.createdTimestampString))).filter(x => x.groupId == row.groupId).flatMap(x => x.items);
                    let results = items.filter(x => x.roomText == row.text);
                    return results.flatMap(x => x.serviceNames).map(x => x.text).join(',')
                }
            };
        });

        let sumEntry = {
            name: GroupSettingsIncidenReport.Columns.sum,
            title: "Summe",
            getCellValue: (row: GroupSettingsIncidenReport) => {
                let items = groupPayPerService.filter(x => isBefore(new Date(x.createdTimestampString), endDate) && isAfter(new Date(x.createdTimestampString), startDate)).filter(x => x.groupId == row.groupId).flatMap(x => x.items);
                let results = items.filter(x => x.roomText == row.text);
                return results.flatMap(x => x.serviceNames).reduce((prevVal, curVal) => prevVal + curVal.price, 0);
            },
        };

        dateColumns = [initialEntry, ...dateColumns, sumEntry as any];

        let dateColumnsWidth = dateColumns.map(el => {
            if (el.name == GroupSettingsIncidenReport.Columns.incident || el.name == GroupIncidentVisitor.Columns.customTexts) {
                return { columnName: el.name, width: 450 } as TableColumnWidthInfo;
            }
            return { columnName: el.name, width: 180 } as TableColumnWidthInfo;
        });
        setColumns([...dateColumns]);
        setColumnWidths([...dateColumnsWidth]);
        setTotalSummaryItems([
            { columnName: GroupSettingsIncidenReport.Columns.incident, type: 'count' },
            { columnName: GroupSettingsIncidenReport.Columns.sum, type: 'max' },
            { columnName: GroupSettingsIncidenReport.Columns.sum, type: 'sum' },
        ]);
        setCurrencyColumns([GroupSettingsIncidenReport.Columns.sum]);
    }, [startDate, endDate, rows])


    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupIncidentVisitor } = props;

        return <Table.Cell {...props} />;
    };


    let [columns, setColumns] = React.useState<Column[]>([]);


    let [columnWidths, setColumnWidths] = React.useState<TableColumnWidthInfo[]>(columns.map(el => {
        if (el.name == GroupIncidentVisitor.Columns.items || el.name == GroupIncidentVisitor.Columns.customTexts) {
            return { columnName: el.name, width: 450 } as TableColumnWidthInfo;
        }
        return { columnName: el.name, width: 180 } as TableColumnWidthInfo;
    }));

    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Leistung - PayPerService Kalender');
        });
    };


    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <CurrencyTypeProvider
                    for={currencyColumns}
                />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <IntegratedSummary />

                <VirtualTable
                    height={800}
                    cellComponent={Cell}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                />
                <TableHeaderRow />
                <TableSummaryRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
                totalSummaryItems={totalSummaryItems}
            />
        </div>
    );
};
