import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Divider, Fab, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, FilterAlt, VisibilityOff } from "@mui/icons-material";
import { GroupSettings, GroupSettingsIncidenName, GroupSettingsIncidenReport, IncidentModeType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { errorColor, white } from "../../styles/colors";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { ReactComponent as AlertSvg } from '../../assets/svgs/alert.svg';
import { useContainerStyles } from "../../styles/container";
import { Group, GroupType } from "../../models/Group";
import { StandardWorkItem, StandardWorkItemType } from "../../models/StandardItem";
import { api } from "../../api";
import { FileBlobType } from "../../models/FileBlobType";
import { useSnackbar } from "notistack";
import DefaultItemsDialogOld, { DefaultItemsType, getDefaultIcon } from "../dialogs/DefaultItemsDialogOld";
import DefaultItemsDialog from "../dialogs/DefaultItemsDialog";
import { LocalMedia } from "../images/NetworkMedia";
import { NameOfExceptionIncidentListDialog } from "../dialogs/groupSettings/NameOfExceptionIncidentListDialog";
import { GroupSettingsMappingKeys } from "../../helpers/group-settings-management";
import { PublicFeedbackModeComponent } from "./GroupSettingFieldsFeedbackControl";
import { TextFieldGroupSettings } from "./components/groupSettings/TextFieldGroupSettings";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const GroupSettingFieldsIncidenReports = ({ row, onChange, group, token }
    : { row: GroupSettings | UserGroupSettingsTemplate; onChange: any; group: Group | undefined; token: any }) => {

    const classes = GroupSettingFieldsUseStyles();
    const containerStyles = useContainerStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [currentIncident, setCurrentIncident] = React.useState<GroupSettingsIncidenReport>();
    const [isNameOfExceptionIncidentListDialogVisible, setIsNameOfExceptionIncidentListDialogVisible] = React.useState(false);

    const {t} = useTranslation();

    const [standardIncidents, setStandardIncidents] = React.useState<StandardWorkItem[]>([]);
    return (
        <Accordion className={classes.hover} onChange={async (event, expanded) => {
            if (expanded && standardIncidents.length < 1) {
                let res = await api.GetStandardWorkItemsByType(token, StandardWorkItemType.Incident);
                setStandardIncidents(res);
                for (let item of row.incidentReports?.incidentReports || []) {
                    let curPhotoToDownload = res.find(x => x.id == item.standardItemId);
                    if (curPhotoToDownload)
                        curPhotoToDownload.downloadedPhotoUrl = await api.DownloadUserFile(curPhotoToDownload.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, curPhotoToDownload.photos[0].fileName, curPhotoToDownload.photos[0].contentType, true)

                }
                setStandardIncidents([...res]);
            }
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <AlertSvg width={40} height={40} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        {group?.groupType == GroupType.DisplayBatteryWallApp && <Typography component="h4" variant="h4">{t("settingsPage.incidentReport")} Public</Typography>}
                        {(group?.groupType == GroupType.DisplayWallApp || group?.groupType == GroupType.Checkpoint) && <Typography component="h4" variant="h4">{t("settingsPage.incidentReport")}</Typography>}

                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={row.incidentReports?.isIncidentReportEnabled}
                                onChange={(event: any) => {
                                    event.target['value'] = event.target.checked;
                                    onChange(event);
                                }}
                                name="isIncidentReportEnabled"
                                color="primary" />}
                            label={`${t("settingsPage.incidentReport")} ${t("settingsPage.activated")}`} />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={3}>
                    <Grid item>
                        <Typography variant='h5'>{t("myUnitsSettings.serviceStaffIncidents")}</Typography>
                    </Grid>
                    <IncidentModeTypeComponent row={row} onChange={onChange} />
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid container item justifyContent={"flex-start"} spacing={3}>


                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Add />}
                                variant={'contained'}
                                onClick={async () => {
                                    onChange({ target: { name: 'incidentReportItemsAdd', value: "" } });
                                }}
                                color="primary"
                            >
                                {t("myUnitsSettings.addIncident")}
                            </Button>
                        </Grid>
                        <Grid item>
                            {row.incidentReports && row.incidentReports.incidentReports &&
                                <DefaultItemsDialog
                                    onLoadFiles={async () => {
                                        let res = await Promise.all(standardIncidents.map(async (x) => {
                                            if (x.photos.length > 0) {
                                                x.downloadedPhotoUrl = await api.DownloadUserFile(x.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, x.photos[0].fileName, x.photos[0].contentType, true)
                                            }
                                            return x;
                                        }));
                                        setStandardIncidents(res);
                                    }}
                                    allStandardItems={standardIncidents}
                                    currentItems={row.incidentReports.incidentReports.map(el => standardIncidents.find(x => x.id == el.standardItemId) || new StandardWorkItem()) || []}
                                    manageItems={(newItems: any[]) => {
                                        onChange({ target: { name: 'incidentReportItemsAddMultiple', value: newItems.map(x => GroupSettingsIncidenReport.CreateInstance(x.text, true, x.id)) } });
                                    }}
                                    itemType={StandardWorkItemType.Incident}
                                />
                            }
                        </Grid>
                        <Grid item>
                            {row.incidentReports && row.incidentReports.incidentReports &&
                                <DefaultItemsDialogOld
                                    currentItems={row.incidentReports.incidentReports.map(el => el.text) || []}
                                    manageItems={(newItems: any[]) => {
                                        onChange({ target: { name: 'incidentReportItemsAddMultipleOld', value: newItems } });
                                    }}
                                    itemType={DefaultItemsType.Incidents}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid item container direction={"row"} spacing={3}>
                        {
                            row?.incidentReports?.incidentReports &&
                            row.incidentReports?.incidentReports.map((el: GroupSettingsIncidenReport, index) => {
                                const currentStandardItem = standardIncidents.find(x => x.id == el.standardItemId);
                                return (
                                    <Grid item md={12} lg={6} xl={4} container direction="row" alignItems="center" spacing={3}>
                                        <Grid item xs={1}>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                onClick={() => {
                                                    onChange({ target: { name: 'incidentReportItemsRemove', value: JSON.stringify({ index: index }) } });
                                                }}>
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        {currentStandardItem ?
                                            <Grid item xs={6} className={containerStyles.marginFieldSmall}>
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment variant="filled" position="end">{currentStandardItem.downloadedPhotoUrl ?
                                                            <LocalMedia src={currentStandardItem.downloadedPhotoUrl.url} contentType={currentStandardItem.downloadedPhotoUrl.type} height={50} />
                                                            : <CircularProgress />}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="incidentReportItems"
                                                    label={t("myUnitsSettings.incidentReport")}
                                                    value={currentStandardItem.text || ''}
                                                    disabled />
                                            </Grid>
                                            :
                                            <Grid item xs={6} className={containerStyles.marginFieldSmall}>
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(el.text || '')}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="incidentReportItems"
                                                    label={t("myUnitsSettings.incidentReport")}
                                                    value={el.text || ''}
                                                    onChange={(event: any) => {
                                                        let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                        onChange(res);
                                                    }} />
                                            </Grid>
                                        }
                                        {row.incidentReports?.isIncidentVisitorWithNamePriceEnabled &&
                                            <Grid item xs={2}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    startIcon={<VisibilityOff />}
                                                    variant={'contained'}
                                                    onClick={async () => {
                                                        setCurrentIncident(el)
                                                        setIsNameOfExceptionIncidentListDialogVisible(true);
                                                    }}
                                                    color="primary"
                                                >
                                                    Namen
                                                </Button>
                                            </Grid>
                                        }
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={el.isEnabled}
                                                        onChange={(target: any) => {
                                                            target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                            onChange(target);
                                                        }}
                                                        name="incidentReportItems"
                                                        color="primary"
                                                    />
                                                }
                                                label={t("myUnitsSettings.activated")} />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant='h5'>{t("myUnitsSettings.visitorsIncidents")}</Typography>
                    </Grid>
                    <Grid container item justifyContent={"flex-start"} spacing={3}>

                        <IncidentVisitorCheckboxComponent onChange={onChange} row={row} />
                        <IncidentVisitorFreeTextCheckboxComponent onChange={onChange} row={row} />
                        <IncidentVisitorWithNameCheckboxComponent onChange={onChange} row={row} />

                    </Grid>

                    <Grid item container direction={"row"} spacing={3}>
                        <PublicFeedbackCheckboxComponent title={"publicRegistrationActivated"} onChange={onChange} row={row} />
                        <PublicFeedbackRestartCheckboxComponent onChange={onChange} row={row} />
                    </Grid>
                    <Grid item>
                        <PublicFeedbackModeComponent row={row} onChange={onChange} />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                        <Grid item>
                            <Typography variant='h5'>{t("myUnitsSettings.incidentNames")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={row.incidentReports?.isIncidentVisitorWithNamePriceEnabled}
                                    onChange={(event: any) => {
                                        if (!row.incidentReports) return;
                                        row.incidentReports.isIncidentVisitorWithNamePriceEnabled = event.target.checked;
                                        onChange({ target: { name: "row", value: "" } });
                                    }}
                                    name="isIncidentVisitorWithNamePriceEnabled"
                                    color="primary" />}
                                label={t("myUnitsSettings.costDisplayActivated")} />
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent={"flex-start"} spacing={3}>


                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Add />}
                                variant={'contained'}
                                onClick={async () => {
                                    onChange({ target: { name: 'incidentNameItemsAdd', value: "" } });
                                }}
                                color="primary"
                            >
                                {t("myUnitsSettings.addNames")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"row"} spacing={3}>
                        {
                            row?.incidentReports?.incidentNames &&
                            row.incidentReports?.incidentNames.map((el: GroupSettingsIncidenName, index) => {
                                return (
                                    <Grid item md={12} lg={6} xl={4} container direction="row" alignItems="center" spacing={0}>
                                        <Grid item lg={1}>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                onClick={() => {
                                                    onChange({ target: { name: 'incidentNameItemsRemove', value: JSON.stringify({ index: index }) } });
                                                }}>
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        <Grid item lg={6} className={containerStyles.marginFieldSmall}>
                                            <TextFieldGroupSettings
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(el.text || '')}</InputAdornment>,
                                                }}
                                                variant={"filled"}
                                                name="incidentNameItems"
                                                label="Name"
                                                value={el.text || ''}
                                                onChange={(event: any) => {
                                                    let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                    onChange(res);
                                                }} />
                                        </Grid>
                                        {row?.incidentReports?.isIncidentVisitorWithNamePriceEnabled &&
                                            <Grid item lg={2} className={containerStyles.marginFieldSmall}>
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment variant="filled" position="end">€</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="incidentNameItems"
                                                    label="Preis"
                                                    type={"number"}
                                                    value={el.price || ''}
                                                    onChange={(event: any) => {
                                                        let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'price' }) } };
                                                        onChange(res);
                                                    }} />
                                            </Grid>
                                        }
                                        <Grid item lg={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={el.isEnabled}
                                                        onChange={(target: any) => {
                                                            target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                            onChange(target);
                                                        }}
                                                        name="incidentNameItems"
                                                        color="primary"
                                                    />
                                                }
                                                label={t("myUnitsSettings.activated")} />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </AccordionDetails>
            {currentIncident &&
                <NameOfExceptionIncidentListDialog row={row as GroupSettings} incident={currentIncident} onClose={() => {
                    setIsNameOfExceptionIncidentListDialogVisible(false);
                }} onChange={() => {
                    onChange({ target: { name: "row", value: "" } });
                }} open={isNameOfExceptionIncidentListDialogVisible} />
            }
        </Accordion>
    );
};
export const IncidentVisitorCheckboxComponent = ({ onChange, row }: { onChange: any, row: any }) => {
    return (
        <Grid item>
            <FormControlLabel
                control={<Checkbox
                    checked={row.incidentReports?.isIncidentVisitorEnabled}
                    onChange={(event: any) => {
                        event.target['value'] = event.target.checked;
                        if (event.target.checked && row.feedbacks){
                            row.feedbacks.isPublicFeedbackEnabled = true;
                        }
                        onChange(event);
                    }}
                    name="isIncidentVisitorEnabled"
                    color="primary" />}
                label={t("myUnitsSettings.incidentReportingForVisitorsEnabled")} />
        </Grid>
    )
}




export const IncidentVisitorFreeTextCheckboxComponent = ({ onChange, row }: { onChange: any, row: any }) => {
    return (
        <Grid item>
            {row.incidentReports?.isIncidentVisitorEnabled &&
                <FormControlLabel
                    control={<Checkbox
                        checked={row.incidentReports?.isIncidentVisitorCustomTextEnabled}
                        onChange={(event: any) => {
                            event.target['value'] = event.target.checked;
                            onChange(event);
                        }}
                        name="isIncidentVisitorCustomTextEnabled"
                        color="primary" />}
                    label={t("myUnitsSettings.incidentReportingWithFreeTextForVisitorsEnabled")} />
            }
        </Grid>
    )
}

export const PublicFeedbackCheckboxComponent = ({ onChange, row, title = "publicFeedbackEnabled" }: { onChange: any, row: any, title?: string }) => {
    const {t} = useTranslation();
    return (
        <Grid item>
            <FormControlLabel
                label={t(`myUnitsSettings.${title}`)}
                control={
                    <Checkbox
                        name={GroupSettingsMappingKeys.isPublicFeedbackEnabled}
                        color="primary"
                        checked={row.feedbacks?.isPublicFeedbackEnabled}
                        onChange={(event: any, checked: boolean) => {
                            event.target.value = checked;
                            onChange(event);
                        }}
                    />
                }
            />
        </Grid>
    )
}
export const PublicFeedbackRestartCheckboxComponent = ({ onChange, row }: { onChange: any, row: any }) => {
    const {t} = useTranslation();

    return (
        <Grid item>
            <FormControlLabel
                label={t(`myUnitsSettings.restartActivated`)}
                control={
                    <Checkbox
                        name={GroupSettingsMappingKeys.isPublicFeedbackRestartEnabled}
                        color="primary"
                        checked={row.feedbacks?.isPublicFeedbackRestartEnabled}
                        onChange={(event: any, checked: boolean) => {
                            event.target.value = checked;
                            onChange(event);
                        }}
                    />
                }
            />
        </Grid>
    )
}
export const IncidentVisitorWithNameCheckboxComponent = ({ onChange, row }: { onChange: any, row: any }) => {
    const {t} = useTranslation();
    return (
        <Grid item>
            {row.incidentReports?.isIncidentVisitorEnabled &&
                <FormControlLabel
                    control={<Checkbox
                        checked={row.incidentReports?.isIncidentVisitorWithNameEnabled}
                        onChange={(event: any) => {
                            event.target['value'] = event.target.checked;
                            onChange(event);
                        }}
                        name="isIncidentVisitorWithNameEnabled"
                        color="primary" />}
                    label={t("myUnitsSettings.incidentReportingForVisitorsWithNameActivated")} />
            }
        </Grid>
    )
}

export const IncidentModeTypeComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    const {t} = useTranslation();
    return (
        <Grid item>
            <FormControl component="fieldset">
                <FormLabel component="legend">{t("myUnitsSettings.incidentModeForServiceStaffSelection")}</FormLabel>
                <RadioGroup
                    row
                    aria-label="gender"
                    value={row?.incidentReports?.incidentModeType}
                    onChange={(event) => {
                        if (row?.incidentReports?.incidentModeType == null) return;

                        row.incidentReports.incidentModeType = parseInt(event.target.value);

                        onChange({ target: { name: "row", value: "" } });
                    }}
                >
                    <FormControlLabel
                        value={IncidentModeType.Standard}
                        control={<Radio />}
                        label={t("myUnitsSettings.withoutNameWithoutFreeText")}
                    />

                    <FormControlLabel
                        value={IncidentModeType.WithName}
                        control={<Radio />}
                        label={t("myUnitsSettings.withNameWithoutFreeText")}
                    />

                    <FormControlLabel
                        value={IncidentModeType.WithNameAndFreeText}
                        control={<Radio />}
                        label={t("myUnitsSettings.withNameWithFreeText")}
                    />

                    <FormControlLabel
                        value={IncidentModeType.WithoutNameButFreeText}
                        control={<Radio />}
                        label={t("myUnitsSettings.withoutNameWithFreeText")}
                    />


                </RadioGroup>

            </FormControl>
        </Grid>
    )

}