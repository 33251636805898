import { GroupServiceModeIncident, GroupServiceModeIncidentImage, GroupServiceModeIncidentPhoto } from "./GroupServiceMode";
import { ItemThingType } from "./GroupSettings";
import { QMRatingType } from "./ObjectUnit";

export class ObjectLeaderQualityCheckModel {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public userServiceWorkerId !: string;
    public markedServices !: MarkedService[];
    public markedMaterials !: MarkedMaterial[];
    public markedItemThings !: MarkedItemThing[];
    public incidents !: string[];
    public incidentNames !: GroupServiceModeIncident[];
    public incidentPhotos !: GroupServiceModeIncidentPhoto[];
    public qmRatingType !: QMRatingType;

    public createdTimestamp!: string;
    public constructor () {
        this.systemETag = null;
        this.groupId = "";
        this.markedMaterials = [];
        this.markedServices = [];
    }

    static Columns = {
        id: 'id',
        groupId: "groupId",
        userId: "userId",
        userServiceWorkerId: "userServiceWorkerId",
        markedServices: "markedServices",
        markedItemThings: "markedItemThings",
        weighting : "weighting",
        markedMaterials: "markedMaterials",
        incidents: "incidents",
        createdTimestamp: "createdTimestamp",
        incidentPhotos: 'incidentPhotos',
        qmRatingType: 'qmRatingType',
        weightingSum: 'weightingSum',
        overAllUsageComponent: "overAllUsageComponent",
        overAllUsageComponentGrade: "overAllUsageComponentGrade",
        mainUsageComponent: "mainUsageComponent",
        mainUsageComponentGrade: "mainUsageComponentGrade",

        restInventory : "restInventory",
        restInventoryGrade: "restInventoryGrade",
        wallsAndCovers : "wallsAndCovers",
        wallsAndCoversGrade : "wallsAndCoversGrade",
        floor : "floor",
        floorGrade : "floorGrade",
        hardToSeeThings : "hardToSeeThings",
        hardToSeeThingsGrade : "hardToSeeThingsGrade"
    }

}

export class MarkedService {
    public positiveAmount!: number;
    public negativeAmount!: number;
    public neutralAmount!: number;
    public veryNegativeAmount!: number;
    public veryPositiveAmount!: number;
    public cleaningOperationText !: string;
    public qualityCheckPhoto : GroupServiceModeIncidentPhoto[] = [];
    public weighting !: number;
}

export class MarkedMaterial {
    public positiveAmount!: number;
    public negativeAmount!: number;
    public neutralAmount!: number;
    public veryNegativeAmount!: number;
    public veryPositiveAmount!: number;
    public materialText !: string;
}

export class MarkedItemThing {
    public errorCount !: number;
    public text !: string;
    public itemThingType !: ItemThingType;
    public qualityCheckPhotoContent : GroupServiceModeIncidentImage[] = [];
    public qualityCheckPhoto : GroupServiceModeIncidentPhoto[] = [];
}