import axios from 'axios';
import { headers } from '../api';
import { getOwnUserIdFromStorage, getUserIdFromStorage } from '../helpers/common';
import { StandardWorkItem } from '../models/StandardItem';
import { UserAddress } from '../models/UserAddress';

export const createStandardItem = async (standarditem: StandardWorkItem | undefined, token: string, enqueueSnackbar: any, subTitle: string): Promise<StandardWorkItem> => {
    try {
        if (!standarditem) throw (null);
        let userId = getUserIdFromStorage();
        standarditem.creatorUserId = userId || "";
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/create-standarditem`,
            { ...standarditem },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar(`${subTitle} Speichern erfolgreich`, { variant: 'success' });
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar(`${subTitle} Speichern error`, { variant: 'error' });
    }
    return null as any;
}

export const createStandardItemAdmin = async (standarditem: StandardWorkItem | undefined, token: string, enqueueSnackbar: any, subTitle: string): Promise<StandardWorkItem> => {
    try {
        if (!standarditem) throw (null);
        let userId = getOwnUserIdFromStorage();
        standarditem.creatorUserId = userId || "";
        console.log(standarditem, "mystandard");
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/create-standarditem-admin`,
            { ...standarditem },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar(`${subTitle} Speichern erfolgreich`, { variant: 'success' });
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar(`${subTitle} Speichern error`, { variant: 'error' });
    }
    return null as any;
}