
export class UserServiceWorkerObjectSession {
    public id!: string;
    public systemETag!: string;
    public systemTimestamp!: string;
    public objectId!: string;
    public userId!: string;
    public userServiceWorkerId!: string;
    public signedInObjectSinceTimestamp!: Date;
    public signedOutOfObjectSinceTimestamp !: Date;
    public creationDate!: string;
    public nfcCode !: string;
    public groupId !: string;
    public logoutGroupId !: string;
    public staffNumber !: string;

    static CreateUserServiceWorkerObjectSession = () => {
        const object = new UserServiceWorkerObjectSession();

        return object;
    }
    static CreateFullUserServiceWorkerObjectSession = (objectId: string, userId: string, userServiceWorkerId: string, signedInObjectSinceTimestamp: Date, signedOutOfObjectSinceTimestamp: Date) => {
        const objectSession = new UserServiceWorkerObjectSession();
        objectSession.objectId = objectId;
        objectSession.userId = userId;
        objectSession.userServiceWorkerId = userServiceWorkerId;
        objectSession.signedInObjectSinceTimestamp = signedInObjectSinceTimestamp;
        objectSession.signedOutOfObjectSinceTimestamp = signedOutOfObjectSinceTimestamp;


        return objectSession;
    }
    static Columns = {
        id: 'id',
        object: "object",
        groupId: "groupId",
        serviceWorker: 'serviceWorker',
        phoneNumber: 'phoneNumber',
        creationDate: 'creationDate',
        signedInObjectSinceTimestamp: 'signedInObjectSinceTimestamp',
        signedInObjectSinceTime: 'signedInObjectSinceTime',
        signedOutOfObjectSinceTimestamp: "signedOutOfObjectSinceTimestamp",
        signedOutOfObjectSinceTime: "signedOutOfObjectSinceTime",
        duration : 'duration',
        durationMinutes : 'durationMinutes',
        durationHours : 'durationHours',
        logoutGroupId : 'logoutGroupId'
    }


}

export class ObjectSessionCountTableDataType {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public objectId!: string;
    public userId!: string;
    public signedInObjectSinceTimestamp!: string;
    public signedOutOfObjectSinceTimestamp !: string;
    public creationDate!: string;
    public sessionCount!: number;
    public userServiceWorkerIds !: string[];
    public constructor (objectId: string, userId: string, sessionCount: number, userServiceWorkerIds: string[]) {
        this.systemETag = null;
        this.objectId = objectId;
        this.userId = userId;
        this.sessionCount = sessionCount;
        this.userServiceWorkerIds = userServiceWorkerIds;
    }

    static Columns = {
        id: 'id',
        objectId: "objectId",
        userId: "userId",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        countMax: "countMax",
        allWorkers: "allWorkers",
        countPercent: "countPercent",
        missingWorkers: "missingWorkers",
        sessionCount: "sessionCount"
    }
}

export class UserServiceWorkerSessionCountTableDataType {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public objectId!: string;
    public userId!: string;
    public userServiceWorkerId!: string;
    public signedInObjectSinceTimestamp!: string;
    public signedOutOfObjectSinceTimestamp !: string;
    public creationDate!: string;
    public onlineCount!: number;
    public onlineCountMax !: number;
    public constructor (objectId: string, userId: string, onlineCount: number, onlineCountMax: number) {
        this.systemETag = null;
        this.objectId = objectId;
        this.userId = userId;
        this.onlineCount = onlineCount;
        this.onlineCountMax = onlineCountMax;
    }

    static Columns = {
        id: 'id',
        objectId: "objectId",
        userServiceWorkerId: "userServiceWorkerId",
        userId: "userId",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        countMax: "countMax",
        countPercent: "countPercent",
        sessionCount: "sessionCount"
    }
}