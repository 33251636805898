import {
    AppBar,
    Dialog,
    Toolbar,
    Typography,
    DialogTitle,
    Theme,
    IconButton,
    DialogContent,
    Grid,
    Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { formatEuropeanDate } from '../../helpers/date-management';
import { StringHelper } from '../../helpers/string-helper';
import { UserInvoice } from '../../models/UserInvoice';
import { UserInvoicesTableInvoiceDetailDialogItemTable } from './UserInvoicesTableInvoiceDetailDialogTable';
import { useTranslation } from 'react-i18next';

export const UserInvoicesTableInvoiceDetailDialog = ({ visible, setVisible, invoice }: { visible: boolean, setVisible: any, invoice: UserInvoice }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const onClose = () => setVisible(false);

    if (!invoice) return null;

    const invoiceDate = formatEuropeanDate(invoice.date);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={visible}
            onClose={onClose}
        >
            <DialogTitle className={classes.dialogTitle}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography className={classes.dialogTitleText} variant="h6">
                            {`Bestellung ${StringHelper.toInvoiceNumber(invoice.number)} von ${invoiceDate}`}
                        </Typography>
                        <IconButton color="inherit" onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>

                <Grid container direction="column" spacing={1}>

                    <Grid item xs={12}>
                        <Paper className={classes.dialogContentContainer} style={{ marginTop: 0 }}>
                            <Grid item container xs={12} direction="row">

                                <Grid item container xs={12} md={6} direction="row" alignItems="center" justifyContent="flex-start">
                                    <Grid item xs={6}>
                                        <Typography className={classes.title}>{t("orders.orderNumber")}:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>{StringHelper.toInvoiceNumber(invoice.number)}</Grid>
                                </Grid>

                                <Grid item container xs={12} md={6} direction="row" alignItems="center" justifyContent="flex-start">
                                    <Grid item xs={6}>
                                        <Typography className={classes.title}>Datum:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>{invoiceDate}</Grid>
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.dialogContentContainer}>

                            <Grid item container xs={12} direction="row">

                                <Grid item container xs={12} md={6}>
                                    <Grid item xs={12} md={6}>
                                        <Typography className={classes.title}>Empfänger / Besteller</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography>{invoice.paymentAddress.firstName} {invoice.paymentAddress.lastName}</Typography>
                                        <Typography>{invoice.paymentAddress.addressLine}</Typography>
                                        <Typography>{invoice.paymentAddress.country} - {invoice.paymentAddress.zipCode}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container xs={12} md={6}>
                                    <Grid item xs={12} md={6}>
                                        <Typography className={classes.title}>Lieferaddresse</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography>{invoice.deliveryAddress.firstName} {invoice.deliveryAddress.lastName}</Typography>
                                        <Typography>{invoice.deliveryAddress.addressLine}</Typography>
                                        <Typography>{invoice.deliveryAddress.country} - {invoice.deliveryAddress.zipCode}</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <UserInvoicesTableInvoiceDetailDialogItemTable
                            taxRate={invoice.taxRate}
                            items={invoice.items || []}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 0,
        flexGrow: 1,
    },
    dialogTitleText: {
        flexGrow: 1,
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogContentContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    title: {
        fontWeight: 'bold',
    },
}));