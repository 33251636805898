import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, FilterAlt, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ObjectSelectionDataTable } from './ObjectSelectionDataTable';
import { useTranslation } from 'react-i18next';

export const FilterObjectDialog = ({
  open,
  setOpen,
  groups,
  setGroups,
  objects,
  selectedObjects,
  setSelectedObjects
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable [],
  setGroups: any,
  objects: ObjectUnit[],
  selectedObjects: ObjectUnit[],
  setSelectedObjects: any
}) => {

  const {t} = useTranslation();


  const [selection, setSelection] = React.useState<string[]>([]);

  const handleClose = () => {
    setOpen(false);
  }


  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Filter Einheiten nach Objekten</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
      <ObjectSelectionDataTable rows={objects} groups={groups} selectedObjects={selectedObjects}
                onSelectionChange={(selection: string[]) => {
                  setSelectedObjects(objects.filter(x=>selection.some(y=>x.id == y)));
                  setSelection(selection);
                }}
                currentGroup={null} />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Delete />}
          onClick={async () => {
            handleClose();
          }}
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<FilterAlt />}
          onClick={async () => {
            setGroups(groups.filter(x => selection.some(y=>y == x.objectUnitId)));
            handleClose();
          }}
        >
          Filtern
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));