import React from 'react';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, Toolbar, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import makeStyles from '@mui/styles/makeStyles';
import { lightgrey } from '../../styles/colors';
import { userTypePredicate } from './components/Cells';
import { UserAccount } from '../../models/UserAccount';
import { mapLabelUserType } from '../../helpers/common';

export const UserSingleSelection = ({ users, selectedUser, setSelectedUser }
    : { users: UserAccount[], selectedUser: UserAccount | undefined, setSelectedUser: any }) => {

    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);

    return (
        <Grid
            columns={[
                {
                    name: keys.mail,
                    title: 'Email',
                    getCellValue: (user: UserAccount) => user.mail,
                },
                {
                    name: keys.firstname,
                    title: 'Vorname',
                    getCellValue: (user: UserAccount) => user.firstname,
                },
                {
                    name: keys.lastname,
                    title: 'Nachname',
                    getCellValue: (user: UserAccount) => user.lastname,
                },
                {
                    name: keys.company,
                    title: 'Firma',
                    getCellValue: (user: UserAccount) => user.company,
                },
                {
                    name: keys.userType,
                    title: 'User Typ',
                    getCellValue: (user: UserAccount) => mapLabelUserType(user.userType),
                },
                {
                    name: keys.title,
                    title: 'Titel',
                    getCellValue: (user: UserAccount) => user.title,
                },
                {
                    name: UserAccount.Columns.uid,
                    title: 'UID',
                    getCellValue: (user: UserAccount) => user.uid,
                },
                {
                    name: UserAccount.Columns.atu,
                    title: 'FN',
                    getCellValue: (user: UserAccount) => user.atu,
                },
            ]}
            rows={users}
            getRowId={(row) => row.id}
        >
            <FilteringState />
            <IntegratedFiltering columnExtensions={[
                { columnName: keys.userType, predicate: userTypePredicate },
            ]} />

            <SortingState
                defaultSorting={[{ columnName: keys.mail, direction: 'desc' }]}
            />
            <IntegratedSorting />
            <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
            />

            <SelectionState
                selection={selectedUser ? [selectedUser.id] : []}
                onSelectionChange={(changes: (string | number)[]) => {

                    if (changes.length > 1)
                        changes.shift();

                    setSelectedUser(users.filter(x => x.id === changes[0])[0]);
                }}
            />
            <IntegratedSelection />
            <IntegratedPaging />

            <Table />
            <TableColumnResizing
                columnWidths={[
                    { columnName: 'mail', width: 220 },
                    { columnName: 'firstname', width: 220 },
                    { columnName: 'lastname', width: 220 },
                    { columnName: 'company', width: 220 },
                    { columnName: 'userType', width: 220 },
                    { columnName: 'title', width: 220 },
                    { columnName: 'uid', width: 220 },
                    { columnName: 'atu', width: 220 },
                ]}
            />
            <TableHeaderRow showSortingControls />
            <TableColumnVisibility />
            <Toolbar />
            <ColumnChooser />
            <TableFilterRow />
            <TableSelection
                selectByRowClick
                highlightRow
            />
            <PagingPanel pageSizes={[10, 15, 0]} />
        </Grid>
    );
};

const useStyles = makeStyles({
    selected: {
        backgroundColor: lightgrey
    },
    customRow: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: lightgrey
        }
    }
});

const keys = {
    mail: 'mail',
    firstname: 'firstname',
    lastname: 'lastname',
    company: 'company',
    userType: 'userType',
    title: 'title',
    notificationName: 'notificationName',
};
