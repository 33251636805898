
export enum LicenseType {
    None = 99,
    Device = 0,
    Advertisement = 1,
    Extension = 2,
    Checkpoint = 3,
    ObjectNotificationLog = 4,
    QualityCheck = 5,
    BatteryDisplay = 6,
    BatterySensor = 7,
    LoraSensorExtension = 8,
    PublicFeedback = 9,
    PayPerService = 10,
    CalendarPro = 11,
    TaqtFeedback = 12,
    TaqtTime = 13
}
