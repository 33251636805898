import { Autocomplete, Box, Grid, InputAdornment, TextField } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguagePickerI18n() {
    const { t, i18n } = useTranslation();
    const languages = React.useMemo(() => countries, []);
    const [currentLanguage, setCurrentLanguage] = React.useState({ language: "de", label: 'german', code: 'DE' });

    React.useEffect(()=>{
        let stringToDecode = localStorage.getItem("LANGUAGE") || JSON.stringify({ language: "de", label: 'german', code: 'DE' });
        let settings = JSON.parse(stringToDecode);
        setCurrentLanguage(settings);
        i18n.changeLanguage(settings.language);
    },[])

    const currentLabel = t(currentLanguage.label);

    return (
        <Grid container direction={"row"} spacing={3} alignItems={"center"}>
            <Grid item >
                <Autocomplete
                    style={{ width: 150 }}
                    id="country-select-demo"
                    fullWidth
                    options={languages}
                    autoHighlight
                    size='small'
                    value={{ code: currentLanguage.code,language: currentLanguage.language, label: currentLabel || '' }}
                    getOptionLabel={(option) => `${t(option.label)}`}
                    onChange={(_: any, newValue: any) => {
                        if (newValue) {
                            i18n.changeLanguage(newValue.language);
                            localStorage.setItem("LANGUAGE",JSON.stringify(newValue));
                            setCurrentLanguage(newValue);
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {t(option.label)}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("language")}
                            style={{ backgroundColor: 'white', borderRadius: 5 }}
                            variant={"filled"}
                            value={currentLanguage}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item >
                <img
                    loading="lazy"
                    width="50"
                    style={{border: "1px white solid", borderRadius: 4, padding: 1}}
                    src={`https://flagcdn.com/w20/${currentLanguage.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${currentLanguage.code.toLowerCase()}.png 2x`}
                    alt=""
                />
            </Grid>
        </Grid>
    );
}

interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries: readonly any[] = [
    { language: 'en', code: 'GB', label: 'english', phone: '44' },
    {
        code: 'DE',
        language: 'de',
        label: 'german',
        phone: '49',
        suggested: true,
    }
];