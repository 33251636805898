import { useCallback, useRef, useState } from 'react';
import {
    ColumnChooser,
    ExportPanel,
    Grid,
    PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, FormControlLabel, ListItem, TableCell, Typography } from '@mui/material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { Group } from '../../../../../models/Group';
import { mapToiletTypeKey } from '../../../../tables/Base';
import React from 'react';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { formatEuropeanDateTime, formatUnixSecTimestampEuropeanDateInMinutes, isOlderThanFifteenMinutes } from '../../../../../helpers/date-management';
import { differenceInHours, differenceInMinutes, fromUnixTime, getUnixTime } from 'date-fns';
import { Check, Error, ExpandMore, SensorDoor } from '@mui/icons-material';
import { Button, Grid as MuiGrid, List, ListItemIcon, ListItemText } from '@mui/material';
import { green } from '@mui/material/colors';
import { getApiToken } from '../../../../../helpers/storage-management';
import { api } from '../../../../../api';
import { useHistory } from 'react-router-dom';
import { LoadingIcon } from '../../../../common/LoadingIcon';
import { ReactComponent as MotionSensorSvg } from '../../../../../assets/svgs/motion-sensor.svg'
import { BatteryOnlineStatusCountTableClass, 
 } from '../../../../../models/GroupOnlineStatus';
import { useTranslation } from 'react-i18next';


const onSave = (workbook: any) => {


    //unfreeze panes (scrolling)
    workbook._worksheets[1].views[0].state = "split";

    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Check-Me-Now-Services`);
    });
};

export const BatteryOnlineStatusCountTable = ({ rows, groupLicenseTable }: { rows: BatteryOnlineStatusCountTableClass[], groupLicenseTable?: GroupLicenseTable[] }) => {
    const exporterRef: any = useRef(null);
    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const history = useHistory();
    const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);
    const {t} = useTranslation();
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [columns] = useState([
        { name: BatteryOnlineStatusCountTableClass.Columns.groupId, title: t("serviceDocumentationTable.unitName"), getCellValue: (row: BatteryOnlineStatusCountTableClass) => groupLicenseTable?.find(x => x.id == row.groupId)?.notificationName },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.groupType,
            title: t("myUnitsSettings.unitType"),
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => Group.GetLabelByType(groupLicenseTable?.find(x => x.id == row.groupId)?.groupType),
        },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.objectName,
            title: t("myUnitsSettings.objectName"),
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => groupLicenseTable?.find(x => x.id == row.groupId)?.objectName,
        },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.levelName,
            title: t("myUnitsSettings.level"),
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => groupLicenseTable?.find(x => x.id == row.groupId)?.levelName,
        },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.roomName,
            title: t("myUnitsSettings.customer"),
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => groupLicenseTable?.find(x => x.id == row.groupId)?.roomName,
        },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.toiletType,
            title: t("myUnitsSettings.roomType"),
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => mapToiletTypeKey(groupLicenseTable?.find(x => x.id == row.groupId)?.toiletType),
        },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => groupLicenseTable?.find(x => x.id == row.groupId)?.userPropertyName,
        },
        { name: BatteryOnlineStatusCountTableClass.Columns.onlineCount, title: 'Online Gemeldet', getCellValue: (row: BatteryOnlineStatusCountTableClass) => row?.groupOnlineStatusCountTable?.onlineCount || "Keine Online Meldung" },
        { name: BatteryOnlineStatusCountTableClass.Columns.lastOnlineTimestamp, title: 'Zuletzt Gemeldet', getCellValue: (row: BatteryOnlineStatusCountTableClass) => row.groupOnlineStatusCountTable ? formatEuropeanDateTime(row.groupOnlineStatusCountTable.lastOnlineTimestamp) : "Kein Online Status" },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.lastOnlineBattery,
            title: 'Last Online Battery',
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => row.groupOnlineStatusCountTable?.batteryLevel,
        },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.feedbackCount,
            title: 'Feedback Anzahl',
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => row.feedbackCount,
        },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.feedbackCountBattery,
            title: 'FeedbackBattery',
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => row.feedbackBatteryLevel,
        },

        { name: BatteryOnlineStatusCountTableClass.Columns.feedbackLastOnline, title: 'Letztes Feedback', getCellValue: (row: BatteryOnlineStatusCountTableClass) => !isNaN(row.feedbackLastOnline as any) ? formatEuropeanDateTime(row.feedbackLastOnline) : "Kein Datum" },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.cleanerFeedbackCount,
            title: 'Servicekraft Feedback Anzahl',
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => row.cleanerFeedbackCount,
        },
        { name: BatteryOnlineStatusCountTableClass.Columns.cleanerFeedbackLastOnline, title: 'Letztes Servicekraft Feedback', getCellValue: (row: BatteryOnlineStatusCountTableClass) => !isNaN(row.cleanerFeedbackLastOnline as any) ? formatEuropeanDateTime(row.cleanerFeedbackLastOnline) : "Kein Datum" },
        { name: BatteryOnlineStatusCountTableClass.Columns.sensors, title: 'Sensoren', getCellValue: (row: BatteryOnlineStatusCountTableClass) => JSON.stringify(groupLicenseTable?.find(x => x.id == row.groupId)?.sensors) },
        { name: BatteryOnlineStatusCountTableClass.Columns.countMax, title: 'Minimum Online Anzahl', getCellValue: (row: BatteryOnlineStatusCountTableClass) => row?.groupOnlineStatusCountTable?.onlineCountMax || "Keine Online Meldung" },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.countPercent, title: 'Prozentanteil Online',
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => row?.groupOnlineStatusCountTable ? ((100 * row.groupOnlineStatusCountTable.onlineCount) / row.groupOnlineStatusCountTable.onlineCountMax).toFixed(2) + '%' : "Keine Online Meldung"
        },
        {
            name: BatteryOnlineStatusCountTableClass.Columns.deviceId,
            title: "Geräte ID",
            getCellValue: (row: BatteryOnlineStatusCountTableClass) => row.deviceId,
        }
    ]);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        const [loading, setLoading] = React.useState(false);
        if (column.name === BatteryOnlineStatusCountTableClass.Columns.lastOnlineTimestamp) {
            let diffMin = 0;
            if (column.name === BatteryOnlineStatusCountTableClass.Columns.lastOnlineTimestamp && row.groupOnlineStatusCountTable) {
                diffMin = differenceInHours(new Date(), row.groupOnlineStatusCountTable.lastOnlineTimestamp)
            } else {
                diffMin = differenceInHours(new Date(), fromUnixTime(groupLicenseTable?.find(x => x.id == row.groupId)?.lastOnline || 0));
            }

            console.log(diffMin, "diffhours");

            return (
                <Table.Cell
                    {...props}>
                    <MuiGrid container direction={"row"} alignItems="center" spacing={1}>
                        <MuiGrid item>
                            <Typography fontWeight={'bold'} color={diffMin <= 24 ? green[700] : "error"}>{value}</Typography>
                        </MuiGrid>
                        <MuiGrid item>
                            {diffMin <= 24 ? <Check style={{ color: green[700] }} fontSize={"large"} /> : <Error color='error' fontSize={"large"} />}
                        </MuiGrid>
                    </MuiGrid>
                </Table.Cell>
            )
        } else if (column.name == BatteryOnlineStatusCountTableClass.Columns.sensors) {
            const sensors = groupLicenseTable?.find(x => x.id == row.groupId)?.sensors || [];
            return (
                <Table.Cell {...props}>
                    <MuiGrid container alignItems="center" justifyContent={"center"} alignContent={"center"}>
                        <MuiGrid item xs={12}>
                            <Accordion style={{ margin: 0 }} onChange={async (_, expanded) => {
                                if (expanded && groupLicenseTable) {
                                    const token = getApiToken(history);
                                    setLoading(true);
                                    let motions = await api.getLastMotionsByGroupId(token, row.groupId);
                                    for (let sensor of sensors) {
                                        let motion = motions.find(x => x.sensorId == sensor.id);
                                        if (motion) {
                                            sensor.lastMessage = formatUnixSecTimestampEuropeanDateInMinutes(motion.createdTimestamp);
                                            sensor.color = isOlderThanFifteenMinutes(motion?.createdTimestamp) ? "#eb4034" : "#2ee656";
                                        }
                                    }
                                    setLoading(false);
                                }
                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <MuiGrid container direction="column">
                                        <MuiGrid item>
                                            <Typography>{`${t("myObjectSettings.contains")} ${sensors?.length} Sensoren`}</Typography>
                                        </MuiGrid>

                                    </MuiGrid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MuiGrid container direction="column">
                                        <MuiGrid item>
                                            <List component="nav" aria-label="main mailbox folders">
                                                {sensors && sensors.length > 0 && sensors.map((el, index) => {
                                                    return (
                                                        <ListItem button key={index}>
                                                            <ListItemText style={{ color: el.color }} primary={el.sensorRoomName} secondary={el.lastMessage} />
                                                            <ListItemIcon>
                                                                <LoadingIcon
                                                                    icon={<MotionSensorSvg fill={el.color} />}
                                                                    loading={loading}
                                                                />
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    )
                                                })}

                                            </List>
                                        </MuiGrid>
                                    </MuiGrid>
                                </AccordionDetails>
                            </Accordion>
                        </MuiGrid>
                    </MuiGrid>
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };



    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == BatteryOnlineStatusCountTableClass.Columns.groupId) {
            return { columnName: el.name, width: 180 }
        }
        if (el.name == BatteryOnlineStatusCountTableClass.Columns.lastOnline || el.name == BatteryOnlineStatusCountTableClass.Columns.lastOnlineTimestamp) {
            return { columnName: el.name, width: 230 }
        }
        if (el.name == BatteryOnlineStatusCountTableClass.Columns.sensors) {
            return { columnName: el.name, width: 300 }
        }
        return { columnName: el.name, width: 180 }
    }));


    return (
        <>
            <Grid
                rows={rows}
                columns={columns}
            >
                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //defaultSorting={[{ columnName: 'startTimestamp', direction: 'desc' }]}
                />
                <IntegratedSorting />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >

                    <TemplatePlaceholder />
                </Template>
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow
                />
                <PagingPanel pageSizes={[10, 15, 20, 25, 0]} />
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={rows}
                columns={columns}
                onSave={onSave}
            />
        </>
    );
};