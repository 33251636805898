import { Theme, Typography } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const EmptyBoxOverlay = (props: any) => {

    const { text } = props;

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'white',
            },
        }),
    );

    const styles = useStyles();

    return (
        <div className={styles.root}>
            <div>
                <Typography>{text}</Typography>
            </div>
        </div>
    );
}