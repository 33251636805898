import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { UserKey } from '../models/UserKey';

export const getUserKeys = async (token: string): Promise<UserKey[]> => {

    const userId = getUserIdFromStorage();

    return await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/user-key`,
        { headers: { ...headers, 'Authorization': 'Bearer ' + token } }
    ).then((res) => {
        return res.data || [];
    }).catch((error) => {
        handleApiError(error);

        return [];
    });
}