import React from 'react';
import {
    AppBar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Group, TouchApp } from '@mui/icons-material';
import { formatEuropeanDate } from '../../helpers/date-management';
import { GroupLicense } from '../../models/GroupLicense';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { ReactComponent as LicenseSvg } from '../../assets/svgs/license_grey.svg'
import { useTranslation } from 'react-i18next';


export enum ShowCountType {
    Sensor = 0,
    Checkpoint = 1,
    QualityCheck = 2,
    ObjectStandalone = 3,
    BssdSensor = 4,
    LoraSensor = 5,
    PublicFeedback = 6,
    PayPerService = 7,
    CalendarPro = 8,
    Ad = 9,
    TaqtFeedback = 10,
    TaqtTime = 11

}

export const AssignLicenseChoosingTableDialog = ({ visible, onClose, licenses, onLicenseSelected, showCountType = ShowCountType.Sensor }
    : { visible: boolean, onClose: any, licenses: GroupLicense[], onLicenseSelected: any, showCountType?: ShowCountType }) => {

    const classes = useStyles();

    const [selection, setSelection] = React.useState<GroupLicense | null>(null);
    const [loading, setLoading] = React.useState(false);

    const {t} = useTranslation();

    React.useEffect(() => {

        setSelection(null);
        setLoading(false);

    }, [visible])


    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={visible}
            onClose={onClose}
        >
            <DialogTitle className={classes.dialogTitle}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography className={classes.dialogTitleText}>{t("license-general.selectAvailableLicense")}</Typography>
                        <IconButton color="inherit" onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <List>
                    {
                        licenses &&
                        licenses.length > 0 &&
                        licenses.map((item: GroupLicense, index: number) => (
                            <ListItem
                                key={index}
                                button
                                onClick={() => {
                                    setSelection(item);
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selection?.id === item.id}
                                        tabIndex={-1}
                                        inputProps={{}}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={`${t("myUnitsSettings.purchaseDate")}: ${formatEuropeanDate(item.purchaseTimestamp)}`} />
                                <ListItemText primary={`${t("myUnitsSettings.duration")}: ${item.durationDays} ${t("myUnitsSettings.days")}`} />
                                {buildCountCol(item, showCountType)}
                                <ListItemIcon>
                                    <LicenseSvg />
                                </ListItemIcon>
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!selection || loading}
                    startIcon={loading ? <CircularProgress size={32} /> : <TouchApp />}
                    onClick={async () => {
                        if (!selection) return;
                        setLoading(true);
                        await onLicenseSelected(selection);
                        setLoading(false);
                    }}
                >
                    {t("myUnitsSettings.selectLicense")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const buildCountCol = (item: GroupLicense, showCountType: ShowCountType) => {
    switch (showCountType) {
        case ShowCountType.LoraSensor:
            return <ListItemText primary={`Anzahl Lora Erweiterung Sensor: ${item.loraSensorCount}`} />
        case ShowCountType.Ad:
            return <ListItemText primary={`Anzahl Werbung lizenzen: 1`} />
        case ShowCountType.Sensor:
            return <ListItemText primary={`Anzahl Erweiterung Sensor: ${item.sensorCount}`} />
        case ShowCountType.Checkpoint:
            return <ListItemText primary={`Anzahl Verfügbare Checkpoints: ${item.checkpointCount}`} />
        case ShowCountType.QualityCheck:
            return <ListItemText primary={`Anzahl Verfügbare Qualitätskontrollelizenzen: ${item.qualityCheckCount}`} />
        case ShowCountType.ObjectStandalone:
            return <ListItemText primary={`Objekt Standalone Lizenz Verfügbar`} />
        case ShowCountType.BssdSensor:
            return <ListItemText primary={`Battery Smart Service Display: Sensor Gerät: ${item.batterySmartServiceDisplaySensorCount}`} />
        case ShowCountType.PublicFeedback:
            return <ListItemText primary={`Anzahl Öffentliche Feedback Lizenzen: ${item.publicFeedbackCount}`} />
        case ShowCountType.PayPerService:
            return <ListItemText primary={`Anzahl Pay Per Service Lizenzen: ${item.payPerServiceCount}`} />
        case ShowCountType.CalendarPro:
            return <ListItemText primary={`Anzahl Kalender Pro Lizenzen: ${item.calendarProCount}`} />

    }
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 0,
        flexGrow: 1,
    },
    dialogTitleText: {
        flexGrow: 1,
    },
    dialogContent: {
        padding: theme.spacing(2),
        height: 320,
    },
    dialogContentRadio: {
        padding: theme.spacing(1),
    },
}));