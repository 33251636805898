import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupFeedback } from '../models/GroupFeedback';
import { GroupFeedbackQuestion } from '../models/GroupFeedbackQuestion';
import { GroupIncidentVisitor } from '../models/GroupIncidentVisitor';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserAddress } from '../models/UserAddress';
import { UserProperty } from '../models/UserProperty';
import { GroupPayPerService, GroupPayPerServiceItem } from '../models/GroupPayPerService';

export const CreateIncidentVisitorLightByGroupId = async (feedback: GroupIncidentVisitor,groupId: string, userId: string, enqueueSnackbar: any): Promise<GroupIncidentVisitor> => {
    try {
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/incident-visitor-public`,
            { ...feedback },
            { headers: { ...headers } })
        enqueueSnackbar("Vorfall wurde gemeldet", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Vorfallmeldung error", { variant: 'error' });
    }
    return null as any;
}


export const CreatePayPerServiceLightByGroupId = async (feedback: GroupPayPerService,groupId: string, userId: string, enqueueSnackbar: any): Promise<GroupPayPerService> => {
    try {
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/pay-per-service-public`,
            { ...feedback },
            { headers: { ...headers } })
        enqueueSnackbar("Leistung wurde gemeldet", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Leistungmeldung error", { variant: 'error' });
    }
    return null as any;
}