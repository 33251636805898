import axios from 'axios';
import { api, headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { GroupSettings, GroupSettingsUpdateType } from '../../models/GroupSettings';

export const removeBssdSensorFromGroup = async (groupId: string, deviceId: string, token: string, enqueueSnackbar: any): Promise<any> => {
    const userId = getUserIdFromStorage();
    return await axios
        .patch(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/devices/${deviceId}/remove-bssd-sensor-to-group`,{},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Sensor entfernt", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Error beim Sensor entfernen", { variant: 'error' });
        });
}