import axios from 'axios';
import { headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { gridStackCustomNameTitle } from '../../helpers/names';
import { GridStackCustom } from '../../models/GridStackCustom';

export const putUserGridStackCustom = async (gridStackCustom: GridStackCustom | undefined, token: string, enqueueSnackbar: any): Promise<GridStackCustom> => {
    try {
        if (!gridStackCustom) throw (null);
        let userId = getUserIdFromStorage();
        gridStackCustom.userId = userId || "";
        let ret = await axios.put(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/grid-stack-custom`,
            { ...gridStackCustom },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });
        enqueueSnackbar(`${gridStackCustomNameTitle} Update erfolgreich`, { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar(`${gridStackCustomNameTitle} Update error`, { variant: 'error' });
    }
    return null as any;
}