import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { PieChartComponent } from "../../../../charts/DoughnutChart";
import { StandardBarChart } from "../../../../charts/StandardBarChart";
import { SumChartChoice } from "../../../../core/SumChartChoice";
import { TimeChoiceSum } from "../../../../core/TimeChoiceSum";
import { FeedbackDisplayType } from "../../../../../models/GroupSettings";

export enum ChartSumType {
    Any = 0,
    Motion = 1
}

export const CustomSumOptionsFeedbacks = ({ data, dataField, allData, setChartData, text, defaultChoice = '0', childRef = null, loadingCompleted, colors = "", childRefChart, feedbackDisplayTypeChart = FeedbackDisplayType.PositiveNegativeNeutral }
    : { data: any, dataField: any, allData: any, setChartData: any, text: string, defaultChoice: string, childRef?: any, loadingCompleted: boolean, colors?: any, childRefChart?: any, feedbackDisplayTypeChart?: FeedbackDisplayType }) => {

    const [chartChoice, setChartChoice] = React.useState(defaultChoice);

    const sumFeedback = React.useMemo(() => data.reduce((prevVal: any, x: any) => {
        return prevVal + x.value || 0
    }, 0), [data]);

    const newData = React.useMemo(() => data.map((x: any) => {
        return {
            dateDisplay: x.dateDisplay,
            date: x.date,
            value: x.value,
            key: x.key,
            percentValue: Math.round((100 * x.value) / sumFeedback),
        }
    }), [data, feedbackDisplayTypeChart]);


    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <StandardBarChart
                    childRefChart={childRefChart}
                    data={newData}
                    text={text}
                    colors={colors}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <PieChartComponent
                    childRefChart={childRefChart}
                    data={newData}
                    text={text}
                    colors={colors}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoiceSum ref={childRef} fields={dataField} data={allData} setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                        <SumChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}