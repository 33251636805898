import axios from 'axios';
import { handleApiError, headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { ObjectUnit } from '../../models/ObjectUnit';
import { UserServiceWorker } from '../../models/UserServiceWorker';
import { UserAddress } from '../../models/UserAddress';
import { ObjectLeaderQualityCheckModel } from '../../models/ObjectLeaderQualityCheckModel';

export const sendSelectedQualityChecksToMail = async (token: string, qualityCheckIds : string[], mail : string,enqueueSnackbar: any): Promise<ObjectLeaderQualityCheckModel[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/selected-quality-checks/mail`, {mail : mail, qualityCheckIds : qualityCheckIds},{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        enqueueSnackbar(`Email zu ${mail} versendet!`,{variant:'success'});
        return ret.data;
    } catch (err) {
        enqueueSnackbar(`Email zu ${mail} konnte nicht versendet werden!`,{variant:'error'});
        handleApiError(err);

    }
    return null as any;

}