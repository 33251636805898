import React from 'react';
import { Checkbox, FormControlLabel, ImageList, ImageListItem } from '@mui/material';
import { useGroupSideBarImagesDialogStyles } from '../../styles/useGroupSideBarImagesDialogStyles';
import { GroupSideBarImagesDialogImageHoverOverlay } from './GroupSideBarImagesDialog.ImageHoverOverlay';
import { GroupSettingsSideBarImageItem, GroupSettingsType } from '../../models/GroupSettings';
import { NetworkMedia } from '../images/NetworkMedia';
import { GroupSideBarImagesAssignmentDialogImageHoverOverlay } from './GroupSideBarImagesAssignmentDialog.ImageHoverOverlay';
import { getUserIdFromStorage } from '../../helpers/common';

export const GroupSideBarImagesDialogGridList = ({ groupId, settings, type, onOpenButtonClicked, onSettingButtonClicked, onDeleteButtonClicked }
    : { groupId: string, settings: GroupSettingsSideBarImageItem[], type: GroupSettingsType, onOpenButtonClicked: any, onSettingButtonClicked: any, onDeleteButtonClicked: any }) => {

    const styles = useGroupSideBarImagesDialogStyles();
    const userId = getUserIdFromStorage();

    const buildGroupAdvertisementsDialogGridListItem = (item: GroupSettingsSideBarImageItem, styles: any) => {

        const onOpenButtonClick = async () => {
            await onOpenButtonClicked(item);
        };

        const onSettingButtonClick = async () => {
            await onSettingButtonClicked(item);
        };

        const onDeleteButtonClick = async () => {
            await onDeleteButtonClicked(item);
        };

        let uri = '';
        switch (type) {
            case GroupSettingsType.Template:
                uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${groupId}/images/sidebars/${item.fileName}`;
                break;
            default:
                uri = `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/images/sidebars/${item.fileName}`;
                break;
        }

        return (
            <ImageListItem className={styles.gridListTile} key={item.id} cols={1}>
                <GroupSideBarImagesDialogImageHoverOverlay
                    onOpenButtonClick={onOpenButtonClick}
                    onSettingButtonClick={onSettingButtonClick}
                    onDeleteButtonClick={onDeleteButtonClick} />
                <NetworkMedia src={uri} contentType={item.contentType} />
            </ImageListItem>
        );
    };

    if (!settings) return null;

    return (
        <div className={styles.gridListContainer}>
            <ImageList className={styles.gridList} rowHeight={280} cols={3}>
                {settings.map((item: GroupSettingsSideBarImageItem) => buildGroupAdvertisementsDialogGridListItem(item, styles))}
            </ImageList>
        </div>);
};

export const GroupSideBarImagesAssignmentDialogGridList = ({ groupId, settings, type, onOpenButtonClicked, onAssignmentButtonClicked, onRemoveAssignmentButtonClicked, filterToOnlyAssigend }
    : { groupId: string, settings: GroupSettingsSideBarImageItem[], type: GroupSettingsType, onOpenButtonClicked: any, onAssignmentButtonClicked: any, onRemoveAssignmentButtonClicked: any, filterToOnlyAssigend: any }) => {

    const styles = useGroupSideBarImagesDialogStyles();
    const userId = getUserIdFromStorage();
    const [onlyShowAssigned, setOnlyShowAssigned] = React.useState(false);

    const buildGroupAdvertisementsDialogGridListItem = (item: GroupSettingsSideBarImageItem, styles: any) => {

        const onOpenButtonClick = async () => {
            await onOpenButtonClicked(item);
        };

        const onAssignmentButtonClick = async () => {
            await onAssignmentButtonClicked(item);
        };

        const onRemoveAssignmentButtonClick = async () => {
            await onRemoveAssignmentButtonClicked(item);
        };


        let uri = '';
        switch (type) {
            case GroupSettingsType.Template:
                uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${groupId}/images/sidebars/${item.fileName}`;
                break;
            default:
                uri = `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/images/sidebars/${item.fileName}`;
                break;
        }

        return (
            <ImageListItem className={styles.gridListTile} key={item.id} cols={1}>
                <GroupSideBarImagesAssignmentDialogImageHoverOverlay
                    onOpenButtonClick={onOpenButtonClick}
                    onAssignmentButtonClick={onAssignmentButtonClick}
                    onRemoveAssignmentButtonClick={onRemoveAssignmentButtonClick} />
                <NetworkMedia src={uri} contentType={item.contentType} />
            </ImageListItem>
        );
    };

    if (!settings) return null;

    return (
        <div className={styles.gridListContainer}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={onlyShowAssigned}
                        onChange={(event: any) => {
                            filterToOnlyAssigend(event.target.checked);
                            setOnlyShowAssigned(event.target.checked);
                        }}
                        name="onlyShowAssigned"
                        color="primary"
                    />
                }
                label="Zeige nur noch nicht zugewiesene"
            />
            <ImageList className={styles.gridList} rowHeight={280} cols={3}>
                {settings.map((item: GroupSettingsSideBarImageItem) => buildGroupAdvertisementsDialogGridListItem(item, styles))}
            </ImageList>
        </div>);
};