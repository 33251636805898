import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const assignLicenseObjectStandaloneToObject = async (objectUnitId: string, licenseId: string, token: string, enqueueSnackbar: any) => {

    if (!token || !enqueueSnackbar) return;
    let userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/licenses/${licenseId}/objects/${objectUnitId}/assign-standalone-license`,null,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Standalone Lizenz Objekt wurde dem Objekt erfolgreich zugewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};