import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close, Room, Battery6Bar, Battery90, Hub, Router, SupervisedUserCircle, FeaturedVideo, TabletAndroid, HowToReg, SettingsInputHdmi, CleanHands, AddTask, Map, LibraryAdd, QrCodeScanner, Visibility, QrCode, Sensors, Cancel, Publish } from "@mui/icons-material";
import React, { useContext } from "react";
import { api } from "../../../api";
import { getApiToken, setStorageForDrawer } from "../../../helpers/storage-management";
import { GroupLicenseTable, SensorLicense } from "../../../models/GroupLicenseTable";
import { GroupLicense } from "../../../models/GroupLicense";
import { useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";
import { UserKey } from "../../../models/UserKey";
import { handleSelectedCombinationGroup } from "../../tables/Base";
import { green, red, white, yellow } from "../../../styles/colors";
import { UserAccount } from "../../../models/UserAccount";
import { UserType } from "../../../models/UserType";
import { UserInvoice } from '../../../models/UserInvoice';
import { LicenseType } from '../../../models/LicenseType';
import { GroupSelectionTable } from '../../tables/GroupSelectionTable';
import { AssignLicenseChoosingTableDialog, ShowCountType } from '../../dialogs/AssignLicenseChoosingTableDialog';
import { LicensesAdminTable } from '../../tables/LicensesAdminTable';
import { ConfirmationDialog } from "../../dialogs/ConfirmationDialog";
import { AssignSensorLicenseChoosingTableDialog } from "../../dialogs/AssignSensorLicenseChoosingTableDialog";
import { LoadingIcon } from "../../common/LoadingIcon";
import { useQuery } from "./checkout/ShopPage";
import { GlobalContext } from "../PortalPage";
import { LicenseWhite } from "../../../assets";
import { AssignMultipleLicenseChoosingTableDialog } from "../../dialogs/AssignMultipleLicenseChoosingTableDialog";
import { DefaultDialogTitle } from "../../dialogs/DefaultDialogTitle";
import { useTranslation } from "react-i18next";

export const LicenseManagementGeneral = ({
    selectedCombinationGroup,
    setSelectedCombinationGroup,
    userAccount,
    selectedGroupId }
    : {
        selectedCombinationGroup: GroupLicenseTable[],
        setSelectedCombinationGroup: any,
        userAccount: UserAccount | undefined,
        selectedGroupId: string
    }) => {

    const history = useHistory();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const globals: any = useContext(GlobalContext);

    const [token, setToken] = React.useState("");
    const [backdropLoading, setBackdropLoading] = React.useState(false);
    const [checkpointCountAdd, setCheckpointCountAdd] = React.useState(1);
    const [freeLicenses, setFreeLicenses] = React.useState<GroupLicense[]>([]);
    const [checkpointLicenses, setCheckpointLicenses] = React.useState<GroupLicense[]>([]);
    const [qualityCheckLicenses, setQualityLicenses] = React.useState<GroupLicense[]>([]);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [userKeys, setUserKeys] = React.useState<UserKey[]>([]);
    const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | null>(null);
    const [invoices, setInvoices] = React.useState<UserInvoice[]>([]);


    //multiple dialog

    const [loading, setLoading] = React.useState(false);

    const chosenUnit = useQuery(history).get("chosenUnit");
    const navigationForAddingCheckpoint = useQuery(history).get("navigationForAddingCheckpoint");
    const [isAllGroupsAccordionExpandedLicenseTable, setIsAllGroupsAccordionExpandedLicenseTable] = React.useState(false);


    const [assignMultipleCheckpoints, setAssignMultipleCheckpoints] = React.useState(false);
    const [openAllGroupsLicenseDialog, setOpenAllGroupsLicenseDialog] = React.useState(false);
    const {t} = useTranslation();



    const loadDataAsync = async () => {

        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setSelectedGroup(null);
        setSelectedGroups([]);
        setLoading(true);

        const licenses = await api.getLicensesAsync(tempToken);
        setFreeLicenses(licenses.filter(el => !el.isUsed && el.type != LicenseType.ObjectNotificationLog));
        setCheckpointLicenses(licenses.filter(el => (el.checkpointCount > 0)));
        setQualityLicenses(licenses.filter(el => el.qualityCheckCount > 0));

        const tempGroups = await api.getAllGroups(tempToken);
        setGroups(tempGroups);
        let isGroupPreChosen = tempGroups.find(el => el.id == chosenUnit);
        if (isGroupPreChosen) {
            setSelectedGroup(isGroupPreChosen);
            setSelectedGroups([isGroupPreChosen]);
        }




        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();

            if (navigationForAddingCheckpoint) {
                setAssignMultipleCheckpoints(false);
            }
        })();
    }, []);

    React.useEffect(() => {
        setSelectedGroups(selectedCombinationGroup.map((el: any) => el.id));
    }, [selectedCombinationGroup]);




    const navigateToGroup = (currentGroup: GroupLicenseTable) => {

        globals.onUpdateDisplayGroup(currentGroup);

    }

    const navigateByLicenseTable = (selectedGroup: GroupLicenseTable) => {
        if (!selectedGroup) return;
        navigateToGroup(selectedGroup);
    }




    const buildAllGroupsPanel = () => {
        return (
            <Grid item xs={12}>
                <Accordion expanded={!!chosenUnit || isAllGroupsAccordionExpandedLicenseTable} >
                    <AccordionSummary onClick={() => { setIsAllGroupsAccordionExpandedLicenseTable(!isAllGroupsAccordionExpandedLicenseTable) }} expandIcon={<ExpandMore />}                    >
                        <Typography component="h4" variant="h4">{t("license-general.allLicensedUnits")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AllLicensedGroups loading={loading} groups={groups} selectedGroup={selectedGroup} navigateByLicenseTable={navigateByLicenseTable} selectedGroups={selectedGroups} setSelectedCombinationGroup={setSelectedCombinationGroup} freeLicenses={freeLicenses} setSelectedGroups={setSelectedGroups} setSelectedGroup={setSelectedGroup} loadDataAsync={loadDataAsync} setGroups={setGroups} qualityCheckLicenses={qualityCheckLicenses} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const buildAllGroupsLicenseDialog = ({ open, setOpen, onRefresh }
        : { open: boolean, setOpen: any, onRefresh: any }) => {

        const handleClose = () => setOpen(false);

        return (
            <Dialog
                fullScreen
                onClose={handleClose}
                open={open}
            >
                <DefaultDialogTitle
                    title="Alle Lizenzierte Einheiten"
                    handleClose={handleClose}
                />
                <DialogContent dividers>
                    <AllLicensedGroups loading={loading} groups={groups} selectedGroup={selectedGroup} navigateByLicenseTable={navigateByLicenseTable} selectedGroups={selectedGroups} setSelectedCombinationGroup={setSelectedCombinationGroup} freeLicenses={freeLicenses} setSelectedGroups={setSelectedGroups} setSelectedGroup={setSelectedGroup} loadDataAsync={loadDataAsync} setGroups={setGroups} qualityCheckLicenses={qualityCheckLicenses} />
                </DialogContent>
                <DialogActions>
                    <Button
                        size="large"
                        startIcon={<Cancel />}
                        variant={'outlined'}
                        onClick={handleClose}
                        disabled={loading}
                        color="primary"
                    >
                        {t("myUnitsSettings.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    return (
        <Grid
            container
            direction="row"
            spacing={3}
        >
            <Grid xs={12} item>
                <Typography component="h2" variant="h2">{t("license-general.licenseOverview")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider></Divider>
            </Grid>

            {buildAllGroupsPanel()}

            {

                freeLicenses &&
                <Grid item xs={12} lg={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}                    >
                            <Typography component="h4" variant="h4">{t("license-general.freeLicenses")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                freeLicenses &&
                                freeLicenses.length > 0 &&
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                        <LicensesAdminTable
                                            licenses={freeLicenses}
                                            setLicenses={setFreeLicenses}
                                            user={userAccount}
                                            onComplete={loadDataAsync}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {
                                freeLicenses &&
                                freeLicenses.length === 0 &&
                                <Paper className="Paper">
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <Typography
                                                component="h5"
                                                variant="h5"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {t("license-general.youHaveNoLicensesToAssignPleasBuyANewLicenseInOurShop")}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                endIcon={<ShoppingCart />}
                                                onClick={() => {
                                                    history.push(`/portal/shop`);
                                                }}
                                            >
                                                {t("license-general.toShop")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {buildAllGroupsLicenseDialog({ open: openAllGroupsLicenseDialog, setOpen: setOpenAllGroupsLicenseDialog, onRefresh: loadDataAsync })}
        </Grid >
    );
}

export const AllLicensedGroups = ({ loading, groups, selectedGroup, navigateByLicenseTable, selectedGroups, setSelectedCombinationGroup, freeLicenses, setSelectedGroups, setSelectedGroup, loadDataAsync, setGroups, qualityCheckLicenses }:
    { loading: boolean, groups: GroupLicenseTable[], selectedGroup: any, navigateByLicenseTable: any, selectedGroups: any[], setSelectedCombinationGroup: any, freeLicenses: GroupLicense[], setSelectedGroups: any, setSelectedGroup: any, loadDataAsync: any, setGroups: any, qualityCheckLicenses: GroupLicense[] }) => {
    const [assignLoraSensorDialogVisible, setAssignLoraSensorDialogVisible] = React.useState(false);
    const [assignLoraSensorLicenseChooserDialogVisible, setAssignLoraSensorLicenseChooserDialogVisible] = React.useState(false);
    const classes = useStyles();
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { userAccount }: any = useContext(GlobalContext);
    const [assignQualityCheckLicenseDialogVisible, setAssignQualityCheckLicenseDialogVisible] = React.useState(false);
    const [assignMultipleQualityCheckLicenseDialogVisible, setAssignMultipleQualityCheckLicenseDialogVisible] = React.useState(false);
    const [selectedLicense, setSelectedLicense] = React.useState<GroupLicense>();
    const [token, setToken] = React.useState("");
    const history = useHistory();
    const {t} = useTranslation();

    const assignLicense = async (selectedLicense: GroupLicense, setVisibleDialog: any = null) => {
        if (!selectedLicense || !selectedGroup) return;

        if (setVisibleDialog) setVisibleDialog(false);

        await api.assignLicenseToGroup(selectedGroup.id, selectedLicense, token, enqueueSnackbar);
        await loadDataAsync();
    }

    const assignLicenseToSpecificGroup = async (selectedLicense: GroupLicense, currentGroup: GroupLicenseTable) => {
        await api.assignLicenseToGroup(currentGroup.id, selectedLicense, token, enqueueSnackbar);
    }

    React.useEffect(() => {
        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);
    }, [])

    return (
        <>
            {loading && <LoadingIcon loading={loading} icon={null} />}
            {
                groups.length > 0 && !loading &&
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <Button
                            disabled={!selectedGroup}
                            variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<Navigation />}
                            onClick={() => {
                                if (!selectedGroup) return;
                                navigateByLicenseTable(selectedGroup);
                            }}
                        >
                            {t("license-general.navigateToUnit")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={selectedGroups.length < 2}
                            variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<MergeType />}
                            onClick={() => {
                                handleSelectedCombinationGroup(selectedGroups, groups, setSelectedCombinationGroup, history);
                            }}
                        >
                            {t("license-general.combineUnits")}
                        </Button>
                    </Grid>
                    {
                        userAccount?.userType == UserType.Admin &&
                        selectedGroups.length > 0 &&
                        <Grid item>
                            <Button
                                className={classes.deleteButton}
                                variant="contained"
                                size="large"
                                startIcon={<Close />}
                                disabled={selectedGroups.length < 1}
                                onClick={() => {

                                    if (selectedGroups.length < 1) return;
                                    setDeleteConfirmationDialogVisible(true);
                                }}
                            >
                                {t("license-general.deleteUnit")}
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} container spacing={3}>
                        {selectedGroups.length < 2 &&
                            <Grid item lg={3}>
                                <Button
                                    disabled={!selectedGroup}
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    color="primary"
                                    startIcon={LicenseWhite()}
                                    endIcon={<Sensors />}
                                    onClick={() => {
                                        setAssignLoraSensorDialogVisible(true);
                                    }}
                                >
                                    {t("license-general.assignLoraSensorLicense")}
                                </Button>
                            </Grid>
                        }
                        {selectedGroups.length < 2 &&
                            <Grid item lg={3}>
                                <Button
                                    disabled={!selectedGroup}
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    color="primary"
                                    startIcon={LicenseWhite()}
                                    endIcon={<SupervisedUserCircle />}
                                    onClick={() => {
                                        setAssignQualityCheckLicenseDialogVisible(true);
                                    }}
                                >
                                    {selectedGroup && selectedGroup.qualityCheckLicenseIsValid ? t("license-general.extendQualityCheck") : t("license-general.assignQualityCheck")}
                                </Button>
                            </Grid>
                        }
                        {selectedGroups.length > 1 &&
                            <Grid item lg={3}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    color="primary"
                                    startIcon={LicenseWhite()}
                                    endIcon={<SupervisedUserCircle />}
                                    onClick={() => {
                                        setAssignMultipleQualityCheckLicenseDialogVisible(true);
                                    }}
                                >
                                    {t("license-general.assignQualityCheckFor",{title: selectedGroups.length})}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item style={{ maxWidth: '100%' }}>
                        <GroupSelectionTable
                            groups={groups}
                            selectedGroups={selectedGroups}
                            onSelectionChange={(selection: string[]) => {
                                setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
                                setSelectedGroup(selection.length === 1 ? groups.filter(x => selection.some(s => s === x.id))[0] : null);
                            }}
                        />
                    </Grid>
                </Grid>
            }
            {
                (!groups || groups.length === 0) &&
                <Paper className="Paper">
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography component="h5" variant="h5"
                            >
                                {t("license-general.youHaveNotYetAssignedAnyLicencesInstallADeviceNow")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            }
            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.LoraSensor}
                visible={assignLoraSensorDialogVisible}
                licenses={freeLicenses.filter(x => x.type === LicenseType.LoraSensorExtension)}
                onClose={() => {
                    setAssignLoraSensorDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    if (!selectedLicense || !selectedGroup) return;
                    setAssignLoraSensorDialogVisible(false);
                    if (selectedLicense.loraSensorCount > 0) {
                        setSelectedLicense(selectedLicense);
                        setAssignLoraSensorLicenseChooserDialogVisible(true);
                    } else {
                        await assignLicense(selectedLicense, setAssignLoraSensorDialogVisible);
                    }
                }}
            />
            {selectedGroup && selectedLicense && selectedGroup.loraSensorLicenses &&
                <AssignSensorLicenseChoosingTableDialog
                    visible={assignLoraSensorLicenseChooserDialogVisible}
                    licenses={selectedGroup.loraSensorLicenses}
                    currentSelectedLicense={selectedLicense}
                    sensorCount={selectedLicense.loraSensorCount}
                    onClose={() => {
                        setAssignLoraSensorLicenseChooserDialogVisible(false);
                    }}
                    onLicenseSelected={async (selectedSensorLicenses: SensorLicense[]) => {
                        selectedLicense.loraSensorLicenses = selectedSensorLicenses;
                        if (selectedLicense) await assignLicense(selectedLicense, setAssignLoraSensorLicenseChooserDialogVisible);
                    }}
                />
            }
            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title={`Sind Sie sicher, die ausgewählten Einheiten zu löschen?`}
                onClose={() => setDeleteConfirmationDialogVisible(false)}
                onConfirmed={async () => {

                    if (selectedGroups.length < 1) return;

                    const token = getApiToken(history);
                    for (let currentGroup of selectedGroups) {
                        await api.deleteGroupAsync(currentGroup.id, token, enqueueSnackbar);
                    }
                    setGroups(groups.filter(x => !selectedGroups.some(y => y.id == x.id)));

                    setDeleteConfirmationDialogVisible(false);
                }}
            />
            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.QualityCheck}
                visible={assignQualityCheckLicenseDialogVisible}
                licenses={qualityCheckLicenses}
                onClose={() => {
                    setAssignQualityCheckLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    assignLicense(selectedLicense, setAssignQualityCheckLicenseDialogVisible);
                }}
            />
            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.QualityCheck}
                visible={assignMultipleQualityCheckLicenseDialogVisible}
                licenses={qualityCheckLicenses}
                onClose={() => {
                    setAssignMultipleQualityCheckLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.qualityCheckCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultipleQualityCheckLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />
        </>

    )
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));