import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useRef } from 'react';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { ReactComponent as MotionSensorSvg } from '../../../../assets/svgs/motion-sensor.svg';
import { GridExporter } from '@devexpress/dx-react-grid-export';

import { BarChart, Check, CheckCircle, Edit, Error, NavigateNext, PieChart, Sensors, Settings, SwitchCamera } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { formatEuropeanDate, formatEuropeanDateTime } from '../../../../helpers/date-management';
import { GroupLicenseTable, SensorLicense } from '../../../../models/GroupLicenseTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { mapToiletTypeKey } from '../../../tables/Base';
import { toiletTypePredicate } from '../../../tables/components/Cells';
import { buildCellObject } from '../serviceWorker/UserServiceWorkerManagementPageDataTable';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupSelectionAssignGroupToPropertyDialog } from './GroupSelectionAssignGroupToPropertyDialog';
import { GroupSelectionAssignGroupToObjectDialog } from './GroupSelectionAssignGroupToObjectDialog';
import { GroupSelectionAssignGroupToServiceWorkerDialog } from './GroupSelectionAssignGroupToServiceWorkerDialog';
import { GroupSettingsQuickEditDialog } from './GroupSettingsQuickEditDialog';
import { api } from '../../../../api';
import { useSnackbar } from 'notistack';
import { UserGroupSettingsTemplate } from '../../../../models/UserGroupSettingsTemplate';
import { Group } from '../../../../models/Group';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { buildCellAssignedColumn } from '../../../tables/GroupSelectionTable';
import { useTranslation } from 'react-i18next';
import { getStorage, setStorage } from '../../../../helpers/storage-management';
import { GLOBAL_VISIBILITY_SettingsChooserManagementTable } from '../../../../constants';


export const SettingsChooserManagementTable = ({ groups, selectedGroups, onSelectionChange, properties,
    selectedObjectId, selectedServiceWorkerId, objects, userServiceWorkers, token,
    userGroupSettingsTemplate, onEditSettings, onRefresh, onEditSettingsInDialog, filters, setFilters }
    : {
        groups: GroupLicenseTable[], selectedGroups: GroupLicenseTable[], onSelectionChange: any, properties: UserProperty[],
        selectedObjectId?: string | undefined, selectedServiceWorkerId?: string | undefined, objects?: ObjectUnit[] | undefined, userServiceWorkers: UserServiceWorker[], token: string,
        userGroupSettingsTemplate: UserGroupSettingsTemplate[], onEditSettings: any, onRefresh: any, onEditSettingsInDialog: any, filters: any, setFilters: any
    }) => {



    const exporterRef: any = useRef(null);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Gesamttabelle');
        });
    };

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [groupEditDialogVisible, setGroupEditDialogVisible] = React.useState(false);
    const [hiddenColumnNames, setHiddenColumnNames] = React.useState<any>([]);


    const [currentSelectedGroup, setCurrentSelectedGroup] = React.useState<GroupLicenseTable>();

    React.useEffect(() => {
        setHiddenColumnNames(getStorage<string[]>(GLOBAL_VISIBILITY_SettingsChooserManagementTable) || [])
    }, [])

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: GroupLicenseTable, value: any } = props;
        if (column.name === GroupLicenseTable.Columns.sensorLicenseValidUntil && row.sensorLicenses) {
            return buildCellStringArray(props, row.sensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)), `${t("myObjectSettings.contains")} ${row.sensorLicenses.length} Sensor Lizenzen`, null,
                row.sensorLicenses.map(x => <MotionSensorSvg fill={x.isSensorLicenseExpired ? "#eb4034" : "#2ee656"} />));;
        }
        if (column.name === GroupLicenseTable.Columns.loraSensorLicenseValidUntil && row.loraSensorLicenses) {
            return buildCellStringArray(props, row.loraSensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)), `${t("myObjectSettings.contains")} ${row.loraSensorLicenses.length} Lora Sensor Lizenzen`, null,
                row.loraSensorLicenses.map(x => <Sensors style={{ color: x.isSensorLicenseExpired ? "#eb4034" : "#2ee656" }} />));
        }
        if (column.name == GroupLicenseTable.Columns.currentAssignedObject) {
            return buildCellObject(objects?.find(x => x.id == row.objectUnitId), props, () => {
                setCurrentSelectedGroup(row);
            });
        }
        if (column.name == GroupLicenseTable.Columns.assignedObject) {
            return buildCellAssignedColumn(row.objectUnitId == selectedObjectId, props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedServiceWorker && selectedServiceWorkerId) {
            return buildCellAssignedColumn(row.userServiceWorkerIds.includes(selectedServiceWorkerId), props);
        }
        if (column.name === GroupLicenseTable.Columns.loraSensors) {
            return buildCellStringArray(props, row.loraSensors.map(x => x.id), `${t("myObjectSettings.contains")} ${row.loraSensors.length} Lora-Sensor`, null,
                row.loraSensors.map(x => <MotionSensorSvg />));;
        }
        if (column.name === GroupLicenseTable.Columns.deviceSensorId && row.sensors) {
            return buildCellStringArray(props, row.sensors.map(x => x.id), `${t("myObjectSettings.contains")} ${row.sensors.length} Sensoren`, null,
                row.sensors.map(x => <MotionSensorSvg />));
        }
        return <Table.Cell {...props} />;
    };

    let columns = [
        {
            name: GroupLicenseTable.Columns.userPropertyName,
            title: t("myUnitsSettings.attributeName"),
            getCellValue: (row: GroupLicenseTable) => row.userPropertyName,
        },

        {
            name: GroupLicenseTable.Columns.objectName,
            title: t("myUnitsSettings.objectName"),
            getCellValue: (row: GroupLicenseTable) => row.objectName,
        },
        {
            name: GroupLicenseTable.Columns.objectCostLocation,
            title: t("myUnitsSettings.objectCostCenter"),
            getCellValue: (row: GroupLicenseTable) => row.objectCostLocation,
        },
        {
            name: GroupLicenseTable.Columns.objectDescription,
            title: t("myUnitsSettings.objectDescription"),
            getCellValue: (row: GroupLicenseTable) => row.objectDescription,
        },
        {
            name: GroupLicenseTable.Columns.levelName,
            title: t("myUnitsSettings.level"),
            getCellValue: (row: GroupLicenseTable) => row.levelName,
        },
        {
            name: GroupLicenseTable.Columns.toiletType,
            title: t("myUnitsSettings.roomType"),
            getCellValue: (row: GroupLicenseTable) => mapToiletTypeKey(row.toiletType),
        },
        {
            name: GroupLicenseTable.Columns.roomType,
            title: t("myUnitsSettings.usageType"),
            getCellValue: (row: GroupLicenseTable) => row.roomType,
        },
        {
            name: GroupLicenseTable.Columns.roomSize,
            title: t("myUnitsSettings.roomSize"),
            getCellValue: (row: GroupLicenseTable) => row.roomSize,
        },
        {
            name: GroupLicenseTable.Columns.uniqueDeviceId,
            title: t("myUnitsSettings.deviceId"),
            getCellValue: (row: GroupLicenseTable) => row.uniqueDeviceId,
        },
        {
            name: GroupLicenseTable.Columns.deviceLicenseValidUntil,
            title: t("myUnitsSettings.displayValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.deviceLicenseIsValid ? (row.deviceLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.deviceLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.adLicenseValidUntil,
            title: t("myUnitsSettings.advertisingLicenseValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.adLicenseIsValid ? (row.adLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.adLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.loraSensorLicenseValidUntil,
            title: t("myUnitsSettings.loraSensorLicensesValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.loraSensorLicenses ? row.loraSensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)).join(',') : "Keine"
        },
        {
            name: GroupLicenseTable.Columns.sensorLicenseValidUntil,
            title: t("myUnitsSettings.sensorLicensesValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.sensorLicenses ? row.sensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)).join(',') : "Keine"
        },
        {
            name: GroupLicenseTable.Columns.deviceSensorId,
            title: t("myUnitsSettings.sensorId"),
            getCellValue: (row: GroupLicenseTable) => row?.sensors?.map(x => x.id).join(","),
        },
        {
            name: GroupLicenseTable.Columns.groupCreatedDate,
            title: t("myUnitsSettings.unitActivatedOn"),
            getCellValue: (row: GroupLicenseTable) => formatEuropeanDateTime(new Date(row.groupCreatedDate))
        }
    ];

    let columnWidth = Object.keys(GroupLicenseTable.Columns).map(el => {
        if (el == GroupLicenseTable.Columns.currentAssignedObject || el == GroupLicenseTable.Columns.loraSensorLicenseValidUntil || (el == GroupLicenseTable.Columns.sensorLicenseValidUntil)) {
            return { columnName: el, width: 400 }
        }
        if (el == GroupLicenseTable.Columns.userPropertyName) {
            return { columnName: el, width: 220 }
        }
        if (el == GroupLicenseTable.Columns.currentAssignedServiceWorkers) {
            return { columnName: el, width: 400 }
        }
        if (el == GroupLicenseTable.Columns.actions) {
            return { columnName: el, width: 130 }
        }
        if (el == GroupLicenseTable.Columns.assignedTemplate) {
            return { columnName: el, width: 220 }
        }
        return { columnName: el, width: 170 }
    });







    const customerColumn = {
        name: GroupLicenseTable.Columns.roomName,
        title: t("myUnitsSettings.customer"),
        getCellValue: (row: GroupLicenseTable) => row.roomName,
    };
    columns = [customerColumn, ...columns];

    const unitNameColumn = {
        name: GroupLicenseTable.Columns.notificationName,
        title: t("myUnitsSettings.unitName"),
        getCellValue: (row: GroupLicenseTable) => row.notificationName,
    };
    columns = [unitNameColumn, ...columns];


    const groupTypeColumn = {
        name: GroupLicenseTable.Columns.groupType,
        title: t("myUnitsSettings.unitType"),
        getCellValue: (row: GroupLicenseTable) => Group.GetLabelByType(row.groupType),
    };

    columns = [groupTypeColumn, ...columns]

    const editColumn: any = {
        name: GroupLicenseTable.Columns.actions,
        title: t("myUnitsSettings.edit"),
        getCellValue: (row: GroupLicenseTable) => {
            return <>
                <Tooltip title={t("myUnitsSettings.edit")}>
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            onEditSettingsInDialog(row);
                            setCurrentSelectedGroup(row);
                            setGroupEditDialogVisible(true);

                        }}
                        size="large">
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("myUnitsSettings.toSingleEvaluation")}>
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            onEditSettings(row);
                        }}
                        size="large">
                        <PieChart />
                    </IconButton>
                </Tooltip>
            </>;
        }
    };

    const [sorting, setSorting] = React.useState([]);


    return (
        <>
            <Grid
                rows={groups}
                columns={[editColumn, ...columns]}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters}
                />
                <IntegratedFiltering columnExtensions={[
                    { columnName: GroupLicenseTable.Columns.toiletType, predicate: toiletTypePredicate },
                ]} />

                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting as any}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={columnWidth} />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={(names) => {
                        setStorage(GLOBAL_VISIBILITY_SettingsChooserManagementTable, names);
                        setHiddenColumnNames(names);
                    }}

                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <PagingPanel
                    pageSizes={[5, 10, 15, 0]}
                />
            </Grid>
            {currentSelectedGroup &&
                <GroupSettingsQuickEditDialog group={currentSelectedGroup} onRefresh={onRefresh} visible={groupEditDialogVisible} setVisible={setGroupEditDialogVisible} />
            }
            <GridExporter
                ref={exporterRef}
                rows={groups}
                columns={columns}
                sorting={sorting}
                filters={filters}
                onSave={onSave}
                hiddenColumnNames={hiddenColumnNames}

            />
        </>
    );
};