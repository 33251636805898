import axios from 'axios';
import { handleApiError, handleApiSuccess, headers } from '../api';
import { USER_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';



export const telegramCreateGroup = async (groupName: string, token: string, phoneNumber: string, enqueueSnackbar: any) : Promise<any> => {
    try {
        const userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/telegram/${phoneNumber}/group`,
        {
            "title" : groupName
        },
        {headers: {...headers,'Authorization': 'Bearer '+token}})
        handleApiSuccess(ret.data, enqueueSnackbar, "Gruppe wurde erfolgreich erstellt!");
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar, "Konne keine Gruppe erstellen");
    }
    return null as any;
}