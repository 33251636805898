import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { api } from '../../api';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";
import { getApiToken, setUserTokenStorage } from '../../helpers/storage-management';
import { Session } from '../../models/Session';
import { SELECTED_USER_ID_FROM_ADMIN, USER_ID } from '../../constants';
import { Copyright } from '../Copyright';
import { AppBar, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Hidden, IconButton, Paper, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { LoginOutlined } from '@mui/icons-material';
import { lightBlue } from '../../styles/colors';
import NewsHomePng from '../../assets/image/home/news_home.png'
import ShopHomePng from '../../assets/image/home/shop_home.png'
import CheckMeNowIcon from '../../assets/svgs/check-me-now_icon.svg'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const { userId, tokenId } = useParams<any>();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const backgroundColor = lightBlue;

  const heightImage = 250;
  const padding = 50;

  const loggedIn = async (res: Session | undefined) => {
    if (res) {
      localStorage.setItem(USER_ID, res.userId);
      let user = await api.getUser(res.token, res.userId);
      if (user.invitedFromUserId) {
        localStorage.setItem(SELECTED_USER_ID_FROM_ADMIN, user.invitedFromUserId);
      }
      setUserTokenStorage(res.token);
      enqueueSnackbar("Login erfolgreich", { variant: 'success' });
      history.push("/portal");
      return true;
    } else {
      enqueueSnackbar("Login fehlgeschlagen", { variant: 'error' });
      return false;
    }
  }
  React.useEffect(() => {
    (async () => {
      if (tokenId && userId) {
        let res = await api.verifyEmail(userId, tokenId);
        if (await loggedIn(res)) {
          enqueueSnackbar("Email erfolgreich bestätigt", { variant: 'success' });
        } else {
          enqueueSnackbar("Email Bestätigung fehlgeschlagen", { variant: 'error' });
        }
      }
      let token = getApiToken(history, true);
      if (token) history.push("portal");
    })()
  }, []);
  const theme = useTheme();
  const isLGStyle = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Hidden smDown>
        <AppBar position="sticky">
          <Toolbar>
            <Grid item>
              <img style={{ marginRight: 10 }} src={CheckMeNowIcon} height={60} alt="check-me-now-icon" />
            </Grid>
            <Grid item >
              <Typography style={{ flexGrow: 1 }} variant="h6">
                Portal: Check-Me-Now Login!
              </Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}></Grid>
            <Grid item>
              <img src={"https://restroombird.blob.core.windows.net/smile-login-portal/smile-login-portal.png"} style={{ height: 100 }} />
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Grid container direction={"row"} justifyContent={'center'} alignItems="center" style={{height: '80vh'}}>
        <Grid item lg={4} style={{ maxWidth: 500 }}>
          <Card sx={{ backgroundColor: backgroundColor, textAlign: 'center' }}>
            <CardActionArea style={{ padding: padding }}
              onClick={() => {
                history.push({
                  pathname: '/news',
                });
              }}>
              <CardMedia
                style={{ objectFit: 'contain' }}
                component="img"
                height={heightImage}
                image={NewsHomePng}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" fontWeight={'bold'} fontSize={28}>
                  News & INFOs
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item lg={4} sx={{  }}>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography color="primary" style={{ fontWeight: 'bolder', fontSize: 50 }}>Portal Login</Typography>
              <form className={classes.form} noValidate
                onSubmit={async (e) => {
                  alert("test");
                  return e.preventDefault();
                }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  onChange={(text) => setEmail(text.target.value)}
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(text) => setPassword(text.target.value)}
                  autoComplete="password"
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  size='large'
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={32} />}
                  endIcon={<LoginOutlined />}
                  className={classes.submit}
                  onClick={async () => {
                    setLoading(true);
                    let res = await api.login(email, password, enqueueSnackbar);
                    loggedIn(res);
                    setLoading(false);
                  }}
                >
                  Login
                </Button>
                <Grid container spacing={3} justifyContent={'center'}>
                  <Grid item>
                    <Link variant="body2" component={RouterLink} to="/register">
                      {"Jetzt Registrieren!"}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2" component={RouterLink} to="/password-forgotten">
                      Passwort vergessen?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link variant="body2" component={RouterLink} to="/instructions">
                      {"Betriebsanleitungen"}
                    </Link>
                  </Grid>
                  <Grid item xs={12} >

                  </Grid>

                </Grid>
              </form>
            </div>
          </Container>
        </Grid>

        <Grid item lg={4} style={{ maxWidth: 500}}>

          <Card sx={{ backgroundColor: backgroundColor, textAlign: 'center' }}>
            <CardActionArea style={{ padding: padding }}
              onClick={() => {
                history.push("/shop/public");

              }}>
              <CardMedia
                style={{ objectFit: 'contain' }}
                component="img"
                height={heightImage}
                image={ShopHomePng}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" fontWeight={'bold'} fontSize={28}>
                  Shop
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

        </Grid>
        <Grid item lg={12}>
          <Copyright />
        </Grid>
      </Grid>
    </>
  );
}