import axios from 'axios';
import { handleApiError, handleApiSuccess, headers } from '../api';
import { Group } from '../models/Group';


export const createGroupBssdDisplay = async (userId: string, deviceId: string, licenseId: string,userToken: string, enqueueSnackbar:any) : Promise<Group> => {
    try {
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/bssd-devices/${deviceId}/licenses/${licenseId}/groups-bssd`,{
        }, {headers: {...headers,'Authorization': 'Bearer '+userToken}})
        handleApiSuccess(ret.data, enqueueSnackbar, "Battery Smart Service Display (Einheit) wurde erstellt");
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar,"Battery Smart Service Display (Einheit) konnte nicht erstellt werden!");
    }
    return null as any;
}