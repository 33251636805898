import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DataFilterType, PeriodChartFilterType, generateChartData } from '../../helpers/charts/base';
import { InlineDateTimePicker } from './InlineDateTimePicker';
import { Grid, TextField } from '@mui/material';
import { defaultEndDate, defaultPastDays, defaultStartDate } from '../../helpers/date-management';
import { Autocomplete } from '@mui/material';
import { ChartCalcType, setTimeBasedChartData } from './TimeChoice';
import DataFilterTypeChoiceDialog from '../dialogs/DataFilterTypeChoiceDialog';
import { Sensor } from '../../models/GroupSettings';
import { SensorFilterType } from '../authorized/portal/Dashboard/customerUsage/specificSensor/SpecificSensorCharts';
import { useTranslation } from 'react-i18next';

export const TimeSensorChoice = forwardRef(({ data, setChartData, fields, sensor, setCurrentSensor, sensors, chartCalcType = ChartCalcType.Sum, filterSensorType = SensorFilterType.SensorRoomName }: { data: any, setChartData: any, fields: any, sensor: Sensor | undefined, setCurrentSensor: any, sensors: Sensor[], chartCalcType?: ChartCalcType, filterSensorType: SensorFilterType }, ref: any) => {
  useImperativeHandle(
    ref,
    () => ({
      updateEndDateFromParent(date: any) {
        setSelectedEndDate(date);
      },
      updateStartDateFromParent(date: any) {
        setSelectedStartDate(date);
      },
      updateDataFilterTypeFromParent(dataFilterType: any) {
        setDataFilterType(dataFilterType);
      },
      updateChosenPastDays(days: any) {
        setChosenPastDays(parseInt(days));
      }
    }),
  )
  const [value, setValue] = React.useState(PeriodChartFilterType.Day);
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);
  const [chosenPastDays, setChosenPastDays] = React.useState(defaultPastDays);
  const isInitialMount = useRef(true);
  const [dataFilterType, setDataFilterType] = React.useState<DataFilterType>(DataFilterType.StartEnd);
  const { t } = useTranslation();

  React.useEffect(() => {
    changeData(sensor);
  }, [value, selectedEndDate, selectedStartDate, chosenPastDays, dataFilterType]);


  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (event.target.value === PeriodChartFilterType.Hour) {
      setChosenPastDays(1);
    }
    else if (event.target.value === PeriodChartFilterType.Day) {
      setChosenPastDays(10)
    }
    else if (event.target.value === PeriodChartFilterType.Week) {
      setChosenPastDays(30);
    }
    else if (event.target.value === PeriodChartFilterType.Month) {
      setChosenPastDays(90);
    }
  };

  const changeData = (sensor: Sensor | undefined) => {
    if (!sensor) return;
    let filteredUserData = [];
    if (filterSensorType == SensorFilterType.SensorId) {
      filteredUserData = data.filter((el: any) => {
        return el.sensorId === sensor.id
      });
    } else if (filterSensorType == SensorFilterType.SensorRoomName) {
      filteredUserData = data.filter((el: any) => {
        return el.sensorRoomName === sensor.sensorRoomName
      });
    }

    setTimeBasedChartData(dataFilterType, isInitialMount, value, selectedEndDate, selectedStartDate, filteredUserData, fields, chosenPastDays, setChartData, chartCalcType);
  }

  const handleChangeUser = (event: any, value: any) => {
    if (!sensor) return;
    let newsensor = sensors.find(x => x.sensorRoomName == value);
    if (newsensor) {
      setCurrentSensor(newsensor)
      changeData(newsensor);
    }
  }

  const buildFields = () => {
    switch (dataFilterType) {
      case DataFilterType.StartEnd:
        return (
          <Grid container item direction={'row'} spacing={2}>
            <Grid item>
              <InlineDateTimePicker title={t("serviceDocumentationTable.startDate")} selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
          </Grid>
        );
      case DataFilterType.EndDatePastDays:
        return (
          <Grid container item xs={12} direction={'row'}>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 100, marginLeft: 30 }}
                inputProps={{ min: 1, style: {} }}
                id="filled-number"
                label="Tage zurück"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                value={chosenPastDays}
                onChange={(event) => { setChosenPastDays(parseInt(event.target.value)) }}
              />
            </Grid>
          </Grid>
        );
    }
  }

  return (
    <Grid container spacing={2} style={{ padding: 10, paddingBottom: 10 }}>
      {sensor &&
        <Grid item xs={12}>
          <Autocomplete
            id="combo-box-demo"
            options={sensors}
            value={sensor}
            getOptionLabel={(option: Sensor) => option.sensorRoomName}
            onInputChange={handleChangeUser}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Sensor Auswahl" defaultValue={sensor?.sensorRoomName} value={sensor?.sensorRoomName} variant="filled" />}
          />
        </Grid>
      }
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t("dashboard.timeSelection")}</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} row>
            <FormControlLabel value={PeriodChartFilterType.Hour} control={<Radio />} label={t("dashboard.hourly")} />
            <FormControlLabel value={PeriodChartFilterType.Day} control={<Radio />} label={t("dashboard.daily")} />
            <FormControlLabel value={PeriodChartFilterType.Week} control={<Radio />} label={t("dashboard.weekly")} />
            <FormControlLabel value={PeriodChartFilterType.Month} control={<Radio />} label={t("dashboard.monthly")} />
          </RadioGroup>
        </FormControl>
      </Grid>
      <DataFilterTypeChoiceDialog dataFilterType={dataFilterType} setDataFilterType={setDataFilterType} />
      {buildFields()}
    </Grid >
  );
});

export const getUniqueValues = (arr: string[]) => {
  return arr.reduce(
    (accumulator: any, current: any) => {
      if (current && !accumulator.some((x: any) => x === current)) {
        accumulator.push(current)
      }
      return accumulator;
    }, []
  )
}