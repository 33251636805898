import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupFeedback } from '../models/GroupFeedback';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserAddress } from '../models/UserAddress';
import { UserProperty } from '../models/UserProperty';

export const CreateFeedbackLightByGroupId = async (feedback: GroupFeedback, enqueueSnackbar: any): Promise<UserProperty> => {
    try {
        if (!feedback) throw (null);
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/groups/${feedback.groupId}/feedback-light`,
            { ...feedback },
            { headers: { ...headers } })
        enqueueSnackbar("Feedbackmeldung erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Feedbackmeldung error", { variant: 'error' });
    }
    return null as any;
}