import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, AddBox, Close, Delete, ManageAccounts, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable, SensorLicense } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { errorColor, white } from '../../../../styles/colors';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { BatteryDisplaySelectionTable } from './BatteryDisplaySelectionTable';
import { ReactComponent as MotionSensorSvg } from '../../../../assets/svgs/motion-sensor.svg';
import { GroupType } from '../../../../models/Group';
import { AssignLicenseToRegisteredSensorChoosingTableDialog } from '../../../dialogs/AssignLicenseToRegisteredSensorChoosingTableDialog';
import { GroupSettings, GroupSettingsSensorLicense } from '../../../../models/GroupSettings';
import { AssignBssdSensorLicenseToGroupTableDialog } from './AssignBssdSensorLicenseToGroupTableDialog';
import { useTranslation } from 'react-i18next';

export const AddRegisteredSensorToBatteryDisplaySelectionDialog = ({
  open,
  setOpen,
  groups,
  selectedDeviceId,
  onSelected,
  closeFromSensorAdd
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable[],
  selectedDeviceId: string,
  onSelected: any,
  closeFromSensorAdd: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [token, setToken] = React.useState("");
  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
  const [selectedGroupSettings, setSelectedGroupSettings] = React.useState<GroupSettings>();
  const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable>();
  const [currentSelectedSensorLicense, setCurrentSelectedSensorLicense] = React.useState<SensorLicense>();
  const [assignToRegisteredSensorDialogVisible, setAssignToRegisteredSensorDialogVisible] = React.useState(false);
  const {t} = useTranslation();

  const handleClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      if (!open) return;



      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const tempObjects = await api.getUserObjectUnits(tempToken);
      setObjects(tempObjects);

    })();

  }, [open]);

  const disabled = selectedGroups.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Batterie Smart-Service-Displays: Wähle deine Einheit`}
        handleClose={handleClose}
      />

      <DialogContent>
        <BatteryDisplaySelectionTable
          groups={groups.filter(x => x.groupType == GroupType.DisplayBatteryWallApp)} objects={objects}
          selectedGroups={selectedGroups}
          onSelectionChange={(selection: string[]) => {
            let selected = selection[selection.length - 1];
            setSelectedGroups(groups.filter(x => selected === x.id));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<AddBox />}
          disabled={disabled}
          onClick={async () => {
            console.log(selectedGroups, "selectedGroups");
            let settings = await api.getGroupSettings(selectedGroups[0].id,token);
            setSelectedGroupSettings(settings);
            setSelectedGroup(selectedGroups[0]);
            setAssignToRegisteredSensorDialogVisible(true);
          }}
        >
          Sensor zur ausgewählten Einheit hinzufügen
        </Button>
      </DialogActions>
      <AssignBssdSensorLicenseToGroupTableDialog
        visible={assignToRegisteredSensorDialogVisible}
        licenses={selectedGroup?.sensorLicenses || []}
        currentDeviceId={selectedDeviceId}
        sensors={selectedGroupSettings?.sensorControl?.sensors || []}
        onClose={() => {
          setAssignToRegisteredSensorDialogVisible(false);
        }}
        onLicenseSelected={async (selectedLicense: GroupSettingsSensorLicense) => {
          if (!selectedGroup) return;
          await api.addBssdSensorToGroup(selectedGroup?.id, selectedDeviceId, selectedGroup?.sensorLicenses.find(x=>selectedLicense.id == x.id)?.id || "", token, enqueueSnackbar);
          setAssignToRegisteredSensorDialogVisible(false);
          closeFromSensorAdd();
        }}
      />
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));