import axios from 'axios';
import { headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { GroupSettings, LocationInformation } from '../../models/GroupSettings';

export const getGroupSettingsByLocationName = async (location: GroupSettings[], token: string, enqueueSnackbar: any): Promise<GroupSettings[]> => {
    const userId = getUserIdFromStorage();
    return await axios
        .post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/by-location-information`,
            { manyGroupSettings : location },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Get Einheiten erfolgreich", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            enqueueSnackbar("Get Einheiten Error", { variant: 'error' });
        });
}