import axios from "axios";
import { getUserIdFromStorage } from "../../helpers/common";
import { headers } from "../../api";
import { GroupLicenseTable } from "../../models/GroupLicenseTable";

export const patchMultipleGroupSettingsAsync = async (groups: GroupLicenseTable[], token: string, enqueueSnackbar: any) => {

    if (!groups || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/patch-groups-settings`,{groups : groups},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Update Einheit Settings erfolgreich", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Update Einheit Settings fehlgeschlagen", { variant: 'error' });
    });
};
