import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { lightgrey } from "./colors";

export const GroupSettingFieldsUseStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 'normal',
        },
        hover: {
            "&:hover": {
                backgroundColor: lightgrey
            }
        },
        label: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%'
        },
        marginTop: {
            marginTop: '10px'
        },
        cardContent: {
            fontSize: 15,
            padding: '3px'
        },
        gridFloatLeft: {
            float: 'left'
        },
        paper: {
            padding: '6px 16px',
        },
        secondaryTail: {
            backgroundColor: theme.palette.secondary.main,
        },
    }),
);

export const fillAvailable: React.CSSProperties = {
    maxWidth: "-webkit-fill-available",  /* Mozilla-based browsers will ignore this. */
}