
export class NumberHelper {

    static isTextFieldNumber = (value: any) => {
        const regex = /[0-9]+(,[0-9]+)*/g;
        return value === "" || regex.test(value);
    }

    static roundToTwoDigitsAfterComma = (floatNumber:number) => {
        return parseFloat((Math.round(floatNumber * 100) / 100).toFixed(2)) || 0;
    }

    static roundDigitsAfterComma = (floatNumber:number, commaNumber: number) => {
        return Number(floatNumber.toFixed(commaNumber));
    }

}