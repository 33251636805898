

export enum FeedbackQuestionOriginType
{
    Device = 0,
    Public = 1
}
export enum FeedbackQuestionType
{
    Negative = 0,
    Positive = 1
}
export class GroupFeedbackQuestion {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public items!: string[];
    public createdTimestamp!: number;
    public customTexts !: string[];
    public messenger!: string;
    public createdTimestampString !: string;
    public feedbackQuestionOriginType !: FeedbackQuestionOriginType;
    public feedbackQuestionType !: FeedbackQuestionType;


    public constructor(groupId: string, userId: string, items: string[], createdTimestamp: number, customTexts: string[], messenger: string, feedbackQuestionType: FeedbackQuestionType) {
        this.systemETag = null;
        this.groupId = groupId;
        this.userId = userId;
        this.items = items;
        this.createdTimestamp = createdTimestamp;
        this.customTexts = customTexts;
        this.messenger = messenger;
        this.feedbackQuestionType = feedbackQuestionType;
    }


    static Columns = {
        id: 'id',
        groupId: "groupId",
        userId: "userId",
        items: "items",
        createdTimestamp: "createdTimestamp",
        createdTime: "createdTime",
        neutralAmount: "neutralAmount",
        customTexts: "customTexts",
        messenger: "messenger",
        feedbackQuestionOriginType : "feedbackQuestionOriginType",
        feedbackQuestionType: 'feedbackQuestionType'
    }

    static AvailableTypes = [
        
        { value: FeedbackQuestionOriginType.Device, label: 'Display Gerät' },
        { value: FeedbackQuestionOriginType.Public, label: 'Öffentlich' },
    ];

    static FeedbackQuestionscAvailableTypes = [
        
        { value: FeedbackQuestionType.Negative, label: 'Negativ' },
        { value: FeedbackQuestionType.Positive, label: 'Positiv' },
    ];

    static GetLabelByType = (type: FeedbackQuestionOriginType) => GroupFeedbackQuestion.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';

    static GetLabelByFeedbackQuestionType = (type: FeedbackQuestionType) => GroupFeedbackQuestion.FeedbackQuestionscAvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';


}

export class FeedbackQuestionTotal {
    item !: string;
    amount !: number;
}

export class FeedbackQuestionTotalTable {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string
    public items : FeedbackQuestionTotal[] = [];
    public totalAmount !: number;

    public createdTimestamp!: number;
    public createdTimestampString!: string;

    static Columns = {
        id: 'id',
        item: 'item',
        groupId: "groupId",
        levelName: "levelName",
        objectName : "objectName",
        totalAmount: "totalAmount",

    }
}