import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Chair, CloudUpload, Delete, Download, Remove, Save, Visibility } from '@mui/icons-material';
import { UserAccount } from '../../../models/UserAccount';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { StringHelper } from '../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { UserType } from '../../../models/UserType';
import { Autocomplete } from '@mui/material';
import { StandardWorkItem, StandardWorkItemExtras, StandardWorkItemType } from '../../../models/StandardItem';
import { GroupServiceModeIncidentImage, GroupServiceModeIncidentPhoto } from '../../../models/GroupServiceMode';
import { encode } from 'base64-arraybuffer';
import { ItemThing, ItemThingType } from '../../../models/GroupSettings';
import { errorColor, white } from '../../../styles/colors';
import { useTranslation } from 'react-i18next';

export const StandardItemEditingDialog = ({ visible, setVisible, currentStandardItem, onSave, onShowImages, subTitle, standardWorkItemType }
    : { visible: boolean, setVisible: any, currentStandardItem?: StandardWorkItem, onSave: any, onShowImages: any, subTitle: string, standardWorkItemType?: StandardWorkItemType | undefined }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();
    const {t} = useTranslation();
    const [standardItem, setStandardItem] = React.useState<StandardWorkItem | undefined>();

    const [loading, setLoading] = React.useState(false);

    const onClose = () => {
        setStandardItem(undefined);
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    const isValid = () => {
        let valid = true;

        if (standardItem && StringHelper.IsNullOrWhiteSpace(standardItem.text)) {
            enqueueSnackbar("Text pflicht!", { variant: 'error' });
            valid = false;
        }

        return valid;
    }


    React.useEffect(() => {

        (async () => {
            if (currentStandardItem) {
                currentStandardItem.photoContent = [];
                setStandardItem(currentStandardItem);
            } else {
                let newItem = new StandardWorkItem();
                if (standardWorkItemType != undefined) {
                    newItem.itemType = standardWorkItemType;
                }
                newItem.activated = true;
                setStandardItem(newItem);
            }

        })();

    }, [visible, currentStandardItem])

    if (!standardItem) return <Typography>Loading</Typography>;

    const buidRadioButtons = () => {

        switch (standardWorkItemType) {
            case StandardWorkItemType.RoomType:
                return (
                    <Grid container direction={"column"} item xs={12} spacing={3}>
                        <Grid item>
                            <RadioGroup
                                row
                                aria-label="gender"
                                name="contentCode"
                                value={standardItem.itemType}
                                onChange={(event: any) => {
                                    setStandardItem({ ...standardItem, itemType: parseInt(event.target.value) })
                                }}
                            >
                                <FormControlLabel
                                    value={StandardWorkItemType.RoomType}
                                    control={<Radio />}
                                    label="Raumart"
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Add />}
                                variant={'contained'}
                                color="primary"
                                onClick={async () => {
                                    let newItem = new StandardWorkItemExtras("", 0);
                                    standardItem.extras.push(newItem);
                                    setStandardItem({ ...standardItem })
                                }}
                            >
                                Nutzungskomponente Hinzufügen
                            </Button>
                        </Grid>
                        <Grid item container direction={"column"} spacing={3}>
                            {standardItem?.extras?.map(extra => {
                                return (

                                    <Grid item container direction={"row"} spacing={3} alignItems={"center"}>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                onClick={() => {
                                                    setStandardItem({ ...standardItem, extras: standardItem.extras.filter(x => !(x.text == extra.text && x.type == extra.type)) })
                                                }}>
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                required
                                                variant="outlined"
                                                margin="normal"
                                                label={"Nutzungskomponente"}
                                                disabled={loading}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"><Chair /></InputAdornment>
                                                }}
                                                value={extra.text}
                                                onChange={(event: any) => {
                                                    extra.text = event.target.value;
                                                    setStandardItem({ ...standardItem });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <RadioGroup
                                                row
                                                aria-label="gender"
                                                name="contentCode"
                                                value={extra.type}
                                                onChange={(event: any) => {
                                                    extra.type = event.target.value;
                                                    setStandardItem({ ...standardItem });
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={ItemThingType.MainUsageComponent}
                                                    control={<Radio />}
                                                    label={ItemThing.getItemThingTypeText(ItemThingType.MainUsageComponent,t)}
                                                />
                                                <FormControlLabel
                                                    value={ItemThingType.RestInventory}
                                                    control={<Radio />}
                                                    label={ItemThing.getItemThingTypeText(ItemThingType.RestInventory,t)}
                                                />
                                                <FormControlLabel
                                                    value={ItemThingType['Walls&Covers']}
                                                    control={<Radio />}
                                                    label={ItemThing.getItemThingTypeText(ItemThingType['Walls&Covers'],t)}
                                                />
                                                <FormControlLabel
                                                    value={ItemThingType.Floor}
                                                    control={<Radio />}
                                                    label={ItemThing.getItemThingTypeText(ItemThingType.Floor,t)}
                                                />
                                                <FormControlLabel
                                                    value={ItemThingType.HardToSeeThings}
                                                    control={<Radio />}
                                                    label={ItemThing.getItemThingTypeText(ItemThingType.HardToSeeThings,t)}
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                )
            case StandardWorkItemType.Material:
                return (
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            aria-label="gender"
                            name="contentCode"
                            value={standardItem.itemType}
                            onChange={(event: any) => {
                                setStandardItem({ ...standardItem, itemType: parseInt(event.target.value) })
                            }}
                        >
                            <FormControlLabel
                                value={StandardWorkItemType.Material}
                                control={<Radio />}
                                label="Material"
                            />
                        </RadioGroup>
                    </Grid>
                )
            default:
                return (
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            aria-label="gender"
                            name="contentCode"
                            value={standardItem.itemType}
                            onChange={(event: any) => {
                                setStandardItem({ ...standardItem, itemType: parseInt(event.target.value) })
                            }}
                        >
                            <FormControlLabel
                                value={StandardWorkItemType.Serivce}
                                control={<Radio />}
                                label="Service"
                            />
                            <FormControlLabel
                                value={StandardWorkItemType.Material}
                                control={<Radio />}
                                label="Material"
                            />
                            <FormControlLabel
                                value={StandardWorkItemType.Incident}
                                control={<Radio />}
                                label="Vorfall"
                            />
                            <FormControlLabel
                                value={StandardWorkItemType.FeedbackQuestion}
                                control={<Radio />}
                                label="Feedbackfrage"
                            />
                        </RadioGroup>
                    </Grid>
                )

        }

    }


    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title={`${subTitle} bearbeiten`}
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">Details</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                id="filled-name"
                                InputLabelProps={{ shrink: true }}
                                label="Text"
                                variant="filled"
                                value={standardItem.text}
                                onChange={(event: any) => {
                                    setStandardItem({ ...standardItem, text: event.target.value })
                                }}
                            />
                        </Grid>
                        {standardWorkItemType == StandardWorkItemType.Material &&
                            <Grid item xs={12} lg={12}>
                                <TextField
                                    fullWidth
                                    id="filled-name"
                                    InputLabelProps={{ shrink: true }}
                                    label="Material Einheit"
                                    variant="filled"
                                    value={standardItem.entity}
                                    onChange={(event: any) => {
                                        setStandardItem({ ...standardItem, entity: event.target.value })
                                    }}
                                />
                            </Grid>
                        }
                        {standardWorkItemType == StandardWorkItemType.Material &&
                            <Grid item xs={12} lg={12}>
                                <TextField
                                    fullWidth
                                    id="filled-name"
                                    InputLabelProps={{ shrink: true }}
                                    label="Artikelnummer"
                                    variant="filled"
                                    value={standardItem.article}
                                    onChange={(event: any) => {
                                        setStandardItem({ ...standardItem, article: event.target.value })
                                    }}
                                />
                            </Grid>
                        }
                        <Grid item>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                startIcon={<CloudUpload />}
                                component="label">
                                Bilder oder Videos hochladen
                                <input
                                    type="file"
                                    name="uploadAdvertisements"
                                    onChange={async (event) => {

                                        if (event.target.files && event.target.files.length) {
                                            file2Base64(event.target.files[0]);
                                            for (var i = 0; i < event.target.files.length; i++) {
                                                let base64 = encode(await (event.target.files[i].arrayBuffer()));

                                                let newItem = new GroupServiceModeIncidentImage(base64, "");
                                                newItem.originalFilename = event.target.files[i].name;
                                                newItem.contentType = event.target.files[i].type;
                                                standardItem.photoContent.push(newItem);
                                            }
                                            setStandardItem({ ...standardItem });
                                        }
                                    }
                                    }
                                    hidden
                                    accept="image/png, image/jpeg, image/jpg, image/gif, video/mp4"
                                    multiple />
                            </Button>
                        </Grid>
                        {buidRadioButtons()}
                        {standardItem.photoContent &&
                            <Grid item>
                                <Typography fontWeight={'bold'} color='primary'>{`Neue Dateien hochladen: ${standardItem?.photoContent?.length || 0} `}</Typography>
                                {standardItem.photoContent.map((x, index) => {
                                    return (
                                        <Typography fontWeight={'bold'} color='primary'>{`Datei ${index + 1}: `}{x.originalFilename}</Typography>
                                    )
                                })}
                            </Grid>
                        }
                        {standardItem?.photos &&
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Typography fontWeight={'bold'} color='primary'>{`Aktuelle Dateien hochgeladen: ${standardItem?.photos?.length || 0}`}</Typography>
                                    </Grid>

                                    {standardItem.photos.map((photo, index) => {
                                        return (
                                            <Grid item container spacing={3} alignItems="center" >
                                                <Grid item>
                                                    <Typography fontWeight={'bold'} color='primary'>{`Datei ${index + 1}: `}{photo.fileName}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        endIcon={<Visibility />}
                                                        onClick={() => {
                                                            let newItem = new StandardWorkItem();
                                                            newItem.photos = [photo];
                                                            newItem.id = standardItem.id;
                                                            onShowImages(newItem);
                                                        }}
                                                    >
                                                        Anzeigen
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        size="small"
                                                        endIcon={<Delete />}
                                                        onClick={() => {
                                                            standardItem.photos = standardItem.photos.filter(x => x.fileName != photo.fileName);
                                                            setStandardItem({ ...standardItem });

                                                        }}
                                                    >
                                                        {t("shared-folders-objects-all.removeFile")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid item>
                                        <Button variant='contained' endIcon={<Download />} onClick={() => {
                                            onShowImages(standardItem);
                                        }}>
                                            Zeige Alle Files
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={standardItem.activated}
                                        onChange={(event: any) => { setStandardItem({ ...standardItem, activated: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label={`${subTitle} Aktiviert`}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        await onSave(standardItem);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = error => reject(error);
    })
}