import React, { useContext, useRef } from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FileDownload, FindReplace, Print, Refresh, Save, SdStorage, Shop } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { api } from '../../../../../api';
import { getApiToken } from '../../../../../helpers/storage-management';
import { StringHelper } from '../../../../../helpers/string-helper';
import { GroupSettings } from '../../../../../models/GroupSettings';
import { NavigatedFromType } from '../../../../../models/NavigatedFrom';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { usePortalAccountStyles } from '../../../../../styles/usePortalAccountStyles';
import { DefaultDialogTitle } from '../../../../dialogs/DefaultDialogTitle';
import { DigitalCheckpointDialog } from '../../../../dialogs/groupSettings/DigitalCheckpointDialog';
import { GroupLogosDialog } from '../../../../dialogs/GroupLogosDialog';
import { UserLogo } from '../../../../../models/UserLogo';
import { handleDownloadImage, noNullInInputNumber } from '../../../../common/qrcode/qrcodeManagement';
import { useReactToPrint } from 'react-to-print';
import IconCMN from '../../../../../assets/svgs/check-me-now_icon.png'
import { primaryColor } from '../../../../../App';
import { ChromePicker } from 'react-color';
import saveAs from 'file-saver';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { buildPrintItemGroup } from '../../../../core/GroupSettingFieldsLocation';
import { GlobalContext } from '../../../PortalPage';
import { useTranslation } from 'react-i18next';

export enum QrCodeObjectType {
    Address = 0,
    Description = 1,
}

export class QrCodeGroupUnit {
    public qrCode !: any;
    public groupField1 !: string;
    public groupField2 !: string;
    public group !: GroupSettings;
    public object: ObjectUnit | undefined;
    public getRefPrint !: any;
    public logo !: any;
}

export const GroupsQrCodePrintManyDialog = ({ visible, setVisible, groups, objects }
    : { visible: boolean, setVisible: any, groups: GroupLicenseTable[], objects: ObjectUnit[] }) => {

    const classes = usePortalAccountStyles();
    const { sourceLogoUser
    }: any = useContext(GlobalContext);
    const [currentObject, setCurrentObject] = React.useState<ObjectUnit>(new ObjectUnit());

    const [qrCodes, setQrCodes] = React.useState<QrCodeGroupUnit[]>([]);

    const [groupSettings, setGroupSettings] = React.useState<GroupSettings>();
    const history = useHistory();
    const [qrCodeObjectType, setQrCodeObjectType] = React.useState(QrCodeObjectType.Address);

    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    const refPrint: any = useRef();

    const [sizeQrCode, setSizeQrCode] = React.useState(250);
    const [fontSizeSpecQrCode, setFontSizeSpecQrCode] = React.useState(30);
    const [fontSizeTypo, setFontSizeTypo] = React.useState(20);
    const [token, setToken] = React.useState("");
    const [logo, setLogo] = React.useState<any>(IconCMN);
    const [logos, setLogos] = React.useState<UserLogo[]>([]);
    const [groupLogosDialogIsOpen, setOpenGroupLogosDialog] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [selectedQrCode, setSelectedQrCode] = React.useState<QrCodeGroupUnit>();
    const [loadingFiles, setLoadingFiles] = React.useState(false);
    const { t } = useTranslation();

    const onClose = () => {
        setCurrentObject(ObjectUnit.CreateObject());
        setVisible(false);
    }

    React.useEffect(() => {
        if (visible) {
            (async () => {
                setLoading(true);
                setCount(groups.length);
                let qrCodes: QrCodeGroupUnit[] = [];
                const tokenTemp = getApiToken(history);
                setToken(tokenTemp);
                for (let group of groups) {
                    let qrCode = await api.downloadQrCodeImageByGroupIdAsync(tokenTemp, group.id);
                    let newEntry = new QrCodeGroupUnit();
                    newEntry.qrCode = qrCode;
                    mapQrCodeTypeToText(QrCodeObjectType.Address, newEntry, group);
                    newEntry.group = await api.getGroupSettings(group.id, tokenTemp);
                    let logo = await api.downloadLogoImageAsync(newEntry.group, token);
                    if (!logo) logo = sourceLogoUser;
                    if (!logo) logo = IconCMN;
                    newEntry.logo = logo;
                    if (newEntry.group.locationInformation?.objectUnitId) {
                        newEntry.object = objects.find(x => x.id == newEntry.group.locationInformation?.objectUnitId);
                    }
                    qrCodes.push(newEntry);
                    setCount(prev => prev - 1);

                }
                setLoading(false);
                setQrCodes(qrCodes);
            })();
        }


    }, [visible]);




    const mapQrCodeTypeToText = (qrCodeObjectType: QrCodeObjectType, qrCodeObject: QrCodeGroupUnit, group: GroupLicenseTable) => {
        switch (qrCodeObjectType) {
            default:
                qrCodeObject.groupField1 = group.roomName || "";
                qrCodeObject.groupField2 = `${group.levelName} ${group?.notificationName}`;
                break
        }
    }

    const handlePrint = useReactToPrint({

        content: () => refPrint.current,
        onAfterPrint: () => {

        }

    });


    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title="Einheit Qr-Code"
                handleClose={onClose}
            />

            <DialogContent>

                <Grid container direction="row" className={classes.container} spacing={3}>
                    {loading &&
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Lade Qr Codes: Noch {count} Downloads</Typography>
                            <CircularProgress size={128} />
                        </Grid>}
                    <Grid item container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={6} container direction="row" spacing={2}>
                            <Grid item>
                                <Button size="small" variant="contained" startIcon={<FileDownload />} onClick={() => {
                                    for (let qrCode of qrCodes) {
                                        saveAs(qrCode.qrCode, `${qrCode.groupField2}.png`);
                                    }
                                }} color="primary">
                                    {t("myUnitsSettings.downloadQRCode")}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button size="small" variant="contained" startIcon={<Print />} color="primary" onClick={handlePrint}>
                                    {t("myUnitsSettings.printQRCode")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button size="small" variant="contained" startIcon={<FileDownload />} color="primary" onClick={() => handleDownloadImage(refPrint)}>
                                    {t("myUnitsSettings.downloadLabel")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button endIcon={loadingFiles && <CircularProgress size={32} />} disabled={loadingFiles} size="small" variant="contained" startIcon={<FileDownload />} color="primary"
                                    onClick={async () => {
                                        setLoadingFiles(true);
                                        for (let qrCode of qrCodes) {
                                            await new Promise(resolve => setTimeout(() => {
                                                handleDownloadImage(qrCode.getRefPrint(), qrCode.group.locationInformation?.notificationName)
                                                resolve(true)
                                            }, 2000))
                                        }
                                        setLoadingFiles(false);
                                    }}>
                                    Download Alle Etikette Einzeln
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    size="small"
                                    startIcon={loading ? <CircularProgress color="inherit" /> : <FindReplace />}
                                    variant={'contained'}
                                    disabled={loading}
                                    onClick={async () => {
                                        setLoading(true);
                                        let logos = await api.getLogosOfGroup(token);
                                        let ret = [];
                                        for (let logo of logos) {
                                            let res = await api.downloadAnyLogoImageAsync(logo.filename, logo.groupId, token);
                                            logo.objectUrl = res;
                                            ret.push(logo);
                                        }
                                        setLogos(ret);
                                        setOpenGroupLogosDialog(true);
                                        setLoading(false);
                                    }}
                                    color="primary">
                                    Gespeicherte Logos ansehen
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                    <div ref={refPrint} className="page-size">
                        {qrCodes?.map(qrCode => {
                            return (
                                <>
                                    <BuildQrCodeLogo qrCode={qrCode.qrCode} firstTitle={qrCode.groupField1} secondTitle={qrCode.groupField2} sizeQrCode={sizeQrCode} fontSizeSpecQrCode={fontSizeSpecQrCode}
                                        fontSizeTypo={fontSizeTypo} qrCodeObject={qrCode} groupSettings={qrCode.group} objectUnit={qrCode.object} logo={qrCode.logo} />
                                    <div className="page-break"></div>
                                </>
                            )
                        })}
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
            </DialogActions>
            <GroupLogosDialog logos={logos} visible={groupLogosDialogIsOpen}
                onConfirmed={async (item: UserLogo) => {
                    setOpenGroupLogosDialog(false);
                    let blob = await fetch(item.objectUrl).then(r => r.blob());
                    const file = new File([blob], "random", { type: blob.type })
                    setLogo(item.objectUrl);
                }}
                onClose={() => {
                    setOpenGroupLogosDialog(false);
                }
                }
                onDelete={async (item: UserLogo) => {
                    let settings = await api.getGroupSettings(item.groupId, token);
                    if (settings.logo?.fileName == item.filename) {
                        enqueueSnackbar("Logo wird gerade von der Einheit verwendet! Kann nicht gelöscht werden", { variant: 'error' });
                        return;
                    }
                    await api.deleteGroupLogoAsync(item.groupId, item.filename, token, enqueueSnackbar)
                }}
            />
        </Dialog >
    );
};

export const BuildQrCodeLogo = (
    { qrCode, sizeQrCode, fontSizeSpecQrCode, fontSizeTypo, qrCodeObject, groupSettings, objectUnit, logo }:
        { qrCode: any, firstTitle: any, secondTitle: any, sizeQrCode: number, fontSizeSpecQrCode: number, fontSizeTypo: number, qrCodeObject: QrCodeGroupUnit, groupSettings: GroupSettings, objectUnit: ObjectUnit | undefined, logo: any }) => {

    const refPrint = useRef();


    const getRefPrint = () => {
        return refPrint;
    };

    React.useEffect(() => {
        qrCodeObject.getRefPrint = getRefPrint;
    }, []);


    return (
        <React.Fragment>
            {buildPrintItemGroup(sizeQrCode, fontSizeSpecQrCode, fontSizeTypo, refPrint, qrCode, groupSettings.locationInformation?.qrCodeInformation, logo, groupSettings.locationInformation?.notificationName, !!objectUnit ? objectUnit.name : groupSettings.locationInformation?.objectName, groupSettings.logo)}
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
