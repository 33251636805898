import axios from 'axios';
import { handleApiError, headers } from '../api';
import { USER_ID } from '../constants';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupMotion } from '../models/GroupMotion';

export const getMotionsByUserGroupIds = async (token: string, groupIds : string[] = []): Promise<Array<GroupMotion>> => {

    try {

        let userId = getUserIdFromStorage();

        if (!userId) return [];

        let ret = await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/motion-by-groupIds?${getQueryParamFromApiDataAmountType()}`, {StringArray: groupIds},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return ret.data || [];

    } catch (err) {
        handleApiError(err);
        return [];
    }
}

export const getMotionsByObject = async (token: string, objectIds: string[]): Promise<Array<GroupMotion>> => {

    try {

        let userId = getUserIdFromStorage();

        if (!userId) return [];

        let ret = await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/motion-by-objects`,{stringArray: objectIds},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return ret.data;

    } catch (err) {
        handleApiError(err);
        return [];
    }
}

export const getMotionsByServiceWorker = async (token: string, objectIds: string[]): Promise<Array<GroupMotion>> => {

    try {

        let userId = getUserIdFromStorage();

        if (!userId) return [];

        let ret = await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/motion-by-service-worker`,{stringArray: objectIds},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        return ret.data;

    } catch (err) {
        handleApiError(err);
        return [];
    }
}