import Dialog from '@mui/material/Dialog';
import { useHistory } from 'react-router-dom';
import { DefaultDialogTitle } from './DefaultDialogTitle';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { Button, DialogActions, DialogContent, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, Undo } from '@mui/icons-material';

export const TransactionCompletedDialog = ({ result, setResult, onReturnToShop }: { result: PaymentIntentResult | null, setResult: any, onReturnToShop: any }) => {

  const classes = useStyles();
  const history = useHistory();

  const onClose = () => setResult(null);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={result !== null}>
      <DefaultDialogTitle
        title={result?.error ? "Zahlung war nicht erfolgreich" : "Zahlung in Abwicklung"}
      />
      <DialogContent className={classes.dialogContent}>
        <Typography
        >
          {
            result?.error ?
              result.error.message :
              "Sobald ihre Bezahlung vollständig abgewickelt wurde, können Sie in der Lizenzverwaltung Ihre neuen erworbenen Lizenzen einsehen"
          }
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<Undo />}
          onClick={() => {
            history.push("licenses");
          }}
        >
          Zur Lizenzverwaltung
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ShoppingCart />}
          onClick={onReturnToShop}
        >
          Zurück zum Shop
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },
}));