import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Accessibility } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { differenceInHours, differenceInMinutes, formatDistance, fromUnixTime } from 'date-fns';
import { de } from 'date-fns/locale';
import { UserServiceWorker } from '../../../../../models/UserServiceWorker';
import { formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime, formatEuropeanTime } from '../../../../../helpers/date-management';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { buildCellElementArray } from '../../../../common/AccordionForElementArray';
import { lightRed, lightGreen } from '../../../../../styles/colors';
import { ObjectCleaningTriggerForExcel } from '../../../../../models/ObjectCleaningTriggerForExcel';
import { useTranslation } from 'react-i18next';

export const ObjectPresenceTriggerTableForExcel = ({ objectCleaningTriggers, userServiceWorkers, selectedObjectUnit, objects = [] }
    : { objectCleaningTriggers: ObjectCleaningTriggerForExcel[], userServiceWorkers: UserServiceWorker[], selectedObjectUnit?: ObjectUnit, objects?: ObjectUnit[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `ObjectSession-${selectedObjectUnit?.name || "All-Objects"}-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const [pageSize, setPageSize] = React.useState(30);
    const [currentPage, setCurrentPage] = React.useState(10);
    const {t} = useTranslation();

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: ObjectCleaningTriggerForExcel, value: any } = props;
        if (column.name === ObjectCleaningTriggerForExcel.Columns.minimumServiceWorker || column.name === ObjectCleaningTriggerForExcel.Columns.isNotEnoughServiceWorkerSessions) {
            return (
                <Table.Cell  {...props} style={{ backgroundColor: row.isNotEnoughServiceWorkerSessions ? lightRed : lightGreen, borderRadius: 1, fontWeight: 'bold' }}>
                    {value}
                </Table.Cell>
            )
        }
        if (column.name === ObjectCleaningTriggerForExcel.Columns.isAllServiceWorkersMissing) {
            return (
                <Table.Cell  {...props} style={{ backgroundColor: row.isAllServiceWorkersMissing ? lightRed : lightGreen, borderRadius: 1, fontWeight: 'bold' }}>
                    {value}
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let columns = [
        {
            name: ObjectCleaningTriggerForExcel.Columns.objectId,
            title: t("object-storage-single-eval.object"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => {
                let object = objects.find(x => x.id == row.objectId);
                return (
                    !object ? t("object-interval-trigger.notFound") : `${object?.name} ${object?.addressLine}`
                );
            }
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.objectScheduleStart,
            title: t("object-interval-trigger.plannedDate"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => formatEuropeanDate(row.objectScheduleStart),
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.objectScheduleStartTime,
            title: t("object-interval-trigger.plannedTime"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => formatEuropeanTime(row.objectScheduleStart),
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.triggerMoment,
            title: t("object-interval-trigger.reactionEndTime"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => formatEuropeanDateTime(row.triggerMoment),
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.reactionTimeMinutes,
            title: t("object-interval-trigger.reactionTime"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => row.deviationTime,
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.isAllServiceWorkersMissing,
            title: t("object-interval-trigger.totalFailure"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => row.isAllServiceWorkersMissing ? t("myUnitsSettings.yes") : t("myUnitsSettings.no"),
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.minimumServiceWorker,
            title: t("object-interval-trigger.insufficient"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => `${row.isNotEnoughServiceWorkerSessions ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}`,
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.minimumServiceWorker2,
            title: t("object-interval-trigger.actualCount"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => `${row.istCountServiceWorker} Servicekräfte`,
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.minimumServiceWorker3,
            title: t("object-interval-trigger.targetCount"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => `${row.minimumServiceWorker} Servicekräfte`,
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.serviceWorker,
            title: t("object-interval-trigger.serviceStaff"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => {
                if (!row?.session?.serviceWorkerId) return "";
                let serviceWorker = userServiceWorkers.find(worker => worker.id == row?.session?.serviceWorkerId);
                return (
                        `${serviceWorker?.name}`
                );
            }
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.tooShort,
            title: t("object-interval-trigger.tooShort"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => {
                return (`${row.isDurationOfSessionTooShort ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}`
                );
            }
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.sincePresence,
            title: t("object-interval-trigger.attendanceDuration"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => {
                return (
                        `${row.durationLoggedIn}`
                );
            }
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.minimumDuration,
            title: t("object-interval-trigger.minimumDuration"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => {
                return (
                        `${row.minimumTime || t("object-interval-trigger.notFound")}`
                );
            }
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.diffText,
            title: t("object-interval-trigger.attendanceStatus"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => {
                let diffText = row.isDurationOfSessionTooShort ? `${t("object-interval-trigger.absent")}: ${row.diffTime}` : `${t("object-interval-trigger.excess")}: ${row.diffTime}`
                return (
                        `${diffText}`
                );
            }
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.signInAt,
            title: t("object-interval-trigger.registration"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => {
                if (!row?.session?.serviceWorkerId) return "";
                return (
                        `${formatEuropeanDateTime(fromUnixTime(row.session.signedInObjectSinceTimestamp))}`
                )
            }
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.signOutAt,
            title: t("object-interval-trigger.logout"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => {
                if (!row?.session?.serviceWorkerId) return "";
                return (
                        `${formatEuropeanDateTime(fromUnixTime(row.session.signedOutOfObjectSinceTimestamp))}`
                )
            }
        },
        {
            name: ObjectCleaningTriggerForExcel.Columns.objectCleaningTriggerInTimeState,
            title: t("object-interval-trigger.serviceStaffRegistrationsByEndOfDay"),
            getCellValue: (row: ObjectCleaningTriggerForExcel) => ObjectCleaningTriggerForExcel.GetLabelByInTimeStateType(row.objectCleaningTriggerInTimeState),
        },
    ];

    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: ObjectCleaningTriggerForExcel.Columns.objectId, width: 350 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.objectScheduleStart, width: 150 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.objectScheduleStartTime, width: 150 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.triggerMoment, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.reactionTimeMinutes, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.durationOfObjectSessions, width: 400 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.sessions, width: 350 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.isAllServiceWorkersMissing, width: 150 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.minimumServiceWorker, width: 150 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.minimumServiceWorker2, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.minimumServiceWorker3, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.objectCleaningTriggerInTimeState, width: 350 },

        { columnName: ObjectCleaningTriggerForExcel.Columns.serviceWorker, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.tooShort, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.sincePresence, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.minimumDuration, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.diffText, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.signInAt, width: 200 },
        { columnName: ObjectCleaningTriggerForExcel.Columns.signOutAt, width: 200 },

    ]);

    /*  if (objects.length > 0) {
          columns = [{
              name: ObjectCleaningTrigger.Columns.object,
              title: 'Object',
              getCellValue: (row: ObjectCleaningTrigger) => {
                  let object = objects.find(x => x.id == row.objectId);
                  return (
                      !object ? t("object-sessions.notActive") : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                  );
              }
          }, ...columns]
      } */

    return (
        <Paper>
            <Grid
                rows={objectCleaningTriggers}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={objectCleaningTriggers}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};