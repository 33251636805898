import { LocationInformation, CleaningOperations, Content, Feedback, IncidentReports, GroupSettingsIntervalControl, MaterialConsumptions, NotificationInformation, SensorControl, Users, GroupSettings, GroupSettingsSideBarImage, GroupSettingsLogo, Sensor, LoraSensorControl, PayPerServicesControl } from './GroupSettings';

export class UserGroupSettingsTemplate {
    public id!: string | null;
    public name!: string | null;
    public systemETag!: string | null;
    public systemTimestamp!: string | null;
    public locationInformation!: LocationInformation | null;
    public cleaningOperations!: CleaningOperations | null;
    public notification!: NotificationInformation | null;
    public users!: Users | null;
    public sensorControl!: SensorControl | null;
    public loraSensorControl!: LoraSensorControl | null;
    public feedbacks!: Feedback | null;
    public intervalControl!: GroupSettingsIntervalControl | null;
    public materialConsumptions!: MaterialConsumptions | null;
    public incidentReports!: IncidentReports | null;
    public payPerServiceControl!: PayPerServicesControl | null;
    public content!: Content | null;
    public sideBarImage!: GroupSettingsSideBarImage | null;
    public logo!: GroupSettingsLogo | null;
    public isDeviceRestartEnabled! : boolean;

    static Default: UserGroupSettingsTemplate = new UserGroupSettingsTemplate('', new GroupSettings(''));

    public constructor(name: string, groupSettings: GroupSettings) {
        this.name = name;
        this.systemETag = null;
        this.systemTimestamp = null;
        this.locationInformation = groupSettings.locationInformation;
        this.cleaningOperations = groupSettings.cleaningOperations;
        this.notification = groupSettings.notification;
        this.users = groupSettings.users;
        this.sensorControl = groupSettings.sensorControl;
        this.loraSensorControl = groupSettings.loraSensorControl;
        this.feedbacks = groupSettings.feedbacks;
        this.intervalControl = groupSettings.intervalControl;
        this.materialConsumptions = groupSettings.materialConsumptions;
        this.incidentReports = groupSettings.incidentReports;
        this.content = groupSettings.content;
        this.sideBarImage = groupSettings.sideBarImage;
        this.payPerServiceControl = groupSettings.payPerServiceControl;
        this.logo = groupSettings.logo;
    }

    static Columns = {
        name: "name",
        assignedTemplate: "assignedTemplate"
    }
}