export class GroupSettingsTemplateOption {
    public locationInformation!: boolean;
    public cleaningOperations!: boolean;
    public notification!: boolean;
    public users!: boolean;
    public loraSensorControl!: boolean;
    public sensorControl!: boolean;
    public feedbacks!: boolean;
    public intervalControl!: boolean;
    public materialConsumptions!: boolean;
    public incidentReports!: boolean;
    public content!: boolean;
    public sideBarImage!: boolean;
    public logo!: boolean;

    constructor () {
        this.locationInformation = false;
        this.cleaningOperations = false;
        this.notification = false;
        this.users = false;
        this.loraSensorControl = false;
        this.sensorControl = false;
        this.feedbacks = false;
        this.intervalControl = false;
        this.materialConsumptions = false;
        this.incidentReports = false;
        this.content = false;
        this.sideBarImage = false;
        this.logo = false;
    }
}