import axios from 'axios';
import { headers } from '../../api';
import { LoginServiceWorkerByUsernameCodeModel } from '../../models/public/LoginServiceWorkerByUsernameCodeModel';
import { PublicSession } from '../../models/Session';

export const loginServiceWorkerByUsernameCode = async (login: LoginServiceWorkerByUsernameCodeModel, enqueueSnackbar: any): Promise<PublicSession> => {
    try {
        const ret = await axios.post<PublicSession>(
            `${process.env.REACT_APP_BASIC_URI}/auth/service-worker/by-username-code`,
            { ...login },
            { headers: { ...headers } });
        enqueueSnackbar("Login erfolgreich", { variant: 'success' });

        return ret.data;

    } catch (err) {
        enqueueSnackbar("Login fehlgeschlagen", { variant: 'error' });

        return null as any;
    }
}