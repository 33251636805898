import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import SimpleList from '../lists/SimpleList';
import { SimpleListGroup } from '../lists/SimpleListGroup';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export const GroupCombinationDialog = (props:any) => {
  const classes = useStyles();
  const {  selectedValue, open, items, setOpen } = props;
  const handleClose = () => {
    setOpen(false);
};

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Verwendete Einheiten</DialogTitle>
      <SimpleListGroup data={items}></SimpleListGroup>
    </Dialog>
  );
}
