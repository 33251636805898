import { Grid, Paper } from "@mui/material";
import React from "react";
import { GroupFeedback } from "../../../../../models/GroupFeedback";
import { FeedbackDisplayType } from "../../../../../models/GroupSettings";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { TimeChoice } from "../../../../core/TimeChoice";

export const GetGroupFeedbacks = ({ data, dataField, allData, setChartData, childRef, loadingCompleted, childRefChart, feedbackDisplayTypeChart }
    : { data: any[], dataField: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any, feedbackDisplayTypeChart : FeedbackDisplayType }) => {
    const [chartChoice, setChartChoice] = React.useState('2');

    const sumFeedback = React.useMemo(() => data.reduce((prevVal, x : GroupFeedback) => {
        const sum = feedbackDisplayTypeChart ? x.negativeAmount + x.neutralAmount + x.positiveAmount + x.veryPositiveAmount + x.veryNegativeAmount : x.negativeAmount + x.neutralAmount + x.positiveAmount
        return prevVal + sum
    },0), [data, feedbackDisplayTypeChart]);

    const text = `Feedback Typ ausgelöst (Anzahl)<br>Gesamtsumme: <b>${sumFeedback} Feedbacks</b>`;

    const colors = feedbackDisplayTypeChart == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? ["#0EAD1C", "#05ff86", "#d4d008",'#ff9d14',"#FF0000"] : ["#0EAD1C", "#d4d008", "#FF0000"];

    const buildGridItem = () => {

        switch (chartChoice) {
            case '0':
                return <LineChart
                    childRefChart={childRefChart}
                    colors={colors}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    childRefChart={childRefChart}
                    colors={colors}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    childRefChart={childRefChart}
                    colors={colors}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}