import axios from 'axios';
import { headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { gridStackCustomNameTitle } from '../../helpers/names';
import { GridStackCustom } from '../../models/GridStackCustom';


export const CreateUserGridStackCustom = async (objectUnit: GridStackCustom | undefined, token: string, enqueueSnackbar: any): Promise<GridStackCustom> => {
    try {
        if (!objectUnit) throw (null);
        let userId = getUserIdFromStorage();
        objectUnit.userId = userId || "";
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/create-grid-stack-custom`,
            { ...objectUnit },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar(`${gridStackCustomNameTitle} Speichern erfolgreich`, { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar(`${gridStackCustomNameTitle} Speichern error`, { variant: 'error' });
    }
    return null as any;
}