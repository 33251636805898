import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DataFilterType, PeriodChartFilterType, generateChartData, generateSumChartData } from '../../helpers/charts/base';
import { InlineDateTimePicker } from './InlineDateTimePicker';
import { Grid, TextField } from '@mui/material';
import { defaultEndDate, defaultPastDays, defaultStartDate } from '../../helpers/date-management';
import DataFilterTypeChoiceDialog from '../dialogs/DataFilterTypeChoiceDialog';
import { useTranslation } from 'react-i18next';

export const TimeChoiceSum = forwardRef(({ data, setChartData, fields }: any, ref: any) => {
  useImperativeHandle(
    ref,
    () => ({
      updateEndDateFromParent(date: any) {
        setSelectedEndDate(date);
      },
      updateStartDateFromParent(date: any) {
        setSelectedStartDate(date);
      },
      updateDataFilterTypeFromParent(dataFilterType: any) {
        setDataFilterType(dataFilterType);
      },
      updateChosenPastDays(days: any) {
        setChosenPastDays(parseInt(days));
      }
    }),
  )
  const [value, setValue] = React.useState(PeriodChartFilterType.Day);
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);
  const [chosenPastDays, setChosenPastDays] = React.useState(defaultPastDays);
  const isInitialMount = useRef(true);
  const [dataFilterType, setDataFilterType] = React.useState<DataFilterType>(DataFilterType.StartEnd);
  const {t} = useTranslation();

  React.useEffect(() => {
    setTimeBasedSumChartData(isInitialMount, value, dataFilterType, selectedEndDate, selectedStartDate, data, fields, chosenPastDays, setChartData);
  }, [value, selectedEndDate, selectedStartDate, chosenPastDays, dataFilterType]);

  const buildFields = () => {
    switch (dataFilterType) {
      case DataFilterType.StartEnd:
        return (
          <Grid container item direction={'row'} spacing={2}>
            <Grid item>
              <InlineDateTimePicker title={t("serviceDocumentationTable.startDate")} selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
          </Grid>
        );
      case DataFilterType.EndDatePastDays:
        return (
          <Grid container item xs={12} direction={'row'}>
            <Grid item>
              <InlineDateTimePicker title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate}></InlineDateTimePicker>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 100, marginLeft: 30 }}
                inputProps={{ min: 1, style: {} }}
                id="filled-number"
                label="Tage zurück"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                value={chosenPastDays}
                onChange={(event) => { setChosenPastDays(parseInt(event.target.value)) }}
              />
            </Grid>
          </Grid>
        );
    }
  }

  return (
    <Grid container spacing={2}>
      <DataFilterTypeChoiceDialog dataFilterType={dataFilterType} setDataFilterType={setDataFilterType}/>
      {buildFields()}
    </Grid>
  );
});

export const setTimeBasedSumChartData = (isInitialMount: any, value: any, dataFilterType: DataFilterType, selectedEndDate: any, selectedStartDate: any, data: any, fields: any, chosenPastDays: any, setChartData: any) => {
  if (isInitialMount.current) {
    isInitialMount.current = false;
  } else {
    if (value === PeriodChartFilterType.Hour) {
      setChartData(generateSumChartData(data, PeriodChartFilterType.Hour, dataFilterType, selectedEndDate, selectedStartDate, chosenPastDays));
    }
    else if (value === PeriodChartFilterType.Day) {
      let temp = generateSumChartData(data, PeriodChartFilterType.Day, dataFilterType, selectedEndDate, selectedStartDate, chosenPastDays);
      setChartData(temp);
    }
    else if (value === PeriodChartFilterType.Week) {
      setChartData(generateSumChartData(data, PeriodChartFilterType.Week, dataFilterType, selectedEndDate, selectedStartDate, chosenPastDays))
    }
    else if (value === PeriodChartFilterType.Month) {
      setChartData(generateSumChartData(data, PeriodChartFilterType.Month, dataFilterType, selectedEndDate, selectedStartDate, chosenPastDays))
    }
  }
}