import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Accessibility } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { differenceInHours, differenceInMinutes, format, formatDistance, getDate } from 'date-fns';
import { de } from 'date-fns/locale';
import { saveExcelFile } from './tables/common';
import { formatEuropeanCurrentDateForExcelExport } from './date-management';

export const DateDataTable = ({ rows }
    : { rows: Date[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `DateData-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let columns = [
        {
            name: "weekday",
            title: 'Wochentag',
            getCellValue: (row: Date) => format(row, "EEEE",{locale: de}),
        },
        {
            name: "monthDay",
            title: 'Monatstag',
            getCellValue: (row: Date) => format(row, "d",{locale: de}),
        },        
        {
            name: "monthname",
            title: 'Monat',
            getCellValue: (row: Date) => format(row, "MMMM",{locale: de}),
        },
        {
            name: "year",
            title: 'Jahr',
            getCellValue: (row: Date) => format(row, "y",{locale: de}),
        },
        {
            name: "time",
            title: 'Zeit',
            getCellValue: (row: Date) => {
                return format(row, "HH:mm:ss",{locale: de})
            },
        },
    ];

    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: "weekday", width: 200 },
        { columnName: "monthDay", width: 200 },
        { columnName: "monthname", width: 200 },
        { columnName: "year", width: 200 },
        { columnName: "time", width: 200 },

    ]);

    return (
        <Paper>
            <Grid
                rows={rows}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};
