import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const telegramGetIsUserAuthorized = async (phoneNumber:any, token: any, enqueueSnackbar: any) : Promise<boolean> => {
    try {
        let userId = getUserIdFromStorage();

        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/telegram/${phoneNumber}/is-authorized`,
        {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar, "Gruppen Chats konnten nicht aufgerufen werden!");
    }
    return null as any;
}