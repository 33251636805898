import { parse } from "date-fns";

export const compareDatesSortingEuropeanDateTime = (a: string, b: string) => {
    const priorityA = parse( a, 'dd.MM.yyyy HH:mm', new Date());
    const priorityB = parse(b, 'dd.MM.yyyy HH:mm', new Date());

    if (priorityA === priorityB) {
        return 0;
    }
    return (priorityA < priorityB) ? -1 : 1;
};

export const compareDatesSortingEuropeanDate = (a: string, b: string) => {
    const priorityA = parse( a, 'dd.MM.yyyy', new Date());
    const priorityB = parse(b, 'dd.MM.yyyy', new Date());

    if (priorityA === priorityB) {
        return 0;
    }
    return (priorityA < priorityB) ? -1 : 1;
};

export const compareDatesSortingEuropeanHours = (a: string, b: string) => {
    const priorityA = parse( a, 'HH:mm', new Date());
    const priorityB = parse(b, 'HH:mm', new Date());

    if (priorityA === priorityB) {
        return 0;
    }
    return (priorityA < priorityB) ? -1 : 1;
};