import { Paper, Grid, Typography, Avatar, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { trimPathname } from "../../helpers/common";
import { getQueryString } from "../../helpers/general";
import { useStyles } from "../../styles/container";
import { EvaluationChoice } from "../../models/EvaluationChoice";
import { GroupLicenseTable } from '../../models/GroupLicenseTable';


export enum SimplePanelRouteType {
    "Default" = -1,
    "ServicesChart" = 0,
    "ServiceTable" = 1,
    "FeedbackChart" = 2,
    "FrequenceChart" = 3,
    "LicenseManagement" = 4,
}

export const SimplePanel = ({
    title,
    result,
    svgIcon,
    color,
    simplePanelRouteType = -1,
    evaluationChoice = EvaluationChoice.All,
    selectedCombinationGroup,
    selectedGroupId }
    : {
        title: string,
        result: any,
        svgIcon: any,
        color?: any,
        simplePanelRouteType?: SimplePanelRouteType,
        evaluationChoice?: EvaluationChoice,
        selectedCombinationGroup: GroupLicenseTable[],
        selectedGroupId: string
    }) => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    let path = `/portal/users/services`;
    let queryString = "";

    switch (evaluationChoice) {
        case EvaluationChoice.Multiple:
            path = `/portal/groups/services`;
            queryString = selectedCombinationGroup.length > 0 ? '?' + getQueryString(selectedCombinationGroup) : "";
            break;
        case EvaluationChoice.Single:
            path = `/portal/group/${selectedGroupId}`;
            break;
    }

    let { pathname } = useLocation();
    pathname = trimPathname(pathname);

    return (
        <Paper className={simplePanelRouteType != - 1 ? "Paper Paper-Hover" : "Paper"} onClick={() => {
            switch (simplePanelRouteType) {
                case SimplePanelRouteType.ServiceTable:
                    history.push(`${path}/table${queryString}`);
                    break;
                case SimplePanelRouteType.FeedbackChart:
                    history.push(`${path}/feedback${queryString}`);
                    break;
                case SimplePanelRouteType.ServicesChart:
                    history.push(`${path}${queryString}`);
                    break;
                case SimplePanelRouteType.LicenseManagement:
                    history.push(`/portal/licenses`);
                    break;
                default:
            }
        }}>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
            >
                <Grid
                    xs={10}
                    item
                    container
                    direction="column"
                >
                    <Grid item>
                        <Typography variant="body1" component="p" color="textSecondary">{title}</Typography >
                    </Grid>
                    <Grid item>
                        <Typography className="Paper-Result" component="p">{result}</Typography >
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Avatar className={classes.large} style={{ backgroundColor: color ? color : theme.palette.primary.main }}>
                        <img src={svgIcon} width={40} height={40} />
                    </Avatar>
                </Grid>
            </Grid>
        </Paper>
    );
}