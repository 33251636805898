import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, ValueAxis, Tooltip } from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { chartHeight, ChartLabelType, customizeLegendTextBarLineChart, customizePercentageText, customizeTooltip, mapLabels, NoDataChartLoader } from './Base';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});



export const StackDraw = (props: any) => {
    const { classes, data, dataField, text, loadingCompleted, chartLabelType } = props;

    if (data.length === 0)
        return <NoDataChartLoader title={text} data={data} loadingCompleted={loadingCompleted} />;

    const buildLabel = () => {
        switch (chartLabelType) {
            case ChartLabelType.Percent:
                return (
                    <ValueAxis allowDecimals={true}>
                        <Label customizeText={customizePercentageText} />
                    </ValueAxis>
                )
            default:
                return <ValueAxis allowDecimals={false} />
        }
    }

    const customizeLabelChart = (point: any) => {
        if (point.valueText == 0) {
            return "";
        }
        return `${point.valueText}%`;
    }

    const buildBarLabel = () => {
        switch (chartLabelType) {
            case ChartLabelType.Percent:
                return (
                    <Label visible={true}
                        customizeText={customizeLabelChart}>

                    </Label>
                )
            default:
                return (
                    <Label visible={true}>
                        <Format type="fixedPoint" precision={0} />
                    </Label>
                )
        }
    }

    return (
        <Chart
            ref={props.childRefChart}
            title={text}
            dataSource={data}
            id="chart"
            palette={props.colors}
            style={{ height: chartHeight }}
        >
            <CommonSeriesSettings
                argumentField="dateDisplay"
                type="bar"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
            >
                {buildBarLabel()}
            </CommonSeriesSettings>
            {dataField.map((el: any, id: any) => {
                return (
                    <Series
                        valueField={el}
                        argumentField="dateDisplay"
                        name={el}
                        key={id}
                    />
                );
            })}
            {buildLabel()}
            <Tooltip
                enabled={true}
                location="edge"
                customizeTooltip={customizeTooltip}
            />
            <Legend verticalAlignment="bottom" horizontalAlignment="center" customizeText={customizeLegendTextBarLineChart}></Legend>
            <Export enabled={true} />
        </Chart>
    );
}

export const SideBySideBarChartDynamic = withStyles(demoStyles, { name: 'Demo' })(StackDraw);