import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupCleaningTrigger } from '../models/GroupCleaningTrigger';


export const getCleaningTriggerByGroup = async (token: string, groupId: string): Promise<Array<GroupCleaningTrigger>> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/groups/${groupId}/users/${userId}/cleaning-trigger?${getQueryParamFromApiDataAmountType()}`, {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err);
    }
    return null as any;

}