import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const assignQmToGroupByIdsAsync = async (groupIds: string[], token: string, enqueueSnackbar: any) => {

    if (!groupIds || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/qm-control-assign`,{StringArray : groupIds},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("QM wurde erfolgreich zugewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};

export const unassignQmToGroupByIdsAsync = async (groupIds: string[], token: string, enqueueSnackbar: any) => {

    if (!groupIds || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/qm-control-unassign`,{StringArray : groupIds},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("QM wurde erfolgreich weggewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Wegweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};