import {
    FormControlLabel,
    IconButton,
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import React from "react";
import { CurrencyHelper } from '../../../../helpers/CurrencyHelper';
import { CheckoutItem } from '../../../../models/CheckoutItem';
import { NumberInput } from '../../../common/NumberInput';
import { OrderOverViewTableSummary } from './OrderOverViewTableSummary';
import { Product, ItemOption, ItemProduct } from './../../../../models/Product';
import { Add, Maximize } from '@mui/icons-material';
import { ProductType } from "../../ProductType";
import { showHeaderImage } from "./CheckoutProductListing";

export const OrderOptionOverViewTable = ({ items, setItems, products }
    : { items: CheckoutItem[], products: Product[], setItems: React.Dispatch<React.SetStateAction<CheckoutItem[]>> }) => {

    const classes = useStyles();
    console.log(items);
    return (
        <div className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableRowCell} style={{ width: '20%' }} align="left">Bezeichnung</TableCell>
                            <TableCell className={classes.tableRowCell} style={{ width: '60%' }} align="left">Beschreibung</TableCell>
                            <TableCell className={classes.tableRowCellSmall} style={{ width: '10%' }} align="left">Anzahl</TableCell>
                            <TableCell className={classes.tableRowCellSmall} style={{ width: '10%' }} align="right">Summe</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items &&
                            items.length > 0 &&
                            items
                                .sort((a, b) => a.product.type - b.product.type)
                                .map((item: CheckoutItem, rowIndex: number) => (
                                    <TableRow key={rowIndex} className={classes.tableRow} style={{ borderBottom: 0 }}>
                                        <TableCell colSpan={4}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow className={classes.tableRow}>
                                                        <TableCell style={{ width: '20%', padding: 0 }} align="left">
                                                            {item.product.name}
                                                            {/* {
                                                                item.product &&
                                                                item.product.image && 
                                                                <img src={`${item.product.image}`} alt={item.product.name} />
                                                            } */}

                                                        </TableCell>
                                                        <TableCell style={{ width: '60%', padding: 0 }} align="left">
                                                            {item.product.description}
                                                            {
                                                                item.product.itemProduct &&
                                                                item.product.itemProduct.filter(el => el.isVisible) &&
                                                                <ul>
                                                                    In Ihrem Einkauf Inkludiert:
                                                                    {
                                                                        item.product.itemProduct
                                                                            .map((itemproduct: ItemProduct) => {
                                                                                return (
                                                                                    <li>
                                                                                        <Grid xs={12} direction="row">
                                                                                            <Grid item xs={6} className={classes.listContent}>
                                                                                                {itemproduct.name}
                                                                                            </Grid>
                                                                                            <Grid item xs={4} className={classes.listContent2}>
                                                                                                {CurrencyHelper.toCurrencyString(itemproduct.price)}
                                                                                            </Grid>
                                                                                            <Grid item xs={2}></Grid>
                                                                                        </Grid>

                                                                                    </li>
                                                                                )
                                                                            })
                                                                    }
                                                                </ul>
                                                            }

                                                        </TableCell>
                                                        <TableCell style={{ width: '10%', padding: 0, textAlign: 'center' }} align="left">
                                                            {
                                                                setItems &&
                                                                <NumberInput
                                                                    value={item.count}
                                                                    onValueChanged={(value: number) => {

                                                                        if (item.product.parentId) {
                                                                            let parent = products.filter((parent: Product) => parent.id === item.product.parentId);

                                                                            if (parent) {
                                                                                let itm = items.filter((itm: CheckoutItem) => itm.product.id == parent[0].id);

                                                                                if (itm) {
                                                                                    console.log(value + " <> " + itm[0].count + " <> " + item.count);
                                                                                    if (value > itm[0].count) {
                                                                                        value = itm[0].count;
                                                                                        item.count = itm[0].count;
                                                                                        return;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        if (isNaN(value) || !setItems) return;

                                                                        item.count = value;
                                                                        item.updatePrice();

                                                                        setItems([...items]);
                                                                    }}
                                                                />
                                                            }
                                                            {
                                                                !setItems &&
                                                                <Typography>{item.count}</Typography>
                                                            }
                                                        </TableCell>
                                                        <TableCell style={{ width: '10%', padding: 0 }} align="right">
                                                            {CurrencyHelper.toCurrencyString(item.total)}
                                                            {
                                                                setItems &&

                                                                <Tooltip title="Eintrag entfernen ?">
                                                                    <IconButton
                                                                        className={classes.deleteButton}
                                                                        onClick={() => {
                                                                            items.splice(rowIndex, 1);
                                                                            setItems([...items]);
                                                                        }}
                                                                        size="large">
                                                                        <Close />
                                                                    </IconButton>
                                                                </Tooltip>

                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    {item.product.itemOption
                                                        && item.product.itemOption.length > 0 &&
                                                        <TableRow className={classes.tableRow} >
                                                            <TableCell colSpan={4}>
                                                                {
                                                                    item.product.itemOption
                                                                        && item.product.itemOption.length > 0 ?
                                                                        <div>
                                                                            <div className={classes.textBold}>Sie haben die Möglichkeit Ihre Lizenz zu erweitern. Folgende Erweiterungsprodukte können Sie jetzt kaufen:</div>
                                                                            {
                                                                                item.product.itemOption.map((option: ItemOption) => {

                                                                                    return (
                                                                                        products.filter((product: Product) => product.id === option.id)
                                                                                            .map((product: Product, rowIndex: number) => {
                                                                                                return (
                                                                                                    <Card className={classes.cardRoot}>
                                                                                                        <CardContent>
                                                                                                            <Typography variant="body2" color="textSecondary" component="p">
                                                                                                                {product.name}
                                                                                                            </Typography>
                                                                                                        </CardContent>
                                                                                                        <CardActions>
                                                                                                            <Grid container direction="row">
                                                                                                                <Grid item xs={12} className={classes.unitPriceContainer}>
                                                                                                                    <Typography variant="h6" className={classes.unitPriceText}>
                                                                                                                        {item.count}&nbsp;
                                                                                                                    </Typography>
                                                                                                                    <Typography variant="h6" className={classes.unitPriceText}>
                                                                                                                        *&nbsp;
                                                                                                                    </Typography>
                                                                                                                    <Typography variant="h5" className={classes.unitPriceText}>
                                                                                                                        {CurrencyHelper.toCurrencyString(product.unitPrice)}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12}>
                                                                                                                    <ButtonGroup
                                                                                                                        fullWidth
                                                                                                                        variant="contained"
                                                                                                                        color="primary">
                                                                                                                        <Button
                                                                                                                            variant="contained"
                                                                                                                            onClick={() => {

                                                                                                                                if (!items.some(x => x.product.type === product.type)) {
                                                                                                                                    items.push(CheckoutItem.createItem(product, item.count, product.sensorCount));
                                                                                                                                    item.updateSensorCount(item.sensorCount + product.sensorCount);
                                                                                                                                    setItems([...items]);
                                                                                                                                    return;
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            disabled={items.some(x => x.product.type === product.type) || checkExtentionAlternativeItem(product, items)}
                                                                                                                        >
                                                                                                                            <Add />
                                                                                                                            Hinzufügen
                                                                                                                        </Button>
                                                                                                                    </ButtonGroup>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12}>
                                                                                                                    {showHeaderImage(product)}
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </CardActions>
                                                                                                    </Card>
                                                                                                )
                                                                                            })
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        : null
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <OrderOverViewTableSummary items={items} userAddress={null}/>

        </div>
    );
}
export const checkExtentionAlternativeItem = (product: Product, items: CheckoutItem[]) => {
    switch (product.type) {
        case ProductType.ExtensionLicenseSensorExtendedOne12Months:
            return items.some(x => x.product.type === ProductType.ExtensionLicenseSensorExtendedTwo12Months);
        case ProductType.ExtensionLicenseSensorExtendedOne24Months:
            return items.some(x => x.product.type === ProductType.ExtensionLicenseSensorExtendedTwo24Months);
        case ProductType.ExtensionLicenseSensorExtendedOne36Months:
            return items.some(x => x.product.type === ProductType.ExtensionLicenseSensorExtendedTwo36Months);
        case ProductType.ExtensionLicenseSensorExtendedTwo12Months:
            return items.some(x => x.product.type === ProductType.ExtensionLicenseSensorExtendedOne12Months);
        case ProductType.ExtensionLicenseSensorExtendedTwo24Months:
            return items.some(x => x.product.type === ProductType.ExtensionLicenseSensorExtendedOne24Months);
        case ProductType.ExtensionLicenseSensorExtendedTwo36Months:
            return items.some(x => x.product.type === ProductType.ExtensionLicenseSensorExtendedOne36Months);
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: '75vh'
    },
    textBold: {
        fontWeight: 'bold'
    },
    tableRow: {
        verticalAlign: 'top'
    },
    tableRowCell: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    tableRowCellSmall: {
        fontWeight: 'bold',
        fontSize: 16,
        width: 128,
    },
    tableRowCell20: {
        width: '20%'
    },
    tableRowCell50: {
        width: '50%'
    },
    tableRowCell15: {
        width: '15%'
    },
    tableCellNumber: {
        fontSize: 32,
    },
    container: {
        '&::-webkit-scrollbar': {
            width: 12,
        },
        '&::-webkit-scrollbar-track': {
            width: 12,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 12,
            borderRadius: 16,
            backgroundColor: theme.palette.primary.main,
        },
    },
    deleteButton: {
        display: 'block',
        marginLeft: 'auto',
        color: theme.palette.error.main,
    },
    cardTitle: {
        fontSize: '13px'
    },
    cardRoot: {
        marginTop: '1vh',
        paddingTop: theme.spacing(1),
        height: 370,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 12,
        },
        '&::-webkit-scrollbar-track': {
            width: 12,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 12,
            borderRadius: 16,
            backgroundColor: theme.palette.primary.main,
        },
        float: 'left',
        width: '30%',
        marginLeft: '3%',
        maxWidth: '250px'
    },
    unitPriceContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    unitPriceText: {
        fontWeight: 'bold',
    },
    minusButton: {
        paddingTop: 18,
    },
    listContent: {
        float: 'left',
        width: '100%'
    },
    listContent2: {
        width: '100%',
        float: 'left'

    }

}));

