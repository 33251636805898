import React from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useParams } from 'react-router-dom';
import { useSearchParams } from '../../../../helpers/tables/common';
import { StripePaymentMethod } from '../../../../models/PaymentMethod';
import { StripeRedirectStatus } from '../../../../models/StripeRedirectStatus';
import { api } from '../../../../api';
import { VerifyTransactionModel } from '../../../../models/VerifyTransactionModel';
import { getApiToken } from '../../../../helpers/storage-management';
import { useSnackbar } from 'notistack';

export const PaymentResultPage = () => {

    const history = useHistory();
    const searchParams = useSearchParams();
    const { id }: { id: string } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const paymentMethod = searchParams.get("payment_method") as StripePaymentMethod;
    const paymentIntent = searchParams.get("payment_intent");
    const paymentSecret = searchParams.get("payment_intent_client_secret");
    const redirectStatus = searchParams.get("redirect_status") as StripeRedirectStatus;

    React.useEffect(() => {
        (async () => {

            const token = getApiToken(history);
            if (!token) return;

            const isValid = await api.verifyTransactionAsync(VerifyTransactionModel.create(id, paymentMethod, paymentIntent, paymentSecret, redirectStatus), token, enqueueSnackbar);

            if (!isValid) {
                history.push("/portal/checkout/failed");
                return;
            }

            history.push("/portal/checkout/success");
        })();
    }, []);

    return (
        <Typography variant="h6">Zahlung wird verifiziert...</Typography>
    );
};
