import { TextField } from "@mui/material";
import React from "react";

export const TextFieldGroupSettings = (
    { value, name = "", label, onChange, variant, id = "", inputProps, fullWidth = false, InputProps, style, disabled, type, margin, multiline, minRows, helperText, placeholder }:
        { value: string | number | undefined, name?: string, label?: string, onChange?: (event: any) => void, variant: any, id?: any, inputProps?: any, fullWidth?: boolean, InputProps?: any, style? : any, disabled?: any, type?: any, margin? : any, multiline? : any, minRows?: any, helperText? :any, placeholder? : any }) => {

    React.useEffect(()=>{
        setLocalValue(value);
    },[value])


    const [localValue, setLocalValue] = React.useState(value);

    return (
        <TextField
            inputProps={inputProps}
            id="filled-name"
            InputProps={InputProps}
            type={type}
            variant={variant}
            placeholder={placeholder}
            helperText={helperText}
            name={name}
            margin={margin}
            style={style}
            fullWidth={fullWidth}
            disabled={disabled}
            label={label}
            multiline={multiline}
            minRows={minRows}
            value={localValue || ''}
            onBlur={(event) => {
                if (onChange){
                    return onChange(event);
                }
            }}
            onChange={(event: any) => {
                setLocalValue(event.target.value);
            }} />
    )
}