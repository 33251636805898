import axios from 'axios';
import { headers } from '../api';
import { USER_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { UserAccount } from '../models/UserAccount';
import { UserAssignedGroup } from '../models/UserAssignedGroup';

export const getUserAssignedGroupByInvitedUserId = async (token: string, invitedUserId: string): Promise<UserAssignedGroup> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/portal-users/${invitedUserId}/invited-assigned-groups`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
    }
    return null as any;

}