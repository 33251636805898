import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupLicenseTable } from '../models/GroupLicenseTable';
import { UserServiceWorkerObjectSession } from '../models/UserServiceWorkerObjectSession';

export const GetAllGroupsAsync = async (token: string): Promise<GroupLicenseTable[]> => {

    const userId = getUserIdFromStorage();

    return await axios
        .get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/all-groups-portal`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
};

export const GetAllGroupsByUserIdAsync = async (token: string, userId: string): Promise<GroupLicenseTable[]> => {

    return await axios
        .get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/all-groups-portal`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
};

export const GetAllGroupsByGroupIdsAsync = async (token: string, groupIds: string[]): Promise<GroupLicenseTable[]> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/multiple-groups-portal`, groupIds,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
};

export const getUniqueGroupsBySessions = async (token: string, tempSessions : UserServiceWorkerObjectSession[]) => {
    let allgroupIds = new Set(tempSessions.map(x=>x.groupId));
    let groups = await GetAllGroupsByGroupIdsAsync(token, Array.from(allgroupIds.values()));
    return groups;
}