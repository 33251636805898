
export class StringHelper {

    static IsValidEmail = (email: string | null): boolean => {

        if (!email) return false;
        for (let currentMail of email.split(';')){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!pattern.test(currentMail.toLowerCase())){
                return false;
            }
        }
        return true;

    }

    static IsNullOrWhiteSpace = (str: string | null | undefined): boolean => !str || str.match(/^ *$/) !== null;
    static toLeadingZeroNumber = (value: number, length: number): string => String(value).padStart(length, '0');
    static toInvoiceNumber = (value: number): string => `#${StringHelper.toLeadingZeroNumber(value, 10)}`;
    static isShortedThan = (str: string | null | undefined, requiredLength: number): boolean => !str || StringHelper.IsNullOrWhiteSpace(str) || str.length < requiredLength;
}