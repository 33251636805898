import axios from 'axios';
import { handleApiError, headers } from '../api';
import { StripeOrderDetails } from "../models/StripeOrderDetails";
import { getUserIdFromStorage } from '../helpers/common';

export const GetStripeClientSecretAsync = async (model: StripeOrderDetails, token: string): Promise<StripeOrderDetails> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/stripe-secrets/card`,
            { ...model },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return null;
        });
};
