import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserAddress } from '../models/UserAddress';

export const putUserObjectUnit = async (objectUnit: ObjectUnit | undefined, token: string, enqueueSnackbar: any): Promise<ObjectUnit> => {
    try {
        if (!objectUnit) throw (null);
        let userId = getUserIdFromStorage();
        objectUnit.userId = userId || "";
        let ret = await axios.put(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/object-unit`,
            { ...objectUnit },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Objekt Update erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Objekt Update error", { variant: 'error' });
    }
    return null as any;
}