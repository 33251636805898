import { formatISO } from "date-fns/fp";
import { IncidentVisitorChartType } from "../../components/authorized/portal/Dashboard/customerUsage/IncidentVisitorChart";
import { formatEuropeanDateWithDay, getDefaultPeriod } from "../../helpers/date-management";
import { GroupFeedback } from "../../models/GroupFeedback";
import { FeedbackQuestionType, GroupFeedbackQuestion } from "../../models/GroupFeedbackQuestion";
import { GroupIncidentVisitor } from "../../models/GroupIncidentVisitor";
import { GroupMotion } from "../../models/GroupMotion";
import { generateDisplayDate, generateDisplayDateSingle } from "./base";
import { UserServiceWorkerObjectSession } from "../../models/UserServiceWorkerObjectSession";


export const generateFeedbacksChartData = (groupFeedbacks: GroupFeedback[]) => {
    let arr = groupFeedbacks.map(el => {
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), "negativeAmount": el.negativeAmount, "positiveAmount": el.positiveAmount, "neutralAmount": el.neutralAmount, "veryNegativeAmount": el.veryNegativeAmount, "veryPositiveAmount": el.veryPositiveAmount }
    });
    return arr;

}

export const generateFeedbacksChartDataSum = (groupFeedbacks: GroupFeedback[]) => {
    let res: any = [];
    groupFeedbacks.forEach(el => {
        res.push({ date: formatISO(new Date(el.createdTimestamp * 1000)), key: "veryNegativeAmount", value: el.veryNegativeAmount });
        res.push({ date: formatISO(new Date(el.createdTimestamp * 1000)), key: "veryPositiveAmount", value: el.veryPositiveAmount });
        res.push({ date: formatISO(new Date(el.createdTimestamp * 1000)), key: "negativeAmount", value: el.negativeAmount });
        res.push({ date: formatISO(new Date(el.createdTimestamp * 1000)), key: "positiveAmount", value: el.positiveAmount });
        res.push({ date: formatISO(new Date(el.createdTimestamp * 1000)), key: "neutralAmount", value: el.neutralAmount });
    });
    return res;

}

export const generateIncidentVisitorChartData = (groupsServiceMode: GroupIncidentVisitor[], incidentVisitorType: IncidentVisitorChartType) => {
    let arr = groupsServiceMode.map(el => {
        if (!el.items) return el;
        let questions = el.items.reduce((acc, curVal) => {
            if (incidentVisitorType == IncidentVisitorChartType.Standard) {
                return { ...acc, [curVal.incidentText]: 1 }
            } else {
                return { ...acc, [`${curVal.incidentText} | ${curVal.reporterName}`]: 1 }
            }
        }, {});
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), ...questions }
    });
    return arr;
}

export const generateFeedbackQuestionChartData = (feedbackQuestions: GroupFeedbackQuestion[]) => {
    let arr = feedbackQuestions.map(el => {
        if (!el.items) return el;
        let questions = el.items.reduce((acc: any, curVal: any) => {
            return { ...acc, [curVal]: 1 }
        }, {});
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), ...questions }
    });
    return arr;
}

export const generateFeedbackQuestionCountChartData = (feedbackQuestions: GroupFeedbackQuestion[]) => {
    let arr = feedbackQuestions.map(el => {
        if (!el.items || el.items.length == 0) return { date: formatISO(new Date(el.createdTimestamp * 1000)), positiveCountFeedbackQuestion: el.feedbackQuestionType == FeedbackQuestionType.Negative ? 0 : 1, negativeCountFeedbackQuestion: el.feedbackQuestionType == FeedbackQuestionType.Negative ? 1 : 0 };
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), positiveCountFeedbackQuestion: el.feedbackQuestionType == FeedbackQuestionType.Negative ? 0 : el.items.length + 1, negativeCountFeedbackQuestion: el.feedbackQuestionType == FeedbackQuestionType.Negative ? el.items.length + 1 : 0 };
    });
    return arr;
}

export const generateFeedbackQuestionSumChartData = (feedbackQuestions: GroupFeedbackQuestion[]) => {
    let res: any = [];
    feedbackQuestions.forEach(el => {
        let incidents = el.items.reduce((acc: any, curVal: any) => {
            return { ...acc, [curVal]: 1 }
        }, {});
        Object.keys(incidents).forEach(key => {
            res.push({ date: formatISO(new Date(el.createdTimestamp * 1000)), key: key, value: incidents[key] });
        })
    });
    return res;
}

export const getAllFeedbackQuestions = (feedbackQuestions: GroupFeedbackQuestion[]) => {
    let allFeedbackQuestions = feedbackQuestions.reduce((acc: any[], curVal: GroupFeedbackQuestion) => {
        let res = curVal.items.filter(el => !acc.includes(el)).map(el => el);
        return [...acc, ...res];
    }, []);
    return allFeedbackQuestions;
}

export const getAllIncidentVisitor = (groupsServiceMode: GroupIncidentVisitor[], incidentVisitorType: IncidentVisitorChartType) => {
    let allFeedbackQuestions = groupsServiceMode.reduce((acc: string[], curVal: GroupIncidentVisitor) => {
        if (!curVal || !curVal.items) return acc;
        let res = curVal.items.filter(x => !!x.reporterName).filter(el => {
            if (incidentVisitorType == IncidentVisitorChartType.Standard) {
                return !acc.includes(el.incidentText || "");
            } else {
                return !acc.includes(`${el.incidentText} | ${el.reporterName}`);
            }
        }).map(el => {
            if (incidentVisitorType == IncidentVisitorChartType.WithName) {
                return `${el.incidentText} | ${el.reporterName}`;
            } else {
                return el.incidentText || "";
            }
        });
        return [...acc, ...res];
    }, []);
    return allFeedbackQuestions;
}

export const getAllFeebacksTypes = () => {
    return ["veryPositiveAmount", "positiveAmount", "neutralAmount", "negativeAmount", "veryNegativeAmount"];
}

export const getFeebacksTypesThreeSmiles = () => {
    return ["positiveAmount", "neutralAmount", "negativeAmount"];
}

export const getAllCleanerFeebacksTypes = () => {
    return ["veryPositiveAmount", "positiveAmount", "neutralAmount", "negativeAmount", "veryNegativeAmount"];
}

export class GroupMotionChart {
    public date !: string;
    public motionCount !: number;
    public blockCount !: number;
    public dateDisplay !: string;
    public sensorId !: string;
    public sensorRoomName !: string;
    public groupId !: string;
    public groupNotificationName !: string;
    [key: string]: number | string;
}

export const generateMotionsChartData = (groupMotions: GroupMotion[]) => {
    let arr = groupMotions.map(el => {
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), "motionCount": el.motionCount, "blockCount": el.blockCount, "dateDisplay": generateDisplayDateSingle(new Date(el.createdTimestamp * 1000), getDefaultPeriod()), "sensorId": (el.sensorId ? el.sensorId : ""), "sensorRoomName": el.sensorRoomName ? el.sensorRoomName : "", "groupId": el.groupId } as GroupMotionChart
    });
    return arr;
}


export const generateObjectSessionChartData = (objectSessions: UserServiceWorkerObjectSession[]) => {
    
    let arr = objectSessions.map(el => {
        console.log(el);
        return { date: new Date(el.signedOutOfObjectSinceTimestamp), "signCount": 1, "dateDisplay": generateDisplayDateSingle(new Date(el.signedOutOfObjectSinceTimestamp), getDefaultPeriod()), "objectId": el.objectId }
    });
    return arr;
}

export const generateMotionsChartDataSum = (groupMotions: GroupMotion[]) => {
    let res: any = [];
    groupMotions.forEach(el => {
        //     res.push({date : formatISO(new Date(el.createdTimestamp * 1000)), key: "motionCount", value: el.motionCount});
        res.push({ date: formatISO(new Date(el.createdTimestamp * 1000)), key: "blockCount", value: el.blockCount, "sensorId": (el.sensorId ? el.sensorId : ""), "sensorRoomName": el.sensorRoomName ? el.sensorRoomName : "" });
    });
    return res;

}

export const getAllMotionTypes = () => {
    return ["blockCount"];
}
