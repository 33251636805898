import React from 'react';
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    TextField,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Edit, EditAttributes, Group, TouchApp } from '@mui/icons-material';
import { formatEuropeanDate } from '../../helpers/date-management';
import { GroupLicense } from '../../models/GroupLicense';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { ReactComponent as LicenseSvg } from '../../assets/svgs/license_grey.svg'
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { api } from '../../api';
import { getApiToken } from '../../helpers/storage-management';
import Settings from '@mui/icons-material/Settings';
import { GroupSettings } from '../../models/GroupSettings';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

export const TemplateSettingsSaveDialog = ({ visible, onClose, row }
    : { visible: boolean, onClose: any, row: GroupSettings }) => {

    const classes = useStyles();
    const [userGroupSettingsTemplate, setUserGroupSettingsTemplate] = React.useState<UserGroupSettingsTemplate[]>([]);
    const [templateNameText,setTemplateNameText] = React.useState("");
    const [token, setToken] = React.useState("");
    const [disabled, setDisabled] = React.useState(false);
    const { enqueueSnackbar, } = useSnackbar();
    const history = useHistory();

    React.useEffect(() => {
        let token = getApiToken(history);
        (async () => {
            let settings = await api.getGroupSettingsTemplateByUser(token);
            setToken(token);
            setUserGroupSettingsTemplate(settings);
        })();

    }, []);

    const [selection, setSelection] = React.useState<UserGroupSettingsTemplate | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {

        setSelection(null);
        setLoading(false);

    }, [visible])
    const onSaveTemplate = async () => {

        if (!row) return;
        if (!row.id) return;

        let templateSettings = {...row} as UserGroupSettingsTemplate;
        templateSettings.name = templateNameText;
        setDisabled(true);
        await api.updateGroupSettings(row, token, enqueueSnackbar);
        const groupTemplate = await api.createUserGroupSettingsTemplate(templateSettings, token, enqueueSnackbar);
        await api.copyBlobItemsOfGroupToTemplateAsync(groupTemplate.id, row.id, token, enqueueSnackbar);
        setDisabled(false);
    }

    const onOverrideTemplate = async () => {
        if (!selection) return;
        let templateId = selection.id;
        let templateSettings = {...selection,...row} as UserGroupSettingsTemplate;
        templateSettings.id = templateId
        templateSettings.systemETag = null;
        setDisabled(true);
        await api.updateUserGroupSettingsTemplate(templateSettings, token, enqueueSnackbar);
        setDisabled(false);
    }
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={visible}
            onClose={onClose}
        >
            <DialogTitle className={classes.dialogTitle}>
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography className={classes.dialogTitleText}>Aktuelle Settings als Vorlage Speichern</Typography>
                        <IconButton color="inherit" onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <List>
                    {
                        userGroupSettingsTemplate &&
                        userGroupSettingsTemplate.length > 0 &&
                        userGroupSettingsTemplate.map((item: UserGroupSettingsTemplate, index: number) => (
                            <ListItem
                                key={index}
                                button
                                onClick={() => {
                                    setSelection(item);
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selection?.id === item.id}
                                        tabIndex={-1}
                                        inputProps={{}}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={`Name: ${item.name}`} />
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <TextField
                    fullWidth
                    label="Vorlage Name"
                    disabled={disabled || !row?.id}
                    value={templateNameText}
                    onChange={(event) => {
                        setTemplateNameText(event.target.value);
                    }}
                    variant="filled"
                />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={templateNameText.length < 1}
                    startIcon={<Add />}
                    onClick={async () => {
                        onSaveTemplate();
                        onClose();
                    }}
                >
                    Vorlage Hinzufügen
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!selection}
                    startIcon={<Edit />}
                    onClick={async () => {

                        if (!selection) return;

                        onOverrideTemplate();
                        onClose();
                    }}
                >
                    Vorlage {selection?.name} überschreiben
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 0,
        flexGrow: 1,
    },
    dialogTitleText: {
        flexGrow: 1,
    },
    dialogContent: {
        padding: theme.spacing(2),
        height: 320,
    },
    dialogContentRadio: {
        padding: theme.spacing(1),
    },
}));