import axios from 'axios';
import { headers } from '../../api';
import { UserAccount } from '../../models/UserAccount';


export const acceptTermsOfUseForUser = async (user: UserAccount,token: string,enqueueSnackbar:any) : Promise<UserAccount> => {
    try {
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/users/${user.id}/terms-of-use-accept`,
        {...user},
        {headers: {...headers,'Authorization': `Bearer ${token}` }})
        enqueueSnackbar("Nutzungsbedienung Akzeptiert",{variant:'success'});
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Nutzungsbedienung Error beim Eintrag",{variant:'error'});
    }
    return null as any;
}