import { StripePaymentMethod } from './PaymentMethod';
import { StripeRedirectStatus } from './StripeRedirectStatus';

export class VerifyTransactionModel {

    public id!: string;
    public paymentMethod!: StripePaymentMethod;
    public intent!: string;
    public secret!: string;
    public suceeded: boolean = false;

    static create = (
        id: string | null,
        paymentMethod: StripePaymentMethod,
        paymentIntent: string | null,
        paymentSecret: string | null,
        redirectStatus: StripeRedirectStatus): VerifyTransactionModel => {

        const model = new VerifyTransactionModel();

        model.id = id || '';
        model.paymentMethod = paymentMethod;
        model.intent = paymentIntent || '';
        model.secret = paymentSecret || '';
        model.suceeded = redirectStatus === StripeRedirectStatus.Succeeded;

        return model;
    }
}
