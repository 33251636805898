import { CheckoutItem } from './CheckoutItem';
import { StripePaymentMethod } from './PaymentMethod';
import { UserAddress } from "./UserAddress";
import { UserInvoiceAddress } from "./UserInvoiceAddress.1";

export class InvoicePaymentOrderDetails {

    public id!: string;
    public userId!: string;
    public immediateAssignGroupId! : string | null;
    public deliveryAddress!: UserInvoiceAddress;
    public paymentAddress!: UserInvoiceAddress;
    public items: CheckoutItem[] = [];
    public paymentMethod!: StripePaymentMethod;
    public taxRate: number = 20;

    static create = (
        userId: string,
        immediateAssignGroupId: string | null,
        paymentMethod: StripePaymentMethod,
        items: CheckoutItem[],
        deliveryAddress: UserAddress,
        paymentAddress: UserAddress) => {

        const orderDetails = new InvoicePaymentOrderDetails();

        orderDetails.userId = userId;
        orderDetails.immediateAssignGroupId = immediateAssignGroupId;
        orderDetails.paymentMethod = paymentMethod;
        orderDetails.deliveryAddress = deliveryAddress;
        orderDetails.paymentAddress = paymentAddress;
        orderDetails.items = items;

        return orderDetails;
    };
}
