import { Product } from './Product';

export class CheckoutItem {
    public count: number = 0;
    public sensorCount: number = 0;
    public product!: Product;
    public taxRate: number = 20;
    public unitPrice: number = 0;
    public total: number = 0;
    

    static createItem = (product: Product, count: number = 0, sensorCount: number=0, taxRate: number = 20): CheckoutItem => {

        const item = new CheckoutItem();
        
        item.count = count;
        item.product = product;
        item.sensorCount = sensorCount;
        item.updatePrice();

        return item;
    }

    public updatePrice = (): CheckoutItem => {

        if (!this.product) return this;

        this.unitPrice = this.product.unitPrice;
        this.total = this.count * this.unitPrice;

        return this;
    }

    public updateSensorCount = (sensorCount: number): CheckoutItem => {
        if (!this.product) return this;
        this.sensorCount = sensorCount;
        return this;
    }

    public updateCMSLicense = (cms: number): CheckoutItem => {
        if (!this.product) return this;
        return this;
    }
}
