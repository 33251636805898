import React, { useContext, useRef, useState } from "react";
import { getIncludeHourFilter } from "../../helpers/charts/base";
import { GlobalContext } from "../authorized/PortalPage";
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { TextField } from "@mui/material";

export function InlineDateTimePicker(props: any) {
  const globals: any = useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const oldVal = useRef(props.selectedDate);

  return <>
    {!globals.includeFilterHours && !props.includeHours ?
      < DatePicker
        disabled={props?.disabled}
        disableFuture

        slotProps={{ textField: { variant: 'outlined' } }}
        label={props.title ? props.title : "No Title"}
        value={props.selectedDate}
        onChange={(newVal) => props.setSelectedDate(newVal)}
        format={"dd.MM.yyyy"}

      />

      :

      <DateTimePicker
        disabled={props?.disabled}
        disableFuture
        ampm={false}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        slotProps={{ textField: { variant: 'outlined' } }}
        label={props.title ? props.title : "No Title"}
        value={props.selectedDate}
        onAccept={(event) => {
          oldVal.current = event;
          console.log(event, "onAccept");
        }}
        onChange={(newVal, event) => {
          if (oldVal.current != newVal) {
            props.setSelectedDate(newVal)
          }
        }}
        format={"dd.MM.yyyy HH:mm"}
        componentsProps={{
          toolbar: {
            hidden: false,
          },
        }} />
    }
  </>;
}

export function CustomDateTimePicker({ value, change, label }: { value: any, change: any, label: any }) {
  const [open, setOpen] = React.useState(false);
  const oldVal = useRef(value);
  return <>

    <DateTimePicker
      disableFuture
      ampm={false}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      slotProps={{ textField: { variant: 'outlined' } }}
            label={label}
      value={value}
      onAccept={(event) => {
        oldVal.current = event;
        console.log(event, "onAccept");
      }}
      onChange={(newVal, event) => {
        if (oldVal.current + "" != newVal + "") {
          change(newVal);
        }
      }}
      format={"dd.MM.yyyy HH:mm"}
      componentsProps={{
        toolbar: {
          hidden: false,
        },
      }} />
  </>;
}


export function InlineTimePicker({ value, change, label, disabled = false }: { value: any, change: any, label: string, disabled?: boolean }) {
  const oldVal = useRef(value);
  return <>

    <TimePicker
      disabled={disabled}
      slotProps={{ textField: { variant: 'outlined' } }}    
        ampm={false}
      format="HH:mm"
      label={label}
      value={value}
      onAccept={(event) => {
        oldVal.current = event;
        console.log(event, "onAccept");
      }}
      onChange={(newVal: any) => {
        console.log(newVal, oldVal.current, "onChange");
        if (oldVal.current + "" != newVal + "") {
          change(newVal);
        }
      }} />
  </>;
}

