import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getOwnUserIdFromStorage, getUserIdFromStorage } from '../helpers/common';
import { GroupLicense } from '../models/GroupLicense';

export const GetLicensesAsync = async (token: string): Promise<GroupLicense[]> => {

    const userId = getUserIdFromStorage();

    return await axios
        .get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/licenses`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
};


export const GetAllLicensesByAdminAsync = async (token: string): Promise<GroupLicense[]> => {

    const userId = getOwnUserIdFromStorage();

    return await axios
        .get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/licenses/admin`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
};
