import { GroupType } from "./Group";

export class HubModel {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public creationTimestamp!: string;
    public ssid!: string;     
    public userId!: string;     
    public version : string = ""; // lastUpdate
    public registeredTimestamp!: string;



    static Columns = {
        id: 'id',
        creationTimestamp: 'creationTimestamp',
        userId: 'userId',
        version: 'version',
        ssid: 'ssid',
        availableDisplays: 'availableDisplays',
        availableSensors: 'availableSensors',
    };


}

export class NetworkBatteryConfig {
    public prefer !: string;
    public wlan !: WlanBatteryConfig;
}

export class WlanBatteryConfig {
    public ssid !: string;
}