import React from "react";
import { Container, Typography } from "@mui/material";
import { api } from "../../api";
import { useChangeUserEmailStyles } from "../../styles/useChangeUserEmailStyles";
import { CircleProgressOverlay } from "../overlays/CircleProgressOverlay";

export const ChangeUserEmailPage = (props: any) => {

    const { match: { params: { userId, requestId } } } = props;
    const classes = useChangeUserEmailStyles();

    const [notificationText, setNotificationText] = React.useState<string>('');
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            const succeed = await api.changeUserEmailAsync(userId, requestId);
            setSuccess(succeed);
            setLoading(false);
        })();
    }, [userId, requestId]);

    React.useEffect(() => {
        (async () => {
            setNotificationText(success ?
                'Ihr Email Addresse wurde erfolgreich aktualisiert' :
                'Aktualisierung fehlgeschlagen. Wahrscheinlich wird diese Email Addresse bereits verwendet');
        })();
    }, [success]);

    return (
        <Container component="main" maxWidth="sm">
            <div className={classes.container}>
                <CircleProgressOverlay hidden={!loading} />
                <Typography component="h1" variant="h5">Email Änderung</Typography>
                <Typography className="Info" style={{ width: '100%', marginTop: 16 }} component="p" variant="body1">{notificationText}</Typography>
            </div>
        </Container>
    );
}