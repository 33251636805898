import axios from 'axios';
import { headers } from '../api';
import { TemplateInfo } from '../models/TemplateInfo';

export const UpdateTemplateInfoAsync = async (templateInfo: TemplateInfo, token: string, enqueueSnackbar: any): Promise<TemplateInfo> => {
    return await axios
        .put(`${process.env.REACT_APP_BASIC_URI}/templates/${templateInfo.id}`,
            { ...templateInfo },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Speichern von Vorlagen erfolgreich", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Beim Speichern von Vorlagen ist ein Fehler aufgetreten", { variant: 'error' });
        });
};
