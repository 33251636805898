import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from '../helpers/common';
import { GroupSettingsSideBarImageItem, GroupSettingsType } from "../models/GroupSettings";

export const deleteGroupSideBarSettingsAsync = async (groupId: string, id: string, type: GroupSettingsType, token: string, enqueueSnackbar: any): Promise<GroupSettingsSideBarImageItem[]> => {

    try {

        const userId = getUserIdFromStorage();

        let uri: string = '';
        switch (type) {
            case GroupSettingsType.Template:
                uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${groupId}/sidebar-settings/${id}`;
                break;
            default:
                uri = `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/sidebar-settings/${id}`;
                break;
        }

        const res = await axios.delete(uri, { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

        enqueueSnackbar("Werbung wurden erfolgreich gelöscht", { variant: 'success' });
        return res.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Werbung kann nicht gelöscht werden", { variant: 'error' });
    }
    return null as any;
};