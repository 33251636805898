import { useHistory } from 'react-router-dom';
import { GROUP_ID, GROUP_NAME, GROUP_TYPE, OBJECT_COMBINATION, OBJECT_ID, OBJECT_NAME, SERVICE_WORKER_COMBINATION, SERVICE_WORKER_ID, SERVICE_WORKER_NAME, USER_ID, USER_TOKEN } from "../constants";
import { GroupLicenseTable } from '../models/GroupLicenseTable';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';

export const getUserToken = (localToken: string = "") => {
    let tempUserToken = localStorage.getItem(USER_TOKEN);
    if (tempUserToken) {
        return tempUserToken;
    } else {
        return localToken;
    }
}
export const setUserTokenStorage = (token: string) => {
    localStorage.setItem(USER_TOKEN, token);
}

export const getApiToken = (history: any, fromLogin = false): string => {
    let tempUserToken = localStorage.getItem(USER_TOKEN);
    if (!tempUserToken && !fromLogin) {
        history.push("/login");
    }
    return tempUserToken || "";
}

export const getUserId = (): string => {
    return localStorage.getItem(USER_ID) as string;
}

export const useApiToken = (fromLogin = false) => {
    const history = useHistory();
    return getApiToken(history, fromLogin);
}

export const setStorageForDrawer = (selectedGroup: GroupLicenseTable) => {
    if (!selectedGroup) return;

    localStorage.setItem(GROUP_ID, selectedGroup.id);

    localStorage.setItem(GROUP_NAME, selectedGroup.notificationName);

    localStorage.setItem(GROUP_TYPE, selectedGroup.groupType.toString());
}

export const setObjectStorageForDrawer = (selectedObject: ObjectUnit) => {
    if (!selectedObject) return;

    localStorage.setItem(OBJECT_ID, selectedObject.id);

    localStorage.setItem(OBJECT_NAME, selectedObject.name);

}

export const setObjectStorageForMultipleDrawer = (selectedObjects: {id: string}[]) => {
    if (!selectedObjects) return;

    localStorage.setItem(OBJECT_COMBINATION, JSON.stringify(selectedObjects));

}

export const setServiceWorkerStorageForDrawer = (selectedServiceWorker: UserServiceWorker) => {
    if (!selectedServiceWorker) return;

    localStorage.setItem(SERVICE_WORKER_ID, selectedServiceWorker.id);

    localStorage.setItem(SERVICE_WORKER_NAME, selectedServiceWorker.name);

}

export const setServiceWorkerStorageForMultipleDrawer = (selectedServiceWorkers: {id: string}[]) => {
    if (!selectedServiceWorkers) return;

    localStorage.setItem(SERVICE_WORKER_COMBINATION, JSON.stringify(selectedServiceWorkers));

}

export const setStorage = (constant: string, object: any) => {
    localStorage.setItem(constant, JSON.stringify(object));

}
export const getStorage = <T,>(constant: string) => {
    return JSON.parse(localStorage.getItem(constant) || "null") as T;

}