import React, { ChangeEvent } from "react";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { StringHelper } from "../../../helpers/string-helper";
import { useTranslation } from "react-i18next";


export const MinimumTimePresenceComponent = ({ minimumTime, onChange, target, index }: { minimumTime: string, onChange: any, target: string, index?: any }) => {


    const [hoursMinimumTimePresence, setHoursMinimumTimePresence] = React.useState(0);
    const [minutesMinimumTimePresence, setMinutesMinimumTimePresence] = React.useState(30);
    const [secondsMinimumTimePresence, setSecondsMinimumTimePresence] = React.useState(0);
    const {t} = useTranslation();

    React.useEffect(() => {

        var timesToSplit = minimumTime;
        if (timesToSplit) {
            let [hoursMinimumTimePresence, minutesMinimumTimePresence, secondsMinimumTimePresence] = timesToSplit.split(":") as any;
            setHoursMinimumTimePresence(hoursMinimumTimePresence || 0);
            setMinutesMinimumTimePresence(minutesMinimumTimePresence || 30);
            setSecondsMinimumTimePresence(secondsMinimumTimePresence || 0);
        }

    }, [])

    return (
        <Grid container direction={"column"} spacing={3}>
            <Grid item>
                <Typography fontWeight={'bold'}>{t("myObjectSettings.minimumTimePresence")}</Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item>
                    <TextField
                        variant="outlined"
                        label={t("myUnitsSettings.hours")}
                        type="number"
                        InputProps={{
                            inputProps: { min: 0, max: 23 },
                        }}
                        value={hoursMinimumTimePresence}
                        onChange={(event: any) => {
                            if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                            setHoursMinimumTimePresence(event.target.value);

                            if (target == "intervalControlSpecificTimes") {
                                let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: `${event.target.value}:${minutesMinimumTimePresence}:${secondsMinimumTimePresence}`, index: index, property: 'minimumTime' }) } };
                                onChange(res);
                            } else {
                                let res = { target: { name: target, value: `${event.target.value}:${minutesMinimumTimePresence}:${secondsMinimumTimePresence}` } };
                                onChange(res);
                            }
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        label={t("myUnitsSettings.minutes")}
                        type="number"
                        InputProps={{
                            inputProps: { min: 0, max: 59 },
                        }}
                        value={minutesMinimumTimePresence}
                        onChange={(event: any) => {
                            if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                            setMinutesMinimumTimePresence(event.target.value);

                            if (target == "intervalControlSpecificTimes") {
                                let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: `${hoursMinimumTimePresence}:${event.target.value}:${secondsMinimumTimePresence}`, index: index, property: 'minimumTime' }) } };
                                onChange(res);
                            } else {
                                let res = { target: { name: target, value: `${hoursMinimumTimePresence}:${event.target.value}:${secondsMinimumTimePresence}` } };
                                onChange(res);
                            }
                        }}
                    />
                </Grid>
                {/*
                <Grid item>
                    <TextField
                        variant="outlined"
                        label={t("myUnitsSettings.seconds")}
                        type="number"
                        InputProps={{
                            inputProps: { min: 0, max: 59 },
                            endAdornment: <InputAdornment position="end">Sekunden</InputAdornment>
                        }}
                        value={secondsMinimumTimePresence}
                        onChange={(event: any) => {
                            if (StringHelper.IsNullOrWhiteSpace(event.target.value)) return;
                            setSecondsMinimumTimePresence(event.target.value);

                            if (target == "intervalControlSpecificTimes") {
                                let res = { target: { name: 'intervalControlSpecificTimes', value: JSON.stringify({ val: `${hoursMinimumTimePresence}:${minutesMinimumTimePresence}:${event.target.value}`, index: index, property: 'minimumTime' }) } };
                                onChange(res);
                            } else {
                                let res = { target: { name: target, value: `${hoursMinimumTimePresence}:${minutesMinimumTimePresence}:${event.target.value}` } };
                                onChange(res);
                            }

                        }}
                    />
                </Grid>
                    */}
            </Grid>
        </Grid>
    )
}