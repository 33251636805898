import { Apartment, FileDownload, FindReplace, Print, QrCode, Refresh, RefreshOutlined, SdStorage, Smartphone, Upload } from "@mui/icons-material";
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import React, { useRef } from "react";
import { ChromePicker } from "react-color";
import { useReactToPrint } from "react-to-print";
import { primaryColor } from "../../../App";
import { GroupType } from "../../../models/Group";
import IconCMN from '../../../assets/svgs/check-me-now_icon.png'
import { ObjectUnit } from "../../../models/ObjectUnit";
import { api } from "../../../api";
import { getApiToken } from "../../../helpers/storage-management";
import { UserLogo } from "../../../models/UserLogo";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { GroupLogosDialog } from "../../dialogs/GroupLogosDialog";
import { buildPrintItemGroup } from "../../core/GroupSettingFieldsLocation";
import { useTranslation } from "react-i18next";

export const BuildQrCodeLogo = ({ qrCode, firstTitle, secondTitle, defaultLogo, objectUnit, setObject, setQrCodeImage }: { qrCode: any, firstTitle: any, secondTitle: any, defaultLogo?: any, objectUnit: ObjectUnit, setObject: any, setQrCodeImage: any }) => {

    const [logo, setLogo] = React.useState<any>(IconCMN);
    const [loading, setLoading] = React.useState(false);
    const [logos, setLogos] = React.useState<UserLogo[]>([]);
    const [groupLogosDialogIsOpen, setOpenGroupLogosDialog] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [logosToLoad, setLogosToLoad] = React.useState(0);
    const { t } = useTranslation();
    const refreshLogo = async () => {
        let logo = "";// = await api.downloadLogoImageAsync(row as GroupSettings, token);
        if (!logo) logo = IconCMN;
        setLogo(logo);
    }
    const refPrint: any = useRef();
    const history = useHistory();

    const onLogoFileChosen = async (event: any) => {

        const file = event.target.files[0];
        const blob = new Blob([file]);
        const url = URL.createObjectURL(blob);

        setLogo(url);
    }

    const handlePrint = useReactToPrint({

        content: () => refPrint.current,
        onAfterPrint: () => {

        }

    });
    React.useEffect(() => {
        if (defaultLogo) setLogo(defaultLogo);
    }, []);
    return (
        <React.Fragment>
            <Grid item container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={6} container direction="row" spacing={2}>
                    <Grid item>
                        <Button size="small" variant="contained" startIcon={<FileDownload />} color="primary" href={qrCode} download={`${firstTitle}.png`}>
                            {t("myUnitsSettings.downloadQRCode")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button size="small" variant="contained" startIcon={<Print />} color="primary" onClick={handlePrint}>
                            {t("myUnitsSettings.printQRCode")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button size="small" variant="contained" startIcon={<FileDownload />} color="primary" onClick={() => handleDownloadImage(refPrint)}>
                            {t("myUnitsSettings.downloadLabel")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            startIcon={<SdStorage />}
                            component="label">
                            Logo von Festplatte
                            <input
                                type="file"
                                name="updateLogo"
                                onChange={onLogoFileChosen}
                                hidden
                                accept="image/png, image/jpeg, image/jpg" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size="small"
                            startIcon={loading ? <CircularProgress color="inherit" /> : <FindReplace />}
                            variant={'contained'}
                            disabled={loading}
                            onClick={async () => {
                                if (logos.length > 0) {
                                    setOpenGroupLogosDialog(true);
                                    return;
                                }
                                const tokenTemp = getApiToken(history);
                                setLoading(true);
                                let logosTemp = await api.getLogosOfGroup(tokenTemp);
                                let ret = [];
                                setLogosToLoad(logosTemp.length);
                                for (let logo of logosTemp) {
                                    let res = await api.downloadAnyLogoImageAsync(logo.filename, logo.groupId, tokenTemp);
                                    logo.objectUrl = res;
                                    ret.push(logo);
                                    setLogosToLoad((prev) => prev - 1);
                                }
                                setLogos(ret);
                                setOpenGroupLogosDialog(true);
                                setLoading(false);
                            }}
                            color="primary">
                            {loading ? logosToLoad + " Logos werden noch geladen" : "Gespeicherte Logos ansehen"}
                        </Button>
                    </Grid>

                </Grid>
                <Grid item>
                    <TextField
                        label={t("myUnitsSettings.printSize")}
                        value={objectUnit.qrCodeInformation.printSize}
                        type="number"
                        onChange={(event) => {
                            setObject({ ...objectUnit, qrCodeInformation: { ...objectUnit.qrCodeInformation, printSize: noNullInInputNumber(event) } })
                        }}
                        variant="filled"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={t("myUnitsSettings.fontSizeButton")}
                        value={objectUnit.qrCodeInformation.fontSizeButton}
                        type="number"
                        onChange={(event) => {
                            setObject({ ...objectUnit, qrCodeInformation: { ...objectUnit.qrCodeInformation, fontSizeButton: noNullInInputNumber(event) } })
                        }}
                        variant="filled"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={t("myUnitsSettings.fontSizeBottomRight")}
                        value={objectUnit.qrCodeInformation.fontSizeBottomRight}
                        type="number"
                        onChange={(event) => {
                            setObject({ ...objectUnit, qrCodeInformation: { ...objectUnit.qrCodeInformation, fontSizeBottomRight: noNullInInputNumber(event) } })
                        }}
                        variant="filled"
                    />
                </Grid>



                <Grid container item justifyContent="center" direction="row">

                    <Grid item>
                        <Grid item style={{ textAlign: 'center' }}>
                            <Typography variant="h3">{t("myUnitsSettings.preview")}</Typography>
                        </Grid>
                        <Button variant="contained" startIcon={<Refresh />} color="primary" onClick={refreshLogo}>
                            {t("myUnitsSettings.refreshPreview")}
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={3} spacing={3} container direction="column" style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Grid item>
                        <Typography variant="h5">{t("myUnitsSettings.labelBackgroundColor")}</Typography>
                    </Grid>
                    <Grid item>
                        <ChromePicker color={objectUnit.qrCodeInformation.backgroundColor} onChange={(event) => {
                            setObject({ ...objectUnit, qrCodeInformation: { ...objectUnit.qrCodeInformation, backgroundColor: event.hex } })
                        }} />
                    </Grid>
                </Grid>
                <Grid item xs={3} spacing={3} container direction="column" style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Grid item>
                        <Typography variant="h5">{t("myUnitsSettings.borderColor")}</Typography>
                    </Grid>
                    <Grid item>
                        <ChromePicker color={objectUnit.qrCodeInformation.borderColor} onChange={(event) => {
                            setObject({ ...objectUnit, qrCodeInformation: { ...objectUnit.qrCodeInformation, borderColor: event.hex } })
                        }} />
                    </Grid>
                </Grid>
                <Grid container item flexDirection={"row"} spacing={3} justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <TextField
                            label="Url"
                            value={objectUnit.qrCodeInformation.url}
                            onChange={(event) => {
                                setObject({ ...objectUnit, qrCodeInformation: { ...objectUnit.qrCodeInformation, url: event.target.value } })
                            }}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={loading}
                            startIcon={loading ? <CircularProgress color="inherit" /> : <RefreshOutlined />}
                            size="large" variant="contained" color="primary" onClick={async () => {
                                setLoading(true);
                                await setQrCodeImage();
                                setLoading(false);
                            }}>
                            Refresh Qr Code (URL)
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item>
                        {buildPrintItemGroup(objectUnit.qrCodeInformation.printSize, objectUnit.qrCodeInformation.fontSizeButton, objectUnit.qrCodeInformation.fontSizeBottomRight, refPrint, qrCode, objectUnit.qrCodeInformation, logo, firstTitle, secondTitle, logo, "Objekt")}
                    </Grid>
                </Grid>


            </Grid>
            <GroupLogosDialog logos={logos} visible={groupLogosDialogIsOpen}
                onConfirmed={async (item: UserLogo) => {
                    setOpenGroupLogosDialog(false);
                    let blob = await fetch(item.objectUrl).then(r => r.blob());
                    const file = new File([blob], "random", { type: blob.type })
                    setLogo(item.objectUrl);
                }}
                onClose={() => {
                    setOpenGroupLogosDialog(false);
                }
                }
                onDelete={async (item: UserLogo) => {
                    const tokenTemp = getApiToken(history);
                    let settings = await api.getGroupSettings(item.groupId, tokenTemp);
                    if (settings.logo?.fileName == item.filename) {
                        enqueueSnackbar("Logo wird gerade von der Einheit verwendet! Kann nicht gelöscht werden", { variant: 'error' });
                        return;
                    }
                    await api.deleteGroupLogoAsync(item.groupId, item.filename, tokenTemp, enqueueSnackbar)
                }}
            />
        </React.Fragment>
    );
}

export const buildPrintItem = (size = 500, fontSizeSpec = 60, fontSizeTypo = 40, refPrint: any, borderColorQrCode: any, backgroundColorQrCode: any, qrCode: any, logo: any, fistTitle: any, secondTitle: any) => {
    return (
        <Grid ref={refPrint} container justifyContent="center" alignContent="center" alignItems="center" className="page-root qr-code-field" style={{ textAlign: 'center', width: size * 2 + 100, backgroundColor: backgroundColorQrCode, padding: 20 }}>

            <Grid item xs={6} style={{ height: size }}>
                <img src={qrCode} style={{ width: size, height: size, borderRadius: fontSizeTypo, border: `${fontSizeTypo / 2}px solid ${borderColorQrCode}` }} />
            </Grid>
            {logo &&
                <Grid item xs={6} style={{ width: size, height: size, backgroundImage: `url(${logo})`, backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

                </Grid>
            }
            <Grid item xs={6}>
                <Button variant="contained" style={{ backgroundColor: borderColorQrCode, color: 'white', borderRadius: fontSizeTypo, fontSize: fontSizeSpec }} startIcon={<Apartment style={{ fontSize: fontSizeSpec }} />} size={"large"}>
                    Objekt
                </Button>
            </Grid>
            <Grid item container direction="column" xs={6} >
                <Grid item>
                    <Typography style={{ fontSize: fontSizeTypo }}>{fistTitle}</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: fontSizeTypo }}>{secondTitle}</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: fontSizeTypo }}>www.check-me-now.com</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const handleDownloadImage = async (refPrint: any, name: string = "image.png") => {
    const element = refPrint.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
        link.href = data;
        link.download = name;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        window.open(data);
    }
};

export const handleDownloadImageWithoutRef = async (refPrint: any) => {
    const element = refPrint;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
        link.href = data;
        link.download = 'image.png';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        window.open(data);
    }
};

export const noNullInInputNumber = (event: any) => {
    let refVal = 1;
    if (event.target.value == 0) {
        refVal = 1;
    } else {
        refVal = Number.parseInt(event.target.value);
    }
    return refVal;
}