import axios from 'axios';
import { handleApiError, handleApiSuccess, headers } from '../api';
import { Group } from '../models/Group';


export const createGroupCheckpoint = async (userId: string, licenseId: string,userToken: string, enqueueSnackbar:any) : Promise<Group> => {
    try {
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/licenses/${licenseId}/groups-checkpoint`,{
        }, {headers: {...headers,'Authorization': 'Bearer '+userToken}})
        handleApiSuccess(ret.data, enqueueSnackbar, "Checkpoint wurde erstellt");
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar,"Checkpoint konnte nicht erstellt werden!");
    }
    return null as any;
}