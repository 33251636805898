import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { UserAddress } from '../models/UserAddress';
import { t } from 'i18next';

export const createUserAddress = async (userAddress: UserAddress | undefined, token: string, enqueueSnackbar: any): Promise<UserAddress> => {
    try {
        if (!userAddress) throw (null);
        let userId = getUserIdFromStorage();
        userAddress.userId = userId || "";
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/user-address`,
            { ...userAddress },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar(`Adresse Speichern erfolgreich`, { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar(`Adresse Speichern error`, { variant: 'error' });
    }
    return null as any;
}