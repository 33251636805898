import Dialog from '@mui/material/Dialog';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { getApiToken, setObjectStorageForDrawer, setServiceWorkerStorageForDrawer, setServiceWorkerStorageForMultipleDrawer } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { GlobalContext } from '../../authorized/PortalPage';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { UserServiceWorkerSelectionDataTable } from '../../authorized/extendedapp/entireEval/UserServiceWorkerSelectionDataTable';
import { useTranslation } from 'react-i18next';

export const ServiceWorkerSelectionEvaluationDialog = ({
  open,
  setOpen,
  isMultiple = false
}: {
  open: boolean,
  setOpen: any,
  isMultiple?: boolean
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
  const { selectedServiceWorkerId, setSelectedServiceWorkerId, setSelectedServiceWorkerName, setSelectedCombinationServiceWorker, selectedCombinationServiceWorker }: any = useContext(GlobalContext);
  const [selectedServiceWorker, setSelectedServiceWorker] = React.useState<UserServiceWorker | undefined>(undefined);

  const [selectedServiceWorkers, setSelectedServiceWorkers] = React.useState<UserServiceWorker[]>([]);
  const {t} = useTranslation();


  const handleClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const token = getApiToken(history);
      if (!token) return;

      const tempGroups = await api.getAllGroups(token);
      setGroups(tempGroups);

      const tempObjects = await api.getUserObjectUnits(token);
      setObjects(tempObjects);

      const tempUserServiceWorkers = await api.getServiceWorkers(token);
      setUserServiceWorkers(tempUserServiceWorkers);

      if (isMultiple) {
        setSelectedServiceWorkers(tempUserServiceWorkers.filter(x => selectedCombinationServiceWorker.some((y: { id: string }) => y.id == x.id)));
      } else {
        setSelectedServiceWorker(tempUserServiceWorkers.filter(x => x.id === selectedServiceWorkerId)[0]);
      }

    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={`Wähle Servicekraft für ${isMultiple ? "Mehrfachauswertung" : "Einzelauswertung"}`}
        handleClose={handleClose}
      />

      <DialogContent>
        <UserServiceWorkerSelectionDataTable rows={userServiceWorkers} objects={objects} groups={groups} currentGroup={null}
          selectedUserServiceWorkers={
            isMultiple ? selectedServiceWorkers :
              selectedServiceWorker ? [selectedServiceWorker] : []
          }
          onSelectionChange={(selection: string[]) => {
            if (isMultiple) {
              setSelectedServiceWorkers(userServiceWorkers.filter(x => selection.includes(x.id)));
            } else {
              let selected = selection[selection.length - 1];
              setSelectedServiceWorker(userServiceWorkers.filter(x => selected === x.id)[0]);
            }
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<TouchApp />}
          disabled={isMultiple ? selectedServiceWorkers.length < 1 : !selectedServiceWorker}
          onClick={() => {
            if (isMultiple) {

              let saveToStorageItems = selectedServiceWorkers.map(x => { return { id: x.id } });
              setServiceWorkerStorageForMultipleDrawer(saveToStorageItems);
              setSelectedCombinationServiceWorker(saveToStorageItems);
              history.push(`/portal/service-worker/multiple/objects/sessions?`);
              handleClose();
            } else {

              if (!selectedServiceWorker) return;

              setSelectedServiceWorkerId(selectedServiceWorker.id);
              setSelectedServiceWorkerName(selectedServiceWorker.name);
              setServiceWorkerStorageForDrawer(selectedServiceWorker);

              history.push(`/portal/service-worker/${selectedServiceWorker.id}/objects/sessions`);
              handleClose();
            }

          }}
        >
          Servicekraft auswählen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));