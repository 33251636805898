import axios from 'axios';
import { PUBLIC_CURRENT_USER_SERVICE_WORKER, PUBLIC_SERVICEWORKER_TOKEN } from '../../constants';
import { UserServiceWorker } from '../../models/UserServiceWorker';
import { getStorage } from '../../helpers/storage-management';
import { headers } from '../../api';


export const signIntoObject = async (objectId: string, groupId: string, enqueueSnackbar: any): Promise<any> => {
  try {
    let token = localStorage.getItem(PUBLIC_SERVICEWORKER_TOKEN);
    let serviceWorkers = await getStorage<UserServiceWorker>(PUBLIC_CURRENT_USER_SERVICE_WORKER);
    let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/service-workers/${serviceWorkers.id}/sign-into-object/${objectId}`,
      {groupId: groupId},
      { headers: { ...headers, 'Authorization': 'Bearer ' + token } });

      enqueueSnackbar("Objekt Anmeldung erfolgreich!", { variant: 'success' });
    return true;
  } catch (err) {

    enqueueSnackbar("Objekt Anmeldung fehlgeschlagen", { variant: 'error' });

    return false;
  }
}