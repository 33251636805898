import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { DialogActions, DialogContent, Divider, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../../helpers/storage-management';
import { DataFileTree, ObjectRenameFile } from '../../../../models/DataFileTree';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const RenameFileDialog = ({ open, setOpen, onAdd, selectedItems }
    : { open: boolean, setOpen: any, onAdd: any, selectedItems: DataFileTree[] }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [text, setText] = React.useState("");
    const [selectedFiles, setSelectedFiles] = React.useState<ObjectRenameFile[]>([]);
    const {t} = useTranslation();

    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        setSelectedFiles(selectedItems.map(x => {
            return new ObjectRenameFile(x.name || "", x.fullPath, x.fullPath + x.name);
        }))
    }, [open])

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    Datei umbennen
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container direction="column" spacing={3}>
                        {selectedFiles.map(x => {
                            return (
                                <Grid item container direction={'row'} alignItems="center" spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            style={{ width: '100%' }}
                                            id="filled-number"
                                            label="Pfad"
                                            variant="filled"
                                            value={x.path}
                                            onChange={(event: any) => {
                                                setSelectedFiles(selectedFiles.map(selectedFile => {
                                                    if (selectedFile.oldFullFilename == x.oldFullFilename) {
                                                        selectedFile.path = event.target.value;
                                                    }
                                                    return selectedFile;
                                                }))
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            style={{ width: '100%' }}
                                            id="filled-number"
                                            label="Dateiname"
                                            variant="filled"
                                            value={x.filename}
                                            onChange={(event: any) => {
                                                setSelectedFiles(selectedFiles.map(selectedFile => {
                                                    if (selectedFile.oldFullFilename == x.oldFullFilename) {
                                                        selectedFile.filename = event.target.value;
                                                    }
                                                    return selectedFile;
                                                }))
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>

                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button
                        size="large"
                        startIcon={<Edit />}
                        variant={'contained'}
                        color="primary"
                        onClick={async () => {
                            onAdd(selectedFiles);
                            handleClose();
                        }}
                    >
                        {t("shared-folders-objects-all.renameFile")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));