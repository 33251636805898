import { Tooltip, ValueAxis } from "@devexpress/dx-react-chart-material-ui";
import { Typography, CircularProgress } from "@mui/material";
import { Font, Format, Label } from "devextreme-react/chart";
import { useNoDataChartStyles } from "../../styles/noDataChart";
import { ChartLabelAlternative } from "./LineChart";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export enum ChartLabelType {
    "Sum" = 0,
    "Percent" = 1,
    "Comma" = 2,
    "Minutes" = 3,
    "Hours" = 4,
    "Seconds" = 5,
    "Co2" = 6,
    "SquareMeter" = 7
}

export function customizePercentageText({ valueText }: any) {
    return `${valueText}%`;
}

export const ToolTipWithLegend = (props: any) => {
    const { text, targetItem } = props;
    return (
        <Tooltip.Content
            {...props}
            text={`${targetItem.series} : ${text}`}
        />
    );
};

export const NoDataChartText = (props: any) => {

    const {t} = useTranslation();
    const { text = t('object-sessions.noData'), title = "" }: { text: string, title: string } = props;
    const styles = useNoDataChartStyles();

    return (
        <div className={styles.root}>
            <Typography component="h3" variant="h3" style={{ margin: 'auto' }}>{`${title} \n ${text}`}</Typography>
        </div>
    );
}

export const NoDataChartLoader = (props: any) => {
    const { data, loadingCompleted = false, size = 128, thickness = 6 }
        : { data: any[], loadingCompleted: false, size: number | string, thickness: number } = props;

    const styles = useNoDataChartStyles();

    if (!loadingCompleted && (!data || data.length === 0))
        return (
            <div className={styles.root}>
                <CircularProgress size={size} thickness={thickness} />
            </div>
        );

    if (data && data.length === 0)
        return <NoDataChartText title={props.title} />

    return null;
}

export const ValueLabel = (props: any) => {
    const { text } = props;
    let num = parseFloat(text);
    if (Math.ceil(num) === num) {
        return (
            <ValueAxis.Label
                {...props}
                text={`${num}`}
            />
        );
    }
    return (
        <ValueAxis.Label
            {...props}
            text={``}
            x={10000}
        />
    );
};

export const customizeLegendTextBarLineChart = (arg: any) => {
    return mapLabels(arg.seriesName);
}

export const mapLabels = (text: any, chartLabelAlternative: ChartLabelAlternative = ChartLabelAlternative.Standard) => {
    switch (text) {
        case "kmCo2Consumption":
            return "Anreise"
        case "clothesCo2Consumption":
            return "Kleidung"
        case "signCount":
            return "Anmeldungen"
        case "negativeAmount":
            return "Negativ"
        case "veryNegativeAmount":
            return "Sehr Negativ"
        case "veryPositiveAmount":
            return "Sehr Positiv";
        case "positiveAmount":
            return "Positiv";
        case "neutralAmount":
            return "Neutral";
        case "blockCount":
            if (chartLabelAlternative == ChartLabelAlternative.Standard) {
                return "Besucher"
            } else {
                return "Σ"

            }
        case "Planned":
            return "Intervallauslösung";
        case "ManualFromObjectLeader":
            return "Objektleiter";
        case "Feedback":
            return "Feedbackauslösung";
        case "Motion":
            return "Besucherauslösung";
        case "allFeedback":
            return "Feedback Gesamt pro Besucher";
        case "allFeedbackCount":
            return "Feedback Anzahl"
        case "allFeedbackSum":
            return "Feedbackqualität (mit Besucher)"
        case "allFeedbackWithoutVisitor":
            return "Tatsächliche Feedbackqualität"
        case "timeOfService":
            return t("myUnitsSettings.duration")
        case "plannedDuration":
            return "Geplante Zeit"
        case "realDuration":
            return "Tatsächliche Zeit"
        case "savedDuration":
            return "Eingesparte Zeit"
        case "actualCarbonDixoideConsumption":
            return "Tatsächlicher Co2 Verbrauch"
        case "setPointcarbonDixoideConsumption":
            return "Geplante Co2 Verbrauch"
        case "savedCarbonDixoideConsumption":
            return "Eingesparte Co2 Verbrauch"
        case "actualAreaSize":
            return "Tatsächliche Fläche"
        case "setPointAreaSize":
            return "Geplante Fläche"
        case "savedAreaSize":
            return "Eingesparte Fläche"

        case "actualServiceModesCount":
            return "Tatsächliche Services"
        case "setPointServiceModesCount":
            return "Geplante Services"
        case "savedServiceModesCount":
            return "Eingesparte Services"
        case "positiveCountFeedbackQuestion":
            return "Positiv Feedbackfragen"
        case "negativeCountFeedbackQuestion":
            return "Negativ Feedbackfragen"
        default:
            return text;
    }

}

export const customizeLabel = (point: any) => {
    return `${point.argumentText}: ${point.valueText}`;
}
export const customizeLabelWithPercent = (point: any) => {
    return `${point.argumentText}:\n Gesamt: ${point.valueText} \nProzent: ${point.percentText}`;
}
export const customizeLabelData = (point: any) => {
    //console.log("GetArgumentField", point);
    return `${mapLabels(point.key)}: ${point.valueText}`;
}

export const mapChartKeyFeedback = (data: any[]) => {
    return data.map((el: any) => {
        el.key = mapLabels(el.key);
        return el;
    })
}

export const mapGermanChartLabelMotion = (data: any[]) => {
    return data.map((el: any) => {
        el.Besucher = el.blockCount;
        return el;
    })
}

export const mapChartKeyMotion = (data: any[]) => {
    return data.map((el: any) => {
        el.key = mapLabels(el.key);
        return el;
    })
}

export const chartHeight = 450;
export const chartHeightBig = 850;

export const customizeTooltip = (arg: any) => {
    return {
        text: `${mapLabels(arg.seriesName)}: ${arg.valueText}`
    };
}

export const customizeTooltipWithType = (arg: any, chartLabelType: any, chartLabelAlternative: ChartLabelAlternative = ChartLabelAlternative.Standard) => {
    switch (chartLabelType) {
        case ChartLabelType.Percent:
            return {
                text: `${mapLabels(arg.seriesName, chartLabelAlternative)}: ${arg.valueText}%`
            };
        default:
            let htmlContent = `<span class='top-series-name'>${mapLabels(arg.seriesName, chartLabelAlternative)}:</span> </div><div class="value-text"><span class='top-series-value'>${arg.valueText}</span></div>`
            htmlContent += `<div class="series-name"><span class='bottom-series-name'>Prozent:</span> </div><div class="value-text"><span class='bottom-series-value'>${arg.percentText}</span></div>`;
            return {
                html: `<div><div class="tooltip-header">${arg.argumentText}</div><div class="tooltip-body"><div class="series-name">
                    ${htmlContent}
                    </div>`,
            };

    }
}

const customizeLabelChart = (point: any) => {
    if (point.valueText == 0) {
        return "";
    }
    return `${point.valueText}%`;
}

export const buildBarLabel = (chartLabelType: any) => {
    switch (chartLabelType) {
        case ChartLabelType.Percent:
            return (
                <Label visible={true}
                    customizeText={customizeLabelChart}>
                    <Font weight={'bold'} size={18} />

                </Label>
            )
        case ChartLabelType.Comma:
            return (
                <Label visible={true}
                    customizeText={(point: any) => `${mapLabels(point.seriesName)}:\nGesamt: ${point.valueText}\n Prozent:${point.percentText}`}>
                    <Font weight={'bold'} size={18} />

                </Label>
            );
        default:
            return (
                <Label visible={true}
                    customizeText={(point: any) => `${point.valueText}${point.percentText == '100%' ? "" : ` / ${point.percentText}`}`}
                >
                    <Font weight={'bold'} size={18} />
                    <Format type="fixedPoint" precision={0} />
                </Label>
            )
    }
}