import axios from "axios";
import { headers } from "../../api";
import { getUserIdFromStorage } from "../../helpers/common";
import { GroupLicense } from "../../models/GroupLicense";
import { SensorLicense } from "../../models/GroupLicenseTable";
import { GroupSettingsSensorLicense } from "../../models/GroupSettings";

export const AssignLoraDeviceToGroupByIdAsync = async (groupId: string, deviceId: string, sensorLicense : GroupSettingsSensorLicense,token: string, enqueueSnackbar: any) => {

    if (!groupId || !deviceId || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();

    await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/lora-devices/${deviceId}/assign`,
        {...sensorLicense},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Sensor wurde erfolgreich zugewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};