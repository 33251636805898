import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Divider, Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, ManageAccounts, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { errorColor, white } from '../../../../styles/colors';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ReactComponent as MotionSensorSvg } from '../../../../assets/svgs/motion-sensor.svg';
import { HubModel } from '../../../../models/HubModel';
import { useTranslation } from 'react-i18next';

export const TorkDevicesLocations = ({
  open,
  setOpen,
}: {
  open: boolean,
  setOpen: any,
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [token, setToken] = React.useState("");
  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
  const [dispensers, setDispensers] = React.useState<any[]>([]);
  const handleClose = () => setOpen(false);
  const {t} = useTranslation();

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;

      let items = await api.getLocationsFromTork(tempToken);
      setDispensers(items || []);
      setToken(tempToken);

    })();

  }, [open]);

  const disabled = selectedGroups.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Tork Locations Anzeige`}
        handleClose={handleClose}
      />

      <DialogContent>
        <Grid container spacing={3} direction="column">
          {dispensers.map(item => {
            return (
              <Grid item>
                <pre>{JSON.stringify(item, null, 2)}</pre>
                <Divider />
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.close")}
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));