import { GroupServiceModeIncidentImage, GroupServiceModeIncidentPhoto } from "./GroupServiceMode";

export enum AdminSettingItemType
{
    TouchDisplay = 0,
    Portal = 1
}

export enum AdminSettingNewsType
{
    Standard = 0,
    Important = 1
}


export class AdminSetting {
    public id!: string;
    public systemETag!: string;
    public systemTimestamp!: string;
    
    public creatorUserId !: string;
    public createdTimestamp !: string;

    public activeApks !: string;
    public activeApksVersion !: string;


    public currentDisplayApkVersion !: string;

    public active !: boolean;
    public deviceRestartTime !: string;
    public isDeviceRestartTimeEnabled !: boolean;
    public systemCommand !: string;

    public adminSettingType !: AdminSettingItemType;
    public adminSettingNewsType !: AdminSettingNewsType;
    public adminUsageCategories !: AdminUsageCategory[];

    static Columns = {
        id: 'id',
        creatorUserId: 'creatorUserId',
        text: 'text',
        createdTimestamp: 'createdTimestamp',
        currentDisplayApkVersion: 'currentDisplayApkVersion',
        activeAoks: 'activeAoks',
        itemType: 'itemType',
        actions: 'actions',
        active: 'active',
        deviceRestartTime: 'deviceRestartTime',
        isDeviceRestartTimeEnabled: 'isDeviceRestartTimeEnabled',
        isAdminItem : 'isAdminItem',
        systemCommand : 'systemCommand',
        adminSettingType: 'adminSettingType',
        adminSettingNewsType: 'adminSettingNewsType',
        adminUsageCategory: 'adminUsageCategory'
    }

    constructor () {

    }

    static AvailableTypes = [
        
        { value: AdminSettingItemType.TouchDisplay, label: 'Touch Display' },
        { value: AdminSettingItemType.Portal, label: 'Portal' }
    ];

    static AvailableTypesNews = [
        
        { value: AdminSettingNewsType.Standard, label: 'Standard' },
        { value: AdminSettingNewsType.Important, label: 'Wichtig' }
    ];

    static GetLabelByType = (type: AdminSettingItemType | undefined) => AdminSetting.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
    static GetLabelNewsByType = (type: AdminSettingNewsType | undefined) => AdminSetting.AvailableTypesNews.filter(x => x.value === type).map(x => x.label)[0] || '';

}

export class AdminUsageCategory {
    public carbonDioxideConsumption : number = 0;
    public name : string = "";
    public id : string = "";
    public isEnabled !: boolean;
}