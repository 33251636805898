import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupServiceMode } from '../models/GroupServiceMode';
import { CleaningOperation } from '../models/GroupSettings';

export const GetSettingsServiceEntriesOfObject = async (token: string, objectIds: string[]): Promise<Array<CleaningOperation>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-settings-entries-by-objects`,{stringArray: objectIds},
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}

export const GetSettingsServiceEntriesOfServiceWorkers = async (token: string, serviceWorkerIds: string[]): Promise<Array<CleaningOperation>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-settings-entries-by-service-workers`,{stringArray: serviceWorkerIds},
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}

export const GetSettingsServiceEntriesByGroups = async (token: string, groupIds: string[]): Promise<Array<CleaningOperation>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-settings-entries`, {stringArray: groupIds},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
};
