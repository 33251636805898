import React from "react";
import { useHistory } from "react-router";
import { getApiToken } from "../../helpers/storage-management";
import { CircleProgressOverlay } from "../overlays/CircleProgressOverlay";
import { ImageErrorOverlay } from "../overlays/ImageErrorOverlay";

export const NetworkVideo = ({ src, contentType, controls = false }
    : { src: string, contentType: string, controls?: boolean }) => {

    const [videoSource, setVideoSource] = React.useState<string | undefined>(undefined);
    const history = useHistory();
    const [boxHeight, setBoxHeight] = React.useState<string | number>(320);
    const [boxWidth, setBoxWidth] = React.useState<string | number>(320);
    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setLoading(true);

            const token = getApiToken(history);
            setVideoSource(`${src}?code=${process.env.REACT_APP_FUNCTIONS_KEY}&authorization=Bearer ${token}`);

            setBoxHeight('100%');
            setBoxWidth('100%');
        })();
    }, []);

    const onLoad = () => {
        setLoading(false);
    }

    const onError = () => {
        setLoading(false);
        setHasError(true);
    }

    if (!videoSource) return null;

    return (
        <div style={{ height: boxHeight, width: boxWidth }} key={src}>
            <CircleProgressOverlay hidden={!loading} />
            <ImageErrorOverlay hidden={!hasError} text="Video wurde nicht gefunden" />

            <video style={{ height: '100%', width: '100%' }}
                muted
                controls={controls}
                onError={onError}
                onLoadedData={onLoad}>
                <source src={videoSource} type={contentType} />
            </video>
        </div>
    );
}