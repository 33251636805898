import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { UserAddress } from '../../../../models/UserAddress';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../../helpers/storage-management';
import { useSnackbar } from 'notistack';
import { api } from '../../../../api';
import { ArrowBack, ArrowForward, Backspace, Close, ShoppingBasket, Undo } from '@mui/icons-material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentMethod } from '../../../../models/PaymentMethod';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { ReviewOption } from './ReviewOption';
import { Product } from '../../../../models/Product';
import { CheckoutProductListing } from './CheckoutProductListing';
import { CheckoutItem } from '../../../../models/CheckoutItem';
import { TransactionCompletedDialog } from '../../../dialogs/TransactionCompletedDialog';
import { PORTAL_URL } from '../../../../constants';
import { LoadingDialog } from '../../../dialogs/LoadingDialog';
import { ProductType } from '../../ProductType';
import { NavigatedFromType } from '../../../../models/NavigatedFrom';
import { AppBar, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup, Toolbar, Typography } from '@mui/material';
import { UserAccount } from '../../../../models/UserAccount';
import { PurchaseReasonType } from '../../../../models/GroupLicense';
import { sortShopItemsByQueryUrl } from '../../../unauthorized/ShopPagePublic';
import { useTranslation } from 'react-i18next';

export const ShopPageAdminDialog = (
  {
    showall, open, setOpen, selectedUserAccount }: {
      showall: any, open: boolean, setOpen: any, selectedUserAccount: UserAccount
    }) => {

  const classes = useStyles();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const [token, setToken] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);

  const [products, setProducts] = React.useState<Product[]>([]);

  const [items, setItems] = React.useState<CheckoutItem[]>([]);
  const [deliveryAddress, setDeliveryAddress] = React.useState<UserAddress | null>();
  const [paymentAddress, setPaymentAddress] = React.useState<UserAddress | null>();
  const [chosenPaymentMethod, setChosenPaymentMethod] = React.useState(StripePaymentMethod.Card);
  const [loading, setLoading] = React.useState(false);
  const [paymentResult, setPaymentResult] = React.useState<PaymentIntentResult | null>(null);
  const [redirectDialogVisible, setRedirectDialogVisible] = React.useState(false);
  const [showAllProducts, setShowAllProducts] = React.useState(false);
  const {t} = useTranslation();

  const steps = ['Produkte auswählen', 'Review'];

  const show = useQuery().get("showall") == "1" ? true : false;

  const [chosenPurchaseReasonType, setChosenPurchaseReasonType] = React.useState(PurchaseReasonType.Gift);

  const navigatedFrom = useQuery().get("navigatedFrom");

  const onClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      const tokenTemp = getApiToken(history);
      if (!tokenTemp) return;
      setToken(tokenTemp);
      setLoading(true);
      setShowAllProducts(show);


      const tempProducts = await api.listProductsAsync(tokenTemp, enqueueSnackbar);
      sortShopItemsByQueryUrl(navigatedFrom, tempProducts);

      setProducts(tempProducts);

      setLoading(false);
      setRedirectDialogVisible(false);
    })();

  }, []);

  function useQuery() {
    return new URLSearchParams(history.location.search);
  }


  return (
    <Dialog fullScreen onClose={onClose} open={open}>
      <DialogTitle className={classes.dialogTitle}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography className={classes.dialogTitleAppBarTitle} variant="h6">
              Lizenzen Zuweisen für User: {selectedUserAccount.mail} ({`${selectedUserAccount.firstname} ${selectedUserAccount.lastname}`})
            </Typography>
            <IconButton color="inherit" onClick={onClose} size="large">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <React.Fragment>
          <main className={classes.layout}>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {
                steps &&
                steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))
              }
            </Stepper>
            <React.Fragment>
              {
                activeStep < steps.length &&
                <React.Fragment>
                  {
                    activeStep === 0 &&
                    <CheckoutProductListing
                      products={products}
                      items={items}
                      setItems={setItems}
                      showAllProducts={showAllProducts}
                    />
                  }
                  {
                    activeStep === 1 &&
                    <ReviewOption
                      items={items}
                      products={products}
                      setItems={setItems}
                    />

                  }


                </React.Fragment>

              }


              <LoadingDialog
                text="Sie werden an die Zahllungseite weitergeleitet"
                visible={redirectDialogVisible}
                setVisible={setRedirectDialogVisible}
              />

            </React.Fragment>
          </main>
        </React.Fragment >
      </DialogContent>
      <DialogActions className={classes.dialogActions}>

        <div className={classes.buttons}>
          <RadioGroup
            row
            aria-label="gender"
            name="contentCode"
            value={chosenPurchaseReasonType}
            onChange={(event: any) => {
              setChosenPurchaseReasonType(parseInt(event.target.value))
            }}
          >
            <FormControlLabel
              value={PurchaseReasonType.Gift}
              control={<Radio />}
              label="Geschenk"
            />
            <FormControlLabel
              value={PurchaseReasonType.Promotion}
              control={<Radio />}
              label="Geschäftlich"
            />
            <FormControlLabel
              value={PurchaseReasonType.Borrowed}
              control={<Radio />}
              label="Promotion"
            />
            <FormControlLabel
              value={PurchaseReasonType.PersonalNeed}
              control={<Radio />}
              label="Eigenbedarf"
            />
          </RadioGroup>
          <Button
            className={classes.button}
            startIcon={<Backspace />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              onClose();
            }}
          >
            Dialog {t("myUnitsSettings.close")}
          </Button>
          {
            !loading &&
            activeStep === 0 &&
            items.length > 0 &&
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<Undo />}
              onClick={() => {
                setItems([]);
              }}
            >
              Auswahl abheben
            </Button>
          }
          {
            activeStep !== 0 && (
              <Button
                className={classes.button}
                startIcon={<ArrowBack />}
                onClick={() => {
                  setActiveStep(activeStep - 1);
                }}
              >
                Zurück
              </Button>
            )
          }
          <Button
            className={classes.button}
            startIcon={<ShoppingBasket />}
            variant={'contained'}
            disabled={items.length < 1}
            color="primary"
            onClick={async () => {
              items.forEach(async (el) => {
                for (let i = 0; i < el.count; i++) {
                  el.product.purchaseReasonType = chosenPurchaseReasonType;
                  await api.orderByAdminWithoutInvoice(el.product, selectedUserAccount.id, token, enqueueSnackbar);
                }
              })
              onClose();

            }}
          >
            Lizenzen User zuweisen
          </Button>

          {
            !loading &&
            activeStep < steps.length - 1 &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
              className={classes.button}
              endIcon={<ArrowForward />}
              disabled={items.length <= 0}
            >
              Nächste
            </Button>
          }

        </div>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1200 + parseInt(theme.spacing(2)) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
  },
  dialogTitle: {
    padding: 0,
    flexGrow: 1,
  },
  dialogTitleAppBar: {
    flexGrow: 1,
  },
  dialogTitleAppBarTitle: {
    flexGrow: 1,
  },
  empty: {
    width: '100%',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  }
}));