import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { BatteryChargingFull, CompareArrows, Dashboard, Delete, Difference, DoNotDisturb, ExpandLess, ExpandMore, GridOn, Help, NotificationImportant, Refresh, RefreshOutlined, Search, SecurityUpdateGood, Sensors, Settings, SettingsInputAntenna, SupervisorAccount, TableChart, Visibility, Work } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { Button, CircularProgress, Collapse, FormControl, FormControlLabel, FormLabel, Grid, IconButton, ListItemButton, ListItemSecondaryAction, Radio, RadioGroup, Tooltip, Typography, useTheme } from '@mui/material';
import { getQueryString } from '../../helpers/general';
import { GLOBAL_CHART_DISPLAY_SIZE_TYPE, GLOBAL_GRID_STACK_CUSTOMS, GLOBAL_SELECTED_GRID_STACK_CUSTOM, GROUP_COMBINATION, GROUP_ID } from '../../constants';
import { useSnackbar } from 'notistack';
import ChartBellCurve from '../../assets/svgs/chart-bell-curve.svg';
import EmoticonNeutralOutline from '../../assets/svgs/emoticon-neutral-outline.svg';
import { LastMotionDisplay } from '../common/LastMotionDisplay';
import { trimPathname } from '../../helpers/common';
import { LastOnlineGroup } from '../common/LastOnlineGroup';
import { subHeaderTextGroup, subHeaderTextMultipleGroup } from './DrawerList';
import { Group, GroupType } from '../../models/Group';
import { UserAccount, UserMenuVisibility } from '../../models/UserAccount';
import { UserType } from '../../models/UserType';
import { GlobalContext } from './PortalPage';
import { gridStackCustomNameTitle } from '../../helpers/names';
import { ChartDisplaySizeType, getLabelTypeForChartDisplaySizeType, GridStackCustom } from '../../models/GridStackCustom';
import { drawerLightBlue, errorColor } from '../../styles/colors';
import { api } from '../../api';
import { AdminSettingItemType, AdminSetting } from '../../models/AdminSetting';
import { LastLoraMotionDisplay } from '../common/LastLoraMotionDisplay';
import { useTranslation } from 'react-i18next';


export const onlineStatusTitleMenu = 'drawerList.touchOnlineStatus'
export const batteryOnlineStatusTitleMenu = 'drawerList.batteryOnlineStatus'

export const ChartsDrawerPoints = ({ user, title, classes, subHeaderText, path, selectedGroupId, selectedCombinationGroup = false,
    subHeaderText2 = "", openSearchDialog, setSelectedCombinationGroup, setOpenGroupCombinationDialog, selectedGroupType, open, setOpen, hasAccessTo }:
    {
        user: UserAccount | undefined, title: any; classes: any; subHeaderText: any; path: any; selectedGroupId: string; selectedCombinationGroup: any;
        subHeaderText2?: string; openSearchDialog?: any; setSelectedCombinationGroup?: any; setOpenGroupCombinationDialog?: any; selectedGroupType?: GroupType | undefined, open: boolean, setOpen: any, hasAccessTo: any
    }) => {
    const { showStatus, setShowStatus, gridStackCustoms, setGridStackCustoms, token, chartDisplaySizeType, setChartDisplaySizeType }: { showStatus: boolean, setShowStatus: any, gridStackCustoms: GridStackCustom[], setGridStackCustoms: any, token: string, chartDisplaySizeType: ChartDisplaySizeType, setChartDisplaySizeType: any } = useContext(GlobalContext);
    const { t } = useTranslation();
    const handleClick = () => {
        setOpen(!open);
    };
    let showContent = true;
    if (t(subHeaderTextGroup) === subHeaderText) {
        showContent = !!title;
    } else if (t(subHeaderTextMultipleGroup) == subHeaderText) {
        showContent = selectedCombinationGroup.length > 0;
    } else {
        showContent = true;
    }
    const { enqueueSnackbar } = useSnackbar();

    const queryString = selectedCombinationGroup.length > 0 ? '?' + getQueryString(selectedCombinationGroup) : "";
    let { pathname } = useLocation();
    pathname = trimPathname(pathname);

    const [openCustomDashboardCollabsable, setOpenCustomDashboardCollabsable] = React.useState(false);
    const [loadingCustomDashboards, setLoadingCustomDashboards] = React.useState(false);

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <ListItem role={undefined} button onClick={showContent ? handleClick : openSearchDialog} className="font-subheader-drawer" style={{ backgroundColor: drawerLightBlue }}>
                {showContent ?
                    <ListItemIcon className="font-subheader-drawer">
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                    :
                    <ListItemIcon className="font-subheader-drawer"><Help /></ListItemIcon>}
                <Grid container direction={"row"} justifyContent={"space-between"} alignContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h5"}>{subHeaderText}</Typography>
                        {title && <Typography style={{ fontSize: 16 }}>{title}</Typography>}
                        {subHeaderText2 && <Typography style={{ fontSize: 16 }}>{subHeaderText2}</Typography>

                        }
                        {subHeaderText == t(subHeaderTextGroup) && selectedGroupType != null &&
                            <Typography>{Group.GetLabelByType(selectedGroupType)}</Typography>}
                    </Grid>
                </Grid>
                {subHeaderText != t("drawerList.overallEvaluation") &&
                    <ListItemSecondaryAction>
                        <IconButton className="font-subheader-drawer" onClick={openSearchDialog} size="large">
                            <Search />
                        </IconButton>
                    </ListItemSecondaryAction>
                }
            </ListItem>
            {showContent &&
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {hasAccessTo(user?.userMenuVisibility.customSum) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/sum${queryString}`}
                                selected={pathname === `${path}/sum`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Dashboard />
                                </ListItemIcon>
                                <ListItemText primary="Standard Cockpit" />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.servicesDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}${queryString}`}
                                selected={pathname === `${path}`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Work />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.servicedocumentation")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.serviceModeDashboardTable) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/table${queryString}`}
                                selected={pathname === `${path}/table`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <TableChart />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.servicedocumentationTable")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.servicesDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/user${queryString}`}
                                selected={pathname === `${path}/user`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <SupervisorAccount />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.servicedocumentationServiceworker")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.cleaningTriggerDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/reinigungstrigger${queryString}`}
                                selected={pathname === `${path}/reinigungstrigger`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <NotificationImportant />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.servicetriggers")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.qmTriggerDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/qm-trigger${queryString}`}
                                selected={pathname === `${path}/qm-trigger`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <NotificationImportant />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.qmtriggers")} />
                            </ListItem>
                        }
                        {(selectedGroupType == GroupType.DisplayWallApp || selectedGroupType == undefined) && hasAccessTo(user?.userMenuVisibility.onlineStatusDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/online-status${queryString}`}
                                selected={pathname === `${path}/online-status`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <SettingsInputAntenna />
                                </ListItemIcon>
                                <ListItemText primary={t(onlineStatusTitleMenu)} />
                            </ListItem>
                        }
                        {(selectedGroupType == GroupType.DisplayBatteryWallApp || selectedGroupType == undefined) && hasAccessTo(user?.userMenuVisibility.batteryDisplayOnlineStatusDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/battery-online-status${queryString}`}
                                selected={pathname === `${path}/battery-online-status`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <BatteryChargingFull />
                                </ListItemIcon>
                                <ListItemText primary={t(batteryOnlineStatusTitleMenu)} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.feedbackDashboard) &&

                            <ListItem
                                button
                                component={Link}
                                to={`${path}/feedback${queryString}`}
                                selected={pathname === `${path}/feedback`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <img src={EmoticonNeutralOutline} />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.feedback")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.frequencyDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/frequency${queryString}`}
                                selected={pathname === `${path}/frequency`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <img src={ChartBellCurve} />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.frequency")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.frequencyLoraDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/frequency-lora${queryString}`}
                                selected={pathname === `${path}/frequency-lora`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Sensors />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.frequencylora")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.qualityChecksDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/qualitätssicherung${queryString}`}
                                selected={pathname === `${path}/qualitätssicherung`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <SecurityUpdateGood />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.qualitycheck")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.hitListDashboard) &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/hitlist${queryString}`}
                                selected={pathname === `${path}/hitlist`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <CompareArrows />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.hitlist")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.setPointAndActualValueDashboard) &&

                            <ListItem
                                button
                                component={Link}
                                to={`${path}/soll-ist${queryString}`}
                                selected={pathname === `${path}/soll-ist`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Difference />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.targetactual")} />
                            </ListItem>
                        }
                        {hasAccessTo(user?.userMenuVisibility.customDashboard) &&
                            <ListItemButton onClick={() => { setOpenCustomDashboardCollabsable(prev => !prev) }}>
                                <ListItemIcon>
                                    <GridOn />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.myReports")} />
                                {openCustomDashboardCollabsable ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        }
                        <Collapse in={openCustomDashboardCollabsable} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {gridStackCustoms.length < 1 ?
                                    <ListItem
                                        style={{ textAlign: 'center' }}
                                        button
                                        component={Link}
                                        to={`/portal/dashboardverwaltung`}
                                        selected={pathname === `/portal/dashboardverwaltung`}
                                        className={classes.nested}
                                    >
                                        <ListItemIcon style={{ color: errorColor }}>
                                            <DoNotDisturb />
                                        </ListItemIcon>
                                        <ListItemText style={{ color: errorColor }} primary={t("noReportsLoaded")} secondary={t("drawerList.toTheReportsManagement")} />
                                    </ListItem>
                                    :
                                    <>
                                        {gridStackCustoms.map(item => {
                                            return (
                                                <ListItem
                                                    onClick={() => {
                                                        localStorage.setItem(GLOBAL_SELECTED_GRID_STACK_CUSTOM, JSON.stringify(item));
                                                    }}
                                                    button
                                                    component={Link}
                                                    to={`${path}/custom-sum/${item.name}/${queryString}`}
                                                    selected={pathname === `${path}/${item.name}/custom-sum`}
                                                    className={classes.nested}
                                                >
                                                    <ListItemIcon>
                                                        <GridOn />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} />
                                                </ListItem>
                                            )
                                        })}
                                        <FormControl style={{ paddingLeft: 20 }} component="fieldset">
                                            <FormLabel component="legend">{t("drawerList.size")}</FormLabel>
                                            <RadioGroup
                                                value={chartDisplaySizeType}
                                                onChange={(event) => {
                                                    localStorage.setItem(GLOBAL_CHART_DISPLAY_SIZE_TYPE, event.target.value);
                                                    setChartDisplaySizeType(parseInt(event.target.value))
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={ChartDisplaySizeType.Print}
                                                    control={<Radio />}
                                                    label={getLabelTypeForChartDisplaySizeType(ChartDisplaySizeType.Print)}
                                                />
                                                <FormControlLabel
                                                    value={ChartDisplaySizeType.FullWidth}
                                                    control={<Radio />}
                                                    label={getLabelTypeForChartDisplaySizeType(ChartDisplaySizeType.FullWidth)}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={loadingCustomDashboards}
                                            endIcon={loadingCustomDashboards && <CircularProgress size={32} />}
                                            startIcon={<RefreshOutlined />}
                                            onClick={async () => {
                                                setLoadingCustomDashboards(true);
                                                const tempgridStackCustoms = await api.getUsergridStackCustoms(token);
                                                setGridStackCustoms(tempgridStackCustoms);
                                                localStorage.setItem(GLOBAL_GRID_STACK_CUSTOMS, JSON.stringify(tempgridStackCustoms));
                                                setLoadingCustomDashboards(false);
                                            }}
                                        >
                                            Refresh Custom-Dashboards
                                        </Button>

                                    </>
                                }

                            </List>
                        </Collapse>

                        {subHeaderText == t(subHeaderTextGroup) && user?.userType != UserType.ObjectCustomer &&
                            <ListItem
                                button
                                component={Link}
                                to={`${path}/settings`}
                                selected={pathname === `${path}/settings`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.settingsEdit")} />
                            </ListItem>}

                        {subHeaderText == t(subHeaderTextGroup) && !showStatus &&
                            <Button
                                variant="contained"
                                fullWidth
                                size="small"
                                color="primary"
                                startIcon={<Refresh />}
                                onClick={() => {
                                    setShowStatus(true);
                                }}
                            >
                                {t("drawerList.showDisplayStatus")}
                            </Button>

                        }

                        {showStatus &&
                            <LastMotionDisplay
                                className={classes.nested}
                                groupId={selectedGroupId || ''} />}

                        {showStatus &&
                            <LastLoraMotionDisplay
                                className={classes.nested}
                                groupId={selectedGroupId || ''} />}

                        {showStatus &&
                            <LastOnlineGroup
                                className={classes.nested}
                                groupId={selectedGroupId || ''} />}

                    </List>
                    {subHeaderText == t(subHeaderTextMultipleGroup) &&
                        <>
                            <Tooltip title={t("drawerList.deleteCombination")}>
                                <IconButton
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() => {
                                        localStorage.setItem(GROUP_COMBINATION, "[]");
                                        setSelectedCombinationGroup([]);
                                        enqueueSnackbar(t("drawerList.combinationDeleted"), { variant: 'success' });
                                    }}
                                    size="large">
                                    <Delete fontSize='large' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("drawerList.showUnits")}>
                                <IconButton
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() => {
                                        setOpenGroupCombinationDialog(true);
                                    }}
                                    size="large">
                                    <Visibility fontSize='large' />
                                </IconButton>
                            </Tooltip>
                        </>}
                </Collapse>}
        </List>
    );
};