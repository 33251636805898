
export enum FileBlobType {
        GroupLogo = 1,

        /// <summary>
        /// Group's sidebar image
        /// </summary>
        GroupSideBarImage = 2,

        /// <summary>
        /// sensor's firmware
        /// </summary>
        SensorFirmware = 3,

        /// <summary>
        /// Group's logo of template
        /// </summary>
        GroupLogoTemplate = 6,

        /// <summary>
        /// Group's sidebar image of template
        /// </summary>
        GroupSideBarImageTemplate = 7,

        /// <summary>
        /// Email template for resetting password
        /// </summary>
        ResetPasswordEmailTemplate = 8,

        /// <summary>
        /// Email template for confirming user's registration
        /// </summary>
        UserRegistrationConfirmationEmailTemplate = 9,

        /// <summary>
        /// Email template after user's registration was deleted
        /// </summary>
        UserRegistrationWasDeletedEmailTemplate = 10,

        /// <summary>
        /// Email template for requesting change user email to current owner
        /// </summary>
        RequestChangeUserEmailToOwnerEmailTemplate = 11,

        /// <summary>
        /// Email template for requesting change user email to the new owner
        /// </summary>
        RequestChangeUserEmailToNewOwnerEmailTemplate = 12,

        /// <summary>
        /// Email template for confirming invitation
        /// </summary>
        UserRegistrationInvitationConfirmationEmailTemplate = 14,

        /// <summary>
        /// Email template of contacting support
        /// </summary>
        ContactSupportEmailTemplate = 15,

        /// <summary>
        /// Email template of invoice
        /// </summary>
        InvoiceEmailTemplate = 16,

        /// <summary>
        /// Template of invoice PDF
        /// </summary>
        InvoiceTemplate = 17,

        /// <summary>
        /// Invoice PDF
        /// </summary>
        InvoicePdf = 18,

        /// <summary>
        /// Template of invoice PDF (Auf Rechnung)
        /// </summary>
        InvoicePayLaterTemplate = 19,

        /// <summary>
        /// Releases for CMN
        /// </summary>
        ReleaseReportNewsCmn = 20,

        /// <summary>
        /// Releases for CMN
        /// </summary>
        UserLogoOfSavedGroupCombination = 21,
        GroupIncidentPhoto = 22,
        Objectunits = 23,
        Objectunitsshared = 24,
        Instructions = 25,
        GroupQualityCheckPhoto = 26,
        StandardItemPhoto = 27,
        ApkCmn = 28,
        UserLogo = 29,
        GroupMaterialStockPhoto = 30,
        ObjectMaterialStockPhoto = 31,
        All = 255
}
