import { useCallback, useRef, useState } from 'react';
import {
    ColumnChooser,
    ExportPanel,
    Grid,
    PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    TableSelection,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Button, Checkbox, FormControlLabel, TableCell, Typography } from '@mui/material';
import { checkIfDateIsEmpty, convertMinuteToHours, formatEuropeanDate, formatEuropeanDateSpecifcSec, formatEuropeanDateSpecific, formatEuropeanDateTime, formatEuropeanTime } from '../../helpers/date-management';
import { CleaningServices, PhotoAlbum, ShoppingCart, Visibility, VisibilityOff } from '@mui/icons-material';
import { GroupServiceModeIncident, GroupServiceModeIncidentPhoto, GroupServiceModeTable } from '../../models/GroupServiceMode';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { ReactComponent as PaperRoll } from '../../assets/svgs/paper-roll.svg'
import { GroupSettings } from '../../models/GroupSettings';
import { mapToiletTypeKey } from './Base';
import { saveExcelFile } from '../../helpers/tables/common';
import { IncidentImagesDialog } from '../authorized/portal/Dashboard/serviceMode/IncidentImagesDialog';
import React from 'react';
import { differenceInHours, differenceInMinutes, formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { Group } from '../../models/Group';
import { buildCellStringArray } from '../common/AccordionForStringArray';
import { UserServiceWorker } from '../../models/UserServiceWorker';
import { compareDatesSortingEuropeanDate, compareDatesSortingEuropeanDateTime, compareDatesSortingEuropeanHours } from '../../helpers/devexpressTableExtension';
import { useTranslation } from 'react-i18next';

const beginIndex = 2;

export const CheckboxCell = (props: any) => {
    const { value, style, ...restProps } = props;
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        name="belegt"
                        color="primary"
                    />
                }
                label=""
            />
        </Table.Cell>
    )
};



export const FilterCellCheck = ({ filter, onFilter, classes }: { filter: any, onFilter: any, classes: any }) => {
    return (
        <TableCell>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={filter?.value || false}
                        indeterminate={filter?.value == null}
                        onChange={e => {
                            onFilter({ value: e.target.checked });
                        }}
                        color="primary"
                    />
                }
                label=""
            />
        </TableCell>
    )
};

const FilterCell = (props: any) => {
    const { column } = props;
    if (column.name === 'isOnTime' || column.name === 'isCompleted' || column.name === "isPartlyCompleted" || column.name === GroupServiceModeTable.Columns.isCanceled ||
        column.name === "finishedMotionTrigger" || column.name === "finishedFeedbackTrigger" || column.name === "finishedIntervalTrigger" || column.name === "isInProgress") {
        return <FilterCellCheck {...props} />;
    }
    return <TableFilterRow.Cell {...props} />;
};

const onSave = (workbook: any) => {


    //unfreeze panes (scrolling)
    workbook._worksheets[1].views[0].state = "split";

    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Check-Me-Now-Services`);
    });
};

const customizeCell = (cell: any, row: any, column: any) => {
    console.log(cell, "mycell");
    if (cell.row == 1) {
        cell.height = 20;
        cell.alignment = { horizontal: 'center', vertical: 'center' };
        cell.font = { bold: true, size: 18 };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c7c4bd' } };
    }
    /*  cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFBB00' } };
      if (column.name === 'startTimestamp') {
      } */
};


const customizeHeaderStandard = (worksheet: any) => {
    /*
        worksheet.getRow(1).height = 20;
        worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'center' };
        worksheet.getRow(1).font = { bold: true, size: 18 };
        worksheet.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c7c4bd' } };
    */
};




export const ServiceModeTable = ({ groupServicesMode, groupsSettings, userServiceWorkers, selectedRows, setSelectedRows, materialNames }: { groupServicesMode: GroupServiceModeTable[], groupsSettings?: GroupLicenseTable[], userServiceWorkers: UserServiceWorker[], selectedRows: GroupServiceModeTable[], setSelectedRows: any, materialNames: string[] }) => {
    const exporterRef: any = useRef(null);

    const { t } = useTranslation();
    let materalColumnsName: any = [];
    let materialColumnsWidth: any = [];
    let materialColumns: any = [];

    materialColumns = materialNames;
    materalColumnsName = materialNames.map((col) => {
        return {
            name: col,
            title: col,
            getCellValue: (row: any) => {
                return (row.materialConsumptionItems[col] != null ? row.materialConsumptionItems[col] : 0)
            },
        }
    });
    materialColumnsWidth = materialNames.map((col) => {
        return {
            columnName: col,
            width: 150
        }
    });





    const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);
    const [showMaterialColumns, setShowMaterialColumns] = useState(true);

    const [integratedSortingColumnExtensions] = useState([
        { columnName: GroupServiceModeTable.Columns.createdTimestamp, compare: compareDatesSortingEuropeanDateTime },
        { columnName: GroupServiceModeTable.Columns.startTimestamp, compare: compareDatesSortingEuropeanDate },
        { columnName: GroupServiceModeTable.Columns.startTime, compare: compareDatesSortingEuropeanHours },
        { columnName: GroupServiceModeTable.Columns.endTimestamp, compare: compareDatesSortingEuropeanDate },
        { columnName: GroupServiceModeTable.Columns.endTime, compare: compareDatesSortingEuropeanHours },
    ]);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);


    const [columns] = useState([
        {
            name: 'notificationName', title: t("serviceDocumentationTable.unitName"), getCellValue: (row: GroupServiceModeTable) => {
                let res = groupsSettings?.find(x => x.id == row.groupId)?.notificationName;
                return res;
            }
        },
        {
            name: 'notificationType', title: t("serviceDocumentationTable.unitType"), getCellValue: (row: GroupServiceModeTable) => {
                let res = Group.GetLabelByType(groupsSettings?.find(x => x.id == row.groupId)?.groupType);
                return res;
            }
        },
        {
            name: 'object', title: t("object-storage-single-eval.object"), getCellValue: (row: GroupServiceModeTable) => {
                let res = groupsSettings?.find(x => x.id == row.groupId)?.objectName;
                return res;
            }
        },
        {
            name: 'doneByUser', title: t("serviceDocumentationTable.serviceStaffName"), getCellValue: (row: GroupServiceModeTable) => {
                let res = userServiceWorkers.find(x => x.id == row.userServiceWorkerId);
                if (res) {
                    return res.name;
                } else {
                    return row.doneByUser;
                }
            }
        },
        {
            name: GroupServiceModeTable.Columns.serviceWorker, title: t("serviceDocumentationTable.serviceStaffType"), getCellValue: (row: GroupServiceModeTable) => {
                let res = userServiceWorkers.find(x => x.id == row.userServiceWorkerId);
                return `${UserServiceWorker.GetLabel(res?.type, t)}`;
            }
        },
        { name: 'sensorRoomName', title: t("serviceDocumentationTable.roomLabel"), getCellValue: (row: GroupServiceModeTable) => (row?.sensor?.sensorRoomName || groupsSettings?.find(x => x.id == row.groupId)?.notificationName || "Nicht Vorhanden") },
        {
            name: 'toiletType', title: t("serviceDocumentationTable.roomType"), getCellValue: (row: GroupServiceModeTable) => {
                if (row.sensor.toiletType != null) {
                    return (mapToiletTypeKey(row?.sensor?.toiletType))
                } else {
                    let settings = groupsSettings?.find(x => x.id == row.groupId);
                    if (settings) {
                        return mapToiletTypeKey(settings.toiletType);
                    }
                }
                return t("serviceDocumentationTable.notAvailable")
            }
        },
        { name: GroupServiceModeTable.Columns.nfcCode, title: t("serviceDocumentationTable.rfidCode"), getCellValue: (row: GroupServiceModeTable) => row.nfcCode },


        {
            name: GroupServiceModeTable.Columns.startTimestamp,
            title: t("serviceDocumentationTable.startDate"),
            getCellValue: (row: GroupServiceModeTable) => row.isOnTime ? formatEuropeanDate(row.startTimestamp) : `Verpasst`,
        },
        {
            name: GroupServiceModeTable.Columns.startTime,
            title: t("serviceDocumentationTable.startTimestamp"),
            getCellValue: (row: GroupServiceModeTable) => row.isOnTime ? formatEuropeanTime(row.startTimestamp) : 'Verpasst',
        },
        {
            name: GroupServiceModeTable.Columns.endTimestamp,
            title: t("serviceDocumentationTable.endDate"),
            getCellValue: (row: GroupServiceModeTable) => {
                if (row.isCanceled) {
                    return t("serviceDocumentationTable.aborted");
                }
                if (checkIfDateIsEmpty(row.endTimestamp)) {
                    return t("serviceDocumentationTable.serviceInWork")
                }
                return row.isOnTime ? formatEuropeanDate(row.endTimestamp) : row.endTimestamp
            },
        },
        {
            name: GroupServiceModeTable.Columns.endTime,
            title: t("serviceDocumentationTable.endTimestamp"),
            getCellValue: (row: GroupServiceModeTable) => {
                if (row.isCanceled) {
                    return t("serviceDocumentationTable.aborted");
                }
                if (checkIfDateIsEmpty(row.endTimestamp)) {
                    return t("serviceDocumentationTable.serviceInWork")
                }
                return row.isOnTime ? formatEuropeanTime(row.endTimestamp) : row.endTimestamp
            },
        },
        {
            name: GroupServiceModeTable.Columns.duration,
            title: t("serviceDocumentationTable.serviceDuration"),
            getCellValue: (row: GroupServiceModeTable) => {
                if (row.isCanceled) {
                    return t("serviceDocumentationTable.aborted");
                }
                if (checkIfDateIsEmpty(row.endTimestamp)) {
                    return t("serviceDocumentationTable.serviceInWork")
                }
                return row.isOnTime ? formatDistance(new Date(row.startTimestamp), new Date(row.endTimestamp), { locale: de }) : 'Verpasst'
            }
        },
        {
            name: GroupServiceModeTable.Columns.durationMinutes,
            title: t("serviceDocumentationTable.serviceDurationMinutes"),
            getCellValue: (row: GroupServiceModeTable) => {
                if (row.isCanceled) {
                    return t("serviceDocumentationTable.aborted");
                }
                if (checkIfDateIsEmpty(row.endTimestamp)) {
                    return t("serviceDocumentationTable.serviceInWork")
                }
                return differenceInMinutes(new Date(row.endTimestamp), new Date(row.startTimestamp))
            }
        },
        {
            name: GroupServiceModeTable.Columns.durationHours,
            title: t("serviceDocumentationTable.serviceDurationHours"),
            getCellValue: (row: GroupServiceModeTable) => {
                if (row.isCanceled) {
                    return t("serviceDocumentationTable.aborted");
                }
                if (checkIfDateIsEmpty(row.endTimestamp)) {
                    return t("serviceDocumentationTable.serviceInWork")
                }
                return convertMinuteToHours(differenceInMinutes(new Date(row.endTimestamp), new Date(row.startTimestamp)))
            }
        },
        { name: 'isOnTime', title: t("serviceDocumentationTable.onTime") },
        { name: 'isCompleted', title: t("serviceDocumentationTable.allServicesCompleted") },
        { name: 'isPartlyCompleted', title: t("serviceDocumentationTable.servicesPartiallyCompleted") },
        { name: GroupServiceModeTable.Columns.isCanceled, title: t("serviceDocumentationTable.aborted") },
        { name: 'isInProgress', title: t("serviceDocumentationTable.inProgress") },
        ...materalColumnsName,
        { name: 'incidentReportItems', title: t("serviceDocumentationTable.incidentReports") },
        { name: GroupServiceModeTable.Columns.incidentTexts, title: t("serviceDocumentationTable.incidentReportWithName"), getCellValue: (row: GroupServiceModeTable) => row.incidentTexts && row.incidentTexts.map(x => `${x.incidentText} | ${x.reporterName}`).join(',') },
        { name: 'incidentPhotos', title: t("serviceDocumentationTable.incidentPhotos"), getCellValue: (row: GroupServiceModeTable) => row.incidentPhotos && `${row.incidentPhotos.length} ${t("serviceDocumentationTable.incidentPhotosDisplay")}` },

        { name: 'cleaningTaskItems', title: t("serviceDocumentationTable.servicesCompleted") },
        { name: 'finishedMotionTrigger', title: t("serviceDocumentationTable.visitorTrigger") },
        { name: 'finishedFeedbackTrigger', title: t("serviceDocumentationTable.feedbackTrigger") },
        { name: 'finishedIntervalTrigger', title: t("serviceDocumentationTable.intervalTrigger") },
        { name: 'sensorName', title: t("serviceDocumentationTable.deviceType"), getCellValue: (row: GroupServiceModeTable) => (row?.sensor?.sensorName) },
        { name: GroupServiceModeTable.Columns.serviceCancelReasonType, title: t("serviceDocumentationTable.cancellationReason"), getCellValue: (row: GroupServiceModeTable) => GroupServiceModeTable.GetLabelByServiceCancelReasonType(row.serviceCancelReasonType) },
        {
            name: GroupServiceModeTable.Columns.createdTimestamp,
            title: t("serviceDocumentationTable.serverCreatedAt"),
            getCellValue: (row: GroupServiceModeTable) => formatEuropeanDateTime(row.createdTimestamp),
        },

    ]);



    const [defaultColumnWidths] = useState([
        { columnName: 'object', width: 180 },
        { columnName: 'notificationName', width: 180 },
        { columnName: 'notificationType', width: 180 },

        { columnName: 'sensorRoomName', width: 180 },
        { columnName: 'toiletType', width: 180 },
        { columnName: 'createdTimestamp', width: 200 },
        { columnName: GroupServiceModeTable.Columns.startTimestamp, width: 180 },
        { columnName: GroupServiceModeTable.Columns.startTime, width: 180 },
        { columnName: GroupServiceModeTable.Columns.endTimestamp, width: 180 },
        { columnName: GroupServiceModeTable.Columns.endTime, width: 180 },
        { columnName: GroupServiceModeTable.Columns.duration, width: 180 },
        { columnName: GroupServiceModeTable.Columns.durationMinutes, width: 180 },
        { columnName: GroupServiceModeTable.Columns.durationHours, width: 180 },

        { columnName: 'doneByUser', width: 170 },
        { columnName: GroupServiceModeTable.Columns.serviceWorker, width: 170 },
        { columnName: 'isOnTime', width: 120 },
        { columnName: 'isCompleted', width: 160 },
        { columnName: 'isPartlyCompleted', width: 160 },
        { columnName: GroupServiceModeTable.Columns.isCanceled, width: 160 },
        { columnName: 'isInProgress', width: 160 },
        ...materialColumnsWidth,
        { columnName: 'incidentReportItems', width: 240 },
        { columnName: GroupServiceModeTable.Columns.incidentTexts, width: 480 },
        { columnName: 'cleaningTaskItems', width: 240 },
        { columnName: 'finishedMotionTrigger', width: 200 },
        { columnName: 'finishedFeedbackTrigger', width: 200 },
        { columnName: 'finishedIntervalTrigger', width: 200 },
        { columnName: 'sensorName', width: 180 },
        { columnName: 'incidentPhotos', width: 200 },
        { columnName: GroupServiceModeTable.Columns.serviceCancelReasonType, width: 200 },
        { columnName: GroupServiceModeTable.Columns.nfcCode, width: 200 },


    ]);




    const allpunctualServices = groupServicesMode.filter((el) => el.isOnTime);
    const allMissedServices = groupServicesMode.filter((el) => !el.isOnTime);
    const allCompleteServices = groupServicesMode.filter((el) => el.isCompleted);
    const allIncompleteServices = groupServicesMode.filter((el) => !el.isCompleted);

    const startExportIndex = groupsSettings?.length || 0;

    const customizeHeader = (worksheet: any) => {
        let startIndex = beginIndex;

        if (groupsSettings) {
            worksheet.addRow({});
            worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'center' };
            worksheet.getRow(1).font = { bold: true, size: 18 };
            worksheet.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c7c4bd' } };
            worksheet.getRow(1).getCell(1).value = "Objekt Name";
            worksheet.getRow(1).getCell(2).value = "Ebene Name";
            worksheet.getRow(1).getCell(3).value = t("myUnitsSettings.customerName");
            worksheet.getRow(1).getCell(4).value = "Einheit Name";
            worksheet.getRow(1).getCell(5).value = "Toiletten Typ";
            worksheet.getRow(1).getCell(6).value = "Device Id";

            for (let groupSettings of groupsSettings) {
                worksheet.addRow({});
                worksheet.getRow(startIndex).getCell(1).value = groupSettings?.objectName;
                worksheet.getRow(startIndex).getCell(2).value = groupSettings?.levelName;
                worksheet.getRow(startIndex).getCell(3).value = groupSettings?.roomName;
                worksheet.getRow(startIndex).getCell(4).value = groupSettings?.notificationName;
                worksheet.getRow(startIndex).getCell(5).value = groupSettings?.toiletType;
                worksheet.getRow(startIndex).getCell(6).value = groupSettings?.uniqueDeviceId;
                startIndex++;
            }
        }


        worksheet.getRow(6 + startIndex).height = 30;
        worksheet.getRow(1 + startIndex).font = { bold: true, size: 16 };
        worksheet.getRow(2 + startIndex).font = { bold: true, size: 16 };
        worksheet.getRow(3 + startIndex).font = { bold: true, size: 16 };
        worksheet.getRow(4 + startIndex).font = { bold: true, size: 16 };
        worksheet.getRow(5 + startIndex).font = { bold: true, size: 16 };

        worksheet.getRow(1 + startIndex).getCell(1).value = t("serviceDocumentationTable.totalServices");
        worksheet.getRow(1 + startIndex).getCell(2).value = `${groupServicesMode.length} (100%)`;
        worksheet.getRow(2 + startIndex).getCell(1).value = t("serviceDocumentationTable.onTimeServices");
        worksheet.getRow(2 + startIndex).getCell(2).value = `${allpunctualServices.length} (${Math.round(allpunctualServices.length / groupServicesMode.length * 100)}%)`;
        worksheet.getRow(3 + startIndex).getCell(1).value = t("serviceDocumentationTable.lateServices");
        worksheet.getRow(3 + startIndex).getCell(2).value = `${allMissedServices.length} (${Math.round(allMissedServices.length / groupServicesMode.length * 100)}%)`;
        worksheet.getRow(4 + startIndex).getCell(1).value = t("serviceDocumentationTable.allServicesCompleted");
        worksheet.getRow(4 + startIndex).getCell(2).value = `${allCompleteServices.length} (${Math.round(allCompleteServices.length / groupServicesMode.length * 100)}%)`;
        worksheet.getRow(5 + startIndex).getCell(1).value = t("serviceDocumentationTable.servicesPartiallyCompleted");
        worksheet.getRow(5 + startIndex).getCell(2).value = `${allIncompleteServices.length} (${Math.round(allIncompleteServices.length / groupServicesMode.length * 100)}%)`;


    };


    const customizeFooter = (worksheet: any) => {

        for (let i = 1; i <= columns.length; i++) {
            worksheet.getColumn(i).width = 40;
        }
        worksheet.getRow(7 + startExportIndex + beginIndex).alignment = { horizontal: 'center', vertical: 'center' };
        worksheet.getRow(7 + startExportIndex + beginIndex).font = { bold: true, size: 18 };
        worksheet.getRow(7 + startExportIndex + beginIndex).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c7c4bd' } };
    };


    const [isIncidentImagesDialogVisible, setIsIncidentImagesDialogVisible] = React.useState(false);
    const [incidentPhotos, setIncidentPhotos] = React.useState<GroupServiceModeIncidentPhoto[]>([]);
    const [groupId, setGroupId] = React.useState("");

    const [isExportWithSummaryEnabled, setIsExportWithSummaryEnabled] = React.useState(true);


    const PurchaseCell = (props: any) => {
        const { style, row, ...restProps } = props;
        return (
            <Table.Cell
                {...restProps}
                style={{
                    ...style
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ShoppingCart></ShoppingCart>}
                >Lizenz erweitern</Button>
            </Table.Cell>
        )
    };

    const PhotoCell = (props: any) => {
        const { style, row, ...restProps } = props;
        return (
            <Table.Cell
                {...restProps}
                style={{
                    ...style
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setGroupId(row.groupId);
                        setIncidentPhotos(row.incidentPhotos);
                        setIsIncidentImagesDialogVisible(true);
                    }}
                    endIcon={<PhotoAlbum></PhotoAlbum>}
                >{row.incidentPhotos.length} Vorfallfotos anzeigen</Button>
            </Table.Cell>
        )
    };

    const Cell = (props: any) => {
        const { column, row } = props;
        if (column.name === 'isOnTime' || column.name === 'isCompleted' || column.name === "isPartlyCompleted" || column.name === GroupServiceModeTable.Columns.isCanceled ||
            column.name === "finishedMotionTrigger" || column.name === "finishedFeedbackTrigger" || column.name === "finishedIntervalTrigger" || column.name === "isInProgress") {
            return <CheckboxCell {...props} />;
        }
        else if (column.name === 'purchase') {
            return <PurchaseCell {...props} />;
        }
        else if (column.name === "incidentPhotos" && row.incidentPhotos) {
            return <PhotoCell {...props} />
        }
        if (column.name === GroupServiceModeTable.Columns.incidentTexts) {
            return buildCellStringArray(props, row.incidentTexts.map((x: GroupServiceModeIncident) => `${x.incidentText} | ${x.reporterName}`), `${t("myObjectSettings.contains")} ${row.incidentTexts.length} Vorfälle mit Name`);
        }
        if (column.name === "cleaningTaskItems") {
            return buildCellStringArray(props, row?.cleaningTaskItems || [], `${t("myObjectSettings.contains")} ${row?.cleaningTaskItems?.length || 0} Services`);
        }
        if (column.name === "incidentReportItems") {
            return buildCellStringArray(props, row?.incidentReportItems || [], `${t("myObjectSettings.contains")} ${row?.incidentReportItems?.length || 0} Vorfälle`);
        }
        return <Table.Cell {...props} />;
    };

    const [filters, setFilters] = useState([{ columnName: 'isOnTime', value: true }]);
    let isOnTime = filters.find(x => x.columnName == 'isOnTime')?.value;


    return (
        <>
            <IncidentImagesDialog groupId={groupId} visible={isIncidentImagesDialogVisible} setVisible={setIsIncidentImagesDialogVisible} incidentPhotos={incidentPhotos} />
            <Grid
                rows={groupServicesMode}
                columns={columns}
                getRowId={(x) => x.id}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters as any}
                />
                <IntegratedFiltering />
                <PagingState
                    defaultCurrentPage={0}
                    pageSize={10}
                />
                <SelectionState selection={selectedRows.map(x => x.id)} onSelectionChange={(ids) => {
                    setSelectedRows(groupServicesMode.filter(x => ids.includes(x.id)));
                }} />
                <IntegratedSelection />
                <SortingState
                //defaultSorting={[{ columnName: 'startTimestamp', direction: 'desc' }]}
                />
                <IntegratedSorting
                    columnExtensions={integratedSortingColumnExtensions}
                />

                <IntegratedPaging />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >
                    <TemplatePlaceholder />
                    <Button
                        size="large"
                        startIcon={<Visibility />}
                        endIcon={<CleaningServices />}
                        variant={'contained'} color="primary"
                        onClick={() => {
                            let val = filters.find(x => x.columnName == GroupServiceModeTable.Columns.isOnTime)?.value;
                            if (val == true) {
                                setFilters(filters.filter(x => x.columnName != GroupServiceModeTable.Columns.isOnTime));
                            } else if (val == false) {
                                setFilters(filters.map(x => {
                                    if (x.columnName == GroupServiceModeTable.Columns.isOnTime) {
                                        x.value = !x.value;
                                    }
                                    return x;
                                }));
                            }
                            else if (val == undefined) {
                                setFilters([...filters, { columnName: 'isOnTime', value: false }]);
                            }

                        }}
                    >
                        {isOnTime == true ? t("serviceDocumentationTable.showMissed&PunctualServices") : isOnTime == undefined ? t("serviceDocumentationTable.showMissedServices") : t("serviceDocumentationTable.showPunctualServices")}
                    </Button>
                    <Button
                        size="large"
                        style={{ marginLeft: 30 }}
                        startIcon={showMaterialColumns ? <VisibilityOff /> : <Visibility />}
                        endIcon={<PaperRoll width={25} height={25} fill={"#ffffff"} />}
                        variant={'contained'} color="primary"
                        onClick={() => {
                            if (showMaterialColumns) {
                                setShowMaterialColumns(false);
                                setHiddenColumnNames([...hiddenColumnNames, ...materialColumns]);
                            } else {
                                setShowMaterialColumns(true);
                                setHiddenColumnNames(hiddenColumnNames.filter((el: any) => !materialColumns.includes(el)));
                            }

                        }}
                    >
                        {showMaterialColumns ? t("serviceDocumentationTable.hideMaterialconsumption") : t("serviceDocumentationTable.showMaterialconsumption")}
                    </Button>
                    <FormControlLabel
                        style={{ marginLeft: 10 }}
                        control={<Checkbox
                            checked={isExportWithSummaryEnabled}
                            onChange={(event: any) => {
                                setIsExportWithSummaryEnabled(event.target.checked);
                            }}
                            name="isDeviceRestartTimeEnabled"
                            color="primary" />}
                        label={t("serviceDocumentationTable.exportWithUnitSummary")} />
                </Template>
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow
                    cellComponent={FilterCell}
                />
                <TableSelection showSelectAll />
                <PagingPanel />
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={groupServicesMode}
                columns={columns}
                customizeCell={customizeCell}
                customizeHeader={(worksheet) => {
                    if (isExportWithSummaryEnabled) {
                        return customizeHeader(worksheet);
                    } else {
                        return customizeHeaderStandard(worksheet);
                    }
                }}
                customizeFooter={customizeFooter}
                filters={filters}
                onSave={onSave}
            />
            <>
                <Typography> {t("serviceDocumentationTable.totalServices")}: {groupServicesMode.length} (100%)</Typography>
                <Typography> {t("serviceDocumentationTable.onTimeServices")}: {allpunctualServices.length} ({Math.round(allpunctualServices.length / groupServicesMode.length * 100)}%)</Typography>
                <Typography> {t("serviceDocumentationTable.lateServices")}: {allMissedServices.length} ({Math.round(allMissedServices.length / groupServicesMode.length * 100)}%)</Typography>
                <Typography> {t("serviceDocumentationTable.completeServices")}: {allCompleteServices.length} ({Math.round(allCompleteServices.length / groupServicesMode.length * 100)}%)</Typography>
                <Typography> {t("serviceDocumentationTable.incompleteService")}: {allIncompleteServices.length} ({Math.round(allIncompleteServices.length / groupServicesMode.length * 100)}%)</Typography>

            </>
        </>
    );
};