import { GroupSettingsLogo } from './GroupSettings';
import { UserAccountBasic } from './UserAccountLight';
import { UserType } from './UserType';

export class UserAccount extends UserAccountBasic{

    public password: string = '';
    public userType!: UserType;
    public invitedFromUserId: string = '';
    public activated!: boolean;
    public isEnabled!: boolean;
    public mailConfirmed! : boolean;
    public isTermsOfUseAccepted !: boolean;
    public logo!: GroupSettingsLogo | null;
    public isNewsletterActive !: boolean;
    public languageChoice : string = "en";
    public monthsAgoProtecedData : number = 1;
    public  userMenuVisibility !: UserMenuVisibility;
    static Columns = {
        id: 'id',
        firstname: 'firstname',
        lastname: 'lastname',
        company: 'company',
        uid: 'uid',
        atu: 'atu',
        mail: 'mail',
        title: 'title',
        activated: 'activated',
        customerNumber: 'customerNumber',
        userType: 'userType',
        invitedFromUserId: 'invitedFromUserId',
        isTermsOfUseAccepted: 'isTermsOfUseAccepted',
        isNewsletterActive: 'isNewsletterActive',
        IsTermsOfUseAccepted : 'IsTermsOfUseAccepted',
        languageChoice : "languageChoice"
    }

    static AvailableTypes = [
        
        { value: UserType.Admin, label: 'Admin' },
        { value: UserType.Customer, label: 'Kunde' },
        { value: UserType.Manager, label: 'Manager' },
        { value: UserType.Owner, label: 'Besitzer' }
    ];

    static GetLabelByType = (type: UserType | undefined) => UserAccount.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';

    constructor (){
        super();
        this.userType = UserType.Owner;
    }

    static CreateInvitedUser = (invitedUserId: string, userType: UserType.Customer | UserType.Manager | UserType.ObjectCustomer) => {
        const user = new UserAccount();

        user.invitedFromUserId = invitedUserId;
        user.userType = userType;
        user.isEnabled = true;
        user.activated = false;

        return user;
    }
}
export enum MenuVisibilityType
{
    All = 0,
    OnlySingle = 1,
    SingleAndMultiple = 2,
    Nothing = 100
}
export class UserMenuVisibility {
    public qualityChecksDashboard !: MenuVisibilityType;
    public servicesDashboard !: MenuVisibilityType
    public  serviceModeDashboardTable  !:MenuVisibilityType
    public  serviceWorkerServicesDashboard  !:MenuVisibilityType
    public  qmTriggerDashboard  !:MenuVisibilityType
    public  cleaningTriggerDashboard  !:MenuVisibilityType
    public  setPointAndActualValueDashboard  !:MenuVisibilityType
    public  onlineStatusDashboard  !:MenuVisibilityType
    public  batteryDisplayOnlineStatusDashboard  !:MenuVisibilityType
    public  hitListDashboard  !:MenuVisibilityType
    public  feedbackDashboard  !:MenuVisibilityType
    public  frequencyDashboard  !:MenuVisibilityType
    public  frequencyLoraDashboard  !:MenuVisibilityType
    public  customDashboard  !:MenuVisibilityType
    public  customSum !: MenuVisibilityType
    public  objectSessionAnalytic !:MenuVisibilityType
    public  objectCarbonDioxideDashboard  !:MenuVisibilityType
    public  objectStockDashboard !: MenuVisibilityType
    public  objectCleaningTriggerDashboard  !:MenuVisibilityType
    public  objectSharedFolderDashboard  !:MenuVisibilityType
    public  serviceWorkerSession  !:MenuVisibilityType


    static hasAccessToSingle = (type: MenuVisibilityType | undefined) => {
        return (type == MenuVisibilityType.All || type == MenuVisibilityType.OnlySingle || type == MenuVisibilityType.SingleAndMultiple);
    }

    static hasAccessToMultiple = (type: MenuVisibilityType | undefined) => {
        return (type == MenuVisibilityType.All || type == MenuVisibilityType.SingleAndMultiple);
    }

    static hasAccessToAll = (type: MenuVisibilityType | undefined) => {
        return (type == MenuVisibilityType.All);
    }


    static Labels = [
        { value: MenuVisibilityType.All, label: 'Alle'},
        { value: MenuVisibilityType.OnlySingle, label: 'Einzel'},
        { value: MenuVisibilityType.SingleAndMultiple, label: 'Einzel und Mehrfach'},
        { value: MenuVisibilityType.Nothing, label: 'Nicht sichtbar'},

    ];

    static GetLabelByType = (type: MenuVisibilityType | undefined) => UserMenuVisibility.Labels.find(x => x.value == type)?.label || '';

}