import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { api } from '../../api';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { Add, Cancel, CheckCircle, PhoneAndroid, PhotoAlbum, Save } from '@mui/icons-material';
import { CircularProgress, DialogActions, DialogContent, Grid, ListItemButton, TextField } from '@mui/material';
import { StandardWorkItem, StandardWorkItemType } from '../../models/StandardItem';
import { DownloadFileImageDialog } from '../authorized/admin/DownloadFileImageDialog';
import { FileBlobType } from '../../models/FileBlobType';
import { getDefaultIcon } from './DefaultItemsDialogOld';
import { LocalMedia } from '../images/NetworkMedia';
import { getUserIdFromStorage } from '../../helpers/common';
import { MaterialStock, StockActionDoneFromType, StockActionType, UserStock } from '../../models/UserStock';
import { MaterialStockGroupSettings } from '../../models/MaterialStockGroupSettings';
import { useSnackbar } from 'notistack';
import { GroupSettings } from '../../models/GroupSettings';
import { MaterialSelectionTableGroup } from '../tables/material/MaterialSelectionTableGroup';
import { DefaultDialogTitle } from './DefaultDialogTitle';
import { useTranslation } from 'react-i18next';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 800,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export interface SimpleDialogProps {
  row: GroupSettings;
  open: boolean;
  onClose: () => void;
  token: string;
}

export const GroupMaterialStockDialog = (props: SimpleDialogProps) => {



  const { onClose, row, open, token } = props;

  const [materials, setMaterials] = React.useState<MaterialStockGroupSettings[]>([]);
  const [selectedMaterials, setSelectedMaterials] = React.useState<MaterialStockGroupSettings[]>([]);
  const [reason, setReason] = React.useState("");
  const [alarmEmail, setAlarmEmail] = React.useState("");
  const [countEdited, setCountEdited] = React.useState(0);
  const [latestUserGroupStock, setLatestUserGroupStock] = React.useState<UserStock>();
  const [loading, setLoading] = React.useState(false);
  const [latestObjectUserStock, setLatestObjectUserStock] = React.useState<UserStock>();
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation();

  React.useEffect(() => {
    if (open) {
      (async () => {

        let latestObjectUserStock = await api.getLatestUserStockByObjectId(token, row.locationInformation?.objectUnitId || "");
        setLatestObjectUserStock(latestObjectUserStock);
        if (!latestObjectUserStock) {
          setLoading(false);
          return;
        }
        let latestGroupUserStock = await api.getLatestUserStockByGroupId(token, row.id || "");
        if (latestGroupUserStock) {
          setLatestUserGroupStock(latestGroupUserStock);
          setCountEdited(latestGroupUserStock.restockGroupCount + 1);
        }
        setLoading(false);



        if (materials.length < 1) {
          let tempMaterials =
            (await api.GetStandardWorkItemsByType(token, StandardWorkItemType.Material, getUserIdFromStorage()))
              .map(x => MaterialStockGroupSettings.CreateInstanceFrontend(x.id, 0, x.photos, x.createdTimestamp, x.text, x.article, x.entity));
          tempMaterials = tempMaterials.filter(x => latestObjectUserStock.materialStocks.some(y => y.materialId == x.materialId));
          for (let standardMaterial of tempMaterials) {
            let material = latestGroupUserStock?.materialStocks?.find(x => x.materialId == standardMaterial.materialId);
            if (material) {
              standardMaterial = MaterialStockGroupSettings.SetValuesFromMaterialStockForGroup(material, standardMaterial);
            }
          }
          setMaterials(tempMaterials);
          setSelectedMaterials(tempMaterials.filter(x => latestGroupUserStock.materialStocks.some(y => y.materialId == x.materialId && y.isEnabledForGroup)));
        } else {
          for (let standardMaterial of materials) {
            let material = latestGroupUserStock?.materialStocks?.find(x => x.materialId == standardMaterial.materialId);
            if (material) {
              standardMaterial = MaterialStockGroupSettings.SetValuesFromMaterialStockForGroup(material, standardMaterial);
            }
          }
          setSelectedMaterials(materials.filter(x => latestGroupUserStock.materialStocks.some(y => y.materialId == x.materialId && y.isEnabledForGroup)));
        }


      }
      )();

    }
  }, [open]);

  const handleClose = () => {
    setMaterials([]);
    setSelectedMaterials([]);
    setCountEdited(0);
    setLatestUserGroupStock(undefined);
    onClose();

  }

  const classes = useStyles();
  const [selectedItems, setSelectedItems] = React.useState<StandardWorkItem[]>([]);

  const handleToggle = (value: StandardWorkItem) => () => {
    const currentIndex = selectedItems.indexOf(value);
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedItems(newChecked);
  };

  return (
    <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DefaultDialogTitle
        title={t("myUnitsSettings.manageMaterialInTheUnit")}
        handleClose={onClose}
      />
      <DialogContent>
        {loading && <CircularProgress size={64} />}
        {!latestObjectUserStock ?
          <Typography>{t("myUnitsSettings.youDoNotHaveObjectStore")}</Typography>
          :
          <Grid item xs={12} container direction={"column"} spacing={3} alignItems="center">
            <Grid item container spacing={2} direction={"row"}>

              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 'bold', marginRight: 5 }} style={{ fontSize: 30 }}>{t("myUnitsSettings.storageEntryNo")}: {countEdited}</Typography>
              </Grid>
              <Grid item>
                <TextField variant={"filled"} value={reason} onChange={(event) => setReason(event.target.value)} label={t("myUnitsSettings.storageReason")} />
              </Grid>


              <Grid item xs={12}>
                <MaterialSelectionTableGroup
                  materials={materials}
                  setMaterials={setMaterials}
                  selectedMaterials={selectedMaterials}
                  onSelectionChange={(selection: string[]) => {
                    setSelectedMaterials(materials.filter(x => selection.some(s => s === x.materialId)));
                  }}
                />
              </Grid>

            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        <Button size="large"
          startIcon={<Cancel />} variant={'outlined'} onClick={onClose} color="primary">
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          size="large"
          endIcon={loading ? <CircularProgress size={32} /> : <CheckCircle />}
          onClick={async () => {
            setLoading(true);
            let userId = getUserIdFromStorage();
            let materialStocks = selectedMaterials.map(x => MaterialStock.CreateInstanceStockGroup(x.materialId, x.amount, x.newAmount, 0, x.specificReason, StockActionType.ManualModifyGroup, x.minimumAmount, x.maximumAmount, x.alarmEmail))
            let userStock = new UserStock(userId, "", row.id || "", "", StockActionDoneFromType.PortalGroupSettings, reason, materialStocks, 0, countEdited);
            if (latestUserGroupStock) {
              userStock = UserStock.setLastStockGroupValues(userStock, latestUserGroupStock);
            }
            await api.createUserStock(userStock, token, enqueueSnackbar);
            handleClose();
            setLoading(false);
          }}
        >
          {t("myUnitsSettings.adoptSelectedMaterials")}
        </Button>

      </DialogActions>
    </Dialog>
  );
}