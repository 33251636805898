import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close, Downloading, DeleteForever } from "@mui/icons-material";
import React from "react";
import { api } from "../../../api";
import { getApiToken } from "../../../helpers/storage-management";
import { GroupLicenseTable, SensorLicense } from "../../../models/GroupLicenseTable";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { green, red, white, yellow } from "../../../styles/colors";
import { UserInvoice } from '../../../models/UserInvoice';
import { AdminInvoicesTable } from "../../dialogs/AdminInvoicesTable";
import { UserAccount } from "../../../models/UserAccount";
import { getOwnUserIdFromStorage } from "../../../helpers/common";
import { GroupLicense } from "../../../models/GroupLicense";
import { LicensesAdminTable } from "../../tables/LicensesAdminTable";
import { AllLicensesAdminTable } from "../../tables/AllLicensesAdminTable";
import { ConfirmationDialog } from "../../dialogs/ConfirmationDialog";

export const GroupLicenseAdminPage = () => {

    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState("");
    const [licenses, setLicenses] = React.useState<GroupLicense[]>([]);
    const [selectedLicenses, setSelectedLicenses] = React.useState<GroupLicense[]>([]);
    const [isLoadingDeleting, setIsLoadingDeleting] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);


    const [loading, setLoading] = React.useState(false);
    const [loadingGroups, setLoadingGroups] = React.useState(false);
    const [users, setUsers] = React.useState<UserAccount[]>([]);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);


    const loadDataAsync = async () => {

        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setLoading(true);


        const licenses = await api.GetAllLicensesByAdminAsync(tempToken);
        setLicenses(licenses);

        const tempUsers = await api.getAllUsersInclusiveNonActivatedByAdmin(tempToken);
        /*   let allGroupsTemp = await Promise.all(tempUsers.map(async (el) => {
               let groupLicenses = await api.GetAllGroupsByUserIdAsync(tempToken, el.id);
               return new UserManagementGroups(el.id,groupLicenses);
           }));
           setGroups(allGroupsTemp);*/
        setUsers(tempUsers);


        setLoading(false);
    }

    const loadGroups = async () => {
        setLoadingGroups(true);
        let allGroupsTemp = await Promise.all(users.map(async (el) => {
            let groupLicenses = await api.GetAllGroupsByUserIdAsync(token, el.id);
            return groupLicenses.filter(x => licenses.some(y => y.usedByGroup == x.id));
        }));
        setGroups(allGroupsTemp.flatMap(x => x));
        setLicenses([...licenses]);
        setLoadingGroups(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();
        })();
    }, []);

    return (
        <Grid
            container
            direction="row"
            spacing={3}
        >


            {
                licenses &&
                token &&
                <Grid item xs={12}>
                    <Accordion expanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid container direction={"row"} spacing={3} alignItems="center">
                                <Grid
                                    item
                                    xs={6}
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                >
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            component="p"
                                            color="textSecondary"
                                        >
                                            Alle Lizenzen {loading ? "(wird geladen)" : ""}
                                        </Typography >
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className="Paper-Result"
                                            component="p"
                                        >
                                            {licenses.length}
                                        </Typography >
                                    </Grid>
                                </Grid>
                                {!loading &&
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<Downloading />}
                                            disabled={loadingGroups}
                                            endIcon={loadingGroups && <CircularProgress size={32} />}
                                            onClick={async () => {
                                                await loadGroups();
                                            }}
                                        >
                                            Alle Einheiten von {users.length} User laden
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item lg={2}>
                                <Button
                                    size="large"
                                    fullWidth
                                    color="error"

                                    startIcon={isLoadingDeleting && <CircularProgress size={32} />}
                                    disabled={selectedLicenses.length < 1 || isLoadingDeleting} variant='contained'
                                    endIcon={<DeleteForever />} onClick={async () => {
                                        setDeleteConfirmationDialogVisible(true);
                                    }}>
                                    {selectedLicenses.length} Lizenzen löschen
                                </Button>
                            </Grid>
                            {!loading &&
                                <AllLicensesAdminTable
                                    selectedRows={selectedLicenses}
                                    setSelectedRows={setSelectedLicenses}
                                    token={token}
                                    groups={groups}
                                    licenses={licenses}
                                    setLicenses={setLicenses}
                                    onComplete={loadDataAsync}
                                    users={users}
                                />
                            }
                            {loading &&
                                <CircularProgress size={64} />
                            }
                        </AccordionDetails>
                    </Accordion>
                    <ConfirmationDialog
                        visible={deleteConfirmationDialogVisible}
                        title={`Sind Sie sicher, diese Lizenzen ${selectedLicenses.length} zu löschen ?`}
                        onClose={() => setDeleteConfirmationDialogVisible(false)}
                        onConfirmed={async () => {

                            setDeleteConfirmationDialogVisible(false);

                            setIsLoadingDeleting(true);
                            let token = getApiToken(history);
                            for (let license of selectedLicenses) {
                                await api.deleteLicenseAsync(license.id, license.userId, token, enqueueSnackbar);
                                setSelectedLicenses((prev) => prev.filter(x => x.id != license.id));
                                setLicenses((prev) => prev.filter(x => x.id != license.id));
                            }
                            setIsLoadingDeleting(false);
                        }}
                    />
                </Grid>
            }



        </Grid >
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));