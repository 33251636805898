import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useCardStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const useContainerStyles = makeStyles((theme) => ({
  marginSmall: {
    margin: theme.spacing(1),
  },
  marginFieldSmall: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  headerTableMargin: {
    marginBottom: 10,
    marginTop: 10
  }
}));

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
  },
  }),
);

export const paperStyle = { padding: 10 };
export const elevationPaper = 10;

