import { Autocomplete, Box, Grid, InputAdornment, TextField } from '@mui/material';
import * as React from 'react';
import { UserAddress } from '../../models/UserAddress';
import { UserAccount } from '../../models/UserAccount';
import { useTranslation } from 'react-i18next';

export default function LanguagePicker({ user, setUser }: { user: UserAccount, setUser: any }) {
    let country = countries.find(x => x.language == user.languageChoice);
    const {t} = useTranslation();
    return (
        <Grid container direction={"row"} spacing={3} alignItems={"center"}>
            <Grid item>
                <img
                    loading="lazy"
                    width="30"
                    src={`https://flagcdn.com/w20/${country?.code?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${country?.code?.toLowerCase()}.png 2x`}
                    alt=""
                />
            </Grid>
            <Grid item >
                <Autocomplete
                    style={{ width: 250 }}
                    id="country-select-demo"
                    fullWidth
                    options={countries}
                    autoHighlight
                    value={{ code: country?.code, phone: '', label: country?.label || '' }}
                    getOptionLabel={(option) => `${option.label}`}
                    onChange={(_: any, newValue: any) => {
                        if (newValue)
                            setUser({ ...user, languageChoice: newValue.language })
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.label} ({option.code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("account.newsletterActivated")}
                            variant={"outlined"}
                            value={country?.label}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}


// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries: readonly any[] = [
    { code: 'GB', language: 'en', label: 'United Kingdom', phone: '44' },
    {
        code: 'DE',
        language: 'de',
        label: 'German',
        phone: '49',
        suggested: true,
    }
];