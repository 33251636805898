import axios from 'axios';
import { saveAs } from 'file-saver';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectRenameFile } from '../models/DataFileTree';


export const RenameObjectFileAsync = async (objectId: string, objectRenameFile: ObjectRenameFile, token: string, enqueueSnackbar: any): Promise<any> => {

    if (!objectId || !token || !enqueueSnackbar)
        return "";
    const userId = getUserIdFromStorage();

    try {
        const ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/users/${userId}/file/rename`, objectRenameFile,
            {
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        enqueueSnackbar("Objekt Datei wurde erfolgreich umbenannt", { variant: 'success' });
        return ret.data;
    } catch (err: any) {
        if (err.response && err.response.data && err.response.data.message) {
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }
        enqueueSnackbar("Es ist ein Fehler beim umbennen der Objekt Datei aufgetreten", { variant: 'error' });
        return "error";
    }



};

function _arrayBufferToBase64(buffer: any) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}