import { DeleteForever, ExpandMore, Height } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { blue, blueGrey, green, red } from "@mui/material/colors";
import { DatePicker, yearCalendarClasses } from "@mui/x-date-pickers";
import { endOfDay, startOfDay, subDays } from "date-fns";
import React, { Fragment, useContext, useImperativeHandle, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { FEEDBACK_DISPLAY_TYPE_CHART } from "../../../../constants";
import { AggregateType, DataFilterType, PeriodChartFilterType, generateChartData, generateSumChartData, getDataFromGroupIdsFeedbackQuestions, getDataFromGroupIdsIncidentsVisitor, getDataFromGroupIdsMotion, getMotionData, APIDataAmountType, getCleanerFeedbackByUserGroupIds, getFeedbackQuestionsByUserGroupIds, getIncidentVisitorByGroupIds, getFeedbackData, getGroupPayPerServiceByGroupIds, getSuggestionsByUserGroupIds } from "../../../../helpers/charts/base";
import { generateFeedbackQuestionChartData, generateFeedbackQuestionCountChartData, generateFeedbackQuestionSumChartData, generateFeedbacksChartData, generateFeedbacksChartDataSum, generateIncidentVisitorChartData, generateMotionsChartData, getAllCleanerFeebacksTypes, getAllFeebacksTypes, getAllFeedbackQuestions, getAllIncidentVisitor, getAllMotionTypes, getFeebacksTypesThreeSmiles } from "../../../../helpers/charts/customerUsageGenerator";
import { getAvgFromSimpleChart, getAvgFromSimpleChartValueField, getEvaluationChoice, getGroupIdsForPrint, getUserIdFromStorage } from "../../../../helpers/common";
import { MaxChartItemCountInput } from "../../../../helpers/compontents";
import { defaultEndDate, defaultStartDate, formatDateRange, getDefaultPeriod, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../helpers/date-management";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupCleanerFeedback } from "../../../../models/GroupCleanerFeedback";
import { FeedbackTotalTable, GroupFeedback } from "../../../../models/GroupFeedback";
import { FeedbackQuestionTotal, FeedbackQuestionTotalTable, FeedbackQuestionType, GroupFeedbackQuestion } from "../../../../models/GroupFeedbackQuestion";
import { GroupIncidentVisitor } from "../../../../models/GroupIncidentVisitor";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { FeedbackDisplayType, FeedbackQuestion, GroupSettings, GroupSettingsIncidenReport, LocationInformation } from "../../../../models/GroupSettings";
import { green as greenIntern, red as redIntern, yellow } from "../../../../styles/colors";
import { elevationPaper, paperStyle } from "../../../../styles/container";
import { mapChartKeyFeedback } from "../../../charts/Base";
import { StandardBarChartSimpleColor } from "../../../charts/StandardBarChartSimpleColor";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";
import { ChartHeightTextBox, GlobalTimeChoice, PrintButton, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { getRatioMotionFeedbackThreeSmilesType, getRatioMotionFeedbackType } from "../../../core/TimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { GlobalContext } from "../../PortalPage";
import { getQuery, useQuery } from "../checkout/ShopPage";
import { GetCleanerGroupFeedbacks } from "./customerUsage/GetCleanerGroupFeedbacks";
import { GetGroupFeedbackQuestions } from "./customerUsage/GetGroupFeedbackQuestions";
import { GetGroupFeedbacks } from "./customerUsage/GetGroupFeedbacks";
import { GetGroupFeedbacksPercent } from "./customerUsage/GetGroupFeedbacksPercent";
import { GroupMotionChart } from "./customerUsage/GetGroupMotions";
import { IncidentVisitorChart, IncidentVisitorChartType } from "./customerUsage/IncidentVisitorChart";
import { loadMotionData } from "./FrequencyDashboard";
import { FeedbackQuestionDataTable } from "./feedbacks/FeedbackQuestionDataTable";
import { GroupFeedbackEntireChartsSimple } from "./feedbacks/GroupFeedbackEntireChartsSimple";
import { IncidentVisitorDataTable } from "./feedbacks/IncidentVisitorDataTable";
import { StandardBarChartSimpleFeedbackQuestion } from "./feedbacks/StandardBarChartSimpleFeedbackQuestion";
import { PrintContext } from "./HitListDashboard";
import useNoInitialEffect from "../../../../helpers/extensions";
import { GetGroupFeedbacksMotionComb } from "./customerUsage/GetGroupFeedbacksMotionComb";
import { GroupMotion } from "../../../../models/GroupMotion";
import { GetGroupFeedbacksQualityMotionComb } from "./customerUsage/GetGroupFeedbacksQualityMotionComb";
import { GetGroupFeedbackPerVisitor } from "./customerUsage/GetGroupFeedbackPerVisitor";
import { GetGroupRatioMotionsFeedbacks } from "./customerUsage/GetGroupRatioMotionsFeedbacks";
import { FeedbackDataTable } from "./FeedbackDataTable";
import { CleanerFeedbackDataTable } from "./CleanerFeedbackDataTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { EvalApiType } from "./Services";
import { AnalyticSummaryPayPerServiceDataTable } from "./AnalyticSummaryPayPerServiceDataTable";
import { StringHelper } from "../../../../helpers/string-helper";
import { FeedbackTotalTableComponent } from "../../../tables/feedback/FeedbackTotalTableComponent";
import { BuildGroupFeedbackQuestionsWithGroupsSimple, FEEDBACK_QUESTION_NEGATIVE_WITH_GROUPS_SIMPLE_TITLE, FEEDBACK_QUESTION_POSITIVE_WITH_GROUPS_SIMPLE_TITLE } from "./feedbacks/BuildGroupFeedbackQuestionsWithGroupsSimple";
import { FeedbackQualityVerticalChart } from "./feedbacks/FeedbackQualityVerticalChart";
import { GroupSuggestionDataTable } from "./feedbacks/GroupSuggestionDataTable";
import { GroupSuggestion } from "../../../../models/GroupSuggestion";
import { useSnackbar } from "notistack";
import { UserAccount } from "../../../../models/UserAccount";
import { UserType } from "../../../../models/UserType";
import { GetGroupFeedbackQuestionsCountChart } from "./customerUsage/GetGroupFeedbackQuestionsCountChart";
import { FeedbackQuestionTotalTableComponent } from "../../../tables/feedback/FeedbackQuestionTotalTableComponent";
import { useTranslation } from "react-i18next";

export const FEEDBACK_QUESTION_NEGATIVE_CHART_TITLE = 'Negative Feedbackfrage ausgelöst';
export const FEEDBACK_QUESTION_POSITIVE_CHART_TITLE = 'Positive Feedbackfrage ausgelöst';

export const FEEDBACK_QUESTION_NEGATIVE_CHART_SIMPLE_TITLE = "Negative Feedbackfragen"
export const FEEDBACK_QUESTION_POSITIVE_CHART_SIMPLE_TITLE = "Positive Feedbackfragen"


export const FeedbackDashboard = ({ serviceDisplayType, groupIds, drawerDisplayType }: { serviceDisplayType: EvalApiType, groupIds?: any, drawerDisplayType: DrawerMenuItemDisplayType }) => {
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);

    const [page, setPage] = React.useState(0);
    const { maxChartItemCount, setApiDataLoading, setIsPreFilterDialogVisible }: { maxChartItemCount: number, setApiDataLoading: any, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);

    const { enqueueSnackbar } = useSnackbar();
    const { userAccount }: { userAccount: UserAccount } = useContext(GlobalContext);

    const [suggestions, setSuggestions] = React.useState<GroupSuggestion[]>([]);
    const [isSuggestionsLoading, setIsSuggestionsLoading] = React.useState(false);
    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);

    const [motionTableData, setMotionTableData] = React.useState<GroupMotion[]>([]);

    const [feedbackDashboardfeedbackMotionCombChartData, setfeedbackDashboardFeedbackMotionCombChartData] = React.useState<any>([]);
    const [feedbackDashboardfeedbackMotionCombChartDataAll, setfeedbackDashboardFeedbackMotionCombChartDataAll] = React.useState<any>([]);
    const [feedbackDashboardfeedbackCombDataFields, setfeedbackDashboardFeedbackCombDataFields] = React.useState<any>([]);
    const [feedbackDashboardmotionCombDataFields, setfeedbackDashboardMotionCombDataFields] = React.useState<any>([]);
    const feedbackDashboardchildRefFeedbackMotionComb: any = useRef();
    const feedbackDashboardchildRefFeedbackMotionCombChart: any = useRef();


    const [feedbackQualityMotionCombChartData, setFeedbackQualityMotionCombChartData] = React.useState<any>([]);
    const feedbackDashboardchildRefFeedbackQualityMotionComb: any = useRef();
    const feedbackDashboardchildRefFeedbackQualityMotionCombChart: any = useRef();


    const [groupMotions, setGroupMotions] = React.useState<any>([]);
    const [groupMotionsAll, setGroupMotionsAll] = React.useState<any>([]);
    const [groupMotionType, setGroupMotionType] = React.useState<any>([]);

    const [feedbackDashboardfeedbackData, setfeedbackDashboardFeedbackData] = React.useState<any>([]);
    const [feedbackDashboardfeedbackAllData, setfeedbackDashboardFeedbackAllData] = React.useState<any>([]);
    const [feedbackDashboardfeedbackDataType, setfeedbackDashboardFeedbackDataType] = React.useState<any>([]);

    const [cleanerfeedbackDashboardFeedbackData, setFeedbackDasboardCleanerFeedbackData] = React.useState<any>([]);
    const [feedbackDashboardcleanerfeedbackDashboardFeedbackAllData, setfeedbackDashboardCleanerfeedbackDashboardFeedbackAllData] = React.useState<any>([]);
    const [cleanerfeedbackDashboardFeedbackDataType, setFeedbackDasboardCleanerFeedbackDataType] = React.useState<any>([]);
    const [feedbackDisplayTypeChart, setFeedbackDisplayTypeChart] = React.useState(FeedbackDisplayType.PositiveNegativeNeutral);

    const [feedbackQuestionCountData, setFeedbackQuestionCountData] = React.useState<any>([]);
    const [feedbackQuestionCountAllData, setFeedbackQuestionCountAllData] = React.useState<any>([]);
    const [feedbackQuestionCountDataFields, setFeedbackQuestionCountDataFields] = React.useState<any>([]);

    const [feedbackQuestionData, setFeedbackQuestionData] = React.useState<any>([]);
    const [feedbackQuestionAllData, setFeedbackQuestionAllData] = React.useState<any>([]);
    const [feedbackQuestionDataFields, setFeedbackQuestionDataFields] = React.useState<any>([]);

    const [feedbackQuestionPositiveData, setFeedbackQuestionPositiveData] = React.useState<any>([]);
    const [feedbackQuestionPositiveAllData, setFeedbackQuestionPositiveAllData] = React.useState<any>([]);
    const [feedbackQuestionPositiveDataFields, setFeedbackQuestionPositiveDataFields] = React.useState<any>([]);

    const [incidentVisitorData, setIncidentVisitorData] = React.useState<any>([]);
    const [incidentVisitorAllData, setIncidentVisitorAllData] = React.useState<any>([]);
    const [incidentVisitorDataFields, setIncidentVisitorDataFields] = React.useState<any>([]);
    const [incidentVisitorTypeChart, setIncidentVisitorTypeChart] = React.useState(IncidentVisitorChartType.Standard);

    const [allFeedbackQuestionTableData, setAllFeedbackQuestionTableData] = React.useState<GroupFeedbackQuestion[]>([]);
    const [feedbackQuestionTableData, setFeedbackQuestionTableData] = React.useState<GroupFeedbackQuestion[]>([]);
    const [feedbackQuestionPositiveTableData, setFeedbackQuestionPositiveTableData] = React.useState<GroupFeedbackQuestion[]>([]);

    const [incidentVisitorTableData, setIncidentVisitorTableData] = React.useState<GroupIncidentVisitor[]>([]);
    const [isPrintingPage, setIsPrintingPage] = React.useState({ print: false, resolve: undefined });

    const [isFeedbackDeletingLoading, setIsFeedbackDeletingLoading] = React.useState(false);


    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const [loadingMotionCompleted, setLoadingMotionCompleted] = React.useState(false);
    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();
    const childRefMotion: any = useRef();
    const childRefMotionChart: any = useRef();
    const feedbackDashboardchildRefFeedback: any = useRef();
    const feedbackDashboardchildRefFeedbackChart: any = useRef();

    const feedbackDashboardchildRefCleanerFeedback: any = useRef();
    const feedbackDashboardchildRefCleanerFeedbackChart: any = useRef();

    const feedbackDashboardchildRefFeedbackQuestion: any = useRef();
    const feedbackDashboardchildRefFeedbackQuestionChart: any = useRef();

    const feedbackDashboardchildRefFeedbackQuestionCount: any = useRef();
    const feedbackDashboardchildRefFeedbackQuestionCountChart: any = useRef();

    const feedbackDashboardchildRefFeedbackQuestionPositive: any = useRef();
    const feedbackDashboardchildRefFeedbackQuestionPositiveChart: any = useRef();

    const childRefIncidentVisitor: any = useRef();
    const childRefIncidentVisitorChart: any = useRef();

    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);

    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);

    const feedbackDashboardchildRefGroupRatioMotionsFeedback: any = useRef();
    const [feedbackDashboardgroupRatioMotionsFeedback, setfeedbackDashboardGroupRatioMotionsFeedback] = React.useState<any>([]);
    const [feedbackDashboardgroupRatioMotionsFeedbackAll, setfeedbackDashboardGroupRatioMotionsFeedbackAll] = React.useState<any>([]);
    const [feedbackDashboardgroupRatioMotionsFeedbackType, setfeedbackDashboardGroupRatioMotionsFeedbackType] = React.useState<any>([]);


    const feedbackDashboardchildRefGroupFeedbackPerVisitor: any = useRef();
    const [feedbackDashboardGroupFeedbackPerVisitor, setfeedbackDashboardGroupFeedbackPerVisitor] = React.useState<any>([]);
    const [feedbackDashboardGroupFeedbackPerVisitorAll, setfeedbackDashboardGroupFeedbackPerVisitorAll] = React.useState<any>([]);
    const [groupfeedbackDashboardGroupFeedbackPerVisitorType, setfeedbackDashboardGroupFeedbackPerVisitorType] = React.useState<any>([]);


    const refFeedbackQuestionSimpleChart: any = useRef()
    const refFeedbackQuestionPositiveSimpleChart: any = useRef()


    const [initializeGlobalData, setInitializeGlobalData] = React.useState(false);
    const [tablefeedbackDashboardFeedbackData, setTablefeedbackDashboardFeedbackData] = React.useState<GroupFeedback[]>([]);
    const [feedbackCleanerTableData, setFeedbackCleanerTableData] = React.useState<GroupCleanerFeedback[]>([]);

    const refGroupFeedbackEntireChartsSimple = useRef<{ updateStartDateFeedbackWithGroupsSimpleData(newVal: Date): void, updateEndDateFeedbackWithGroupsSimpleData(newVal: Date): void }>();

    const refGroupFeedbackQuestionsWithGroupsSimple = useRef<{ updateStartDateFeedbackQuestionWithGroupsSimpleData(newVal: Date): void, updateEndDateFeedbackQuestionWithGroupsSimpleData(newVal: Date): void }>();
    const refGroupFeedbackQuestionsPositiveWithGroupsSimple = useRef<{ updateStartDateFeedbackQuestionWithGroupsSimpleData(newVal: Date): void, updateEndDateFeedbackQuestionWithGroupsSimpleData(newVal: Date): void }>();
    const refFeedbackQualityVerticalChart = useRef<{ updateStartDateFeedbackQualityVerticalChartData(newVal: Date): void, updateEndDateFeedbackQualityVerticalChartData(newVal: Date): void }>();

    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();


    const [startDateFeedbackTotalTable, setStartDateFeedbackTotalTable] = React.useState(subDays(new Date(), 10));
    const [endDateFeedbackTotalTable, setEndDateFeedbackTotalTable] = React.useState(new Date());
    const [feedbackTotalTableData, setFeedbackTotalTableData] = React.useState<FeedbackTotalTable[]>([]);


    const [startDateFeedbackQuestionTotalTable, setStartDateFeedbackQuestionTotalTable] = React.useState(subDays(new Date(), 10));
    const [endDateFeedbackQuestionTotalTable, setEndDateFeedbackQuestionTotalTable] = React.useState(new Date());
    const [feedbackQuestionTotalTableData, setFeedbackQuestionTotalTableData] = React.useState<FeedbackQuestionTotalTable[]>([]);

    const [selection, setSelection] = React.useState<any[]>([]);
    const {t} = useTranslation();


    React.useEffect(() => {
        if (groupMotionsAll.length > 0 && feedbackDashboardfeedbackAllData.length > 0) {
            setfeedbackDashboardGroupRatioMotionsFeedbackAll([...groupMotionsAll, ...feedbackDashboardfeedbackAllData]);
            setfeedbackDashboardGroupFeedbackPerVisitorAll([...groupMotionsAll, ...feedbackDashboardfeedbackAllData]);

            showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
        }
    }, [groupMotionsAll, feedbackDashboardfeedbackAllData, page]);



    const loadDataAsync = async () => {

        const token = getApiToken(history);
        if (!token) return;
        setApiDataLoading(true);
        setfeedbackDashboardFeedbackData([]);
        setGroupMotions([]);
        setLoadingCompleted(false);
        setLoadingMotionCompleted(false);
        setLoadingProgress(0);
        setValueBuffer(20);

        let groups: GroupLicenseTable[] = [];

        if (serviceDisplayType == EvalApiType.Objects) {
            groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        if (serviceDisplayType == EvalApiType.Groups) {
            groups = await getPortalGroups(groupIds, token, groupId);
            setGroupsLicenseTableEntries(groups);
        }


        let feedbacks = await getFeedbackData(groupId, token, groupIds);

        await loadFeedbackData(
            setfeedbackDashboardFeedbackAllData,
            setfeedbackDashboardFeedbackDataType,
            setfeedbackDashboardFeedbackData,
            setLoadingCompleted,
            AggregateType.Time,
            false,
            feedbacks,
            setTablefeedbackDashboardFeedbackData);
        const queryString = getQuery();
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        setStartDateFeedbackTotalTable(startDate);
        setEndDateFeedbackTotalTable(endDate);
        updateTotalFeedbackTable(startDate, endDate, feedbacks, groups);

        setLoadingProgress(20);
        setValueBuffer(40);

        let motions = (await getMotionData(groupId, token, groupIds)) || [];
        setMotionTableData(motions);
        await loadMotionData(
            setGroupMotionsAll,
            setGroupMotionType,
            setGroupMotions,
            setLoadingMotionCompleted,
            AggregateType.Time,
            motions);



        let chartData = await loadFeedbackMotionCombData(setfeedbackDashboardFeedbackMotionCombChartDataAll, setfeedbackDashboardFeedbackCombDataFields, setfeedbackDashboardMotionCombDataFields, setfeedbackDashboardFeedbackMotionCombChartData, setLoadingCompleted, feedbacks, motions);
        setFeedbackQualityMotionCombChartData(chartData);

        setLoadingProgress(40);
        setValueBuffer(60);


        setInitializeGlobalData(true);

        setLoadingProgress(60);
        setValueBuffer(80);


        var promise2 = (async () => {
            let temp = await getCleanerFeedbackByUserGroupIds(groupId, token, groupIds);
            await loadCleanerFeedbackData(groupId,
                setfeedbackDashboardCleanerfeedbackDashboardFeedbackAllData,
                setFeedbackDasboardCleanerFeedbackDataType,
                setFeedbackDasboardCleanerFeedbackData,
                setLoadingCompleted,
                AggregateType.Time,
                setFeedbackCleanerTableData,
                temp);
        })();
        await promise2;
        setLoadingProgress(80);
        setValueBuffer(90);
        let currentFeedbackDisplayType = localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString();
        setFeedbackDisplayTypeChart(JSON.parse(currentFeedbackDisplayType));
        updateGlobalFeedbackDisplayTypeChart(currentFeedbackDisplayType);
        var promise3 = (async () => {
            const tempGroupFeedbacks: GroupFeedbackQuestion[] = await getFeedbackQuestionsByUserGroupIds(groupId, token, groupIds);
            setAllFeedbackQuestionTableData(tempGroupFeedbacks);

            setStartDateFeedbackQuestionTotalTable(startDate);
            setEndDateFeedbackQuestionTotalTable(endDate);
            updateTotalFeedbackQuestionTable(startDate, endDate, tempGroupFeedbacks, groups);

            await loadFeedbackQuestionCountData(tempGroupFeedbacks,
                setFeedbackQuestionCountAllData,
                setFeedbackQuestionCountDataFields,
                setFeedbackQuestionCountData,
                setLoadingCompleted);

            let negativeFeedbackQuestions = tempGroupFeedbacks.filter(x => x.feedbackQuestionType == FeedbackQuestionType.Negative);
            setFeedbackQuestionTableData(negativeFeedbackQuestions);

            await loadFeedbackQuestionData(negativeFeedbackQuestions,
                setFeedbackQuestionAllData,
                setFeedbackQuestionDataFields,
                setFeedbackQuestionData,
                setLoadingCompleted,
                AggregateType.Time);

            let positiveFeedbackQuestions = tempGroupFeedbacks.filter(x => x.feedbackQuestionType == FeedbackQuestionType.Positive);
            setFeedbackQuestionPositiveTableData(positiveFeedbackQuestions);


            await loadFeedbackQuestionData(positiveFeedbackQuestions,
                setFeedbackQuestionPositiveAllData,
                setFeedbackQuestionPositiveDataFields,
                setFeedbackQuestionPositiveData,
                setLoadingCompleted,
                AggregateType.Time);

            setLoadingProgress(90);
            setValueBuffer(100);
            let tempIncidentVisitorData = await getIncidentVisitorByGroupIds(groupId, token, groupIds)
            await loadIncidentVisitorData(tempIncidentVisitorData,
                setIncidentVisitorAllData,
                setIncidentVisitorDataFields,
                setIncidentVisitorData,
                setLoadingCompleted,
                AggregateType.Time,
                IncidentVisitorChartType.Standard);
            setIncidentVisitorTableData(tempIncidentVisitorData);


            setLoadingProgress(100);
            setValueBuffer(100);
            setApiDataLoading(false);
        })();

        await promise3;



    }


    React.useEffect(() => {
        if (initializeGlobalData)
            showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
    }, [initializeGlobalData]);

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, [])

    useNoInitialEffect(() => {
        loadDataAsync();
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
    }, [apiDataAmountType, reloadDataFromApi]);

    const updateChosenPastDaysFromParent = (event: any) => {



        if (feedbackDashboardchildRefFeedback?.current)
            feedbackDashboardchildRefFeedback.current.updateChosenPastDays(event.target.value);

        if (feedbackDashboardchildRefCleanerFeedback?.current)
            feedbackDashboardchildRefCleanerFeedback.current.updateChosenPastDays(event.target.value);

        if (feedbackDashboardchildRefFeedbackQuestion?.current)
            feedbackDashboardchildRefFeedbackQuestion.current.updateChosenPastDays(event.target.value);

        if (feedbackDashboardchildRefFeedbackQuestionCount?.current)
            feedbackDashboardchildRefFeedbackQuestionCount.current.updateChosenPastDays(event.target.value);

        if (childRefMotion?.current)
            childRefMotion.current.updateChosenPastDays(event.target.value);

        if (feedbackDashboardchildRefGroupRatioMotionsFeedback?.current)
            feedbackDashboardchildRefGroupRatioMotionsFeedback.current.updateChosenPastDays(event.target.value);

        if (feedbackDashboardchildRefGroupFeedbackPerVisitor?.current)
            feedbackDashboardchildRefGroupFeedbackPerVisitor.current.updateChosenPastDays(event.target.value);

    }

    const updateEndDateFromParent = (event: any) => {

        if (feedbackDashboardchildRefFeedback?.current)
            feedbackDashboardchildRefFeedback.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefCleanerFeedback?.current)
            feedbackDashboardchildRefCleanerFeedback.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefFeedbackQuestion?.current)
            feedbackDashboardchildRefFeedbackQuestion.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefFeedbackQuestionCount?.current)
            feedbackDashboardchildRefFeedbackQuestionCount.current.updateEndDateFromParent(event);

        if (childRefMotion?.current)
            childRefMotion.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefGroupRatioMotionsFeedback?.current)
            feedbackDashboardchildRefGroupRatioMotionsFeedback.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefGroupFeedbackPerVisitor?.current)
            feedbackDashboardchildRefGroupFeedbackPerVisitor.current.updateEndDateFromParent(event);


        if (feedbackDashboardchildRefFeedbackMotionComb?.current)
            feedbackDashboardchildRefFeedbackMotionComb.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefFeedbackQualityMotionComb?.current)
            feedbackDashboardchildRefFeedbackQualityMotionComb.current.updateEndDateFromParent(event);

        if (feedbackDashboardchildRefGroupFeedbackPerVisitor?.current)
            feedbackDashboardchildRefGroupFeedbackPerVisitor.current.updateEndDateFromParent(event);

        if (refGroupFeedbackQuestionsWithGroupsSimple.current)
            refGroupFeedbackQuestionsWithGroupsSimple.current.updateEndDateFeedbackQuestionWithGroupsSimpleData(event);

        if (refGroupFeedbackQuestionsPositiveWithGroupsSimple.current)
            refGroupFeedbackQuestionsPositiveWithGroupsSimple.current.updateEndDateFeedbackQuestionWithGroupsSimpleData(event);

        if (refFeedbackQualityVerticalChart.current)
            refFeedbackQualityVerticalChart.current.updateEndDateFeedbackQualityVerticalChartData(event);

        if (refFeedbackQuestionSimpleChart.current)
            refFeedbackQuestionSimpleChart.current.updateEndDateFeedbackQuestionSimpleData(event);


        if (refFeedbackQuestionPositiveSimpleChart.current)
            refFeedbackQuestionPositiveSimpleChart.current.updateEndDateFeedbackQuestionSimpleData(event);

        if (refGroupFeedbackEntireChartsSimple.current)
            refGroupFeedbackEntireChartsSimple.current.updateEndDateFeedbackWithGroupsSimpleData(event);

    }

    const updateStartDateFromParent = (event: any) => {
        if (feedbackDashboardchildRefFeedback?.current)
            feedbackDashboardchildRefFeedback.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefCleanerFeedback?.current)
            feedbackDashboardchildRefCleanerFeedback.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefFeedbackQuestion?.current)
            feedbackDashboardchildRefFeedbackQuestion.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefFeedbackQuestionCount?.current)
            feedbackDashboardchildRefFeedbackQuestionCount.current.updateStartDateFromParent(event);

        if (childRefMotion?.current)
            childRefMotion.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefGroupRatioMotionsFeedback?.current)
            feedbackDashboardchildRefGroupRatioMotionsFeedback.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefGroupFeedbackPerVisitor?.current)
            feedbackDashboardchildRefGroupFeedbackPerVisitor.current.updateStartDateFromParent(event);


        if (feedbackDashboardchildRefFeedbackMotionComb?.current)
            feedbackDashboardchildRefFeedbackMotionComb.current.updateStartDateFromParent(event);

        if (feedbackDashboardchildRefFeedbackQualityMotionComb?.current)
            feedbackDashboardchildRefFeedbackQualityMotionComb.current.updateStartDateFromParent(event);

        if (refGroupFeedbackQuestionsWithGroupsSimple.current)
            refGroupFeedbackQuestionsWithGroupsSimple.current.updateStartDateFeedbackQuestionWithGroupsSimpleData(event);

        if (refGroupFeedbackQuestionsPositiveWithGroupsSimple.current)
            refGroupFeedbackQuestionsPositiveWithGroupsSimple.current.updateStartDateFeedbackQuestionWithGroupsSimpleData(event);

        if (refFeedbackQualityVerticalChart.current)
            refFeedbackQualityVerticalChart.current.updateStartDateFeedbackQualityVerticalChartData(event);

        if (refFeedbackQuestionSimpleChart.current)
            refFeedbackQuestionSimpleChart.current.updateStartDateFeedbackQuestionSimpleData(event);

        if (refFeedbackQuestionPositiveSimpleChart.current)
            refFeedbackQuestionPositiveSimpleChart.current.updateStartDateFeedbackQuestionSimpleData(event);


        if (refGroupFeedbackEntireChartsSimple.current)
            refGroupFeedbackEntireChartsSimple.current.updateStartDateFeedbackWithGroupsSimpleData(event);

    }

    const updateDataFilterTypeFromParent = (event: any) => {
        if (feedbackDashboardchildRefFeedback?.current)
            feedbackDashboardchildRefFeedback.current.updateDataFilterTypeFromParent(event);

        if (feedbackDashboardchildRefCleanerFeedback?.current)
            feedbackDashboardchildRefCleanerFeedback.current.updateDataFilterTypeFromParent(event);

        if (feedbackDashboardchildRefFeedbackQuestion?.current)
            feedbackDashboardchildRefFeedbackQuestion.current.updateDataFilterTypeFromParent(event);


        if (feedbackDashboardchildRefFeedbackQuestionCount?.current)
            feedbackDashboardchildRefFeedbackQuestionCount.current.updateDataFilterTypeFromParent(event);

        if (childRefMotion?.current)
            childRefMotion.current.updateDataFilterTypeFromParent(event);

        if (feedbackDashboardchildRefGroupRatioMotionsFeedback?.current)
            feedbackDashboardchildRefGroupRatioMotionsFeedback.current.updateDataFilterTypeFromParent(event);

        if (feedbackDashboardchildRefGroupFeedbackPerVisitor?.current)
            feedbackDashboardchildRefGroupFeedbackPerVisitor.current.updateDataFilterTypeFromParent(event);

        if (feedbackDashboardchildRefFeedbackMotionComb?.current)
            feedbackDashboardchildRefFeedbackMotionComb.current.updateDataFilterTypeFromParent(event);

        if (feedbackDashboardchildRefFeedbackQualityMotionComb?.current)
            feedbackDashboardchildRefFeedbackQualityMotionComb.current.updateDataFilterTypeFromParent(event);

    }

    const buildPrintContent = () => {
        return (
            <div ref={refPrint} className="page-size">
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Feedback Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[feedbackDashboardchildRefFeedbackChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                    <Grid item xs={12}>
                        {buildFeedbackCountChoice()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetGroupFeedbacks()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetGroupFeedbacksPercent()}
                    </Grid>

                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildGetGroupMotion()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetfeedbackDashboardGroupRatioMotionsFeedbacks()}
                    </Grid>
                </Grid>
                {//  <GroupTypePrintDisplay title={"Feedback Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsSettings} />}
                }
                <div className="page-break"></div>
                {thirdPartContent()}
                <div className="page-break"></div>
                {fourthPartContent()}
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildGetGroupFeedbackQuestionsNegative()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetGroupFeedbackQuestionsPositive()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGetCleanerGroupFeedbacks()}
                    </Grid>
                </Grid>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildIncidentVisitorChart()}
                    </Grid>
                </Grid>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Feedback Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        )
    }

    const updateGlobalFeedbackDisplayTypeChart = (value: string) => {
        if (value == FeedbackDisplayType.PositiveNegativeNeutral.toString()) {
            setFeedbackDisplayTypeChart(FeedbackDisplayType.PositiveNegativeNeutral);
            setfeedbackDashboardFeedbackDataType(getFeebacksTypesThreeSmiles());
            setFeedbackDasboardCleanerFeedbackDataType(getFeebacksTypesThreeSmiles());
            setfeedbackDashboardGroupRatioMotionsFeedbackType(getRatioMotionFeedbackThreeSmilesType());
            setfeedbackDashboardGroupFeedbackPerVisitorType(["allFeedback"]);
            localStorage.setItem(FEEDBACK_DISPLAY_TYPE_CHART, FeedbackDisplayType.PositiveNegativeNeutral.toString());
        } else {
            setFeedbackDisplayTypeChart(FeedbackDisplayType.AdvancedWithVeryPostiveNegative);
            setfeedbackDashboardFeedbackDataType(getAllFeebacksTypes());
            setFeedbackDasboardCleanerFeedbackDataType(getAllCleanerFeebacksTypes());
            setfeedbackDashboardGroupRatioMotionsFeedbackType(getRatioMotionFeedbackType());
            setfeedbackDashboardGroupFeedbackPerVisitorType(["allFeedback"]);
            localStorage.setItem(FEEDBACK_DISPLAY_TYPE_CHART, FeedbackDisplayType.AdvancedWithVeryPostiveNegative.toString());
        }
    }

    const buildfeedbackDashboardFeedbackDataTable = () => {
        return (
            <Paper>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Feedback Log Tabelle
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {tablefeedbackDashboardFeedbackData.length}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                            {(userAccount?.userType == UserType.Manager || userAccount?.userType == UserType.Admin || userAccount?.userType == UserType.Owner) &&
                                <Grid item xs={12}>
                                    <Button

                                        size="large"
                                        startIcon={<DeleteForever />}
                                        variant={'contained'}
                                        disabled={isFeedbackDeletingLoading || selection.length < 1}
                                        endIcon={isFeedbackDeletingLoading && <CircularProgress size={32} />}
                                        onClick={async () => {
                                            let feedbacksForDelete = tablefeedbackDashboardFeedbackData.filter(x => selection.includes(x.id));
                                            setIsFeedbackDeletingLoading(true);
                                            for (let feedback of feedbacksForDelete) {
                                                await api.DeleteFeedbackById(feedback.groupId, feedback.id, getApiToken(history) || "", enqueueSnackbar);
                                                setSelection((prev) => prev.filter(id => id != feedback.id));
                                            }
                                            setIsFeedbackDeletingLoading(false);
                                        }}
                                        color="error"
                                    >
                                        {selection.length} Feedbacks löschen
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <FeedbackDataTable groups={groupsLicenseTableEntries} rows={tablefeedbackDashboardFeedbackData} selectedEntries={selection} onSelectionChange={(selection: string[]) => {
                                    setSelection(selection);
                                }} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        )
    }

    const buildCleanerfeedbackDashboardFeedbackDataTable = () => {
        return (
            <Paper>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Feedback-Servicekraft Log Tabelle
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {feedbackCleanerTableData.length}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CleanerFeedbackDataTable groups={groupsLicenseTableEntries} rows={feedbackCleanerTableData} selectedEntries={[]} onSelectionChange={() => { }} />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        )
    }

    const buildFeedbackQuestionDataTable = () => {
        return (
            <Paper>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Feedbackfragen Log Tabelle
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {feedbackQuestionTableData.length}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FeedbackQuestionDataTable groups={groupsLicenseTableEntries} rows={allFeedbackQuestionTableData} selectedEntries={[]} onSelectionChange={() => { }} />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        )
    }


    const buildGroupSuggestionDataTable = () => {
        return (
            <Paper>
                <Accordion onChange={async (event, expanded) => {
                    if (expanded && suggestions.length < 1) {
                        const token = getApiToken(history);

                        setIsSuggestionsLoading(true);
                        let suggestions = await getSuggestionsByUserGroupIds(groupId, token, groupIds);
                        setSuggestions(suggestions);
                        setIsSuggestionsLoading(false);
                    }
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {isSuggestionsLoading && <CircularProgress size={32} />}
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Anregungen und Wünsche Log Tabelle
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {isSuggestionsLoading ? "Daten werden geladen..." : suggestions.length > 0 ? suggestions.length : "Öffnen um zu laden"}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GroupSuggestionDataTable groups={groupsLicenseTableEntries} rows={suggestions} selectedEntries={[]} onSelectionChange={() => { }} />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        )
    }

    const buildIncidentVisitorDataTable = () => {
        return (
            <Paper>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Vorfallbesucher Log Tabelle
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {incidentVisitorTableData.length}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <IncidentVisitorDataTable groups={groupsLicenseTableEntries} rows={incidentVisitorTableData} selectedEntries={[]} onSelectionChange={() => { }} />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        )
    }


    const buildFeedbackCountChoice = () => {

        return (
            <Paper style={{ padding: 10 }}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("myUnitsSettings.smileType")}</FormLabel>
                    <RadioGroup

                        name="feedbackDisplayTypeChart"
                        value={feedbackDisplayTypeChart}
                        onChange={(event) => {
                            updateGlobalFeedbackDisplayTypeChart(event.target.value);
                        }}
                    >
                        <FormControlLabel value={FeedbackDisplayType.PositiveNegativeNeutral} control={<Radio />} label="3 Smiles" />
                        <FormControlLabel value={FeedbackDisplayType.AdvancedWithVeryPostiveNegative} control={<Radio />} label="5 Smiles" />
                    </RadioGroup>
                </FormControl>
            </Paper>
        )
    }

    const buildGetGroupFeedbacksQualityMotionComb = () => {
        return (
            <GetGroupFeedbacksQualityMotionComb feedbackDisplayTypeChart={feedbackDisplayTypeChart} data={feedbackQualityMotionCombChartData} allData={feedbackDashboardfeedbackMotionCombChartDataAll} setChartData={setFeedbackQualityMotionCombChartData}
                childRef={feedbackDashboardchildRefFeedbackQualityMotionComb}
                childRefChart={feedbackDashboardchildRefFeedbackQualityMotionCombChart}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbacksMotionComb = () => {
        return (
            <GetGroupFeedbacksMotionComb feedbackDisplayTypeChart={feedbackDisplayTypeChart} data={feedbackDashboardfeedbackMotionCombChartData} dataFieldBars={feedbackDashboardmotionCombDataFields} dataFieldLines={feedbackDashboardfeedbackCombDataFields} allData={feedbackDashboardfeedbackMotionCombChartDataAll} setChartData={setfeedbackDashboardFeedbackMotionCombChartData}
                childRefChart={feedbackDashboardchildRefFeedbackMotionCombChart}
                childRef={feedbackDashboardchildRefFeedbackMotionComb} loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbacks = () => {
        return (
            <GetGroupFeedbacks
                childRefChart={feedbackDashboardchildRefFeedbackChart}
                childRef={feedbackDashboardchildRefFeedback}
                setChartData={setfeedbackDashboardFeedbackData}
                allData={feedbackDashboardfeedbackAllData}
                data={feedbackDashboardfeedbackData}
                dataField={feedbackDashboardfeedbackDataType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbacksPercent = () => {
        return (
            <GetGroupFeedbacksPercent
                childRefChart={feedbackDashboardchildRefFeedbackChart}
                childRef={feedbackDashboardchildRefFeedback}
                setChartData={setfeedbackDashboardFeedbackData}
                allData={feedbackDashboardfeedbackAllData}
                data={feedbackDashboardfeedbackData}
                dataField={feedbackDashboardfeedbackDataType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupMotion = () => {
        return (
            <GroupMotionChart
                childRefChart={childRefMotionChart}
                childRef={childRefMotion}
                setChartData={setGroupMotions}
                allData={groupMotionsAll}
                data={groupMotions}
                dataField={groupMotionType}
                loadingCompleted={loadingMotionCompleted} />
        )
    }

    const buildGetfeedbackDashboardGroupRatioMotionsFeedbacks = () => {
        return (
            <GetGroupRatioMotionsFeedbacks
                childRef={feedbackDashboardchildRefGroupRatioMotionsFeedback}
                setChartData={setfeedbackDashboardGroupRatioMotionsFeedback}
                allData={feedbackDashboardgroupRatioMotionsFeedbackAll}
                data={feedbackDashboardgroupRatioMotionsFeedback}
                dataField={feedbackDashboardgroupRatioMotionsFeedbackType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingMotionCompleted} />
        )
    }

    const buildGetfeedbackDashboardGroupFeedbackPerVisitor = () => {
        return (
            <GetGroupFeedbackPerVisitor
                childRef={feedbackDashboardchildRefGroupFeedbackPerVisitor}
                setChartData={setfeedbackDashboardGroupFeedbackPerVisitor}
                allData={feedbackDashboardGroupFeedbackPerVisitorAll}
                data={feedbackDashboardGroupFeedbackPerVisitor}
                dataField={groupfeedbackDashboardGroupFeedbackPerVisitorType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingMotionCompleted} />
        )
    }

    const buildGetGroupFeedbackQuestionsCountChart = () => {
        return (
            <GetGroupFeedbackQuestions
                title={"Anzahl Feedbackfragen Positiv und Negativ"}
                childRefChart={feedbackDashboardchildRefFeedbackQuestionCountChart}
                childRef={feedbackDashboardchildRefFeedbackQuestionCount}
                setChartData={setFeedbackQuestionCountData}
                allData={feedbackQuestionCountAllData}
                data={feedbackQuestionCountData}
                dataField={feedbackQuestionCountDataFields}
                colors={[redIntern, greenIntern]}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbackQuestionsNegative = () => {
        return (
            <GetGroupFeedbackQuestions
                title={FEEDBACK_QUESTION_NEGATIVE_CHART_TITLE}
                childRefChart={feedbackDashboardchildRefFeedbackQuestionChart}
                childRef={feedbackDashboardchildRefFeedbackQuestion}
                setChartData={setFeedbackQuestionData}
                allData={feedbackQuestionAllData}
                data={feedbackQuestionData}
                dataField={feedbackQuestionDataFields}
                colors={[red[900], red[800], red[700], red[600], red[500], red[400], red[300], red[200], red[100]]}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildGetGroupFeedbackQuestionsPositive = () => {
        return (
            <GetGroupFeedbackQuestions
                title={FEEDBACK_QUESTION_POSITIVE_CHART_TITLE}
                childRefChart={feedbackDashboardchildRefFeedbackQuestionPositiveChart}
                childRef={feedbackDashboardchildRefFeedbackQuestionPositive}
                setChartData={setFeedbackQuestionPositiveData}
                allData={feedbackQuestionPositiveAllData}
                data={feedbackQuestionPositiveData}
                dataField={feedbackQuestionPositiveDataFields}
                colors={[green[900], green[800], green[700], green[600], green[500], green[400], green[300], green[200], green[100]]}
                loadingCompleted={loadingCompleted} />
        )
    }


    const buildIncidentVisitorChart = () => {
        return (
            <Paper>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Besuchervorfallmeldung Ansicht</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={incidentVisitorTypeChart} onChange={async (event) => {
                            setIncidentVisitorTypeChart(event.target.value as IncidentVisitorChartType);
                            let token = getApiToken(history);
                            let tempIncidentVisitorData = await loadIncidentVisitorData(incidentVisitorTableData,
                                setIncidentVisitorAllData,
                                setIncidentVisitorDataFields,
                                setIncidentVisitorData,
                                setLoadingCompleted,
                                AggregateType.Time,
                                event.target.value as IncidentVisitorChartType);
                            setIncidentVisitorTableData(tempIncidentVisitorData);
                        }} row>
                            <FormControlLabel value={IncidentVisitorChartType.Standard} control={<Radio />} label="Standard" />
                            <FormControlLabel value={IncidentVisitorChartType.WithName} control={<Radio />} label="Mit Name" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <IncidentVisitorChart
                    childRefChart={childRefIncidentVisitorChart}
                    childRef={childRefIncidentVisitor}
                    setChartData={setIncidentVisitorData}
                    allData={incidentVisitorAllData}
                    data={incidentVisitorData}
                    dataField={incidentVisitorDataFields}
                    loadingCompleted={loadingCompleted} />
            </Paper>

        )
    }

    const buildGetCleanerGroupFeedbacks = () => {
        return (
            <GetCleanerGroupFeedbacks
                childRefChart={feedbackDashboardchildRefCleanerFeedbackChart}
                childRef={feedbackDashboardchildRefCleanerFeedback}
                setChartData={setFeedbackDasboardCleanerFeedbackData}
                allData={feedbackDashboardcleanerfeedbackDashboardFeedbackAllData}
                data={cleanerfeedbackDashboardFeedbackData}
                dataField={cleanerfeedbackDashboardFeedbackDataType}
                feedbackDisplayTypeChart={feedbackDisplayTypeChart}
                loadingCompleted={loadingCompleted} />
        )
    }

    const updateTotalFeedbackTable = (startDate: Date, endDate: Date, feedbacks: GroupFeedback[], groupsPortal: GroupLicenseTable[]) => {
        startDate = startOfDay(startDate);
        endDate = endOfDay(endDate);
        let feedbacksInTime = feedbacks.filter(x => new Date(x.createdTimestampString) >= startDate && new Date(x.createdTimestampString) <= endDate);


        let currentGroupsPortal = groupsPortal.filter(x => feedbacksInTime?.some(y => y.groupId == x.id)).filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName));
        let feedbackTotalTable = currentGroupsPortal.reduce((prevVal, group) => {

            let newEntry = new FeedbackTotalTable(group.id, 0, 0, 0);
            newEntry.veryNegativeAmount = 0;
            newEntry.veryPositiveAmount = 0;
            newEntry.totalAmount = 0;

            let groupFeedbacks = feedbacksInTime.filter(x => x.groupId == newEntry.groupId);

            groupFeedbacks.forEach(feedback => {
                newEntry.veryNegativeAmount += feedback.veryNegativeAmount;
                newEntry.negativeAmount += feedback.negativeAmount;
                newEntry.neutralAmount += feedback.neutralAmount;
                newEntry.positiveAmount += feedback.positiveAmount;
                newEntry.veryPositiveAmount += feedback.veryPositiveAmount;
            });
            newEntry.totalAmount = newEntry.veryNegativeAmount + newEntry.negativeAmount + newEntry.neutralAmount + newEntry.positiveAmount + newEntry.veryPositiveAmount;

            return [...prevVal, newEntry]
        }, [] as FeedbackTotalTable[]);


        setFeedbackTotalTableData(feedbackTotalTable);

    }


    const buildTotalFeedbackTable = () => {
        return (
            <Grid item xs={12}>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Feedback Summarisch Tabelle {loadingCompleted ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {feedbackTotalTableData.length > 0 ? feedbackTotalTableData.length : "Keine Einträge"}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>

                        {tablefeedbackDashboardFeedbackData &&
                            <div className="page-root">
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Feedback Summarisch Tabelle</Typography>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item>
                                        < DatePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={t("serviceDocumentationTable.startDate")}
                                            value={startDateFeedbackTotalTable}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                setStartDateFeedbackTotalTable(newVal);
                                                updateTotalFeedbackTable(newVal, endDateFeedbackTotalTable, tablefeedbackDashboardFeedbackData, groupsLicenseTableEntries);
                                            }}
                                            format={"dd.MM.yyyy"}

                                        />
                                    </Grid>
                                    <Grid item>
                                        < DatePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={t("serviceDocumentationTable.endDate")}
                                            value={endDateFeedbackTotalTable}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                setEndDateFeedbackTotalTable(newVal);
                                                updateTotalFeedbackTable(startDateFeedbackTotalTable, newVal, tablefeedbackDashboardFeedbackData, groupsLicenseTableEntries);
                                            }}
                                            format={"dd.MM.yyyy"}

                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>
                                        <FeedbackTotalTableComponent rows={feedbackTotalTableData} groups={groupsLicenseTableEntries} selectedEntries={[]} onSelectionChange={() => { }} />
                                    </Grid>
                                </Grid>
                                <div className="page-break"></div>

                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>
        );
    }


    const updateTotalFeedbackQuestionTable = (startDate: Date, endDate: Date, feedbacks: GroupFeedbackQuestion[], groupsPortal: GroupLicenseTable[]) => {
        startDate = startOfDay(startDate);
        endDate = endOfDay(endDate);
        let feedbacksInTime = feedbacks.filter(x => new Date(x.createdTimestampString) >= startDate && new Date(x.createdTimestampString) <= endDate);


        let currentGroupsPortal = groupsPortal.filter(x => feedbacksInTime?.some(y => y.groupId == x.id)).filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName));
        let feedbackTotalTable = currentGroupsPortal.reduce((prevVal, group) => {

            let newEntry = new FeedbackQuestionTotalTable();
            newEntry.groupId = group.id;
            newEntry.totalAmount = 0;

            let groupFeedbacks = feedbacksInTime.filter(x => x.groupId == newEntry.groupId);
            let totalAmount = 0;
            groupFeedbacks.forEach(feedback => {
                feedback.items.forEach(itemToAdd=>{
                    let itemToLook = newEntry?.items?.find(curItem=>curItem.item == itemToAdd);
                    if (itemToLook){
                        itemToLook.amount = itemToLook.amount + 1;
                    }else {
                        let newItem = new FeedbackQuestionTotal();
                        newItem.amount = 1;
                        newItem.item = itemToAdd;
                        newEntry.items.push(newItem);
                    }
                    totalAmount++;
                });
            });
            newEntry.totalAmount = totalAmount;
            return [...prevVal, newEntry]
        }, [] as FeedbackQuestionTotalTable[]);


        setFeedbackQuestionTotalTableData(feedbackTotalTable);

    }

    const buildTotalFeedbackQuestionTable = () => {
        return (
            <Grid item xs={12}>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Feedbackfrage Summarisch Tabelle {loadingCompleted ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {feedbackQuestionTotalTableData.length > 0 ? feedbackQuestionTotalTableData.length : "Keine Einträge"}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>

                        {feedbackQuestionAllData &&
                            <div className="page-root">
                                <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Feedbackfrage Summarisch Tabelle</Typography>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item>
                                        < DatePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={t("serviceDocumentationTable.startDate")}
                                            value={startDateFeedbackQuestionTotalTable}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                setStartDateFeedbackQuestionTotalTable(newVal);
                                                updateTotalFeedbackQuestionTable(newVal, endDateFeedbackQuestionTotalTable, feedbackQuestionAllData, groupsLicenseTableEntries);
                                            }}
                                            format={"dd.MM.yyyy"}

                                        />
                                    </Grid>
                                    <Grid item>
                                        < DatePicker
                                            disableFuture
                                            slotProps={{ textField: { variant: 'outlined' } }}
                                            label={t("serviceDocumentationTable.endDate")}
                                            value={endDateFeedbackQuestionTotalTable}
                                            onChange={(newVal) => {
                                                if (!newVal) return;
                                                setEndDateFeedbackQuestionTotalTable(newVal);
                                                updateTotalFeedbackQuestionTable(startDateFeedbackQuestionTotalTable, newVal, feedbackQuestionAllData, groupsLicenseTableEntries);
                                            }}
                                            format={"dd.MM.yyyy"}

                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>
                                        <FeedbackQuestionTotalTableComponent rows={feedbackQuestionTotalTableData} groups={groupsLicenseTableEntries} selectedEntries={[]} onSelectionChange={() => { }} />
                                    </Grid>
                                </Grid>
                                <div className="page-break"></div>

                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>
        );
    }



    const firstPartContent = () => {
        return (
            <Grid item container spacing={2}>

                <Grid item xs={12}>
                    {buildfeedbackDashboardFeedbackDataTable()}
                </Grid>
                <Grid item xs={12}>
                    {buildTotalFeedbackTable()}
                </Grid>
                <Grid item xs={12}>
                    {buildTotalFeedbackQuestionTable()}
                </Grid>
                <Grid item xs={12}>
                    {buildCleanerfeedbackDashboardFeedbackDataTable()}
                </Grid>
                <Grid item xs={12}>
                    {buildFeedbackQuestionDataTable()}
                </Grid>
                <Grid item xs={12}>
                    {buildGroupSuggestionDataTable()}
                </Grid>
                <Grid item xs={12}>
                    {buildIncidentVisitorDataTable()}
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryPayPerServiceDataTable groupsLicenseTableEntries={groupsLicenseTableEntries} groupId={groupId} groupIds={groupIds} />
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[feedbackDashboardchildRefFeedbackChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    {buildFeedbackCountChoice()}
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    {buildGetGroupFeedbacks()}
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    {buildGetGroupFeedbacksPercent()}
                </Grid>
                <Grid item xs={12} lg={4}>
                    {buildGetGroupMotion()}
                </Grid>
            </Grid>
        )
    }

    const secondPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    {buildGetGroupFeedbacksQualityMotionComb()}
                </Grid>
                <Grid item xs={12}>
                    {buildGetGroupFeedbacksMotionComb()}
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FeedbackQualityVerticalChart ref={refFeedbackQualityVerticalChart} feedbackTableData={tablefeedbackDashboardFeedbackData} motionTableData={motionTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildGetfeedbackDashboardGroupRatioMotionsFeedbacks()}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {buildGetfeedbackDashboardGroupFeedbackPerVisitor()}
                </Grid>
                <Grid item xs={12}>
                    {buildGetGroupFeedbackQuestionsCountChart()}
                </Grid>
                <Grid item xs={12} lg={12}>
                    {buildGetGroupFeedbackQuestionsNegative()}
                </Grid>
                <Grid item xs={12} lg={12}>
                    {buildGetGroupFeedbackQuestionsPositive()}
                </Grid>
            </Grid>
        )
    }

    const thirdPartContent = () => {
        return (
            <Grid item container spacing={2}>

                <Grid item xs={12} lg={12}>
                    <BuildGroupFeedbackQuestionsSimple color={redIntern} title={FEEDBACK_QUESTION_NEGATIVE_CHART_SIMPLE_TITLE} ref={refFeedbackQuestionSimpleChart} feedbackQuestionTableData={feedbackQuestionTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <BuildGroupFeedbackQuestionsSimple color={greenIntern} title={FEEDBACK_QUESTION_POSITIVE_CHART_SIMPLE_TITLE} ref={refFeedbackQuestionPositiveSimpleChart} feedbackQuestionTableData={feedbackQuestionPositiveTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </Grid>
            </Grid>
        )
    }

    const fourthPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <BuildGroupFeedbackQuestionsWithGroupsSimple color={redIntern} titleHeader={FEEDBACK_QUESTION_NEGATIVE_WITH_GROUPS_SIMPLE_TITLE} ref={refGroupFeedbackQuestionsWithGroupsSimple} feedbackQuestionTableData={feedbackQuestionTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <BuildGroupFeedbackQuestionsWithGroupsSimple color={greenIntern} titleHeader={FEEDBACK_QUESTION_POSITIVE_WITH_GROUPS_SIMPLE_TITLE} ref={refGroupFeedbackQuestionsPositiveWithGroupsSimple} feedbackQuestionTableData={feedbackQuestionPositiveTableData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <GroupFeedbackEntireChartsSimple ref={refGroupFeedbackEntireChartsSimple} tableFeedbackData={tablefeedbackDashboardFeedbackData} groupsLicenseTableEntries={groupsLicenseTableEntries} />
                </Grid>
            </Grid>
        )
    }

    const fifthPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12} lg={12}>
                    {buildIncidentVisitorChart()}
                </Grid>
                <Grid item xs={12}>
                    {buildGetCleanerGroupFeedbacks()}
                </Grid>
            </Grid>
        )
    }

    const [hasMore, setHasMore] = React.useState(true);
    const allItems = [firstPartContent, secondPartContent, thirdPartContent, fourthPartContent];
    const fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        if (page <= allItems.length - 1) {
            setPage((curVal) => curVal + 1);
        } else {
            setHasMore(false);
        }
    };

    const buildGridContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" color="primary" fontWeight={"bold"}>Feedback Analyse</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
                </Grid>
                <Grid item style={{ minHeight: 300 }}>
                    <InfiniteScroll
                        dataLength={page} //This is important field to render the next data
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <Paper style={{ margin: 5 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size={"large"}
                                    startIcon={<ExpandMore />}
                                    //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                                    onClick={async () => {
                                        fetchMoreData();
                                    }}
                                >
                                    Mehr Laden...
                                </Button>
                            </Paper>
                        }
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Ende der Feedbackanalyse erreicht!</b>
                            </p>
                        }
                    // below props only if you need pull down functionality
                    //        refreshFunction={this.refresh}
                    >
                        <Grid container direction={"column"} spacing={2}>
                            {page >= 0 && firstPartContent()}
                            {page >= 1 && secondPartContent()}
                            {page >= 2 && thirdPartContent()}
                            {page >= 3 && fourthPartContent()}
                            {page >= 4 && fifthPartContent()}
                        </Grid>
                    </InfiniteScroll>
                </Grid>
            </Grid>
        )
    }


    const globalFields = {
        isPrintPage: isPrintPage,
        setIsPrintPage: setIsPrintPage,
        isPrintingPage: isPrintingPage,
        setIsPrintingPage: setIsPrintingPage
    }

    return (
        <PrintContext.Provider value={globalFields}>
            {isPrintPage ? buildPrintContent() : buildGridContent()}
        </PrintContext.Provider>
    )
}

export const loadFeedbackData = async (
    setfeedbackDashboardFeedbackAllData: any,
    setfeedbackDashboardFeedbackDataType: any,
    setfeedbackDashboardFeedbackData: any,
    setLoadingCompleted: any,
    aggregateType: AggregateType,
    filterAfterFeedbackDisplayType = false,
    tempGroupFeedbacks: any[] = [],
    setFeedbackTableData: any = null) => {

    if (setFeedbackTableData) {
        setFeedbackTableData([...tempGroupFeedbacks])
    }
    let feedbackChartAllData = [];
    let currentFeedbackDisplayType = JSON.parse(localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString());

    let feedbackChartData;
    if (aggregateType === AggregateType.Time) {
        let allFeedbackTypes = currentFeedbackDisplayType == FeedbackDisplayType.PositiveNegativeNeutral ? getFeebacksTypesThreeSmiles() : getAllFeebacksTypes();
        feedbackChartAllData = generateFeedbacksChartData(tempGroupFeedbacks);
        setfeedbackDashboardFeedbackAllData(feedbackChartAllData);
        feedbackChartData = generateChartData(feedbackChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allFeedbackTypes);
        setfeedbackDashboardFeedbackDataType(allFeedbackTypes);
        setfeedbackDashboardFeedbackData(feedbackChartData);

    } else if (aggregateType === AggregateType.Sum) {
        let allFeedbackTypes = currentFeedbackDisplayType == FeedbackDisplayType.PositiveNegativeNeutral ? getFeebacksTypesThreeSmiles() : getAllFeebacksTypes();
        feedbackChartAllData = generateFeedbacksChartDataSum(tempGroupFeedbacks);
        if (filterAfterFeedbackDisplayType && currentFeedbackDisplayType == FeedbackDisplayType.PositiveNegativeNeutral) {
            feedbackChartAllData = feedbackChartAllData.filter((el: any) => {
                return !(el.key == "veryNegativeAmount" || el.key == "veryPositiveAmount")
            });
        }


        feedbackChartAllData = mapChartKeyFeedback(feedbackChartAllData);

        setfeedbackDashboardFeedbackAllData(feedbackChartAllData);
        feedbackChartData = generateSumChartData(feedbackChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allFeedbackTypes);
        setfeedbackDashboardFeedbackDataType(allFeedbackTypes);
        //   setfeedbackDashboardFeedbackData(feedbackChartData);

    }

    setLoadingCompleted(true);
    return feedbackChartAllData;

}

export const loadFeedbackMotionCombData = async (
    seFeedbackMotionCombkAllData: any,
    sefeedbackDashboardFeedbackCombDataFields: any,
    setfeedbackDashboardMotionCombDataFields: any,
    seFeedbackMotionCombkChartData: any,
    setLoadingCompleted: any,
    tempGroupFeedbacks: GroupFeedback[] = [],
    tempGroupMotion: GroupMotion[]) => {

    let feedbackChartAllData: any[] = [];
    let motionChartAllData: any[] = [];

    let currenFeedbackMotionCombkDisplayType = JSON.parse(localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString());

    let chartData;
    let allFeedbackTypes = currenFeedbackMotionCombkDisplayType == FeedbackDisplayType.PositiveNegativeNeutral ? getFeebacksTypesThreeSmiles() : getAllFeebacksTypes();
    sefeedbackDashboardFeedbackCombDataFields(allFeedbackTypes);
    setfeedbackDashboardMotionCombDataFields(getAllMotionTypes());
    feedbackChartAllData = generateFeedbacksChartData(tempGroupFeedbacks);
    motionChartAllData = generateMotionsChartData(tempGroupMotion);
    let allData = [...motionChartAllData, ...feedbackChartAllData];
    if (!allData) allData = [];
    seFeedbackMotionCombkAllData(allData);
    chartData = generateChartData(allData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, []);
    seFeedbackMotionCombkChartData(chartData);

    setLoadingCompleted(true);
    return chartData;

}

export const getFeedbackColorsSum = () => {
    let currentFeedbackDisplayType = JSON.parse(localStorage.getItem(FEEDBACK_DISPLAY_TYPE_CHART) || FeedbackDisplayType.PositiveNegativeNeutral.toString());
    return currentFeedbackDisplayType == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? ["#FF0000", "#0EAD1C", "#ff9d14", "#05ff86", '#d4d008'] : ["#FF0000", "#0EAD1C", "#d4d008"];
}

export const loadCleanerFeedbackData = async (groupId: string,
    setfeedbackDashboardFeedbackAllData: any,
    setfeedbackDashboardFeedbackDataType: any,
    setfeedbackDashboardFeedbackData: any,
    setLoadingCompleted: any,
    aggregateType: AggregateType,
    setCleanerFeedbackTableData: any = null,
    tempGroupFeedbacks: any[] = []) => {

    if (setCleanerFeedbackTableData) {
        setCleanerFeedbackTableData([...tempGroupFeedbacks])
    }

    if (aggregateType === AggregateType.Time) {
        let allFeedbackTypes = getAllCleanerFeebacksTypes();
        let feedbackChartAllData = generateFeedbacksChartData(tempGroupFeedbacks);
        setfeedbackDashboardFeedbackAllData(feedbackChartAllData);
        let feedbackChartData = generateChartData(feedbackChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allFeedbackTypes);
        setfeedbackDashboardFeedbackDataType(allFeedbackTypes);
        setfeedbackDashboardFeedbackData(feedbackChartData);

    } else if (aggregateType === AggregateType.Sum) {
        let allFeedbackTypes = getAllCleanerFeebacksTypes();
        let feedbackChartAllData = generateFeedbacksChartDataSum(tempGroupFeedbacks);
        feedbackChartAllData = mapChartKeyFeedback(feedbackChartAllData);
        setfeedbackDashboardFeedbackAllData(feedbackChartAllData);
        let feedbackChartData = generateSumChartData(feedbackChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allFeedbackTypes);
        setfeedbackDashboardFeedbackDataType(allFeedbackTypes);
        setfeedbackDashboardFeedbackData(feedbackChartData);

    }

    setLoadingCompleted(true);
}

export const loadIncidentVisitorData = async (
    tempGroupIncidentsVisitor: any[],
    setIncidentVisitorAllData: any,
    setIncidentVisitorDataType: any,
    setIncidentVisitorData: any,
    setLoadingCompleted: any,
    aggregateType: AggregateType,
    incidentVisitorType: IncidentVisitorChartType) => {



    if (aggregateType === AggregateType.Time) {
        let allIncidentVisitorTypes = getAllIncidentVisitor(tempGroupIncidentsVisitor, incidentVisitorType);
        let incidentVisitorChartAllData = generateIncidentVisitorChartData(tempGroupIncidentsVisitor as any[], incidentVisitorType);
        setIncidentVisitorAllData(incidentVisitorChartAllData);
        let incidentVisitorChartData = generateChartData(incidentVisitorChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allIncidentVisitorTypes);
        setIncidentVisitorDataType(allIncidentVisitorTypes);
        setIncidentVisitorData(incidentVisitorChartData);

    }

    setLoadingCompleted(true);
    return tempGroupIncidentsVisitor;
}

export const loadFeedbackQuestionCountData = async (tempGroupFeedbacks: GroupFeedbackQuestion[],
    setfeedbackDashboardFeedbackAllData: any,
    setfeedbackDashboardFeedbackDataType: any,
    setfeedbackDashboardFeedbackData: any,
    setLoadingCompleted: any) => {

    let allFeedbackTypes = ["negativeCountFeedbackQuestion", "positiveCountFeedbackQuestion"];
    let feedbackChartAllData = generateFeedbackQuestionCountChartData(tempGroupFeedbacks);
    setfeedbackDashboardFeedbackAllData(feedbackChartAllData);
    let feedbackChartData = generateChartData(feedbackChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allFeedbackTypes);
    setfeedbackDashboardFeedbackDataType(allFeedbackTypes);
    setfeedbackDashboardFeedbackData(feedbackChartData);

    setLoadingCompleted(true);
    return tempGroupFeedbacks;
}

export const loadFeedbackQuestionData = async (tempGroupFeedbacks: GroupFeedbackQuestion[],
    setfeedbackDashboardFeedbackAllData: any,
    setfeedbackDashboardFeedbackDataType: any,
    setfeedbackDashboardFeedbackData: any,
    setLoadingCompleted: any,
    aggregateType: AggregateType) => {


    if (aggregateType === AggregateType.Time) {
        let allFeedbackTypes = getAllFeedbackQuestions(tempGroupFeedbacks);
        let feedbackChartAllData = generateFeedbackQuestionChartData(tempGroupFeedbacks);
        setfeedbackDashboardFeedbackAllData(feedbackChartAllData);
        let feedbackChartData = generateChartData(feedbackChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allFeedbackTypes);
        setfeedbackDashboardFeedbackDataType(allFeedbackTypes);
        setfeedbackDashboardFeedbackData(feedbackChartData);

    } else if (aggregateType === AggregateType.Sum) {
        let allFeedbackTypes = getAllFeedbackQuestions(tempGroupFeedbacks);
        let feedbackChartAllData = generateFeedbackQuestionSumChartData(tempGroupFeedbacks);
        feedbackChartAllData = mapChartKeyFeedback(feedbackChartAllData);
        setfeedbackDashboardFeedbackAllData(feedbackChartAllData);
        let feedbackChartData = generateSumChartData(feedbackChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allFeedbackTypes);
        setfeedbackDashboardFeedbackDataType(allFeedbackTypes);
        setfeedbackDashboardFeedbackData(feedbackChartData);

    }

    setLoadingCompleted(true);
    return tempGroupFeedbacks;
}

export enum FeedbackQuestionTextType {
    StandardText = "0",
    FreeText = "1"
}

export const BuildGroupFeedbackQuestionsSimple = React.forwardRef(({ feedbackQuestionTableData, groupsLicenseTableEntries, title, color }: { feedbackQuestionTableData: GroupFeedbackQuestion[], groupsLicenseTableEntries: GroupLicenseTable[], title: string, color?: string }, ref: any) => {

    useImperativeHandle(ref, () => ({
        updateStartDateFeedbackQuestionSimpleData(date: Date) {
            updateStartDateFeedbackQuestionSimpleData(date);
        },
        updateEndDateFeedbackQuestionSimpleData(date: Date) {
            updateEndDateFeedbackQuestionSimpleData(date);
        }

    }));


    const history = useHistory();
    const queryString = useQuery(history);

    const [feedbackQuestionType, setFeedbackQuestionType] = React.useState(FeedbackQuestionTextType.StandardText);


    const [startDateFeedbackQuestionSimple, setStartDateFeedbackQuestionSimple] = React.useState(subDays(new Date(), 10));
    const [endDateFeedbackQuestionSimple, setEndDateFeedbackQuestionSimple] = React.useState(new Date());

    const [chartHeightFeedbackQuestionSimple, setChartHeightFeedbackQuestionSimple] = React.useState(300);
    const [feedbackQuestionSimpleChartData, setFeedbackQuestionSimpleChartData] = React.useState<any[]>([]);
    const [feedbackQuestionSimpleChartDataBad, setFeedbackQuestionSimpleChartDataBad] = React.useState<any[]>([]);

    const [simpleChartDataAvgAll, setSimpleChartDataAvgAll] = React.useState(0);
    const [simpleChartDataAvg, setSimpleChartDataAvg] = React.useState(0);
    const [simpleChartDataAvgBad, setSimpleChartDataAvgBad] = React.useState(0);

    const [filteredSimpleChartDataCount, setFilteredSimpleChartDataCount] = React.useState(0);
    const {t} = useTranslation();

    const refPrint = React.useRef(null);
    const { isPrintPage, isPrintingPage }: { isPrintPage: boolean, isPrintingPage: { print: boolean, resolve: any } } = useContext(PrintContext);


    const { maxChartItemCount }: { maxChartItemCount: number } = React.useContext(GlobalContext);

    React.useEffect(() => {
        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        if (feedbackQuestionTableData) {
            setStartDateFeedbackQuestionSimple(startDate);
            setEndDateFeedbackQuestionSimple(endDate);
            updateFeedbackQuestionSimpleChart(startDate, endDate, feedbackQuestionTableData);
        }
    }, [feedbackQuestionTableData, groupsLicenseTableEntries])

    React.useEffect(() => {
        updateEndDateFeedbackQuestionSimpleData(endDateFeedbackQuestionSimple);
    }, [maxChartItemCount]);

    const updateStartDateFeedbackQuestionSimpleData = (newVal: Date) => {
        if (!feedbackQuestionTableData) return;
        setStartDateFeedbackQuestionSimple(newVal);
        updateFeedbackQuestionSimpleChart(newVal, endDateFeedbackQuestionSimple, feedbackQuestionTableData);
    }

    const updateEndDateFeedbackQuestionSimpleData = (newVal: Date) => {
        if (!feedbackQuestionTableData) return;
        setEndDateFeedbackQuestionSimple(newVal);
        updateFeedbackQuestionSimpleChart(startDateFeedbackQuestionSimple, newVal, feedbackQuestionTableData);
    }

    const updateFeedbackQuestionSimpleChart = (startDate: Date, endDate: Date, feedbackQuestionAllData: GroupFeedbackQuestion[], type: FeedbackQuestionTextType = FeedbackQuestionTextType.StandardText) => {
        let feedbackQuestions = feedbackQuestionAllData.filter(x => new Date(x.createdTimestampString) >= startDate && new Date(x.createdTimestampString) <= endDate);
        let res = feedbackQuestions.reduce((sumValue, newValue: GroupFeedbackQuestion) => {
            let newItems = type == FeedbackQuestionTextType.StandardText ? newValue.items : newValue.customTexts;
            if (newItems) {
                for (let text of newItems) {
                    let entryExists = sumValue.find((x: any) => x.argumentField == text);
                    if (entryExists) {
                        entryExists.valueField += 1;
                    } else {
                        sumValue.push({ argumentField: text, valueField: 1 });
                    }
                }
            }


            return sumValue;
        }, [] as any[]);
        setFilteredSimpleChartDataCount(res.length);

        res = res.sort((a, b) => a.valueField - b.valueField);
        setFeedbackQuestionSimpleChartData(res.slice(-maxChartItemCount));
        setFeedbackQuestionSimpleChartDataBad(res.slice(0, maxChartItemCount).reverse());


        setSimpleChartDataAvgAll(getAvgFromSimpleChartValueField(res))
        setSimpleChartDataAvg(getAvgFromSimpleChartValueField(res.slice(-maxChartItemCount)))
        setSimpleChartDataAvgBad(getAvgFromSimpleChartValueField(res.slice(0, maxChartItemCount).reverse()))

    }

    const actualCount = feedbackQuestionSimpleChartData.length < feedbackQuestionSimpleChartDataBad.length ? feedbackQuestionSimpleChartDataBad.length : feedbackQuestionSimpleChartData.length;


    const buildContent = () => {
        return (
            <Paper className="paper-small">
                <Grid item>
                    <Typography style={{ textAlign: 'center', marginBottom: 10 }} fontWeight="bold" variant="h4" color={"primary"}>{title} Gesamt Bericht</Typography>
                    <Typography className="avg-font" variant="h5">Durchschnitt von {filteredSimpleChartDataCount} {title} : <b>{simpleChartDataAvgAll} Antworten</b></Typography>

                </Grid>
                <Grid item>
                    <FormControl component="fieldset" >
                        <FormLabel component="legend">{title} Art</FormLabel>
                        <RadioGroup
                            row
                            name="feedbackQuestionType"
                            value={feedbackQuestionType}
                            onChange={(event) => {
                                setFeedbackQuestionType(event.target.value as any);
                                updateFeedbackQuestionSimpleChart(startDateFeedbackQuestionSimple, endDateFeedbackQuestionSimple, feedbackQuestionTableData, event.target.value as any);
                            }}
                        >
                            <FormControlLabel value={FeedbackQuestionTextType.StandardText} control={<Radio />} label="Vorgefertigter Text" />
                            <FormControlLabel value={FeedbackQuestionTextType.FreeText} control={<Radio />} label="Freitexte" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item>
                        < DatePicker
                            disableFuture
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={t("serviceDocumentationTable.startDate")}
                            value={startDateFeedbackQuestionSimple}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                updateStartDateFeedbackQuestionSimpleData(newVal);
                            }}
                            format={"dd.MM.yyyy"}

                        />
                    </Grid>
                    <Grid item>
                        < DatePicker
                            disableFuture
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label={t("serviceDocumentationTable.endDate")}
                            value={endDateFeedbackQuestionSimple}
                            onChange={(newVal) => {
                                if (!newVal) return;
                                updateEndDateFeedbackQuestionSimpleData(newVal);

                            }}
                            format={"dd.MM.yyyy"}

                        />
                    </Grid>
                    <Grid item>
                        <ChartHeightTextBox actualCount={actualCount} chartHeight={chartHeightFeedbackQuestionSimple} setChartHeight={setChartHeightFeedbackQuestionSimple} />

                    </Grid>
                    <MaxChartItemCountInput />

                </Grid>
                <Grid item container direction={"row"} spacing={3}>
                    <Grid container direction={"column"} spacing={3} item xs={6}>
                        <Grid item>
                            <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Anzahl {title} Absteigend</Typography>
                            <Typography className="avg-font" variant="h5">Durchschnitt von {feedbackQuestionSimpleChartData.length} {title}: <b>{simpleChartDataAvg} Antworten</b></Typography>
                        </Grid>
                        <Grid item>
                            <StandardBarChartSimpleColor color={color || blue[600]} avgVal={simpleChartDataAvg} valueFieldService="valueField" argumentFieldService="argumentField" dataSource={feedbackQuestionSimpleChartData} name={`${title} Gesamt`} height={chartHeightFeedbackQuestionSimple} extraLabel=" Antworten" />
                        </Grid>
                    </Grid>
                    <Grid container direction={"column"} spacing={3} item xs={6}>
                        <Grid item>
                            <Typography style={{ textAlign: 'center', marginBottom: 10 }} variant="h4" color={"primary"}>Anzahl {title} Aufsteigend</Typography>
                            <Typography className="avg-font" variant="h5">Durchschnitt von {feedbackQuestionSimpleChartData.length} {title}: <b>{simpleChartDataAvgBad} Antworten</b></Typography>
                        </Grid>
                        <Grid item>
                            <StandardBarChartSimpleColor color={color || red[600]} avgVal={simpleChartDataAvgBad} valueFieldService="valueField" argumentFieldService="argumentField" dataSource={feedbackQuestionSimpleChartDataBad} name={"${title} Gesamt Schlecht"} height={chartHeightFeedbackQuestionSimple} extraLabel=" Antworten" />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    const buildPrintContent = () => {
        const titleDesc = `<b>Anzahl ${title} Absteigend</b><br />${formatDateRange(startDateFeedbackQuestionSimple, endDateFeedbackQuestionSimple)}`;
        const subTitleDesc = `Durchschnitt von ${feedbackQuestionSimpleChartData.length} ${title}: <b>${simpleChartDataAvg} Antworten</b>`

        const titleAsc = `<b>Anzahl ${title} Aufsteigend</b><br />${formatDateRange(startDateFeedbackQuestionSimple, endDateFeedbackQuestionSimple)}`
        const subTitleAsc = `Durchschnitt von ${feedbackQuestionSimpleChartData.length} ${title}: <b>${simpleChartDataAvgBad} Antworten</b>`

        return (
            <>
                <PrintButton title="Feedback Qualität pro Einheit Drucken" pageRef={refPrint} />
                <div ref={refPrint} style={{ display: 'inline-block', width: '100%' }}>
                    <ChartHeightTextBox chartHeight={chartHeightFeedbackQuestionSimple} setChartHeight={setChartHeightFeedbackQuestionSimple} display={false} actualCount={actualCount} />
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvg} valueFieldService="valueField" argumentFieldService="argumentField" color={blue[600]} dataSource={feedbackQuestionSimpleChartData} name={`${title} Gesamt`} height={chartHeightFeedbackQuestionSimple} extraLabel=" Antworten" title={titleDesc} subTitle={subTitleDesc} />
                    </div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <StandardBarChartSimpleColor avgVal={simpleChartDataAvgBad} valueFieldService="valueField" argumentFieldService="argumentField" color={red[600]} dataSource={feedbackQuestionSimpleChartDataBad} name={`${title} Gesamt Schlecht`} height={chartHeightFeedbackQuestionSimple} extraLabel=" Antworten" title={titleAsc} subTitle={subTitleAsc} />
                    </div>
                </div>
            </>

        )
    }

    if (!feedbackQuestionTableData) return <></>

    return (
        <>
            {isPrintPage ? buildPrintContent() : buildContent()}
        </>
    )
});

