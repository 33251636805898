import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Delete, Download, Mail, Save, UploadFile } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { api } from '../../../../../api';
import { getApiToken } from '../../../../../helpers/storage-management';
import { StringHelper } from '../../../../../helpers/string-helper';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { usePortalAccountStyles } from '../../../../../styles/usePortalAccountStyles';
import { DefaultDialogTitle } from '../../../../dialogs/DefaultDialogTitle';
import { GroupServiceModeIncidentPhoto } from '../../../../../models/GroupServiceMode';
import { useTranslation } from 'react-i18next';

export const IncidentImagesDialog = ({ visible, setVisible, groupId, incidentPhotos }
    : { visible: boolean, setVisible: any, groupId: string, incidentPhotos: GroupServiceModeIncidentPhoto[] }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [images, setImages] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    const history = useHistory();


    const onClose = () => {
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

        (async () => {


            const tokenTemp = getApiToken(history);
            setLoading(true);
            let photos = await Promise.all(incidentPhotos.map((async (x) => {
                return { text: x.description, image: await api.downloadGroupIncidentImage(tokenTemp, groupId, x.fileName) };
            })));
            setLoading(false);
            setImages(photos);

        })();

    }, [visible])

    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="xl">
            <DefaultDialogTitle
                title="Vorfallmeldungfotos"
                handleClose={onClose}
            />

            <DialogContent style={{minHeight: 300}}>
                {loading ?
                    <Grid style={{width: '100%'}} container direction={"column"} justifyContent={"center"} justifyItems={"center"} alignItems={"center"} alignContent={"center"}>
                        <Grid item>
                            <CircularProgress style={{padding: 20}} size={200} />
                        </Grid>
                    </Grid> :
                    <Grid container direction="row" className={classes.container}>
                        <Grid item>
                            <ImageList cols={3}>
                                {images.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={item.image}
                                            loading="lazy"
                                            style={{ width: '100%', height: 300, objectFit: 'contain' }}
                                        />
                                        <ImageListItemBar
                                            title={`Vorfall ${index + 1}`}
                                            subtitle={item.text}
                                            actionIcon={
                                                <>
                                                    <Button
                                                        size="small"
                                                        startIcon={<Download />}
                                                        variant={'contained'}
                                                        onClick={()=>{
                                                            var link = document.createElement("a"); // Or maybe get it from the current document
                                                            link.href = item.image;
                                                            link.download = `Vorfall ${index + 1}.jpeg`;
                                                            link.click();
                                                        }}
                                                        color="primary">
                                                        Download Bild
                                                    </Button>
                                                </>
                                            }

                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
