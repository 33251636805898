import React, { useCallback, useContext } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { GroupSettings, GroupSettingsType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { GroupSettingFieldsContent } from "./GroupSettingFields.Content";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { useContainerStyles } from "../../styles/container";
import { GroupSettingFieldsLocation } from "./GroupSettingFieldsLocation";
import { useHistory } from "react-router-dom";
import { getApiToken } from "../../helpers/storage-management";
import { useSnackbar } from "notistack";
import { GroupSettingsFieldsGeneralInformation } from './GroupSettingFieldsGeneralInformation';
import { GroupSettingFieldsNotificationSettings } from './GroupSettingFieldsNotificationSettings';
import { GroupSettingFieldsFeedbackControl } from './GroupSettingFieldsFeedbackControl';
import { GroupSettingFieldsCleaner } from './GroupSettingFieldsCleaner';
import { GroupSettingFieldsCleaningPerformance } from './GroupSettingFieldsCleaningPerformance';
import { GroupSettingFieldsSensorControl } from './GroupSettingFieldsSensorControl';
import { GroupSettingFieldsIntervalControl, IntervalType } from './intervalControl/GroupSettingFieldsIntervalControl';
import { GroupSettingFieldsMaterialConsumption } from './GroupSettingFieldsMaterialConsumption';
import { GroupSettingFieldsIncidenReports } from './GroupSettingFieldsIncidenReports';
import { ro } from "date-fns/locale";
import { Group, GroupType } from "../../models/Group";
import { api } from "../../api";
import { GroupLicense } from "../../models/GroupLicense";
import { ObjectUnit } from "../../models/ObjectUnit";
import { UserProperty } from "../../models/UserProperty";
import IconCMN from '../../assets/svgs/check-me-now_icon.png'
import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { GroupSettingFieldsAdmin } from "./GroupSettingFieldsAdmin";
import { GlobalContext, IntervalContext, SettingsContext } from "../authorized/PortalPage";
import { UserAccount } from "../../models/UserAccount";
import { UserType } from "../../models/UserType";
import { GroupSettingsExtended } from "../../models/GroupSettingsExtended";
import { GroupSettingFieldsBssd } from "./GroupSettingFieldsBssd";
import { GroupSettingFieldsLicenseInformation } from "./GroupSettingFieldsLicenseInformation";
import { GroupSettingFieldsLoraSensorControl } from "./GroupSettingFieldsLoraSensorControl";
import { GroupSettingFieldsPayPerService } from "./GroupSettingFieldsPayPerService";
import { GroupSettingFieldsMaterialStock } from "./GroupSettingFieldsMaterialStock";
import { makeValidAppointments } from "../../helpers/common";
import { GroupSettingFieldsQmControl } from "./GroupSettingFieldsQmControl";
import { StandardWorkItem, StandardWorkItemType } from "../../models/StandardItem";
import { FileBlobType } from "../../models/FileBlobType";
import { getDefaultIcon } from "../dialogs/DefaultItemsDialogOld";
import { LocalMedia } from "../images/NetworkMedia";


export const GroupSettingFields = ({ row, onChange, type, group, loadData }
    : { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, type: GroupSettingsType, group: Group, loadData: any }) => {

    const classes = GroupSettingFieldsUseStyles();
    const containerStyles = useContainerStyles();
    const history = useHistory();

    const [token, setToken] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();

    const { userAccount }: { userAccount: UserAccount } = useContext(GlobalContext);

    const [isLoadingSendCode, setIsLoadingSendCode] = React.useState(false);
    const [freeSensorLicenses, setFreeSensorLicenses] = React.useState<GroupLicense[]>([]);
    const [currentObjectUnit, setCurrentObjectUnit] = React.useState<ObjectUnit>();
    const [properties, setProperties] = React.useState<UserProperty[]>();
    const [qrCode, setQrCode] = React.useState<any>();
    const [logo, setLogo] = React.useState<any>();
    const [myAppointments, setMyAppointments] = React.useState<AppointmentModel[]>([]);
    const [standardServices, setStandardServices] = React.useState<StandardWorkItem[]>([]);
    const [standardRoomTypes, setStandardRoomTypes] = React.useState<StandardWorkItem[]>([]);

    const refreshLogo = async () => {
        let logo = await api.downloadLogoImageAsync(row as GroupSettings, token);
        if (!logo) logo = IconCMN;
        setLogo(logo);
    }


    const reloadObject = async (row: any) => {
        if (row.locationInformation && row.locationInformation.objectUnitId) {
            let tempObject = await api.getUserObjectUnit(row.locationInformation.objectUnitId, token);
            setCurrentObjectUnit(tempObject);
        }
    }

    const reloadProps = async (row: any) => {
        if (row.locationInformation && row.locationInformation.userPropertyIds) {
            let allProperties = await Promise.all(row.locationInformation.userPropertyIds.map(async (x: any) => {
                let prop = await api.getUserProperty(x, token);
                prop.id = x;
                return prop;
            }))
            setProperties(allProperties);
        }
    }

    React.useEffect(() => {
        const token = getApiToken(history);
        if (!token) return;
        setToken(token);
        (async () => {
            if (!row.id) return;
            if (row.intervalControl) {
                if (!row.intervalControl?.calendarEntries) row.intervalControl.calendarEntries = [];
                setMyAppointments(makeValidAppointments(row.intervalControl.calendarEntries));

            }

            if (type == GroupSettingsType.Default) {
                //        if (group.groupType == GroupType.Checkpoint) {
                let code = await api.downloadQrCodeImageAsync(token);
                setQrCode(code);
                let logo = await api.downloadLogoImageAsync(row as GroupSettings, token);
                if (!logo) logo = IconCMN;
                setLogo(logo);
                //       }
            }

            if (row.locationInformation && row.locationInformation.objectUnitId) {
                let tempObject = await api.getUserObjectUnit(row.locationInformation.objectUnitId, token);

                setCurrentObjectUnit(tempObject);
            }

            if (row.locationInformation && row.locationInformation.userPropertyIds) {
                let allProperties = await Promise.all(row.locationInformation.userPropertyIds.map(async (x) => {
                    let prop = await api.getUserProperty(x, token);
                    prop.id = x;
                    return prop;
                }))
                setProperties(allProperties);
            }



        })()
    }, []);

    const loadFreeSensorLicenses = async () => {
        if (group.groupType == GroupType.DisplayWallApp) {
            const licenses = await api.getLicensesAsync(token);
            setFreeSensorLicenses(licenses.filter(el => !el.isUsed && el.sensorCount > 0));
        } else {
            const licenses = await api.getLicensesAsync(token);
            setFreeSensorLicenses(licenses.filter(el => !el.isUsed && el.batterySmartServiceDisplaySensorCount > 0));
        }
    }

    const loadStandardServices = React.useCallback(async () => {
        let res = await api.GetStandardWorkItemsByType(token, StandardWorkItemType.Serivce);
        setStandardServices(res);
        for (let item of row.cleaningOperations?.cleaningOperations || []) {
            let curPhotoToDownload = res.find(x => x.id == item.standardItemId);
            if (curPhotoToDownload)
                curPhotoToDownload.downloadedPhotoUrl = await api.DownloadUserFile(curPhotoToDownload?.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, curPhotoToDownload?.photos[0]?.fileName, curPhotoToDownload?.photos[0]?.contentType, true)

        }
        setStandardServices([...res]);
    }, [row]);

    const loadStandardRoomTypes = React.useCallback(async () => {
        let res = await api.GetStandardWorkItemsByType(token, StandardWorkItemType.RoomType);
        setStandardRoomTypes(res);
    }, [row]);

    const buildStandardItemIcon = (currentStandardItem: StandardWorkItem) => {
        if (!currentStandardItem.downloadedPhotoUrl)
            return <CircularProgress />
        if (currentStandardItem.downloadedPhotoUrl.url == "") {
            return getDefaultIcon("");
        }
        return <LocalMedia src={currentStandardItem.downloadedPhotoUrl.url} contentType={currentStandardItem.downloadedPhotoUrl.type} height={50} />
    }

    /*  React.useEffect(() => {
          if (row.notification?.isIncidentReportEmailEnabled)
              onChange({ target: { name: 'isIncidentReportEnabled', value: "false" } });
     
      }, [row.notification?.isIncidentReportEmailEnabled]) */

    const [originUsers, setOriginUsers] = React.useState<any[]>([]);



    return (
        <Grid container spacing={3}>
            <SettingsContext.Provider value={{
                refreshSettings: () => {
                    let res = { target: { name: 'row', value: null } };
                    onChange(res)
                }
            }}>

                <Grid item xs={12}>
                    <GroupSettingsFieldsGeneralInformation
                        row={row}
                        onChange={onChange}
                        type={type}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GroupSettingFieldsLicenseInformation
                        row={row}
                        loadData={loadData}
                        group={group}
                        onChange={onChange}
                        type={type} token={token} enqueueSnackbar={enqueueSnackbar}
                    />
                </Grid>
                {userAccount && userAccount.userType == UserType.Admin &&
                    <Grid item xs={12}>
                        <GroupSettingFieldsAdmin
                            loadData={loadData}
                            row={row}
                            onChange={onChange}
                            group={group}
                            token={token}
                        />
                    </Grid>
                }

                {group && group.groupType == GroupType.DisplayBatteryWallApp &&
                    <Grid item xs={12}>
                        <GroupSettingFieldsBssd
                            loadData={loadData}
                            row={row}
                            onChange={onChange}
                            group={group}
                            token={token}
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <GroupSettingFieldsLocation
                        reloadProps={reloadProps}
                        reloadObject={reloadObject}
                        row={row}
                        onChange={onChange}
                        objectUnit={currentObjectUnit}
                        setObjectUnit={setCurrentObjectUnit}
                        properties={properties}
                        group={group}
                        qrCode={qrCode}
                        logo={logo}
                        type={type}
                        refreshLogo={refreshLogo}
                        token={token}
                        loadStandardRoomTypes={loadStandardRoomTypes}
                        standardRoomTypes={standardRoomTypes}
                        loadData={async () => {
                            const tempGroupSettings: GroupSettingsExtended = await loadData();
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsCleaner
                        group={group}
                        row={row}
                        onChange={onChange}
                        token={token}
                        isLoadingSendCode={isLoadingSendCode}
                        setIsLoadingSendCode={setIsLoadingSendCode}
                        loadData={loadData}
                        originUsers={originUsers}
                        setOriginUsers={setOriginUsers}
                        type={type}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsCleaningPerformance
                        token={token}
                        group={group}
                        row={row}
                        onChange={onChange}
                        loadStandardServices={loadStandardServices}
                        standardServices={standardServices}
                        buildStandardItemIcon={buildStandardItemIcon}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsQmControl
                        currentObjectUnit={currentObjectUnit}
                        token={token}
                        row={row}
                        onChange={onChange}
                        loadStandardServices={loadStandardServices}
                        standardServices={standardServices}
                        standardRoomType={standardRoomTypes.find(x => x.text == row.locationInformation?.roomType)}
                        buildStandardItemIcon={buildStandardItemIcon}
                        loadStandardRoomTypes={loadStandardRoomTypes}
                        standardRoomTypes={standardRoomTypes}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsNotificationSettings
                        group={group}
                        row={row}
                        type={type}
                        onChange={onChange}
                        token={token}
                        enqueueSnackbar={enqueueSnackbar}
                        isLoadingSendCode={isLoadingSendCode}
                        setIsLoadingSendCode={setIsLoadingSendCode}
                        originUsers={originUsers}
                        setOriginUsers={setOriginUsers}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsLoraSensorControl
                        row={row}
                        group={group}
                        onChange={onChange}
                        freeSensorLicenses={freeSensorLicenses}
                        loadFreeSensorLicenses={loadFreeSensorLicenses}
                        type={type}
                    />
                </Grid>

                {(group?.groupType == GroupType.DisplayWallApp || group.groupType == GroupType.DisplayBatteryWallApp) &&

                    <Grid item xs={12}>
                        <GroupSettingFieldsSensorControl
                            row={row}
                            group={group}
                            onChange={onChange}
                            freeSensorLicenses={freeSensorLicenses}
                            loadFreeSensorLicenses={loadFreeSensorLicenses}
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <GroupSettingFieldsFeedbackControl
                        token={token}
                        group={group}
                        row={row}
                        onChange={onChange}
                        type={type}
                    />
                </Grid>

                <Grid item xs={12}>
                    {row.intervalControl &&
                        <IntervalContext.Provider value={{
                            intervalType: IntervalType.Group,
                            refreshSettings: () => {
                                let res = { target: { name: 'row', value: null } };
                                onChange(res)
                            }
                        }}>
                            <GroupSettingFieldsIntervalControl
                                row={row}
                                services={row.cleaningOperations?.cleaningOperations || []}
                                intervalControl={row.intervalControl}
                                onChange={onChange}
                                setMyAppointments={setMyAppointments}
                                myAppointments={myAppointments}
                            />
                        </IntervalContext.Provider>
                    }
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsMaterialConsumption
                        token={token}
                        row={row}
                        onChange={onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsMaterialStock
                        currentObjectUnit={currentObjectUnit}
                        token={token}
                        row={row}
                        onChange={onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsIncidenReports
                        token={token}
                        group={group}
                        row={row}
                        onChange={onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GroupSettingFieldsPayPerService
                        token={token}
                        group={group}
                        row={row}
                        onChange={onChange}
                    />
                </Grid>

                {group?.groupType == GroupType.DisplayWallApp &&

                    <Grid item xs={12}>
                        <GroupSettingFieldsContent row={row}
                            onChange={onChange}
                            group={group}
                            type={type}
                        />
                    </Grid>
                }
            </SettingsContext.Provider>
        </Grid>
    );
}