import { GREEN_SMILE, RED_SMILE, YELLOW_SMILE } from "../../../assets";
import { GroupFeedback } from "../../../models/GroupFeedback";
import { GroupLicenseTable } from "../../../models/GroupLicenseTable";
import { MarkedMaterial, MarkedService, ObjectLeaderQualityCheckModel } from "../../../models/ObjectLeaderQualityCheckModel";
import { StringHelper } from "../../string-helper";

export const getSmileText = (veryNegative: number, negative: number, neutral: number, positive: number, veryPositive: number) => {
    if (veryNegative > 0) {
        return "Sehr Negativ";
    } else if (negative > 0) {
        return "Negativ";
    } else if (neutral > 0) {
        return "Neutral";
    } else if (positive > 0) {
        return "Positiv";
    } else if (veryPositive > 0) {
        return "Sehr Positiv";
    } else {
        return "Nichts"
    }
}

export const getSmileValue = (veryNegative: number, negative: number, neutral: number, positive: number, veryPositive: number) => {
    if (veryNegative > 0) {
        return 25;
    } else if (negative > 0) {
        return 25;
    } else if (neutral > 0) {
        return 75;
    } else if (positive > 0) {
        return 100;
    } else if (veryPositive > 0) {
        return 100;
    }
    return 0;
}

export const getSmileValueWeighting = (veryNegative: number, negative: number, neutral: number, positive: number, veryPositive: number) => {
    if (veryNegative > 0) {
        return 0;
    } else if (negative > 0) {
        return 40;
    } else if (neutral > 0) {
        return 75;
    } else if (positive > 0) {
        return 80;
    } else if (veryPositive > 0) {
        return 100;
    }
    return 0;
}

export const getSmileForSumFeedbackTable = (val: any, height = 20) => {

    if (val >= 80) {
        return (
            <img src={GREEN_SMILE} height={height} />
        );
    }
    else if (val >= 68) {
        return (
            <img src={YELLOW_SMILE} height={height} />
        );
    }
    return (
        <img src={RED_SMILE} height={height} />
    );
}

export const filterAwayNonEntryQualityCheck = (item: MarkedService | MarkedMaterial) => {
    if (item.veryNegativeAmount > 0) {
        return true;
    } else if (item.negativeAmount > 0) {
        return true;
    } else if (item.neutralAmount > 0) {
        return true;
    } else if (item.positiveAmount > 0) {
        return true;
    } else if (item.veryPositiveAmount > 0) {
        return true;
    }
    return false;
}

export const getAllIncidentsByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[]) => {
    let allIncidents = qualityChecks.reduce((acc: any[], curVal: ObjectLeaderQualityCheckModel) => {
        let res = curVal.incidents.filter(el => !acc.includes(el));
        return [...acc, ...res];
    }, []);
    return allIncidents;
}

export const generateIncidentReportChartDataByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[]) => {
    let arr = qualityChecks.map(el => {
        let incidents = el.incidents.reduce((acc: any, curVal: any) => {
            return { ...acc, [curVal]: 1 }
        }, {});
        return { date: el.createdTimestamp, ...incidents }
    });
    return arr;
}


const getTextFromMarkedService = (x: MarkedService) => {
    return `${x.cleaningOperationText} ${getSmileText(x.veryNegativeAmount, x.negativeAmount, x.neutralAmount, x.positiveAmount, x.veryPositiveAmount)}`
}

export const getValueFromMarkedService = (x: MarkedService | MarkedMaterial) => {
    return getSmileValue(x.veryNegativeAmount, x.negativeAmount, x.neutralAmount, x.positiveAmount, x.veryPositiveAmount);
}

export const getValueFromGroupFeedback = (x: GroupFeedback) => {
    return getSmileValue(x.veryNegativeAmount, x.negativeAmount, x.neutralAmount, x.positiveAmount, x.veryPositiveAmount);
}

export const getAllServicesByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[]) => {
    let allServices = qualityChecks.reduce((acc: string[], curVal: ObjectLeaderQualityCheckModel) => {
        let res = curVal.markedServices.filter(el => !acc.includes(getTextFromMarkedService(el))).map(x => getTextFromMarkedService(x));
        return [...acc, ...res];
    }, []);
    return allServices;
}

export const generateServicesChartDataByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[]) => {
    let arr = qualityChecks.map(el => {
        let services = el.markedServices.reduce((acc: any, curVal: MarkedService) => {
            return { ...acc, [getTextFromMarkedService(curVal)]: 1 }
        }, {});
        return { date: el.createdTimestamp, ...services }
    });
    return arr;
}

//average service

export const getAllAverageServicesByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[]) => {
    let allServices = qualityChecks.reduce((acc: string[], curVal: ObjectLeaderQualityCheckModel) => {
        let res = curVal.markedServices.filter(el => !acc.includes(el.cleaningOperationText)).map(x => x.cleaningOperationText);
        return [...acc, ...res];
    }, []);
    return allServices;
}

export const generateAverageServicesChartDataByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[], allServices: string[]) => {
    let arr = qualityChecks.map(el => {
        let services = el.markedServices.filter(x => filterAwayNonEntryQualityCheck(x)).reduce((acc: any, curVal: MarkedService) => {
            return { ...acc, [curVal.cleaningOperationText]: getValueFromMarkedService(curVal) }
        }, {});
        return { date: el.createdTimestamp, ...services }
    });
    return arr;
}


const getTextFromMarkedMaterial = (x: MarkedMaterial) => {
    return `${x.materialText} ${getSmileText(x.veryNegativeAmount, x.negativeAmount, x.neutralAmount, x.positiveAmount, x.veryPositiveAmount)}`
}

export const getAllMaterialsByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[]) => {
    let allMaterials = qualityChecks.reduce((acc: string[], curVal: ObjectLeaderQualityCheckModel) => {
        let res = curVal.markedMaterials.filter(el => !acc.includes(getTextFromMarkedMaterial(el))).map(x => getTextFromMarkedMaterial(x));
        return [...acc, ...res];
    }, []);
    return allMaterials;
}

export const generateMaterialsChartDataByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[]) => {
    let arr = qualityChecks.map(el => {
        let materials = el.markedMaterials.reduce((acc: any, curVal: MarkedMaterial) => {
            return { ...acc, [getTextFromMarkedMaterial(curVal)]: 1 }
        }, {});
        return { date: el.createdTimestamp, ...materials }
    });
    return arr;
}

//average

export const getAllAverageMaterialsByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[]) => {
    let allServices = qualityChecks.reduce((acc: string[], curVal: ObjectLeaderQualityCheckModel) => {
        let res = curVal.markedMaterials.filter(el => !acc.includes(el.materialText)).map(x => x.materialText);
        return [...acc, ...res];
    }, []);
    return allServices;
}

export const generateAverageMaterialsChartDataByObjectLeaderQualityChecks = (qualityChecks: ObjectLeaderQualityCheckModel[], allMaterials: string[]) => {
    let arr = qualityChecks.map(el => {
        let services = el.markedMaterials.filter(x => filterAwayNonEntryQualityCheck(x)).reduce((acc: any, curVal: MarkedMaterial) => {
            return { ...acc, [curVal.materialText]: getValueFromMarkedService(curVal) }
        }, {});
        return { date: el.createdTimestamp, ...services }
    });
    return arr;
}


//Groups Einheiten

export const generateAverageGroupCombinedQualityChecksChartDataByObjectLeader = (qualityChecks: ObjectLeaderQualityCheckModel[], allGroups: GroupLicenseTable[]) => {
    let ret = allGroups.filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName)).map(el => {
        let currentQualityChecks = qualityChecks.filter(qualityCheck => qualityCheck.groupId == el.id);

        let sumOfQualityCheck: any[] = currentQualityChecks.reduce((acc: any, qualityCheck: ObjectLeaderQualityCheckModel) => {

            let services = qualityCheck.markedServices.filter(x => filterAwayNonEntryQualityCheck(x)).map((x: MarkedService) => {
                return getValueFromMarkedService(x);
            });
            return [...acc, ...services.map(x => { return { date: qualityCheck.createdTimestamp, ["Gesamt"]: x } })];
        }, []);

        return sumOfQualityCheck;

    });

    return ret.flat();
}

export const generateAverageGroupCombinedMaterialsQualityChecksChartDataByObjectLeader = (qualityChecks: ObjectLeaderQualityCheckModel[], allGroups: GroupLicenseTable[]) => {
    let ret = allGroups.filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName)).map(el => {
        let currentQualityChecks = qualityChecks.filter(qualityCheck => qualityCheck.groupId == el.id);

        let sumOfQualityCheck: any[] = currentQualityChecks.reduce((acc: any, qualityCheck: ObjectLeaderQualityCheckModel) => {

            let services = qualityCheck.markedMaterials.filter(x => filterAwayNonEntryQualityCheck(x)).map((x: MarkedMaterial) => {
                return getValueFromMarkedService(x);
            });
            return [...acc, ...services.map(x => { return { date: qualityCheck.createdTimestamp, ["Gesamt"]: x } })];
        }, []);

        return sumOfQualityCheck;

    });

    return ret.flat();
}

export const generateAverageGroupQualityChecksChartDataByObjectLeader = (qualityChecks: ObjectLeaderQualityCheckModel[], allGroups: GroupLicenseTable[]) => {
    let ret = allGroups.filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName)).map(el => {
        let currentQualityChecks = qualityChecks.filter(qualityCheck => qualityCheck.groupId == el.id);

        let sumOfQualityCheck: any[] = currentQualityChecks.reduce((acc: any, qualityCheck: ObjectLeaderQualityCheckModel) => {

            let services = qualityCheck.markedServices.filter(x => filterAwayNonEntryQualityCheck(x)).map((x: MarkedService) => {
                return getValueFromMarkedService(x);
            });
            return [...acc, ...services.map(x => { return { date: qualityCheck.createdTimestamp, [el.notificationName]: x } })];
        }, []);

        return sumOfQualityCheck;

    });

    return ret.flat();
}


export const generateAverageMaterialsGroupQualityChecksChartDataByObjectLeader = (qualityChecks: ObjectLeaderQualityCheckModel[], allGroups: GroupLicenseTable[]) => {
    let ret = allGroups.filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName)).map(el => {
        let currentQualityChecks = qualityChecks.filter(qualityCheck => qualityCheck.groupId == el.id);

        let sumOfQualityCheck: any[] = currentQualityChecks.reduce((acc: any, qualityCheck: ObjectLeaderQualityCheckModel) => {

            let services = qualityCheck.markedMaterials.filter(x => filterAwayNonEntryQualityCheck(x)).map((x: MarkedMaterial) => {
                return getValueFromMarkedService(x);
            });
            return [...acc, ...services.map(x => { return { date: qualityCheck.createdTimestamp, [el.notificationName]: x } })];
        }, []);

        return sumOfQualityCheck;

    });

    return ret.flat();
}




//Table Average

const organizeAverageData = (currentQualityChecks: ObjectLeaderQualityCheckModel[], allServices: string[], doMaterial = false) => {
    let allEmptyFields: any = {};
    let allEmptyFieldsCount: any = {};
    for (let field of allServices) {
        allEmptyFields[field] = 0;
        allEmptyFieldsCount[field] = 0;
    }
    currentQualityChecks.forEach((qualityCheck: ObjectLeaderQualityCheckModel) => {
        if (doMaterial) {
            qualityCheck.markedMaterials.filter(x => filterAwayNonEntryQualityCheck(x)).forEach(x => {
                allEmptyFields[x.materialText] = allEmptyFields[x.materialText] + getValueFromMarkedService(x);
                allEmptyFieldsCount[x.materialText] = allEmptyFieldsCount[x.materialText] + 1;
            });
        } else {
            qualityCheck.markedServices.filter(x => filterAwayNonEntryQualityCheck(x)).forEach(x => {
                allEmptyFields[x.cleaningOperationText] = allEmptyFields[x.cleaningOperationText] + getValueFromMarkedService(x);
                allEmptyFieldsCount[x.cleaningOperationText] = allEmptyFieldsCount[x.cleaningOperationText] + 1;
            });
        }
    });
    let servicesWithCount = Object.keys(allEmptyFieldsCount).filter(x => allEmptyFields[x] > 0).reduce((acc, x) => {
        return { ...acc, [x]: Math.round(allEmptyFields[x] / allEmptyFieldsCount[x]) };
    }, {});
    return servicesWithCount;
}

export const generateAverageGroupCombinedQualityChecksTableDataByObjectLeader = (filteredQualityChecksByTimestamp: ObjectLeaderQualityCheckModel[], allGroups: GroupLicenseTable[], allServices: string[], allMaterials: string[]) => {

    let ret = allGroups.filter(x => !StringHelper.IsNullOrWhiteSpace(x.notificationName)).map(el => {
        let currentQualityChecks = filteredQualityChecksByTimestamp.filter(qualityCheck => qualityCheck.groupId == el.id);

        let servicesWithCount = organizeAverageData(currentQualityChecks, allServices);
        let materialsWithCount = organizeAverageData(currentQualityChecks, allMaterials, true);

        return { ...el, services: servicesWithCount, materials: materialsWithCount };

    });
    return ret;
}