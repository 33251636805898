import { Warning } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CountdownDialog = ({ visible, title, onConfirmed, onClose }
    : { visible: boolean; title: string, onConfirmed: any; onClose: any; }) => {
    const [loading, setLoading] = React.useState(false);

    const [countdown, setCountdown] = React.useState(30);
    const {t} = useTranslation();

    React.useEffect(() => {

        if (visible) {
            setTimeout(() => {
                (async () => {
                    setCountdown((prev) => prev - 1);
                    if (countdown < 1) {
                        setLoading(true);
                        await onConfirmed();
                        setLoading(false);
                    }
                })();
            }, 1000);


        }
    }, [visible, countdown]);


    return (
        <Dialog fullWidth maxWidth="md" open={visible} onClose={onClose}>
            <DialogContent>
                <Grid container alignItems={"center"} spacing={4}>
                    <Grid item>
                        <Warning style={{ fontSize: 45 }} color='error' />
                    </Grid>
                    <Grid item>
                        <Typography color={"error"} style={{ fontSize: 40, fontWeight: 'bold' }}>{title}</Typography>
                    </Grid>

                </Grid>
                <Typography color={"primary"} style={{ fontSize: 30, fontWeight: 'bold' }}>Countdown: {countdown}</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>{t("myUnitsSettings.cancel")}</Button>
            </DialogActions>

        </Dialog>
    );
};
