import { BuyerType, PurchaseReasonType } from '../models/GroupLicense';
import { LicenseType } from '../models/LicenseType';

export class LicenseHelper {

    static toLicenseTypeString = (type: LicenseType) => {
        switch (type) {
            case LicenseType.Checkpoint:
                return "Checkpoint"
            case LicenseType.Extension:
                return "Sensor Extended"
            case LicenseType.Advertisement:
                return "Werbung";
            case LicenseType.QualityCheck:
                return "Qualitätssicherung";
            case LicenseType.BatteryDisplay:
                return "Battery SSD"
            case LicenseType.BatterySensor:
                return "Battery Sensor"
            case LicenseType.ObjectNotificationLog:
                return "Objektzeiterfassung"
            case LicenseType.LoraSensorExtension:
                return "Lora Sensor Extended"
            case LicenseType.PayPerService:
                return "Pay-Per-Service"
            case LicenseType.PublicFeedback:
                return "Öffentliches Feedback"
            case LicenseType.CalendarPro:
                return "Kalender Pro"
            case LicenseType.TaqtFeedback:
                return "Taqt Feedback"
            case LicenseType.TaqtTime:
                return "Taqt Time"
            case LicenseType.Device:
            default:
                return "Standard";
        }
    };

    static toBuyerTypeString = (type: BuyerType) => {
        switch (type) {
            case BuyerType.FromSeller:
                return "Verkäufer";
            case BuyerType.FromAdmin:
                return "Admin"
            default:
                return "User selbst";
        }
    };

    static toPurchaseReasonTypeString = (type: PurchaseReasonType) => {
        switch (type) {
            case PurchaseReasonType.Gift:
                return "Geschenk";
            case PurchaseReasonType.Promotion:
                return "Geschäftlich"
            case PurchaseReasonType.Borrowed:
                return "Promotion"
            case PurchaseReasonType.PersonalNeed:
                return "Eigenbedarf"
            default:
                return "Nicht bekannt";
        }
    };

    static getLicenseDescription = (type: LicenseType): { title: string, subTitle: string } => {
        switch (type) {
            case LicenseType.Advertisement:
                return {
                    title: "Check me now - Werbunglizenz",
                    subTitle: ''
                };
            case LicenseType.Device:
            default:
                return {
                    title: "Check me now - Lizenz",
                    subTitle: `Check me now - 10" Touch/Display inkl. Sensor`
                };
        }
    }

    static getExtendLicenseDescription = (type: LicenseType): { title: string, subTitle: string } => {
        switch (type) {
            case LicenseType.Advertisement:
                return {
                    title: "Check me now - Werbunglizenz",
                    subTitle: "Erweitern"
                };
            case LicenseType.Device:
            default:
                return {
                    title: "Check me now - Lizenz",
                    subTitle: "Erweitern"
                };
        }
    }
}
