import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Typography } from "@mui/material";
import React, { Fragment, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { DataFilterType, PeriodChartFilterType, generateChartData, getDataFromGroupIdsTrigger, APIDataAmountType, getServiceTriggerByUserIdGroupIds, getQmTriggersByUserIdGroupIds, getQualityChecksByUserIdGroupIds } from "../../../../helpers/charts/base";
import { generateCleaningTriggerChartData, getAllCleaningTriggerTypes } from "../../../../helpers/charts/cleaningTriggerGenerator";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { getDefaultPeriod } from "../../../../helpers/date-management";
import useNoInitialEffect from "../../../../helpers/extensions";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupCleaningTrigger } from "../../../../models/GroupCleaningTrigger";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { CleaningTriggerDataTable } from "./cleaningTrigger/CleaningTriggerDataTable";
import { TriggeredCleaningTask } from "./cleaningTrigger/TriggeredCleaningTask";
import { EvalApiType } from "./Services";
import { GroupQmTrigger } from "../../../../models/GroupQmTrigger";
import { generateQmChartData, getAllQmTriggerTypes } from "../../../../helpers/charts/qmTriggerGenerator";
import { QmTriggerDataTable } from "./qmTrigger/QmTriggerDataTable";
import { UserServiceWorker } from "../../../../models/UserServiceWorker";
import { ObjectLeaderQualityCheckModel } from "../../../../models/ObjectLeaderQualityCheckModel";
import { ObjectUnit, QMRatingType } from "../../../../models/ObjectUnit";
import { ObjectLeaderQualityCheckTable } from "../../../dialogs/tables/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckTable";
import { ObjectLeaderQualityCheckTableErrorPoints } from "../../../dialogs/tables/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckTableErrorPoints";
import { ObjectLeaderQualityCheckTableWeighting } from "../../../dialogs/tables/ObjectLeaderQualityCheck/ObjectLeaderQualityCheckTableWeighting";

export const QmTriggerDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any }) => {
    const [ServiceTriggerstriggeredCleaningTasks, setServiceTriggersTriggeredCleaningTasks] = React.useState<any>([]);
    const [serviceTriggerstriggeredCleaningAllTasks, setserviceTriggersTriggeredCleaningAllTasks] = React.useState<any>([]);
    const [serviceTriggerstriggerCleaningType, setserviceTriggersTriggerCleaningType] = React.useState<any>([]);
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);
    const { setApiDataLoading, setIsPreFilterDialogVisible }: { setApiDataLoading: any, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);

    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();
    const childRefTriggeredCleaningTask: any = useRef();
    const childRefTriggeredCleaningTaskChart: any = useRef();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [qmTriggerTableData, setQmTriggerTableData] = React.useState<GroupQmTrigger[]>([]);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();

    const [userServiceWorkers, setServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [allQualityChecks, setAllQualityChecks] = React.useState<ObjectLeaderQualityCheckModel[]>([]);
    const [allQualityChecksErrorPoints, setAllQualityChecksErrorPoints] = React.useState<ObjectLeaderQualityCheckModel[]>([]);
    const [allQualityChecksWeighting, setAllQualityChecksWeighting] = React.useState<ObjectLeaderQualityCheckModel[]>([]);

    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);


    const loadDataAsync = async () => {

        setApiDataLoading(true);
        setLoadingCompleted(false);
        setServiceTriggersTriggeredCleaningTasks([]);
        const token = getApiToken(history);
        if (!token) return;

        let groups: GroupLicenseTable[] = [];
        if (serviceDisplayType == EvalApiType.Objects) {
            groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        else if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        } else {
            groups = await getPortalGroups(groupIds, token, groupId);
            setGroupsLicenseTableEntries(groups);
        }

        let objectIds = Array.from(new Set(groups.map(x => x.objectUnitId)));

        let objectUnits = await api.getUserObjectUnitsByIds(token, objectIds);
        setObjects(objectUnits);

        let qualityChecks = await getQualityChecksByUserIdGroupIds(groupId, token, groupIds);
        setAllQualityChecks(qualityChecks);
        setAllQualityChecksErrorPoints(qualityChecks.filter(x => x.qmRatingType == QMRatingType.QMErrorPointsCount));
        setAllQualityChecksWeighting(qualityChecks.filter(x => x.qmRatingType == QMRatingType.QMWeighting));


        let ids = Array.from(new Set(qualityChecks.map(x => x.userServiceWorkerId)));

        let workers = await api.getServiceWorkersByIds(token, ids);
        setServiceWorkers(workers);


        let serviceTriggers = await getQmTriggersByUserIdGroupIds(groupId, token, groupIds);
        setQmTriggerTableData(serviceTriggers);
        let allCleaningTriggerTypes = getAllQmTriggerTypes();
        let cleaningTriggerChartAllData = generateQmChartData(serviceTriggers);
        setserviceTriggersTriggeredCleaningAllTasks(cleaningTriggerChartAllData);
        let cleaningTriggerChartData = generateChartData(cleaningTriggerChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allCleaningTriggerTypes);
        setserviceTriggersTriggerCleaningType(allCleaningTriggerTypes);
        setServiceTriggersTriggeredCleaningTasks(cleaningTriggerChartData);
        showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
        setLoadingCompleted(true);
        setApiDataLoading(false);
    }

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);

    useNoInitialEffect(() => {
        loadDataAsync();
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
        (async () => {
            const token = getApiToken(history);

        })();

    }, [apiDataAmountType, reloadDataFromApi]);

    const updateChosenPastDaysFromParent = (event: any) => {
        childRefTriggeredCleaningTask.current.updateChosenPastDays(event.target.value);
    }

    const updateEndDateFromParent = (event: any) => {
        childRefTriggeredCleaningTask.current.updateEndDateFromParent(event);
    }

    const updateStartDateFromParent = (event: any) => {
        childRefTriggeredCleaningTask.current.updateStartDateFromParent(event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {
        childRefTriggeredCleaningTask.current.updateDataFilterTypeFromParent(event);
    }

    const buildPrintContent = () => {
        return (
            <div className="page-size" ref={refPrint}>
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[childRefTriggeredCleaningTaskChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                    <Grid item xs={12}>
                        <TriggeredCleaningTask
                            childRefChart={childRefTriggeredCleaningTaskChart}
                            childRef={childRefTriggeredCleaningTask}
                            setChartData={setServiceTriggersTriggeredCleaningTasks}
                            allData={serviceTriggerstriggeredCleaningAllTasks}
                            data={ServiceTriggerstriggeredCleaningTasks}
                            dataField={serviceTriggerstriggerCleaningType}
                            loadingCompleted={loadingCompleted} />
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }

    const buildGridContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        QM-Auslösungen {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {qmTriggerTableData.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>

                            <QmTriggerDataTable userServiceWorkers={userServiceWorkers} rows={qmTriggerTableData} groups={groupsLicenseTableEntries} qualityChecks={allQualityChecks}/>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Qualitätssicherungen Log Tabelle {allQualityChecks ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {allQualityChecks ? allQualityChecks.length : ""}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {allQualityChecks ?
                                <ObjectLeaderQualityCheckTable qualityChecks={allQualityChecks} groups={groupsLicenseTableEntries} userServiceWorkers={userServiceWorkers} /> : <CircularProgress size={32} />
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        QM-Services-Gewichtung Analyse Tabelle {allQualityChecksWeighting ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {allQualityChecksWeighting ? allQualityChecksWeighting.length : ""}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {allQualityChecksWeighting ?
                                <ObjectLeaderQualityCheckTableWeighting qualityChecks={allQualityChecksWeighting} groups={groupsLicenseTableEntries} userServiceWorkers={userServiceWorkers} /> : <CircularProgress size={32} />
                            }
                        </AccordionDetails>
                    </Accordion>

                </Grid>

                <Grid item xs={12}>
                    <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        QM-Fehlerpunkte Analyse Tabelle {allQualityChecksErrorPoints ? "" : <div>(wird geladen)<CircularProgress size={30} /></div>}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {allQualityChecksErrorPoints ? allQualityChecksErrorPoints.length : ""}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {allQualityChecksErrorPoints ?
                                <ObjectLeaderQualityCheckTableErrorPoints objectUnits={objects} qualityChecks={allQualityChecksErrorPoints} groups={groupsLicenseTableEntries} userServiceWorkers={userServiceWorkers} /> : <CircularProgress size={32} />
                            }
                        </AccordionDetails>
                    </Accordion>

                </Grid>



                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[childRefTriggeredCleaningTaskChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    <TriggeredCleaningTask
                        childRefChart={childRefTriggeredCleaningTaskChart}
                        childRef={childRefTriggeredCleaningTask}
                        setChartData={setServiceTriggersTriggeredCleaningTasks}
                        allData={serviceTriggerstriggeredCleaningAllTasks}
                        data={ServiceTriggerstriggeredCleaningTasks}
                        dataField={serviceTriggerstriggerCleaningType}
                        loadingCompleted={loadingCompleted}
                        title={"Qm-Auslösungen"} />
                </Grid>

            </Grid>
        );
    }

    return isPrintPage ? buildPrintContent() : buildGridContent();

}