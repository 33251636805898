import React from 'react';
import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AccountCircle, NavigateNext, Save, SwitchCamera, Window } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { UserProperty } from '../../../../models/UserProperty';
import { StringHelper } from '../../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../../styles/usePortalAccountStyles';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { ToiletType } from '../../../../models/GroupSettings';
import { useHistory } from 'react-router-dom';
import { GroupSelectionAssignGroupToObjectDialog } from './GroupSelectionAssignGroupToObjectDialog';
import { GroupSelectionAssignGroupToPropertyDialog } from './GroupSelectionAssignGroupToPropertyDialog';
import { GroupSelectionAssignGroupToServiceWorkerDialog } from './GroupSelectionAssignGroupToServiceWorkerDialog';
import AccordionForStringArray from '../../../common/AccordionForStringArray';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { useTranslation } from 'react-i18next';

export const GroupEntrieEvalEditingDialog = ({ visible, setVisible, group, onSave, groups, properties, userServiceWorkers, onRefresh }
    : { visible: boolean, setVisible: any, group: GroupLicenseTable, onSave: any, groups: GroupLicenseTable[], properties: UserProperty[], userServiceWorkers: UserServiceWorker[], onRefresh: any }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [currentGroup, setCurrentGroup] = React.useState<GroupLicenseTable>(new GroupLicenseTable());

    const [nameError, setNameError] = React.useState('');
    const [typeError, setTypeError] = React.useState('');
    const [unitPriceError, setUnitPriceError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const onClose = () => {
        setCurrentGroup(new GroupLicenseTable());
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    const isValid = () => {
        let valid = true;

        if (StringHelper.IsNullOrWhiteSpace(currentGroup.notificationName)) {
            setNameError("Name ist erforderlich");
            enqueueSnackbar("Name pflicht!", { variant: 'error' });
            valid = false;
        }

        return valid;
    }

    const resetErrorText = () => {
        setNameError('');
        setTypeError('');
        setUnitPriceError('');
    }
    const history = useHistory();

    const [groupSelectionAssignObjectDialogVisible, setGroupSelectionAssignObjectDialogVisible] = React.useState(false);
    const [groupSelectionAssignPropertyDialogVisible, setGroupSelectionAssignPropertyDialogVisible] = React.useState(false);
    const [groupSelectionAssignServiceWorkerDialogVisible, setGroupSelectionAssignServiceWorkerDialogVisible] = React.useState(false);

    const [currentProperties, setCurrentProperties] = React.useState<UserProperty[]>([]);
    const [currentUserServiceWorkers, setCurrentUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const {t} = useTranslation();

    React.useEffect(() => {

        (async () => {
            if (currentGroup) {
                setCurrentGroup(group);
                let res = properties.filter(x => group.userPropertyIds.includes(x.id));
                setCurrentProperties(res);
                let userServiceWorkersForRow = userServiceWorkers.filter(x => group.userServiceWorkerIds.includes(x.id))
                setCurrentUserServiceWorkers(userServiceWorkersForRow);
            } else {
                setCurrentGroup(new GroupLicenseTable());
            }

            resetErrorText();

        })();

    }, [visible, group])



    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="md">
            <DefaultDialogTitle
                title={`Einheit ${currentGroup.notificationName} bearbeiten`}
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">Details</Typography>
                        </Grid>
                        {!!currentGroup.objectUnitId &&
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    endIcon={<NavigateNext />}
                                    disabled={loading}
                                    onClick={async () => {
                                        const queryParams = { chosenObject: currentGroup.objectUnitId };
                                        const queryString = new URLSearchParams(queryParams).toString()
                                        history.push({ pathname: `/portal/objekte`, search: queryString });
                                    }}
                                >
                                    Zum Objekt, um Kunde oder Objekt Name zu ändern
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Einheit-Name"
                                variant="filled"
                                value={currentGroup.notificationName}
                                onChange={(event: any) => { setCurrentGroup({ ...currentGroup, notificationName: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Objekt-Name (spezifisch)"
                                disabled={!!currentGroup.objectUnitId}
                                variant="filled"
                                value={currentGroup.objectName}
                                onChange={(event: any) => { setCurrentGroup({ ...currentGroup, objectName: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Ebene-Name"
                                variant="filled"
                                value={currentGroup.levelName}
                                onChange={(event: any) => { setCurrentGroup({ ...currentGroup, levelName: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                disabled={!!currentGroup.objectUnitId}
                                label="Kunde-Name (spezifisch)"
                                variant="filled"
                                value={currentGroup.roomName}
                                onChange={(event: any) => { setCurrentGroup({ ...currentGroup, roomName: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Raumart</FormLabel>
                                <RadioGroup
                                    row
                                    name="sensorItems"
                                    value={currentGroup.toiletType}
                                    onChange={(event) => {
                                        setCurrentGroup({ ...currentGroup, toiletType: JSON.parse(event.target.value) });
                                    }}
                                >
                                    <FormControlLabel value={ToiletType.Neutral} control={<Radio />} label="Neutral" />
                                    <FormControlLabel value={ToiletType.Male} control={<Radio />} label="Männlich" />
                                    <FormControlLabel value={ToiletType.Female} control={<Radio />} label="Weiblich" />
                                    <FormControlLabel value={ToiletType.Meeting} control={<Radio />} label="Meeting" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item container spacing={2} alignItems="center" justifyContent={"center"}>
                            <Grid item xs={6}>
                                <Typography>
                                    Zugewiesenes Objekt: {group?.objectName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    size='large'
                                    color="primary"
                                    fullWidth
                                    endIcon={<SwitchCamera />}
                                    onClick={async () => {
                                        setGroupSelectionAssignObjectDialogVisible(true);
                                    }}
                                >
                                    Objekt zuweisen
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={6}>
                                <AccordionForStringArray names={currentProperties.map(x => x.name)} title={`${t("myObjectSettings.contains")} ${currentProperties.length} ${t("myObjectSettings.features")}`} listItemIcon={currentProperties.map(x => <Window />)} onAssign={null} onRemoveAssign={null} />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    size='large'
                                    color="primary"
                                    fullWidth
                                    endIcon={<SwitchCamera />}
                                    onClick={async () => {
                                        setGroupSelectionAssignPropertyDialogVisible(true);
                                    }}
                                >
                                    Merkmal zuweisen
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={6}>
                                    <AccordionForStringArray names={currentUserServiceWorkers.map(x => `${x.name} ${x.phoneNumber}`)} title={`${t("myObjectSettings.contains")} ${currentUserServiceWorkers.length} Servicekräfte`} listItemIcon={currentUserServiceWorkers.map(x => <AccountCircle />)} onAssign={null} onRemoveAssign={null} />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    size='large'
                                    color="primary"
                                    fullWidth
                                    endIcon={<SwitchCamera />}
                                    onClick={async () => {
                                        setGroupSelectionAssignServiceWorkerDialogVisible(true);
                                    }}
                                >
                                    Servicekraft zuweisen
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        await onSave(currentGroup);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
            {group &&
                <GroupSelectionAssignGroupToObjectDialog onRefresh={onRefresh} currentGroup={group} groups={groups} open={groupSelectionAssignObjectDialogVisible} setOpen={setGroupSelectionAssignObjectDialogVisible} />
            }
            {group &&
                <GroupSelectionAssignGroupToPropertyDialog onRefresh={onRefresh} currentGroup={group} groups={groups} open={groupSelectionAssignPropertyDialogVisible} setOpen={setGroupSelectionAssignPropertyDialogVisible} />
            }
            {group &&
                <GroupSelectionAssignGroupToServiceWorkerDialog onRefresh={onRefresh} currentGroup={group} groups={groups} open={groupSelectionAssignServiceWorkerDialogVisible} setOpen={setGroupSelectionAssignServiceWorkerDialogVisible} />
            }
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
