import { Chat, Cancel, Check, CheckBox, CheckBoxOutlineBlank, Add, Remove } from "@mui/icons-material";
import { Grid, ListItem, Checkbox, ListItemButton, ListItemIcon, ListItemText, TextField, Divider, Button, List, Autocomplete, Typography, Fab } from "@mui/material";
import {  GroupServiceModeIncidenName } from "../../models/GroupServiceMode";
import { GroupSettingsIncidenReport, GroupSettingsIncidenName } from "../../models/GroupSettings";
import { StandardWorkItem } from "../../models/StandardItem";
import { StandardItemIcon } from "../core/GroupSettingFieldsFeedbackControl";
import { getDefaultIcon } from "../dialogs/DefaultItemsDialogOld";
import { errorColor, white } from "../../styles/colors";
import { GroupPayPerServiceItem, GroupPayPerServiceName } from "../../models/GroupPayPerService";
import { useTranslation } from "react-i18next";

export const PayPerServiceContentComponent = ({ items, selectedPayPerServices, setselectedPayPerServices, standardFeedbackQuestion, onFinish, onFreeText, onCancel, customTexts, isFreeTextEnabled, isWithNameEnabled, PayPerServiceItemNames, isWithNamePriceEnabled }:
  { items: GroupSettingsIncidenReport[], selectedPayPerServices: GroupPayPerServiceItem[], setselectedPayPerServices: any, standardFeedbackQuestion: StandardWorkItem[], onFinish: any, onFreeText: any, onCancel: any, customTexts: GroupPayPerServiceItem[], isFreeTextEnabled: boolean, isWithNameEnabled: boolean, PayPerServiceItemNames: GroupSettingsIncidenName[], isWithNamePriceEnabled: boolean }) => {
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  const togglePayPerServiceRoom = (value: GroupSettingsIncidenReport) => {
    if (selectedPayPerServices.some(x => x.roomText == value.text)) {
      setselectedPayPerServices(selectedPayPerServices.filter(x => x.roomText != value.text));

    } else {
      setselectedPayPerServices((prev: any) => [...prev, new GroupPayPerServiceItem("", value.text)]);
    }
  };
  const {t} = useTranslation();

  return (
    <Grid container spacing={3} direction="column">
      {isFreeTextEnabled &&
        <Grid item>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Chat />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              onFreeText();
            }}
          >
            Extra Raum
          </Button>
        </Grid>
      }
      <Grid item >
        <List dense sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}>
          {items.map((value) => {
            const checked = selectedPayPerServices.some(x => x.roomText == value.text);
            let currentPayPerServiceItem = selectedPayPerServices.find(x => x.roomText == value.text);
            const currentStandardItem = standardFeedbackQuestion ? standardFeedbackQuestion.find(x => x.id == value.standardItemId) : undefined;
            const labelId = `checkbox-list-secondary-label-${value}`;
            const allowedPayPerServiceItemNames = PayPerServiceItemNames.filter(x => !value.nameExceptionList.includes(x.text));
            return (
              <Grid container>
                <Grid item xs={12}>
                  <ListItem

                    key={value.text}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={() => togglePayPerServiceRoom(value)}
                        checked={checked}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <Grid container direction={"row"} style={{ marginRight: 40 }}>
                      <Grid item xs={(isWithNamePriceEnabled || isWithNameEnabled) && currentPayPerServiceItem ? 6 : 12}>
                        <ListItemButton style={{ width: currentPayPerServiceItem ? '50%' : '100%' }} onClick={() => togglePayPerServiceRoom(value)}>
                          <ListItemIcon style={{ width: 70 }}>
                            {currentStandardItem ? <StandardItemIcon currentStandardItem={currentStandardItem} /> : getDefaultIcon(value.text)}
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={currentStandardItem ? currentStandardItem.text : value.text} />
                        </ListItemButton>
                      </Grid>
                      {(isWithNamePriceEnabled || isWithNameEnabled) && currentPayPerServiceItem &&
                        <Grid item xs={6} >
                          {
                            allowedPayPerServiceItemNames.length > 0 && isWithNamePriceEnabled &&
                            <Autocomplete
                              onChange={(event, newValue) => {
                                if (!currentPayPerServiceItem) return;
                                currentPayPerServiceItem.serviceNames = newValue.map(x => GroupServiceModeIncidenName.CreateInstanceFromSettings(x));
                                setselectedPayPerServices((prev: any) => [...prev]);
                              }}
                              multiple
                              id="checkboxes-tags-demo"
                              options={allowedPayPerServiceItemNames}
                              autoComplete={false}
                              getOptionLabel={(option) => option.text}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.text}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                  }}
                                  label="Leistungen" placeholder="Leistungen" />
                              )}
                            />
                          }
                          {currentPayPerServiceItem && isWithNameEnabled &&

                            < TextField
                              fullWidth
                              variant={"outlined"}
                              label="Name"
                              value={currentPayPerServiceItem.reporterName}
                              onChange={(event: any) => {
                                if (currentPayPerServiceItem) {
                                  currentPayPerServiceItem.reporterName = event.target.value;
                                  setselectedPayPerServices((prev: any) => [...prev]);
                                }
                              }} />
                          }

                        </Grid>
                      }
                    </Grid>
                  </ListItem>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
              </Grid>
            );
          })}

        </List >
      </Grid >
      <Grid item container direction={"row"} spacing={3} alignItems="center" justifyContent={"center"}>
        <Grid item xs={5}>
          <Button
            color="error"
            variant='outlined'
            startIcon={<Cancel />}
            onClick={async () => {
              onCancel();
            }}
          >
            {t("myUnitsSettings.cancel")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Check />}
            disabled={selectedPayPerServices.length < 1 && customTexts.length < 1}
            variant={'contained'}
            color="primary"
            onClick={() => { onFinish() }}
          >
            {selectedPayPerServices.length + customTexts.length}  melden
          </Button>
        </Grid>


      </Grid>
    </Grid >

  )
}














export const FreeTextPanelPayPerServiceComponent = ({ messenger, setMessenger, roomText, setroomText, onCancel, onFinish, title, isWithNameEnabled, payPerServiceItemNames }: { messenger: string, setMessenger: any, roomText: GroupPayPerServiceItem[], setroomText: any, onCancel: any, onFinish: any, title: string, isWithNameEnabled: boolean, payPerServiceItemNames: GroupPayPerServiceName[] }) => {
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item>
        <Typography className='text-public' color="primary" fontSize={28} fontWeight={"bold"}>Extra {title}</Typography>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          variant={"outlined"}
          label="Melder"
          value={messenger}
          onChange={(event: any) => {
            setMessenger(event.target.value);
          }} />
      </Grid>
      <Grid item>
        <Button
          fullWidth
          size="large"
          startIcon={<Add />}
          variant={'contained'}
          onClick={async () => {
            setroomText([...roomText, new GroupPayPerServiceItem("", "")])
          }}
          color="primary"
        >
          {title}
        </Button>
      </Grid>
      <Grid item container direction={"row"} spacing={3}>
        {roomText &&
          roomText.map((el, index: number) => {
            return (
              <Grid item xs={12} container direction="row" alignItems="center" spacing={3}>
                <Grid item xs={2}>
                  <Fab
                    size="small"
                    style={{ backgroundColor: errorColor, color: white }}
                    onClick={() => {
                      setroomText(roomText.filter((x, index2) => index2 != index));
                    }}
                  >
                    <Remove />
                  </Fab>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant={"outlined"}
                    name="feedbackQuestionsItems"
                    label={title}
                    value={el.roomText || ''}
                    onChange={(event: any) => {
                      el.roomText = event.target.value;
                      setroomText([...roomText]);
                    }} />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (!el) return;
                      el.serviceNames = newValue.map(x => GroupPayPerServiceName.CreateInstanceFromSettings(x));
                      setroomText([...roomText]);
                    }}
                    multiple
                    id="checkboxes-tags-demo"
                    options={payPerServiceItemNames}
                    autoComplete={false}
                    getOptionLabel={(option) => option.text}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.text}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        label="Leistungen" placeholder="Leistungen" />
                    )}
                  />
                </Grid>
              </Grid>
            );
          })}
      </Grid>
      <Grid container item direction={"row"} spacing={3} alignItems="center">
        <Grid item xs={3}>
          <Fab
            size='large'
            color="error"
            onClick={async () => {
              onCancel();
            }}
          >
            <Cancel />
          </Fab >
        </Grid>
        <Grid item xs={9}>
          <Button
            fullWidth
            size='large'
            style={{ width: '100%' }}
            startIcon={<Check />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              onFinish();
            }}
          >
            Fertig
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}