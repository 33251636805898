import axios from 'axios';
import { handleApiError, headers } from '../api';
import { USER_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { UserGroupSettingsTemplate } from '../models/UserGroupSettingsTemplate';


export const getGroupSettingsTemplateByUser = async (token: string) : Promise<UserGroupSettingsTemplate[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/template`,
        {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err);
    }
    return null as any;
}

export const getGroupSettingsTemplateById = async (token: string, templateId : string) : Promise<UserGroupSettingsTemplate> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${templateId}`,
        {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err);
    }
    return null as any;
}