import { Theme } from "@mui/material";


import makeStyles from '@mui/styles/makeStyles';


export const useuseGroupSideBarImagesDialogSettingViewStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        paddingTop: 16,
    },
    sliderContainer: {
        marginTop: 12,
    }
}));
