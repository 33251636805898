import axios from "axios"
import { headers } from "../api";
import { ApiHelper } from '../helpers/apiHelper';

export const deleteUserAsync = async (userId: string, token: string, enqueueSnackbar: any) => {

    return await axios
        .delete(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
        ).then(() => {
            enqueueSnackbar("Benutzer wurde erfolgreich gelöscht", { variant: 'success' });
        }).catch((error) => {

            if (ApiHelper.hasForbiddenDataRestrictedError(error, "Sie haben keine Berechtigung, um diesen Benutzer zu löschen", enqueueSnackbar))
                return;

            ApiHelper.handleUnexpectedError(error, enqueueSnackbar);
        });
}