import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { UserAccount } from '../../../models/UserAccount';
import { Close, TouchApp } from '@mui/icons-material';
import { UserSingleSelection } from '../UserSingleSelection';
import { useTranslation } from 'react-i18next';

export const UserSingleSelectionForLicenseDialog = ({
  open,
  setOpen,
  users,
  user,
  onSave
}
  : {
    open: boolean,
    setOpen: any,
    users: UserAccount[],
    user: UserAccount | undefined,
    onSave: any
  }) => {

  const classes = useStyles();

  const [selectedUser, setSelectedUser] = React.useState<UserAccount | undefined>(undefined);
  const {t} = useTranslation();

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className={classes.dialogTitle}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography className={classes.dialogTitleText} variant="h6">Wähle Kaufer! Aktueller Käufer: {user?.firstname} {user?.lastname}</Typography>
            <IconButton color="inherit" onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <UserSingleSelection
          users={users}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </DialogContent>

      <DialogActions>

        <Button
          className={classes.deleteButton}
          variant="outlined"
          startIcon={<Close />}
          onClick={async () => {

            handleClose();
          }}
        >
          {t("myUnitsSettings.cancel")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          endIcon={<TouchApp />}
          disabled={!selectedUser}
          onClick={() => {

            if (!selectedUser) return;

            onSave(selectedUser);
          }}
        >
          {selectedUser ? selectedUser.mail : 'Benutzer'} als Käufer annehmen
        </Button>

      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    padding: 0,
    flexGrow: 1,
  },
  dialogTitleText: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: theme.spacing(1),
  },
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  }
}));