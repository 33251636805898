export const schemaImportCheckpoint = {
    'Einheit Name': {
        prop: 'Einheit Name',
        type: String
    },
    'Ebene': {
        prop: 'Ebene',
        type: String
    },
    'Objekt Kostenstelle': {
        prop: 'Objekt Kostenstelle',
        type: String
    },
    'Objekt Name': {
        prop: 'Objekt Name',
        type: String
    },
    'Kunden Name': {
        prop: 'Kunden Name',
        type: String
    },
    'Raum Typ': {
        prop: 'Raum Typ',
        type: String
    },
    'Nutzungsart': {
        prop: 'Nutzungsart',
        type: String
    },
    'Raumgröße': {
        prop: 'Raumgröße',
        type: Number
    },
}