import { addDays, addHours, addMinutes, formatISO, getHours, getMinutes, getUnixTime, isAfter, isBefore, subDays } from "date-fns";
import format from "date-fns/format";
import { api } from "../../api";
import { DrawerMenuItemDisplayType } from "../../components/authorized/ObjectMenuPointDrawer";
import { getQuery } from "../../components/authorized/portal/checkout/ShopPage";
import { ADMIN_DATA_BACKEND, FILTER_BETWEEN_SPECIFIED_TIME, FILTER_BETWEEN_SPECIFIED_TIME_BACKEND, GLOBAL_API_DATA_AMOUNT_TYPE, GLOBAL_CHART_DISPLAY_SIZE_TYPE, GLOBAL_END_TIME_BACKEND_FILTER, GLOBAL_START_TIME_BACKEND_FILTER, INCLUDE_HOUR_FILTER } from "../../constants";
import { GroupFeedbackQuestion } from "../../models/GroupFeedbackQuestion";
import { GroupIncidentVisitor } from "../../models/GroupIncidentVisitor";
import { GroupMotion } from "../../models/GroupMotion";
import { Sensor } from "../../models/GroupSettings";
import { defaultEndDate, defaultPastDays, defaultStartDate, formatToISO, formatDateInHours, formatDateInMonths, formatDateInWeeks, formatEuropeanDate, formatEuropeanDateWithDay, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage, defaultAPIDataAmountType } from "../date-management";
import { deepCopy } from "../general";

export enum DataFilterType {
    "StartEnd" = 0,
    "EndDatePastDays" = 1
}

export const ChartDisplaySizeCustomDashboard = JSON.parse(localStorage.getItem(GLOBAL_CHART_DISPLAY_SIZE_TYPE) || "0");

export const getFilterBetweenSpecifiedTime = () => {
    return JSON.parse(localStorage.getItem(FILTER_BETWEEN_SPECIFIED_TIME) || 'false') as boolean;
}

export const getFilterBetweenSpecifiedTimeBackend = () => {
    return JSON.parse(localStorage.getItem(FILTER_BETWEEN_SPECIFIED_TIME_BACKEND) || 'false') as boolean;
}

export const getAdminDataBackend = () => {
    return JSON.parse(localStorage.getItem(ADMIN_DATA_BACKEND) || 'false') as boolean;
}

export const getStartTimeBackendFilter = () => {
    return new Date(localStorage.getItem(GLOBAL_START_TIME_BACKEND_FILTER) || new Date().toISOString());
}

export const getEndTimeBackendFilter = () => {
    return new Date(localStorage.getItem(GLOBAL_END_TIME_BACKEND_FILTER) || new Date().toISOString());
}

export const setFilterBetweenSpecifiedTime = (val: any) => {
    localStorage.setItem(FILTER_BETWEEN_SPECIFIED_TIME, JSON.stringify(val));
}

export const setFilterBetweenSpecifiedTimeBackend = (val: any) => {
    localStorage.setItem(FILTER_BETWEEN_SPECIFIED_TIME_BACKEND, JSON.stringify(val));
}

export const setAdminDataBackend = (val: any) => {
    localStorage.setItem(ADMIN_DATA_BACKEND, JSON.stringify(val));
}

export const getIncludeHourFilter = () => {
    return JSON.parse(localStorage.getItem(INCLUDE_HOUR_FILTER) || 'false');
}

export const setIncludeHourFilter = (val: any) => {
    localStorage.setItem(INCLUDE_HOUR_FILTER, JSON.stringify(val));
}

export const generateChartData = (arr: any, displayDateType: PeriodChartFilterType = PeriodChartFilterType.Day, dataFilterType: DataFilterType = DataFilterType.StartEnd, endDate: Date | null, startDate: Date | null, fields: string[], pastDays: any = defaultPastDays) => {
    if (!endDate) endDate = defaultEndDate;
    if (!startDate) startDate = defaultStartDate;
    arr = sumUp(deepCopy(arr), displayDateType, dataFilterType, endDate, startDate, pastDays);
    return fillRestofChartData(arr, fields);
}

export const generateChartDataWMissingDay = (arr: any, displayDateType: PeriodChartFilterType, dataFilterType: DataFilterType = DataFilterType.StartEnd, endDate: Date | null, startDate: Date | null, fields: string[], includeMissingDays: boolean | null, pastDays: any = defaultPastDays) => {
    if (!endDate) endDate = defaultEndDate;
    if (!startDate) startDate = defaultStartDate;

    if (includeMissingDays) {
        arr = addMissingDates(arr, startDate, endDate);
    }
    arr = sumUp(deepCopy(arr), displayDateType, dataFilterType, endDate, startDate, pastDays);
    return fillRestofChartData(arr, fields);
}

export const addMissingDates = (arr: any, startDate: Date, endDate: Date) => {

    let start = new Date(startDate);
    let end = new Date(endDate);
    if (end > new Date()) {
        end = new Date();
    }
    const list = Object.keys(arr).map((dateDisplay) => arr[dateDisplay]);
    //console.log(list);
    while (start <= end) {
        var missingDate = {
            date: formatToISO(start),
            motionCount: 0,
            dateDisplay: formatEuropeanDateWithDay(start),
            blockCount: 0,
            sensorId: ""
        };
        if (list.some((el) => el['dateDisplay'] == missingDate.dateDisplay) == false) {
            arr.push(missingDate);
        }
        // else {
        //     console.log("DATE FOUND: " + missingDate.dateDisplay );
        // }
        start.setDate(start.getDate() + 1);

    }
    return arr;
}

export const generateSumChartData = (arr: any, displayDateType: PeriodChartFilterType = PeriodChartFilterType.Day, dataFilterType: DataFilterType = DataFilterType.StartEnd, endDate: Date | null, startDate: Date | null, pastDays: any = defaultPastDays) => {
    if (!endDate) endDate = defaultEndDate;
    if (!startDate) startDate = defaultStartDate;

    arr = sumUpTotal(deepCopy(arr), displayDateType, dataFilterType, endDate, startDate, pastDays);
    return arr;
}


export const sumUpTotal = (arr: any, displayDateType: PeriodChartFilterType = PeriodChartFilterType.Day, dataFilterType: DataFilterType = DataFilterType.StartEnd, endDate: Date, startDate: Date, pastDays: number) => {
    arr = filterFrom(arr, displayDateType, dataFilterType, endDate, startDate, pastDays);
    var result: any = [];
    arr.reduce(function (res: any, value: any) {
        if (!res[value.key]) {
            res[value.key] = { ...value };
            result.push(res[value.key])
        } else {
            Object.keys(value).filter(el => !(el == 'key' || el == 'date' || el == 'cleaner')).forEach(el => {
                if (res[value.key].hasOwnProperty([el])) {
                    res[value.key][el] += value[el];
                } else {
                    res[value.key][el] = value[el];
                }
            })
        }
        return res;
    }, {});

    return result;
}


export const sumUp = (arr: any, displayDateType: PeriodChartFilterType = PeriodChartFilterType.Day, dataFilterType: DataFilterType = DataFilterType.StartEnd, endDate: Date, startDate: Date, pastDays: number) => {
    arr = filterFrom(arr, displayDateType, dataFilterType, endDate, startDate, pastDays);
    arr = generateDisplayDate(arr, displayDateType);
    var result: any = [];
    if (!arr) arr = [];
    let res2 = arr.reduce(function (res: any, value: any) {
        if (!res[value.dateDisplay]) {
            res[value.dateDisplay] = { ...value };
            result.push(res[value.dateDisplay])
        } else {
            let keys = Object.keys(value).filter(el => !(el == 'dateDisplay' || el == 'date' || el == 'cleaner'));
            for (let el of keys) {
                if (res[value.dateDisplay][el] == null) {
                    res[value.dateDisplay][el] = value[el];
                } else {
                    res[value.dateDisplay][el] += value[el];
                }
            }
        }
        return res;
    }, {});
    return result;
}
export const filterFrom = (chartData: any[], displayDateType: PeriodChartFilterType, dataFilterType: DataFilterType, endDate: Date, startDate: Date, pastDays: number) => {
    endDate = new Date(endDate);
    switch (dataFilterType) {
        case DataFilterType.StartEnd:
            startDate = new Date(startDate);
            return doFilteringStartEnd(chartData, endDate, startDate);
        case DataFilterType.EndDatePastDays:
            return doFilteringPastDays(chartData, endDate, pastDays);
    }

}


export const doFilteringPastDays = (chartData: any[], endDate: Date, pastDays: number) => {
    return chartData.filter(el => isAfter(new Date(el.date), subDays(endDate, pastDays)) && isBefore(new Date(el.date), endDate));
}

export const doFilteringStartEnd = (chartData: any[], endDate: Date, startDate: Date) => {
    let filterHour = getIncludeHourFilter();

    if (!filterHour) {
        startDate.setHours(0, 0, 0);
        endDate.setHours(23, 59, 59);
    }
    let retChartData = chartData.filter(el => isAfter(new Date(el.date), startDate) && isBefore(new Date(el.date), endDate));

    if (filterHour) {
        let filterBetweenSpecifiedTime = getFilterBetweenSpecifiedTime();
        if (filterBetweenSpecifiedTime) {
            retChartData = retChartData.filter(el => {
                let currentDate = new Date(el.date);
                let startHours = getHours(startDate);
                let endHours = getHours(endDate);
                let currentHours = getHours(currentDate);

                let startMinutes = getMinutes(startDate) / 60;
                let endMinutes = getMinutes(endDate) / 60;
                let currentMinutes = getMinutes(currentDate) / 60;


                startHours += startMinutes;
                endHours += endMinutes;
                currentHours += currentMinutes;

                return currentHours >= startHours && currentHours <= endHours;
            });
        }
    }

    return retChartData;
}


export const fillRestofChartData = (chartData: any[], fields: string[]) => {
    let fullData = chartData.map(el => {
        fields.forEach(field => {
            if (!el[field]) {
                el[field] = 0;
            }
        });
        return el;
    });
    fullData = sortChartDataByDate(fullData);

    return fullData;
}

export const generateDisplayDate = (chartData: any[], displayDateType: PeriodChartFilterType) => {
    if (PeriodChartFilterType.Hour === displayDateType)
        return chartData.map(el => {
            el["dateDisplay"] = formatDateInHours(new Date(el.date));
            return el;
        })
    else if (PeriodChartFilterType.Day === displayDateType)
        return chartData.map(el => {
            el["dateDisplay"] = formatEuropeanDateWithDay(new Date(el.date));
            return el;
        })
    else if (PeriodChartFilterType.Week === displayDateType)
        return chartData.map(el => {
            el["dateDisplay"] = formatDateInWeeks(new Date(el.date));
            return el;
        })
    else if (PeriodChartFilterType.Month === displayDateType)
        return chartData.map(el => {
            el["dateDisplay"] = formatDateInMonths(new Date(el.date));
            return el;
        })
}

export const addTime = (currentDate: Date, displayDateType: PeriodChartFilterType) => {
    if (PeriodChartFilterType.Hour === displayDateType) {
        return addHours(currentDate, 1)
    }
    else if (PeriodChartFilterType.Day === displayDateType) {
        return addDays(currentDate, 1)
    }
    else if (PeriodChartFilterType.Week === displayDateType) {
        return addDays(currentDate, 1)
    }
    else if (PeriodChartFilterType.Month === displayDateType) {
        return addDays(currentDate, 1)
    }
    return currentDate;
}


export const generateDisplayDateSingle = (paramDate: Date, displayDateType: PeriodChartFilterType) => {
    if (PeriodChartFilterType.Hour === displayDateType) {
        return formatDateInHours(paramDate);
    }
    else if (PeriodChartFilterType.Day === displayDateType) {
        return formatEuropeanDateWithDay(paramDate);
    }
    else if (PeriodChartFilterType.Week === displayDateType) {
        return formatDateInWeeks(paramDate);
    }
    else if (PeriodChartFilterType.Month === displayDateType) {
        return formatDateInMonths(paramDate);
    }
    return "";
}

export const fillGap = (startDate: Date, endDate: Date, chartData: any[], displayDateType: PeriodChartFilterType, fields: string[]) => {
    let currentDate = startDate;
    while (currentDate < endDate) {
        if (chartData.some(x => x.displayDate == currentDate)) {

        } else {
            chartData.push({ dateDisplay: generateDisplayDateSingle(currentDate, displayDateType), date: currentDate })
        }
        currentDate = addTime(currentDate, displayDateType);
    }
    return fillRestofChartData(chartData, fields)

}

export const sortChartDataByDate = (chartData: any[]) => {
    return chartData.sort((a: any, b: any) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
    })
}

export enum APIDataAmountType {
    All = "1",
    DateRange = "2"
}

export enum PeriodChartFilterType {
    Hour = "1",
    Day = "2",
    Week = "3",
    Month = "4",

    None = "255"
}

export enum AggregateType {
    Time = "1",
    Sum = "2",
    Avg = "3",
    SumIncrease = "4"
}

export const getQueryParamFromApiDataAmountType = () => {

    let apiDataAmountType = localStorage.getItem(GLOBAL_API_DATA_AMOUNT_TYPE) || "" + defaultAPIDataAmountType;
    switch (apiDataAmountType) {
        case APIDataAmountType.All:
            return "";
        case APIDataAmountType.DateRange:
            const queryString = getQuery();
            let endDate = getEndDateFromUrlOrStorage(queryString);
            let startDate = getStartDateFromUrlOrStorage(queryString);
            return `startTimestamp=${getUnixTime(startDate)}&endTimestamp=${getUnixTime(endDate)}`;
        default:
            return "";
    }
}

export const getQueryParamsTimeBackendFilter = () => {

    const timeFilterBackend = getFilterBetweenSpecifiedTimeBackend();
    let endDate = getEndTimeBackendFilter();
    let startDate = getStartTimeBackendFilter();
    return `timefilter=${timeFilterBackend}&startTime=${getUnixTime(startDate)}&endTime=${getUnixTime(endDate)}`;}

export const getQueryParamsAdminDataFilter = () => {
    const adiBackend = getAdminDataBackend();
    return `adi=${adiBackend}`;
}

export const getLoraMotionData = async (drawerDisplayType: DrawerMenuItemDisplayType, token: any, groupIds: string[], groupId: string) => {
    if (drawerDisplayType == DrawerMenuItemDisplayType.All) {
        return await api.getLoraMotionsByGroupIds(token, []);
    } else if (drawerDisplayType == DrawerMenuItemDisplayType.Multiple) {
        return await api.getLoraMotionsByGroupIds(token, groupIds);
    }
    else {
        return await api.getLoraMotionsByGroupIds(token, [groupId]);
    }
}

export const getOnlineStatusData = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getGroupOnlineStatusByGroupIdsAsync(token, groupIds);
    }
    else if (groupId) {
        return await api.getOnlineStatusByGroupId(token, groupId);

    } else {
        return await api.GetOnlineStatusByUser(token);
    }
}

export const getOnlineStatusBatteryData = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getGroupOnlineStatusByGroupIdsAsync(token, groupIds);
    }
    else if (groupId) {
        return await api.getOnlineStatusByGroupId(token, groupId);

    } else {
        return await api.GetOnlineStatusByUser(token);
    }
}

export const getOnlineStatusDataBattery = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getGroupOnlineStatusBatteryDisplaysByGroupIdsAsync(token, groupIds);
    }
    else if (groupId) {
        return await api.getGroupOnlineStatusBatteryDisplaysByGroupIdsAsync(token, [groupId]);

    } else {
        return await api.getGroupOnlineStatusBatteryDisplaysByGroupIdsAsync(token, []);
    }
}

export const getFeedbackData = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.GetFeedbacksByGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.getFeedbacksByGroup(token, groupId);

    } else {
        return await api.getFeedbacksByUser(token);
    }
}

export const getCleanerFeedbackByUserGroupIds = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getCleanerFeedbacksByUserGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.getCleanerFeedbacksByUserGroupIds(token, [groupId]);

    } else {
        return await api.getCleanerFeedbacksByUserGroupIds(token, []);
    }
}

export const getFeedbackQuestionsByUserGroupIds = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getFeedbackQuestionsByUserGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.getFeedbackQuestionsByUserGroupIds(token, [groupId]);

    } else {
        return await api.getFeedbackQuestionsByUserGroupIds(token, []);
    }
}

export const getSuggestionsByUserGroupIds = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getSuggestionsByUserGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.getSuggestionsByUserGroupIds(token, [groupId]);

    } else {
        return await api.getSuggestionsByUserGroupIds(token, []);
    }
}

export const getGroupPayPerServiceByGroupIds = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.GetPayPerServiceByUser(token, groupIds);
    }
    else if (groupId) {
        return await api.GetPayPerServiceByUser(token, [groupId]);

    } else {
        return await api.GetPayPerServiceByUser(token, []);
    }
}

export const getIncidentVisitorByGroupIds = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getIncidentVisitorByUserGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.getIncidentVisitorByUserGroupIds(token, [groupId]);

    } else {
        return await api.getIncidentVisitorByUserGroupIds(token, []);
    }
}

export const getServiceTriggerByUserIdGroupIds = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getCleaningTriggerByUserGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.getCleaningTriggerByUserGroupIds(token, [groupId]);

    } else {
        return await api.getCleaningTriggerByUserGroupIds(token, []);
    }
}

export const getQmTriggersByUserIdGroupIds = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.GetQmTriggerByUserGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.GetQmTriggerByUserGroupIds(token, [groupId]);

    } else {
        return await api.GetQmTriggerByUserGroupIds(token, []);
    }
}

export const getQualityChecksByUserIdGroupIds = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getQualityChecksByUserIdGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.getQualityChecksByUserIdGroupIds(token, [groupId]);

    } else {
        return await api.getQualityChecksByUserIdGroupIds(token, []);
    }
}

export const getMotionData = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getMotionsByUserGroupIds(token, groupIds);
    }
    else if (groupId) {
        return await api.getMotionsByUserGroupIds(token, [groupId]);

    } else {
        return await api.getMotionsByUserGroupIds(token);
    }
}

export const getMotionsByObjectIds = async (objectIds: string[], token: string) => {
    return await api.getMotionsByObject(token, objectIds);
}

export const getMotionsByServiceWorkerIds = async (serviceWorkerIds: string[], token: string) => {
    return await api.getMotionsByServiceWorker(token, serviceWorkerIds);
}

export const getSettingsServicesByObject = async (objectIds: string[], token: string) => {
    return await api.GetSettingsServiceEntriesOfObject(token, objectIds);
}

export const getSettingsServicesByServiceWorker = async (serviceWorkerIds: string[], token: string) => {
    return await api.GetSettingsServiceEntriesOfServiceWorkers(token, serviceWorkerIds);
}

export const getSettingsServicesByGroups = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.GetSettingsServiceEntriesByGroups(token, groupIds);
    }
    else if (groupId) {
        return await api.GetSettingsServiceEntriesByGroups(token, [groupId]);
    } else {
        return await api.GetSettingsServiceEntriesByGroups(token, []);
    }
}

export const getServiceData = async (groupId: string, token: string, groupIds: string[] = []) => {
    if (groupIds && groupIds.length > 0) {
        return await api.getGroupServiceModeByGroupIdsAsync(token, groupIds);
    }
    else if (groupId) {
        return await api.getGroupServiceModeByGroupIdsAsync(token, [groupId]);
    } else {
        return await api.getGroupServiceModeByGroupIdsAsync(token, []);
    }
}

export const getServicesByObject = async (objectIds: string[], token: string) => {
    return await api.getGroupServiceModeByObjects(token, objectIds);
}

export const getServicesModesByServiceworkers = async (serviceWorkerIds: string[], token: string) => {
    return await api.getGroupServiceModeByServiceWorker(token, serviceWorkerIds);
}

export const getDataFromGroupIdsTrigger = async (groupIds: any, token: any) => {
    let allRes = await Promise.all(groupIds.map(async (el: any) => await api.getCleaningTriggerByGroup(token, el)));
    let res: any = [];
    allRes.forEach((el: any) => {
        el.forEach((innerEl: any) => {
            res.push(innerEl);
        })
    });
    return res;
}

export const getDataFromGroupIdsFeedback = async (groupIds: any, token: any) => {
    let allRes = await Promise.all(groupIds.map(async (el: any) => await api.getFeedbacksByGroup(token, el)));
    let res: any = [];
    allRes.forEach((el: any) => {
        el.forEach((innerEl: any) => {
            res.push(innerEl);
        })
    });
    return res;
}


export const getDataFromGroupIdsFeedbackQuestions = async (groupIds: any, token: any) => {
    let allRes = await Promise.all(groupIds.map(async (el: any) => await api.getFeedbackQuestionsByGroup(token, el)));
    let res: GroupFeedbackQuestion[] = [];
    allRes.forEach((el: any) => {
        el.forEach((innerEl: any) => {
            res.push(innerEl);
        })
    });
    return res;
}

export const getDataFromGroupIdsIncidentsVisitor = async (groupIds: any, token: any) => {
    let allRes = await Promise.all(groupIds.map(async (el: any) => await api.getIncidentVisitorByGroup(token, el)));
    let res: GroupIncidentVisitor[] = [];
    allRes.forEach((el: any) => {
        el.forEach((innerEl: any) => {
            res.push(innerEl);
        })
    });
    return res;
}

export const getDataFromGroupIdsMotion = async (groupIds: any, token: any) => {
    let allRes = await Promise.all(groupIds.map(async (el: any) => await api.getMotionsByGroup(token, el)));
    let res: any = [];
    allRes.forEach((el: any) => {
        el.forEach((innerEl: any) => {
            res.push(innerEl);
        })
    });
    return res;
}
export const getDataFromGroupIdsSensors = async (groupIds: any, token: any) => {
    let allRes: Sensor[][] = await Promise.all(groupIds.map(async (el: any) => await api.getGroupSensorsById(token, el)));
    return extractSensorsArray(allRes);
}

export const extractArrayData = (data: any) => {
    return data.reduce((prevVal: any, curVal: any) => {
        curVal.forEach((x: any) => {
            prevVal.push(x);
        })
        return prevVal;
    }, [])
}


export const extractSensorsArray = (allSensors: Sensor[][]) => {
    return allSensors.reduce((prevVal, curVal) => {
        curVal.forEach(x => {
            prevVal.push(x);
        })
        return prevVal;
    }, [])
}