import Dialog from '@mui/material/Dialog';
import React, { useContext } from 'react';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, DialogActions } from '@mui/material';
import { GroupCombinationSelection } from '../tables/GroupCombinationSelection';
import { handleSelectedCombinationGroup } from '../tables/Base';
import { MergeType } from '@mui/icons-material';
import { DefaultDialogTitle } from './DefaultDialogTitle';
import { useTranslation } from 'react-i18next';

export const GroupSelectionCombinationEvaluationDialog = ({ open, setOpen, selectedCombinationGroup, setSelectedCombinationGroup }
  : { open: boolean, setOpen: any, selectedCombinationGroup: GroupLicenseTable[], setSelectedCombinationGroup: any }) => {

  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
  const [selection, setSelection] = React.useState<GroupLicenseTable[]>([]);
  const {t} = useTranslation();

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      (async () => {
        let token = getApiToken(history);
        if (!token) return;
        let tempGroups = await api.getAllGroups(token);
        setGroups(tempGroups);

      })();
    }

  }, [open]);

  React.useEffect(() => {
    setSelection(selectedCombinationGroup.map((el: any) => el.id));
  }, [selectedCombinationGroup]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={false}
    >
      <DefaultDialogTitle
        title="Wähle Einheiten für Mehrfachauswertung"
        handleClose={handleClose}
      />
      <GroupCombinationSelection
        groups={groups}
        selection={selection}
        setSelection={setSelection}
      />
      <DialogActions>
        <Button
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          disabled={selection.length < 1}
          variant="contained"
          size="large"
          color="primary"
          endIcon={<MergeType />}
          onClick={() => {
            handleSelectedCombinationGroup(selection, groups, setSelectedCombinationGroup, history);
            handleClose();
          }}
        >
          {t("dashboard.combineUnits")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}