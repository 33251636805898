import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Typography } from "@mui/material";
import React, { Fragment, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { AggregateType, DataFilterType, PeriodChartFilterType, extractSensorsArray, generateChartData, generateChartDataWMissingDay, generateSumChartData, getDataFromGroupIdsMotion, getDataFromGroupIdsSensors, getMotionData, getMotionsByObjectIds, getMotionsByServiceWorkerIds, APIDataAmountType, getLoraMotionData } from "../../../../helpers/charts/base";
import { generateMotionsChartData, generateMotionsChartDataSum, getAllMotionTypes } from "../../../../helpers/charts/customerUsageGenerator";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { getApiToken } from "../../../../helpers/storage-management";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupSettings, LocationInformation, Sensor } from "../../../../models/GroupSettings";
import { mapChartKeyFeedback } from "../../../charts/Base";
import { prepareSensorsForChartAutoComplete } from "../../../../helpers/charts/dataGenerator";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { GroupMotionChart } from "./customerUsage/GetGroupMotions";
import { MotionsAggSumDashboard } from "./MotionsAggSumDashboard";
import { ChartCalcType } from "../../../core/TimeChoice";
import { SensorFilterType, SpecificSensorCharts } from "./customerUsage/specificSensor/SpecificSensorCharts";
import { MotionDataTable } from "./customerUsage/MotionDataTable";
import { ExpandMore } from "@mui/icons-material";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { GroupMotion } from "../../../../models/GroupMotion";
import InfiniteScroll from "react-infinite-scroll-component";
import { EvalApiType } from "./Services";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";
import { drawerPadding, drawerWidth, GlobalContext, maxContentWrapperPortalStyle, paperPadding } from "../../PortalPage";
import { LoraGroupMotion } from "../../../../models/LoraGroupMotion";
import { LoraMotionDataTable } from "./customerUsage/LoraMotionDataTable";
import useNoInitialEffect from "../../../../helpers/extensions";
import { loadMotionData } from "./FrequencyDashboard";
import { GroupMotionLoraChart } from "./lora/GroupMotionLoraChart";
import { LoraMotionWithGroupsChartSimple } from "./lora/LoraMotionWithGroupsChartSimple";
import { subMinutes } from "date-fns";
import { AnalyticSummaryLoraData } from "./lora/AnalyticSummaryLoraData";
import { AnalyticSummaryLoraDataByGroupsDaily } from "./lora/AnalyticSummaryLoraDataByGroupsDaily";
import { AnalyticSummaryLoraDataByGroupsDailyCalendar } from "./lora/AnalyticSummaryLoraDataByGroupsDailyCalendar";
import { AnalyticSummaryLoraDataByGroupsWeekday } from "./lora/AnalyticSummaryLoraDataByGroupsWeekday";
import { AnalyticSummaryLoraDataByWeeks } from "./lora/AnalyticSummaryLoraDataByWeeks";
import { AnalyticSummaryLoraDataByGroupsWeeklyCalendar } from "./lora/AnalyticSummaryLoraDataByGroupsWeeklyCalendar";
import { AnalyticSummaryLoraDataByGroupsMonthlyCalendar } from "./lora/AnalyticSummaryLoraDataByGroupsMonthlyCalendar";
import { AnalyticSummaryLoraDataByMonths } from "./lora/AnalyticSummaryLoraDataByMonths";
import { AnalyticSummarySingleValuesLoraData } from "./lora/AnalyticSummarySingleValuesLoraData";


export const FrequencyLoraDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any }) => {
    const [groupLoraMotionsTableData, setGroupLoraMotionsTableData] = React.useState<LoraGroupMotion[]>([]);
    const [groupLoraMotionsTableDataFiltered, setGroupLoraMotionsTableDataFiltered] = React.useState<LoraGroupMotion[]>([]);

    const [groupLoraMotions, setGroupLoraMotions] = React.useState<any>([]);
    const [groupLoraMotionsAll, setGroupLoraMotionsAll] = React.useState<any>([]);
    const [groupLoraMotionType, setGroupLoraMotionType] = React.useState<any>([]);

    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();

    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();

    const childRefLoraMotionIncrease: any = useRef();
    const childRefLoraMotion: any = useRef();

    const childRefLoraMotionIncreaseChart: any = useRef();
    const childRefLoraMotionChart: any = useRef();


    const [motionsFromApi, setMotionsFromApi] = React.useState<GroupMotion[]>([]);
    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);
    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);

    const [loadingCompleted, setLoadingCompleted] = React.useState(false);


    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);

    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(30);
    const refGroupLoraMotionsWithGroupsSimple = useRef<{ updateStartDateLoraMotionWithGroupsSimpleData(newVal: Date): void, updateEndDateLoraMotionWithGroupsSimpleData(newVal: Date): void }>();

    const loadDataAsync = async () => {
        const token = getApiToken(history);
        if (!token) return;
        let remoteSensors: Sensor[] = [];
        let motions = [];
        setApiDataLoading(true);
        setLoadingProgress(0);
        setValueBuffer(30);
        setLoadingCompleted(false);

        if (serviceDisplayType == EvalApiType.Objects) {
            let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
            setLoadingProgress(15);
        }
        if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }


        motions = await getLoraMotionData(drawerDisplayType, token, groupIds, groupId);
        let filteredMotions = motions.filter(x => !x.activity && x.motionCount > 1);

        setGroupLoraMotionsTableDataFiltered(filteredMotions.map(x => {
            let newMotionStartDate = subMinutes(new Date(x.createdTimestampString), x.motionCount);
            x.createdTimestamp = newMotionStartDate.getTime() / 1000
            return x;
        }));
        setGroupLoraMotionsTableData(motions);

        let motionChartAllData = await loadMotionData(
            setGroupLoraMotionsAll,
            setGroupLoraMotionType,
            setGroupLoraMotions,
            setLoadingCompleted,
            AggregateType.Time,
            filteredMotions,
            null, ["motionCount"]);


        setLoadingProgress(80);
        setValueBuffer(100);





        if (serviceDisplayType == EvalApiType.Groups) {
            let groupsPortal = await getPortalGroups(groupIds, token, groupId);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        setLoadingProgress(100);
        setValueBuffer(100);
        setApiDataLoading(false);

        showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
    }


    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);

    useNoInitialEffect(() => {
        loadDataAsync();
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
    }, [apiDataAmountType, reloadDataFromApi]);

    const updateChosenPastDaysFromParent = (event: any) => {
        if (childRefLoraMotion?.current)
            childRefLoraMotion.current.updateChosenPastDays(event.target.value);


    }

    const updateEndDateFromParent = (event: any) => {
        if (childRefLoraMotion?.current)
            childRefLoraMotion.current.updateEndDateFromParent(event);


        if (refGroupLoraMotionsWithGroupsSimple.current)
            refGroupLoraMotionsWithGroupsSimple.current.updateEndDateLoraMotionWithGroupsSimpleData(event);
    }

    const updateStartDateFromParent = (event: any) => {
        if (childRefLoraMotion?.current)
            childRefLoraMotion.current.updateStartDateFromParent(event);

        if (refGroupLoraMotionsWithGroupsSimple.current)
            refGroupLoraMotionsWithGroupsSimple.current.updateStartDateLoraMotionWithGroupsSimpleData(event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {

    }


    const buildLoraMotionDataTable = () => {
        return (
            <Paper>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            md={6}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    color="textSecondary"
                                >
                                    Lora-Frequenz Log Tabelle
                                </Typography >
                            </Grid>
                            <Grid item>
                                <Typography
                                    className="Paper-Result"
                                    component="p"
                                >
                                    {groupLoraMotionsTableData.length}
                                </Typography >
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LoraMotionDataTable groups={groupsLicenseTableEntries} rows={groupLoraMotionsTableData} selectedEntries={[]} onSelectionChange={() => { }} />
                    </AccordionDetails>
                </Accordion>
            </Paper>
        )
    }


    const buildPrintContent = () => {
        return (
            <div ref={refPrint} className="page-size">
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Frequenzanalyse Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[childRefLoraMotionChart, childRefLoraMotionIncreaseChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>

                </Grid>
                <div className="page-break"></div>
                {buildGetGroupLoraMotion()}
                <div className="page-break"></div>
                {buildLoraMotionWithGroupsChartSimple()}
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Frequenzanalyse Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }

    const firstPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    {buildLoraMotionDataTable()}
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[childRefLoraMotionChart, childRefLoraMotionIncreaseChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    {buildGetGroupLoraMotion()}
                </Grid>
                <Grid item xs={12}>
                    {buildLoraMotionWithGroupsChartSimple()}
                </Grid>
            </Grid>
        )
    }

    const secondPartContent = () => {
        return (
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <AnalyticSummarySingleValuesLoraData groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryLoraData groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryLoraDataByWeeks groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryLoraDataByMonths groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryLoraDataByGroupsDaily groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryLoraDataByGroupsDailyCalendar groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryLoraDataByGroupsWeeklyCalendar groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryLoraDataByGroupsMonthlyCalendar groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
                <Grid item xs={12}>
                    <AnalyticSummaryLoraDataByGroupsWeekday groupsLicenseTableEntries={groupsLicenseTableEntries} loraMotions={groupLoraMotionsTableDataFiltered} />
                </Grid>
            </Grid>
        )
    }


    const [page, setPage] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(true);
    const allItems = [firstPartContent, secondPartContent];
    const fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        if (page <= allItems.length - 1) {
            setPage((curVal) => curVal + 1);
        } else {
            setHasMore(false);
        }
    };

    const buildGridContent = () => {
        return (
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography variant="h4">Lora Frequenz</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
                </Grid>
                <Grid item style={{ minHeight: 300 }}>
                    <InfiniteScroll
                        style={maxContentWrapperPortalStyle}
                        dataLength={page} //This is important field to render the next data
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <Paper style={{ margin: 5 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size={"large"}
                                    startIcon={<ExpandMore />}
                                    //     disabled={!products || !Product.AvailableTypes.some(x => !products.some(p => p.type === x.value))}
                                    onClick={async () => {
                                        fetchMoreData();
                                    }}
                                >
                                    Mehr Laden...
                                </Button>
                            </Paper>
                        }
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Ende der Frequenzanalyse erreicht!</b>
                            </p>
                        }
                    // below props only if you need pull down functionality
                    //        refreshFunction={this.refresh}
                    >
                        <Grid container direction={"column"} spacing={2}>
                            {page >= 0 && firstPartContent()}
                            {page >= 1 && secondPartContent()}
                        </Grid>
                    </InfiniteScroll>
                </Grid>
            </Grid>
        );
    }

    const buildGetGroupLoraMotion = () => {
        return (
            <GroupMotionLoraChart
                childRefChart={childRefLoraMotionChart}
                childRef={childRefLoraMotion}
                setChartData={setGroupLoraMotions}
                allData={groupLoraMotionsAll}
                data={groupLoraMotions}
                dataField={groupLoraMotionType}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildLoraMotionWithGroupsChartSimple = () => {
        return (
            <LoraMotionWithGroupsChartSimple
                ref={refGroupLoraMotionsWithGroupsSimple}
                loraMotionsTableData={groupLoraMotionsTableDataFiltered}
                groupsLicenseTableEntries={groupsLicenseTableEntries} />
        )
    }

    return isPrintPage ? buildPrintContent() : buildGridContent();

}

export const halfMotions = (motionChartAllData: GroupMotion[]) => {
    motionChartAllData.forEach((element: any) => {
        if (element.blockCount && element.blockCount >= 1) element.blockCount = Math.round(element.blockCount / 2);
    });
}
export const loadMotionDataForSensor = async (sensors: Sensor[], setCurrentSensor: any, motionChartAllData: any, setSpecificSensorMotionData: any) => {
    let currentSensor = sensors[0];
    setCurrentSensor(currentSensor);
    let allMotionTypes = getAllMotionTypes();
    let specificSensorMotionData = generateChartData(motionChartAllData, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allMotionTypes);
    //  setCleaningTaskUserDataFields(allUserItems);
    setSpecificSensorMotionData(specificSensorMotionData);
}

export const loadMotionDataForMultipleSensors = async (sensors: Sensor[], motionChartAllData: any, setMultipleSensorMotionData: any) => {
    let allRoomNames = sensors.map(el => el.sensorRoomName);
    let realData = motionChartAllData.map((el: any) => {
        let room = el["sensorRoomName"];
        el[room] = el.blockCount;
        return el;
    })
    let specificSensorMotionData = generateChartData(realData, PeriodChartFilterType.Day, DataFilterType.StartEnd, null, null, allRoomNames);
    //  setCleaningTaskUserDataFields(allUserItems);
    setMultipleSensorMotionData(specificSensorMotionData);
}

export const filterAfterSensorRoomName = (data: any, sensor: Sensor) => {
    return data.filter((el: any) => {
        return el.sensorRoomName === sensor.sensorRoomName
    });
}

export const filterAfterSensorId = (data: any, sensor: Sensor) => {
    return data.filter((el: any) => {
        return el.id === sensor.id
    });
}
