import React from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { Paper, Hidden, AppBar, Grid, Typography, IconButton, CircularProgress, ListItem, ListItemIcon, Divider, Toolbar, List } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import { formatUnixSecTimestampEuropeanDateInMinutes } from '../../helpers/date-management';
import { AzureStorageBrowserModel } from '../../models/AzureStorageBrowserModel';
import { usePortalAccountStyles } from '../../styles/usePortalAccountStyles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CheckMeNowIcon from '../../assets/svgs/check-me-now_icon.svg'
import GreenbirdLogo from '../../assets/svgs/greenbird_logo.svg'


export const NewsPublic = () => {

    const classes = usePortalAccountStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [newsList, setNewsList] = React.useState<AzureStorageBrowserModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            let newNews = await api.getNews();
            setLoading(false);
            setNewsList(newNews);
        })();
    }, []);

    const onClose = () => {
        history.goBack();
      }

    return (
        <Paper className={classes.root}>
            <Hidden smDown>
                <AppBar position="sticky">
                    <Toolbar>
                        <Grid item>
                            <img style={{ marginRight: 10 }} src={CheckMeNowIcon} height={60} alt="check-me-now-icon" />
                        </Grid>
                        <Grid item >
                            <Typography style={{flexGrow: 1}} variant="h6">
                                Öffentliche News für Check-Me-Now!
                            </Typography>
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}></Grid>
                        <Grid item>
                            <img src={GreenbirdLogo} height={50} alt="greenbird-logo" />
                        </Grid>
                        <Grid item>
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    onClose();
                                }}
                                size="large">
                                <Close />
                            </IconButton>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <Typography component="h3" variant="h3" className={classes.title}>News</Typography>
            {loading &&
                <CircularProgress size={64} />
            }
            <Grid container direction="row" className={classes.container}>
                <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                    <List component="nav" aria-label="main mailbox folders">
                        {newsList.map((el) => {
                            return (
                                <React.Fragment>
                                    <ListItem style={{ height: 100 }} button onClick={() => {
                                        window.open(el.uri, '_blank');
                                    }}>
                                        <ListItemIcon color='primary'>
                                            <PictureAsPdfIcon />
                                        </ListItemIcon>
                                        <Typography color="primary" variant="h4">{el.fileName}</Typography>
                                        <Typography style={{ marginLeft: 'auto' }} color="primary" variant="h6">Erstellt am: {formatUnixSecTimestampEuropeanDateInMinutes(el.unixTimestamp)}</Typography>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
        </Paper >
    );
}