import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { UserServiceWorkerObjectSession } from '../../../models/UserServiceWorkerObjectSession';
import makeStyles from '@mui/styles/makeStyles';
import { lightgrey } from '../../../styles/colors';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { buildCellObject } from '../../authorized/extendedapp/serviceWorker/UserServiceWorkerManagementPageDataTable';
import { convertMinuteToHours, formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime, formatEuropeanTime } from '../../../helpers/date-management';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { saveExcelFile } from '../../../helpers/tables/common';
import { differenceInHours, differenceInMinutes, formatDistance, isAfter, isBefore, subDays } from 'date-fns';
import { de } from 'date-fns/locale';
import { Button, Grid as MuiGrid, List, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { useTranslation } from 'react-i18next';



export const ServiceWorkerObjectSessionTable = ({ userServiceWorkerObjectSessions, objects, userServiceWorker, serviceWorkers, groups }
    : { userServiceWorkerObjectSessions: UserServiceWorkerObjectSession[], objects: ObjectUnit[], userServiceWorker?: UserServiceWorker, serviceWorkers: UserServiceWorker[], groups: GroupLicenseTable[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `ServicekraftSession-${userServiceWorker?.name || "Alle-Servicekräfte"}-`);
        });
    };
    const customizeCell = (cell: any, row: any, column: any) => {
        if (column.name === UserServiceWorkerObjectSession.Columns.durationHours) {
            cell.value = +cell.value;
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFBB00' } };

        }
    };
    const classes = useStyles();

    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(0);

    const [startDate, setStartDate] = React.useState(subDays(new Date(), 100));
    const [endDate, setEndDate] = React.useState(new Date());
    const [filters, setFilters]: any = React.useState([]);

    const {t} = useTranslation();


    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === UserServiceWorkerObjectSession.Columns.object) {
            let object = objects.find(x => x.id == row.objectId);
            return buildCellObject(object, props);
        }
        return <Table.Cell {...props} />;
    };

    let columns = [
        {
            name: UserServiceWorkerObjectSession.Columns.object,
            title: 'Object',
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                let object = objects.find(x => x.id == row.objectId);
                return (
                    !object ? t("object-sessions.notActive") : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            }
        },
        {
            name: UserServiceWorkerObjectSession.Columns.creationDate,
            title: 'Filterdatum',
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanDateTime(row.creationDate),
        },
        {
            name: UserServiceWorkerObjectSession.Columns.signedInObjectSinceTimestamp,
            title: t("object-sessions.registrationDate"),
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanDate(row.signedInObjectSinceTimestamp),
        },
        {
            name: UserServiceWorkerObjectSession.Columns.signedInObjectSinceTime,
            title: t("object-sessions.registrationTime"),
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanTime(row.signedInObjectSinceTimestamp),
        },
        {
            name: UserServiceWorkerObjectSession.Columns.signedOutOfObjectSinceTimestamp,
            title: t("object-sessions.deregistrationDate"),
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanDate(row.signedOutOfObjectSinceTimestamp),
        },
        {
            name: UserServiceWorkerObjectSession.Columns.signedOutOfObjectSinceTime,
            title: t("object-sessions.deregistrationTime"),
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanTime(row.signedOutOfObjectSinceTimestamp),
        }
        ,
        {
            name: UserServiceWorkerObjectSession.Columns.duration,
            title: t("object-sessions.attendanceDuration"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                return formatDistance(new Date(row.signedInObjectSinceTimestamp), new Date(row.signedOutOfObjectSinceTimestamp), { locale: de })
            }
        },
        {
            name: UserServiceWorkerObjectSession.Columns.durationMinutes,
            title: t("object-sessions.attendanceDurationMinutes"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                return differenceInMinutes(new Date(row.signedOutOfObjectSinceTimestamp), new Date(row.signedInObjectSinceTimestamp))
            }
        },
        {
            name: UserServiceWorkerObjectSession.Columns.durationHours,
            title: t("object-sessions.attendanceDurationHours"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                return convertMinuteToHours(differenceInMinutes(new Date(row.signedOutOfObjectSinceTimestamp), new Date(row.signedInObjectSinceTimestamp)))
            }
        },
        {
            name: UserServiceWorkerObjectSession.Columns.groupId,
            title: t("object-storage-single-eval.unit"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                let group = groups.find(x => x.id == row.groupId);
                return group?.notificationName || "Keine Einheit"
            },
        },
        {
            name: UserServiceWorkerObjectSession.Columns.logoutGroupId,
            title: t("object-sessions.logoutUnit"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                let group = groups.find(x => x.id == row.logoutGroupId);
                return group?.notificationName || "Keine Einheit"
            },
        },
    ];
    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);


    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: UserServiceWorkerObjectSession.Columns.object, width: 350 },
        { columnName: UserServiceWorkerObjectSession.Columns.serviceWorker, width: 350 },
        { columnName: UserServiceWorkerObjectSession.Columns.creationDate, width: 150 },
        { columnName: UserServiceWorkerObjectSession.Columns.signedInObjectSinceTimestamp, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.signedOutOfObjectSinceTimestamp, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.signedOutOfObjectSinceTime, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.signedInObjectSinceTime, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.duration, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.durationMinutes, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.durationHours, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.groupId, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.logoutGroupId, width: 200 },
    ]);

    if (serviceWorkers.length > 0) {
        columns = [{
            name: UserServiceWorkerObjectSession.Columns.serviceWorker,
            title: t("myObjectSettings.serviceStaff"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                let serviceWorker = serviceWorkers.find(x => x.id == row.userServiceWorkerId);
                return (
                    `${serviceWorker?.name ?? "anonym"} ${serviceWorker?.phoneNumber ?? ""} ${serviceWorker?.email ?? ""} ${serviceWorker?.loginUsername ?? ""} ${row?.nfcCode ?? ""} ${row?.staffNumber ?? ""}`
                );
            }
        }, ...columns]
    }

    const rows = userServiceWorkerObjectSessions.filter(x => isAfter(new Date(x.creationDate), startDate) && isBefore(new Date(x.creationDate), endDate))

    return (
        <Paper>
            <MuiGrid xs={12} item container spacing={3}>
                <MuiGrid item>
                    < DateTimePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={t("serviceDocumentationTable.startDate")}
                        value={startDate}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            setStartDate(newVal);
                        }}
                        format={"dd.MM.yyyy HH:mm"}

                    />
                </MuiGrid>
                <MuiGrid item>
                    < DateTimePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={t("serviceDocumentationTable.endDate")}
                        value={endDate}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            setEndDate(newVal);
                        }}
                        format={"dd.MM.yyyy HH:mm"}

                    />
                </MuiGrid>
            </MuiGrid>
            <Grid
                rows={rows}
                columns={columns}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters}
                />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                customizeCell={customizeCell}
                filters={filters}
                ref={exporterRef}
                rows={rows}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};

const useStyles = makeStyles({
    selected: {
        backgroundColor: lightgrey
    },
    customRow: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: lightgrey
        }
    }
});