export class UpdateGroupSettingsByObjectIdModel {
    public isObjectSignInRfidEnabled!: boolean;
    public isContentEnabled !: boolean;
    public applyGroupSettingsUpdateByObjectModel : ApplyGroupSettingsUpdateByObjectModel = new ApplyGroupSettingsUpdateByObjectModel();
    constructor() {
        this.isContentEnabled = false;
        this.isObjectSignInRfidEnabled = false;
    }
}

export class ApplyGroupSettingsUpdateByObjectModel{
    public isObjectSignInRfidEnabled: boolean = false;
    public isContentEnabled : boolean = false;
}