import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { api } from '../../api';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { Add, Cancel, Save, Wc } from '@mui/icons-material';
import { DialogActions, DialogContent, Grid } from '@mui/material';

import { ReactComponent as SMIcon01 } from '../../assets/icons/tasks/sm_icon-01.svg';
import { ReactComponent as SMIcon02 } from '../../assets/icons/tasks/sm_icon-02.svg';
import { ReactComponent as SMIcon03 } from '../assets/icons/tasks/sm_icon-03.svg';
import { ReactComponent as SMIcon04 } from '../../assets/icons/tasks/sm_icon-04.svg';
import { ReactComponent as SMIcon05 } from '../../assets/icons/tasks/sm_icon-05.svg';
import { ReactComponent as SMIcon06 } from '../../assets/icons/tasks/sm_icon-06.svg';
import { ReactComponent as SMIcon07 } from '../../assets/icons/tasks/sm_icon-07.svg';
import { ReactComponent as SMIcon08 } from '../../assets/icons/tasks/sm_icon-08.svg';
import { ReactComponent as SMIcon09 } from '../../assets/icons/tasks/sm_icon-09.svg';
import { ReactComponent as SMIcon10 } from '../../assets/icons/tasks/sm_icon-10.svg';
import { ReactComponent as SMIcon11 } from '../../assets/icons/tasks/sm_icon-11.svg';
import { ReactComponent as SMIcon12 } from '../../assets/icons/tasks/sm_icon-12.svg';
import { ReactComponent as SMIcon13 } from '../../assets/icons/tasks/sm_icon-13.svg';
import { ReactComponent as SMIcon14 } from '../../assets/icons/tasks/sm_icon-14.svg';
import { ReactComponent as SMIcon15 } from '../../assets/icons/tasks/sm_icon-15.svg';
import { ReactComponent as SMIcon16 } from '../../assets/icons/tasks/sm_icon-16.svg';
import { ReactComponent as SMIcon17 } from '../../assets/icons/tasks/sm_icon-17.svg';
import { ReactComponent as SMIcon18 } from '../../assets/icons/tasks/sm_icon-18.svg';
import { ReactComponent as SMIcon19 } from '../../assets/icons/tasks/sm_icon-19.svg';
import { ReactComponent as SMIcon20 } from '../../assets/icons/tasks/sm_icon-20.svg';
import { ReactComponent as SMIcon21 } from '../../assets/icons/tasks/sm_icon-21.svg';
import { ReactComponent as SMIcon22 } from '../../assets/icons/tasks/sm_icon-22.svg';
import { ReactComponent as SMIcon23 } from '../../assets/icons/tasks/sm_icon-23.svg';
import { ReactComponent as SMIcon24 } from '../../assets/icons/tasks/sm_icon-24.svg';
import { ReactComponent as SMIcon25 } from '../../assets/icons/tasks/sm_icon-25.svg';
import { ReactComponent as SMIcon26 } from '../../assets/icons/tasks/sm_icon-26.svg';
import { ReactComponent as SMIcon27 } from '../../assets/icons/tasks/sm_icon-27.svg';
import { ReactComponent as SMIcon28 } from '../../assets/icons/tasks/sm_icon-28.svg';
import { ReactComponent as SMIcon29 } from '../../assets/icons/tasks/sm_icon-29.svg';
import { ReactComponent as SMIcon30 } from '../../assets/icons/tasks/sm_icon-30.svg';
import { ReactComponent as SMIcon31 } from '../../assets/icons/tasks/sm_icon-31.svg';
import { ReactComponent as SMIcon32 } from '../../assets/icons/tasks/sm_icon-32.svg';
import { ReactComponent as CheckMeNowIcon } from '../../assets/svgs/check-me-now_icon.svg';
import { useTranslation } from 'react-i18next';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 800,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export interface SimpleDialogProps {
  itemType: DefaultItemsType;
  manageItems: any;
  open: boolean;
  selectedValue: string;
  currentItems: string[];
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {



  const { onClose, selectedValue, open, itemType, manageItems, currentItems } = props;
  const [listItems, setListItems] = React.useState<any>([]);
  const history = useHistory();
  const {t} = useTranslation();


  React.useEffect(() => {
    let token = getApiToken(history);
    if (open) {
      (async () => {
        let res: any = [];
        switch (itemType) {
          case DefaultItemsType.Services:
            res = await api.getDefaultCleaningTasksAsync(token);
            break;
          case DefaultItemsType.Materials:
            res = await api.getDefaultMaterialsAsync(token);
            break;
          case DefaultItemsType.Incidents:
            res = await api.getDefaultIncidentsAsync(token);
            break;
          default:
            res = [];

        }
        setListItems(res);
      }
      )();

    }


  }, [open]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  const handleToggle = (value: string) => () => {
    const currentIndex = selectedItems.indexOf(value);
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedItems(newChecked);
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Items Auswahl</DialogTitle>
      <DialogContent>
        <List className={classes.root}>
          {listItems.map((value: string, index: number) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem disabled={currentItems.some(x => x == value)} key={index} role={undefined} button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedItems.indexOf(value) !== -1 || currentItems.some(x => x == value)}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value}`} />
                <ListItemIcon>
                  {getDefaultIcon(value)}
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button size="large"
              startIcon={<Cancel />} variant={'outlined'} onClick={handleClose} color="primary">
              {t("myUnitsSettings.cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button size="large"
              startIcon={<Save />} variant={'contained'} onClick={async () => {
                handleClose();
                manageItems(selectedItems);
                setSelectedItems([]);
              }} color="primary">
              Hinzufügen
            </Button>
          </Grid>
        </Grid>

      </DialogActions>
    </Dialog>
  );
}

export default function DefaultItemsDialogOld({ itemType, manageItems, currentItems }: { itemType: DefaultItemsType, manageItems: any, currentItems: string[] }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const {t} = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <Button startIcon={<Add />} endIcon={<Wc />} size="large" variant="contained" color="primary" onClick={handleClickOpen}>
        {t("myUnitsSettings.assignWcItem")}
      </Button>
      <SimpleDialog manageItems={manageItems} selectedValue={selectedValue} open={open} onClose={handleClose} itemType={itemType} currentItems={currentItems} />
    </>
  );
}


export const getDefaultIcon = (element: string, fill = '#000000') => {
  const width = 50;
  const height = 50;
  switch (element) {
    case "Boden wischen":
      return <SMIcon01 width={width} height={height} fill={fill} />
    case "Boden kehren":
      return <SMIcon02 width={width} height={height} fill={fill} />
    case "Boden saugen":
      return <SMIcon04 width={width} height={height} fill={fill} />
    case "WC reinigen":
      return <SMIcon05 width={width} height={height} fill={fill} />
    case "Pissoire reinigen":
      return <SMIcon06 width={width} height={height} fill={fill} />
    case "Spiegel reinigen":
      return <SMIcon07 width={width} height={height} fill={fill} />
    case "Spiegel wischen":
      return <SMIcon08 width={width} height={height} fill={fill} />
    case "Lampe wischen / abstauben":
      return <SMIcon09 width={width} height={height} fill={fill} />
    case "Staub wischen":
      return <SMIcon10 width={width} height={height} fill={fill} />
    case "Waschtisch reinigen":
      return <SMIcon11 width={width} height={height} fill={fill} />
    case "Wischen":
      return <SMIcon12 width={width} height={height} fill={fill} />
    case "Waschtisch reinigen":
      return <SMIcon12 width={width} height={height} fill={fill} />
    case "Trennwände wischen":
      return <SMIcon13 width={width} height={height} fill={fill} />
    case "Pissoire Trennwände reinigen":
      return <SMIcon13 width={width} height={height} fill={fill} />
    case "Mist ausleeren":
      return <SMIcon14 width={width} height={height} fill={fill} />
    case "WC-Bürste reinigen":
      return <SMIcon15 width={width} height={height} fill={fill} />
    case "WC-Papier nachfüllen":
      return <SMIcon16 width={width} height={height} fill={fill} />
    case "WC Papier":
      return <SMIcon16 width={width} height={height} fill={fill} />
    case "Handtücher nachfüllen":
      return <SMIcon17 width={width} height={height} fill={fill} />
    case "Handtuch":
      return <SMIcon17 width={width} height={height} fill={fill} />
    case "Seife":
      return <SMIcon18 width={width} height={height} fill={fill} />
    case "Desinfizieren":
      return <SMIcon19 width={width} height={height} fill={fill} />
    case "Desinfektionsmittel":
      return <SMIcon19 width={width} height={height} fill={fill} />
    case "Reste kehren":
      return <SMIcon20 width={width} height={height} fill={fill} />
    case "Vandalismus":
      return <SMIcon21 width={width} height={height} fill={fill} />
    case "Wasserschaden":
      return <SMIcon22 width={width} height={height} fill={fill} />
    case "Licht defekt":
      return <SMIcon23 width={width} height={height} fill={fill} />
    case "Spiegel gebrochen":
      return <SMIcon24 width={width} height={height} fill={fill} />
    case "Diebstahl":
      return <SMIcon25 width={width} height={height} fill={fill} />
    case "WC verstopft":
      return <SMIcon26 width={width} height={height} fill={fill} />
    case "Türen wischen":
      return <SMIcon27 width={width} height={height} fill={fill} />
    case "Türen-Griffspuren entfernen":
      return <SMIcon28 width={width} height={height} fill={fill} />
    case "Spinnweben entfernen":
      return <SMIcon29 width={width} height={height} fill={fill} />
    case "Armatur defekt":
      return <SMIcon30 width={width} height={height} fill={fill} />
    case "Kalk entfernen":
      return <SMIcon31 width={width} height={height} fill={fill} />
    case "Damen-Hygiene nachfüllen":
      return <SMIcon32 width={width} height={height} fill={fill} />
    case "Damen-Hygiene Behälter entleeren":
      return <SMIcon32 width={width} height={height} fill={fill} />
    default:
      return <CheckMeNowIcon width={width} height={height} fill={fill} />
  }
}

export enum DefaultItemsType {
  Services = 1,
  Materials = 2,
  Incidents = 3
}