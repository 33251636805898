import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { CenterFocusStrong, LocalShipping, SaveAlt, Sensors, TouchApp } from '@mui/icons-material';
import { Alert, AlertTitle, Checkbox, CircularProgress, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Switch, Typography } from '@mui/material';
import { GroupSettingsTemplateOption } from '../../../models/GroupSettingsTemplateOption';
import { ReactComponent as MapMarkerRadiusSvg } from '../../../assets/svgs/map-marker-radius.svg'
import { ReactComponent as FormatListChecksSvg } from '../../../assets/svgs/format-list-checks.svg';
import { ReactComponent as CardAccountMail } from '../../../assets/svgs/card-account-mail.svg'
import { ReactComponent as CleanerSvg } from '../../../assets/icons/tasks/sm_icon-01.svg';
import { ReactComponent as MotionSensorSvg } from '../../../assets/svgs/motion-sensor.svg';
import { ReactComponent as EmoticonNeutalSmileSvg } from '../../../assets/svgs/emoticon-neutral-outline.svg'
import { ReactComponent as ChartBellCurve } from '../../../assets/svgs/chart-bell-curve.svg';
import { ReactComponent as PaperRoll } from '../../../assets/svgs/paper-roll.svg';
import { ReactComponent as AlertSvg } from '../../../assets/svgs/alert.svg';
import { ReactComponent as WeatherCloudyAlert } from '../../../assets/svgs/weather-cloudy-alert.svg'
import { t } from 'i18next';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const GroupSettingsTemplateOptionChooserDialog = ({ open, setOpen, templateOptions, setTemplateOptions, onFinish }: { open: boolean, setOpen: any, templateOptions: GroupSettingsTemplateOption, setTemplateOptions: React.Dispatch<React.SetStateAction<GroupSettingsTemplateOption>>, onFinish: any }) => {

  const [selection, setSelection] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (open) {

    }
  }, [open])

  const handleClose = () => {
    setOpen(false);
  };

  const svgSize = 60;

  return (
    <div>
      <Dialog fullWidth maxWidth={"md"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          Vorlage Optionen
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.locationInformation = !prev.locationInformation;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <MapMarkerRadiusSvg width={svgSize} height={svgSize} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Location</Typography>
                  </Grid>
                  <Grid item>
                    <Alert severity="warning">
                      <AlertTitle>Überschrieben wird:</AlertTitle>
                      Merkmalzuweisung, Objektzuweisungen, Geräte Optionen,<br></br>Einheiten Passwort, QR-Code URL,Offline (Lokales) Passwort
                    </Alert>
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}></Grid>
                  <Grid item>
                    <Switch
                      edge="end"
                      checked={templateOptions.locationInformation}
                    />
                  </Grid>
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.cleaningOperations = !prev.cleaningOperations;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <FormatListChecksSvg height={svgSize} width={svgSize} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Serviceleistungen</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.cleaningOperations}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.cleaningOperations = !prev.cleaningOperations;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <CenterFocusStrong style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Qualitätsmanagement</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.cleaningOperations}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.notification = !prev.notification;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <CardAccountMail
                      width={svgSize}
                      height={svgSize}
                      fill={"#000000"}
                    />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">{t("myUnitsSettings.notificationSettings")}</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.notification}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.users = !prev.users;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <CleanerSvg width={svgSize} height={svgSize} fill={"#000000"} style={{ marginLeft: -4 }} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4" style={{ marginLeft: -16 }}>Lokale Servicekräfte</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.users}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.loraSensorControl = !prev.loraSensorControl;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <Sensors style={{ fontSize: 60 }} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Lora Sensor-Steuerung</Typography>
                  </Grid>
                  <Grid item>
                    <Alert severity="warning">
                      <AlertTitle>Überschrieben wird:</AlertTitle>
                      Aktivierung
                    </Alert>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.loraSensorControl}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.sensorControl = !prev.sensorControl;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <MotionSensorSvg width={svgSize} height={svgSize} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Sensor-Steuerung</Typography>
                  </Grid>
                  <Grid item>
                    <Alert severity="warning">
                      <AlertTitle>Überschrieben wird:</AlertTitle>
                      Aktivierung
                    </Alert>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.sensorControl}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.feedbacks = !prev.feedbacks;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <EmoticonNeutalSmileSvg width={svgSize} height={svgSize} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Feedback-Steuerung</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.feedbacks}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.intervalControl = !prev.intervalControl;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <ChartBellCurve width={svgSize} height={svgSize} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Intervall-Steuerung (Standard)</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.intervalControl}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.materialConsumptions = !prev.materialConsumptions;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <PaperRoll width={svgSize} height={svgSize} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Materialverbrauch</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.materialConsumptions}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.materialConsumptions = !prev.materialConsumptions;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <LocalShipping style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Materialwirtschaft</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.materialConsumptions}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.incidentReports = !prev.incidentReports;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <AlertSvg width={svgSize} height={svgSize} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Vorfallmeldung</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.incidentReports}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => {
                setTemplateOptions((prev) => {
                  prev.content = !prev.content;
                  return { ...prev };
                })
              }}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                  <Grid item>
                    <WeatherCloudyAlert width={svgSize} height={svgSize} fill={"#000000"} />
                  </Grid>
                  <Grid item>
                    <Typography component="h4" variant="h4">Display Einstellungen & Logo,Werbung</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}></Grid>
                <Grid item>
                  <Switch
                    edge="end"
                    checked={templateOptions.content}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button size="large"
            startIcon={<TouchApp />} variant={'contained'}
            endIcon={loading && <CircularProgress size={32} />}
            onClick={async () => {
              setLoading(true);
              setLoading(false);
              handleClose();
              onFinish();
            }} color="primary">
            Optionen Auswählen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}