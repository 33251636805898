import axios from "axios"
import { headers } from "../api";
import { USER_ID } from "../constants";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const addNewUserKey = async (token: string,enqueueSnackbar:any, notice: string): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/user-key`,
            {
                notice: notice
            }, { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
        );
        enqueueSnackbar("Neuer Lizenz-Key hinzugefügt!",{variant:'success'});
        return ret.data;
    } catch {
        enqueueSnackbar("Lizenz-Key hinzufügen fehlgeschlagen",{variant:'error'});
        return undefined;
    }
}