import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { DataFilterType, getAdminDataBackend, getIncludeHourFilter, setAdminDataBackend, setFilterBetweenSpecifiedTime, setFilterBetweenSpecifiedTimeBackend, setIncludeHourFilter } from '../../helpers/charts/base';
import { Settings } from '@mui/icons-material';
import { GlobalContext } from '../authorized/PortalPage';
import { TimePicker } from '@mui/x-date-pickers';
import { GLOBAL_END_TIME_BACKEND_FILTER, GLOBAL_START_TIME_BACKEND_FILTER } from '../../constants';
import { getDayOfYear, getMonth, getYear, setDayOfYear, setMonth, setYear } from 'date-fns';
import { UserType } from '../../models/UserType';
import { useTranslation } from 'react-i18next';

export default function DataFilterTypeChoiceDialog(props: any) {
    const [open, setOpen] = React.useState(false);
    const [isAdminDataBackendEnabled, setAdminDataBackendEnabled] = React.useState(getAdminDataBackend());
    const {t} = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setDataFilterType(parseInt((event.target as HTMLInputElement).value));
    };
    const globals: any = useContext(GlobalContext);
    const { userAccount } = useContext(GlobalContext);
    return (
        <Grid item>
            <Button variant="outlined" color="primary" endIcon={<Settings />} onClick={handleClickOpen}>
                {t("filterSettings.filterSettings")}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("filterSettings.filterTypeSettings")}</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={globals.includeFilterHours}
                                        onChange={(event: any) => {
                                            setIncludeHourFilter(event.target.checked);
                                            globals.setIncludeFilterHours(event.target.checked)
                                            //       event.target['value'] = event.target.checked;
                                            //      onChange(event);
                                        }}
                                        name="contentIsContentEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("filterSettings.hourDisplay")}
                            />
                        </Grid>
                        {userAccount?.userType == UserType.Admin &&
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAdminDataBackendEnabled}
                                            onChange={(event: any) => {
                                                setAdminDataBackendEnabled(event.target.checked);
                                                setAdminDataBackend(event.target.checked)
                                            }}
                                            name="adminData"
                                            color="primary"
                                        />
                                    }
                                    label={t("filterSettings.showAdminData")}
                                />
                            </Grid>
                        }
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={globals.filterBetweenSpecifiedTimeBackend}
                                        onChange={(event: any) => {
                                            setFilterBetweenSpecifiedTimeBackend(event.target.checked)
                                            globals.setFilterBetweenSpecifiedTimeBackend(event.target.checked);
                                            //       event.target['value'] = event.target.checked;
                                            //      onChange(event);
                                        }}
                                        name="contentIsContentEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("filterSettings.specificTimeFilterBackend")}
                            />
                        </Grid>
                        {globals.filterBetweenSpecifiedTimeBackend &&
                            <Grid container item direction={"row"} spacing={3}>
                                <Grid item lg={6}>
                                    <TimePicker
                                        label={t("filterSettings.startDate")}
                                        value={globals.startTimeBackendFilter}
                                        onChange={(newValue) => {
                                            let currentDate = new Date();
                                            setMonth(newValue, getMonth(currentDate))
                                            setYear(newValue, getYear(currentDate))
                                            setDayOfYear(newValue, getDayOfYear(currentDate))

                                            globals.setStartTimeBackendFilter(newValue);
                                            localStorage.setItem(GLOBAL_START_TIME_BACKEND_FILTER, newValue);
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                    <TimePicker
                                        label={t("filterSettings.endDate")}
                                        value={globals.endTimeBackendFilter}
                                        onChange={(newValue) => {
                                            let currentDate = new Date();
                                            setMonth(newValue, getMonth(currentDate))
                                            setYear(newValue, getYear(currentDate))
                                            setDayOfYear(newValue, getDayOfYear(currentDate))

                                            globals.setEndTimeBackendFilter(newValue)
                                            localStorage.setItem(GLOBAL_END_TIME_BACKEND_FILTER, newValue);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("filterSettings.dataFilterType")}</FormLabel>
                                <RadioGroup value={props.dataFilterType} onChange={handleChange}>
                                    <FormControlLabel value={DataFilterType.StartEnd} control={<Radio />} label={t("filterSettings.startDateAndEndDate")} />
                                    <FormControlLabel value={DataFilterType.EndDatePastDays} control={<Radio />} label={t("filterSettings.endDateAndDaysBack")} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {t("myUnitsSettings.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}