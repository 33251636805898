export enum DeviceType
{
    Display = 0,
    Sensor = 1
}

export class SsdDeviceModel {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public creationTimestamp!: string;
    public userId!: string;     
    public version : string = ""; // lastUpdate
    public deviceType : DeviceType = DeviceType.Display;
    public hubId!: string;


    static Columns = {
        id : 'id',
        creationTimestamp: 'creationTimestamp',
        userId: 'userId',
        version: 'version',
        availableDisplays: 'availableDisplays',
        availableSensors: 'availableSensors',
        hubId: 'hubId',
        deviceType: 'deviceType'
    };

    static GetLabel = (deviceType: DeviceType) => {
        switch (deviceType){
            case DeviceType.Display:
                return "Batterie Smart Service Display"
            case DeviceType.Sensor:
                return "Batterie Sensor"
        }
    }


}
