import axios from 'axios';
import { headers } from '../api';

import { getUserIdFromStorage } from '../helpers/common';
import { Sensor } from '../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../models/UserGroupSettingsTemplate';

export const getGroupSensorsById = async (token: string, groupId: string) : Promise<Sensor[]> => {
    try {
        const userId = getUserIdFromStorage();
        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/sensors`,
        {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err,null,2));
    }
    return null as any;
}