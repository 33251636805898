import React from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableColumnVisibility, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { IconButton, Tooltip } from '@mui/material';
import { Edit, Delete, Visibility, VisibilityOff } from '@mui/icons-material';
import { CurrencyHelper } from "../../helpers/CurrencyHelper";
import { Product } from '../../models/Product';
import { errorColor } from '../../styles/colors';
import { useTranslation } from 'react-i18next';

export const ProductManagementPageDataTable = ({ rows, onEdit, onDelete }
    : { rows: Product[], onEdit: (row: Product) => Promise<any>, onDelete: (row: Product) => Promise<any> }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();

    return (
        <Grid
            rows={rows}
            getRowId={(row: Product) => row.id}
            columns={[
                {
                    name: Product.Columns.name,
                    title: "Name",
                    getCellValue: (row: Product) => row.name,
                },
                {
                    name: Product.Columns.type,
                    title: "Typ",
                    getCellValue: (row: Product) => `${row.type} - ${Product.GetLabelByType(row.type)}`,
                },
                {
                    name: Product.Columns.unitPrice,
                    title: "Einheit",
                    getCellValue: (row: Product) => CurrencyHelper.toCurrencyString(row.unitPrice),
                },
                {
                    name: Product.Columns.actions,
                    title: " ",
                    getCellValue: (row: Product) => {
                        return <>
                            <Tooltip title={t("myUnitsSettings.edit")}>
                                <IconButton
                                    color="primary"
                                    onClick={async () => {
                                        await onEdit(row);
                                    }}
                                    size="large">
                                    <Edit />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Löschen">
                                <IconButton
                                    style={{ color: errorColor }}
                                    onClick={async () => {
                                        await onDelete(row);
                                    }}
                                    size="large">
                                    <Delete />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={row.isVisibleInShop ? "In Shop sichtbar" : "Nicht in Shop sichtbar"}>
                                <IconButton size="large">
                                    {row.isVisibleInShop ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </Tooltip>
                        </>;
                    },
                },
                {
                    name: Product.Columns.filter,
                    getCellValue: (row: Product) => row.type,
                }
            ]}
        >

            <FilteringState
                columnExtensions={[
                    {
                        columnName: Product.Columns.actions,
                        filteringEnabled: false,
                    }
                ]}
            />
            <IntegratedFiltering />

            <SortingState
                sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
            />
            <IntegratedSorting />

            <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />

            <Table
                columnExtensions={[
                    {
                        columnName: Product.Columns.unitPrice,
                        width: 128,
                    },
                    {
                        columnName: Product.Columns.actions,
                        width: 160,
                    },
                ]}
            />
            <TableHeaderRow />
            <TableFilterRow />
            <TableColumnVisibility
                hiddenColumnNames={[Product.Columns.filter]}
            />
            <PagingPanel pageSizes={[10, 20, 30, 0]} />
        </Grid>
    );
};
