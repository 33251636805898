import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Fab, FormControlLabel, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, LocalShipping, Category } from "@mui/icons-material";
import { GroupSettings, MaterialConsumption } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { errorColor, white } from "../../styles/colors";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { ReactComponent as PaperRoll } from '../../assets/svgs/paper-roll.svg';
import { useContainerStyles } from "../../styles/container";
import { StandardWorkItem, StandardWorkItemType } from "../../models/StandardItem";
import { api } from "../../api";
import { FileBlobType } from "../../models/FileBlobType";
import { useSnackbar } from "notistack";
import DefaultItemsDialogOld, { DefaultItemsType, getDefaultIcon } from "../dialogs/DefaultItemsDialogOld";
import DefaultItemsDialog from "../dialogs/DefaultItemsDialog";
import { LocalMedia } from "../images/NetworkMedia";
import { TextFieldGroupSettings } from "./components/groupSettings/TextFieldGroupSettings";
import { ObjectUnit } from "../../models/ObjectUnit";
import { SettingsContext } from "../authorized/PortalPage";
import { useTranslation } from "react-i18next";

export const GroupSettingFieldsMaterialConsumption = ({ row, onChange, token }: { row: GroupSettings | UserGroupSettingsTemplate; onChange: any; token: any }) => {

    const classes = GroupSettingFieldsUseStyles();
    const containerStyles = useContainerStyles();
    const [standardMaterials, setStandardMaterials] = React.useState<StandardWorkItem[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    const {t} = useTranslation();

    return (
        <Accordion className={classes.hover} onChange={async (event, expanded) => {
            if (expanded && standardMaterials.length < 1) {
                let res = await api.GetStandardWorkItemsByType(token, StandardWorkItemType.Material);
                setStandardMaterials(res);
                for (let item of row.materialConsumptions?.materialConsumptions || []) {
                    let curPhotoToDownload = res.find(x => x.id == item.standardItemId);
                    if (curPhotoToDownload)
                        curPhotoToDownload.downloadedPhotoUrl = await api.DownloadUserFile(curPhotoToDownload.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, curPhotoToDownload.photos[0].fileName, curPhotoToDownload.photos[0].contentType, true)

                }
                setStandardMaterials([...res]);
            }
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <PaperRoll width={40} height={40} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage.materialconsumption")}</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={row.materialConsumptions?.isMaterialConsumptionEnabled}
                                onChange={(event: any) => {
                                    event.target['value'] = event.target.checked;
                                    onChange(event);
                                }}
                                name="isMaterialConsumptionEnabled"
                                color="primary" />}
                            label={`${t("settingsPage.materialconsumption")} ${t("settingsPage.activated")}`} />
                    </Grid>

                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item xs={12} container direction={"column"} spacing={3} alignItems="center">
                    <Grid container item justifyContent={"flex-start"} xs={12} spacing={3}>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Add />}
                                variant={'contained'}
                                onClick={async () => {
                                    onChange({ target: { name: 'materialConsumptionsItemsAdd', value: "" } });
                                }}
                                color="primary"
                            >
                                {t("myUnitsSettings.addMaterial")}
                            </Button>
                        </Grid>

                        <Grid item>
                            <DefaultItemsDialog
                                onLoadFiles={async () => {
                                    let res = await Promise.all(standardMaterials.map(async (x) => {
                                        if (x.photos.length > 0) {
                                            x.downloadedPhotoUrl = await api.DownloadUserFile(x.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, x.photos[0].fileName, x.photos[0].contentType, true)
                                        }
                                        return x;
                                    }));
                                    setStandardMaterials(res);
                                }}
                                allStandardItems={standardMaterials}
                                currentItems={row.materialConsumptions?.materialConsumptions.map(el => standardMaterials.find(x => x.id == el.standardItemId) || new StandardWorkItem()) || []}
                                manageItems={(newItems: any[]) => {
                                    onChange({ target: { name: 'materialConsumptionsItemsAddMultiple', value: newItems.map(x => MaterialConsumption.CreateInstance(x.text, true, x.id)) } });
                                }}
                                itemType={StandardWorkItemType.Material} />
                        </Grid>
                        <Grid item>
                            {row.materialConsumptions && row.materialConsumptions.materialConsumptions &&
                                <DefaultItemsDialogOld
                                    currentItems={row.materialConsumptions?.materialConsumptions?.map(el => el.text) || []}
                                    manageItems={(newItems: any[]) => {
                                        onChange({ target: { name: 'materialConsumptionsItemsAddMultipleOld', value: newItems } });
                                    }}
                                    itemType={DefaultItemsType.Materials}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid item container direction={"row"} spacing={3}>
                        {row?.materialConsumptions?.materialConsumptions &&
                            row.materialConsumptions?.materialConsumptions.map((el: MaterialConsumption, index: number) => {
                                const currentStandardItem = standardMaterials.find(x => x.id == el.standardItemId);
                                return (
                                    <Grid item md={12} lg={6} xl={4} container direction="row" alignItems="center" spacing={0}>
                                        <Grid item xs={1}>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                onClick={() => {
                                                    onChange({ target: { name: 'materialConsumptionsItemsRemove', value: JSON.stringify({ index: index }) } });
                                                }}
                                            >
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        {currentStandardItem ?
                                            <Grid item xs={8} className={containerStyles.marginFieldSmall}>
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: <InputAdornment variant="filled" position="start">{<Category />}</InputAdornment>,

                                                        endAdornment: <InputAdornment variant="filled" position="end">{currentStandardItem.downloadedPhotoUrl ?
                                                            <LocalMedia src={currentStandardItem.downloadedPhotoUrl.url} contentType={currentStandardItem.downloadedPhotoUrl.type} height={50} />
                                                            : <CircularProgress />}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="materialConsumptionsItems"
                                                    label={t("myUnitsSettings.materialConsumptionDescription")}
                                                    value={currentStandardItem.text || ''}
                                                    disabled />
                                            </Grid>
                                            :
                                            <Grid item xs={8} className={containerStyles.marginFieldSmall}>
                                                <TextFieldGroupSettings
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(el.text || '')}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="materialConsumptionsItems"
                                                    label={t("myUnitsSettings.materialConsumption")}
                                                    value={el.text || ''}
                                                    onChange={(event: any) => {
                                                        let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                        onChange(res);
                                                    }} />
                                            </Grid>
                                        }
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={el.isEnabled}
                                                    onChange={(target: any) => {
                                                        target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                        onChange(target);
                                                    }}
                                                    name="materialConsumptionsItems"
                                                    color="primary" />}
                                                label={t("myUnitsSettings.activated")} />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
