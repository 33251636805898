import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAssignPropertyDialog = ({
  open,
  setOpen,
  userProperty,
  userProperties,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  userProperty: UserProperty,
  userProperties: UserProperty[]
  onRefresh: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
  const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | undefined>(undefined);
  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
  const [token, setToken] = React.useState("");
  const {t} = useTranslation();
  const handleClose = () => {
    setSelectedGroups([]);
    setOpen(false);
  }

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const tempGroups = await api.getAllGroups(tempToken);
      setGroups(tempGroups);

    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Wähle Einheiten die dem Merkmal ${userProperty.name} zugewiesen werden sollen`}
        handleClose={handleClose}
      />

      <DialogContent>
        <GroupSelectionTable
          selectedPropertyId={userProperty.id}
          properties={userProperties}
          groups={groups}
          selectedGroups={selectedGroups}
          onSelectionChange={(selection: string[]) => {
            setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ backgroundColor: errorColor, color: white }}
          endIcon={<Delete />}
          onClick={() => {

            selectedGroups.forEach(x => api.unAssignPropertyToGroup(x.id, userProperty.id, token, enqueueSnackbar))
            handleClose();
            onRefresh();
          }}
        >
          Ausgewählte Einheiten vom Merkmal entfernen
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Add />}
          onClick={() => {

            selectedGroups.forEach(x => api.assignPropertyToGroup(x.id, userProperty.id, token, enqueueSnackbar))
            handleClose();
            onRefresh();
          }}
        >
          Ausgewählte Einheiten zum Merkmal zuweisen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));