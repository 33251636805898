import axios from "axios";
import { multipartHeaders } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { UserSavedCombinationGroup } from "../models/UserSavedCombinationGroup";

export const updateUserSavedCombinationGroupLogo = async (logo: File, id: string, token: string, enqueueSnackbar: any): Promise<UserSavedCombinationGroup> => {
    try {

        const userId = getUserIdFromStorage();

        const formData = new FormData();
        formData.append('body', logo);

        let uri: string = '';
        uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/saved-combination-group/${id}/images/logos`;

        const ret = await axios.post(uri, formData, { headers: { ...multipartHeaders, 'Authorization': `Bearer ${token}` } });

        enqueueSnackbar("Logo wurde erfolgreich hochgeladen", { variant: 'success' });

        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Logo kann nicht hochgeladen werden", { variant: 'error' });
    }
    return null as any;
}
