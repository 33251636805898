import axios from 'axios';
import { api, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { BackendErrorCodes } from '../models/ErrorCodes';
import { GroupSettings, GroupSettingsUpdateType } from '../models/GroupSettings';

export const addBssdSensorToGroup = async (groupId: string, deviceId: string, sensorLicenseId: string,token: string, enqueueSnackbar: any): Promise<GroupSettings> => {

    const userId = getUserIdFromStorage();
    return await axios
        .patch(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/devices/${deviceId}/sensor-licenses/${sensorLicenseId}/add-bssd-sensor-to-group`,{},
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Sensor hinzugefügt", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            if (error.response.data.code == BackendErrorCodes.ForbiddenDataRestricted) {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
            } else {
                enqueueSnackbar("Error beim Sensor hinzufügen", { variant: 'error' });
            }
        });
}