import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const unAssignPropertyToGroup = async (groupId: string, propertyId: string, token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    let userId = getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/property/${propertyId}/un-assign`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Merkmal wurde erfolgreich entfernt", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Entfernung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};

export const unAssignPropertiesFromGroup = async (groupId: string, propertyIds: string [], token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    let userId = getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/properties/un-assign`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` }, data: {stringArray: propertyIds} }
    ).then((res) => {
        enqueueSnackbar("Merkmale wurde erfolgreich entfernt", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Entfernung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};