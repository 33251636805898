import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { GroupSettings } from '../../../../models/GroupSettings';
import { formatEuropeanDate, formatEuropeanDateTime, formatUnixSecTimestampEuropeanDateInMinutes, formatUnixSecToDate, formatUnixSecToTime, formatUnixSecToTimeWithSec } from '../../../../helpers/date-management';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GroupFeedback } from '../../../../models/GroupFeedback';
import { Paper } from '@mui/material';
import { CheckboxCell, FilterCellCheck } from '../../../tables/ServiceModeTable';
import { green, lightGreen, lightRed, red, white, yellow } from '../../../../styles/colors';

export const FeedbackDataTable = ({ rows, groups, selectedEntries, onSelectionChange }
    : { rows: GroupFeedback[], groups: GroupLicenseTable[], selectedEntries: string[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);



    const columns = [
        {
            name: GroupFeedback.Columns.feedbackType,
            title: "Feedback Typ",
            getCellValue: (row: GroupFeedback) => GroupFeedback.GetLabelByType(row.feedbackType),
        },
        {
            name: GroupFeedback.Columns.veryPositiveAmount,
            title: "Sehr Positiv",
            getCellValue: (row: GroupFeedback) => row.veryPositiveAmount,
        },
        {
            name: GroupFeedback.Columns.positiveAmount,
            title: "Positiv",
            getCellValue: (row: GroupFeedback) => row.positiveAmount,
        },
        {
            name: GroupFeedback.Columns.neutralAmount,
            title: "Neutral",
            getCellValue: (row: GroupFeedback) => row.neutralAmount,
        },
        {
            name: GroupFeedback.Columns.negativeAmount,
            title: "Negativ",
            getCellValue: (row: GroupFeedback) => row.negativeAmount,
        },
        {
            name: GroupFeedback.Columns.veryNegativeAmount,
            title: "Sehr Negativ",
            getCellValue: (row: GroupFeedback) => row.veryNegativeAmount,
        },
        {
            name: GroupFeedback.Columns.createdTimestamp,
            title: "Datum",
            getCellValue: (row: GroupFeedback) => formatUnixSecToDate(row.createdTimestamp),
        },
        {
            name: GroupFeedback.Columns.createdTimestampTime,
            title: "Zeitpunkt",
            getCellValue: (row: GroupFeedback) => formatUnixSecToTimeWithSec(row.createdTimestamp),
        },
        {
            name: GroupFeedback.Columns.groupId,
            title: "Einheit",
            getCellValue: (row: GroupFeedback) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: GroupFeedback.Columns.isSummarizedMonth,
            title: "Zusammengefasst",
            getCellValue: (row: GroupFeedback) => row.isSummarizedMonth,
        },
        {
            name: GroupFeedback.Columns.summarizedFrom,
            title: "Start Zusammenfassung",
            getCellValue: (row: GroupFeedback) => row.isSummarizedMonth ? formatEuropeanDateTime(row.summarizedFrom) : "nicht zusammengefasst",
        },
        {
            name: GroupFeedback.Columns.summarizedUntil,
            title: "Ende Zusammenfassung",
            getCellValue: (row: GroupFeedback) => row.isSummarizedMonth ? formatEuropeanDateTime(row.summarizedUntil) : "nicht zusammengefasst",
        },
        {
            name: GroupFeedback.Columns.batteryLevel,
            title: "Batteriezustand",
            getCellValue: (row: GroupFeedback) => row.batteryLevel,
        },

    ];



    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 150 } }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Feedback');
        });
    };

    const Cell = (props: any) => {
        const { column, row } = props;

        if (column.name === GroupFeedback.Columns.isSummarizedMonth) {
            return <CheckboxCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    const FilterCell = (props: any) => {
        const { column } = props;
        if (column.name === GroupFeedback.Columns.isSummarizedMonth) {
            return <FilterCellCheck {...props} />;
        }
        return <TableFilterRow.Cell {...props} />;
    };


    const TableRow = (props: any) => {
        const { row } = props;
        let color = "#000000";

        if (row?.veryPositiveAmount > 0) {
            color = green;
        } else if (row?.positiveAmount > 0) {
            color = lightGreen;
        } else if (row?.neutralAmount > 0) {
            color = yellow
        } else if (row?.negativeAmount > 0) {
            color = lightRed;
        } else if (row?.veryNegativeAmount > 0) {
            color = red;
        }
        return (
            <Table.Row
                {...props}
                // eslint-disable-next-line no-alert
                style={{
                    backgroundColor: color,
                    color: white
                }}
            />
        )
    }; 


    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
                getRowId={x => x.id}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //            sorting={[{ columnName: GroupMotion.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selectedEntries} onSelectionChange={onSelectionChange} />

                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                    rowComponent={TableRow}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow
                    cellComponent={FilterCell}
                />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selectedEntries}
                onSave={onSave}
            />
        </div>
    );
};
