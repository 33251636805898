import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const DeleteGroupAsync = async (groupId: string, token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar)
        return;
    let userId = getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((response) => {
        enqueueSnackbar("Einheit wurde erfolgreich gelöscht", { variant: 'success' });
    }).catch((error) => {
        console.log(JSON.stringify(error, null, 2));
        enqueueSnackbar("Es ist ein Fehler bei der Löschung der Einheit aufgetreten", { variant: 'error' });
    });
};
