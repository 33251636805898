export enum FeedbackType
{
    Device = 0,
    Public = 1,
    Summarized = 2,
    Bssd = 3
}

export class GroupFeedback {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string
    public positiveAmount!: number;
    public negativeAmount!: number;
    public neutralAmount!: number;
    public veryNegativeAmount!: number;
    public veryPositiveAmount!: number;

    public createdTimestamp!: number;
    public createdTimestampString!: string;
    
    public isSummarizedMonth !: boolean;
    public summarizedFrom !: string;
    public summarizedUntil !: string;

    public feedbackType !: FeedbackType;

    public batteryLevel !: number;

    public constructor (groupId: string, positiveAmount: number, negativeAmount: number, neutralAmount: number, createdTimestamp: number) {
        this.systemETag = null;
        this.groupId = groupId;
        this.positiveAmount = positiveAmount;
        this.negativeAmount = negativeAmount;
        this.neutralAmount = neutralAmount;
        this.createdTimestamp = createdTimestamp;
    }

    static Columns = {
        id: 'id',
        feedbackType: 'feedbackType',
        negativeAmount: "negativeAmount",
        veryNegativeAmount: "veryNegativeAmount",
        positiveAmount: "positiveAmount",
        veryPositiveAmount: "veryPositiveAmount",
        neutralAmount: "neutralAmount",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        isSummarizedMonth: "isSummarizedMonth",
        summarizedFrom: "summarizedFrom",
        summarizedUntil: "summarizedUntil",
        batteryLevel: "batteryLevel"

    }

    static AvailableTypes = [
        
        { value: FeedbackType.Device, label: 'Display Gerät' },
        { value: FeedbackType.Public, label: 'Öffentliches Feedback' },
        { value: FeedbackType.Summarized, label: 'Zusammengefasst' },
        { value: FeedbackType.Bssd, label: 'Batterie Display' },


    ];

    static GetLabelByType = (type: FeedbackType) => GroupFeedback.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
}


export class FeedbackTotalTable {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string
    public positiveAmount!: number;
    public negativeAmount!: number;
    public neutralAmount!: number;
    public veryNegativeAmount!: number;
    public veryPositiveAmount!: number;
    public totalAmount !: number;

    public createdTimestamp!: number;
    public createdTimestampString!: string;
    
    public feedbackType !: FeedbackType;

    public constructor (groupId: string, positiveAmount: number, negativeAmount: number, neutralAmount: number) {
        this.systemETag = null; 
        this.groupId = groupId;
        this.positiveAmount = positiveAmount;
        this.negativeAmount = negativeAmount;
        this.neutralAmount = neutralAmount;
        
    }

    static Columns = {
        id: 'id',
        feedbackType: 'feedbackType',
        negativeAmount: "negativeAmount",
        veryNegativeAmount: "veryNegativeAmount",
        positiveAmount: "positiveAmount",
        veryPositiveAmount: "veryPositiveAmount",
        neutralAmount: "neutralAmount",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId",
        levelName: "levelName",
        objectName : "objectName",
        totalAmount: "totalAmount",
        negativeAmountPercent: "negativeAmountPercent",
        veryNegativeAmountPercent: "veryNegativeAmountPercent",
        positiveAmountPercent: "positiveAmountPercent",
        veryPositiveAmountPercent: "veryPositiveAmountPercent",
        neutralAmountPercent: "neutralAmountPercent",

    }

    static AvailableTypes = [
        
        { value: FeedbackType.Device, label: 'Display Gerät' },
        { value: FeedbackType.Public, label: 'Öffentliches Feedback' },
        { value: FeedbackType.Summarized, label: 'Zusammengefasst' },
        { value: FeedbackType.Bssd, label: 'Batterie Display' },


    ];

    static GetLabelByType = (type: FeedbackType) => GroupFeedback.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
}
