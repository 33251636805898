import axios from 'axios';
import { headers } from "../api";

export const changeUserEmailAsync = async (userId: string, requestId: string): Promise<boolean> => {

    try {
        await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/email/change/${requestId}`,
            null,
            { headers: headers }
        );

        return true;

    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        return false;
    }
}