import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { IntervalControl } from '../models/IntervalControl';


export const GetIntervalControlByUser = async (token: string): Promise<IntervalControl[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/interval-controls`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;
}