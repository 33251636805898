import axios from 'axios';
import { saveAs } from 'file-saver';
import { headers } from '../api';


export const DownloadObjectFileAsync = async (objectId: string, pathNameWithFilename: string, token: string, enqueueSnackbar: any, filename: string, preview = false): Promise<any> => {

    if (!objectId || !token || !enqueueSnackbar)
        return "";

    try {
        const ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/file/download`, { text: pathNameWithFilename },
        {
            responseType: 'arraybuffer',
            headers: { ...headers, 'Authorization': `Bearer ${token}` }
        });
        enqueueSnackbar("Objekt Datei wurde erfolgreich gedownloaded", { variant: 'success' });
        console.log(ret.data);
        const blob = new Blob([ret.data],{type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        if (!preview) {
            saveAs(blob, filename);
        }
        return {url: url, type: blob.type};
    }catch(error) {
        console.log(JSON.stringify(error, null, 2));
        enqueueSnackbar("Es ist ein Fehler beim downloaden der Objekt Datei aufgetreten", { variant: 'error' });
        return "error";
    }



};
