import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle, CircularProgress, DialogActions, DialogContent, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { DigitalCheckpointConfigLoggedIn } from './DigitalCheckpointConfigLoggedIn';
import { GroupSettings } from '../../../models/GroupSettings';
import { Check } from '@mui/icons-material';
import { ObjectUnit } from '../../../models/ObjectUnit';

export const DigitalCheckpointDialog = ({ open, setOpen, onRefresh, groupSettings, objectUnit }
    : { open: boolean, setOpen: any, onRefresh: any, groupSettings: GroupSettings | undefined, objectUnit : ObjectUnit | undefined }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [hubId, setHubId] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"lg"}
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    {groupSettings ? "Digital Checkpoint einrichten" : "Digitales Objekt einrichten"}
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <Grid container direction={'row'} alignItems="center" spacing={3}>
                        <Grid item xs={12}>
                            <Alert severity="warning">
                                <AlertTitle>Verbinden Sie sich mit dem Gerät via Bluetooth</AlertTitle>
                                Um das {groupSettings ? "Digital Checkpoint" : "Digitale Objekt"} zu konfigurieren, brauchen Sie mit dem Gerät eine Bluetoothverbindung (Koppeln)
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <DigitalCheckpointConfigLoggedIn objectUnit={objectUnit} groupSettings={groupSettings} visible={open} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<Check />}
                        endIcon={loading && <CircularProgress size={32}/>}
                        variant={'contained'}
                        color="primary"
                        disabled={loading}
                        onClick={async () => {
                            handleClose();
                        }}
                    >
                        Fertig!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));