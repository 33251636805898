import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupCleanerFeedback } from '../models/GroupCleanerFeedback';


export const getCleanerFeedbacksByGroup = async (token: string, groupId: string): Promise<Array<GroupCleanerFeedback>> => {
    try {
        const userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/groups/${groupId}/users/${userId}/cleaner-feedback?${getQueryParamFromApiDataAmountType()}`, {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err);
    }
    return null as any;

}