import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, ValueAxis, SeriesTemplate, Title, Subtitle, Tooltip, Size } from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { chartHeight, customizeLabelWithPercent, customizeLabel, customizeTooltip, NoDataChartLoader } from './Base';
import React from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { ChartHeightField, FullscreenButton } from './StackedChart';
import { FullScreenChartDialog } from './dialog/FullScreenChartDialog';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});

export const StandardBarChartDraw = (props: any) => {
    const [isFullScreenChartVisible, setIsFullscreenChartVisible] = React.useState(false);

    const [chartHeightNow, setChartHeightNow] = React.useState(chartHeight);
    const { data, text, name, loadingCompleted } = props;
    data.reduce((s: any, p: any) => s + p.originalValue, 0);

    if (data.length === 0)
        return <NoDataChartLoader title={text} data={data} loadingCompleted={loadingCompleted} />;



    const buildChart = () => {
        return (
            <>
                <Chart
                    ref={props.childRefChart}
                    dataSource={data}
                    id="chart"
                    palette={props.colors || "Material"}
                >
                    <Title text={text}>
                        <Subtitle font={{ color: "red", weight: "500" }} text={`Gesamt: ${barChartTotal(data)}`} />
                    </Title>
                    <CommonSeriesSettings
                        argumentField="key"
                        valueField="value"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                        ignoreEmptyPoints={true}
                    >
                        <Label visible={true}
                            customizeText={customizeLabel}>
                            <Format type="fixedPoint" precision={0} />
                        </Label>
                    </CommonSeriesSettings>
                    <Size
                        height={chartHeightNow}
                        width={'100%'}
                    />
                    <SeriesTemplate nameField="key" />

                    <ValueAxis allowDecimals={false} />
                    <Legend verticalAlignment="right" horizontalAlignment="right">
                    </Legend>
                    <Export enabled={true} />
                    <Tooltip
                        enabled={true}
                        location="edge"
                        customizeTooltip={customizeTooltip}
                    />
                </Chart>
                <MuiGrid container direction="row" spacing={3} alignItems="center">
                    <MuiGrid item>
                        <ChartHeightField chartHeight={chartHeightNow} setChartHeight={setChartHeightNow} />
                    </MuiGrid>
                    <MuiGrid item>
                        <FullscreenButton isFullScreenVisible={isFullScreenChartVisible} setIsFullscreenChartVisible={setIsFullscreenChartVisible} setChartHeight={setChartHeightNow} />
                    </MuiGrid>
                </MuiGrid>
            </>
        );
    }

    return isFullScreenChartVisible ? <FullScreenChartDialog setChartHeight={setChartHeightNow} visible={isFullScreenChartVisible} title={text} onClose={() => { setIsFullscreenChartVisible(false) }} buildChart={buildChart} /> : buildChart();



}

const barChartTotal = (data: any) => {
    return data.reduce((s: any, p: any) => s + p.value, 0);
}

export const StandardBarChart = withStyles(demoStyles, { name: 'Demo' })(StandardBarChartDraw);