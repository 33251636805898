import React from 'react';
import { Button, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Product } from '../../../models/Product';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../helpers/storage-management';
import { Add, Downloading } from '@mui/icons-material';
import { ProductManagementPageEditingDialog } from '../ProductManagementPageEditingDialog';
import { ConfirmationDialog } from '../../dialogs/ConfirmationDialog';
import { api } from '../../../api';
import { useSnackbar } from 'notistack';
import { ProductManagementPageDataTable } from '../ProductManagementPageDataTable';
import { UserManagementPageDataTable } from './UserManagementPageDataTable';
import { UserAccount } from '../../../models/UserAccount';
import { UserManagementPageEditingDialog } from './UserManagementPageEditingDialog';
import { GroupLicenseTable, UserManagementGroups } from '../../../models/GroupLicenseTable';
import { StandardWorkItem } from '../../../models/StandardItem';
import { StandardItemEditingDialog } from './StandardItemEditingDialog';
import { StandardItemDataTable } from './StandardItemDataTable';
import { formatISO } from 'date-fns';
import { DownloadFileImageDialog } from './DownloadFileImageDialog';
import { FileBlobType } from '../../../models/FileBlobType';
import { AdminSettingsDataTable } from './AdminSettingsDataTable';
import { AdminSetting } from '../../../models/AdminSetting';
import { AdminSettingsEditingDialog } from './AdminSettingsEditingDialog';

export const AdminSettingsPage = () => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [standarditems, setStandardItems] = React.useState<AdminSetting[]>([]);
    const [addSettingDialogVisible, setAddSettingDialogVisible] = React.useState(false);
    const [updateSettingDialogVisible, setUpdateSettingDialogVisible] = React.useState(false);

    const [selectedStandardItem, setSelectedStandardItem] = React.useState<AdminSetting>();
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [groups, setGroups] = React.useState<UserManagementGroups[]>([]);
    const [users, setUsers] = React.useState<UserAccount[]>([]);
    const [currentSelectedWorkingItem, setCurrentSelectedWorkingItem] = React.useState<AdminSetting>();
    const [showDownloadDialog, setShowDownloadDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);


    const loadData = async () => {
        const tempToken = getApiToken(history);
        if (!tempToken) return;

        setToken(tempToken);
        setLoading(true);

        let standardItems = await api.GetAdminSettings(tempToken);
        setStandardItems([...standardItems]);

        setLoading(false);

        const tempUsers = await api.getAllUsersInclusiveNonActivatedByAdmin(tempToken);
        setUsers(tempUsers);
        setStandardItems([...standardItems]);

    }


    React.useEffect(() => {

        (async () => {

            loadData();

        })();

    }, [history])

    const deleteStandardItem = async () => {
        if (!selectedStandardItem) return;
        //       await api.DeleteStandardItemAsync(selectedStandardItem, token, enqueueSnackbar);

    }

    const addStandardItem = async (standarditem: AdminSetting) => {
        const added = await api.CreateAdminSetting(standarditem, token, enqueueSnackbar);
    }

    const onShowImages = (row: AdminSetting) => {
        setCurrentSelectedWorkingItem(row);
        setShowDownloadDialog(true);
    }


    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Admin Settings
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={3}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={async () => {
                            setSelectedStandardItem(new AdminSetting());
                            setAddSettingDialogVisible(true);
                        }}
                    >
                        Setting hinzufügen
                    </Button>
                </Grid>
            </Grid>
            {loading &&
                <Grid item xs={12}>
                    <CircularProgress size={64} />
                </Grid>
            }
            <Grid item xs={12}>
                <Paper>
                    <AdminSettingsDataTable users={users} rows={standarditems}
                        onShowImages={onShowImages}
                        onEdit={function (row: AdminSetting): any {
                            setSelectedStandardItem(row);
                            setUpdateSettingDialogVisible(true);
                        }} onDelete={(row: AdminSetting) => {
                            setSelectedStandardItem(row);
                            setDeleteConfirmationDialogVisible(true);
                        }} />
                </Paper>
            </Grid>

            <AdminSettingsEditingDialog
                onShowImages={onShowImages}
                visible={addSettingDialogVisible}
                setVisible={setAddSettingDialogVisible}
                currentAdminSettings={selectedStandardItem}
                onSave={async (standarditem: AdminSetting) => {
                    standarditem.createdTimestamp = formatISO(new Date());
                    await addStandardItem(standarditem);
                    loadData();
                }}
            />

            <AdminSettingsEditingDialog
                onShowImages={onShowImages}
                visible={updateSettingDialogVisible}
                setVisible={setUpdateSettingDialogVisible}
                currentAdminSettings={selectedStandardItem}
                onSave={async (row: AdminSetting) => {

                    await addStandardItem(row);
                    loadData();
                    //    const updated = await api.updateUserFromAdmin(user, token, enqueueSnackbar);

                    //    if (!updated) return;
                }}
            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title="Sind Sie sicher, dieses StandardItem zu löschen ?"
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {

                    await deleteStandardItem();
                    loadData();
                    setDeleteConfirmationDialogVisible(false);

                }}
            />

        </Grid>
    );
};