
export class UserAccountBasic {

    public id: string = '';
    public systemETag: string = '';
    public systemTimestamp: string = '';
    public mail: string = '';
    public customerNumber! : string;
    public title: string = '';
    public firstname: string = '';
    public lastname: string = '';
    public company: string = '';
    public uid: string = '';
    public atu: string = '';
}
