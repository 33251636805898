import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, FilterAlt, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { UserServiceWorkerSelectionDataTable } from './UserServiceWorkerSelectionDataTable';
import { useTranslation } from 'react-i18next';

export const FilterServiceWorkerDialog = ({
  open,
  setOpen,
  groups,
  setGroups,
  userServiceWorkers,
  selectedUserServiceWorkers,
  setSelectedUserServiceWorkers
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable[],
  setGroups: any,
  userServiceWorkers: UserServiceWorker[],
  selectedUserServiceWorkers: UserServiceWorker[],
  setSelectedUserServiceWorkers: any
}) => {

  const {t} = useTranslation();


  const [selection, setSelection] = React.useState<string[]>([]);
  const [isExactFilteringEnabled, setIsExactFilteringEnabled] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Filter Einheiten nach Servicekräften</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <UserServiceWorkerSelectionDataTable rows={userServiceWorkers} groups={groups} selectedUserServiceWorkers={selectedUserServiceWorkers}
          onSelectionChange={(selection: string[]) => {
            setSelectedUserServiceWorkers(userServiceWorkers.filter(x => selection.some(y => x.id == y)));
            setSelection(selection);
          }}
          currentGroup={null} objects={[]} />
      </DialogContent>

      <DialogActions>
        <FormControlLabel
          control={<Checkbox
            checked={isExactFilteringEnabled}
            onChange={(event: any) => {
              setIsExactFilteringEnabled(event.target.value);
            }}
            name="isDeviceRestartTimeEnabled"
            color="primary" />}
          label="Exakte Filterung" />
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Delete />}
          onClick={async () => {
            handleClose();
          }}
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<FilterAlt />}
          onClick={async () => {
            if (!isExactFilteringEnabled){
              setGroups(groups.filter(x => x.userServiceWorkerIds.some(y => selection.some(z => z == y))));
            }else{
              setGroups(groups.filter(x => selection.every(selectedItem=> x.userServiceWorkerIds.includes(selectedItem))));
            }
            handleClose();
          }}
        >
          Filtern
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));