import React from 'react';
import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Edit, NavigateNext, Save, SwitchCamera } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { GroupSettings } from '../../../models/GroupSettings';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { ApiOfflineDataModel } from '../../../models/ApiOfflineDataModel';
import { OfflineDataTable } from './OfflineDataTable';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { useTranslation } from 'react-i18next';

export const OfflineDataDialog = ({ visible, setVisible, group }
    : { visible: boolean, setVisible: any, group: GroupSettings }) => {


    const onClose = () => {
        setVisible(false);
    }
    const {t} = useTranslation();
    const [offlineData, setOfflineData] = React.useState<ApiOfflineDataModel>();

    const history = useHistory();

    React.useEffect(() => {
        (async () => {
            const tempToken = getApiToken(history);
            const offlineData = await api.getOfflineDataByGroupId(tempToken, group.id || "");
            if (offlineData.length > 0) {
                setOfflineData(offlineData[0]);
            }
        })();
    }, [visible])


    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="lg">
            <DefaultDialogTitle
                title={`Einheit ${group.locationInformation?.notificationName} Offline Daten`}
                handleClose={onClose}
            />

            <DialogContent>
                {offlineData?.offlineDataContent &&
                    <OfflineDataTable offlineData={offlineData?.offlineDataContent} groupSettings={group}/>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.close")}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);


