import React from 'react';
import {
    Checkbox,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    AppBar,
    Toolbar,
    Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel, Group, Save } from '@mui/icons-material';
import { GroupSettingsMappingKeys } from '../../helpers/group-settings-management';
import { GroupSettings, NotificationTelegramGroup } from '../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { useTranslation } from 'react-i18next';

export const ChooseTelegramGroupDialog = ({ open, setOpen, items, row, onChange }
    : { open: boolean; setOpen: any; items: NotificationTelegramGroup[]; row: GroupSettings | UserGroupSettingsTemplate; onChange: any }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const [selection, setSelection] = React.useState<NotificationTelegramGroup | null>(null);

    const onClose = () => setOpen(false);

    React.useEffect(() => {
        (async () => {
            setSelection(row?.notification?.notificationTelegramGroup ?? null);
        })();
    }, [open]);

    return (
        <Dialog fullWidth maxWidth={"sm"} onClose={onClose} open={open}>
            <DialogTitle className={classes.dialogTitle}>
                <AppBar className={classes.dialogTitleAppBar} position="sticky">
                    <Toolbar>
                        <Typography className={classes.dialogTitleAppBarTitle} variant="h6" noWrap>
                            Gruppenauswahl
                        </Typography>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <List className={classes.root}>
                    {
                        items &&
                        items.length > 0 &&
                        items.map((item: NotificationTelegramGroup, index: number) => {
                            const labelId = `checkbox-list-label-${item.title}`;

                            return (
                                <ListItem
                                    key={index}
                                    button
                                    onClick={() => {
                                        setSelection(item);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selection?.id === item.id}
                                            tabIndex={-1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${item.title}`} />
                                    <ListItemIcon>
                                        <Group />
                                    </ListItemIcon>
                                </ListItem>
                            );
                        })
                    }
                    {
                        (!items || items.length === 0) &&
                        <div className={classes.empty}>
                            <Typography style={{ textAlign: 'center' }}>Keine Gruppe angelegt</Typography>
                        </div>
                    }
                </List>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    size="large"
                    startIcon={<Cancel />}
                    variant={'outlined'}
                    onClick={onClose}
                    color="primary"
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    size="large"
                    startIcon={<Save />}
                    variant={'contained'}
                    color="primary"
                    onClick={() => {
                        if (selection)
                            onChange({
                                target: {
                                    name: GroupSettingsMappingKeys.notificationTelegramGroups,
                                    value: JSON.stringify({ notificationTelegramGroup: selection, systemETag: row?.systemETag })
                                }
                            });

                        onClose();
                    }}
                >
                    Übernehmen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        dialogTitle: {
            padding: 0,
        },
        dialogTitleAppBar: {
            flexGrow: 1,
        },
        dialogTitleAppBarTitle: {
            flexGrow: 1,
        },
        empty: {
            width: '100%',
            height: 150,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        dialogContent: {
            padding: theme.spacing(2),
        },
        dialogActions: {
            margin: 0,
            padding: theme.spacing(1),
        }
    }),
);
