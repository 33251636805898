import axios from 'axios';
import { headers } from '../../api';
import { getOwnUserIdFromStorage, getUserIdFromStorage } from '../../helpers/common';
import { BssdRegisteredDeviceModel } from '../../models/bssd/BssdRegisteredDeviceModel';
import { SsdDeviceModel } from '../../models/bssd/SsdDeviceModel';
import { GroupCleanerFeedback } from '../../models/GroupCleanerFeedback';
import { HubModel } from '../../models/HubModel';
import { LoraDeviceModel } from '../../models/lora/LoraDeviceModel';

export const getAllLoraDevices = async (token: string): Promise<Array<LoraDeviceModel>> => {

    const userId = getOwnUserIdFromStorage();

    try {
        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/all-lora-devices`,{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        return [];
    }
}