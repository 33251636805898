import { Grid, List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Business, LocationOn, Person, Phone } from '@mui/icons-material';
import { UserAddress } from '../../../../models/UserAddress';

export const ReviewAddress = ({ address, title }: { address: UserAddress | null | undefined; title: string; }) => {

    const classes = useStyles();

    if (!address) return null;

    return (
        <Grid container spacing={2} className={classes.root}>

            <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
            </Grid>

            <Grid item xs={12}>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary={`${address.firstName} ${address.lastName}`} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText primary={`${address.company} ${address.uid} ${address.atu}`} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LocationOn />
                        </ListItemIcon>
                        <ListItemText primary={address.addressLine} />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <LocationOn />
                        </ListItemIcon>
                        <ListItemText primary={`${address.zipCode} ${address.city} - ${address.country}`} />
                    </ListItem>

                    {
                        address.phoneNumber &&
                        <ListItem>
                            <ListItemIcon>
                                <Phone />
                            </ListItemIcon>
                            <ListItemText primary={address.phoneNumber} />
                        </ListItem>
                    }

                </List>
            </Grid>

        </Grid>

    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    title: {
        fontWeight: 'bold',
    },
}));
