import { MaterialStock, StockActionDoneFromType, UserStock } from "./UserStock";


export class UserStockExpanded extends UserStock{

    public materialStock !: MaterialStock;
    public materialText !: string;

    static CreateInstance(userStock: UserStock, materialStock: MaterialStock) {
        let ret = new UserStockExpanded(userStock.userId, userStock.objectId, userStock.groupId, userStock.userServiceWorkerId, userStock.stockActionDoneFromType, userStock.reason, materialStock, userStock.restockObjectCount, userStock.restockGroupCount );
        ret.createdTimestamp = userStock.createdTimestamp;
        return ret;
    }

    constructor(userId: string, objectId: string, groupId: string, userServiceWorkerId: string,
        stockActionDoneFromType: StockActionDoneFromType, reason: string, materialStock: MaterialStock, restockObjectCount: number, restockGroupCount: number) {
        super(userId, objectId, groupId, userServiceWorkerId, stockActionDoneFromType, reason, [], restockObjectCount, restockGroupCount);
        this.materialStock = materialStock;
    }

    static Columns = {
        objectId: 'objectId',
        userId: "userId",
        groupId: "groupId",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        userServiceWorkerId: "userServiceWorkerId",
        reason: "reason",
        restockObjectCount: "restockObjectCount",
        restockGroupCount: "restockGroupCount",
        stockActionDoneFromType: "stockActionDoneFromType",

        currentObjectStock : "currentObjectStock",
        lastObjectStock: "lastObjectStock",
        diffObjectStock: "diffObjectStock",
        objectStockNumber : "objectStockNumber",

        currentGroupStock : "currentGroupStock",
        lastGroupStock : "lastGroupStock",
        diffGroupStock : "diffGroupStock",
        groupStockNumber: "groupStockNumber",
        groupImages: "groupImages",
        objectImages : "objectImages",

        materials: "materials",

    }

}