import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import { OrderOptionOverViewTable } from './OrderOptionOverViewTable';
import { CheckoutItem } from '../../../../models/CheckoutItem';
import { Product } from './../../../../models/Product';
export const ReviewOption = ({ items, products, setItems}
  : { items: CheckoutItem[], products: Product[], setItems: React.Dispatch<React.SetStateAction<CheckoutItem[]>> }) => {

  const classes = useStyles();

  return (
    <Grid container className={classes.root} style={{ display: 'block' }}>

      <Grid item xs={12}>
        <OrderOptionOverViewTable 
          items={items} 
          products={products}
          setItems={setItems}
        />
      </Grid>

      <Grid item container xs={12} direction="row">
        <Grid item xs={6}>
          
        </Grid>
        <Grid item xs={6}>
          
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));


