import axios from 'axios';
import { USER_ID } from '../../constants';
import { handleApiError, headers } from '../../api';
import { GroupSettings } from '../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { Session } from '../../models/Session';


export const verifyEmail = async (userId: string, tokenId: string) : Promise<Session | undefined> => {
    try {
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/auth/verify-email`,
        {
            userId: userId,
            tokenId: tokenId
        },
        {headers: {...headers}})
        return ret.data;
    } catch (err) {
        handleApiError(err);
    }
    return null as any;
}