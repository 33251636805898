import React, { forwardRef } from 'react';
import { AccordionSummary, Grid, Typography, AccordionDetails, TextField, FormControlLabel, Checkbox, Button, Accordion, Divider, Alert } from '@mui/material';
import { ExpandMore, Send, Add, Group as GroupIcon, Edit, Sync, Visibility, SupervisedUserCircle } from '@mui/icons-material';
import { api } from '../../api';
import { GroupSettings, GroupSettingsType, NotificationTelegramGroup } from '../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { GroupSettingFieldsUseStyles } from '../../styles/GroupSettingFieldsStyles';
import { ReactComponent as CardAccountMail } from '../../assets/svgs/card-account-mail.svg'
import { EditTelegramGroupDialog } from '../dialogs/EditTelegramGroupDialog';
import { CreateTelegramGroupDialog } from '../dialogs/CreateTelegramGroupDialog';
import { MakeAuthTelegramCodeDialog } from '../dialogs/MakeAuthTelegramCode';
import { GroupSettingsMappingKeys } from '../../helpers/group-settings-management';
import { ChooseTelegramGroupDialog } from './ChooseTelegramGroupDialog';
import { ReactComponent as MotionSensorSvg } from '../../assets/svgs/motion-sensor.svg';
import { ReactComponent as ChartBellCurve } from '../../assets/svgs/chart-bell-curve.svg';
import { ReactComponent as EmoticonNeutalSmileSvg } from '../../assets/svgs/emoticon-neutral-outline.svg'

import makeStyles from '@mui/styles/makeStyles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Paper from '@mui/material/Paper';
import PhoneInput from 'react-phone-number-input'
import PhoneInputTextFieldTelegramAdmin from '../common/PhoneInputTextFieldTelegramAdmin';
import { SelectObjectLeaderForGroupNotificationDialog } from './dialogServiceWorker/SelectObjectLeaderForGroupNotificationDialog';
import { ShowAssignedObjectLeaderEmailDialog } from './dialogServiceWorker/ShowAssignedObjectLeaderEmailDialog';
import { Group, GroupType } from '../../models/Group';
import { TextFieldGroupSettings } from './components/groupSettings/TextFieldGroupSettings';
import { useTranslation } from 'react-i18next';


export const GroupSettingFieldsNotificationSettings = ({ group, row, onChange, token, enqueueSnackbar, isLoadingSendCode, setIsLoadingSendCode, type, originUsers, setOriginUsers }
    : { group: Group, row: GroupSettings | UserGroupSettingsTemplate, onChange: any, token: string, enqueueSnackbar: any, isLoadingSendCode: boolean, setIsLoadingSendCode: any, type: GroupSettingsType, originUsers: any, setOriginUsers: any }) => {

    const classes = GroupSettingFieldsUseStyles();

    const [makeAuthDialogVisible, setMakeAuthDialogVisible] = React.useState(false);
    const [authHash, setAuthHash] = React.useState("");
    const [groups, setGroups] = React.useState<NotificationTelegramGroup[]>([]);
    const [groupChooserDialogVisible, setGroupChooserDialogVisible] = React.useState(false);
    const [createGroupDialogVisible, setCreateGroupDialogVisible] = React.useState(false);
    const [editGroupChatListDialogVisible, setEditGroupChatListDialogVisible] = React.useState(false);
    const { t } = useTranslation();

    const hasValidPhoneNumber = (): boolean => {
        if (!row.notification?.notificationTelegramGroup?.phoneNumber) {
            enqueueSnackbar("Ungültige Telefonnummer. Es konnte kein Code gesendet werden");
            return false;
        }

        return true;
    }

    const loadGroupChatsAsync = async () => {
        setIsLoadingSendCode(true);
        const groupChatsTemp = await api.telegramGetGroupChatsAsync(row.notification?.notificationTelegramGroup.phoneNumber as string, token, enqueueSnackbar);
        setGroups(groupChatsTemp);
    }

    const [openSelectObjectLeaderForGroupNotificationDialog, setOpenSelectObjectLeaderForGroupNotificationDialog] = React.useState(false);

    const updateSettings = () => {
        onChange({ target: { name: "row", value: "" } });
    }
    return (
        <Accordion className={classes.hover}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <CardAccountMail
                            width={40}
                            height={40}
                            fill={"#000000"}
                        />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage.notificationSetting")}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>

                    <Grid item xs={12} lg={12} container direction={"column"} spacing={2} justifyContent="center" >
                        <Grid item>
                            <Typography variant='h5'>{t("myUnitsSettings.objectManagerInfoServices")}</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Edit />}
                                variant={'contained'}
                                disabled={isLoadingSendCode}
                                color="primary"
                                onClick={async () => {
                                    setOpenSelectObjectLeaderForGroupNotificationDialog(true);
                                }}
                            >
                                {t("myUnitsSettings.manageObjectManagerNotification")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant='h5'>{t("myUnitsSettings.pushNotificationTelegramMessageTrigger")}</Typography>
                        </Grid>

                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={4} container direction="row" alignItems="center" spacing={1}>
                                <Grid item>
                                    <FormControlLabel
                                        label={t("myUnitsSettings.frequencyMessagesEnabled")}
                                        control={
                                            <Checkbox
                                                name="isMotionNotificationEnabled"
                                                color="primary"
                                                checked={row.notification?.isMotionNotificationEnabled}
                                                onChange={(event: any, checked: boolean) => {
                                                    event.target.value = checked;
                                                    onChange(event);
                                                }}
                                            />
                                        }
                                    />

                                </Grid>
                                <Grid item>
                                    <MotionSensorSvg width={40} height={40} fill={"#000000"} />
                                </Grid>
                            </Grid>
                            <Grid item xs={4} container direction="row" alignItems="center" spacing={1}>
                                <Grid item>
                                    <FormControlLabel
                                        label={t("myUnitsSettings.intervalMessageTriggerEnabled")}
                                        control={
                                            <Checkbox
                                                name="isIntervalNotificationEnabled"
                                                color="primary"
                                                checked={row.notification?.isIntervalNotificationEnabled}
                                                onChange={(event: any, checked: boolean) => {
                                                    event.target.value = checked;
                                                    onChange(event);
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <ChartBellCurve width={40} height={40} fill={"#000000"} />
                                </Grid>
                            </Grid>
                            <Grid item xs={4} container direction="row" alignItems="center" spacing={1}>
                                <Grid item>
                                    <FormControlLabel
                                        label={t("myUnitsSettings.feedbackMessageTriggerEnabled")}
                                        control={
                                            <Checkbox
                                                name="isFeedbackNotificationEnabled"
                                                color="primary"
                                                checked={row.notification?.isFeedbackNotificationEnabled}
                                                onChange={(event: any, checked: boolean) => {
                                                    event.target.value = checked;
                                                    onChange(event);
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <EmoticonNeutalSmileSvg width={40} height={40} fill={"#000000"} />
                                </Grid>
                            </Grid>
                            <MissingMessagesCheckboxes onChange={onChange} row={row} />
                        </Grid>

                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    label={t("myUnitsSettings.automaticServiceChatMessageStartEndCancelledEnabled")}
                                    control={
                                        <Checkbox
                                            name="isServiceNotificationEnabled"
                                            color="primary"
                                            checked={row.notification?.isServiceNotificationEnabled}
                                            onChange={(event: any, checked: boolean) => {
                                                event.target.value = checked;
                                                onChange(event);
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant='h5'>{t("myUnitsSettings.emailInfoServices")}</Typography>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item >
                                <Alert severity="info">{t("myUnitsSettings.assignedObjectManagersReceiveAnAutomaticEmailWhenActivated")}</Alert>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<SupervisedUserCircle />}
                                    disabled={false}
                                    onClick={async () => {

                                    }}
                                >
                                    {t("myUnitsSettings.showCurrentObjectManagers")}
                                </Button>
                            </Grid>
                        </Grid>
                        {(!(group.groupType == GroupType.Checkpoint || group.groupType == GroupType.DigitalCheckpoint)) &&
                            <Grid item container direction="row" alignItems="center" spacing={1}>
                                <Grid item xs={6}>
                                    <TextFieldGroupSettings
                                        fullWidth
                                        variant={"filled"}
                                        name="onlineStatusEmail"
                                        label={t("myUnitsSettings.onlineStatusEmail")}
                                        value={row.notification?.onlineStatusEmail}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={row.notification?.isOnlineStatusEmailEnabled}
                                                onChange={(event: any) => {
                                                    event.target['value'] = event.target.checked;
                                                    onChange(event);
                                                }}
                                                name="isOnlineStatusEmailEnabled"
                                                color="primary"
                                            />
                                        }
                                        label={t("myUnitsSettings.onlineStatusDailyReportEnabled")}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={row.notification?.isOnlineStatusWarnEmailEnabled}
                                                onChange={(event: any) => {
                                                    event.target['value'] = event.target.checked;
                                                    onChange(event);
                                                }}
                                                name="isOnlineStatusWarnEmailEnabled"
                                                color="primary"
                                            />
                                        }
                                        label={t("myUnitsSettings.warningEmailAfterDeviceOfflineFor24HoursEnabled")}
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={6}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="cleaningEmail"
                                    label={t("myUnitsSettings.cleaningEmail")}
                                    value={row.notification?.cleaningEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isCleaningEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="cleaningEmailIsEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.activated")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={6}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="incidentReportEmail"
                                    label={t("myUnitsSettings.incidentReportEmail")}
                                    value={row.notification?.incidentReportEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isIncidentReportEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isIncidentReportEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.activated")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={6}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="freeTextIncidentEmail"
                                    label={t("myUnitsSettings.freeTextIncidentEmail")}
                                    value={row.notification?.freeTextIncidentEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFreeTextIncidentEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFreeTextIncidentEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.activated")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={6}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="feedbackQuestionEmail"
                                    label={`${t("myUnitsSettings.feedbackQuestions")}email`}
                                    value={row.notification?.feedbackQuestionEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFeedbackQuestionEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFeedbackQuestionEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.activated")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={6}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="freeTextFeedbackEmail"
                                    label={`${t("myUnitsSettings.freetext")}feedbackemail`}
                                    value={row.notification?.freeTextFeedbackEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFreeTextFeedbackEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFreeTextFeedbackEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.activated")}
                                />
                            </Grid>

                        </Grid>

                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={4}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="missedServiceEmail"
                                    label={t("myUnitsSettings.emailForMissedServices")}
                                    value={row.notification?.missedServiceEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isMissedServiceEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isMissedServiceEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.missedServicesReportEnabledEmail")}
                                />
                            </Grid>
                            <Grid item xs={6} container direction="row" alignItems="center" spacing={1}>
                                <MissingMessagesCheckboxes onChange={onChange} row={row} />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant='h5'>{t("myUnitsSettings.emailAutomatedInfoServices")}</Typography>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="frequencyAutoEmail"
                                    label={t("myUnitsSettings.emailForAutomatedFrequencyReports")}
                                    value={row.notification?.frequencyAutoEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFrequencyDailyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFrequencyDailyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.dailyFrequencyReportsEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFrequencyWeeklyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFrequencyWeeklyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.weeklyMonday0000FrequencyReportsEnabled")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFrequencyMonthlyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFrequencyMonthlyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.monthlyFrequencyReportsEnabled")}
                                />
                            </Grid>
                        </Grid>



                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="serviceTriggerAutoEmail"
                                    label={t("myUnitsSettings.emailForAutomatedServiceTriggerReports")}
                                    value={row.notification?.serviceTriggerAutoEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isServiceTriggerAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isServiceTriggerAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.emailForEachIndividualServiceTriggerEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isServiceTriggerDailyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isServiceTriggerDailyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.dailyServiceTriggerReportsEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isServiceTriggerWeeklyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isServiceTriggerWeeklyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.weeklyMonday0000ServiceTriggerReportsEnabled")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isServiceTriggerMonthlyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isServiceTriggerMonthlyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.monthlyServiceTriggerReportsEnabled")}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="onlineStatusAutoEmail"
                                    label={t("myUnitsSettings.emailForAutomatedOnlineStatusReports")}
                                    value={row.notification?.onlineStatusAutoEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isOnlineStatusAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isOnlineStatusAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.emailForEachIndividualOnlineStatusEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isOnlineStatusDailyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isOnlineStatusDailyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.dailyOnlineStatusReportsEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isOnlineStatusWeeklyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isOnlineStatusWeeklyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.weeklyMonday0000OnlineStatusReportsEnabled")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isOnlineStatusMonthlyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isOnlineStatusMonthlyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.monthlyOnlineStatusReportsEnabled")}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="cockpitAutoEmail"
                                    label={t("myUnitsSettings.emailForAutomatedCockpitReports")}
                                    value={row.notification?.cockpitAutoEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isCockpitDailyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isCockpitDailyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.dailyCockpitReportsEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isCockpitWeeklyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isCockpitWeeklyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.weeklyMonday0000CockpitReportsEnabled")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isCockpitMonthlyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isCockpitMonthlyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.monthlyCockpitReportsEnabled")}
                                />
                            </Grid>
                        </Grid>


                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="hitlistAutoEmail"
                                    label={t("myUnitsSettings.emailForAutomatedHitlistReports")}
                                    value={row.notification?.hitlistAutoEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isHitlistDailyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isHitlistDailyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.dailyHitlistReportsEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isHitlistWeeklyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isHitlistWeeklyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.weeklyMonday0000HitlistReportsEnabled")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isHitlistMonthlyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isHitlistMonthlyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.monthlyHitlistReportsEnabled")}
                                />
                            </Grid>
                        </Grid>


                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="feebackAutoEmail"
                                    label={t("myUnitsSettings.emailForAutomatedFeedbackReports")}
                                    value={row.notification?.feebackAutoEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFeedbackAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFeedbackAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.emailForEachIndividualFeedbackEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFeedbackDailyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFeedbackDailyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.dailyFeedbackReportsEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFeedbackWeeklyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFeedbackWeeklyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.weeklyMonday0000FeedbackReportsEnabled")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isFeedbackMonthlyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isFeedbackMonthlyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.monthlyFeedbackReportsEnabled")}
                                />
                            </Grid>
                        </Grid>



                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="servicesAutoEmail"
                                    label={t("myUnitsSettings.emailForAutomatedServiceReports")}
                                    value={row.notification?.servicesAutoEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isServicesAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isServicesAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.emailForEachIndividualServiceEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isServicesDailyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isServicesDailyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.dailyServiceReportsEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isServicesWeeklyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isServicesWeeklyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.weeklyMonday0000ServiceReportsEnabled")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isServicesMonthlyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isServicesMonthlyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.monthlyServiceReportsEnabled")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    name="qualityCheckAutoEmail"
                                    label={t("myUnitsSettings.emailForAutomatedQualityAssuranceReports")}
                                    value={row.notification?.qualityCheckAutoEmail}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isQualityCheckAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isQualityCheckAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.emailForEachIndividualQualityAssuranceEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isQualityCheckDailyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isQualityCheckDailyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.dailyQualityAssuranceReportsEnabled")}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isQualityCheckWeeklyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isQualityCheckWeeklyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.weeklyMonday0000QualityAssuranceReportsEnabled")}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.isQualityCheckMonthlyAutoEmailEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isQualityCheckMonthlyAutoEmailEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.monthlyQualityAssuranceReportsEnabled")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant='h5'>{t("myUnitsSettings.messengerServicesTelegram")}</Typography>
                        </Grid>
                        <Grid item container direction={"row"} alignItems="center" spacing={1}>

                            <Grid item xs={6}>
                                <PhoneInput
                                    placeholder={t("myUnitsSettings.phoneNumber")}
                                    name={GroupSettingsMappingKeys.notificationTelegramPhoneNumber}
                                    value={row.notification?.notificationTelegramGroup.phoneNumber || "+"}
                                    onChange={(event) => {
                                        let res = { target: { name: GroupSettingsMappingKeys.notificationTelegramPhoneNumber, value: event } };
                                        onChange(res);
                                    }}
                                    inputComponent={PhoneInputTextFieldTelegramAdmin}

                                />

                            </Grid>
                            <Grid item xs={2}>


                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    size="large"
                                    fullWidth
                                    startIcon={<Send />}
                                    variant={'contained'}
                                    disabled={isLoadingSendCode}
                                    color="primary"
                                    onClick={async () => {

                                        if (!hasValidPhoneNumber() || authHash) return;

                                        setIsLoadingSendCode(true);

                                        const hash = await api.sendCodeRequestTelegram(row?.notification?.notificationTelegramGroup.phoneNumber as string, token, enqueueSnackbar);

                                        setIsLoadingSendCode(false);
                                        setMakeAuthDialogVisible(true);
                                        setAuthHash(hash);
                                    }}
                                >
                                    {t("myUnitsSettings.codeRequestForRegistration")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item lg={6}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    disabled
                                    name="telegramGroupTitle"
                                    label={t("myUnitsSettings.telegramGroupTitle")}
                                    value={row.notification?.notificationTelegramGroup.title || ''}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item lg={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.notificationTelegramGroup.isTelegramGroupEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isTelegramGroupEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.activated")}
                                />
                            </Grid>


                            <Grid item lg={4}>
                                {
                                    row.notification?.notificationTelegramGroup?.phoneNumber &&
                                    <Button
                                        size="large"
                                        startIcon={<Edit />}
                                        fullWidth
                                        variant={'contained'}
                                        disabled={isLoadingSendCode}
                                        color="primary"
                                        onClick={async () => {
                                            if (!hasValidPhoneNumber()) return;

                                            await loadGroupChatsAsync();

                                            setEditGroupChatListDialogVisible(true);
                                            setIsLoadingSendCode(false);
                                        }}
                                    >
                                        {t("myUnitsSettings.editGroup")}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion className={classes.hover}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Grid container direction="row" alignContent={"center"} alignItems={"center"} spacing={3}>
                                        <Grid item>
                                            <Typography component="h5" variant="h5" style={{ marginLeft: -16 }}>{t("myUnitsSettings.telegramDevelopmentAssistance")}</Typography>
                                        </Grid>

                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Timeline>
                                        <TimelineItem>
                                            <TimelineOppositeContent>
                                                <Typography variant="body1" color="textSecondary">
                                                    1.
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot color="primary">
                                                    <Send />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    <Button
                                                        size="large"
                                                        startIcon={<Send />}
                                                        variant={'contained'}
                                                        disabled={isLoadingSendCode}
                                                        color="primary"
                                                        onClick={async () => {

                                                            if (!hasValidPhoneNumber() || authHash) return;

                                                            setIsLoadingSendCode(true);

                                                            const hash = await api.sendCodeRequestTelegram(row?.notification?.notificationTelegramGroup.phoneNumber as string, token, enqueueSnackbar);

                                                            setIsLoadingSendCode(false);
                                                            setMakeAuthDialogVisible(true);
                                                            setAuthHash(hash);
                                                        }}
                                                    >
                                                        {t("myUnitsSettings.codeRequestForRegistration")}
                                                    </Button>
                                                    <Typography>{t("myUnitsSettings.activateTelegramOnYourPhoneNumberThisIsMandatoryToUseTheMessageTriggerFunction")}

                                                    </Typography>
                                                    <Typography color="textSecondary" variant="body2">{t("myUnitsSettings.warningTheTelegramSessionWillBeRenewedWhenConfirmingTheCode")}</Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineOppositeContent>
                                                <Typography variant="body1" color="textSecondary">
                                                    2.
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot color="primary">
                                                    <Add />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    <Button
                                                        size="large"
                                                        startIcon={<Add />}
                                                        variant={'contained'}
                                                        disabled={isLoadingSendCode}
                                                        color="primary"
                                                        onClick={async () => {
                                                            if (!hasValidPhoneNumber()) return;
                                                            setCreateGroupDialogVisible(true);
                                                        }}
                                                    >
                                                        {t("myUnitsSettings.createGroup")}
                                                    </Button>
                                                    <Typography>{t("myUnitsSettings.createTheGroupDirectlyInTelegram")}</Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineOppositeContent>
                                                <Typography variant="body1" color="textSecondary">
                                                    3.
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot color="primary">
                                                    <GroupIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    {
                                                        row.notification?.notificationTelegramGroup?.phoneNumber &&
                                                        <Button
                                                            size="large"
                                                            startIcon={<GroupIcon />}
                                                            variant={'contained'}
                                                            disabled={isLoadingSendCode}
                                                            color="primary"
                                                            onClick={async () => {

                                                                if (!hasValidPhoneNumber()) return;

                                                                await loadGroupChatsAsync();

                                                                setGroupChooserDialogVisible(true);
                                                                setIsLoadingSendCode(false);
                                                            }}
                                                        >
                                                            {t("myUnitsSettings.selectGroup")}
                                                        </Button>
                                                    }
                                                    <Typography>{t("myUnitsSettings.selectTheTelegramGroupThatWillReceiveMessages")}</Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineOppositeContent>
                                                <Typography variant="body1" color="textSecondary">
                                                    4.
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot color="primary">
                                                    <Sync />
                                                </TimelineDot>
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    <Button
                                                        size="large"
                                                        disabled={isLoadingSendCode}
                                                        startIcon={<Sync />}
                                                        variant={'contained'}
                                                        color="primary"
                                                        onClick={async () => {

                                                            if (!row.notification?.notificationTelegramGroup.phoneNumber || !row.users) {
                                                                enqueueSnackbar("Ungültige Telefonnummer. Bitte geben sie eine gültige authentifizierte Nummer ein!");
                                                                return;
                                                            }

                                                            setIsLoadingSendCode(true);

                                                            for (let user of row.users?.users)
                                                                await api.telegramInviteUserToGroup(
                                                                    user.username,
                                                                    row.notification?.notificationTelegramGroup?.phoneNumber,
                                                                    user.whatsAppPhoneNumber,
                                                                    row.id,
                                                                    token,
                                                                    enqueueSnackbar);

                                                            for (let originUser of originUsers) {
                                                                if (!(row.users?.users.some((user: any) => originUser.whatsAppPhoneNumber === user.whatsAppPhoneNumber))) {
                                                                    await api.telegramDeleteUserFromGroup(
                                                                        row.notification.notificationTelegramGroup.phoneNumber,
                                                                        originUser.whatsAppPhoneNumber,
                                                                        row.id,
                                                                        token,
                                                                        enqueueSnackbar);
                                                                }
                                                            }

                                                            setIsLoadingSendCode(false);
                                                        }}
                                                    >
                                                        {row.notification?.notificationTelegramGroup.title} {t("myUnitsSettings.synchronizeTelegramGroupOnlyFirst5")}
                                                    </Button>
                                                    <Typography>{t("myUnitsSettings.yourServiceStaffWillBeAddedToTheTelegramGroup")}</Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant='h5'>{t("myUnitsSettings.messengerServicesWhatsApp")}</Typography>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={6}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    disabled
                                    name="whatsAppGroupTitle"
                                    label={t("myUnitsSettings.whatsAppGroupTitlePaid")}
                                    value={row.notification?.notificationInformationWhatsAppGroup?.whatsAppGroupName || ''}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.notification?.notificationInformationWhatsAppGroup?.isWhatsAppGroupEnabled || false}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isWhatsAppGroupEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.activated")}
                                />
                            </Grid>
                        </Grid>


                    </Grid>

                </Grid>

                <MakeAuthTelegramCodeDialog
                    open={makeAuthDialogVisible}
                    setOpen={setMakeAuthDialogVisible}
                    token={token}
                    phoneNumber={row.notification?.notificationTelegramGroup.phoneNumber}
                    hash={authHash}
                />

                <CreateTelegramGroupDialog
                    setOpen={setCreateGroupDialogVisible}
                    open={createGroupDialogVisible}
                    token={token}
                    phoneNumber={row.notification?.notificationTelegramGroup.phoneNumber}
                />

                <ChooseTelegramGroupDialog
                    open={groupChooserDialogVisible}
                    setOpen={setGroupChooserDialogVisible}
                    items={groups}
                    row={row}
                    onChange={onChange}
                />

                <EditTelegramGroupDialog
                    open={editGroupChatListDialogVisible}
                    setOpen={setEditGroupChatListDialogVisible}
                    items={groups}
                    setItems={setGroups}
                    token={token}
                    enqueueSnackbar={enqueueSnackbar}
                    row={row}
                    onChange={onChange}
                    type={type}
                />
                {row.id &&
                    <SelectObjectLeaderForGroupNotificationDialog open={openSelectObjectLeaderForGroupNotificationDialog} setOpen={setOpenSelectObjectLeaderForGroupNotificationDialog}
                        currentGroupId={row.id} onRefresh={undefined} updateSettings={updateSettings} row={row} />
                }
            </AccordionDetails>
        </Accordion>
    );
}

export const MissingMessagesCheckboxes = ({ row, onChange }: { row: any, onChange: any }) => {
    const { t } = useTranslation();
    return (
        <Grid item container direction="row" alignItems="center" spacing={1}>
            <Grid item xs={4} container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                    <FormControlLabel
                        label={t("myUnitsSettings.missedMessagesForFrequencyTriggerEnabled")}
                        control={
                            <Checkbox
                                name="isMissedServiceMotionNotificationEnabled"
                                color="primary"
                                checked={row.notification?.isMissedServiceMotionNotificationEnabled}
                                onChange={(event: any, checked: boolean) => {
                                    event.target.value = checked;
                                    onChange(event);
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item>
                    <MotionSensorSvg width={40} height={40} fill={"#000000"} />
                </Grid>
            </Grid>
            <Grid item xs={4} container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                    <FormControlLabel
                        label={t("myUnitsSettings.missedMessagesForIntervalTriggerEnabled")}
                        control={
                            <Checkbox
                                name="isMissedServiceIntervalNotificationEnabled"
                                color="primary"
                                checked={row.notification?.isMissedServiceIntervalNotificationEnabled}
                                onChange={(event: any, checked: boolean) => {
                                    event.target.value = checked;
                                    onChange(event);
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item>
                    <ChartBellCurve width={40} height={40} fill={"#000000"} />
                </Grid>
            </Grid>
            <Grid item xs={4} container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                    <FormControlLabel
                        label={t("myUnitsSettings.missedMessagesForFeedbackTriggerEnabled")}
                        control={
                            <Checkbox
                                name="isMissedServiceFeedbackNotificationEnabled"
                                color="primary"
                                checked={row.notification?.isMissedServiceFeedbackNotificationEnabled}
                                onChange={(event: any, checked: boolean) => {
                                    event.target.value = checked;
                                    onChange(event);
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item>
                    <EmoticonNeutalSmileSvg width={40} height={40} fill={"#000000"} />
                </Grid>
            </Grid>
        </Grid>
    )
}