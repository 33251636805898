import axios from 'axios';
import { getUnixTime } from 'date-fns';
import { handleApiError, headers } from '../api';
import { getQuery } from '../components/authorized/portal/checkout/ShopPage';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from '../helpers/date-management';
import { GroupOnlineStatus } from '../models/GroupOnlineStatus';
import { GroupServiceMode } from '../models/GroupServiceMode';

export const GetOnlineStatusByUser = async (token: string): Promise<Array<GroupOnlineStatus>> => {

    const userId = getUserIdFromStorage();


    return await axios
        .get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/online-status?${getQueryParamFromApiDataAmountType()}`,
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}

export const GetOnlineStatusByUserId = async (token: string, userId: string): Promise<Array<GroupOnlineStatus>> => {

    return await axios
        .get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/online-status?${getQueryParamFromApiDataAmountType()}`,
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}

export const getGroupOnlineStatusByGroupIdsAsync = async (token: string, groupIds: string[]): Promise<Array<GroupOnlineStatus>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/online-status-multiple?${getQueryParamFromApiDataAmountType()}`, groupIds,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
};


export const getGroupOnlineStatusBatteryDisplaysByGroupIdsAsync = async (token: string, groupIds: string[]): Promise<Array<GroupOnlineStatus>> => {

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/online-status-multiple-battery?${getQueryParamFromApiDataAmountType()}`, groupIds,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => res.data)
        .catch(error => {
            handleApiError(error);
            return [];
        });
};
