import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import { GridStackCustom, GridStackCustomItem } from '../../models/GridStackCustom';
import { CustomDashboardContext } from '../authorized/portal/Dashboard/CustomDashboard';
import { useTranslation } from 'react-i18next';

export function ChartChoice({ value, setValue }: { value: string, setValue: any }) {

  const { item, updateGridStack, selectedGridStackCustom }: { item: GridStackCustomItem, updateGridStack: (currentGridStackCustom: GridStackCustomItem) => void, selectedGridStackCustom: GridStackCustom } = React.useContext(CustomDashboardContext);
  const {t} = useTranslation();


  const handleChange = (event: any) => {
    if (item) {
      item.chartChoice = event.target.value;
      updateGridStack(item)
    }
    setValue(event.target.value);
  };

  React.useEffect(() => {
    if (item && item.chartChoice) {
      setValue(item.chartChoice);
    }
  }, [selectedGridStackCustom]);

  return (
    <FormControl component="fieldset" style={{ paddingLeft: 10 }}>
      <FormLabel component="legend">{t("object-sessions.chartSelection")}</FormLabel>
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} row>
        <FormControlLabel value="0" control={<Radio />} label={t("object-sessions.lineChart")} />
        <FormControlLabel value="1" control={<Radio />} label={t("object-sessions.stackedChart")} />
        <FormControlLabel value="2" control={<Radio />} label={t("object-sessions.sideBySideChart")} />
      </RadioGroup>
    </FormControl>
  );
}