import React, { useContext } from "react";
import {
    AccordionDetails,
    Accordion,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    AccordionSummary,
    Typography,
    InputAdornment,
    IconButton,
    Theme,
    Button,
    CircularProgress,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Assignment, ExpandMore, Info, Save, Visibility, VisibilityOff } from "@mui/icons-material";
import { GroupSettings, GroupSettingsType, ToiletType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { lightgrey } from '../../styles/colors';
import { Group, GroupType } from "../../models/Group";
import { LicenseBlackSize } from "../../assets";
import { formatEuropeanDate } from "../../helpers/date-management";
import { GroupLicense } from "../../models/GroupLicense";
import { api } from "../../api";
import { LicenseType } from "../../models/LicenseType";
import { getApiToken } from "../../helpers/storage-management";
import { useHistory } from "react-router-dom";
import { AssignLicenseChoosingTableDialog, ShowCountType } from "../dialogs/AssignLicenseChoosingTableDialog";
import { AssignMultipleLicenseChoosingTableDialog } from "../dialogs/AssignMultipleLicenseChoosingTableDialog";
import { LicenseManagementTouchDisplayDialog, TouchDisplayDialogChoice, TouchDisplayLicenseActionChoice } from "../authorized/portal/dialog/LicenseManagementTouchDisplayDialog";
import { handleSelectedCombinationGroup } from "../tables/Base";
import { GroupLicenseTable } from "../../models/GroupLicenseTable";
import { GlobalContext } from "../authorized/PortalPage";
import { BatteryDisplayDialogChoice, BatteryDisplaySelectionDialog } from "../authorized/extendedapp/batteryDisplay/BatteryDisplaySelectionDialog";
import { useTranslation } from "react-i18next";

export const GroupSettingFieldsLicenseInformation = ({ loadData, row, onChange, type, group, token, enqueueSnackbar }: { loadData: any, row: GroupSettings | UserGroupSettingsTemplate; onChange: any; type: GroupSettingsType, group: Group, token: any, enqueueSnackbar: any }) => {

    const classes = useStyles();
    const [qualityCheckLicenses, setQualityLicenses] = React.useState<GroupLicense[]>([]);
    const [checkpointLicenses, setCheckpointLicenses] = React.useState<GroupLicense[]>([]);
    const [freeLicenses, setFreeLicenses] = React.useState<GroupLicense[]>([]);
    const [deviceLicenses, setDeviceLicenses] = React.useState<GroupLicense[]>([]);
    const [sensorLicenses, setSensorLicenses] = React.useState<GroupLicense[]>([]);

    const [adLicenses, setAdLicenses] = React.useState<GroupLicense[]>([]);
    const [assignMultipleQualityCheckLicenseDialogVisible, setAssignMultipleQualityCheckLicenseDialogVisible] = React.useState(false);

    const [assignMultiplePublicFeedbackLicenseDialogVisible, setAssignMultiplePublicFeedbackLicenseDialogVisible] = React.useState(false);
    const [assignMultiplePayPerServiceLicenseDialogVisible, setAssignMultiplePayPerServiceLicenseDialogVisible] = React.useState(false);
    const [assignMultipleCalendarProLicenseDialogVisible, setAssignMultipleCalendarProLicenseDialogVisible] = React.useState(false);


    const [assignCheckpointLicenseExtentedDialogVisible, setAssignCheckpointLicenseExtentedDialogVisible] = React.useState(false);
    const [qualityCheckCount, setQualityCheckCount] = React.useState(0);
    const [publicFeedbackCount, setPublicFeedbackCount] = React.useState(0);
    const [payPerServiceCount, setPayPerServiceCount] = React.useState(0);
    const [calendarProCount, setCalendarProCount] = React.useState(0);

    const [checkpointCount, setCheckpointCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [isTouchDisplayManagementVisible, setIsTouchDisplayManagementVisible] = React.useState(false);
    const history = useHistory();
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [dialogChoiceTouchDisplay, setDialogChoiceTouchDisplay] = React.useState(TouchDisplayDialogChoice.None);
    const [dialogChoiceBatteryDisplay, setDialogChoiceBatteryDisplay] = React.useState(BatteryDisplayDialogChoice.None);
    const globals: any = useContext(GlobalContext);



    const [batteryDisplaysLicenses, setBatteryDisplaysLicenses] = React.useState<GroupLicense[]>([]);
    const [batterySensorsLicenses, setBatterySensorsLicenses] = React.useState<GroupLicense[]>([]);
    const [batteryDisplaySelectionDialogVisible, setBatteryDisplaySelectionDialogVisible] = React.useState(false);

    const [allLicenses, setAllLicenses] = React.useState<GroupLicense[]>([]);
    const {t} = useTranslation();

    const navigateToGroup = (currentGroup: GroupLicenseTable) => {

        globals.onUpdateDisplayGroup(currentGroup);

    }

    const navigateByLicenseTable = (selectedGroup: GroupLicenseTable) => {
        if (!selectedGroup) return;
        navigateToGroup(selectedGroup);
    }

    const loadDataAsync = async () => {

        const tempToken = getApiToken(history);
        if (!tempToken) return;

        setLoading(true);

        const licenses = await api.getLicensesAsync(tempToken);
        setFreeLicenses(licenses.filter(el => !el.isUsed && el.type != LicenseType.ObjectNotificationLog));
        const checkpointCount = licenses.filter(el => el.checkpointCount > 0).reduce((prevVal, curVal) => prevVal + curVal.checkpointCount, 0);
        setCheckpointCount(checkpointCount);
        setCheckpointLicenses(licenses.filter(el => (el.checkpointCount > 0)));
        setDeviceLicenses(licenses.filter(el => !el.isUsed && el.type == LicenseType.Device));
        setAdLicenses(licenses.filter(el => !el.isUsed && el.type == LicenseType.Advertisement));
        setSensorLicenses(licenses.filter(el => !el.isUsed && el.type == LicenseType.Extension));
        setBatterySensorsLicenses(licenses.filter(el => !el.isUsed && el.type == LicenseType.BatterySensor));
        setBatteryDisplaysLicenses(licenses.filter(el => !el.isUsed && el.type == LicenseType.BatteryDisplay));


        const qualityCheckCount = licenses.filter(el => el.qualityCheckCount > 0).reduce((prevVal, curVal) => prevVal + curVal.qualityCheckCount, 0);
        setQualityCheckCount(qualityCheckCount);
        setQualityLicenses(licenses.filter(el => el.qualityCheckCount > 0));

        const publicFeedbackCount = licenses.filter(el => el.publicFeedbackCount > 0).reduce((prevVal, curVal) => prevVal + curVal.publicFeedbackCount, 0);
        setPublicFeedbackCount(publicFeedbackCount);

        const payPerServiceCount = licenses.filter(el => el.payPerServiceCount > 0).reduce((prevVal, curVal) => prevVal + curVal.payPerServiceCount, 0);
        setPayPerServiceCount(payPerServiceCount);

        const calendarProCount = licenses.filter(el => el.calendarProCount > 0).reduce((prevVal, curVal) => prevVal + curVal.calendarProCount, 0);
        setCalendarProCount(calendarProCount);

        setAllLicenses(licenses);


        setLoading(false);
    }

    return (
        <Accordion className={classes.hover} onChange={(event, expanded) => {
            if (expanded) {
                loadDataAsync();
            }
        }}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        {LicenseBlackSize(50, 50)}
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage.licenseInformation")}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {loading && <CircularProgress size={64} />}
                <Grid container direction={"column"} spacing={4}>
                    <Grid item xs={12} container direction={"row"} spacing={3}>
                        <Grid item md={3}>
                            {group.qualityCheckLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.freeQMLicenses")}: {loading ? "Loading..." : qualityCheckCount}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            {group.qualityCheckLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.qualityAssuranceExpired")}: {group.qualityCheckLicenseIsExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            {group.qualityCheckLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.qualityAssuranceValidUntil")}: {formatEuropeanDate(group.qualityCheckLicenseValidUntil)}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Assignment />}
                                onClick={async () => {
                                    setAssignMultipleQualityCheckLicenseDialogVisible(true);
                                }}
                            >
                                {t("myUnitsSettings.assignQualityAssuranceLicense")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction={"row"} spacing={3}>
                        <Grid item md={3}>
                            {group.publicFeedbackLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.freePublicFeedbackLicenses")}: {loading ? "Loading..." : publicFeedbackCount}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            {group.qualityCheckLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.publicFeedbackExpired")}: {group.publicFeedbackLicenseIsExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            {group.publicFeedbackLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.publicFeedbacksValidUntil")}: {formatEuropeanDate(group.publicFeedbackLicenseValidUntil)}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Assignment />}
                                onClick={async () => {
                                    setAssignMultiplePublicFeedbackLicenseDialogVisible(true);
                                }}
                            >
                                {t("myUnitsSettings.assignPublicFeedbackLicense")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction={"row"} spacing={3}>
                        <Grid item md={3}>
                            <Typography>{t("myUnitsSettings.freePayPerServiceLicenses")}: {loading ? "Loading..." : payPerServiceCount}</Typography>

                        </Grid>
                        <Grid item md={3}>
                            {group.payPerServiceLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.payPerServiceExpired")}: {group.payPerServiceLicenseIsExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            {group.payPerServiceLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.payPerServiceValidUntil")}: {formatEuropeanDate(group.payPerServiceLicenseValidUntil)}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Assignment />}
                                onClick={async () => {
                                    setAssignMultiplePayPerServiceLicenseDialogVisible(true);
                                }}
                            >
                                {t("myUnitsSettings.assignPayPerServiceLicense")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction={"row"} spacing={3}>
                        <Grid item md={3}>
                            <Typography>{t("myUnitsSettings.freeCalendarProLicenses")}: {loading ? "Loading..." : calendarProCount}</Typography>

                        </Grid>
                        <Grid item md={3}>
                            {group.calendarProLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.calendarProExpired")}: {group.calendarProLicenseIsExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            {group.calendarProLicenseValidUntil &&
                                <Typography>{t("myUnitsSettings.calendarProValidUntil")}: {formatEuropeanDate(group.calendarProLicenseValidUntil)}</Typography>
                            }
                        </Grid>
                        <Grid item md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Assignment />}
                                onClick={async () => {
                                    setAssignMultipleCalendarProLicenseDialogVisible(true);
                                }}
                            >
                                {t("myUnitsSettings.assignCalendarProLicense")}
                            </Button>
                        </Grid>
                    </Grid>
                    {group.groupType == GroupType.DisplayWallApp &&
                        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.freeSensorLicenses")}: {loading ? "Loading..." : sensorLicenses.length}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.sensorLicenses &&
                                    group.sensorLicenses.map(x => {
                                        return (
                                            <Typography>{t("myUnitsSettings.sensorLicenseExpired")}: {x.isSensorLicenseExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.sensorLicenses &&
                                    group.sensorLicenses.map(x => {
                                        return (
                                            <Typography>{t("myUnitsSettings.sensorValidUntil")}: {formatEuropeanDate(x.sensorLicenseValidUntilDate)}</Typography>
                                        )
                                    })
                                }
                            </Grid>

                            <Grid item md={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Assignment />}
                                    disabled={loading}
                                    startIcon={loading && <CircularProgress size={32} />}
                                    onClick={async () => {
                                        setLoading(true);
                                        const tempGroups = await api.getAllGroups(token);
                                        setGroups(tempGroups);
                                        setLoading(false);
                                        setDialogChoiceTouchDisplay(TouchDisplayDialogChoice.ExtendSensor);
                                        setIsTouchDisplayManagementVisible(true);
                                    }}
                                >
                                    {t("myUnitsSettings.assignSensorLicense")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {group.groupType == GroupType.DisplayWallApp &&
                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.freeAdvertisingLicenses")}: {loading ? "Loading..." : adLicenses.length}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.adLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.advertisingLicenseExpired")}: {group.adLicenseExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.adLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.adValidUntil")}: {formatEuropeanDate(group.adLicenseValidUntil)}</Typography>
                                }
                            </Grid>

                            <Grid item md={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Assignment />}
                                    disabled={loading}
                                    startIcon={loading && <CircularProgress size={32} />}
                                    onClick={async () => {
                                        setLoading(true);
                                        const tempGroups = await api.getAllGroups(token);
                                        setGroups(tempGroups);
                                        setLoading(false);
                                        setDialogChoiceTouchDisplay(TouchDisplayDialogChoice.ExtendAd);
                                        setIsTouchDisplayManagementVisible(true);
                                    }}
                                >
                                    {t("myUnitsSettings.assignAdvertisingLicense")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {group.groupType == GroupType.Checkpoint &&
                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item md={3}>
                                {group.adLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.freeCheckpointLicenses")}: {loading ? "Loading..." : checkpointCount}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>Checkpoint {t("myUnitsSettings.expired")}: {group.deviceLicenseExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>Checkpoint {t("myUnitsSettings.validUntil")}: {formatEuropeanDate(group.deviceLicenseValidUntil)}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Assignment />}
                                    onClick={async () => {
                                        setAssignCheckpointLicenseExtentedDialogVisible(true);
                                    }}
                                >
                                    Checkpoint {t("myUnitsSettings.licenseAssigned")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {group.groupType == GroupType.DisplayWallApp &&
                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.freeDisplayLicenses")}: {loading ? "Loading..." : deviceLicenses.length}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.displayExpired")}: {group.deviceLicenseExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>Display {t("myUnitsSettings.validUntil")}: {formatEuropeanDate(group.deviceLicenseValidUntil)}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Assignment />}
                                    disabled={loading}
                                    startIcon={loading && <CircularProgress size={32} />}
                                    onClick={async () => {
                                        setLoading(true);
                                        const tempGroups = await api.getAllGroups(token);
                                        setGroups(tempGroups);
                                        setLoading(false);
                                        setDialogChoiceTouchDisplay(TouchDisplayDialogChoice.ExtendTouchDevice);
                                        setIsTouchDisplayManagementVisible(true);
                                    }}
                                >
                                    Display {t("myUnitsSettings.licenseAssigned")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {group.groupType == GroupType.DisplayBatteryWallApp &&
                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.freeBatteryDisplayLicenses")}: {loading ? "Loading..." : batteryDisplaysLicenses.length}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>Battery Display {t("myUnitsSettings.expired")}: {group.deviceLicenseExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>Battery Display {t("myUnitsSettings.validUntil")}: {formatEuropeanDate(group.deviceLicenseValidUntil)}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Assignment />}
                                    disabled={loading}
                                    startIcon={loading && <CircularProgress size={32} />}
                                    onClick={async () => {
                                        setLoading(true);
                                        const tempGroups = await api.getAllGroups(token);
                                        setGroups(tempGroups);
                                        setLoading(false);
                                        setDialogChoiceBatteryDisplay(BatteryDisplayDialogChoice.ExtendBatteryDevice);
                                        setBatteryDisplaySelectionDialogVisible(true);
                                    }}
                                >
                                    Battery Display {t("myUnitsSettings.licenseAssigned")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {group.groupType == GroupType.DisplayBatteryWallApp &&
                        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                            <Grid item md={3}>
                                {group.deviceLicenseValidUntil &&
                                    <Typography>{t("myUnitsSettings.freeSensorLicenses")}: {loading ? "Loading..." : batterySensorsLicenses.length}</Typography>
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.sensorLicenses &&
                                    group.sensorLicenses.map(x => {
                                        return (
                                            <Typography>Battery Sensor {t("myUnitsSettings.expired")}: {x.isSensorLicenseExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}</Typography>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item md={3}>
                                {group.sensorLicenses &&
                                    group.sensorLicenses.map(x => {
                                        return (
                                            <Typography>Battery Sensor {t("myUnitsSettings.validUntil")}: {formatEuropeanDate(x.sensorLicenseValidUntilDate)}</Typography>
                                        )
                                    })
                                }
                            </Grid>

                            <Grid item md={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Assignment />}
                                    disabled={loading}
                                    startIcon={loading && <CircularProgress size={32} />}
                                    onClick={async () => {
                                        setLoading(true);
                                        const tempGroups = await api.getAllGroups(token);
                                        setGroups(tempGroups);
                                        setLoading(false);
                                        setDialogChoiceBatteryDisplay(BatteryDisplayDialogChoice.ExtendSensor);
                                        setBatteryDisplaySelectionDialogVisible(true);
                                    }}
                                >
                                    Battery Sensor {t("myUnitsSettings.licenseAssigned")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </AccordionDetails>
            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.QualityCheck}
                visible={assignMultipleQualityCheckLicenseDialogVisible}
                licenses={qualityCheckLicenses}
                onClose={() => {
                    setAssignMultipleQualityCheckLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    if (!row.id) return;
                    for (let selectedLicense of selectedLicenses) {
                        await api.assignLicenseToGroup(row?.id, selectedLicense, token, enqueueSnackbar);
                    }

                    setAssignMultipleQualityCheckLicenseDialogVisible(false);
                    await loadData();
                }}
            />

            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.PublicFeedback}
                visible={assignMultiplePublicFeedbackLicenseDialogVisible}
                licenses={allLicenses.filter(el => el.publicFeedbackCount > 0)}
                onClose={() => {
                    setAssignMultiplePublicFeedbackLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    if (!row.id) return;
                    for (let selectedLicense of selectedLicenses) {
                        await api.assignLicenseToGroup(row?.id, selectedLicense, token, enqueueSnackbar);
                    }

                    setAssignMultiplePublicFeedbackLicenseDialogVisible(false);
                    await loadData();
                }}
            />
            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.PayPerService}
                visible={assignMultiplePayPerServiceLicenseDialogVisible}
                licenses={allLicenses.filter(el => el.payPerServiceCount > 0)}
                onClose={() => {
                    setAssignMultiplePayPerServiceLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    if (!row.id) return;
                    for (let selectedLicense of selectedLicenses) {
                        await api.assignLicenseToGroup(row?.id, selectedLicense, token, enqueueSnackbar);
                    }

                    setAssignMultiplePayPerServiceLicenseDialogVisible(false);
                    await loadData();
                }}
            />
            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.CalendarPro}
                visible={assignMultipleCalendarProLicenseDialogVisible}
                licenses={allLicenses.filter(el => el.calendarProCount > 0)}
                onClose={() => {
                    setAssignMultipleCalendarProLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    if (!row.id) return;
                    for (let selectedLicense of selectedLicenses) {
                        await api.assignLicenseToGroup(row?.id, selectedLicense, token, enqueueSnackbar);
                    }

                    setAssignMultipleCalendarProLicenseDialogVisible(false);
                    await loadData();
                }}
            />


            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.Checkpoint}
                visible={assignCheckpointLicenseExtentedDialogVisible}
                licenses={checkpointLicenses}
                onClose={() => {
                    setAssignCheckpointLicenseExtentedDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    if (!row.id) return;
                    await api.assignLicenseToGroup(row?.id, selectedLicense, token, enqueueSnackbar);
                    setAssignCheckpointLicenseExtentedDialogVisible(false);
                    await loadData();
                }}
            />
            <LicenseManagementTouchDisplayDialog dialogChoice={dialogChoiceTouchDisplay} preSelectedGroup={groups.find(x => x.id == row.id)} visible={isTouchDisplayManagementVisible} setVisible={setIsTouchDisplayManagementVisible} groups={groups} setGroups={setGroups} freeLicenses={freeLicenses} handleSelectedCombinationGroup={handleSelectedCombinationGroup} navigateByLicenseTable={navigateByLicenseTable} loadDataAsync={() => {
                setIsTouchDisplayManagementVisible(false);
                loadData();
            }} licenseActionChoice={TouchDisplayLicenseActionChoice.None} />


            <BatteryDisplaySelectionDialog
                open={batteryDisplaySelectionDialogVisible}
                setOpen={setBatteryDisplaySelectionDialogVisible}
                groups={groups.filter(x => x.groupType == GroupType.DisplayBatteryWallApp)}
                onEdit={async (group: GroupLicenseTable) => {
                    setBatteryDisplaySelectionDialogVisible(false);
                }}
                dialogChoice={dialogChoiceBatteryDisplay}
                preSelectedGroup={groups.find(x => x.id == row.id)}
                freeLicenses={freeLicenses}
                loadData={loadData}
                onNavigate={(group: GroupLicenseTable) => {
                    navigateToGroup(group);
                }}

            />
        </Accordion >
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 'bold',
        },
        hover: {
            "&:hover": {
                backgroundColor: lightgrey
            }
        }
    }),
);