import axios from 'axios';
import { handleApiError, handleApiSuccess, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const telegramInviteUserToGroup = async (userName: string, adminPhoneNumber: string, newPhoneNumber:any, groupId: any, token: any, enqueueSnackbar: any) : Promise<any> => {
    try {
        const userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/telegram/${adminPhoneNumber}/new-user/${newPhoneNumber}/name/${userName}`,null,
        {headers: {...headers,'Authorization': 'Bearer '+token}})
        handleApiSuccess(ret.data, enqueueSnackbar, `User ${userName} ${newPhoneNumber} wurde eingeladen`);
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar, `User ${userName} ${newPhoneNumber} konnte nicht eingeladen werden!`);
    }
    return null as any;
}