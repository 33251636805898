import axios from 'axios';
import { headers } from "../api";

export const changePasswordByRequestIdAsync = async (requestId: string, password: string, enqueueSnackbar: any): Promise<boolean> => {

    try {

        await axios.patch(
            `${process.env.REACT_APP_BASIC_URI}/auth/change-password/${requestId}`,
            { password: password },
            { headers: headers });

        enqueueSnackbar("Passwort wurde geändert!", { variant: 'success' });
        return true;

    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Passwort ändern fehlgeschlagen", { variant: 'error' });
        return false;
    }
}