import axios from 'axios';
import { handleApiError, headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { ObjectUnit } from '../../models/ObjectUnit';

export const getObjectUnitsByCostLocation = async (token: string, costLocations : string[]): Promise<ObjectUnit[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/object-units-cost-location`, {stringArray : costLocations},{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}