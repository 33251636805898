import axios from "axios";
import { multipartHeaders } from "../api";
import { FileBlobType } from '../models/FileBlobType';

export const UploadTemplateAsync = async (file: File, type: FileBlobType, token: string, enqueueSnackbar: any) => {

    const formData = new FormData();
    formData.append('body', file);

    await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/templates/${type}`,
            formData,
            { headers: { ...multipartHeaders, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Vorlage wurde erfolgreich hochgeladen", { variant: 'success' });
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Vorlage kann nicht hochgeladen werden", { variant: 'error' });
        });
};
