import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle, CircularProgress, DialogActions, DialogContent, Grid, Paper, TextField, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { Close } from '@mui/icons-material';
import { LinearProgressWithLabel } from './ProgressComponent';
import prettyBytes from 'pretty-bytes';
import { useTranslation } from 'react-i18next';

export const ProgressDialogComponent = ({ open, setOpen, title, progressData }
    : { open: boolean, setOpen: any, title: string, progressData: any }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const {t} = useTranslation();



    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                onClose={(dialog) => {
                    console.log(dialog);
                }}
                open={open}

            >
                <DefaultDialogTitle
                    title={title}
                />
                <DialogContent style={{ minHeight: 600 }} className={classes.dialogContent} dividers>
                    <Grid container direction={'row'} alignItems="center" justifyContent={"center"} spacing={3}>
                        <Grid item xs={12}>
                            <Alert severity="warning">
                                <AlertTitle>Achtung!</AlertTitle>
                                Daten werden hochgeladen!
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <CircularProgress size={32} />
                        </Grid>
                        {progressData && progressData.loaded &&

                            <Grid item xs={12}>
                                <Typography>Daten hochgeladen für File {progressData?.index || 1}: {prettyBytes(progressData.loaded)}</Typography>
                                <LinearProgressWithLabel value={(progressData.loaded * 100) / progressData.total} valueBuffer={(progressData.loaded * 100) / progressData.total} />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<Close />}
                        variant={'contained'}
                        color="primary"
                        onClick={async () => {
                            setOpen(false);
                        }}
                    >
                        Dialog {t("myUnitsSettings.close")}!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));