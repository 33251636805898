import axios from 'axios';
import { headers } from '../api';
import { getUserId } from '../helpers/storage-management';
import { SupportContactRequest } from '../models/SupportContactRequest';

export const sendSupportContactRequest = async (token: string, model: SupportContactRequest) => {
    try {

        const userId = getUserId();

        await axios.post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/email/support-contact`,
            model,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } });

    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};