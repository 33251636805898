import { CircularGauge } from "devextreme-react"
import { Export, Font, Label, RangeContainer, Scale, Title, Range } from "devextreme-react/circular-gauge"
import { green, red, yellow } from "../../../../styles/colors";

export const GaugeFeedback = ({ gaugeVal, title }
    : { gaugeVal: number, title: string}) => {
    return (
        <CircularGauge
            id="gauge"
            value={gaugeVal}
        >
            <Scale startValue={0} endValue={100} tickInterval={10}>
                <Label customizeText={"dd"} />
            </Scale>
            <RangeContainer>
                <Range startValue={0} endValue={65} color={red} />
                <Range startValue={65} endValue={85} color={yellow} />
                <Range startValue={85} endValue={100} color={green} />
            </RangeContainer>
            <Export enabled={true} />
            <Title text="Gesamt Services">
                <Font size={28} />
            </Title>
        </CircularGauge>
    );
}