import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ConfirmationDialog = ({ visible, title, onConfirmed, onClose }
    : { visible: boolean; title: string, onConfirmed: any; onClose: any; }) => {
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();

    return (
        <Dialog fullWidth maxWidth="xs" open={visible} onClose={onClose}>
            <DialogContent>
                <Typography>{title}</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>{t("myUnitsSettings.cancel")}</Button>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    endIcon={loading && <CircularProgress size={32} />}
                    onClick={async () => {
                        setLoading(true);
                        await onConfirmed();
                        setLoading(false);
                    }}
                >
                    Bestätigen
                </Button>
            </DialogActions>

        </Dialog>
    );
};
