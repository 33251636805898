import axios from "axios";
import { multipartHeaders } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupSettings, GroupSettingsType, User } from "../models/GroupSettings";
import { UserAccount } from "../models/UserAccount";

export const updateUserLogo = async (logo: File, token: string, enqueueSnackbar: any): Promise<UserAccount> => {
    try {

        const userId = getUserIdFromStorage();

        const formData = new FormData();
        formData.append('body', logo);

        let uri: string = '';

        uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/images/logo`;


        const ret = await axios.post(uri, formData, { headers: { ...multipartHeaders, 'Authorization': `Bearer ${token}` } });

        enqueueSnackbar("Logo wurde erfolgreich hochgeladen", { variant: 'success' });

        return ret.data;
    } catch (err) {
        enqueueSnackbar("Logo kann nicht hochgeladen werden", { variant: 'error' });
    }
    return null as any;
}
