import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Autocomplete, Button, CircularProgress, DialogActions, DialogContent, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../tables/GroupSelectionTable';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { errorColor, white } from '../../../styles/colors';
import { EditedGroupLicenseTable } from '../../../models/EditedGroupLicenseTable';
import { StandardWorkItem, StandardWorkItemType } from '../../../models/StandardItem';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAssignQmControl = ({
  open,
  setOpen,
  objectUnit,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  objectUnit: ObjectUnit,
  onRefresh: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
  const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
  const [token, setToken] = React.useState("");

  const [roomSize, setRoomSize] = React.useState(0);
  const [roomType, setRoomType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [standardRoomTypes, setStandardRoomTypes] = React.useState<StandardWorkItem[]>([]);
  const {t} = useTranslation();

  
  const handleClose = () => {
    setSelectedGroups([]);
    setOpen(false);
  }

  const loadStandardRoomTypes = React.useCallback(async () => {
    let res = await api.GetStandardWorkItemsByType(token, StandardWorkItemType.RoomType);
    setStandardRoomTypes(res);
  }, [groups]);

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);
      if (groups.length < 1) {
        const tempGroups = await api.getGroupsPortalByObjectId(tempToken, [objectUnit.id]);
        setGroups(tempGroups);
        loadStandardRoomTypes();
        onRefresh(tempGroups.filter(x => x.isQmControlEnabled));
      }

    })();

  }, [open]);

  const editedGroups = React.useMemo(() => {
    return groups.filter(x => x.edited);
  }, [groups])

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen
    >
      <DefaultDialogTitle
        title={`Wähle Einheiten des Objektes ${objectUnit.name} die Qualitätsmanagement aktiviert haben sollen`}
        handleClose={handleClose}
      />

      <DialogContent style={{ padding: 30 }}>
        <Grid container direction="row" spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              id="filled-name"
              type='number'
              InputLabelProps={{ shrink: true }}
              label={t("myUnitsSettings.roomSize")}
              variant="filled"
              value={roomSize}
              onChange={(event: any) => {
                setRoomSize(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              freeSolo
              loading={loading}
              value={roomType}
              onChange={(event: any, newValue: string | null) => {
                if (!newValue) return;

                setRoomType(newValue);
              }}

              id="controllable-states-demo"
              options={standardRoomTypes.map(x => x.text)}
              sx={{ width: 300 }}
              renderInput={(params: any) => <TextField variant='filled' onChange={(event) => {
                setRoomType(event.target.value);

              }} {...params} label="Raumart" />}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={loading || selectedGroups.length < 1}
              startIcon={loading && <CircularProgress size={32} />}
              endIcon={<Add />}
              onClick={async () => {
                setLoading(true);
                await api.patchMultipleGroupSettingsAsync(selectedGroups.map(x => {
                  x.roomSize = roomSize;
                  x.roomType = roomType;
                  return x;
                }), token, enqueueSnackbar);
                setLoading(false);
              }}
            >
              Ausgewählte Einheiten {selectedGroups.length} Speichern
            </Button>
          </Grid>
        </Grid>
        <GroupSelectionTable
          groups={groups}
          standardRoomTypes={standardRoomTypes}
          setGroups={setGroups}
          showIsQmAssigned={true}
          selectedGroups={selectedGroups}
          onSelectionChange={(selection: string[]) => {
            setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ backgroundColor: errorColor, color: white }}
          endIcon={<Delete />}
          onClick={() => {

            api.unassignQmToGroupByIdsAsync(selectedGroups.map(x => x.id), token, enqueueSnackbar);
            handleClose();
            onRefresh(groups.map(x => {
              if (selectedGroups.some(y => y.id == x.id)) {
                x.isQmControlEnabled = false;
              }
              return x;
            }).filter(x => x.isQmControlEnabled));
          }}
        >
          Ausgewählte Einheiten QM deaktivieren
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={32} />}
          endIcon={<Add />}
          onClick={async () => {
            setLoading(true);
            await api.assignQmToGroupByIdsAsync(selectedGroups.map(x => x.id), token, enqueueSnackbar);
            setLoading(false)
            handleClose();
            onRefresh(groups.map(x => {
              if (selectedGroups.some(y => y.id == x.id)) {
                x.isQmControlEnabled = true;
              }
              return x;
            }).filter(x => x.isQmControlEnabled));
          }}
        >
          Ausgewählte Einheiten QM aktivieren
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={loading || editedGroups.length < 1}
          startIcon={loading && <CircularProgress size={32} />}

          endIcon={<Add />}
          onClick={async () => {
            setLoading(true);
            await api.patchMultipleGroupSettingsAsync(editedGroups, token, enqueueSnackbar);
            setLoading(false);
          }}
        >
          Einheiten {editedGroups.length} Speichern
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));