import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';
import { UserAddress } from '../models/UserAddress';
import { ObjectLeaderQualityCheckModel } from '../models/ObjectLeaderQualityCheckModel';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';

export const getQualityChecksByUserIdGroupIds = async (token: string, groupIds: string[]): Promise<ObjectLeaderQualityCheckModel[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/quality-checks?${getQueryParamFromApiDataAmountType()}`, {StringArray: groupIds}, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}