import React from 'react';
import { Button, CircularProgress, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Email, Save, VpnKey } from '@mui/icons-material';
import { getApiToken } from '../../../../helpers/storage-management';
import { UserServiceWorkerObjectSession } from '../../../../models/UserServiceWorkerObjectSession';
import { api } from '../../../../api';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ServiceWorkerObjectSessionTable } from '../../../dialogs/tables/ServiceWorkerObjectSessionTable';
import { DrawerMenuItemDisplayType } from '../../ObjectMenuPointDrawer';
import { SERVICE_WORKER_COMBINATION } from '../../../../constants';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { getUniqueGroupsBySessions } from '../../../../api/GetAllGroupsAsync';

export const ServiceWorkerSession = ({ displayType = DrawerMenuItemDisplayType.Single }: { displayType?: DrawerMenuItemDisplayType }) => {


    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [userServiceWorkerObjectSessions, setUserServiceWorkerObjectSessions] = React.useState<UserServiceWorkerObjectSession[]>([]);


    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();
    const [selectedServiceWorker, setSelectedServiceWorker] = React.useState<UserServiceWorker>();
    const [serviceWorkers, setServiceWorkers] = React.useState<UserServiceWorker[]>([]);

    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);


    React.useEffect(() => {
        (async () => {
            setLoading(true);
            const token = getApiToken(history);
            if (!token) return;
            let tempSessions = [];
            if (displayType == DrawerMenuItemDisplayType.All) {
                tempSessions = await api.getObjectSessionsByUserId(token);
                setUserServiceWorkerObjectSessions(tempSessions);
            }else if (displayType == DrawerMenuItemDisplayType.Multiple){
                const tempCombServiceWorker = localStorage.getItem(SERVICE_WORKER_COMBINATION) || "[]";
                tempSessions = await api.getObjectSessionsByUserId(token);
                setUserServiceWorkerObjectSessions(tempSessions.filter(x=> JSON.parse(tempCombServiceWorker).some((y:{id: string})=>y.id == x.userServiceWorkerId)));
            } else {
                tempSessions = await api.getObjectSessionsOfServiceWorker(token, selectedServiceWorkerId);
                setUserServiceWorkerObjectSessions(tempSessions);
            }
            setGroups(await getUniqueGroupsBySessions(token, tempSessions));

            const tempObjects = await api.getUserObjectUnits(token);
            setObjects(tempObjects);

            const tempServiceWorkers = await api.getServiceWorkers(token);

            setServiceWorkers(tempServiceWorkers);

            setSelectedServiceWorker(tempServiceWorkers.find(x => x.id == selectedServiceWorkerId));
            setLoading(false);

        })();
    }, [history.location.pathname]);


    return (
        <Paper>
            <Typography variant='h4' component={"h4"}>Anmeldung/Abmeldung der Servicekraft {selectedServiceWorker?.name} in Objekten</Typography>
            {loading && <CircularProgress size={128} />}
            {(selectedServiceWorker || displayType == DrawerMenuItemDisplayType.All || displayType == DrawerMenuItemDisplayType.Multiple) &&
                <ServiceWorkerObjectSessionTable
                    serviceWorkers={serviceWorkers}
                    userServiceWorker={selectedServiceWorker}
                    userServiceWorkerObjectSessions={userServiceWorkerObjectSessions}
                    objects={objects}
                    groups={groups}
                />
            }
        </Paper>
    );
}