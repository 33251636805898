import { useCallback, useRef, useState } from 'react';
import {
    ColumnChooser,
    ExportPanel,
    Grid,
    PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableHeaderRow,
    Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, FormControlLabel, ListItem, TableCell, Typography } from '@mui/material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { GroupOnlineStatus, GroupOnlineStatusCountTable } from '../../../../../models/GroupOnlineStatus';
import { GroupServiceModeCountTable } from '../../../../../models/GroupServiceModeCountTable';
import { Group, GroupType } from '../../../../../models/Group';
import { mapToiletTypeKey } from '../../../../tables/Base';
import React from 'react';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { formatEuropeanDateTime, formatUnixSecTimestampEuropeanDateInMinutes, isOlderThanFifteenMinutes } from '../../../../../helpers/date-management';
import { differenceInMinutes, fromUnixTime, getUnixTime } from 'date-fns';
import { Check, Error, ExpandMore, SensorDoor } from '@mui/icons-material';
import { Button, Grid as MuiGrid, List, ListItemIcon, ListItemText } from '@mui/material';
import { green } from '@mui/material/colors';
import { getApiToken } from '../../../../../helpers/storage-management';
import { api } from '../../../../../api';
import { useHistory } from 'react-router-dom';
import { LoadingIcon } from '../../../../common/LoadingIcon';
import { ReactComponent as MotionSensorSvg } from '../../../../../assets/svgs/motion-sensor.svg'
import { useTranslation } from 'react-i18next';


const onSave = (workbook: any) => {


    //unfreeze panes (scrolling)
    workbook._worksheets[1].views[0].state = "split";

    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Check-Me-Now-Services`);
    });
};

export const OnlineStatusCountTable = ({ groupServicesMode, groupLicenseTable, setGroupsLicenseTableEntries }: { groupServicesMode: GroupOnlineStatusCountTable[], groupLicenseTable?: GroupLicenseTable[], setGroupsLicenseTableEntries: any }) => {
    const exporterRef: any = useRef(null);
    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const history = useHistory();
    const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);
    const {t} = useTranslation();
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);
    const [columns] = useState([
        { name: GroupServiceModeCountTable.Columns.groupId, title: t("serviceDocumentationTable.unitName"), getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.notificationName },
        {
            name: GroupServiceModeCountTable.Columns.groupType,
            title: t("myUnitsSettings.unitType"),
            getCellValue: (row: GroupOnlineStatusCountTable) => Group.GetLabelByType(groupLicenseTable?.find(x => x.id == row.groupId)?.groupType),
        },
        {
            name: GroupServiceModeCountTable.Columns.objectName,
            title: t("myUnitsSettings.objectName"),
            getCellValue: (row: GroupOnlineStatusCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.objectName,
        },
        {
            name: GroupServiceModeCountTable.Columns.levelName,
            title: t("myUnitsSettings.level"),
            getCellValue: (row: GroupOnlineStatusCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.levelName,
        },
        {
            name: GroupServiceModeCountTable.Columns.roomName,
            title: t("myUnitsSettings.customer"),
            getCellValue: (row: GroupOnlineStatusCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.roomName,
        },
        {
            name: GroupServiceModeCountTable.Columns.toiletType,
            title: t("myUnitsSettings.roomType"),
            getCellValue: (row: GroupOnlineStatusCountTable) => mapToiletTypeKey(groupLicenseTable?.find(x => x.id == row.groupId)?.toiletType),
        },
        {
            name: GroupServiceModeCountTable.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.userPropertyName,
        },
        {
            name: GroupServiceModeCountTable.Columns.lastOnlineSetting,
            title: 'Letzter Settings Check',
            getCellValue: (row: GroupServiceModeCountTable) => formatEuropeanDateTime(fromUnixTime(groupLicenseTable?.find(x => x.id == row.groupId)?.lastOnline || 0)),
        },
        { name: GroupServiceModeCountTable.Columns.lastOnline, title: 'Zuletzt Gemeldet', getCellValue: (row: GroupOnlineStatusCountTable) => formatEuropeanDateTime(row.lastOnlineTimestamp) },
        { name: GroupServiceModeCountTable.Columns.sensors, title: 'Sensoren', getCellValue: (row: GroupServiceModeCountTable) => JSON.stringify(groupLicenseTable?.find(x => x.id == row.groupId)?.sensors) },
        {
            name: GroupServiceModeCountTable.Columns.buildVersion,
            title: 'Aktuelle Build Version',
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.buildVersion || "",
        },
        {
            name: GroupServiceModeCountTable.Columns.nativeApplicationVersion,
            title: 'Installierte Apk Version',
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.nativeApplicationVersion || "",
        },
        {
            name: GroupServiceModeCountTable.Columns.downloadedFilename,
            title: 'Gedownloadet Apk Datei',
            getCellValue: (row: GroupServiceModeCountTable) => groupLicenseTable?.find(x => x.id == row.groupId)?.downloadedFilename || "",
        },
        {
            name: GroupOnlineStatusCountTable.Columns.deviceId,
            title: "Geräte ID",
            getCellValue: (row: GroupOnlineStatusCountTable) => row.deviceId,
        },
        {
            name: GroupOnlineStatusCountTable.Columns.sensorIds,
            title: "Sensor IDs",
            getCellValue: (row: GroupOnlineStatusCountTable) => row.sensorIds,
        },
        { name: GroupServiceModeCountTable.Columns.serviceCount, title: 'Online Gemeldet', getCellValue: (row: GroupOnlineStatusCountTable) => row.onlineCount },
        { name: GroupOnlineStatusCountTable.Columns.countMax, title: 'Maximal Online Anzahl', getCellValue: (row: GroupOnlineStatusCountTable) => row.onlineCountMax },
        { name: GroupOnlineStatusCountTable.Columns.countPercent, title: 'Prozentanteil Online', getCellValue: (row: GroupOnlineStatusCountTable) => ((100 * row.onlineCount) / row.onlineCountMax).toFixed(2) + '%' },
        {
            name: GroupOnlineStatusCountTable.Columns.batteryLevel,
            title: "Batterie Status",
            getCellValue: (row: GroupOnlineStatusCountTable) => row.batteryLevel,
        }
    ]);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        const [loading, setLoading] = React.useState(false);
        if (column.name === GroupServiceModeCountTable.Columns.lastOnline || column.name == GroupServiceModeCountTable.Columns.lastOnlineSetting) {
            let group = groupLicenseTable?.find(x => x.id == row.groupId);

            if (column.name == GroupServiceModeCountTable.Columns.lastOnlineSetting && group?.groupType != GroupType.DisplayWallApp) {
                return (
                    <Table.Cell
                        {...props}>
                        <MuiGrid container direction={"row"} alignItems="center" spacing={1} textAlign={"center"}>
                            <MuiGrid item>
                                <Typography>Setting Status nur für <br /><b>Touch Display</b></Typography>
                            </MuiGrid>
                        </MuiGrid>
                    </Table.Cell>
                )
            }


            let diffMin = 0;
            if (column.name === GroupServiceModeCountTable.Columns.lastOnline) {
                diffMin = differenceInMinutes(new Date(), row.lastOnlineTimestamp)
            } else {
                diffMin = differenceInMinutes(new Date(), fromUnixTime(groupLicenseTable?.find(x => x.id == row.groupId)?.lastOnline || 0));
            }
            let isInTime = diffMin <= 50;

            if (group?.groupType == GroupType.DisplayWallApp) {
                isInTime = diffMin <= 50;
            } else if (group?.groupType == GroupType.DisplayBatteryWallApp || group?.groupType == GroupType.TaqtFeedback || group?.groupType == GroupType.TaqtTime) {
                isInTime = diffMin <= 1500;
            }

            return (
                <Table.Cell
                    {...props}>
                    <MuiGrid container direction={"row"} alignItems="center" spacing={1}>
                        <MuiGrid item>
                            <Typography fontWeight={'bold'} color={isInTime ? green[700] : "error"}>{value}</Typography>
                        </MuiGrid>
                        <MuiGrid item>
                            {isInTime ? <Check style={{ color: green[700] }} fontSize={"large"} /> : <Error color='error' fontSize={"large"} />}
                        </MuiGrid>
                    </MuiGrid>
                </Table.Cell>
            )
        } else if (column.name == GroupServiceModeCountTable.Columns.sensors) {
            const sensors = groupLicenseTable?.find(x => x.id == row.groupId)?.sensors || [];
            return (
                <Table.Cell {...props}>
                    <MuiGrid container alignItems="center" justifyContent={"center"} alignContent={"center"}>
                        <MuiGrid item xs={12}>
                            <Accordion style={{ margin: 0 }} onChange={async (_, expanded) => {
                                if (expanded && groupLicenseTable) {
                                    const token = getApiToken(history);
                                    setLoading(true);
                                    let motions = await api.getLastMotionsByGroupId(token, row.groupId);
                                    for (let sensor of sensors) {
                                        let motion = motions.find(x => x.sensorId == sensor.id);
                                        if (motion) {
                                            sensor.lastMessage = formatUnixSecTimestampEuropeanDateInMinutes(motion.createdTimestamp);
                                            sensor.color = isOlderThanFifteenMinutes(motion?.createdTimestamp) ? "#eb4034" : "#2ee656";
                                        }
                                    }
                                    setLoading(false);
                                }
                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <MuiGrid container direction="column">
                                        <MuiGrid item>
                                            <Typography>{`${t("myObjectSettings.contains")} ${sensors?.length} Sensoren`}</Typography>
                                        </MuiGrid>

                                    </MuiGrid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MuiGrid container direction="column">
                                        <MuiGrid item>
                                            <List component="nav" aria-label="main mailbox folders">
                                                {sensors && sensors.length > 0 && sensors.map((el, index) => {
                                                    return (
                                                        <ListItem button key={index}>
                                                            <ListItemText style={{ color: el.color }} primary={el.sensorRoomName} secondary={el.lastMessage} />
                                                            <ListItemIcon>
                                                                <LoadingIcon
                                                                    icon={<MotionSensorSvg fill={el.color} />}
                                                                    loading={loading}
                                                                />
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    )
                                                })}

                                            </List>
                                        </MuiGrid>
                                    </MuiGrid>
                                </AccordionDetails>
                            </Accordion>
                        </MuiGrid>
                    </MuiGrid>
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };



    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == GroupServiceModeCountTable.Columns.groupId) {
            return { columnName: el.name, width: 180 }
        }
        if (el.name == GroupServiceModeCountTable.Columns.lastOnline || el.name == GroupServiceModeCountTable.Columns.lastOnlineSetting) {
            return { columnName: el.name, width: 230 }
        }
        if (el.name == GroupServiceModeCountTable.Columns.sensors) {
            return { columnName: el.name, width: 300 }
        }
        return { columnName: el.name, width: 180 }
    }));


    return (
        <>
            <Grid
                rows={groupServicesMode}
                columns={columns}
            >
                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //defaultSorting={[{ columnName: 'startTimestamp', direction: 'desc' }]}
                />
                <IntegratedSorting />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >

                    <TemplatePlaceholder />
                </Template>
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow
                />
                <PagingPanel pageSizes={[10, 15, 20, 25, 0]} />
            </Grid>
            <GridExporter
                hiddenColumnNames={hiddenColumnNames}
                ref={exporterRef}
                rows={groupServicesMode}
                columns={columns}
                onSave={onSave}
            />
        </>
    );
};