import makeStyles from '@mui/styles/makeStyles';
import { chartHeight } from "../components/charts/Base";

export const useNoDataChartStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: chartHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));


