import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp, Visibility } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { errorColor, white } from '../../../../styles/colors';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ObjectSelectionDataTable } from '../entireEval/ObjectSelectionDataTable';
import { ObjectSharedFolderTable } from '../../../../models/DataFileTree';
import { ObjectSharedFolderDataTableSelection } from './ObjectSharedFolderDataTable';
import { useTranslation } from 'react-i18next';

export const ObjectSharedFolderSelectionDialog = ({
  open,
  setOpen,
  currentObject,
  onShowNewViews,
}: {
  open: boolean,
  setOpen: any,
  currentObject: ObjectUnit,
  onShowNewViews: any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [token, setToken] = React.useState("");
  const [selectedRows, setSelectedRows] = React.useState<ObjectSharedFolderTable[]>([]);
  const [rows, setRows] = React.useState<ObjectSharedFolderTable[]>([]);
  const {t} = useTranslation();
  
  const handleClose = () => {
    setSelectedRows([]);
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const rows = await api.GetObjectSharedFolderTableByObjectIdAsync(currentObject.id, tempToken, enqueueSnackbar);
      setRows(rows);

      const tempObjects = await api.getUserObjectUnits(tempToken);
      setObjects(tempObjects);

    })();

  }, [open]);

  const disabled = selectedRows.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Wähle Geteilte Ordner, die angezeigt werden soll</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <ObjectSharedFolderDataTableSelection rows={rows} objects={objects}
          selectedRows={selectedRows}
          onSelectionChange={(selection: string[]) => {
            setSelectedRows(rows.filter(x => selection.includes(x.id)));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Visibility />}
          disabled={disabled}
          onClick={async () => {
            if (!currentObject) return;
            onShowNewViews(selectedRows);
            handleClose();
          }}
        >
          Dateien Anzeigen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));