import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { UserAddress } from '../../../../models/UserAddress';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../../helpers/storage-management';
import { useSnackbar } from 'notistack';
import { api } from '../../../../api';
import { ArrowBack, ArrowForward, ShoppingBasket, Undo } from '@mui/icons-material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentMethod } from '../../../../models/PaymentMethod';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { StripeOrderDetails } from "../../../../models/StripeOrderDetails";
import { getUserIdFromStorage } from '../../../../helpers/common';
import { PaymentForm } from './PaymentForm';
import { AddressForm } from './AddressForm';
import { Review } from './Review';
import { ReviewOption } from './ReviewOption';
import { Product } from '../../../../models/Product';
import { CheckoutProductListing } from './CheckoutProductListing';
import { CheckoutItem } from '../../../../models/CheckoutItem';
import { TransactionCompletedDialog } from '../../../dialogs/TransactionCompletedDialog';
import { PORTAL_URL, PUBLIC_SHOP_CHOSEN_ITEMS } from '../../../../constants';
import { LoadingDialog } from '../../../dialogs/LoadingDialog';
import { InvoicePaymentOrderDetails } from '../../../../models/InvoicePaymentOrderDetails';
import { ProductType } from '../../ProductType';
import { NavigatedFromType } from '../../../../models/NavigatedFrom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress, Dialog, DialogActions, DialogContent, Paper } from '@mui/material';
import { sortShopItemsByQueryUrl } from '../../../unauthorized/ShopPagePublic';

export const ShopPage = (
  {
    showall }: {
      showall: any
    }) => {

  const classes = useStyles();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const [token, setToken] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);

  const [userAddresses, setUserAddresses] = React.useState<UserAddress[]>([]);
  const [products, setProducts] = React.useState<Product[]>([]);

  const [items, setItems] = React.useState<CheckoutItem[]>([]);
  const [deliveryAddress, setDeliveryAddress] = React.useState<UserAddress | null>();
  const [paymentAddress, setPaymentAddress] = React.useState<UserAddress | null>();
  const [chosenPaymentMethod, setChosenPaymentMethod] = React.useState(StripePaymentMethod.Card);
  const [loading, setLoading] = React.useState(false);
  const [paymentResult, setPaymentResult] = React.useState<PaymentIntentResult | null>(null);
  const [redirectDialogVisible, setRedirectDialogVisible] = React.useState(false);
  const [showAllProducts, setShowAllProducts] = React.useState(false);
  const [isDirectlyAssigned, setIsDirectlyAssigned] = React.useState(true);

  const steps = ['Produkte auswählen', 'Warenkorb', 'LieferAdresse', 'Bezahldetails', 'Zusammenfassung'];

  const show = useQuery(history).get("showall") == "1" ? true : false;

  const chosenUnit = useQuery(history).get("chosenUnit");

  const chosenUnitName = useQuery(history).get("chosenUnitName");

  const navigatedFrom = useQuery(history).get("navigatedFrom");


  React.useEffect(() => {

    (async () => {

      const tokenTemp = getApiToken(history);
      if (!tokenTemp) return;
      setToken(tokenTemp);
      setLoading(true);
      setShowAllProducts(show);


      let prevItems = localStorage.getItem(PUBLIC_SHOP_CHOSEN_ITEMS);
      if (prevItems) {
        localStorage.removeItem(PUBLIC_SHOP_CHOSEN_ITEMS);
        setItems(JSON.parse(prevItems));
      }

      const tempProducts = await api.listProductsAsync(tokenTemp, enqueueSnackbar);
      sortShopItemsByQueryUrl(navigatedFrom, tempProducts);

      setProducts(tempProducts);

      setLoading(false);
      setRedirectDialogVisible(false);

      const tempUserAddresses = await api.getUserAddresses(tokenTemp);
      setUserAddresses(tempUserAddresses);
    })();

  }, []);

  const handlePayment = async () => {

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    switch (chosenPaymentMethod) {
      case StripePaymentMethod.Card:
        await handleCardPayment();
        break;
      case StripePaymentMethod.Sofort:
        await handleSofortPayment();
        break;
      case StripePaymentMethod.Invoice: /* this is actually not a stripe payment... */
        await handleInvoicePayment();
        break;
    }
  };

  const handleCardPayment = async () => {

    const userId = getUserIdFromStorage();

    if (!elements || !stripe || !deliveryAddress || !paymentAddress || !userId || !items) return;

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    const response = await api.getStripeClientSecretAsync(StripeOrderDetails.create(userId, isDirectlyAssigned ? chosenUnit : null, StripePaymentMethod.Card, items, deliveryAddress, paymentAddress), token);

    const result = await stripe.confirmCardPayment(response?.secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${paymentAddress.firstName} ${paymentAddress.lastName}`,
          phone: `${paymentAddress.phoneNumber}`
        },
      }
    });

    await setPaymentResult(result);
  }

  const handleSofortPayment = async () => {

    const userId = getUserIdFromStorage();

    if (!stripe || !deliveryAddress || !paymentAddress || !userId || !items) return;

    const response = await api.getStripeClientSofortSecret(StripeOrderDetails.create(userId, isDirectlyAssigned ? chosenUnit : null, StripePaymentMethod.Sofort, items, deliveryAddress, paymentAddress), token);

    if (!response) return;

    const result = await stripe.confirmSofortPayment(response?.secret, {
      payment_method: {
        sofort: {
          country: "DE"
        },
        billing_details: {
          name: `${paymentAddress.firstName} ${paymentAddress.lastName}`,
          phone: `${paymentAddress.phoneNumber}`,
        },
      },
      return_url: `${PORTAL_URL}/portal/payment-results/${response.id}?payment_method=${StripePaymentMethod.Sofort}`,
    });

    onRedirectPaymentStarted();
  }

  const handleInvoicePayment = async () => {
    const userId = getUserIdFromStorage();
    if (!deliveryAddress || !paymentAddress || !userId || !items) return;
    const response = await api.getInvoiceTransactionAsync(InvoicePaymentOrderDetails.create(userId, isDirectlyAssigned ? chosenUnit : null, StripePaymentMethod.Invoice, items, deliveryAddress, paymentAddress), token);

    if (!response) {
      history.push("/portal/checkout/failed");
      return;
    }
    history.push("/portal/checkout/success");


  }
  const onRedirectPaymentStarted = () => setRedirectDialogVisible(true);

  if (loading){
    return (
      <CircularProgress size={64}/>
    )
  }

  return (
    <div style={{position: "relative"}}>
        <main className={classes.layout} >
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {
              steps &&
              steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))
            }
          </Stepper>
          {
            activeStep === 0 &&
            <CheckoutProductListing
              products={products}
              items={items}
              setItems={setItems}
              showAllProducts={showAllProducts}
            />
          }
          {
            activeStep === 1 &&
            <ReviewOption
              items={items}
              products={products}
              setItems={setItems}
            />

          }

          {
            activeStep === 2 &&
            <AddressForm
              title={"LieferAdresse"}
              address={deliveryAddress}
              setAddress={setDeliveryAddress}
              userAddresses={userAddresses}
              setUserAddresses={setUserAddresses}
            />
          }
          {
            (activeStep === 3 || activeStep === 4) &&
            <>
              <PaymentForm
                hidden={activeStep !== 3}
                chosenPaymentMethod={chosenPaymentMethod}
                setChosenPaymentMethod={setChosenPaymentMethod}
                address={paymentAddress}
                setAddress={setPaymentAddress}
                userAddresses={userAddresses}
                setUserAddresses={setUserAddresses}
              />
              <Review
                hidden={activeStep !== 4}
                deliveryAddress={deliveryAddress}
                paymentAddress={paymentAddress}
                items={items}
              />
            </>
          }
        </main>
        <Paper className={classes.buttons} style={{position: 'sticky', padding: 10, width: "calc(48px + 100%)", marginLeft: -24, bottom: 0, marginTop: 10, borderRadius: 0}}>
          {chosenUnit != null &&
            <FormGroup style={{ alignSelf: 'flex-end', marginRight: 'auto' }}>
              <FormControlLabel control={<Checkbox checked={isDirectlyAssigned} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsDirectlyAssigned(event.target.checked)} />} label={`Direkt zuweisen auf Einheit: ${chosenUnitName}`} />
            </FormGroup>
          }
          {
            !loading &&
            activeStep === 0 &&
            items.length > 0 &&
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<Undo />}
              onClick={() => {
                setItems([]);
              }}
            >
              Auswahl abheben
            </Button>
          }
          {
            activeStep !== 0 && (
              <Button
                className={classes.button}
                startIcon={<ArrowBack />}
                onClick={() => {
                  setActiveStep(activeStep - 1);
                }}
              >
                Zurück
              </Button>
            )
          }
          {
            !loading &&
            activeStep === steps.length - 1 &&
            <Button
              className={classes.button}
              startIcon={<ShoppingBasket />}
              variant={'contained'}
              color="primary"
              onClick={async () => {

                const userId = getUserIdFromStorage();

                if (!userId) {
                  enqueueSnackbar("Benutzer ID nicht gefunden", { variant: 'error' });
                  return;
                }

                if (items.length === 0) {
                  enqueueSnackbar("Mindesten ein Artikel ist für die Bestellung erforderlich", { variant: 'error' });
                  return;
                }

                if (!deliveryAddress) {
                  enqueueSnackbar("LieferAdresse ist erforderlich", { variant: 'error' });
                  return;
                }

                if (!paymentAddress) {
                  enqueueSnackbar("RechnungsAdresse ist erforderlich", { variant: 'error' });
                  return;
                }

                return await handlePayment();
              }}
            >
              Bestellen
            </Button>
          }

          {
            !loading &&
            activeStep < steps.length - 1 &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
              className={classes.button}
              endIcon={<ArrowForward />}
              disabled={items.length <= 0}
            >
              Nächste
            </Button>
          }
        </Paper>

      <TransactionCompletedDialog
        result={paymentResult}
        setResult={setPaymentResult}
        onReturnToShop={() => {
          setActiveStep(0);
          setItems([]);
          setDeliveryAddress(undefined);
          setPaymentAddress(undefined);
          setChosenPaymentMethod(StripePaymentMethod.Card);
          setPaymentResult(null);
          setLoading(false);
        }}
      />

      <LoadingDialog
        text="Sie werden an die Zahllungseite weitergeleitet"
        visible={redirectDialogVisible}
        setVisible={setRedirectDialogVisible}
      />

    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1200 + parseInt(theme.spacing(2)) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  }
}));

export const useQuery = (history: any) => {
  return new URLSearchParams(history.location.search);
}

export const getQuery = () => {
  return new URLSearchParams(window.location.search);
}