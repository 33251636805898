import axios from 'axios';
import { headers } from "../api";

export const changePassword = async (password: string, token: string, enqueueSnackbar: any): Promise<any> => {
    try {
        let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/auth/change-password`,
            { password: password },
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
        enqueueSnackbar("Passwort wurde geändert!", { variant: 'success' });
        return ret.status;
    } catch (err: any) {
        enqueueSnackbar("Passwort ändern fehlgeschlagen", { variant: 'error' });
        console.log(JSON.stringify(err, null, 2));
        console.log(err.response.status);
    }
    return null as any;
}