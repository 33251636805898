import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { api } from '../../api';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";
import { getApiToken, setUserTokenStorage } from '../../helpers/storage-management';
import { Session } from '../../models/Session';
import { USER_ID } from '../../constants';
import { Copyright } from '../Copyright';
import ShoppingImage from '../../assets/image/shop/shopping-icon.png';
import SignInLogo from '../../assets/image/signin-logo.png';
import { Card, CardActionArea, CardMedia, Divider, IconButton } from '@mui/material';
import { BluetoothDisabled, BluetoothSearching, Scanner, Send } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let bluetoothDevice: BluetoothDevice | null;
let charNotify: any;
let charWrite: any;

export default function DigitalCheckpointConfig() {



  const DEFAULT_BLE_MTU = 20;
  const TXT_ONLY_MAGIC = 0x0D;

  const SERVICE_UUID = 0xfff0;
  const CHAR_NOTIFY = "0000fff2-0000-1000-8000-00805f9b34fb"; // 0xFFF1
  const CHAR_WRITE = "0000fff2-0000-1000-8000-00805f9b34fb"; // 0xFFF2

  const ACK_TYPE_LINK = 0x00;
  const ACK_SUBTYPE_LINK_OK = 0x00;
  const ACK_SUBTYPE_LINK_ERR = 0x01;

  const ACK_TYPE_DRAW = 0x01;
  const ACK_SUBTYPE_DRAW_OK = 0x00;
  const ACK_SUBTYPE_DRAW_ERR = 0x01;

  let bleMtuSize = DEFAULT_BLE_MTU;


  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [isSendDisabled, setIsSendDisabled] = React.useState(true);
  const [isDisconnectDisabled, setIsDisconnectDisabled] = React.useState(true);
  const [dataSentText, setDataSentText] = React.useState("N");
  const [drawScreenText, setDrawScreenText] = React.useState("N");

  const [customField1, setCustomField1] = React.useState("");
  const [customField2, setCustomField2] = React.useState("");
  const [displayIdText, setDisplayIdText] = React.useState("");
  const [customField4, setCustomField4] = React.useState("");
  const [qrCodeText, setQrCodeText] = React.useState("");

  const onScanButtonClick = () => {
    let options = { filters: [{ services: [SERVICE_UUID] }, { name: 'SyncSign-Display-Lite' }, { name: 'greenbird' }] };

    bluetoothDevice = null;
    console.log("Requesting Bluetooth Device...");
    navigator.bluetooth
      .requestDevice(options)
      .then((device) => {
        bluetoothDevice = device;
        bluetoothDevice.addEventListener(
          "gattserverdisconnected",
          onDisconnected
        );
        return connect();
      })
      .catch((error) => {
        console.log("Argh! " + error);
      });
  }

  function onDisconnectButtonClick() {
    if (!bluetoothDevice) {
      return alert("No Bluetooht Device");
    }
    console.log("Disconnecting from Bluetooth Device...");
    if (bluetoothDevice?.gatt?.connected) {
      if (charNotify) {
        charNotify
          .stopNotifications()
          .then((_: any) => {
            console.log("> Notifications stopped");
            charNotify.removeEventListener(
              "characteristicvaluechanged",
              handleNotifications
            );
          })
          .then((_: any) => {
            bluetoothDevice?.gatt?.disconnect();
            updateDeviceStatus("RESET", null);
          })
          .catch((error: any) => {
            console.log("Argh! " + error);
          });
      } else {
        bluetoothDevice.gatt.disconnect();
        updateDeviceStatus("RESET", null);
      }
    } else {
      console.log("> Bluetooth Device is already disconnected");
    }
  }

  const onDisconnected = (event: any) => {
    // Object event.target is Bluetooth Device getting disconnected.
    console.log("> Bluetooth Device disconnected");
    setIsSendDisabled(true);
    setIsDisconnectDisabled(true);
  }

  const connect = async () => {
    try {
      charWrite = null;
      charNotify = null;
      console.log("Connecting to Bluetooth Device...");
      let server = await bluetoothDevice?.gatt?.connect();
      if (server) {
        console.log("> Bluetooth Device connected");
        console.log(">>> getPrimaryService", server.getPrimaryService(SERVICE_UUID));
        let service = await server.getPrimaryService(SERVICE_UUID);
        console.log("service", service);
        console.log("Getting Characteristic...");
        await enumerateGatt(server);

        if (charNotify) {
          console.log(`Found Characteristic: ${charNotify.uuid}`);
          await charNotify.startNotifications();
          console.log("> Notifications started");
          charNotify.addEventListener(
            "characteristicvaluechanged",
            handleNotifications
          );
        }
      }

    } catch (error) {
      setIsSendDisabled(true);
      setIsDisconnectDisabled(true);
      console.error("Argh! " + error);
    }
  }

  const handleNotifications = (event: any) => {
    let value = event.target.value;
    let data: any[] = [];
    for (let i = 0; i < value.byteLength; i++) {
      data.push("0x" + ("00" + value.getUint8(i).toString(16)).slice(-2));
    }
    const magic = data[0];
    if (parseFloat(magic) != TXT_ONLY_MAGIC) {
      console.log("invalid response");
      return;
    }
    const payloadLength = data[1] | (data[2] << 8);
    const scope = data[3];
    const result = data[4];
    const errCode = data[5];
    if (scope == ACK_TYPE_LINK) {
      if (result == ACK_SUBTYPE_LINK_OK) {
        console.log("Data Sent OK");
        updateDeviceStatus("LINK", true);
      } else if (result == ACK_SUBTYPE_LINK_ERR) {
        console.log("Data Sent Failed", errCode);
        updateDeviceStatus("LINK", false);
      } else {
        console.log("Data Sent Result:", result, errCode);
        updateDeviceStatus("LINK", false);
      }
    } else if (scope == ACK_TYPE_DRAW) {
      if (result == ACK_SUBTYPE_DRAW_OK) {
        console.log("Draw Screen OK");
        updateDeviceStatus("DRAW", true);
      } else if (result == ACK_SUBTYPE_DRAW_ERR) {
        console.log("Draw Screen Failed", errCode);
        updateDeviceStatus("DRAW", false);
      } else {
        console.log("Draw Screen Result:", result, errCode);
        updateDeviceStatus("DRAW", false);
      }
    } else {
      console.log("scope:", scope, "result:", result, "errCode:", errCode);
    }
    console.log("> " + data.join(" "));
  }

  const updateDeviceStatus = (scope: string, result: boolean | null) => {
    let dataSent = document.querySelector("#data-sent");
    let drawScreen = document.querySelector("#draw-screen");
    if (scope === "LINK") {
      setDataSentText(result ? "Data Sent OK" : "Data Sent Failed");
    } else if (scope === "DRAW") {
      setDrawScreenText(result ? "Draw Screen OK" : "Draw Screen Failed");
    } else if (scope === "RESET") {
      setDataSentText("N");
      setDrawScreenText("N");
    }
  }

  const enumerateGatt = async (server: BluetoothRemoteGATTServer) => {
    const services = await server.getPrimaryServices();
    console.log(services);
    const sPromises = services.map(async (service) => {
      const characteristics = await service.getCharacteristics();
      const cPromises = characteristics.map(async (characteristic) => {
        let descriptors = await characteristic.getDescriptors();
        let resDescriptions: string[] = [];
        resDescriptions = descriptors.map(
          (descriptor) => `\t\t|_descriptor: ${descriptor.uuid}`
        );
        resDescriptions.unshift(`\t|_characteristic: ${characteristic.uuid}`);
        if (characteristic.uuid === CHAR_NOTIFY) {
          charNotify = characteristic;
        }
        if (characteristic.uuid === CHAR_WRITE) {
          charWrite = characteristic;
          setIsSendDisabled(false);
          setIsDisconnectDisabled(false);
        }
        return resDescriptions.join("\n");
      });

      const descriptors = await Promise.all(cPromises);
      descriptors.unshift(`service: ${service.uuid}`);
      return descriptors.join("\n");
    });

    const result = await Promise.all(sPromises);
    console.log(result.join("\n"));
  }

  const onSendTextOnly = async () => {
    try {
      updateDeviceStatus("RESET", null);
      await sendTextOnly(0, [
        customField1,
        customField2,
        displayIdText,
        customField4,
        qrCodeText,
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  const sendTextOnly = async (templateId: any, strings: any) => {
    // [TXT_ONLY_MAGIC:1] [TotalLength:2] [TemplateId:1] [Reserved:3] [TextStrings:N]
    // Please note that the [TextStrings:N] is formated as:
    //      [LEN:1][TEXT:LEN] [LEN:1][TEXT:LEN]...[LEN:1][TEXT:LEN]

    console.log(strings);
    let textData = new Uint8Array();
    for (let i = 0; i < strings.length; i++) {
      if (strings[i].length <= 0x7f) {
        let enc = new TextEncoder();
        let len = new Uint8Array([strings[i].length & 0x7f]);
        let text = enc.encode(strings[i]);
        let mergedArray = new Uint8Array(
          textData.length + len.length + text.length
        );
        mergedArray.set(textData);
        mergedArray.set(len, textData.length);
        mergedArray.set(text, textData.length + len.length);
        textData = mergedArray;
      }
    }
    let totalLength = 1 + 3 + textData.length;

    let data = new Uint8Array([
      TXT_ONLY_MAGIC,
      totalLength & 0xff,
      (totalLength >> 8) & 0xff,
      templateId,
      0x00,
      0x00,
      0x00,
    ]);

    data = concatArrayBuffer(data, textData);
    console.log("data:", data);
    await writeDataChunk(data);
    console.log("sendTextOnly TPL ID=%d Strings=%s done", templateId, strings);
  }

  const writeDataChunk = async (data: any) => {
    // send data splited by MTU, then send the remaining in recursion

    if (data === "" || data === null || data === undefined) return;

    let pkt = data.slice(0, bleMtuSize); // splite by MTU
    let remain = data.slice(bleMtuSize);
    let typedArray = new Uint8Array(pkt);
    console.log("   sending chunk", typedArray.buffer);
    if (charWrite) {
      await charWrite
        .writeValueWithResponse(typedArray.buffer)
        .then(async () => {
          console.log("data sent", typedArray.length);
          if (remain.length) {
            await writeDataChunk(remain);
          } else {
            console.log("All chunks(s) sent.");
          }
        })
        .catch((e:any) => {
          alert(JSON.stringify(e));
          console.log(e);
        });
    }

  }

  function concatArrayBuffer(arrayOne: any, arrayTwo: any) {
    // a, b TypedArray of same type

    let mergedArray = new Uint8Array(arrayOne.length + arrayTwo.length);
    mergedArray.set(arrayOne);
    mergedArray.set(arrayTwo, arrayOne.length);
    return mergedArray;
  }


  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container direction={"column"} spacing={3}>
          <Grid item>
            <Typography variant='h4'>Digitales Checkpoint Konfigurieren</Typography>
          </Grid>
          <Grid item container direction={"row"} spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size='large'
                onClick={() => {
                  onScanButtonClick();
                }}
                endIcon={<BluetoothSearching />}
              >
                Scannen
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size='large'
                disabled={isDisconnectDisabled}
                onClick={() => {
                  onDisconnectButtonClick();
                }}
                endIcon={<BluetoothDisabled />}
              >
                Verbindung Trennen
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={"Text Oben"}
              placeholder="Weniger als 12 Zeichen"
              disabled={loading}
              value={customField1}
              onChange={(event: any) => {
                setCustomField1(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={"Text Mitte"}
              placeholder="Weniger als 16 Zeichen"
              disabled={loading}
              value={customField2}
              onChange={(event: any) => {
                setCustomField2(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={"Display Id"}
              placeholder="weniger als 12 Zeichen"
              disabled={loading}
              value={displayIdText}
              onChange={(event: any) => {
                setDisplayIdText(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={"Text unten"}
              placeholder="weniger als 32 Zeichen"
              disabled={loading}
              value={customField4}
              onChange={(event: any) => {
                setCustomField4(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              label={"Qr Code Url"}
              type="url"
              placeholder="https://xxx"
              disabled={loading}
              value={qrCodeText}
              onChange={(event: any) => {
                setQrCodeText(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size='large'
              disabled={isSendDisabled}
              onClick={() => {
                onSendTextOnly();
              }}
              endIcon={<Send />}
            >
              Zum Display (Digital-Checkpoint) senden
            </Button>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>{drawScreenText}/{dataSentText}</Typography>
          </Grid>
        </Grid>

      </div>
      <Copyright />
    </Container>
  );
}