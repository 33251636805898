import { CleaningServices, EnergySavingsLeaf, ExpandMore, MapsHomeWork, Savings, Timer } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { Fragment, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { DataFilterType, PeriodChartFilterType, generateChartData, getDataFromGroupIdsTrigger, APIDataAmountType, getServiceTriggerByUserIdGroupIds, getServiceData } from "../../../../helpers/charts/base";
import { generateCleaningTriggerChartData, getAllCleaningTriggerTypes } from "../../../../helpers/charts/cleaningTriggerGenerator";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { defaultPeriodChartFilterType, formatEuropeanDate, getDefaultPeriod, getEndDateFromUrlOrStorage, getStartDateFromUrlOrStorage } from "../../../../helpers/date-management";
import useNoInitialEffect from "../../../../helpers/extensions";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupCleaningTrigger } from "../../../../models/GroupCleaningTrigger";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { CleaningTriggerDataTable } from "./cleaningTrigger/CleaningTriggerDataTable";
import { TriggeredCleaningTask } from "./cleaningTrigger/TriggeredCleaningTask";
import { EvalApiType } from "./Services";
import { GLOBAL_PERIOD_CHART_FILTER } from "../../../../constants";
import { generateCleaningDurationData, generatePlannedCleaningDuration } from "../../../../helpers/charts/setPointAndActualValue/SetPointAndActualValueDataGenerator";
import { ServicesDurationChart } from "./services/ServicesDurationChart";
import { PropertyType, UserProperty } from "../../../../models/UserProperty";
import { useQuery } from "../checkout/ShopPage";
import { FormattedNumber } from "react-intl";
import { PropertiesPerformanceTable } from "../../../tables/PropertiesPerformanceTable";
import { DatePicker } from "@mui/x-date-pickers";
import { ServiceModeDurationPlanned } from "../../../../models/ServiceModeDurationPlanned";
import { eachDayOfInterval, fromUnixTime, isSameDay, isWithinInterval, subDays } from "date-fns";
import { StringHelper } from "../../../../helpers/string-helper";
import { GroupServiceMode, GroupServiceModeTable } from "../../../../models/GroupServiceMode";
import { ServicesPlannedDurationChart } from "./services/ServicesPlannedDurationChart";
import { DurationChoice, DurationChoiceType, getChartLabelType } from "../../../core/DurationChoice";
import { ChartLabelType } from "../../../charts/Base";
import { NumberHelper } from "../../../../helpers/number-helper";
import { AdminSetting, AdminUsageCategory } from "../../../../models/AdminSetting";
import { RruleGeneratorComponent } from "../../../../helpers/RruleGenerator";
import RRule from "rrule";
import { DateDataTable } from "../../../../helpers/DateDataTable";
import { ServiceModeTable } from "../../../tables/ServiceModeTable";
import { UserServiceWorker } from "../../../../models/UserServiceWorker";
import { formatServiceMode } from "../../../../helpers/tables/serviceModeGenerator";
import { GroupSelectionTable } from "../../../tables/GroupSelectionTable";
import { green } from "../../../../styles/colors";
import { SetPointActualValueModel } from "../../../../models/SetPointActualValueModel";
import { CarbonDioxideConsumptionChart } from "./services/CarbonDioxideConsumptionChart";
import { AreaSizeChart } from "./services/AreaSizeChart";
import { ServiceModesCountChart } from "./services/ServiceModesCountChart";
import { CurrencyHelper } from "../../../../helpers/CurrencyHelper";
import { UserServiceWorkerGroupDistanceTable } from "../../../tables/UserServiceWorkerGroupDistanceTable";
import { GroupDistanceFromServiceWorker } from "../../../../models/GroupDistanceFromServiceWorker";
import { useTranslation } from "react-i18next";

export const co2PerKm = 1.2;

export const SetPointAndActualValueDashboard = ({ serviceDisplayType, drawerDisplayType, groupIds }: { serviceDisplayType: EvalApiType, drawerDisplayType: DrawerMenuItemDisplayType, groupIds?: any }) => {
    const [ServiceTriggerstriggeredCleaningTasks, setServiceTriggersTriggeredCleaningTasks] = React.useState<any>([]);
    const [serviceTriggerstriggeredCleaningAllTasks, setserviceTriggersTriggeredCleaningAllTasks] = React.useState<any>([]);
    const [serviceTriggerstriggerCleaningType, setserviceTriggersTriggerCleaningType] = React.useState<any>([]);
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);
    const { apiDataAmountType, reloadDataFromApi }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number } = React.useContext(GlobalContext);
    const { setApiDataLoading, setIsPreFilterDialogVisible }: { setApiDataLoading: any, setIsPreFilterDialogVisible: any } = React.useContext(GlobalContext);
    const {t} = useTranslation();

    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();
    const childRefTriggeredCleaningTaskChart: any = useRef();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [cleaningTriggerTableData, setCleaningTriggerTableData] = React.useState<GroupCleaningTrigger[]>([]);
    const [serviceModesTableData, setServieModesTableData] = React.useState<GroupServiceMode[]>([]);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();

    const [serviceModeCarbonDioxideConsumptionDataAll, setServiceModeCarbonDioxideConsumptionDataAll] = React.useState<any>([]);
    const [serviceModeCarbonDioxideConsumptionData, setServiceCarbonDioxideConsumptionData] = React.useState<any[]>([]);

    const [serviceModeAreaSizeDataAll, setServiceModeAreaSizeDataAll] = React.useState<any>([]);
    const [serviceModeAreaSizeData, setServiceAreaSizeData] = React.useState<any[]>([]);

    const [serviceModeSerivceModesCountDataAll, setServiceModeSerivceModesCountDataAll] = React.useState<any>([]);
    const [serviceModeSerivceModesCountData, setServiceSerivceModesCountData] = React.useState<any[]>([]);


    const [serviceModeDurationPlannedDataAll, setServiceModeDurationPlannedDataAll] = React.useState<any>([]);
    const [serviceModeDurationPlannedData, setServiceModeDurationPlannedData] = React.useState<any[]>([]);
    const [serviceModeDurationPlannedDataFields, setServiceModeDurationPlannedDataFields] = React.useState<any[]>([]);
    const childRefsserviceModeDurationPlannedDataChart: any = useRef();
    const childRefsetPointActualValueServiceDurationPlannedChart: any = useRef();
    const childRefsserviceModeDurationPlannedData: any = useRef();


    const childRefsetPointActualValueCarbonDioxideConsumptionChart: any = useRef();
    const childRefCarbonDioxideConsumptionData: any = useRef();

    const childRefAreaSizeChart: any = useRef();
    const childRefAreaSizeData: any = useRef();

    const childRefServiceModesCountChart: any = useRef();
    const childRefServiceModesCountData: any = useRef();

    const [startDateServiceModeDurationPlanned, setStartDateServiceModeDurationPlanned] = React.useState(subDays(new Date(), 10));
    const [endDateServiceModeDurationPlanned, setEndDateServiceModeDurationPlanned] = React.useState(new Date());


    const [durationChoiceType, setDurationChoiceType] = React.useState(DurationChoiceType.hours);


    const [areaSizeSum, setAreaSizeSum] = React.useState(0);
    const [systemProperties, setSystemProperties] = React.useState<UserProperty[]>([]);
    const [currentSystemProperty, setCurrentSystemProperty] = React.useState<UserProperty>();
    const [currentRRule, setCurrentRRule] = React.useState(new RRule());
    const [currentRRuleDates, setCurrentRRuleDates] = React.useState<Date[]>([]);
    const [currentServicesOfUsageType, setCurrentServicesOfUsageType] = React.useState<GroupServiceModeTable[]>([]);
    const [rowsPerformanceTable, setRowsPerformanceTable] = React.useState<ServiceModeDurationPlanned[]>([]);
    const [rowsSetPointActualValueData, setRowsSetPointActualValueData] = React.useState<SetPointActualValueModel[]>([]);
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [currentGroups, setCurrentGroups] = React.useState<GroupLicenseTable[]>([]);
    const [currentArea, setCurrentArea] = React.useState(0);
    const [currentCarbonDioxideConsumption, setCurrentCarbonDioxideConsumption] = React.useState(0);

    const [groupDistanceFromServiceWorker, setGroupDistanceFromServiceWorker] = React.useState<GroupDistanceFromServiceWorker[]>([]);
    const [currentDistanceSum, setCurrentDistanceSum] = React.useState(0);

    const { adminSetting }: { adminSetting: AdminSetting } = useContext(GlobalContext);
    const [currentUsageCategory, setCurrentUsageCategory] = React.useState<AdminUsageCategory>();

    const queryString = useQuery(history);
    const savedServiceDatesCount = currentRRuleDates.length - currentServicesOfUsageType.length;
    const currentAvgAreaSize = Math.ceil(currentArea / currentGroups.length);
    React.useEffect(() => {
        if (currentSystemProperty && currentSystemProperty.rrule) {
            let currentGroups = groupsLicenseTableEntries.filter(x => x.userPropertyIds.includes(currentSystemProperty.id));
            setCurrentGroups(currentGroups);
            let currentAreaSize = currentGroups.reduce((prevVal, group) => {
                return prevVal + group.areaSize;
            }, 0);
            setCurrentArea(currentAreaSize);
            let rules = RRule.fromString(currentSystemProperty.rrule);
            rules.options.dtstart = startDateServiceModeDurationPlanned;
            rules.options.until = endDateServiceModeDurationPlanned;
            rules.options.count = null;
            let currentServices = serviceModesTableData.filter(service => {
                return isWithinInterval(fromUnixTime(service.startTimestamp), { start: startDateServiceModeDurationPlanned, end: endDateServiceModeDurationPlanned }) && service.isPartlyCompleted && currentGroups.map(x => x.id).includes(service.groupId);
            });
            let usageCategory = adminSetting?.adminUsageCategories?.find(x => x.id == currentSystemProperty.usageCategoryId);
            setCurrentUsageCategory(usageCategory);
            setCurrentServicesOfUsageType(formatServiceMode(currentServices));
            setCurrentRRule(rules);
            let setPointDates = rules.all();
            setCurrentRRuleDates(setPointDates)

            const currentAvgAreaSize = Math.ceil(currentAreaSize / currentGroups.length);
            let dataPointAndActualValue: SetPointActualValueModel[] = [];
            setPointDates.forEach(date => {
                let newVal = new SetPointActualValueModel();
                newVal.date = date;
                newVal.setPointServiceTime = Math.ceil(currentSystemProperty.areaPerformancePerSquareMeter / currentAvgAreaSize);
                if (usageCategory)
                    newVal.setPointcarbonDixoideConsumption = currentAvgAreaSize * (usageCategory.carbonDioxideConsumption / 100)

                newVal.setPointAreaSize = currentAvgAreaSize;
                newVal.setPointServiceModesCount = 1;
                dataPointAndActualValue.push(newVal);
            });

            currentServices.forEach(service => {
                let newVal = new SetPointActualValueModel();
                newVal.date = fromUnixTime(service.startTimestamp);
                newVal.actualServiceTime = (service.endTimestamp - service.startTimestamp) / 3600;
                if (usageCategory)
                    newVal.actualCarbonDixoideConsumption = currentAvgAreaSize * (usageCategory.carbonDioxideConsumption / 100)
                newVal.actualAreaSize = currentAvgAreaSize;
                newVal.actualServiceModesCount = 1;

                dataPointAndActualValue.push(newVal);
            });

            let currentVals: GroupDistanceFromServiceWorker[] = [];
            let allDistance = 0;
            currentGroups.forEach(group => {
                let newEntries = group.groupServiceWorkers.map(serviceWorker => {
                    let newVal = new GroupDistanceFromServiceWorker();
                    newVal.notificationName = group.notificationName + ' ' + group.levelName;
                    newVal.serviceWorkerName = serviceWorker.username;
                    newVal.distanceFromGroup = serviceWorker.distanceFromGroup;
                    allDistance += serviceWorker.distanceFromGroup;
                    return newVal;
                })

                currentVals.push(...newEntries);
            });

            setCurrentDistanceSum(allDistance);

            setGroupDistanceFromServiceWorker(currentVals);

            setRowsSetPointActualValueData(dataPointAndActualValue);

            updatePlannedDurationChart(startDateServiceModeDurationPlanned, endDateServiceModeDurationPlanned, dataPointAndActualValue, durationChoiceType);

        }
    }, [currentSystemProperty, startDateServiceModeDurationPlanned, endDateServiceModeDurationPlanned])


    const loadDataAsync = async () => {

        let endDate = getEndDateFromUrlOrStorage(queryString);
        let startDate = getStartDateFromUrlOrStorage(queryString);
        setStartDateServiceModeDurationPlanned(startDate);
        setEndDateServiceModeDurationPlanned(endDate);

        setApiDataLoading(true);
        setLoadingCompleted(false);
        setServiceTriggersTriggeredCleaningTasks([]);
        const token = getApiToken(history);
        if (!token) return;



        let serviceTriggers = await getServiceTriggerByUserIdGroupIds(groupId, token, groupIds);

        setCleaningTriggerTableData(serviceTriggers);
        let allCleaningTriggerTypes = getAllCleaningTriggerTypes();
        let cleaningTriggerChartAllData = generateCleaningTriggerChartData(serviceTriggers);
        setserviceTriggersTriggeredCleaningAllTasks(cleaningTriggerChartAllData);
        let cleaningTriggerChartData = generateChartData(cleaningTriggerChartAllData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allCleaningTriggerTypes);
        setserviceTriggersTriggerCleaningType(allCleaningTriggerTypes);
        setServiceTriggersTriggeredCleaningTasks(cleaningTriggerChartData);

        let groupsPortal: GroupLicenseTable[] = [];
        if (serviceDisplayType == EvalApiType.Objects) {
            groupsPortal = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groupsPortal.map(x => x.id);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        else if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            groupsPortal = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groupsPortal.map(x => x.id);
            setGroupsLicenseTableEntries(groupsPortal);
        }
        else if (serviceDisplayType == EvalApiType.Groups) {
            groupsPortal = await getPortalGroups(groupIds, token, groupId);
            groupIds = groupsPortal.map(x => x.id);
            setGroupsLicenseTableEntries(groupsPortal);
        }


        let sumArea = groupsPortal.reduce((sum, group) => {
            return sum + group.areaSize;
        }, 0);

        setAreaSizeSum(sumArea);
        let allProperties = groupsPortal.flatMap(x => x.userPropertyIds).filter((value, index, array) => array.indexOf(value) === index);
        let properties = await api.getUserPropertiesByIds(allProperties, token);

        let systemProperties = properties.filter(x => x.propertyType == PropertyType.System && adminSetting?.adminUsageCategories?.some(category => category.id == x.usageCategoryId));
        setSystemProperties(systemProperties);


        const performanceItems: ServiceModeDurationPlanned[] = [];

        systemProperties.forEach(systemProperty => {
            let groups = groupsPortal.filter(group => group.userPropertyIds.includes(systemProperty.id));
            let currentUsageCategory = adminSetting?.adminUsageCategories?.find(x => x.id == systemProperty.usageCategoryId);
            if (currentUsageCategory) {
                let newEntries = groups.map(group => {

                    return { groupId: group.id, notificationName: group.notificationName, propertyId: systemProperty.id, propertyName: systemProperty.name, areaSize: group.areaSize, usageType: currentUsageCategory?.name || "Kein Systemmerkmal", areaPerformancePerSquareMeter: systemProperty.areaPerformancePerSquareMeter, co2Value: currentUsageCategory?.carbonDioxideConsumption || 0, countGroups: groups.length }
                })
                performanceItems.push(...newEntries);
            }

        });


        setRowsPerformanceTable(performanceItems);


        const tempServiceWorkers = await api.getServiceWorkers(token);
        setUserServiceWorkers(tempServiceWorkers);


        setGroupsLicenseTableEntries(groupsPortal);


        let periodChartFilter: PeriodChartFilterType = (localStorage.getItem(GLOBAL_PERIOD_CHART_FILTER) || "" + defaultPeriodChartFilterType) as PeriodChartFilterType;
        let servicesMode = await getServiceData(groupId, token, groupIds);
        setServieModesTableData(servicesMode);



        setLoadingCompleted(true);
        setApiDataLoading(false);
    }

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);

    useNoInitialEffect(() => {
        loadDataAsync();
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
        (async () => {
            const token = getApiToken(history);

        })();

    }, [apiDataAmountType, reloadDataFromApi]);

    useNoInitialEffect(() => {
        updatePlannedDurationChart(startDateServiceModeDurationPlanned, endDateServiceModeDurationPlanned, rowsSetPointActualValueData, durationChoiceType);

    }, [durationChoiceType]);

    const updateChosenPastDaysFromParent = (event: any) => {
        if (childRefsserviceModeDurationPlannedData.current)
            childRefsserviceModeDurationPlannedData.current.updateChosenPastDays(event.target.value);

        if (childRefCarbonDioxideConsumptionData.current)
            childRefCarbonDioxideConsumptionData.current.updateChosenPastDays(event.target.value);

        if (childRefAreaSizeData.current)
            childRefAreaSizeData.current.updateChosenPastDays(event.target.value);
    }

    const updateEndDateFromParent = (event: any) => {
        setStartDateServiceModeDurationPlanned(event);
        if (childRefsserviceModeDurationPlannedData.current)
            childRefsserviceModeDurationPlannedData.current.updateEndDateFromParent(event);

        if (childRefCarbonDioxideConsumptionData.current)
            childRefCarbonDioxideConsumptionData.current.updateEndDateFromParent(event);

        if (childRefAreaSizeData.current)
            childRefAreaSizeData.current.updateEndDateFromParent(event);

    }

    const updateStartDateFromParent = (event: any) => {
        setEndDateServiceModeDurationPlanned(event);
        if (childRefsserviceModeDurationPlannedData.current)
            childRefsserviceModeDurationPlannedData.current.updateStartDateFromParent(event);

        if (childRefCarbonDioxideConsumptionData.current)
            childRefCarbonDioxideConsumptionData.current.updateStartDateFromParent(event);

        if (childRefAreaSizeData.current)
            childRefAreaSizeData.current.updateStartDateFromParent(event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {
        if (childRefsserviceModeDurationPlannedData.current)
            childRefsserviceModeDurationPlannedData.current.updateDataFilterTypeFromParent(event);

        if (childRefCarbonDioxideConsumptionData.current)
            childRefCarbonDioxideConsumptionData.current.updateDataFilterTypeFromParent(event);

        if (childRefAreaSizeData.current)
            childRefAreaSizeData.current.updateDataFilterTypeFromParent(event);
    }




    const buildServicePlannedDurationChart = () => {
        return (
            <ServicesPlannedDurationChart
                durationChoiceType={durationChoiceType}
                childRefChart={childRefsserviceModeDurationPlannedDataChart}
                childRef={childRefsserviceModeDurationPlannedData}
                data={serviceModeDurationPlannedData}
                setChartData={setServiceModeDurationPlannedData}
                allData={serviceModeDurationPlannedDataAll}
                dataField={["realDuration", "plannedDuration", "savedDuration"]}
                loadingCompleted={loadingCompleted}
                title={`Servciedauer Analyse von ${currentGroups.length} ${t("homeDrawerFilter.units")}`} chartLabelType={getChartLabelType(durationChoiceType)} />
        )
    }

    const buildCarbonDioxideConsumptionChart = () => {
        return (
            <CarbonDioxideConsumptionChart
                childRefChart={childRefsetPointActualValueCarbonDioxideConsumptionChart}
                childRef={childRefCarbonDioxideConsumptionData}
                data={serviceModeCarbonDioxideConsumptionData}
                setChartData={setServiceCarbonDioxideConsumptionData}
                allData={serviceModeCarbonDioxideConsumptionDataAll}
                dataField={["actualCarbonDixoideConsumption", "setPointcarbonDixoideConsumption", "savedCarbonDixoideConsumption"]}
                loadingCompleted={loadingCompleted}
                title={`Co2 Ausstoß Analyse von ${currentGroups.length} ${t("homeDrawerFilter.units")}`} chartLabelType={ChartLabelType.Co2} />
        )
    }

    const buildAreaSizeChart = () => {
        return (
            <AreaSizeChart
                childRefChart={childRefAreaSizeChart}
                childRef={childRefAreaSizeData}
                data={serviceModeAreaSizeData}
                setChartData={setServiceAreaSizeData}
                allData={serviceModeAreaSizeDataAll}
                dataField={["actualAreaSize", "setPointAreaSize", "savedAreaSize"]}
                loadingCompleted={loadingCompleted}
                title={`Flächen Analyse von ${currentGroups.length} ${t("homeDrawerFilter.units")}`} chartLabelType={ChartLabelType.SquareMeter} />
        )
    }

    const buildServiceModesCountChart = () => {
        return (
            <ServiceModesCountChart
                childRefChart={childRefServiceModesCountChart}
                childRef={childRefServiceModesCountData}
                data={serviceModeSerivceModesCountData}
                setChartData={setServiceSerivceModesCountData}
                allData={serviceModeSerivceModesCountDataAll}
                dataField={["actualServiceModesCount", "setPointServiceModesCount", "savedServiceModesCount"]}
                loadingCompleted={loadingCompleted}
                title={`Service Analyse von ${currentGroups.length} ${t("homeDrawerFilter.units")}`} chartLabelType={ChartLabelType.Sum} />
        )
    }

    const updatePlannedDurationChart = (startDate: Date, endDate: Date, setPointAndActualValueModel: SetPointActualValueModel[], durationChoiceType: DurationChoiceType) => {


        let chartData = setPointAndActualValueModel.filter(x => isWithinInterval(x.date, { start: startDate, end: endDate })).map(setPointAndActualValueItem => {

            return { date: setPointAndActualValueItem.date, realDuration: setPointAndActualValueItem.actualServiceTime, plannedDuration: setPointAndActualValueItem.setPointServiceTime, }

        });


        let chartDataCarbonDioxide = setPointAndActualValueModel.filter(x => isWithinInterval(x.date, { start: startDate, end: endDate })).map(setPointAndActualValueItem => {

            return { date: setPointAndActualValueItem.date, actualCarbonDixoideConsumption: setPointAndActualValueItem.actualCarbonDixoideConsumption, setPointcarbonDixoideConsumption: setPointAndActualValueItem.setPointcarbonDixoideConsumption, }

        });


        let chartDataAreaSize = setPointAndActualValueModel.filter(x => isWithinInterval(x.date, { start: startDate, end: endDate })).map(setPointAndActualValueItem => {

            return { date: setPointAndActualValueItem.date, actualAreaSize: setPointAndActualValueItem.actualAreaSize, setPointAreaSize: setPointAndActualValueItem.setPointAreaSize, }

        });

        let chartDataServiceModesCount = setPointAndActualValueModel.filter(x => isWithinInterval(x.date, { start: startDate, end: endDate })).map(setPointAndActualValueItem => {

            return { date: setPointAndActualValueItem.date, actualServiceModesCount: setPointAndActualValueItem.actualServiceModesCount, setPointServiceModesCount: setPointAndActualValueItem.setPointServiceModesCount, }

        });

        setServiceModeSerivceModesCountDataAll(chartDataServiceModesCount);

        setServiceModeAreaSizeDataAll(chartDataAreaSize);

        setServiceModeCarbonDioxideConsumptionDataAll(chartDataCarbonDioxide);

        setServiceModeDurationPlannedDataAll(chartData);

    }



    const buildPrintContent = () => {
        return (
            <div className="page-size" ref={refPrint}>
                <Grid container spacing={2} >
                    <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[childRefTriggeredCleaningTaskChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Servicesauslösung Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }

    const buildGridContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-start"
                                md={6}
                            >
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        color="textSecondary"
                                    >
                                        Serviceauslösungen {false ? "(wird geladen)" : ""}
                                    </Typography >
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className="Paper-Result"
                                        component="p"
                                    >
                                        {cleaningTriggerTableData.length}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CleaningTriggerDataTable rows={cleaningTriggerTableData} groups={groupsLicenseTableEntries} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[childRefsetPointActualValueServiceDurationPlannedChart]} updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent} updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Typography style={{ fontWeight: 'bold', fontSize: 30 }}>Einheiten Gesamtfläche: <FormattedNumber value={areaSizeSum} /> m²</Typography>
                        <Typography style={{ fontWeight: 'bold', fontSize: 30 }}>Anzahl Systemmerkmale mit Nutzungsart: {systemProperties.length}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <PropertiesPerformanceTable rows={rowsPerformanceTable} properties={systemProperties} />
                </Grid>

                <Grid item xs={12}>
                    <Paper>
                        <Grid container direction={"row"} spacing={3}>
                            <Grid item xs={12}>
                                <Typography fontWeight={'bold'} style={{ fontSize: 40 }}>Systemmerkmal Analyse Services</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={currentSystemProperty}
                                    onChange={(_: any, newValue: UserProperty | null) => {
                                        if (newValue)
                                            setCurrentSystemProperty(newValue);
                                    }}
                                    id="controllable-states-demo"
                                    options={systemProperties}
                                    getOptionLabel={(x => x.name)}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Wähle das Systemmerkmal aus" variant="outlined" />}
                                />
                            </Grid>
                            {currentSystemProperty && currentUsageCategory &&
                                <Grid container direction={"row"} item spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 30 }}>Einheiten Gesamtfläche: <FormattedNumber value={currentArea} /> m²</Typography>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 30 }}>Einheiten Durchschnittsfläche: <FormattedNumber value={currentAvgAreaSize} /> m²</Typography>
                                            <Accordion TransitionProps={{ unmountOnExit: true }}>
                                                <AccordionSummary expandIcon={<ExpandMore />}>
                                                    <Grid
                                                        item
                                                        container
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        md={6}
                                                    >
                                                        <Grid item>
                                                            <Typography
                                                                variant="body1"
                                                                component="p"
                                                                color="textSecondary"
                                                            >
                                                                Einheiten des Merkmals
                                                            </Typography >
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                className="Paper-Result"
                                                                component="p"
                                                            >
                                                                {currentGroups.length}
                                                            </Typography >
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <GroupSelectionTable selectedServiceWorkerId={"test"} userServiceWorkers={userServiceWorkers} groups={currentGroups} selectedGroups={[]} onSelectionChange={undefined} />
                                                </AccordionDetails>
                                            </Accordion>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid item>
                                                <DatePicker
                                                    disableFuture
                                                    slotProps={{ textField: { variant: 'outlined' } }}
                                                    label={t("serviceDocumentationTable.startDate")}
                                                    value={startDateServiceModeDurationPlanned}
                                                    onChange={(newVal) => {
                                                        if (!newVal) return;
                                                        setStartDateServiceModeDurationPlanned(newVal);
                                                        updatePlannedDurationChart(newVal, endDateServiceModeDurationPlanned, rowsSetPointActualValueData, durationChoiceType);
                                                    }}
                                                    format={"dd.MM.yyyy"}
                                                    
                                                />
                                            </Grid>
                                            <Grid item>
                                                < DatePicker
                                                    disableFuture
                                                    slotProps={{ textField: { variant: 'outlined' } }}
                                                    label={t("serviceDocumentationTable.endDate")}
                                                    value={endDateServiceModeDurationPlanned}
                                                    onChange={(newVal) => {
                                                        if (!newVal) return;
                                                        setEndDateServiceModeDurationPlanned(newVal);
                                                        updatePlannedDurationChart(startDateServiceModeDurationPlanned, newVal, rowsSetPointActualValueData, durationChoiceType);
                                                    }}
                                                    format={"dd.MM.yyyy"}
                                                    
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion TransitionProps={{ unmountOnExit: true }}>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Grid
                                                    item
                                                    container
                                                    direction="column"
                                                    justifyContent="flex-start"
                                                    md={6}
                                                >
                                                    <Grid item>
                                                        <Typography
                                                            variant="body1"
                                                            component="p"
                                                            color="textSecondary"
                                                        >
                                                            Geplante Services {false ? "(wird geladen)" : ""}
                                                        </Typography >
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            className="Paper-Result"
                                                            component="p"
                                                        >
                                                            {currentRRuleDates.length}
                                                        </Typography >
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <DateDataTable rows={currentRRuleDates} />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    {groupsLicenseTableEntries.length > 0 && currentServicesOfUsageType.length > 0 &&
                                        <Grid item xs={12}>
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMore />}>
                                                    <Grid
                                                        item
                                                        container
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        md={6}
                                                    >
                                                        <Grid item>
                                                            <Typography
                                                                variant="body1"
                                                                component="p"
                                                                color="textSecondary"
                                                            >
                                                                Tatsächliche Services {false ? "(wird geladen)" : ""}
                                                            </Typography >
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                className="Paper-Result"
                                                                component="p"
                                                            >
                                                                {currentServicesOfUsageType.length}
                                                            </Typography >
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <ServiceModeTable materialNames={[]} groupsSettings={groupsLicenseTableEntries} groupServicesMode={currentServicesOfUsageType} userServiceWorkers={userServiceWorkers} selectedRows={[]} setSelectedRows={(rows: any) => { }} />
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    }
                                    {savedServiceDatesCount > 0 &&
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 40, color: green }}>Durch die Verwendung von Check-Me-Now haben Sie sich folgendes erspart <Savings style={{ fontSize: 45 }} /></Typography>
                                            <Typography style={{ color: green, fontSize: 32 }}><CleaningServices /> {savedServiceDatesCount} Services weniger benötigt.</Typography>
                                            <Typography style={{ color: green, fontSize: 32 }}><EnergySavingsLeaf style={{ color: green }} />Eingesparte CO2-Ausstoß {savedServiceDatesCount * (currentAvgAreaSize * (currentUsageCategory?.carbonDioxideConsumption / 100))} kg Co2</Typography>
                                            <Typography style={{ color: green, fontSize: 32 }}><MapsHomeWork />Eingesparte Fläche: {savedServiceDatesCount * currentAvgAreaSize} m²</Typography>
                                            <Typography style={{ color: green, fontSize: 32 }}><Timer />Eingesparte Zeit: {CurrencyHelper.toCurrencyCommaString((currentAvgAreaSize / currentSystemProperty.areaPerformancePerSquareMeter) * savedServiceDatesCount)} Stunden</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            }
                            {savedServiceDatesCount > 0 &&
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                justifyContent="flex-start"
                                                md={6}
                                            >
                                                <Grid item>
                                                    <Typography
                                                        variant="body1"
                                                        component="p"
                                                        color="textSecondary"
                                                    >
                                                        Eingesparte Services Analyse {false ? "(wird geladen)" : ""}
                                                    </Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        className="Paper-Result"
                                                        component="p"
                                                    >
                                                        {rowsSetPointActualValueData.length}
                                                    </Typography >
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {buildServiceModesCountChart()}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }

                            {savedServiceDatesCount > 0 &&
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                justifyContent="flex-start"
                                                md={6}
                                            >
                                                <Grid item>
                                                    <Typography
                                                        variant="body1"
                                                        component="p"
                                                        color="textSecondary"
                                                    >
                                                        Eingesparte Co2 Ausstoß Analyse {false ? "(wird geladen)" : ""}
                                                    </Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        className="Paper-Result"
                                                        component="p"
                                                    >
                                                        {rowsSetPointActualValueData.length}
                                                    </Typography >
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {buildCarbonDioxideConsumptionChart()}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }
                            {savedServiceDatesCount > 0 &&
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                justifyContent="flex-start"
                                                md={6}
                                            >
                                                <Grid item>
                                                    <Typography
                                                        variant="body1"
                                                        component="p"
                                                        color="textSecondary"
                                                    >
                                                        Eingesparte Flächen Analyse {false ? "(wird geladen)" : ""}
                                                    </Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        className="Paper-Result"
                                                        component="p"
                                                    >
                                                        {rowsSetPointActualValueData.length}
                                                    </Typography >
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {buildAreaSizeChart()}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }
                            {savedServiceDatesCount > 0 &&
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                justifyContent="flex-start"
                                                md={6}
                                            >
                                                <Grid item>
                                                    <Typography
                                                        variant="body1"
                                                        component="p"
                                                        color="textSecondary"
                                                    >
                                                        Eingesparte Zeit Analyse {false ? "(wird geladen)" : ""}
                                                    </Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        className="Paper-Result"
                                                        component="p"
                                                    >
                                                        {rowsSetPointActualValueData.length}
                                                    </Typography >
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item xs={12}>
                                                <DurationChoice value={durationChoiceType} setValue={setDurationChoiceType} />
                                            </Grid>
                                            {buildServicePlannedDurationChart()}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }
                        </Grid>
                    </Paper>
                </Grid>
                {groupDistanceFromServiceWorker.length > 0 &&
                    <Grid item xs={12}>
                        <Paper>
                            <Typography style={{ fontWeight: 'bold', fontSize: 30 }}>Co2 Verbrauch pro km: <FormattedNumber value={co2PerKm} /> kg</Typography>
                            <Typography style={{ fontWeight: 'bold', fontSize: 30 }}>Einheiten/Servicekräfte Gesamt km: <FormattedNumber value={currentDistanceSum} /> km</Typography>
                            <Typography style={{ fontWeight: 'bold', fontSize: 30 }}>Co2 Verbrauch alle Servicekräfte für 1 Fahrt Gesamt: <FormattedNumber value={(currentDistanceSum * co2PerKm) * 2} /> kg</Typography>
                            {savedServiceDatesCount > 0 && groupDistanceFromServiceWorker.length > 0 &&
                                <Paper>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 30, color: green }}>Eingesparte Co2 Fahrten: {savedServiceDatesCount}</Typography>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 30, color: green }}>Einheiten/Servicekräfte Gesamt km: <FormattedNumber value={currentDistanceSum * savedServiceDatesCount} /> km</Typography>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 30, color: green }}>Co2 Verbrauch alle Servicekräfte für 1 Fahrt Gesamt: <FormattedNumber value={(currentDistanceSum * co2PerKm) * 2 * savedServiceDatesCount} /> kg</Typography>
                                </Paper>
                            }
                            <Accordion TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        md={6}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                color="textSecondary"
                                            >
                                                Servicekräfte der Einheiten (km)
                                            </Typography >
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                className="Paper-Result"
                                                component="p"
                                            >
                                                {groupDistanceFromServiceWorker.length}
                                            </Typography >
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <UserServiceWorkerGroupDistanceTable savedServiceDatesCount={savedServiceDatesCount} rows={groupDistanceFromServiceWorker} />
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </Grid>
                }

            </Grid>
        );
    }

    return isPrintPage ? buildPrintContent() : buildGridContent();

}