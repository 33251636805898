import { Grid, Paper } from "@mui/material";
import React from "react";
import { Sensor } from "../../../../../../models/GroupSettings";
import { elevationPaper, paperStyle } from "../../../../../../styles/container";
import { chartHeightBig } from "../../../../../charts/Base";
import { LineChart } from "../../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../../charts/StackedChart";
import { ChartChoice } from "../../../../../core/ChartChoice";
import { ChartCalcType } from "../../../../../core/TimeChoice";
import { TimeSensorChoice } from "../../../../../core/TimeSensorChoice";
import { TimeUserChoice } from "../../../../../core/TimeUserChoice";

export enum SensorFilterType {
    "SensorId" = 0,
    "SensorRoomName" =  1
  }

export const SpecificSensorCharts = ({ sensors,chartCalcType = ChartCalcType.Sum,data, dataField, allData, setChartData, currentSensor, setCurrentSensor, childRef, loadingCompleted, childRefChart,sensorFilterType = SensorFilterType.SensorRoomName }
    : { sensors: Sensor[], chartCalcType?: ChartCalcType,data: any, dataField: any, allData: any, setChartData: any, currentSensor: Sensor | undefined, setCurrentSensor: any, childRef: any, loadingCompleted: boolean, childRefChart?: any, sensorFilterType : SensorFilterType }) => {

    const [chartChoice, setChartChoice] = React.useState('1');

    let textTitle = (() => {
        switch (chartCalcType) {
            case ChartCalcType.IncreaseSum:
                return `Besucher Verlauf im Raum: <b>${currentSensor?.sensorRoomName}</b>`
            case ChartCalcType.Sum:
                return `Besucher im Raum: <b>${currentSensor?.sensorRoomName}</b>`
        }
      })();

      textTitle += (() => {
        switch (sensorFilterType) {
            case SensorFilterType.SensorId:
                return `<br />Filter: Session (Letzter Sensor Setting Aufbau)`
            case SensorFilterType.SensorRoomName:
                return `<br />Filter: Sensor-Raum-Name`
        }
      })();

    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <LineChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={textTitle}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={textTitle}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={textTitle}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeSensorChoice
                            filterSensorType={sensorFilterType}
                            ref={childRef}
                            sensor={currentSensor}
                            setCurrentSensor={setCurrentSensor}
                            sensors={sensors}
                            fields={dataField}
                            data={allData}
                            setChartData={setChartData}
                            chartCalcType={chartCalcType} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}