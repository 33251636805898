import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Save } from '@mui/icons-material';
import { UserAccount } from '../../../models/UserAccount';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { StringHelper } from '../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { UserType } from '../../../models/UserType';
import { Autocomplete } from '@mui/material';
import { objectCustomerName } from '../portal/ManagerCustomers';
import { useTranslation } from 'react-i18next';

export const UserManagementPageEditingDialog = ({ visible, setVisible, user, onSave, users }
    : { visible: boolean, setVisible: any, user?: UserAccount, users: UserAccount[], onSave: any }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [userAccount, setUserAccount] = React.useState<UserAccount>(new UserAccount());

    const [name, setName] = React.useState('');
    const [type, setType] = React.useState<UserAccount>();
    const [description, setDescription] = React.useState('');
    const [unitPrice, setUnitPrice] = React.useState('');
    const [isVisibleInShop, setIsVisibleInShop] = React.useState(true);

    const [nameError, setNameError] = React.useState('');
    const [typeError, setTypeError] = React.useState('');
    const [unitPriceError, setUnitPriceError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    const onClose = () => {
        setUserAccount(new UserAccount());
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    const isValid = () => {
        let valid = true;

        if (StringHelper.IsNullOrWhiteSpace(userAccount.mail)) {
            setNameError("Email ist erforderlich");
            enqueueSnackbar("Email pflicht!", { variant: 'error' });
            valid = false;
        }

        if (userAccount.userType == null) {
            enqueueSnackbar("Typ pflicht!", { variant: 'error' });
            valid = false;
        }

        return valid;
    }

    const resetErrorText = () => {
        setNameError('');
        setTypeError('');
        setUnitPriceError('');
    }

    React.useEffect(() => {

        (async () => {
            if (user) {
                setUserAccount(user);
            } else {
                setUserAccount(new UserAccount());
            }

            resetErrorText();

        })();

    }, [visible, user])



    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="md">
            <DefaultDialogTitle
                title="User bearbeiten"
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">Details</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                id="filled-name"
                                InputLabelProps={{ shrink: true }}
                                label="Vorname"
                                variant="filled"
                                value={userAccount.firstname}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, firstname: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                label="Nachname"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={userAccount.lastname}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, lastname: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                label="Titel"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={userAccount.title}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, title: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                label="Firma"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={userAccount.company}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, company: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                label="UID"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={userAccount.uid}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, uid: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                label="FN"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={userAccount.atu}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, atu: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                label="E-Mail"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={userAccount.mail}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, mail: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth

                                label="Password"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={userAccount.password}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, password: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                label="Kundennummer"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={userAccount.customerNumber}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, customerNumber: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                label="Verhindern der Datenzusammenfassung in die Vergangenheit (in Monate)"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                type='number'
                                value={userAccount.monthsAgoProtecedData}
                                onChange={(event: any) => { setUserAccount({ ...userAccount, monthsAgoProtecedData: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                aria-label="gender"
                                name="contentCode"
                                value={userAccount.userType}
                                onChange={(event: any) => {
                                    if (event.target.value == UserType.Owner || event.target.value == UserType.Admin) {
                                        userAccount.invitedFromUserId = "";
                                    }
                                    setUserAccount({ ...userAccount, userType: parseInt(event.target.value) })
                                }}
                            >
                                <FormControlLabel
                                    value={UserType.Admin}
                                    control={<Radio />}
                                    label="Admin"
                                />
                                <FormControlLabel
                                    value={UserType.Owner}
                                    control={<Radio />}
                                    label="Besitzer"
                                />
                                <FormControlLabel
                                    value={UserType.Customer}
                                    control={<Radio />}
                                    label="Kunde"
                                />
                                <FormControlLabel
                                    value={UserType.Manager}
                                    control={<Radio />}
                                    label="Manager"
                                />
                                <FormControlLabel
                                    value={UserType.ObjectCustomer}
                                    control={<Radio />}
                                    label={objectCustomerName}
                                />
                            </RadioGroup>
                        </Grid>
                        {(userAccount.userType == UserType.Customer || userAccount.userType == UserType.Manager) &&
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ fontWeight: 'bold', marginBottom: 10 }}>Wähle den Besitzer des Users</Typography>
                                <Autocomplete
                                    value={users.find(el => el.id == userAccount.invitedFromUserId)?.mail || ""}
                                    onChange={(_: any, newValue: string | null) => {
                                        let user = users.find(el => el.mail == newValue);
                                        if (user) {
                                            setUserAccount({ ...userAccount, invitedFromUserId: user.id });
                                        }
                                    }}
                                    id="controllable-states-demo"
                                    options={users.map(el => el.mail)}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Mail des Besitzer" variant="outlined" />}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userAccount.activated}
                                        onChange={(event: any) => { setUserAccount({ ...userAccount, activated: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label="Account Aktiviert"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userAccount.mailConfirmed}
                                        onChange={(event: any) => { setUserAccount({ ...userAccount, mailConfirmed: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label="Email Bestätigt"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userAccount.isTermsOfUseAccepted}
                                        onChange={(event: any) => { setUserAccount({ ...userAccount, isTermsOfUseAccepted: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label="Nutzungsbedingungen akzeptiert"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        await onSave(userAccount);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
