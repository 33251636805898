import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { errorColor, white } from '../../../../styles/colors';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ObjectSelectionDataTable } from '../entireEval/ObjectSelectionDataTable';
import { ObjectSharedFolderTable } from '../../../../models/DataFileTree';
import { useTranslation } from 'react-i18next';

export const AssignObjectToSharedFolderDialog = ({
  open,
  setOpen,
  groups,
  objects,
  selectedObjectSharedFolders,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  groups: GroupLicenseTable[],
  objects: ObjectUnit[],
  selectedObjectSharedFolders: ObjectSharedFolderTable[],
  onRefresh:any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [token, setToken] = React.useState("");
  const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const {t} = useTranslation();
  
  const handleClose = () => {
    setSelectedObjects([]);
    onRefresh();
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

    })();

  }, [open]);

  const disabled = selectedObjects.length < 1 || loading;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Wähle Objekte, die den Geteilten Ordner <span className='header-dialog-default-title'>{selectedObjectSharedFolders.map(x => x.name).join(', ')}</span> zugewiesen werden sollen</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <ObjectSelectionDataTable rows={objects} groups={groups} currentGroup={null}
          selectedObjects={selectedObjects}
          defaultPageSize={0}
          onSelectionChange={(selection: string[]) => {
            setSelectedObjects(objects.filter(x => selection.includes(x.id)));
            setCount(selection.length);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={disabled ? {} : { backgroundColor: errorColor, color: white }}
          endIcon={<Delete />}
          disabled={disabled}
          onClick={async () => {
            setLoading(true);
            await Promise.all(selectedObjectSharedFolders.map(async (sharedFolder) => {
              setCount(selectedObjects.length);
              for (let object of selectedObjects) {
                await api.unassignObjectToSharedFolderAsync(object.id, selectedObjects.map(x => x.id), sharedFolder.id, token, enqueueSnackbar);
                setCount((prev)=>prev - 1);
              }
            }));
            setLoading(false);
            handleClose();
          }}
        >
          Entfernen
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Add />}
          startIcon={loading && <CircularProgress size={32}/>}
          disabled={disabled}
          onClick={async () => {
            setLoading(true);
            await Promise.all(selectedObjectSharedFolders.map(async (sharedFolder) => {
              setCount(selectedObjects.length);
              for (let object of selectedObjects) {
                await api.assignObjectToSharedFolderAsync(object.id, selectedObjects.map(x => x.id), sharedFolder.id, token, enqueueSnackbar);
                setCount((prev)=>prev - 1);
              }
            }));
            setLoading(false);
            handleClose();
          }}
        >
          Zuweisen {count} Objekte
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));