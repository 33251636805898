import { AssignmentTurnedIn, Backspace, NextPlan, Warning } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { chartHeight } from '../Base';



export const FullScreenChartDialog = ({ visible, title, onClose, buildChart, setChartHeight }
    : { visible: boolean; title: string; onClose: any; buildChart: any, setChartHeight: any }) => {

    const closeDialog = () => {
        if (setChartHeight) {
            setChartHeight(chartHeight);
        }
        onClose();
    }

    return (
        <Dialog style={{ maxWidth: '100%' }} fullScreen open={visible} onClose={closeDialog}>
            <DefaultDialogTitle
                title={`Chart Fullscreen`}
                handleClose={closeDialog}
            />
            <DialogContent style={{ overflowX: 'hidden' }}>
                {buildChart()}
            </DialogContent>


        </Dialog>
    );
};
