import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Grid, TextField, Typography, FormHelperText, ListItemIcon, ListItem, FormControl, FormLabel, Radio, RadioGroup, Divider, Alert, CircularProgress, Slider } from "@mui/material";
import { Add, Remove, ExpandMore, DeleteOutline, Sensors } from "@mui/icons-material";
import Card from '@mui/material/Card';
import { GroupSettings, GroupSettingsType, LoraSensor, Sensor, SensorControl, ToiletType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { ReactComponent as MotionSensorSvg } from '../../assets/svgs/motion-sensor.svg';
import { isConstructSignatureDeclaration } from "typescript";
import { useHistory } from "react-router-dom";
import { getApiToken } from "../../helpers/storage-management";
import { convertMinutesToTimespan, convertTimespanToMinutes, formatEuropeanDate, formatUnixSecTimestampEuropeanDateInMinutes, isOlderThanFifteenMinutes } from "../../helpers/date-management";
import { api } from "../../api";
import { LoadingIcon } from '../common/LoadingIcon';
import { GroupMotion } from "../../models/GroupMotion";
import { NavigatedFromType } from "../../models/NavigatedFrom";
import { Group, GroupType } from "../../models/Group";
import { MAX_SENSOR_COUNT } from "../../models/defaults";
import { AssignLicenseToRegisteredSensorChoosingTableDialog } from "../dialogs/AssignLicenseToRegisteredSensorChoosingTableDialog";
import { SensorLicense } from "../../models/GroupLicenseTable";
import { ReactComponent as LicenseSvg } from '../../assets/svgs/license_grey.svg'
import { GroupLicense } from "../../models/GroupLicense";
import { primaryColor } from "../../App";
import { white } from "../../styles/colors";
import { StringHelper } from "../../helpers/string-helper";
import { useTranslation } from "react-i18next";




export const GroupSettingFieldsLoraSensorControl = ({ row, onChange, group, freeSensorLicenses, loadFreeSensorLicenses, type }: { row: GroupSettings | UserGroupSettingsTemplate; onChange: any; group: Group | undefined; freeSensorLicenses: GroupLicense[], loadFreeSensorLicenses: any, type: GroupSettingsType }) => {
    const classes = GroupSettingFieldsUseStyles();
    let maxSensor = MAX_SENSOR_COUNT;
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [motions, setMotions] = React.useState<GroupMotion[]>([]);
    const [sensors, setSensors] = React.useState<LoraSensor[]>([]);

    const [assignToRegisteredSensorDialogVisible, setAssignToRegisteredSensorDialogVisible] = React.useState(false);
    const [currentSelectedSensor, setCurrentSelectedSensor] = React.useState<LoraSensor>();
    const [currentIndex, setCurrentIndex] = React.useState<number>();

    const { t } = useTranslation();


    React.useEffect(() => {
        (async () => {
            if (row.id) {
                if (row.loraSensorControl && row.loraSensorControl.sensors) {
                    setSensors(row.loraSensorControl.sensors);
                }

            }
        })();

    })
    const timer = React.useRef<NodeJS.Timeout>();
    const [loadingLastMotions, setLoadingLastMotions] = React.useState(false);
    const setLastMotions = () => {

        (async () => {
            if (row.id) {

                if (row.loraSensorControl && row.loraSensorControl.sensors) {
                    const token = getApiToken(history);
                    setLoadingLastMotions(true);
                    setMotions(await api.getLastMotionsByGroup(token));
                    setLoadingLastMotions(false);
                    timer.current = setTimeout(() => {
                        setLastMotions();
                    }, process.env.REACT_APP_STATUS_REFRESH_INTERVAL * 1000);


                }
            }
        })();
    }
    React.useEffect(() => {



        return () => {
            if (timer.current) clearTimeout(timer.current);
        }
    }, [row.id, history]);

    React.useEffect(() => {

        if (!row.sensorControl?.isSensorControlEnabled) {
            onChange({ target: { name: "sensorControlIsAmountTriggerEnabled", value: "false" } });
        }
    }, [row.sensorControl?.isSensorControlEnabled])

    const getSensorCreatedTimestamp = (sensorid: any) => {
        let timestamp = 0;
        if (row.loraSensorControl && row.loraSensorControl.sensors) {
            if (row.loraSensorControl.sensors.length > 0) {
                row.loraSensorControl?.sensors
                    .filter((x: LoraSensor) => x.id === sensorid)
                    .map((el: LoraSensor) => {
                        if (motions) {
                            let motion = motions.find((m: GroupMotion) => m.sensorId == el.id);
                            if (motion) {
                                //console.log(el.sensorRoomName + " " + sensorid + " " + motion.systemTimestamp + " " + isOlderThanFifteenMinutes(motion.createdTimestamp));
                                timestamp = motion.createdTimestamp;
                            }
                        }

                    });
            }
        }
        return formatUnixSecTimestampEuropeanDateInMinutes(timestamp);
    }

    return (
        <Accordion className={classes.hover} onChange={(event, expanded) => {
            if (!timer.current && expanded) {
                setLastMotions();
            }
            if (expanded && freeSensorLicenses.length < 1) {
                loadFreeSensorLicenses();
            }
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <Sensors style={{ fontSize: 40 }} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage.loraSensorControl")}</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    {type == GroupSettingsType.Default &&
                        <Grid item xs={3}>
                            <FormControlLabel
                                label={t("settingsPage.frequencyMessagesActivated")}
                                control={
                                    <Checkbox
                                        name="isMotionNotificationEnabled"
                                        color="primary"
                                        checked={row.notification?.isMotionNotificationEnabled}
                                        onChange={(event: any, checked: boolean) => {
                                            event.target.value = checked;
                                            onChange(event);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={row.loraSensorControl?.isSensorControlEnabled}
                                    name="loraSensorControlIsSensorEnabled"
                                    color="primary"
                                    onChange={(event: any, checked: boolean) => {
                                        event.target.value = checked;
                                        onChange(event);
                                    }}
                                />
                            }
                            label={`${t("settingsPage.loraSensorControl")} ${t("settingsPage.controlActivated")}`}
                        />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {type == GroupSettingsType.Default &&
                    <Grid container direction={"column"} spacing={3}>
                        {
                            row.loraSensorControl &&
                            <Grid item container justifyContent={"flex-start"} spacing={4}>
                                <Grid item xs={12} container direction="row" alignItems="center" spacing={3}>
                                    <Grid item xs={3}>
                                        {
                                            <Card variant="outlined">
                                                <Typography color="textSecondary" className={classes.cardContent}>
                                                    {t("myUnitsSettings.infoYouHaveTheOptionToAssignMultipleAdditionalSensorsToOneUnitYouCanBuyAdditionalLicensesInTheShop")}
                                                </Typography>
                                            </Card>
                                        }
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            size="small"
                                            startIcon={<Add />}
                                            variant={'contained'}
                                            color="primary"
                                            onClick={() => {
                                                const queryParams = { showall: "1", chosenUnit: row.id || "", chosenUnitName: row.locationInformation?.notificationName || "", navigatedFrom: NavigatedFromType.LoraSensor }
                                                const queryString = new URLSearchParams(queryParams).toString()
                                                history.push({
                                                    pathname: '/portal/shop',
                                                    search: queryString
                                                });
                                            }}
                                        >
                                            {t("myUnitsSettings.buy1LoraSensorLicense")}
                                        </Button>


                                        <br />
                                    </Grid>

                                </Grid>
                                <Grid item container direction="row" spacing={2} alignItems="center">
                                    <Grid item xs={6} lg={3}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            variant={"filled"}
                                            name="loraSensorControlDeviationTime"
                                            label={t("myUnitsSettings.responseTimeInMinutes")}
                                            value={row.loraSensorControl ? convertTimespanToMinutes(row.loraSensorControl.deviationTime) : 0}
                                            onChange={(event: any) => {
                                                let res = { target: { name: 'loraSensorControlDeviationTime', value: convertMinutesToTimespan(event.target.value) } };
                                                onChange(res);
                                            }} />
                                    </Grid>
                                    {freeSensorLicenses.length > 0 &&
                                        <Grid item container direction="row" lg={9} alignItems="center" spacing={2}>
                                            <Grid item lg={8}>
                                                <Alert severity="info">
                                                    {t("myUnitsSettings.youHaveFreeSensorLicenses", { title: freeSensorLicenses.length })}
                                                    <br />
                                                </Alert>

                                            </Grid>
                                            <Grid item lg={4}>


                                                <Button
                                                    size="large"
                                                    startIcon={<LicenseSvg fill={white} />}
                                                    variant={'contained'}
                                                    color="primary"
                                                    onClick={() => {
                                                        const queryParams = { chosenUnit: row.id || "" }
                                                        const queryString = new URLSearchParams(queryParams).toString()
                                                        history.push({
                                                            pathname: '/portal/licenses',
                                                            search: queryString
                                                        });
                                                    }}
                                                >
                                                    {t("myUnitsSettings.addAdditionalSensorLicenseToUnit")}
                                                </Button>


                                            </Grid>

                                        </Grid>
                                    }
                                </Grid>

                            </Grid>
                        }
                        <Grid item>
                            <Divider />
                        </Grid>
                        {
                            row.loraSensorControl?.sensors &&
                            row.loraSensorControl.sensors.filter(x => !StringHelper.IsNullOrWhiteSpace(x.id)).map((sensor: LoraSensor, index: number) => {
                                const currentSensorLicense = group?.loraSensorLicenses.find(x => x.id == sensor?.sensorLicense?.id);
                                return (
                                    <Grid item xs={12} container direction="row" alignItems="center" spacing={3} key={sensor.id}>
                                        <Grid item container direction="row" spacing={3} xs={12} alignItems="center">
                                            <Grid item>
                                                {!currentSensorLicense?.id ?
                                                    <Typography>{t("myUnitsSettings.NoLicenseAssignedYourSensorsWorkIf")}</Typography>
                                                    :
                                                    <Typography >{t("myUnitsSettings.licenseAssigned")}</Typography>

                                                }
                                            </Grid>
                                            <Grid item>
                                                {currentSensorLicense?.id ?
                                                    <Typography>{`Gültig bis: ${formatEuropeanDate(currentSensorLicense?.sensorLicenseValidUntilDate)}`}</Typography>
                                                    :
                                                    <Typography>Ungültig</Typography>
                                                }
                                            </Grid>
                                            <Grid item>
                                                {currentSensorLicense?.id ?
                                                    <Typography>{`${t("myUnitsSettings.expired")}: ${currentSensorLicense?.isSensorLicenseExpired ? `${t("myUnitsSettings.yes")}` : `${t("myUnitsSettings.no")}`}`}</Typography>
                                                    :
                                                    <Typography>{`${t("myUnitsSettings.expired")}: ${t("myUnitsSettings.yes")}`}</Typography>
                                                }
                                            </Grid>
                                            <Grid item>
                                                {currentSensorLicense?.id ?
                                                    <LicenseSvg fill={currentSensorLicense?.isSensorLicenseExpired ? "#eb4034" : "#2ee656"} />
                                                    :
                                                    <LicenseSvg fill={"#eb4034"} />
                                                }
                                            </Grid>
                                            <Grid item>

                                                <Button
                                                    className={classes.marginTop}
                                                    size="large"
                                                    startIcon={<Sensors />}
                                                    variant={'contained'}
                                                    color="primary"

                                                    onClick={() => {
                                                        setCurrentSelectedSensor(sensor);
                                                        setCurrentIndex(index);
                                                        setAssignToRegisteredSensorDialogVisible(true);
                                                    }}
                                                >
                                                    {t("myUnitsSettings.assignLoraSensorLicense")}
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                variant={"filled"}
                                                type="text"
                                                name="loraSensorItems"
                                                label={t("myUnitsSettings.roomDesignation")}
                                                value={sensor.sensorRoomName || ''}
                                                className={classes.label}
                                                onChange={(target: any) => {
                                                    target.target.value = JSON.stringify({ val: target.target.value, index: index, property: 'sensorRoomName' });
                                                    //handleChange(target, index);
                                                    onChange(target);
                                                }}


                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                variant={"filled"}
                                                type="text"
                                                name="id"
                                                label="ID"
                                                disabled
                                                value={sensor.id || ''}
                                                className={classes.label}
                                                onChange={(target: any) => {
                                                }}


                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                variant={"filled"}
                                                type="number"
                                                name="loraSensorItems"
                                                label={t("myUnitsSettings.usageTimeInMinutes")}
                                                value={sensor.amountTrigger || 0}
                                                className={classes.label}
                                                onChange={(event: any) => {
                                                    let triggercount = parseInt(event.target.value);
                                                    if (triggercount < 0) {
                                                        triggercount = 0;
                                                    }
                                                    if (triggercount > 999) {
                                                        triggercount = 999;
                                                    }
                                                    sensor.amountTrigger = triggercount;
                                                    onChange(event);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={sensor.isAmountTriggerEnabled || false}
                                                        name="loraSensorItems"
                                                        color="primary"
                                                        onChange={(event: any) => {
                                                            event.target['value'] = JSON.stringify({ val: event.target.checked, index: index, property: 'isAmountTriggerEnabled' });
                                                            onChange(event);
                                                            //handleChange(target);
                                                        }}
                                                    />
                                                }
                                                label={t("myUnitsSettings.serviceTriggerActivated")}
                                            />

                                        </Grid>

                                        {loadingLastMotions ? <Grid item xs={1}><CircularProgress size={32} /></Grid> :
                                            <Grid item xs={1}>
                                                <ListItemIcon>
                                                    <LoadingIcon
                                                        icon={<Sensors color="info" />}
                                                        loading={loading}
                                                    />
                                                </ListItemIcon>
                                                <Typography variant="body1">{getSensorCreatedTimestamp(sensor.id)}</Typography>
                                            </Grid>
                                        }
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{t("myUnitsSettings.roomType")}</FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="loraSensorItems"
                                                    value={sensor.toiletType || ToiletType.Neutral}
                                                    onChange={(event) => {
                                                        onChange({ target: { name: 'loraSensorItems', value: JSON.stringify({ val: JSON.parse(event.target.value), index: index, property: 'toiletType' }) } });
                                                    }}
                                                >
                                                    <FormControlLabel value={ToiletType.Neutral} control={<Radio />} label={t("myUnitsSettings.neutral")} />
                                                    <FormControlLabel value={ToiletType.Male} control={<Radio />} label={t("myUnitsSettings.male")} />
                                                    <FormControlLabel value={ToiletType.Female} control={<Radio />} label={t("myUnitsSettings.female")} />
                                                    <FormControlLabel value={ToiletType.Meeting} control={<Radio />} label={t("myUnitsSettings.meeting")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>


                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }

                    </Grid>

                }
            </AccordionDetails>
            <AssignLicenseToRegisteredSensorChoosingTableDialog
                visible={assignToRegisteredSensorDialogVisible}
                licenses={group?.loraSensorLicenses || []}
                sensor={currentSelectedSensor as Sensor}
                sensors={row.loraSensorControl?.sensors || []}
                onClose={() => {
                    setAssignToRegisteredSensorDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: SensorLicense) => {
                    if (currentSelectedSensor) {
                        onChange({ target: { name: 'loraSensorItems', value: JSON.stringify({ val: selectedLicense, index: currentIndex, property: 'sensorLicense' }) } })
                    }
                    setAssignToRegisteredSensorDialogVisible(false);
                }}
            />
        </Accordion>
    );
};
