import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { UserInvoice } from '../models/UserInvoice';

export const getUserInvoicesAsync = async (token: string): Promise<UserInvoice[]> => {

    const userId = getUserIdFromStorage();

    return await axios
        .get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/orders`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
        ).then(res => {
            return res.data;
        }).catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}

export const getAllInvoicesAsync = async (token: string, userId: string): Promise<UserInvoice[]> => {

    return await axios
        .get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/admin-orders`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
        ).then(res => {
            return res.data;
        }).catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}