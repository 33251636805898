export const deepCopy = (object: any) => {
    if (!object) return null;
    return JSON.parse(JSON.stringify(object));
}

export function deepCopyType<Type> (object: Type) : Type {
    return JSON.parse(JSON.stringify(object));
}

export const getQueryString = (res: any[]) => {
    const searchParams = new URLSearchParams();
    res.forEach(params => {
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));
    });
    return searchParams.toString();
}