import { Grid, Paper } from "@mui/material";
import React from "react";
import { GroupFeedback } from "../../../../../models/GroupFeedback";
import { FeedbackDisplayType } from "../../../../../models/GroupSettings";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { LineBarCombChart } from "../../../../charts/LineBarCombChart";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { TimeChoice } from "../../../../core/TimeChoice";

export const GetGroupFeedbacksMotionComb = ({ data, dataFieldBars, dataFieldLines, allData, setChartData, childRef, loadingCompleted, childRefChart, feedbackDisplayTypeChart }
    : { data: any[], dataFieldBars: any[], dataFieldLines: any[], allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any, feedbackDisplayTypeChart: any }) => {
    const [chartChoice, setChartChoice] = React.useState('0');

    const sumMotions = React.useMemo(() => data.reduce((prevVal, x: any) => {
        let sum = x.blockCount;
        if (!sum) sum = 0;
        return prevVal + sum
    }, 0), [data]);

    const sumFeedback = React.useMemo(() => data.reduce((prevVal, x: any) => {
        let sum = feedbackDisplayTypeChart ? x.negativeAmount + x.neutralAmount + x.positiveAmount + x.veryPositiveAmount + x.veryNegativeAmount : x.negativeAmount + x.neutralAmount + x.positiveAmount
        if (!sum) sum = 0;
        return prevVal + sum
    }, 0), [data]);

    const text = `Besucher & Feedback Typ ausgelöst in Prozent zueinander<br>Gesamtsumme: <b>${sumMotions} Besucher</b><br>Gesamtsumme: <b>${sumFeedback} Feedbacks</b>`;

    const newData = React.useMemo(() => data.map((x: any) => {
        const sum = feedbackDisplayTypeChart == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? x.negativeAmount + x.neutralAmount + x.positiveAmount + x.veryPositiveAmount + x.veryNegativeAmount : x.negativeAmount + x.neutralAmount + x.positiveAmount;
        return {
            dateDisplay: x.dateDisplay,
            date: x.date,
            blockCount: x.blockCount,
            veryPositiveAmount: Math.round((100 * x.veryPositiveAmount) / sum),
            veryNegativeAmount: Math.round((100 * x.veryNegativeAmount) / sum),

            negativeAmount: Math.round((100 * x.negativeAmount) / sum),
            neutralAmount: Math.round((100 * x.neutralAmount) / sum),
            positiveAmount: Math.round((100 * x.positiveAmount) / sum)
        }
    }), [data, feedbackDisplayTypeChart]);
    const colors = feedbackDisplayTypeChart == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? ["#a7f5fa", "#0EAD1C", "#05ff86", "#d4d008", '#ff9d14', "#FF0000"] : ["#a7f5fa", "#0EAD1C", "#d4d008", "#FF0000"];


    const buildGridItem = () => {

        switch (chartChoice) {
            case '0':
                return <LineBarCombChart
                    childRefChart={childRefChart}
                    colors={colors}
                    data={newData}
                    dataFieldBars={dataFieldBars}

                    text={text}
                    loadingCompleted={loadingCompleted} dataFieldLines={dataFieldLines} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={[]} data={allData} setChartData={setChartData} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}