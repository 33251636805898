import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { CheckCircle, Error } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import React, { useRef, useCallback } from 'react';
import { formatEuropeanDateTime } from '../../../helpers/date-management';
import { saveExcelFile, virtualTableHeight } from '../../../helpers/tables/common';
import { NumberInput } from '../../common/NumberInput';
import { MaterialObjectUnit } from '../../../models/ObjectUnit';
import { ExcelHelper } from '../../../helpers/excel-helper';
import { ImportanceLevel } from '../../../models/UserStock';
import { useTranslation } from 'react-i18next';


export const buildCellAssignedColumn = (assigned: boolean, props: any) => {
    const { column, row, value } = props;
    return (
        assigned ?
            <Table.Cell {...props} style={{ color: green }}>
                <div className="Flex-Row-Center">
                    <Typography className="Big-Cell-Font" style={{ marginRight: 10 }}>{value} </Typography>
                    <CheckCircle className="Big-Cell-Font" />
                </div>
            </Table.Cell > :
            <Table.Cell {...props} style={{ color: red }}>
                <div className="Flex-Row-Center">
                    <Typography className="Big-Cell-Font" style={{ marginRight: 10 }}>{value} </Typography>
                    <Error className="Big-Cell-Font" />
                </div>
            </Table.Cell >

    );
}


export const MaterialSelectionTable = ({ materials, setMaterials, selectedMaterials: selectedMaterials, onSelectionChange }
    : {
        materials: MaterialObjectUnit[], setMaterials: any, selectedMaterials: MaterialObjectUnit[], onSelectionChange: any
    }) => {

    const exporterRef: any = useRef(null);
    const [filters, setFilters] = React.useState([]);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: MaterialObjectUnit, value: any } = props;
        const [specficReason, setSpecificReason] = React.useState(row.specificReason);
        const [alarmEmail, setAlaramEmail] = React.useState(row.alarmEmail);
        const [diffAmount, setDiffAmount] = React.useState(row.diffAmount);
        const [minimumAmount, setMinimumAmount] = React.useState(row.minimumAmount);
        const [maximumAmount, setMaximumAmount] = React.useState(row.maximumAmount);

        if (column.name == MaterialObjectUnit.Columns.diffAmount) {
            const countDown = (val: number) => {
                let material = materials.find(x => x.materialId == row.materialId);
                if (material) {
                    material.newAmount = material.amount + val;
                    material.diffAmount = val;
                    setDiffAmount(val);
                }
            }
            return (
                <Table.Cell {...props} >
                    <NumberInput
                        width={80}
                        disabled={!selectedMaterials.some(x => x.materialId == row.materialId)}
                        value={diffAmount}
                        onBlur={(val: string) => {
                            setMaterials([...materials])
                        }}
                        onChangeText={(val: number) => {
                            countDown(val);
                        }}
                        onValueChanged={(val) => {
                            countDown(val);
                            setMaterials([...materials])
                        }}

                    />


                </Table.Cell>
            );
        }
        if (column.name == MaterialObjectUnit.Columns.minimumAmount) {
            const changeMinimum = (val: number) => {
                let material = materials.find(x => x.materialId == row.materialId);
                if (material) {
                    material.minimumAmount = val;
                    setMinimumAmount(val);
                }
            }
            return (
                <Table.Cell {...props} >
                    <NumberInput
                        width={80}
                        disabled={!selectedMaterials.some(x => x.materialId == row.materialId)}
                        value={minimumAmount}
                        onBlur={(val: string) => {
                            setMaterials([...materials])
                        }}
                        onChangeText={(val: number) => {
                            changeMinimum(val);
                        }}
                        onValueChanged={(val) => {
                            changeMinimum(val);
                            setMaterials([...materials])
                        }}

                    />


                </Table.Cell>
            );
        }
        if (column.name == MaterialObjectUnit.Columns.maximumAmount) {
            const changeMaximum = (val: number) => {
                let material = materials.find(x => x.materialId == row.materialId);
                if (material) {
                    material.maximumAmount = val;
                    setMaximumAmount(val);
                }
            }
            return (
                <Table.Cell {...props} >
                    <NumberInput
                        width={80}
                        disabled={!selectedMaterials.some(x => x.materialId == row.materialId)}
                        value={maximumAmount}
                        onBlur={(val: string) => {
                            setMaterials([...materials])
                        }}
                        onChangeText={(val: number) => {
                            changeMaximum(val);
                        }}
                        onValueChanged={(val) => {
                            changeMaximum(val);
                            setMaterials([...materials])
                        }}

                    />


                </Table.Cell>
            );
        }
        if (column.name == MaterialObjectUnit.Columns.amount) {
            return (
                <Table.Cell {...props} style={{ textAlign: 'center' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 22 }}>{value}</Typography>
                </Table.Cell>
            );
        }
        if (column.name == MaterialObjectUnit.Columns.newAmount) {
            return (
                <Table.Cell {...props} style={{ textAlign: 'center' }} >
                    <Typography style={{ fontWeight: 'bold', fontSize: 22, color: row.newAmount < row.amount ? red[900] : green[900] }}>{value}</Typography>
                </Table.Cell>
            );
        }
        if (column.name == MaterialObjectUnit.Columns.specificReason) {
            return (
                <Table.Cell {...props} >
                    <TextField
                        disabled={!selectedMaterials.some(x => x.materialId == row.materialId)}
                        label={t("myObjectSettings.specificReason")}
                        value={specficReason}
                        onBlur={(event) => {
                            let material = materials.find(x => x.materialId == row.materialId);
                            if (material) {
                                material.specificReason = event.target.value;
                                setMaterials([...materials])
                            }
                        }}
                        onChange={(event) => {
                            setSpecificReason(event.target.value);

                        }}

                    />


                </Table.Cell>
            );
        }
        if (column.name == MaterialObjectUnit.Columns.alarmEmail) {
            return (
                <Table.Cell {...props} >
                    <TextField
                        disabled={!selectedMaterials.some(x => x.materialId == row.materialId)}
                        label={"Alarmemail"}
                        value={alarmEmail}
                        onBlur={(event) => {
                            let material = materials.find(x => x.materialId == row.materialId);
                            if (material) {
                                material.alarmEmail = event.target.value;
                                setMaterials([...materials])
                            }
                        }}
                        onChange={(event) => {
                            setAlaramEmail(event.target.value);

                        }}

                    />


                </Table.Cell>
            );
        }
        if (column.name == MaterialObjectUnit.Columns.importanceLevelObject) {
            return (
                <Table.Cell {...props} >
                    <FormControl fullWidth variant="outlined">
                        <Select
                            labelId="template-management-page-upload-dialog-type-select"
                            value={row.importanceLevelObject}
                            onChange={(event: any) => {
                                row.importanceLevelObject = event.target.value;
                                setMaterials([...materials])
                            }}
                        >
                            <MenuItem value={ImportanceLevel.Default}>{"Standard"}</MenuItem>
                            <MenuItem value={ImportanceLevel.Important}>Gelb</MenuItem>
                            <MenuItem value={ImportanceLevel.VeryImportant}>Rot</MenuItem>
                        </Select>
                    </FormControl>


                </Table.Cell>
            );
        }
        return <Table.Cell {...props} />;
    };


    let columns = [
        {
            name: MaterialObjectUnit.Columns.text,
            title: t("myObjectSettings.designation"),
            getCellValue: (row: MaterialObjectUnit) => row.text,
        },
        {
            name: MaterialObjectUnit.Columns.entity,
            title: t("object-storage-single-eval.unit"),
            getCellValue: (row: MaterialObjectUnit) => row.entity,
        },
        {
            name: MaterialObjectUnit.Columns.article,
            title: t("myObjectSettings.article"),
            getCellValue: (row: MaterialObjectUnit) => row.article,
        },
        {
            name: MaterialObjectUnit.Columns.photoContent,
            title: t("myObjectSettings.numberOfPhotos"),
            getCellValue: (row: MaterialObjectUnit) => row?.photos?.length ?? "Keine Fotos"
        },
        {
            name: MaterialObjectUnit.Columns.amount,
            title: t("myObjectSettings.stockLevel"),
            getCellValue: (row: MaterialObjectUnit) => row.amount
        },
        {
            name: MaterialObjectUnit.Columns.specificReason,
            title: t("myUnitsSettings.inventoryMovements"),
            getCellValue: (row: MaterialObjectUnit) => row.specificReason,
        },
        {
            name: MaterialObjectUnit.Columns.diffAmount,
            title: t("myObjectSettings.quantity"),
            getCellValue: (row: MaterialObjectUnit) => row.diffAmount
        },
        {
            name: MaterialObjectUnit.Columns.newAmount,
            title: t("myUnitsSettings.newInventory"),
            getCellValue: (row: MaterialObjectUnit) => row.newAmount
        },
        {
            name: MaterialObjectUnit.Columns.minimumAmount,
            title: t("myUnitsSettings.minimumInventory"),
            getCellValue: (row: MaterialObjectUnit) => row.minimumAmount
        },
        {
            name: MaterialObjectUnit.Columns.maximumAmount,
            title: t("myUnitsSettings.maximumInventory"),
            getCellValue: (row: MaterialObjectUnit) => row.maximumAmount
        },
        {
            name: MaterialObjectUnit.Columns.alarmEmail,
            title: t("myObjectSettings.alarmEmail"),
            getCellValue: (row: MaterialObjectUnit) => row.alarmEmail
        },
        {
            name: MaterialObjectUnit.Columns.importanceLevelObject,
            title: t("myObjectSettings.highlight"),
            getCellValue: (row: MaterialObjectUnit) => row.importanceLevelObject
        },
    ];

    let columnWidth = Object.keys(MaterialObjectUnit.Columns).map(el => {
        return { columnName: el, width: 210 }
    });


    return (
        <>
            <Grid
                getRowId={(row) => row.materialId}
                rows={materials}
                columns={columns}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters as any}
                />
                <IntegratedFiltering columnExtensions={[
                ]} />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedMaterials.filter(x => !!x && x.materialId != undefined).map(x => x.materialId)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <VirtualTable
                    height={virtualTableHeight}
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={columnWidth} />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <PagingPanel
                    pageSizes={[0, 5, 10, 15]}
                />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={materials}
                columns={columns}
                onSave={(workbook) => ExcelHelper.onSave(workbook, `Lager`)}
                filters={filters}
            />
        </>
    );
};