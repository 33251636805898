import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from '../helpers/common';
import { VerifyTransactionModel } from '../models/VerifyTransactionModel';

export const VerifyTransactionAsync = async (model: VerifyTransactionModel, token: string, enqueueSnackbar: any): Promise<boolean> => {

    if (!model || !token || !enqueueSnackbar) return false;

    const userId = getUserIdFromStorage();

    return await axios
        .post(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/transactions/${model.id}`,
            model,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then((res) => {
            return true;
        })
        .catch(error => {
            return false;
        });
};
