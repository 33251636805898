import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Delete, Download, Mail, Save, UploadFile } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { GroupServiceModeIncidentPhoto } from '../../../models/GroupServiceMode';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { getApiToken } from '../../../helpers/storage-management';
import { FileBlobType } from '../../../models/FileBlobType';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { LocalMedia } from '../../images/NetworkMedia';
import { useTranslation } from 'react-i18next';

export const DownloadFileImageDialog = ({ visible, setVisible, id, fileBlobType, incidentPhotos, title, fotoSubDescription = "Vorfall" }
    : { visible: boolean, setVisible: any, id ?: string, incidentPhotos: GroupServiceModeIncidentPhoto[], fileBlobType: FileBlobType, title: string, fotoSubDescription? : string }) => {

    const classes = usePortalAccountStyles();

    const [images, setImages] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    const history = useHistory();


    const onClose = () => {
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

        if (visible){
            (async () => {

                const tokenTemp = getApiToken(history);
                setLoading(true);
                let photos = await Promise.all(incidentPhotos.map((async (x) => {
                    return { contentType: x.contentType, text: x.description, image: await api.DownloadUserFile(id || x.fileName, fileBlobType, tokenTemp, enqueueSnackbar, x.fileName, x.contentType, true) };
                })));
                setLoading(false);
                setImages(photos);
    
            })();
        }


    }, [visible])

    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="xl">
            <DefaultDialogTitle
                title={title}
                handleClose={onClose}
            />

            <DialogContent style={{ minHeight: 300 }}>
                {loading ?
                    <Grid style={{ width: '100%' }} container direction={"column"} justifyContent={"center"} justifyItems={"center"} alignItems={"center"} alignContent={"center"}>
                        <Grid item>
                            <CircularProgress style={{ padding: 20 }} size={200} />
                        </Grid>
                    </Grid> :
                    <Grid container direction="row" className={classes.container}>
                        <Grid item>
                            <ImageList cols={3}>
                                {images.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <LocalMedia src={item.image.url} contentType={item.contentType} />
                                        {item.contentType != "video/mp4" &&
                                            <ImageListItemBar
                                                title={`${fotoSubDescription} ${index + 1}`}
                                                subtitle={item.text}
                                                actionIcon={
                                                    <>
                                                        <Button
                                                            size="small"
                                                            startIcon={<Download />}
                                                            variant={'contained'}
                                                            onClick={() => {
                                                                var link = document.createElement("a"); // Or maybe get it from the current document
                                                                link.href = item.image;
                                                                link.download = `${title} ${index + 1}.jpeg`;
                                                                link.click();
                                                            }}
                                                            color="primary">
                                                            Download Bild
                                                        </Button>
                                                    </>
                                                }

                                            />
                                        }
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
