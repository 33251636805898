import React from 'react';
import TextField from '@mui/material/TextField';
import { getApiToken } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { UserAccount } from '../../../models/UserAccount';
import { Button, CircularProgress, Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Email, Save, VpnKey } from '@mui/icons-material';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { StringHelper } from '../../../helpers/string-helper';
import { getUserIdFromStorage } from '../../../helpers/common';
import { AzureStorageBrowserModel } from '../../../models/AzureStorageBrowserModel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { formatUnixSecTimestampEuropeanDateInMinutes } from '../../../helpers/date-management';
export const News = () => {

    const classes = usePortalAccountStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [newsList, setNewsList] = React.useState<AzureStorageBrowserModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            let newNews = await api.getNews();
            setLoading(false);
            setNewsList(newNews);
        })();
    }, []);

    return (
        <Paper className={classes.root}>
            <Typography component="h3" variant="h3" className={classes.title}>News</Typography>
            {loading &&
                <CircularProgress size={64}/>
            }
            <Grid container direction="row" className={classes.container}>
                <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                    <List component="nav" aria-label="main mailbox folders">
                        {newsList.map((el) => {
                            return (
                                <React.Fragment>
                                    <ListItem style={{height: 100}} button onClick={()=>{
                                        window.open(el.uri, '_blank');
                                    }}>
                                        <ListItemIcon color='primary'>
                                            <PictureAsPdfIcon />
                                        </ListItemIcon>
                                        <Typography color="primary" variant="h4">{el.fileName}</Typography>
                                        <Typography style={{marginLeft: 'auto'}} color="primary" variant="h6">Erstellt am: {formatUnixSecTimestampEuropeanDateInMinutes(el.unixTimestamp)}</Typography>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
        </Paper >
    );
}