import React from 'react';
import { Button, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Product } from '../../../models/Product';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../helpers/storage-management';
import { Add, Downloading, Mail } from '@mui/icons-material';
import { ProductManagementPageEditingDialog } from '../ProductManagementPageEditingDialog';
import { ConfirmationDialog } from '../../dialogs/ConfirmationDialog';
import { api } from '../../../api';
import { useSnackbar } from 'notistack';
import { ProductManagementPageDataTable } from '../ProductManagementPageDataTable';
import { UserManagementPageDataTable } from './UserManagementPageDataTable';
import { UserAccount } from '../../../models/UserAccount';
import { UserManagementPageEditingDialog } from './UserManagementPageEditingDialog';
import { GroupLicenseTable, UserManagementGroups } from '../../../models/GroupLicenseTable';
import { OnlineStatusAdminDialog } from './dialog/OnlineStatusAdminDialog';
import { GlobalTimeChoice } from '../../core/GlobalTimeChoice';

export const UserManagementPage = () => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [users, setUsers] = React.useState<UserAccount[]>([]);
    const [addSettingDialogVisible, setAddSettingDialogVisible] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<UserAccount>();
    const [editSettingDialogVisible, setEditSettingDialogVisible] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [groups, setGroups] = React.useState<UserManagementGroups[]>([]);
    const [selection, setSelection] = React.useState<(string | number)[]>([]);
    const [usersNews, setUsersNews] = React.useState<UserAccount[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState<UserAccount[]>([]);
    const [count, setCount] = React.useState(1);

    const [isOnlineStatusAdminDialogVisible, setIsOnlineStatusAdminDialogVisible] = React.useState(false);

    const loadGroups = async () => {
        setLoading(true);
        setCount(selectedUsers.length);
        let allGroupsTemp = await Promise.all(users.map(async (el) => {
            let groupLicenses = await api.GetAllGroupsByUserIdAsync(token, el.id);
            setCount(prev => prev - 1);
            return new UserManagementGroups(el.id, groupLicenses);
        }));
        setGroups(allGroupsTemp);
        setLoading(false);
    }

    const loadGroupsBySelectedUsers = async () => {
        setLoading(true);
        setCount(selectedUsers.length);
        let allGroupsTemp = await Promise.all(selectedUsers.map(async (el) => {
            let groupLicenses = await api.GetAllGroupsByUserIdAsync(token, el.id);
            setCount(prev => prev - 1);
            return new UserManagementGroups(el.id, groupLicenses);
        }));
        setGroups(allGroupsTemp);
        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {

            const tempToken = getApiToken(history);
            if (!tempToken) return;

            setToken(tempToken);
            setLoading(true);


            const tempUsers = await api.getAllUsersInclusiveNonActivatedByAdmin(tempToken);
            setUsers(tempUsers);

            setLoading(false);

        })();

    }, [history])

    React.useEffect(() => {
        let usersNews = users.filter(x => x.isNewsletterActive && selection.includes(x.id));
        setUsersNews(usersNews)

    }, [selection])

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <GlobalTimeChoice
                    isPrintPage={null}
                    setIsPrintPage={null}
                    pageRef={null}
                    chartRefs={[]}
                    updateChosenPastDaysFromParent={null} updateDataFilterTypeFromParent={null}
                    updateEndDateFromParent={null} updateStartDateFromParent={null} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">
                    User Verwaltung
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={3}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Mail />}
                        onClick={async () => {
                            let mail = "mailto:?bcc=";
                            for (let myUser of usersNews) {
                                if (myUser) {
                                    mail += myUser.mail + ';';
                                }
                            }
                            window.open(`${mail}`);
                        }}
                    >
                        Email an {usersNews.length} User senden
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={async () => {
                            setSelectedUser(new UserAccount());
                            setAddSettingDialogVisible(true);
                        }}
                    >
                        User hinzufügen
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        startIcon={<Downloading />}
                        onClick={async () => {
                            await loadGroupsBySelectedUsers();
                            setIsOnlineStatusAdminDialogVisible(true);
                        }}
                    >
                        Online Status Displays für {selection.length} User anzeigen
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Downloading />}
                        onClick={async () => {
                            loadGroups();
                        }}
                    >
                        Alle Einheiten Laden
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={usersNews.length != 1}
                        startIcon={<Mail />}
                        onClick={async () => {
                            setUsersNews([]);
                            await api.requestResetPassword(usersNews[0].mail, enqueueSnackbar);
                        }}
                    >
                        Passwort neusetzen Email an {usersNews[0]?.mail} senden
                    </Button>
                </Grid>

            </Grid>
            {loading &&
                <Grid item xs={12}>
                    <CircularProgress size={64} />
                    <Typography style={{ fontWeight: 'bold', fontSize: 40 }}>Noch {count} Dateneinheiten zum Laden</Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <Paper>
                    <UserManagementPageDataTable
                        handleSelection={(x: string[]) => {
                            setSelectedUsers(x.map(id => users.find(user => user.id == id) || new UserAccount()))
                            setSelection(x);
                        }}
                        groups={groups}
                        rows={users}
                        onEdit={async (row: UserAccount) => {
                            setSelectedUser(row);
                            setEditSettingDialogVisible(true);
                        }}
                        onDelete={async (row: UserAccount) => {
                            setSelectedUser(row);
                            setDeleteConfirmationDialogVisible(true);
                        }}
                    />
                </Paper>
            </Grid>

            <UserManagementPageEditingDialog
                users={users}
                visible={addSettingDialogVisible}
                setVisible={setAddSettingDialogVisible}
                user={selectedUser}
                onSave={async (user: UserAccount) => {


                    const added = await api.registerNewUserFromAdmin(user.mail, user.password, user.title, user.firstname, user.lastname, user.company, user.userType, user.invitedFromUserId, user.activated, token);

                    if (!added)
                        return;

                    let newUser = await api.getUser(token, added.userId);

                    if (!newUser)
                        return;

                    enqueueSnackbar("User hinzugefügt", { variant: 'success' });

                    setUsers([newUser, ...users]);
                }}
            />

            <UserManagementPageEditingDialog
                users={users}
                visible={editSettingDialogVisible}
                setVisible={setEditSettingDialogVisible}
                user={selectedUser}
                onSave={async (user: UserAccount) => {


                    const updated = await api.updateUserFromAdmin(user, token, enqueueSnackbar);

                    if (!updated) return;

                    setUsers(p => p.map((item: UserAccount) => item.id === updated.id ? updated : item));
                }}
            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title="Sind Sie sicher, diesen User zu löschen ?"
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {
                    if (!selectedUser) return;

                    setUsers(users.filter(x => x.id !== selectedUser.id));
                    setDeleteConfirmationDialogVisible(false);

                    await api.deleteUserAsync(selectedUser.id, token, enqueueSnackbar);
                }}
            />

            <OnlineStatusAdminDialog open={isOnlineStatusAdminDialogVisible} setOpen={setIsOnlineStatusAdminDialogVisible} groups={groups.map(x => x.groups).flatMap(x => x)} users={selectedUsers} />

        </Grid>
    );
};