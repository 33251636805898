import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { Product } from '../../../../../models/Product';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { GroupSettings } from '../../../../../models/GroupSettings';
import { formatEuropeanDateSec, formatEuropeanDateTime, formatUnixSecTimestampEuropeanDateInMinutes, formatUnixSecToDate, formatUnixSecToTime } from '../../../../../helpers/date-management';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { GroupMotion } from '../../../../../models/GroupMotion';
import { CheckboxCell, FilterCellCheck } from '../../../../tables/ServiceModeTable';
import { differenceInMinutes, fromUnixTime } from 'date-fns';
import { Button } from '@mui/material';
import { ForwardToInbox } from '@mui/icons-material';
import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { useTranslation } from 'react-i18next';

export const MotionDataTable = ({ rows, groups, selectedEntries, onSelectionChange, }
    : { rows: GroupMotion[], groups: GroupLicenseTable[], selectedEntries: GroupMotion[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);
    const {t} = useTranslation();


    const columns = [
        {
            name: GroupMotion.Columns.blockCount,
            title: "Besucher Anzahl",
            getCellValue: (row: GroupMotion) => row.blockCount,
        },

        {
            name: GroupMotion.Columns.sensorRoomName,
            title: "Raum Name",
            getCellValue: (row: GroupMotion) => row.sensorRoomName,
        },
        {
            name: GroupMotion.Columns.notificationName,
            title: t("serviceDocumentationTable.unitName"),
            getCellValue: (row: GroupMotion) => groups.find(x => x.id == row.groupId)?.notificationName || "Keine Einheit",
        },
        {
            name: GroupMotion.Columns.createdTimestamp,
            title: "Datum",
            getCellValue: (row: GroupMotion) => formatUnixSecToDate(row.createdTimestamp),
        },
        {
            name: GroupMotion.Columns.createdTimestampTime,
            title: "Zeitpunkt",
            getCellValue: (row: GroupMotion) => formatUnixSecToTime(row.createdTimestamp),
        },
        {
            name: GroupMotion.Columns.groupId,
            title: t("serviceDocumentationTable.unitName"),
            getCellValue: (row: GroupMotion) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: GroupMotion.Columns.batchCount,
            title: "Batch",
            getCellValue: (row: GroupMotion) => row.batchCount,
        },
        {
            name: GroupMotion.Columns.motionDeviceType,
            title: "Geräte Typ",
            getCellValue: (row: GroupMotion) => GroupMotion.MapLabelTypeMotionDeviceType(row.motionDeviceType),
        },
        {
            name: GroupMotion.Columns.isSummarizedMonth,
            title: "Zusammengefasst",
            getCellValue: (row: GroupMotion) => row.isSummarizedMonth,
        },
        {
            name: GroupMotion.Columns.summarizedFrom,
            title: "Start Zusammenfassung",
            getCellValue: (row: GroupMotion) => row.isSummarizedMonth ? formatEuropeanDateTime(row.summarizedFrom) : "nicht zusammengefasst",
        },
        {
            name: GroupMotion.Columns.summarizedUntil,
            title: "Ende Zusammenfassung",
            getCellValue: (row: GroupMotion) => row.isSummarizedMonth ? formatEuropeanDateTime(row.summarizedUntil) : "nicht zusammengefasst",
        },

        {
            name: GroupMotion.Columns.systemTimestamp,
            title: "Server Last Update",
            getCellValue: (row: GroupMotion) => formatEuropeanDateTime(row.systemTimestamp),
        },
        {
            name: GroupMotion.Columns.batteryLevel,
            title: "Batteriezustand",
            getCellValue: (row: GroupMotion) => row.batteryLevel,
        },
        {
            name: GroupMotion.Columns.diffInMinutes,
            title: "Server Date Differenz",
            getCellValue: (row: GroupMotion) => differenceInMinutes(new Date(row.systemTimestamp), fromUnixTime(row.createdTimestamp)),
        },
        {
            name: GroupMotion.Columns.deviceTimestamp,
            title: "Gerät gesendet am",
            getCellValue: (row: GroupMotion) => formatEuropeanDateTime(fromUnixTime(row.deviceTimestamp)),
        },
        {
            name: GroupMotion.Columns.serviceBusDeliveryCount,
            title: "ServiceBus Delivery Count",
            getCellValue: (row: GroupMotion) => row.serviceBusDeliveryCount,
        },
        {
            name: GroupMotion.Columns.serviceBusEnqueuedTime,
            title: "ServiceBus EnqueuedTime",
            getCellValue: (row: GroupMotion) => formatEuropeanDateTime(row.serviceBusEnqueuedTime),
        },
        {
            name: GroupMotion.Columns.messageId,
            title: "ServiceBus MessageId",
            getCellValue: (row: GroupMotion) => row.messageId,
        },
        {
            name: GroupMotion.Columns.serviceBusSequenceNumber,
            title: "ServiceBus SequenceNumber",
            getCellValue: (row: GroupMotion) => row.serviceBusSequenceNumber,
        },
        /*    {
                name: GroupMotion.Columns.serverTimestamp,
                title: "Server Date",
                getCellValue: (row: GroupMotion) => formatEuropeanDateTime(fromUnixTime(row.serverTimestamp)),
            },
    */
    ];

    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Frequenz');
        });
    };

    const Cell = (props: any) => {
        const { column, row } = props;
        if (column.name === GroupMotion.Columns.isSummarizedMonth) {
            return <CheckboxCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    const FilterCell = (props: any) => {
        const { column } = props;
        if (column.name === GroupMotion.Columns.isSummarizedMonth) {
            return <FilterCellCheck {...props} />;
        }
        return <TableFilterRow.Cell {...props} />;
    };

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 180 } }));
    const serviceBusColumns = React.useMemo(() => [GroupMotion.Columns.diffInMinutes, GroupMotion.Columns.deviceTimestamp, GroupMotion.Columns.serviceBusSequenceNumber, GroupMotion.Columns.messageId,
    GroupMotion.Columns.serviceBusEnqueuedTime, GroupMotion.Columns.serviceBusDeliveryCount], []);
    const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>([...serviceBusColumns]);

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //            sorting={[{ columnName: GroupMotion.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <TableFilterRow
                    cellComponent={FilterCell}
                />
                <TableColumnVisibility
                />
                <Toolbar />
                <Template
                    name="toolbarContent"
                >
                    <TemplatePlaceholder />
                    {!hiddenColumnNames.some(column => serviceBusColumns.includes(column)) ?
                        <Button color='error' onClick={() => {
                            setHiddenColumnNames([...hiddenColumnNames, ...serviceBusColumns]);
                        }} variant="contained" endIcon={<ForwardToInbox />}>
                            Hide ServiceBus Columns (Battery Display)
                        </Button>
                        :
                        <Button onClick={() => {
                            setHiddenColumnNames([...hiddenColumnNames.filter(column => !serviceBusColumns.includes(column))]);
                        }} variant="contained" endIcon={<ForwardToInbox />}>
                            Show ServiceBus Columns (Battery Display)
                        </Button>
                    }
                </Template>
                <ColumnChooser />
                <TableSelection showSelectAll selectByRowClick />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
                <ExportPanel startExport={startExport} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
