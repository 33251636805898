export class SettingsServiceTableEntry {
    public id!: string;
    public serviceEntry !: string;
    public countDone !: number;
    public standardItemId !: string;
    public isEnabled !: boolean;

    public constructor (serviceEntry: string, countDone: number, isEnabled: boolean,standardItemId: string){
        this.serviceEntry = serviceEntry;
        this.countDone = countDone;
        this.standardItemId = standardItemId;
        this.isEnabled = isEnabled;
    }

    static Columns = {
        id: 'id',
        serviceEntry: "serviceEntry",
        countDone: "countDone",
        standardItemId: "standardItemId",
        isEnabled: 'isEnabled'
    }
}