import { Grid, Paper } from "@mui/material";
import React from "react";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { TimeUserChoice } from "../../../../core/TimeUserChoice";
import { useTranslation } from "react-i18next";

export const DoneByUserMaterialConsumptionDashboard = ({ data, dataField, allData, setChartData, currentCleaner, setCurrentCleaner, childRef, loadingCompleted, childRefChart }
    : { data: any, dataField: any, allData: any, setChartData: any, currentCleaner: any, setCurrentCleaner: any, childRef: any, loadingCompleted: boolean, childRefChart?: any }) => {
    const [chartChoice, setChartChoice] = React.useState('2');
    const {t} = useTranslation();

    const textTitle = `${t("servicedocumentationServicestaff.materialUsageBy")} ${currentCleaner}`


    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <LineChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={textTitle}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={textTitle}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    childRefChart={childRefChart}
                    data={data}
                    dataField={dataField}
                    text={textTitle}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeUserChoice
                            ref={childRef}
                            currentCleaner={currentCleaner}
                            setCurrentCleaner={setCurrentCleaner}
                            fields={dataField}
                            data={allData}
                            setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}