import { UserType } from './UserType';

export enum PropertyType {
    Standard = 0,
    System = 1,
    Object = 2
}

export class UserProperty {

    public id: string = '';
    public systemETag: string = '';
    public systemTimestamp: string = '';
    public creationDate: string = '';
    public name: string = '';
    public userId!: string;

    public propertyType: PropertyType = PropertyType.Standard;
    public usageType: string = ""
    public areaPerformancePerSquareMeter: number = 0;
    public co2Value: number = 0;
    public usageCategoryId !: string;
    public rrule !: string;

    static Columns = {
        id: 'id',
        name: "name",
        creationDate: "creationDate",
        assign: "assign",
        deviceIds: "deviceIds",
        actions: "actions",
        propertyType: 'propertyType',
        usageType: 'usageType',
        areaPerformancePerSquareMeter: 'areaPerformancePerSquareMeter',
        co2Value: 'co2Value'

    }

    constructor() {
    }

    static CreateUserProperty = () => {
        const object = new UserProperty();

        return object;
    }

    static AvailableTypes = [

        { value: PropertyType.Standard, label: 'Standard' },
        { value: PropertyType.System, label: 'System' },
        { value: PropertyType.Object, label: 'Objekt' }
    ];

    static GetLabelByType = (type: PropertyType | undefined) => UserProperty.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';

}
