import { GroupServiceModeIncident } from "./GroupServiceMode";

export enum IncidentOriginType {
    Device = 0,
    Public = 1
}
export class GroupPayPerService {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public items!: GroupPayPerServiceItem [];
    public createdTimestamp!: number;
    public customTexts !: GroupPayPerServiceItem [];
    public messenger!: string;
    public incidentOriginType !: IncidentOriginType;
    public createdTimestampString !: string;

    public constructor (groupId: string, userId: string, items: GroupPayPerServiceItem[], createdTimestamp: number, customTexts: GroupPayPerServiceItem[], messenger: string) {
        this.systemETag = null;
        this.groupId = groupId;
        this.userId = userId;
        this.items = items;
        this.createdTimestamp = createdTimestamp;
        this.customTexts = customTexts;
        this.messenger = messenger;
    }

    static AvailableTypes = [
        
        { value: IncidentOriginType.Device, label: 'Display Gerät' },
        { value: IncidentOriginType.Public, label: 'Öffentlich' },


    ];

    static Columns = {
        id: 'id',
        groupId: "groupId",
        userId: "userId",
        items: "items",
        createdTimestamp: "createdTimestamp",
        createdTime: "createdTime",
        neutralAmount: "neutralAmount",
        customTexts: "customTexts",
        messenger: "messenger",
        incidentOriginType : "incidentOriginType"
    }

    static GetLabelByType = (type: IncidentOriginType) => GroupPayPerService.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
}

export class GroupPayPerServiceItem {
    public groupId : string = "";
    public reporterName : string = "";
    public roomText : string = "";
    public serviceNames !: GroupPayPerServiceName[];
    constructor (reporterName: string, roomText: string) {
        this.reporterName = reporterName;
        this.roomText = roomText;
    }
}

export class GroupPayPerServiceName {
    public text!: string;
    public price !: number;
    constructor(text: string, price : number) {
        this.text = text;
        this.price = price;
    }
    static CreateInstance = (text: string, price: number) => {
        let ret = new GroupPayPerServiceName(text, price);
        return ret;
    }
    static CreateInstanceFromSettings = (serviceName : GroupPayPerServiceName) => {
        let ret = new GroupPayPerServiceName(serviceName.text, serviceName.price);
        return ret;
    }
}