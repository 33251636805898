import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { blue } from '@mui/material/colors';
import { UserAddress } from '../../models/UserAddress';
import { AppBar, Button, Card, CardActions, CardContent, Dialog, DialogContent, DialogTitle, Grid, Theme, Toolbar, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog } from './ConfirmationDialog';
import { api } from '../../api';
import { getApiToken } from '../../helpers/storage-management';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

export const UserAdressesDialog = ({ setAddress, userAddresses, setUserAddresses, open, setOpen }
  : { setAddress: any, userAddresses: UserAddress[], setUserAddresses: any, open: boolean, setOpen: any }) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [confirmationDialogVisible, setConfirmationDialogVisible] = React.useState(false);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle className={classes.dialogTitle}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography className={classes.dialogTitleText}>Adresse</Typography>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid direction={"row"} container spacing={3}>
            {
              userAddresses &&
              userAddresses.map((userAddress: UserAddress, rowIndex: number) => {
                return (
                  <Grid item xs={4}>
                    <Card>
                      <CardContent style={{ height: 160 }}>
                        <Typography variant="h6" component="h2">
                          {userAddress.firstName} {userAddress.lastName}
                        </Typography>
                        <Typography variant="body2" component="h2">
                          {userAddress.company} {userAddress.uid} {userAddress.atu}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {userAddress.addressLine}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {userAddress.city} {userAddress.zipCode}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {userAddress.state}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {userAddress.country}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {userAddress.phoneNumber}
                        </Typography>
                      </CardContent>

                      <CardActions>
                        <Grid container direction="column" spacing={1}>
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              variant={"contained"}
                              color="primary"
                              size="small"
                              onClick={() => {
                                setAddress({ ...userAddress });
                                setOpen(false);
                              }}
                            >
                              Diese Adresse verwenden
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              variant={"contained"}
                              size="small"
                              onClick={() => setConfirmationDialogVisible(true)}
                            >
                              Löschen
                            </Button>
                            <ConfirmationDialog
                              title="Diese Adresse löschen ?"
                              visible={confirmationDialogVisible}
                              onClose={() => setConfirmationDialogVisible(false)}
                              onConfirmed={async () => {

                                setUserAddresses(userAddresses.filter(x => x.id !== userAddress.id));
                                setConfirmationDialogVisible(false);

                                const token = getApiToken(history);
                                await api.deleteUserAddress(userAddress, token, enqueueSnackbar);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialogTitle: {
    flexGrow: 1,
    padding: 0,
  },
  dialogTitleText: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: theme.spacing(2),
  }
}));
