import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Checkbox, CircularProgress, Fab, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, Sync, DeleteForever, Telegram, AddReaction, Edit, Sms, SwitchAccount, Swipe, EnergySavingsLeaf } from "@mui/icons-material";
import { errorColor, white } from "../../styles/colors";
import { ReactComponent as CleanerSvg } from '../../assets/icons/tasks/sm_icon-01.svg';
import { api } from "../../api";
import { GroupSettingFieldsUseStyles } from '../../styles/GroupSettingFieldsStyles';
import { useSnackbar } from 'notistack';
import { GroupSettings, GroupSettingsServiceWorkerActionDisplayType, GroupSettingsType, User } from '../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { deepCopy } from '../../helpers/general';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import PhoneInputTextField from "../common/PhoneInputTextField";
import { useHistory } from "react-router-dom";
import { GroupSelectionAssignMultupleGroupToServiceWorkerDialog } from "../authorized/extendedapp/entireEval/GroupSelectionAssignMultupleGroupToServiceWorkerDialog";
import { GroupLicenseTable } from "../../models/GroupLicenseTable";
import { ObjectUnit } from "../../models/ObjectUnit";
import { UserServiceWorker } from "../../models/UserServiceWorker";
import { getUserIdFromStorage } from "../../helpers/common";
import { GroupType } from "../../models/Group";
import { GroupSettingsMappingKeys } from "../../helpers/group-settings-management";
import { TextFieldGroupSettings } from "./components/groupSettings/TextFieldGroupSettings";
import { useTranslation } from "react-i18next";

export const GroupSettingFieldsCleaner = ({ row, onChange, type, token, isLoadingSendCode, setIsLoadingSendCode, originUsers, setOriginUsers, loadData, group }
    : { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, type: GroupSettingsType, token: string, isLoadingSendCode: boolean, setIsLoadingSendCode: any, originUsers: any, setOriginUsers: any, loadData: any, group: any }) => {

    const classes = GroupSettingFieldsUseStyles();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [groupAssignServiceWorkerMultipleDialogVisible, setGroupAssignServiceWorkerMultipleDialogVisible] = React.useState(false);
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [groupLicenseTable, setGroupLicenseTable] = React.useState<GroupLicenseTable>();
    const [groupLicenseTables, setGroupLicenseTables] = React.useState<GroupLicenseTable[]>();
    React.useEffect(() => {
        setOriginUsers(deepCopy(row.users?.users) || []);

    }, []);

    const {t} = useTranslation();


    return (
        <Accordion className={classes.hover}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid container direction="row" alignContent={"center"} alignItems={"center"} spacing={3}>
                    <Grid item>
                        <CleanerSvg width={60} height={60} fill={"#000000"} style={{ marginLeft: -4 }} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4" style={{ marginLeft: -16 }}>{t("settingsPage.localServiceworkers")}</Typography>
                    </Grid>

                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={3}>
                    <IsObjectSignInRfidComponent row={row} onChange={onChange} group={group} />
                    <Grid item>
                        <ServiceWorkerActionModeComponent row={row} onChange={onChange} />
                    </Grid>
                    <Grid container justifyContent={"flex-start"} item spacing={2}>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Add />}
                                variant={'contained'}
                                color="primary"
                                onClick={async () => onChange({ target: { name: 'usernamesAdd', value: "" } })}
                            >
                                {t("myUnitsSettings.addUser")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                disabled={isLoadingSendCode}
                                startIcon={<Sync />}
                                endIcon={<Telegram />}
                                variant={'contained'}
                                color="primary"
                                onClick={async () => {

                                    if (!row.notification?.notificationTelegramGroup.phoneNumber || !row.users) {
                                        enqueueSnackbar("Ungültige Telefonnummer. Bitte geben sie eine gültige authentifizierte Nummer ein!");
                                        return;
                                    }

                                    setIsLoadingSendCode(true);

                                    for (let user of row.users?.users)
                                        await api.telegramInviteUserToGroup(
                                            user.username,
                                            row.notification?.notificationTelegramGroup?.phoneNumber,
                                            user.whatsAppPhoneNumber,
                                            row.id,
                                            token,
                                            enqueueSnackbar);

                                    for (let originUser of originUsers) {
                                        if (!(row.users?.users.some((user: any) => originUser.whatsAppPhoneNumber === user.whatsAppPhoneNumber))) {
                                            await api.telegramDeleteUserFromGroup(
                                                row.notification.notificationTelegramGroup.phoneNumber,
                                                originUser.whatsAppPhoneNumber,
                                                row.id,
                                                token,
                                                enqueueSnackbar);
                                        }
                                    }

                                    setIsLoadingSendCode(false);
                                }}
                            >
                              {row.notification?.notificationTelegramGroup.title} {t("myUnitsSettings.syncTelegramGroup")} 
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={32} /> : <Swipe />}
                                endIcon={<SwitchAccount />}
                                variant={'contained'}
                                color="primary"
                                onClick={async () => {
                                    if (!row.id) return;
                                    setLoading(true);
                                    let res = await api.GetAllGroupsByUserIdAsync(token, getUserIdFromStorage() || "");
                                    setGroupLicenseTables(res);
                                    setGroupLicenseTable(res.find(x => x.id == row.id));
                                    setGroupAssignServiceWorkerMultipleDialogVisible(true);
                                    const tempServiceWorkers = await api.getServiceWorkers(token);
                                    setUserServiceWorkers(tempServiceWorkers);
                                    const tempObjects = await api.getUserObjectUnits(token);
                                    setObjects(tempObjects);
                                    setLoading(false);
                                }}
                            >
                                {t("myUnitsSettings.assignOrUnassignServicePersonnelToUnit")} 
                            </Button>
                        </Grid>
                        <Grid item >
                            <Alert severity="info">{t("myUnitsSettings.usersCanOnlyUseTheAppIfTheyAreDirectlyAddedInTheServicePersonnelManagement")}</Alert>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction={"row"} spacing={3}>
                        {
                            row.users?.users.map((el, index) => {
                                const isServiceWorkerFromCenter = el?.userServiceWorkerId?.length > 0;
                                return (
                                    <Grid key={index} item md={12} xl={12} container direction="row" alignItems="center" alignContent="center" spacing={2}>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                aria-label="add"
                                                onClick={() => onChange({ target: { name: 'usernamesRemove', value: JSON.stringify({ index: index }) } })}>
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        {isServiceWorkerFromCenter &&
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    color="primary"
                                                    aria-label="add"
                                                    onClick={() => {
                                                        const queryParams = { serviceWorkerId: el.userServiceWorkerId }
                                                        const queryString = new URLSearchParams(queryParams).toString();
                                                        const win = window.open("/portal/servicekräfte?" + queryString, "_blank");
                                                        if (win) win.focus();
                                                    }}>
                                                    <Edit />
                                                </Fab>
                                            </Grid>
                                        }
                                        <Grid item>
                                            <TextFieldGroupSettings
                                                id="filled-name"
                                                variant={"filled"}
                                                name="usernames"
                                                label="Username"
                                                value={el.username || ''}
                                                onChange={(event: any) => {
                                                    let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'username' }) } };
                                                    return onChange(res);
                                                } } />
                                        </Grid>

                                        <Grid item>
                                            <PhoneInput
                                                placeholder={t("myUnitsSettings.phoneNumber")}
                                                name="usernames"
                                                value={el.whatsAppPhoneNumber || ''}
                                                onChange={(event: any) => {
                                                    let res = { target: { name: "usernames", value: JSON.stringify({ val: event, index: index, property: 'whatsAppPhoneNumber' }) } };
                                                    onChange(res);
                                                }}
                                                inputComponent={PhoneInputTextField}

                                            />

                                        </Grid>
                                        <Grid item>
                                            <TextFieldGroupSettings
                                                inputProps={{ pattern: '[0-9]*' }}
                                                fullWidth={true}
                                                variant={"filled"}
                                                name="usernames"
                                                label={t("myUnitsSettings.nfcCode")}
                                                value={el.nfcCode || ''}
                                                onChange={(event: any) => {
                                                    const regex = /^[0-9\b]+$/;
                                                    if (event.target.value === "" || regex.test(event.target.value)) {
                                                        let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'nfcCode' }) } };
                                                        onChange(res);
                                                    }

                                                }} />
                                        </Grid>
                                        <Grid item>
                                            <TextFieldGroupSettings
                                                id="filled-name"
                                                variant={"filled"}
                                                name="row"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><EnergySavingsLeaf /></InputAdornment>,
                                                    endAdornment: <InputAdornment position="end">km</InputAdornment>
                                                }}
                                                label={t("myUnitsSettings.travelRouteToUnit")}
                                                value={el.distanceFromGroup || ''}
                                                onChange={(event: any) => {
                                                    el.distanceFromGroup = event.target.value;
                                                    return onChange(event);
                                                }} />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                size="large"
                                                endIcon={<Sms />}
                                                startIcon={<Add />}
                                                variant={'contained'}
                                                disabled={isLoadingSendCode}
                                                color="primary"
                                                onClick={async () => {
                                                    setIsLoadingSendCode(true);
                                                    await api.inviteUserServiceWorkerToTelegram(el.whatsAppPhoneNumber, token, enqueueSnackbar);
                                                    setIsLoadingSendCode(false);
                                                }}
                                            >
                                                {t("myUnitsSettings.inviteToTelegram")}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                size="large"
                                                disabled={isLoadingSendCode}
                                                endIcon={<Telegram />}
                                                startIcon={<Add />}
                                                variant={'contained'}
                                                color="primary"
                                                onClick={async () => {

                                                    if (!row.notification?.notificationTelegramGroup.phoneNumber || !row.users) {
                                                        enqueueSnackbar("Ungültige Telefonnummer. Bitte geben sie eine gültige authentifizierte Nummer ein!");
                                                        return;
                                                    }

                                                    setIsLoadingSendCode(true);

                                                    await api.telegramInviteUserToGroup(
                                                        el.username,
                                                        row.notification?.notificationTelegramGroup?.phoneNumber,
                                                        el.whatsAppPhoneNumber,
                                                        row.id,
                                                        token,
                                                        enqueueSnackbar);

                                                    setIsLoadingSendCode(false);
                                                }}
                                            >
                                                {t("myUnitsSettings.addToGroup")}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                size="large"
                                                disabled={isLoadingSendCode}
                                                endIcon={<Telegram />}
                                                startIcon={<DeleteForever />}
                                                variant={'contained'}
                                                color="primary"
                                                onClick={async () => {

                                                    if (!row.notification?.notificationTelegramGroup.phoneNumber || !row.users) {
                                                        enqueueSnackbar("Ungültige Telefonnummer. Bitte geben sie eine gültige authentifizierte Nummer ein!");
                                                        return;
                                                    }

                                                    setIsLoadingSendCode(true);

                                                    await api.telegramDeleteUserFromGroup(
                                                        row.notification.notificationTelegramGroup.phoneNumber,
                                                        el.whatsAppPhoneNumber,
                                                        row.id,
                                                        token,
                                                        enqueueSnackbar);

                                                    setIsLoadingSendCode(false);
                                                }}
                                            >
                                                {t("myUnitsSettings.remove")}
                                            </Button>
                                        </Grid>
                                        <Grid item xl={1}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={el.isEnabled}
                                                    onChange={(target: any) => {
                                                        target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                        onChange(target);
                                                    }}
                                                    name="usernames"
                                                    color="primary" />}
                                                label={t("myUnitsSettings.activated")} />
                                        </Grid>
                                        <Grid item xl={1}>
                                            {isServiceWorkerFromCenter ?
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={isServiceWorkerFromCenter}
                                                        name="usernames"
                                                        color="primary" />}
                                                    label={t("myUnitsSettings.managedInCentralUnitManagement")} /> :
                                                <Button color="primary" variant={'contained'} size="small" endIcon={<AddReaction />} onClick={() => {
                                                    if (!row.id) return;
                                                    let queryParams = {};
                                                    if (type == GroupSettingsType.Default) {
                                                        queryParams = { name: el.username, phoneNumber: el.whatsAppPhoneNumber, nfcCode: el.nfcCode, groupId: row.id }
                                                    } else {
                                                        queryParams = { name: el.username, phoneNumber: el.whatsAppPhoneNumber, nfcCode: el.nfcCode, templateId: row.id }
                                                    }
                                                    const queryString = new URLSearchParams(queryParams).toString()
                                                    const win = window.open("/portal/servicekräfte?" + queryString, "_blank");
                                                    if (win) win.focus();
                                                }}>
                                                    {t("myUnitsSettings.addToCentralUnitManagement")}
                                                </Button>
                                            }
                                        </Grid>

                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </AccordionDetails>

            <GroupSelectionAssignMultupleGroupToServiceWorkerDialog
                type={type}
                preSelected={row.users?.users.map(x => x.userServiceWorkerId)}
                open={groupAssignServiceWorkerMultipleDialogVisible} setOpen={setGroupAssignServiceWorkerMultipleDialogVisible} allGroups={[]}
                objects={objects} currentGroups={type == GroupSettingsType.Default ? groupLicenseTable ? [groupLicenseTable] : [] : []} userServiceWorkers={userServiceWorkers} onRefresh={() => { }}
                onDelete={async (selectedUserServiceWorkers: UserServiceWorker[], assignObjectOfGroup: boolean) => {
                    if (!row.users) return;

                    row.users.users = row.users?.users.filter(x => !selectedUserServiceWorkers.some(y => y.id == x.userServiceWorkerId));
                    if (row.locationInformation?.objectUnitId && assignObjectOfGroup) {
                        await api.unassignServiceWorkersFromObject(row.locationInformation?.objectUnitId, selectedUserServiceWorkers.map(x => x.id), token, enqueueSnackbar);
                    }
                    onChange({ target: { name: "row", value: "" } });
                }}
                onAdd={async (selectedUserServiceWorkers: UserServiceWorker[], assignObjectOfGroup: boolean) => {
                    if (!row.users) return;
                    row.users.users = row.users?.users.concat(selectedUserServiceWorkers.filter(x => !row.users?.users.some(y => y.userServiceWorkerId == x.id)).map(x => User.CreateInstance(x.name, x.nfcCode, x.phoneNumber, true, x.id)));
                    if (row.locationInformation?.objectUnitId && assignObjectOfGroup) {
                        await api.assignServiceWorkersToObject(row.locationInformation?.objectUnitId, selectedUserServiceWorkers.map(x => x.id), token, enqueueSnackbar);
                    }
                    onChange({ target: { name: "row", value: "" } });
                }} />

        </Accordion>
    );
};
export const ServiceWorkerActionModeComponent = ({ row, onChange }: { row: GroupSettings | UserGroupSettingsTemplate, onChange: any }) => {
    const {t} = useTranslation();
    if (row.users?.isObjectSignInRfidEnabled) {
        return (
            <FormControl component="fieldset">
                <FormLabel component="legend">{t("myUnitsSettings.serviceStaffWorkflowSelection")}</FormLabel>
                <RadioGroup
                    row
                    aria-label="gender"
                    value={row?.users?.serviceWorkerActionDisplayType}
                    onChange={(event: any) => {
                        if (row?.users?.serviceWorkerActionDisplayType == null) return;

                        row.users.serviceWorkerActionDisplayType = parseInt(event.target.value);

                        onChange({ target: { name: "row", value: "" } });
                    }}
                >
                    <FormControlLabel
                        value={GroupSettingsServiceWorkerActionDisplayType.ServiceAndTimeTracking}
                        control={<Radio />}
                        label={t("myUnitsSettings.serviceAndObject-Timereporting")}
                    />

                    <FormControlLabel
                        value={GroupSettingsServiceWorkerActionDisplayType.OnlyTimeTracking}
                        control={<Radio />}
                        label={t("myUnitsSettings.onlyObjectTimeReporting")}
                    />

                    <FormControlLabel
                        value={GroupSettingsServiceWorkerActionDisplayType.OnlyService}
                        control={<Radio />}
                        label={t("myUnitsSettings.onlyService")}
                    />


                </RadioGroup>

            </FormControl>
        )
    } else {
        return <></>
    }


}


export const IsObjectSignInRfidComponent = ({ row, onChange, group }: { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, group: any }) => {
    const {t} = useTranslation();
    return (
        group?.groupType == GroupType.DisplayWallApp ?
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox
                        checked={row.users?.isObjectSignInRfidEnabled}
                        onChange={(event: any) => {
                            event.target['value'] = event.target.checked;
                            onChange(event);
                        }}
                        name={GroupSettingsMappingKeys.isObjectSignInRfidEnabled}
                        color="primary" />}
                    label={t("myUnitsSettings.objectRegistrationWithRfidEnabled")} />
            </Grid> : <></>

    )

}