import axios from 'axios';
import { handleApiError, headers } from '../api';
import { USER_ID } from '../constants';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupCleaningTrigger } from '../models/GroupCleaningTrigger';
import { GroupFeedback } from '../models/GroupFeedback';
import { GroupFeedbackQuestion } from '../models/GroupFeedbackQuestion';
import { GroupIncidentVisitor } from '../models/GroupIncidentVisitor';
import { GroupServiceMode } from '../models/GroupServiceMode';


export const getIncidentVisitorByGroup = async (token: string, groupId: string): Promise<Array<GroupIncidentVisitor>> => {
    try {
        const userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/groups/${groupId}/users/${userId}/incident-visitor?${getQueryParamFromApiDataAmountType()}`, {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}