import axios from "axios"
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupSettingsType, NotificationTelegramGroup } from '../models/GroupSettings';

export const updateUserTelegramGroupsTitleAsync = async (rowId: string, groups: NotificationTelegramGroup[], type: GroupSettingsType, token: string, enqueueSnackbar: any) => {

    if (groups.length === 0) return;

    try {
        const userId = getUserIdFromStorage();

        let uri: string = '';
        switch (type) {
            case GroupSettingsType.Template:
                uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${rowId}/telegram/title`;
                break;
            default:
                uri = `${process.env.REACT_APP_BASIC_URI}/groups/${rowId}/users/${userId}/telegram/title`;
                break;
        }

        await axios.post(
            uri,
            { groups }
            , { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
        );
        enqueueSnackbar("Gruppen wurden erfolgreich gespeichert", { variant: 'success' });
    } catch {
        enqueueSnackbar("Es ist ein Fehler aufgetreten", { variant: 'error' });
    }
}