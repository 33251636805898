import React from "react";
import { NetworkImage } from "./NetworkImage";
import { NetworkVideo } from "./NetworkVideo";
import { CircleProgressOverlay } from "../overlays/CircleProgressOverlay";
import { ImageErrorOverlay } from "../overlays/ImageErrorOverlay";

export const NetworkMedia = ({ src, contentType, videoControlsVisible = false }
    : { src: string, contentType: string, videoControlsVisible?: boolean }) => {

    switch (contentType) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/gif':
            return <NetworkImage src={src} />;
        case 'video/mp4':
            return <NetworkVideo src={src} contentType={contentType} controls={videoControlsVisible} />
        default:
            return null;
    }
}

export const LocalMedia = ({ src, contentType, videoControlsVisible = true, height = 300 }
    : { src: string, contentType: string, videoControlsVisible?: boolean, height?:number }) => {

    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    const onLoad = () => {
        setLoading(false);
    }

    const onError = () => {
        setLoading(false);
        setHasError(true);
    }

    switch (contentType) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/gif':
            return (
                <img
                    src={src}
                    loading="lazy"
                    style={{ width: '100%', height: height, objectFit: 'contain' }}
                />
            )
        case 'video/mp4':
            return (
                <>
                    <CircleProgressOverlay hidden={!loading} />
                    <ImageErrorOverlay hidden={!hasError} text="Video wurde nicht gefunden" />
                    <video style={{ height: height, width: '100%' }}
                        muted
                        autoPlay={true}
                        controls={videoControlsVisible}
                        onError={onError}
                        onLoadedData={onLoad}>
                        <source src={src} type={contentType} />
                    </video>
                </>
            )
        default:
            return null;
    }
}