import { Button, Dialog, DialogActions, DialogContent, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Undo, ShoppingCart } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';

export const PaymentResultPageDialog = ({ valid }: { valid: boolean }) => {

    const classes = useStyles();
    const history = useHistory();

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={true}
            onClose={() => {

            }}>
            <DefaultDialogTitle
                title={valid ? "Danke für Ihre Bestellung" : "Es ist ein Fehler aufgetreten"}
            />

            <DialogContent className={classes.dialogContent}>
                <Typography>{valid ? "Ihre Bestellung wurde erfolgreich verifiziert" : "Zahlung kann nicht verifiziert werden"}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<Undo />}
                    onClick={() => {
                        history.push("/portal/licenses");
                    }}
                >
                    Zur Lizenzverwaltung
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ShoppingCart />}
                    onClick={() => {
                        history.push("/portal/shop");
                    }}
                >
                    Zurück zum Shop
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
    },
}));
