import axios from "axios"
import { headers } from "../api";
import { getUserIdFromStorage } from '../helpers/common';
import { GroupSettings, GroupSettingsType, NotificationTelegramGroup } from '../models/GroupSettings';

export const deleteUserTelegramGroupsAsync = async (rowId: string, groups: NotificationTelegramGroup[], type: GroupSettingsType, token: string, enqueueSnackbar: any): Promise<GroupSettings> => {

    const userId = getUserIdFromStorage();

    let uri: string = '';
    switch (type) {
        case GroupSettingsType.Template:
            uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${rowId}/telegram`;
            break;
        default:
            uri = `${process.env.REACT_APP_BASIC_URI}/groups/${rowId}/telegram`;
            break;
    }

    return axios.delete(
        uri,
        {
            headers: { ...headers, 'Authorization': `Bearer ${token}` },
            data: { groups }
        })
        .then((ret) => {
            enqueueSnackbar('Gruppen wurden erfolgreich entfernt', { variant: 'success' });
            return ret.data;
        })
        .catch((error) => {
            handleApiError(error, enqueueSnackbar);
            return null as any;
        });
}

const handleApiError = (error: any, enqueueSnackbar: any) => {

    if (error.response.status === 400 && error.response.data.message === 'CHAT_ADMIN_REQUIRED') {
        enqueueSnackbar('Diese Nummer hat nicht die Berechtigung, um diese Gruppe zu löschen', { variant: 'error' });
        return;
    }

    enqueueSnackbar("Es ist ein Fehler aufgetreten", { variant: 'error' });
}