import axios from 'axios';
import { handleApiError, handleApiSuccess, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const telegramDeleteUserFromGroup = async (adminPhoneNumber: string, newPhoneNumber:any, groupId: any, token: any, enqueueSnackbar: any) : Promise<any> => {
    try {
        const userId = getUserIdFromStorage();
        let ret = await axios.delete(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/telegram/${adminPhoneNumber}/remove-new-user/${newPhoneNumber}`,
        {headers: {...headers,'Authorization': 'Bearer '+token}})
        handleApiSuccess(ret.data, enqueueSnackbar, `User ${newPhoneNumber} wurde von der Gruppe entfernt`);
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar, `Löschen des User ${newPhoneNumber} fehlgeschlagen`);
    }
    return null as any;
}