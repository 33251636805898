import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Grid, TextField, Typography, FormHelperText, ListItemIcon, ListItem, FormControl, FormLabel, Radio, RadioGroup, Divider, Alert, CircularProgress, Slider } from "@mui/material";
import { Add, Remove, ExpandMore, DeleteOutline } from "@mui/icons-material";
import Card from '@mui/material/Card';
import { GroupSettings, Sensor, SensorControl, ToiletType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { ReactComponent as MotionSensorSvg } from '../../assets/svgs/motion-sensor.svg';
import { isConstructSignatureDeclaration } from "typescript";
import { useHistory } from "react-router-dom";
import { getApiToken } from "../../helpers/storage-management";
import { convertMinutesToTimespan, convertTimespanToMinutes, formatEuropeanDate, formatUnixSecTimestampEuropeanDateInMinutes, isOlderThanFifteenMinutes } from "../../helpers/date-management";
import { api } from "../../api";
import { LoadingIcon } from '../common/LoadingIcon';
import { GroupMotion } from "../../models/GroupMotion";
import { NavigatedFromType } from "../../models/NavigatedFrom";
import { Group, GroupType } from "../../models/Group";
import { MAX_SENSOR_COUNT } from "../../models/defaults";
import { AssignLicenseToRegisteredSensorChoosingTableDialog } from "../dialogs/AssignLicenseToRegisteredSensorChoosingTableDialog";
import { SensorLicense } from "../../models/GroupLicenseTable";
import { ReactComponent as LicenseSvg } from '../../assets/svgs/license_grey.svg'
import { GroupLicense } from "../../models/GroupLicense";
import { primaryColor } from "../../App";
import { white } from "../../styles/colors";
import { getBatteryState, getDataCompleteState } from "./GroupSettingFieldsBssd";
import { useTranslation } from "react-i18next";




export const GroupSettingFieldsSensorControl = ({ row, onChange, group, freeSensorLicenses, loadFreeSensorLicenses }: { row: GroupSettings | UserGroupSettingsTemplate; onChange: any; group: Group | undefined; freeSensorLicenses: GroupLicense[], loadFreeSensorLicenses: any }) => {
    const classes = GroupSettingFieldsUseStyles();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [motions, setMotions] = React.useState<GroupMotion[]>([]);
    const [isChecked, setIsChecked] = React.useState(false);
    const [sensors, setSensors] = React.useState<Sensor[]>([]);

    const [assignToRegisteredSensorDialogVisible, setAssignToRegisteredSensorDialogVisible] = React.useState(false);
    const [currentSelectedSensor, setCurrentSelectedSensor] = React.useState<Sensor>();
    const [currentIndex, setCurrentIndex] = React.useState<number>();

    const { t } = useTranslation();

    const checkIfAnySensorFound = (sensors: Sensor[]) => {
        if (group?.groupType == GroupType.DisplayWallApp) {

            return sensors.find((el: Sensor) => el.macAddress.length > 0);
        }
        return true;
    }

    React.useEffect(() => {
        (async () => {
            if (row.id) {
                if (row.sensorControl && row.sensorControl.sensors && checkIfAnySensorFound(row.sensorControl.sensors)) {
                    setSensors(row.sensorControl.sensors);
                }

            }
        })();

    })
    const timer = React.useRef<NodeJS.Timeout>();
    const [loadingLastMotions, setLoadingLastMotions] = React.useState(false);
    const setLastMotions = () => {

        (async () => {
            if (row.id) {

                if (row.sensorControl && row.sensorControl.sensors && checkIfAnySensorFound(row.sensorControl.sensors)) {
                    const token = getApiToken(history);
                    setLoadingLastMotions(true);
                    setMotions(await api.getLastMotionsByGroup(token));
                    setLoadingLastMotions(false);
                    timer.current = setTimeout(() => {
                        setLastMotions();
                    }, process.env.REACT_APP_STATUS_REFRESH_INTERVAL * 1000);


                }
            }
        })();
    }
    React.useEffect(() => {



        return () => {
            if (timer.current) clearTimeout(timer.current);
        }
    }, [row.id, history]);

    React.useEffect(() => {

        if (!row.sensorControl?.isSensorControlEnabled) {
            onChange({ target: { name: "sensorControlIsAmountTriggerEnabled", value: "false" } });
        }
    }, [row.sensorControl?.isSensorControlEnabled])

    const getSensorColor = (sensorid: any) => {
        let color = "";
        if (row.sensorControl && row.sensorControl?.sensors) {
            if (row.sensorControl.sensors.length > 0) {
                row.sensorControl?.sensors
                    .filter((x: Sensor) => x.id === sensorid)
                    .map((el: Sensor) => {
                        if (motions) {
                            let motion = motions.find((m: GroupMotion) => m.sensorId == el.id);
                            if (motion) {
                                //console.log(el.sensorRoomName + " " + sensorid + " " + motion.systemTimestamp + " " + isOlderThanFifteenMinutes(motion.createdTimestamp));
                                color = isOlderThanFifteenMinutes(motion.createdTimestamp) ? "#eb4034" : "#2ee656";
                            }
                        }

                    });
            }
        }
        return color;
    }
    const getSensorCreatedTimestamp = (sensorid: any) => {
        let timestamp = 0;
        if (row.sensorControl && row.sensorControl?.sensors) {
            if (row.sensorControl.sensors.length > 0) {
                row.sensorControl?.sensors
                    .filter((x: Sensor) => x.id === sensorid)
                    .map((el: Sensor) => {
                        if (motions) {
                            let motion = motions.find((m: GroupMotion) => m.sensorId == el.id);
                            if (motion) {
                                //console.log(el.sensorRoomName + " " + sensorid + " " + motion.systemTimestamp + " " + isOlderThanFifteenMinutes(motion.createdTimestamp));
                                timestamp = motion.createdTimestamp;
                            }
                        }

                    });
            }
        }
        return formatUnixSecTimestampEuropeanDateInMinutes(timestamp);
    }

    return (
        <Accordion className={classes.hover} onChange={(event, expanded) => {
            if (!timer.current && expanded) {
                setLastMotions();
            }
            if (expanded && freeSensorLicenses.length < 1) {
                loadFreeSensorLicenses();
            }
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <MotionSensorSvg width={40} height={40} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">{t("settingsPage.sensorControl")}</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item xs={3}>
                        <FormControlLabel
                            label={t("settingsPage.frequencyMessagesActivated")}
                            control={
                                <Checkbox
                                    name="isMotionNotificationEnabled"
                                    color="primary"
                                    checked={row.notification?.isMotionNotificationEnabled}
                                    onChange={(event: any, checked: boolean) => {
                                        event.target.value = checked;
                                        onChange(event);
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={row.sensorControl?.isSensorControlEnabled}
                                    name="sensorControlIsSensorEnabled"
                                    color="primary"
                                    onChange={(event: any, checked: boolean) => {
                                        event.target.value = checked;
                                        onChange(event);
                                    }}
                                />
                            }
                            label={`Sensor ${t("settingsPage.controlActivated")}`}
                        />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={3}>
                    {
                        row.sensorControl &&
                        <Grid item container justifyContent={"flex-start"} spacing={4}>
                            <Grid item xs={12} container direction="row" alignItems="center" spacing={3}>
                                {
                                    group && group.groupType == GroupType.DisplayBatteryWallApp &&
                                    <Grid item>
                                        <Button
                                            className={classes.marginTop}
                                            size="small"
                                            startIcon={<Add />}
                                            variant={'contained'}
                                            color="primary"

                                            onClick={() => {
                                                const queryParams = { showall: "1", chosenUnit: row.id || "", chosenUnitName: row.locationInformation?.notificationName || "", navigatedFrom: NavigatedFromType.BssdSensorExtensionTwo }
                                                const queryString = new URLSearchParams(queryParams).toString()
                                                history.push({
                                                    pathname: '/portal/shop',
                                                    search: queryString
                                                });
                                            }}
                                        >
                                            {t("myUnitsSettings.buy1BatterySensor")}
                                        </Button>
                                    </Grid>
                                }
                                <Grid item xs={3}>
                                    {
                                        row.sensorControl.sensors && group &&
                                        group.sensorLicenses.length > row.sensorControl.sensors.length &&
                                        <FormHelperText variant={'filled'}>
                                            <Typography color="textSecondary" className={classes.cardContent}>
                                                {t("myUnitsSettings.youCanInstallYourSensorOnTheCMNDevice")}
                                            </Typography>
                                        </FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    {
                                        group && group.groupType == GroupType.DisplayWallApp && group.sensorLicenses.length < MAX_SENSOR_COUNT &&
                                        <Card variant="outlined">
                                            <Typography color="textSecondary" className={classes.cardContent}>
                                                {t("myUnitsSettings.info1", {title : MAX_SENSOR_COUNT})}
                                            </Typography>
                                        </Card>
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    {

                                        group && group.groupType == GroupType.DisplayWallApp && group.sensorLicenses.length < MAX_SENSOR_COUNT &&
                                        <Button
                                            size="small"
                                            startIcon={<Add />}
                                            variant={'contained'}
                                            color="primary"
                                            onClick={() => {
                                                const queryParams = { showall: "1", chosenUnit: row.id || "", chosenUnitName: row.locationInformation?.notificationName || "", navigatedFrom: NavigatedFromType.SensorExtensionOne }
                                                const queryString = new URLSearchParams(queryParams).toString()
                                                history.push({
                                                    pathname: '/portal/shop',
                                                    search: queryString
                                                });
                                            }}
                                        >
                                            {"myUnitsSettings.buy1SensorLicense"}
                                        </Button>

                                    }
                                    <br />
                                </Grid>

                            </Grid>
                            <Grid item container direction="row" spacing={2} alignItems="center">
                                <Grid item xs={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        variant={"filled"}
                                        name="sensorControlDeviationTime"
                                        label={t("myUnitsSettings.responseTimeInMinutes")}
                                        value={row.sensorControl ? convertTimespanToMinutes(row.sensorControl.deviationTime) : 0}
                                        onChange={(event: any) => {
                                            let res = { target: { name: 'sensorControlDeviationTime', value: convertMinutesToTimespan(event.target.value) } };
                                            onChange(res);
                                        }} />
                                </Grid>
                                {freeSensorLicenses.length > 0 &&
                                    <Grid item container direction="row" lg={9} alignItems="center" spacing={2}>
                                        <Grid item lg={8}>
                                            <Alert severity="info">{t("myUnitsSettings.youCanAssignAvailableLicensesForTheUnitInTheLicenseManagement")} <br />
                                                {t("myUnitsSettings.youHaveFreeSensorLicenses",{title:freeSensorLicenses.length})} <br />
                                            </Alert>

                                        </Grid>
                                        {group && group.sensorLicenses.length < MAX_SENSOR_COUNT &&
                                            <Grid item lg={4}>


                                                <Button
                                                    size="large"
                                                    startIcon={<LicenseSvg fill={white} />}
                                                    variant={'contained'}
                                                    color="primary"
                                                    onClick={() => {
                                                        const queryParams = { chosenUnit: row.id || "" }
                                                        const queryString = new URLSearchParams(queryParams).toString()
                                                        history.push({
                                                            pathname: '/portal/licenses',
                                                            search: queryString
                                                        });
                                                    }}
                                                >
                                                    {t("myUnitsSettings.addAdditionalSensorLicenseToUnit")}
                                                </Button>


                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                    }
                    <Grid item>
                        <Divider />
                    </Grid>
                    {
                        row.sensorControl?.sensors &&
                        row.sensorControl.sensors.map((sensor: Sensor, index: number) => {
                            const currentSensorLicense = group?.sensorLicenses.find(x => x.id == sensor?.sensorLicense?.id);
                            const hours = (sensor.maxReportTimeSeconds || 3600) / 3600;
                            return (
                                <Grid item xs={12} container direction="row" alignItems="center" spacing={3} key={sensor.id}>
                                    <Grid item container direction="row" spacing={3} xs={12} alignItems="center">
                                        <Grid item>
                                            {!currentSensorLicense?.id ?
                                                <Typography>{t("myUnitsSettings.NoLicenseAssignedYourSensorsWorkIf")}:</Typography>
                                                :
                                                <Typography >{t("myUnitsSettings.licenseAssigned")}:</Typography>

                                            }
                                        </Grid>
                                        <Grid item>
                                            {currentSensorLicense?.id ?
                                                <Typography>{`${t("myUnitsSettings.validUntil")}: ${formatEuropeanDate(currentSensorLicense?.sensorLicenseValidUntilDate)}`}</Typography>
                                                :
                                                <Typography>{t("myUnitsSettings.invalid")}</Typography>
                                            }
                                        </Grid>
                                        <Grid item>
                                            {currentSensorLicense?.id ?
                                                <Typography>{`${t("myUnitsSettings.expired")}: ${currentSensorLicense?.isSensorLicenseExpired ? t("myUnitsSettings.yes") : t("myUnitsSettings.no")}`}</Typography>
                                                :
                                                <Typography>{`${t("myUnitsSettings.expired")}: ${t("myUnitsSettings.yes")}`}</Typography>
                                            }
                                        </Grid>
                                        <Grid item>
                                            {currentSensorLicense?.id ?
                                                <LicenseSvg fill={currentSensorLicense?.isSensorLicenseExpired ? "#eb4034" : "#2ee656"} />
                                                :
                                                <LicenseSvg fill={"#eb4034"} />
                                            }
                                        </Grid>
                                        <Grid item>

                                            <Button
                                                className={classes.marginTop}
                                                size="large"
                                                startIcon={<Add />}
                                                variant={'contained'}
                                                color="primary"

                                                onClick={() => {
                                                    setCurrentSelectedSensor(sensor);
                                                    setCurrentIndex(index);
                                                    setAssignToRegisteredSensorDialogVisible(true);
                                                }}
                                            >
                                                {t("myUnitsSettings.assignLicense")}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            variant={"filled"}
                                            type="text"
                                            name="sensorItems"
                                            label={t("myUnitsSettings.roomDesignation")}
                                            value={sensor.sensorRoomName || ''}
                                            className={classes.label}
                                            onChange={(target: any) => {
                                                target.target.value = JSON.stringify({ val: target.target.value, index: index, property: 'sensorRoomName' });
                                                onChange(target);
                                            }}


                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            variant={"filled"}
                                            type="number"
                                            name="sensorItems"
                                            label={t("myUnitsSettings.sensorNumberContinuousTriggers")}
                                            value={sensor.amountTrigger || 0}
                                            className={classes.label}
                                            onChange={(event: any) => {
                                                let triggercount = parseInt(event.target.value);
                                                if (triggercount < 0) {
                                                    triggercount = 0;
                                                }
                                                if (triggercount > 999) {
                                                    triggercount = 999;
                                                }
                                                sensor.amountTrigger = triggercount;
                                                onChange(event);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={sensor.isAmountTriggerEnabled || false}
                                                    name="sensorItems"
                                                    color="primary"
                                                    onChange={(event: any) => {
                                                        event.target['value'] = JSON.stringify({ val: event.target.checked, index: index, property: 'isAmountTriggerEnabled' });
                                                        onChange(event);
                                                        //handleChange(target);
                                                    }}
                                                />
                                            }
                                            label={t("myUnitsSettings.serviceTriggerEnabled")}
                                        />

                                    </Grid>
                                    {group?.groupType == GroupType.DisplayWallApp &&
                                        <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                variant={"filled"}
                                                margin="normal"
                                                name="sensorItems"
                                                label="Name"
                                                value={sensor.sensorName}
                                            />
                                        </Grid>
                                    }
                                    {group?.groupType == GroupType.DisplayBatteryWallApp &&
                                        <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                variant={"filled"}
                                                margin="normal"
                                                name="sensorItems"
                                                label="ID"
                                                value={sensor.id}
                                            />
                                        </Grid>
                                    }
                                    {loadingLastMotions ? <Grid item xs={1}><CircularProgress size={32} /></Grid> :
                                        <Grid item xs={1}>
                                            <ListItemIcon>
                                                <LoadingIcon
                                                    icon={<MotionSensorSvg fill={getSensorColor(sensor.id)} />}
                                                    loading={loading}
                                                />
                                            </ListItemIcon>
                                            <Typography variant="body1">{getSensorCreatedTimestamp(sensor.id)}</Typography>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{t("myUnitsSettings.roomType")}</FormLabel>
                                            <RadioGroup
                                                row
                                                name="sensorItems"
                                                value={sensor.toiletType || ToiletType.Neutral}
                                                onChange={(event) => {
                                                    onChange({ target: { name: 'sensorItems', value: JSON.stringify({ val: JSON.parse(event.target.value), index: index, property: 'toiletType' }) } });
                                                }}
                                            >
                                                <FormControlLabel value={ToiletType.Neutral} control={<Radio />} label={t("myUnitsSettings.neutral")} />
                                                <FormControlLabel value={ToiletType.Male} control={<Radio />} label={t("myUnitsSettings.male")} />
                                                <FormControlLabel value={ToiletType.Female} control={<Radio />} label={t("myUnitsSettings.female")} />
                                                <FormControlLabel value={ToiletType.Meeting} control={<Radio />} label={t("myUnitsSettings.meeting")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {group?.groupType == GroupType.DisplayWallApp &&
                                        <Grid item container direction="column" alignItems="center" xs={4} spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">{t("myUnitsSettings.numberOfMaxDfuAttempts")}{sensor.maxDfuTries}</Typography>
                                            </Grid>
                                            <Grid item container direction="row" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Typography variant="body1">{t("myUnitsSettings.numberOfDfuAttempts")}{sensor.currentDfuTries}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        size="small"
                                                        variant={'contained'}
                                                        onClick={async () => {
                                                            onChange({ target: { name: 'sensorItems', value: JSON.stringify({ val: 0, index: index, property: 'currentDfuTries' }) } });
                                                        }}
                                                        color="primary"
                                                    >
                                                        Reset
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">{sensor.isBonded ? t("myUnitsSettings.paired") : t("myUnitsSettings.notPaired")}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {group?.groupType == GroupType.DisplayWallApp &&
                                        sensor.lastSuccessDfuTimestamp != 0 &&
                                        <Grid item xs={4}>
                                            <Typography variant="body1">{t("myUnitsSettings.lastDFUSuccessWasOn")}:{formatUnixSecTimestampEuropeanDateInMinutes(sensor.lastSuccessDfuTimestamp)}</Typography>
                                        </Grid>

                                    }
                                    {group?.groupType == GroupType.DisplayBatteryWallApp &&
                                        <Grid item container direction="row" spacing={3}>

                                            <Grid item xs={12} container spacing={3} alignItems="center" justifyContent={"flex-start"}>
                                                <Grid item>
                                                    <Typography fontWeight={'bold'} color="primary" fontSize={30}>{t("myUnitsSettings.hours")}: {hours}</Typography>
                                                </Grid>
                                                <Grid item lg={2}>
                                                    <TextField
                                                        label={t("myUnitsSettings.activityReportingIntervalInSeconds")}
                                                        value={sensor.maxReportTimeSeconds}
                                                        fullWidth
                                                        inputProps={{ min: 3600, max: 86400 }}
                                                        type="number"
                                                        onChange={(event) => {
                                                            if (!row.locationInformation?.bssdInformation) return;

                                                            sensor.maxReportTimeSeconds = parseInt(event.target.value);
                                                            onChange({ target: { name: "row", value: "" } });
                                                        }}
                                                        variant="filled"
                                                    />
                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Alert severity="info">{t("myUnitsSettings.batteryDisplayVisitorActivityReportingFrequencyWarning")}</Alert>
                                                </Grid>
                                                <Grid item xs={12}></Grid>
                                                <Grid item lg={3}>
                                                    {getDataCompleteState(hours)}
                                                </Grid>
                                                <Grid item lg={4}>
                                                    <Slider
                                                        value={sensor.maxReportTimeSeconds}
                                                        onChange={(event, value: number | number[]) => {
                                                            if (!row.locationInformation?.bssdInformation) return;

                                                            sensor.maxReportTimeSeconds = value as number;
                                                            onChange({ target: { name: "row", value: "" } });
                                                        }}
                                                        min={3600}
                                                        max={86400}
                                                        step={3600} />
                                                </Grid>
                                                <Grid item lg={3}>
                                                    {getBatteryState(hours)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            );
                        })
                    }

                </Grid>


            </AccordionDetails>
            <AssignLicenseToRegisteredSensorChoosingTableDialog
                visible={assignToRegisteredSensorDialogVisible}
                licenses={group?.sensorLicenses || []}
                sensor={currentSelectedSensor}
                sensors={row.sensorControl?.sensors || []}
                onClose={() => {
                    setAssignToRegisteredSensorDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: SensorLicense) => {
                    if (currentSelectedSensor) {
                        onChange({ target: { name: 'sensorItemsObject', value: JSON.stringify({ val: JSON.stringify(selectedLicense), index: currentIndex, property: 'sensorLicense' }) } })
                    }
                    setAssignToRegisteredSensorDialogVisible(false);
                }}
            />
        </Accordion>
    );
};
