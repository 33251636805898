import axios from 'axios';
import { headers } from '../api';
import { GroupLicenseTable } from '../models/GroupLicenseTable';
import { GroupSettings } from '../models/GroupSettings';

export const updateGroupSettingsByGroupLicenseTable = async (currentGroup: GroupLicenseTable, token: string, enqueueSnackbar: any): Promise<GroupSettings> => {
    return await axios
        .patch(`${process.env.REACT_APP_BASIC_URI}/groups/${currentGroup.id}/settings-by-group-license-table`,
            { ...currentGroup, systemETag: null },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Speichern von Einstellungen erfolgreich", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Beim Speichern von Einstellungen ist ein Fehler aufgetreten", { variant: 'error' });
        });
}