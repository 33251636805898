import Dialog from '@mui/material/Dialog';
import React from 'react';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, CircularProgress, DialogActions } from '@mui/material';
import { GroupCombinationSelection } from '../tables/GroupCombinationSelection';
import { MergeType, RemoveCircle } from '@mui/icons-material';
import { UserAccount } from '../../models/UserAccount';
import { UserAssignedGroup } from '../../models/UserAssignedGroup';
import { useSnackbar } from 'notistack';
import { getUserIdFromStorage } from '../../helpers/common';
import { DefaultDialogTitle } from './DefaultDialogTitle';
import { useTranslation } from 'react-i18next';

export const AddGroupsToUserDialog = ({ user, open, setOpen, userAssignedGroups, setUserAssignedGroups }
  : { user: UserAccount | undefined, open: boolean, setOpen: any, userAssignedGroups: UserAssignedGroup, setUserAssignedGroups: any }) => {

  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
  const [selection, setSelection] = React.useState<any[]>([]);
  const [token, setToken] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const {t} = useTranslation();

  const history = useHistory();

  const handleClose = () => {
    setSelection([]);
    setOpen(false);
  };

  React.useEffect(() => {
    (async () => {
      let tempToken = getApiToken(history);
      if (!tempToken) return;
      let tempGroups = await api.getAllGroups(tempToken);
      setToken(tempToken);
      setGroups(tempGroups);
    })();
  }, []);


  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={`Wähle Einheiten die für User ${user?.firstname} ${user?.lastname} zugänglich sind`}
        handleClose={handleClose}
      />
      <GroupCombinationSelection
        groups={groups}
        selection={selection}
        setSelection={setSelection}
        assignedGroups={userAssignedGroups.groupIds}
      />
      <DialogActions>
        <Button
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          disabled={selection.length < 1 || loading}
          variant="contained"
          size="large"
          color="error"
          startIcon={loading && <CircularProgress size={32} />}
          endIcon={<RemoveCircle />}
          onClick={async () => {
            setLoading(true);
            userAssignedGroups.groupIds = selection;
            userAssignedGroups.objectIds = [];
            userAssignedGroups = await api.deleteUserAssignedGroup(userAssignedGroups, token, enqueueSnackbar);
            
              setUserAssignedGroups(userAssignedGroups);


            setLoading(false);
            handleClose();
          }}
        >
          Ausgewählte Einheiten entfernen
        </Button>
        <Button
          disabled={selection.length < 1 || loading}
          variant="contained"
          size="large"
          color="primary"
          startIcon={loading && <CircularProgress size={32} />}
          endIcon={<MergeType />}
          onClick={async () => {
            setLoading(true);
            userAssignedGroups.groupIds = selection;
            userAssignedGroups.objectIds = [];
            userAssignedGroups = await api.createUserAssignedGroup(userAssignedGroups, token, enqueueSnackbar);
            setUserAssignedGroups(userAssignedGroups);

            setLoading(false);
            handleClose();
          }}
        >
          Ausgewählte Einheiten zuweisen
        </Button>
      </DialogActions>
    </Dialog>
  );
}