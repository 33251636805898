import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Paper, Typography, Divider, Switch, TextField, InputAdornment, FormControlLabel } from "@mui/material";
import { useHistory } from "react-router-dom";
import { addMinutes, eachDayOfInterval, endOfDay, fromUnixTime, isSameDay, isWithinInterval, startOfDay } from "date-fns";
import React from "react";
import { api } from "../../../../../api";
import { getGroupPayPerServiceByGroupIds } from "../../../../../helpers/charts/base";
import { getUserIdFromStorage } from "../../../../../helpers/common";
import { defaultEndDate, defaultStartDate } from "../../../../../helpers/date-management";
import { getApiToken } from "../../../../../helpers/storage-management";
import { GroupLicenseTable } from "../../../../../models/GroupLicenseTable";
import { GroupPayPerService } from "../../../../../models/GroupPayPerService";
import { GroupSettingsIncidenReport } from "../../../../../models/GroupSettings";
import { InlineDateTimePicker } from "../../../../core/InlineDateTimePicker";
import { AnalyticSummaryIncidentVisitorDataTable } from "../feedbacks/AnalyticSummaryIncidentVisitorDataTable";
import { GroupPayPerServiceDataTable } from "../feedbacks/GroupPayPerServiceDataTable";
import { LoraDailyMotion } from "../../../../../models/LoraDailyMotion";
import { LoraGroupMotion, LoraGroupMotionDailyTable, LoraGroupSummaryMotionDailyCalendarTable, LoraGroupSummaryMotionDailyTable } from "../../../../../models/LoraGroupMotion";
import { AnalyticSummaryLoraDailyDataTable } from "./AnalyticSummaryLoraDailyDataTable";
import { deepCopy } from "../../../../../helpers/general";
import getUnixTime from "date-fns/getUnixTime/index.js";
import { AnalyticGroupsSummaryLoraDailyDataTable } from "./AnalyticGroupsSummaryLoraDailyDataTable";
import { AnalyticGroupsSummaryLoraDailyCalendarDataTable } from "./AnalyticGroupsSummaryLoraDailyCalendarDataTable";
import { useTranslation } from "react-i18next";

export const AnalyticSummaryLoraDataByGroupsDailyCalendar = ({ groupsLicenseTableEntries, loraMotions }: { groupsLicenseTableEntries: GroupLicenseTable[], loraMotions: LoraGroupMotion[] }) => {
    const [selectedStartDate, setSelectedStartDate] = React.useState(startOfDay(defaultStartDate));
    const [selectedEndDate, setSelectedEndDate] = React.useState(endOfDay(defaultEndDate));

    const [loading, setLoading] = React.useState(false);
    const [loraDailyMotionTableItems, setLoraDailyMotionTableItems] = React.useState<LoraGroupSummaryMotionDailyCalendarTable[]>([]);
    const [showEmptyResults, setShowEmptyResults] = React.useState(false);
    const [virtualTableHeight, setVirtualTableHeight] = React.useState(800);
    const history = useHistory();
    const {t} = useTranslation();


    const rows = React.useMemo(() => {
        if (showEmptyResults) {
            return loraDailyMotionTableItems;
        } else {
            return loraDailyMotionTableItems.filter(x => x.data.flatMap(x=>x.data).length > 0);
        }
    }, [showEmptyResults, selectedStartDate, selectedEndDate, loraDailyMotionTableItems])

    const buildEndDateField = () => {
        return (
            <Grid item>
                <InlineDateTimePicker includeHours={true} title={"End Datum"} selectedDate={selectedEndDate} setSelectedDate={(event: any) => {
                    let date = new Date(event);
                    date.setHours(23, 59, 59);
                    setSelectedEndDate(date);
                    loadData(selectedStartDate, date);

                }}></InlineDateTimePicker>
            </Grid>
        )
    }
    const buildStartDateField = () => {
        return (
            <Grid item>
                <InlineDateTimePicker includeHours={true} title={t("serviceDocumentationTable.startDate")} selectedDate={selectedStartDate} setSelectedDate={(event: any) => {
                    let startDate = new Date(event);
                    startDate.setHours(0, 0, 0);
                    setSelectedStartDate(startDate);
                    loadData(startDate, selectedEndDate);
                }}></InlineDateTimePicker>
            </Grid>
        )
    }

    const loadData = (selectedStartDate: Date, selectedEndDate: Date) => {
        const token = getApiToken(history);
        setLoading(true);

        let days = eachDayOfInterval({ start: selectedStartDate, end: selectedEndDate });

        let table = groupsLicenseTableEntries.map(group => {
            let newMotions = structuredClone(loraMotions) as LoraGroupMotion[];
            let groupData: {day: Date,data : LoraGroupMotion[]}[] = [];
            days
                .forEach(day => {
                    let data: LoraGroupMotion[] = [];
                    newMotions.forEach(lora => {
                        let loraCopy = structuredClone(lora);
                        if (loraCopy.groupId == group.id) {
                            let loraDate = fromUnixTime(loraCopy.createdTimestamp);
                            if (isSameDay(day, loraDate)) {
                                data.push(lora);
                            }
                        }
                    })
                    groupData.push({day: day, data: data})
                })
            return { groupId: group.id, data: groupData, notificationName: group.notificationName };
        });

        setLoraDailyMotionTableItems(table);


        setLoading(false);
    }

    return (
        <Paper>
            <Accordion onChange={async (event, expanded) => {
                if (expanded) {
                    loadData(selectedStartDate, selectedEndDate);
                }

            }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        md={6}
                    >
                        <Grid item>
                            <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                            >
                                Lora Einheiten Täglich - Gesamt Analyse
                            </Typography >
                        </Grid>
                        <Grid item>
                            <Typography
                                className="Paper-Result"
                                component="p"
                            >
                                Einheiten für Gesamt: {groupsLicenseTableEntries.length}
                            </Typography >
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">Lora Einheiten Täglich</Typography>
                        </Grid>
                        <Grid item container direction="row" spacing={3} alignItems={"center"}>
                            {buildStartDateField()}
                            {buildEndDateField()}
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showEmptyResults}
                                            onChange={(event) => {
                                                setShowEmptyResults(event.target.checked);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                    label="Einheiten ohne Lora Daten anzeigen"
                                />

                            </Grid>
                        </Grid>

                        {loading && <Grid item><CircularProgress size={64} /></Grid>}
                        <Grid item container spacing={3} direction={"column"}>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>{rows.length} Einheiten in der Tabelle</Typography>
                            </Grid>
                            <Grid item>
                                <AnalyticGroupsSummaryLoraDailyCalendarDataTable rows={rows} onSelectionChange={() => { }} startDate={selectedStartDate} endDate={selectedEndDate} virtualTableHeight={virtualTableHeight} />
                            </Grid>
                        </Grid>


                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}