import axios from "axios";
import { multipartHeaders } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupSettings } from "../models/GroupSettings";

export const uploadObjectFile = async (file: File, path: string, objectId: string, token: string, enqueueSnackbar: any): Promise<GroupSettings> => {
    try {
        const userId = getUserIdFromStorage();
        const formData = new FormData();
        formData.append('body', file);
        formData.append('path', path);
        const ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/users/${userId}/file`, formData, { headers: { ...multipartHeaders, 'Authorization': `Bearer ${token}` } });

        enqueueSnackbar("Objekt Datei wurde erfolgreich hochgeladen", { variant: 'success' });

        return ret.data;
    } catch (err: any) {
        if (err.response && err.response.data && err.response.data.message){
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }
        enqueueSnackbar("Objekt Datei kann nicht hochgeladen werden", { variant: 'error' });
    }
    return null as any;
}
