import React from 'react';
import { Button, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { PropertyManagementPageEditingDialog } from './PropertyManagementPageEditingDialog';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getUserIdFromStorage } from '../../../../helpers/common';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';
import { PropertyManagementPageDataTable } from './PropertyManagementPageDataTable';
import { LinearProgressWithLabel } from '../../../core/components/ProgressComponent';

export const PropertyManagementPage = () => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [properties, setProperties] = React.useState<UserProperty[]>([]);
    const [addSettingDialogVisible, setAddSettingDialogVisible] = React.useState(false);
    const [selectedProperty, setSelectedProperty] = React.useState<UserProperty>();
    const [editSettingDialogVisible, setEditSettingDialogVisible] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);

    const [loading, setLoading] = React.useState(false);

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(40);

    const onRefresh = async () => {
        setLoading(true);
        const tempToken = getApiToken(history);
        const userId = getUserIdFromStorage();

        if (!tempToken || !userId) return;

        setToken(tempToken);
        setLoadingProgress(0);
        setValueBuffer(40);

        const tempObjects = await api.getUserProperties(tempToken);
        setProperties(tempObjects);

        setLoadingProgress(40);
        setValueBuffer(100);

        let allGroupsTemp = await api.GetAllGroupsByUserIdAsync(tempToken, userId);
        setGroups(allGroupsTemp);

        setLoadingProgress(100);
        setValueBuffer(100);


        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {

            await onRefresh();

        })();

    }, [history])

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Merkmal Verwaltung
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={async () => {
                            setSelectedProperty(UserProperty.CreateUserProperty());
                            setAddSettingDialogVisible(true);
                        }}
                    >
                        Merkmal hinzufügen
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    {loading &&
                        <Grid item>
                            <CircularProgress size={64} />
                        </Grid>
                    }
                    <PropertyManagementPageDataTable
                        onRefresh={onRefresh}
                        rows={properties}
                        groups={groups}
                        onEdit={async (row: UserProperty) => {
                            setSelectedProperty(row);
                            setEditSettingDialogVisible(true);
                        }}
                        onDelete={async (row: UserProperty) => {
                            setSelectedProperty(row);
                            setDeleteConfirmationDialogVisible(true);
                        }}
                    />

                </Paper>
            </Grid>

            <PropertyManagementPageEditingDialog
                objects={properties}
                visible={addSettingDialogVisible}
                setVisible={setAddSettingDialogVisible}
                object={selectedProperty}
                onSave={async (property: UserProperty) => {


                    const added = await api.createUserProperty(property, token, enqueueSnackbar);

                    if (!added)
                        return;
                    setProperties([added, ...properties]);
                    onRefresh();
                }}
            />

            <PropertyManagementPageEditingDialog
                objects={properties}
                visible={editSettingDialogVisible}
                setVisible={setEditSettingDialogVisible}
                object={selectedProperty}
                onSave={async (property: UserProperty) => {


                    const updated = await api.putUserProperty(property, token, enqueueSnackbar);

                    if (!updated) return;

                    setProperties(p => p.map((item: UserProperty) => item.id === updated.id ? updated : item));
                    onRefresh();
                }}
            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title="Sind Sie sicher, dieses Merkmal zu löschen ?"
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {
                    if (!selectedProperty) return;

                    await api.deleteUserProperty(selectedProperty.id, token, enqueueSnackbar);

                    setProperties(properties.filter(x => x.id !== selectedProperty.id));
                    setDeleteConfirmationDialogVisible(false);
                    onRefresh();
                }}
            />

        </Grid>
    );
};