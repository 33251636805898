import axios from 'axios';
import { saveAs } from 'file-saver';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectRenameFile } from '../models/DataFileTree';


export const RenameObjectSharedFileAsync = async (objectId: string, sharedObjectFolderId: string, objectRenameFile: ObjectRenameFile, token: string, enqueueSnackbar: any): Promise<any> => {

    if (!objectId || !token || !enqueueSnackbar)
        return "";

    try {
        const userId = await getUserIdFromStorage();

        const ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/objectunits/${objectId}/shared-folders/${sharedObjectFolderId}/users/${userId}/shared-file/rename`, objectRenameFile,
            {
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        enqueueSnackbar("Objekt Geteilte Datei wurde erfolgreich umbenannt", { variant: 'success' });
        return ret.data;
    } catch (err:any) {
        if (err.response && err.response.data && err.response.data.message) {
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }
        enqueueSnackbar("Es ist ein Fehler beim umbenennen der geteilten Datei aufgetreten", { variant: 'error' });
        return "error";
    }



};