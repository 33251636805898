import axios from 'axios';
import { headers } from '../api';
import { UserAssignedGroup } from '../models/UserAssignedGroup';
import { UserSavedCombinationGroup } from '../models/UserSavedCombinationGroup';


export const deleteUserSavedCombinationGroup = async (userSavedCombinationGroup: UserSavedCombinationGroup | undefined,token: string,enqueueSnackbar:any) : Promise<boolean> => {
    try {
        if (!userSavedCombinationGroup) throw(null);
        let ret = await axios.delete(`${process.env.REACT_APP_BASIC_URI}/users/${userSavedCombinationGroup.userId}/delete-saved-combination-group`,
        {
            headers: {...headers,'Authorization': `Bearer ${token}` },
            data: {
                ...userSavedCombinationGroup
            }
        })
        enqueueSnackbar("User Einheit-Kombination-Löschen erfolgreich",{variant:'success'});
        return ret.data;
    } catch (err) {
        enqueueSnackbar("User Einheit-Kombination-Löschen error",{variant:'error'});
    }
    return null as any;
}