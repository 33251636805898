import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { ArrowBack, ArrowForward, Backspace, Close, ShoppingBasket, Undo } from '@mui/icons-material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { NavigatedFromType } from '../../models/NavigatedFrom';
import { AppBar, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import { UserAccount } from '../../models/UserAccount';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import { getApiToken } from '../../helpers/storage-management';
import { CheckoutItem } from '../../models/CheckoutItem';
import { StripePaymentMethod } from '../../models/PaymentMethod';
import { Product } from '../../models/Product';
import { UserAddress } from '../../models/UserAddress';
import { CheckoutProductListing } from '../authorized/portal/checkout/CheckoutProductListing';
import { ReviewOption } from '../authorized/portal/checkout/ReviewOption';
import { ProductType } from '../authorized/ProductType';
import { LoadingDialog } from '../dialogs/LoadingDialog';
import { SignUpPage } from './SignUpPage';
import { PUBLIC_SHOP_CHOSEN_ITEMS } from '../../constants';
import CheckMeNowIcon from '../../assets/svgs/check-me-now_icon.svg'
import GreenbirdLogo from '../../assets/svgs/greenbird_logo.svg'

export const ShopPagePublic = () => {

  const classes = useStyles();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const childEvents = { register: () => { } };

  const [activeStep, setActiveStep] = React.useState(0);

  const [products, setProducts] = React.useState<Product[]>([]);

  const [items, setItems] = React.useState<CheckoutItem[]>([]);
  const [deliveryAddress, setDeliveryAddress] = React.useState<UserAddress | null>();
  const [paymentAddress, setPaymentAddress] = React.useState<UserAddress | null>();
  const [chosenPaymentMethod, setChosenPaymentMethod] = React.useState(StripePaymentMethod.Card);
  const [loading, setLoading] = React.useState(false);
  const [paymentResult, setPaymentResult] = React.useState<PaymentIntentResult | null>(null);
  const [redirectDialogVisible, setRedirectDialogVisible] = React.useState(false);
  const [showAllProducts, setShowAllProducts] = React.useState(false);

  const steps = ['Produkte auswählen', 'Review', 'Account Erstellung'];

  const show = useQuery().get("showall") == "1" ? true : false;

  const navigatedFrom = useQuery().get("navigatedFrom");

  const onClose = () => {
    history.goBack();
  }


  React.useEffect(() => {

    (async () => {

      setLoading(true);
      setShowAllProducts(show);

      const tempProducts = await api.listProductsForPublicAsync(enqueueSnackbar);
      sortShopItemsByQueryUrl(navigatedFrom, tempProducts);
      if (navigatedFrom == null) {

      }
      setProducts(tempProducts);

      setLoading(false);
      setRedirectDialogVisible(false);
    })();

  }, []);

  function useQuery() {
    return new URLSearchParams(history.location.search);
  }

  if (loading){
    return (
      <CircularProgress size={64}/>
    )
  }


  return (
    <Dialog fullScreen open={true}>
      <DialogTitle className={classes.dialogTitle}>
        <Hidden smDown>
          <AppBar position="sticky">
            <Toolbar>
              <Grid item>
                <img style={{ marginRight: 10 }} src={CheckMeNowIcon} height={60} alt="check-me-now-icon" />
              </Grid>
              <Grid item >
                <Typography className={classes.dialogTitleAppBarTitle} variant="h6">
                  Öffentlicher Shop: Wähle deine Produkte für Check-Me-Now!
                </Typography>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}></Grid>
              <Grid item>
                <img src={GreenbirdLogo} height={50} alt="greenbird-logo" />
              </Grid>
              <Grid item>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    onClose();
                  }}
                  size="large">
                  <Close />
                </IconButton>
              </Grid>
            </Toolbar>
          </AppBar>
        </Hidden>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <main className={classes.layout}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {
              steps &&
              steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))
            }
          </Stepper>
          <React.Fragment>
            {
              activeStep < steps.length &&
              <React.Fragment>
                {
                  activeStep === 0 &&
                  <CheckoutProductListing
                    products={products}
                    items={items}
                    setItems={setItems}
                    showAllProducts={showAllProducts}
                  />
                }
                {
                  activeStep === 1 &&
                  <ReviewOption
                    items={items}
                    products={products}
                    setItems={setItems}
                  />

                }

                {
                  activeStep === 2 &&
                  <SignUpPage isFromShop={true} events={childEvents}
                  />

                }


              </React.Fragment>

            }


            <LoadingDialog
              text="Sie werden an die Zahllungseite weitergeleitet"
              visible={redirectDialogVisible}
              setVisible={setRedirectDialogVisible}
            />

          </React.Fragment>
        </main>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            startIcon={<Backspace />}
            variant={'contained'}
            color="primary"
            onClick={async () => {
              onClose();
            }}
          >
            Zur Landing Page
          </Button>
          {
            !loading &&
            activeStep === 0 &&
            items.length > 0 &&
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<Undo />}
              onClick={() => {
                setItems([]);
              }}
            >
              Auswahl abheben
            </Button>
          }
          {
            activeStep !== 0 && (
              <Button
                className={classes.button}
                startIcon={<ArrowBack />}
                onClick={() => {
                  setActiveStep(activeStep - 1);
                }}
              >
                Zurück
              </Button>
            )
          }
          {activeStep == steps.length - 1 &&
            <Button
              className={classes.button}
              startIcon={<ShoppingBasket />}
              variant={'contained'}
              disabled={items.length < 1}
              color="primary"
              onClick={async () => {
                localStorage.setItem(PUBLIC_SHOP_CHOSEN_ITEMS, JSON.stringify(items));
                childEvents.register();

              }}
            >
              Registrieren und Speichern
            </Button>
          }
          {
            !loading &&
            activeStep < steps.length - 1 &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
              className={classes.button}
              endIcon={<ArrowForward />}
              disabled={items.length <= 0}
            >
              Nächste
            </Button>
          }
        </div>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1200 + parseInt(theme.spacing(2)) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
  },
  dialogTitle: {
    padding: 0,
  },
  dialogTitleAppBar: {
    flexGrow: 1,
  },
  dialogTitleAppBarTitle: {
    flexGrow: 1,
  },
  empty: {
    width: '100%',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  }
}));


export const sortShopItemsByQueryUrl = (navigatedFrom: any, tempProducts: any[]) => {
  switch (navigatedFrom) {
    case NavigatedFromType.SettingsAdsUnit:
      tempProducts.sort((el: Product, el2: Product) => {
        if (el.type == el2.type) return 0;
        return el.type == ProductType.ExtensionContentManagement12Months ||
          el.type == ProductType.ExtensionContentManagement24Months ||
          el.type == ProductType.ExtensionContentManagement36Months ? -1 : 1
      });
      break;
    case NavigatedFromType.SensorExtensionOne:
      tempProducts.sort((el: Product, el2: Product) => {
        if (el.type == el2.type) return 0;
        return el.type == ProductType.ExtensionLicenseSensorExtendedOne12Months ||
          el.type == ProductType.ExtensionLicenseSensorExtendedOne24Months ||
          el.type == ProductType.ExtensionLicenseSensorExtendedOne36Months ? -1 : 1
      });
      break;
    case NavigatedFromType.SensorExtensionTwo:
      tempProducts.sort((el: Product, el2: Product) => {
        if (el.type == el2.type) return 0;
        return el.type == ProductType.ExtensionLicenseSensorExtendedTwo12Months ||
          el.type == ProductType.ExtensionLicenseSensorExtendedTwo24Months ||
          el.type == ProductType.ExtensionLicenseSensorExtendedTwo36Months ? -1 : 1
      });
      break;
    case NavigatedFromType.BssdSensorExtensionTwo:
      tempProducts.sort((el: Product, el2: Product) => {
        if (el.type == el2.type) return 0;
        return el.type == ProductType.ExtensionLicenseBssdSensorExtendedOne12Months ||
          el.type == ProductType.ExtensionLicenseBssdSensorExtendedOne24Months ||
          el.type == ProductType.ExtensionLicenseBssdSensorExtendedOne36Months ? -1 : 1
      });
      break;
    case NavigatedFromType.LoraSensor:
      tempProducts.sort((el: Product, el2: Product) => {
        if (el.type == el2.type) return 0;
        return el.type == ProductType.LicenseLoraSensor12Months ||
          el.type == ProductType.LicenseLoraSensor24Months ||
          el.type == ProductType.LicenseLoraSensor36Months ? -1 : 1
      });
      break;
    default:
      tempProducts.sort((a, b) => a.type - b.type)
  }
}