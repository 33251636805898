import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';
import { UserAddress } from '../models/UserAddress';
import { UserProperty } from '../models/UserProperty';

export const inviteUserServiceWorkerToApp = async (serviceWorker: UserServiceWorker | undefined, token: string, enqueueSnackbar: any): Promise<UserServiceWorker> => {
    try {
        if (!serviceWorker) throw (null);
        let userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-workers/${serviceWorker.id}/invite-to-app`,
            null,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Servicekraft Einladung erfolgreich gesendet", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Servicekraft Einladung error", { variant: 'error' });
    }
    return null as any;
}