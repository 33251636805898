import { fromUnixTime } from "date-fns";
import { GroupServiceMode, GroupServiceModeMaterialConsumption, GroupServiceModeTable } from "../../models/GroupServiceMode";
import {  formatEuropeanDateSpecifcSec, getUnixMillisec } from "../date-management";

export const formatServiceMode = (groupServiceModes: GroupServiceMode[]) => {
    let allMaterialCol = getAllMaterialColumns(groupServiceModes);
    return groupServiceModes.map((el) => {
        let startTimestamp = fromUnixTime(el.startTimestamp);
        let endTimestamp = el.isOnTime ? new Date(fromUnixTime(el.endTimestamp)).toISOString() : `Verpasst um ${formatEuropeanDateSpecifcSec(el.endTimestamp)}`;
        let createdTimestamp = fromUnixTime(el.createdTimestamp);
        let materials = setMaterialConsumptionItems(groupServiceModes,el.materialConsumptionItems, allMaterialCol);
        return new GroupServiceModeTable( el.id,el.groupId,el.isOnTime,el.isCompleted,materials,el.incidentReportItems, el.incidentTexts,el.cleaningTaskItems,
        startTimestamp, endTimestamp, createdTimestamp, el.doneByUser, el.sensor, el.finishedMotionTrigger, el.finishedFeedbackTrigger, el.finishedIntervalTrigger, el.isPartlyCompleted, el.isInProgress, el.incidentPhotos, el.isCanceled, el.serviceCancelReasonType, el.userServiceWorkerId,
        el.nfcCode);
    })
}

export const getAllMaterialColumns = (groupServiceModes: any[]) => {
    let allColumns = groupServiceModes.reduce((res:any,el:any)=>{
        el.materialConsumptionItems.forEach((el2:any)=>{
            if (!res.includes(el2.text))
            {
                res.push(el2.text);
            }
        })
        return res;
    },[]);
    return allColumns;
}

export const getAllIncidentsColumns = (groupServiceModes: GroupServiceMode[]) => {
    let allColumns = groupServiceModes.reduce((res,el)=>{
        el.incidentTexts.forEach((el2)=>{
            if (!res.includes(el2.incidentText))
            {
                res.push(el2.incidentText);
            }
        })
        return res;
    },[] as string[]);
    return allColumns;
}

export const getAllServiceColumns = (groupServiceModes: GroupServiceMode[]) => {
    let allColumns = groupServiceModes.reduce((res:any,el)=>{
        el.cleaningTaskItems.forEach((el2:any)=>{
            if (!res.includes(el2))
            {
                res.push(el2);
            }
        })
        return res;
    },[]);
    return allColumns;
}

const setMaterialConsumptionItems = (groupServiceModes: any[], items: GroupServiceModeMaterialConsumption[], allColumns: string[]) => {

    let ret = items.reduce((res:any,el)=>{
        res[el.text] = el.amount;
        return res;       
    },{});
    allColumns.forEach((col:any)=>{
        if (!items.some((el)=>el.text === col)){
            ret[col] = 0;
        } 
    });

    return ret;
}