import axios from "axios";
import { headers } from "../api";
import { Product } from '../models/Product';

export const ListProductsAsync = async (token: string, enqueueSnackbar: any): Promise<Product[]> => {

    return await axios.get(
        `${process.env.REACT_APP_BASIC_URI}/products`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        return res.data;
    }).catch((error) => {
        enqueueSnackbar("Bei der Ladung von Produkten ist ein Fehler aufgetreten", { variant: 'error' });
        return [];
    });
};

export const listProductsForPublicAsync = async (enqueueSnackbar: any): Promise<Product[]> => {

    return await axios.get(
        `${process.env.REACT_APP_BASIC_URI}/products/public`,
        { headers: { ...headers } }
    ).then((res) => {
        return res.data;
    }).catch((error) => {
        enqueueSnackbar("Bei der Ladung von Produkten ist ein Fehler aufgetreten", { variant: 'error' });
        return [];
    });
};
