import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { toCurrencyString } from '../../helpers/common';
import { CurrencyHelper } from '../../helpers/CurrencyHelper';
import { UserInvoiceItem } from '../../models/UserInvoice';
import { UserInvoiceAddress } from '../../models/UserInvoiceAddress';

export const UserInvoicesTableInvoiceDetailDialogItemTable = ({ items, taxRate }: { items: UserInvoiceItem[], taxRate: number }) => {

    const classes = useStyles();
    const net = items.length > 0 ? items.map(x => x.netTotal).reduce((a, b) => a + b) : 0;
    const tax = CurrencyHelper.taxOf(net, taxRate);
    const gross = CurrencyHelper.grossOf(net, taxRate);

    return (
        <div className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableRowCell} align="left">Bezeichnung</TableCell>
                            <TableCell className={classes.tableRowCell} align="left">Anzahl</TableCell>
                            <TableCell className={classes.tableRowCell} align="left">Einzelnpreis</TableCell>
                            <TableCell className={classes.tableRowCell} align="right">Summe</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items &&
                            items.length > 0 &&
                            items.map((item: UserInvoiceItem, rowIndex: number) => {

                                return (
                                    <TableRow key={rowIndex}>
                                        <TableCell align="left">{item.product.name}</TableCell>
                                        <TableCell align="left">{item.count}</TableCell>
                                        <TableCell align="right">
                                            <Typography>{CurrencyHelper.toCurrencyString(item.product.unitPrice)}</Typography>
                                        </TableCell>
                                        <TableCell align="right">{CurrencyHelper.toCurrencyString(item.netTotal)}</TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell rowSpan={3} width="60%" />
                            <TableCell align="left" colSpan={2}>Summe Netto:</TableCell>
                            <TableCell align="right">{CurrencyHelper.toCurrencyString(net)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Mwst</TableCell>
                            <TableCell align="left">{taxRate}%</TableCell>
                            <TableCell align="right">{CurrencyHelper.toCurrencyString(tax)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" colSpan={2}>Summe Brutto:</TableCell>
                            <TableCell align="right">{CurrencyHelper.toCurrencyString(gross)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {

    },
    tableRowCell: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    tableCellNumber: {
        fontSize: 32,
    },
    container: {
        height: 256,
        '&::-webkit-scrollbar': {
            width: 12,
        },
        '&::-webkit-scrollbar-track': {
            width: 12,
        },
        '&::-webkit-scrollbar-thumb': {
            width: 12,
            borderRadius: 16,
            backgroundColor: theme.palette.primary.main,
        },
    },
}));
