import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { ObjectUnit } from '../models/ObjectUnit';
import { UserServiceWorker } from '../models/UserServiceWorker';
import { UserAddress } from '../models/UserAddress';
import { UserServiceWorkerObjectSession } from '../models/UserServiceWorkerObjectSession';
import { ObjectCleaningTrigger } from '../models/ObjectCleaningTrigger';
import { getQueryParamFromApiDataAmountType } from '../helpers/charts/base';

export const getObjectCleaningTriggerByUserId = async (token: string): Promise<ObjectCleaningTrigger[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/object-cleaning-triggers`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}

export const getObjectCleaningTriggerByObjectIds = async (token: string, objectIds: string[]): Promise<ObjectCleaningTrigger[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/object-cleaning-triggers?${getQueryParamFromApiDataAmountType()}`, {stringArray : objectIds},{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}