import axios from 'axios';
import { headers } from '../api';
import { getOwnUserIdFromStorage, getUserIdFromStorage } from '../helpers/common';
import { AdminSetting } from '../models/AdminSetting';
import { IntervalControl } from '../models/IntervalControl';
import { StandardWorkItem } from '../models/StandardItem';
import { UserAddress } from '../models/UserAddress';

export const CreateIntervalControl = async (intervalControl: IntervalControl | undefined, token: string, enqueueSnackbar: any): Promise<IntervalControl> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.put(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/upsert-interval-control`,
            { ...intervalControl },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Intervall Speichern erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Intervall Speichern error", { variant: 'error' });
    }
    return null as any;
}