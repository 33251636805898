import React from "react";
import {
    AccordionDetails,
    Accordion,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    AccordionSummary,
    Typography,
    InputAdornment,
    IconButton,
    Theme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ExpandMore, Info, Visibility, VisibilityOff } from "@mui/icons-material";
import { GroupSettings, GroupSettingsType, ToiletType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { lightgrey } from '../../styles/colors';

export const GroupSettingsFieldsGeneralInformation = ({ row, onChange, type }: { row: GroupSettings | UserGroupSettingsTemplate; onChange: any; type: GroupSettingsType }) => {

    const classes = useStyles();

    React.useEffect(() => {

        return () => {
        }
    }, []);

    if (type !== GroupSettingsType.Template) return null;

    return (
        <Accordion className={classes.hover}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <Info width={40} height={40} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">Allgemein</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item xs={12} container direction={"row"} spacing={3}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                variant={"filled"}
                                margin="normal"
                                name="name"
                                label="Name"
                                value={row.name || ''}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 'bold',
        },
        hover: {
            "&:hover": {
                backgroundColor: lightgrey
            }
        }
    }),
);