import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../api';
import { useSnackbar } from 'notistack';
import { Grid, TextField } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { LockOpen } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const MakeAuthTelegramCodeDialog = ({ open, setOpen, token, phoneNumber, hash }: { open: boolean, setOpen: any, token: string, phoneNumber: any, hash: any }) => {

    const { enqueueSnackbar } = useSnackbar();
    const [code, setCode] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog fullWidth maxWidth={"sm"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title">
                    Telegram authorisieren mit der Nummer {phoneNumber}
        </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction={'row'} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: '100%' }}
                                id="filled-number"
                                label="Code"
                                variant="filled"
                                value={code}
                                onChange={(event: any) => { setCode(event.target.value) }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="large"
                        startIcon={<CancelIcon />} variant={'outlined'} onClick={handleClose} color="primary">
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button size="large" disabled={loading}
                        startIcon={<LockOpen></LockOpen>} variant={'contained'} onClick={async () => {
                            setLoading(true);
                            let res = await api.telegramMakeAuthAsync(phoneNumber, hash, code, token, enqueueSnackbar);
                            setLoading(false);
                            if (res){
                                handleClose();
                            }
                        }} color="primary">
                        Authentifizieren!
        </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}