import { formatISO } from "date-fns";
import { GroupServiceMode, GroupServiceModeMaterialConsumption } from "../../models/GroupServiceMode";


export const generateDoneByUserCleaningTasks = (groupsServiceMode: GroupServiceMode[]) => {
    let arr = groupsServiceMode.map(el => {
        let cleaningTaskItems = el.cleaningTaskItems.reduce((acc: any, curVal: any) => {
            return { ...acc, [curVal]: 1, cleaner: el.doneByUser }
        }, {});
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), ...cleaningTaskItems }
    });
    return arr;
}

export const generateDoneByUserCleaningTasksSum = (groupsServiceMode: GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        let cleaningTaskItems = el.cleaningTaskItems.reduce((acc: any, curVal: any) => {
            return { ...acc, [curVal]: 1 }
        }, {});
        Object.keys(cleaningTaskItems).forEach(key => {
            res.push({ cleaner: el.doneByUser,date: formatISO(new Date(el.createdTimestamp * 1000)), key: key, value: cleaningTaskItems[key] });
        })
    });
    return res;
}

export const generateDoneByUserCleaningTasksSumTotal = (groupsServiceMode: GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        let cleaningTaskItems = el.cleaningTaskItems.reduce((acc: any, curVal: any) => {
            return { ...acc, [el.doneByUser]: 1 }
        }, {});
        Object.keys(cleaningTaskItems).forEach(key => {
            res.push({ date: formatISO(new Date(el.createdTimestamp * 1000)), key: key, value: cleaningTaskItems[key] });
        })
    });
    return res;
}

export const getAllUserItems = (groupsServiceMode: GroupServiceMode[], field: string) => {
    let allCleaningTaskUser = groupsServiceMode.reduce((acc: any[], curVal: any) => {
        let res = curVal[field].filter((el: GroupServiceMode) => curVal.doneByUser && !acc.includes(el))
            .map((el: GroupServiceMode) => el);
        return [...acc, ...res];
    }, []);
    return allCleaningTaskUser;
}

export const generateDoneByUserIncidentReports = (groupsServiceMode: GroupServiceMode[]) => {
    let arr = groupsServiceMode.map(el => {
        let incidentReportItems = el.incidentReportItems.reduce((acc: any, curVal: any) => {
            return { ...acc, [curVal]: 1, cleaner: el.doneByUser }
        }, {});
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), ...incidentReportItems }
    });
    return arr;

}

export const generateDoneByUserIncidentReportsSum = (groupsServiceMode: GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        let incidentReportItems = el.incidentReportItems.reduce((acc: any, curVal: any) => {
            return { ...acc, [curVal]: 1 }
        }, {});
        Object.keys(incidentReportItems).forEach(key => {
            res.push({ cleaner: el.doneByUser,date: formatISO(new Date(el.createdTimestamp * 1000)), key: key, value: incidentReportItems[key] });
        })
    });
    return res;
}

export const generateMaterialConsumptionUserChartData = (groupsServiceMode: GroupServiceMode[]) => {
    let arr = groupsServiceMode.map(el => {
        let materials = el.materialConsumptionItems.reduce((acc: any, curVal: GroupServiceModeMaterialConsumption) => {
            return { ...acc, [curVal.text]: curVal.amount, cleaner: el.doneByUser }
        }, {});
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), ...materials }
    });
    return arr;
}

export const generateMaterialConsumptionUserChartDataSum = (groupsServiceMode: GroupServiceMode[]) => {
    let res: any = [];
    groupsServiceMode.forEach(el => {
        let materialConsumptionItems = el.materialConsumptionItems.reduce((acc: any, curVal: GroupServiceModeMaterialConsumption) => {
            return { ...acc, [curVal.text]: curVal.amount }
        }, {});
        Object.keys(materialConsumptionItems).forEach(key => {
            res.push({  cleaner: el.doneByUser ,date: formatISO(new Date(el.createdTimestamp * 1000)), key: key, value: materialConsumptionItems[key] });
        })
    });
    return res;
}


export const getAllMaterials = (groupsServiceMode: GroupServiceMode[]) => {
    let allMaterials = groupsServiceMode.reduce((acc: any[], curVal: GroupServiceMode) => {
        let res = curVal.materialConsumptionItems.filter(el => curVal.doneByUser && el && !acc.includes(el.text)).map(el => el.text);
        return [...acc, ...res];
    }, []);
    return allMaterials;
}