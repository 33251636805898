import React from 'react';
import TextField from '@mui/material/TextField';
import { getApiToken } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { UserAccount } from '../../../models/UserAccount';
import { Button, Card, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CloudUpload, Email, Save, SwitchCamera, Visibility, VpnKey } from '@mui/icons-material';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { StringHelper } from '../../../helpers/string-helper';
import { getOwnUserIdFromStorage, getUserIdFromStorage } from '../../../helpers/common';
import { ServiceWorkerServicesDashboard } from './Dashboard/ServiceWorkerServicesDashboard';
import { UserType } from '../../../models/UserType';
import { GlobalContext } from '../PortalPage';
import { GroupLogoDialog } from '../../dialogs/GroupLogoDialog';
import { NetworkImageNonDialog } from '../../images/NetworkImageNonDialog';
import GreenbirdLogo from '../../../assets/svgs/greenbird_logo.svg'
import { primaryColor } from '../../../App';
import CountrySelect from '../../common/CountryPicker';
import { UserAddress } from '../../../models/UserAddress';
import LanguagePicker from '../../common/LanguagePicker';
import { useTranslation } from 'react-i18next';

export const UserAccountPage = ({ setDrawerUserAccount, currentLoggedInUser }: { setDrawerUserAccount: any, currentLoggedInUser: UserAccount | undefined }) => {

    const classes = usePortalAccountStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [userAccount, setUserAccount] = React.useState<UserAccount>();
    const [token, setToken] = React.useState("");
    const [saving, setSaving] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [email, setEmail] = React.useState('');
    const [hasEmailError, setHasEmailError] = React.useState(false);
    const [emailHelperText, setEmailHelperText] = React.useState<string | null>(null);
    const { user }: any = React.useContext(GlobalContext);
    const [loading, setLoading] = React.useState(false);

    const [source, setSource] = React.useState('');
    const { t } = useTranslation();


    React.useEffect(() => {
        (async () => {
            setLoading(true);
            let token = getApiToken(history);
            if (!token) return;
            const loggedIn = await api.getUser(token, getOwnUserIdFromStorage());
            if (loggedIn.userType == UserType.Admin) {
                let user = await api.getUser(token, getUserIdFromStorage());
                setUserAccount(user);
                setSource(`${process.env.REACT_APP_BASIC_URI}/users/${user.id}/logos/${user.logo?.fileName}`);
            } else {
                setUserAccount(loggedIn);
                setSource(`${process.env.REACT_APP_BASIC_URI}/users/${loggedIn.id}/logos/${loggedIn.logo?.fileName}`);
            }
            setLoading(false);
            setToken(token);
        })();
    }, []);


    const updateLogo = async (file: File) => {
        const newUserAccount = await api.updateUserLogo(file, token, enqueueSnackbar);
        setUserAccount(newUserAccount);
    }

    const onLogoFileChosen = async (event: any) => {

        const file = event.target.files[0];
        updateLogo(file);

        if (event.onChanged)
            event.onChanged();
    }

    const onLogoFileDelete = async () => {
        if (!userAccount || !userAccount.logo) return;

        userAccount.logo.fileName = "";
        userAccount.logo.contentType = "";

        setSource("");

        await onSave();

        enqueueSnackbar("Logo Greenbird wird angewendet. ", { variant: 'success' });

    }

    const onSave = async () => {
        if (!userAccount) return;
        setSaving(true);
        await api.updateUser(userAccount, token, enqueueSnackbar);
        if (currentLoggedInUser && currentLoggedInUser.userType != UserType.Admin) setDrawerUserAccount(userAccount);
        setSaving(false);
    }

    return (
        <Paper className={classes.root}>
            {loading &&
                <CircularProgress size={100} />
            }
            <Typography component="h3" variant="h3" className={classes.title}>{t("account.accountSettings")}</Typography>
            <Divider></Divider>
            <Grid container direction="column" spacing={3}>
                <Grid item container direction="row" className={classes.container} justifyContent="space-around" spacing={3}>
                    {userAccount &&
                        <Grid container xs={12} lg={4} direction="column" spacing={3} item>
                            <Grid item>
                                <Typography component="h4" variant="h4">{t("account.details")}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    fullWidth
                                    id="filled-name"
                                    InputLabelProps={{ shrink: true }}
                                    label={t("account.firstName")}
                                    variant="filled"
                                    value={userAccount.firstname}
                                    onChange={(event: any) => { setUserAccount({ ...userAccount, firstname: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    fullWidth
                                    label={t("account.lastName")}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    value={userAccount.lastname}
                                    onChange={(event: any) => { setUserAccount({ ...userAccount, lastname: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    fullWidth
                                    label={t("account.title")}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    value={userAccount.title}
                                    onChange={(event: any) => { setUserAccount({ ...userAccount, title: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    fullWidth
                                    label={t("account.company")}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    value={userAccount.company}
                                    onChange={(event: any) => { setUserAccount({ ...userAccount, company: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    fullWidth
                                    label={t("account.fn")}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    value={userAccount.atu}
                                    onChange={(event: any) => { setUserAccount({ ...userAccount, atu: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    fullWidth
                                    label={t("account.uid")}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    value={userAccount.uid}
                                    onChange={(event: any) => { setUserAccount({ ...userAccount, uid: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    fullWidth
                                    label={t("account.email")}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    value={userAccount.mail}
                                    onChange={(event: any) => { setUserAccount({ ...userAccount, mail: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    fullWidth
                                    id="filled-name"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    label={t("account.customerNumber")}
                                    variant="filled"
                                    value={userAccount.customerNumber}
                                    onChange={(event: any) => { setUserAccount({ ...userAccount, customerNumber: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={userAccount.isNewsletterActive}
                                        onChange={(event: any) => {
                                            setUserAccount({ ...userAccount, isNewsletterActive: event.target.checked })
                                        }}
                                        name="isIncidentVisitorEnabled"
                                        color="primary" />}
                                    label={t("account.newsletterActivated")} />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <LanguagePicker user={userAccount} setUser={setUserAccount} />
                            </Grid>
                            <Grid item container xs={12} lg={8} direction={'row'}>
                                <Grid item>
                                    <Button size="large" disabled={saving}
                                        startIcon={<Save />}
                                        variant={'contained'}
                                        onClick={async () => {
                                            onSave();
                                        }}
                                        color="primary">
                                        {t("account.save")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid container xs={12} lg={8} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">{t("account.yourLoginDetails")}</Typography>
                        </Grid>
                        <Grid item container direction="row" spacing={3} alignItems={"center"}>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label={t("account.currentPassword")}
                                    variant="filled"
                                    value={password}
                                    onChange={(event: any) => { setPassword(event.target.value) }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label={t("account.newPassword")}
                                    variant="filled"
                                    value={newPassword}
                                    onChange={(event: any) => { setNewPassword(event.target.value) }}
                                />
                            </Grid>
                            <Grid item>
                                <Button size="large"
                                    startIcon={<VpnKey />}
                                    variant={'contained'}
                                    onClick={async () => {
                                        let res = await api.login(userAccount?.mail as string, password, enqueueSnackbar);
                                        if (res) {
                                            await api.changePassword(newPassword, token, enqueueSnackbar);
                                        }
                                    }}
                                    color="primary">
                                    {t("account.changePassword")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" spacing={3} alignItems={"center"}>
                            <Grid item md={12} lg={9}>
                                <TextField
                                    fullWidth
                                    label={t("account.transferOwner")}
                                    variant="filled"
                                    value={email}
                                    error={hasEmailError}
                                    helperText={emailHelperText}
                                    onChange={(event: any) => { setEmail(event.target.value) }}
                                    onFocus={async () => {
                                        setHasEmailError(false);
                                        setEmailHelperText(null);
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} lg={3}>
                                <Button
                                    startIcon={<Email />}
                                    variant={'contained'}
                                    color="primary"
                                    onClick={async () => {

                                        if (!StringHelper.IsValidEmail(email)) {
                                            setHasEmailError(true);
                                            return;
                                        }

                                        if (email === userAccount?.mail) {
                                            setHasEmailError(true);
                                            setEmailHelperText(t("account.emailMustNotBeIdenticalToTheCurrentOne"));
                                            return;
                                        }

                                        await api.requestChangeUserEmailAsync(email, token, enqueueSnackbar);
                                    }}>
                                    {t("account.confirmNewEmail")}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
                <Grid item>
                    <Divider></Divider>

                </Grid>



                <Grid className={classes.container} item container direction="row" spacing={3} alignItems="center">
                    <Grid item>
                        <Typography component="h4" variant="h4" >{t("account.logo")}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            startIcon={<CloudUpload />}
                            component="label">
                            {t("account.uploadLogo")}
                            <input
                                type="file"
                                name="updateLogo"
                                onChange={onLogoFileChosen}
                                hidden
                                accept="image/png, image/jpeg, image/jpg" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            startIcon={<SwitchCamera />}
                            variant={'contained'}
                            onClick={() => onLogoFileDelete()}
                            color="primary">
                            {t("account.useDefaultLogo")}
                        </Button>
                    </Grid>
                    <Grid item>
                        {source &&
                            <Grid item>
                                <Typography variant="h5">{t("account.currentLogo")}</Typography>
                                <Card>
                                    {userAccount?.logo?.fileName ?
                                        <NetworkImageNonDialog src={source} hideOverlay={true} />
                                        :
                                        <div style={{ backgroundColor: primaryColor }}>
                                            <img src={GreenbirdLogo} height={50} alt="greenbird-logo" />
                                        </div>
                                    }
                                </Card>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>

        </Paper>
    );
}