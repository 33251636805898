import { GroupType } from "./Group";

export class GroupOnlineStatus {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public createdTimestamp!: string;
    public batteryLevel !: number;
    public constructor (groupId: string, userId: string) {
        this.systemETag = null;
        this.groupId = groupId;
        this.userId = userId;
    }

    static Columns = {
        id: 'id',
        groupId: "groupId",
        userId: "userId",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        batteryLevel: "batteryLevel"
    }
}

export class GroupOnlineStatusCountTable {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public createdTimestamp!: string;
    public lastOnlineTimestamp : Date;
    public onlineCount!: number;
    public onlineCountMax !: number;
    public batteryLevel !: number;
    public deviceId !: string;
    public sensorIds !: string;
    public constructor (groupId: string, userId: string, onlineCount: number, onlineCountMax: number, lastOnlineTimestamp: Date, batteryLevel : number, deviceId: string,sensorIds: string) {
        this.systemETag = null;
        this.groupId = groupId;
        this.userId = userId;
        this.onlineCount = onlineCount;
        this.onlineCountMax = onlineCountMax;
        this.lastOnlineTimestamp = lastOnlineTimestamp;
        this.batteryLevel = batteryLevel;
        this.deviceId = deviceId;
        this.sensorIds = sensorIds;
    }

    static Columns = {
        id: 'id',
        groupId: "groupId",
        userId: "userId",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        countMax: "countMax",
        countPercent: "countPercent",
        batteryLevel: "batteryLevel",
        deviceId : "deviceId",
        sensorIds : "sensorIds"

    }
}

export class BatteryOnlineStatusCountTableClass {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public userId!: string;
    public createdTimestamp!: string;

    public feedbackCount !: number;
    public feedbackLastOnline !: Date;
    public feedbackBatteryLevel !: number;

    public cleanerFeedbackCount !: number;
    public cleanerFeedbackLastOnline !: Date;
    public groupOnlineStatusCountTable : GroupOnlineStatusCountTable | undefined = undefined;
    public deviceId !: string;

    public constructor () {

    }

    static Columns = {
        id: 'id',
        groupId: "groupId",
        groupType: "groupType",
        objectName:'objectName',
        levelName: 'levelName',
        roomName: 'roomName',
        toiletType: 'toiletType',
        userPropertyName:'userPropertyName',
        onlineCount: "onlineCount",
        sensors: "sensors",
        lastOnline: "lastOnline",
        lastOnlineSetting: "lastOnlineSetting",
        nativeApplicationVersion: "nativeApplicationVersion",
        downloadedFilename: "downloadedFilename",
        buildVersion: "buildVersion",
        materials: "materials",
        services: "services",
        feedbackCount : 'feedbackCount',
        feedbackCountBattery : 'feedbackCountBattery',
        feedbackLastOnline: 'feedbackLastOnline',
        cleanerFeedbackCount: 'cleanerFeedbackCount',
        cleanerFeedbackLastOnline: 'cleanerFeedbackLastOnline',
        lastOnlineTimestamp : 'lastOnlineTimestamp',
        lastOnlineBattery : 'lastOnlineBattery',
        countMax: 'countMax',
        countPercent: 'countPercent',
        deviceId : "deviceId"

        
    }
}