import React from 'react';
import { Button, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IntervalControlEditingDialog } from './IntervalControlEditingDialog';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getApiToken } from '../../../../helpers/storage-management';
import { api } from '../../../../api';
import { Add } from '@mui/icons-material';
import { IntervalControl } from '../../../../models/IntervalControl';
import { IntervalControlDataTable } from './IntervalControlDataTable';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';

export const IntervalControlPage = ({intervalControls, setIntervalControls} : {intervalControls : IntervalControl[], setIntervalControls: any}) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [addIntervalDialogVisible, setAddIntervalDialogVisible] = React.useState(false);
    const [updateIntervalDialogVisible, setUpdateIntervalDialogVisible] = React.useState(false);

    const [selectedIntervalControl, setSelectedIntervalControl] = React.useState<IntervalControl>();
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [selection, setSelection] = React.useState([]);


    const loadData = async () => {
        const tempToken = getApiToken(history);
        if (!tempToken) return;

        setToken(tempToken);
        setLoading(true);

        let intervalControls = await api.GetIntervalControlByUser(tempToken);
        setIntervalControls([...intervalControls]);

        setLoading(false);

    }


    React.useEffect(() => {

        (async () => {

            loadData();

        })();

    }, [history])

    const deleteStandardItem = async () => {
        if (!selectedIntervalControl) return;
        await api.DeleteIntervalControlAsync(selectedIntervalControl, token, enqueueSnackbar);

    }

    const addIntervalControlItem = async (standarditem: IntervalControl) => {
        const added = await api.CreateIntervalControl(standarditem, token, enqueueSnackbar);
    }



    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Intervallgruppen Einstellungen
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={3}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={async () => {
                            setSelectedIntervalControl(new IntervalControl());
                            setAddIntervalDialogVisible(true);
                        }}
                    >
                        Intervallgruppe hinzufügen
                    </Button>
                </Grid>
            </Grid>
            {loading &&
                <Grid item xs={12}>
                    <CircularProgress size={64} />
                </Grid>
            }
            <Grid item xs={12}>
                <Paper>
                    <IntervalControlDataTable rows={intervalControls}
                    onEdit={function (row: IntervalControl): any {
                        setSelectedIntervalControl(row);
                        setUpdateIntervalDialogVisible(true);
                    } } onDelete={(row: IntervalControl) => {
                        setSelectedIntervalControl(row);
                        setDeleteConfirmationDialogVisible(true);
                    } } selection={selection} setSelection={setSelection} />
                </Paper>
            </Grid>

            <IntervalControlEditingDialog
                visible={addIntervalDialogVisible}
                setVisible={setAddIntervalDialogVisible}
                onSave={async (intervalControl: IntervalControl) => {
                    await addIntervalControlItem(intervalControl);
                    await loadData();
                }}
            />

            <IntervalControlEditingDialog
                visible={updateIntervalDialogVisible}
                setVisible={setUpdateIntervalDialogVisible}
                currentIntervalItem={selectedIntervalControl}
                onSave={async (intervalControl: IntervalControl) => {

                    await addIntervalControlItem(intervalControl);
                    await loadData();
                }}
            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title="Sind Sie sicher, dieses Intervall zu löschen ?"
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {

                    await deleteStandardItem();
                    loadData();
                    setDeleteConfirmationDialogVisible(false);

                }}
            />

        </Grid>
    );
};