import axios from 'axios';
import { PUBLIC_CURRENT_USER_SERVICE_WORKER, PUBLIC_SERVICEWORKER_TOKEN } from '../../constants';
import { getStorage } from '../../helpers/storage-management';
import { UserServiceWorker } from '../../models/UserServiceWorker';
import { headers } from '../../api';


export const isServiceWorkerSignedIntoObject = async (objectId: string, enqueueSnackbar: any): Promise<any> => {
  try {
    let token = localStorage.getItem(PUBLIC_SERVICEWORKER_TOKEN);
    let serviceWorkers = await getStorage<UserServiceWorker>(PUBLIC_CURRENT_USER_SERVICE_WORKER);

    let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/service-workers/${serviceWorkers.id}/is-signed-in-object/${objectId}`,
      { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
    if (!ret.data) {
      enqueueSnackbar("Sie sind im Objekt nicht angemeldet!", { variant: 'info' });
    }
    return ret.data;
  } catch (err) {
    enqueueSnackbar("Sie sind im Objekt nicht angemeldet", { variant: 'info' });

    return false;
  }
}