import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const unAssignObjectFromGroup = async (groupId: string, objectId: string, token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    let userId = getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/object-unit/${objectId}/un-assign`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Objekt wurde von der Einheit erfolgreich entfernt", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Entfernung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};