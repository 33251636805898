import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { Add, Cancel, PhoneAndroid, PhotoAlbum, Save } from '@mui/icons-material';
import { CircularProgress, DialogActions, DialogContent, Grid, ListItemButton, TextField } from '@mui/material';
import { StandardWorkItemExtras } from '../../models/StandardItem';
import { ItemThingType } from '../../models/GroupSettings';
import { useTranslation } from 'react-i18next';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 800,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export interface DefaultItemsDialogProps {
  manageItems: any;
  open: boolean;
  selectedValue: string;
  currentItems: StandardWorkItemExtras[];
  allStandardItems: StandardWorkItemExtras[];
  onClose: (value: string) => void;
  objectView?: boolean;
  itemThingType : ItemThingType;
}

function SimpleDialog(props: DefaultItemsDialogProps) {



  const { onClose, selectedValue, open, manageItems, currentItems, allStandardItems } = props;
  const [listItems, setListItems] = React.useState<StandardWorkItemExtras[]>([]);
  const history = useHistory();
  const {t} = useTranslation();

  React.useEffect(() => {
    let token = getApiToken(history);
    if (open) {
      (async () => {
        setListItems(allStandardItems);
      }
      )();

    }
  }, [open]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const classes = useStyles();
  const [selectedItems, setSelectedItems] = React.useState<StandardWorkItemExtras[]>([]);

  const handleToggle = (value: StandardWorkItemExtras) => () => {
    const currentIndex = selectedItems.indexOf(value);
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedItems(newChecked);
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Items Auswahl</DialogTitle>
      <DialogContent>
        <List className={classes.root}>
          {listItems.map((value: StandardWorkItemExtras, index: number) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem disabled={currentItems.some(x => x.text == value.text)} key={index} role={undefined} button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedItems.indexOf(value) !== -1 || currentItems.some(x => x == value)}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.text}`} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button size="large"
              startIcon={<Cancel />} variant={'outlined'} onClick={handleClose} color="primary">
              {t("myUnitsSettings.cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button size="large"
              startIcon={<Save />} variant={'contained'} onClick={async () => {
                handleClose();
                manageItems(selectedItems, props.itemThingType);
                setSelectedItems([]);
              }} color="primary">
              Hinzufügen
            </Button>
          </Grid>
        </Grid>

      </DialogActions>
    </Dialog>
  );
}

export default function RoomTypeItemsDialog({ disabled = false, title = "Nutzungskomponente zuweisen", manageItems, currentItems, allStandardItems, onLoadFiles, itemThingType }: { disabled?: boolean, title?: string, manageItems: any, currentItems: StandardWorkItemExtras[], allStandardItems: StandardWorkItemExtras[], onLoadFiles: any, itemThingType: ItemThingType }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = async () => {
    setLoading(true);
    await onLoadFiles();
    setLoading(false);
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <Button disabled={loading || disabled} endIcon={loading && <CircularProgress size={32} />} startIcon={<Add />} size="large" variant="contained" color="primary" onClick={handleClickOpen}>
        {title}
      </Button>
      <SimpleDialog itemThingType={itemThingType} allStandardItems={allStandardItems} manageItems={manageItems} selectedValue={selectedValue} open={open} onClose={handleClose} currentItems={currentItems} />
    </>
  );
}