import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { UserAddress } from '../models/UserAddress';
import { t } from 'i18next';

export const deleteUserAddress = async (userAddress: UserAddress, token: string, enqueueSnackbar: any) => {

    if (!userAddress)
        return;

    const userId = getUserIdFromStorage();

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/user-address/${userAddress.id}`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((response) => {
        enqueueSnackbar(`Adresse wurde erfolgreich gelöscht`, { variant: 'success' });
    }).catch((error) => {
        console.log(JSON.stringify(error, null, 2));
        enqueueSnackbar(`Es ist ein Fehler bei der Löschung von Adresse aufgetreten`, { variant: 'error' });
    });
};
