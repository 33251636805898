import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';

import { Sensor } from '../models/GroupSettings';

export const getGroupSensorsByUserId = async (token: string) : Promise<Array<Sensor[]>> => {
    try {
        let userId = getUserIdFromStorage();

        if (!userId) return [];
        
        let ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/sensors`,
        {headers: {...headers,'Authorization': 'Bearer '+token}})
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err,null,2));
    }
    return null as any;
}