import { useImageHoverOverlayStyles } from "../../styles/overlay";
import { IconButtonWithLabel } from "../buttons/IconButtonWithLabel";
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';

export const GroupSideBarImagesDialogImageHoverOverlay = ({ onOpenButtonClick, onDeleteButtonClick, onSettingButtonClick }
    : { onOpenButtonClick: any, onDeleteButtonClick: any, onSettingButtonClick: any }) => {

    const overlayStyles = useImageHoverOverlayStyles();

    return (
        <div className={overlayStyles.root}>
            <IconButtonWithLabel
                icon={<OpenInNewIcon fontSize="large" aria-label="view" />}
                label="Anzeigen"
                onClick={onOpenButtonClick} />

            <IconButtonWithLabel
                icon={<SettingsIcon fontSize="large" aria-label="view" />}
                label="Einstellungen"
                onClick={onSettingButtonClick} />

            <IconButtonWithLabel
                icon={<DeleteIcon fontSize="large" aria-label="delete" />}
                label="Löschen"
                onClick={onDeleteButtonClick} />
        </div>
    );
}