import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { GroupSettings } from '../../../../models/GroupSettings';
import { formatUnixSecTimestampEuropeanDateInMinutes, formatUnixSecToDate, formatUnixSecToTime } from '../../../../helpers/date-management';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GroupCleanerFeedback } from '../../../../models/GroupCleanerFeedback';

export const CleanerFeedbackDataTable = ({ rows, groups, selectedEntries, onSelectionChange,  }
    : { rows: GroupCleanerFeedback[], groups: GroupLicenseTable[], selectedEntries: GroupCleanerFeedback[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);

    const columns = [
        {
            name: GroupCleanerFeedback.Columns.veryPositiveAmount,
            title: "Sehr Positiv",
            getCellValue: (row: GroupCleanerFeedback) => row.veryPositiveAmount,
        },
        {
            name: GroupCleanerFeedback.Columns.positiveAmount,
            title: "Positiv",
            getCellValue: (row: GroupCleanerFeedback) => row.positiveAmount,
        },
        {
            name: GroupCleanerFeedback.Columns.neutralAmount,
            title: "Neutral",
            getCellValue: (row: GroupCleanerFeedback) => row.neutralAmount,
        },
        {
            name: GroupCleanerFeedback.Columns.negativeAmount,
            title: "Negativ",
            getCellValue: (row: GroupCleanerFeedback) => row.negativeAmount,
        },
        {
            name: GroupCleanerFeedback.Columns.veryNegativeAmount,
            title: "Sehr Negativ",
            getCellValue: (row: GroupCleanerFeedback) => row.veryNegativeAmount,
        },
        {
            name: GroupCleanerFeedback.Columns.createdTimestamp,
            title: "Datum",
            getCellValue: (row: GroupCleanerFeedback) => formatUnixSecToDate(row.createdTimestamp),
        },
        {
            name: GroupCleanerFeedback.Columns.createdTimestampTime,
            title: "Zeitpunkt",
            getCellValue: (row: GroupCleanerFeedback) => formatUnixSecToTime(row.createdTimestamp),
        },
        {
            name: GroupCleanerFeedback.Columns.groupId,
            title: "Einheit",
            getCellValue: (row: GroupCleanerFeedback) => groups.find(x=>x.id == row.groupId)?.notificationName,
        },

    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 180 } }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Servicekraftfeedback');
        });
    };

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
          //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll selectByRowClick />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
