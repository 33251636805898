import { GroupServiceModeIncidentImage, GroupServiceModeIncidentPhoto } from "./GroupServiceMode";

export enum StockActionDoneFromType {
    PortalObjectSettings = 0,
    PortalGroupSettings = 1,
    ServiceworkerQM = 2,
    ServiceWorkerService = 3,
}

export enum StockActionType
{
    TakeFromObject = 0,
    PutBackToObject = 1,
    TakeFromGroup = 2,
    ManualModifyGroup = 3,
    ManualModifyObject = 4,
    ManualModifyObjectAndGroup = 5,

    NoAction = 255,
}

export class UserStock {
    public id!: string;
    public systemETag!: string;
    public systemTimestamp!: string;

    public userId !: string;
    public objectId !: string;
    public groupId !: string;
    public userServiceWorkerId !: string;
    public stockActionDoneFromType !: StockActionDoneFromType
    public reason !: string;
    public materialStocks: MaterialStock[] = [];
    public restockObjectCount !: number;
    public restockGroupCount !: number;
    public createdTimestamp !: Date;

    constructor(userId: string, objectId: string, groupId: string, userServiceWorkerId: string,
        stockActionDoneFromType: StockActionDoneFromType, reason: string, materialStocks: MaterialStock[], restockObjectCount: number, restockGroupCount: number) {
        this.userId = userId;
        this.objectId = objectId;
        this.groupId = groupId;
        this.userServiceWorkerId = userServiceWorkerId;
        this.stockActionDoneFromType = stockActionDoneFromType;
        this.reason = reason;
        this.materialStocks = materialStocks;
        this.restockGroupCount = restockGroupCount;
        this.restockObjectCount = restockObjectCount;
    }

    public static setLastStockObjectValues(newUserStock: UserStock, oldUserStock: UserStock) {
        newUserStock?.materialStocks?.forEach(newMaterial => {
            let oldMaterial = oldUserStock?.materialStocks?.find(x => x.materialId == newMaterial.materialId);
            if (oldMaterial) {
                newMaterial.lastObjectStockAmount = oldMaterial.currentObjectStockAmount;
                newMaterial.resetCountObject = oldMaterial.resetCountObject + 1;
            }
            else {
                newMaterial.lastObjectStockAmount = 0;
                newMaterial.resetCountObject = 0;
            }
            newMaterial.lastGroupStockAmount = 0;

        })
        return newUserStock;
    }

    public static setLastStockGroupValues(newUserStock: UserStock, oldUserStock: UserStock) {
        newUserStock?.materialStocks?.forEach(newMaterial => {
            let oldMaterial = oldUserStock?.materialStocks?.find(x => x.materialId == newMaterial.materialId);
            if (oldMaterial) {
                newMaterial.lastGroupStockAmount = oldMaterial.currentGroupStockAmount;
                newMaterial.resetCountGroup = oldMaterial.resetCountGroup + 1;
            }
            else {
                newMaterial.lastGroupStockAmount = 0;
                newMaterial.resetCountGroup = 0;
            }
            newMaterial.lastObjectStockAmount = 0;

        })
        return newUserStock;
    }

    static Columns = {
        objectId: 'objectId',
        userId: "userId",
        groupId: "groupId",
        createdTimestamp: "createdTimestamp",
        createdTimestampOnlyTime: "createdTimestampOnlyTime",
        userServiceWorkerId: "userServiceWorkerId",
        reason: "reason",
        restockObjectCount: "restockObjectCount",
        restockGroupCount: "restockGroupCount",
        stockActionDoneFromType: "stockActionDoneFromType",


        materials: "materials",

    }
    static GetLabelByStockActionDoneFromType = (type: StockActionDoneFromType) => UserStock.AvailableStockActionDoneFromTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
    static AvailableStockActionDoneFromTypes = [

        { value: StockActionDoneFromType.PortalGroupSettings, label: 'Einheit Lagerung / Portal Einheit Settings' },
        { value: StockActionDoneFromType.PortalObjectSettings, label: 'Objekt Lagerung / Portal Objekt Settings' },
        { value: StockActionDoneFromType.ServiceWorkerService, label: 'Einheit Lagerung / App Service' },
        { value: StockActionDoneFromType.ServiceworkerQM, label: 'Einheit und Objekt Lagerung / App Qualitätssicherung' },
    ];

}
export class MaterialStock {
    materialId !: string;
    lastObjectStockAmount !: number;
    currentObjectStockAmount !: number;
    resetCountObject !: number;
    currentGroupStockAmount !: number;
    lastGroupStockAmount !: number;
    resetCountGroup !: number;
    specificReason !: string;
    stockActionType !: StockActionType;
    minimumGroupAmount !: number;
    maximumGroupAmount !: number;

    minimumObjectAmount !: number;
    maximumObjectAmount !: number;
    importanceLevelObject !: ImportanceLevel;


    alarmEmailObject !: string;
    alarmEmailGroup !: string;
    isEnabledForGroup : boolean = false;
    public objectImages : GroupServiceModeIncidentImage[] = [];
    public groupImages : GroupServiceModeIncidentImage[] = [];


    public objectPhotos : GroupServiceModeIncidentPhoto[] = [];
    public groupPhotos : GroupServiceModeIncidentPhoto[] = [];
    constructor() {
    }

    public static CreateInstanceStockObject(materialId: string, lastObjectStockAmount: number, currentObjectStockAmount: number,
        resetCountObject: number, specficReason: string, stockActionType: StockActionType, minimumObjectAmount: number, maximumObjectAmount: number ,alarmEmailObject: string, importantLevelObject : ImportanceLevel) {
        let newObjectStock = new MaterialStock();
        newObjectStock.materialId = materialId;
        newObjectStock.lastObjectStockAmount = lastObjectStockAmount;
        newObjectStock.currentObjectStockAmount = currentObjectStockAmount;
        newObjectStock.resetCountObject = resetCountObject;
        newObjectStock.lastGroupStockAmount = 0;
        newObjectStock.specificReason = specficReason;
        newObjectStock.stockActionType = stockActionType;
        newObjectStock.minimumObjectAmount = minimumObjectAmount;
        newObjectStock.maximumObjectAmount = maximumObjectAmount;
        newObjectStock.alarmEmailObject = alarmEmailObject;
        newObjectStock.importanceLevelObject = importantLevelObject;
        return newObjectStock
    }

    public static CreateInstanceStockGroup(materialId: string, lastGroupStockAmount: number, currentGroupStockAmount: number,
        resetCountGroup: number, specficReason: string, stockActionType: StockActionType, minimumGroupAmount: number, maximumGroupAmount: number ,alarmEmailGroup: string) {
        
            let newGroupStock = new MaterialStock();
            newGroupStock.materialId = materialId;
            newGroupStock.lastGroupStockAmount = lastGroupStockAmount;
            newGroupStock.currentGroupStockAmount = currentGroupStockAmount;
            newGroupStock.resetCountGroup = resetCountGroup;
            newGroupStock.lastObjectStockAmount = 0;
            newGroupStock.specificReason = specficReason;
            newGroupStock.stockActionType = stockActionType;
            newGroupStock.minimumGroupAmount = minimumGroupAmount;
            newGroupStock.maximumGroupAmount = maximumGroupAmount;
            newGroupStock.alarmEmailGroup = alarmEmailGroup;
            newGroupStock.isEnabledForGroup = true;
            return newGroupStock;
    }

    static GetLabelByStockActionType = (type: StockActionType) => MaterialStock.AvailableStockActionTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
    static AvailableStockActionTypes = [
        { value: StockActionType.ManualModifyObjectAndGroup, label: 'Manuelle Einheit und Objekt Lagerung' },
        { value: StockActionType.ManualModifyGroup, label: 'Manuelle Einheit Lagerung' },
        { value: StockActionType.ManualModifyObject, label: 'Manuelle Objekt Lagerung' },
        { value: StockActionType.TakeFromObject, label: 'Objekt entnahme / Qualitätssicherung' },
        { value: StockActionType.TakeFromGroup, label: 'Einheit entnahme / Service' },
        { value: StockActionType.NoAction, label: 'NoAction' },
    ];
}

export enum ImportanceLevel {
    Default = 0,
    Important = 1,
    VeryImportant = 2
}

export class MaterialStockSummaryTableEntry {
    materialId !: string;
    materialText !: string;
    lastStockDate !: Date;
    stockSum !: number;
    objectStock !: number;
    minimumObjectStock !: number;
    maximumObjectStock !: number;

    importanceLevelObject !: ImportanceLevel;

    objectId !: string;

    groups !: MaterialStockGroupEntry[];

    article !: string;
    entity !: string;
    objectStockDifference !: number;
    objectStockSumOutput !: number;
    objectStockSumInput !: number;


    static Columns = {
        objectId: 'objectId',
        userId: "userId",
        groupId: "groupId",
        createdTimestamp: "createdTimestamp",
        materialText: "materialText",
        stockSum: "stockSum",
        objectStock: "objectStock",
        minimumObjectStock: "minimumObjectStock",

        groupStock: "groupStock",


        materials: "materials",
        article: "article",
        entity : "entity",
        objectStockDifference : "objectStockDifference",
        objectStockOutLast : "objectStockOutLast",
        objectStockInLast : "objectStockInLast",
        objectStockOut : "objectStockOut",
        objectStockIn : "objectStockIn",
        maximumObjectStock : "maximumObjectStock"


    }

}

export class MaterialStockGroupEntry {
    groupId !: string;
    stockAmount !: number;
    minimumStockAmount !: number;
    
}