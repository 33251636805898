import axios from 'axios';
import { headers } from '../api';
import { GROUP_ID } from '../constants';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupSettings } from '../models/GroupSettings';

export const downloadGroupIncidentImage = async (token: string, groupId: string, filename: string): Promise<any> => {

    try {

        const ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/service-mode/photos/${filename}`,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return [];
    }
}

export const downloadGroupQualityCheckImage = async (token: string, groupId: string, filename: string): Promise<any> => {

    try {

        const ret = await axios.get(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/quality-check/photos/${filename}`,
            {
                responseType: 'arraybuffer',
                headers: { ...headers, 'Authorization': `Bearer ${token}` }
            });
        const blob = new Blob([ret.data]);
        const url = URL.createObjectURL(blob);

        return url;

    } catch (err) {
        return [];
    }
}