import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Add, Save, Smartphone, SwitchCamera } from '@mui/icons-material';

export const useAccordionForElementArrayStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 'bold',
        },
    }),
);

export default function AccordionForElementArray({ names, title, listItemIcon=null,onAssign = null, onRemoveAssign = null }: { names: JSX.Element[], title: any, listItemIcon?: any,onAssign?: any, onRemoveAssign?: any }) {
    const classes = useAccordionForElementArrayStyles();

    const getIcon = (index:number) => {
        if (listItemIcon && listItemIcon[index]){
            return listItemIcon[index];
        }
        return (
            <Smartphone />
        )
    }

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container direction="column">
                        <Grid item>
                            <Typography className={classes.heading}>{title}</Typography>
                        </Grid>

                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="column">
                        <Grid item>
                            <List component="nav" aria-label="main mailbox folders">
                                {names && names.length > 0 && names.map((el,index) => {
                                    return (
                                        <ListItemButton key={index}>
                                            {el}
                                        </ListItemButton>
                                    )
                                })}

                            </List>
                        </Grid>
                        {onAssign &&
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size='small'
                                    color="primary"
                                    fullWidth
                                    endIcon={<SwitchCamera />}
                                    onClick={async () => {
                                        onAssign();
                                    }}
                                >
                                    Neue Zuweisung
                                </Button>
                            </Grid>
                        }
                        {onRemoveAssign &&
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Add />}
                                    onClick={async () => {
                                        onAssign();
                                    }}
                                >
                                    Neue Zuweisung
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}

export const buildCellElementArray = (props: any, names: JSX.Element[], title: any, onAssign: any = null,listItemIcon: any = null, onRemoveAssign: any = null) => {
    const { column, row, value } = props;
    return (
        <Table.Cell {...props}>
            <AccordionForElementArray names={names} title={title} listItemIcon={listItemIcon} onAssign={onAssign} onRemoveAssign={onRemoveAssign} />
        </Table.Cell>
    );
}