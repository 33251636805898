import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import { getApiToken } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { UserAccount } from '../../../models/UserAccount';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Email, NavigateNext, Navigation, Save, VpnKey } from '@mui/icons-material';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { AzureStorageBrowserModel } from '../../../models/AzureStorageBrowserModel';

import SmileHomePng from '../../../assets/image/home/smile_home.png'
import ServiceWorkerHomePng from '../../../assets/image/home/serviceworker_home.png'
import ObjectHomePng from '../../../assets/image/home/object_home.png'
import CustomDashboardHomePng from '../../../assets/image/home/customdashboard_home.png'
import SettingsHomePng from '../../../assets/image/home/settings_home.png'
import AccountHomePng from '../../../assets/image/home/account_home.png'
import NewsHomePng from '../../../assets/image/home/news_home.png'
import ShopHomePng from '../../../assets/image/home/shop_home.png'

import { primaryColor } from '../../../App';
import { lightBlue, lightRed, lightgrey, red } from '../../../styles/colors';
import { GlobalContext } from '../PortalPage';
import { GLOBAL_IS_EVAL_ACCOUNT_VISIBLE, GLOBAL_IS_EVAL_DASHBOARD_VISIBLE, GLOBAL_IS_EVAL_GROUPS_VISIBLE, GLOBAL_IS_EVAL_NEWS_INFO_VISIBLE, GLOBAL_IS_EVAL_OBJECTS_VISIBLE, GLOBAL_IS_EVAL_SERVICE_WORKERS_VISIBLE, GLOBAL_IS_EVAL_SETTINGS_VISIBLE, GLOBAL_IS_EVAL_SHOP_VISIBLE } from '../../../constants';
import { AdminSetting, AdminSettingNewsType } from '../../../models/AdminSetting';
import { useTranslation } from 'react-i18next';


export const HomeDrawerFilter = () => {

    const classes = usePortalAccountStyles();
    const history = useHistory();
    const { t } = useTranslation();


    const { adminSetting }: { adminSetting: AdminSetting } = useContext(GlobalContext);
    const { openOverviewItems,
        setOpenOverviewItems,
        openNewsInfos,
        setOpenNewsInfos,
        openSingleEvalObjectDialog,
        setOpenSingleEvalObjectDialog,
        openEvalMultipleObjectMenu,
        setOpenEvalMultipleObjectMenu,
        openSingleEvalAllObjectMenu,
        setOpenSingleEvalAllObjectMenu,
        openSingleEvalServiceWorkerDialog,
        setOpenSingleEvalServiceWorkerDialog,
        openMultipleEvalServiceWorkerDialog,
        setOpenMultipleEvalServiceWorkerDialog,
        openAllEvalServiceWorkerDialog,
        setOpenAllEvalServiceWorkerDialog,
        openMultipleEvalDialog,
        setOpenMultipleEvalDialog,
        openAllEvalDialog,
        setOpenAllEvalDialog,
        dataEvaluationListOpen,
        setDataEvaluationListOpen,
        dataEvaluationObjectListOpen,
        setDataEvaluationObjectListOpen,
        dataEvaluationServiceWorkerListOpen,
        setDataEvaluationServiceWorkerListOpen,

        openDrawerSettings,
        setOpenDrawerSettings,
        openDrawerAdmin,
        setOpenDrawerAdmin,
        openDrawerCustomDashboard,
        setOpenDrawerCustomDashboard,
        openDrawerShop,
        setOpenDrawerShop,
    }: {
        openOverviewItems: boolean,
        setOpenOverviewItems: any,
        openNewsInfos: boolean,
        setOpenNewsInfos: any,
        openSingleEvalObjectDialog: boolean,
        setOpenSingleEvalObjectDialog: any,
        openEvalMultipleObjectMenu: boolean,
        setOpenEvalMultipleObjectMenu: any,
        openSingleEvalAllObjectMenu: boolean,
        setOpenSingleEvalAllObjectMenu: any,
        openSingleEvalServiceWorkerDialog: boolean,
        setOpenSingleEvalServiceWorkerDialog: any,
        openMultipleEvalServiceWorkerDialog: boolean,
        setOpenMultipleEvalServiceWorkerDialog: any,
        openAllEvalServiceWorkerDialog: boolean,
        setOpenAllEvalServiceWorkerDialog: any,
        openMultipleEvalDialog: boolean,
        setOpenMultipleEvalDialog: any,
        openAllEvalDialog: boolean,
        setOpenAllEvalDialog: any,
        dataEvaluationListOpen: boolean,
        setDataEvaluationListOpen: any,
        dataEvaluationObjectListOpen: boolean,
        setDataEvaluationObjectListOpen: any,
        dataEvaluationServiceWorkerListOpen: boolean,
        setDataEvaluationServiceWorkerListOpen: any,


        openDrawerSettings: boolean,
        setOpenDrawerSettings: any,
        openDrawerAdmin: boolean,
        setOpenDrawerAdmin: any,
        openDrawerCustomDashboard: boolean,
        setOpenDrawerCustomDashboard: any,
        openDrawerShop: boolean,
        setOpenDrawerShop: any,
    } = useContext(GlobalContext);

    const { isEvalGroupsVisible, setIsEvalGroupsVisible,
        isEvalServiceWorkersVisible, setIsEvalServiceWorkersVisible,
        isEvalObjectsVisible, setIsEvalObjectsVisible,
        isEvalDashboardVisible, setIsEvalDashboardVisible
    }: any = useContext(GlobalContext);


    const { isEvalSettingsVisible, setIsEvalSettingsVisible,
        isEvalAccountVisible, setIsEvalAccountVisible,
        isEvalNewsInfoVisible, setIsEvalNewsInfoVisible,
        isEvalShopVisible, setIsEvalShopVisible
    }: any = useContext(GlobalContext);

    const witdhBox = 500;
    const heightBox = 300;

    const heightImage = 200;
    const backgroundColor = lightBlue;
    const backgroundNotSelectedColor = lightgrey;
    const padding = 20;
    return (
        <Paper className={classes.root}>
            <Grid container direction={"column"} justifyContent="center" alignItems={"center"} spacing={3}>
                <Grid item>
                    <Typography style={{fontSize: 42}} component="h3" variant="h3" className={classes.title}>{t("homeDrawerFilter.welcome")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                <Grid item container direction="row" justifyContent={"center"} alignItems="center" spacing={5}>
                    <Grid container direction={"column"} item xs={6} spacing={3} justifyContent={"center"} alignItems="center">
                        <Grid item>
                            <Typography component="h4" variant="h4" className={classes.title}>{t("homeDrawerFilter.eval")}</Typography>
                        </Grid>
                        <Grid container item spacing={3} justifyContent={"center"} alignItems="center">
                            <Grid item lg={6} style={{ maxWidth: witdhBox }}>
                                <Card sx={{ backgroundColor: isEvalGroupsVisible ? backgroundColor : backgroundNotSelectedColor, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            setDataEvaluationListOpen(true);
                                            localStorage.setItem(GLOBAL_IS_EVAL_GROUPS_VISIBLE, JSON.stringify(!isEvalGroupsVisible))
                                            setIsEvalGroupsVisible((prev: boolean) => !prev);
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={SmileHomePng}
                                            alt="green iguana"

                                        />
                                        <CardContent>
                                            <Typography style={{lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                            {t("homeDrawerFilter.units")}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size={"small"}
                                    color="primary"
                                    endIcon={<Navigation />}
                                    onClick={async () => {
                                        setDataEvaluationListOpen(true);
                                        setOpenAllEvalDialog(true);
                                        setIsEvalGroupsVisible(true);
                                        localStorage.setItem(GLOBAL_IS_EVAL_GROUPS_VISIBLE, JSON.stringify(true))
                                        history.push({
                                            pathname: '/portal/users/services/sum',
                                        });
                                    }}
                                >
                                    Standard Cockpit
                                </Button>
                            </Grid>
                            <Grid item lg={6} style={{ maxWidth: witdhBox }}>
                                <Card sx={{ backgroundColor: isEvalServiceWorkersVisible ? backgroundColor : backgroundNotSelectedColor, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            setDataEvaluationServiceWorkerListOpen(true);
                                            localStorage.setItem(GLOBAL_IS_EVAL_SERVICE_WORKERS_VISIBLE, JSON.stringify(!isEvalServiceWorkersVisible))
                                            setIsEvalServiceWorkersVisible((prev: boolean) => !prev);
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={ServiceWorkerHomePng}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography style={{lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                            {t("homeDrawerFilter.serviceworkers")}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size={"small"}
                                    color="primary"
                                    endIcon={<Navigation />}
                                    onClick={async () => {
                                        setDataEvaluationServiceWorkerListOpen(true);
                                        setOpenAllEvalServiceWorkerDialog(true);
                                        setIsEvalServiceWorkersVisible(true);
                                        localStorage.setItem(GLOBAL_IS_EVAL_SERVICE_WORKERS_VISIBLE, JSON.stringify(true))
                                        history.push({
                                            pathname: '/portal/service-worker/all/objects/sessions',
                                        });
                                    }}
                                >
                                    {t("homeDrawerFilter.objectlogins")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3} justifyContent={"center"} alignItems="center">
                            <Grid item lg={6} style={{ maxWidth: witdhBox }}>
                                <Card sx={{ backgroundColor: isEvalObjectsVisible ? backgroundColor : backgroundNotSelectedColor, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            setDataEvaluationObjectListOpen(true);
                                            localStorage.setItem(GLOBAL_IS_EVAL_OBJECTS_VISIBLE, JSON.stringify(!isEvalObjectsVisible))
                                            setIsEvalObjectsVisible((prev: boolean) => !prev);
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={ObjectHomePng}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography style={{lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                                {t("homeDrawerFilter.objects")}
                                           </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size={"small"}
                                    color="primary"
                                    endIcon={<Navigation />}
                                    onClick={async () => {
                                        setOpenSingleEvalAllObjectMenu(true);
                                        setDataEvaluationObjectListOpen(true);
                                        setIsEvalObjectsVisible(true);
                                        localStorage.setItem(GLOBAL_IS_EVAL_OBJECTS_VISIBLE, JSON.stringify(true))
                                        history.push({
                                            pathname: '/portal/object/all/service-worker/sessions',
                                        });
                                    }}
                                >
                                    {t("homeDrawerFilter.loginsinobject")}
                                </Button>
                            </Grid>
                            <Grid item lg={6} style={{ maxWidth: witdhBox }}>
                                <Card sx={{ backgroundColor: isEvalDashboardVisible ? backgroundColor : backgroundNotSelectedColor, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            setOpenDrawerCustomDashboard(true);
                                            localStorage.setItem(GLOBAL_IS_EVAL_DASHBOARD_VISIBLE, JSON.stringify(!isEvalDashboardVisible))
                                            setIsEvalDashboardVisible((prev: boolean) => !prev);
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={CustomDashboardHomePng}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography style={{lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                                {t("homeDrawerFilter.myReportsDashboard")}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size={"small"}
                                    color="primary"
                                    endIcon={<Navigation />}
                                    onClick={async () => {
                                        setIsEvalDashboardVisible(true);
                                        setOpenDrawerCustomDashboard(true);
                                        localStorage.setItem(GLOBAL_IS_EVAL_DASHBOARD_VISIBLE, JSON.stringify(true))
                                        history.push({
                                            pathname: '/portal/dashboard',
                                        });
                                    }}
                                >
                                    {t("homeDrawerFilter.reportmanagement")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={"column"} item xs={6} spacing={3} justifyContent={"center"} alignItems="center">
                        <Grid item>
                            <Typography component="h4" variant="h4" className={classes.title}>System - Management</Typography>
                        </Grid>
                        <Grid container item spacing={3} justifyContent={"center"} alignItems="center">
                            <Grid item lg={6} style={{ maxWidth: witdhBox }}>
                                <Card sx={{ backgroundColor: isEvalSettingsVisible ? backgroundColor : backgroundNotSelectedColor, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            setOpenDrawerSettings(true);
                                            localStorage.setItem(GLOBAL_IS_EVAL_SETTINGS_VISIBLE, JSON.stringify(!isEvalSettingsVisible))
                                            setIsEvalSettingsVisible((prev: boolean) => !prev);
                                        }}
                                    >
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={SettingsHomePng}
                                            alt="green iguana"

                                        />
                                        <CardContent>
                                            <Typography style={{lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                                {t("homeDrawerFilter.settings")}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size={"small"}
                                    color="primary"
                                    endIcon={<Navigation />}
                                    onClick={async () => {
                                        setOpenDrawerSettings(true);
                                        setIsEvalSettingsVisible(true);
                                        localStorage.setItem(GLOBAL_IS_EVAL_SETTINGS_VISIBLE, JSON.stringify(true))
                                        history.push({
                                            pathname: '/portal/eingabehilfeapp',
                                        });
                                    }}
                                >
                                    {t("homeDrawerFilter.management")}
                                </Button>
                            </Grid>
                            <Grid item lg={6} style={{ maxWidth: witdhBox }}>
                                <Card sx={{ backgroundColor: isEvalAccountVisible ? backgroundColor : backgroundNotSelectedColor, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            setOpenOverviewItems(true);
                                            localStorage.setItem(GLOBAL_IS_EVAL_ACCOUNT_VISIBLE, JSON.stringify(!isEvalAccountVisible))
                                            setIsEvalAccountVisible((prev: boolean) => true);
                                            history.push({
                                                pathname: '/portal/licenses',
                                            });
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={ServiceWorkerHomePng}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography style={{lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                                {t("homeDrawerFilter.account")}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size={"small"}
                                    color="primary"
                                    endIcon={<Navigation />}
                                    onClick={async () => {
                                        setOpenOverviewItems(true);
                                        setIsEvalAccountVisible(true);
                                        localStorage.setItem(GLOBAL_IS_EVAL_ACCOUNT_VISIBLE, JSON.stringify(true))
                                        history.push({
                                            pathname: '/portal/licenses',
                                        });
                                    }}
                                >
                                    {t("homeDrawerFilter.licensemanagement")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3} justifyContent={"center"} alignItems="center">
                            <Grid item lg={6} style={{ maxWidth: witdhBox }}>
                                {adminSetting && adminSetting.adminSettingNewsType == AdminSettingNewsType.Important ?
                                    <Card sx={{ backgroundColor: red, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                        <CardActionArea style={{ padding: padding }}
                                            onClick={() => {
                                                setOpenNewsInfos(true);
                                                setIsEvalNewsInfoVisible(true);
                                                localStorage.setItem(GLOBAL_IS_EVAL_NEWS_INFO_VISIBLE, JSON.stringify(true))
                                                history.push({
                                                    pathname: '/portal/news',
                                                });
                                            }}>
                                            <CardMedia
                                                style={{ objectFit: 'contain' }}
                                                component="img"
                                                height={heightImage}
                                                image={NewsHomePng}
                                                alt="green iguana"
                                            />
                                            <CardContent>
                                                <Typography style={{color: 'white', lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                                    News & Information
                                               </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card> :
                                    <Card sx={{ backgroundColor: isEvalNewsInfoVisible ? backgroundColor : backgroundNotSelectedColor, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                        <CardActionArea style={{ padding: padding }}
                                            onClick={() => {
                                                setOpenNewsInfos(true);
                                                localStorage.setItem(GLOBAL_IS_EVAL_NEWS_INFO_VISIBLE, JSON.stringify(!isEvalNewsInfoVisible))
                                                setIsEvalNewsInfoVisible((prev: boolean) => !prev);
                                            }}>
                                            <CardMedia
                                                style={{ objectFit: 'contain' }}
                                                component="img"
                                                height={heightImage}
                                                image={NewsHomePng}
                                                alt="green iguana"
                                            />
                                            <CardContent>
                                                <Typography style={{lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                                    News & Information
                                               </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                }
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size={"small"}
                                    color="primary"
                                    endIcon={<Navigation />}
                                    onClick={async () => {
                                        setOpenNewsInfos(true);
                                        setIsEvalNewsInfoVisible(true);
                                        localStorage.setItem(GLOBAL_IS_EVAL_NEWS_INFO_VISIBLE, JSON.stringify(true))
                                        history.push({
                                            pathname: '/portal/news',
                                        });
                                    }}
                                >
                                    News
                                </Button>
                            </Grid>
                            <Grid item lg={6} style={{ maxWidth: witdhBox }}>
                                <Card sx={{ backgroundColor: isEvalShopVisible ? backgroundColor : backgroundNotSelectedColor, maxWidth: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            setOpenDrawerShop(true);
                                            localStorage.setItem(GLOBAL_IS_EVAL_SHOP_VISIBLE, JSON.stringify(!isEvalShopVisible))
                                            setIsEvalShopVisible((prev: boolean) => !prev);
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={ShopHomePng}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography style={{lineHeight: 1}} variant="h6" component="div" fontWeight={'bold'} fontSize={28}>
                                                Shop
                                           </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size={"small"}
                                    color="primary"
                                    endIcon={<Navigation />}
                                    onClick={async () => {
                                        setOpenDrawerShop(true);
                                        setIsEvalShopVisible(true);
                                        localStorage.setItem(GLOBAL_IS_EVAL_SHOP_VISIBLE, JSON.stringify(true))
                                        history.push({
                                            pathname: '/portal/shop',
                                        });
                                    }}
                                >
                                    Shop
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    );
}