import { CleaningOperation, GroupSettingsIncidenReport, MaterialConsumption, SensorControl, Sensor, SpecificTimeDeviation, User, FeedbackQuestion } from "../models/GroupSettings";
import { formatToTime } from "./date-management";

export const GroupSettingsMappingKeys = {
    notificationTelegramGroups: 'notificationTelegramGroups',
    notificationTelegramPhoneNumber: 'notificationTelegramPhoneNumber',
    feedbackControlNotificationEnabled: 'feedbackControlNotificationEnabled',
    isLightSmileNegativCountSame: 'isLightSmileNegativCountSame',
    isLightSmilePositiveCountSame: 'isLightSmilePositiveCountSame',
    isFeedbackQuestionEnabled: 'isFeedbackQuestionEnabled',
    isFeedbackQuestionPositiveEnabled: 'isFeedbackQuestionPositiveEnabled',

    isFeedbackCustomTextEnabled: 'isFeedbackCustomTextEnabled',
    isFeedbackOnlyCustomTextEnabled: 'isFeedbackOnlyCustomTextEnabled',
    isFeedbackCustomTextPositiveEnabled: 'isFeedbackCustomTextPositiveEnabled',

    isCleanerFeedbackEnabled: 'isCleanerFeedbackEnabled',
    feedbackCleanerDisplayType: 'feedbackCleanerDisplayType',
    feedbackDisplayType: 'feedbackDisplayType',

    isDeviceRestartEnabled: 'isDeviceRestartEnabled',
    isNavigationBarEnabled: 'isNavigationBarEnabled',
    feedbackControlAmountTriggerVeryGood: 'feedbackControlAmountTriggerVeryGood',
    qualityCheckAutoEmail: 'qualityCheckAutoEmail',
    isObjectSignInRfidEnabled: 'isObjectSignInRfidEnabled',
    isExecuteManuellOfflineEnabled: 'isExecuteManuellOfflineEnabled',
    isExecuteOfflineDataNowEnabled: 'isExecuteOfflineDataNowEnabled',
    isUploadOfflineDataEnabled: 'isUploadOfflineDataEnabled',
    isPublicFeedbackEnabled: 'isPublicFeedbackEnabled',

    isPublicFeedbackRestartEnabled: 'isPublicFeedbackRestartEnabled',
    isEnabledFeedbackQuestionVeryNegative: 'isEnabledFeedbackQuestionVeryNegative',
    row: 'row'
}

export const mapGroupSettings = (row: any, key: any, value: any) => {

switch (key) {
        case 'row':
            return { ...row };
        case GroupSettingsMappingKeys.isDeviceRestartEnabled:
            return { ...row, isDeviceRestartEnabled: JSON.parse(value) };
        case GroupSettingsMappingKeys.isNavigationBarEnabled:
            return { ...row, locationInformation: { ...row.locationInformation, isNavigationBarEnabled: JSON.parse(value) } }
        case GroupSettingsMappingKeys.isExecuteManuellOfflineEnabled:
            return { ...row, locationInformation: { ...row.locationInformation, isExecuteManuellOfflineEnabled: JSON.parse(value) } }
        case GroupSettingsMappingKeys.isUploadOfflineDataEnabled:
            return { ...row, locationInformation: { ...row.locationInformation, isUploadOfflineDataEnabled: JSON.parse(value) } }
        case GroupSettingsMappingKeys.isExecuteOfflineDataNowEnabled:
            return { ...row, locationInformation: { ...row.locationInformation, isExecuteOfflineDataNowEnabled: JSON.parse(value) } }
        case 'name':
            return { ...row, name: value }
        case 'isAutoApkInstallEnabled':
            return { ...row, locationInformation: { ...row.locationInformation, isAutoApkInstallEnabled: JSON.parse(value) } };
        case 'objectName':
            return { ...row, locationInformation: { ...row.locationInformation, objectName: value } }
        case 'levelName':
            return { ...row, locationInformation: { ...row.locationInformation, levelName: value } }
        case 'roomName':
            return { ...row, locationInformation: { ...row.locationInformation, roomName: value } }
        case 'notificationName':
            return { ...row, locationInformation: { ...row.locationInformation, notificationName: value } };
        case 'toiletType':
            //console.log(value, "toilet");
            return { ...row, locationInformation: { ...row.locationInformation, toiletType: JSON.parse(value) } };
        case 'locationPassword':
            return { ...row, locationInformation: { ...row.locationInformation, password: value } };
        case 'localPassword':
            return { ...row, locationInformation: { ...row.locationInformation, localPassword: value } };
        case 'deviceRestartTime':
            return { ...row, locationInformation: { ...row.locationInformation, deviceRestartTime: formatToTime(new Date(value)) } };
        case 'isDeviceRestartTimeEnabled':
            return { ...row, locationInformation: { ...row.locationInformation, isDeviceRestartTimeEnabled: JSON.parse(value) } };
        case 'isLoginQrCodeEnabled':
            return { ...row, locationInformation: { ...row.locationInformation, isLoginQrCodeEnabled: JSON.parse(value) } };
        case 'cleaningOperations':
            let { val, index, property } = JSON.parse(value);
            let newVal = { [property]: val };
            return ({ ...row, cleaningOperations: { cleaningOperations: row.cleaningOperations?.cleaningOperations.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
        case 'cleaningOperationsAdd':
            return ({ ...row, cleaningOperations: { cleaningOperations: [...row.cleaningOperations?.cleaningOperations, new CleaningOperation(value, true)] } })
        case 'cleaningOperationsAddMultipleOld':
            let newValsCleaningOp = value.map((el: any) => {
                return new CleaningOperation(el, true);
            })
            return ({ ...row, cleaningOperations: { cleaningOperations: [...row.cleaningOperations?.cleaningOperations, ...newValsCleaningOp] } })
        case 'cleaningOperationsAddMultiple':
            return ({ ...row, cleaningOperations: { cleaningOperations: [...row.cleaningOperations?.cleaningOperations, ...value] } })
        case 'cleaningOperationsRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.cleaningOperations?.cleaningOperations.splice(index, 1);
                console.log(row.cleaningOperations, index, "meinTest");
                return ({ ...row })
            })();
        case 'serviceWorkingModeType':
            return { ...row, cleaningOperations: { ...row.cleaningOperations, serviceWorkingModeType: JSON.parse(value) } };
        //-------------------------------------- Notifications --------------------------------------------------
        case 'isMissedServiceMotionNotificationEnabled':
            return { ...row, notification: { ...row.notification, isMissedServiceMotionNotificationEnabled: JSON.parse(value) } };
        case 'isMissedServiceFeedbackNotificationEnabled':
            return { ...row, notification: { ...row.notification, isMissedServiceFeedbackNotificationEnabled: JSON.parse(value) } };
        case 'isMissedServiceIntervalNotificationEnabled':
            return { ...row, notification: { ...row.notification, isMissedServiceIntervalNotificationEnabled: JSON.parse(value) } };
        case 'isMotionNotificationEnabled':
            return { ...row, notification: { ...row.notification, isMotionNotificationEnabled: JSON.parse(value) } };
        case 'isFeedbackNotificationEnabled':
            return { ...row, notification: { ...row.notification, isFeedbackNotificationEnabled: JSON.parse(value) } };
        case 'isIntervalNotificationEnabled':
            return { ...row, notification: { ...row.notification, isIntervalNotificationEnabled: JSON.parse(value) } };
        case 'isServiceNotificationEnabled':
            return { ...row, notification: { ...row.notification, isServiceNotificationEnabled: JSON.parse(value) } };
        case 'cleaningEmail':
            return { ...row, notification: { ...row.notification, cleaningEmail: value } };
        case 'cleaningEmailIsEnabled':
            return { ...row, notification: { ...row.notification, isCleaningEmailEnabled: JSON.parse(value) } };
        case 'isOnlineStatusEmailEnabled':
            return { ...row, notification: { ...row.notification, isOnlineStatusEmailEnabled: JSON.parse(value) } };
        case 'isOnlineStatusWarnEmailEnabled':
            return { ...row, notification: { ...row.notification, isOnlineStatusWarnEmailEnabled: JSON.parse(value) } };
        case 'onlineStatusEmail':
            return { ...row, notification: { ...row.notification, onlineStatusEmail: value } };
        case 'incidentReportEmail':
            return { ...row, notification: { ...row.notification, incidentReportEmail: value } };
        case 'isIncidentReportEmailEnabled':
            return { ...row, notification: { ...row.notification, isIncidentReportEmailEnabled: JSON.parse(value) } };
        case 'missedServiceEmail':
            return { ...row, notification: { ...row.notification, missedServiceEmail: value } };
        case 'isMissedServiceEmailEnabled':
            return { ...row, notification: { ...row.notification, isMissedServiceEmailEnabled: JSON.parse(value) } };
        case 'freeTextIncidentEmail':
            return { ...row, notification: { ...row.notification, freeTextIncidentEmail: value } };
        case 'isFreeTextIncidentEmailEnabled':
            return { ...row, notification: { ...row.notification, isFreeTextIncidentEmailEnabled: JSON.parse(value) } };
        case 'feedbackQuestionEmail':
            return { ...row, notification: { ...row.notification, feedbackQuestionEmail: value } };
        case 'isFeedbackQuestionEmailEnabled':
            return { ...row, notification: { ...row.notification, isFeedbackQuestionEmailEnabled: JSON.parse(value) } };
        case 'freeTextFeedbackEmail':
            return { ...row, notification: { ...row.notification, freeTextFeedbackEmail: value } };
        case 'isFreeTextFeedbackEmailEnabled':
            return { ...row, notification: { ...row.notification, isFreeTextFeedbackEmailEnabled: JSON.parse(value) } };
        case 'frequencyAutoEmail':
            return { ...row, notification: { ...row.notification, frequencyAutoEmail: value } };
        case 'isFrequencyDailyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isFrequencyDailyAutoEmailEnabled: JSON.parse(value) } };
        case 'isFrequencyWeeklyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isFrequencyWeeklyAutoEmailEnabled: JSON.parse(value) } };
        case 'isFrequencyMonthlyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isFrequencyMonthlyAutoEmailEnabled: JSON.parse(value) } };


        case 'serviceTriggerAutoEmail':
            return { ...row, notification: { ...row.notification, serviceTriggerAutoEmail: value } };
        case 'isServiceTriggerAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isServiceTriggerAutoEmailEnabled: JSON.parse(value) } };
        case 'isServiceTriggerDailyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isServiceTriggerDailyAutoEmailEnabled: JSON.parse(value) } };
        case 'isServiceTriggerWeeklyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isServiceTriggerWeeklyAutoEmailEnabled: JSON.parse(value) } };
        case 'isServiceTriggerMonthlyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isServiceTriggerMonthlyAutoEmailEnabled: JSON.parse(value) } };


        case 'onlineStatusAutoEmail':
            return { ...row, notification: { ...row.notification, onlineStatusAutoEmail: value } };
        case 'isOnlineStatusAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isOnlineStatusAutoEmailEnabled: JSON.parse(value) } };
        case 'isOnlineStatusDailyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isOnlineStatusDailyAutoEmailEnabled: JSON.parse(value) } };
        case 'isOnlineStatusWeeklyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isOnlineStatusWeeklyAutoEmailEnabled: JSON.parse(value) } };
        case 'isOnlineStatusMonthlyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isOnlineStatusMonthlyAutoEmailEnabled: JSON.parse(value) } };


        case 'hitlistAutoEmail':
            return { ...row, notification: { ...row.notification, hitlistAutoEmail: value } };
        case 'isHitlistDailyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isHitlistDailyAutoEmailEnabled: JSON.parse(value) } };
        case 'isHitlistWeeklyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isHitlistWeeklyAutoEmailEnabled: JSON.parse(value) } };
        case 'isHitlistMonthlyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isHitlistMonthlyAutoEmailEnabled: JSON.parse(value) } };

        case 'cockpitAutoEmail':
            return { ...row, notification: { ...row.notification, cockpitAutoEmail: value } };
        case 'isCockpitDailyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isCockpitDailyAutoEmailEnabled: JSON.parse(value) } };
        case 'isCockpitWeeklyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isCockpitWeeklyAutoEmailEnabled: JSON.parse(value) } };
        case 'isCockpitMonthlyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isCockpitMonthlyAutoEmailEnabled: JSON.parse(value) } };


        case 'feebackAutoEmail':
            return { ...row, notification: { ...row.notification, feebackAutoEmail: value } };
        case 'isFeedbackAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isFeedbackAutoEmailEnabled: JSON.parse(value) } };
        case 'isFeedbackDailyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isFeedbackDailyAutoEmailEnabled: JSON.parse(value) } };
        case 'isFeedbackWeeklyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isFeedbackWeeklyAutoEmailEnabled: JSON.parse(value) } };
        case 'isFeedbackMonthlyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isFeedbackMonthlyAutoEmailEnabled: JSON.parse(value) } };
        case 'servicesAutoEmail':
            return { ...row, notification: { ...row.notification, servicesAutoEmail: value } };
        case 'isServicesAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isServicesAutoEmailEnabled: JSON.parse(value) } };
        case 'isServicesDailyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isServicesDailyAutoEmailEnabled: JSON.parse(value) } };
        case 'isServicesWeeklyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isServicesWeeklyAutoEmailEnabled: JSON.parse(value) } };
        case 'isServicesMonthlyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isServicesMonthlyAutoEmailEnabled: JSON.parse(value) } };
        case 'qualityCheckAutoEmail':
            return { ...row, notification: { ...row.notification, qualityCheckAutoEmail: value } };
        case 'isQualityCheckAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isQualityCheckAutoEmailEnabled: JSON.parse(value) } };
        case 'isQualityCheckDailyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isQualityCheckDailyAutoEmailEnabled: JSON.parse(value) } };
        case 'isQualityCheckWeeklyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isQualityCheckWeeklyAutoEmailEnabled: JSON.parse(value) } };
        case 'isQualityCheckMonthlyAutoEmailEnabled':
            return { ...row, notification: { ...row.notification, isQualityCheckMonthlyAutoEmailEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.notificationTelegramPhoneNumber:
            return { ...row, notification: { ...row.notification, notificationTelegramGroup: { ...row.notification?.notificationTelegramGroup, phoneNumber: value } } };
        case 'telegramGroupTitle':
            return { ...row, notification: { ...row.notification, notificationTelegramGroup: { ...row.notification?.notificationTelegramGroup, title: value } } };
        case 'isTelegramGroupEnabled':
            return { ...row, notification: { ...row.notification, notificationTelegramGroup: { ...row.notification?.notificationTelegramGroup, isTelegramGroupEnabled: JSON.parse(value) } } };
        case GroupSettingsMappingKeys.notificationTelegramGroups:
            const { notificationTelegramGroup, systemETag } = JSON.parse(value);

            return { ...row, systemETag: systemETag, notification: { ...row?.notification, notificationTelegramGroup: notificationTelegramGroup } };
        case 'whatsAppGroupTitle':
            return { ...row, notification: { ...row.notification, notificationTelegramGroup: { ...row.notification?.notificationTelegramGroup, title: value } } };
        case 'isWhatsAppGroupEnabled':
            return { ...row, notification: { ...row.notification, notificationTelegramGroup: { ...row.notification?.notificationTelegramGroup, isTelegramGroupEnabled: JSON.parse(value) } } };
        case GroupSettingsMappingKeys.isObjectSignInRfidEnabled:
            return { ...row, users: { ...row.users, isObjectSignInRfidEnabled: JSON.parse(value) } };
        case 'usernames':
            return (() => {
                let { val, index, property } = JSON.parse(value);
                let newVal: any = { [property]: val, changed: true };
                return ({ ...row, users: { ...row.users, users: row.users?.users.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'usernamesAdd':
            return ({ ...row, users: { ...row.users, users: [new User("", "", "", true), ...row.users?.users] } })
        case 'usernamesRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.users?.users.splice(index, 1);
                return ({ ...row, users: { ...row.users, users: [...row.users?.users] } })
            })();
        case 'loraSensorControlIsSensorEnabled':
            return { ...row, loraSensorControl: { ...row.loraSensorControl, isSensorControlEnabled: JSON.parse(value) } };
        case 'loraSensorControlDeviationTime':
            return { ...row, loraSensorControl: { ...row.loraSensorControl, deviationTime: value } };
        case 'sensorControlDeviationTime':
            return { ...row, sensorControl: { ...row.sensorControl, deviationTime: value } };
        case 'sensorControlIsSensorEnabled':
            return { ...row, sensorControl: { ...row.sensorControl, isSensorControlEnabled: JSON.parse(value) } };
        case 'sensorControlAmountTrigger':
            return { ...row, sensorControl: { ...row.sensorControl, amountTrigger: value } };
        case 'sensorControlIsAmountTriggerEnabled':
            return { ...row, sensorControl: { ...row.sensorControl, isAmountTriggerEnabled: JSON.parse(value) } };
        case 'sensorItems':
            return (() => {

                let { val, index, property } = JSON.parse(value);

                let newSensor = { [property]: val };
                return ({ ...row, sensorControl: { ...row.sensorControl, sensors: row.sensorControl?.sensors?.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newSensor } : el) } });
            })();
        case 'loraSensorItems':
            return (() => {

                let { val, index, property } = JSON.parse(value);

                let newSensor = { [property]: val };
                console.log(newSensor, "newSensor");
                return ({ ...row, loraSensorControl: { ...row.loraSensorControl, sensors: row.loraSensorControl?.sensors?.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newSensor } : el) } });
            })();
        case 'sensorItemsObject':
            return (() => {

                let { val, index, property } = JSON.parse(value);

                let newSensor = { [property]: JSON.parse(val) };
                return ({ ...row, sensorControl: { ...row.sensorControl, sensors: row.sensorControl?.sensors?.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newSensor } : el) } });
            })();
        case 'sensorControlAdd':
            return ({ ...row, sensorControl: { sensors: [...row.sensorControl?.sensors, new Sensor()] } });
        case 'feedbackControlDeviationTime':
            return { ...row, feedbacks: { ...row.feedbacks, deviationTime: value } };
        case 'feedbackControlAmountTriggerBad':
            return { ...row, feedbacks: { ...row.feedbacks, amountTriggerBad: value } };
        case 'feedbackControlAmountTriggerVeryBad':
            return { ...row, feedbacks: { ...row.feedbacks, amountTriggerVeryBad: value } };
        case 'feedbackControlAmountTriggerVeryGood':
            return { ...row, feedbacks: { ...row.feedbacks, amountTriggerVeryGood: value } };
        case 'feedbackControlAmountTriggerOk':
            return { ...row, feedbacks: { ...row.feedbacks, amountTriggerOk: value } };
        case 'feedbackControlAmountTriggerGood':
            return { ...row, feedbacks: { ...row.feedbacks, amountTriggerGood: value } };
        case 'feedbackControlFeedbackDisabledDuration':
            return { ...row, feedbacks: { ...row.feedbacks, feedbackDisabledDuration: value } };
        case 'isFeedbackControlEnabled':
            return { ...row, feedbacks: { ...row.feedbacks, isFeedbackControlEnabled: JSON.parse(value) } };
        case 'isLightSmileNegativCountSame':
            return { ...row, feedbacks: { ...row.feedbacks, isLightSmileNegativCountSame: JSON.parse(value) } };
        case 'isLightSmileNegativCountSame':
            return { ...row, feedbacks: { ...row.feedbacks, isLightSmileNegativCountSame: JSON.parse(value) } };
        case 'isLightSmileNegativCountSame':
            return { ...row, feedbacks: { ...row.feedbacks, isLightSmileNegativCountSame: JSON.parse(value) } };
        case 'isLightSmileNegativCountSame':
            return { ...row, feedbacks: { ...row.feedbacks, isLightSmileNegativCountSame: JSON.parse(value) } };
        case 'isLightSmileNegativCountSame':
            return { ...row, feedbacks: { ...row.feedbacks, isLightSmileNegativCountSame: JSON.parse(value) } };
        case 'isLightSmileNegativCountSame':
            return { ...row, feedbacks: { ...row.feedbacks, isLightSmileNegativCountSame: JSON.parse(value) } };
        case 'isLightSmilePositiveCountSame':
            return { ...row, feedbacks: { ...row.feedbacks, isLightSmilePositiveCountSame: JSON.parse(value) } };
        case 'feedbackQuestionsItems':
            return (() => {
                let { val, index, property } = JSON.parse(value);
                let newVal = { [property]: val };
                //console.log(val, index);
                return ({ ...row, feedbacks: { ...row.feedbacks, feedbackQuestions: row.feedbacks?.feedbackQuestions.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'feedbackQuestionsItemsAdd':
            return ({ ...row, feedbacks: { ...row.feedbacks, feedbackQuestions: [...row.feedbacks?.feedbackQuestions, new FeedbackQuestion("", true)] } })
        case 'feedbackQuestionsItemsAddMultiple':
            return ({ ...row, feedbacks: { ...row.feedbacks, feedbackQuestions: [...row.feedbacks?.feedbackQuestions, ...value] } })
        case 'feedbackQuestionsItemsRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.feedbacks?.feedbackQuestions.splice(index, 1);
                return ({ ...row, feedbacks: { ...row.feedbacks, feedbackQuestions: [...row.feedbacks?.feedbackQuestions] } })
            })();
        case 'feedbackQuestionsPositiveItems':
            return (() => {
                let { val, index, property } = JSON.parse(value);
                let newVal = { [property]: val };
                //console.log(val, index);
                return ({ ...row, feedbacks: { ...row.feedbacks, feedbackQuestionsPositive: row.feedbacks?.feedbackQuestionsPositive.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'feedbackQuestionsPositiveItemsAdd':
            return ({ ...row, feedbacks: { ...row.feedbacks, feedbackQuestionsPositive: [...row.feedbacks?.feedbackQuestionsPositive, new FeedbackQuestion("", true)] } })
        case 'feedbackQuestionsPositiveItemsAddMultiple':
            return ({ ...row, feedbacks: { ...row.feedbacks, feedbackQuestionsPositive: [...row.feedbacks?.feedbackQuestionsPositive, ...value] } })
        case 'feedbackQuestionsPositiveItemsRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.feedbacks?.feedbackQuestionsPositive.splice(index, 1);
                return ({ ...row, feedbacks: { ...row.feedbacks, feedbackQuestionsPositive: [...row.feedbacks?.feedbackQuestionsPositive] } })
            })();
        case 'isSumDisplayEnabled':
            return { ...row, feedbacks: { ...row.feedbacks, isSumDisplayEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.feedbackControlNotificationEnabled:
            return { ...row, feedbacks: { ...row.feedbacks, isNotificationEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.isFeedbackQuestionEnabled:
            return { ...row, feedbacks: { ...row.feedbacks, isFeedbackQuestionEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.isFeedbackQuestionPositiveEnabled:
            return { ...row, feedbacks: { ...row.feedbacks, isFeedbackQuestionPositiveEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.isPublicFeedbackEnabled:
            return { ...row, feedbacks: { ...row.feedbacks, isPublicFeedbackEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.isPublicFeedbackRestartEnabled:
            return { ...row, feedbacks: { ...row.feedbacks, isPublicFeedbackRestartEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.isFeedbackCustomTextEnabled:
            return { ...row, feedbacks: { ...row.feedbacks, isFeedbackCustomTextEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.isFeedbackCustomTextPositiveEnabled:
            return { ...row, feedbacks: { ...row.feedbacks, isFeedbackCustomTextPositiveEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.isCleanerFeedbackEnabled:
            return { ...row, feedbacks: { ...row.feedbacks, isCleanerFeedbackEnabled: JSON.parse(value) } };
        case GroupSettingsMappingKeys.feedbackCleanerDisplayType:
            return { ...row, feedbacks: { ...row.feedbacks, feedbackCleanerDisplayType: JSON.parse(value) } };
        case GroupSettingsMappingKeys.feedbackDisplayType:
            return { ...row, feedbacks: { ...row.feedbacks, feedbackDisplayType: JSON.parse(value) } };
        case 'intervalControlIsIntervalEnabled':
            return { ...row, intervalControl: { ...row.intervalControl, isIntervalEnabled: JSON.parse(value) } };
        case 'groupSettingsIntervalType':
            return { ...row, intervalControl: { ...row.intervalControl, type: JSON.parse(value) } };
        case 'intervalControlStartTime':
            let newVal4 = formatToTime(new Date(value));
            return { ...row, intervalControl: { ...row.intervalControl, startTime: newVal4 } };
        case 'intervalControlEndTime':
            let newVal3 = formatToTime(new Date(value));
            return { ...row, intervalControl: { ...row.intervalControl, endTime: newVal3 } };
        case 'intervalControlRepeatDuration':
            let newVal2 = value;
            return { ...row, intervalControl: { ...row.intervalControl, repeatDuration: newVal2 } };
        case 'intervalControlDeviationTime':
            return { ...row, intervalControl: { ...row.intervalControl, deviationTime: value } };
        case 'intervalControlMinimumTime':
            return { ...row, intervalControl: { ...row.intervalControl, minimumTime: value } };
        case 'intervalControlMinimumServiceWorker':
            return { ...row, intervalControl: { ...row.intervalControl, minimumServiceWorker: value } };
        case 'intervalControlSpecificTimes':
            return (() => {
                //console.log(value);
                let { val, index, property } = JSON.parse(value);
                let newVal = { [property]: val };
                if (property == "specificTime") {
                    //console.log(val);
                    newVal = { [property]: formatToTime(new Date(val)) };
                }
                return ({ ...row, intervalControl: { ...row.intervalControl, specificTimes: row.intervalControl?.specificTimes.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'intervalControlSpecificTimesAdd':
            return ({ ...row, intervalControl: { ...row.intervalControl, specificTimes: [...row.intervalControl?.specificTimes, new SpecificTimeDeviation("00:00:00", "00:30","00:00:00", 1, [], false)] } })
        case 'intervalControlSpecificTimesRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.intervalControl?.specificTimes.splice(index, 1);
                return ({ ...row, intervalControl: { ...row.intervalControl, specificTimes: [...row.intervalControl?.specificTimes] } })
            })();
        case 'isIntervalControlEnabled':
            return { ...row, intervalControl: { ...row.intervalControl, isIntervalControlEnabled: JSON.parse(value) } };
        case 'isMaterialConsumptionEnabled':
            return { ...row, materialConsumptions: { ...row.materialConsumptions, isMaterialConsumptionEnabled: JSON.parse(value) } };
        case 'daysOfWeekEnabled':
            return { ...row, intervalControl: { ...row.intervalControl, daysOfWeekEnabled: JSON.parse(value) } };
        case 'materialConsumptionsItems':
            return (() => {
                let { val, index, property } = JSON.parse(value);
                let newVal = { [property]: val };
                //console.log(val, index);
                return ({ ...row, materialConsumptions: { ...row.materialConsumptions, materialConsumptions: row.materialConsumptions?.materialConsumptions.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'materialConsumptionsItemsAdd':
            return ({ ...row, materialConsumptions: { ...row.materialConsumptions, materialConsumptions: [...row.materialConsumptions?.materialConsumptions, new MaterialConsumption("", true)] } })
        case 'materialConsumptionsItemsAddMultipleOld':
            let newValsMaterials = value.map((el: any) => {
                return new MaterialConsumption(el, true);
            })
            return ({ ...row, materialConsumptions: { ...row.materialConsumptions, materialConsumptions: [...row.materialConsumptions?.materialConsumptions, ...newValsMaterials] } })

        case 'materialConsumptionsItemsAddMultiple':
            return ({ ...row, materialConsumptions: { ...row.materialConsumptions, materialConsumptions: [...row.materialConsumptions?.materialConsumptions, ...value] } })
        case 'materialConsumptionsItemsRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.materialConsumptions?.materialConsumptions.splice(index, 1);
                return ({ ...row, materialConsumptions: { ...row.materialConsumptions, materialConsumptions: [...row.materialConsumptions?.materialConsumptions] } })
            })();
        case 'isIncidentReportEnabled':
            return { ...row, incidentReports: { ...row.incidentReports, isIncidentReportEnabled: JSON.parse(value) } };
        case 'isIncidentVisitorCustomTextEnabled':
            return { ...row, incidentReports: { ...row.incidentReports, isIncidentVisitorCustomTextEnabled: JSON.parse(value) } };
        case 'isIncidentVisitorWithNameEnabled':
            return { ...row, incidentReports: { ...row.incidentReports, isIncidentVisitorWithNameEnabled: JSON.parse(value) } };
        case 'isIncidentVisitorEnabled':
            return { ...row, incidentReports: { ...row.incidentReports, isIncidentVisitorEnabled: JSON.parse(value) } };
        case 'incidentReportItems':
            return (() => {
                let { val, index, property } = JSON.parse(value);
                let newVal = { [property]: val };
                //console.log(val, index);
                return ({ ...row, incidentReports: { ...row.incidentReports, incidentReports: row.incidentReports?.incidentReports.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'incidentReportItemsAdd':
            return ({ ...row, incidentReports: { ...row.incidentReports, incidentReports: [...row.incidentReports?.incidentReports, new GroupSettingsIncidenReport("", true)] } })
        case 'incidentReportItemsAddMultipleOld':
            let newValsIncidents = value.map((el: any) => {
                return new GroupSettingsIncidenReport(el, true);
            })
            return ({ ...row, incidentReports: { ...row.incidentReports, incidentReports: [...row.incidentReports?.incidentReports, ...newValsIncidents] } })

        case 'incidentReportItemsAddMultiple':
            return ({ ...row, incidentReports: { ...row.incidentReports, incidentReports: [...row.incidentReports?.incidentReports, ...value] } })
        case 'incidentReportItemsRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.incidentReports?.incidentReports.splice(index, 1);
                return ({ ...row, incidentReports: { ...row.incidentReports, incidentReports: [...row.incidentReports?.incidentReports] } })
            })();


        case 'incidentNameItems':
            return (() => {
                let { val, index, property } = JSON.parse(value);
                let newVal = { [property]: val };
                //console.log(val, index);
                return ({ ...row, incidentReports: { ...row.incidentReports, incidentNames: row.incidentReports?.incidentNames.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'incidentNameItemsAdd':
            return ({ ...row, incidentReports: { ...row.incidentReports, incidentNames: [...row.incidentReports?.incidentNames, new GroupSettingsIncidenReport("", true)] } })
        case 'incidentNameItemsAddMultipleOld':
            let newValsIncidentNames = value.map((el: any) => {
                return new GroupSettingsIncidenReport(el, true);
            })
            return ({ ...row, incidentReports: { ...row.incidentReports, incidentNames: [...row.incidentReports?.incidentNames, ...newValsIncidentNames] } })

        case 'incidentNameItemsAddMultiple':
            return ({ ...row, incidentReports: { ...row.incidentReports, incidentNames: [...row.incidentReports?.incidentNames, ...value] } })
        case 'incidentNameItemsRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.incidentReports?.incidentReports.splice(index, 1);
                return ({ ...row, incidentReports: { ...row.incidentReports, incidentNames: [...row.incidentReports?.incidentNames] } })
            })();





        case 'isPayPerServiceEnabled':
            return { ...row, payPerServiceControl: { ...row.payPerServiceControl, isPayPerServiceEnabled: JSON.parse(value) } };
        case 'isPayPerServiceCustomTextEnabled':
            return { ...row, payPerServiceControl: { ...row.payPerServiceControl, isPayPerServiceCustomTextEnabled: JSON.parse(value) } };
        case 'isPayPerServiceWithNameEnabled':
            return { ...row, payPerServiceControl: { ...row.payPerServiceControl, isPayPerServiceWithNameEnabled: JSON.parse(value) } };
        case 'isPayPerServiceEnabled':
            return { ...row, payPerServiceControl: { ...row.payPerServiceControl, isPayPerServiceEnabled: JSON.parse(value) } };
        case 'payPerServiceItems':
            return (() => {
                let { val, index, property } = JSON.parse(value);
                let newVal = { [property]: val };
                //console.log(val, index);
                return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItems: row.payPerServiceControl?.payPerServiceItems.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'payPerServiceItemsAdd':
            console.log(row.payPerServiceControl, "payperservice");
            return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItems: [...row.payPerServiceControl?.payPerServiceItems, new GroupSettingsIncidenReport("", true)] } })
        case 'payPerServiceItemsAddMultipleOld':
            let newValsPayPerService = value.map((el: any) => {
                return new GroupSettingsIncidenReport(el, true);
            })
            return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItems: [...row.payPerServiceControl?.payPerServiceItems, ...newValsPayPerService] } })

        case 'payPerServiceItemsAddMultiple':
            return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItems: [...row.payPerServiceControl?.payPerServiceItems, ...value] } })
        case 'payPerServiceItemsRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.payPerServiceControl?.payPerServiceItems.splice(index, 1);
                return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItems: [...row.payPerServiceControl?.payPerServiceItems] } })
            })();
        case 'payPerServiceItemName':
            return (() => {
                let { val, index, property } = JSON.parse(value);
                let newVal = { [property]: val };
                //console.log(val, index);
                return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItemNames: row.payPerServiceControl?.payPerServiceItemNames.map((el: any, currentIndex: any) => currentIndex === index ? { ...el, ...newVal } : el) } });
            })();
        case 'payPerServiceItemNameAdd':
            return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItemNames: [...row.payPerServiceControl?.payPerServiceItemNames, new GroupSettingsIncidenReport("", true)] } })
        case 'payPerServiceItemNameAddMultipleOld':
            let newValsPayPerServiceNames = value.map((el: any) => {
                return new GroupSettingsIncidenReport(el, true);
            })
            return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItemNames: [...row.payPerServiceControl?.payPerServiceItemNames, ...newValsPayPerServiceNames] } })

        case 'payPerServiceItemNameAddMultiple':
            return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItemNames: [...row.payPerServiceControl?.payPerServiceItemNames, ...value] } })
        case 'payPerServiceItemNameRemove':
            return (() => {
                let { index } = JSON.parse(value);
                row.payPerServiceControl?.payPerServiceItemNames.splice(index, 1);
                return ({ ...row, payPerServiceControl: { ...row.payPerServiceControl, payPerServiceItemNames: [...row.payPerServiceControl?.payPerServiceItemNames] } })
            })();




        case 'contentIsContentEnabled':
            return { ...row, content: { ...row.content, isContentEnabled: JSON.parse(value) } };
        case 'isEasyLoginEnabled':
            return { ...row, content: { ...row.content, isEasyLoginEnabled: JSON.parse(value) } };
        case 'isRfidExtraLoginEnabled':
            return { ...row, content: { ...row.content, isRfidExtraLoginEnabled: JSON.parse(value) } };
        case 'isServiceListOnWaitingScreenEnabled':
            return { ...row, content: { ...row.content, isServiceListOnWaitingScreenEnabled: JSON.parse(value) } };
        case 'isFeedbackQuestionListOnWaitingScreenEnabled':
            return { ...row, content: { ...row.content, isFeedbackQuestionListOnWaitingScreenEnabled: JSON.parse(value) } };
        case 'isIncidentVisitorListOnWaitingScreenEnabled':
            return { ...row, content: { ...row.content, isIncidentVisitorListOnWaitingScreenEnabled: JSON.parse(value) } };
        case 'isFeedbackQuestionListOnServiceModeEnabled':
            return { ...row, content: { ...row.content, isFeedbackQuestionListOnServiceModeEnabled: JSON.parse(value) } };
        case 'isIncidentVisitorListOnServiceModeEnabled':
            return { ...row, content: { ...row.content, isIncidentVisitorListOnServiceModeEnabled: JSON.parse(value) } };
        case 'isLastCheckEnabled':
            return { ...row, content: { ...row.content, isLastCheckEnabled: JSON.parse(value) } };
        case 'isObjectPresenceVisibleEnabled':
            return { ...row, content: { ...row.content, isObjectPresenceVisibleEnabled: JSON.parse(value) } };
        case 'isLastCleaningEnabled':
            return { ...row, content: { ...row.content, isLastCleaningEnabled: JSON.parse(value) } };
        case 'contentCode':
            return { ...row, content: { ...row.content, contentCode: JSON.parse(value) } };
        case 'displayLastCleaningType':
            return { ...row, content: { ...row.content, displayLastCleaningType: JSON.parse(value) } };
        case 'newsLanguageContent':
            return { ...row, content: { ...row.content, news: { language: value } } };
        case 'weatherCityContent':
            return { ...row, content: { ...row.content, weather: { city: value } } };
        case 'addContentCombination':
            value = JSON.parse(value);
            return { ...row, content: { ...row.content, contentCombinationItems: [...row.content?.contentCombinationItems, value] } };
        case 'contentCombinationModify':
            value = JSON.parse(value);
            return {
                ...row, content: {
                    ...row.content, contentCombinationItems: row.content?.contentCombinationItems.map((el: any, index: number) =>
                        index == value.modifiyIndex ? value.newVal : el)
                }
            };
        case 'contentCombinationAdvertisementModify':
            value = JSON.parse(value);
            return {
                ...row, sideBarImage: value.sideBarImage, systemETag: value.systemETag, content: {
                    ...row.content, contentCombinationItems: row.content?.contentCombinationItems.map((el: any, index: number) =>
                        index == value.modifiyIndex ? value.newVal : el)
                }
            };
        case 'logo':
            value = JSON.parse(value);
            return { ...row, logo: value.logo, systemETag: value.systemETag };
        case 'sideBarImage':
            value = JSON.parse(value);
            return { ...row, sideBarImage: value.sideBarImage, systemETag: value.systemETag };
        case 'qrCodeUrl':
            return { ...row, content: { ...row.content, qrCodeUrl: value } };
        case 'inProgressText1':
            return { ...row, content: { ...row.content, inProgressText1: value } };
        case 'inProgressText2':
            return { ...row, content: { ...row.content, inProgressText2: value } };
        case 'inProgressText3':
            return { ...row, content: { ...row.content, inProgressText3: value } };
        case 'inProgressText4':
            return { ...row, content: { ...row.content, inProgressText4: value } };
        case 'inProgressText5':
            return { ...row, content: { ...row.content, inProgressText5: value } };
        case 'byeText1':
            return { ...row, content: { ...row.content, byeText1: value } };
        case 'byeText1Color':
            return { ...row, content: { ...row.content, byeText1Color: value } };
        case 'byeText2':
            return { ...row, content: { ...row.content, byeText2: value } };
        case 'byeText2Color':
            return { ...row, content: { ...row.content, byeText2Color: value } };
        case 'publicText1':
            return { ...row, content: { ...row.content, publicText1: value } };
        case 'publicDisplayText1':
            return { ...row, content: { ...row.content, publicDisplayText1: value } };
        case 'publicDisplayText2':
            return { ...row, content: { ...row.content, publicDisplayText2: value } };
        case 'publicDisplayText1Color':
            return { ...row, content: { ...row.content, publicDisplayText1Color: value } };
        case 'publicDisplayText2Color':
            return { ...row, content: { ...row.content, publicDisplayText2Color: value } };
        case 'displayText1':
            return { ...row, content: { ...row.content, displayText1: value } };
        case 'displayText2':
            return { ...row, content: { ...row.content, displayText2: value } };
        case 'publicText1':
            return { ...row, content: { ...row.content, publicText1: value } };
        case 'displayText1Color':
            return { ...row, content: { ...row.content, displayText1Color: value } };
        case 'displayText2Color':
            return { ...row, content: { ...row.content, displayText2Color: value } };
        case 'publicText1Color':
            return { ...row, content: { ...row.content, publicText1Color: value } };
        default:
            console.error(`Kein Zuweisung für ${key} gefunden`, 'mapGroupSettings');
            return row;
    }
}