import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { getApiToken } from '../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { Add, Cancel, PhoneAndroid, PhotoAlbum, Save } from '@mui/icons-material';
import { CircularProgress, DialogActions, DialogContent, Grid, ListItemButton, TextField } from '@mui/material';
import { StandardWorkItem, StandardWorkItemType } from '../../models/StandardItem';
import { DownloadFileImageDialog } from '../authorized/admin/DownloadFileImageDialog';
import { FileBlobType } from '../../models/FileBlobType';
import { LocalMedia } from '../images/NetworkMedia';
import { useTranslation } from 'react-i18next';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 800,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export interface DefaultItemsDialogProps {
  itemType: StandardWorkItemType;
  manageItems: any;
  open: boolean;
  selectedValue: string;
  currentItems: StandardWorkItem[];
  allStandardItems: StandardWorkItem[];
  onClose: (value: string) => void;
  objectView?: boolean;
}

function SimpleDialog(props: DefaultItemsDialogProps) {



  const { onClose, selectedValue, open, itemType, manageItems, currentItems, allStandardItems, objectView } = props;
  const [listItems, setListItems] = React.useState<StandardWorkItem[]>([]);
  const history = useHistory();
  const [currentSelectedWorkingItem, setCurrentSelectedWorkingItem] = React.useState<StandardWorkItem>();
  const [showDownloadDialog, setShowDownloadDialog] = React.useState(false);
  const {t} = useTranslation();

  React.useEffect(() => {
    let token = getApiToken(history);
    if (open) {
      (async () => {
        setListItems(allStandardItems);
      }
      )();

    }
  }, [open]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const classes = useStyles();
  const [selectedItems, setSelectedItems] = React.useState<StandardWorkItem[]>([]);

  const handleToggle = (value: StandardWorkItem) => () => {
    const currentIndex = selectedItems.indexOf(value);
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedItems(newChecked);
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Items Auswahl</DialogTitle>
      <DialogContent>
        <List className={classes.root}>
          {listItems.map((value: StandardWorkItem, index: number) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem disabled={currentItems.some(x => x.text == value.text)} key={index} role={undefined} button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedItems.indexOf(value) !== -1 || currentItems.some(x => x == value)}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.text}`} />
                <ListItemIcon>
                  {value.downloadedPhotoUrl &&
                    <LocalMedia src={value.downloadedPhotoUrl.url} contentType={value.downloadedPhotoUrl.type} height={50} />
                  }
                </ListItemIcon>

                <Button
                  variant='contained'
                  endIcon={<PhotoAlbum />}
                  onClick={() => {
                    setCurrentSelectedWorkingItem(value);
                    setShowDownloadDialog(true);
                  }}
                >
                  {`${value.photos.length} Bilder`}
                </Button>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button size="large"
              startIcon={<Cancel />} variant={'outlined'} onClick={handleClose} color="primary">
              {t("myUnitsSettings.cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button size="large"
              startIcon={<Save />} variant={'contained'} onClick={async () => {
                handleClose();
                manageItems(selectedItems);
                setSelectedItems([]);
              }} color="primary">
              Hinzufügen
            </Button>
          </Grid>
        </Grid>

      </DialogActions>
      {currentSelectedWorkingItem &&
        <DownloadFileImageDialog id={currentSelectedWorkingItem?.id} visible={showDownloadDialog} setVisible={setShowDownloadDialog} incidentPhotos={currentSelectedWorkingItem?.photos} fileBlobType={FileBlobType.StandardItemPhoto}
          title={StandardWorkItem.GetLabelByType(currentSelectedWorkingItem.itemType)} />
      }
    </Dialog>
  );
}

export default function DefaultItemsDialog({ disabled = false, title = "", itemType, manageItems, currentItems, allStandardItems, onLoadFiles }: { disabled?: boolean, title?: string, itemType: StandardWorkItemType, manageItems: any, currentItems: StandardWorkItem[], allStandardItems: StandardWorkItem[], onLoadFiles: any }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [loading, setLoading] = React.useState(false);
  const {t} = useTranslation();
  const handleClickOpen = async () => {
    setLoading(true);
    await onLoadFiles();
    setLoading(false);
    setOpen(true);
  };

  if (!title) title = t("myUnitsSettings.assignStandardItem")

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <Button disabled={loading || disabled} endIcon={loading && <CircularProgress size={32} />} startIcon={<Add />} size="large" variant="contained" color="primary" onClick={handleClickOpen}>
        {title}
      </Button>
      <SimpleDialog allStandardItems={allStandardItems} manageItems={manageItems} selectedValue={selectedValue} open={open} onClose={handleClose} itemType={itemType} currentItems={currentItems} />
    </>
  );
}