import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close, AlarmOn, Edit, PhonelinkSetup, Settings, AddBox, QrCode2, TabletMac, DoneAll, Refresh, BarChart, PieChart, Print, QrCode } from "@mui/icons-material";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { api } from "../../../../api";
import { GROUP_ID, GROUP_NAME, GROUP_TYPE } from "../../../../constants";
import { getApiToken, setStorageForDrawer } from "../../../../helpers/storage-management";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupLicense } from "../../../../models/GroupLicense";
import { UserKey } from "../../../../models/UserKey";
import { white } from "../../../../styles/colors";
import { LoadingIcon } from "../../../common/LoadingIcon";
import { handleSelectedCombinationGroup } from "../../../tables/Base";
import { useQuery } from "../../portal/checkout/ShopPage";
import { SettingsChooserManagementTable } from "./SettingsChooserManagementTable";
import { UserProperty } from "../../../../models/UserProperty";
import { UserServiceWorker } from "../../../../models/UserServiceWorker";
import { ObjectUnit } from "../../../../models/ObjectUnit";
import { UserGroupSettingsTemplate } from "../../../../models/UserGroupSettingsTemplate";
import { CopyAll } from "@mui/icons-material";
import { GroupType } from "../../../../models/Group";
import { LinearProgressWithLabel } from "../../../core/components/ProgressComponent";
import { GroupsSelectionQrCodePrintDialog } from "./dialog/GroupsSelectionQrCodePrintDialog";
import { GroupsQrCodePrintManyDialog } from "./dialog/GroupsQrCodePrintManyDialog";
import { useTranslation } from "react-i18next";

export const SettingsChooserManagement = ({ onUpdateDisplayGroup }: { onUpdateDisplayGroup: any }) => {

    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState("");
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [allGroups, setAllGroups] = React.useState<GroupLicenseTable[]>([]);
    const [userKeys, setUserKeys] = React.useState<UserKey[]>([]);
    const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | null>(null);

    const [isGroupsSelectionForPrintVisible, setIsGroupsSelectionForPrintVisible] = React.useState(false);
    const [selectedGroupsForQrCode,setSelectedGroupsForQrCode] = React.useState<GroupLicenseTable[]>([]);
    const [isGroupsQrCodePrintManyDialogVisible,setIsGroupsQrCodePrintManyDialogVisible] = React.useState(false);


    const [loading, setLoading] = React.useState(false);

    const chosenUnit = useQuery(history).get("chosenUnit");
    const [isAccordionExpandedLicenseTable, setIsAccordionExpandedLicenseTable] = React.useState(false);
    const [properties, setProperties] = React.useState<UserProperty[]>([]);
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [userGroupSettingsTemplate, setUserGroupSettingsTemplate] = React.useState<UserGroupSettingsTemplate[]>([]);

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);

    const { t } = useTranslation();

    const loadDataAsync = async () => {
        setLoadingProgress(0);
        setValueBuffer(20);
        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setSelectedGroup(null);
        setSelectedGroups([]);
        setLoading(true);

        const tempGroups = await api.getAllGroups(tempToken);
        setLoadingProgress(20);
        setValueBuffer(40);
        setGroups(tempGroups);
        setAllGroups(tempGroups);
        let isGroupPreChosen = tempGroups.find(el => el.id == chosenUnit);
        if (isGroupPreChosen) {
            setSelectedGroup(isGroupPreChosen);
            setSelectedGroups([isGroupPreChosen]);
        }
        const tempProps = await api.getUserProperties(tempToken);
        setProperties(tempProps);
        setLoadingProgress(40);
        setValueBuffer(60);
        const tempServiceWorkers = await api.getServiceWorkers(tempToken);
        setUserServiceWorkers(tempServiceWorkers);

        setLoadingProgress(70);
        setValueBuffer(80);

        const tempObjects = await api.getUserObjectUnits(tempToken);
        setObjects(tempObjects);

        setLoadingProgress(80);
        setValueBuffer(100);

        const temp = await api.getGroupSettingsTemplateByUser(tempToken);
        setUserGroupSettingsTemplate(temp);
        setLoadingProgress(100);
        setValueBuffer(100);
        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();
        })();
    }, []);

    const navigateToSettings = (currentGroup: GroupLicenseTable) => {
        newSettingsChose(currentGroup);
        history.push(`/portal/group/${currentGroup.id}/sum`);
    }

    const newSettingsChose = (currentGroup: GroupLicenseTable) => {
        onUpdateDisplayGroup(currentGroup, false);
    }

    const [filters, setFilters] = React.useState([]);

    return (
        <Grid
            container
            direction="row"
            spacing={3}
        >
            <Grid item xs={12}>
                <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
            </Grid>
            <Grid item xs={12}>
                <Accordion expanded={true} >
                    <AccordionSummary onClick={() => { setIsAccordionExpandedLicenseTable(!isAccordionExpandedLicenseTable) }} expandIcon={<ExpandMore />}                    >
                        <Typography component="h4" variant="h4">{t("myUnitsSettings.myUnitsSettings")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction="column" spacing={3}>
                            {loading &&
                                <Grid item>
                                    <CircularProgress size={64} />
                                </Grid>}


                            <Grid item container direction="row" spacing={3}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        startIcon={<Refresh />}
                                        onClick={async () => {
                                            await loadDataAsync();
                                            setFilters([...filters]);
                                        }}
                                    >
                                        {t("myUnitsSettings.refresh")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={!selectedGroup}
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        startIcon={<Navigation />}
                                        onClick={() => {
                                            onUpdateDisplayGroup(selectedGroup);
                                        }}
                                    >
                                        {t("myUnitsSettings.navigateToUnit")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        endIcon={<Print />}
                                        startIcon={<QrCode />}
                                        onClick={async () => {
                                            setIsGroupsSelectionForPrintVisible(true);
                                        }}
                                    >
                                        {t("myUnitsSettings.printQrCodeOfUnits")}
                                    </Button>
                                </Grid>
                                <Grid xs={12} item container direction="row" spacing={3}>
                                    <Grid item>
                                        <Typography variant="h5">
                                            <Edit /> {t("myUnitsSettings.edit")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5">
                                            <PieChart /> {t("myUnitsSettings.toSingleEvaluation")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <SettingsChooserManagementTable
                                        userGroupSettingsTemplate={userGroupSettingsTemplate}
                                        token={token}
                                        objects={objects}
                                        userServiceWorkers={userServiceWorkers}
                                        properties={properties}
                                        groups={groups}
                                        filters={filters}
                                        setFilters={setFilters}
                                        onRefresh={async () => { await loadDataAsync() }}
                                        onEditSettings={navigateToSettings}
                                        onEditSettingsInDialog={newSettingsChose}
                                        selectedGroups={selectedGroups}
                                        onSelectionChange={(selection: string[]) => {
                                            setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
                                            setSelectedGroup(selection.length === 1 ? groups.filter(x => selection.some(s => s === x.id))[0] : null);
                                        }}
                                    />

                                </Grid>
                            </Grid>

                            {
                                (!groups || groups.length === 0) && !loading &&
                                <Grid item>
                                    <Paper className="Paper">
                                        <Grid container direction="row" alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <Typography component="h5" variant="h5"
                                                >
                                                    Keine Einheiten Gefunden!
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <GroupsSelectionQrCodePrintDialog open={isGroupsSelectionForPrintVisible} setOpen={setIsGroupsSelectionForPrintVisible} groups={groups}
                onFinish={(selectedGroupsForQrCode: GroupLicenseTable[]) => {
                    setSelectedGroupsForQrCode(selectedGroupsForQrCode);
                    setIsGroupsQrCodePrintManyDialogVisible(true);
                }}
                objects={objects} />
            <GroupsQrCodePrintManyDialog visible={isGroupsQrCodePrintManyDialogVisible} setVisible={setIsGroupsQrCodePrintManyDialogVisible} groups={selectedGroupsForQrCode} objects={objects}/>
        </Grid >
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));