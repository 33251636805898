import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Accessibility, Download } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { differenceInHours, differenceInMinutes, formatDistance, fromUnixTime } from 'date-fns';
import { de } from 'date-fns/locale';
import { MaterialStock, UserStock } from '../../../../../models/UserStock';
import { UserServiceWorker } from '../../../../../models/UserServiceWorker';
import { formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime } from '../../../../../helpers/date-management';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { buildCellElementArray } from '../../../../common/AccordionForElementArray';
import { lightRed, lightGreen, lightgrey, lightBlue } from '../../../../../styles/colors';
import { StandardWorkItem } from '../../../../../models/StandardItem';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { GroupSettings } from '../../../../../models/GroupSettings';
import { UserAccountBasic } from '../../../../../models/UserAccountLight';
import { DownloadFileImageDialog } from '../../../admin/DownloadFileImageDialog';
import { FileBlobType } from '../../../../../models/FileBlobType';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export const ObjectStockTable = ({ userStocks, groups, userServiceWorkers, selectedObjectUnit, objects = [], materials, usersInformation }
    : { userStocks: UserStock[], groups: GroupSettings[], userServiceWorkers: UserServiceWorker[], selectedObjectUnit?: ObjectUnit, objects?: ObjectUnit[], materials: StandardWorkItem[], usersInformation: UserAccountBasic[] }) => {

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `ObjektSession-${selectedObjectUnit?.name || "Alle-Objekte"}-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const [pageSize, setPageSize] = React.useState(15);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: UserStock, value: any } = props;
        const [showGroupDownloadDialog, setShowGroupDownloadDialog] = React.useState(false);
        const [currentMaterialStock, setCurrentMaterialStock] = React.useState<MaterialStock>();
        const [showDownloadDialog, setShowDownloadDialog] = React.useState(false);
        let currentmaterialText = materials.find(x=>x.id == currentMaterialStock?.materialId)?.text || "";

        if (column.name === ObjectUnit.Columns.materials) {
            let group = groups.find(x => x.id == row.groupId);
            let materialStocks = row.materialStocks;
            if (group) {
                //       materialStocks = row.materialStocks.filter(x=>group?.materialConsumptions?.materialConsumptions.some(y=>y.standardItemId == x.materialId && !!y.objectId));
            }
            let photos = 0;
            let names = materialStocks.map(x => {
                let material = materials.find(material => material.id == x.materialId);
                let addedManualMaterialAmountObject = x.currentObjectStockAmount - x.lastObjectStockAmount;
                let addedManualMaterialAmountGroup = x.currentGroupStockAmount - x.lastGroupStockAmount;
                photos +=  (x?.objectPhotos?.length || 0) + (x?.groupPhotos?.length || 0)
                if (!material) return <Typography>Material wurde nicht gefunden</Typography>;
                let userServiceWorker = userServiceWorkers.find(x => x.id == row.userServiceWorkerId);
                let userType = "Manager"
                if (userServiceWorker) {
                    userType = UserServiceWorker.GetLabel(userServiceWorker.type, t)
                }
                return (
                    <div style={{ width: 400 }}>
                        <Typography style={{ padding: 5, borderRadius: 3, backgroundColor: lightgrey }} >
                            Name: {material?.text}<br />
                            {t("myObjectSettings.specificReason")}:{x.specificReason}<br />
                            Aktions Typ: {MaterialStock.GetLabelByStockActionType(x.stockActionType)}<br />
                            User Typ: {userType}<br />
                        </Typography>
                        {row.objectId &&
                            <Typography style={{ padding: 5, borderRadius: 3, backgroundColor: addedManualMaterialAmountObject > 0 ? lightGreen : addedManualMaterialAmountObject == 0 ? lightBlue : lightRed }}>
                                Aktuelle Lagermenge Objekt:{x.currentObjectStockAmount}<br />
                                Letzte Lagermenge Objekt {x.lastObjectStockAmount} <br />
                                Lagermenge Objekt Differenz: {addedManualMaterialAmountObject}<br />
                                Objekt Lagerung Nr.: {x.resetCountObject} <br />
                                {x?.objectPhotos?.length > 0 &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Download />}
                                        onClick={() => {
                                            setCurrentMaterialStock(x);
                                            setShowDownloadDialog(true);
                                        }}
                                    >
                                        Zeige {x.objectPhotos.length} Bilder
                                    </Button>
                                }
                            </Typography>
                        }
                        {row.groupId &&
                            <Typography style={{ padding: 5, borderRadius: 3, backgroundColor: addedManualMaterialAmountGroup > 0 ? lightGreen : addedManualMaterialAmountGroup == 0 ? lightBlue : lightRed }}>
                                Aktuelle Lagermenge Einheit: {x.currentGroupStockAmount}<br />
                                Letzte Lagermenge Einheit: {x.lastGroupStockAmount}<br />
                                Lagermenge Einheit Differenz: {addedManualMaterialAmountGroup}<br />
                                Einheit Lagerung Nr.: {x.resetCountGroup} <br />
                                {x?.groupPhotos?.length > 0 &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Download />}
                                        onClick={() => {
                                            setCurrentMaterialStock(x);
                                            setShowGroupDownloadDialog(true);
                                        }}
                                    >
                                        Zeige {x.groupPhotos.length} Bilder
                                    </Button>
                                }

                            </Typography>
                        }
                        {currentMaterialStock &&
                            <DownloadFileImageDialog id={row.objectId} visible={showDownloadDialog} setVisible={setShowDownloadDialog} incidentPhotos={currentMaterialStock.objectPhotos} fileBlobType={FileBlobType.ObjectMaterialStockPhoto}
                            title={currentmaterialText} fotoSubDescription='Materialfoto' />
                        }
                        {currentMaterialStock &&
                            <DownloadFileImageDialog id={row.groupId} visible={showGroupDownloadDialog} setVisible={setShowGroupDownloadDialog} incidentPhotos={currentMaterialStock.groupPhotos} fileBlobType={FileBlobType.GroupMaterialStockPhoto}
                                title={currentmaterialText} fotoSubDescription='Materialfoto'/>
                        }
                    </div>
                )

            })
            return buildCellElementArray(props, names, `${t("myObjectSettings.contains")} ${materialStocks?.length} Materialien und ${photos} Fotos`);
        }
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let columns = [
        {
            name: UserStock.Columns.objectId,
            title: t("object-storage-single-eval.object"),
            getCellValue: (row: UserStock) => {
                let object = objects.find(x => x.id == row.objectId);
                return (
                    !object ? "" : `${object?.name} ${object?.addressLine}`
                );
            }
        },
        {
            name: UserStock.Columns.groupId,
            title: t("object-storage-single-eval.unit"),
            getCellValue: (row: UserStock) => {
                let group = groups.find(x => x.id == row.groupId);
                if (group) {
                    return `${group?.locationInformation?.notificationName} ${group?.locationInformation?.levelName}`
                }
                return "";
            }
        },
        {
            name: UserStock.Columns.userServiceWorkerId,
            title: t("object-storage-single-eval.performedBy"),
            getCellValue: (row: UserStock) => {
                let userServiceWorker = userServiceWorkers.find(x => x.id == row.userServiceWorkerId);
                if (userServiceWorker) {
                    return `${userServiceWorker?.name}`
                }
                let user = usersInformation?.find(x => x.id == row.userId);
                if (user) {
                    return `${user.firstname} ${user.lastname} ${user.mail}`
                }
                return "Servicekraft" || "Manager"
            },
        },
        {
            name: UserStock.Columns.reason,
            title: t("object-storage-single-eval.storageReason"),
            getCellValue: (row: UserStock) => row.reason,
        },
        {
            name: UserStock.Columns.restockObjectCount,
            title: t("object-storage-single-eval.objectSerialNumber"),
            getCellValue: (row: UserStock) => row.restockObjectCount || "",
        },
        {
            name: UserStock.Columns.restockGroupCount,
            title: t("object-storage-single-eval.unitSerialNumber"),
            getCellValue: (row: UserStock) => row.groupId ? row.restockGroupCount : "keine Einheit",
        },
        {
            name: UserStock.Columns.stockActionDoneFromType,
            title: t("object-storage-single-eval.actionPerformedAt"),
            getCellValue: (row: UserStock) => UserStock.GetLabelByStockActionDoneFromType(row.stockActionDoneFromType),
        },
        {
            name: UserStock.Columns.materials,
            title: t("object-storage-single-eval.materialStatus"),
            getCellValue: (row: UserStock) => JSON.stringify(row.materialStocks),
        },
        {
            name: UserStock.Columns.createdTimestamp,
            title: t("object-storage-single-eval.creationDate"),
            getCellValue: (row: UserStock) => formatEuropeanDateTime(row.createdTimestamp),
        },
    ];

    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: UserStock.Columns.objectId, width: 350 },
        { columnName: UserStock.Columns.groupId, width: 200 },
        { columnName: UserStock.Columns.userServiceWorkerId, width: 200 },
        { columnName: UserStock.Columns.reason, width: 200 },
        { columnName: UserStock.Columns.restockObjectCount, width: 100 },
        { columnName: UserStock.Columns.restockGroupCount, width: 100 },
        { columnName: UserStock.Columns.stockActionDoneFromType, width: 320 },
        { columnName: UserStock.Columns.materials, width: 600 },
        { columnName: UserStock.Columns.createdTimestamp, width: 150 },

    ]);

    return (
        <Paper>
            <Grid
                rows={userStocks}
                columns={columns}
            >
                <FilteringState />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <PagingPanel pageSizes={[10, 15, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={userStocks}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};