import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Divider, Fab, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, FilterAlt, VisibilityOff } from "@mui/icons-material";
import { GroupSettings, GroupSettingsIncidenName, GroupSettingsIncidenReport, IncidentModeType } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { errorColor, white } from "../../styles/colors";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { ReactComponent as AlertSvg } from '../../assets/svgs/alert.svg';
import { useContainerStyles } from "../../styles/container";
import { Group, GroupType } from "../../models/Group";
import { StandardWorkItem, StandardWorkItemType } from "../../models/StandardItem";
import { api } from "../../api";
import { FileBlobType } from "../../models/FileBlobType";
import { useSnackbar } from "notistack";
import DefaultItemsDialogOld, { DefaultItemsType, getDefaultIcon } from "../dialogs/DefaultItemsDialogOld";
import DefaultItemsDialog from "../dialogs/DefaultItemsDialog";
import { LocalMedia } from "../images/NetworkMedia";
import { NameOfExceptionIncidentListDialog } from "../dialogs/groupSettings/NameOfExceptionIncidentListDialog";
import { GroupSettingsMappingKeys } from "../../helpers/group-settings-management";
import { PublicFeedbackModeComponent } from "./GroupSettingFieldsFeedbackControl";
import PayLicense from '../../assets/image/license/pay_license.png'
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const GroupSettingFieldsPayPerService = ({ row, onChange, group, token }
    : { row: GroupSettings | UserGroupSettingsTemplate; onChange: any; group: Group | undefined; token: any }) => {

    const classes = GroupSettingFieldsUseStyles();
    const containerStyles = useContainerStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [currentIncident, setCurrentIncident] = React.useState<GroupSettingsIncidenReport>();
    const [isNameOfExceptionIncidentListDialogVisible, setIsNameOfExceptionIncidentListDialogVisible] = React.useState(false);

    const [standardIncidents, setStandardIncidents] = React.useState<StandardWorkItem[]>([]);
    return (
        <Accordion disabled={group?.payPerServiceLicenseIsExpired} className={classes.hover} onChange={async (event, expanded) => {
            if (expanded && standardIncidents.length < 1) {
                let res = await api.GetStandardWorkItemsByType(token, StandardWorkItemType.Incident);
                setStandardIncidents(res);
                for (let item of row.payPerServiceControl?.payPerServiceItems || []) {
                    let curPhotoToDownload = res.find(x => x.id == item.standardItemId);
                    if (curPhotoToDownload)
                        curPhotoToDownload.downloadedPhotoUrl = await api.DownloadUserFile(curPhotoToDownload.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, curPhotoToDownload.photos[0].fileName, curPhotoToDownload.photos[0].contentType, true)

                }
                setStandardIncidents([...res]);
            }
        }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <img src={PayLicense} style={{ height: 40, width: 40 }} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">Pay Per Service</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item xs={3}>
                        <PayPerServiceIsEnabledCheckboxComponent row={row} onChange={onChange} group={group} />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={3}>
                    <Grid item>
                        <Typography variant='h5'>{t("myUnitsSettings.roomManagement")}</Typography>
                    </Grid>
                    <PayPerServiceModeTypeComponent row={row} onChange={onChange} />
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid container item justifyContent={"flex-start"} spacing={3}>


                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Add />}
                                variant={'contained'}
                                onClick={async () => {
                                    onChange({ target: { name: 'payPerServiceItemsAdd', value: "" } });
                                }}
                                color="primary"
                            >
                                {t("myUnitsSettings.addRoom")}
                            </Button>
                        </Grid>
                        <Grid item>
                            {row.payPerServiceControl && row.payPerServiceControl.payPerServiceItems &&
                                <DefaultItemsDialog
                                    onLoadFiles={async () => {
                                        let res = await Promise.all(standardIncidents.map(async (x) => {
                                            if (x.photos.length > 0) {
                                                x.downloadedPhotoUrl = await api.DownloadUserFile(x.id, FileBlobType.StandardItemPhoto, token, enqueueSnackbar, x.photos[0].fileName, x.photos[0].contentType, true)
                                            }
                                            return x;
                                        }));
                                        setStandardIncidents(res);
                                    }}
                                    allStandardItems={standardIncidents}
                                    currentItems={row.payPerServiceControl.payPerServiceItems.map(el => standardIncidents.find(x => x.id == el.standardItemId) || new StandardWorkItem()) || []}
                                    manageItems={(newItems: any[]) => {
                                        onChange({ target: { name: 'payPerServiceItemsAddMultiple', value: newItems.map(x => GroupSettingsIncidenReport.CreateInstance(x.text, true, x.id)) } });
                                    }}
                                    itemType={StandardWorkItemType.Incident}
                                />
                            }
                        </Grid>
                        <Grid item>
                            {row.payPerServiceControl && row.payPerServiceControl.payPerServiceItems &&
                                <DefaultItemsDialogOld
                                    currentItems={row.payPerServiceControl.payPerServiceItems.map(el => el.text) || []}
                                    manageItems={(newItems: any[]) => {
                                        onChange({ target: { name: 'payPerServiceItemsAddMultipleOld', value: newItems } });
                                    }}
                                    itemType={DefaultItemsType.Incidents}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid item container direction={"row"} spacing={3}>
                        {
                            row?.payPerServiceControl?.payPerServiceItems &&
                            row.payPerServiceControl?.payPerServiceItems.map((el: GroupSettingsIncidenReport, index) => {
                                const currentStandardItem = standardIncidents.find(x => x.id == el.standardItemId);
                                return (
                                    <Grid item md={12} lg={6} xl={4} container direction="row" alignItems="center" spacing={3}>
                                        <Grid item xs={1}>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                onClick={() => {
                                                    onChange({ target: { name: 'payPerServiceItemsRemove', value: JSON.stringify({ index: index }) } });
                                                }}>
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        {currentStandardItem ?
                                            <Grid item xs={6} className={containerStyles.marginFieldSmall}>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment variant="filled" position="end">{currentStandardItem.downloadedPhotoUrl ?
                                                            <LocalMedia src={currentStandardItem.downloadedPhotoUrl.url} contentType={currentStandardItem.downloadedPhotoUrl.type} height={50} />
                                                            : <CircularProgress />}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="payPerServiceItems"
                                                    label={t("myUnitsSettings.room")}
                                                    value={currentStandardItem.text || ''}
                                                    disabled />
                                            </Grid>
                                            :
                                            <Grid item xs={6} className={containerStyles.marginFieldSmall}>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(el.text || '')}</InputAdornment>,
                                                    }}
                                                    variant={"filled"}
                                                    name="payPerServiceItems"
                                                    label={t("myUnitsSettings.room")}
                                                    value={el.text || ''}
                                                    onChange={(event: any) => {
                                                        let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                        onChange(res);
                                                    }} />
                                            </Grid>
                                        }
                                        {row.payPerServiceControl?.isPayPerServiceWithNamePriceEnabled &&
                                            <Grid item xs={2}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    startIcon={<VisibilityOff />}
                                                    variant={'contained'}
                                                    onClick={async () => {
                                                        setCurrentIncident(el)
                                                        setIsNameOfExceptionIncidentListDialogVisible(true);
                                                    }}
                                                    color="primary"
                                                >
                                                    {t("myUnitsSettings.performance")}
                                                </Button>
                                            </Grid>
                                        }
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={el.isEnabled}
                                                        onChange={(target: any) => {
                                                            target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                            onChange(target);
                                                        }}
                                                        name="payPerServiceItems"
                                                        color="primary"
                                                    />
                                                }
                                                label={t("myUnitsSettings.activated")} />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant='h5'>{t("myUnitsSettings.visitor")} - Pay Per Service</Typography>
                    </Grid>
                    <Grid container item justifyContent={"flex-start"} spacing={3}>

                        <PayPerServiceCheckboxComponent onChange={onChange} row={row} />
                        <PayPerServiceFreeTextCheckboxComponent onChange={onChange} row={row} />
                        <PayPerServiceWithNameCheckboxComponent onChange={onChange} row={row} />

                    </Grid>

                    <Grid item container direction={"row"} spacing={3}>
                        <PublicFeedbackCheckboxComponent title={t("myUnitsSettings.publicRegistrationActivated")} onChange={onChange} row={row} />
                        <PublicFeedbackRestartCheckboxComponent onChange={onChange} row={row} />
                    </Grid>
                    <Grid item>
                        <PublicFeedbackModeComponent row={row} onChange={onChange} />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                        <Grid item>
                            <Typography variant='h5'>{t("myUnitsSettings.performance")} - PayPerService</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={row.payPerServiceControl?.isPayPerServiceWithNamePriceEnabled}
                                    onChange={(event: any) => {
                                        if (!row.payPerServiceControl) return;
                                        row.payPerServiceControl.isPayPerServiceWithNamePriceEnabled = event.target.checked;
                                        onChange({ target: { name: "row", value: "" } });
                                    }}
                                    name="isPayPerServiceWithNamePriceEnabled"
                                    color="primary" />}
                                label={t("myUnitsSettings.costDisplayForServiceStaffActivated")} />
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent={"flex-start"} spacing={3}>


                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Add />}
                                variant={'contained'}
                                onClick={async () => {
                                    onChange({ target: { name: 'payPerServiceItemNameAdd', value: "" } });
                                }}
                                color="primary"
                            >
                                {t("myUnitsSettings.addPerformance")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"row"} spacing={3}>
                        {
                            row?.payPerServiceControl?.payPerServiceItemNames &&
                            row.payPerServiceControl?.payPerServiceItemNames.map((el: GroupSettingsIncidenName, index) => {
                                return (
                                    <Grid item md={12} lg={6} xl={4} container direction="row" alignItems="center" spacing={0}>
                                        <Grid item lg={1}>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                onClick={() => {
                                                    onChange({ target: { name: 'payPerServiceItemNameRemove', value: JSON.stringify({ index: index }) } });
                                                }}>
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        <Grid item lg={6} className={containerStyles.marginFieldSmall}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment variant="filled" position="end">{getDefaultIcon(el.text || '')}</InputAdornment>,
                                                }}
                                                variant={"filled"}
                                                name="payPerServiceItemName"
                                                label="Name"
                                                value={el.text || ''}
                                                onChange={(event: any) => {
                                                    let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'text' }) } };
                                                    onChange(res);
                                                }} />
                                        </Grid>
                                        <Grid item lg={2} className={containerStyles.marginFieldSmall}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment variant="filled" position="end">€</InputAdornment>,
                                                }}
                                                variant={"filled"}
                                                name="payPerServiceItemName"
                                                label={t("myUnitsSettings.price")}
                                                type={"number"}
                                                value={el.price || ''}
                                                onChange={(event: any) => {
                                                    let res = { target: { name: event.target.name, value: JSON.stringify({ val: event.target.value, index: index, property: 'price' }) } };
                                                    onChange(res);
                                                }} />
                                        </Grid>

                                        <Grid item lg={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={el.isEnabled}
                                                        onChange={(target: any) => {
                                                            target.target['value'] = JSON.stringify({ val: target.target.checked, index: index, property: 'isEnabled' });
                                                            onChange(target);
                                                        }}
                                                        name="payPerServiceItemName"
                                                        color="primary"
                                                    />
                                                }
                                                label={t("myUnitsSettings.activated")} />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </AccordionDetails>
            {currentIncident &&
                <NameOfExceptionIncidentListDialog row={row as GroupSettings} incident={currentIncident} onClose={() => {
                    setIsNameOfExceptionIncidentListDialogVisible(false);
                }} onChange={() => {
                    onChange({ target: { name: "row", value: "" } });
                }} open={isNameOfExceptionIncidentListDialogVisible} />
            }
        </Accordion>
    );
};
export const PayPerServiceCheckboxComponent = ({ onChange, row }: { onChange: any, row: any }) => {
    return (
        <Grid item>
            <FormControlLabel
                control={<Checkbox
                    checked={row.payPerServiceControl?.isPayPerServiceEnabled}
                    onChange={(event: any) => {
                        event.target['value'] = event.target.checked;
                        onChange(event);
                    }}
                    name="isPayPerServiceEnabled"
                    color="primary" />}
                label={t("myUnitsSettings.payPerServiceActivatedForVisitors")} />
        </Grid>
    )
}
export const PayPerServiceFreeTextCheckboxComponent = ({ onChange, row }: { onChange: any, row: any }) => {
    return (
        <Grid item>
            {row.payPerServiceControl?.isPayPerServiceEnabled &&
                <FormControlLabel
                    control={<Checkbox
                        checked={row.payPerServiceControl?.isPayPerServiceCustomTextEnabled}
                        onChange={(event: any) => {
                            event.target['value'] = event.target.checked;
                            onChange(event);
                        }}
                        name="isPayPerServiceCustomTextEnabled"
                        color="primary" />}
                    label={t("myUnitsSettings.payPerSErviceActivatedForVisitorsWithFreeText")} />
            }
        </Grid>
    )
}

export const PublicFeedbackCheckboxComponent = ({ onChange, row, title = "Öffentliches Feedback aktiviert" }: { onChange: any, row: any, title?: string }) => {
    return (
        <Grid item>
            <FormControlLabel
                label={title}
                control={
                    <Checkbox
                        name={GroupSettingsMappingKeys.isPublicFeedbackEnabled}
                        color="primary"
                        checked={row.feedbacks?.isPublicFeedbackEnabled}
                        onChange={(event: any, checked: boolean) => {
                            event.target.value = checked;
                            onChange(event);
                        }}
                    />
                }
            />
        </Grid>
    )
}
export const PublicFeedbackRestartCheckboxComponent = ({ onChange, row }: { onChange: any, row: any }) => {
    return (
        <Grid item>
            <FormControlLabel
                label={t("myUnitsSettings.restartEnabled")}
                control={
                    <Checkbox
                        name={GroupSettingsMappingKeys.isPublicFeedbackRestartEnabled}
                        color="primary"
                        checked={row.feedbacks?.isPublicFeedbackRestartEnabled}
                        onChange={(event: any, checked: boolean) => {
                            event.target.value = checked;
                            onChange(event);
                        }}
                    />
                }
            />
        </Grid>
    )
}
export const PayPerServiceWithNameCheckboxComponent = ({ onChange, row }: { onChange: any, row: any }) => {
    return (
        <Grid item>
            {row.payPerServiceControl?.isPayPerServiceEnabled &&
                <FormControlLabel
                    control={<Checkbox
                        checked={row.payPerServiceControl?.isPayPerServiceWithNameEnabled}
                        onChange={(event: any) => {
                            event.target['value'] = event.target.checked;
                            onChange(event);
                        }}
                        name="isPayPerServiceWithNameEnabled"
                        color="primary" />}
                    label={t("myUnitsSettings.payPerServiceActivatedForVisitorsWithNames")} />
            }
        </Grid>
    )
}

export const PayPerServiceModeTypeComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    return (
        <Grid item>
            <FormControl component="fieldset">
                <FormLabel component="legend">{t("myUnitsSettings.roomModeForServiceStaffSelection")}</FormLabel>
                <RadioGroup
                    row
                    aria-label="gender"
                    value={row?.payPerServiceControl?.payPerServiceModeType}
                    onChange={(event) => {
                        if (row?.payPerServiceControl?.payPerServiceModeType == null) return;

                        row.payPerServiceControl.payPerServiceModeType = parseInt(event.target.value);

                        onChange({ target: { name: "row", value: "" } });
                    }}
                >
                    <FormControlLabel
                        value={IncidentModeType.Standard}
                        control={<Radio />}
                        label={t("myUnitsSettings.withoutPerformanceWithoutExtraRoom")}
                    />

                    <FormControlLabel
                        value={IncidentModeType.WithName}
                        control={<Radio />}
                        label={t("myUnitsSettings.withPerformanceWithoutExtraRoom")}
                    />

                    <FormControlLabel
                        value={IncidentModeType.WithNameAndFreeText}
                        control={<Radio />}
                        label={t("myUnitsSettings.WithPerformanceWithExtraRoom")}
                    />

                    <FormControlLabel
                        value={IncidentModeType.WithoutNameButFreeText}
                        control={<Radio />}
                        label={t("myUnitsSettings.WithPerformanceWithExtraRoom")}
                    />


                </RadioGroup>

            </FormControl>
        </Grid>
    )

}

export const PayPerServiceIsEnabledCheckboxComponent = ({ row, onChange, group }: { row: any, onChange: any, group: any }) => {
    const {t} = useTranslation();
    return (
        <Grid container direction="row" alignItems={"center"}>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox
                        disabled={group?.payPerServiceLicenseIsExpired}
                        checked={row.payPerServiceControl?.isPayPerServiceEnabled}
                        onChange={(event: any) => {
                            event.target['value'] = event.target.checked;
                            onChange(event);
                        }}
                        name="isPayPerServiceEnabled"
                        color="primary" />}
                    label={`Pay Per Service ${t("settingsPage.activated")}`} />
            </Grid>
            {group?.payPerServiceLicenseIsExpired &&
                <Grid item>
                    <Typography color={"error"}>{t("settingsPage.ppsLicenseExpired")}</Typography>
                </Grid>
            }
        </Grid>
    )
}