import axios from 'axios';
import { headers } from '../api';
import { UserAccount } from '../models/UserAccount';

export const getUser = async (token: string, userId: string | null): Promise<UserAccount> => {
    try {
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
    }
    return null as any;
}

export const getLightUser = async (userId: string | null): Promise<UserAccount> => {
    try {
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/${userId}/light`, { headers: { ...headers } })
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
    }
    return null as any;
}