import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../api';
import { useSnackbar } from 'notistack';
import {
    AppBar,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useTranslation } from 'react-i18next';

// export const DialogContent = withStyles((theme) => ({
//     root: {
//         padding: theme.spacing(2),
//     },
// }))(MuiDialogContent);

// export const DialogActions = withStyles((theme) => ({
//     root: {
//         margin: 0,
//         padding: theme.spacing(1),
//     },
// }))(MuiDialogActions);

export const CreateTelegramGroupDialog = ({ open, setOpen, token, phoneNumber }: { open: boolean, setOpen: any, token: string, phoneNumber: any }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [groupName, setGroupName] = React.useState("");
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog fullWidth maxWidth={"sm"} onClose={handleClose} open={open}>
                <DialogTitle className={classes.dialogTitle}>
                    <AppBar className={classes.dialogTitleAppBar} position="sticky">
                        <Toolbar>
                            <Typography className={classes.dialogTitleAppBarTitle} variant="h6" noWrap>
                                Telegram Gruppe erstellen
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container direction={'row'} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: '100%' }}
                                id="filled-number"
                                label="Gruppen Titel"
                                variant="filled"
                                value={groupName}
                                onChange={(event: any) => { setGroupName(event.target.value) }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<CancelIcon />}
                        variant={'outlined'}
                        onClick={handleClose}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button size="large"
                        startIcon={<AddBoxIcon></AddBoxIcon>} variant={'contained'} onClick={async () => {

                            await api.telegramCreateGroup(groupName, token, phoneNumber, enqueueSnackbar);
                            handleClose();
                        }} color="primary">
                        Hinzufügen!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: 0,
        },
        dialogTitleAppBar: {
            flexGrow: 1,
        },
        dialogTitleAppBarTitle: {
            flexGrow: 1,
        },
        dialogContent: {
            padding: theme.spacing(2),
        },
        dialogActions: {
            margin: 0,
            padding: theme.spacing(1),
        }
    }),
);