import axios from 'axios';
import { headers } from '../../api';
import { PUBLIC_SERVICEWORKER_TOKEN, PUBLIC_CURRENT_USER_SERVICE_WORKER } from '../../constants';
import { getStorage } from '../../helpers/storage-management';
import { UserServiceWorker } from '../../models/UserServiceWorker';



export const signOutOfObject = async (objectId: string, groupId: string, enqueueSnackbar: any): Promise<any> => {
  try {
    let token = localStorage.getItem(PUBLIC_SERVICEWORKER_TOKEN);
    let serviceWorkers = await getStorage<UserServiceWorker>(PUBLIC_CURRENT_USER_SERVICE_WORKER);

    let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/service-workers/${serviceWorkers.id}/sign-out-of-object/${objectId}`,
    {groupId: groupId},
    { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
    enqueueSnackbar("Objekt Abmeldung erfolgreich!", { variant: 'success' });

    return true;
  } catch (err) {
    enqueueSnackbar("Objekt Abmeldung fehlgeschlagen", { variant: 'error' });
    return false;
  }
}