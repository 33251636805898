import axios from 'axios';
import { headers } from '../api';
import { getQueryParamFromApiDataAmountType, getQueryParamsAdminDataFilter, getQueryParamsTimeBackendFilter } from '../helpers/charts/base';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupFeedback } from '../models/GroupFeedback';

export const getFeedbacksByUser = async (token: string): Promise<Array<GroupFeedback>> => {

    const userId = getUserIdFromStorage();
    return await axios
        .get(
            `${process.env.REACT_APP_BASIC_URI}/users/${userId}/feedback?${getQueryParamFromApiDataAmountType()}&${getQueryParamsTimeBackendFilter()}&${getQueryParamsAdminDataFilter()}`,
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        .then(res => res.data)
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            return [];
        });
}