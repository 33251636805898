import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserProperty } from '../../../../models/UserProperty';
import { api } from '../../../../api';
import { getApiToken } from '../../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { GroupSelectionTable } from '../../../tables/GroupSelectionTable';
import { errorColor, white } from '../../../../styles/colors';
import { PropertySelectionDataTable } from './PropertySelectionDataTable';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { ObjectSelectionDataTable } from './ObjectSelectionDataTable';
import { useTranslation } from 'react-i18next';

export const GroupSelectionAssignGroupToObjectDialog = ({
  open,
  setOpen,
  currentGroup,
  groups,
  onRefresh
}: {
  open: boolean,
  setOpen: any,
  currentGroup: GroupLicenseTable,
  groups: GroupLicenseTable[],
  onRefresh:any
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [token, setToken] = React.useState("");
  const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);
  const {t} = useTranslation();

  const handleClose = () => {
    setSelectedObjects([]);
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const tempObjects = await api.getUserObjectUnits(tempToken);
      setObjects(tempObjects);

    })();

  }, [open]);

  const disabled = selectedObjects.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Wähle Objekte die der Einheit <span className='header-dialog-default-title'>{currentGroup.notificationName}</span> zugewiesen werden sollen</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <ObjectSelectionDataTable rows={objects} groups={groups} currentGroup={currentGroup}
          selectedObjects={selectedObjects}
          onSelectionChange={(selection: string[]) => {
            let selected = selection[selection.length - 1];
            setSelectedObjects(objects.filter(x => selected === x.id));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={disabled ? {} : { backgroundColor: errorColor, color: white }}
          endIcon={<Delete />}
          disabled={disabled}
          onClick={async () => {
            if (!currentGroup) return;
            for (let prop of selectedObjects) {
              await api.unAssignObjectFromGroup(currentGroup.id, prop.id, token, enqueueSnackbar);
            }
            onRefresh();
            handleClose();
          }}
        >
          Entfernen
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<Add />}
          disabled={disabled}
          onClick={async () => {
            if (!currentGroup) return;
            for (let prop of selectedObjects) {
              await api.assignObjectUnitToGroup(currentGroup.id, prop.id, token, enqueueSnackbar);
            }
            onRefresh();
            handleClose();
          }}
        >
          Zuweisen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));