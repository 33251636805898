import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { formatEuropeanDate, formatEuropeanDateTime } from '../../../../helpers/date-management';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { SsdDeviceModel } from '../../../../models/bssd/SsdDeviceModel';

export const SsdUnregisteredDevicesTable = ({ rows, selection, setSelection, }
    : { rows: SsdDeviceModel[], selection: any[], setSelection: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);

    const columns = [
        {
            name: SsdDeviceModel.Columns.id,
            title: "Device Id",
            getCellValue: (row: SsdDeviceModel) => row.id,
        },
        {
            name: SsdDeviceModel.Columns.deviceType,
            title: "Device Typ",
            getCellValue: (row: SsdDeviceModel) => SsdDeviceModel.GetLabel(row.deviceType),
        },
        {
            name: SsdDeviceModel.Columns.hubId,
            title: "Hub Id",
            getCellValue: (row: SsdDeviceModel) => row.hubId,
        },
        {
            name: SsdDeviceModel.Columns.version,
            title: "Letzter Settings Update",
            getCellValue: (row: SsdDeviceModel) => formatEuropeanDateTime(row.version),
        }
    ];

    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Hubs');
        });
    };

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 315 } }));
    const [defaultHiddenColumnNames] = React.useState([]);

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //            sorting={[{ columnName: MotionTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection showSelectAll selectByRowClick />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
                <ExportPanel startExport={startExport} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
