import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const DrawerListSection = ({ title, pathname, items, open, setOpen  }: { title: string, pathname: string, items: { title: string, path: string, icon: React.ReactNode }[],  open: boolean, setOpen: any }) => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <List
            component="nav"
            className={classes.root}
        >
            <ListItem
                button
                onClick={() => setOpen(!open)}
                style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
            >
                <ListItemIcon className={classes.icon}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
                <Typography variant="h5">{title}</Typography>

            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="nav" aria-label="main mailbox folders">
                    {
                        items &&
                        items.map((item) => {
                            return (
                                <ListItem
                                    button
                                    component={Link}
                                    to={item.path}
                                    selected={pathname === item.path}
                                >
                                    <ListItemIcon children={item.icon} />
                                    <ListItemText primary={item.title} />
                                </ListItem>
                            );
                        })
                    }
                </List>
            </Collapse>
        </List>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {

    },
    icon: {
        color: theme.palette.primary.contrastText
    }
}));