import axios from 'axios';
import { handleApiError, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';


export const sendCodeRequestTelegram = async (phoneNumber: string, token: any, enqueueSnackbar: any): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();

        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/telegram/send-code-request`, {phoneNumber: phoneNumber},
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
        return ret.data;
    } catch (err) {
        handleApiError(err, enqueueSnackbar, "Der Code konnte nicht gesendet werden!");
    }
    return null as any;
}