import React from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { formatEuropeanDateTime } from '../../../../helpers/date-management';
import { buildCellAssignedColumn } from '../../../tables/GroupSelectionTable';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { ObjectSharedFolderTable } from '../../../../models/DataFileTree';
import { IconButton, Tooltip } from '@mui/material';
import { Delete, Edit, Folder } from '@mui/icons-material';
import { errorColor } from '../../../../styles/colors';
import { api } from '../../../../api';
import { useSnackbar } from 'notistack';
import { getApiToken } from '../../../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

export const ObjectSharedFolderDataTableSelection = ({ rows, selectedRows, onSelectionChange, objects, onRefresh, onEdit }
    : { rows: ObjectSharedFolderTable[], selectedRows: ObjectSharedFolderTable[], onSelectionChange: any, objects: ObjectUnit[], onRefresh?: any, onEdit?: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState<ObjectSharedFolderTable>();
    const {t} = useTranslation();

    let columns = [
        {
            name: ObjectSharedFolderTable.Columns.tools,
            title: t("shared-folders-objects-all.edit"),
            getCellValue: (row: ObjectSharedFolderTable) => row.name,
        },
        {
            name: ObjectSharedFolderTable.Columns.name,
            title: "Name",
            getCellValue: (row: ObjectSharedFolderTable) => row.name,
        },
        {
            name: ObjectSharedFolderTable.Columns.name,
            title: "Name",
            getCellValue: (row: ObjectSharedFolderTable) => row.name,
        },
        {
            name: ObjectSharedFolderTable.Columns.objectCreatorId,
            title: t("shared-folders-objects-all.createdByObject"),
            getCellValue: (row: ObjectSharedFolderTable) => objects.find(x => row.objectCreatorId == x.id)?.name,
        },
        {
            name: ObjectSharedFolderTable.Columns.objectMemberIds,
            title: t("shared-folders-objects-all.accessObjects"),
            getCellValue: (row: ObjectSharedFolderTable) => {
                return objects.filter(x => row.objectMemberIds.includes(x.id)).reduce((prevVal, curVal) => {
                    if (!curVal.name) return prevVal
                    return curVal.name + ", " + prevVal;
                }, "");;
            },
        },
        {
            name: ObjectSharedFolderTable.Columns.creationTimestamp,
            title: t("shared-folders-objects-all.creationDate"),
            getCellValue: (row: ObjectSharedFolderTable) => formatEuropeanDateTime(row.creationTimestamp),
        }

    ];


    const Cell = (props: any) => {
        const { column, row }: { column: any, row: ObjectSharedFolderTable } = props;

        if (column.name === ObjectSharedFolderTable.Columns.objectMemberIds) {
            let names = objects.filter(x => row.objectMemberIds.includes(x.id)).map(x => x.name);
            return buildCellStringArray(props, names, `Zugriff: ${names.length} Objekte`);
        } else if (column.name === ObjectSharedFolderTable.Columns.tools) {

            return <Table.Cell {...props}>
                {onEdit &&
                    <Tooltip title={t("myUnitsSettings.edit")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>
                }


                <Tooltip title="Löschen">
                    <IconButton
                        style={{ color: errorColor }}
                        onClick={async () => {
                            setCurrentRow(row);
                            setDeleteConfirmationDialogVisible(true);

                            //           await onDelete(row);
                        }}
                        size="large">
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Table.Cell>;
        }

        return <Table.Cell {...props} />;
    };


    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == ObjectSharedFolderTable.Columns.tools) return { columnName: el.name, width: 180 }
        return { columnName: el.name, width: 300 }
    }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: ObjectUnit) => row.id}
                columns={columns}
            >
                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedRows.map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title={`Sind Sie sicher, den Geteilten Ordner ${currentRow?.name} zu löschen ? Damit sind alle Daten von dem Ordner gelöscht!`}
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {
                    if (!currentRow) return;

                    const token = getApiToken(history);
                    await api.DeleteObjectSharedFolder(currentRow.objectCreatorId, currentRow.id, token, enqueueSnackbar);
                    setDeleteConfirmationDialogVisible(false);
                    onRefresh();

                }}
            />
        </div>
    );
};
