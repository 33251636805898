import { ButtonGroup, Button, Theme, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Maximize } from '@mui/icons-material';

export const NumberInput = ({ value, onValueChanged, disabled = false, width = 60, onBlur, onChangeText }: { value: number, onValueChanged: (value: number) => void, disabled?: boolean, width?: number, onBlur? : any, onChangeText? : any }) => {

    const classes = useStyles();

    return (
        <ButtonGroup color="primary">
            <Button
                disabled={disabled}
                className={classes.minusButton}
                variant="contained"
                onBlur={(event)=>{
                    if (onBlur){
                        onBlur(event.target.value);
                    }
                }}
                onClick={() => {
                    onValueChanged(--value);
                }}
            >
                <Maximize />
            </Button>
            <TextField
                disabled={disabled}
                sx={{
                    '.MuiInputBase-input': { fontSize: 20, fontWeight: 'bold', textAlign: 'center' },
                }}
                style={{ width: width }}
                value={value}
                inputMode='numeric'
                onBlur={(event)=>{
                    if (onBlur){
                        onBlur(event.target.value);
                    }
                }}
                onChange={(event) => {
                    if (event.target.value == "") return;
                    if (onChangeText){
                        onChangeText(parseInt(event.target.value))
                    }else{
                        onValueChanged(parseInt(event.target.value))
                    }
                }
                }></TextField>
            <Button
                disabled={disabled}
                className={classes.plusButton}
                variant="contained"
                onClick={() => {
                    onValueChanged(++value);
                }}
            >
                <Add />
            </Button>
        </ButtonGroup>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    minusButton: {
        paddingTop: 18,
    },
    plusButton: {

    },
}));