import React from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { UserProperty } from '../../../../models/UserProperty';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { Product } from '../../../../models/Product';
import { ObjectUnit } from '../../../../models/ObjectUnit';
import { formatEuropeanDateTime } from '../../../../helpers/date-management';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { useTranslation } from 'react-i18next';

export const UserServiceWorkerMultipleSelectionDataTable = ({ rows, groups, selectedUserServiceWorkers, onSelectionChange, objects }
    : { rows: UserServiceWorker[], groups: GroupLicenseTable[], selectedUserServiceWorkers: UserServiceWorker[], onSelectionChange: any, objects: ObjectUnit[] }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();
    
    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === UserServiceWorker.Columns.groups) {
            let names = groups.filter(x => x.userServiceWorkerIds && x.userServiceWorkerIds.includes(row.id)).map(x => x.notificationName);
            return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} ${t("homeDrawerFilter.units")}`);
        }
        if (column.name === "devices") {
            let names = groups.filter(x => x.userServiceWorkerIds && x.userServiceWorkerIds.includes(row.id)).map(x => x.uniqueDeviceId);
            return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} DeviceIds`);
        }
        return <Table.Cell {...props} />;
    };

    const columns = [
        {
            name: UserServiceWorker.Columns.type,
            title: t("myObjectSettings.serviceStaffType"),
            getCellValue: (row: UserServiceWorker) => UserServiceWorker.GetLabel(row.type, t),
        },
        {
            name: UserServiceWorker.Columns.name,
            title: "Name",
            getCellValue: (row: UserServiceWorker) => row.name,
        },
        {
            name: UserServiceWorker.Columns.phoneNumber,
            title: t("myUnitsSettings.phoneNumber"),
            getCellValue: (row: UserServiceWorker) => row.phoneNumber,
        },
        {
            name: UserServiceWorker.Columns.email,
            title: "Email",
            getCellValue: (row: UserServiceWorker) => row.email,
        },
        {
            name: UserServiceWorker.Columns.loginUsername,
            title: t("myObjectSettings.loginUsername"),
            getCellValue: (row: UserServiceWorker) => row.loginUsername,
        },
        {
            name: UserServiceWorker.Columns.signedInObject,
            title: t("myObjectSettings.activeInObject"),
            getCellValue: (row: UserServiceWorker) => {
                let object = objects.find(x => x.id == row?.serviceWorkerSignedIntoObject?.signedInObjectId);
                return (
                    !object ? t("object-sessions.notActive") : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            },
        },
        {
            name: UserServiceWorker.Columns.signedInObjectSince,
            title: t("myObjectSettings.activeInObjectSince"),
            getCellValue: (row: UserServiceWorker) => row?.serviceWorkerSignedIntoObject?.signedInObjectId ?
                formatEuropeanDateTime(row?.serviceWorkerSignedIntoObject?.signedInObjectSinceTimestamp) :
                t("object-sessions.notActive")
        },
        {
            name: UserServiceWorker.Columns.nfcCode,
            title: "RFID Chip Code",
            getCellValue: (row: UserServiceWorker) => row.nfcCode,
        },

        {
            name: UserServiceWorker.Columns.isEnabled,
            title: t("myUnitsSettings.activated"),
            getCellValue: (row: UserServiceWorker) => row.isEnabled ? t("myUnitsSettings.activated") : t("myUnitsSettings.deactivated"),
        },
        {
            name: UserServiceWorker.Columns.groups,
            title: t("myUnitsSettings.assignedUnits"),
            getCellValue: (row: UserServiceWorker) => {
                return groups.filter(x => x.userServiceWorkerIds && x.userServiceWorkerIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.notificationName) return prevVal
                    return curVal.notificationName + ", " + prevVal;
                }, "");
            },
        },
        {
            name: "devices",
            title: "DeviceIds",
            getCellValue: (row: UserServiceWorker) => {
                return groups.filter(x => x.userServiceWorkerIds && x.userServiceWorkerIds.includes(row.id)).reduce((prevVal, curVal) => {
                    if (!curVal.uniqueDeviceId) return prevVal
                    return curVal.uniqueDeviceId + ", " + prevVal;
                }, "");;
            },
        },

    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => { return { columnName: el.name, width: 180 } }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: ObjectUnit) => row.id}
                columns={columns}
            >

                <FilteringState
                    columnExtensions={[
                        {
                            columnName: Product.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[{ columnName: Product.Columns.filter, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedUserServiceWorkers.map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                    columnExtensions={[
                        {
                            columnName: Product.Columns.unitPrice,
                            width: 128,
                        },
                        {
                            columnName: Product.Columns.actions,
                            width: 160,
                        },
                    ]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[Product.Columns.filter]}
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
        </div>
    );
};
