import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../api";
import { formatEuropeanDateSpecific, formatUnixSecTimestampEuropeanDateInMinutes, isOlderThanFifteenMinutes } from "../../helpers/date-management";
import { getApiToken } from "../../helpers/storage-management";
import { GroupMotion } from "../../models/GroupMotion";
import { ReactComponent as MotionSensorSvg } from '../../assets/svgs/motion-sensor.svg'
import { EvaluationChoice } from "../../models/EvaluationChoice";
import { GroupSettings, LocationInformation } from "../../models/GroupSettings";
import { GroupDetailsPrintTable } from "../tables/GroupDetailsPrintTable";
import { ReactComponent as GreenbirdLogoGrey } from '../../assets/svgs/greenbird_logo_grey.svg'
import { ReactComponent as CheckMeNowIcon } from '../../assets/svgs/check-me-now_icon.svg'
import { GroupLicenseTable } from "../../models/GroupLicenseTable";
import { GlobalContext } from "../authorized/PortalPage";
import { UserAccount } from "../../models/UserAccount";
import { NetworkImageNonDialog } from "../images/NetworkImageNonDialog";

export const GroupTypePrintDisplay = ({ evaluationChoice, groupsSettings, title = "Cockpit Bericht", title2 = "Ausgewählte Einheiten:" }: { evaluationChoice: EvaluationChoice, groupsSettings: GroupLicenseTable[], title?: string, title2?: string }) => {
    const history = useHistory();

    const { userAccount }: { userAccount: UserAccount } = React.useContext(GlobalContext);

    const buildGroupTypeDisplay = () => {
        switch (evaluationChoice) {
            case EvaluationChoice.All:
                return <Typography color={"primary"} component={"h5"} variant={"h4"}>{`Alle`}</Typography>;
            case EvaluationChoice.Single:
                return <GroupDetailsPrintTable groups={groupsSettings} />
            case EvaluationChoice.Multiple:
                return <Typography color={"primary"} component={"h5"} variant={"h4"}>{`Kombination von ${groupsSettings.length}`}</Typography>;
            default:
                return;
        }
    }

    return (
        <Grid container item direction="row" alignItems="flex-start" spacing={2}>
            <Grid item xs={3} container direction="column">
                <Grid item container alignItems="center">
                    <Grid item>
                        <CheckMeNowIcon height={50} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" component="h4">Check-Me-Now</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography >Bericht erstellt am: {formatEuropeanDateSpecific(new Date())}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={6} container direction={"column"} alignItems={"center"} className="group-print-header">
                <Grid item style={{ alignSelf: 'center' }}>
                    <Typography style={{ textAlign: 'center' }} color={"primary"} component={"h2"} variant={"h2"}>{title}</Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                    <Typography color={"primary"} component={"h5"} variant={"h4"}>{title2}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} container direction="column">
                <Grid item style={{ alignSelf: 'flex-end' }}>
                    {
                        userAccount?.logo?.fileName ?
                            <div style={{ height: 50 }}>
                                <NetworkImageNonDialog src={`${process.env.REACT_APP_BASIC_URI}/users/${userAccount.id}/logos/${userAccount.logo?.fileName}`} hideOverlay={true} />
                            </div>
                            :
                            <GreenbirdLogoGrey height={50} />
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'center', textAlign: 'center' }}>
                {buildGroupTypeDisplay()}
            </Grid>
        </Grid>
    )
}

