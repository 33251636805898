import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const assignServiceWorkerToObject = async (objectId: string,userServiceWorkerId: string, token: string, enqueueSnackbar: any) => {

    if (!token || !enqueueSnackbar) return;
    let userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-workers/${userServiceWorkerId}/objects/${objectId}/assign`,null,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Servicekraft wurde dem Objekt erfolgreich zugewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};

export const assignServiceWorkersToObject = async (objectId: string,userServiceWorkerIds: string[], token: string, enqueueSnackbar: any) => {

    if (!token || !enqueueSnackbar) return;
    let userId = getUserIdFromStorage();

    await axios.patch(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/service-workers/multiple/objects/${objectId}/assign`,{stringArray: userServiceWorkerIds},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Servicekräfte wurden dem Objekt erfolgreich zugewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};