import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { primaryColor } from "../../../../../App";
import { FeedbackDisplayType } from "../../../../../models/GroupSettings";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { ChartLabelType } from "../../../../charts/Base";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { ChartCalcType, TimeChoice } from "../../../../core/TimeChoice";

export const GetGroupFeedbackPerVisitor = ({ data, dataField, allData, setChartData, childRef, loadingCompleted, childRefChart, feedbackDisplayTypeChart }
    : { data: any, dataField: any, allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any, feedbackDisplayTypeChart: FeedbackDisplayType }) => {
    const [chartChoice, setChartChoice] = React.useState('2');

    const newData = React.useMemo(() => data.map((x: any) => {
        const sum = feedbackDisplayTypeChart ? x.negativeAmount + x.neutralAmount + x.positiveAmount + x.veryPositiveAmount + x.veryNegativeAmount : x.negativeAmount + x.neutralAmount + x.positiveAmount
        if (!x.blockCount) {
            x.blockCount = 1;
        }
        if (isNaN(sum)) {
            return {
                dateDisplay: x.dateDisplay,
                date: x.date,
                allFeedback: 0,
            }
        }
        return {
            dateDisplay: x.dateDisplay,
            date: x.date,
            allFeedback: parseFloat(((sum / x.blockCount) * 100).toFixed(2)),
        }
    }), [data, feedbackDisplayTypeChart]);

    const sumFeedbackPercent = React.useMemo(() => newData.reduce((prevVal: any, x: any) => {
        return prevVal + x.allFeedback;
    }, 0), [data, feedbackDisplayTypeChart]);

    const text = `Gesamt Feedback / Gesamt Besucher - Verhältnis in %<br>Gesamtdurchschnitt: <b>${(sumFeedbackPercent / data.length).toFixed(2)}%</b>`;

    const colors = feedbackDisplayTypeChart == FeedbackDisplayType.AdvancedWithVeryPostiveNegative ? [primaryColor] : [primaryColor];



    const buildGridItem = () => {

        switch (chartChoice) {
            case '0':
                return <LineChart
                    chartLabelType={ChartLabelType.Percent}
                    childRefChart={childRefChart}
                    colors={colors}
                    data={newData}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    chartLabelType={ChartLabelType.Percent}

                    childRefChart={childRefChart}
                    colors={colors}
                    data={newData}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    chartLabelType={ChartLabelType.Percent}
                    childRefChart={childRefChart}
                    colors={colors}
                    data={newData}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} chartCalcType={ChartCalcType.Sum} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}