import React, { useRef } from "react";
import { AccordionDetails, Accordion, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, AccordionSummary, Typography, InputAdornment, IconButton, Checkbox, Divider, Button, Alert, CircularProgress, Switch, Autocomplete } from "@mui/material";
import { Apartment, CloudOff, Delete, ExpandMore, Print, QrCode2, Refresh, Shop, SignalCellularNodata, Smartphone, Swipe, Visibility, VisibilityOff, Window } from "@mui/icons-material";
import { ReactComponent as MapMarkerRadiusSvg } from '../../assets/svgs/map-marker-radius.svg'
import { GroupSettings, GroupSettingsType, QrCodeInformation, SensorControl, ToiletType, User } from "../../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../../models/UserGroupSettingsTemplate";
import { GroupSettingFieldsUseStyles } from "../../styles/GroupSettingFieldsStyles";
import { GroupSettingsMappingKeys } from "../../helpers/group-settings-management";
import { formatEuropeanDateTime } from "../../helpers/date-management";
import { ObjectUnit } from "../../models/ObjectUnit";
import { PropertyType, UserProperty } from "../../models/UserProperty";
import { errorColor } from "../../styles/colors";
import { api } from "../../api";
import { getApiToken } from "../../helpers/storage-management";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Group, GroupType } from "../../models/Group";
import { EvaluationChoice } from "../../models/EvaluationChoice";
import { GroupTypePrintDisplay } from "../common/GroupTypePrintDisplay";
import { useReactToPrint } from "react-to-print";
import { NetworkImage } from "../images/NetworkImage";
import { FileDownload } from "@mui/icons-material";
import { primaryColor } from "../../App";
import html2canvas from 'html2canvas';
import { ChromePicker, SketchPicker } from "react-color";
import { GroupSettingFieldsLogo } from "./GroupSettingFields.Logo";
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { handleDownloadImage, noNullInInputNumber } from "../common/qrcode/qrcodeManagement";
import { GroupSelectionAssignMultupleGroupToObjectDialog } from "../authorized/extendedapp/entireEval/GroupSelectionAssignMultupleGroupToObjectDialog";
import { UserServiceWorker } from "../../models/UserServiceWorker";
import { GroupLicenseTable } from "../../models/GroupLicenseTable";
import { getUserIdFromStorage } from "../../helpers/common";
import { GroupSelectionAssignMultipleGroupToPropertyDialog } from "../authorized/extendedapp/entireEval/GroupSelectionAssignMultipleGroupToPropertyDialog";
import { OfflineDataDialog } from "../dialogs/offlineData/OfflineDataDialog";
import { DigitalCheckpointDialog } from "../dialogs/groupSettings/DigitalCheckpointDialog";
import { NavigatedFromType } from "../../models/NavigatedFrom";
import digiCheckpoint from '../../assets/image/shop/digicheckpoint.png';
import { ObjectQrCodeDialog } from "../authorized/extendedapp/ObjectQrCodeDialog";
import { IsObjectSignInRfidComponent } from "./GroupSettingFieldsCleaner";
import { objectCustomerName } from "../authorized/portal/ManagerCustomers";
import { NumberHelper } from "../../helpers/number-helper";
import { TextFieldGroupSettings } from "./components/groupSettings/TextFieldGroupSettings";
import { SettingsContext, SettingsEditContext } from "../authorized/PortalPage";
import { StandardWorkItem } from "../../models/StandardItem";
import { useTranslation } from "react-i18next";

export const GroupSettingFieldsLocation = ({ reloadProps, reloadObject, row, onChange, objectUnit, setObjectUnit, properties, group, qrCode, logo, type, refreshLogo, token, loadData, loadStandardRoomTypes, standardRoomTypes }:
    { reloadProps: any, reloadObject: any, row: GroupSettings | UserGroupSettingsTemplate; onChange: any; objectUnit: ObjectUnit | undefined, setObjectUnit: any, properties: UserProperty[] | undefined, group: Group | undefined, qrCode: any, logo: any, type: GroupSettingsType, refreshLogo: any, token: any, loadData: any, loadStandardRoomTypes: any, standardRoomTypes: StandardWorkItem[] }) => {


    const { simId, teamViewerId, setSimId, setTeamViewerId } = React.useContext(SettingsEditContext);

    const classes = GroupSettingFieldsUseStyles();
    const [showPassword, setShowPassword] = React.useState(true);
    const refPrint: any = useRef();
    const [currentPropertyType, setCurrentPropertyType] = React.useState(PropertyType.Standard);

    React.useEffect(() => {

        var timer = setTimeout(() => {
            setShowPassword(false);
        }, 500);

        return () => {
            clearTimeout(timer);
        }
    }, []);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    if (row.locationInformation && !row.locationInformation.deviceRestartTime) {
        row.locationInformation.deviceRestartTime = "00:00:00"
    }
    const handlePrint = useReactToPrint({

        content: () => refPrint.current,
        onAfterPrint: () => {

        }

    });

    const [qrCodeDialogVisible, setQrCodeDialogVisible] = React.useState(false);

    const [groupAssignPropertyMultipleDialogVisible, setGroupAssignPropertyMultipleDialogVisible] = React.useState(false);
    const [groupAssignObjectMultipleDialogVisible, setGroupAssignObjectMultipleDialogVisible] = React.useState(false);
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [groupLicenseTable, setGroupLicenseTable] = React.useState<GroupLicenseTable>();
    const [groupLicenseTables, setGroupLicenseTables] = React.useState<GroupLicenseTable[]>([]);

    const [isOfflineDataVisible, setIsOfflineDataVisible] = React.useState(false);
    const [isDigitalCheckpointDialogVisible, setIsDigitalCheckpointDialogVisible] = React.useState(false);
    const { refreshSettings } = React.useContext(SettingsContext);
    const [expanded, setExpanded] = React.useState(false);
    const { t } = useTranslation();


    const buildLocationInformationForCheckpoint = () => {
        if (!group || type == GroupSettingsType.Template) return;
        return (
            <React.Fragment>
                <Grid item container spacing={3} justifyContent="flex-start" alignItems="center">
                    <Grid item>
                        <Alert severity="info">{t("myUnitsSettings.manageYourLogo")}</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <GroupSettingFieldsLogo
                            row={row}
                            onChange={onChange}
                            group={group}
                            type={type}
                        />
                    </Grid>
                    <Grid item>
                        <Alert severity="info">{t("myUnitsSettings.printYourLabelWeRecommendLabelPrinterBrotherVC500w")}</Alert>
                    </Grid>
                    <Grid item xs={12} container direction="row" spacing={2}>
                        <Grid item>
                            <Button size="small" variant="contained" startIcon={<FileDownload />} color="primary" href={qrCode} download={`${row.locationInformation?.notificationName}.png`}>
                                {t("myUnitsSettings.downloadQRCode")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" variant="contained" startIcon={<Print />} color="primary" onClick={handlePrint}>
                                {t("myUnitsSettings.printQRCode")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" variant="contained" startIcon={<FileDownload />} color="primary" onClick={() => handleDownloadImage(refPrint)}>
                                {t("myUnitsSettings.downloadLabel")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextFieldGroupSettings
                            label={t("myUnitsSettings.printSize")}
                            value={row.locationInformation?.qrCodeInformation?.printSize}
                            type="number"
                            onChange={(event) => {
                                if (!row.locationInformation?.qrCodeInformation.printSize) return;
                                row.locationInformation.qrCodeInformation.printSize = noNullInInputNumber(event);
                                onChange({ target: { name: "row", value: "" } });
                            }}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item>
                        <TextFieldGroupSettings
                            label={t("myUnitsSettings.fontSizeButton")}
                            value={row.locationInformation?.qrCodeInformation?.fontSizeButton || 0}
                            type="number"
                            onChange={(event) => {
                                if (!row.locationInformation?.qrCodeInformation.fontSizeButton) return;
                                row.locationInformation.qrCodeInformation.fontSizeButton = noNullInInputNumber(event);
                                onChange({ target: { name: "row", value: "" } });
                            }}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item>
                        <TextFieldGroupSettings
                            label={t("myUnitsSettings.fontSizeBottomRight")}
                            value={row.locationInformation?.qrCodeInformation?.fontSizeBottomRight || 0}
                            type="number"
                            onChange={(event) => {
                                if (!row.locationInformation?.qrCodeInformation.fontSizeBottomRight) return;
                                row.locationInformation.qrCodeInformation.fontSizeBottomRight = noNullInInputNumber(event);
                                onChange({ target: { name: "row", value: "" } });
                            }}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item>
                        <TextFieldGroupSettings
                            label="Url"
                            value={row.locationInformation?.qrCodeInformation?.url || ""}
                            onChange={(event) => {
                                if (!row.locationInformation?.qrCodeInformation) return;
                                row.locationInformation.qrCodeInformation.url = event.target.value;
                                onChange({ target: { name: "row", value: "" } });

                            }}
                            variant="filled"
                        />
                    </Grid>

                    <Grid container item justifyContent="center" direction="row">
                        <Grid item>
                            <Grid item style={{ textAlign: 'center' }}>
                                <Typography variant="h3">{t("myUnitsSettings.preview")}</Typography>
                            </Grid>
                            <Button variant="contained" startIcon={<Refresh />} color="primary" onClick={refreshLogo}>
                                {t("myUnitsSettings.refreshPreview")}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={3} spacing={3} container direction="column" style={{ textAlign: 'center', alignItems: 'center' }}>
                        <Grid item>
                            <Typography variant="h5">{t("myUnitsSettings.labelBackgroundColor")}</Typography>
                        </Grid>
                        <Grid item>
                            <ChromePicker color={row.locationInformation?.qrCodeInformation?.backgroundColor}
                                onChange={(event) => {
                                    if (!row.locationInformation?.qrCodeInformation.backgroundColor) return;
                                    row.locationInformation.qrCodeInformation.backgroundColor = event.hex;
                                    onChange({ target: { name: "row", value: "" } });

                                }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} spacing={3} container direction="column" style={{ textAlign: 'center', alignItems: 'center' }}>
                        <Grid item>
                            <Typography variant="h5">{t("myUnitsSettings.borderColor")}</Typography>
                        </Grid>
                        <Grid item>
                            <ChromePicker color={row.locationInformation?.qrCodeInformation?.borderColor} onChange={(event) => {
                                if (!row.locationInformation?.qrCodeInformation.borderColor) return;
                                row.locationInformation.qrCodeInformation.borderColor = event.hex;
                                onChange({ target: { name: "row", value: "" } });

                            }} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item>
                            {buildPrintItemGroup(row.locationInformation?.qrCodeInformation.printSize, row.locationInformation?.qrCodeInformation.fontSizeButton, row.locationInformation?.qrCodeInformation.fontSizeBottomRight, refPrint, qrCode, row.locationInformation?.qrCodeInformation, logo, row.locationInformation?.notificationName, !!objectUnit ? objectUnit.name : row.locationInformation?.objectName, row.logo)}
                        </Grid>
                    </Grid>


                </Grid>
            </React.Fragment>
        );
    }

    const buildLocationInformationForDisplay = () => {
        if (!group) return;
        if (group.groupType != GroupType.DisplayWallApp) return;
        return (
            <React.Fragment>
                <Grid item>
                    <Typography variant="h4" fontWeight={"bold"}>{t("myUnitsSettings.offlineData")}</Typography>
                </Grid>
                <Grid container item spacing={3} >
                    <Grid item lg={2}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={row.locationInformation?.isExecuteManuellOfflineEnabled}
                                onChange={(event: any) => {
                                    event.target['value'] = event.target.checked;
                                    onChange(event);
                                }}
                                name={GroupSettingsMappingKeys.isExecuteManuellOfflineEnabled}
                                color="primary" />}
                            label={t("myUnitsSettings.runOfflineDataManuallyNoAutomaticExecutionInTheApp")} />
                    </Grid>
                    <Grid item lg={2}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={row.locationInformation?.isUploadOfflineDataEnabled}
                                onChange={(event: any) => {
                                    event.target['value'] = event.target.checked;
                                    onChange(event);
                                }}
                                name={GroupSettingsMappingKeys.isUploadOfflineDataEnabled}
                                color="primary" />}
                            label={t("myUnitsSettings.automaticUploadOfflineData")} />
                    </Grid>
                    {row.locationInformation?.isUploadOfflineDataEnabled &&
                        <Grid item lg={3}>
                            <Button
                                size="large"
                                fullWidth
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={32} /> : <SignalCellularNodata />}
                                endIcon={<CloudOff />}
                                variant={'contained'}
                                color="primary"
                                onClick={async () => {
                                    setIsOfflineDataVisible(true);
                                }}
                            >
                                {t("myUnitsSettings.showCurrentOfflineData")}
                            </Button>
                        </Grid>
                    }
                    <Grid item lg={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={row.locationInformation?.isExecuteOfflineDataNowEnabled}
                                    onChange={(event: any) => {
                                        event.target['value'] = event.target.checked;
                                        onChange(event);
                                    }}
                                    name={GroupSettingsMappingKeys.isExecuteOfflineDataNowEnabled}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={t("myUnitsSettings.executeOfflineFunctionsNow")}
                        />
                    </Grid>

                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Typography variant="h4" fontWeight={"bold"}>Geräte Optionen</Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={row.isDeviceRestartEnabled}
                                onChange={(event: any) => {
                                    event.target['value'] = event.target.checked;
                                    onChange(event);
                                }}
                                name={GroupSettingsMappingKeys.isDeviceRestartEnabled}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t("myUnitsSettings.deviceRestartEnabledAfterSaving")}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox
                            checked={row.locationInformation?.isNavigationBarEnabled}
                            onChange={(event: any) => {
                                event.target['value'] = event.target.checked;
                                onChange(event);
                            }}
                            name={GroupSettingsMappingKeys.isNavigationBarEnabled}
                            color="primary" />}
                        label={t("myUnitsSettings.navigationBarStatusBarEnabled")} />
                </Grid>
                <Grid container item spacing={3} >
                    <Grid item lg={4} xs={9}>
                        <TimePicker
                            ampm={false}
                            slotProps={{ textField: { variant: 'outlined' } }}
                            views={["hours", "minutes", "seconds"]}
                            format="HH:mm:ss"
                            label={t("myUnitsSettings.24hRebootDeviceRepetition")}
                            value={new Date(`${"2017-03-13"} ${row.locationInformation?.deviceRestartTime}`)}
                            onChange={(value: any) => {
                                if (value != "Invalid Date" && value) {
                                    let res = { target: { name: 'deviceRestartTime', value: value } };
                                    onChange(res);
                                }
                            }} />
                    </Grid>
                    <Grid item lg={2}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={row.locationInformation?.isDeviceRestartTimeEnabled}
                                onChange={(event: any) => {
                                    event.target['value'] = event.target.checked;
                                    onChange(event);
                                }}
                                name="isDeviceRestartTimeEnabled"
                                color="primary" />}
                            label={t("myUnitsSettings.24hDeviceRebootEnabled")} />
                    </Grid>
                    {row.locationInformation?.lastDeviceRestartTimestamp &&
                        <Grid item lg={3}>
                            <Typography variant="body1">{t("myUnitsSettings.lastDeviceRestartNoDeviceRestartYet")}: {new Date(row.locationInformation.lastDeviceRestartTimestamp).getTime() < 1 ?
                                t("myUnitsSettings.noDeviceRestartYet")
                                :
                                formatEuropeanDateTime(row.locationInformation?.lastDeviceRestartTimestamp)}</Typography>
                        </Grid>
                    }
                </Grid>
            </React.Fragment>
        );
    }

    const buildSystemProperties = () => {
        return (
            <Grid item container direction={"row"} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" fontWeight={"bold"}>{t("myUnitsSettings.systemFeatureInformation")}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        size="large"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={32} /> : <Swipe />}
                        endIcon={<Window />}
                        variant={'contained'}
                        color="primary"
                        onClick={async () => {
                            if (!row.id) return;
                            setLoading(true);
                            setCurrentPropertyType(PropertyType.System);
                            let res = await api.GetAllGroupsByUserIdAsync(token, getUserIdFromStorage() || "");
                            setGroupLicenseTables(res);
                            setGroupLicenseTable(res.find(x => x.id == row.id));
                            setGroupAssignPropertyMultipleDialogVisible(true);
                            setLoading(false);
                        }}
                    >
                        {t("myUnitsSettings.assignOrUnassignFeatureToUnit")}
                    </Button>
                </Grid>
                {properties &&
                    <Grid item xs={12} container direction={"row"} spacing={3}>

                        {properties.filter(x => x.propertyType == PropertyType.System).map(x => {
                            return (
                                <Grid container item xs={12} md={3} alignItems="center">
                                    <Grid item>
                                        <TextFieldGroupSettings
                                            fullWidth
                                            variant={"filled"}
                                            margin="normal"
                                            name="propertyName"
                                            label="Name"
                                            disabled
                                            value={x.name || ''}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            style={{ color: errorColor }}
                                            onClick={async () => {
                                                if (row.id) {
                                                    const token = getApiToken(history);
                                                    await api.unAssignPropertyToGroup(row.id, x.id, token, enqueueSnackbar);
                                                }
                                            }}
                                            size="large">
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            );
                        })}

                        {!objectUnit &&
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        }
                    </Grid>
                }
                {properties?.some(x => x.propertyType == PropertyType.System) &&
                    <Grid item container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m²</InputAdornment>
                                }}
                                variant={"filled"}
                                margin="normal"
                                name="areaSize"
                                type="number"
                                label={t("myUnitsSettings.areaM2")}
                                value={row.locationInformation?.areaSize || 0}
                                onChange={(event) => {
                                    if (row.locationInformation)
                                        row.locationInformation.areaSize = parseFloat(event.target.value);
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Anzahl</InputAdornment>
                                }}
                                variant={"filled"}
                                margin="normal"
                                name="machineCount"
                                type="number"
                                label="Maschinen Anzahl"
                                value={row.locationInformation?.machineCount || 0}
                                onChange={(event) => {
                                    if (row.locationInformation)
                                        row.locationInformation.machineCount = parseFloat(event.target.value);
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                variant={"filled"}
                                margin="normal"
                                name="machineUsagePercent"
                                type="number"
                                label="Maschinen Prozenz Anteil"
                                value={row.locationInformation?.machineUsagePercent || 0}
                                onChange={(event) => {
                                    if (row.locationInformation)
                                        row.locationInformation.machineUsagePercent = parseFloat(event.target.value);
                                    onChange({ target: { name: "row", value: "" } });
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
        )
    }

    return (
        <Accordion expanded={expanded} className={classes.hover} onChange={async (event, expanded) => {
            setExpanded(expanded);
            if (expanded && standardRoomTypes.length < 1) {
                setLoading(true);
                await loadStandardRoomTypes();
                setLoading(false);
            }
        }}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <MapMarkerRadiusSvg width={40} height={40} fill={"#000000"} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">Location</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={2}>

                    {buildLocationInformationForCheckpoint()}
                    {buildLocationInformationForDisplay()}
                    <Grid item container xs={12} direction={"row"} spacing={3}>
                        <Grid item xs={6} lg={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                variant={"filled"}
                                label={"Team Viewer ID"}
                                disabled={loading}
                                value={teamViewerId}
                                onChange={(event) => {
                                    setTeamViewerId(event.target.value);

                                }}
                            />
                        </Grid>

                        <Grid item xs={6} lg={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                variant={"filled"}
                                label={"SIM ID"}
                                disabled={loading}
                                value={simId}
                                onChange={(event) => {
                                    setSimId(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {group?.groupType == GroupType.Checkpoint &&
                        <Grid item container direction={"column"} spacing={2}>
                            <Grid item>
                                <Typography variant="h4" fontWeight={"bold"}>Digital Checkpoint</Typography>
                            </Grid>
                            <Grid item container direction="row" spacing={3} alignItems="center">
                                <Grid item>
                                    <Button size="large" variant="contained" startIcon={<FileDownload />} color="primary" onClick={() => {
                                        setIsDigitalCheckpointDialogVisible(true);
                                    }}>
                                        Digital Checkpoint Aktivieren
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        size="large"
                                        startIcon={<Shop />}
                                        variant={'contained'}
                                        color="primary"

                                        onClick={() => {
                                            const queryParams = { showall: "1", chosenUnit: row.id || "", chosenUnitName: row.locationInformation?.notificationName || "", navigatedFrom: NavigatedFromType.BssdDigitalCheckpoint }
                                            const queryString = new URLSearchParams(queryParams).toString()
                                            history.push({
                                                pathname: '/portal/shop',
                                                search: queryString
                                            });
                                        }}
                                    >
                                        1 Digital Checkpoint kaufen
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <img src={digiCheckpoint} style={{ width: '100%', objectFit: 'contain' }} />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="h4" fontWeight={"bold"}>{t("myUnitsSettings.featureInformation")}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={32} /> : <Swipe />}
                            endIcon={<Window />}
                            variant={'contained'}
                            color="primary"
                            onClick={async () => {
                                if (!row.id) return;
                                setCurrentPropertyType(PropertyType.Standard);
                                setLoading(true);
                                let res = await api.GetAllGroupsByUserIdAsync(token, getUserIdFromStorage() || "");
                                setGroupLicenseTables(res);
                                setGroupLicenseTable(res.find(x => x.id == row.id));
                                setGroupAssignPropertyMultipleDialogVisible(true);
                                setLoading(false);
                            }}
                        >
                            {t("myUnitsSettings.assignOrUnassignFeatureToUnit")}
                        </Button>
                    </Grid>

                    {properties &&
                        <Grid item xs={12} container direction={"row"} spacing={3}>

                            {properties.filter(x => x.propertyType == PropertyType.Standard).map(x => {
                                return (
                                    <Grid container item xs={12} md={3} alignItems="center">
                                        <Grid item>
                                            <TextFieldGroupSettings
                                                fullWidth
                                                variant={"filled"}
                                                margin="normal"
                                                name="propertyName"
                                                label="Name"
                                                disabled
                                                value={x.name || ''}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                style={{ color: errorColor }}
                                                onClick={async () => {
                                                    if (row.id) {
                                                        const token = getApiToken(history);
                                                        await api.unAssignPropertyToGroup(row.id, x.id, token, enqueueSnackbar);
                                                    }
                                                }}
                                                size="large">
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                );
                            })}

                            {!objectUnit &&
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            }
                        </Grid>
                    }
                    <Grid item>
                        <Divider />
                    </Grid>
                    {buildSystemProperties()}
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" fontWeight={"bold"}>{t("myUnitsSettings.objectInformation")}</Typography>
                    </Grid>
                    <Grid item container spacing={3}>
                        <Grid item>
                            <Button
                                size="large"
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={32} /> : <Swipe />}
                                variant={'contained'}
                                color="primary"
                                onClick={async () => {
                                    if (!row.id) return;
                                    setLoading(true);
                                    let res = await api.GetAllGroupsByUserIdAsync(token, getUserIdFromStorage() || "");
                                    setGroupLicenseTables(res);
                                    setGroupLicenseTable(res.find(x => x.id == row.id));
                                    setGroupAssignObjectMultipleDialogVisible(true);

                                    const tempObjects = await api.getUserObjectUnits(token);
                                    setObjects(tempObjects);
                                    setLoading(false);
                                }}
                            >
                                {t("myUnitsSettings.assignOrUnassignObjectToUnit")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={32} /> : <QrCode2 />}
                                variant={'contained'}
                                color="primary"
                                onClick={async () => {
                                    setQrCodeDialogVisible(true);
                                }}
                            >
                                {t("myUnitsSettings.showQrCode")}
                            </Button>
                        </Grid>
                    </Grid>
                    {!objectUnit &&
                        <Grid item>
                            <Divider />
                        </Grid>}

                    <Grid item xs={12}>
                        <Grid item>
                            <FormControlLabel
                                label={<Typography>{t("myUnitsSettings.publicEasyServiceActivated")}</Typography>}
                                control={
                                    <Checkbox
                                        name="isPublicEasyServiceEnabled"
                                        color="primary"
                                        checked={row.cleaningOperations?.isPublicEasyServiceEnabled}
                                        onChange={(event: any, checked: boolean) => {
                                            if (!row.cleaningOperations) return;
                                            row.cleaningOperations.isPublicEasyServiceEnabled = checked;
                                            onChange({ target: { name: "row", value: "" } });
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>




                    {objectUnit ?
                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <IsObjectSignInRfidComponent row={row} onChange={onChange} group={group} />
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    name="objectName"
                                    label={t("myUnitsSettings.objectName")}
                                    disabled
                                    value={objectUnit.name || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    label={t("myUnitsSettings.customerName")}
                                    disabled
                                    value={objectUnit.customerName || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    name="roomName"
                                    label={t("myUnitsSettings.address")}
                                    disabled
                                    value={objectUnit.addressLine || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    label={t("myUnitsSettings.city")}
                                    disabled
                                    value={objectUnit.city || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    label={t("myUnitsSettings.state")}
                                    disabled
                                    value={objectUnit.state || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    label={t("myUnitsSettings.postalCode")}
                                    disabled
                                    value={objectUnit.zipCode || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    label={t("myUnitsSettings.country")}
                                    disabled
                                    value={objectUnit.country || ''}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    name="roomName"
                                    label={objectCustomerName}
                                    value={row.locationInformation?.roomName || ''}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    name="objectName"
                                    label="Objekt"
                                    value={row.locationInformation?.objectName || ''}
                                    onChange={onChange}
                                />
                            </Grid>
                        </Grid>

                    }

                    <Grid item xs={12}>
                        <Typography variant="h4" fontWeight={"bold"}>{t("myUnitsSettings.unitInformation")}</Typography>
                    </Grid>
                    <Grid item xs={12} container direction={"row"} spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("myUnitsSettings.roomType")}</FormLabel>
                                <RadioGroup
                                    row
                                    name="toiletType"
                                    value={row.locationInformation?.toiletType}
                                    onChange={onChange}
                                >
                                    <FormControlLabel value={ToiletType.Neutral} control={<Radio />} label={t("myUnitsSettings.neutral")} />
                                    <FormControlLabel value={ToiletType.Male} control={<Radio />} label={t("myUnitsSettings.male")} />
                                    <FormControlLabel value={ToiletType.Female} control={<Radio />} label={t("myUnitsSettings.female")} />
                                    <FormControlLabel value={ToiletType.Meeting} control={<Radio />} label={t("myUnitsSettings.meeting")} />
                                    <FormControlLabel value={ToiletType.Custom} control={<Radio />} label={t("myUnitsSettings.byUsageTypeDirectory")} />

                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {row.locationInformation?.toiletType == ToiletType.Custom &&
                            <Grid item xs={12} md={12}>
                                <Typography variant='h6' style={{ fontWeight: 'bold', marginBottom: 10 }}>{t("myUnitsSettings.selectUsageType")}</Typography>
                                <FormControl variant="filled" style={{ minWidth: 300 }}>
                                    <Autocomplete
                                        freeSolo
                                        loading={loading}
                                        value={row.locationInformation?.roomType}
                                        onChange={(event: any, newValue: string | null) => {
                                            if (!newValue) return;
                                            if (!row.locationInformation) return;

                                            row.locationInformation.roomType = newValue;
                                            refreshSettings();
                                        }}

                                        id="controllable-states-demo"
                                        options={standardRoomTypes.map(x => x.text)}
                                        sx={{ width: 300 }}
                                        renderInput={(params: any) => <TextFieldGroupSettings onChange={(event) => {
                                            if (!row.locationInformation) return;
                                            row.locationInformation.roomType = event.target.value;
                                            refreshSettings();
                                        }} {...params} label={t("myUnitsSettings.usageType")} />}
                                    />
                                </FormControl>


                            </Grid>
                        }
                        {row.locationInformation?.toiletType == ToiletType.Custom &&
                            <Grid item>
                                <TextFieldGroupSettings
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">m²</InputAdornment>
                                    }}
                                    variant={"filled"}
                                    margin="normal"
                                    name="roomSize"
                                    type="number"
                                    label={t("myUnitsSettings.areaM2")}
                                    value={row.locationInformation?.roomSize || 0}
                                    onChange={(event) => {
                                        if (row.locationInformation)
                                            row.locationInformation.roomSize = parseFloat(event.target.value);
                                        onChange({ target: { name: "row", value: "" } });
                                    }}
                                />
                            </Grid>
                        }


                        <Grid item xs={12} md={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                variant={"filled"}
                                margin="normal"
                                name="levelName"
                                label={t("myUnitsSettings.levelName")}
                                value={row.locationInformation?.levelName || ''}
                                onChange={onChange}
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                variant={"filled"}
                                margin="normal"
                                name="roomName"
                                label={t("myUnitsSettings.roomDesignation")}
                                value={row.locationInformation?.roomName || ''}
                                onChange={onChange}
                            />
                        </Grid> */}

                        <Grid item xs={12} md={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                variant={"filled"}
                                margin="normal"
                                name="notificationName"
                                label={t("myUnitsSettings.unitName")}
                                value={row.locationInformation?.notificationName || ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextFieldGroupSettings
                                fullWidth
                                disabled
                                variant={"filled"}
                                margin="normal"
                                name="uniqueDeviceId"
                                label="Device Id"
                                value={row.locationInformation?.uniqueDeviceId || group?.deviceId || "Keine Device Id"}
                            />
                        </Grid>
                        {group?.groupType == GroupType.DisplayWallApp &&
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    name="locationPassword"
                                    label={t("myUnitsSettings.localPassword")}
                                    type={showPassword ? 'text' : 'password'}
                                    helperText="Passwort wird ausgeblendet"
                                    value={row.locationInformation?.password || ''}
                                    onChange={onChange}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={async () => {
                                                        setShowPassword(value => !value);
                                                    }}
                                                    size="large">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>,
                                    }} />
                            </Grid>
                        }
                        {group?.groupType == GroupType.DisplayWallApp &&
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    name="localPassword"
                                    label={t("myUnitsSettings.localPassword")}
                                    value={row.locationInformation?.localPassword || ''}
                                    onChange={onChange}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={3}>
                            <QrCodeUrlComponent onChange={onChange} row={row} />
                        </Grid>
                    </Grid>
                    {group?.groupType == GroupType.DisplayWallApp &&

                        <Grid item xs={12}>
                            <Typography variant="h4" fontWeight={"bold"}>{t("myUnitsSettings.displayApkInformation")}</Typography>
                        </Grid>
                    }
                    {group?.groupType == GroupType.DisplayWallApp &&

                        <Grid item xs={12} container direction={"row"} spacing={3}>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    disabled
                                    label={t("myUnitsSettings.currentBuildVersionExpoUpdate")}
                                    value={row.locationInformation?.buildVersion || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    variant={"filled"}
                                    margin="normal"
                                    disabled
                                    label={t("myUnitsSettings.installedApkVersion")}
                                    value={row.locationInformation?.nativeApplicationVersion || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldGroupSettings
                                    fullWidth
                                    disabled
                                    variant={"filled"}
                                    margin="normal"
                                    label={t("myUnitsSettings.downloadedApkFile")}
                                    value={row.locationInformation?.downloadedFilename || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.locationInformation?.isAutoApkInstallEnabled}
                                            onChange={(event: any) => {
                                                event.target['value'] = event.target.checked;
                                                onChange(event);
                                            }}
                                            name="isAutoApkInstallEnabled"
                                            color="primary"
                                        />
                                    }
                                    label={t("myUnitsSettings.autoApkInstallEnabled")}
                                />
                            </Grid>

                        </Grid>
                    }

                </Grid>

            </AccordionDetails>

            <GroupSelectionAssignMultupleGroupToObjectDialog
                type={type}
                onDone={async (selectedObject: ObjectUnit, assignServiceWorkersOfObject: boolean) => {
                    if (row.locationInformation) {
                        row.locationInformation.objectUnitId = selectedObject.id;
                        if (assignServiceWorkersOfObject && row.users) {
                            let serviceWorkers = await api.getServiceWorkers(token);
                            row.users.users = row.users?.users.concat(selectedObject.userServiceWorkerIds.map(x => {
                                let worker = serviceWorkers.find(y => y.id == x);
                                if (worker) {
                                    return User.CreateInstanceFromServiceWorker(worker);
                                }
                                return User.CreateInstance("", "", "", false, x);
                            }
                            ));
                        }
                    }
                    await reloadObject(row);
                    onChange({ target: { name: "row", value: "" } });
                }}
                open={groupAssignObjectMultipleDialogVisible} setOpen={setGroupAssignObjectMultipleDialogVisible} allGroups={groupLicenseTables}
                userObjects={objects} currentGroups={groupLicenseTable ? [groupLicenseTable] : []} onRefresh={() => {


                }} preSelectedObjects={objectUnit ? [objectUnit] : []} />


            <GroupSelectionAssignMultipleGroupToPropertyDialog
                propertyType={currentPropertyType}
                open={groupAssignPropertyMultipleDialogVisible} setOpen={setGroupAssignPropertyMultipleDialogVisible} groups={groupLicenseTables}
                currentGroups={groupLicenseTable ? [groupLicenseTable] : []} onRefresh={() => { }} preSelectedProperties={properties}
                onAdd={async (selectedProps: UserProperty[]) => {
                    if (!row.locationInformation) return;
                    row.locationInformation.userPropertyIds = selectedProps.map(x => x.id);
                    await reloadProps(row);
                    onChange({ target: { name: "row", value: "" } });
                }}
                type={type}
            />

            {row &&
                <OfflineDataDialog visible={isOfflineDataVisible} setVisible={setIsOfflineDataVisible} group={row as GroupSettings} />
            }
            {row &&
                <DigitalCheckpointDialog open={isDigitalCheckpointDialogVisible} setOpen={setIsDigitalCheckpointDialogVisible} onRefresh={() => { }} groupSettings={row as GroupSettings} objectUnit={objectUnit} />
            }
            {objectUnit &&
                <ObjectQrCodeDialog
                    visible={qrCodeDialogVisible}
                    setVisible={setQrCodeDialogVisible}
                    object={objectUnit}
                    setObject={setObjectUnit}
                />
            }
        </Accordion >
    );
};

export const QrCodeUrlComponent = ({ row, onChange }: { row: any, onChange: any }) => {
    return (
        <TextFieldGroupSettings
            fullWidth
            id="filled-name"
            variant={"filled"}
            margin="normal"
            name="qrCodeUrl"
            label="QR-Code URL"
            value={row.content?.qrCodeUrl || ''}
            onChange={(event: any) => {
                onChange({ target: { name: "qrCodeUrl", value: event.target.value } });
            }} />
    )
}

export const buildPrintItemGroup = (size = 500, fontSizeSpec = 60, fontSizeTypo = 40, refPrint: any, qrCode: any, qrCodeInformation: QrCodeInformation | undefined, logo: any, name: string | undefined, secondName: string | undefined, logoSettings: any, btnText = "Checkpoint") => {
    return (
        <Grid ref={refPrint} container justifyContent="center" alignContent="center" alignItems="center" className="page-root qr-code-field" style={{ textAlign: 'center', width: size * 2 + 100, backgroundColor: qrCodeInformation?.backgroundColor, padding: 20 }}>

            <Grid item xs={6} style={{ height: size }}>
                <img src={qrCode} style={{ width: size, height: size, borderRadius: fontSizeTypo, border: `${fontSizeTypo / 2}px solid ${qrCodeInformation?.borderColor}` }} />
            </Grid>
            {logoSettings &&
                <Grid item xs={6} style={{ width: size, height: size, backgroundImage: `url(${logo})`, backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                </Grid>
            }
            <Grid item xs={6}>
                <Button variant="contained" style={{ backgroundColor: qrCodeInformation?.borderColor, color: 'white', borderRadius: fontSizeTypo, fontSize: fontSizeSpec }} startIcon={<Smartphone style={{ fontSize: fontSizeSpec }} />} size={"large"}>
                    {btnText}
                </Button>
            </Grid>
            <Grid item container direction="column" xs={6} >
                <Grid item>
                    <Typography style={{ fontSize: fontSizeTypo }}>{name}</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: fontSizeTypo }}>{secondName}</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: fontSizeTypo }}>{qrCodeInformation?.url || "www.check-me-now.com"}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}