import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {
    AppBar,
    Button,
    DialogActions,
    DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    TextField,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel, ClassSharp, RemoveCircle, Save } from '@mui/icons-material';
import { GroupSettings, GroupSettingsType, NotificationTelegramGroup } from '../../models/GroupSettings';
import { api } from '../../api';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { GroupSettingsMappingKeys } from '../../helpers/group-settings-management';
import { useTranslation } from 'react-i18next';

export const EditTelegramGroupDialog = ({ open, setOpen, items, setItems, enqueueSnackbar, token, row, onChange, type }
    : { open: boolean; setOpen: any; items: NotificationTelegramGroup[]; setItems: any; enqueueSnackbar: any; token: string; row: GroupSettings | UserGroupSettingsTemplate, onChange: any, type: GroupSettingsType }) => {

    const classes = useStyles();
    const [titleChanges, setTitleChanges] = React.useState<NotificationTelegramGroup[]>([]);
    const [deletions, setDeletions] = React.useState<NotificationTelegramGroup[]>([]);
    const {t} = useTranslation();

    const onClose = () => setOpen(false);

    React.useEffect(() => {
        setTitleChanges([]);
        setDeletions([]);
    }, [open])

    return (
        <Dialog fullWidth maxWidth={"sm"} onClose={onClose} open={open}>
            <DialogTitle className={classes.dialogTitle}>
                <AppBar className={classes.dialogTitleAppBar} position="sticky">
                    <Toolbar>
                        <Typography className={classes.dialogTitleAppBarTitle} variant="h6" noWrap>
                            {t("myUnitsSettings.editGroup")}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <List className={classes.list}>
                    {
                        items &&
                        items.length > 0 &&
                        items.map((item: NotificationTelegramGroup, index: number) => {
                            return (
                                <ListItem key={index}                                >
                                    <TextField
                                        fullWidth
                                        className={classes.listItemTextField}
                                        variant="filled"
                                        value={item.title}
                                        onChange={(event: any) => {
                                            setItems(items.map((e: NotificationTelegramGroup) => e.id === item.id ? { ...e, title: event.target.value } : e));

                                            const index = titleChanges.findIndex(x => x.id === item.id);

                                            if (index >= 0) titleChanges.splice(index);

                                            titleChanges.push({ ...item, title: event.target.value });

                                            setTitleChanges(titleChanges);
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                setItems(items.filter((e: NotificationTelegramGroup) => e.id !== item.id));

                                                const deletionIndex = deletions.findIndex(x => x.id === item.id);
                                                const titleChangeIndex = titleChanges.findIndex(x => x.id === item.id);

                                                if (deletionIndex === -1) deletions.push(item);
                                                if (titleChangeIndex >= 0) titleChanges.splice(titleChangeIndex);

                                                setTitleChanges(titleChanges);
                                                setDeletions(deletions);
                                            }}
                                            size="large">
                                            <RemoveCircle color="error" className={classes.listItemIcon} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })
                    }
                    {
                        (!items || items.length === 0) &&
                        <div className={classes.empty}>
                            <Typography style={{ textAlign: 'center' }}>Keine Gruppe angelegt</Typography>
                        </div>
                    }
                </List>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    size="large"
                    startIcon={<Cancel />}
                    variant={'outlined'}
                    onClick={onClose}
                    color="primary"
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    onClick={async () => {

                        await api.updateUserTelegramGroupsTitleAsync(row?.id as string, titleChanges, type, token, enqueueSnackbar);

                        if (deletions.length > 0) {
                            const rowData = await api.deleteUserTelegramGroupsAsync(row?.id as string, deletions, type, token, enqueueSnackbar);

                            if (rowData)
                                onChange({ target: { name: GroupSettingsMappingKeys.notificationTelegramGroups, value: JSON.stringify({ systemETag: rowData.systemETag, notificationTelegramGroup: rowData.notification?.notificationTelegramGroup }) } });
                        }

                        setTitleChanges([]);
                        setDeletions([]);
                        onClose();
                    }}
                >
                    Übernehmen
                </Button>
            </DialogActions>

        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: 0,
        },
        dialogTitleAppBar: {
            flexGrow: 1,
        },
        dialogTitleAppBarTitle: {
            flexGrow: 1,
        },
        list: {

        },
        listItemTextField: {
            marginRight: 16,
        },
        listItemIcon: {
            fontSize: 32,
        },
        empty: {
            width: '100%',
            height: 150,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        dialogContent: {
            padding: theme.spacing(2),
        },
        dialogActions: {
            margin: 0,
            padding: theme.spacing(1),
        }
    }),
);
