import axios from 'axios';
import { UserStock } from '../../models/UserStock';
import { getUserIdFromStorage } from '../../helpers/common';
import { handleApiError, headers } from '../../api';
import { getQueryParamFromApiDataAmountType } from '../../helpers/charts/base';

export const getUserStocks = async (token: string, objectIds : string[], groupIds: string[]): Promise<UserStock[]> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(process.env.REACT_APP_BASIC_URI + `/users/${userId}/get-user-stock?${getQueryParamFromApiDataAmountType()}`, {stringArray: objectIds, stringArray2 : groupIds} ,{ headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        handleApiError(err);

    }
    return null as any;

}