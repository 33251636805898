import React from 'react';
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Toolbar,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CloudUpload, Delete, Download, Save, Visibility } from '@mui/icons-material';
import { IntervalControl } from '../../../../models/IntervalControl';
import { usePortalAccountStyles } from '../../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { DefaultDialogTitle } from '../../../dialogs/DefaultDialogTitle';
import { BuildCalendarIntervalItem, BuildIntervalItem, BuildSpecificIntervalItem, BuildTypeChooserInterval } from '../../../core/intervalControl/GroupSettingFieldsIntervalControl';
import { GroupSettingsIntervalControl, GroupSettingsIntervalType } from '../../../../models/GroupSettings';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { mapGroupSettings } from '../../../../helpers/group-settings-management';
import CheckMeNowIcon from '../../../../assets/svgs/check-me-now_icon.svg'
import { makeValidAppointments } from '../../../../helpers/common';
import { useTranslation } from 'react-i18next';

export const IntervalControlEditingDialog = ({ visible, setVisible, currentIntervalItem, onSave, labelTime = "Reaktionszeit" }
    : { visible: boolean, setVisible: any, currentIntervalItem?: IntervalControl, onSave: any, labelTime? :  string }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [intervalControlNew, setIntervalControlNew] = React.useState<IntervalControl>(IntervalControl.CreateInstance());

    const [loading, setLoading] = React.useState(false);
    const [myAppointments, setMyAppointments] = React.useState<AppointmentModel[]>([]);
    const {t} = useTranslation();
    const onClose = () => {
        setIntervalControlNew(IntervalControl.CreateInstance());
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    const isValid = () => {
        let valid = true;

        if (intervalControlNew.interval.type == undefined) {
            enqueueSnackbar("Typ pflicht!", { variant: 'error' });
            valid = false;
        }

        return valid;
    }


    React.useEffect(() => {

        (async () => {
            if (currentIntervalItem) {
                setIntervalControlNew(currentIntervalItem);
                setMyAppointments(makeValidAppointments(currentIntervalItem.interval.calendarEntries));
            } else {
                let newItem = IntervalControl.CreateInstance();
                setIntervalControlNew(newItem);
            }

        })();

    }, [visible, currentIntervalItem])

    const onChange = (event: any) => {
        setIntervalControlNew((newVal: any) => {

            return { ...newVal, interval: mapGroupSettings({ intervalControl: newVal.interval }, event.target.name, event.target.value).intervalControl };
        });
    }

    const onSavePage = async () => {
        if (!isValid()) return;

        setLoading(true);
        await onSave(intervalControlNew);
        setLoading(false);

        onClose();
    }


    return (
        <Dialog open={visible} onClose={onClose} fullScreen>

            <AppBar color='inherit' position='sticky' style={{ padding: 10 }}>
                <Toolbar>
                    <Grid container direction="row" spacing={3} alignItems={"center"} justifyContent={"flex-start"}>
                        <Grid item xs={12}>
                            <Typography fontWeight={'bolder'} fontSize={40} color="primary">{t("myUnitsSettings.editIntervalGroup")}</Typography>
                        </Grid>
                        <Grid item container direction="row" spacing={3} xs={12}>

                            <Grid item>
                                <Button
                                    color="primary"
                                    variant='outlined'
                                    onClick={onClose}
                                >
                                    {t("myUnitsSettings.cancel")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Save />}
                                    disabled={loading}
                                    onClick={async () => {
                                        onSavePage();
                                    }}
                                >
                                    Speichern
                                </Button>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography color="primary" fontWeight={'bold'} fontSize={30}>Name eingeben:</Typography>
                        </Grid>
                        <Grid item lg={4}>
                            <TextField value={intervalControlNew.name} fullWidth variant='filled' label={"Name"} onChange={(event) => {
                                setIntervalControlNew((newVal: any) => {
                                    return { ...newVal, name: event.target.value };
                                });
                            }}>

                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <BuildTypeChooserInterval intervalControl={intervalControlNew.interval}
                                onChange={(event: any) => {
                                    setIntervalControlNew((newVal) => {
                                        newVal.interval.daysOfWeekEnabled = JSON.parse(event.target.value);
                                        return { ...newVal };
                                    });
                                }}
                                onChange2={(event: any) => {
                                    console.log(event, "event");
                                    setIntervalControlNew((newVal) => {
                                        newVal.interval.type = parseInt(event.target.value);
                                        return { ...newVal };
                                    });
                                }} />
                        </Grid>

                    </Grid>
                </Toolbar>
            </AppBar>
            <Paper style={{ padding: 10, flexGrow: 1 }}>
                <Grid container direction="column" spacing={3} alignItems={"flex-start"} justifyContent={"flex-start"}>

                    <Grid item container direction={"column"} spacing={2} style={{ maxHeight: 800 }}>
                        <BuildIntervalItem intervalControl={intervalControlNew.interval} onChange={onChange} labelTime={"Reaktionszeit"} />
                        <BuildSpecificIntervalItem intervalControl={intervalControlNew.interval} onChange={onChange} labelTime={"Reaktionszeit"} />
                        <BuildCalendarIntervalItem intervalControl={intervalControlNew.interval} onChange={onChange} services={[]} myAppointments={myAppointments} setMyAppointments={setMyAppointments} />
                    </Grid>

                </Grid>
            </Paper>

        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = error => reject(error);
    })
}
export const AppBarCheckMeNowSave = ({ title, onSave }: { title: string, onSave: any }) => {
    return (
        <AppBar position="sticky">
            <Toolbar>
                <Grid item xs={12} container direction={'row'} spacing={3} alignItems="center">
                    <Grid item>
                        <img style={{ marginRight: 10 }} src={CheckMeNowIcon} height={60} alt="check-me-now-icon" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">{title}</Typography>
                    </Grid>

                    <Grid item style={{ flexGrow: 1 }}></Grid>
                    <Grid item>
                        <Button
                            onClick={onSave}
                            color="info"
                            variant="contained"
                            size="large"
                            startIcon={<Save />}
                        >
                            Speichern
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}