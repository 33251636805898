import { UserAccount } from "../models/UserAccount";
import { UserType } from "../models/UserType";

export class RightsHelper {

    static hasWritePermission = (userAccount: UserAccount) => {
        return userAccount?.userType == UserType.Admin || userAccount?.userType == UserType.Manager || userAccount?.userType == UserType.Owner;
    }

    static roundToTwoDigitsAfterComma = (floatNumber:number) => {
        return parseFloat((Math.round(floatNumber * 100) / 100).toFixed(2)) || 0;
    }

    static roundDigitsAfterComma = (floatNumber:number, commaNumber: number) => {
        return Number(floatNumber.toFixed(commaNumber));
    }

}