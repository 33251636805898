import { GroupLicenseTable, SensorLicense } from "./GroupLicenseTable";

export enum GroupType {
    DisplayWallApp = 0,
    Checkpoint = 1,
    DisplayBatteryWallApp = 2,
    DigitalCheckpoint = 3,
    TaqtFeedback = 4,
    TaqtTime = 5,
    All = 255
}

export enum GroupStateType {
    LoggedIn = 0,
    LoggedOut = 1,
}

export class Group {
    public id!: string;
    public systemETag!: string;
    public systemTimestamp!: string;
    public userId!: string;
    public validUntil!: string;
    public deviceLicenseValidUntil!: string;
    public deviceLicenseExpired!: boolean;
    public adLicenseValidUntil!: string;
    public isFreeAdLicense!: boolean;
    public adLicenseExpired!: boolean;
    public lastOnline!: number;
    public sensorLicenses!: SensorLicense[];
    public loraSensorLicenses!: SensorLicense[];
    public groupType!: GroupType;
    public groupStateType !: GroupStateType;
    

    public qualityCheckLicenseValidUntil!: Date;
    public publicFeedbackLicenseValidUntil!: Date;
    public payPerServiceLicenseValidUntil!: Date;
    public calendarProLicenseValidUntil!: Date;





    public qualityCheckLicenseIsValid: boolean = false;


    public qualityCheckLicenseIsExpired: boolean = false;
    public publicFeedbackLicenseIsExpired: boolean = false;
    public payPerServiceLicenseIsExpired: boolean = false;
    public calendarProLicenseIsExpired: boolean = false;

    public qualityCheckLicenseDuration!: string;

    public deviceId !: string;
    public portalSettings !: PortalSettings;

    static Labels = [
        { value: GroupType.DisplayWallApp, label: 'Smart Service Display'},
        { value: GroupType.Checkpoint, label: 'Checkpoint'},
        { value: GroupType.DisplayBatteryWallApp, label: 'SSD mit Battery'},
        { value: GroupType.DigitalCheckpoint, label: 'Digitales Checkpoint'},
        { value: GroupType.TaqtFeedback, label: 'Taqt Feedback Display'},
        { value: GroupType.TaqtTime, label: 'Taqt Time Display'},

    ];

    static GetLabelByType = (type: GroupType | undefined) => Group.Labels.find(x => x.value == type)?.label || '';


}

export class PortalSettings {
    constructor () {
        this.identifierDetails = new IdentifierDetails();
    }
    public identifierDetails !: IdentifierDetails;
}
export class IdentifierDetails {
    public teamViewerId !: string;
    public simId !: string;
}

