import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { api } from '../../api';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { UserType } from "../../models/UserType";
import { RegistrationUser } from '../../models/RegistrationUser';
import { StringHelper } from '../../helpers/string-helper';
import { ApiError } from '../../models/ApiError';
import { HttpStatusCodes } from '../../models/HttpStatusCodes';
import { BackendErrorCodes } from '../../models/ErrorCodes';

import { HowToReg } from '@mui/icons-material';

export const SignUpPage = ({ isFromShop = false, events = undefined }: { isFromShop: boolean, events: any }) => {
  const theme = useTheme();

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const minPasswordLength = 8;

  const [user, setUser] = React.useState<RegistrationUser>(new RegistrationUser());
  const [firstNameError, setFirstNameError] = React.useState<string | undefined>(undefined);
  const [lastNameError, setLastNameError] = React.useState<string | undefined>(undefined);
  const [companyError, setCompanyError] = React.useState<string | undefined>(undefined);
  const [emailError, setEmailError] = React.useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = React.useState<string | undefined>(undefined);

  const isFormValid = (): boolean => {

    if (!user) return false;

    if (StringHelper.IsNullOrWhiteSpace(user.firstName)) {
      setFirstNameError('Vorname ist erforderlich');
      return false;
    }

    if (StringHelper.IsNullOrWhiteSpace(user.lastName)) {
      setLastNameError('Nachname ist erforderlich');
      return false;
    }

    if (StringHelper.IsNullOrWhiteSpace(user.email)) {
      setEmailError('Email Adresse ist erforderlich');
      return false;
    }

    if (StringHelper.IsNullOrWhiteSpace(user.company)) {
      setCompanyError('Firmenname ist erforderlich');
      return false;
    }

    if (!StringHelper.IsValidEmail(user.email)) {
      setEmailError('Email Adresse ist ungültigt');
      return false;
    }

    if (StringHelper.IsNullOrWhiteSpace(user.password)) {
      setPasswordError('Passwort ist erforderlich');
      return false;
    }

    if (StringHelper.isShortedThan(user.password, minPasswordLength)) {
      setPasswordError(`Passwort muss mindesten ${minPasswordLength} Zeichen lang sein`);
      return false;
    }

    return true;
  }

  const onFocus = () => {
    setFirstNameError(undefined);
    setLastNameError(undefined);
    setEmailError(undefined);
    setPasswordError(undefined);
    setCompanyError(undefined);
  }

  React.useEffect(() => {
    if (events) {
      events.register = register;
    }
  }, []);

  const register = async () => {

    if (!isFormValid()) return;

    await api.registerNewUser(
      user.email,
      user.password,
      user.title,
      user.firstName,
      user.lastName,
      user.company,
      UserType.Owner,
      "",
      () => {
        enqueueSnackbar("Registrierung erfolgreich! Jetzt müssen Sie nur noch die Email bestätigen", { variant: 'success' });
        history.push("/login");
      },
      (error: ApiError) => {

        if (error.response.status === HttpStatusCodes.Conflict && error.response.data.code === BackendErrorCodes.KeyConflictUserRegistered) {
          enqueueSnackbar("Diese Email Adresse ist bereits vergeben", { variant: 'error' });
          return;
        }

        enqueueSnackbar("Registrierung fehlgeschlagen", { variant: 'error' });
      });
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <HowToReg />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrieren
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name={keys.firstName}
                value={user.firstName}
                variant="outlined"
                required
                fullWidth
                label="Vorname"
                autoFocus
                onChange={(e: any) => setUser({ ...user, firstName: e.target.value })}
                onFocus={onFocus}
                error={!StringHelper.IsNullOrWhiteSpace(firstNameError)}
                helperText={firstNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                value={user.lastName}
                fullWidth
                autoComplete="family-name"
                label="Last Name"
                name={keys.lastName}
                onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                onFocus={onFocus}
                error={!StringHelper.IsNullOrWhiteSpace(lastNameError)}
                helperText={lastNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                value={user.company}
                required
                fullWidth
                label="Firma"
                name={keys.company}
                onChange={(e) => setUser({ ...user, company: e.target.value })}
                onFocus={onFocus}
                error={!StringHelper.IsNullOrWhiteSpace(companyError)}
                helperText={companyError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name={keys.title}
                variant="outlined"
                value={user.title}
                fullWidth
                label="Titel"
                autoFocus
                onChange={(e) => setUser({ ...user, title: e.target.value })}
                onFocus={onFocus}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                value={user.email}
                fullWidth
                label="Email Address"
                name={keys.email}
                autoComplete="email"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                onFocus={onFocus}
                error={!StringHelper.IsNullOrWhiteSpace(emailError)}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                value={user.password}
                required
                fullWidth
                name={keys.password}
                label="Password"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                onFocus={onFocus}
                error={!StringHelper.IsNullOrWhiteSpace(passwordError)}
                helperText={passwordError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="Info"
                style={{ borderColor: theme.palette.primary.main }}
                component="p"
                variant="body1"
              >
                Nach der Registrierung müssen sie die Email bestätigen, damit das Einloggen funktioniert. Sie bekommen dazu eine Email, wo Sie mit dem Link, Ihre Email bestätigen können.
              </Typography>
            </Grid>
          </Grid>
          {!isFromShop &&
            <Button
              fullWidth
              variant="contained"
              size='large'
              color="primary"
              className={classes.submit}
              onClick={register}
            >
              Registrieren
            </Button>
          }
          {!isFromShop &&
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/login" variant="body2">
                  Haben Sie schon einen Account? Loggen Sie sich hier ein!
                </Link>
              </Grid>
            </Grid>
          }
        </form>
      </div>
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const keys = {
  email: 'email',
  password: 'password',
  firstName: 'firstName',
  lastName: 'lastName',
  title: 'title',
  company: 'company',
}