import React from "react";
import { useHistory } from "react-router";
import { getApiToken } from "../../helpers/storage-management";
import { useNetWorkImageStyles } from "../../styles/NetWorkImageStyles";
import { ImageErrorOverlay } from "../overlays/ImageErrorOverlay";
import { CircleProgressOverlay } from "../overlays/CircleProgressOverlay";
import { CircularProgress } from "@mui/material";

export const NetworkImageNonDialog = ({ src, alt = 'Bild existiert nicht', hideOverlay = false, minWidth = 120, authorize = true }
    : { src: string, alt?: string, hideOverlay?: boolean, minWidth?: number, authorize?: boolean }) => {

    const [imageSource, setImageSource] = React.useState<string | undefined>(undefined);
    const styles = useNetWorkImageStyles();
    const history = useHistory();
    const [boxHeight, setBoxHeight] = React.useState<string | number>(320);
    const [boxWidth, setBoxWidth] = React.useState<string | number>(320);
    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setLoading(true);

            //  const token = getApiToken(history);
            if (authorize) {
                const tempToken = getApiToken(history);
                setImageSource(`${src}?code=${process.env.REACT_APP_FUNCTIONS_KEY}&authorization=Bearer ${tempToken}`);
            } else {
                setImageSource(`${src}?code=${process.env.REACT_APP_FUNCTIONS_KEY}`);
            }

            setBoxHeight('100%');
            setBoxWidth('100%');
        })();
    }, [src]);

    const onImageLoaded = () => {
        setLoading(false);
    }

    const onImageError = () => {
        setLoading(false);
        setHasError(true);
    }

    if (!imageSource) return null;

    return (
        <div style={{ height: boxHeight, width: boxWidth }} key={src}>
            {!hideOverlay ?
                <CircleProgressOverlay hidden={!loading} /> : loading && <CircularProgress size={32}/>
            }
            {!hideOverlay &&
                <ImageErrorOverlay hidden={!hasError} text={alt} />
            }
            <img
                style={{ minWidth: minWidth, minHeight: 10, maxWidth: '100%', maxHeight: 100 }}
                className={styles.image}
                src={imageSource}
                alt={alt}
                onLoad={onImageLoaded}
                onError={onImageError} />
        </div>
    );
}