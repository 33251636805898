import React, { useContext } from 'react';
import { Button, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridStackCustomDataTable } from '../GridStackCustomDataTable';
import { GridStackCustom } from '../../../../../../models/GridStackCustom';
import { ConfirmationDialog } from '../../../../../dialogs/ConfirmationDialog';
import { getUserIdFromStorage } from '../../../../../../helpers/common';
import { getApiToken } from '../../../../../../helpers/storage-management';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { api } from '../../../../../../api';
import { GridStackCustomManagementPageEditingDialog } from './GridStackCustomManagementPageEditingDialog';
import Add from 'date-fns/add';
import { LinearProgressWithLabel } from '../../../../../core/components/ProgressComponent';
import { AddCard } from '@mui/icons-material';
import { GlobalContext } from '../../../../PortalPage';
import { GLOBAL_GRID_STACK_CUSTOMS } from '../../../../../../constants';
import { useTranslation } from 'react-i18next';


export const GridsStackCustomManagementDialog = () => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [addSettingDialogVisible, setAddSettingDialogVisible] = React.useState(false);
    const [selectedGridCustom, setSelectedGridStackCustom] = React.useState<GridStackCustom>();
    const [editSettingDialogVisible, setEditSettingDialogVisible] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);

    const { gridStackCustoms, setGridStackCustoms }: { gridStackCustoms: GridStackCustom[], setGridStackCustoms: React.Dispatch<React.SetStateAction<GridStackCustom[]>> } = useContext(GlobalContext);

    const [selection, setSelection] = React.useState<string[]>([]);

    const [selectedgridStackCustoms, setSelectedgridStackCustoms] = React.useState<GridStackCustom[]>([]);

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);


    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    const onRefresh = async () => {
        setLoading(true);

        const tempToken = getApiToken(history);
        const userId = getUserIdFromStorage();

        if (!tempToken || !userId) return;

        setToken(tempToken);
        setLoadingProgress(0);
        setValueBuffer(20);

        const tempgridStackCustoms = await api.getUsergridStackCustoms(tempToken);
        setGridStackCustoms(tempgridStackCustoms);
        localStorage.setItem(GLOBAL_GRID_STACK_CUSTOMS, JSON.stringify(tempgridStackCustoms))

        setLoadingProgress(20);
        setValueBuffer(60);


        setLoadingProgress(60);
        setValueBuffer(100);

        setLoadingProgress(100);
        setValueBuffer(100);
        setLoading(false);

    }

    React.useEffect(() => {

        (async () => {
            await onRefresh();

        })();

    }, [history])

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">
                    {t("dashboardManagement.reportManagement")}
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCard />}
                        onClick={async () => {
                            setSelectedGridStackCustom(GridStackCustom.CreateInstance());
                            setAddSettingDialogVisible(true);
                        }}
                    >
                        {t("dashboardManagement.addReport")}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    {loading &&
                        <Grid item>
                            <CircularProgress size={64} />
                        </Grid>
                    }
                    <GridStackCustomDataTable
                        rows={gridStackCustoms}
                        onEdit={async (row: GridStackCustom) => {
                            setSelectedGridStackCustom(row);
                            setEditSettingDialogVisible(true);
                        }}
                        onDelete={async (row: GridStackCustom) => {
                            setSelectedGridStackCustom(row);
                            setDeleteConfirmationDialogVisible(true);
                        }}
                        selection={selection}
                        setSelection={setSelection}
                    />

                </Paper>
            </Grid>

            <GridStackCustomManagementPageEditingDialog
                visible={addSettingDialogVisible}
                setVisible={setAddSettingDialogVisible}
                gridStackCustom={undefined}
                onSave={async (objectUnit: GridStackCustom) => {


                    const added = await api.CreateUserGridStackCustom(objectUnit, token, enqueueSnackbar);

                    if (!added)
                        return;
                    setGridStackCustoms([added, ...gridStackCustoms]);
                    onRefresh();
                }}
            />

            <GridStackCustomManagementPageEditingDialog
                visible={editSettingDialogVisible}
                setVisible={setEditSettingDialogVisible}
                gridStackCustom={selectedGridCustom}
                onSave={async (object: GridStackCustom) => {


                    const updated = await api.putUserGridStackCustom(object, token, enqueueSnackbar);

                    if (!updated) return;

                    setGridStackCustoms(p => p.map((item: GridStackCustom) => item.id === updated.id ? updated : item));
                    onRefresh();


                }}
            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title="Sind Sie sicher, dieses Custom Dashboard zu löschen ?"
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {
                    if (!selectedGridCustom) return;

                    await api.DeleteGridStackCustomById(selectedGridCustom.id, token, enqueueSnackbar);

                    setGridStackCustoms(gridStackCustoms.filter(x => x.id !== selectedGridCustom.id));
                    setDeleteConfirmationDialogVisible(false);
                    onRefresh();
                }}
            />

        </Grid>
    );
};