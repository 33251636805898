import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, DialogActions, DialogContent, Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, CallMissed, Close, Delete, DomainVerification, FileCopy, Groups, TouchApp, Warning } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { getApiToken } from '../../../helpers/storage-management';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { UserServiceWorkerMultipleSelectionDataTable } from '../../authorized/extendedapp/entireEval/UserServiceWorkerMultipleSelectionDataTable';
import { api } from '../../../api';
import { disabledColor, errorColor, white } from '../../../styles/colors';
import { GroupSettings } from '../../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../../models/UserGroupSettingsTemplate';
import { UserServiceWorkerWithAssignedMultipleSelectionDataTable } from '../../authorized/extendedapp/entireEval/UserServiceWorkerWithAssignedMultipleSelectionDataTable';
import { ReactComponent as CleanerSvg } from '../../../assets/icons/tasks/sm_icon-01.svg';
import { useTranslation } from 'react-i18next';

export const SelectObjectLeaderForGroupNotificationDialog = ({
  open,
  setOpen,
  currentGroupId,
  onRefresh,
  updateSettings,
  row
}: {
  open: boolean,
  setOpen: any,
  currentGroupId: string,
  onRefresh: any,
  updateSettings: any,
  row: GroupSettings | UserGroupSettingsTemplate
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const [token, setToken] = React.useState("");
  const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
  const [allGroups, setAllGroups] = React.useState<GroupLicenseTable[]>([]);
  const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
  const [selectedUserServiceWorkers, setSelectedUserServiceworkers] = React.useState<UserServiceWorker[]>([]);
  const {t} = useTranslation();

  const handleClose = () => {
    setSelectedUserServiceworkers([]);
    setOpen(false);
  }
  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      let workers = await api.getServiceWorkerObjectLeadersByGroupId(tempToken, currentGroupId);
      setUserServiceWorkers(workers);

      const tempGroups = await api.getAllGroups(tempToken);
      setAllGroups(tempGroups);

      const tempObjects = await api.getUserObjectUnits(tempToken);
      setObjects(tempObjects);



    })();

  }, [open]);

  const deleteEntryEmailObjectLeader = (currentEmails: string[]) => {
    if (!row.notification) return;
    setLoading(true);
    for (let i = 0; i < selectedUserServiceWorkers.length; i++) {
      let currentId = selectedUserServiceWorkers[i].id;
      let indexToDelete = currentEmails.findIndex(x => x == currentId);
      if (!currentId || indexToDelete == -1) continue;

      currentEmails.splice(indexToDelete, 1);
      enqueueSnackbar(`Zuweisung gelöscht für: ${selectedUserServiceWorkers[i].name}`, { variant: 'success' });
    }
    updateSettings();
    setLoading(false);
  }

  const addEntryEmailObjectLeader = (currentEmails: string[]) => {
    setLoading(true);
    for (let i = 0; i < selectedUserServiceWorkers.length; i++) {
      let currentId = selectedUserServiceWorkers[i].id;
      if (!currentId || currentEmails.includes(currentId)) continue;
      currentEmails.push(currentId);
      enqueueSnackbar(`Zugewiesen ${selectedUserServiceWorkers[i].name}`, { variant: 'success' });
    }
    updateSettings();
    setLoading(false);
  }

  const disabled = selectedUserServiceWorkers.length < 1 || loading;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DefaultDialogTitle
        title={`Wähle Objektleiter, die eine Benachrichtigung bekommen sollen.`}
        handleClose={handleClose}
      />

      <DialogContent>
        <Grid style={{ marginTop: 30 }} container direction="column" spacing={3} rowSpacing={2}>
          <Grid container item spacing={3}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                style={selectedUserServiceWorkers.length < 1 || loading ? {} : { backgroundColor: errorColor, color: white }}
                disabled={disabled}
                endIcon={<Delete />}
                startIcon={loading ? <CircularProgress /> : <CleanerSvg width={30} height={30} fill={disabled ? disabledColor : white} style={{ marginLeft: -4 }} />}
                onClick={async () => {
                  if (!row.notification) return;
                  deleteEntryEmailObjectLeader(row.notification?.assignedObjectLeadersCleaningEmail);
                }}
              >
                Serviceauslösung
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                startIcon={loading ? <CircularProgress /> : <CleanerSvg width={30} height={30} fill={disabled ? disabledColor : white} style={{ marginLeft: -4 }} />}
                disabled={selectedUserServiceWorkers.length < 1 || loading}
                endIcon={<Add />}
                onClick={async () => {
                  if (!row.notification) return;
                  addEntryEmailObjectLeader(row.notification?.assignedObjectLeadersCleaningEmail);
                }}
              >
                Serviceauslösung
              </Button>
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                style={selectedUserServiceWorkers.length < 1 || loading ? {} : { backgroundColor: errorColor, color: white }}
                disabled={disabled}
                endIcon={<Delete />}
                startIcon={loading ? <CircularProgress /> : <DomainVerification />}
                onClick={async () => {
                  if (!row.notification) return;
                  deleteEntryEmailObjectLeader(row.notification?.assignedObjectLeadersServiceDoneEmail);
                }}
              >
                Service Erledigt Auslösung
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                startIcon={loading ? <CircularProgress /> : <DomainVerification />}
                disabled={selectedUserServiceWorkers.length < 1 || loading}
                endIcon={<Add />}
                onClick={async () => {
                  if (!row.notification) return;
                  addEntryEmailObjectLeader(row.notification?.assignedObjectLeadersServiceDoneEmail);
                }}
              >
                Service Erledigt Auslösung
              </Button>
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                style={selectedUserServiceWorkers.length < 1 || loading ? {} : { backgroundColor: errorColor, color: white }}
                disabled={selectedUserServiceWorkers.length < 1 || loading}
                endIcon={<Delete />}
                startIcon={loading ? <CircularProgress /> : <Warning />}
                onClick={async () => {
                  if (!row.notification) return;
                  deleteEntryEmailObjectLeader(row.notification?.assignedObjectLeadersIncidentReportEmail);

                }}
              >
                Vorfallmeldungauslösung
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                startIcon={loading ? <CircularProgress /> : <Warning />}
                disabled={selectedUserServiceWorkers.length < 1 || loading}
                endIcon={<Add />}
                onClick={async () => {
                  if (!row.notification) return;
                  addEntryEmailObjectLeader(row.notification?.assignedObjectLeadersIncidentReportEmail);

                }}
              >
                Vorfallmeldungauslösung
              </Button>
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                size="large"
                style={selectedUserServiceWorkers.length < 1 || loading ? {} : { backgroundColor: errorColor, color: white }}
                disabled={selectedUserServiceWorkers.length < 1 || loading}
                endIcon={<Delete />}
                startIcon={loading ? <CircularProgress /> : <CallMissed />}
                onClick={async () => {
                  if (!row.notification) return;
                  deleteEntryEmailObjectLeader(row.notification?.assignedObjectLeadersMissedServiceEmail);

                }}
              >
                Verpasstauslösung
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                size="large"
                startIcon={loading ? <CircularProgress /> : <CallMissed />}
                disabled={selectedUserServiceWorkers.length < 1 || loading}
                endIcon={<Add />}
                onClick={async () => {
                  if (!row.notification) return;
                  addEntryEmailObjectLeader(row.notification?.assignedObjectLeadersMissedServiceEmail);
                }}
              >
                Verpasstauslösung
              </Button>
            </Grid>

          </Grid>
        </Grid>
        <UserServiceWorkerWithAssignedMultipleSelectionDataTable rows={userServiceWorkers} group={row}
          groups={allGroups} objects={objects}
          selectedUserServiceWorkers={selectedUserServiceWorkers}
          onSelectionChange={(selection: string[]) => {
            setSelectedUserServiceworkers(userServiceWorkers.filter(x => selection.includes(x.id)));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          Dialog {t("myUnitsSettings.close")}
        </Button>

      </DialogActions>

    </Dialog >
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));