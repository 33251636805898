import axios from 'axios';
import { getApiToken, getStorage } from '../../helpers/storage-management';
import { PUBLIC_CURRENT_USER_SERVICE_WORKER, PUBLIC_SERVICEWORKER_TOKEN } from '../../constants';
import { GroupServiceMode } from '../../models/GroupServiceMode';
import { UserServiceWorker } from '../../models/UserServiceWorker';
import { headers } from '../../api';


export const finishGroupServiceModePublic = async (groupServiceMode: GroupServiceMode, enqueueSnackbar: any): Promise<GroupServiceMode> => {
  try {
    let token = await localStorage.getItem(PUBLIC_SERVICEWORKER_TOKEN);
    let serviceWorkers = await getStorage<UserServiceWorker>(PUBLIC_CURRENT_USER_SERVICE_WORKER);
    groupServiceMode.userServiceWorkerId = serviceWorkers.id;
    let ret = await axios.patch(`${process.env.REACT_APP_BASIC_URI}/service-workers/${serviceWorkers.id}/groups/${groupServiceMode.groupId}/finish-service-mode`,
      { ...groupServiceMode },
      { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
      enqueueSnackbar("Service erledigt", { variant: 'success' });

    return ret.data;
  } catch (err) {
    enqueueSnackbar("Error beim Service erledigen", { variant: 'error' });

  }
  return null as any;
}