import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, TableSummaryRow, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { Filter, FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, IntegratedSummary, PagingState, SelectionState, SortingState, SummaryState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Accessibility } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { differenceInHours, differenceInMinutes, formatDistance, fromUnixTime } from 'date-fns';
import { de } from 'date-fns/locale';
import { UserServiceWorker } from '../../../../../models/UserServiceWorker';
import { formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime } from '../../../../../helpers/date-management';
import { ObjectUnit } from '../../../../../models/ObjectUnit';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { buildCellStringArray } from '../../../../common/AccordionForStringArray';
import { buildCellElementArray } from '../../../../common/AccordionForElementArray';
import { lightRed, lightGreen, lightgrey, lightBlue, yellow, white } from '../../../../../styles/colors';
import { StandardWorkItem } from '../../../../../models/StandardItem';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { GroupSettings } from '../../../../../models/GroupSettings';
import { ImportanceLevel, MaterialStockSummaryTableEntry } from '../../../../../models/UserStock';
import { getStorage, setStorage } from '../../../../../helpers/storage-management';
import { GLOBAL_VISIBILITY_MATERIAL_ANALYSIS } from '../../../../../constants';
import useNoInitialEffect from '../../../../../helpers/extensions';
import { useTranslation } from 'react-i18next';

export const ObjectMaterialStockSummaryTable = ({ materialStockSummaryTableEntrys, groups, objects }
    : { materialStockSummaryTableEntrys: MaterialStockSummaryTableEntry[], groups: GroupSettings[], objects?: ObjectUnit[] }) => {
    const [filters, setFilters] = React.useState<Filter[]>([]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `ObjektSession-${"Alle-Objekte"}-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [totalSummaryItems, setTotalSummaryItems] = React.useState<any[]>([]);
    const [hiddenColumnNames, setHiddenColumnNames] = React.useState<string[]>([]);
    const {t} = useTranslation();

    React.useEffect(() => {
        setHiddenColumnNames(getStorage<string[]>(GLOBAL_VISIBILITY_MATERIAL_ANALYSIS) || [])
    }, [])

    const Cell = (props: any) => {
        const { column, row, value }: { column: any, row: MaterialStockSummaryTableEntry, value: any } = props;
        let group = groups.find(x => x.id == column.name);
        if (group) {
            let currentEntry = row.groups.find(x => x.groupId == column.name);
            if (group.locationInformation?.objectUnitId != row.objectId) {
                return (
                    <Table.Cell {...props}>
                        <Typography color={"blue"}>{t("object-storage-single-eval.notAssigned")}</Typography>
                    </Table.Cell>
                )
            }
            return (
                <Table.Cell {...props}>
                    <Typography color={(currentEntry?.stockAmount || 0) < (currentEntry?.minimumStockAmount || 0) ? "red" : "green"}>Gesamt: {currentEntry?.stockAmount || 0} <br /> Mindest: {currentEntry?.minimumStockAmount || 0}</Typography>
                </Table.Cell>
            )

        }
        if (column.name == MaterialStockSummaryTableEntry.Columns.objectStock) {
            return (
                <Table.Cell {...props}>
                    <Typography color={row.objectStock < row.minimumObjectStock ? "red" : "green"}>{row.objectStock}</Typography>
                </Table.Cell>
            )

        }
        if (column.name == MaterialStockSummaryTableEntry.Columns.objectStockInLast && row.objectStockDifference >= 0) {
            return (
                <Table.Cell {...props}>
                    <Typography color={row.objectStockDifference == 0 ? "black" : "green"}>{row.objectStockDifference}</Typography>
                </Table.Cell>
            )
        }
        if (column.name == MaterialStockSummaryTableEntry.Columns.objectStockOutLast && row.objectStockDifference <= 0) {
            return (
                <Table.Cell {...props}>
                    <Typography color={row.objectStockDifference == 0 ? "black" : "red"}>{row.objectStockDifference}</Typography>
                </Table.Cell>
            )
        }
        if (column.name == MaterialStockSummaryTableEntry.Columns.objectStockIn) {
            return (
                <Table.Cell {...props}>
                    <Typography color={row.objectStockSumInput > 0 ? "black" : "green"}>{row.objectStockSumInput}</Typography>
                </Table.Cell>
            )
        }
        if (column.name == MaterialStockSummaryTableEntry.Columns.objectStockOut) {
            return (
                <Table.Cell {...props}>
                    <Typography color={row.objectStockSumOutput > 0 ? "black" : "red"}>{row.objectStockSumOutput}</Typography>
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };

    // worksheet customization
    /* eslint-disable no-param-reassign */
    const customizeCell = (cell: any, row: MaterialStockSummaryTableEntry, column: any) => {
        console.log("customizeCell", row.importanceLevelObject, cell);
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: getColorImportance(row.importanceLevelObject).replace("#","") }, bgColor: { argb: getColorImportance(row.importanceLevelObject).replace("#","") } };
    };

    const getColorImportance = (importance: ImportanceLevel) => {
        let color = white;
        if (importance == ImportanceLevel.Important) {
            color = yellow;
        } else if (importance == ImportanceLevel.VeryImportant) {
            color = lightRed;
        }
        return color;
    }

    const TableRow = (props: any) => {
        const { row }: { column: any, row: MaterialStockSummaryTableEntry, value: any } = props;

        return (
            <Table.Row
                {...props}
                // eslint-disable-next-line no-alert
                style={{
                    backgroundColor: getColorImportance(row.importanceLevelObject)
                }}
            />
        )
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);



    const [columns, setColumns] = React.useState([
        {
            name: MaterialStockSummaryTableEntry.Columns.materialText,
            title: 'Material',
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.materialText,
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.article,
            title: t("object-storage-single-eval.itemNumber"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.article,
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.entity,
            title: t("object-storage-single-eval.unit"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.entity,
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.objectId,
            title: t("object-storage-single-eval.object"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => {
                let object = objects?.find(x => x.id == row.objectId);
                return (
                    !object ? "Nicht Gefunden" : `${object?.name}`
                );
            }
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.createdTimestamp,
            title: t("object-storage-single-eval.lastStockMovement"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.objectStockDifference != 0 ? formatEuropeanDateTime(row.lastStockDate) : "",
        },

        {
            name: MaterialStockSummaryTableEntry.Columns.stockSum,
            title: t("object-storage-single-eval.totalStock"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.stockSum,
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.objectStock,
            title: t("object-storage-single-eval.objectStock"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.objectStock
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.objectStockInLast,
            title:  t("object-storage-single-eval.lastGoodsReceipt"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.objectStockDifference > 0 ? row.objectStockDifference : 0
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.objectStockOutLast,
            title: t("object-storage-single-eval.lastGoodsIssue"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.objectStockDifference < 0 ? row.objectStockDifference : 0
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.objectStockIn,
            title: t("object-storage-single-eval.totalGoodsReceipt"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.objectStockSumInput
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.objectStockOut,
            title: t("object-storage-single-eval.totalGoodsIssue"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.objectStockSumOutput
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.minimumObjectStock,
            title: t("object-storage-single-eval.minimumObjectStock"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.minimumObjectStock
        },
        {
            name: MaterialStockSummaryTableEntry.Columns.maximumObjectStock,
            title: t("object-storage-single-eval.maximumObjectStock"),
            getCellValue: (row: MaterialStockSummaryTableEntry) => row.maximumObjectStock
        },


    ]);



    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: MaterialStockSummaryTableEntry.Columns.objectId, width: 350 },
        { columnName: MaterialStockSummaryTableEntry.Columns.createdTimestamp, width: 150 },
        { columnName: MaterialStockSummaryTableEntry.Columns.materialText, width: 600 },

        { columnName: MaterialStockSummaryTableEntry.Columns.stockSum, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.objectStock, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.objectStockIn, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.objectStockOut, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.objectStockInLast, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.objectStockOutLast, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.minimumObjectStock, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.article, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.entity, width: 200 },

        { columnName: MaterialStockSummaryTableEntry.Columns.minimumObjectStock, width: 200 },
        { columnName: MaterialStockSummaryTableEntry.Columns.maximumObjectStock, width: 200 },




    ]);

    React.useEffect(() => {

        let extraEntries = [];
        if (columns) {
            extraEntries = groups.filter(group => !columns.some(x => x.name == group.id)).map(group => {
                let val = {
                    name: group.id,
                    title: group.locationInformation?.notificationName,
                    getCellValue: (row: MaterialStockSummaryTableEntry) => {
                        let currentEntry = row.groups.find(x => x.groupId == group.id);
                        if (group.locationInformation?.objectUnitId != row.objectId) {
                            return `Nicht zugewiesen`;
                        }
                        return currentEntry?.stockAmount || 0;
                    }
                };
                return val;

            });
            columns.push(...extraEntries as any);
        }
        setColumns([...columns]);
        let columnWidths = columns.map(x => {
            return {
                columnName: x.name, width: 350,
            }
        })
        setColumnWidths(columnWidths);
        setTotalSummaryItems([
            { columnName: MaterialStockSummaryTableEntry.Columns.stockSum, type: 'max' },
            { columnName: MaterialStockSummaryTableEntry.Columns.stockSum, type: 'sum' },
            { columnName: MaterialStockSummaryTableEntry.Columns.objectStock, type: 'max' },
            { columnName: MaterialStockSummaryTableEntry.Columns.objectStock, type: 'sum' },
            { columnName: MaterialStockSummaryTableEntry.Columns.objectStockDifference, type: 'max' },
            { columnName: MaterialStockSummaryTableEntry.Columns.objectStockDifference, type: 'sum' },
            ...groups.filter(group => columns.some(x => x.name == group.id)).map(x => {
                return {
                    columnName: x.id, type: 'max'
                }
            }),
            ...groups.filter(group => columns.some(x => x.name == group.id)).map(x => {
                return {
                    columnName: x.id, type: 'sum'
                }
            })
        ]);
    }, [materialStockSummaryTableEntrys])

    return (
        <Paper>
            <Grid
                rows={materialStockSummaryTableEntrys}
                columns={columns}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={(filters) => {
                        setFilters(filters);

                    }}
                />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SummaryState
                    totalItems={totalSummaryItems}
                />
                <IntegratedPaging />
                <IntegratedSummary />

                <Table cellComponent={Cell} rowComponent={TableRow} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={(names) => {
                        setStorage(GLOBAL_VISIBILITY_MATERIAL_ANALYSIS, names);
                        setHiddenColumnNames(names);
                    }}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <TableSummaryRow />

                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={materialStockSummaryTableEntrys}
                columns={columns}
                onSave={onSave}
                filters={filters}
                totalSummaryItems={totalSummaryItems}
                hiddenColumnNames={hiddenColumnNames}
                customizeCell={customizeCell as any}


            />
        </Paper>
    );
};