import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';

export const deleteLicenseAsync = async (licenseId: string, userId: string, token: string, enqueueSnackbar: any) => {


    if (!licenseId || !token || !enqueueSnackbar || !userId)
        return;

    await axios.delete(
        `${process.env.REACT_APP_BASIC_URI}/users/${userId}/licenses/${licenseId}`,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then(res => {
        enqueueSnackbar("Lizenz wurde erfolgreich gelöscht", { variant: 'success' });
    }).catch(error => {
        enqueueSnackbar("Es ist ein Fehler bei der Löschung der Lizenz aufgetreten", { variant: 'error' });
    });
};
