import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
function ListItemLink(props: any) {
    return <ListItem button component="a" {...props} />;
}

export function SimpleListGroup({ data }: { data: any[] }) {

    return (
        <List>
            {data.map((el) => {
                return (
                    <ListItem button>
                        <ListItemIcon>
                            <VideoLabelIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${el.name}`}/>
                    </ListItem>
                );
            })}

        </List>
    );
}