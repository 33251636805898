import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle, CircularProgress, DialogActions, DialogContent, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { DigitalCheckpointConfigLoggedIn } from './DigitalCheckpointConfigLoggedIn';
import { GroupSettings } from '../../../models/GroupSettings';
import { Cancel, Check } from '@mui/icons-material';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { IntervalControl } from '../../../models/IntervalControl';
import { IntervalControlDataTable } from '../../authorized/extendedapp/interval/IntervalControlDataTable';
import { useTranslation } from 'react-i18next';

export const AssignIntervalDialog = ({ open, setOpen, onRefresh, intervalControls, onAssign, intervalControlIds, IntervallButton }
    : { open: boolean, setOpen: any, onRefresh: any, intervalControls: IntervalControl[], onAssign: any, intervalControlIds: string[], IntervallButton: () => JSX.Element }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);
    const [selection, setSelection] = React.useState<string[]>([]);
    const {t} = useTranslation();

    React.useEffect(() => {
        if (intervalControlIds) {
            setSelection(intervalControlIds);
        }
    }, [open])

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"xl"}
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    Intervallgruppen
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <IntervalControlDataTable selection={selection} setSelection={setSelection} rows={intervalControls} />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Grid container spacing={3}>
                        <IntervallButton />
                        <Grid item style={{flexGrow: 1}}></Grid>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Cancel />}
                                variant={'outlined'}
                                onClick={handleClose}
                                color="primary"
                            >
                                {t("myUnitsSettings.cancel")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Check />}
                                endIcon={loading && <CircularProgress size={32} />}
                                variant={'contained'}
                                color="primary"
                                disabled={loading}
                                onClick={async () => {
                                    onAssign(selection);
                                    handleClose();
                                }}
                            >
                                {selection.length} Intervalle zuweisen!
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));