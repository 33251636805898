import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    Legend,
    Margin,
    Tooltip,
    Grid,
    ValueAxis,
    Label,
    ZoomAndPan,
    Size,
    ConstantLine
} from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { chartHeight, ChartLabelType, customizeLegendTextBarLineChart, customizePercentageText, mapLabels, NoDataChartLoader } from './Base';
import { ChartHeightField, FullscreenButton, ResetButtonZoom } from './StackedChart';
import React from 'react';
import { Grid as MuiGrid } from '@mui/material';
import { FullScreenChartDialog } from './dialog/FullScreenChartDialog';


export const LineBarCombChart = ({ data, dataFieldBars, dataFieldLines, text, loadingCompleted, chartLabelType, childRefChart, colors, constantLineVal }: { data: any[], dataFieldBars: any[], dataFieldLines: any[], text: string, loadingCompleted: boolean, chartLabelType?: ChartLabelType, childRefChart: any, colors: any, constantLineVal?: number }) => {
    const [isFullScreenChartVisible, setIsFullscreenChartVisible] = React.useState(false);
    const [chartHeightNow, setChartHeightNow] = React.useState(800);

    if (!data || data.length === 0)
        return <NoDataChartLoader title={text} data={data} loadingCompleted={loadingCompleted} />;


    function customizeTooltip(pointInfo: any) {
        let htmlContent = ""
        for (let point of pointInfo.points.slice(1)) {
            let newContent = `<div class="series-name"><span class='bottom-series-name'>${mapLabels(point.seriesName)}</span>: </div><div class="value-text"><span class='bottom-series-value'>${point.valueText}</span>${dataFieldBars.includes(point.seriesName) ? "" : '%'}</div>`;
            htmlContent += newContent;
        }
        return {
            html: `<div><div class="tooltip-header">${pointInfo.argumentText}</div><div class="tooltip-body"><div class="series-name">
            <span class='top-series-name'>${mapLabels(pointInfo.points[0].seriesName)}</span>: </div><div class="value-text"><span class='top-series-value'>${pointInfo.points[0].valueText}</span></div>${htmlContent}
            </div>`,
        };
    }

    const buildChart = () => {
        return (
            <>
                <Chart title={text} dataSource={data}
                    ref={childRefChart}
                    palette={colors} >
                    <Size
                        height={chartHeightNow}
                        width={'100%'}
                    />
                    <CommonSeriesSettings argumentField="dateDisplay" />


                    {
                        dataFieldBars.map((field: any, id: any) => {
                            return <Series axis={"left"} type={"bar"} valueField={field} name={field} key={id} />;
                        })
                    }
                    {
                        dataFieldLines.map((field: any, id: any) => {
                            return <Series axis={"right"} type={"spline"} valueField={field} name={field} key={id} />;
                        })
                    }
                    <ArgumentAxis>
                        <Label overlappingBehavior="stagger" />
                    </ArgumentAxis>
                    <ZoomAndPan
                        valueAxis="both"
                        argumentAxis="both"
                        dragToZoom={true}
                        allowMouseWheel={false}
                        panKey="shift" />
                    <Margin bottom={20} />
                    <ValueAxis
                        name="left"
                        position="left"
                        tickInterval={300}
                    />
                    <ValueAxis
                        name="right"
                        position="right"
                        tickInterval={20}
                        showZero={true}
                        valueMarginsEnabled={false}
                    >
                        {constantLineVal &&
                            <ConstantLine
                                value={constantLineVal}
                                width={3}
                                color="#bf171d"
                                dashStyle="dash"
                            ></ConstantLine>
                        }
                        <Label customizeText={customizePercentageText} />
                    </ValueAxis>
                    <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom" customizeText={customizeLegendTextBarLineChart} />
                    <Export enabled={true} />
                    <Tooltip shared={true} enabled={true} customizeTooltip={customizeTooltip} />
                </Chart>
                <MuiGrid container direction="row" spacing={3} alignItems="center">
                    <MuiGrid item>
                        <ChartHeightField chartHeight={chartHeightNow} setChartHeight={setChartHeightNow} />
                    </MuiGrid>
                    <MuiGrid item>
                        <ResetButtonZoom chartRef={childRefChart} />
                    </MuiGrid>
                    <MuiGrid item>
                        <FullscreenButton isFullScreenVisible={isFullScreenChartVisible} setIsFullscreenChartVisible={setIsFullscreenChartVisible} setChartHeight={setChartHeightNow} />
                    </MuiGrid>
                </MuiGrid>
            </>
        )
    }

    return isFullScreenChartVisible ? <FullScreenChartDialog setChartHeight={setChartHeightNow} visible={isFullScreenChartVisible} title={text} onClose={() => { setIsFullscreenChartVisible(false) }} buildChart={buildChart} /> : buildChart();
}

