import { GroupTriggerBase } from "./GroupTriggerBase";


export enum QmTriggerType
{
    Planned = 0,
    Manual = 1
}

export class GroupQmTrigger extends GroupTriggerBase{

    public constructor(groupId: string, QmTriggerType: QmTriggerType, createdTimestamp: Date) {
        super(groupId, createdTimestamp);
        this.systemETag = null;
        this.qmTriggerType = QmTriggerType;
    }
    public isQmStillWithinTime!:boolean;
    public isQmDone!: boolean;
    public qmTriggerType!: QmTriggerType;
    static GetLabelByType = (type: QmTriggerType) => GroupQmTrigger.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
    static AvailableTypes = [
        
        { value: QmTriggerType.Planned, label: 'Intervall Geplant' },
        { value: QmTriggerType.Manual, label: 'Manuell' }
    ];

    static Columns = {
        ...GroupTriggerBase.Columns,
        qmTriggerType: "cleaningTriggerType",
        isQmStillWithinTime: "isQmStillWithinTime",
        isQmDone: "isQmDone",
    }

    static isQmClass(trigger : GroupTriggerBase) {
        let newtrigger = trigger as GroupQmTrigger;
        return newtrigger.qmTriggerType !== undefined;
    }    
}

export class CleaningTriggerExtraServices {
    public text !: string;
    public isDone !: boolean;
    public standardItemId !: string;
}

