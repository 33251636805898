import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";

export const AssignLicenseToGroup = async (groupId: string, license: GroupLicense, token: string, enqueueSnackbar: any) => {

    if (!groupId || !license || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();

    await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/licenses/${license.id}/assignments`,
        {sensorLicenses : license.sensorLicenses, loraSensorLicenses : license.loraSensorLicenses},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Lizenz wurde erfolgreich zugewiesen", { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};