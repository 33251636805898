import axios from 'axios';
import { api, headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { GroupSettings, GroupSettingsUpdateType } from '../models/GroupSettings';

export const createGroupSettings = async (groupSettings: GroupSettings, token: string, enqueueSnackbar: any): Promise<GroupSettings> => {
    const userId = getUserIdFromStorage();
    return await axios
        .post(`${process.env.REACT_APP_BASIC_URI}/groups/${groupSettings.id}/users/${userId}/settings`,
            { ...groupSettings, systemETag: null },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Speichern von Gerätseinstellungen erfolgreich", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Beim Speichern von Gerätseinstellungen ist ein Fehler aufgetreten", { variant: 'error' });
        });
}