import { t } from "i18next";
import { onlineStatusTitleMenu } from "../components/authorized/ChartsDrawerPoints";
import { PeriodChartFilterType } from "../helpers/charts/base";


export enum ChartDisplaySizeType {
    Print = 0,
    FullWidth = 1,
    FullScreen = 2,
}

export const getLabelTypeForChartDisplaySizeType = (chartDisplaySizeType: ChartDisplaySizeType) => {
    switch (chartDisplaySizeType){
        case ChartDisplaySizeType.Print:
            return "Druckansicht";
        case ChartDisplaySizeType.FullWidth:
            return "Volle Breite";
        case ChartDisplaySizeType.FullScreen:
            return "Fullscreen";
    }
    return "";
}

export enum GridStackElementType {
    Chart = 0,
    Title = 1
}

export enum GridStackItemType {
    CockpitFeedback = 0,
    CockpitVisitor = 1,
    CockpitServiceTrigger = 2,
    CockpitFeedbackCombFeedbackQualityMotion = 3, // feedbackdashboard
    CockpitFeedbacksFeedbackQuestion = 4, // from feedback dashboard
    CockpitCleaningTasks = 5,
    CockpitIncidents = 6,
    CockpitMaterials = 7,
    CockpitServiceWorkerCleaningTasks = 8,
    CockpitServiceWorkerMaterials = 9,
    CockpitServiceWorkerIncidents = 10,
    CockpitGroupFeedbackEntireChartsSimple = 11, // from feedbadck dashboard
    CockpitServiceCompareChart = 12,
    CockpitServiceComparePunctualChart = 13,
    ServiceModesServicesChart = 14,
    ServiceModesMaterialsChart = 15,
    ServiceModesRatioMotionsMaterials = 16,
    ServiceModesMotionMaterialCount = 17,
    ServicesModesIncidentChart = 18,
    ServiceModesServicesCompleted = 19,
    ServiceModesServicesOnTime = 20,
    ServiceModesGroupMotion = 21, //also in Feedback dashboard and Motion dashboard
    ServiceModesTaskDoneChartSimple = 22,
    ServiceModesTaskNotDoneChartSimple = 23,
    ServiceWorkerServiceModesServices = 24,
    ServiceWorkerServiceModesMaterial = 25,
    ServiceWorkerServiceModesIncident = 26,
    ServiceTriggers = 27,
    OnlineStatusGroups = 28,
    OnlineStatusGroupsPercent = 29,


    FeedbacksGetGroupFeedback = 30,
    FeedbacksGetGroupFeedbacksPercent = 31,
    FeedbacksGetGroupFeedbacksMotionComb = 33,
    FeedbacksGetGroupRatioMotionsFeedbacks = 34,
    FeedbacksGetGroupFeedbackPerVisitor = 35,
    FeedbackGroupFeedbackQuestionsWithGroupsSimple = 36,
    FeedbackIncidentVisitorChart = 38,
    FeedbackGetCleanerGroupFeedbacks = 39,


    FrequencyAvgPercentChart = 40,
    FrequencyGroupChart = 41,
    MotionsAggSumDashboard = 42,
    SpecifcGetGroupMotion = 43,
    SpecificSensorCharts = 44,
    SpecificSensorChartsIncreaseSum = 45,
    SpecificSensorChartsFilterSession = 46,
    SpecifcSensorChartsFilterSessionIncreaseSum = 47,


    LoraFrequencyGetGroupLoraMotion = 48,
    LoraFrequencyLoraMotionWithGroupsChartSimple = 49,

    QualityCheckAverageGroupsSimpleChart = 50,
    QualityCheckAverageServicesSimpleChart = 51,
    ObjectLeaderQualityCheckServicesChart = 52,
    ObjectLeaderQualityCheckMaterialChart = 53,
    ObjectLeaderIncidentReportChart = 54,
    ObjectLeaderQualityCheckAverageServicesChart = 55,
    ObjectLeaderQualityCheckAverageMaterialsChart = 56,
    ObjectLeaderQualityCheckAverageServicesAllGroupsCombined = 57,
    ObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined = 58,
    ObjectLeaderQualityCheckAverageServicesGroupsChart = 59,



    HitlistFeedbackAvgDetails = 60,
    HitlistFeedbackQuestion = 61, // from feedbackdashboard also
    HitlistFrequencyCountDetails = 62,
    HitlistServicesCountDetails = 63,
    HitlistServicePunctualCount = 64,

    ObjectLeaderQualityCheckAverageMaterialsGroupsChart = 65,


    FeedbackQualityVerticalChart = 66,


    HeaderTitle = 200,
    FooterTitle = 201


}

export class GridStackCustom {
    public id: string = "";
    public items: GridStackCustomItem[] = [];
    public name: string = "";
    public createdTimestamp: string = "";
    public userId: string = "";
    public cellHeight: number = 100;
    public columnCount: number = 12;

    public isPageCountEnabled: boolean = false;
    public pageCount: number = 1;
    static Columns = {
        actions: 'actions',
        name: 'name',
        items: 'items',
        createdTimestamp: "createdTimestamp",
        createdTime: "createdTime",
        isPageCountEnabled: "isPageCountEnabled",
        pageCount: "pageCount"
    }

    static CreateInstance = () => {
        let newInstance = new GridStackCustom();
        return newInstance;
    }
}

export class GridStackCustomItem {
    public gridStackItemType: GridStackItemType = GridStackItemType.CockpitFeedback;
    public id: string = "testi";
    public createdTimestamp: Date = new Date();
    public posX: number = 0;
    public posY: number = 0;
    public width: number = 0;
    public height: number = 0;
    public chartHeight: number = 450;
    public isAutoChartHeightEnabled = false;
    public isStandardChartHeightEnabled = true;

    public chartChoice: string = '';
    public periodChartFilterType: PeriodChartFilterType = PeriodChartFilterType.None;


    public originOfChart: string = "";

    constructor(type: GridStackItemType) {
        this.gridStackItemType = type;
        this.id = type.toString();
    }

    static CreateInstance = (type: GridStackItemType) => {
        let newInstance = new GridStackCustomItem(type);
        newInstance.id = this.GetLabelType(type);
        return newInstance;
    }


    static GetLabelType = (type: GridStackItemType | null) => {
        switch (type) {
            case GridStackItemType.CockpitFeedback:
                return "Feedback-Meldungen";
            case GridStackItemType.CockpitVisitor:
                return "Besucher";
            case GridStackItemType.CockpitServiceTrigger:
                return "Serviceauslösungen";
            case GridStackItemType.CockpitFeedbacksFeedbackQuestion:
                return "Feedbackfrage Typ ausgelöst";
            case GridStackItemType.CockpitCleaningTasks:
                return "Serviceleistungen"
            case GridStackItemType.CockpitIncidents:
                return "Vorfallmeldungen"
            case GridStackItemType.CockpitMaterials:
                return "Materialverbrauch"
            case GridStackItemType.CockpitServiceWorkerCleaningTasks:
                return "Serviceleistungen durchgeführt durch Servicekräfte"
            case GridStackItemType.CockpitServiceWorkerIncidents:
                return "Vorfallmeldung durchgeführt durch Servicekräfte"
            case GridStackItemType.CockpitServiceWorkerMaterials:
                return "Material verwendet durch Servicekräfte"
            case GridStackItemType.CockpitFeedbackCombFeedbackQualityMotion:
                return "Besucher & Feedback Qualität";
            case GridStackItemType.CockpitGroupFeedbackEntireChartsSimple:
                return "Feedback Analyse Gruppiert Einheiten"
            case GridStackItemType.CockpitServiceCompareChart:
                return "Service erledigt: Alle & Teilweise";
            case GridStackItemType.CockpitServiceComparePunctualChart:
                return "Verpasst & Pünktlich";
            case GridStackItemType.ServiceModesServicesChart:
                return "Serviceleistungen";
            case GridStackItemType.ServiceModesMaterialsChart:
                return t("servicedocumentationChart.materialUsage");
            case GridStackItemType.ServiceModesRatioMotionsMaterials:
                return t("servicedocumentationChart.materialPerPieceForVisitors");
            case GridStackItemType.ServiceModesMotionMaterialCount:
                return t("servicedocumentationChart.materialPer100Visitors");
            case GridStackItemType.ServicesModesIncidentChart:
                return "Vorfallmeldungen";
            case GridStackItemType.ServiceModesServicesCompleted:
                return t("servicedocumentationChart.serviceModePartialAll")
            case GridStackItemType.ServiceModesServicesOnTime:
                return t("servicedocumentationChart.serviceModeMissedOnTime")
            case GridStackItemType.ServiceModesGroupMotion:
                return "Besucher Gesamt";
            case GridStackItemType.ServiceModesTaskDoneChartSimple:
                return "Services, die erledigt wurden";
            case GridStackItemType.ServiceModesTaskNotDoneChartSimple:
                return "Services, die nicht erledigt wurden"
            case GridStackItemType.ServiceWorkerServiceModesServices:
                return t("servicedocumentationServicestaff.servicesCompletedBy")
            case GridStackItemType.ServiceWorkerServiceModesMaterial:
                return "Materialverbrauch durch:"
            case GridStackItemType.ServiceWorkerServiceModesIncident:
                return t("servicedocumentationServicestaff.incidentReportBy")
            case GridStackItemType.ServiceTriggers:
                return "Serviceauslösungen"
            case GridStackItemType.OnlineStatusGroups:
                return "Online Status pro Einheit Gesamt Bericht";
            case GridStackItemType.OnlineStatusGroupsPercent:
                return "Online Status pro Einheit in Prozent Bericht";
            case GridStackItemType.HitlistFeedbackAvgDetails:
                return "Feedbackqualität Gesamt Bericht"
            case GridStackItemType.HitlistFeedbackQuestion:
                return "Feedbackfrage Gesamt Bericht"
            case GridStackItemType.HitlistFrequencyCountDetails:
                return "Frequenz Anzahl pro Einheit Gesamt Bericht"
            case GridStackItemType.HitlistServicesCountDetails:
                return "Service Anzahl pro Einheit Gesamt Bericht"
            case GridStackItemType.HitlistServicePunctualCount:
                return "Service Pünktlich/Verpasst pro Einheit Gesamt Bericht";


            case GridStackItemType.FeedbacksGetGroupFeedback:
                return "Feedback Typ ausgelöst (Anzahl)";
            case GridStackItemType.FeedbacksGetGroupFeedbacksPercent:
                return "Feedback Typ ausgelöst in Prozent zueinander"

            case GridStackItemType.FeedbacksGetGroupFeedbacksMotionComb:
                return "Besucher & Feedback Typ ausgelöst in Prozent zueinander"
            case GridStackItemType.FeedbacksGetGroupRatioMotionsFeedbacks:
                return "Gesamt Feedbackarten / Gesamt Besucher Verhältnis in %";
            case GridStackItemType.FeedbacksGetGroupFeedbackPerVisitor:
                return "Gesamt Feedback / Gesamt Besucher - Verhältnis in %";
            case GridStackItemType.FeedbackGroupFeedbackQuestionsWithGroupsSimple:
                return "Gesamt Feedbackfrage der Einheiten Bericht"
            case GridStackItemType.FeedbackIncidentVisitorChart:
                return "Vorfall Besucher (Standard Vorgefertigte Texte)"
            case GridStackItemType.FeedbackGetCleanerGroupFeedbacks:
                return "Servicekraftfeedback";
            case GridStackItemType.FeedbackQualityVerticalChart:
                return "Gesamt Feedbackqualität der Einheiten Bericht"

            case GridStackItemType.FrequencyAvgPercentChart:
                return "Besucherauslastung über Zeitraum in % pro Einheit"
            case GridStackItemType.FrequencyGroupChart:
                return "Einheiten Besucherauslastung (in % zueinander)";
            case GridStackItemType.MotionsAggSumDashboard:
                return "Besucher Verlauf Gesamt";
            case GridStackItemType.SpecifcGetGroupMotion:
                return "Besucher aller Räume";
            case GridStackItemType.SpecificSensorCharts:
                return "Besucher im Raum: | Filter: Sensor-Raum-Name";
            case GridStackItemType.SpecificSensorChartsIncreaseSum:
                return "Besucher Verlauf im Raum: | Filter: Sensor-Raum-Name";
            case GridStackItemType.SpecificSensorChartsFilterSession:
                return "Besucher im Raum | Filter: Session (Letzter Sensor Setting Aufbau)";
            case GridStackItemType.SpecifcSensorChartsFilterSessionIncreaseSum:
                return "Besucher Verlauf im Raum | Filter: Session (Letzter Sensor Setting Aufbau)";
            case GridStackItemType.LoraFrequencyGetGroupLoraMotion:
                return "Belegung Gesamt (h)";
            case GridStackItemType.LoraFrequencyLoraMotionWithGroupsChartSimple:
                return "Belegungsrate Gruppiert Nach Einheiten Gesamt";

            case GridStackItemType.QualityCheckAverageGroupsSimpleChart:
                return "Qualitätsmanagement Einheiten Gesamt Bericht";
            case GridStackItemType.QualityCheckAverageServicesSimpleChart:
                return "Qualitätsmanagement Services Gesamt Bericht";
            case GridStackItemType.ObjectLeaderQualityCheckServicesChart:
                return "Services Qualitätssicherung";
            case GridStackItemType.ObjectLeaderQualityCheckMaterialChart:
                return "Material Qualitätssicherung";
            case GridStackItemType.ObjectLeaderIncidentReportChart:
                return "Vorfallmeldungen Qualitätssicherung";
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesChart:
                return "Durchschnitt Services Qualitätssicherung";
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsChart:
                return "Durchschnitt Materialien Qualitätssicherung";
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesAllGroupsCombined:
                return "Bewertungsverlauf Alle Einheiten Zusammen: Services";
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined:
                return "Bewertungsverlauf Alle Einheiten Zusammen: Materialverbrauch";
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesGroupsChart:
                return "Einheiten Gesamt Bewertung Services";
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsGroupsChart:
                return "Einheiten Gesamt Bewertung Materialverbrauch";
            case GridStackItemType.HeaderTitle:
                return "Kopfzeile mit Dashboard-Name";
            case GridStackItemType.FooterTitle:
                return "Footerzeile";

            default:
                return "unbekannt"
        }
    }

    static GetOrigin = (gridStackItemType: GridStackItemType) => {
        let originOfChart = [""];
        switch (gridStackItemType) {
            case GridStackItemType.CockpitFeedback:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitVisitor:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitServiceTrigger:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitFeedbacksFeedbackQuestion:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitCleaningTasks:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitIncidents:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitMaterials:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitServiceWorkerCleaningTasks:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitServiceWorkerIncidents:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitServiceWorkerMaterials:
                originOfChart = ["Cockpit"];
                break;
            case GridStackItemType.CockpitFeedbackCombFeedbackQualityMotion:
                originOfChart = ["Cockpit", "Feedback - Analyse"];
                break;
            case GridStackItemType.CockpitGroupFeedbackEntireChartsSimple:
                originOfChart = ["Cockpit", "Feedback - Analyse"];
                break;
            case GridStackItemType.CockpitServiceCompareChart:
                originOfChart = ["Cockpit", "Services"];
                break;
            case GridStackItemType.CockpitServiceComparePunctualChart:
                originOfChart = ["Cockpit", "Services"];
                break;
            case GridStackItemType.ServiceModesServicesChart:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceModesMaterialsChart:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceModesRatioMotionsMaterials:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceModesMotionMaterialCount:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServicesModesIncidentChart:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceModesServicesCompleted:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceModesServicesOnTime:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceModesGroupMotion:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceModesTaskDoneChartSimple:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceModesTaskNotDoneChartSimple:
                originOfChart = [t("drawerList.servicedocumentation")];
                break;
            case GridStackItemType.ServiceWorkerServiceModesServices:
                originOfChart = ["Leistungsdoku - Servicekräfte/in"];
                break;
            case GridStackItemType.ServiceWorkerServiceModesMaterial:
                originOfChart = ["Leistungsdoku - Servicekräfte/in"];
                break;
            case GridStackItemType.ServiceWorkerServiceModesIncident:
                originOfChart = ["Leistungsdoku - Servicekräfte/in"];
                break;
            case GridStackItemType.ServiceTriggers:
                originOfChart = ["Serviceauslösungen - Analyse"];
                break;
            case GridStackItemType.OnlineStatusGroups:
                originOfChart = [onlineStatusTitleMenu, "Hitliste"];
                break;
            case GridStackItemType.OnlineStatusGroupsPercent:
                originOfChart = [onlineStatusTitleMenu];
                break;
            case GridStackItemType.HitlistFeedbackAvgDetails:
                originOfChart = ["Hitliste"];
                break;
            case GridStackItemType.HitlistFeedbackQuestion:
                originOfChart = ["Hitliste"];
                break;
            case GridStackItemType.HitlistFrequencyCountDetails:
                originOfChart = ["Hitliste"];
                break;
            case GridStackItemType.HitlistServicesCountDetails:
                originOfChart = ["Hitliste"];
                break;
            case GridStackItemType.HitlistServicePunctualCount:
                originOfChart = ["Hitliste"];
                break;


            case GridStackItemType.FeedbacksGetGroupFeedback:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FeedbacksGetGroupFeedbacksPercent:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FeedbacksGetGroupFeedbacksMotionComb:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FeedbacksGetGroupRatioMotionsFeedbacks:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FeedbacksGetGroupFeedbackPerVisitor:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FeedbackGroupFeedbackQuestionsWithGroupsSimple:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FeedbackIncidentVisitorChart:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FeedbackGetCleanerGroupFeedbacks:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FeedbackQualityVerticalChart:
                originOfChart = ["Feedback - Analyse"];
                break;
            case GridStackItemType.FrequencyAvgPercentChart:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.FrequencyGroupChart:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.MotionsAggSumDashboard:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.SpecifcGetGroupMotion:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.SpecificSensorCharts:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.SpecificSensorChartsIncreaseSum:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.SpecificSensorChartsFilterSession:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.SpecifcSensorChartsFilterSessionIncreaseSum:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.SpecificSensorChartsFilterSession:
                originOfChart = ["Frequenz - Analyse"];
                break;
            case GridStackItemType.SpecifcSensorChartsFilterSessionIncreaseSum:
                originOfChart = ["Frequenz - Analyse"];
                break;

            case GridStackItemType.LoraFrequencyGetGroupLoraMotion:
                originOfChart = ["Frequenz Lora - Analyse"];
                break;
            case GridStackItemType.LoraFrequencyLoraMotionWithGroupsChartSimple:
                originOfChart = ["Frequenz Lora - Analyse"];
                break;
            case GridStackItemType.QualityCheckAverageGroupsSimpleChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.QualityCheckAverageServicesSimpleChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderQualityCheckServicesChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderQualityCheckMaterialChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderIncidentReportChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesAllGroupsCombined:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderQualityCheckAverageServicesGroupsChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsGroupsChart:
                originOfChart = ["Qualitätssicherung - Analyse"];
                break;
            case GridStackItemType.HeaderTitle:
                originOfChart = ["Überschrift"];
                break;
            case GridStackItemType.FooterTitle:
                originOfChart = ["Einheiten"];
                break;

            default:
                originOfChart = ["unbekannt"];
        }
        return originOfChart
    }

    static Columns = {
        id: 'id',
        createdTimestamp: "createdTimestamp",
        createdTime: "createdTime",
        type: 'type',
        origin: 'origin'
    }


}

export class GridStackItemDisplayTable {
    public gridStackItemType: GridStackItemType = GridStackItemType.CockpitFeedback;
    public title: string = "";
    public originOfChart: string[] = [];
    public imagePreview: string = "";

    static CreateInstance = (gridStackItemType: GridStackItemType) => {
        let newItem = new GridStackItemDisplayTable();
        newItem.title = GridStackCustomItem.GetLabelType(gridStackItemType);
        newItem.gridStackItemType = gridStackItemType;
        newItem.originOfChart = GridStackCustomItem.GetOrigin(gridStackItemType);
        newItem.imagePreview = `https://restroombird.blob.core.windows.net/images-charts/${gridStackItemType}-chartpreview.png`
        return newItem;
    }

    static Columns = {
        gridStackItemType: 'gridStackItemType',
        originOfChart: "originOfChart",
        imagePreview: "imagePreview",
        id: "id",
    }

}


export const GLOBAL_ALL_GRID_STACK_TYPES_TITLE = [
    GridStackItemType.HeaderTitle,
    GridStackItemType.FooterTitle
];

export const GLOBAL_ALL_GRID_STACK_TYPES = [
    GridStackItemType.CockpitFeedback,
    GridStackItemType.CockpitVisitor,
    GridStackItemType.CockpitServiceTrigger,
    GridStackItemType.CockpitFeedbackCombFeedbackQualityMotion,
    GridStackItemType.CockpitFeedbacksFeedbackQuestion,
    GridStackItemType.CockpitCleaningTasks,
    GridStackItemType.CockpitIncidents,
    GridStackItemType.CockpitMaterials,
    GridStackItemType.CockpitServiceWorkerCleaningTasks,
    GridStackItemType.CockpitServiceWorkerMaterials,
    GridStackItemType.CockpitServiceWorkerIncidents,
    GridStackItemType.CockpitGroupFeedbackEntireChartsSimple,
    GridStackItemType.CockpitServiceCompareChart,
    GridStackItemType.CockpitServiceComparePunctualChart,
    GridStackItemType.ServiceModesServicesChart,
    GridStackItemType.ServiceModesMaterialsChart,
    GridStackItemType.ServiceModesRatioMotionsMaterials,
    GridStackItemType.ServiceModesMotionMaterialCount,
    GridStackItemType.ServicesModesIncidentChart,
    GridStackItemType.ServiceModesServicesCompleted,
    GridStackItemType.ServiceModesServicesOnTime,
    GridStackItemType.ServiceModesGroupMotion,
    GridStackItemType.ServiceModesTaskDoneChartSimple,
    GridStackItemType.ServiceModesTaskNotDoneChartSimple,
    GridStackItemType.ServiceWorkerServiceModesServices,
    GridStackItemType.ServiceWorkerServiceModesMaterial,
    GridStackItemType.ServiceWorkerServiceModesIncident,
    GridStackItemType.ServiceTriggers,
    GridStackItemType.OnlineStatusGroups,
    GridStackItemType.OnlineStatusGroupsPercent,


    GridStackItemType.FeedbacksGetGroupFeedback,
    GridStackItemType.FeedbacksGetGroupFeedbacksPercent,
    GridStackItemType.FeedbacksGetGroupFeedbacksMotionComb,
    GridStackItemType.FeedbacksGetGroupRatioMotionsFeedbacks,
    GridStackItemType.FeedbacksGetGroupFeedbackPerVisitor,
    GridStackItemType.FeedbackGroupFeedbackQuestionsWithGroupsSimple,
    GridStackItemType.FeedbackIncidentVisitorChart,
    GridStackItemType.FeedbackGetCleanerGroupFeedbacks,
    GridStackItemType.FeedbackQualityVerticalChart,


    GridStackItemType.FrequencyAvgPercentChart,
    GridStackItemType.FrequencyGroupChart,
    GridStackItemType.MotionsAggSumDashboard,
    GridStackItemType.SpecifcGetGroupMotion,
    GridStackItemType.SpecificSensorCharts,
    GridStackItemType.SpecificSensorChartsIncreaseSum,
    GridStackItemType.SpecificSensorChartsFilterSession,
    GridStackItemType.SpecifcSensorChartsFilterSessionIncreaseSum,

    GridStackItemType.LoraFrequencyGetGroupLoraMotion,
    GridStackItemType.LoraFrequencyLoraMotionWithGroupsChartSimple,

    GridStackItemType.QualityCheckAverageGroupsSimpleChart,
    GridStackItemType.QualityCheckAverageServicesSimpleChart,
    GridStackItemType.ObjectLeaderQualityCheckServicesChart,
    GridStackItemType.ObjectLeaderQualityCheckMaterialChart,
    GridStackItemType.ObjectLeaderIncidentReportChart,
    GridStackItemType.ObjectLeaderQualityCheckAverageServicesChart,
    GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsChart,
    GridStackItemType.ObjectLeaderQualityCheckAverageServicesAllGroupsCombined,
    GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsAllGroupsCombined,
    GridStackItemType.ObjectLeaderQualityCheckAverageServicesGroupsChart,
    GridStackItemType.ObjectLeaderQualityCheckAverageMaterialsGroupsChart,





    GridStackItemType.HitlistFeedbackAvgDetails,
    GridStackItemType.HitlistFeedbackQuestion,
    GridStackItemType.HitlistFrequencyCountDetails,
    GridStackItemType.HitlistServicesCountDetails,
    GridStackItemType.HitlistServicePunctualCount,

];