import React from 'react';
import { api } from '../../../api';
import { UserAccount } from '../../../models/UserAccount';
import { UserType } from "../../../models/UserType";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Add, Apartment, ExpandMore, SupervisedUserCircle, SupportAgent } from '@mui/icons-material';
import { GroupSettingFieldsUseStyles } from '../../../styles/GroupSettingFieldsStyles';
import { ReactComponent as AccountReactivateSvg } from '../../../assets/svgs/account-reactivate.svg'
import { ReactComponent as AccountSearchSvg } from '../../../assets/svgs/account-search.svg'
import { useSnackbar } from 'notistack';
import { AddGroupsToUserDialog } from '../../dialogs/AddGroupsToUserDialog';
import { UserAssignedGroup } from '../../../models/UserAssignedGroup';
import { ManagerCustomersUserItem } from './ManagerCustomersUserItem';
import { ManagerCustomersConfirmDeletionDialog } from '../../dialogs/ManagerCustomersConfirmDeletionDialog';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../helpers/storage-management';
import { RegistrationUser } from '../../../models/RegistrationUser';
import { getUserIdFromStorage } from '../../../helpers/common';
import { AddObjectsToPortalUserDialog } from '../../dialogs/AddObjectsToPortalUserDialog';
import { UserMenuVisibilityEditingDialog } from './UserMenuVisibilityEditingDialog';

export const objectCustomerName = "Kunde - Objektcontroller"

export const ManagerCustomers = ({ }: {}) => {

    const classes = GroupSettingFieldsUseStyles();
    const history = useHistory();

    const [token, setToken] = React.useState<string>('');
    const [objectCustomers, setObjectCustomers] = React.useState<UserAccount[]>([]);
    const [customers, setCustomers] = React.useState<UserAccount[]>([]);
    const [managers, setManagers] = React.useState<UserAccount[]>([]);
    const [visibleAddGroupsToUserDialog, setVisibleAddGroupsToUserDialog] = React.useState(false);
    const [visibleAddObjectsToUserDialog, setVisibleAddObjectsToUserDialog] = React.useState(false);

    const [selectedUser, setSelectedUser] = React.useState<UserAccount>();
    const [userAssignedGroups, setUserAssignedGroups] = React.useState<UserAssignedGroup>();
    const [deletionUser, setDeletionUser] = React.useState<UserAccount | null>(null);
    const [confirmDeletionDialogVisible, setConfirmDeletionDialogVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [isUserMenuVisibilityDialogVisible, setIsUserMenuVisibilityDialogVisible] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        (async () => {

            if (!token) {
                setToken(getApiToken(history));
                return;
            }
            setLoading(true);
            const users = await api.getUserByInvitedFromUserId(token);
            if (!users) return;

            setManagers(users.filter((user: UserAccount) => user.userType === UserType.Manager));
            setCustomers(users.filter((user: UserAccount) => user.userType === UserType.Customer));
            setObjectCustomers(users.filter((user: UserAccount) => user.userType === UserType.ObjectCustomer));
            setLoading(false);
        })();
    }, [token]);



    const inviteUser = async (user: UserAccount, userIndex: number) => {

        const res = await api.registerNewUser(user.mail, "", user.title, user.firstname, user.lastname, user.company, user.userType, user.invitedFromUserId, enqueueSnackbar);

        if (!res?.token) {
            return;
        }

        user.id = res.userId;
        switch (user.userType) {
            case UserType.Manager:
                setManagers(managers.map((manager: UserAccount, index: number) => index === userIndex ? { ...manager, id: res.userId } : manager));
                break;
            case UserType.Customer:
                setCustomers(customers.map((customer: UserAccount, index: number) => index === userIndex ? { ...customer, id: res.userId } : customer));
                break;
            case UserType.ObjectCustomer:
                setCustomers(objectCustomers.map((customer: UserAccount, index: number) => index === userIndex ? { ...customer, id: res.userId } : customer));
                break;
        }

        manageUserUnits(user);
    }

    const manageUserUnits = async (user: UserAccount, userIndex: number = 0) => {
        setSelectedUser(user);
        const assignedGroups = await api.getUserAssignedGroupByInvitedUserId(token, user.id);
        setUserAssignedGroups(assignedGroups);
        setVisibleAddGroupsToUserDialog(true);
    }

    const manageObjectUnits = async (user: UserAccount, userIndex: number = 0) => {
        setSelectedUser(user);
        const assignedGroups = await api.getUserAssignedGroupByInvitedUserId(token, user.id);
        setUserAssignedGroups(assignedGroups);
        setVisibleAddObjectsToUserDialog(true);
    }

    const onManageUserVisibility = async (user: UserAccount, userIndex: number = 0) => {
        setSelectedUser(user);
        setIsUserMenuVisibilityDialogVisible(true);
    }


    const updateUserByIdFromOwner = async (user: UserAccount, userIndex: number = 0) => {
        await api.updateUserFromOwner(user, token, enqueueSnackbar);
    }

    const deleteUser = async (user: UserAccount, userIndex: number) => {

        if (!user?.id) {

            switch (user.userType) {
                case UserType.Manager:
                    setManagers(managers.filter((manager: UserAccount, index: number) => index !== userIndex));
                    break;
                case UserType.Customer:
                    setCustomers(customers.filter((customer: UserAccount, index: number) => index !== userIndex));
                    break;
            }

            return;
        }

        setDeletionUser(user);
        setConfirmDeletionDialogVisible(true);
    }

    if (loading) {
        return (
            <Grid container spacing={3}>
                <Grid item>
                    <CircularProgress size={64} />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Accordion className={classes.hover}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container direction="row" spacing={3}>
                            <Grid item>
                                <SupervisedUserCircle style={{ fontSize: 50 }} fill={"#000000"} />
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4" style={{ marginLeft: -14 }}>Manager</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction={"column"} spacing={3}>
                            <Grid container justifyContent={"flex-start"} item>
                                <Grid item>
                                    <Button
                                        size="large"
                                        startIcon={<Add />}
                                        variant={'contained'}
                                        onClick={async () => {
                                            const userId = getUserIdFromStorage() || "";
                                            setManagers([...managers, UserAccount.CreateInvitedUser(userId, UserType.Manager)]);
                                        }}
                                        color="primary"
                                    >
                                        Manager hinzufügen
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                {
                                    managers &&
                                    managers.map((manager: UserAccount, index: number) => (
                                        <ManagerCustomersUserItem
                                            user={manager}
                                            setUser={setManagers}
                                            users={managers}
                                            userIndex={index}
                                            onUpdate={updateUserByIdFromOwner}
                                            onManageUserUnits={manageUserUnits}
                                            onManageObjects={manageObjectUnits}
                                            onInviteUser={inviteUser}
                                            onDeleteUser={deleteUser} 
                                            onManageUserVisibility={onManageUserVisibility}                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion className={classes.hover}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid container direction="row" spacing={3}>
                            <Grid item>
                                <SupportAgent style={{ fontSize: 50 }} fill={"#000000"} />
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4" style={{ marginLeft: -14 }}>Kunden</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction={"column"} spacing={3}>
                            <Grid container justifyContent={"flex-start"} item>
                                <Grid item>
                                    <Button
                                        size="large"
                                        startIcon={<Add />}
                                        variant={'contained'}
                                        onClick={async () => {
                                            const userId = getUserIdFromStorage() || "";
                                            setCustomers([...customers, UserAccount.CreateInvitedUser(userId, UserType.Customer)]);
                                        }}
                                        color="primary"
                                    >
                                        Kunde hinzufügen
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                {
                                    customers &&
                                    customers.map((customer: UserAccount, index: number) => (
                                        <ManagerCustomersUserItem
                                            user={customer}
                                            setUser={setCustomers}
                                            userIndex={index}
                                            users={customers}
                                            onUpdate={updateUserByIdFromOwner}
                                            onManageUserUnits={manageUserUnits}
                                            onManageObjects={manageObjectUnits}
                                            onManageUserVisibility={onManageUserVisibility}
                                            onInviteUser={inviteUser}
                                            onDeleteUser={deleteUser}
                                        />
                                    ))}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion className={classes.hover}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid container direction="row" spacing={3}>
                            <Grid item>
                                <Apartment style={{ fontSize: 50 }} fill={"#000000"} />
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4" style={{ marginLeft: -14 }}>{objectCustomerName}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction={"column"} spacing={3}>
                            <Grid container justifyContent={"flex-start"} item>
                                <Grid item>
                                    <Button
                                        size="large"
                                        startIcon={<Add />}
                                        variant={'contained'}
                                        onClick={async () => {
                                            const userId = getUserIdFromStorage() || "";
                                            setObjectCustomers([...objectCustomers, UserAccount.CreateInvitedUser(userId, UserType.ObjectCustomer)]);
                                        }}
                                        color="primary"
                                    >
                                        {objectCustomerName} hinzufügen
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                {
                                    objectCustomers &&
                                    objectCustomers.map((customer: UserAccount, index: number) => (
                                        <ManagerCustomersUserItem
                                            user={customer}
                                            setUser={setCustomers}
                                            userIndex={index}
                                            users={customers}
                                            onUpdate={updateUserByIdFromOwner}
                                            onManageUserUnits={manageUserUnits}
                                            onManageObjects={manageObjectUnits}
                                            onInviteUser={inviteUser}
                                            onDeleteUser={deleteUser}
                                            onManageUserVisibility={onManageUserVisibility}

                                        />
                                    ))}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {userAssignedGroups &&
                <AddGroupsToUserDialog
                    user={selectedUser}
                    open={visibleAddGroupsToUserDialog}
                    setOpen={setVisibleAddGroupsToUserDialog}
                    userAssignedGroups={userAssignedGroups}
                    setUserAssignedGroups={setUserAssignedGroups}
                />
            }

            {userAssignedGroups &&
                <AddObjectsToPortalUserDialog
                    user={selectedUser}
                    open={visibleAddObjectsToUserDialog}
                    setOpen={setVisibleAddObjectsToUserDialog}
                    userAssignedGroups={userAssignedGroups}
                    setUserAssignedGroups={setUserAssignedGroups}
                />
            }
            {selectedUser &&
                <UserMenuVisibilityEditingDialog updateUserByIdFromOwner={updateUserByIdFromOwner} visible={isUserMenuVisibilityDialogVisible} setVisible={setIsUserMenuVisibilityDialogVisible} userAccount={selectedUser} setUserAccount={setSelectedUser} />
            }
            <ManagerCustomersConfirmDeletionDialog
                open={confirmDeletionDialogVisible}
                setOpen={setConfirmDeletionDialogVisible}
                onDeletionConfirmed={async () => {

                    if (!deletionUser?.id) return;

                    await api.deleteUserAsync(deletionUser.id, token, enqueueSnackbar);

                    switch (deletionUser.userType) {
                        case UserType.Manager:
                            setManagers(managers.filter((manager: UserAccount) => manager.id !== deletionUser.id));
                            break;
                        case UserType.Customer:
                            setCustomers(customers.filter((customer: UserAccount) => customer.id !== deletionUser.id));
                            break;
                        case UserType.ObjectCustomer:
                            setObjectCustomers(objectCustomers.filter((customer: UserAccount) => customer.id !== deletionUser.id));
                            break;
                    }

                    setDeletionUser(null);
                }}
            />

        </Grid>
    );
}