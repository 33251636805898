import axios from 'axios';
import { headers, handleApiError, handleApiSuccess } from "../api";
import { getUserIdFromStorage } from '../helpers/common';

export const copyBlobItemsOfTemplateToGroupAsync = async (templateId: string | null, groupId: string, token: string, enqueueSnackbar:any) => {

    if (!templateId) return;

    try {
        const userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/templates/${templateId}/types/copy-blobs-from-template`,
            null,
            { headers: { ...headers, 'Authorization': 'Bearer ' + token } });
        handleApiSuccess(ret.data, enqueueSnackbar, "Werbung und Logo wurden kopiert");
        return ret.data;

    } catch (err) {
        handleApiError(err, enqueueSnackbar,"Werbung und Logo konnten nicht kopiert werden");
        return null;
    }
}