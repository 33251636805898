import GreenSmile from './image/smiles/green.png';
import YellowSmile from './image/smiles/yellow.png';
import RedSmile from './image/smiles/red.png';
import GreenLightSmile from './image/smiles/lightgreensmile.png';
import RedLightSmile from './image/smiles/lightredsmile.png';
import ThreeSmiles from './image/smiles/threesmiles.png';
import FiveSmiles from './image/smiles/fivesmiles.png';
import SmileLine from './image/smiles/smileLine.png';
import DisplayText from './image/smiles/displaytext.png';

import { ReactComponent as LicenseSvg } from './svgs/license.svg'
import { ReactComponent as MotionSensorSvg } from './svgs/motion-sensor.svg';



import { ReactComponent as HexagonSlice2Svg } from './svgs/hexagon-slice-2.svg'
import { ReactComponent as HexagonSlice3Svg } from './svgs/hexagon-slice-3.svg';
import { ReactComponent as HexagonSlice4Svg } from './svgs/hexagon-slice-4.svg'
import { ReactComponent as HexagonSlice5Svg } from './svgs/hexagon-slice-5.svg';
import { ReactComponent as HexagonSlice6Svg } from './svgs/hexagon-slice-6.svg'

import { errorColor, green, grey, lightRed, red, yellow } from '../styles/colors';
import { lightGreen } from '@mui/material/colors';


export const GREEN_SMILE = GreenSmile;
export const GREEN_LIGHT_SMILE = GreenLightSmile;
export const YELLOW_SMILE = YellowSmile;
export const RED_LIGHT_SMILE = RedLightSmile;
export const RED_SMILE = RedSmile;
export const FIVE_SMILE = FiveSmiles;
export const SMILE_LINE = SmileLine;
export const DISPLAY_TEXT = DisplayText;

export const LicenseBlackSize = (width: number, height: number) => <LicenseSvg fill='#000000' width={width} height={height}/>
export const LicenseWhite = () => <LicenseSvg fill='#ffffff'/>
export const LicenseBlack = () => <LicenseSvg fill='#000000'/>
export const LicenseSvgGrey = () => <LicenseSvg fill="#616161" />
export const LicenseIconGreen = () => <LicenseSvg fill={green}/>
export const LicenseIconRed = () => <LicenseSvg fill={red}/>
export const MotionSensorSvgWhite = ({color = '#ffffff', height = 30} : {color? : string, height?: number}) => <MotionSensorSvg height={height} width={height} fill={color}/>

export const HexagonSlice2 = () => <HexagonSlice2Svg width={250} height={250} fontSize={100} fill={errorColor}/>
export const HexagonSlice3 = () => <HexagonSlice3Svg width={250} height={250} fontSize={100} fill={lightRed}/>
export const HexagonSlice4 = () => <HexagonSlice4Svg width={250} height={250} fontSize={100} fill={yellow}/>
export const HexagonSlice5 = () => <HexagonSlice5Svg width={250} height={250} fontSize={100} fill={lightGreen[100]}/>
export const HexagonSlice6 = () => <HexagonSlice6Svg width={250} height={250} fontSize={100} fill={lightGreen[800]}/>


