import { eachDayOfInterval, formatISO, getMinutes } from "date-fns";
import { GroupServiceCompletedCleaningTasks, GroupServiceIsOnTimeCleaningTasks, GroupServiceMode, GroupServiceModeMaterialConsumption } from "../../../models/GroupServiceMode";

export const generateCleaningDurationData = (groupsServiceMode: GroupServiceMode[]) => {
    let arr = groupsServiceMode.filter(x=>x.endTimestamp > x.startTimestamp).map(el => {
        console.log(el.endTimestamp, el.startTimestamp , "timestamps");
        return { date: formatISO(new Date(el.createdTimestamp * 1000)), timeOfService: el.endTimestamp - el.startTimestamp, }
    });
    return arr;
}

export const generatePlannedCleaningDuration = (startDate: Date, endDate: Date, areaPerformance: number, areaSize: number) => {
    let allDays = eachDayOfInterval({
        start: startDate,
        end: endDate
    });
    return allDays.map(day=>{
        return {date: day, duration: areaSize / areaPerformance}
    })
}
