import { Dialog, DialogContent, DialogActions, Button, Typography, Theme, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ManagerCustomersConfirmDeletionDialog = ({ open, setOpen, onDeletionConfirmed }: { open: boolean; setOpen: any; onDeletionConfirmed: any }) => {

    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);

    const onClose = () => setOpen(false);
    const {t} = useTranslation();

    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
            <DialogContent className={classes.dialogContent}>
                <Typography>Möchten Sie diesen Benutzer wirklich löschen ?</Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    size="large"
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    endIcon={loading && <CircularProgress size={32}/>}
                    onClick={async () => {
                        setLoading(true);
                        await onDeletionConfirmed();
                        setLoading(false);
                        onClose();
                    }}
                >
                    Bestätigen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(4),
    },
}));
