import { Grid, Paper } from "@mui/material";
import React from "react";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { TimeChoice } from "../../../../core/TimeChoice";

export const GetGroupFeedbackQuestions = ({ data, dataField, allData, setChartData, childRef, loadingCompleted, childRefChart, title, colors =  ["#0EAD1C", "#FF0000", "#d4d008"]}
    : { data: any, dataField: any[], allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any, title: string, colors?: string[] | string}) => {
    const [chartChoice, setChartChoice] = React.useState('1');

    const sumFeedbackQuestion = React.useMemo(() => data.reduce((prevVal:any, x: any) => {
        let sum = dataField.reduce((currentSum,field) => {
            return x[field] + currentSum;
        },0)
        if (!sum) sum = 0;
        return prevVal + sum

    }, 0), [data]);
/*
    const newData = React.useMemo(() => data.map((x: any) => {
        let sum = dataField.reduce((currentSum,field) => {
            return x[field] + currentSum;
        },0);
        if (!sum) sum = 0;
        for (let field of dataField){
            x[field+'percent'] = x[field] * 100 / sum
        }
        return x;

    }), [data]); */

    const text = title + `<br>Gesamtsumme: <b>${sumFeedbackQuestion}</b>`;


    const buildGridItem = () => {

        switch (chartChoice) {
            case '0':
                return <LineChart
                    childRefChart={childRefChart}
                    colors={colors}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    childRefChart={childRefChart}
                    colors={colors}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChart
                    childRefChart={childRefChart}
                    colors={colors}
                    data={data}
                    dataField={dataField}
                    text={text}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} />
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}