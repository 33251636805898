import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Delete, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { ServiceWorkerType, UserServiceWorker } from '../../../models/UserServiceWorker';
import { errorColor, white } from '../../../styles/colors';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { UserServiceWorkerSelectionDataTable } from './entireEval/UserServiceWorkerSelectionDataTable';
import { assignGroupsFromObjectToServiceWorker, unAssignGroupsFromObjectToServiceWorker } from './serviceWorker/ObjectSelectionAssignServiceWorkerToObjectDialog';
import { useTranslation } from 'react-i18next';

export const UserServiceWorkerSelectionDialog = ({
  open,
  setOpen,
  currentObject,
  objects,
  onRefresh,
  serviceWorkerTypeToShow = []
}: {
  open: boolean,
  setOpen: any,
  currentObject: ObjectUnit,
  objects: ObjectUnit[]
  onRefresh: any,
  serviceWorkerTypeToShow? : ServiceWorkerType[]
}) => {

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
  const [token, setToken] = React.useState("");
  const [selectedServiceWorkers, setSelectedServiceWorkers] = React.useState<UserServiceWorker[]>([]);
  const {t} = useTranslation();

  const handleClose = () => {
    setSelectedServiceWorkers([]);
    setOpen(false);
  }
  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);

  React.useEffect(() => {

    (async () => {

      if (!open) return;

      const tempToken = getApiToken(history);
      if (!tempToken) return;
      setToken(tempToken);

      const tempUserServiceWorkers = await api.getServiceWorkers(tempToken);
      if (serviceWorkerTypeToShow.some(x=>x == ServiceWorkerType.ObjectLeader || x == ServiceWorkerType.ServiceWorkerPlus)){
        setUserServiceWorkers(tempUserServiceWorkers.filter(x=>serviceWorkerTypeToShow.some(y=>y == x.type)));
      }else {
        setUserServiceWorkers(tempUserServiceWorkers);
      }


      const tempGroups = await api.getAllGroups(tempToken);
      setGroups(tempGroups);

    })();

  }, [open]);

  const disabled = selectedServiceWorkers.length < 1;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DefaultDialogTitle
        title={<React.Fragment>Wähle die Servicekraft, die der Anreise zugewiesen werden soll</React.Fragment>}
        handleClose={handleClose}
      />

      <DialogContent>
        <UserServiceWorkerSelectionDataTable rows={userServiceWorkers} groups={groups} objects={objects}
          currentGroup={currentObject}
          selectedUserServiceWorkers={selectedServiceWorkers}
          onSelectionChange={(selection: string[]) => {
            setSelectedServiceWorkers(userServiceWorkers.filter(x => selection[selection.length - 1] == x.id));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          {t("myUnitsSettings.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          size="large"
          endIcon={<Add />}
          onClick={async () => {
            if (!currentObject) return;
            for (let prop of selectedServiceWorkers) {
              onRefresh(prop.id);
            }
            handleClose();
          }}
        >
          Zuweisen
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));