import axios from 'axios';
import { headers } from '../../api';
import { getUserIdFromStorage } from '../../helpers/common';
import { BackendErrorCodes } from '../../models/ErrorCodes';

export const createRegisteredBssdHub = async (hubId: string, token: string, enqueueSnackbar: any): Promise<any> => {
    try {
        let userId = getUserIdFromStorage();
        let ret = await axios.post(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/bssd-registered-hubs/${hubId}`,
            { hubId: hubId },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Hub erfolgreich hinzugefügt", { variant: 'success' });
        return ret.data;
    } catch (err: any) {
        if (err.response.data.code == BackendErrorCodes.RecordAlreadyExists) {
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        } else {
            enqueueSnackbar("Hub error beim hinzufügen", { variant: 'error' });
        }
    }
    return null as any;
}