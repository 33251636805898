import axios from 'axios';
import { headers } from '../api';
import { USER_ID } from '../constants';
import { getOwnUserIdFromStorage, getUserIdFromStorage } from '../helpers/common';
import { UserAccount } from '../models/UserAccount';

export const getAllUsersByAdmin = async (token: string): Promise<UserAccount[]> => {
    try {
        let userId = getOwnUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/admin/${userId}/all-users`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
    }
    return null as any;

}

export const getAllUsersInclusiveNonActivatedByAdmin = async (token: string): Promise<UserAccount[]> => {
    try {
        let userId = getOwnUserIdFromStorage();
        let ret = await axios.get(process.env.REACT_APP_BASIC_URI + `/users/admin/${userId}/all-users-incl-non-activated`, { headers: { ...headers, 'Authorization': 'Bearer ' + token } })
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
    }
    return [] as any;

}