import { GroupLicenseTable } from "../../models/GroupLicenseTable";
import { GroupLicense } from "../../models/GroupLicense";

export const formatLicenses = (groupServiceModes: GroupLicense[]) => {

    if (!groupServiceModes) return [];

    return groupServiceModes.map((el) => {
        return el;
    })
}

export const formatGroupLicenseTable = (groupServiceModes: GroupLicenseTable[]) => {

    if (!groupServiceModes) return [];

    return groupServiceModes.map((el) => {
        return el;
    })
}