import { GroupServiceModeIncidentImage, GroupServiceModeIncidentPhoto } from "./GroupServiceMode";

export enum StandardWorkItemType
{
    Serivce = 0,
    Material = 1,
    Incident = 2,
    FeedbackQuestion = 3,
    RoomType = 10
}


export class StandardWorkItem {
    public id!: string;
    public systemETag!: string;
    public systemTimestamp!: string;
    
    public creatorUserId !: string;
    public text !: string;
    public createdTimestamp !: string;
    public activated !: boolean;

    public photos : GroupServiceModeIncidentPhoto [] = [];
    public photoContent : GroupServiceModeIncidentImage [] = [];
    public downloadedPhotoUrl !: {url: string, type: string};
    public itemType !:  StandardWorkItemType;
    public addedFromAdmin !: boolean;

    //material type
    public entity !: string;
    public article !: string;

    //roomType suggestions
    public extras !: StandardWorkItemExtras[];


    static Columns = {
        id: 'id',
        creatorUserId: 'creatorUserId',
        text: 'text',
        createdTimestamp: 'createdTimestamp',
        activated: 'activated',
        photoContent: 'photoContent',
        itemType: 'itemType',
        actions: 'actions',
        isAdminItem : 'isAdminItem',
    }

    constructor () {
        this.activated = true;
        this.itemType = StandardWorkItemType.Serivce;
    }

    static AvailableTypes = [
        
        { value: StandardWorkItemType.Serivce, label: 'Service' },
        { value: StandardWorkItemType.Material, label: 'Material' },
        { value: StandardWorkItemType.Incident, label: 'Vorfall' },
        { value: StandardWorkItemType.FeedbackQuestion, label: 'Feedbackfrage' },
        { value: StandardWorkItemType.RoomType, label: 'Raumart' }
    ];

    static GetLabelByType = (type: StandardWorkItemType | undefined) => StandardWorkItem.AvailableTypes.filter(x => x.value === type).map(x => x.label)[0] || '';
}

export class StandardWorkItemExtras
{
    public text !: string;
    public type !: number;

    public constructor(text: string, type: number)
    {
        this.text = text;
        this.type = type;
    }
}
