import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Add, CloudUpload, Delete, Download, Remove, Save, Visibility } from '@mui/icons-material';
import { UserAccount } from '../../../models/UserAccount';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { StringHelper } from '../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { UserType } from '../../../models/UserType';
import { Autocomplete } from '@mui/material';
import { GroupServiceModeIncidentImage, GroupServiceModeIncidentPhoto } from '../../../models/GroupServiceMode';
import { encode } from 'base64-arraybuffer';
import { AdminSetting, AdminSettingItemType, AdminSettingNewsType, AdminUsageCategory } from '../../../models/AdminSetting';
import { TimePicker } from '@mui/x-date-pickers';
import { formatToTime } from '../../../helpers/date-management';
import { errorColor, white } from '../../../styles/colors';
import { useTranslation } from 'react-i18next';

export const AdminSettingsEditingDialog = ({ visible, setVisible, currentAdminSettings: currentStandardItem, onSave, onShowImages }
    : { visible: boolean, setVisible: any, currentAdminSettings?: AdminSetting, onSave: any, onShowImages: any }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [adminSettings, setAdminSettings] = React.useState<AdminSetting>(new AdminSetting());

    const [loading, setLoading] = React.useState(false);

    const {t} = useTranslation();

    const onClose = () => {
        setAdminSettings(new AdminSetting());
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    const isValid = () => {
        let valid = true;

        if (adminSettings.adminSettingType == undefined) {
            enqueueSnackbar("Admin Typ pflicht!", { variant: 'error' });
            valid = false;
        }

        return valid;
    }


    React.useEffect(() => {

        (async () => {
            if (currentStandardItem) {
                setAdminSettings(currentStandardItem);
            } else {
                let newItem = new AdminSetting();
                setAdminSettings(newItem);
            }

        })();

    }, [visible, currentStandardItem])

    const buildTouchDisplaySettings = () => {
        return (
            <Grid container direction={"column"} spacing={3}>
                <Grid item xs={12} lg={12}>
                    <TextField
                        fullWidth
                        id="filled-name"
                        InputLabelProps={{ shrink: true }}
                        label="Display Check Me Now Apk Version"
                        variant="filled"
                        value={adminSettings.currentDisplayApkVersion}
                        onChange={(event: any) => {
                            setAdminSettings({ ...adminSettings, currentDisplayApkVersion: event.target.value })
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={adminSettings.active}
                                onChange={(event: any) => { setAdminSettings({ ...adminSettings, active: event.target.checked }) }}
                                name="isEnabled"
                                color="primary"
                            />
                        }
                        label="Admin Setting Aktiv"
                    />
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item lg={4} xs={9}>
                        <TimePicker
                            ampm={false}
                            slotProps={{ textField: { variant: 'outlined' } }}
                                                        views={["hours", "minutes", "seconds"]}
                            format="HH:mm:ss"
                            label="Wiederholung 24h Reboot Gerät"
                            value={new Date(`${"2017-03-13"} ${adminSettings.deviceRestartTime}`)}
                            onChange={(value: any) => {
                                if (value != "Invalid Date" && value) {
                                    setAdminSettings({ ...adminSettings, deviceRestartTime: formatToTime(new Date(value)) })
                                }
                            }} />
                    </Grid>
                    <Grid item lg={4}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={adminSettings.isDeviceRestartTimeEnabled}
                                onChange={(event: any) => {
                                    setAdminSettings({ ...adminSettings, isDeviceRestartTimeEnabled: event.target.checked })
                                }}
                                name="isDeviceRestartTimeEnabled"
                                color="primary" />}
                            label="Geräte Reboot alle 24h Aktiviert" />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <TextField
                        fullWidth
                        id="filled-name"
                        InputLabelProps={{ shrink: true }}
                        label="System Command (Android Shell)"
                        variant="filled"
                        value={adminSettings.systemCommand}
                        onChange={(event: any) => {
                            setAdminSettings({ ...adminSettings, systemCommand: event.target.value })
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    const buildPortalAdminSettings = () => {
        return (
            <Grid item container direction={"column"} spacing={3}>
                <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">News Typ</FormLabel>
                        <RadioGroup
                            row
                            value={adminSettings.adminSettingNewsType}
                            onChange={(event) => {
                                setAdminSettings({ ...adminSettings, adminSettingNewsType: parseInt(event.target.value) })
                            }}
                        >
                            <FormControlLabel
                                value={AdminSettingNewsType.Standard}
                                control={<Radio />}
                                label={AdminSetting.GetLabelNewsByType(AdminSettingNewsType.Standard)}
                            />
                            <FormControlLabel
                                value={AdminSettingNewsType.Important}
                                control={<Radio />}
                                label={AdminSetting.GetLabelNewsByType(AdminSettingNewsType.Important)}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>Nutzungsart für Systemmerkmal</Typography>
                </Grid>
                <Grid item container justifyContent={"flex-start"} spacing={3}>
                    <Grid item>
                        <Button
                            size="large"
                            startIcon={<Add />}
                            variant={'contained'}
                            onClick={async () => {
                                adminSettings.adminUsageCategories = [...adminSettings.adminUsageCategories, new AdminUsageCategory()];
                                setAdminSettings({ ...adminSettings });
                            }}
                            color="primary"
                        >
                            Nutzungsart hinzufügen
                        </Button>
                    </Grid>
                </Grid>
                {adminSettings.adminSettingType == AdminSettingItemType.Portal &&
                    <Grid item container direction={"row"} spacing={3}>
                        {
                            adminSettings?.adminUsageCategories?.map((adminUsageCategory: AdminUsageCategory, index: number) => {
                                return (
                                    <Grid key={index} item container direction="row" spacing={2}>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                style={{ backgroundColor: errorColor, color: white }}
                                                onClick={() => {
                                                    adminSettings.adminUsageCategories.splice(index, 1);
                                                    setAdminSettings({ ...adminSettings });
                                                }}>
                                                <Remove />
                                            </Fab>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myUnitsSettings.co2ConsumptionKgM2")}
                                                type='number'
                                                variant="filled"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>
                                                }}
                                                value={adminUsageCategory.carbonDioxideConsumption}
                                                onChange={(event: any) => {
                                                    adminUsageCategory.carbonDioxideConsumption = event.target.value;
                                                    setAdminSettings({ ...adminSettings });
                                                }}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myUnitsSettings.usageType")}
                                                variant="filled"
                                                value={adminUsageCategory.name}
                                                onChange={(event: any) => {
                                                    adminUsageCategory.name = event.target.value;
                                                    setAdminSettings({ ...adminSettings });
                                                }}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={adminUsageCategory.isEnabled}
                                                    onChange={(event) => {
                                                        adminUsageCategory.isEnabled = event.target.checked;
                                                        setAdminSettings({ ...adminSettings });
                                                    }}
                                                    name="cleaningadminUsageCategory"
                                                    color="primary" />
                                                }
                                                label="Aktiviert" />
                                        </Grid>

                                    </Grid>
                                );
                            })}

                    </Grid>
                }
            </Grid>
        )
    }

    const buildAdminItems = () => {
        switch (adminSettings.adminSettingType) {
            case AdminSettingItemType.TouchDisplay:
                return buildTouchDisplaySettings();
            default:
                return buildPortalAdminSettings();
        }
    }


    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title="Admin Settings bearbeiten"
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">Details</Typography>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Einstellungs Typ</FormLabel>
                                <RadioGroup
                                    row
                                    value={adminSettings.adminSettingType}
                                    onChange={(event) => {
                                        setAdminSettings({ ...adminSettings, adminSettingType: parseInt(event.target.value) })
                                    }}
                                >
                                    <FormControlLabel
                                        value={AdminSettingItemType.TouchDisplay}
                                        control={<Radio />}
                                        label={AdminSetting.GetLabelByType(AdminSettingItemType.TouchDisplay)}
                                    />
                                    <FormControlLabel
                                        value={AdminSettingItemType.Portal}
                                        control={<Radio />}
                                        label={AdminSetting.GetLabelByType(AdminSettingItemType.Portal)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {buildAdminItems()}

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        await onSave(adminSettings);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = error => reject(error);
    })
}