import { DeviceType } from "./SsdDeviceModel";

export class BssdRegisteredDeviceModel {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public creationTimestamp!: string;
    public userId!: string;     
    public deviceType : DeviceType = DeviceType.Display;
    public hubId!: string;
    public groupId!: string;


    static Columns = {
        id : 'id',
        creationTimestamp: 'creationTimestamp',
        userId: 'userId',
        version: 'version',
        availableDisplays: 'availableDisplays',
        availableSensors: 'availableSensors',
        hubId: 'hubId',
        groupId: 'groupId',
        deviceType: 'deviceType',
        assignSensorLicense: 'assignSensorLicense',
        groupName: 'groupName',
        deleteButton: 'deleteButton'
    };


}