import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { GroupFeedbackQuestion } from '../../../../../models/GroupFeedbackQuestion';
import { GroupLicenseTable } from '../../../../../models/GroupLicenseTable';
import { saveExcelFile } from '../../../../../helpers/tables/common';
import { formatEuropeanDate, formatEuropeanTime } from '../../../../../helpers/date-management';
import { buildCellStringArray, useAccordionForStringArrayStyles } from '../../../../common/AccordionForStringArray';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ExpandMore, PointOfSale } from '@mui/icons-material';
import { Grid as MuiGrid } from '@mui/material';
import { GroupPayPerService } from '../../../../../models/GroupPayPerService';
import { useTranslation } from 'react-i18next';

export const GroupPayPerServiceDataTable = ({ rows, groups, selectedEntries, onSelectionChange, }
    : { rows: GroupPayPerService[], groups: GroupLicenseTable[], selectedEntries: GroupPayPerService[], onSelectionChange: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selection, setSelection] = useState<any[]>([]);
    const classes = useAccordionForStringArrayStyles();

    const {t} = useTranslation();


    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupPayPerService } = props;
        if (column.name == GroupPayPerService.Columns.items && row.items) {
            let names = row.items.map(x => `${x.roomText} | ${x.reporterName || ""} ${JSON.stringify(x.serviceNames)}`);
            return (
                <Table.Cell {...props}>
                    <div className={classes.root}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <MuiGrid container direction="column">
                                    <MuiGrid item>
                                        <Typography className={classes.heading}>{`${t("myObjectSettings.contains")} ${row.items.length} Räume`}</Typography>
                                    </MuiGrid>

                                </MuiGrid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MuiGrid container direction="column">
                                    <MuiGrid item>
                                        <List component="nav" aria-label="main mailbox folders">
                                            {row.items && row.items.length > 0 && row.items.map((el, index) => {
                                                return (
                                                    <ListItem button key={index}>
                                                        <ListItemIcon>
                                                            <PointOfSale />
                                                        </ListItemIcon>
                                                        <ListItemText primary={`${el.roomText} | ${el.reporterName || ""}`} />

                                                        <Accordion>
                                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                                                expandIcon={<ExpandMore />}
                                                            >
                                                                <Typography>Enthält {el.serviceNames.length} Leistungen mit Preise</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {el.serviceNames.map(x => {
                                                                    return (
                                                                        <Typography>
                                                                            Leistung: {x.text} | Preis: {x.price}€
                                                                        </Typography>
                                                                    )
                                                                })}

                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </ListItem>
                                                )
                                            })}

                                        </List>
                                    </MuiGrid>
                                </MuiGrid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Table.Cell>

            )

        } else if (column.name == GroupFeedbackQuestion.Columns.customTexts && row.customTexts) {
            return buildCellStringArray(props, row.customTexts.map(x => `${x.roomText} | ${x.reporterName || ""}`), `${t("myObjectSettings.contains")} ${row.customTexts.length} Extra-Räume`);
        }

        return <Table.Cell {...props} />;
    };


    const columns = [
        {
            name: GroupPayPerService.Columns.incidentOriginType,
            title: "Ursprung",
            getCellValue: (row: GroupPayPerService) => GroupPayPerService.GetLabelByType(row.incidentOriginType),
        },
        {
            name: GroupFeedbackQuestion.Columns.id,
            title: t("serviceDocumentationTable.unitName"),
            getCellValue: (row: GroupPayPerService) => groups.find(x => x.id == row.groupId)?.notificationName,
        },
        {
            name: GroupPayPerService.Columns.messenger,
            title: "Melder",
            getCellValue: (row: GroupPayPerService) => row.messenger,
        },
        {
            name: GroupPayPerService.Columns.items,
            title: "Raum mit Leistung",
            getCellValue: (row: GroupPayPerService) => row.items ? row.items.map(x => `${x.roomText} | ${x.reporterName || ""} ${JSON.stringify(x.serviceNames)}`).join(',') : "",
        },

        {
            name: GroupPayPerService.Columns.customTexts,
            title: "Extra Räume",
            getCellValue: (row: GroupPayPerService) => row.customTexts ? row.customTexts.map(x => `${x.roomText} | ${x.reporterName || ""}`).join(',') : "",
        },
        {
            name: GroupPayPerService.Columns.createdTimestamp,
            title: t("dashboard.creationDate"),
            getCellValue: (row: GroupPayPerService) => formatEuropeanDate(row.createdTimestampString),
        },
        {
            name: GroupPayPerService.Columns.createdTime,
            title: t("dashboard.creationTime"),
            getCellValue: (row: GroupPayPerService) => formatEuropeanTime(row.createdTimestampString),
        }
    ];

    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == GroupPayPerService.Columns.items || el.name == GroupPayPerService.Columns.customTexts) {
            return { columnName: el.name, width: 300 }
        }
        return { columnName: el.name, width: 180 }
    }));
    const [defaultHiddenColumnNames] = React.useState([]);


    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Feedbackfragen');
        });
    };

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //          sorting={[{ columnName: FeedbackTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
