import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close, Room, Battery6Bar, Battery90, Hub, Router, SupervisedUserCircle, FeaturedVideo, TabletAndroid, HowToReg, SettingsInputHdmi, CleanHands, AddTask, Map, LibraryAdd, QrCodeScanner, Visibility, QrCode, Sensors, Cancel, Publish, Today } from "@mui/icons-material";
import React, { useContext } from "react";
import { api } from "../../../api";
import { getApiToken, setStorageForDrawer } from "../../../helpers/storage-management";
import { GroupLicenseTable, SensorLicense } from "../../../models/GroupLicenseTable";
import { GroupLicense } from "../../../models/GroupLicense";
import { useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";
import { handleSelectedCombinationGroup } from "../../tables/Base";
import { green, red, white, yellow } from "../../../styles/colors";
import { SimplePanel } from "../../common/SimplePanel";
import { UserAccount } from "../../../models/UserAccount";
import { UserType } from "../../../models/UserType";
import { UserInvoice } from '../../../models/UserInvoice';
import { UserInvoicesTable } from '../../dialogs/UserInvoicesTable';
import { LicenseType } from '../../../models/LicenseType';
import { GroupSelectionTable } from '../../tables/GroupSelectionTable';
import { AssignLicenseChoosingTableDialog, ShowCountType } from '../../dialogs/AssignLicenseChoosingTableDialog';
import { LicensesAdminTable } from '../../tables/LicensesAdminTable';
import { GROUP_ID, GROUP_NAME } from '../../../constants';
import { ConfirmationDialog } from "../../dialogs/ConfirmationDialog";
import { AssignSensorLicenseChoosingTableDialog } from "../../dialogs/AssignSensorLicenseChoosingTableDialog";
import { LoadingIcon } from "../../common/LoadingIcon";
import { useQuery } from "./checkout/ShopPage";
import { getUserIdFromStorage } from "../../../helpers/common";
import { GroupType } from "../../../models/Group";
import { CheckpointGroupEditingDialog } from "../../dialogs/CheckpointGroupEditingDialog";
import { GroupSettings, ToiletType } from "../../../models/GroupSettings";
import { CheckpointSelectionDialog } from "../extendedapp/checkpoint/CheckpointSelectionDialog";
import { GlobalContext } from "../PortalPage";
import { AddHubDialog } from "../../dialogs/licenseManagement/AddHubDialog";
import { BatteryDisplayDialogChoice, BatteryDisplaySelectionDialog } from "../extendedapp/batteryDisplay/BatteryDisplaySelectionDialog";
import { BssdSelectionDialog } from "../extendedapp/bssdHub/BssdSelectionDialog";
import { HubModel } from "../../../models/HubModel";
import { SsdUnregisteredDevicesDialog } from "../extendedapp/bssdHub/SsdUnregisteredDevicesDialog";
import { GREEN_SMILE, LicenseWhite } from "../../../assets";
import { AssignMultipleLicenseChoosingTableDialog } from "../../dialogs/AssignMultipleLicenseChoosingTableDialog";
import PayLicense from '../../../assets/image/license/pay_license.png'
import { useTranslation } from "react-i18next";


export enum AllLicensedGroupsDialogType {
    QualityCheck = 0,
    Lora = 1,
    PublicFeedback = 2,
    PayPerService = 3,
    CalendarPro = 4,
    None = 255
}

export const AllLicensedGroups = ({ allLicensedGroupsDialogType, loading, groups, selectedGroup, navigateByLicenseTable, selectedGroups, setSelectedCombinationGroup, freeLicenses, setSelectedGroups, setSelectedGroup, loadDataAsync, setGroups, qualityCheckLicenses, licenses }:
    { allLicensedGroupsDialogType: AllLicensedGroupsDialogType, loading: boolean, groups: GroupLicenseTable[], selectedGroup: any, navigateByLicenseTable: any, selectedGroups: any[], setSelectedCombinationGroup: any, freeLicenses: GroupLicense[], setSelectedGroups: any, setSelectedGroup: any, loadDataAsync: any, setGroups: any, qualityCheckLicenses: GroupLicense[], licenses: GroupLicense[] }) => {
    const [assignLoraSensorDialogVisible, setAssignLoraSensorDialogVisible] = React.useState(false);
    const [assignLoraSensorLicenseChooserDialogVisible, setAssignLoraSensorLicenseChooserDialogVisible] = React.useState(false);
    const classes = useStyles();
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { userAccount }: any = useContext(GlobalContext);
    const [assignQualityCheckLicenseDialogVisible, setAssignQualityCheckLicenseDialogVisible] = React.useState(false);
    const [assignMultipleQualityCheckLicenseDialogVisible, setAssignMultipleQualityCheckLicenseDialogVisible] = React.useState(false);
    const [assignMultiplePublicFeedbackLicenseDialogVisible, setAssignMultiplePublicFeedbackLicenseDialogVisible] = React.useState(false);
    const [assignMultiplePayPerServiceLicenseDialogVisible, setAssignMultiplePayPerServiceLicenseDialogVisible] = React.useState(false);
    const [assignMultipleCalendarProLicenseDialogVisible, setAssignMultipleCalendarProLicenseDialogVisible] = React.useState(false);

    const { t } = useTranslation();

    const [selectedLicense, setSelectedLicense] = React.useState<GroupLicense>();
    const [token, setToken] = React.useState("");
    const history = useHistory();

    const assignLicense = async (selectedLicense: GroupLicense, setVisibleDialog: any = null) => {
        if (!selectedLicense || !selectedGroup) return;

        if (setVisibleDialog) setVisibleDialog(false);

        await api.assignLicenseToGroup(selectedGroup.id, selectedLicense, token, enqueueSnackbar);
        await loadDataAsync();
    }

    const assignLicenseToSpecificGroup = async (selectedLicense: GroupLicense, currentGroup: GroupLicenseTable) => {
        await api.assignLicenseToGroup(currentGroup.id, selectedLicense, token, enqueueSnackbar);
    }

    React.useEffect(() => {
        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);
    }, []);


    const buildButtonQualityCheckLicense = () => {
        return (
            <>
                {selectedGroups.length < 2 &&
                    <Grid item lg={3}>
                        <Button
                            disabled={!selectedGroup}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="primary"
                            startIcon={LicenseWhite()}
                            endIcon={<SupervisedUserCircle />}
                            onClick={() => {
                                setAssignQualityCheckLicenseDialogVisible(true);
                            }}
                        >
                            {selectedGroup && selectedGroup.qualityCheckLicenseIsValid ? "Qualitätscheck erweitern" : "Qualitätscheck zuweisen"}
                        </Button>
                    </Grid>
                }
                {selectedGroups.length > 1 &&
                    <Grid item lg={3}>
                        <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            color="primary"
                            startIcon={LicenseWhite()}
                            endIcon={<SupervisedUserCircle />}
                            onClick={() => {
                                setAssignMultipleQualityCheckLicenseDialogVisible(true);
                            }}
                        >
                            {`Qualitätscheck für ${selectedGroups.length} Einheiten zuweisen`}
                        </Button>
                    </Grid>
                }
            </>
        )
    }

    const buildButtonCalendarProLicense = () => {
        return (
            <>
                <Grid item lg={3}>
                    <Button
                        disabled={selectedGroups.length < 1}
                        variant="contained"
                        size="large"
                        fullWidth
                        color="primary"
                        startIcon={LicenseWhite()}
                        endIcon={<Today />}
                        onClick={() => {
                            setAssignMultipleCalendarProLicenseDialogVisible(true);
                        }}
                    >
                        {`Kalender Pro für ${selectedGroups.length} Einheiten zuweisen`}
                    </Button>
                </Grid>

            </>
        )
    }

    const buildButtonPublicFeedbackLicense = () => {
        return (
            <>
                <Grid item lg={3}>
                    <Button
                        disabled={selectedGroups.length < 1}
                        variant="contained"
                        size="large"
                        fullWidth
                        color="primary"
                        startIcon={LicenseWhite()}
                        endIcon={<img style={{ height: 50, width: 50 }} src={GREEN_SMILE} />}
                        onClick={() => {
                            setAssignMultiplePublicFeedbackLicenseDialogVisible(true);
                        }}
                    >
                        {`Öffentliches Feedback für ${selectedGroups.length} Einheiten zuweisen`}
                    </Button>
                </Grid>

            </>
        )
    }

    const buildButtonPayPerServiceLicense = () => {
        return (
            <>
                <Grid item lg={3}>
                    <Button
                        disabled={selectedGroups.length < 1}
                        variant="contained"
                        size="large"
                        fullWidth
                        color="primary"
                        startIcon={LicenseWhite()}
                        endIcon={<img style={{ height: 50, width: 50 }} src={PayLicense} />}
                        onClick={() => {
                            setAssignMultiplePayPerServiceLicenseDialogVisible(true);
                        }}
                    >
                        {`Pay Per Service für ${selectedGroups.length} Einheiten zuweisen`}
                    </Button>
                </Grid>

            </>
        )
    }


    const buildButtonLoraLicense = () => {
        return <>
            <Grid item lg={3}>
                <Button
                    disabled={selectedGroups.length != 1 && !selectedGroup}
                    variant="contained"
                    size="large"
                    fullWidth
                    color="primary"
                    startIcon={LicenseWhite()}
                    endIcon={<Sensors />}
                    onClick={() => {
                        setAssignLoraSensorDialogVisible(true);
                    }}
                >
                    Lora Sensor Lizenz zuweisen
                </Button>
            </Grid>

        </>
    }

    const buildLicenseButtons = () => {
        switch (allLicensedGroupsDialogType) {
            case AllLicensedGroupsDialogType.Lora:
                return buildButtonLoraLicense();
            case AllLicensedGroupsDialogType.QualityCheck:
                return buildButtonQualityCheckLicense();
            case AllLicensedGroupsDialogType.PublicFeedback:
                return buildButtonPublicFeedbackLicense();
            case AllLicensedGroupsDialogType.PayPerService:
                return buildButtonPayPerServiceLicense();
            case AllLicensedGroupsDialogType.CalendarPro:
                return buildButtonCalendarProLicense();
            default:
                return (
                    <>
                        {buildButtonLoraLicense()}
                        {buildButtonQualityCheckLicense()}
                        {buildButtonPublicFeedbackLicense()}
                        {buildButtonPayPerServiceLicense()}
                        {buildButtonCalendarProLicense()}
                    </>
                )
        }

    }

    return (
        <>
            {loading && <LoadingIcon loading={loading} icon={null} />}
            {
                groups.length > 0 && !loading &&
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <Button
                            disabled={!selectedGroup}
                            variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<Navigation />}
                            onClick={() => {
                                if (!selectedGroup) return;
                                navigateByLicenseTable(selectedGroup);
                            }}
                        >
                            Zur Einheit navigieren
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={selectedGroups.length < 2}
                            variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<MergeType />}
                            onClick={() => {
                                handleSelectedCombinationGroup(selectedGroups, groups, setSelectedCombinationGroup, history);
                            }}
                        >
                            {t("dashboard.combineUnits")}
                        </Button>
                    </Grid>
                    {
                        userAccount?.userType == UserType.Admin &&
                        selectedGroups.length > 0 &&
                        <Grid item>
                            <Button
                                className={classes.deleteButton}
                                variant="contained"
                                size="large"
                                startIcon={<Close />}
                                disabled={selectedGroups.length < 1}
                                onClick={() => {

                                    if (selectedGroups.length < 1) return;
                                    setDeleteConfirmationDialogVisible(true);
                                }}
                            >
                                Einheit löschen
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item style={{ maxWidth: '100%' }}>
                        <GroupSelectionTable
                            groups={groups}
                            selectedGroups={selectedGroups}
                            onSelectionChange={(selection: string[]) => {
                                setSelectedGroups(groups.filter(x => selection.some(s => s === x.id)));
                                setSelectedGroup(selection.length === 1 ? groups.filter(x => selection.some(s => s === x.id))[0] : null);
                            }}
                        />
                    </Grid>
                </Grid>
            }
            {
                (!groups || groups.length === 0) &&
                <Paper className="Paper">
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography component="h5" variant="h5"
                            >
                                {t("license-general.youHaveNotYetAssignedAnyLicencesInstallADeviceNow")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            }
            <Grid container justifyContent={"flex-end"}>
                {buildLicenseButtons()}
            </Grid>
            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.LoraSensor}
                visible={assignLoraSensorDialogVisible}
                licenses={freeLicenses.filter(x => x.type === LicenseType.LoraSensorExtension)}
                onClose={() => {
                    setAssignLoraSensorDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    if (!selectedLicense || !selectedGroup) return;
                    console.log(selectedGroup, "mygroup");
                    setAssignLoraSensorDialogVisible(false);
                    if (selectedLicense.loraSensorCount > 0) {
                        setSelectedLicense(selectedLicense);
                        setAssignLoraSensorLicenseChooserDialogVisible(true);
                    } else {
                        await assignLicense(selectedLicense, setAssignLoraSensorDialogVisible);
                    }
                }}
            />
            {selectedGroup && selectedLicense && selectedGroup.loraSensorLicenses &&
                <AssignSensorLicenseChoosingTableDialog
                    visible={assignLoraSensorLicenseChooserDialogVisible}
                    licenses={selectedGroup.loraSensorLicenses}
                    currentSelectedLicense={selectedLicense}
                    sensorCount={selectedLicense.loraSensorCount}
                    onClose={() => {
                        setAssignLoraSensorLicenseChooserDialogVisible(false);
                    }}
                    onLicenseSelected={async (selectedSensorLicenses: SensorLicense[]) => {
                        selectedLicense.loraSensorLicenses = selectedSensorLicenses;
                        if (selectedLicense) await assignLicense(selectedLicense, setAssignLoraSensorLicenseChooserDialogVisible);
                    }}
                />
            }
            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title={`Sind Sie sicher, die ausgewählten Einheiten zu löschen?`}
                onClose={() => setDeleteConfirmationDialogVisible(false)}
                onConfirmed={async () => {

                    if (selectedGroups.length < 1) return;

                    const token = getApiToken(history);
                    for (let currentGroup of selectedGroups) {
                        await api.deleteGroupAsync(currentGroup.id, token, enqueueSnackbar);
                    }
                    setGroups(groups.filter(x => !selectedGroups.some(y => y.id == x.id)));

                    setDeleteConfirmationDialogVisible(false);
                }}
            />
            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.QualityCheck}
                visible={assignQualityCheckLicenseDialogVisible}
                licenses={qualityCheckLicenses}
                onClose={() => {
                    setAssignQualityCheckLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    assignLicense(selectedLicense, setAssignQualityCheckLicenseDialogVisible);
                }}
            />
            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.QualityCheck}
                visible={assignMultipleQualityCheckLicenseDialogVisible}
                licenses={qualityCheckLicenses}
                onClose={() => {
                    setAssignMultipleQualityCheckLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.qualityCheckCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultipleQualityCheckLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />

            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.PublicFeedback}
                visible={assignMultiplePublicFeedbackLicenseDialogVisible}
                licenses={licenses.filter(x => x.publicFeedbackCount > 0)}
                onClose={() => {
                    setAssignMultiplePublicFeedbackLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.publicFeedbackCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultiplePublicFeedbackLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />

            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.PayPerService}
                visible={assignMultiplePayPerServiceLicenseDialogVisible}
                licenses={licenses.filter(x => x.payPerServiceCount > 0)}
                onClose={() => {
                    setAssignMultiplePayPerServiceLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.payPerServiceCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultiplePayPerServiceLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />

            <AssignMultipleLicenseChoosingTableDialog
                showCountType={ShowCountType.CalendarPro}
                visible={assignMultipleCalendarProLicenseDialogVisible}
                licenses={licenses.filter(x => x.calendarProCount > 0)}
                onClose={() => {
                    setAssignMultipleCalendarProLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicenses: GroupLicense[]) => {
                    let groupsToAssignLicense = [...selectedGroups];
                    loop1:
                    for (let selectedLicense of selectedLicenses) {
                        for (let i = 0; i < selectedLicense.calendarProCount; i++) {
                            let curGroup = groupsToAssignLicense.shift();
                            if (!curGroup) break loop1;
                            await assignLicenseToSpecificGroup(selectedLicense, curGroup)
                        }
                    }

                    setAssignMultipleCalendarProLicenseDialogVisible(false);
                    await loadDataAsync();
                }}
            />
        </>

    )
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));