import axios from 'axios';
import { headers } from '../api';
import { getOwnUserIdFromStorage, getUserIdFromStorage } from '../helpers/common';
import { AdminSetting } from '../models/AdminSetting';
import { StandardWorkItem } from '../models/StandardItem';
import { UserAddress } from '../models/UserAddress';

export const CreateAdminSetting = async (standarditem: AdminSetting | undefined, token: string, enqueueSnackbar: any): Promise<AdminSetting> => {
    try {
        if (!standarditem) throw (null);
        let userId = getOwnUserIdFromStorage();
        standarditem.creatorUserId = userId || "";
        let ret = await axios.put(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/admin-settings`,
            { ...standarditem },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Admin Settings Speichern erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        enqueueSnackbar("Admin Settings Speichern error", { variant: 'error' });
    }
    return null as any;
}