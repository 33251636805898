import axios from "axios";
import { multipartHeaders } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupSettings, GroupSettingsType } from "../models/GroupSettings";
import { UserGroupSettingsTemplate } from "../models/UserGroupSettingsTemplate";

export const uploadGroupSideBarImages = async (files: FileList, groupSettings: GroupSettings | UserGroupSettingsTemplate, type: GroupSettingsType, token: string, enqueueSnackbar: any, setProgressData: any): Promise<GroupSettings> => {

    try {

        const userId = getUserIdFromStorage();

        let ret: any;
        for (let i = 0; i < files.length; i++)
        {
            const formData = new FormData();
            formData.append('body', files[i]);
            let uri: string = '';
            switch (type) {
                case GroupSettingsType.Template:
                    uri = `${process.env.REACT_APP_BASIC_URI}/users/${userId}/template/${groupSettings.id}/images/sidebars`;
                    break;
                default:
                    uri = `${process.env.REACT_APP_BASIC_URI}/groups/${groupSettings.id}/users/${userId}/images/sidebars`;
                    break;
            }
            setProgressData(undefined);
            ret = await axios.post(uri, formData, { headers: { ...multipartHeaders, 'Authorization': `Bearer ${token}` }, onUploadProgress: progressEvent => {
                progressEvent.index = i + 1;
                setProgressData(progressEvent);
            } });
            setProgressData(undefined);
            enqueueSnackbar("Werbung erfolgreich hochgeladen", { variant: 'success' });

        }




        return ret.data;

    } catch (err: any) {
        try {
            enqueueSnackbar(err?.response?.data?.message, { variant: 'error' });
        }catch{}
        enqueueSnackbar("Einige Werbungen können nicht hochgeladen werden", { variant: 'error' });
    }
    return null as any;
}