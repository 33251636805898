
export class CurrencyHelper {

    static convertStringToCurrencyValue = (str: string | null | undefined): number => parseFloat((str || '').split(',').join('.'));
    static convertNumberToCurrencyNumberString = (value: number | null | undefined): string => value?.toString().split('.').join(',') || '';
    static toCurrencyString = (value: any): string => value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
    static toCurrencyCommaString = (value: number): string => value.toFixed(2).replace('.',',');
    static grossOf = (net: number, taxRate: number): number => net * (100 + taxRate) / 100;
    static taxOf = (net: number, taxRate: number): number => net * taxRate / 100;
}
