import { FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, TextField } from "@mui/material";
import React from "react";
import { GroupFeedback } from "../../../../../models/GroupFeedback";
import { FeedbackDisplayType } from "../../../../../models/GroupSettings";
import { elevationPaper, paperStyle } from "../../../../../styles/container";
import { LineBarCombChart } from "../../../../charts/LineBarCombChart";
import { LineChart } from "../../../../charts/LineChart";
import { SideBySideBarChart } from "../../../../charts/SideBySideBarChart";
import { StackedChart } from "../../../../charts/StackedChart";
import { ChartChoice } from "../../../../core/ChartChoice";
import { TimeChoice } from "../../../../core/TimeChoice";
import { buildSmileDescriptionWithVisitors } from "../qualityChecks/QualityChecksDashboard";
import { PeriodChartFilterType } from "../../../../../helpers/date-management";

export enum DetailType {
    Standard = "0",
    Details = "1"
}

export const GetGroupFeedbacksQualityMotionComb = ({ data, allData, setChartData, childRef, loadingCompleted, childRefChart, feedbackDisplayTypeChart }
    : { data: any[], allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any, feedbackDisplayTypeChart: any }) => {

    const [chartChoice, setChartChoice] = React.useState('0');
    const [detailsType, setDetailsType] = React.useState(DetailType.Details);

    const [dataFieldBars, setDataFieldBars] = React.useState(["blockCount", "allFeedbackCount"]);
    const [dataFieldLines, setDataFieldLines] = React.useState(["allFeedbackSum", "allFeedbackWithoutVisitor"]);
    const [sollValue, setSollValue] = React.useState(80);

    React.useEffect(() => {
        switch (detailsType) {
            case DetailType.Standard:
                setDataFieldBars(["blockCount"]);
                setDataFieldLines(["allFeedbackSum"]);
                break;
            case DetailType.Details:
                setDataFieldBars(["blockCount", "allFeedbackCount"]);
                setDataFieldLines(["allFeedbackSum", "allFeedbackWithoutVisitor"]);
                break;
        }
    }, [detailsType])

    const sumMotions = React.useMemo(() => data?.reduce((prevVal, x: any) => {
        let sum = x?.blockCount;
        if (!sum) sum = 0;
        return prevVal + sum
    }, 0) || [], [data]);

    const sumFeedback = React.useMemo(() => data.reduce((prevVal, x: any) => {
        let sum = feedbackDisplayTypeChart ? x.negativeAmount + x.neutralAmount + x.positiveAmount + x.veryPositiveAmount + x.veryNegativeAmount : x.negativeAmount + x.neutralAmount + x.positiveAmount
        if (!sum) sum = 0;
        return prevVal + sum
    }, 0), [data]);

    const text = `Besucher & Feedback Qualität<br>Gesamtsumme: <b>${sumMotions} Besucher</b><br>Gesamtsumme: <b>${sumFeedback} Feedbacks</b>`;

    const newData = React.useMemo(() => data.map((x: any) => {
        let allFeedbackCount = 0;
        let overallFeedbackWithVisitor = 0;
        let allFeedbackSum = 0;
        let allFeedbackWithoutVisitorSum = 80;
        if (feedbackDisplayTypeChart == FeedbackDisplayType.AdvancedWithVeryPostiveNegative) {
            let veryNegativeAmount = x.veryNegativeAmount;
            while (veryNegativeAmount > 0) {
                allFeedbackSum = allFeedbackSum + 25;
                veryNegativeAmount = veryNegativeAmount - 1;
                allFeedbackCount++;
            }
            let veryPositiveAmount = x.veryPositiveAmount;
            while (veryPositiveAmount > 0) {
                allFeedbackSum = allFeedbackSum + 100;
                veryPositiveAmount = veryPositiveAmount - 1;
                allFeedbackCount++;
            }
        }
        let negativeAmount = x.negativeAmount;
        while (negativeAmount > 0) {
            allFeedbackSum = allFeedbackSum + 25;
            negativeAmount = negativeAmount - 1;
            allFeedbackCount++;
        }
        let neutralAmount = x.negativeAmount;
        while (neutralAmount > 0) {
            allFeedbackSum = allFeedbackSum + 75;
            neutralAmount = neutralAmount - 1;
            allFeedbackCount++;
        }
        let positiveAmount = x.positiveAmount;
        while (positiveAmount > 0) {
            allFeedbackSum = allFeedbackSum + 100;
            positiveAmount = positiveAmount - 1;
            allFeedbackCount++;
        }
        let noFeedbackCount = x.blockCount - allFeedbackCount;
        overallFeedbackWithVisitor = allFeedbackCount;

        if (allFeedbackSum > 0) {
            allFeedbackWithoutVisitorSum = allFeedbackSum;
        }
        while (noFeedbackCount > 0) {
            allFeedbackSum = allFeedbackSum + 80;
            noFeedbackCount--;
            overallFeedbackWithVisitor++;
        }
        if (overallFeedbackWithVisitor > 0) {
            allFeedbackSum = Math.round(allFeedbackSum / overallFeedbackWithVisitor)
        }
        if (allFeedbackCount > 0) {
            allFeedbackWithoutVisitorSum = Math.round(allFeedbackWithoutVisitorSum / allFeedbackCount)
        }


        return {
            dateDisplay: x.dateDisplay,
            date: x.date,
            blockCount: x.blockCount,
            allFeedbackSum: allFeedbackSum,
            allFeedbackWithoutVisitor: allFeedbackWithoutVisitorSum,
            allFeedbackCount: allFeedbackCount,
        }
    }), [data, feedbackDisplayTypeChart, detailsType]);
    const colors = detailsType == DetailType.Standard ? ["#a7f5fa", "#2f55b5"] : ["#a7f5fa", "#f2af83", "#2f55b5", "#e30eca"];


    const buildGridItem = () => {

        switch (chartChoice) {
            case '0':
                return <LineBarCombChart
                    constantLineVal={sollValue}
                    childRefChart={childRefChart}
                    colors={colors}
                    data={newData}
                    dataFieldBars={dataFieldBars}

                    text={text}
                    loadingCompleted={loadingCompleted} dataFieldLines={dataFieldLines} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item>
                        <FormControl component="fieldset" >
                            <FormLabel component="legend">Detail Ansicht</FormLabel>
                            <RadioGroup
                                row
                                value={detailsType}
                                onChange={(event) => {
                                    setDetailsType(event.target.value as DetailType);
                                }}
                            >
                                <FormControlLabel value={"0"} control={<Radio />} label="Standard" />
                                <FormControlLabel value={"1"} control={<Radio />} label="Details" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item style={{width: 500}}>
                        {buildSmileDescriptionWithVisitors(60, feedbackDisplayTypeChart)}
                    </Grid>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TextField
                            
                            required
                            variant="outlined"
                            label="Sollwert"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            value={sollValue}
                            onChange={(event: any) => {
                                setSollValue(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={[]} data={allData} setChartData={setChartData} dateDisplayType={PeriodChartFilterType.Day}/>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}