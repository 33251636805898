import Dialog from '@mui/material/Dialog';
import React from 'react';

import { Button, DialogActions, DialogContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, TouchApp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { useHistory } from 'react-router-dom';
import { ObjectLeaderQualityCheckTable } from './ObjectLeaderQualityCheckTable';
import { DefaultDialogTitle } from '../../DefaultDialogTitle';
import { getApiToken } from '../../../../helpers/storage-management';
import { api } from '../../../../api';
import { UserServiceWorker } from '../../../../models/UserServiceWorker';
import { ObjectLeaderQualityCheckModel } from '../../../../models/ObjectLeaderQualityCheckModel';
import { GroupLicenseTable } from '../../../../models/GroupLicenseTable';
import { useTranslation } from 'react-i18next';

export const ObjectLeaderQualityCheckDialog = ({
  open,
  setOpen,
  userServiceWorker
}: {
  open: boolean,
  setOpen: any,
  userServiceWorker: UserServiceWorker
}) => {

  const history = useHistory();
  const {t} = useTranslation();


  const [objectLeaderQualityChecks, setObjectLeaderQualityChecks] = React.useState<ObjectLeaderQualityCheckModel[]>([]);
  const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {

    (async () => {

      if (!open) return;
      

      const token = getApiToken(history);
      if (!token) return;

      const qualityChecks = await api.getQualityChecksByServiceWorkerId(token, userServiceWorker.id);
      setObjectLeaderQualityChecks(qualityChecks);

      const tempGroups = await api.getAllGroups(token);
      setGroups(tempGroups);


    })();

  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen
    >
      <DefaultDialogTitle
        title={`Qualitätssicherung Objektleiter: ${userServiceWorker.name} ${userServiceWorker.email}`}
        handleClose={handleClose}
      />

      <DialogContent dividers style={{height: "100%"}}>
        {userServiceWorker &&
          <ObjectLeaderQualityCheckTable
            userServiceWorkers={[userServiceWorker]}
            qualityChecks={objectLeaderQualityChecks}
            groups={groups}
          />
        }
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          startIcon={<CancelIcon />}
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
        >
          Dialog {t("myUnitsSettings.close")}
        </Button>
      </DialogActions>

    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));