import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle, CircularProgress, DialogActions, DialogContent, Grid, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { DigitalCheckpointConfigLoggedIn } from './DigitalCheckpointConfigLoggedIn';
import { CleaningOperation, CleaningOperations, GroupSettings } from '../../../models/GroupSettings';
import { Cancel, Check } from '@mui/icons-material';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { IntervalControl } from '../../../models/IntervalControl';
import { IntervalControlDataTable } from '../../authorized/extendedapp/interval/IntervalControlDataTable';
import { DefaultDialogTitle } from '../DefaultDialogTitle';
import { useTranslation } from 'react-i18next';

export const CleaningOperationIntervalDialog = ({ open, setOpen, onRefresh, intervalControls, cleaningOperations }
    : { open: boolean, setOpen: any, onRefresh: any, intervalControls: IntervalControl[], cleaningOperations: CleaningOperation[] }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);
    const [selection, setSelection] = React.useState([]);
    const {t} = useTranslation();

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Dialog
                fullScreen
                onClose={handleClose}
                open={open}
            >
                <DefaultDialogTitle
                    title="Kalender Pro"
                    handleClose={handleClose}
                />
                <DialogContent className={classes.dialogContent} dividers>
                    <IntervalControlDataTable cleaningOperations={cleaningOperations} selection={selection} setSelection={setSelection} rows={intervalControls} />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        size="large"
                        startIcon={<Cancel />}
                        variant={'outlined'}
                        onClick={handleClose}
                        color="primary"
                    >
                        {t("myUnitsSettings.cancel")}
                    </Button>
                    <Button
                        size="large"
                        startIcon={<Check />}
                        endIcon={loading && <CircularProgress size={32} />}
                        variant={'contained'}
                        color="primary"
                        onClick={async () => {
                            handleClose();
                        }}
                    >
                        Fertig!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: 0,
    },
}));