export class GroupCleanerFeedback {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public veryPositiveAmount!: number;
    public positiveAmount!: number;
    public negativeAmount!: number;
    public veryNegativeAmount!: number;
    public neutralAmount!: number;
    public createdTimestamp!: number;

    public constructor (groupId: string, veryPositiveAmount: number,positiveAmount: number, negativeAmount: number, veryNegativeAmount : number, neutralAmount: number, createdTimestamp: number) {
        this.systemETag = null;
        this.groupId = groupId;
        this.veryPositiveAmount = veryPositiveAmount;
        this.positiveAmount = positiveAmount;
        this.negativeAmount = negativeAmount;
        this.neutralAmount = neutralAmount;
        this.veryNegativeAmount = veryNegativeAmount;
        this.createdTimestamp = createdTimestamp;
    }

    static Columns = {
        id: 'id',
        negativeAmount: "negativeAmount",
        veryNegativeAmount: "veryNegativeAmount",
        positiveAmount: "positiveAmount",
        veryPositiveAmount: "veryPositiveAmount",
        neutralAmount: "neutralAmount",
        createdTimestamp: "createdTimestamp",
        createdTimestampTime: "createdTimestampTime",
        groupId: "groupId"
    }
}