import { Grid, Paper } from "@mui/material"
import React from "react"
import { PeriodChartFilterType } from "../../../../helpers/charts/base";
import { elevationPaper, paperStyle } from "../../../../styles/container";
import { ChartLabelType } from "../../../charts/Base";
import { LineChart } from "../../../charts/LineChart";
import { SideBySideBarChart } from "../../../charts/SideBySideBarChart";
import { SideBySideBarChartDynamic } from "../../../charts/SideBySideBarChartDynamic";
import { StackedChart } from "../../../charts/StackedChart";
import { ChartChoice } from "../../../core/ChartChoice";
import { ChartCalcType, TimeChoice } from "../../../core/TimeChoice";

export const ObjectLeaderQualityCheckAverageServicesChart = ({ servicesData, dataField, allData, setChartData, childRef, loadingCompleted, childRefChart, title = "Durchschnitt Services Qualitätssicherung" }: { servicesData: any[], dataField: any[], allData: any, setChartData: any, childRef: any, loadingCompleted: boolean, childRefChart?: any, title?: string }) => {
    const [chartChoice, setChartChoice] = React.useState('2');
    React.useEffect(()=>{
        if (childRef.current)  childRef.current.renderData();
      },[])
    const buildGridItem = () => {
        switch (chartChoice) {
            case '0':
                return <LineChart
                    chartLabelType={ChartLabelType.Percent}
                    childRefChart={childRefChart}
                    data={servicesData}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '1':
                return <StackedChart
                    chartLabelType={ChartLabelType.Percent}
                    childRefChart={childRefChart}
                    data={servicesData}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            case '2':
                return <SideBySideBarChartDynamic
                    chartLabelType={ChartLabelType.Percent}
                    childRefChart={childRefChart}
                    data={servicesData}
                    dataField={dataField}
                    text={title}
                    loadingCompleted={loadingCompleted} />;
            default:
                return null;
        }
    }

    return (
        <div>
            <Paper elevation={elevationPaper} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        {buildGridItem()}
                    </Grid>
                    <Grid item>
                        <TimeChoice ref={childRef} fields={dataField} data={allData} setChartData={setChartData} chartCalcType={ChartCalcType.AverageQualityCheckServices} dateDisplayType={PeriodChartFilterType.Month}/>
                    </Grid>
                    <Grid item>
                        <ChartChoice value={chartChoice} setValue={setChartChoice} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}