import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, DialogContent, Typography, DialogActions, Button, CircularProgress } from '@mui/material';
import { UserLogo } from '../../models/UserLogo';
import { Delete, UploadFile } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const GroupLogos = ({ logos, onConfirmed, onDelete }: { logos: UserLogo[], onConfirmed: any, onDelete: any }) => {
    const { t } = useTranslation();
    return (
        <ImageList cols={3}>
            {logos.map((item, index) => (
                <ImageListItem key={index}>
                    {item.objectUrl ?
                        <img
                            src={item.objectUrl}
                            loading="lazy"
                            style={{ width: '100%', height: 300, objectFit: 'contain' }}
                        /> :
                        <div style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress size={64} />
                        </div>
                    }
                    <ImageListItemBar
                        title={item.notificationName}

                        actionIcon={
                            <>
                                <Button
                                    size="small"
                                    startIcon={<UploadFile />}
                                    variant={'contained'}
                                    onClick={() => onConfirmed(item)}
                                    color="primary">
                                    {t("myUnitsSettings.useAsLogo")}
                                </Button>
                                <IconButton color='error' onClick={() => onDelete(item)}>
                                    <Delete />
                                </IconButton>
                            </>
                        }

                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}

export const GroupLogosDialog = ({ visible, onClose, onConfirmed, logos, onDelete }
    : { visible: boolean, onClose: any, onConfirmed: any, logos: UserLogo[], onDelete: any }) => {


    const { t } = useTranslation();

    return (
        <Dialog fullWidth maxWidth="xl" open={visible} onClose={onClose}>
            <DialogContent>
                <GroupLogos onDelete={onDelete} onConfirmed={onConfirmed} logos={logos} />
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.close")}
                </Button>
            </DialogActions>

        </Dialog>
    );
};