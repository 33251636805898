import axios from 'axios';
import { getUserIdFromStorage } from '../../helpers/common';
import { UserServiceWorkerObjectSession } from '../../models/UserServiceWorkerObjectSession';
import { headers } from "../../api";

export const deleteServiceWorkerObjectSession = async (objectSessionId: string, token: string, enqueueSnackbar: any): Promise<UserServiceWorkerObjectSession> => {
    try {
        if (!objectSessionId) throw (null);
        let userId = getUserIdFromStorage();
        let ret = await axios.delete(`${process.env.REACT_APP_BASIC_URI}/users/${userId}/object-sessions/${objectSessionId}`,
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        enqueueSnackbar("Objekt Anwesenheit Löschen erfolgreich", { variant: 'success' });
        return ret.data;
    } catch (err) {
        enqueueSnackbar("Objekt Anwesenheit Löschen error", { variant: 'error' });
    }
    return null as any;
}