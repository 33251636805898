import { addNewUserKey } from "./api/AddNewUserKey";
import { login } from "./api/authorization/login";
import { registerNewUser, registerNewUserFromAdmin, reSendRegistrationMail } from "./api/authorization/Register";
import { verifyEmail } from "./api/authorization/VerifyEmail";
import { changePassword } from "./api/ChangePassword";
import { createUserAddress } from "./api/CreateUserAddress";
import { extendLicense } from "./api/ExtendLicense";
import { GetAllGroupsAsync, GetAllGroupsByGroupIdsAsync, GetAllGroupsByUserIdAsync } from "./api/GetAllGroupsAsync";
import { getCleaningTriggerByGroup } from "./api/GetCleaningTriggerByGroup";
import {  getCleaningTriggerByUserGroupIds } from "./api/GetCleaningTriggerByUserGroupIds";
import { getFeedbacksByGroup } from "./api/GetFeedbacksByGroup";
import { getFeedbacksByUser } from "./api/GetFeedbacksByUser";
import { getGroupSettings, getGroupSettingsExtended, getGroupSettingsLight } from "./api/GetGroupSettings";
import { getMotionsByGroup } from "./api/GetMotionsByGroup";
import { getMotionsByObject, getMotionsByServiceWorker, getMotionsByUserGroupIds } from "./api/GetMotionsByUser";
import { getGroupServiceModeByGroup } from "./api/GetServiceModeByGroup";
import { getGroupServiceModeByGroupIdsAsync, getGroupServiceModeByObjects, getGroupServiceModeByServiceWorker } from "./api/GetServiceModeByUser";
import { getLightUser, getUser } from "./api/GetUser";
import { getUserAddresses } from "./api/GetUserAddresses";
import { getGroupSettingsTemplateById, getGroupSettingsTemplateByUser } from "./api/GetUserGroupSettingsTemplateByUser";
import { getUserKeys } from "./api/GetUserKeys";
import { updateGroupSettings } from "./api/UpdateGroupSettings";
import { updateUser, updateUserFromAdmin, updateUserFromOwner } from "./api/UpdateUser";
import { updateUserGroupSettingsTemplate } from "./api/UpdateUserGroupSettingsTemplate";
import { updateGroupLogo } from "./api/UpdateGroupLogo";
import { uploadGroupSideBarImages } from "./api/UploadGroupSideBarImages";
import { createGroupLicenseInvoice } from "./api/CreateGroupLicenseInvoice";
import { getStripeClientSofortSecret } from "./api/GetStripeClientSofortSecret";
import { getInvoiceTransactionAsync } from "./api/GetInvoiceTransactionAsync";
import { getLicenseCostConfig } from "./api/GetLicenseCostConfigAsync";
import { requestResetPassword } from "./api/authorization/RequestResetPassword";
import { getResetPasswordRequestAsync } from "./api/GetResetPasswordRequest";
import { changePasswordByRequestIdAsync } from "./api/ChangePasswordByRequestId";
import { getDefaultCleaningTasksAsync } from "./api/GetDefaultCleaningTasks";
import { getDefaultIncidentsAsync } from "./api/GetDefaultIncidents";
import { getDefaultMaterialsAsync } from "./api/GetDefaultMaterials";
import { verifyUserToken } from "./api/authorization/VerifyUserToken";
import { getLastMotionByGroup, getLastMotionByGroupId } from "./api/GetLastMotionByGroup";
import { getLastMotionsByGroup, getLastMotionsByGroupId } from "./api/GetLastMotionsByGroup";
import { listGroupSettingsSideBarSettingsAsync } from "./api/ListGroupSettingsSideBarSettingsAsync";
import { deleteGroupSideBarSettingsAsync } from "./api/DeleteGroupSideBarSettingsAsync";
import { updateGroupSettingsSideBarSettingsAsync } from "./api/UpdateGroupSettingsSideBarSettingsAsync";
import { requestChangeUserEmailAsync } from "./api/RequestChangeUserEmailAsync";
import { changeUserEmailAsync } from "./api/ChangeUserEmailAsync";
import { getUserByInvitedFromUserId } from "./api/GetUserByInvitedFromUserId";
import { createUserAssignedGroup } from "./api/CreateUserAssignedGroup";
import { getUserAssignedGroupByInvitedUserId } from "./api/GetUserAssignedGroupByInvitedUserId";
import { getUserAssignedGroupByOwnerUserId } from "./api/GetUserAssignedGroupByOwnerUserId";
import { deleteUserAssignedGroup } from "./api/DeleteUserAssignedGroup";
import { telegramCreateGroup } from "./api/TelegramCreateGroup";
import { telegramInviteUserToGroup } from "./api/TelegramInviteUserToGroup";
import { telegramDeleteUserFromGroup } from "./api/TelegramDeleteUserFromGroup";
import { telegramGetIsUserAuthorized } from "./api/TelegramGetIsUserAuthorized";
import { sendCodeRequestTelegram } from "./api/SendCodeRequestTelegramAsync";
import { telegramMakeAuthAsync } from "./api/TelegramMakeAuthAsync";
import { telegramGetGroupChatsAsync } from "./api/TelegramGetGroupChats";
import { copyBlobItemsOfTemplateToGroupAsync } from "./api/CopyBlobItemsOfTemplateToGroupAsync";
import { sendSupportContactRequest } from './api/sendSupportContactRequest';
import { getSupportContactTopics } from './api/getSupportContactTopics';
import { getAllUsersByAdmin, getAllUsersInclusiveNonActivatedByAdmin } from "./api/GetAllUsersByAdmin";
import { createUserGroupSettingsTemplate } from './api/CreateUserGroupSettingsTemplate';
import { deleteUserTelegramGroupsAsync } from './api/DeleteUserTelegramGroupsAsync';
import { getGroup } from './api/GetGroup';
import { updateUserTelegramGroupsTitleAsync } from './api/UpdateUserTelegramGroupsTitleAsync';
import { deleteUserAsync } from './api/DeleteUserAsync';
import { getAllInvoicesAsync, getUserInvoicesAsync } from './api/GetUserInvoices';
import { deleteUserAddress } from './api/DeleteUserAddress';
import { AssignLicenseToGroup } from './api/AssignLicenseToGroup';
import { DeleteGroupAsync } from './api/DeleteGroupAsync';
import { deleteLicenseAsync } from './api/DeleteLicenseAsync';
import { AddLicenseAsAdminAsync } from './api/AddLicenseAsAdminAsync';
import { AddProductAsync } from './api/AddProductAsync';
import { ListProductsAsync, listProductsForPublicAsync } from './api/ListProductsAsync';
import { UpdateProductAsync } from './api/UpdateProductAsync';
import { DeleteProductAsync } from './api/DeleteProductAsync';
import { GetStripeClientSecretAsync } from './api/GetStripeClientSecretAsync';
import { GetAllLicensesByAdminAsync, GetLicensesAsync } from './api/GetLicensesAsync';
import { GetGroupSettingsByUserAsync, GetGroupSettingsByUserGroupIdsAsync } from './api/GetGroupSettingsByUserAsync';
import { ListTemplateInfosAsync } from './api/ListTemplateInfosAsync';
import { UploadTemplateAsync } from './api/UploadTemplateAsync';
import { UpdateTemplateInfoAsync } from './api/UpdateTemplateInfoAsync';
import { DeleteTemplateInfoAsync } from './api/DeleteTemplateInfoAsync';
import { VerifyTransactionAsync } from './api/VerifyTransactionAsync';
import { getGroupSensorsById } from "./api/GetGroupSensorsAsync";
import { getFeedbackQuestionsByGroup } from "./api/GetFeedbackQuestionsByGroup";
import { getGroupSensorsByUserId } from "./api/GetGroupSensorsByUserId";
import { getCleanerFeedbacksByGroup } from "./api/GetCleanerFeedbacksByGroup";
import { deleteUserGroupSettingsTemplate } from "./api/DeleteUserGroupSettingsTemplate";
import { getNews } from "./api/GetNews";
import { orderByAdminWithoutInvoice } from "./api/OrderByAdminWithoutInvoice";
import { copyBlobItemsOfGroupToTemplateAsync } from "./api/CopyBlobItemsOfGroupToTemplateAsync";
import { deleteUserSavedCombinationGroup } from "./api/DeleteUserSavedCombinationGroup";
import { createUserSavedCombinationGroup } from "./api/CreateUserSavedCombinationGroup";
import { getUserSavedCombinationGroupsByUserId } from "./api/GetUserSavedCombinationGroupsByUserId";
import {  updateUserSavedCombinationGroupLogo } from "./api/UpdateUserSavedCombinationGroup";
import { deleteLogoUserSavedCombinationGroup } from "./api/DeleteLogoUserSavedCombinationGroup";
import { getUserObjectUnits, getUserObjectUnitsByIds } from "./api/getUserObjectUnits";
import { createUserObjectUnit } from "./api/CreateUserObjectUnit";
import { assignObjectUnitToGroup } from "./api/AssignObjectUnitToGroup";
import { putUserObjectUnit } from "./api/PutUserObjectUnit";
import { getUserObjectUnit } from "./api/getUserObjectUnit";
import { deleteUserObjectUnit } from "./api/DeleteUserObjectUnit";
import { getUserProperties, getUserPropertiesByType } from "./api/getUserProperties";
import { createUserProperty } from "./api/CreateUserProperty";
import { putUserProperty } from "./api/PutUserProperty";
import { getUserPropertiesByIds, getUserProperty } from "./api/getUserProperty";
import { deleteUserProperty } from "./api/DeleteUserProperty";
import { assignPropertiesToGroup, assignPropertyToGroup } from "./api/AssignPropertyToGroup";
import { getServiceWorkers, getServiceWorkersByIds } from "./api/getServiceWorkers";
import { createUserServiceWorker } from "./api/CreateUserServiceWorker";
import { deleteUserServiceWorker } from "./api/DeleteUserServiceWorker";
import { putUserServiceWorker } from "./api/PutUserServiceWorker";
import { unAssignPropertiesFromGroup, unAssignPropertyToGroup } from "./api/UnAssignPropertyToGroup";
import { UnAssignUserServiceWorkerFromGroup, UnAssignUserServiceWorkersFromGroup } from "./api/UnAssignUserServiceWorkerFromGroup";
import { assignUserServiceWorkersToGroup, assignUserServiceWorkerToGroup } from "./api/AssignUserServiceWorkerToGroup";
import { unAssignObjectFromGroup } from "./api/UnAssignObjectFromGroup";
import { inviteUserServiceWorkerToApp } from "./api/InviteUserServiceWorkerToApp";
import { getObjectSessionsOfServiceWorker } from "./api/GetObjectSessionsOfServiceWorker";
import { getObjectSessionsByObjectId } from "./api/GetObjectSessionsByObjectId";
import { updateGroupSettingsByGroupLicenseTable } from "./api/UpdateGroupSettingsByGroupLicenseTable";
import { createGroupCheckpoint } from "./api/CreateGroupCheckpoint";
import { createGroupSettings } from "./api/CreateGroupSettings";
import { downloadAnyLogoImageAsync, downloadLogoImageAsync, downloadQrCodeImageAsync, downloadQrCodeImageByGroupIdAsync, downloadQrCodeObjectImageAsync, downloadQrCodeObjectImageWithUrlAsync, downloadUserLogoAsync } from "./api/DownloadQrCodeImage";
import { getLogosOfGroup } from "./api/GetLogosOfGroup";
import { deleteGroupLogoAsync } from "./api/DeleteGroupLogoAsync";
import { getBssdRegisteredHubs } from "./api/bssd/GetBssdRegisteredHubs";
import { getQualityChecksByServiceWorkerId } from "./api/getQualityChecksByServiceWorkerId";
import { getServiceWorkerObjectLeadersByGroupId } from "./api/getServiceWorkerObjectLeadersByGroupId";
import { getQualityChecksByGroupId } from "./api/getQualityChecksByGroupId";
import { getQualityChecksByGroupIds } from "./api/getQualityChecksByGroupIds";
import { assignServiceWorkersToObject, assignServiceWorkerToObject } from "./api/AssignServiceWorkerToObject";
import { deleteServiceWorkerFromObject, unassignServiceWorkersFromObject } from "./api/DeleteServiceWorkerFromObject";
import { assignLicenseObjectStandaloneToObject } from "./api/AssignLicenseObjectStandaloneToObject";
import { getIncidentVisitorByGroup } from "./api/GetIncidentVisitorByGroup";
import { getIncidentVisitorByUserGroupIds } from "./api/GetIncidentVisitorByUser";
import { downloadGroupIncidentImage, downloadGroupQualityCheckImage } from "./api/DownloadGroupIncidentImage";
import { sendSelectedQualityChecksToMail } from "./api/qualityChecks/sendSelectedQualityChecksToMail";
import { createRegisteredBssdHub } from "./api/bssd/CreateRegisteredBssdHub";
import { getBssdUnregisteredDevices } from "./api/bssd/GetBssdUnregisteredDevices";
import { createRegisteredBssdDevice } from "./api/bssd/CreateRegisteredBssdDevice";
import { getBssdRegisteredDevices } from "./api/bssd/GetBssdRegisteredDevices";
import { createGroupBssdDisplay } from "./api/CreateGroupBssdDisplay";
import { addBssdSensorToGroup } from "./api/AddBssdSensorToGroup";
import { removeBssdSensorFromGroup } from "./api/bssd/RemoveBssdSensorFromGroup";
import { getObjectSessionsByObjectIds, getObjectSessionsByUserId } from "./api/GetObjectSessionsByUserId";
import { updateGroupSettingsFromObjectUnitAsync } from "./api/UpdateGroupSettingsFrimObjectUnitAsync";
import { getObjectCleaningTriggerByObjectId } from "./api/getObjectCleaningTriggerByObjectId";
import { getObjectCleaningTriggerByObjectIds, getObjectCleaningTriggerByUserId } from "./api/GetObjectCleaningTriggerByUserId";
import { getOnlineStatusByGroupId } from "./api/GetOnlineStatusByGroupId";
import { getGroupOnlineStatusBatteryDisplaysByGroupIdsAsync, getGroupOnlineStatusByGroupIdsAsync, GetOnlineStatusByUser, GetOnlineStatusByUserId } from "./api/GetOnlineStatusByUser";
import { uploadObjectFile } from "./api/UploadObjectFile";
import { listObjectFiles } from "./api/ListObjectFiles";
import { DeleteObjectFileAsync } from "./api/DeleteObjectFileAsync";
import { DownloadObjectFileAsync } from "./api/DownloadObjectFileAsync";
import { createObjectSharedFolderAsync } from "./api/CreateObjectSharedFolderAsync";
import { assignObjectToSharedFolderAsync } from "./api/objectUnit/AssignObjectToSharedFolderAsync";
import { GetObjectSharedFolderTableByObjectIdAsync } from "./api/GetObjectSharedFolderTableByObjectIdAsync";
import { listObjectSharedFiles } from "./api/ListObjectSharedFiles";
import { uploadObjectSharedFileAsync } from "./api/UploadObjectSharedFileAsync";
import { DownloadObjectSharedFileAsync } from "./api/DownloadObjectSharedFileAsync";
import { inviteUserServiceWorkerToTelegram } from "./api/InviteUserServiceWorkerToTelegram";
import { patchGroupLicenseAsync } from "./api/PatchGroupLicenseAsync";
import { getDispensersFromTork } from "./api/GetDispensersFromTork";
import { getLocationsFromTork } from "./api/GetLocationsFromTork";
import { activateLoginForGroupByAdmin, deactivateLoginForGroupByAdmin, setDeviceValidUntilAdmin } from "./api/activateLoginForGroupByAdmin";
import { getOfflineDataByGroupId } from "./api/getOfflineDataByGroupId";
import { GetInstructions } from "./api/GetInstructions";
import { GetObjectSharedFoldersByUserId } from "./api/GetObjectSharedFoldersByUserId";
import { DeleteObjectSharedFolder } from "./api/DeleteObjectSharedFolderAsync";
import { assignObjectsToServiceWorker, unAssignObjectsFromServiceWorker } from "./api/AssignObjectsToServiceWorker";
import { RenameObjectFileAsync } from "./api/RenameObjectFileAsync";
import { RenameObjectSharedFileAsync } from "./api/RenameObjectSharedFileAsync";
import { createStandardItem, createStandardItemAdmin } from "./api/CreateStandardItem";
import { GetAdminStandardWorkItems, GetAdminStandardWorkItemsByType, GetStandardWorkItems, GetStandardWorkItemsByType } from "./api/GetStandardWorkItems";
import { DownloadUserFile } from "./api/DownloadUserFile";
import { DeleteStandardItemAdminAsync, DeleteStandardItemAsync } from "./api/DeleteStandardItemAsync";
import { GetFeedbacksByGroupIds } from "./api/GetFeedbacksByGroupIds";
import { GetAdminSettingPortal, GetAdminSettings } from "./api/GetAdminSettings";
import { CreateAdminSetting } from "./api/CreateAdminSetting";
import { GetSettingsServiceEntriesByGroups, GetSettingsServiceEntriesOfObject, GetSettingsServiceEntriesOfServiceWorkers } from "./api/GetSettingsServicesByUser";
import { CreateIntervalControl } from "./api/CreateIntervalControl";
import { DeleteIntervalControlAsync } from "./api/DeleteIntervalControlAsync";
import { GetIntervalControlByUser } from "./api/GetIntervalControlByUser";
import { updateUserLogo } from "./api/UpdateUserLogo";
import { CreateFeedbackLightByGroupId } from "./api/CreateFeedbackLightByGroupId";
import { CreateFeedbackQuestionsLightByGroupId } from "./api/CreateFeedbackQuestionsLightByGroupId";
import { CreateIncidentVisitorLightByGroupId, CreatePayPerServiceLightByGroupId } from "./api/CreateIncidentVisitorLightByGroupId";
import { createLoraDevice } from "./api/lora/CreateLoraDevice";
import { getLoraDevices } from "./api/lora/GetLoraDevices";
import { UnAssignLoraDeviceToGroupByIdAsync } from "./api/lora/UnAssignLoraDeviceToGroupByIdAsync";
import { AssignLoraDeviceToGroupByIdAsync } from "./api/lora/AssignLoraDeviceToGroupByIdAsync";
import { getLoraMotionsByGroupIds } from "./api/lora/GetLoraMotionsBy";
import { acceptTermsOfUseForUser } from "./api/userAccount/AcceptTermsOfUseForUser";
import { getAllLoraDevices } from "./api/lora/GetAllLoraDevices";
import { addLoraDeviceAdmin } from "./api/lora/AddLoraDeviceAdmin";
import { deleteLoraDeviceAdmin } from "./api/lora/DeleteLoraDeviceAdmin";
import { getObjectUnitsByCostLocation } from "./api/lora/getObjectUnitsByCostLocation";
import { getServiceWorkersByName } from "./api/serviceworkers/getServiceWorkersByName";
import { getGroupSettingsByLocationName } from "./api/groupSettings/getGroupSettingsByLocationName";
import { getGroupSettingsByObjectId, getGroupsPortalByObjectId, getGroupsPortalByServiceWorkerIds } from "./api/objectUnit/getGroupSettingsByObjectId";
import { getFeedbacksByObjectId } from "./api/objectUnit/getFeedbacksByObjectId";
import { getFeedbackQuestionsByObjectId } from "./api/objectUnit/getFeedbackQuestionsByObjectId";
import { unassignObjectToSharedFolderAsync } from "./api/objectUnit/UnAssignObjectToSharedFolderAsync";
import { DeleteObjectSharedFileAsync } from "./api/objectUnit/DeleteObjectSharedFileAsync";
import { copySharedFolderStructrueToObjectsAsync } from "./api/objectshared/CopySharedFolderStructrueToObjectsAsync";
import { getCleanerFeedbacksByUserGroupIds } from "./api/GetCleanerFeedbacksByUser";
import { getFeedbackQuestionsByUserGroupIds } from "./api/GetFeedbackQuestionsByUser";
import { getQualityChecksByUserIdGroupIds } from "./api/getQualityChecksByUserId";
import { getUsergridStackCustoms } from "./api/gridStackCustom/getUserObjectUnits";
import { CreateUserGridStackCustom } from "./api/gridStackCustom/CreateUserGridStackCustom";
import { putUserGridStackCustom } from "./api/gridStackCustom/PutUserGridStackCustom";
import { createServiceWorkerObjectSession } from "./api/CreateServiceWorkerObjectSession";
import { CreateGroupServiceModeManual } from "./api/CreateGroupServiceModeManual";
import { DeleteServiceModeById } from "./api/service-mode/DeleteServiceModeById";
import { deleteServiceWorkerObjectSession } from "./api/service-worker-object-session/DeleteServiceWorkerObjectSession";
import { DeleteGridStackCustomById } from "./api/gridStackCustom/DeleteGridStackCustomById";
import { GetPayPerServiceByUser } from "./api/GetPayPerServiceByUser";
import { GetSuggestionsByGroup } from "./api/GetSuggestionsByGroup";
import { getSuggestionsByUserGroupIds } from "./api/suggestion/GetFeedbackQuestionsByUser";
import { DeleteFeedbackById } from "./api/Feedbacks/DeleteFeedbackById";
import { getLastLoraMotionByGroup } from "./api/lora/GetLastLoraMotionByGroup";
import { createGroupTaqtFeedback } from "./api/CreateGroupTaqtFeedback";
import { createGroupTaqtTime } from "./api/CreateGroupTaqtTime";
import { assignDeviceIdToGroup } from "./api/group/activateLoginForGroupByAdmin";
import { GetMaterialsByUserIdAsync } from "./api/GetMaterialsByUserIdAsync";
import { createUserStock } from "./api/userstock/CreateUserStock";
import { getUserStocks } from "./api/userstock/GetUserStocks";
import { getLatestUserStockByGroupId, getLatestUserStockByGroupIds, getLatestUserStockByObjectId } from "./api/userstock/GetLatestUserStockByObjectId";
import { getBasicUserInformationByUserIds } from "./api/userAccount/getQualityChecksByGroupIds";
import { assignQmToGroupByIdsAsync, unassignQmToGroupByIdsAsync } from "./api/AssignQmToGroupByIdsAsync";
import { GetQmTriggerByUserGroupIds } from "./api/GetQmTriggerByUserGroupIds";
import { patchMultipleGroupSettingsAsync } from "./api/groupSettings/patchMultipleGroupSettingsAsync";
import { GetSmilesUri } from "./api/GetSmilesUri";
import { loginServiceWorkerByUsernameCode } from "./api/public/LoginServiceWorkerByUsernameCode";
import { loginServiceWorkerByEmailCode } from "./api/public/LoginServiceWorkerByEmailCode";
import { loginServiceWorkerByPhoneCode } from "./api/public/LoginServiceWorkerByPhoneCode";
import { createGroupServiceModePublic } from "./api/public/CreateGroupServiceMode";
import { finishGroupServiceModePublic } from "./api/public/FinishGroupServiceMode";
import { isServiceWorkerSignedIntoObject } from "./api/public/IsServiceWorkerSignedIntoObject";
import { signIntoObject } from "./api/public/SignIntoObject";
import { signOutOfObject } from "./api/public/SignOutOfObject";
import { getActiveObjectSessionByStaffNumberAsync } from "./api/public/GetActiveObjectSessionByStaffNumberAsync";
import { createServiceWorkerObjectSessionPublic } from "./api/public/CreateServiceWorkerObjectSessionPublic";
import { parseISO } from "date-fns";
import { patchGroupPortalSettings } from "./api/PatchGroupPortalSettings";

export const api = {
    login: login,
    registerNewUser: registerNewUser,
    updateUserFromOwner: updateUserFromOwner,
    getGroupSettingsTemplateByUser: getGroupSettingsTemplateByUser,
    getAllGroups: GetAllGroupsAsync,
    GetAllGroupsByUserIdAsync: GetAllGroupsByUserIdAsync,
    getGroupServiceModeByGroup: getGroupServiceModeByGroup,
    getCleaningTriggerByGroup: getCleaningTriggerByGroup,
    getFeedbacksByGroup: getFeedbacksByGroup,
    getFeedbackQuestionsByGroup: getFeedbackQuestionsByGroup,
    getMotionsByGroup: getMotionsByGroup,
    getLastMotionByGroup: getLastMotionByGroup,
    getLastMotionsByGroup: getLastMotionsByGroup,
    updateGroupSettings: updateGroupSettings,
    updateUserGroupSettingsTemplate: updateUserGroupSettingsTemplate,
    getMotionsByUserGroupIds: getMotionsByUserGroupIds,
    getFeedbacksByUser: getFeedbacksByUser,
    getGroupSettings: getGroupSettings,
    verifyEmail: verifyEmail,
    extendLicense: extendLicense,
    getUserKeys: getUserKeys,
    addNewUserKey: addNewUserKey,
    getUser: getUser,
    updateUser: updateUser,
    changePassword: changePassword,
    changePasswordByRequestIdAsync: changePasswordByRequestIdAsync,
    createUserAddress: createUserAddress,
    getUserAddresses: getUserAddresses,
    getStripeClientSecretAsync: GetStripeClientSecretAsync,
    updateGroupLogo: updateGroupLogo,
    uploadObjectFile: uploadObjectFile,
    uploadGroupSideBarImages: uploadGroupSideBarImages,
    createGroupLicenseInvoice: createGroupLicenseInvoice,
    getStripeClientSofortSecret: getStripeClientSofortSecret,
    getInvoiceTransactionAsync: getInvoiceTransactionAsync,
    getLicenseCostConfig: getLicenseCostConfig,
    requestResetPassword: requestResetPassword,
    getResetPasswordRequestAsync: getResetPasswordRequestAsync,
    getDefaultCleaningTasksAsync: getDefaultCleaningTasksAsync,
    getDefaultIncidentsAsync: getDefaultIncidentsAsync,
    getDefaultMaterialsAsync: getDefaultMaterialsAsync,
    verifyUserToken: verifyUserToken,
    listGroupSettingsSideBarSettingsAsync: listGroupSettingsSideBarSettingsAsync,
    deleteGroupSideBarSettingsAsync: deleteGroupSideBarSettingsAsync,
    updateGroupSettingsSideBarSettingsAsync: updateGroupSettingsSideBarSettingsAsync,
    requestChangeUserEmailAsync: requestChangeUserEmailAsync,
    changeUserEmailAsync: changeUserEmailAsync,
    getUserByInvitedFromUserId: getUserByInvitedFromUserId,
    createUserAssignedGroup: createUserAssignedGroup,
    getUserAssignedGroupByInvitedUserId: getUserAssignedGroupByInvitedUserId,
    getUserAssignedGroupByOwnerUserId: getUserAssignedGroupByOwnerUserId,
    deleteUserAssignedGroup: deleteUserAssignedGroup,
    telegramCreateGroup: telegramCreateGroup,
    telegramGetGroupChatsAsync: telegramGetGroupChatsAsync,
    telegramInviteUserToGroup: telegramInviteUserToGroup,
    telegramDeleteUserFromGroup: telegramDeleteUserFromGroup,
    telegramGetIsUserAuthorized: telegramGetIsUserAuthorized,
    sendCodeRequestTelegram: sendCodeRequestTelegram,
    telegramMakeAuthAsync: telegramMakeAuthAsync,
    getAllGroupsSettingsByUser: GetGroupSettingsByUserAsync,
    getGroupSensorsById: getGroupSensorsById,
    getGroupSensorsByUserId: getGroupSensorsByUserId,
    copyBlobItemsOfTemplateToGroupAsync: copyBlobItemsOfTemplateToGroupAsync,
    sendSupportContactRequest: sendSupportContactRequest,
    getSupportContactTopics: getSupportContactTopics,
    getAllUsersByAdmin: getAllUsersByAdmin,
    createUserGroupSettingsTemplate: createUserGroupSettingsTemplate,
    updateUserTelegramGroupsTitleAsync: updateUserTelegramGroupsTitleAsync,
    deleteUserTelegramGroupsAsync: deleteUserTelegramGroupsAsync,
    getGroup: getGroup,
    deleteUserAsync: deleteUserAsync,
    getUserInvoicesAsync: getUserInvoicesAsync,
    deleteUserAddress: deleteUserAddress,
    assignLicenseToGroup: AssignLicenseToGroup,
    deleteGroupAsync: DeleteGroupAsync,
    deleteLicenseAsync: deleteLicenseAsync,
    addLicenseAsAdminAsync: AddLicenseAsAdminAsync,
    addProductAsync: AddProductAsync,
    listProductsAsync: ListProductsAsync,
    updateProductAsync: UpdateProductAsync,
    deleteProductAsync: DeleteProductAsync,
    getLicensesAsync: GetLicensesAsync,
    listTemplateInfosAsync: ListTemplateInfosAsync,
    uploadTemplateAsync: UploadTemplateAsync,
    updateTemplateInfoAsync: UpdateTemplateInfoAsync,
    deleteTemplateInfoAsync: DeleteTemplateInfoAsync,
    verifyTransactionAsync: VerifyTransactionAsync,
    getFeedbackQuestionsByUserGroupIds: getFeedbackQuestionsByUserGroupIds,
    getCleanerFeedbacksByGroup: getCleanerFeedbacksByGroup,
    getCleanerFeedbacksByUserGroupIds: getCleanerFeedbacksByUserGroupIds,
    deleteUserGroupSettingsTemplate: deleteUserGroupSettingsTemplate,
    getNews: getNews,
    getAllUsersInclusiveNonActivatedByAdmin: getAllUsersInclusiveNonActivatedByAdmin,
    registerNewUserFromAdmin: registerNewUserFromAdmin,
    updateUserFromAdmin: updateUserFromAdmin,
    getAllInvoicesAsync: getAllInvoicesAsync,
    orderByAdminWithoutInvoice: orderByAdminWithoutInvoice,
    copyBlobItemsOfGroupToTemplateAsync: copyBlobItemsOfGroupToTemplateAsync,
    listProductsForPublicAsync: listProductsForPublicAsync,
    deleteUserSavedCombinationGroup: deleteUserSavedCombinationGroup,
    createUserSavedCombinationGroup: createUserSavedCombinationGroup,
    getUserSavedCombinationGroupsByUserId: getUserSavedCombinationGroupsByUserId,
    updateUserSavedCombinationGroupLogo: updateUserSavedCombinationGroupLogo,
    deleteLogoUserSavedCombinationGroup: deleteLogoUserSavedCombinationGroup,
    getUserObjectUnits: getUserObjectUnits,
    createUserObjectUnit: createUserObjectUnit,
    assignObjectUnitToGroup: assignObjectUnitToGroup,
    putUserObjectUnit: putUserObjectUnit,
    getUserObjectUnit: getUserObjectUnit,
    deleteUserObjectUnit: deleteUserObjectUnit,
    getUserObjectUnitsByIds: getUserObjectUnitsByIds,
    getUserProperties: getUserProperties,
    getUserPropertiesByIds: getUserPropertiesByIds,
    getUserPropertiesByType : getUserPropertiesByType,
    createUserProperty: createUserProperty,
    putUserProperty: putUserProperty,
    getUserProperty: getUserProperty,
    deleteUserProperty: deleteUserProperty,
    assignPropertyToGroup: assignPropertyToGroup,
    unAssignPropertiesFromGroup: unAssignPropertiesFromGroup,
    getServiceWorkers: getServiceWorkers,
    getServiceWorkersByIds: getServiceWorkersByIds,
    createUserServiceWorker: createUserServiceWorker,
    deleteUserServiceWorker: deleteUserServiceWorker,
    putUserServiceWorker: putUserServiceWorker,
    unAssignPropertyToGroup: unAssignPropertyToGroup,
    UnAssignUserServiceWorkerFromGroup: UnAssignUserServiceWorkerFromGroup,
    assignUserServiceWorkerToGroup: assignUserServiceWorkerToGroup,
    assignUserServiceWorkersToGroup: assignUserServiceWorkersToGroup,
    unAssignObjectFromGroup: unAssignObjectFromGroup,
    UnAssignUserServiceWorkersFromGroup: UnAssignUserServiceWorkersFromGroup,
    inviteUserServiceWorkerToApp: inviteUserServiceWorkerToApp,
    getObjectSessionsOfServiceWorker: getObjectSessionsOfServiceWorker,
    getObjectSessionsByObjectId: getObjectSessionsByObjectId,
    updateGroupSettingsByGroupLicenseTable: updateGroupSettingsByGroupLicenseTable,
    createGroupCheckpoint: createGroupCheckpoint,
    createGroupTaqtFeedback: createGroupTaqtFeedback,
    createGroupTaqtTime: createGroupTaqtTime,
    createGroupSettings: createGroupSettings,
    downloadQrCodeImageAsync : downloadQrCodeImageAsync,
    downloadLogoImageAsync: downloadLogoImageAsync,
    downloadQrCodeObjectImageAsync: downloadQrCodeObjectImageAsync,
    downloadQrCodeObjectImageWithUrlAsync: downloadQrCodeObjectImageWithUrlAsync,
    getLogosOfGroup: getLogosOfGroup,
    downloadAnyLogoImageAsync: downloadAnyLogoImageAsync,
    deleteGroupLogoAsync: deleteGroupLogoAsync,
    GetAllGroupsByGroupIdsAsync: GetAllGroupsByGroupIdsAsync,
    getGroupSettingsExtended: getGroupSettingsExtended,
    getGroupServiceModeByGroupIdsAsync: getGroupServiceModeByGroupIdsAsync,
    getBssdRegisteredHubs: getBssdRegisteredHubs,
    getQualityChecksByServiceWorkerId: getQualityChecksByServiceWorkerId,
    getServiceWorkerObjectLeadersByGroupId: getServiceWorkerObjectLeadersByGroupId,
    getQualityChecksByGroupId: getQualityChecksByGroupId,
    getQualityChecksByGroupIds: getQualityChecksByGroupIds,
    getQualityChecksByUserIdGroupIds: getQualityChecksByUserIdGroupIds,
    assignServiceWorkerToObject: assignServiceWorkerToObject,
    assignServiceWorkersToObject: assignServiceWorkersToObject,
    deleteServiceWorkerFromObject: deleteServiceWorkerFromObject,
    unassignServiceWorkersFromObject: unassignServiceWorkersFromObject,
    assignLicenseObjectStandaloneToObject: assignLicenseObjectStandaloneToObject,
    getIncidentVisitorByGroup: getIncidentVisitorByGroup,
    downloadGroupIncidentImage: downloadGroupIncidentImage,
    sendSelectedQualityChecksToMail: sendSelectedQualityChecksToMail,
    createRegisteredBssdHub: createRegisteredBssdHub,
    getBssdUnregisteredDevices: getBssdUnregisteredDevices,
    createRegisteredBssdDevice: createRegisteredBssdDevice,
    getBssdRegisteredDevices: getBssdRegisteredDevices,
    createGroupBssdDisplay: createGroupBssdDisplay,
    addBssdSensorToGroup: addBssdSensorToGroup,
    removeBssdSensorFromGroup: removeBssdSensorFromGroup,
    getObjectSessionsByUserId: getObjectSessionsByUserId,
    getObjectSessionsByObjectIds: getObjectSessionsByObjectIds,
    updateGroupSettingsFromObjectUnitAsync: updateGroupSettingsFromObjectUnitAsync,
    GetAllLicensesByAdminAsync: GetAllLicensesByAdminAsync,
    getObjectCleaningTriggerByObjectId: getObjectCleaningTriggerByObjectId,
    getObjectCleaningTriggerByUserId: getObjectCleaningTriggerByUserId,
    getObjectCleaningTriggerByObjectIds: getObjectCleaningTriggerByObjectIds,
    getOnlineStatusByGroupId: getOnlineStatusByGroupId,
    GetOnlineStatusByUser: GetOnlineStatusByUser,
    getGroupOnlineStatusByGroupIdsAsync: getGroupOnlineStatusByGroupIdsAsync,
    listObjectFiles : listObjectFiles,
    DeleteObjectFileAsync: DeleteObjectFileAsync,
    DownloadObjectFileAsync: DownloadObjectFileAsync,
    createObjectSharedFolderAsync: createObjectSharedFolderAsync,
    assignObjectToSharedFolderAsync: assignObjectToSharedFolderAsync,
    GetObjectSharedFolderTableByObjectIdAsync: GetObjectSharedFolderTableByObjectIdAsync,
    listObjectSharedFiles: listObjectSharedFiles,
    uploadObjectSharedFileAsync: uploadObjectSharedFileAsync,
    unassignObjectToSharedFolderAsync: unassignObjectToSharedFolderAsync,
    DownloadObjectSharedFileAsync: DownloadObjectSharedFileAsync,
    DeleteObjectSharedFileAsync: DeleteObjectSharedFileAsync,
    inviteUserServiceWorkerToTelegram: inviteUserServiceWorkerToTelegram,
    patchGroupLicenseAsync: patchGroupLicenseAsync,
    getDispensersFromTork: getDispensersFromTork,
    getLocationsFromTork: getLocationsFromTork,
    activateLoginForGroupByAdmin: activateLoginForGroupByAdmin,
    deactivateLoginForGroupByAdmin: deactivateLoginForGroupByAdmin,
    assignPropertiesToGroup: assignPropertiesToGroup,
    getOfflineDataByGroupId: getOfflineDataByGroupId,
    GetInstructions: GetInstructions,
    GetObjectSharedFoldersByUserId: GetObjectSharedFoldersByUserId,
    DeleteObjectSharedFolder: DeleteObjectSharedFolder,
    assignObjectsToServiceWorker: assignObjectsToServiceWorker,
    unAssignObjectsFromServiceWorker: unAssignObjectsFromServiceWorker,
    downloadGroupQualityCheckImage: downloadGroupQualityCheckImage,
    RenameObjectFileAsync: RenameObjectFileAsync,
    RenameObjectSharedFileAsync: RenameObjectSharedFileAsync,
    createStandardItem: createStandardItem,
    GetStandardWorkItems: GetStandardWorkItems,
    DownloadUserFile:DownloadUserFile,
    DeleteStandardItemAsync: DeleteStandardItemAsync,
    DeleteStandardItemAdminAsync: DeleteStandardItemAdminAsync,
    GetStandardWorkItemsByType: GetStandardWorkItemsByType,
    createStandardItemAdmin: createStandardItemAdmin,
    GetAdminStandardWorkItems: GetAdminStandardWorkItems,
    GetFeedbacksByGroupIds: GetFeedbacksByGroupIds,
    getLastMotionByGroupId: getLastMotionByGroupId,
    getLastMotionsByGroupId: getLastMotionsByGroupId,
    GetAdminSettings: GetAdminSettings,
    CreateAdminSetting: CreateAdminSetting,
    getGroupSettingsTemplateById: getGroupSettingsTemplateById,
    setDeviceValidUntilAdmin: setDeviceValidUntilAdmin,
    getGroupServiceModeByObjects: getGroupServiceModeByObjects,
    getMotionsByObject: getMotionsByObject,
    GetSettingsServiceEntriesOfObject: GetSettingsServiceEntriesOfObject,
    GetSettingsServiceEntriesByGroups: GetSettingsServiceEntriesByGroups,
    getGroupServiceModeByServiceWorker: getGroupServiceModeByServiceWorker,
    GetSettingsServiceEntriesOfServiceWorkers: GetSettingsServiceEntriesOfServiceWorkers,
    getMotionsByServiceWorker: getMotionsByServiceWorker,
    GetAdminSettingPortal: GetAdminSettingPortal,
    CreateIntervalControl: CreateIntervalControl,
    DeleteIntervalControlAsync: DeleteIntervalControlAsync,
    GetIntervalControlByUser: GetIntervalControlByUser,
    updateUserLogo: updateUserLogo,
    getGroupSettingsLight: getGroupSettingsLight,
    CreateFeedbackLightByGroupId: CreateFeedbackLightByGroupId,
    getLightUser: getLightUser,
    CreateFeedbackQuestionsLightByGroupId: CreateFeedbackQuestionsLightByGroupId,
    CreateIncidentVisitorLightByGroupId: CreateIncidentVisitorLightByGroupId,
    createLoraDevice: createLoraDevice,
    getLoraDevices: getLoraDevices,
    AssignLoraDeviceToGroupByIdAsync: AssignLoraDeviceToGroupByIdAsync,
    UnAssignLoraDeviceToGroupByIdAsync: UnAssignLoraDeviceToGroupByIdAsync,
    getLoraMotionsByGroupIds: getLoraMotionsByGroupIds,
    acceptTermsOfUseForUser: acceptTermsOfUseForUser,
    getAllLoraDevices: getAllLoraDevices,
    addLoraDeviceAdmin: addLoraDeviceAdmin,
    deleteLoraDeviceAdmin: deleteLoraDeviceAdmin,
    getObjectUnitsByCostLocation: getObjectUnitsByCostLocation,
    getServiceWorkersByName: getServiceWorkersByName,
    getGroupSettingsByLocationName: getGroupSettingsByLocationName,
    getGroupSettingsByObjectId: getGroupSettingsByObjectId,
    getFeedbacksByObjectId: getFeedbacksByObjectId,
    getFeedbackQuestionsByObjectId: getFeedbackQuestionsByObjectId,
    copySharedFolderStructrueToObjectsAsync: copySharedFolderStructrueToObjectsAsync,
    downloadQrCodeImageByGroupIdAsync: downloadQrCodeImageByGroupIdAsync,
    GetOnlineStatusByUserId: GetOnlineStatusByUserId,
    getIncidentVisitorByUserGroupIds: getIncidentVisitorByUserGroupIds,
    getCleaningTriggerByUserGroupIds: getCleaningTriggerByUserGroupIds,
    GetGroupSettingsByUserGroupIdsAsync: GetGroupSettingsByUserGroupIdsAsync,
    getUsergridStackCustoms: getUsergridStackCustoms,
    CreateUserGridStackCustom: CreateUserGridStackCustom,
    putUserGridStackCustom: putUserGridStackCustom,
    createServiceWorkerObjectSession: createServiceWorkerObjectSession,
    CreateGroupServiceModeManual: CreateGroupServiceModeManual,
    DeleteServiceModeById: DeleteServiceModeById,
    deleteServiceWorkerObjectSession: deleteServiceWorkerObjectSession,
    getGroupsPortalByObjectId: getGroupsPortalByObjectId,
    getGroupsPortalByServiceWorkerIds: getGroupsPortalByServiceWorkerIds,
    DeleteGridStackCustomById: DeleteGridStackCustomById,
    reSendRegistrationMail: reSendRegistrationMail,
    CreatePayPerServiceLightByGroupId: CreatePayPerServiceLightByGroupId,
    GetPayPerServiceByUser: GetPayPerServiceByUser,
    GetSuggestionsByGroup: GetSuggestionsByGroup,
    getSuggestionsByUserGroupIds: getSuggestionsByUserGroupIds,
    DeleteFeedbackById: DeleteFeedbackById,
    getLastLoraMotionByGroup: getLastLoraMotionByGroup,
    getGroupOnlineStatusBatteryDisplaysByGroupIdsAsync: getGroupOnlineStatusBatteryDisplaysByGroupIdsAsync,
    downloadUserLogoAsync: downloadUserLogoAsync,
    assignDeviceIdToGroup: assignDeviceIdToGroup,
    GetMaterialsByUserIdAsync: GetMaterialsByUserIdAsync,
    createUserStock: createUserStock,
    getUserStocks: getUserStocks,
    getLatestUserStockByObjectId: getLatestUserStockByObjectId,
    getLatestUserStockByGroupId: getLatestUserStockByGroupId,
    getBasicUserInformationByUserIds: getBasicUserInformationByUserIds,
    assignQmToGroupByIdsAsync: assignQmToGroupByIdsAsync,
    unassignQmToGroupByIdsAsync: unassignQmToGroupByIdsAsync,
    GetQmTriggerByUserGroupIds: GetQmTriggerByUserGroupIds,
    GetAdminStandardWorkItemsByType: GetAdminStandardWorkItemsByType,
    patchMultipleGroupSettingsAsync: patchMultipleGroupSettingsAsync,
    GetSmilesUri: GetSmilesUri,
    getLatestUserStockByGroupIds: getLatestUserStockByGroupIds,
    loginServiceWorkerByUsernameCode: loginServiceWorkerByUsernameCode,
    loginServiceWorkerByEmailCode: loginServiceWorkerByEmailCode,
    loginServiceWorkerByPhoneCode: loginServiceWorkerByPhoneCode,
    createGroupServiceModePublic: createGroupServiceModePublic,
    finishGroupServiceModePublic: finishGroupServiceModePublic,
    isServiceWorkerSignedIntoObject: isServiceWorkerSignedIntoObject,
    signIntoObject: signIntoObject,
    signOutOfObject: signOutOfObject,
    getActiveObjectSessionByStaffNumberAsync: getActiveObjectSessionByStaffNumberAsync,
    createServiceWorkerObjectSessionPublic: createServiceWorkerObjectSessionPublic,
    patchGroupPortalSettings: patchGroupPortalSettings
}

export const headers = {
    'Cache-Control': 'no-cache',
    'content-type': 'application/json',
    'x-functions-key': process.env.REACT_APP_FUNCTIONS_KEY
};

export const multipartHeaders = {
    'Cache-Control': 'no-cache',
    'content-type': 'multipart/form-data',
    'x-functions-key': process.env.REACT_APP_FUNCTIONS_KEY,
};

export const handleApiError = (err: any, enqueueSnackbar: any = null, msg: string = "") => {
    switch (process.env.REACT_APP_BASIC_ENV_STATE) {
        case "development":
            console.log(JSON.stringify(err, null, 2));
            break;
        case "staging":
            console.log(JSON.stringify(err, null, 2));
            break;
        case "production":
            break;
    }
    if (msg && enqueueSnackbar != null){
        enqueueSnackbar(msg, { variant: 'error' });
    }
}

export const handleApiErrorEnv = (err: any, enqueueSnackbar: any, msg: string) => {

}

export const handleApiSuccess = (data: any, enqueueSnackbar: any, msg: string) => {
    enqueueSnackbar(msg, { variant: 'success' });
}

export const showDefaultError = (err: any, enqueueSnackbar: any) => {
    if (err.response && err.response.data && err.response.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: 'error' });
    }
}

const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
  return typeof value === "string" && ISODateFormat.test(value);
};

export const handleDates = (data: unknown) => {
  if (isIsoDateString(data)) return parseISO(data);
  if (data === null || data === undefined || typeof data !== "object") return data;

  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (isIsoDateString(val)) data[key] = parseISO(val);
    else if (typeof val === "object") handleDates(val);
  }

  return data
};