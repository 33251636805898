import React from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Edit, Save, SupervisedUserCircle } from '@mui/icons-material';
import { UserAccount } from '../../../models/UserAccount';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { StringHelper } from '../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { UserType } from '../../../models/UserType';
import { Autocomplete } from '@mui/material';
import { ObjectDisplayType, ObjectUnit } from '../../../models/ObjectUnit';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { mapGroupSettings } from '../../../helpers/group-settings-management';
import { GroupSettingsIntervalControl } from '../../../models/GroupSettings';
import { api } from '../../../api';
import { useTranslation } from 'react-i18next';

export const ObjectManagementPageMassEditingDialog = ({ selectedObjects, visible, setVisible, token }
    : { selectedObjects: ObjectUnit[], visible: boolean, setVisible: any, token: any }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [currentObject, setCurrentObject] = React.useState<ObjectUnit>(new ObjectUnit());
    const {t} = useTranslation();

    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [myAppointments, setMyAppointments] = React.useState<AppointmentModel[]>([]);
    const onChange = (event: any) => {
        let intervalControl = mapGroupSettings({ intervalControl: currentObject.intervalControl }, event.target.name, event.target.value);
        console.log(intervalControl, "intervall", event);
        currentObject.intervalControl = intervalControl.intervalControl;
        setCurrentObject({ ...currentObject });
    };

    const onClose = () => {
        setCurrentObject(ObjectUnit.CreateObject());
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

        (async () => {
            setCurrentObject(ObjectUnit.CreateObject());

        })();

    }, [visible])



    return (
        <Dialog open={visible} onClose={onClose} maxWidth={'lg'} fullWidth>
            <DefaultDialogTitle
                title={`${selectedObjects.length} Objekte bearbeiten`}
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="row" spacing={3} item>
                        <Grid item xs={12}>
                            <Typography component="h4" variant="h4">Details (Massenspeichern)</Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentObject.isActivatedEnabled}
                                        onChange={(event: any) => { setCurrentObject({ ...currentObject, isActivatedEnabled: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myObjectSettings.objectActivated")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{(t("myObjectSettings.serviceStaffOptions"))}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentObject.isQrCodeSignInForcedEnabled}
                                        onChange={(event: any) => { setCurrentObject({ ...currentObject, isQrCodeSignInForcedEnabled: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label={(t("myObjectSettings.enforceQRCodeForObjectCheckIn"))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentObject.isQrCodeSignOutForcedEnabled}
                                        onChange={(event: any) => { setCurrentObject({ ...currentObject, isQrCodeSignOutForcedEnabled: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label={(t("myObjectSettings.enforceQRCodeForObjectCheckOut"))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{(t("myObjectSettings.objectLeaderOptions"))}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentObject.isQrCodeGroupSignInObjectLeaderForcedEnabled}
                                        onChange={(event: any) => { setCurrentObject({ ...currentObject, isQrCodeGroupSignInObjectLeaderForcedEnabled: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label={(t("myObjectSettings.enforceQRCodeForUnitCheckIn"))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{t("myObjectSettings.objectDisplay")} App</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("myObjectSettings.objectDisplay")}</FormLabel>
                                <RadioGroup
                                    row
                                    name="displayLastCleaningType"
                                    value={currentObject.objectDisplayType}
                                    onChange={(event) => {
                                        setCurrentObject({ ...currentObject, objectDisplayType: parseInt(event.target.value) as any })
                                    }}
                                >
                                    <FormControlLabel
                                        value={ObjectDisplayType.Standard}
                                        control={<Radio />}
                                        label={(t("myObjectSettings.nameAndAddress"))}
                                    />
                                    <FormControlLabel
                                        value={ObjectDisplayType.DescriptionCostLocation}
                                        control={<Radio />}
                                        label={(t("myObjectSettings.descriptionAndCostCenter"))}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.close")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={loading && <CircularProgress size={32}/>}
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {

                        setCount(selectedObjects.length);
                        setLoading(true);
                        for (let object of selectedObjects) {
                            object.objectDisplayType = currentObject.objectDisplayType;
                            object.isActivatedEnabled = currentObject.isActivatedEnabled;
                            object.isQrCodeSignInForcedEnabled = currentObject.isQrCodeSignInForcedEnabled;
                            object.isQrCodeSignOutForcedEnabled = currentObject.isQrCodeSignOutForcedEnabled;
                            object.isQrCodeGroupSignInObjectLeaderForcedEnabled = currentObject.isQrCodeGroupSignInObjectLeaderForcedEnabled;

                            const updated = await api.putUserObjectUnit(object, token, enqueueSnackbar);
                            setCount((prev)=>prev - 1);
                        }
                        setLoading(false);
                        onClose();
                    }}
                >
                    {loading ? `${count} ${t("myUnitsSettings.save")}` : t("myUnitsSettings.save")}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
