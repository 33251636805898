import { Grid, Typography } from "@mui/material";
import React, { Fragment, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { AggregateType, DataFilterType, PeriodChartFilterType, fillRestofChartData, generateChartData, generateSumChartData, getServiceData, APIDataAmountType } from "../../../../helpers/charts/base";
import { generateDoneByUserCleaningTasks, generateDoneByUserCleaningTasksSum, generateDoneByUserIncidentReports, generateDoneByUserIncidentReportsSum, generateMaterialConsumptionUserChartData, generateMaterialConsumptionUserChartDataSum, getAllMaterials, getAllUserItems } from "../../../../helpers/charts/userDataGenerator";
import { getEvaluationChoice, getGroupIdsForPrint } from "../../../../helpers/common";
import { getDefaultPeriod } from "../../../../helpers/date-management";
import useNoInitialEffect from "../../../../helpers/extensions";
import { getApiToken } from "../../../../helpers/storage-management";
import { getPortalGroups, getPortalGroupsByObjectIds, getPortalGroupsByServiceWorkerIds } from "../../../../helpers/tables/common";
import { EvaluationChoice } from "../../../../models/EvaluationChoice";
import { GroupLicenseTable } from "../../../../models/GroupLicenseTable";
import { GroupServiceMode } from "../../../../models/GroupServiceMode";
import { GroupSettings, LocationInformation } from "../../../../models/GroupSettings";
import { GroupTypePrintDisplay } from "../../../common/GroupTypePrintDisplay";
import { GlobalTimeChoice, showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { getUniqueValues } from "../../../core/TimeUserChoice";
import { GroupDetailsPrintTable } from "../../../tables/GroupDetailsPrintTable";
import { DrawerMenuItemDisplayType } from "../../ObjectMenuPointDrawer";
import { GlobalContext } from "../../PortalPage";
import { EvalApiType } from "./Services";
import { DoneByUserCleaningTaskDashboard } from "./user/DoneByUserCleaningTasks";
import { DoneByUserIncidentReportDashboard } from "./user/DoneByUserIncidentReport";
import { DoneByUserMaterialConsumptionDashboard } from "./user/DoneByUserMaterialConsumption";
import { UserAccount, UserMenuVisibility } from "../../../../models/UserAccount";



export const ServiceWorkerServicesDashboard = ({ serviceDisplayType, groupIds, drawerDisplayType }: { serviceDisplayType: EvalApiType, groupIds?: any, drawerDisplayType: DrawerMenuItemDisplayType }) => {

    const { apiDataAmountType, reloadDataFromApi, setIsPreFilterDialogVisible, userAccount }: { apiDataAmountType: APIDataAmountType, reloadDataFromApi: number, setIsPreFilterDialogVisible: any, userAccount: UserAccount } = React.useContext(GlobalContext);



    const { setApiDataLoading }: { setApiDataLoading: any } = React.useContext(GlobalContext);

    const [groupsServiceMode, setGroupsServiceMode] = React.useState<GroupServiceMode[]>([]);
    const [serviceWorkerServiceModescleaningTaskUserData, setserviceWorkerServiceModesCleaningTaskUserData] = React.useState<any>([]);
    const [serviceWorkerServiceModescleaningTaskUserAllData, setserviceWorkerServiceModesCleaningTaskUserAllData] = React.useState<any>([]);
    const [serviceWorkerServiceModescleaningTaskUserDataFields, setserviceWorkerServiceModesCleaningTaskUserDataFields] = React.useState<any>([]);
    const [serviceWorkerServiceModesincidentReportUserData, setserviceWorkerServiceModesIncidentReportUserData] = React.useState<any>([]);
    const [serviceWorkerServiceModesincidentReportUserAllData, setserviceWorkerServiceModesIncidentReportUserAllData] = React.useState<any>([]);
    const [serviceWorkerServiceModesincidentReportUserDataFields, setserviceWorkerServiceModesIncidentReportUserDataFields] = React.useState<any>([]);
    const [serviceWorkerServiceModesmaterialUserData, setserviceWorkerServiceModesMaterialUserData] = React.useState<any>([]);
    const [serviceWorkerServiceModesmaterialUserAllData, setserviceWorkerServiceModesMaterialUserAllData] = React.useState<any>([]);
    const [serviceWorkerServiceModesmaterialUserDataFields, setserviceWorkerServiceModesMaterialUserDataFields] = React.useState<any>([]);
    const [serviceWorkerServiceModescurrentCleanerTasks, setserviceWorkerServiceModesCurrentCleanerTasks] = React.useState("");
    const [serviceWorkerServiceModescurrentCleanerIncidents, setserviceWorkerServiceModesCurrentCleanerIncidents] = React.useState("");
    const [serviceWorkerServiceModescurrentCleanerMaterials, setserviceWorkerServiceModesCurrentCleanerMaterials] = React.useState("");
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const [groupsLicenseTableEntries, setGroupsLicenseTableEntries] = React.useState<GroupLicenseTable[]>([]);

    const childRefTaskDoneByUser: any = useRef();
    const childRefIncidentReportDoneByUser: any = useRef();
    const childRefMaterialByUser: any = useRef();

    const childRefTaskDoneByUserChart: any = useRef();
    const childRefIncidentReportDoneByUserChart: any = useRef();
    const childRefMaterialByUserChart: any = useRef();

    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();
    const [isPrintPage, setIsPrintPage] = React.useState(false);
    const refPrint: any = useRef();
    const [evaluationChoice, setEvaluationChoice] = React.useState<EvaluationChoice>(EvaluationChoice.All);
    const [groupsSettings, setGroupsSettings] = React.useState<GroupSettings[]>([]);
    const { selectedObjectId } = useParams<{ selectedObjectId: string }>();
    const { selectedServiceWorkerId } = useParams<{ selectedServiceWorkerId: string }>();

    const loadDataAsync = async () => {
        const token = getApiToken(history);
        if (!token) return;
        setApiDataLoading(true);
        setLoadingCompleted(false);
        setserviceWorkerServiceModesCleaningTaskUserData([]);
        setserviceWorkerServiceModesIncidentReportUserData([]);
        setserviceWorkerServiceModesMaterialUserData([]);

        if (serviceDisplayType == EvalApiType.Objects) {
            let groups = await getPortalGroupsByObjectIds(token, selectedObjectId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }
        else if (serviceDisplayType == EvalApiType.ServiceWorkers) {
            let groups = await getPortalGroupsByServiceWorkerIds(token, selectedServiceWorkerId, drawerDisplayType)
            groupIds = groups.map(x => x.id);
            setGroupsLicenseTableEntries(groups);
        }

        let servicesMode = await getServiceData(groupId, token, groupIds);

        await manageServiceUserData(
            servicesMode,
            setGroupsServiceMode,
            setserviceWorkerServiceModesCleaningTaskUserAllData,
            setserviceWorkerServiceModesCleaningTaskUserDataFields,
            setserviceWorkerServiceModesCleaningTaskUserData,
            setserviceWorkerServiceModesIncidentReportUserAllData,
            setserviceWorkerServiceModesIncidentReportUserDataFields,
            setserviceWorkerServiceModesIncidentReportUserData,
            setserviceWorkerServiceModesMaterialUserAllData,
            setserviceWorkerServiceModesMaterialUserDataFields,
            setserviceWorkerServiceModesMaterialUserData,
            setserviceWorkerServiceModesCurrentCleanerIncidents,
            setserviceWorkerServiceModesCurrentCleanerTasks,
            setserviceWorkerServiceModesCurrentCleanerMaterials,
            setLoadingCompleted,
            AggregateType.Time);


        setApiDataLoading(false);

        showGlobalDateData(updateEndDateFromParent, updateStartDateFromParent, updateChosenPastDaysFromParent);
    }

    React.useEffect(() => {
        setIsPreFilterDialogVisible(true);
    }, []);

    useNoInitialEffect(() => {
        loadDataAsync();
        setEvaluationChoice(getEvaluationChoice(groupId, groupIds));
    }, [apiDataAmountType, reloadDataFromApi]);

    React.useEffect(() => {
        if (isPrintPage) {
            (async () => {
                const token = getApiToken(history);
                if (groupsLicenseTableEntries.length < 1) {
                    let groupsPortal = await getPortalGroups(groupIds, token, groupId);
                    setGroupsLicenseTableEntries(groupsPortal);
                }
            })();
        }

    }, [isPrintPage]);

    const updateChosenPastDaysFromParent = (event: any) => {

        if (childRefTaskDoneByUser?.current)
            childRefTaskDoneByUser.current.updateChosenPastDays(event.target.value);

        if (childRefIncidentReportDoneByUser?.current)
            childRefIncidentReportDoneByUser.current.updateChosenPastDays(event.target.value);

        if (childRefMaterialByUser?.current)
            childRefMaterialByUser.current.updateChosenPastDays(event.target.value);
    }

    const updateEndDateFromParent = (event: any) => {

        if (childRefTaskDoneByUser?.current)
            childRefTaskDoneByUser.current.updateEndDateFromParent(event);

        if (childRefIncidentReportDoneByUser?.current)
            childRefIncidentReportDoneByUser.current.updateEndDateFromParent(event);

        if (childRefMaterialByUser?.current)
            childRefMaterialByUser.current.updateEndDateFromParent(event);
    }

    const updateStartDateFromParent = (event: any) => {

        if (childRefTaskDoneByUser?.current)
            childRefTaskDoneByUser.current.updateStartDateFromParent(event);

        if (childRefIncidentReportDoneByUser?.current)
            childRefIncidentReportDoneByUser.current.updateStartDateFromParent(event);

        if (childRefMaterialByUser?.current)
            childRefMaterialByUser.current.updateStartDateFromParent(event);
    }

    const updateDataFilterTypeFromParent = (event: any) => {

        if (childRefTaskDoneByUser?.current)
            childRefTaskDoneByUser.current.updateDataFilterTypeFromParent(event);

        if (childRefIncidentReportDoneByUser?.current)
            childRefIncidentReportDoneByUser.current.updateDataFilterTypeFromParent(event);

        if (childRefMaterialByUser?.current)
            childRefMaterialByUser.current.updateDataFilterTypeFromParent(event);
    }

    const buildDoneByUserCleaningTaskDashboard = () => {
        return (
            <DoneByUserCleaningTaskDashboard
                childRefChart={childRefTaskDoneByUserChart}
                childRef={childRefTaskDoneByUser}
                setCurrentCleaner={setserviceWorkerServiceModesCurrentCleanerTasks}
                currentCleaner={serviceWorkerServiceModescurrentCleanerTasks}
                setChartData={setserviceWorkerServiceModesCleaningTaskUserData}
                allData={serviceWorkerServiceModescleaningTaskUserAllData}
                data={serviceWorkerServiceModescleaningTaskUserData}
                dataField={serviceWorkerServiceModescleaningTaskUserDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildDoneByUserIncidentReportDashboard = () => {
        return (
            <DoneByUserIncidentReportDashboard
                childRefChart={childRefIncidentReportDoneByUserChart}
                childRef={childRefIncidentReportDoneByUser}
                setCurrentCleaner={setserviceWorkerServiceModesCurrentCleanerIncidents}
                currentCleaner={serviceWorkerServiceModescurrentCleanerIncidents}
                setChartData={setserviceWorkerServiceModesIncidentReportUserData}
                allData={serviceWorkerServiceModesincidentReportUserAllData}
                data={serviceWorkerServiceModesincidentReportUserData}
                dataField={serviceWorkerServiceModesincidentReportUserDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildDoneByUserMaterialConsumptionDashboard = () => {
        return (
            <DoneByUserMaterialConsumptionDashboard
                childRefChart={childRefMaterialByUserChart}
                childRef={childRefMaterialByUser}
                setCurrentCleaner={setserviceWorkerServiceModesCurrentCleanerMaterials}
                currentCleaner={serviceWorkerServiceModescurrentCleanerMaterials}
                setChartData={setserviceWorkerServiceModesMaterialUserData}
                allData={serviceWorkerServiceModesmaterialUserAllData}
                data={serviceWorkerServiceModesmaterialUserData}
                dataField={serviceWorkerServiceModesmaterialUserDataFields}
                loadingCompleted={loadingCompleted} />
        )
    }

    const buildPrintContent = () => {


        return (
            <div className="page-size" ref={refPrint}>
                <Grid container spacing={2}>
                    <GroupTypePrintDisplay title={"Servicekräfte Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                    <Grid item xs={12}>
                        <GlobalTimeChoice
                            isPrintPage={isPrintPage}
                            setIsPrintPage={setIsPrintPage}
                            pageRef={refPrint}
                            chartRefs={[childRefTaskDoneByUserChart, childRefIncidentReportDoneByUserChart, childRefMaterialByUserChart]}
                            updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                            updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                    </Grid>
                    <Grid item xs={12}>
                        {buildDoneByUserCleaningTaskDashboard()}
                    </Grid>
                </Grid>
                <div className="page-break"></div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {buildDoneByUserIncidentReportDashboard()}
                    </Grid>
                    <Grid item xs={12}>
                        {buildDoneByUserMaterialConsumptionDashboard()}
                    </Grid>
                </Grid>
                {(evaluationChoice == EvaluationChoice.Multiple || evaluationChoice == EvaluationChoice.All) &&
                    <Fragment>
                        <div className="page-break"></div>
                        <div>
                            <GroupTypePrintDisplay title={"Servicekräfte Bericht"} evaluationChoice={evaluationChoice} groupsSettings={groupsLicenseTableEntries} />
                            <GroupDetailsPrintTable groups={groupsLicenseTableEntries} />
                        </div>
                    </Fragment>
                }
            </div>
        );
    }

    const buildGridContent = () => {
        return (
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <GlobalTimeChoice
                        isPrintPage={isPrintPage}
                        setIsPrintPage={setIsPrintPage}
                        pageRef={refPrint}
                        chartRefs={[childRefTaskDoneByUserChart, childRefIncidentReportDoneByUserChart, childRefMaterialByUserChart]}
                        updateChosenPastDaysFromParent={updateChosenPastDaysFromParent} updateDataFilterTypeFromParent={updateDataFilterTypeFromParent}
                        updateEndDateFromParent={updateEndDateFromParent} updateStartDateFromParent={updateStartDateFromParent} />
                </Grid>
                <Grid item xs={12}>
                    {buildDoneByUserCleaningTaskDashboard()}
                </Grid>
                <Grid item xs={12}>
                    {buildDoneByUserIncidentReportDashboard()}
                </Grid>
                <Grid item xs={12}>
                    {buildDoneByUserMaterialConsumptionDashboard()}
                </Grid>
            </Grid>
        );
    }
    return isPrintPage ? buildPrintContent() : buildGridContent();
}

export const manageServiceUserData = async (temp: GroupServiceMode[],
    setGroupsServiceMode: any,
    setserviceWorkerServiceModesCleaningTaskUserAllData: any,
    setserviceWorkerServiceModesCleaningTaskUserDataFields: any,
    setserviceWorkerServiceModesCleaningTaskUserData: any,
    setserviceWorkerServiceModesIncidentReportUserAllData: any,
    setserviceWorkerServiceModesIncidentReportUserDataFields: any,
    setserviceWorkerServiceModesIncidentReportUserData: any,
    setserviceWorkerServiceModesMaterialUserAllData: any,
    setserviceWorkerServiceModesMaterialUserDataFields: any,
    setserviceWorkerServiceModesMaterialUserData: any,
    setserviceWorkerServiceModesCurrentCleanerIncidents: any,
    setserviceWorkerServiceModesCurrentCleanerTasks: any,
    setserviceWorkerServiceModesCurrentCleanerMaterials: any,
    setLoadingCompleted: any,
    aggregateType: AggregateType) => {
    setGroupsServiceMode(temp);

    if (AggregateType.Time === aggregateType) {
        let cleaningTaskUserAllTemp = generateDoneByUserCleaningTasks(temp);
        let cleaners = getUniqueValues(cleaningTaskUserAllTemp.map((el: any) => el.cleaner));
        let currentCleaner = cleaners[0];
        setserviceWorkerServiceModesCurrentCleanerTasks(currentCleaner);
        setserviceWorkerServiceModesCleaningTaskUserAllData(cleaningTaskUserAllTemp);
        let allUserItems = getAllUserItems(temp, 'cleaningTaskItems');
        let filteredUserData = filterAfterCleaner(cleaningTaskUserAllTemp, currentCleaner)
        let cleaningTaskUserTemp = generateChartData(filteredUserData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allUserItems);
        setserviceWorkerServiceModesCleaningTaskUserDataFields(allUserItems);
        setserviceWorkerServiceModesCleaningTaskUserData(cleaningTaskUserTemp);


        let incidentReportUserAllTemp = generateDoneByUserIncidentReports(temp);
        cleaners = getUniqueValues(incidentReportUserAllTemp.map((el: any) => el.cleaner));
        currentCleaner = cleaners[0];
        setserviceWorkerServiceModesCurrentCleanerIncidents(currentCleaner);
        setserviceWorkerServiceModesIncidentReportUserAllData(incidentReportUserAllTemp);
        let allUserItemsIncident = getAllUserItems(temp, 'incidentReportItems');
        filteredUserData = filterAfterCleaner(incidentReportUserAllTemp, currentCleaner)
        let incidentReportUserTemp = generateChartData(filteredUserData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allUserItemsIncident);
        setserviceWorkerServiceModesIncidentReportUserDataFields(allUserItemsIncident);
        setserviceWorkerServiceModesIncidentReportUserData(incidentReportUserTemp);

        let materialUserAllTemp = generateMaterialConsumptionUserChartData(temp);
        cleaners = getUniqueValues(materialUserAllTemp.map((el: any) => el.cleaner));
        currentCleaner = cleaners[0];
        setserviceWorkerServiceModesCurrentCleanerMaterials(currentCleaner);
        setserviceWorkerServiceModesMaterialUserAllData(materialUserAllTemp);
        let allMaterials = getAllMaterials(temp);
        filteredUserData = filterAfterCleaner(materialUserAllTemp, currentCleaner)
        let materialUserTemp = generateChartData(filteredUserData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allMaterials);
        setserviceWorkerServiceModesMaterialUserDataFields(allMaterials);
        setserviceWorkerServiceModesMaterialUserData(materialUserTemp);
    }
    else if (AggregateType.Sum == aggregateType) {

        let cleaningTaskUserAllTemp = generateDoneByUserCleaningTasksSum(temp);
        let cleaners = getUniqueValues(cleaningTaskUserAllTemp.map((el: any) => el.cleaner));
        let currentCleaner = cleaners[0];
        setserviceWorkerServiceModesCurrentCleanerTasks(currentCleaner);
        setserviceWorkerServiceModesCleaningTaskUserAllData(cleaningTaskUserAllTemp);
        let allUserItems = getAllUserItems(temp, 'cleaningTaskItems');
        let filteredUserData = filterAfterCleaner(cleaningTaskUserAllTemp, currentCleaner)

        let cleaningTaskUserTemp = generateSumChartData(filteredUserData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allUserItems);
        setserviceWorkerServiceModesCleaningTaskUserDataFields(allUserItems);
        setserviceWorkerServiceModesCleaningTaskUserData(cleaningTaskUserTemp);



        let incidentReportUserAllTemp = generateDoneByUserIncidentReportsSum(temp);
        cleaners = getUniqueValues(incidentReportUserAllTemp.map((el: any) => el.cleaner));
        currentCleaner = cleaners[0];
        setserviceWorkerServiceModesCurrentCleanerIncidents(currentCleaner);
        setserviceWorkerServiceModesIncidentReportUserAllData(incidentReportUserAllTemp);
        let allUserItemsIncident = getAllUserItems(temp, 'incidentReportItems');
        filteredUserData = filterAfterCleaner(incidentReportUserAllTemp, currentCleaner)
        let incidentReportUserTemp = generateSumChartData(filteredUserData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allUserItemsIncident);
        setserviceWorkerServiceModesIncidentReportUserDataFields(allUserItemsIncident);
        setserviceWorkerServiceModesIncidentReportUserData(incidentReportUserTemp);


        let materialUserAllTemp = generateMaterialConsumptionUserChartDataSum(temp);
        cleaners = getUniqueValues(materialUserAllTemp.map((el: any) => el.cleaner));
        currentCleaner = cleaners[0];
        setserviceWorkerServiceModesCurrentCleanerMaterials(currentCleaner);
        setserviceWorkerServiceModesMaterialUserAllData(materialUserAllTemp);
        let allMaterials = getAllMaterials(temp);
        filteredUserData = filterAfterCleaner(materialUserAllTemp, currentCleaner)
        let materialUserTemp = generateSumChartData(filteredUserData, getDefaultPeriod(), DataFilterType.StartEnd, null, null, allMaterials);
        setserviceWorkerServiceModesMaterialUserDataFields(allMaterials);
        setserviceWorkerServiceModesMaterialUserData(materialUserTemp);
    } else {

    }

    setLoadingCompleted(true);
}

export const filterAfterCleaner = (data: any, cleaner: any) => {
    return data.filter((el: any) => {
        return el.cleaner === cleaner
    });
}