import { CircularProgress, Divider, Grid, Link, Paper, Typography } from "@mui/material";
import React from "react";
import { api } from "../../../api";
import { getApiToken } from "../../../helpers/storage-management";
import { UserGroupSettingsTemplate } from "../../../models/UserGroupSettingsTemplate";
import { GroupSettingsTemplateTable } from "../../tables/GroupSettingsTemplate";
import { useHistory } from "react-router-dom";
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';

export const SettingsPage = () => {

    const [userGroupSettingsTemplate, setUserGroupSettingsTemplate] = React.useState<UserGroupSettingsTemplate[]>([]);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [token, setToken] = React.useState("");
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        (async () => {

            const tempToken = getApiToken(history);
            if (!tempToken) return;
            setToken(tempToken);

            await loadData(tempToken);

            const tempGroups = await api.getAllGroups(tempToken);
            setGroups(tempGroups);


        })();
    }, []);

    const loadData = async (token: string) => {
        setLoading(true);
        const temp = await api.getGroupSettingsTemplateByUser(token);
        setUserGroupSettingsTemplate(temp);
        setLoading(false);
    }
    return (
        <Grid
            container
            direction="column"
            style={{ width: '85vw' }}>
            <Grid item xs={12}>
                <Paper style={{ borderRadius: 0 }} className="Grid-Header-Tall">
                    <Typography variant="h3" component="h3">Settings Vorlagen</Typography>
                </Paper>
            </Grid >
            <Grid item xs={12}>
                <Paper style={{ borderRadius: 0 }}>

                    {loading ?
                        <CircularProgress size={64} />
                        :
                        <GroupSettingsTemplateTable
                            loadData={loadData}
                            userGroupSettings={userGroupSettingsTemplate}
                            setUserGroupSettings={setUserGroupSettingsTemplate}
                            token={token}
                            groups={groups} />
                    }
                </Paper>
            </Grid>
        </Grid >


    );
}