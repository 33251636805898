import React from 'react';
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NavigateNext, Save, SwitchCamera } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { BuyerType, GroupLicense, PurchaseReasonType } from '../../../models/GroupLicense';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { UserAccount } from '../../../models/UserAccount';
import { UserSingleSelectionForLicenseDialog } from './UserSingleSelectionForLicenseDialog';
import { formatEuropeanDate } from '../../../helpers/date-management';
import { useTranslation } from 'react-i18next';

export const GroupLicenseEditingDialog = ({ visible, setVisible, onSave, onRefresh, currentGroupLicense, users }
    : { visible: boolean, setVisible: any, onSave: any, onRefresh: any, currentGroupLicense: GroupLicense, users: UserAccount[] }) => {

    const classes = usePortalAccountStyles();

    const [loading, setLoading] = React.useState(false);
    const [isBuyerSelectionForLicenseVisible, setIsBuyerSelectionForLicenseVisible] = React.useState(false);

    const [currentSelectedGroupLicenseCopy, setCurrentSelectedGroupLicenseCopy] = React.useState<GroupLicense>();

    const onClose = () => {
        setVisible(false);
    }
    const {t} = useTranslation();



    const history = useHistory();

    React.useEffect(() => {
        if (visible) setCurrentSelectedGroupLicenseCopy({ ...currentGroupLicense });

    }, [visible])


    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="md">
            <DefaultDialogTitle
                title={`Lizenz bearbeiten`}
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="column" spacing={3} item>
                        <Grid item>
                            <Typography component="h4" variant="h4">Details der Lizenz mit Kaufdatum: {currentSelectedGroupLicenseCopy && formatEuropeanDate(currentSelectedGroupLicenseCopy?.purchaseTimestamp)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' color={"primary"} fontWeight="bold">Aktueller Käufer: {(()=>{
                                let user = users.find(x=>x.id == currentSelectedGroupLicenseCopy?.buyerUserId);
                                return `${user?.firstname} ${user?.lastname} ${user?.company}`
                            })()}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={async () => {
                                    setIsBuyerSelectionForLicenseVisible(true);
                                }}
                            >
                                Wähle neuen Käufer
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Käufertyp</FormLabel>
                                <RadioGroup
                                    row
                                    name="sensorItems"
                                    value={currentSelectedGroupLicenseCopy?.buyerType}
                                    onChange={(event: any) => {
                                        if (!currentSelectedGroupLicenseCopy) return;
                                        currentSelectedGroupLicenseCopy.buyerType = JSON.parse(event.target.value);
                                        setCurrentSelectedGroupLicenseCopy({ ...currentSelectedGroupLicenseCopy });
                                    }}
                                >
                                    <FormControlLabel value={BuyerType.FromAdmin} control={<Radio />} label="Admin" />
                                    <FormControlLabel value={BuyerType.FromSeller} control={<Radio />} label="Verkäufer" />
                                    <FormControlLabel value={BuyerType.Normal} control={<Radio />} label="Normal selbst gekauft" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Kaufgrundtyp</FormLabel>
                                <RadioGroup
                                    row
                                    name="sensorItems"
                                    value={currentSelectedGroupLicenseCopy?.purchaseReasonType}
                                    onChange={(event: any) => {
                                        if (!currentSelectedGroupLicenseCopy) return;
                                        currentSelectedGroupLicenseCopy.purchaseReasonType = JSON.parse(event.target.value);
                                        setCurrentSelectedGroupLicenseCopy({ ...currentSelectedGroupLicenseCopy });
                                    }}
                                >
                                    <FormControlLabel value={PurchaseReasonType.Promotion} control={<Radio />} label="Geschäftlich" />
                                    <FormControlLabel value={PurchaseReasonType.Gift} control={<Radio />} label="Geschenkt" />
                                    <FormControlLabel value={PurchaseReasonType.Borrowed} control={<Radio />} label="Promotion" />
                                    <FormControlLabel value={PurchaseReasonType.PersonalNeed} control={<Radio />} label="Eigenbedarf" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={loading ? <CircularProgress size={32} /> : <Save />}
                    disabled={loading}
                    onClick={async () => {

                        setLoading(true);
                        await onSave(currentSelectedGroupLicenseCopy);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button>
            </DialogActions>
            <UserSingleSelectionForLicenseDialog
                onSave={(selectedUser: UserAccount) => {
                    if (!currentSelectedGroupLicenseCopy) return;
                    currentSelectedGroupLicenseCopy.buyerUserId = selectedUser.id;
                    setCurrentSelectedGroupLicenseCopy({ ...currentSelectedGroupLicenseCopy });
                    setIsBuyerSelectionForLicenseVisible(false);
                }}
                open={isBuyerSelectionForLicenseVisible} setOpen={setIsBuyerSelectionForLicenseVisible} users={users} user={users.find(x => x.id == currentSelectedGroupLicenseCopy?.buyerUserId)} />
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
