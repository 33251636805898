import { Chart, Series, CommonSeriesSettings, Label, Legend, Export, Tooltip, ValueAxis } from 'devextreme-react/chart';
import withStyles from '@mui/styles/withStyles';
import { chartHeight, NoDataChartLoader } from './Base';

const demoStyles: any = () => ({
    chart: {
        paddingRight: '20px',
    },
    title: {
        whiteSpace: 'pre',
    },
});

export const StackDraw = (props: any) => {
    const { classes, data, dataField1, dataField2, text, loadingCompleted } = props;

    if (data.length === 0)
        return <NoDataChartLoader title={text} data={data} loadingCompleted={loadingCompleted} />;

    return (
        <Chart
            ref={props.childRefChart}
            title={text}
            dataSource={data}
            id="chart"
            rotated={true}
            style={{ height: chartHeight }}
        >
            <CommonSeriesSettings
                argumentField="dateDisplay"
                type="stackedBar"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
            >
            </CommonSeriesSettings>
            <Series
                name={dataField2}
                valueField={dataField2}
                argumentField="dateDisplay"
                color="#a8352f"
            />
            <Series
                name={dataField1}
                valueField={dataField1}
                argumentField="dateDisplay"
                color="#3F7FBF"
            />

            <ValueAxis allowDecimals={false}>
                <Label customizeText={customizeLabel} />
            </ValueAxis>
            <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
            <Export enabled={true} />
            <Tooltip
                enabled={true}
                customizeTooltip={customizeTooltip}
                location="edge"
            />
        </Chart>
    );
}

const customizeTooltip = (e: any) => {
    return { text: Math.abs(e.valueText) };
}

const customizeLabel = (e: any) => {
    return `${Math.abs(e.value)}`;
}

export const RotatedBarChart = withStyles(demoStyles, { name: 'Demo' })(StackDraw);