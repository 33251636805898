import axios from 'axios';
import { headers } from '../api';
import { getUserIdFromStorage } from '../helpers/common';
import { Group } from '../models/Group';


export const activateLoginForGroupByAdmin = async (groupId: string, token: string, enqueueSnackbar: any): Promise<Group> => {
    const userId = getUserIdFromStorage();
    return await axios
        .patch(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/activate-login`,
            { },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Login Aktivierung für Einheit erfolgreich", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Login Aktivierung für Einheit fehlgeschlagen", { variant: 'error' });
        });
}

export const deactivateLoginForGroupByAdmin = async (groupId: string, token: string, enqueueSnackbar: any): Promise<Group> => {
    const userId = getUserIdFromStorage();
    return await axios
        .patch(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/deactivate-login`,
            { },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Login Deaktivierung für Einheit erfolgreich", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Login Deaktivierung für Einheit fehlgeschlagen", { variant: 'error' });
        });
}

export const setDeviceValidUntilAdmin = async (date: Date,groupId: string, token: string, enqueueSnackbar: any): Promise<Group> => {
    const userId = getUserIdFromStorage();
    return await axios
        .patch(`${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/set-valid-until`,
            { DateTimeParam: date },
            { headers: { ...headers, 'Authorization': `Bearer ${token}` } })
        .then(res => {
            enqueueSnackbar("Gültig Device Lizenz der Einheit erfolgreich gespeichert", { variant: 'success' });
            return res.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error, null, 2));
            enqueueSnackbar("Gültig Device Lizenz der Einheit error", { variant: 'error' });
        });
}