import React from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Addchart, Edit, Save, SupervisedUserCircle } from '@mui/icons-material';
import { GridStackCustom, GridStackCustomItem } from '../../../../../../models/GridStackCustom';
import { usePortalAccountStyles } from '../../../../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { StringHelper } from '../../../../../../helpers/string-helper';
import { DefaultDialogTitle } from '../../../../../dialogs/DefaultDialogTitle';
import { GridStackCustomItemsDataTable } from '../GridStackCustomItemsDataTable';
import { GridStackCustomItemManagementPageEditingDialog } from './GridStackCustomItemManagementPageEditingDialog';
import { ConfirmationDialog } from '../../../../../dialogs/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

export const GridStackCustomManagementPageEditingDialog = ({ visible, setVisible, gridStackCustom, onSave }
    : { visible: boolean, setVisible: any, gridStackCustom?: GridStackCustom | undefined, onSave: any }) => {

    const classes = usePortalAccountStyles();

    const [currentGridStackCustom, setCurrentGridStackCustom] = React.useState<GridStackCustom>(new GridStackCustom());
    const [currentGridStackCustomItem, setCurrentGridStackCustomItem] = React.useState<GridStackCustomItem>();
    const [addGridStackCustomItemDialogVisible, setAddGridStackCustomItemDialogVisible] = React.useState(false);
    const [editGridStackCustomItemDialogVisible, setEditGridStackCustomItemDialogVisible] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    const onClose = () => {
        setCurrentGridStackCustom(new GridStackCustom());
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

        (async () => {
            if (gridStackCustom) {
                setCurrentGridStackCustom(gridStackCustom);
            } else {
                setCurrentGridStackCustom(new GridStackCustom());
            }

        })();

    }, [visible, gridStackCustom]);

    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title="Custom Dashboard bearbeiten"
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="row" spacing={3} item>
                        <Grid item xs={12}>
                            <Typography component="h4" variant="h4">Details</Typography>
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Name"
                                variant="filled"
                                value={currentGridStackCustom.name}
                                onChange={(event: any) => { setCurrentGridStackCustom({ ...currentGridStackCustom, name: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={t("dashboardManagement.elementHeight")}
                                variant="filled"
                                type={"number"}
                                value={currentGridStackCustom.cellHeight}
                                onChange={(event: any) => { setCurrentGridStackCustom({ ...currentGridStackCustom, cellHeight: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={t("dashboardManagement.elementSpacing")}
                                variant="filled"
                                type={"number"}
                                value={currentGridStackCustom.columnCount}
                                onChange={(event: any) => { setCurrentGridStackCustom({ ...currentGridStackCustom, columnCount: event.target.value }) }}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentGridStackCustom.isPageCountEnabled}
                                        onChange={(event: any) => {
                                            setCurrentGridStackCustom({ ...currentGridStackCustom, isPageCountEnabled: event.target.checked });
                                        }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("dashboardManagement.showPageBreak")}
                            />
                        </Grid>
                        {currentGridStackCustom.isPageCountEnabled &&
                            <Grid item>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label="Seitenanzahl"
                                    variant="filled"
                                    type={"number"}
                                    value={currentGridStackCustom.pageCount}
                                    onChange={(event: any) => { setCurrentGridStackCustom({ ...currentGridStackCustom, pageCount: event.target.value }) }}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Addchart />}
                                disabled={loading}
                                onClick={async () => {
                                    setAddGridStackCustomItemDialogVisible(true);
                                }}
                            >
                                {t("dashboardManagement.addElement")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <GridStackCustomItemsDataTable rows={currentGridStackCustom.items} onEdit={(row: GridStackCustomItem) => {
                                setCurrentGridStackCustomItem(row);
                                setEditGridStackCustomItemDialogVisible(true);
                            }} onDelete={(row: GridStackCustomItem) => {
                                setCurrentGridStackCustomItem(row);
                                setDeleteConfirmationDialogVisible(true);
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {

                        setLoading(true);
                        await onSave(currentGridStackCustom);
                        setLoading(false);

                        onClose();
                    }}
                >
                    {t("myUnitsSettings.save")}
                </Button>
            </DialogActions>
            <GridStackCustomItemManagementPageEditingDialog visible={editGridStackCustomItemDialogVisible} gridStackCustomItem={currentGridStackCustomItem} setVisible={setEditGridStackCustomItemDialogVisible}
                onSave={(row: GridStackCustomItem) => {
                    let countIncluded = row.id == currentGridStackCustomItem?.id ? 1 : 0
                    if (currentGridStackCustom.items.filter(x => x.id == row.id).length > countIncluded) {
                        enqueueSnackbar(t("dashboardManagement.thisNameAlreadyExistsCoudNotEditDiagram"), { variant: 'error' })
                    } else {
                        setCurrentGridStackCustom({ ...currentGridStackCustom, items: currentGridStackCustom.items.map(x => x.id == currentGridStackCustomItem?.id ? row : x) })
                    }
                }} />
            <GridStackCustomItemManagementPageEditingDialog gridStackCustomItem={undefined} visible={addGridStackCustomItemDialogVisible} setVisible={setAddGridStackCustomItemDialogVisible} onSave={(row: GridStackCustomItem) => {
                if (currentGridStackCustom.items.some(x => x.id == row.id)) {
                    enqueueSnackbar(t("dashboardManagement.thisNameAlreadyExistsCoudNotEditDiagram"), { variant: 'error' })
                } else {
                    currentGridStackCustom.items = [...currentGridStackCustom.items, row];
                    setCurrentGridStackCustom({ ...currentGridStackCustom })
                }

            }} />

            {
                currentGridStackCustomItem &&
                <ConfirmationDialog
                    title={`Diagramm ${currentGridStackCustomItem.id} löschen ?`}
                    visible={deleteConfirmationDialogVisible}
                    onClose={() => {
                        setDeleteConfirmationDialogVisible(false);
                    }}
                    onConfirmed={async () => {
                        setCurrentGridStackCustom({ ...currentGridStackCustom, items: currentGridStackCustom.items.filter(x => x.id != currentGridStackCustomItem.id) })
                        setDeleteConfirmationDialogVisible(false);
                        enqueueSnackbar("Diagramm entfernt", { variant: 'success' })
                    }}
                />
            }

        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);


