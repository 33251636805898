import axios from "axios";
import { headers } from "../api";
import { Product } from '../models/Product';

export const AddProductAsync = async (product: Product, token: string, enqueueSnackbar: any): Promise<Product> => {

    return await axios.post(
        `${process.env.REACT_APP_BASIC_URI}/products`,
        { ...product },
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        enqueueSnackbar("Produkt wurde erfolgreich hinzugefügt", { variant: 'success' });
        return res.data;
    }).catch((error) => {
        enqueueSnackbar("Bei dem Hinzufügen ist ein Fehler aufgetreten", { variant: 'error' });
        return null;
    });
};
