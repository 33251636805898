import { useImageHoverOverlayStyles } from "../../styles/overlay";
import { IconButtonWithLabel } from "../buttons/IconButtonWithLabel";
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';
import { AssignmentReturn, RemoveCircle } from "@mui/icons-material";

export const GroupSideBarImagesAssignmentDialogImageHoverOverlay = ({ onOpenButtonClick, onAssignmentButtonClick, onRemoveAssignmentButtonClick }
    : { onOpenButtonClick: any, onAssignmentButtonClick: any, onRemoveAssignmentButtonClick: any }) => {

    const overlayStyles = useImageHoverOverlayStyles();

    return (
        <div className={overlayStyles.root}>
            <IconButtonWithLabel
                icon={<OpenInNewIcon fontSize="large" aria-label="view" />}
                label="Anzeigen"
                onClick={onOpenButtonClick} />

            <IconButtonWithLabel
                icon={<AssignmentReturn fontSize="large" aria-label="view" />}
                label="Zuweisen" onClick={onAssignmentButtonClick}
            />
            <IconButtonWithLabel
                icon={<RemoveCircle fontSize="large" aria-label="view" />}
                label="Wegweisen" onClick={onRemoveAssignmentButtonClick}
            />
        </div>
    );
}