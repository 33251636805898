import { GroupServiceModeIncidenName, GroupServiceModeIncident, GroupServiceModeIncidentPhoto, GroupServiceModeMaterialConsumption } from "./GroupServiceMode";
import { MaterialConsumption, Sensor } from "./GroupSettings";

export class GroupServiceModeServices {
    public text!: string;
    public amount!:  number;
    public constructor (text: string, amount: number) {
        this.text = text;
        this.amount = amount;
    }
}

export class GroupServiceModeIncidentWithPhoto {
    public reporterName: string = "";
    public incidentText: string = "";
    public incidentNames !: GroupServiceModeIncidenName[];
    public photos : GroupServiceModeIncidentPhoto[] = [];
    public amount !: number;
    constructor(reporterName: string, incidentText: string) {
        this.reporterName = reporterName;
        this.incidentText = incidentText;
        this.amount = 1;
        this.photos = [];
    }
}

export class GroupServiceModeCountTable {
    public id!: string;
    public systemETag!: string | null;
    public systemTimestamp!: string;
    public groupId!: string;
    public notificationName!: string;
    public serviceSignInCount!: number;
    public servicePartlyDoneCount!: number;
    public serviceCompletelyDoneCount!: number;
    public serviceMissedCount!: number;
    public materials : GroupServiceModeMaterialConsumption[] = [];
    public services : GroupServiceModeServices[] = [];
    public incidents: GroupServiceModeIncidentWithPhoto[] =  [];
    
    public constructor (groupId: string, serviceCount: number, serviceMissedCount: number, serviceCompletelyDoneCount: number, servicePartlyDoneCount: number, materials : GroupServiceModeMaterialConsumption[], services : GroupServiceModeServices[], incidents: GroupServiceModeIncidentWithPhoto[]) {
        this.systemETag = null;
        this.groupId = groupId;
        this.serviceSignInCount = serviceCount;
        this.serviceMissedCount = serviceMissedCount;
        this.serviceCompletelyDoneCount = serviceCompletelyDoneCount;
        this.servicePartlyDoneCount = servicePartlyDoneCount;
        this.materials = materials;
        this.services = services;
        this.incidents = incidents;
    }

    static Columns = {
        groupId: 'groupId',
        groupType: 'groupType',
        objectName:'objectName',
        levelName: 'levelName',
        roomName: 'roomName',
        toiletType: 'toiletType',
        userPropertyName:'userPropertyName',
        serviceCount: "serviceCount",
        sensors: "sensors",
        lastOnline: "lastOnline",
        serviceMissedCount: "serviceMissedCount",
        serviceCompletelyDoneCount: "serviceCompletelyDoneCount",
        servicePartlyDoneCount: "servicePartlyDoneCount",
        lastOnlineSetting: "lastOnlineSetting",
        nativeApplicationVersion: "nativeApplicationVersion",
        downloadedFilename: "downloadedFilename",
        buildVersion: "buildVersion",
        materials: "materials",
        incidents: "incidents",
        services: "services"
    }
}
