import React, { useContext } from 'react';
import { AddTask, AddToQueue, Adjust, AllInclusive, Apartment, Architecture, Category, CloudSync, ContentPaste, CopyAll, EventNote, ExpandLess, ExpandMore, Folder, GridOn, GroupAdd, LocalShipping, PeopleOutlineRounded, Settings } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { GlobalContext } from './PortalPage';
import WindowIcon from '@mui/icons-material/Window';
import { UserType } from '../../models/UserType';
import { drawerLightBlue } from '../../styles/colors';
import { useTranslation } from 'react-i18next';

export const SettingsDrawer = ({ pathname, user }: { pathname: string, user: any }) => {

    const classes = useStyles();
    const theme = useTheme();

    const [openControlCenter, setopenControlCenter] = React.useState(false);
    const [openControlSpecificDrawerMenu, setopenControlSpecificDrawerMenu] = React.useState(false);
    const [openMyTemplatesDrawerMenu, setOpenMyTemplatesDrawerMenu] = React.useState(false);
    const {t} = useTranslation();
    const {
        openDrawerSettings,
        setOpenDrawerSettings,
    }: {

        openDrawerSettings: boolean,
        setOpenDrawerSettings: any,
    } = useContext(GlobalContext);


    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <ListItem
                button
                onClick={() => setOpenDrawerSettings(!openDrawerSettings)}
                style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
            >
                <ListItemIcon className="white-font">
                    {openDrawerSettings ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
                <Typography variant={"h5"} >{t("drawerList.settings")}</Typography>

            </ListItem>
            <Collapse in={openDrawerSettings} timeout="auto" unmountOnExit>


                <List component="nav">

                    <ListItemButton style={{ backgroundColor: drawerLightBlue }} onClick={() => { setopenControlCenter(prev => !prev) }}>
                        <ListItemIcon>
                            <Adjust />
                        </ListItemIcon>
                        <ListItemText primary={t("drawerList.centralControl")} />
                        {openControlCenter ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openControlCenter} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/gesamtverwaltung"
                                selected={pathname === "/portal/gesamtverwaltung"}
                            >
                                <ListItemIcon>
                                    <AllInclusive />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.assignmentTable")} />
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/eingabehilfeapp"
                                selected={pathname === "/portal/eingabehilfeapp"}
                            >
                                <ListItemIcon>
                                    <AddTask />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.basicSetup")} />
                            </ListItemButton>
                        </List>
                    </Collapse>



                </List>


                <List component="nav">

                    <ListItemButton style={{ backgroundColor: drawerLightBlue }} onClick={() => { setopenControlSpecificDrawerMenu(prev => !prev) }}>
                        <ListItemIcon>
                            <Architecture />
                        </ListItemIcon>
                        <ListItemText primary={t("drawerList.specificControl")} />
                        {openControlSpecificDrawerMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openControlSpecificDrawerMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(user?.userType == UserType.Owner || user?.userType == UserType.Admin || user?.userType == UserType.Manager) &&
                                <ListItemButton
                                    className={classes.nested}
                                    component={Link}
                                    to="/portal/manager-customer"
                                    selected={pathname === "/portal/manager-customer"}
                                >
                                    <ListItemIcon>
                                        <PeopleOutlineRounded />
                                    </ListItemIcon>
                                    <ListItemText primary={t("drawerList.usermanagementPortal")} />
                                </ListItemButton>
                            }
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/objekte"
                                selected={pathname === "/portal/objekte"}
                            >
                                <ListItemIcon>
                                    <Apartment />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.objects")} />
                            </ListItemButton>
                            <ListItemButton
                                component={Link}
                                to={`/portal/object/all/shared-folders`}
                                selected={pathname === `/portal/object/all/shared-folders`}
                                className={classes.nested}
                            >
                                <ListItemIcon>
                                    <Folder />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.objectsData")} />
                                <ListItemIcon>
                                    <CloudSync />
                                </ListItemIcon>
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/servicekräfte"
                                selected={pathname === "/portal/servicekräfte"}
                            >
                                <ListItemIcon>
                                    <GroupAdd />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.serviceWorkers")} />
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/license-einheit-management"
                                selected={pathname === "/portal/license-einheit-management"}
                            >
                                <ListItemIcon>
                                    <AddToQueue />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.addNewUnit")} />
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/einheiten/settings"
                                selected={pathname === "/portal/einheiten/settings"}
                            >
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.settingsEdit")} />
                            </ListItemButton>
                        </List>
                    </Collapse>



                </List>


                <List component="nav">

                    <ListItemButton style={{ backgroundColor: drawerLightBlue }} onClick={() => { setOpenMyTemplatesDrawerMenu(prev => !prev) }}>
                        <ListItemIcon>
                            <ContentPaste />
                        </ListItemIcon>
                        <ListItemText primary={t("drawerList.myTemplates")} />
                        {openMyTemplatesDrawerMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openMyTemplatesDrawerMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/merkmale"
                                selected={pathname === "/portal/merkmale"}
                            >
                                <ListItemIcon>
                                    <WindowIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.propertyTags")} />
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/standarditems"
                                selected={pathname === "/portal/standarditems"}
                            >
                                <ListItemIcon>
                                    <Category />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.labelsService")} />
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/materials"
                                selected={pathname === "/portal/materials"}
                            >
                                <ListItemIcon>
                                    <LocalShipping />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.materialEconomy")} />
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/interval"
                                selected={pathname === "/portal/interval"}
                            >
                                <ListItemIcon>
                                    <EventNote />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.calendarProInterval")} />
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/settings"
                                selected={pathname === "/portal/settings"}
                            >
                                <ListItemIcon>
                                    <CopyAll />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.templateSettings")} />
                            </ListItemButton>
                            <ListItemButton
                                className={classes.nested}
                                component={Link}
                                to="/portal/dashboardverwaltung"
                                selected={pathname === "/portal/dashboardverwaltung"}
                            >
                                <ListItemIcon>
                                    <GridOn />
                                </ListItemIcon>
                                <ListItemText primary={t("drawerList.myReportsDashboard")} />
                            </ListItemButton>
                        </List>
                    </Collapse>



                </List>




            </Collapse>
        </List>

    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    icon: {
        color: theme.palette.primary.contrastText
    }
}));