import React, { useCallback } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { Button, IconButton, Tooltip } from '@mui/material';
import { Edit, Delete, Visibility, VisibilityOff, Done, Shop, PhotoAlbum } from '@mui/icons-material';
import { IntervalControl } from '../../../../models/IntervalControl';
import { UserAccount } from '../../../../models/UserAccount';
import { CheckboxCell } from '../../../tables/ServiceModeTable';
import { errorColor } from '../../../../styles/colors';
import { CleaningOperation, GroupSettingsIntervalControl, GroupSettingsIntervalType } from '../../../../models/GroupSettings';
import { formatEuropeanDateTime } from '../../../../helpers/date-management';
import { buildCellStringArray } from '../../../common/AccordionForStringArray';
import { saveExcelFile } from '../../../../helpers/tables/common';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { useTranslation } from 'react-i18next';

export const IntervalControlDataTable = ({ rows, onEdit, onDelete, selection, setSelection, cleaningOperations = [] }
    : { rows: IntervalControl[], onEdit?: (row: IntervalControl) => Promise<any>, onDelete?: (row: IntervalControl) => any , selection: any[], setSelection: any, cleaningOperations? : CleaningOperation[]}) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);
    const {t} = useTranslation();

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'KalenderPro');
        });
    };
    const exporterRef: any = React.useRef(null);

    
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);


    const Cell = (props: any) => {
        const { column, row }: { column: any, row: IntervalControl } = props;
        if (column.name === IntervalControl.Columns.entry) {
            let names = [];
            switch (row.interval.type) {
                case GroupSettingsIntervalType.Interval:
                    return <Table.Cell {...props} >
                        {`Start: ${row.interval.startTime} | Ende: ${row.interval.endTime} | Wiederholung: ${row.interval.repeatDuration} | Reaktionszeit: ${row.interval.deviationTime}`}
                    </Table.Cell>;
                case GroupSettingsIntervalType.Specific:
                    names = row.interval.specificTimes.map(x => `Zeitpunkt: ${x.specificTime} | Reaktionszeit: ${x.deviationTime}`);
                    return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} Einträge`);
                    break;
                case GroupSettingsIntervalType.Calendar:
                    names = row.interval.calendarEntries.map(x => `Datum: ${formatEuropeanDateTime(x.startDate)} | Ende Datum: ${formatEuropeanDateTime(x.endDate)}`);
                    return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} Einträge`);
            }
        }
        if (column.name === IntervalControl.Columns.cleaningOperations){
            let currentCleaningOperations = cleaningOperations.filter(x=>x.intervalControlIds && x.intervalControlIds.includes(row.id));
            let names = currentCleaningOperations.map(x=>x.text);
            return buildCellStringArray(props, names, `${t("myObjectSettings.contains")} ${names.length} Einträge`);

        }

        return <Table.Cell {...props} />;
    };


    let columns: any[] = [
        {
            name: IntervalControl.Columns.intervalControl,
            title: "Einstellung Typ",
            getCellValue: (row: IntervalControl) => {
                return GroupSettingsIntervalControl.GetLabelByType(row.interval.type)
            }
        },
        {
            name: IntervalControl.Columns.name,
            title: "Name",
            getCellValue: (row: IntervalControl) => {
                return row.name
            }
        },
        {
            name: IntervalControl.Columns.createdTimestamp,
            title: "Erstellungsdatum",
            getCellValue: (row: IntervalControl) => {
                return formatEuropeanDateTime(row.createdTimestamp)
            }
        },
        {
            name: IntervalControl.Columns.entry,
            title: "Eintrag",
            getCellValue: (row: IntervalControl) => {
                switch (row.interval.type) {
                    case GroupSettingsIntervalType.Interval:
                        return `Start: ${row.interval.startTime} | Ende: ${row.interval.endTime} | Wiederholung: ${row.interval.repeatDuration} | Reaktionszeit: ${row.interval.deviationTime}`;
                    case GroupSettingsIntervalType.Specific:
                        return `${row.interval.specificTimes.map((x,index) => `'Auslösungszeitpunkt: ${x.specificTime} | Reaktionszeit: ${x.deviationTime} \r\n'`).join('')}`;
                    default:
                        return `${row.interval.calendarEntries.map(x => `'Datum: ${formatEuropeanDateTime(x.startDate)} | Ende Datum: ${formatEuropeanDateTime(x.endDate)} \r\n'`).join(',')}`;
                }
            }
        },
        {
            name: IntervalControl.Columns.weekdays,
            title: "Wochentage",
            getCellValue: (row: IntervalControl) => {
                return row.interval.daysOfWeekEnabled.map(x=> GroupSettingsIntervalControl.GetWeekDayLabelByType(x)).join(',');
            }
        },

    ];
    if (onDelete && onEdit) {
        columns = [{
            name: IntervalControl.Columns.actions,
            title: " ",
            getCellValue: (row: IntervalControl) => {
                return <>
                    <Tooltip title={t("myUnitsSettings.edit")}>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                await onEdit(row);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Löschen">
                        <IconButton
                            style={{ color: errorColor }}
                            onClick={async () => {
                                await onDelete(row);
                            }}
                            size="large">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>;
            },
        }, ...columns]
    }
    if (cleaningOperations.length > 0){
        columns = [ ...columns, {
            name: IntervalControl.Columns.cleaningOperations,
            title: "Services",
            getCellValue: (row: IntervalControl) => {
                let currentCleaningOperations = cleaningOperations.filter(x=>x.intervalControlIds && x.intervalControlIds.includes(row.id));
                let names = currentCleaningOperations.map(x=>x.text);
                return names.join(',')
            },
        }]
    }
    const [defaultColumnWidths] = React.useState(columns.map(el => {
        if (el.name == IntervalControl.Columns.entry) return { columnName: el.name, width: 600 }
        if (el.name == IntervalControl.Columns.cleaningOperations) return { columnName: el.name, width: 350 }
        return { columnName: el.name, width: 220 }
    }));
    const [defaultHiddenColumnNames] = React.useState([]);
    return (
        <div>
            <Grid
                rows={rows}
                getRowId={(row: IntervalControl) => row.id}
                columns={columns}
            >
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection}
                />
                <IntegratedSelection />
                <FilteringState
                    columnExtensions={[
                        {
                            columnName: IntervalControl.Columns.actions,
                            filteringEnabled: false,
                        }
                    ]}
                />
                <IntegratedFiltering />

                <SortingState
                    sorting={[{ columnName: IntervalControl.Columns.id, direction: 'asc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />

                <Table
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableSelection showSelectAll />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                    hiddenColumnNames={[IntervalControl.Columns.id]}
                />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                onSave={onSave}
            />
        </div>
    );
};
