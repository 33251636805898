import { Grid } from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../../api";
import { AggregateType, getMotionData } from "../../../../helpers/charts/base";
import { prepareSensorsForChartAutoComplete } from "../../../../helpers/charts/dataGenerator";
import { getApiToken } from "../../../../helpers/storage-management";
import { Sensor } from "../../../../models/GroupSettings";
import { showGlobalDateData } from "../../../core/GlobalTimeChoice";
import { ChartCalcType } from "../../../core/TimeChoice";
import { GroupMotionChart } from "./customerUsage/GetGroupMotions";

import { loadMotionData } from "./FrequencyDashboard";

export const MotionsAggSumDashboard = (props: any) => {
    const [groupMotions, setGroupMotions] = React.useState<any>([]);
    const [groupMotionsAll, setGroupMotionsAll] = React.useState<any>([]);
    const [groupMotionType, setGroupMotionType] = React.useState<any>([]);
    const [loadingCompleted, setLoadingCompleted] = React.useState(false);
    const { groupId } = useParams<{ groupId: string }>();
    const [sensors, setSensors] = React.useState<Sensor[]>([]);
    
    const history = useHistory();

    const loadDataAsync = async () => {

        const token = getApiToken(history);
        if (!token) return;        

        await loadMotionData(
            setGroupMotionsAll,
            setGroupMotionType,
            setGroupMotions,
            setLoadingCompleted,
            AggregateType.SumIncrease,
            props.motions);

        showGlobalDateData(props.updateEndDateFromParent, props.updateStartDateFromParent, props.updateChosenPastDaysFromParent);
    }

    

    React.useEffect(() => {
        
        loadDataAsync();
    }, [props.motions]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <GroupMotionChart
                    childRefChart={props.childRefChart}
                    chartCalcType={ChartCalcType.IncreaseSum}
                    childRef={props.childRefMotion}
                    setChartData={setGroupMotions}
                    allData={groupMotionsAll}
                    data={groupMotions}
                    dataField={groupMotionType}
                    loadingCompleted={loadingCompleted} />
            </Grid>
            
        </Grid>
        
    );
}