import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Divider, Fab, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Add, Remove, ExpandMore, AdminPanelSettings, AddToHomeScreen, PhonelinkErase, SaveAltOutlined, Save } from "@mui/icons-material";
import { useSnackbar } from 'notistack';
import { GroupSettingFieldsUseStyles } from '../../styles/GroupSettingFieldsStyles';
import { CleaningOperation, FeedbackDisplayType, GroupSettings, ServiceWorkingModeType } from '../../models/GroupSettings';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { useContainerStyles } from '../../styles/container';
import { Group, GroupStateType, GroupType } from "../../models/Group";
import { api } from "../../api";
import { formatEuropeanDate } from "../../helpers/date-management";
import { DatePicker } from "@mui/x-date-pickers";
import YellowSmile from '../../assets/image/smiles/yellow.png';
import RedSmile from '../../assets/image/smiles/red.png';
import { RED_LIGHT_SMILE, RED_SMILE } from "../../assets";

export const GroupSettingFieldsAdmin = ({ row, onChange, group, token, loadData }
    : { row: GroupSettings | UserGroupSettingsTemplate, onChange: any, group: Group, token: any, loadData: any }) => {

    const classes = GroupSettingFieldsUseStyles();
    const containerStyles = useContainerStyles();

    const [deviceValidUntilDate, setDeviceValidUntilDate] = React.useState(new Date());

    const { enqueueSnackbar } = useSnackbar();
    const isThreeSmilesDisplay = row.feedbacks?.feedbackDisplayType == FeedbackDisplayType.PositiveNegativeNeutral;

    return (
        <Accordion className={classes.hover}>
            <AccordionSummary expandIcon={<ExpandMore />}            >
                <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                    <Grid item>
                        <AdminPanelSettings style={{ fontSize: 44 }} />
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4">Admin-Einstellungen</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={"column"} spacing={3}>
                    <Grid item>
                        <Typography component="h5" variant="h5" color={"primary"} fontWeight="bold">Feedback Imitation</Typography>
                    </Grid>
                    <Grid item container direction={"row"}>
                        <Grid item>
                            <img src={YellowSmile} style={{ height: 60 }} />
                        </Grid>
                        <Grid item md={9} lg={4}>
                            <TextField
                                fullWidth
                                variant={"filled"}
                                type="number"
                                name="row"
                                label="Schwelle: Imitierte Feedback Auslösung Neutral"
                                value={row.feedbacks?.adminTriggerFakeAmountNeutral}
                                onChange={(event: any) => {
                                    if (!row.feedbacks) return;

                                    row.feedbacks.adminTriggerFakeAmountNeutral = event.target.value;
                                    onChange(event);
                                }}
                            />
                        </Grid>

                        <Box width="100%" />
                        <Grid item>
                            <img src={isThreeSmilesDisplay ? RED_SMILE : RED_LIGHT_SMILE} style={{ height: 60 }} />
                        </Grid>
                        <Grid item md={9} lg={4}>
                            <TextField
                                fullWidth
                                variant={"filled"}
                                type="number"
                                name="row"
                                label="Schwelle: Imitierte Feedback Auslösung Negativ"
                                value={row.feedbacks?.adminTriggerFakeAmountNegative}
                                onChange={(event: any) => {
                                    if (!row.feedbacks) return;

                                    row.feedbacks.adminTriggerFakeAmountNegative = event.target.value;
                                    onChange(event);
                                }}
                            />
                        </Grid>
                        <Box width="100%" />
                        {!isThreeSmilesDisplay &&
                            <Grid item>
                                <img src={RedSmile} style={{ height: 60 }} />
                            </Grid>
                        }
                        {!isThreeSmilesDisplay &&
                            <Grid item md={9} lg={4}>
                                <TextField
                                    fullWidth
                                    variant={"filled"}
                                    type="number"
                                    name="row"
                                    label="Schwelle: Imitierte Feedback Auslösung Sehr Negativ"
                                    value={row.feedbacks?.adminTriggerFakeAmountVeryNegative}
                                    onChange={(event: any) => {
                                        if (!row.feedbacks) return;

                                        row.feedbacks.adminTriggerFakeAmountVeryNegative = event.target.value;
                                        onChange(event);
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid item>
                        <Typography component="h5" variant="h5" color={"primary"} fontWeight="bold">Login Status: {group.groupStateType == GroupStateType.LoggedIn ? "Kein Login" : "Login Verfügbar"}</Typography>
                    </Grid>
                    <Grid item container direction="row" spacing={3}>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<AddToHomeScreen />}
                                variant={'contained'}
                                onClick={async () => {
                                    await api.activateLoginForGroupByAdmin(group.id, token, enqueueSnackbar);
                                    loadData();
                                }}
                                color="primary"
                            >
                                Zusätzlicher Login für Einheit aktivieren
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<PhonelinkErase />}
                                variant={'contained'}
                                onClick={async () => {
                                    await api.deactivateLoginForGroupByAdmin(group.id, token, enqueueSnackbar);
                                    loadData();
                                }}
                                color="primary"
                            >
                                Login für Einheit deaktivieren
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item container direction="row" spacing={3} alignItems="center">
                        <Grid item>
                            {group.validUntil &&
                                <Typography component="h5" variant="h5" color={"primary"} fontWeight="bold">Einheit Gültig bis: {formatEuropeanDate(group?.validUntil)}</Typography>
                            }
                        </Grid>
                        <Grid item>
                            < DatePicker
                                slotProps={{ textField: { variant: 'outlined' } }}
                                label={"Update Einheit Gültig"}
                                value={deviceValidUntilDate}
                                onChange={(newVal) => {
                                    if (!newVal) return;
                                    setDeviceValidUntilDate(newVal);
                                }}
                                format={"dd.MM.yyyy"}

                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                startIcon={<Save />}
                                variant={'contained'}
                                onClick={async () => {
                                    await api.setDeviceValidUntilAdmin(deviceValidUntilDate, group.id, token, enqueueSnackbar);
                                    loadData();
                                }}
                                color="primary"
                            >
                                Einheit Gültig Bis Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
