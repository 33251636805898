import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import { getApiToken } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { UserAccount } from '../../../models/UserAccount';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Email, NavigateNext, Navigation, Save, VpnKey } from '@mui/icons-material';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { AzureStorageBrowserModel } from '../../../models/AzureStorageBrowserModel';

import GroupsLicensePng from '../../../assets/image/license/groups_license.png'
import ObjectLicensePng from '../../../assets/svgs/office-building-cog.svg'
import OverviewLicensePng from '../../../assets/svgs/license_grey.svg'

import { primaryColor } from '../../../App';
import { lightBlue, lightRed } from '../../../styles/colors';
import { GlobalContext } from '../PortalPage';
import { GLOBAL_IS_EVAL_ACCOUNT_VISIBLE, GLOBAL_IS_EVAL_DASHBOARD_VISIBLE, GLOBAL_IS_EVAL_GROUPS_VISIBLE, GLOBAL_IS_EVAL_NEWS_INFO_VISIBLE, GLOBAL_IS_EVAL_OBJECTS_VISIBLE, GLOBAL_IS_EVAL_SERVICE_WORKERS_VISIBLE, GLOBAL_IS_EVAL_SETTINGS_VISIBLE, GLOBAL_IS_EVAL_SHOP_VISIBLE } from '../../../constants';
import { LicenseSvgGrey } from '../../../assets';
import { useTranslation } from 'react-i18next';


export const LicenseOverview = () => {

    const classes = usePortalAccountStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [token, setToken] = React.useState("");
    const [newsList, setNewsList] = React.useState<AzureStorageBrowserModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();



    React.useEffect(() => {
        (async () => {
            let token = getApiToken(history);
            if (!token) return;
            setLoading(true);
            setLoading(false);
            setToken(token);
        })();
    }, []);

    const witdhBox = 330;
    const heightBox = 330;

    const heightImage = 230;
    const backgroundColor = lightBlue;
    const backgroundNotSelectedColor = lightRed;
    const padding = 20;
    return (
        <Paper className={classes.root}>
            <Grid container direction={"column"} justifyContent="center" alignItems={"center"} spacing={3}>
                <Grid item>
                    <Typography component="h2" variant="h2" className={classes.title}>{t("licenses.licenseMainMenu")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                <Grid item container direction="row" justifyContent={"center"} alignItems="center">
                    <Grid container direction={"column"} item xs={12} spacing={3} justifyContent={"center"} alignItems="center">
                        <Grid container direction={"row"} item spacing={3} justifyContent={"center"} alignItems="center" alignContent={"center"} justifyItems={"center"}>
                            <Grid item>
                                <Card sx={{ backgroundColor: backgroundColor, width: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/portal/license-general',
                                            });
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            src={OverviewLicensePng}
                                            alt="green iguana"

                                        />
                                        <CardContent>
                                            <Typography gutterBottom component="div" fontWeight={'bold'} fontSize={23}>
                                                {t("licenses.licenseOverview")}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card sx={{ backgroundColor: backgroundColor, width: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/portal/license-einheit-management',
                                            });
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={GroupsLicensePng}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography component="div" fontWeight={'bold'} fontSize={23}>
                                                {t("licenses.addAndLicenseUnit")}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card sx={{ backgroundColor: backgroundColor, width: witdhBox, height: heightBox, textAlign: 'center' }}>
                                    <CardActionArea style={{ padding: padding }}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/portal/objekte',
                                            });
                                        }}>
                                        <CardMedia
                                            style={{ objectFit: 'contain' }}
                                            component="img"
                                            height={heightImage}
                                            image={ObjectLicensePng}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom component="div" fontWeight={'bold'} fontSize={23}>
                                            {t("licenses.licenseObjects")}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    );
}