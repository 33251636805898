import axios from "axios";
import { headers } from "../api";
import { getUserIdFromStorage } from "../helpers/common";
import { GroupLicense } from "../models/GroupLicense";
import { UserProperty } from "../models/UserProperty";
import { GroupSettings } from "../models/GroupSettings";

export const assignPropertyToGroup = async (groupId: string, propertyId: string, token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();


    await axios.post<GroupSettings>(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/property/${propertyId}/assign`,null,
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        if (!res.data.locationInformation?.userPropertyIds.some(id => id == propertyId)){
            enqueueSnackbar(`Die Einheit ${res?.data?.locationInformation?.notificationName} kann genau 1 Systemmerkmal zugewiesen bekommen`, { variant: 'error' });
        }else{
            enqueueSnackbar(`Die Einheit ${res?.data?.locationInformation?.notificationName} wurde dem Merkmal erfolgreich zugewiesen`, { variant: 'success' });
        }
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};

export const assignPropertiesToGroup = async (groupId: string, selectedProperties: UserProperty [], token: string, enqueueSnackbar: any) => {

    if (!groupId || !token || !enqueueSnackbar) return;

    const userId = getUserIdFromStorage();


    await axios.post<GroupSettings>(
        `${process.env.REACT_APP_BASIC_URI}/groups/${groupId}/users/${userId}/properties/assign`,{stringArray: selectedProperties.map(x => x.id)},
        { headers: { ...headers, 'Authorization': `Bearer ${token}` } }
    ).then((res) => {
        if (res.data){
            for (let property of selectedProperties){
                if (!res.data.locationInformation?.userPropertyIds.some(id => id == property.id)){
                    enqueueSnackbar(`Die Einheit ${res?.data?.locationInformation?.notificationName} kann 1 Systemmerkmal zugewiesen bekommen`, { variant: 'error' });
                }
            }
        }
        enqueueSnackbar(`Die Einheit ${res?.data?.locationInformation?.notificationName} wurden Merkmale erfolgreich zugewiesen`, { variant: 'success' });
    }).catch((error) => {
        enqueueSnackbar("Bei der Zuweisung ist ein Fehler aufgetreten", { variant: 'error' });
    });
};